/*************************************************
 * RedInk
 * @exports
 * @class ClassDetailsAction.js
 * @extends Component
 * @author vijayakumar // on 05/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { ACTION_TYPES } from "../types"

export const saveClassDetailsInStore = ClassDetails => {
  return {
    type: ACTION_TYPES.CLASS_LIST,
    payload: ClassDetails,
  }
}

export const getClassDetails = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_CLASS_DETAILS, object, true)
      if (response) {
        myLog("====getClassDetails RESPONSE===::::", response)
        dispatch(saveClassDetailsInStore(response.classList))
        callBack(true, response.classList)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getClassDetails ERROR===????", error)
    }
  }
}

export const saveClassDetails = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      const response = await Client.post(URL.SAVE_CLASS_DETAILS, body, true)
      if (response.responseCode === 0) {
        myLog("====saveClassDetails RESPONSE===::::", response)
        dispatch(getClassDetails(() => {}))
        callBack(true)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====saveClassDetails ERROR===????", error)
    }
  }
}

export const updateClassDetails = (body, callBack) => {
  return async () => {
    try {
      const response = await Client.post(URL.UPDATE_CLASS_DETAILS, body, true)
      if (response.responseCode === 0) {
        myLog("====updateClassDetails RESPONSE===::::", response)
        callBack(true)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====updateClassDetails ERROR===????", error)
    }
  }
}

export const deleteClassDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(URL.DELETE_CLASS_DETAILS, body, true)
      if (response.responseCode === 0) {
        myLog("====deleteClassDetails RESPONSE===::::", response)
        dispatch(getClassDetails(() => {}))
        callBack(true)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====deleteClassDetails ERROR===????", error)
    }
  }
}
