/*************************************************
 * RedInk
 * @exports
 * @class HierarchyAction.js
 * @extends Component
 * @author vijayakumar // on 05/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { ACTION_TYPES } from "../types"
import { showNotification } from "../CommonAction"
import {
  SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"

export const gradeBookLoaded = payload => {
  return {
    type: ACTION_TYPES.IS_GRADEBOOK_LOADED,
    payload,
  }
}

export const updateSelectedIndices = payload => {
  return {
    type: ACTION_TYPES.SELECTED_GRADEBOOK_BOX_IINDEICES,
    payload,
  }
}

export const saveExamHierarchyDetailsInStore = HierarchyDetails => {
  return {
    type: ACTION_TYPES.EXAM_HIERARCHY_DETAILS,
    payload: HierarchyDetails,
  }
}

export const saveExamHierarchyTermsListInStore = HierarchyTermsList => {
  return {
    type: ACTION_TYPES.EXAM_HIERARCHY_TERMS_LIST,
    payload: HierarchyTermsList,
  }
}

export const saveExamHierarchyFAsListInStore = HierarchyFAsList => {
  return {
    type: ACTION_TYPES.EXAM_HIERARCHY_FAS_LIST,
    payload: HierarchyFAsList,
  }
}

export const saveExamHierarchySubjectListInStore = HierarchySubjectList => {
  return {
    type: ACTION_TYPES.EXAM_HIERARCHY_SUBJECT_LIST,
    payload: HierarchySubjectList,
  }
}

export const saveStudentPerformanceReportDetailsInStore = studentPerformanceReportDetails => {
  return {
    type: ACTION_TYPES.TEACHER_STUDENT_PERFORMANCE_REPORT_DETAILS,
    payload: studentPerformanceReportDetails,
  }
}

export const saveExamHierarchyTheoryAndPracticalListInStore = HierarchyTheoryAndPracticalList => {
  return {
    type: ACTION_TYPES.EXAM_HIERARCHY_THEORYANDPRACTICAL_DETAILS,
    payload: HierarchyTheoryAndPracticalList,
  }
}

export const saveExamHierarchyActivityListInStore = HierarchyActivityList => {
  return {
    type: ACTION_TYPES.EXAM_HIERARCHY_ACTIVITY_DETAILS,
    payload: HierarchyActivityList,
  }
}

export const saveExamListDetailsInStore = examList => {
  return {
    type: ACTION_TYPES.TEACHER_EXAM_LIST_DETAILS,
    payload: examList,
  }
}

export const saveSubjectListDetailsInStore = subjectList => {
  return {
    type: ACTION_TYPES.TEACHER_SUBJECT_LIST_DETAILS,
    payload: subjectList,
  }
}

export const saveExamHierarchySettingsDetailsInStore = examHierarchySettingObject => {
  return {
    type: ACTION_TYPES.TEACHER_EXAM_HIERARCHY_SETTINGS_DETAILS,
    payload: examHierarchySettingObject,
  }
}

export const saveActivityConditionDetailsInStore = activityConditionList => {
  return {
    type: ACTION_TYPES.TEACHER_ACTIVITY_CONDITION_DETAILS,
    payload: activityConditionList,
  }
}

export const getExamListDetails = callBack => {
  return async dispatch => {
    try {
      myLog("====getExamListDetails===::::")
      // dispatch(saveExamListDetailsInStore(response.examList))
      // callBack(true, response.examList)
      const response = await Client.get(URL.GET_ASSESSMENT_DETAILS, null, true)
      if (response) {
        myLog("====getExamListDetails RESPONSE===::::", response)
        const sorted = response.assessments.sort((a, b) =>
          a.label.localeCompare(b.label, undefined, { numeric: true })
        )
        myLog("Sorted", sorted)
        dispatch(saveExamListDetailsInStore(sorted))
        callBack(true, sorted)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getExamListDetails ERROR===????", error)
    }
  }
}

export const getSubjectListDetails = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_SUBJECT_LIST_DETAILS,
        object,
        true
      )
      if (response) {
        myLog("====getSubjectListDetails RESPONSE===::::", response)
        dispatch(saveSubjectListDetailsInStore(response.subjects))
        callBack(true, response.subjects)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getSubjectListDetails ERROR===????", error)
    }
  }
}

export const getActivityConditionListDetails = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_ACTIVITY_CONDITION_LIST_DETAILS,
        null,
        true
      )
      if (response) {
        myLog("====getActivityConditionListDetails RESPONSE===::::", response)
        dispatch(
          saveActivityConditionDetailsInStore(response.activityConditions)
        )
        callBack(true, response.activityConditions)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getActivityConditionListDetails ERROR===????", error)
    }
  }
}

export const getSubjectListByClassDetails = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_SUBJECT_LIST_BY_CLASS_DETAILS,
        object,
        true
      )
      if (response) {
        myLog("====getSubjectListByClassDetails RESPONSE===::::", response)
        dispatch(saveSubjectListDetailsInStore(response.subjectsForClass))
        callBack(true, response.subjectsForClass)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getSubjectListByClassDetails ERROR===????", error)
    }
  }
}

export const getExamHierarchyDetails = (object, callBack) => {
  return async (dispatch, getState) => {
    try {
      const response = await Client.get(
        URL.GET_EXAM_HIERARCHY_DETAILS,
        object,
        true
      )
      myLog("====getExamHierarchyDetails RESPONSE===::::", response)

      if (response) {
        const {
          termIndex,
          faIndex,
          subjectIndex,
          theoryAndPracticalIndex,
        } = getState().hierarchyState.selected

        response.hierarchyDetails.terms.forEach(t => {
          t.FAs = [...t?.FAs]?.sort((a, b) =>
            a?.name?.localeCompare(b?.name, undefined, { numeric: true })
          )
        })
        dispatch(
          saveExamHierarchyDetailsInStore({
            ...response.hierarchyDetails,
            ...object,
          })
        )

        let termsList = []
        let FAsList = []
        let subjectsList = []
        let theoryAndPracticalList = []
        let activitiesList = []

        if (response.hierarchyDetails.terms.length) {
          termsList = response.hierarchyDetails.terms

          if (termsList[termIndex].FAs.length) {
            FAsList = termsList[termIndex].FAs

            if (FAsList[faIndex]?.subjects?.length) {
              subjectsList = FAsList[faIndex].subjects

              if (subjectsList[subjectIndex]?.theoryAndPractical?.length) {
                theoryAndPracticalList =
                  subjectsList[subjectIndex].theoryAndPractical

                if (
                  theoryAndPracticalList[theoryAndPracticalIndex]?.activities
                    ?.length
                ) {
                  activitiesList =
                    theoryAndPracticalList[theoryAndPracticalIndex].activities
                }
              }
            }
          }
        }

        dispatch(saveExamHierarchyTermsListInStore(termsList))
        dispatch(saveExamHierarchyFAsListInStore(FAsList))
        dispatch(saveExamHierarchySubjectListInStore(subjectsList))
        dispatch(
          saveExamHierarchyTheoryAndPracticalListInStore(theoryAndPracticalList)
        )
        dispatch(saveExamHierarchyActivityListInStore(activitiesList))

        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getExamHierarchyDetails ERROR===????", error)
    }
  }
}

export const saveExamHierarchyAcademicObject = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      myLog("====saveExamHierarchyAcademicObject body===::::", body)
      let object = {
        academicId: body.academicId,
        classId: body.classId,
        name: body.name,
        shortName: body.shortName,
      }
      myLog("====saveExamHierarchyAcademicObject object===::::", object)
      // const response = await Client.post(URL.SAVE_EXAM_HIERARCHY_ACADEMIC_OBJECT, body, true)
      // if (response.responseCode === 0) {
      //   myLog("====saveExamHierarchyAcademicObject RESPONSE===::::", response)
      //   // dispatch(getExamHierarchyDetails(()=>{}));
      //   callBack(true)
      // } else {
      //   callBack(false)
      // }
    } catch (error) {
      callBack(false)
      myLog("====saveExamHierarchyAcademicObject ERROR===????", error)
    }
  }
}

export const saveExamHierarchyTermsList = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      myLog("====saveExamHierarchyTermsList body===::::", body)
      let object = {
        academicId: body.academicId,
        name: body.name,
        shortName: body.shortName,
        isLocked: body.isLocked,
        termFrom: body.termFrom ? body.termFrom : "",
        termTo: body.termTo ? body.termTo : "",
      }
      if (body.hasOwnProperty("termId") && body.termId !== "") {
        object.termId = body.termId
      }
      myLog("====saveExamHierarchyTermsList object===::::", object)
      const response = await Client.post(
        URL.SAVE_EXAM_HIERARCHY_TERMS_LIST,
        object,
        true
      )
      let object1 = {
        sectionId: body.classId,
      }
      dispatch(
        getExamHierarchyDetails(object1, () => {
          if (response.responseCode === 0) {
            myLog("====saveExamHierarchyTermsList RESPONSE===::::", response)

            dispatch(
              showNotification(
                SUCCESS_TOAST,
                VALIDATION_MSG.SUCCESS,
                response.responseMessage
              )
            )
            callBack(true)
          } else {
            dispatch(
              showNotification(
                DANGER_TOAST,
                VALIDATION_MSG.OOPS,
                response.responseMessage
              )
            )
            callBack(false)
          }
        })
      )
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveExamHierarchyTermsList ERROR===????", error)
    }
  }
}

export const saveExamHierarchyFAsList = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      myLog("====saveExamHierarchyFAsList body===::::", body)
      let object = {
        academicId: body.academicId,
        termId: body.hasOwnProperty("termId") ? body.termId : body.term,
        sectionId: body.classId,
        name: body.name,
        shortName: body.shortName,
        isLocked: body.isLocked,
        termScoreEvaluation: body.termScoreEvaluation,
        mustPass: body.mustPass,
        typeOfAssessment: body.typeOfAssessment,
        assessmentFrom: body.assessmentFrom ? body.assessmentFrom : "",
        assessmentTo: body.assessmentTo ? body.assessmentTo : "",
      }
      if (body.hasOwnProperty("faId") && body.faId !== "") {
        object.assessmentId = body.faId
      }
      myLog("====saveExamHierarchyFAsList object===::::", object)
      const response = await Client.post(
        URL.SAVE_EXAM_HIERARCHY_FAS_LIST,
        object,
        true
      )
      let object1 = {
        sectionId: body.classId,
      }
      dispatch(
        getExamHierarchyDetails(object1, () => {
          if (response.responseCode === 0) {
            myLog("====saveExamHierarchyFAsList RESPONSE===::::", response)
            dispatch(
              showNotification(
                SUCCESS_TOAST,
                VALIDATION_MSG.SUCCESS,
                response.responseMessage
              )
            )
            callBack(true)
          } else {
            dispatch(
              showNotification(
                DANGER_TOAST,
                VALIDATION_MSG.OOPS,
                response.responseMessage
              )
            )
            callBack(false)
          }
        })
      )
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveExamHierarchyFAsList ERROR===????", error)
    }
  }
}

export const saveExamHierarchyFAsDate = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      myLog("====saveExamHierarchyFAsDate body===::::", body)
      let object = {
        academicId: body.academicId,
        termId: body.hasOwnProperty("termId") ? body.termId : body.term,
        sectionId: body.classId,
        assessmentFrom: body.assessmentFrom ? body.assessmentFrom : "",
        assessmentTo: body.assessmentTo ? body.assessmentTo : "",
        isClassSpecific: body.isClassSpecific,
        assessmentDateId: body.assessmentDateId ? body.assessmentDateId : "",
      }
      if (body.hasOwnProperty("faId") && body.faId !== "") {
        object.assessmentId = body.faId
      }
      myLog("====saveExamHierarchyFAsDate object===::::", object)
      const response = await Client.post(
        URL.SAVE_EXAM_HIERARCHY_FAS_DATE,
        object,
        true
      )
      let object1 = {
        sectionId: body.classId,
      }
      dispatch(
        getExamHierarchyDetails(object1, () => {
          if (response.responseCode === 0) {
            myLog("====saveExamHierarchyFAsDate RESPONSE===::::", response)
            dispatch(
              showNotification(
                SUCCESS_TOAST,
                VALIDATION_MSG.SUCCESS,
                response.responseMessage
              )
            )
            callBack(true)
          } else {
            dispatch(
              showNotification(
                DANGER_TOAST,
                VALIDATION_MSG.OOPS,
                response.responseMessage
              )
            )
            callBack(false)
          }
        })
      )
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveExamHierarchyFAsDate ERROR===????", error)
    }
  }
}

export const saveExamHierarchySubjectList = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      myLog("====saveExamHierarchySubjectList body===::::", body)
      let object = {
        academicId: body.academicId,
        classId: body.classId,
        termId: body.hasOwnProperty("termId") ? body.termId : body.term,
        faId: body.hasOwnProperty("faId") ? body.faId : body.fa,
        name: body.name,
        shortName: body.shortName,
        isLocked: body.isLocked,
        maxMark: body.maxMark,
        minMark: body.minMark,
        passMark: body.passMark,
        distinctionMark: body.distinctionMark,
        convertMark: body.convertMark,
        needToConvertMark: body.needToConvertMark,
        grades:
          body.grades && body.grades[0].gradeName === "" ? [] : body.grades,
        considerMarkToAssessmentTotal: body.considerMarkToAssessmentTotal,
        requiredToPassToGetRank: body.requiredToPassToGetRank,
        examDay: body.examDay ? body.examDay : "",
        classSubjectId: body.classSubjectId,
        activityConditionValue: body.activityConditionValue,
        theoryConvertMarkTo: body.theoryConvertMarkTo,
        theoryMark: body.theoryMark,
        needToConvertTheoryMark: body.needToConvertTheoryMark,
        examType: body.examType,
      }
      if (body.hasOwnProperty("subjectId") && body.subjectId !== "") {
        object.subjectId = body.subjectId
      }
      myLog("====saveExamHierarchySubjectList object===::::", object)
      const response = await Client.post(
        URL.SAVE_EXAM_HIERARCHY_SUBJECT_LIST,
        object,
        true
      )
      let object1 = {
        sectionId: body.classId,
      }
      dispatch(
        getExamHierarchyDetails(object1, () => {
          if (response.responseCode === 0) {
            myLog("====saveExamHierarchySubjectList RESPONSE===::::", response)
            dispatch(
              showNotification(
                SUCCESS_TOAST,
                VALIDATION_MSG.SUCCESS,
                response.responseMessage
              )
            )
            callBack(true)
          } else {
            dispatch(
              showNotification(
                DANGER_TOAST,
                VALIDATION_MSG.OOPS,
                response.responseMessage
              )
            )
            callBack(false)
          }
        })
      )
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveExamHierarchySubjectList ERROR===????", error)
    }
  }
}

export const saveExamHierarchyTheoryList = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      myLog("====saveExamHierarchyTheoryList body===::::", body)
      let object = {
        academicId: body.academicId,
        sectionId: body.classId,
        termId: body.hasOwnProperty("termId") ? body.termId : body.term,
        faId: body.hasOwnProperty("faId") ? body.faId : body.fa,
        subjectId: body.hasOwnProperty("subjectId")
          ? body.subjectId
          : body.subject,
        name: body.name,
        shortName: body.shortName,
        isLocked: body.isLocked,
        maxMark: body.maxMark,
        minMark: body.minMark,
        passMark: body.passMark,
        distinctionMark: body.distinctionMark,
        convertMark: body.convertMark,
        needToConvertMark: body.needToConvertMark,
        grades:
          body.grades && body.grades[0].gradeName === "" ? [] : body.grades,
        includeMarkToSubjectTotal: body.includeMarkToSubjectTotal,
        activityDay: body.activityDay ? body.activityDay : "",
        classSubjectId: body.classSubjectId,
        theoryId: body.theoryId,
        requiredToPassToGetRank: body.requiredToPassToGetRank,
        examDay: body.examDay ? body.examDay : "",
      }
      if (body.hasOwnProperty("activityId") && body.activityId !== "") {
        object.activityId = body.activityId
      }
      myLog("====saveExamHierarchyTheoryList object===::::", object)
      const response = await Client.post(
        URL.SAVE_EXAM_HIERARCHY_THEORY_DETAILS,
        object,
        true
      )
      let object1 = {
        sectionId: body.classId,
      }
      dispatch(
        getExamHierarchyDetails(object1, () => {
          if (response.responseCode === 0) {
            myLog("====saveExamHierarchyTheoryList RESPONSE===::::", response)

            dispatch(
              showNotification(
                SUCCESS_TOAST,
                VALIDATION_MSG.SUCCESS,
                response.responseMessage
              )
            )
            callBack(true)
          } else {
            dispatch(
              showNotification(
                DANGER_TOAST,
                VALIDATION_MSG.OOPS,
                response.responseMessage
              )
            )
            callBack(false)
          }
        })
      )
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveExamHierarchyTheoryList ERROR===????", error)
    }
  }
}

export const saveExamHierarchyPracticalList = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      myLog("====saveExamHierarchyPracticalList body===::::", body)
      let object = {
        academicId: body.academicId,
        sectionId: body.classId,
        termId: body.hasOwnProperty("termId") ? body.termId : body.term,
        faId: body.hasOwnProperty("faId") ? body.faId : body.fa,
        subjectId: body.hasOwnProperty("subjectId")
          ? body.subjectId
          : body.subject,
        name: body.name,
        shortName: body.shortName,
        isLocked: body.isLocked,
        maxMark: body.maxMark,
        minMark: body.minMark,
        passMark: body.passMark,
        distinctionMark: body.distinctionMark,
        convertMark: body.convertMark,
        needToConvertMark: body.needToConvertMark,
        grades:
          body.grades && body.grades[0].gradeName === "" ? [] : body.grades,
        includeMarkToSubjectTotal: body.includeMarkToSubjectTotal,
        activityDay: body.activityDay ? body.activityDay : "",
        classSubjectId: body.classSubjectId,
        practicalId: body.practicalId,
        requiredToPassToGetRank: body.requiredToPassToGetRank,
        activityConditionValue: body.activityConditionValue,
        examDay: body.examDay ? body.examDay : "",
      }
      if (body.hasOwnProperty("activityId") && body.activityId !== "") {
        object.activityId = body.activityId
      }
      myLog("====saveExamHierarchyPracticalList object===::::", object)
      const response = await Client.post(
        URL.SAVE_EXAM_HIERARCHY_PRACTICAL_DETAILS,
        object,
        true
      )
      let object1 = {
        sectionId: body.classId,
      }
      dispatch(
        getExamHierarchyDetails(object1, () => {
          if (response.responseCode === 0) {
            myLog(
              "====saveExamHierarchyPracticalList RESPONSE===::::",
              response
            )

            dispatch(
              showNotification(
                SUCCESS_TOAST,
                VALIDATION_MSG.SUCCESS,
                response.responseMessage
              )
            )
            callBack(true)
          } else {
            dispatch(
              showNotification(
                DANGER_TOAST,
                VALIDATION_MSG.OOPS,
                response.responseMessage
              )
            )
            callBack(false)
          }
        })
      )
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveExamHierarchyPracticalList ERROR===????", error)
    }
  }
}

export const saveExamHierarchyActivityList = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      myLog("====saveExamHierarchyActivityList body===::::", body)
      let object = {
        academicId: body.academicId,
        classId: body.classId,
        termId: body.hasOwnProperty("termId") ? body.termId : body.term,
        faId: body.hasOwnProperty("faId") ? body.faId : body.fa,
        subjectId: body.hasOwnProperty("subjectId")
          ? body.subjectId
          : body.subject,
        name: body.name,
        shortName: body.shortName,
        isLocked: body.isLocked,
        maxMark: body.maxMark,
        minMark: body.minMark,
        passMark: body.passMark,
        distinctionMark: body.distinctionMark,
        convertMark: body.convertMark,
        needToConvertMark: body.needToConvertMark,
        grades:
          body.grades && body.grades[0].gradeName === "" ? [] : body.grades,
        includeMarkToSubjectTotal: body.includeMarkToSubjectTotal,
        activityDay: body.activityDay ? body.activityDay : "",
        classSubjectId: body.classSubjectId,
        practicalId: body.practicalId,
      }
      if (body.hasOwnProperty("activityId") && body.activityId !== "") {
        object.activityId = body.activityId
      }
      myLog("====saveExamHierarchyActivityList object===::::", object)
      const response = await Client.post(
        URL.SAVE_EXAM_HIERARCHY_ACTIVITY_DETAILS,
        object,
        true
      )
      let object1 = {
        sectionId: body.classId,
      }
      dispatch(
        getExamHierarchyDetails(object1, () => {
          if (response.responseCode === 0) {
            myLog("====saveExamHierarchyActivityList RESPONSE===::::", response)

            dispatch(
              showNotification(
                SUCCESS_TOAST,
                VALIDATION_MSG.SUCCESS,
                response.responseMessage
              )
            )
            callBack(true)
          } else {
            dispatch(
              showNotification(
                DANGER_TOAST,
                VALIDATION_MSG.OOPS,
                response.responseMessage
              )
            )
            callBack(false)
          }
        })
      )
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveExamHierarchyActivityList ERROR===????", error)
    }
  }
}

export const deleteExamHierarchyTermsDetails = (body, classId, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.DELETE_EXAM_HIERARCHY_TERMS_LIST,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteExamHierarchyTermsDetails RESPONSE===::::", response)
        let object = {
          sectionId: classId,
        }
        dispatch(getExamHierarchyDetails(object, () => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteExamHierarchyTermsDetails ERROR===????", error)
    }
  }
}

export const deleteExamHierarchyFAsDetails = (body, classId, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.DELETE_EXAM_HIERARCHY_FAS_LIST,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteExamHierarchyFAsDetails RESPONSE===::::", response)
        let object = {
          sectionId: classId,
        }
        dispatch(getExamHierarchyDetails(object, () => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteExamHierarchyFAsDetails ERROR===????", error)
    }
  }
}

export const deleteExamHierarchySubjectDetails = (body, classId, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.DELETE_EXAM_HIERARCHY_SUBJECT_LIST,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteExamHierarchySubjectDetails RESPONSE===::::", response)
        let object = {
          sectionId: classId,
        }
        dispatch(getExamHierarchyDetails(object, () => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteExamHierarchySubjectDetails ERROR===????", error)
    }
  }
}

export const deleteExamHierarchyActivityDetails = (body, classId, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.DELETE_EXAM_HIERARCHY_ACTIVITY_DETAILS,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog(
          "====deleteExamHierarchyActivityDetails RESPONSE===::::",
          response
        )
        let object = {
          sectionId: classId,
        }
        dispatch(getExamHierarchyDetails(object, () => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteExamHierarchyActivityDetails ERROR===????", error)
    }
  }
}
// export const updateClassDetails = (body, callBack) => {
//   return async () => {
//     try {
//       const response = await Client.post(URL.UPDATE_CLASS_DETAILS, body, true)
//       if (response.responseCode === 0) {
//         myLog("====updateClassDetails RESPONSE===::::", response)
//         callBack(true)
//       } else {
//         callBack(false)
//       }
//     } catch (error) {
//       callBack(false)
//       myLog("====updateClassDetails ERROR===????", error)
//     }
//   }
// }

// export const deleteClassDetails = (body, callBack) => {
//   return async (dispatch) => {
//     try {
//       const response = await Client.delete(URL.DELETE_CLASS_DETAILS, body, true)
//       if (response.responseCode === 0) {
//         myLog("====deleteClassDetails RESPONSE===::::", response)
//         dispatch(getExamHierarchyDetails(()=>{}));
//         callBack(true)
//       } else {
//         callBack(false)
//       }
//     } catch (error) {
//       callBack(false)
//       myLog("====deleteClassDetails ERROR===????", error)
//     }
//   }
// }

export const getStudentPerformanceReportDetails = callBack => {
  return async dispatch => {
    try {
      myLog("====getStudentPerformanceReportDetails===::::")
      let response = {
        studentPerformanceReportDetails: [
          {
            rollNo: "1234",
            studentName: "vijay",
            class: "LKG-A",
            mark: "50",
            percentage: "30",
            grade: "A2",
            rank: "5",
            rankStatus: "increase",
            schoolRank: "10",
            schoolRankStatus: "descrease",
          },
          {
            rollNo: "1234",
            studentName: "sasi",
            class: "LKG-A",
            mark: "50",
            percentage: "70",
            grade: "A2",
            rank: "5",
            rankStatus: "increase",
            schoolRank: "10",
            schoolRankStatus: "descrease",
          },
          {
            rollNo: "1234",
            studentName: "kumar",
            class: "LKG-A",
            mark: "50",
            percentage: "60",
            grade: "A2",
            rank: "5",
            rankStatus: "increase",
            schoolRank: "10",
            schoolRankStatus: "descrease",
          },
          {
            rollNo: "1234",
            studentName: "vijay",
            class: "LKG-A",
            mark: "50",
            percentage: "80",
            grade: "A2",
            rank: "5",
            rankStatus: "increase",
            schoolRank: "10",
            schoolRankStatus: "descrease",
          },
        ],
      }
      myLog("====getStudentPerformanceReportDetails===::::", response)
      dispatch(
        saveStudentPerformanceReportDetailsInStore(
          response.studentPerformanceReportDetails
        )
      )
      callBack(true, response.studentPerformanceReportDetails)
      //   const response = await Client.get(URL.GET_ATTENDANCE_DETAILS, date, true)
      //   if (response) {
      //     myLog("====getStudentPerformanceReportDetails RESPONSE===::::", response)
      //     dispatch(savePerformanceReportDetailsInStore(response.studentPerformanceReportDetails));
      //     callBack(true, response.studentPerformanceReportDetails)
      //   } else {
      //     callBack(false)
      //   }
    } catch (error) {
      callBack(false)
      myLog("====getStudentPerformanceReportDetails ERROR===????", error)
    }
  }
}
