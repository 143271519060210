import React, { useEffect, useState } from "react"
import { Modal } from "reactstrap"
import "./loader.css"

function LoaderModal({ closeModal }) {
  const [val, setVal] = useState(0)

  useEffect(() => {
    const id = setTimeout(() => {
      if (val < 100) {
        setVal(p => p + 10)
      } else {
        // closeModal()
      }
    }, 1000)

    return () => clearTimeout(id)
  }, [val])

  return (
    <Modal
      isOpen
      backdropClassName="upgrade-loader-modal modal-backdrop-show"
      modalClassName="modal-dialog-shadow-none"
      contentClassName="bg-4c4c4c"
      centered
      size="md"
    >
      <div className="upgrade-loader-modal-content">
        <div className="fs-15 text-white">Importing...</div>
        <div className="progress mx-auto" style={{ height: "24px" }}>
          <div
            className="progress-bar transition-width"
            role="progressbar"
            aria-valuenow={val}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: `${val}%` }}
          >
            {val}%
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default LoaderModal
