/*************************************************
 * RedInk
 * @exports
 * @class Profile.js
 * @extends Component
 * @author Sasidharan // on 01/02/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import "react-sweet-progress/lib/style.css"
import AppWrapper from "./Wrapper"
import MessageModal from "./MessageModal"
import {
  getTeacherProfile,
  updateTeacherProfile,
  deleteTeacherQualification,
  deleteTeacherSkill,
} from "../../actions/teacher/DashboardAction"
import { HashLoader } from "react-spinners"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import malePic from "../../assets/images/men.png"
import femalePic from "../../assets/images/female.png"
import { myLog } from "../../utils/Utility" // , validateEmail
import { showNotification } from "../../actions/CommonAction"
import { DANGER_TOAST, VALIDATION_MSG } from "../../utils/Constants"

// const checkOnlyLetters = name => {
//   var regex = new RegExp(/^[A-Za-z. ]+$/)
//   return name.match(regex)
// }

// const checkOnlyNumber = num => {
//   var regex = new RegExp(/^[0-9]{10}$/)
//   return num.match(regex)
// }

class TeacherProfile extends Component {
  constructor(props) {
    super(props)
    document.title = "Profile | Redink"
    this.state = {
      isShowLoading: false,
      id: "",
      firstName: "",
      lastName: "",
      gender: "",
      contactNumber: "",
      emailId: "",
      address: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
      classTeacherFor: "",
      teacherImage: "",
      experience: "",
      dateOfJoining: "",
      assignedClassAndSubject: [],
      // currClass: {},
      qualificationDisplay: [{ qualification: "", id: "" }],
      skillDisplay: [],
      isShowAddqualification: true,
      // updatequalificationId: "",
      isShowAddSkill: false,
      skillName: "",
      isProfileEditing: true,
      isEdQualifyEditing: true,
      isEdQualifyLoading: false,
    }
  }

  componentDidMount = () => {
    this.getProfile()
  }

  getProfile = () => {
    this.props.getTeacherProfile((success, response) => {
      if (success) {
        myLog("Teacher Data in Profile", response.teacherDetails)
        this.setState({
          id: response.teacherDetails.id || "",
          // assignedClasses: response.assigned,
          // teacherImage: response.teacherImage,
          firstName: response.teacherDetails.firstName || "",
          lastName: response.teacherDetails.lastName || "",
          gender: response.teacherDetails.gender || "",
          contactNumber: response.teacherDetails.contactNumber || "",
          emailId: response.teacherDetails.emailId || "",
          address: response.teacherDetails.address || "",
          city: response.teacherDetails.city || "",
          state: response.teacherDetails.state || "",
          country: response.teacherDetails.country || "",
          zipCode: response.teacherDetails.zipCode || "",
          classTeacherFor: response.teacherDetails.classTeacherFor.value || "",
          assignedClassAndSubject:
            response.teacherDetails.assignedClassAndSubject || [],
          dateOfJoining: response.teacherDetails.dateOfJoining || "",
          experience: response.teacherDetails.experience || "",
          qualificationDisplay:
            response.teacherDetails.qualifications.length > 0
              ? response.teacherDetails.qualifications
              : [{ qualification: "", id: "" }],
          skillDisplay:
            response.teacherDetails.skills.length > 0
              ? response.teacherDetails.skills
              : [],
        })
      }
    })
  }

  addqualification = () => {
    const tempARR = this.state.qualificationDisplay
    tempARR.push({ qualification: "", id: "" })
    this.setState({ qualificationDisplay: tempARR })
  }

  deletequalification = (item, index) => {
    if (this.state.qualificationDisplay.length > 1) {
      let arrqualification = this.state.qualificationDisplay
      arrqualification.splice(index, 1)
      myLog("delete qualify", arrqualification)
      this.setState({ qualificationDisplay: arrqualification })
      if (item.id !== "") {
        let body = {
          id: item.id,
        }
        this.props.deleteTeacherQualification(body, () => {
          this.getProfile()
        })
      }
    }
  }

  deleteSkill = (item, index) => {
    let arrqualification = this.state.skillDisplay
    arrqualification.splice(index, 1)
    this.setState({ skillDisplay: arrqualification })
    if (item.id !== "") {
      let body = {
        id: item.id,
      }
      this.props.deleteTeacherSkill(body, () => {
        this.getProfile()
      })
    }
  }

  profileErrorAbstract = error => {
    this.props.showNotification(DANGER_TOAST, VALIDATION_MSG.OOPS, error)
    this.setState({ isShowLoading: false, isProfileEditing: false })
  }

  // Update for teacher profile
  onUpdate = () => {
    if (this.state.isEdQualifyEditing === false) {
      this.setState({ isEdQualifyEditing: true })
    } else {
      this.setState({ isShowLoading: true, isProfileEditing: true })
    }

    let qualifications = []
    for (let i = 0; i < this.state.qualificationDisplay.length; i++) {
      if (this.state.qualificationDisplay[i].id !== "")
        qualifications.push(this.state.qualificationDisplay[i].qualification)
    }

    let skills = []
    if (this.state.skillName !== "") skills.push(this.state.skillName)

    let body = {
      id: this.state.id,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      teacherGender: this.state.gender,
      teacherContactNumber: this.state.contactNumber,
      teacherImage: this.state.teacherImage,
      emailId: this.state.emailId,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      zipCode: this.state.zipCode,
      qualifications: qualifications,
      dateOfJoining: this.state.dateOfJoining,
      experience: this.state.experience,
      skills,
    }

    this.props.updateTeacherProfile(body, (success, response) => {
      if (success) {
        this.setState({
          isShowLoading: false,
          skillName: "",
          isEdQualifyLoading: false,
        })
        this.getProfile()
      } else {
        this.setState({ isShowLoading: false, isEdQualifyLoading: false })
      }
    })
  }

  // Handles Change of field items in form
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  // Handles Click Message in icon bar
  onClickMessageIcon = () => {
    this.setState({ isMessageModalOpen: !this.state.isMessageModalOpen })
  }

  // Handles Message Submit
  onSubmitMessage = () => {
    this.setState({ isMessageModalOpen: false })
  }

  onClickCreateNewAndModalIcon = () => {
    this.setState({
      isCreateResponsibilitiesModalOpen: false,
      isCreateExperienceModalOpen: false,
      isCreateEducationModalOpen: false,
      isCompleteProfile: false,
    })
  }

  onChangeContactNumber = e => {
    if (e.target.value < 9999999999) {
      this.setState({
        contactNumber: e.target.value,
      })
    }
  }

  customStyle = condition => ({
    backgroundColor: condition ? "#094353" : "#0eb292",
    border: "none",
    width: "5rem",
  })

  render() {
    return (
      <AppWrapper
        openMessageModal={() => this.onClickMessageIcon()}
        teacherDashboard
        showTeacherThree
        showTeacherLogo
        screenName="Profile"
      >
        <MessageModal
          isModalOpen={this.state.isMessageModalOpen}
          closeModal={() => this.onClickMessageIcon()}
          onSubmitMessage={() => this.onSubmitMessage()}
        />

        {/* <div className="px-4 py-3">
           <CreateExperienceModal
             isModalOpen={this.state.isCreateExperienceModalOpen}
             closeModal={() => this.onClickCreateNewAndModalIcon()}
             onSubmitMessage={() => this.onSubmitMessage()}
           />
           <CreateEducationModal
             isModalOpen={this.state.isCreateEducationModalOpen}
             closeModal={() => this.onClickCreateNewAndModalIcon()}
             onSubmitMessage={() => this.onSubmitMessage()}
           />
         </div> */}

        <div className="row">
          <div className="col-sm-3">
            <div className="card p-3 br-10  text-white shadow profile1">
              <img
                className="img1"
                src={this.state.gender === "M" ? malePic : femalePic}
                alt="pic"
                // onChange={this.handleChange}
              />
              <br />
              &emsp;
              <h2>{this.state.firstName}</h2>
              <h3 style={{ fontSize: "1vw" }}>
                CLASS TEACHER &ensp;
                {this.state.classTeacherFor
                  ? this.state.classTeacherFor
                  : "Teacher"}
              </h3>
            </div>

            <div className="card p-3 br-10 mt-2 text-white shadow profile1">
              <h5 className="myskills mt-3">
                <b>My Classes</b>
              </h5>
              <h4 className="count-cards-one pt-1 text-white numberbox">
                {this.state.assignedClassAndSubject.length}
              </h4>
              {this.state.assignedClassAndSubject.map(currClass => (
                <div
                  key={currClass.assignedId}
                  className="btn-group mt-4"
                  role="group"
                >
                  <button type="button" className="btn button4 pt-2 text-white">
                    {currClass.assignedClass.value}
                  </button>
                  <button
                    type="button"
                    className="btn button4 pt-2 text-white btn-sm profile-subject"
                  >
                    {currClass.assignedSubject.value}
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div className="col-sm-9">
            <div className="card bg-white p-3 shadow">
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="myskills text-dark">
                    <b>Personal Details</b>
                  </h5>
                </div>

                <div>
                  <button
                    type="button"
                    style={this.customStyle(this.state.isProfileEditing)}
                    className="btn btn-sm text-light rounded-pill"
                    onClick={() =>
                      this.state.isProfileEditing
                        ? this.setState({ isProfileEditing: false })
                        : this.onUpdate()
                    }
                  >
                    {this.state.isShowLoading ? (
                      <HashLoader
                        css={{
                          paddingRight: "55px",
                        }}
                        sizeUnit="px"
                        size={18}
                        color="white"
                        loading={this.state.isShowLoading}
                      />
                    ) : this.state.isProfileEditing ? (
                      "Edit"
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>

              <div className="row personaldetails">
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label htmlFor="firstname">FIRST NAME :</label>
                  <input
                    disabled={this.state.isProfileEditing}
                    type="text"
                    className="form-control"
                    id="firstname"
                    name="firstName"
                    placeholder="First Name"
                    value={this.state.firstName}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label htmlFor="lastname">LAST NAME :</label>
                  <input
                    disabled={this.state.isProfileEditing}
                    type="text"
                    className="form-control"
                    id="lastname"
                    name="lastName"
                    placeholder="Last Name"
                    value={this.state.lastName}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label htmlFor="contact">CONTACT NUMBER :</label>
                  <input
                    disabled={this.state.isProfileEditing}
                    type="number"
                    className="form-control"
                    id="contact"
                    name="contactNumber"
                    placeholder="Contact Number"
                    value={this.state.contactNumber}
                    onChange={this.onChangeContactNumber}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label htmlFor="email">EMAIL ADDRESS :</label>
                  <input
                    type="email"
                    disabled
                    className="form-control"
                    id="email"
                    name="emailId"
                    placeholder="Email"
                    value={this.state.emailId}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label htmlFor="address">ADDRESS:</label>
                  <input
                    disabled={this.state.isProfileEditing}
                    type="text"
                    className="form-control"
                    id="address"
                    name="address"
                    placeholder="Address"
                    value={this.state.address}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label htmlFor="city">CITY:</label>
                  <input
                    disabled={this.state.isProfileEditing}
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    placeholder="City"
                    value={this.state.city}
                    onChange={e =>
                      this.setState({
                        city: e.target.value.replace(/[^A-Za-z]/gi, ""),
                      })
                    }
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label htmlFor="state">STATE:</label>
                  <input
                    disabled={this.state.isProfileEditing}
                    type="text"
                    className="form-control"
                    id="state"
                    name="state"
                    placeholder="State"
                    value={this.state.state}
                    onChange={e =>
                      this.setState({
                        state: e.target.value.replace(/[^A-Za-z]/gi, ""),
                      })
                    }
                  />
                </div>
                <div className="form-group col-sm-3 col-md-3 col-xl-3 col-lg-3">
                  <label htmlFor="state">COUNTRY:</label>
                  <input
                    disabled={this.state.isProfileEditing}
                    type="text"
                    className="form-control"
                    id="country"
                    name="country"
                    placeholder="Country"
                    value={this.state.country}
                    onChange={e =>
                      this.setState({
                        country: e.target.value.replace(/[^A-Za-z]/gi, ""),
                      })
                    }
                  />
                </div>
                <div className="form-group col-sm-3 col-md-3 col-xl-3 col-lg-3">
                  <label htmlFor="state">ZIP CODE:</label>
                  <input
                    disabled={this.state.isProfileEditing}
                    type="text"
                    className="form-control"
                    id="zipcode"
                    name="zipCode"
                    maxLength="12"
                    placeholder="Zip Code"
                    value={this.state.zipCode}
                    onChange={e =>
                      this.setState({
                        zipCode: e.target.value.replace(/[^0-9\- ]/gi, ""),
                      })
                    }
                  />
                </div>
              </div>
            </div>

            {this.state.isShowAddqualification && (
              <div className="card bg-white shadow br-7 mt-4 p-3">
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between">
                    <div>
                      <label>
                        <h5 className=" text-dark">
                          <b>Educational Qualification</b>
                        </h5>
                      </label>
                    </div>

                    <div>
                      <button
                        type="button"
                        style={this.customStyle(this.state.isEdQualifyEditing)}
                        className="btn btn-sm text-light rounded-pill"
                        onClick={() =>
                          this.state.isEdQualifyEditing
                            ? this.setState({ isEdQualifyEditing: false })
                            : this.onUpdate()
                        }
                      >
                        {this.state.isEdQualifyLoading ? (
                          <HashLoader
                            css={{
                              paddingRight: "55px",
                            }}
                            sizeUnit="px"
                            size={18}
                            color="white"
                            loading={this.state.isEdQualifyLoading}
                          />
                        ) : this.state.isEdQualifyEditing ? (
                          "Edit"
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </div>
                </div>

                {this.state.qualificationDisplay &&
                  this.state.qualificationDisplay.map((item, index) => (
                    <div className="row" key={index}>
                      <div className="form-group d-flex col-sm-5 mt-3">
                        <div>
                          <input
                            disabled={this.state.isEdQualifyEditing}
                            type="text"
                            className="form-control"
                            id="Eduql"
                            onChange={e => {
                              let value = e.target.value
                              value = value.replace(/[^A-Za-z .]/gi, "")
                              let state = this.state.qualificationDisplay
                              state[index].qualification = value
                              this.setState(
                                {
                                  qualificationDisplay: state,
                                },
                                () => myLog("state", state)
                              )
                            }}
                            value={item.qualification}
                          />
                        </div>
                        <div className="ml-2">
                          <i
                            className="fas fa-plus text-success "
                            style={
                              this.state.isEdQualifyEditing
                                ? { display: "none", cursor: "pointer" }
                                : { cursor: "pointer" }
                            }
                            onClick={() => this.addqualification()}
                          ></i>
                          <i
                            className="fas fa-trash-alt text-danger px-2 mx-2"
                            style={
                              this.state.isEdQualifyEditing
                                ? { display: "none", cursor: "pointer" }
                                : { cursor: "pointer" }
                            }
                            onClick={() =>
                              this.deletequalification(item, index)
                            }
                          ></i>
                        </div>
                      </div>
                    </div>
                  ))}

                {/* <div className="d-flex jjustify-content-sm-start">
                 <h5><b>Experience in Years</b></h5>
                 <h5><b>Date of joining</b></h5>
               </div> */}

                <div className="d-flex justify-content-sm-start row">
                  <div className="form-group  haha col-sm-3">
                    <label htmlFor="experience">
                      <h5 className=" text-dark">
                        <b>Experience in Years</b>
                      </h5>
                    </label>
                    <input
                      disabled={this.state.isEdQualifyEditing}
                      type="number"
                      className="form-control"
                      id="experience"
                      value={this.state.experience}
                      onChange={e => {
                        const value = e.target.value
                        if (value.length < 3) {
                          this.setState({ experience: e.target.value })
                        }
                      }}
                    />
                  </div>
                  <div className="form-group col-sm-4">
                    <label htmlFor="dateOfJoining">
                      <h5 className=" text-dark">
                        <b>Date of joining</b>
                      </h5>
                    </label>
                    <input
                      disabled={this.state.isEdQualifyEditing}
                      type="date"
                      className="form-control"
                      id="dateOfJoining"
                      value={this.state.dateOfJoining}
                      onChange={e => {
                        this.setState({ dateOfJoining: e.target.value })
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            <div
              className="card bg-white p-3 shadow br-7 mt-3 p-3"
              style={{ marginBottom: "4rem" }}
            >
              <div className="d-flex justify-content-between">
                <h5 className=" text-dark">
                  <b>SKILLS</b>
                </h5>
                <button
                  type="button"
                  style={this.customStyle(!this.state.isShowAddSkill)}
                  className="btn btn-sm text-light br-10"
                  onClick={() => {
                    this.setState(
                      { isShowAddSkill: !this.state.isShowAddSkill },
                      () => {
                        if (!this.state.isShowAddSkill) {
                          this.onUpdate()
                        }
                      }
                    )
                  }}
                >
                  {this.state.isShowAddSkill ? "Save Skill" : "Add Skill"}
                </button>
              </div>
              {this.state.isShowAddSkill ? (
                <input
                  type="text"
                  className="form-control col-sm-4 mt-2 mb-2"
                  id="Eduql"
                  placeholder="Skill Name"
                  onChange={e => {
                    let value = e.target.value
                    value = value.replace(/[^A-Za-z ]/gi, "")
                    this.setState({
                      skillName: value,
                    })
                  }}
                  value={this.state.skillName}
                />
              ) : null}
              <div className="row px-5 mt-2 d-flex">
                {this.state.skillDisplay.length > 0 ? (
                  this.state.skillDisplay.map((item, index) => {
                    return (
                      <div className="col-sm-3">
                        <div
                          className="d-flex align-items-center skillbgcolor rounded-pill"
                          style={{ height: "4rem" }}
                        >
                          <div className="ml-3">
                            <i
                              className="fas fa-trash-alt text-danger"
                              style={{ cursor: "pointer" }}
                              onClick={() => this.deleteSkill(item, index)}
                            ></i>
                          </div>

                          <div>
                            <p
                              className="text-white ml-3 mr-2"
                              style={{
                                marginTop: "11px",
                                wordBreak: "break-all",
                              }}
                            >
                              {item.skill}
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div className="no-data"> No data found</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </AppWrapper>
    )
  }
}
const mapStateToProps = state => {
  return {}
}
const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getTeacherProfile,
      updateTeacherProfile,
      deleteTeacherQualification,
      deleteTeacherSkill,
      showNotification,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(TeacherProfile)
