/*************************************************
 * RedInk
 * @exports
 * @class index.js
 * @extends Component
 * @author Sasidharan // on 15/02/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import AppWrapper from "./AppWrapper"
import moment from "moment"
import { myLog } from "../../utils/Utility"

class MarkUploadStatusScreen extends Component {
  constructor(props) {
    super(props)
    document.title = "Attendance Report | Redink"
    this.state = { isMessageModalOpen: false, reportDate: "" }
  }

  componentDidMount = () => {
    this.setState({ reportDate: moment(new Date()).format("YYYY-MM-DD") })
  }

  render() {
    return (
      <AppWrapper
        openMessageModal={() => this.onClickMessageIcon()}
        showThree
        showSchoolLogo
        superAdminDashboard
        onChangeSchoolName={item => {
          myLog(item)
        }}
      >
        <div className="d-flex justify-content-between">
          <h5 className="p-2">Attendance Report</h5>
          <select
            className="mr-3 mb-2"
            value={this.state.selectedPlan}
            onChange={e => this.setState({ selectedPlan: e.target.value })}
          >
            <option>SCHOLASTIC</option>
            <option>CO-SCHOLASTIC</option>
          </select>
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <div className="outer-box-hierarchy-mark-upload d-flex justify-content-between">
              <span className="text-white">Term 1</span>
              <span
                className="ml-3 attendance-present selected"
                style={{ borderColor: "white" }}
              ></span>
            </div>
            <div className="ml-3 outer-box-hierarchy-mark-upload d-flex justify-content-between">
              <span className="text-white">Term 2</span>
              <span
                className="ml-3 attendance-absent selected"
                style={{ borderColor: "white" }}
              ></span>
            </div>
          </div>
          <div className="d-flex mt-3">
            <div>
              <span
                className="ml-3 attendance-present"
                style={{ padding: "5px 11px" }}
              ></span>
              <span className="ml-2">Complete</span>
            </div>
            <div>
              <span
                className="ml-3 attendance-absent"
                style={{ padding: "5px 11px" }}
              ></span>
              <span className="ml-2">In progress</span>
            </div>
            <div>
              <span
                className="ml-3 attendance-absent"
                style={{ padding: "5px 11px" }}
              ></span>
              <span className="ml-2">Not Started</span>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-5">
          <h5 className="p-2">FORMATIVE ASSESSMENT 1</h5>
          <select
            className="mr-3 mb-2"
            value={this.state.selectedPlan}
            onChange={e => this.setState({ selectedPlan: e.target.value })}
          >
            <option>EXAM</option>
          </select>
        </div>
        <div className="row mb-4 mt-2">
          {[1, 2, 3, 4, 5, 6].map(item => {
            return (
              <div className="col-sm-4 my-2">
                <div className={`card`}>
                  <div className="d-flex outer-box-hierarchy-mark-upload justify-content-between">
                    <span className="text-white">Class {item}</span>
                    <span
                      className="ml-3 attendance-absent"
                      style={{ padding: "1px 60px", borderRadius: "15px" }}
                    ></span>
                  </div>
                  <div className="mt-3 mb-3 ml-2 mr-2 d-flex justify-content-around">
                    <div className="text-center d-flex flex-column">
                      <div className="mb-2">
                        <span
                          className="attendance-absent"
                          style={{ padding: "5px 11px" }}
                        ></span>
                      </div>
                      <small className="text-secondary">ENG</small>
                    </div>
                    <div className="text-center d-flex flex-column">
                      <div className="mb-2">
                        <span
                          className="attendance-absent"
                          style={{ padding: "5px 11px" }}
                        ></span>
                      </div>
                      <small className="text-secondary">ENG</small>
                    </div>
                    <div className="text-center d-flex flex-column">
                      <div className="mb-2">
                        <span
                          className="attendance-absent"
                          style={{ padding: "5px 11px" }}
                        ></span>
                      </div>
                      <small className="text-secondary">ENG</small>
                    </div>
                    <div className="text-center d-flex flex-column">
                      <div className="mb-2">
                        <span
                          className="attendance-absent"
                          style={{ padding: "5px 11px" }}
                        ></span>
                      </div>
                      <small className="text-secondary">TAM</small>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div className="row mb-4 mt-2">
          {[1, 2, 3, 4, 5, 6].map(item => {
            return (
              <div className="col-sm-2 my-2">
                <div className={`card`}>
                  <div className="outer-box-hierarchy-mark-upload d-flex justify-content-between">
                    <span className="ml-2 text-white">Class {item}</span>
                    <span className="ml-5 attendance-absent selected"></span>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </AppWrapper>
    )
  }
}

export default MarkUploadStatusScreen
