import React, { useState } from "react"

import CreateAcademicYear from "./CreateAcademicYear"
import AppWrapper from "../Wrapper1"

function UpgradeAcademic() {
  const [open, setOpen] = useState(false)

  const updateOpen = () => setOpen(p => !p)

  return (
    <AppWrapper showUpgradeAcadic screenName="Academic Details">
      {!open && (
        <div
          className="d-flex flex-column align-items-center justify-content-center my-5 text-center"
          style={{ height: "70vh" }}
        >
          <p className="mb-3 fs-18 fw-600">No Data found</p>

          <p>
            Current academic session has ended. Please <br />
            create a new academic year
          </p>

          <button
            className="mb-5 px-4 py-2 fw-500 text-white bg-5DD1AD br-8px"
            onClick={updateOpen}
          >
            Create Academic Year
          </button>
        </div>
      )}

      {open && <CreateAcademicYear closeModal={updateOpen} />}
    </AppWrapper>
  )
}

export default UpgradeAcademic
