/*************************************************
 * RedInk
 * @exports
 * @class SuperAdminDetails.js
 * @extends Component
 * @author Alex // on 25/07/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { ACTION_TYPES } from "../types"
import { showNotification } from "../CommonAction"
import {
  SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"

// Get Super Admin Details
export const getSuperAdminDetails = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_SUPERADMIN_DETAILS, null, true)
      myLog("hahaha", response)
      if (response) {
        myLog("====getSuperAdminDetails RESPONSE===::::", response)
        if (callBack) callBack(true, response.tentants)
        dispatch({
          type: ACTION_TYPES.FOUNDER_SUPER_ADMIN_DETAILS,
          payload: response.tentants,
        })
      } else {
        if (callBack) callBack(false)
      }
    } catch (error) {
      if (callBack) callBack(false)
      myLog("====getSuperAdminDetails ERROR===????", error)
    }
  }
}

// Add New Super Admin
export const addSuperAdminDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.post(URL.ADD_SUPERADMIN, body, true)
      if (response.responseCode === 0) {
        myLog("====newSuperAdmin RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        if (callBack) callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        if (callBack) callBack(false)
      }
    } catch (error) {
      myLog("====newSuperAdmin ERROR===????", error)
      dispatch(showNotification(DANGER_TOAST, VALIDATION_MSG.OOPS, "ERROR"))
      if (callBack) callBack(false)
    }
  }
}

// Check Tentant Name Avalilability
export const checkTentantNameAvailability = (tentantName, callBack) => {
  return async dispatch => {
    try {
      let object = { tentantName: tentantName }
      const response = await Client.get(
        URL.GET_CHECK_TENTANT_AVAILABILITY,
        object,
        true
      )
      if (response) {
        myLog(
          "====checkTentantNameAvailability RESPONSE===::::",
          response.alreadyExists
        )
        callBack(true, response.alreadyExists)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====checkTentantNameAvailability ERROR===????", error)
    }
  }
}
