import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { HashLoader } from "react-spinners"

import { questList, getRandomNumbers, shuffleArray } from "../common/utils"
import { getQuestionsAnswers } from "../../../../actions/admin/SubjectDetailsAction"
import { showNotification } from "../../../../actions/CommonAction"
import { DANGER_TOAST } from "../../../../utils/Constants"

import { ReactComponent as Close } from "../../../../assets/images/close.svg"

function QuestType({
  id,
  quest,
  count,
  errorAt,
  updateQuest,
  deleteQuest,
  disabled = false,
}) {
  return (
    <div className="d-flex align-items-center mb-4">
      <select
        value={quest}
        onChange={e => updateQuest(id, "quest", e.target.value)}
        className="modern-arrow manual-quest-select form-control br-5px"
      >
        <option value="" disabled>
          Select question type
        </option>
        {questList.map(q => (
          <option value={q} key={q}>
            {q}
          </option>
        ))}
      </select>

      <button
        className="manual-plus-minas ml-auto fs-25"
        onClick={() => {
          if (Number(count)) {
            updateQuest(id, "count", Number(count) - 1)
          }
        }}
      >
        -
      </button>

      <input
        type="number"
        value={count}
        min={0}
        onChange={e => {
          if (e.target.value !== "-") {
            updateQuest(
              id,
              "count",
              e.target.value ? Number(e.target.value.replace("-", "")) : 0
            )
          }
        }}
        style={quest && quest === errorAt ? { borderColor: "red" } : {}}
        className="manual-quest-counter form-control mx-30px text-center br-5px"
      />

      <button
        className="manual-plus-minas mr-5 fs-25"
        onClick={() => updateQuest(id, "count", Number(count) + 1)}
      >
        +
      </button>

      <button className="mr-auto" onClick={deleteQuest} disabled={disabled}>
        <Close width={28} height={28} style={{ fill: "#C9C7C7" }} />
      </button>
    </div>
  )
}

function Step3({
  aiBtn,
  selectedBoard,
  selectedClass,
  selectedQuest,
  selectedSubject,
  selectedChapter,
  setSelectedQuest,
  setStep,
  setData,
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [errorAt, setErrorAt] = useState("")
  const dispatch = useDispatch()

  const addQuest = () => {
    setSelectedQuest(p => [
      ...p,
      {
        id: p[p.length - 1].id + 1,
        quest: "",
        count: 10,
      },
    ])
  }

  const updateQuest = (id, key, value) => {
    setErrorAt("")
    setSelectedQuest(prev =>
      prev.map(pr => {
        if (pr.id === id) {
          return {
            ...pr,
            [key]: value,
          }
        }

        return pr
      })
    )
  }

  const deleteQuest = id => {
    setSelectedQuest(prev => prev.filter(pr => pr.id !== id))
  }

  const goToPreview = () => {
    if (!selectedQuest.every(q => q.quest && q.count > 0)) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          "",
          "Questions type or count not mentioned"
        )
      )
    } else {
      const type = {
        MCQ: "MULTIPLE_CHOICE",
        "Fill in the blanks": "FILL_IN_THE_BLANKS",
        "Short answer": "SHORT_ANSWER",
        "Long answer": "LONG_ANSWER",
        "Match the following": "MATCHING",
        "True or False": "TRUE_OR_FALSE",
      }

      const param = {
        selectedBoard,
        selectedClass,
        selectedSubject: selectedSubject.map(s => s.masterSubjectId).join(","),
        selectedChapter: Object.keys(selectedChapter).join(","),
        selectedTopic: Object.values(selectedChapter)
          .flat()
          .join(","),
      }
      setIsLoading(true)
      getQuestionsAnswers(param, (success, res) => {
        if (success) {
          const questions = res.data.reduce((prev, curr) => {
            let question = JSON.parse(curr?.question) || []
            if (Array.isArray(question)) {
              question = question?.filter(Boolean)
            }
            if (curr.type === "MULTIPLE_CHOICE") {
              question = shuffleArray(
                Array(question.length)
                  .fill(0)
                  .map((_, i) => i)
              ).map(i => question[i])
            }
            let shuffled =
              curr.type === "MATCHING"
                ? shuffleArray(
                    Array(question.length)
                      .fill(0)
                      .map((_, i) => i)
                  )
                : []
            let opts = curr.type === "MATCHING" ? JSON.parse(curr?.answer) : []
            let obj = {
              ...curr,
              question,
              match:
                curr.type === "MATCHING"
                  ? shuffled.map(a => opts?.[a] || "")
                  : [],
            }

            if (prev[curr.type]) prev[curr.type].push(obj)
            else prev[curr.type] = [obj]

            return prev
          }, {})

          const final = selectedQuest.map(a => {
            const quests = questions[type[a.quest]] || []
            const needRandomess = a.count < quests?.length
            const randomNums = needRandomess
              ? getRandomNumbers(a.count, quests.length)
              : []
            let included = !needRandomess ? quests : []
            let excluded = []

            if (needRandomess) {
              quests.forEach((q, i) => {
                if (randomNums.includes(i + 1)) {
                  included.push(q)
                } else {
                  excluded.push(q)
                }
              })
            }
            return {
              ...a,
              included,
              excluded,
              displayName: a.quest,
              mark: included?.[0]?.mark || 1,
            }
          })
          const hasError = final.find(f => f.count > f.included.length)

          setIsLoading(false)
          if (hasError) {
            dispatch(
              showNotification(
                DANGER_TOAST,
                "",
                `No. of questions in ${hasError.quest} is less than actual count`
              )
            )
            setErrorAt(hasError.quest)
          } else {
            setStep(4)
            setData(final)
          }
        }
      })
    }
  }

  return (
    <>
      <div className="manual-quest-wrapper px-5 pt-2 pb-5 bg-F0F0F099 br-8px">
        <div className="mb-4 mt-2 fs-18 fw-600 clr-201F1F">
          Select Question type & Counts
        </div>

        <div className="manual-quest-list bg-white br-5px">
          <div className="d-flex align-items-center mb-2">
            <p className="mb-0 fs-14 clr-201F1F">Select Question Type</p>

            <span style={{ width: "130px" }}></span>

            <p className="mb-0 mx-auto fs-14 clr-201F1F text-center">Count</p>
          </div>

          {selectedQuest.map(s => (
            <QuestType
              {...s}
              key={s.id}
              errorAt={errorAt}
              updateQuest={updateQuest}
              deleteQuest={() => deleteQuest(s.id)}
              disabled={selectedQuest.length === 1}
            />
          ))}

          <button className="mb-5 mt-2 clr-50AE93" onClick={addQuest}>
            +Add More Question Types
          </button>
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-center gap-18px mt-4">
        <button
          className="px-5 py-2 fs-16 fw-500 bg-F7F7F7 clr-8A8A8A br-8px"
          onClick={() => setStep(2)}
        >
          Back
        </button>

        <button
          className="dis-grayed px-5 py-2 fs-16 fw-500 bg-50AE93 text-white br-8px"
          onClick={goToPreview}
        >
          {isLoading ? (
            <HashLoader size={16} color="#fff" sizeUnit="px" loading />
          ) : (
            "Preview Question paper"
          )}
        </button>

        {/* {aiBtn} */}
      </div>
    </>
  )
}

export default Step3
