import React, { useEffect, useState } from "react"
import { HashLoader } from "react-spinners"
import { Modal } from "reactstrap"
import pdfMake from "pdfmake"

import { getQpList } from "../../../../actions/admin/QuestionGeneration"
import { formatTime, change, shuffleArray, alphas } from "../common/utils"

import { ReactComponent as Close } from "../../../../assets/images/close.svg"
import { ChooseType, Header, Mathchtype, ParaType } from "./SaveQP/Types"

const fonts = {
  Roboto: {
    normal: "https://redink-assets.s3.ap-south-1.amazonaws.com/Cambria.ttf",
    bold: "https://redink-assets.s3.ap-south-1.amazonaws.com/cambriab-bold.ttf",
    italics: "https://redink-assets.s3.ap-south-1.amazonaws.com/Cambria.ttf",
    bolditalics:
      "https://redink-assets.s3.ap-south-1.amazonaws.com/Cambria.ttf",
  },
}

function Preview({ id, closeModal }) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})

  useEffect(() => {
    getQpList(`/${id}`, (success, res) => {
      if (success) {
        res.questionPaperSections.sort((a, b) => (a.order > b.order ? 1 : -1))
        setData(res)
        setLoading(false)
      }
    })
  }, [id])

  function downloadGPPdf() {
    const instructions = `General Instructions:\n${data?.instruction}`
      .split("\n")
      .map((a, i) => ({
        text: `${a}\n`,
        bold: i === 0,
        margin: [0, 0, 0, 3],
      }))

    const docDefinition = {
      content: [
        {
          text: data?.schoolName,
          alignment: "center",
          margin: [0, 0, 0, 1],
          fontSize: 14,
        },
        {
          text: `${data?.city || ""} - ${data?.zipCode || ""}`,
          alignment: "center",
          margin: [0, 0, 0, 8],
          fontSize: 13,
        },
        {
          text: data?.title,
          alignment: "center",
          style: "header",
          margin: [0, 0, 0, 5],
        },
        {
          text: `Class ${data?.className}`,
          alignment: "center",
          style: "header",
          margin: [0, 0, 0, 10],
        },
        {
          style: "header",
          margin: [0, 0, 0, 10],
          columns: [
            {
              text: `Time: ${formatTime(Number(data?.durationInMinutes))}`,
              width: "*",
            },
            {
              text: `${data?.subjectNames}`,
              width: "auto",
            },
            {
              text: `Total Marks: ${data?.totalMarks}`,
              width: "*",
              alignment: "right",
            },
          ],
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 0,
              x2: 555,
              y2: 0,
              lineWidth: 1,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        ...instructions,
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 0,
              x2: 555,
              y2: 0,
              lineWidth: 1,
            },
          ],
          margin: [0, 8, 0, 20],
        },
      ],
      footer: function(currentPage, pageCount) {
        return {
          text: `Page ${currentPage} of ${pageCount}`,
          fontSize: 10,
          alignment: "right",
          margin: [0, 0, 20, 0],
        }
      },
      pageSize: "A4",
      pageMargins: 20,
      defaultStyle: {
        fontSize: 12,
      },
      styles: {
        header: {
          fontSize: 13,
          bold: true,
        },
      },
    }

    data.questionPaperSections.forEach((q, i) => {
      docDefinition.content.push({
        style: "header",
        margin: [0, 0, 0, 6],
        columns: [
          {
            width: "*",
            text: `SECTION ${i + 1}: ${q?.sectionName}`,
          },
          {
            width: "auto",
            fontSize: 12,
            text: `${q.questionsCount} x ${
              q.marksPerQuestion
            } = ${q.questionsCount * q.marksPerQuestion}`,
          },
        ],
      })

      let prevNum =
        i === 0
          ? 0
          : data.questionPaperSections.reduce(
              (prev, curr, j) => (j < i ? prev + curr.questions.length : prev),
              0
            )

      if (q.questionType === "MULTIPLE_CHOICE") {
        q.questions.forEach(
          ({ quizDefaultQuestion: { text, question } }, i) => {
            let formated = JSON.parse(question)
            docDefinition.content.push({
              text: `${prevNum + i + 1}. ${text}`,
              margin: [0, 0, 0, 6],
            })

            docDefinition.content.push({
              columns: [change(formated[0], 0), change(formated[1], 1), "", ""],
              margin: [16, 0, 0, 4],
            })
            docDefinition.content.push({
              columns: [change(formated[2], 2), change(formated[3], 3), "", ""],
              margin: [16, 0, 0, 10],
            })
          }
        )
      } else if (q.questionType === "MATCHING") {
        q.questions.forEach(
          ({ quizDefaultQuestion: { question, answer, text } }) => {
            let formated = JSON.parse(question) || []
            if (Array.isArray(formated)) {
              formated = formated.filter(Boolean)
            }
            let opts = JSON.parse(answer) || []
            let shuffled = shuffleArray(
              Array(formated.length)
                .fill(0)
                .map((_, i) => i)
            ).map(a => opts?.[a] || "")

            docDefinition.content.push({
              text: `${prevNum + i + 1}. ${text}`,
              margin: [0, 0, 0, 8],
            })

            formated.forEach((q, i) => {
              docDefinition.content.push({
                margin: [12, 0, 0, 8],
                columns: [
                  { text: `${alphas[i]}. ${q.trim()}`, width: 110 },
                  { text: "-", width: 30 },
                  { text: shuffled[i] },
                ],
              })
            })
          }
        )
      } else {
        q.questions.forEach(({ quizDefaultQuestion: { text } }, i) => {
          docDefinition.content.push({
            text: `${prevNum + i + 1}. ${text}`,
            margin: [0, 0, 0, 8],
          })
        })
      }

      docDefinition.content.push({
        text: "",
        margin: [0, 0, 0, 10],
      })
    })

    pdfMake.createPdf(docDefinition, null, fonts).getBase64(async data1 => {
      const blob = await fetch(
        `data:application/pdf;base64,${data1}`
      ).then(res => res.blob())
      const browserUrl = window.webkitURL || window.URL
      const pdfURL = browserUrl.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = pdfURL
      a.download = `${data?.title} ${data?.subjectNames} ${data?.className}.pdf`
      a.click()
      browserUrl.revokeObjectURL(pdfURL)
    })
  }

  return (
    <Modal
      isOpen
      backdropClassName="modal-backdrop-show"
      modalClassName="modal-dialog-shadow-none report-card-modal-full-wrapper"
      contentClassName="report-modal-wrapper py-4 px-5 bg-F6F7F8"
      centered
      size="lg"
    >
      {loading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "400px" }}
        >
          <HashLoader size={30} color="#084353" sizeUnit="px" loading />
        </div>
      ) : (
        <>
          <div className="d-flex align-items-center mb-4">
            <p className="mb-0 fs-18 fw-700">{data?.title}</p>

            <Close
              width={28}
              height={28}
              onClick={closeModal}
              className="ml-auto cursor-pointer"
            />
          </div>

          <div className="preview-gp bg-EAEAEA p-4">
            <div className="actual-gp-preview p-5 m-2 bg-white br-8px">
              <Header
                schoolName={data?.schoolName}
                address={`${data?.city} - ${data?.zipCode}`}
                exam={data?.title}
                standard={data?.className}
                time={data?.durationInMinutes}
                subject={data?.subjectNames}
                mark={data?.totalMarks}
                instruction={data?.instructions}
              />

              {data?.questionPaperSections.map((q, i) => {
                let prevNum =
                  i === 0
                    ? 0
                    : data.questionPaperSections.reduce(
                        (prev, curr, j) =>
                          j < i ? prev + curr.questions.length : prev,
                        0
                      )
                if (q.questionType === "MULTIPLE_CHOICE") {
                  return (
                    <ChooseType
                      key={i}
                      sectioNo={i + 1}
                      count={q.questionsCount}
                      mark={q.marksPerQuestion}
                      questions={q.questions.map(que => ({
                        text: que?.quizDefaultQuestion?.text,
                        question: JSON.parse(
                          que?.quizDefaultQuestion?.question
                        ),
                      }))}
                      sectionName={q.sectionName}
                      prevNum={prevNum}
                    />
                  )
                }
                if (q.questionType === "MATCHING") {
                  return (
                    <Mathchtype
                      key={i}
                      sectioNo={i + 1}
                      count={q.questionsCount}
                      mark={q.marksPerQuestion}
                      questions={q.questions.map(que => {
                        let question = JSON.parse(
                          que?.quizDefaultQuestion?.question
                        )
                        let opts = JSON.parse(que?.quizDefaultQuestion?.answer)
                        if (Array.isArray(question)) {
                          question = question?.filter(Boolean)
                        }
                        let shuffled = shuffleArray(
                          Array(question.length)
                            .fill(0)
                            .map((_, i) => i)
                        )
                        let match = shuffled.map(a => opts?.[a] || "")
                        return { question, match }
                      })}
                      sectionName={q.sectionName}
                      prevNum={prevNum}
                    />
                  )
                }
                return (
                  <ParaType
                    key={i}
                    sectioNo={i + 1}
                    count={q.questionsCount}
                    mark={q.marksPerQuestion}
                    questions={q.questions.map(que => ({
                      text: que?.quizDefaultQuestion?.text,
                    }))}
                    sectionName={q.sectionName}
                    prevNum={prevNum}
                  />
                )
              })}
            </div>
          </div>

          <div className="text-center mt-4">
            <button
              className="px-5 py-2 fs-16 fw-500 bg-50AE93 text-white br-8px"
              onClick={downloadGPPdf}
            >
              Download
            </button>
          </div>
        </>
      )}
    </Modal>
  )
}

export default Preview
