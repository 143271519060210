import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { ACTION_TYPES } from "../types"

export const getSubjectsList = (body, callback) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.TEACHER_GET_SUBJECT_LIST,
        {},
        true,
        true
      )
      if (response) {
        myLog("====getSubjectsList RESPONSE===::::", response)
        const { data, higherSecondary } = response
        callback(true, {
          CORE: data.filter(item => item.subjectType === "CORE"),
          OTHER: data.filter(item => item.subjectType === "OTHER"),
          LANGUAGE: data.filter(item => item.subjectType === "LANGUAGE"),
          higherSecondary,
        })
      } else {
        callback(false)
      }
    } catch (error) {
      callback(false)
      myLog("====getSubjectsList ERROR===????", error)
    }
  }
}

export const getSubjectGroupsList = (body, callback) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.TEACHER_GET_SUBJECT_GROUPS,
        {},
        true,
        true
      )
      if (response) {
        myLog("====getSubjectGroupsList RESPONSE===::::", response)
        dispatch({
          type: ACTION_TYPES.TEACHER_GET_SUBJECT_GROUPS,
          payload: response.data,
        })
        callback(true, response.data)
      } else {
        callback(false)
      }
    } catch (error) {
      callback(false)
      myLog("====getSubjectGroupsList ERROR===????", error)
    }
  }
}
