import React, { useState } from "react"
import { HashLoader } from "react-spinners"

import edit from "../../../../../assets/images/edit.png"

function Step1({
  min,
  title,
  instruction,
  isLoading,
  setTitle,
  setMin,
  onSave,
  setInstruction,
}) {
  const [canEdit, setCanEdit] = useState(false)

  const adjustTextareaHeight = () => {
    const textarea = document.getElementById("instruction-qp-textarea")
    textarea.style.height = "auto"
    textarea.style.height = textarea.scrollHeight + 6 + "px"
  }

  return (
    <>
      <div className="add-or-edit-qp-input-wapper mb-4">
        <label htmlFor="" className="fs-15 fw-500 clr-606060">
          Title
        </label>
        <input
          type="text"
          className="w-100 br-5px clr-201F1F"
          value={title}
          onChange={e => setTitle(e.target.value)}
          placeholder="Eg. Half yearly examination"
        />
      </div>

      <div className="mb-4">
        <p className="mb-1 fs-15 fw-500 clr-606060">
          Instructions
          <button
            onClick={() => {
              setCanEdit(p => !p)
              adjustTextareaHeight()
            }}
          >
            <img src={edit} alt="" />
          </button>
        </p>

        <textarea
          id="instruction-qp-textarea"
          className="w-100 instruction-qp-textarea br-5px"
          value={instruction}
          onChange={e => {
            setInstruction(e.target.value)
            adjustTextareaHeight()
          }}
          disabled={!canEdit}
        ></textarea>
      </div>

      <div className="add-or-edit-qp-input-wapper">
        <label htmlFor="" className="fs-15 fw-500 clr-606060">
          Duration
        </label>
        <input
          type="text"
          className="w-100px mr-2 br-5px clr-201F1F"
          value={min}
          onChange={e => setMin(e.target.value)}
        />
        <span className="fs-15 clr-606060">Mins</span>
      </div>

      <div className="d-flex align-items-center justify-content-center gap-18px mt-4">
        <button className="px-5 py-2 fs-16 fw-500 bg-E1E1E1 clr-666 br-8px">
          Cancel
        </button>

        <button
          className="px-5 py-2 fs-16 fw-500 bg-50AE93 text-white br-8px"
          onClick={onSave}
          disabled={isLoading}
        >
          {isLoading ? (
            <HashLoader size={16} color="#fff" sizeUnit="px" loading />
          ) : (
            "Save"
          )}
        </button>
      </div>
    </>
  )
}

export default Step1
