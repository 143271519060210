/*************************************************
 * RedInk
 * @exports
 * @class subjectDetailsAction.js
 * @extends Component
 * @author vijayakumar // on 05/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { ACTION_TYPES } from "../types"

export const saveSubjectDetailsInStore = subjectDetails => {
  return {
    type: ACTION_TYPES.SUBJECT_LIST,
    payload: subjectDetails,
  }
}

export const getSubjectDetails = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_SUBJECT_DETAILS, object, true)
      if (response) {
        myLog("====getSubjectDetails RESPONSE===::::", response)
        dispatch(saveSubjectDetailsInStore(response ? response.subjects : []))
        callBack(true, response.subjects || [])
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getSubjectDetails ERROR===????", error)
    }
  }
}

export const saveSubjectDetails = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      const response = await Client.post(URL.SAVE_SUBJECT_DETAILS, body, true)
      if (response.responseCode === 0) {
        myLog("====saveSubjectDetails RESPONSE===::::", response)
        dispatch(getSubjectDetails(() => {}))
        callBack(true)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====saveSubjectDetails ERROR===????", error)
    }
  }
}

export const updateSubjectDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.post(URL.UPDATE_SUBJECT_DETAILS, body, true)
      if (response.responseCode === 0) {
        myLog("====updateSubjectDetails RESPONSE===::::", response)
        dispatch(getSubjectDetails(() => {}))
        callBack(true)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====updateSubjectDetails ERROR===????", error)
    }
  }
}

export const deleteSubjectDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.DELETE_SUBJECT_DETAILS,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteSubjectDetails RESPONSE===::::", response)
        dispatch(getSubjectDetails(() => {}))
        callBack(true)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====deleteSubjectDetails ERROR===????", error)
    }
  }
}
