/*************************************************
 * RedInk
 * @exports
 * @class subjectDetailsAction.js
 * @extends Component
 * @author vijayakumar // on 05/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { ACTION_TYPES } from "../types"
import { showNotification } from "../CommonAction"
import {
  SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"
import _ from "lodash"

export const saveSubjectDetailsInStore = subjectDetails => {
  return {
    type: ACTION_TYPES.SUBJECT_LIST,
    payload: subjectDetails,
  }
}

export const getSubjectDetails = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_SUBJECT_DETAILS, object, true)
      if (response) {
        myLog("====getSubjectDetails RESPONSE===::::", response)
        let object = _.groupBy(response.subjects, "subjectType")
        myLog("====getSubjectDetails object===::::", object)
        dispatch(saveSubjectDetailsInStore(response.subjects))
        callBack(true, object)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getSubjectDetails ERROR===????", error)
    }
  }
}

export const getSubjectDetailsToPR = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_SUBJECT_DETAILS, object, true)
      if (response) {
        myLog("====getSubjectDetailsToPR RESPONSE===::::", response)
        dispatch(saveSubjectDetailsInStore(response.subjects))
        callBack(true, response.subjects)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getSubjectDetailsToPR ERROR===????", error)
    }
  }
}

export const saveSubjectDetails = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      const response = await Client.post(URL.SAVE_SUBJECT_DETAILS, body, true)
      if (response.responseCode === 0) {
        myLog("====saveSubjectDetails RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveSubjectDetails ERROR===????", error)
    }
  }
}

export const updateSubjectDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.post(URL.UPDATE_SUBJECT_DETAILS, body, true)
      if (response.responseCode === 0) {
        myLog("====updateSubjectDetails RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====updateSubjectDetails ERROR===????", error)
    }
  }
}

export const deleteSubjectDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.DELETE_SUBJECT_DETAILS,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteSubjectDetails RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteSubjectDetails ERROR===????", error)
    }
  }
}

export const newSubjectGroups = (query, callback) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        `${URL.ADMIN_SUBJECT_GROUPS}/new`,
        query,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====newSubjectGroups RESPONSE===::::", response)
        callback(true, response)
      } else {
        callback(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      myLog("====newSubjectGroups ERROR===????", error)
      callback(false)
    }
  }
}

export const getSubjectGroups = (body, callback) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.ADMIN_SUBJECT_GROUPS,
        {},
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====getSubjectGroups RESPONSE===::::", response)
        dispatch({
          type: ACTION_TYPES.UPDATE_SUBJECT_GROUP,
          payload: response.data,
        })
        callback(true, response.data)
      } else {
        callback(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      myLog("====getSubjectGroups ERROR===????", error)
      callback(false)
    }
  }
}

export const saveSubjectGroup = (body, callback) => {
  return async dispatch => {
    try {
      if (body.id) {
        const saveResponse = await Client.put(
          URL.ADMIN_SUBJECT_GROUPS + "/" + body.id,
          { ...body, id: undefined },
          true,
          true
        )
        if (saveResponse.responseCode === 0) {
          dispatch(
            showNotification(
              SUCCESS_TOAST,
              VALIDATION_MSG.SUCCESS,
              saveResponse.responseMessage
            )
          )
          const response = await Client.get(
            URL.ADMIN_SUBJECT_GROUPS,
            {},
            true,
            true
          )
          dispatch({
            type: ACTION_TYPES.UPDATE_SUBJECT_GROUP,
            payload: response.data,
          })
          callback(true, response.data)
        } else {
          dispatch(
            showNotification(
              DANGER_TOAST,
              VALIDATION_MSG.OOPS,
              saveResponse.responseMessage
            )
          )
        }
      } else {
        const saveResponse = await Client.post(
          URL.ADMIN_SUBJECT_GROUPS,
          body,
          true,
          true
        )
        if (saveResponse.responseCode === 0) {
          const response = await Client.get(
            URL.ADMIN_SUBJECT_GROUPS,
            {},
            true,
            true
          )
          dispatch({
            type: ACTION_TYPES.UPDATE_SUBJECT_GROUP,
            payload: response.data,
          })
          callback(true, response.data)
        } else {
          dispatch(
            showNotification(
              DANGER_TOAST,
              VALIDATION_MSG.OOPS,
              saveResponse.responseMessage
            )
          )
        }
      }
      callback(false)
    } catch (error) {
      myLog("====saveSubjectGroup ERROR===????", error)
      callback(false)
    }
  }
}

export const deleteSubjectGroup = (body, callback) => {
  return async dispatch => {
    try {
      const deleteResponse = await Client.delete(
        URL.ADMIN_SUBJECT_GROUPS + "/" + body.id,
        {},
        true,
        true
      )
      if (deleteResponse.responseCode === 0) {
        const response = await Client.get(
          URL.ADMIN_SUBJECT_GROUPS,
          {},
          true,
          true
        )
        dispatch({
          type: ACTION_TYPES.UPDATE_SUBJECT_GROUP,
          payload: response.data,
        })
        callback(true)
      } else {
        callback(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      myLog("====deleteSubjectGroup ERROR===????", error)
      callback(false)
    }
  }
}

export const deleteSubjectGroupSubject = (body, callback) => {
  return async dispatch => {
    try {
      const deleteResponse = await Client.delete(
        URL.ADMIN_SUBJECT_GROUP_SUBJECT + "/" + body.id,
        {},
        true,
        true
      )
      if (deleteResponse.responseCode === 0) {
        const response = await Client.get(
          URL.ADMIN_SUBJECT_GROUPS,
          {},
          true,
          true
        )
        dispatch({
          type: ACTION_TYPES.UPDATE_SUBJECT_GROUP,
          payload: response.data,
        })
        callback(true)
      } else {
        callback(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      myLog("====deleteSubjectGroupSubject ERROR===????", error)
      callback(false)
    }
  }
}

export const deleteSubjectGroupClass = (body, callback) => {
  return async dispatch => {
    try {
      const deleteResponse = await Client.delete(
        URL.ADMIN_SUBJECT_GROUP_SECTION + "/" + body.id,
        {},
        true,
        true
      )
      if (deleteResponse.responseCode === 0) {
        const response = await Client.get(
          URL.ADMIN_SUBJECT_GROUPS,
          {},
          true,
          true
        )
        dispatch({
          type: ACTION_TYPES.UPDATE_SUBJECT_GROUP,
          payload: response.data,
        })
        callback(true)
      } else {
        callback(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      myLog("====deleteSubjectGroupClass ERROR===????", error)
      callback(false)
    }
  }
}

// export const deleteSubjectDetailClass = (body, callback, hideNoti) => {
//   return async dispatch => {
//     try {
//       const deleteResponse = await Client.delete(
//         URL.DELETE_CLASS_FROM_SUBJECT,
//         body,
//         true
//       )
//       if (deleteResponse.responseCode === 0) {
//         if (!hideNoti) {
//           dispatch(
//             showNotification(
//               SUCCESS_TOAST,
//               VALIDATION_MSG.SUCCESS,
//               deleteResponse.responseMessage
//             )
//           )
//         }
//         callback(true)
//       } else {
//         callback(false)
//       }
//     } catch (error) {
//       myLog("====deleteSubjectGroupClass ERROR===????", error)
//       callback(false)
//     }
//   }
// }

// Get Master Subjects
export const getSubjectMasterList = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_MASTER_SUBJECTS_ADMIN,
        null,
        true,
        true
      )
      if (response) {
        myLog("====getSubjectMasterList RESPONSE===::::", response)
        if (callBack) callBack(true, response)
        dispatch({
          type: ACTION_TYPES.MASTER_SUBJECTS,
          payload: response,
        })
      } else {
        if (callBack) callBack(false)
      }
    } catch (error) {
      if (callBack) callBack(false)
      myLog("====getSubjectMasterList ERROR===????", error)
    }
  }
}

export const createMasterSubject = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.post(
        URL.ADMIN_CREATE_MASTER_SUBJECT,
        object,
        true,
        true
      )
      if (response) {
        myLog("====createMasterSubject RESPONSE===::::", response)
        dispatch(getSubjectMasterList(callBack))
      }
    } catch (error) {
      callBack(false)
      myLog("====createMasterSubject ERROR===????", error)
    }
  }
}

export const getChapterTopics = async (object, callBack) => {
  try {
    const response = await Client.get(
      URL.ADMIN_GET_CHAPTERS_TOPICS,
      object,
      true,
      true
    )
    if (response) {
      myLog("====getChapterTopics RESPONSE===::::", response)
      if (callBack) callBack(true, response)
    } else {
      if (callBack) callBack(false)
    }
  } catch (error) {
    if (callBack) callBack(false)
    myLog("====getSubjectMasterList ERROR===????", error)
  }
}

export const getQuestionsAnswers = async (object, callBack) => {
  try {
    const response = await Client.get(
      URL.ADMIN_GET_QUESTIONS_DATA,
      object,
      true,
      true
    )
    if (response) {
      myLog("====getQuestionsAnswers RESPONSE===::::", response)
      if (callBack) callBack(true, response)
    } else {
      if (callBack) callBack(false)
    }
  } catch (error) {
    if (callBack) callBack(false)
    myLog("====getQuestionsAnswers ERROR===????", error)
  }
}
