/*************************************************
 * RedInk
 * @exports
 * @class Dashboard.js
 * @extends Component
 * @author Sasidharan // on 11/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"

import MessageModal from "../MessageModal"
import AppWrapper from "../Wrapper"
import { myLog } from "../../../utils/Utility"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  getCommunicationDetails,
  saveCommunicationDetails,
  getClassDetails,
  getSubjectDetails,
} from "../../../actions/superAdmin/CommunicationDetailsAction"

class CommunicationDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showSideMenu: true,
      isMessageModalOpen: false,
    }
  }

  componentDidMount() {
    this.props.getCommunicationDetails(() => {})
    this.props.getClassDetails(() => {})
    this.props.getSubjectDetails(() => {})
  }

  /**
   * handles on click message icon in sidebar
   */
  onClickMessageIcon = () => {
    this.setState({ isMessageModalOpen: !this.state.isMessageModalOpen })
  }

  /**
   * handles message submit
   */
  onSubmitMessage = object => {
    myLog("onSubmitMessage", object)
    this.props.saveCommunicationDetails(object, () => {})
    this.onClickMessageIcon()
  }

  render() {
    return (
      <AppWrapper
        openMessageModal={() => this.onClickMessageIcon()}
        showSchoolLogo
        showThree
        superAdminDashboard
        onChangeSchoolName={item => {
          myLog(item)
        }}
      >
        <MessageModal
          isModalOpen={this.state.isMessageModalOpen}
          closeModal={() => this.onClickMessageIcon()}
          onSubmitMessage={object => this.onSubmitMessage(object)}
        />
        <div className="mx-2">
          <div className="row">
            <div className="col-sm-3 pl-0">
              <div className="card shadow">
                <div className="card-header">
                  <button
                    className="btn btn-dark btn-block rounded-pill"
                    type="button"
                    onClick={() => this.setState({ isMessageModalOpen: true })}
                  >
                    <i className="fas fa-edit mr-2" />
                    Compose
                  </button>
                </div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">All</li>
                  <li className="list-group-item">Starred</li>
                </ul>
              </div>
              <div className="card my-3 shadow">
                <div className="card-header">
                  <i className="fas fa-inbox mr-2"></i>
                  Summary
                </div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item justify-content-between d-flex align-items-center">
                    Sent today<span className="badge badge-info">9</span>
                    <span className="sr-only">unread messages</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Sent this month<span className="badge badge-info">234</span>
                    <span className="sr-only">unread messages</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-9 pl-0">
              <div className="card shadow">
                <div className="table-responsive rounded">
                  <p className="mt-2 mx-3 fs-20">Sent Messages</p>
                  <table className="text-nowrap table-lg m-0 table table-hover">
                    <tbody>
                      {this.props.communicationList &&
                        this.props.communicationList.map(item => {
                          return (
                            <tr>
                              <td
                                className="text-center"
                                style={{ width: "78px" }}
                              >
                                <div className="custom-checkbox custom-control">
                                  <input
                                    type="checkbox"
                                    id="eCheckbox1"
                                    className="custom-control-input"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="eCheckbox1"
                                  >
                                    &nbsp;
                                  </label>
                                </div>
                              </td>
                              <td className="text-left pl-1">
                                {/* <i className="fal fa-star" /> */}
                                <i className="fa fa-star text-warning" />
                              </td>
                              <td>
                                <div className="widget-content p-0">
                                  <div className="widget-content-wrapper">
                                    <div className="widget-content-left">
                                      <div className="widget-heading rounded bg-primary text-white px-3">
                                        {item.role === "0"
                                          ? item.studentName
                                          : item.role === "1"
                                          ? item.className
                                          : item.role === "2"
                                          ? item.teacherName
                                          : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="text-left">
                                {item.subject} {item.content}
                              </td>
                              <td className="text-center">
                                <i className="fa fa-calendar-alt opacity-4 mr-2" />
                                {item.date}
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    subjectList: state.communicationDetailsState.subjectList,
    classesList: state.communicationDetailsState.classList,
    teachersList: state.communicationDetailsState.teachersList,
    studentsList: state.communicationDetailsState.studentsList,
    communicationList: state.communicationDetailsState.communicationList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getCommunicationDetails,
      saveCommunicationDetails,
      getClassDetails,
      getSubjectDetails,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(CommunicationDashboard)
