/*************************************************
 * Redink
 * @exports
 * @file index.js
 * @author Sasidharan // on 19/12/2019
 * @copyright © 2019 Redink. All rights reserved.
 *************************************************/
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { createStore, applyMiddleware, compose } from "redux"
import reduxThunk from "redux-thunk"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import { Toaster } from "react-hot-toast"

import reducers from "./reducers"
import App from "./components/App"
import * as serviceWorker from "./serviceWorker"
import URL from "./config/index"

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  reducers,
  URL.isDevelopment
    ? composeEnhancers(applyMiddleware(reduxThunk))
    : applyMiddleware(reduxThunk)
)

if (URL.isProduction) {
  Sentry.init({
    dsn:
      "https://50870a15cca844c0976bdf199263a725@o929546.ingest.sentry.io/5878320",
    tracesSampleRate: 1.0,
    integrations: [new Integrations.BrowserTracing()],
  })
}

ReactDOM.render(
  <Provider store={store}>
    <Toaster />
    <App />
  </Provider>,
  document.querySelector("#root")
)

serviceWorker.unregister()
