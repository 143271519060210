/*************************************************
 * RedInk
 * @exports
 * @class SubjectsScreen.js
 * @extends Component
 * @author Sasidharan // on 06/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import Modal, { ModalTransition } from "@atlaskit/modal-dialog"
import { bindActionCreators } from "redux"
import { HashLoader } from "react-spinners"
import { components } from "react-select"
import { DatePicker } from "antd"
import makeAnimated from "react-select/animated"
import { connect } from "react-redux"
import moment from "moment"
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  FormGroup,
  Button,
  Label,
  Input,
  Table,
} from "reactstrap"

import "antd/dist/antd.css"

import {
  getMaxMarksList,
  getAcademicYearDetails,
  saveAcademicYearDetails,
  updateAcademicYearDetails,
  deleteDetailAcademicYearDetails,
} from "../../../../actions/admin/AcademicDetailsAction"
import {
  academicYearImorting,
  getOldAcademicList,
} from "../../../../actions/admin/UpgradeAcademicYear"
import {
  DANGER_TOAST,
  SUCCESS_TOAST,
  VALIDATION_MSG,
} from "../../../../utils/Constants"
import { confirmAlertPopUp } from "../../../../utils/Utility"
import { showNotification } from "../../../../actions/CommonAction"
import { getClassGroup } from "../../../../actions/admin/ClassDetailsAction"
import { URL_PATH } from "../../../../config/urlPath"

import { MySelect } from "../../../common/MySelect"

const { RangePicker } = DatePicker

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  )
}

const allOption = {
  label: "Select all",
  value: "*",
}

const MultiValue = props => {
  let labelToBeDisplayed = `${props.data.label}, `
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = "Selected All"
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  )
}

const ValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue()
  let toBeRendered = children
  if (currentValues.some(val => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]]
  }

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  )
}

const animatedComponents = makeAnimated()

const academicConditon = [
  {
    id: "0",
    value: "Sum of Terms",
  },
  {
    id: "1",
    value: "Average of Terms",
  },
  {
    id: "2",
    value: "Best of Terms",
  },
  {
    id: "3",
    value: "Only the final Terms",
  },
]

class AcademicDetailsScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      advanceOpen: false,
      isShowLoading: false,
      isLoading: true,
      newAcademicOpen: false,
      name: "",
      shortName: "",
      isChangeMarkOpen: false,
      allocationDetails: [
        { sectionGroups: [], resultCondition: "", changeMaxMark: [] },
      ],
      indexChangeMark: 0,
      maxMarkList: [],
      showUpgrageProccess: true,
      isLoadingImport: false,
      selectedAcademic: "",
      currentAcademic: {
        id: "",
        isImpored: false,
      },
    }
  }

  componentDidMount = () => {
    getOldAcademicList((success, res) => {
      if (success) {
        let data = res?.find(r => r.isActiveAcademicYear)
        this.setState({
          currentAcademic: {
            id: data.academicId,
            isImpored: data.isPreviousAcademicConfigsImported,
          },
        })
      }
    })
    this.getAcademicYear()
  }

  getAcademicYear = () => {
    this.props.getAcademicYearDetails((success, response) => {
      success &&
        this.setState({
          newAcademicOpen: response.length === 0,
          isLoading: false,
        })
    })
    this.props.getClassGroup({}, () => {})
    this.props.getMaxMarksList(response => {
      response && this.setState({ maxMarkList: response.assessmentMaxMarks })
    })
  }

  addMoreAllocations = () => {
    let array = this.state.allocationDetails
    array.push({
      sectionGroups: [], resultCondition: "", changeMaxMark: []
    }) // prettier-ignore
    this.setState({ allocationDetails: array })
  }

  deleteAllocation = i => {
    let array = this.state.allocationDetails
    array.length > 1 && array.splice(i, 1)
    this.setState({ allocationDetails: array })
  }

  deleteAcademicDetailFromDb = item => {
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT,
      yes => {
        if (yes) {
          this.props.deleteDetailAcademicYearDetails(
            {
              academicId: item.academicId,
              groupId: item.sectionGroups[0].groupId,
            },
            () => {}
          )
        }
      },
      {
        buttons: [],
        customUI: ({ onClose }) => {
          return (
            <div
              className="custom"
              style={{
                backgroundColor: "#fff",
                borderBottomRightRadius: "12px",
                borderBottomLeftRadius: "12px",
              }}
            >
              <div
                className="bg-brand text-light d-flex"
                style={{
                  padding: "0.5rem 2rem",
                  borderTopRightRadius: "12px",
                  borderTopLeftRadius: "12px",
                }}
              >
                <div className="mx-auto fs-18">Delete</div>
              </div>

              <div
                className="mt-3 "
                style={{
                  padding: "0.5rem 1.5rem",
                }}
              >
                <div className="text-muted fs-15">
                  Are you sure you want to delete?
                </div>
                <div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
                  <button
                    className="px-4 py-1 bg-brand text-light w-25 d-flex align-items-center justify-content-center yes-button "
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      this.props.deleteDetailAcademicYearDetails(
                        {
                          academicId: item.academicId,
                          groupId: item.sectionGroups[0].groupId,
                        },
                        () => {}
                      )
                      onClose()
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="px-4 py-1 ml-4 bg-brand text-light w-25 d-flex align-items-center justify-content-center"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={onClose}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )
        },
      }
    )
  }

  saveAcademic = () => {
    const { name, shortName, duration, allocationDetails } = this.state
    if (name !== "" && shortName !== "" && duration) {
      this.setState({ isShowLoading: true })
      const object = {
        name, shortName, allocationDetails,
        fromYear: moment(this.state.duration[0]).format("YYYY-MM-DD"),
        toYear: moment(this.state.duration[1]).format("YYYY-MM-DD"),
      } // prettier-ignore

      if (this.state.isShowLoading) return
      this.props.saveAcademicYearDetails(object, success => {
        this.setState({
          isShowLoading: false,
        })
        if (success) {
          this.cancelAcademic()
          this.setState({
            newAcademicOpen: false,
          })
        }
      })
    } else {
      this.setState({ hasError: true })
    }
  }

  updateAcademic = () => {
    this.setState({ isShowLoading: true })

    if (
      this.state.maxMarkList.length !==
      this.state.allocationDetails[0].changeMaxMark.length
    ) {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.OOPS,
        "Please Set maximum marks for all the Assessments"
      )
      this.setState({ hasError: true, isShowLoading: false })
      return
    }

    if (
      this.state.name !== "" &&
      this.state.shortName !== "" &&
      this.state.duration &&
      this.state.duration.length > 0 &&
      this.state.maxMarkList.length ===
        this.state.allocationDetails[0].changeMaxMark.length
    ) {
      if (this.state.isShowLoading) return

      let object = {
        academicName: this.state.name,
        academicShortName: this.state.shortName,
        academicId: this.state.updateSubjectDetailId,
        academicFrom: moment(this.state.duration[0]).format("YYYY-MM-DD"),
        academicTo: moment(this.state.duration[1]).format("YYYY-MM-DD"),
        changeMaxMark: this.state.allocationDetails[0].changeMaxMark,
        detailId: this.state.allocationDetails[0].detailId,
        resultCondition: this.state.allocationDetails[0].resultCondition,
        sectionGroups: this.state.allocationDetails[0].sectionGroups,
      }
      this.props.updateAcademicYearDetails(object, success => {
        this.setState({ isShowLoading: false })
        if (success) {
          this.setState({ newAcademicOpen: false }, () => {
            this.cancelAcademic()
          })
        }
      })
    } else {
      this.setState({ hasError: true })
    }
  }

  cancelAcademic = () => {
    this.setState({
      isShowUpdateSubjectInput: false,
      name: "",
      shortName: "",
      duration: [],
      allocationDetails: [
        { sectionGroups: [], resultCondition: "", changeMaxMark: [] },
      ],
    })
  }

  editAcademic = item => {
    let array = []
    array.push(moment(item.academicFrom))
    array.push(moment(item.academicTo))
    // let allocationDetail =  [{ sectionGroups: item.sectionGroups, resultCondition: item.resultCondition, changeMaxMark: item.changeMaxMark, detailId: item.detailId }]
    this.setState({
      newAcademicOpen: true,
      isShowUpdateSubjectInput: true,
      updateSubjectDetailId: item.academicId,
      name: item.academicName,
      shortName: item.academicShortName,
      allocationDetails: [
        {
          sectionGroups: item.sectionGroups ? item.sectionGroups : [],
          resultCondition: item.resultCondition,
          changeMaxMark: item.changeMaxMark,
          detailId: item.detailId,
        },
      ],
      duration: array,
    })
    setTimeout(() => {
      let el = document.getElementById("academicName")
      el.focus()
    }, 500)
  }

  startImport = () => {
    this.setState({ isLoadingImport: true })
    academicYearImorting(this.state.selectedAcademic, success => {
      if (success) {
        this.props.showNotification(
          SUCCESS_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Previous academic year imported to current academic year successfully"
        )
        this.getAcademicYear()

        this.setState({
          isLoadingImport: true,
          newAcademicOpen: false,
          currentAcademic: {
            id: this.state.currentAcademic.id,
            isImpored: true,
          },
        })
      }
    })
  }

  render() {
    return (
      <>
        <ModalTransition>
          {this.state.isChangeMarkOpen && (
            <Modal
              heading="Change Max. Marks"
              actions={[
                {
                  text: "Save",
                  onClick: () => this.setState({ isChangeMarkOpen: false }),
                },
                {
                  text: "cancel",
                  onClick: () => this.setState({ isChangeMarkOpen: false }),
                },
              ]}
            >
              <div className="d-flex mt-3">
                <div className="col d-flex flex-column align-items-center">
                  <p className="text-dark mb-2 text-uppercase font-weight-bold mb-2">
                    Assessment name
                  </p>
                </div>
                <div className="col d-flex flex-column align-items-center">
                  <p className="text-dark mb-2 text-uppercase font-weight-bold mb-2">
                    Max. Marks
                  </p>
                </div>
              </div>

              {this.state.maxMarkList &&
                this.state.maxMarkList.map(item => {
                  const maxMarkItem = this.state.allocationDetails[
                    this.state.indexChangeMark
                  ].changeMaxMark.find(
                    ite => ite.assessmentId === item.assessmentId
                  )
                  return (
                    <FormGroup className="d-flex mb-3" key={item.assessmentId}>
                      <div className="col d-flex justify-content-center align-items-center">
                        <Label
                          className="m-0"
                          htmlFor={`maxMark-${item.assessmentShortName}`}
                        >
                          {item.assessmentShortName}
                        </Label>
                      </div>
                      <div className="col d-flex justify-content-center align-items-center">
                        <Input
                          type="number"
                          name={`maxMark-${item.assessmentShortName}`}
                          id={`maxMark-${item.assessmentShortName}`}
                          value={maxMarkItem ? maxMarkItem.maxMark : ""}
                          onChange={e => {
                            const value = e.target.value
                            if (value.length < 4) {
                              if (maxMarkItem) {
                                let allocDet = this.state.allocationDetails
                                allocDet[
                                  this.state.indexChangeMark
                                ].changeMaxMark = allocDet[
                                  this.state.indexChangeMark
                                ].changeMaxMark.map(ite =>
                                  ite.assessmentId === maxMarkItem.assessmentId
                                    ? { ...maxMarkItem, maxMark: value }
                                    : ite
                                )
                                this.setState({
                                  allocationDetails: allocDet,
                                })
                              } else {
                                const allocDet = this.state.allocationDetails
                                allocDet[
                                  this.state.indexChangeMark
                                ].changeMaxMark = [
                                  { ...item, maxMark: value },
                                  ...allocDet[this.state.indexChangeMark]
                                    .changeMaxMark,
                                ]
                                this.setState({ allocationDetails: allocDet })
                              }
                            }
                          }}
                        />
                      </div>
                    </FormGroup>
                  )
                })}
            </Modal>
          )}
        </ModalTransition>

        <Collapse isOpen={this.state.newAcademicOpen}>
          <div className="d-flex">
            <div className="col-md-5" style={{ maxWidth: 290 }}>
              <FormGroup>
                <Label htmlFor="academicName">Academic Name</Label>
                <Input
                  id="academicName"
                  type="text"
                  name="academicName"
                  value={this.state.name}
                  onChange={e => this.setState({ name: e.target.value })}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      let el = document.getElementById("shortName")
                      el.focus()
                    }
                  }}
                />
              </FormGroup>
              <div className="d-flex justify-content-center">
                {this.state.name === "" && this.state.hasError && (
                  <p className="text-danger">Academic Name is required.</p>
                )}
              </div>
              <FormGroup>
                <Label htmlFor="academicName">Short Name</Label>
                <Input
                  id="shortName"
                  type="text"
                  name="shortName"
                  value={this.state.shortName}
                  onChange={e => this.setState({ shortName: e.target.value })}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      let el = document.getElementById("admin-sp-a-d-a-y")
                      el.focus()
                    }
                  }}
                />
              </FormGroup>
              <div className="d-flex justify-content-center">
                {this.state.shortName === "" && this.state.hasError && (
                  <p className="text-danger">
                    Academic Short Name is required.
                  </p>
                )}
              </div>
              <FormGroup>
                <Label>Select Academic year</Label>
                <div>
                  <RangePicker
                    onChange={duration => this.setState({ duration })}
                    value={this.state.duration}
                    format="DD-MM-YYYY"
                    id="admin-sp-a-d-a-y"
                  />
                </div>
              </FormGroup>
              <Button
                className="d-flex align-items-center"
                color="light"
                onClick={() => {
                  if (!this.state.advanceOpen)
                    if (
                      this.state.maxMarkList &&
                      this.state.maxMarkList.length === 0
                    ) {
                      this.props.showNotification(
                        DANGER_TOAST,
                        VALIDATION_MSG.OOPS,
                        "Please fill assessment and term details before going advance settings"
                      )
                    } else {
                      this.setState({
                        advanceOpen: !this.state.advanceOpen,
                      })
                    }
                  else {
                    this.setState({
                      advanceOpen: !this.state.advanceOpen,
                    })
                  }
                }}
              >
                {this.state.advanceOpen ? "Hide" : "Show"} Advance Settings
              </Button>
            </div>
            <div className="col"></div>
          </div>
          {this.props.classGroupsList &&
          this.props.classGroupsList.length > 0 ? (
            this.state.advanceOpen ? (
              <>
                <div className="d-flex justify-content-start mt-3 mb-2">
                  <div className="col-sm flex-1">
                    <h5 className="text-dark font-weight-bold">Assign class</h5>
                  </div>
                  <div className="col-md flex-2">
                    <h5 className="text-dark font-weight-bold">
                      Academic result condition
                    </h5>
                  </div>
                  <div className="col-md flex-2"></div>
                </div>
                {this.state.allocationDetails &&
                  this.state.allocationDetails.map((item, index) => (
                    <div
                      key={index}
                      className="d-flex align-items-center justify-content-start mb-3"
                    >
                      <div className="col-sm flex-1">
                        <MySelect
                          placeholder="Select Class"
                          isClearable
                          value={item.sectionGroups.map(it => ({
                            value: it.groupName || it.value,
                            label: it.groupName || it.label,
                            id: it.groupId,
                          }))}
                          options={this.props?.classGroupsList?.map(it => ({
                            value: it.groupName || it.value,
                            label: it.groupName || it.label,
                            id: it.groupId,
                          }))}
                          className="basic-multi-select"
                          isMulti
                          allowSelectAll
                          hideSelectedOptions={false}
                          closeMenuOnSelect={false}
                          components={{
                            Option,
                            MultiValue,
                            ValueContainer,
                            animatedComponents,
                          }}
                          classNamePrefix="select"
                          onChange={selectedOption => {
                            if (selectedOption !== null) {
                              let array = []
                              for (let i = 0; i < selectedOption.length; i++) {
                                if (selectedOption[i].label !== "Select all") {
                                  array.push({
                                    groupId: selectedOption[i].id,
                                    groupName: selectedOption[i].label,
                                  })
                                }
                              }
                              this.setState(state => {
                                state.allocationDetails[
                                  index
                                ].sectionGroups = array
                                return state
                              })
                            } else {
                              this.setState(state => {
                                state.allocationDetails[
                                  index
                                ].sectionGroups = []
                                return state
                              })
                            }
                          }}
                        />
                      </div>
                      <div className="col-md flex-2">
                        <select
                          className="form-control"
                          value={item.resultCondition}
                          onChange={e => {
                            const value = e.target.value
                            let array = this.state.allocationDetails
                            array[index].resultCondition = value
                            this.setState({
                              allocationDetails: array,
                            })
                          }}
                        >
                          <option>Select Term conditions</option>
                          {academicConditon &&
                            academicConditon.map(item => (
                              <option key={item.id} value={item.id}>
                                {item.value}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-md flex-2">
                        <Button
                          color="light"
                          className="mr-3"
                          size="sm"
                          onClick={() =>
                            this.setState({
                              isChangeMarkOpen: true,
                              selectedMaxMarkIndex: index,
                              indexChangeMark: index,
                            })
                          }
                        >
                          Set Max. Marks
                        </Button>
                        {!this.state.isShowUpdateSubjectInput && (
                          <>
                            <Button
                              color="light"
                              className="mr-3"
                              size="lg"
                              onClick={this.addMoreAllocations}
                            >
                              <i className="fas fa-plus text-dark"></i>
                            </Button>
                            <Button
                              color="light"
                              className="mr-3"
                              size="lg"
                              onClick={() => this.deleteAllocation(index)}
                            >
                              <i className="far fa-trash-alt text-danger"></i>
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
              </>
            ) : (
              <></>
            )
          ) : (
            <div className="d-flex mt-3 mb-2 ml-2">
              <div className="alert alert-info fade show">
                Please create classes and class group in records tab in left
                menu or{" "}
                <div
                  onClick={() => {
                    this.props.history.push({
                      pathname: URL_PATH.ADMIN_SCHOOL_PROFILE_CLASS_DETAILS,
                      state: {},
                    })
                  }}
                >
                  click here
                </div>
              </div>
            </div>
          )}

          {this.state.updateSubjectDetailId === this.state.currentAcademic.id &&
            !this.state.currentAcademic.isImpored && (
              <div className="pl-4 my-4" style={{ maxWidth: "650px" }}>
                <div className="mb-2 fs-15 fw-600">Import Configurations</div>

                <div className="p-4 bg-F7F7F8 br-8px">
                  <div className="fs-14 clr-5B5B5B">Import from</div>

                  <div className="d-flex align-items-center gap-1rem my-3">
                    <select
                      className="modern-arrow form-control border-0"
                      value={this.state.selectedAcademic}
                      onChange={e =>
                        this.setState({ selectedAcademic: e.target.value })
                      }
                      disabled={this.state.isLoadingImport}
                    >
                      <option value="" disabled></option>
                      {this.props.academicYearList?.map(ac => (
                        <option value={ac.academicId} key={ac.academicId}>
                          {ac.academicName}
                        </option>
                      ))}
                    </select>

                    <button
                      className="dis-grayed px-4 py-2 fw-600 bg-66B8A0 text-white br-5px"
                      style={{ flexShrink: "0" }}
                      disabled={this.state.isLoadingImport}
                      onClick={this.startImport}
                    >
                      {this.state.isLoadingImport ? (
                        <HashLoader
                          css={{ margin: "0 auto" }}
                          size={16}
                          color="#fff"
                          loading
                          sizeUnit="px"
                        />
                      ) : (
                        "Start Importing"
                      )}
                    </button>
                  </div>

                  <div className="fs-14 clr-5B5B5B">
                    Import complete configuration like Examination setup,
                    Teacher details <br />
                    and Subjects. These can be changed later as well.
                  </div>
                </div>
              </div>
            )}

          <div className="d-flex align-items-center justify-content-end my-3">
            <Button
              // color="secondary"
              style={{
                backgroundColor: "#DBDBDB",
                color: "#06B191",
                border: "none",
                width: "5rem",
              }}
              className="rounded-pill ml-3"
              onClick={() => {
                this.setState({ newAcademicOpen: false })
              }}
            >
              cancel
            </Button>
            <Button
              style={{
                backgroundColor: "#0eb292",
                border: "none",
                width: "5rem",
              }}
              className="rounded-pill ml-3"
              onClick={() => {
                if (this.state.isShowUpdateSubjectInput) this.updateAcademic()
                else this.saveAcademic()
              }}
            >
              {this.state.isShowLoading ? (
                <HashLoader
                  css={{ paddingLeft: "40px" }}
                  size={16}
                  color="#fff"
                  loading
                  sizeUnit="px"
                />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </Collapse>

        <Table>
          <thead
            style={{
              backgroundColor: "#022636",
              textTransform: "uppercase",
              color: "#fff",
            }}
          >
            <tr>
              <th className="text-center">Academic Name</th>
              <th>Result Condition</th>
              <th className="text-center">Assigned classes</th>
              <th></th>
            </tr>
          </thead>

          {this.state.isLoading ? (
            <HashLoader
              css={{
                position: "fixed",
                width: "100%",
                height: "100%",
                left: "0px",
                top: "0px",
              }}
              size={30}
              color="#084353"
              loading
              sizeUnit="px"
            />
          ) : (
            <tbody>
              {this.state.showUpgrageProccess &&
                this.props.academicYearList &&
                this.props.academicYearList.map(item => (
                  <tr key={item.detailId}>
                    <td className="text-center">
                      <h5 className="m-0 text-dark">
                        {item.academicShortName}
                      </h5>
                    </td>
                    <td className="text-center align-items-center">
                      {item.termMarkArray && item.termMarkArray.length > 0 ? (
                        <div>
                          <div className="d-flex row">
                            {item.termMarkArray.map(item => (
                              <div
                                key={item.termName}
                                style={{ backgroundColor: "#022636" }}
                                className="text-white d-flex align-items-center rounded-lg ml-4"
                              >
                                <div className="py-1 px-2 rounded-lg">
                                  {item.termName}
                                </div>
                                <div
                                  className="py-1 px-2 rounded-lg bg-white"
                                  style={{ color: "#022636", margin: 1 }}
                                >
                                  {item.maxMark}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div>-</div>
                      )}
                    </td>
                    <td className="text-center align-items-center">
                      {item.sectionGroups && item.sectionGroups.length > 0 ? (
                        <div className="badge badge-info px-3 py-2">
                          {item.sectionGroups[0].label}
                        </div>
                      ) : (
                        <div className="text-center">-</div>
                      )}
                    </td>
                    <td>
                      <UncontrolledDropdown direction="left">
                        <DropdownToggle tag="button" className="nav-link">
                          <i className="fas fa-ellipsis-v text-black cursor-pointer"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => this.editAcademic(item)}>
                            Edit
                          </DropdownItem>
                          {item.sectionGroups && item.sectionGroups.length > 0 && (
                            <DropdownItem
                              onClick={() => {
                                this.deleteAcademicDetailFromDb(item)
                              }}
                            >
                              Delete
                            </DropdownItem>
                          )}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </Table>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    assessmentDetailList: state.adminAcademicYearDetailsState.AssessmentDetail,
    academicYearList: state.adminAcademicYearDetailsState.AcademicYearDetail,
    classGroupsList: state.classDetailsState.classGroupsList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getMaxMarksList,
      getAcademicYearDetails,
      saveAcademicYearDetails,
      updateAcademicYearDetails,
      // deleteAcademicYearDetails,
      deleteDetailAcademicYearDetails,
      getClassGroup,
      showNotification,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(AcademicDetailsScreen)
