import React from "react"
import { default as ReactSelect } from "react-select"

const defaultAllOption = {
  label: "Select all",
  value: "*",
}

const defaultAllOptionAsStudent = {
  label: "Select Students",
  value: "*",
}

export const MySelect = ({ allOption = defaultAllOption, ...props }) => {
  if (props.allowSelectAll) {
    return (
      <ReactSelect
        {...props}
        options={[allOption, ...props.options]}
        onChange={(selected, event) => {
          if (selected !== null && selected.length > 0) {
            if (selected[selected.length - 1].value === allOption.value) {
              return props.onChange([allOption, ...props.options])
            }
            let result = []
            if (selected.length === props.options.length) {
              if (selected.includes(allOption)) {
                result = selected.filter(
                  option => option.value !== allOption.value
                )
              } else if (event.action === "select-option") {
                result = [allOption, ...props.options]
              }
              return props.onChange(result)
            }
          }

          return props.onChange(selected)
        }}
      />
    )
  }

  return <ReactSelect {...props} />
}

export const SelectAssignment = ({
  allOption = defaultAllOptionAsStudent,
  ...props
}) => {
  if (props.allowSelectAll) {
    return (
      <ReactSelect
        {...props}
        options={[allOption, ...props.options]}
        onChange={(selected, event) => {
          if (selected !== null && selected.length > 0) {
            if (selected[selected.length - 1].value === allOption.value) {
              return props.onChange([allOption, ...props.options])
            }
            let result = []
            if (selected.length === props.options.length) {
              if (selected.includes(allOption)) {
                result = selected.filter(
                  option => option.value !== allOption.value
                )
              } else if (event.action === "select-option") {
                result = [allOption, ...props.options]
              }
              return props.onChange(result)
            }
          }

          return props.onChange(selected)
        }}
      />
    )
  }

  return <ReactSelect {...props} />
}
