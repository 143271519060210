import React from "react"
import ReactLottie from "react-lottie"
import { Modal } from "reactstrap"

function GeneratedSuccess({ isReoprtGenerated }) {
  return (
    <Modal
      isOpen
      backdropClassName="modal-backdrop-show"
      modalClassName="modal-dialog-shadow-none success-generated-modal"
      contentClassName="report-modal-wrapper"
      centered
    >
      <ReactLottie
        width={300}
        height={180}
        options={{
          loop: true,
          autoplay: true,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
          animationData: require("../../../../assets/lotties/tick.json"),
        }}
      />

      {isReoprtGenerated && (
        <h1 className="clr-0D2535 fs-17 fw-500 mt-2 pb-4 text-center">
          Report card generated successfully
        </h1>
      )}
    </Modal>
  )
}

export default GeneratedSuccess
