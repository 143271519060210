/*************************************************
 * RedInk
 * @exports
 * @class teacherDetailsAction.js
 * @extends Component
 * @author vijayakumar // on 05/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { ACTION_TYPES } from "../types"
import { showNotification } from "../CommonAction"
import {
  SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"
import store from "store"
import _ from "lodash"

export const saveTeacherDetailsInStore = teacherDetails => {
  return {
    type: ACTION_TYPES.TEACHER_DETAIL,
    payload: teacherDetails,
  }
}

export const saveTeacherListInStore = teachersList => {
  return {
    type: ACTION_TYPES.TEACHER_LIST,
    payload: teachersList,
  }
}

export const getTeacherList = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_TEACHERS_LIST, null, true)
      if (response) {
        myLog("====getTeacherList RESPONSE===::::", response)
        dispatch(saveTeacherListInStore(response.teachersList))
        callBack(true, response.teachersList)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getTeacherList ERROR===????", error)
    }
  }
}

export const getTeacherDetails = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_TEACHERS_DETAILS, object, true)
      if (response) {
        myLog("====getTeacherDetails RESPONSE===::::", response)
        dispatch(saveTeacherDetailsInStore(response.teacherDetails))
        callBack(true, response.teacherDetails)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getTeacherDetails ERROR===????", error)
    }
  }
}

export const saveTeacherDetails = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      const response = await Client.post(URL.SAVE_TEACHERS_DETAILS, body, true)
      if (response.responseCode === 0) {
        myLog("====saveteacherDetails RESPONSE===::::", response)
        dispatch(getTeacherDetails({}, () => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(showNotification(DANGER_TOAST, VALIDATION_MSG.OOPS, "error"))
      callBack(false)
      myLog("====saveteacherDetails ERROR===????", error)
    }
  }
}

export const updateTeacherDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.post(
        URL.UPDATE_TEACHERS_DETAILS,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====updateteacherDetails RESPONSE===::::", response)
        dispatch(getTeacherDetails({}, () => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(showNotification(DANGER_TOAST, VALIDATION_MSG.OOPS, "error"))
      callBack(false)
      myLog("====updateteacherDetails ERROR===????", error)
    }
  }
}

export const deleteTeacherDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.DELETE_TEACHERS_DETAILS,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteteacherDetails RESPONSE===::::", response)
        dispatch(getTeacherDetails({}, () => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteteacherDetails ERROR===????", error)
    }
  }
}

export const deleteAssignedClassDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.DELETE_ASSIGNED_TEACHER_CLASS_DETAILS,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteAssignedClassDetails RESPONSE===::::", response)
        dispatch(getTeacherDetails({}, () => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteAssignedClassDetails ERROR===????", error)
    }
  }
}

export const getCheckClassTeacherAvailabilityDetails = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.CHECK_CLASS_TEACHER_AVAILABILITY,
        object,
        true
      )
      if (response) {
        myLog(
          "====getCheckClassTeacherAvailabilityDetails RESPONSE===::::",
          response
        )
        if (response.responseCode === 0 && response.exists === true) {
          dispatch(
            showNotification(
              DANGER_TOAST,
              VALIDATION_MSG.OOPS,
              response.responseMessage
            )
          )
          callBack(true, response)
        } else callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getCheckClassTeacherAvailabilityDetails ERROR===????", error)
    }
  }
}

export const getCheckClassAndSubjectAvailabilityDetails = (
  object,
  callBack
) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.CHECK_CLASS_SUBJECT_AVAILABILITY,
        object,
        true
      )
      if (response) {
        if (response.responseCode === 0 && response.exists === true) {
          dispatch(
            showNotification(
              DANGER_TOAST,
              VALIDATION_MSG.OOPS,
              response.responseMessage
            )
          )
          callBack(true, response)
        } else callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog(
        "====getCheckClassAndSubjectAvailabilityDetails ERROR===????",
        error
      )
    }
  }
}

export const getTeacherRemoteLogin = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_TEACHERS_REMOTE_LOGIN,
        object,
        true
      )
      if (
        _.has(response.remoteLoginData, "access_token") &&
        _.has(response.remoteLoginData, "token_type") &&
        _.has(response.remoteLoginData, "refresh_token") &&
        _.has(response.remoteLoginData, "expires_in") &&
        _.has(response.remoteLoginData, "userInfo")
      ) {
        store.set("userSession", response.remoteLoginData)
        store.set(
          "expiryTime",
          Client.tokenExpires(response.remoteLoginData.expires_in)
        )
        myLog("====LOGIN RESPONSE===::::", response.remoteLoginData)
        dispatch({
          type: ACTION_TYPES.LOGIN_USER,
          payload: response.remoteLoginData,
        })
        dispatch({
          type: ACTION_TYPES.USER_INFO,
          payload: response.remoteLoginData.userInfo,
        })
        callBack(true, response.remoteLoginData)
      } else {
        callBack(false)
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            VALIDATION_MSG.AUTH_FAILED
          )
        )
      }
    } catch (error) {
      callBack(false)
      myLog("====getTeacherRemoteLogin ERROR===????", error)
    }
  }
}
