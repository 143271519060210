/*************************************************
 * RedInk
 * @exports
 * @class Dashboard.js
 * @extends Component
 * @author Sasidharan // on 11/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import AppWrapper from "../Wrapper"
import { connect } from "react-redux"
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import { bindActionCreators } from "redux"
import { HashLoader } from "react-spinners"
import {
  saveBehaviourDetails,
  saveBehaviourRecognitionDetails,
  deleteBehaviourDetails,
  getBehaviourRecognitionDetails,
  getBehaviourDetails,
} from "../../../actions/teacher/BehaviourAction"
import boyPic from "../../../assets/images/boy.png"
import girlPic from "../../../assets/images/girl.png"

class BehaviourScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showSideMenu: true,
      isMessageModalOpen: false,
      isNext: false,
      students: [],
      recognitions: [],
    }
  }

  clickNext = () => {
    this.setState({
      isNext: false,
    })
  }

  changeObject = () => {}

  componentDidMount() {
    this.getRecognition()
  }

  getRecognition = () => {
    this.props.getBehaviourDetails((success, response) => {
      if (success) {
        this.setState({ students: response })
      }
    })
    this.props.getBehaviourRecognitionDetails((success, response) => {
      if (success) {
        this.setState({ recognitions: response })
      }
    })
  }

  addNewRecognition = () => {
    let recognitionArray = this.state.recognitions
    recognitionArray.unshift({ id: "", recognitionName: "", isPositive: "" })
    this.setState({ recognitions: recognitionArray })
  }

  saveRecognition = () => {
    let object = {
      isPositive: this.state.isPositiveSelected ? true : false,
      recognitionName: this.state.newRecognition,
    }
    this.props.saveBehaviourRecognitionDetails(object, (success, response) => {
      if (success) {
        this.getRecognition()
        this.setState({
          newRecognition: "",
          isPositiveSelected: false,
          isNegativeSelected: false,
        })
      }
    })
  }

  saveStudentBehaviour = () => {
    let studentArray = this.state.students.filter(
      item => item.checked && item.checked === true
    )
    let recognitionArray = this.state.recognitions.filter(
      item => item.checked && item.checked === true
    )
    let object = {
      recognitions: recognitionArray,
      students: studentArray,
    }
    this.props.saveBehaviourDetails(object, (success, response) => {
      if (success) {
        this.getRecognition()
        this.setState({
          newRecognition: "",
          isPositiveSelected: false,
          isNegativeSelected: false,
        })
      }
    })
  }

  render() {
    const { isNext } = this.state
    // Cards
    const students = this.state.students?.map((stu, index) => {
      return (
        <div className="student-behaviour-card p-0 mr-3 mb-4 pb-1">
          <div className="card-body p-2">
            <div className="d-flex flex-row-reverse p-0">
              <UncontrolledDropdown>
                <DropdownToggle tag="a" className="p-0 pr-1 nav-link">
                  <button
                    className="fa fa-ellipsis-v"
                    style={{ fontSize: "12px", color: "white" }}
                  ></button>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>
                    <button
                      className="text-black"
                      data-toggle="modal"
                      data-target="#assignModal"
                      onClick={() => {
                        this.setState({
                          isNext: true,
                        })
                        let array = this.state.students
                        array[index].checked = true
                        this.setState({
                          students: array,
                        })
                      }}
                    >
                      Save
                    </button>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <img
              src={stu.studentGender === "M" ? boyPic : girlPic}
              alt=""
              className="student-pic center mx-auto d-block"
              style={{ width: "100px", height: "50%" }}
            />
            <p className="card-subtitle text-white pt-4 pb-3 text-center">
              {stu.studentName}
            </p>
            <i className="card-plus fa fa-plus ml-3 text-white"></i>
            <span className="text-white p-2"> {stu.positiveCount} </span>
            <div className="float-right mr-3 pb-3 text-white">
              <i className="text-white card-minus fa fa-minus"></i>
              <span className="text-white p-2"> {stu.negativeCount} </span>
            </div>
          </div>
        </div>
      )
    })

    return (
      <div>
        {/* model */}
        <div
          className="modal right slide-in-right fade mt-0"
          id="assignModal"
          role="dialog"
          aria-labelledby="myModalLabel2"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content text-white bg-theme">
              <div className="modal-header border-bottom-0 bg-theme text-white d-flex">
                <h6 className="modal-title" id="myModalLabel2">
                  Select {isNext ? "Recognition" : "Students"}
                </h6>
                <div className="d-flex">
                  {isNext ? (
                    <div>
                      <button
                        type="button"
                        className=" text-white"
                        onClick={() => {
                          this.addNewRecognition()
                        }}
                      >
                        <i className="mr-2 text-white fa fa-plus" />
                      </button>
                      <button
                        type="button"
                        className="fa fa-trash text-white"
                        onClick={() => {
                          let recognitionArray = this.state.recognitions.filter(
                            item => item.checked && item.checked === true
                          )
                          let object = {
                            recognitions: recognitionArray,
                          }
                          this.props.deleteBehaviourDetails(object, () => {})
                        }}
                      ></button>
                    </div>
                  ) : (
                    <div className="d-flex">
                      <div>Select All</div>
                      <div className="pt-1 pl-1">
                        <input
                          type="checkbox"
                          id="eCheckbox1"
                          checked={this.state.studentSelectAll}
                          onChange={() => {
                            this.setState(
                              {
                                studentSelectAll: !this.state.studentSelectAll,
                              },
                              () => {
                                if (this.state.studentSelectAll) {
                                  let studentArray = this.state.students
                                  for (
                                    let i = 0;
                                    i < studentArray.length;
                                    i++
                                  ) {
                                    studentArray[i].checked = true
                                  }
                                  this.setState({ students: studentArray })
                                } else {
                                  let studentArray = this.state.students
                                  for (
                                    let i = 0;
                                    i < studentArray.length;
                                    i++
                                  ) {
                                    studentArray[i].checked = false
                                  }
                                  this.setState({ students: studentArray })
                                }
                              }
                            )
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <button
                    type="button"
                    className="close text-white "
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {}}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>

              <div className="modal-body scrollbar">
                {isNext
                  ? this.state.recognitions &&
                    this.state.recognitions.map((rec, index) => {
                      return (
                        <div>
                          {rec.recognitionName !== "" ? (
                            <div className="mb-3 card-body d-flex justify-content-between br-10 bg-green text-dark ">
                              {rec.isPositive ? (
                                <i className="positive-circle fa fa-plus ml-3 text-white" />
                              ) : (
                                <i className="negative-circle fa fa-minus ml-3 text-white" />
                              )}
                              <div>{rec.recognitionName}</div>
                              <div className="checkbox">
                                <input
                                  type="checkbox"
                                  id="eCheckbox1"
                                  checked={
                                    this.state.recognitions[index].checked
                                  }
                                  onChange={() => {
                                    let array = this.state.recognitions
                                    array[index].checked = !array[index].checked
                                    this.setState({
                                      recognitions: array,
                                    })
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="mb-3 card-body br-10 bg-green text-dark ">
                              <div className="form-group">
                                <label>New Recognition</label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="mr-2 rounded form-control"
                                    value={this.state.newRecognition}
                                    onChange={e =>
                                      this.setState({
                                        newRecognition: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{ paddingLeft: "20px" }}
                              >
                                <div
                                  className={`float-right mr-3 pb-1 p-2 ${
                                    this.state.isPositiveSelected
                                      ? "behaviour-background"
                                      : "text-dark"
                                  }`}
                                  onClick={() => {
                                    this.setState({
                                      isPositiveSelected: true,
                                      isNegativeSelected: false,
                                    })
                                  }}
                                >
                                  <i className="text-white card-plus fa fa-plus"></i>
                                  <span className=" p-2"> Positive </span>
                                </div>
                                <div
                                  className={`float-right mr-3 pb-1 p-2 ${
                                    this.state.isNegativeSelected
                                      ? "behaviour-background"
                                      : "text-dark"
                                  }`}
                                  onClick={() => {
                                    this.setState({
                                      isPositiveSelected: false,
                                      isNegativeSelected: true,
                                    })
                                  }}
                                >
                                  <i className="text-white card-minus fa fa-minus"></i>
                                  <span className=" p-2"> Negative </span>
                                </div>
                              </div>
                              <button
                                type="button"
                                disabled={this.state.isShowLoading}
                                className="px-4 mt-2 btn btn-sm btn-success rounded-pill"
                                style={{ background: "#084353" }}
                                onClick={() => this.saveRecognition()}
                              >
                                {this.state.isShowLoading ? (
                                  <HashLoader
                                    sizeUnit={"px"}
                                    size={16}
                                    color={"#ffffff"}
                                    loading={this.state.isShowLoading}
                                  />
                                ) : (
                                  <div>
                                    <i className="far fa-save mr-1" />
                                    Save
                                  </div>
                                )}
                              </button>
                            </div>
                          )}
                        </div>
                      )
                    })
                  : this.state.students &&
                    this.state.students.map((stu, index) => {
                      return (
                        <div className="pt-2 pb-2 mb-3 card-body d-flex justify-content-between br-10 bg-green text-dark ">
                          <div> {stu.studentName} </div>
                          <div className="checkbox">
                            <input
                              type="checkbox"
                              id="eCheckbox1"
                              checked={this.state.students[index].checked}
                              onChange={() => {
                                let array = this.state.students
                                array[index].checked = !array[index].checked
                                this.setState({
                                  students: array,
                                })
                              }}
                            />
                          </div>
                        </div>
                      )
                    })}
              </div>
              <div className="modal-footer border-top-0 bg-theme">
                {isNext ? (
                  <div>
                    <button
                      type="button"
                      className="text-white "
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      CANCEL
                    </button>
                    <button
                      type="button"
                      className="text-white "
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        this.saveStudentBehaviour()
                      }}
                    >
                      SAVE
                    </button>
                  </div>
                ) : (
                  <button
                    className="text-white"
                    onClick={() => {
                      this.setState({
                        isNext: true,
                      })
                    }}
                  >
                    NEXT
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="modal right slide-in-right fade mt=0"></div>

        <AppWrapper
          teacherDashboard
          showTeacherThree
          showTeacherLogo
          screenName={"Behaviour Record"}
        >
          <div>
            <nav className="navbar navbar-light bg-light">
              <div className="navbar-brand font-weight-bold" href="">
                Behaviour Record
              </div>

              <form className="form-inline my-2 my-lg-0">
                <button
                  className="px-4 mr-3 btn btn-sm btn-dark rounded-pill box-shadow-none"
                  id="assign-tooltip"
                  type="button"
                  data-toggle="modal"
                  data-target="#assignModal"
                  onClick={() => {
                    this.clickNext()
                  }}
                >
                  <UncontrolledTooltip
                    placement="top"
                    className="bg-danger"
                    target="assign-tooltip"
                  >
                    Assign positive and negative points to a student according
                    to their behavior)
                  </UncontrolledTooltip>
                  Assign Recognition
                </button>
                {/* <input
                  className="form-control search-btn mr-sm-2"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                /> */}
              </form>
            </nav>

            <div className=" row mt-5 pb-3">{students}</div>
          </div>
        </AppWrapper>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      saveBehaviourRecognitionDetails,
      getBehaviourRecognitionDetails,
      getBehaviourDetails,
      saveBehaviourDetails,
      deleteBehaviourDetails,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(BehaviourScreen)
