/*************************************************
 * RedInk
 * @exports
 * @class AttendanceReportReducer.js
 * @extends Component
 * @author vijayakumar // on 18/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from "../../actions/types"

const INTIAL_STATE = {
  subjectAllotmentList: null,
}

export const teacherSubjectAllotmentReducer = (
  state = INTIAL_STATE,
  action
) => {
  switch (action.type) {
    case ACTION_TYPES.TEACHER_SUBJECT_ALLOTMENT_LIST_UPDATE:
      return {
        ...state,
        subjectAllotmentList: action.payload,
      }

    default:
      return state
  }
}
