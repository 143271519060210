import React, { Component } from "react"
import queryString from "query-string"
import store from "store"
// import crypto from "crypto-js"

class ZoomIntegration extends Component {
  state = {
    integratedSuccessfully: false,
  }

  componentDidMount() {
    let params = queryString.parse(window.location.search)
    if (!params.code) {
      let redirectUrl = "https://school.redink.app/?redirect=zoom"
      if (window.location.hostname === "localhost") {
        redirectUrl = "http://localhost:3000/?redirect=zoom"
      } else if (window.location.hostname === "dev.redink.app") {
        redirectUrl = "https://dev.redink.app/?redirect=zoom"
      } else if (window.location.hostname === "stage.redink.app") {
        redirectUrl = "https://stage.redink.app/?redirect=zoom"
      }
      window.location.href = redirectUrl
    }
    const userSession = store.get("userSession")
    params.provider = "ZOOM"
    // if (params.state) {
    // let originalText = crypto.AES.decrypt(
    //   params.state,
    //   "Redink@123"
    // ).toString(crypto.enc.Utf8)
    // }
    params.token = params.state ? params.state : userSession.access_token
    this.integrateZoom(params)
  }

  integrateZoom = async params => {
    let serverUrl = "https://napi.redink.app/api/3/schedule/integrate"
    if (window.location.hostname === "localhost") {
      // serverUrl = "http://localhost:4000/api/3/schedule/integrate"
      serverUrl =
        "https://redink-nodejs-k3yor.ondigitalocean.app/api/3/schedule/integrate"
    } else if (window.location.hostname === "dev.redink.app") {
      serverUrl =
        "https://redink-nodejs-k3yor.ondigitalocean.app/api/3/schedule/integrate"
    } else if (window.location.hostname === "stage.redink.app") {
      serverUrl =
        "https://redink-nodejs-stage-m7n73.ondigitalocean.app/api/3/schedule/integrate"
    } else if (window.location.hostname === "school.redink.app") {
      serverUrl = "https://napi.redink.app/api/3/schedule/integrate"
    }
    const rawResponse = await fetch(serverUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `bearer ${params.token}`,
      },
      body: JSON.stringify(params),
    })
    const content = await rawResponse.json()
    if (content.responseCode === 0) {
      this.setState({ integratedSuccessfully: true })
      setTimeout(() => {
        window.location.href = "redinkapp://redink.app"
        setTimeout(() => {
          window.location.href = "https://school.redink.app/teacher/schedule"
        }, 250)
      }, 5000)
    } else {
      this.setState({ integratedSuccessfully: false })
    }
  }

  render() {
    return (
      <div className="text-center">
        <p className="p-5">
          {this.state.integratedSuccessfully ? (
            <p>
              <b>Integrated successfully</b>
              <br /> <br />
              You can now close this tab or it will be redirected to home page.
            </p>
          ) : (
            " Checking user account and integrating zoom, please wait..."
          )}
        </p>
      </div>
    )
  }
}

export default ZoomIntegration
