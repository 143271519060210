/*************************************************
 * RedInk
 * @exports
 * @class DashboardAction.js
 * @extends Component
 * @author vijayakumar // on 05/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { ACTION_TYPES } from "../types"
import { showNotification } from "../CommonAction"
import {
  SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"

export const saveTermListInStore = termList => {
  return {
    type: ACTION_TYPES.TERM_LIST,
    payload: termList,
  }
}

export const saveDashboardStudentAttendanceReportListInStore = studentAttendanceReport => {
  return {
    type: ACTION_TYPES.STUDENT_ATTENDENCE_LIST_REPORT,
    payload: studentAttendanceReport,
  }
}

export const saveTeacherClassDetailsInStore = teacherClassDetail => {
  return {
    type: ACTION_TYPES.TEACHER_DASBOARD_CLASS_DETAIL,
    payload: teacherClassDetail,
  }
}

export const saveTeacherClassTodaysAttendanceDetailsInStore = teacherClassDetail => {
  return {
    type: ACTION_TYPES.TEACHER_DASBOARD_CLASS_DETAIL,
    payload: teacherClassDetail,
  }
}

export const getTermList = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_TEACHERS_TERM_LIST, null, true)
      if (response) {
        myLog("====getTermList RESPONSE===::::", response)
        dispatch(saveTermListInStore(response.terms))
        callBack(true, response.terms)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getTermList ERROR===????", error)
    }
  }
}

export const getDashboardStudentAttendanceReportList = (body, callBack) => {
  return async dispatch => {
    try {
      dispatch(saveDashboardStudentAttendanceReportListInStore([]))
      const response = await Client.get(
        URL.GET_DASHBOARD_TEACHER_ATTENDANCE_REPORT,
        null,
        true
      )
      // let response = {
      //     classAttendanceDetail: {
      //       absentCount: "40",
      //       presentCount: "100",
      //       unmarkedCount: "5",
      //       absentPercentage: "40",
      //       presentPercentage: "100",
      //       unmarkPercentage: "5",
      //     },
      // }
      if (response) {
        myLog(
          "====getDashboardStudentAttendanceReportList RESPONSE===::::",
          response
        )
        dispatch(
          saveDashboardStudentAttendanceReportListInStore(
            response.todaysAttendance
          )
        )
        callBack(true, response.todaysAttendance)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getDashboardStudentAttendanceReportList ERROR===????", error)
    }
  }
}

export const getTeacherClassInfoDetails = callBack => {
  return async dispatch => {
    try {
      // let response = {
      //   ClassDetails: {
      //     students: 45,
      //     boys: 25,
      //     girls: 20,
      //     teachers: 7,
      //     male: 2,
      //     female: 5,
      //   }
      // }
      const response = await Client.get(
        URL.GET_DASHBOARD_TEACHER_CLASS_INFO,
        null,
        true
      )
      if (response) {
        myLog("====getTeacherClassInfoDetails RESPONSE===::::", response)
        dispatch(saveTeacherClassDetailsInStore(response.memberCounts))
        callBack(true, response.memberCounts)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getTeacherClassInfoDetails ERROR===????", error)
    }
  }
}

export const getTeacherDashboardTopPerformingStudentsDetails = callBack => {
  return async dispatch => {
    try {
      // let response = {
      //   topPerformingStudents: [
      //     {
      //       studentName: 'Jessie packer',
      //       className: '8-A',
      //       studentAssessmentMark: '485',
      //       totalAssessmentMark: '500',
      //       percentage: '72',
      //     }
      //   ]
      // }
      const response = await Client.get(
        URL.GET_DASHBOARD_TEACHER_PERFORMERS,
        null,
        true
      )
      if (response) {
        myLog(
          "====getTeacherDashboardTopPerformingStudentsDetails RESPONSE===::::",
          response
        )
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog(
        "====getTeacherDashboardTopPerformingStudentsDetails ERROR===????",
        error
      )
    }
  }
}

// export const getTeacherDashboardAttentionNeededStudentsDetails = (callBack) => {
//   return async (dispatch) => {
//       try {
//         let response = {
//           earlyWarning: {
//             studentName: 'Vijayakumar',
//             photo:'',
//             attendance: '70',
//             academic: '70',
//             behaviour: '70',
//           },
//           attentionNeededStudents: [
//             {
//               studentName: 'Jessie',
//               className: '8-A',
//               percentage: '45%',
//             },
//             {
//               studentName: 'Otta',
//               className: '8-A',
//               percentage: '45%',
//             }
//           ]
//         }
//       // const response = await Client.get(URL.GET_TEACHERS_STUDENT_ATTENDANCE_REPORT_LIST, body, true)
//       if (response) {
//           myLog("====getTeacherDashboardAttentionNeededStudentsDetails RESPONSE===::::", response)
//           callBack(true, response)
//       } else {
//           callBack(false)
//       }
//       } catch (error) {
//       callBack(false)
//       myLog("====getTeacherDashboardAttentionNeededStudentsDetails ERROR===????", error)
//       }
//   }
// }

export const getTeacherDashboardClassAllSectionsPerformanceDetails = callBack => {
  return async dispatch => {
    try {
      // let response = {
      //   sections:
      //     {
      //       labels: ["SECTION A", "SECTION B", "SECTION C"],
      //       data: [5, 2, 8],
      //       backgroundColor: ["#ea5a58", "#13af8f", "#64c2e9"],
      //     }
      // }
      const response = await Client.get(
        URL.GET_DASHBOARD_TEACHER_SECTION_WISE_AVERAGE,
        null,
        true
      )
      if (response) {
        myLog(
          "====getTeacherDashboardClassAllSectionsPerformanceDetails RESPONSE===::::",
          response
        )
        callBack(true, response.sectionsAverage)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog(
        "====getTeacherDashboardClassAllSectionsPerformanceDetails ERROR===????",
        error
      )
    }
  }
}

export const getTeacherDashboardClassPerformanceByAssessmentDetails = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_DASHBOARD_TEACHER_CLASS_PERFORMANCE_BY_ASSESSMENT,
        null,
        true
      )
      if (response) {
        myLog(
          "====getTeacherDashboardClassPerformanceByAssessmentDetails RESPONSE===::::",
          response
        )
        const newRes = {
          marks: [],
          percentages: [],
          assessments: [...response.assessments].sort((a, b) =>
            a.localeCompare(b, undefined, { numeric: true })
          ),
        }

        newRes.assessments.forEach(a => {
          let i = response.assessments.findIndex(ra => ra === a)
          newRes.percentages.push(response.percentages[i])
          newRes.marks.push(response.marks[i])
        })

        myLog("sorted", newRes)
        callBack(true, newRes)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog(
        "====getTeacherDashboardClassPerformanceByAssessmentDetails ERROR===????",
        error
      )
    }
  }
}

//Teacher Profile Get
export const getTeacherProfile = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_TEACHER_PROFILE, null, true)
      if (response) {
        myLog("====getTeacherProfile RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getTeacherProfile ERROR===????", error)
    }
  }
}

// Teacher Profile Update
export const updateTeacherProfile = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.post(URL.UPDATE_TEACHER_PROFILE, body, true)
      if (response.responseCode === 0) {
        myLog("====updateTeacherProfile RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      myLog("====updateTeacherProfile ERROR===????", error)
      dispatch(showNotification(DANGER_TOAST, VALIDATION_MSG.OOPS, "ERROR"))
      callBack(false)
    }
  }
}

// Teacher qualification delete
export const deleteTeacherQualification = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.DELETE_TEACHER_QUALIFICATION,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteTeacherQualification RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      myLog("====deleteTeacherQualification ERROR===????", error)
      dispatch(showNotification(DANGER_TOAST, VALIDATION_MSG.OOPS, "ERROR"))
      callBack(false)
    }
  }
}

// Teacher skill delete
export const deleteTeacherSkill = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(URL.DELETE_TEACHER_SKILL, body, true)
      if (response.responseCode === 0) {
        myLog("====deleteTeacherSkill RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      myLog("====deleteTeacherSkill ERROR===????", error)
      dispatch(showNotification(DANGER_TOAST, VALIDATION_MSG.OOPS, "ERROR"))
      callBack(false)
    }
  }
}

export const getTeacherDashboardEarlyWarningDetails = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_DASHBOARD_TEACHER_EARLY_WARNING,
        null,
        true
      )
      if (response) {
        myLog(
          "====getTeacherDashboardEarlyWarningDetails RESPONSE===::::",
          response
        )
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getTeacherDashboardEarlyWarningDetails ERROR===????", error)
    }
  }
}

export const getNotificationList = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_TEACHER_NOTIFICATIONS,
        null,
        true,
        true
      )
      if (response) {
        myLog("====getNotificationList RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getNotificationList ERROR===????", error)
    }
  }
}

export const clearNotification = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_TEACHER_NOTIFICATIONS + "/clearAll",
        null,
        true,
        true
      )
      if (response) {
        myLog("====clearNotification RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====clearNotification ERROR===????", error)
    }
  }
}

export const updateTeacherMarkAsReadAssignment = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.UPDATE_TEACHER_MARK_AS_READ_ASSIGNMENT,
        body,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====updateTeacherMarkAsReadAssignment RESPONSE===::::", response)
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====updateTeacherMarkAsReadAssignment ERROR===????", error)
    }
  }
}
