/*************************************************
 * RedInk
 * @exports
 * @class subjectDetailsReducer.js
 * @extends Component
 * @author Vijayakumar // on 18/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from "../../actions/types"
import { myLog } from "../../utils/Utility"

const INTIAL_STATE = {
  teacherList: [],
  teacherDetail: [],
}

export const teacherDetailsReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.TEACHER_LIST:
      myLog("TEACHER_LIST", action.payload)
      return {
        ...state,
        teacherList: [...action.payload],
      }
    case ACTION_TYPES.TEACHER_DETAIL:
      myLog("payload", action.payload)
      return {
        ...state,
        teacherDetail: [...action.payload],
      }
    default:
      return state
  }
}
