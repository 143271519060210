/*************************************************
 * RedInk
 * @exports
 * @class Dashboard.js
 * @extends Component
 * @author Sasidharan // on 11/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { HashLoader } from "react-spinners"
import { confirmAlertPopUp } from "../../../utils/Utility"
import { VALIDATION_MSG } from "../../../utils/Constants"
import {
  getEventsDetails,
  deleteEventsDetails,
} from "../../../actions/admin/NewsEventsAction"
import { myLog } from "../../../utils/Utility"
import NoDataFound from "../../../assets/images/NoData.gif"
class EventScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showSideMenu: true,
      isNews: true,
      isEvents: false,
      isShowLoading: true,
      eventsList: [],
      eventsDetails: [],
    }
  }

  componentDidMount() {
    this.props.getEventsDetails((success, response) => {
      if (success) {
        myLog(response)
        this.setState({ eventsDetails: response, isShowLoading: false })
      } else {
        this.setState({
          isShowLoading: false,
        })
      }
    })
  }

  /**
   * handles on click message icon in sidebar
   */
  onClickMessageIcon = () => {
    this.setState({ isMessageModalOpen: !this.state.isMessageModalOpen })
  }

  onClickCreateNewAndModalIcon = () => {
    this.setState({
      isCreateEventModalOpen: false,
      isCreateNewsModalOpen: false,
      isCreateTasksModalOpen: false,
    })
  }

  /**
   * Handles message modal
   */
  closeMessageModal = () => this.setState({ isMessageModalOpen: false })

  /**
   * handles message submit
   */
  onSubmitMessage = () => {
    this.closeMessageModal()
  }

  // Date Conversion
  dataConvert = data => {
    const events = data.reduce((events, event) => {
      const date = event.date
      if (!events[date]) {
        events[date] = []
      }
      events[date].push(event)
      return events
    }, {})

    // Edit: to add it in the array format instead
    const eventArrays = Object.keys(events).map(date => {
      return {
        date,
        events: events[date],
      }
    })

    this.setstate.eventsList = eventArrays
  }

  monthName = date => {
    const isoDate = new Date(date)
    const mlist = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]
    return mlist[isoDate.getMonth()]
  }

  dayName = date => {
    const isoDate = new Date(date)
    const dayList = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
    return dayList[isoDate.getDay()]
  }

  onDeleteEvents = event => {
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT,
      yes => {
        if (yes) {
          this.props.deleteEventsDetails({ id: event.id }, () => {})
        }
      }
    )
  }

  render() {
    this.dataConvert(this.props.eventsDetails)
    const events = this.state.eventsList.map(event => {
      return (
        <div className="row d-flex align-content-between event-timeline no-gutters">
          <div className="col-sm-5 event-cards " id="day-1-tile">
            {event.events.map(event => {
              return (
                <div className="event-tile">
                  <h5> {event.title}</h5>
                  <h6>
                    {event.note.length < 40
                      ? event.note
                      : event.note.substring(0, 40) + "...."}
                  </h6>
                  <div className="d-flex justify-content-between">
                    <div>{`${event.timeFrom}-${event.timeTo}`}</div>
                    <span
                      type="button"
                      className="btn badge-pill py-0 text-light bg-danger"
                      onClick={() => this.onDeleteEvents(event)}
                      style={{ fontSize: "0.8vw" }}
                    >
                      Delete
                    </span>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="col-sm-2 timeline-date d-flex flex-column">
            <h1> {this.monthName(event.date)} </h1>
            <h3 className="text-dark">
              {" "}
              {event.date.split("-")[2]} {this.dayName(event.date)}
            </h3>
          </div>
        </div>
      )
    })

    return (
      <div>
        {this.state.isShowLoading ? (
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: "10%" }}
          >
            <HashLoader
              sizeUnit={"px"}
              size={35}
              color={"#084353"}
              loading={this.state.isShowLoading}
            />
          </div>
        ) : (
          <div className="card mb-3">
            <div className="container px-5 my-3" style={{}}>
              <div className="row float-right mb-3">
                <button
                  data-toggle="modal"
                  data-target="#eventsModal"
                  className="btn btn-sm btn-light pl-0 mt-3 mr-5 px-4 rounded-pill mr-3 box-shadow-none "
                >
                  <i className="fa fa-plus mr-2" />
                  Add Event
                </button>
              </div>
              {this.state.eventsList.length > 0 ? (
                <div
                  className="container-sm mb-0 p-3 my-3 scroll-false"
                  style={{
                    height: "72vh",
                  }}
                >
                  <div className="timeline">
                    {this.state.eventsList.length > 0 ? (
                      events
                    ) : (
                      <div> No data found </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="text-center" style={{ marginTop: 50 }}>
                  <div style={{ marginTop: 20 }}>
                    <b style={{ color: "#084353", fontSize: 24 }}>
                      No Data Found
                    </b>
                  </div>
                  <div className="d-flex justify-content-center">
                    <img
                      src={NoDataFound}
                      alt="logo"
                      width={400}
                      height={400}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    eventsDetails: state.adminNewsEventsState.eventsList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getEventsDetails,
      deleteEventsDetails,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(EventScreen)
