/*************************************************
 * RedInk
 * @exports
 * @class ForgotPassword.js
 * @extends Component
 * @author Sasidharan // on 07/11/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component, createRef } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"

import { logInUser, forgetPassword } from "../../actions/LoginAction"
import { showNotification } from "../../actions/CommonAction"
import { URL_PATH } from "../../config/urlPath"
import AuthScreen from "./AuthScreen"

class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    document.title = "Forgot password | Redink"
    this.emailRef = createRef()
    this.btnRef = createRef()
    this.state = {
      emailid: "",
      isLoading: false,
      isValidation: false,
      isShowForgetPassword: false,
    }
  }

  componentDidMount() {
    this.focusEmail()
  }

  focusEmail() {
    this.emailRef.current.focus()
  }

  focusBtn() {
    this.btnRef.current.focus()
  }

  onClicked = () => {
    if (this.state.emailid === "") {
      this.setState({ isValidation: true, showLoginFailed: false })
    } else {
      this.setState({ isLoading: true, showLoginFailed: false })
      const params = {
        email: this.state.emailid,
        host: window.location.host,
      }
      this.props.forgetPassword(params, (success, response) => {
        this.setState({ isLoading: false, emailid: "" })
      })
    }
  }

  render() {
    const { isValidation, emailid } = this.state
    return (
      <AuthScreen>
        <div className="p-5 d-flex login bg-theme-light text-white w-100 justify-content-center">
          <div className="align-self-center">
            <Link
              to={URL_PATH.LOGIN}
              className="d-block d-sm-none text-center mb-2"
            >
              {/* <img src={logo} alt="" /> */}
            </Link>
            <div>
              <h4 className="text-left">Forget Password</h4>
              <small>
                To reset your password, enter the username you use to sign in.
              </small>
              <div className="form-group mt-4">
                <label>Email Id / Username</label>
                <input
                  ref={this.emailRef}
                  type="text"
                  className={`form-control ${
                    isValidation && emailid === "" ? "is-invalid" : ""
                  }`}
                  value={this.state.emailid}
                  onChange={e =>
                    this.setState({
                      emailid: e.target.value,
                      showLoginFailed: false,
                    })
                  }
                  placeholder="Username / Email"
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      this.focusBtn()
                    }
                  }}
                />
                <div className="invalid-feedback">
                  Please enter a valid email/username.
                </div>
              </div>
              <div className="text-right">
                <small>
                  <Link to={URL_PATH.LOGIN}>Take me back to login</Link>
                </small>
              </div>
              <button
                className="login-form-btn btn w-100 mt-3"
                onClick={() => this.onClicked()}
                disabled={this.state.isLoading}
                ref={this.btnRef}
              >
                {this.state.isLoading ? "Sending link..." : "Get reset link"}
              </button>
            </div>
          </div>
        </div>
      </AuthScreen>
    )
  }
}

export default connect(null, { logInUser, showNotification, forgetPassword })(
  ForgotPassword
)
