/*************************************************
 * RedInk
 * @exports
 * @class communicationDetailsReducer.js
 * @extends Component
 * @author Vijayakumar // on 18/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from "../../actions/types"
import { myLog } from "../../utils/Utility"

const INTIAL_STATE = {
  communicationList: [],
  communicationDetail: {},
  subjectList: [],
  classesList: [],
  teachersList: [],
  studentsList: [],
  openMessageModal: false,
}

export const communicationDetailsReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.COMMUNICATION_LIST:
      myLog("COMMUNICATION_LIST", action.payload)
      return {
        ...state,
        communicationList: [...action.payload],
      }
    case ACTION_TYPES.COMMUNICATION_SUBJECT_LIST:
      myLog("COMMUNICATION_SUBJECT_LIST", action.payload)
      return {
        ...state,
        subjectList: [...action.payload],
      }
    case ACTION_TYPES.COMMUNICATION_CLASS_LIST:
      myLog("COMMUNICATION_CLASS_LIST", action.payload)
      return {
        ...state,
        classesList: [...action.payload],
      }
    case ACTION_TYPES.COMMUNICATION_TEACHER_LIST:
      myLog("COMMUNICATION_TEACHER_LIST", action.payload)
      return {
        ...state,
        teachersList: [...action.payload],
      }
    case ACTION_TYPES.OPEN_MESSAGE_MODAL:
      myLog("COMMUNICATION_TEACHER_LIST", action.payload)
      return {
        ...state,
        openMessageModal: action.payload,
      }
    case ACTION_TYPES.COMMUNICATION_STUDENT_LIST:
      myLog("COMMUNICATION_STUDENT_LIST", action.payload)
      return {
        ...state,
        studentsList: [...action.payload],
      }
    default:
      return state
  }
}
