import React from "react"
import { bindActionCreators } from "redux"
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import Pagination from "@material-ui/lab/Pagination"
import Skeleton from "react-loading-skeleton"

import {
  Input,
  Label,
  Modal,
  Button,
  Collapse,
  FormGroup,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"

import {
  getChapters,
  saveQuizAssigns,
  getClassStudents,
  getQuizQuestionBank,
  saveQuizQuestionBank,
  deleteQuizQuestionBank,
  getTeacherAssignedDetails,
} from "../../../actions/teacher/QuizAction"
import { showNotification } from "../../../actions/CommonAction"
import {
  DANGER_TOAST,
  SUCCESS_TOAST,
  VALIDATION_MSG,
} from "../../../utils/Constants"

import LoadingButton from "../../common/LoadingButton"
import AppWrapper from "../Wrapper"

const questionTypes = [
  { value: "TRUE_OR_FALSE", label: "True or false" },
  { value: "MULTIPLE_CHOICE", label: "Multiple choice" },
  { value: "MULTIPLE_ANSWER", label: "Multiple answer" },
  { value: "SHORT_ANSWER", label: "Short answer" },
  { value: "LONG_ANSWER", label: "Long answer" },
  { value: "MATCHING", label: "Matching" },
  { value: "FILL_IN_THE_BLANKS", label: "Fill in the blanks" },
  { value: "SEQUENCING", label: "Sequencing" },
]

const defaultQuestion = {
  type: "",
  quizTopicId: "",
  text: "",
  questions: [""],
  answers: [""],
  distractorAnswers: [],
  mark: 2,
  difficulty: "EASY",
  attachment: null,
  sectionId: "",
  subjectId: "",
  subjectChapterId: "",
  subjectTopicId: "",
}

const style = {
  width: 220,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}

function Loader({ opacity }) {
  return (
    <div className="d-flex flex-column" style={{ width: "100%", opacity }}>
      <div className="font-weight-bold d-flex hover-parent rounded py-2 px-4 my-1">
        <div className="col-3">
          <div style={style}>
            <Skeleton />
          </div>
        </div>

        <div className="col"></div>

        {[0, 1, 2, 3, 4].map(i => (
          <div key={i} className="text-muted col">
            <Skeleton width={60} />
          </div>
        ))}
      </div>

      <div className="col"></div>
    </div>
  )
}

class QuestionBank extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      classesList: [],
      questionsList: [],
      selectedClass: "",
      subjectsPerSection: {},
      subjectsList: [],
      selectedSubject: "",
      lessonsList: [],
      selectedLesson: "",
      topicsPerLesson: {},
      topicsList: [],
      selectedTopic: "",
      questionTypes: questionTypes,
      selectedQuestionType: "",
      difficultyLevels: [
        { value: "EASY", label: "Easy" },
        { value: "MEDIUM", label: "Medium" },
        { value: "DIFFICULT", label: "Difficult" },
      ],
      selectedDiffcultyLevel: "",
      tabs: [
        { title: "My Question Bank", key: "my" },
        { title: "Default Question Bank", key: "default" },
      ],
      selectedTab: "my",

      quesubjectsList: [],
      quelessonsList: [],
      quetopicsPerLesson: {},
      quetopicsList: [],

      deletingIndex: null,
      isAddQuestionModalOpen: false,
      savingQuestion: "",
      newQuestion: defaultQuestion,
      currentPage: 1,
      nextPage: null,
      prevPage: null,
      limit: 25,
      isLoadedFromCreateAssessement: false,
      selectedQuestions: {},
      currentNo: 1,
      totalRecords: "",
      perPage: [10, 15, 20, 25, 30],
      perPageNo: "",
      quizData: {},
      quizAssignInfoId: "",
      sectionId: "",
      mark: 1,
    }
  }

  sortClassOrder = classess => {
    const classOrder = [
      "-",
      "JKG",
      "LKG",
      "SKG",
      "UKG",
      ...Array(12)
        .fill()
        .map((_, idx) => `${1 + idx}`),
    ]
    const classes = classess
    classes.sort((a, b) => {
      const aClass = a.className || "-"
      const aSection = a.sectionName || "-"
      const bClass = b.className || "-"
      const bSection = b.sectionName || "-"
      if (aClass === bClass) return aSection < bSection ? -1 : 1
      else return classOrder.indexOf(aClass) - classOrder.indexOf(bClass)
    })
    return classes
  }

  componentDidMount = () => {
    const { defaultSelection } = this.props
    if (defaultSelection) {
      this.setState({ isLoadedFromCreateAssessement: true })
    }
    const { sectionId, subjectId, defaultSubjectChapters } =
      defaultSelection || {}

    if (defaultSubjectChapters) {
      this.setState(
        {
          lessonsList: defaultSubjectChapters,
          selectedLesson:
            defaultSubjectChapters.length > 0
              ? defaultSubjectChapters[0].id
              : "",
        },
        () => {
          if (defaultSubjectChapters.length > 0) {
            this.props.getChapters(
              { chapterId: defaultSubjectChapters[0].id },
              (success, response) => {
                if (success) {
                  const topicsList = response.data.topicsList.map(item => ({
                    value: item.id,
                    label: item.name,
                  }))
                  this.setState({ topicsList })
                }
              }
            )
          }
        }
      )
    }

    this.props.getTeacherAssignedDetails({}, (success, data) => {
      if (success) {
        const subjectsPerSection = data.assignedSubject || {}
        let board = ""
        if (sectionId) {
          const findQuizAssignedSection = data.sections.find(item => {
            return item.sectionId === sectionId
          })
          board = findQuizAssignedSection ? findQuizAssignedSection.board : ""
        }
        this.setState(
          {
            selectedBoard: board,
            selectedClass: sectionId || "",
            selectedSubject: subjectId || "",
            classesList: data.sections
              ? this.sortClassOrder(data.sections).map(item => ({
                  value: item.sectionId,
                  label: `${item.className} ${item.sectionName}`,
                  className: item.className,
                  board: item.school.board,
                }))
              : [],
            subjectsPerSection,
          },
          () => {
            if (this.state.classesList.length > 0) {
              const subjects =
                subjectsPerSection &&
                subjectsPerSection[this.state.classesList[0].value]
                  ? subjectsPerSection[this.state.classesList[0].value]
                  : ""
              const subjectsList = subjects
                ? subjects.map(item => ({
                    value: item.subjectId,
                    label: item.subjectName,
                    masterSubjectId: item.masterSubjectId,
                  }))
                : ""
              this.setState({
                selectedClass: this.state.classesList[0].value,
                subjectsList: subjectsList ? subjectsList : [],
                selectedSubject: subjectsList ? subjectsList[0].value : "",
                selectedMasterSubjectId: subjectsList
                  ? subjectsList[0].masterSubjectId
                  : "",
              })
            }
            this.loadQuestionBank()
          }
        )
        if (sectionId && subjectId) {
          this.setState({
            isLoadingModalData: false,
            lessonsList: defaultSubjectChapters,
          })
        }
      }
    })
    setTimeout(() => {
      this.filterDetails()
    }, 1500)
  }

  filterDetails = () => {
    const classValue = this.state.selectedClass
    let findClass = this.state.classesList.find(item => {
      return item.value === classValue
    })

    const subjectsList = classValue
      ? this.state.subjectsPerSection[classValue] || []
      : []
    let findSubject = subjectsList.find(item => {
      return item.subjectId === this.state.selectedSubject
    })
    this.setState({
      selectedClassName: findClass ? findClass.className : "",
      selectedBoard: findClass ? findClass.board : "",
    })
    this.props.getChapters(
      {
        subjectId: findSubject ? findSubject.masterSubjectId : "",
        sectionId: findClass ? findClass.className : "",
        board: findClass ? findClass.board : "",
      },
      (success, response) => {
        if (success) {
          const lessonsList = response.data.chaptersList.map(item => ({
            value: item.id,
            label: item.name,
          }))
          const topicsPerLesson = {}
          response.data.chaptersList.forEach(
            item => (topicsPerLesson[item.id] = item.subjectTopics)
          )
          this.setState({ lessonsList, topicsPerLesson })
        }
      }
    )
  }

  paginateMaterial = (event, value) => {
    this.setState({ currentPage: value }, () => {
      this.loadQuestionBank()
    })
  }

  loadQuestionBank = () => {
    this.setState({ isLoading: true })
    let data = {
      list: this.state.selectedTab,
      page: this.state.currentPage,
      limit: this.state.limit,
    }
    if (this.state.selectedClass) data.sectionId = this.state.selectedClass
    if (this.state.selectedMasterSubjectId)
      data.subjectId = this.state.selectedMasterSubjectId
    if (this.state.selectedLesson) {
      data.subjectChapterId = this.state.selectedLesson
    } else {
      data.subjectChapterId = ""
    }
    if (this.state.selectedTopic) data.subjectTopicId = this.state.selectedTopic
    if (this.state.selectedDiffcultyLevel)
      data.difficulty = this.state.selectedDiffcultyLevel
    if (this.state.selectedQuestionType)
      data.questionType = this.state.selectedQuestionType

    this.props.getQuizQuestionBank(data, (success, response) => {
      const indexOfLastPost = this.state.currentNo * this.state.limit
      const indexOfFirstPost = indexOfLastPost - this.state.limit
      if (success) {
        this.setState({
          isLoading: false,
          totalRecords: response.pagination.total,
          questionsList: response.data.slice(indexOfFirstPost, indexOfLastPost),
          nextPage: response.pagination.nextPage,
          prevPage:
            this.state.currentPage - 1 >= 0 ? this.state.currentPage - 1 : null,
        })
      }
    })
  }

  showErrorNoti = message => {
    this.props.showNotification(DANGER_TOAST, VALIDATION_MSG.OOPS, message)
  }

  loadQueInModal = (que, action) => {
    const {
      id,
      type,
      text,
      questions,
      answers,
      mark,
      difficulty,
      attachment,
      subjectTopicId,
      subjectChapterId,
      teacherAssignedDetail: {
        section: { sectionId },
        subject: { subjectId },
      },
    } = que
    const subjectsListInQue = sectionId
      ? this.state.subjectsPerSection[sectionId] || []
      : []

    let findSubject = subjectsListInQue.find(
      item => item.subjectId === subjectId
    )

    let item = {
      type,
      text,
      questions,
      answers,
      mark: `${mark}`,
      difficulty,
      attachment,
      sectionId: sectionId,
      subjectId: subjectId,
      masterSubjectId: findSubject ? findSubject.masterSubjectId : "",
      subjectChapterId,
      subjectTopicId,
    }

    const isDublicate = action === "dublicate"
    this.setState({
      isAddQuestionModalOpen: true,
      newQuestion: isDublicate ? { ...item } : { ...item, id },
      modalTitle: isDublicate && "Create Duplicate",
      isLoadingModalData: true,
    })

    const subjectsList = sectionId
      ? this.state.subjectsPerSection[sectionId] || []
      : []

    this.props.getChapters(
      {
        subjectId: item.masterSubjectId,
        sectionId: this.state.selectedClassName,
        board: this.state.selectedBoard,
      },
      (success, response) => {
        if (success) {
          const quelessonsList = response.data.chaptersList.map(item => ({
            value: item.id,
            label: item.name,
          }))
          this.setState({ quelessonsList })
        }
      }
    )

    const quelessonsList = [que.subjectChapter]
    const quetopicsPerLesson = {}
    quelessonsList.forEach(
      item => (quetopicsPerLesson[item.id] = item.subjectTopics)
    )

    this.setState({
      isLoadingModalData: false,
      quelessonsList,
      quetopicsPerLesson,
      quesubjectsList: subjectsList.map(item => ({
        value: item.subjectId,
        label: item.subjectName,
        masterSubjectId: item.masterSubjectId,
      })),
      quetopicsList: [que.subjectTopic],
      selectedBoard: que.board,
    })
  }

  saveQuizQuestion = async (val, cb) => {
    this.setState({ savingQuestion: val })
    let question = this.state.newQuestion
    let error = ""
    if (this.state.quelessonsList.length <= 0) {
      error += `Please contact admin for Chapters`
    }
    if (!question.subjectChapterId) {
      error = `Please select Chapter\n`
    }
    if (!question.text) {
      error = `Please add question text\n`
    }
    if (!question.difficulty) {
      error = `Please set difficulty\n`
    }
    if (
      (question.type === "MULTIPLE_CHOICE" ||
        question.type === "MULTIPLE_ANSWER") &&
      question.questions.length < 1
    ) {
      error += `Please add atleast two options\n`
    }

    if (
      (question.type === "MULTIPLE_CHOICE" ||
        question.type === "MULTIPLE_ANSWER") &&
      question.answers.length === 0
    ) {
      error += `Please add answer\n`
    }
    if (error) {
      this.showErrorNoti(error)
      this.setState({ savingQuestion: "" })
      return
    }

    if (
      question.type === "FILL_IN_THE_BLANKS" ||
      question.type === "TRUE_OR_FALSE"
    ) {
      question.questions = [question.text]
    }
    question.mark = question.mark.toString()
    question.subjectId = question.masterSubjectId

    const data = { ...question }
    this.props.saveQuizQuestionBank(data, (success, response) => {
      if (success) {
        this.props.showNotification(
          SUCCESS_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Saved question successfully"
        )
        this.setState({ savingQuestion: "" })
        this.loadQuestionBank()
        if (cb) cb()
      } else {
        this.showErrorNoti(
          "Please Contact the admin as there are no Chapters and Topics mapped"
        )
      }
    })
  }

  renderAddQuestionModal = () => {
    const que = this.state.newQuestion
    return (
      <Modal
        isOpen={this.state.isAddQuestionModalOpen}
        backdropClassName="modal-backdrop-show"
        modalClassName="modal-dialog-shadow-none"
        contentClassName="rounded-lg p-4 border-0"
        size="lg"
        centered={true}
      >
        <div className="d-flex align-items-center mb-4">
          <h5 className="text-brand font-weight-bold mb-0">
            {this.state.modalTitle
              ? this.state.modalTitle
              : que.id
              ? "Edit Question"
              : "Add Question"}
          </h5>

          <i
            className="fas fa-times p-1 text-muted cursor-pointer ml-auto"
            onClick={() =>
              this.setState({
                modalTitle: "",
                isAddQuestionModalOpen: false,
                newQuestion: defaultQuestion,
                mark: 1,
              })
            }
          />
        </div>

        <div>
          <div className="d-flex mx-0 mb-3">
            <select
              className="custom-select mr-2"
              style={{ borderRadius: 8 }}
              value={que.sectionId}
              onChange={e => {
                const value = e.target.value
                const subjectsList = value
                  ? this.state.subjectsPerSection[value] || []
                  : []
                let findClass = this.state.classesList.find(item => {
                  return item.value === e.target.value
                })

                this.setState({
                  selectedBoard: findClass ? findClass.board : "",
                  selectedClassName: findClass ? findClass.className : "",
                  newQuestion: {
                    ...que,
                    sectionId: value,
                    subjectId: "",
                    subjectChapterId: "",
                    subjectTopicId: "",
                    lessonsList: [],
                    topicsList: [],
                  },
                  quesubjectsList: subjectsList.map(item => ({
                    value: item.subjectId,
                    label: item.subjectName,
                    masterSubjectId: item.masterSubjectId,
                  })),
                })
              }}
            >
              <option value="">Select Class</option>
              {this.state.classesList &&
                this.state.classesList.map(option => (
                  <option value={option.value}>{option.label}</option>
                ))}
            </select>

            <select
              className="custom-select mr-2"
              style={{ borderRadius: 8 }}
              value={que.subjectId}
              disabled={!que.sectionId}
              onChange={e => {
                const value = e.target.value
                let findSubject = this.state.quesubjectsList.find(item => {
                  return item.value === e.target.value
                })
                this.setState({
                  newQuestion: {
                    ...que,
                    subjectId: value,
                    masterSubjectId: findSubject
                      ? findSubject.masterSubjectId
                      : "",
                    subjectChapterId: "",
                    subjectTopicId: "",
                  },
                })

                this.props.getChapters(
                  {
                    subjectId: findSubject ? findSubject.masterSubjectId : "",
                    sectionId: this.state.selectedClassName,
                    board: this.state.selectedBoard,
                  },
                  (success, response) => {
                    if (success) {
                      const quelessonsList = response.data.chaptersList.map(
                        item => ({
                          value: item.id,
                          label: item.name,
                        })
                      )
                      const quetopicsPerLesson = {}
                      response.data.chaptersList.forEach(
                        item => (quetopicsPerLesson[item.id] = item.topicsList)
                      )
                      this.setState({ quelessonsList, quetopicsPerLesson })
                    }
                  }
                )
              }}
            >
              <option value="">Select Subject</option>
              {this.state.quesubjectsList &&
                this.state.quesubjectsList.map(option => (
                  <option value={option.id ? option.id : option.value}>
                    {option.name ? option.name : option.label}
                  </option>
                ))}
            </select>

            <select
              className="custom-select mr-2"
              style={{ borderRadius: 8 }}
              value={que.subjectChapterId}
              disabled={!que.subjectId}
              onChange={e => {
                const value = e.target.value
                const topicsList = value
                  ? this.state.quetopicsPerLesson[value] || []
                  : []
                this.setState({
                  newQuestion: {
                    ...que,
                    subjectChapterId: value,
                    subjectTopicId: "",
                  },
                  quetopicsList: topicsList.map(item => ({
                    value: item.id,
                    label: item.name,
                  })),
                })
                this.props.getChapters(
                  {
                    chapterId: value,
                  },
                  (success, response) => {
                    if (success) {
                      const quetopicsList = response.data.topicsList.map(
                        item => ({
                          value: item.id,
                          label: item.name,
                        })
                      )
                      this.setState({ quetopicsList })
                    }
                  }
                )
              }}
            >
              <option value="">Select Chapter</option>
              {this.state.quelessonsList &&
                this.state.quelessonsList.map(option => (
                  <option value={option.id ? option.id : option.value}>
                    {option.name ? option.name : option.label}
                  </option>
                ))}
            </select>

            <select
              className="custom-select mr-2"
              style={{ borderRadius: 8 }}
              value={que.subjectTopicId}
              disabled={!que.subjectChapterId}
              onChange={e => {
                const value = e.target.value
                let newQuestion = que
                newQuestion.subjectTopicId = value
                this.setState({ newQuestion })
              }}
            >
              <option value="">Select Topic</option>
              {this.state.quetopicsList &&
                this.state.quetopicsList.map(option => (
                  <option value={option.id ? option.id : option.value}>
                    {option.name ? option.name : option.label}
                  </option>
                ))}
            </select>

            <select
              className="custom-select mr-2"
              style={{ borderRadius: 8 }}
              value={que.type}
              onChange={e => {
                const value = e.target.value
                let newQuestion = que
                newQuestion.type = value
                value === "SHORT_ANSWER" && this.setState({ mark: 3 })
                value === "LONG_ANSWER" && this.setState({ mark: 5 })
                value === "MATCHING" && this.setState({ mark: 1 })
                if (value === "SHORT_ANSWER" || value === "LONG_ANSWER") {
                  newQuestion.questions = []
                  newQuestion.answers = []
                } else if (value === "TRUE_OR_FALSE") {
                  newQuestion.questions = [""]
                  newQuestion.answers = ["true"]
                  this.setState({ mark: 1 })
                } else if (value === "SEQUENCING") {
                  newQuestion.questions = ["1"]
                  newQuestion.answers = [""]
                  this.setState({ mark: 1 })
                } else if (value === "FILL_IN_THE_BLANKS") {
                  newQuestion.questions = [""]
                  newQuestion.answers = [""]
                  this.setState({ mark: 1 })
                } else if (
                  value === "MULTIPLE_CHOICE" ||
                  value === "MULTIPLE_ANSWER"
                ) {
                  newQuestion.questions = [""]
                  newQuestion.answers = []
                  this.setState({ mark: 1 })
                }
                this.setState({ newQuestion })
              }}
            >
              <option value="">Select Type</option>
              {questionTypes.map(queType => (
                <option
                  key={queType.value}
                  value={queType.value}
                  label={queType.label}
                />
              ))}
            </select>
          </div>

          {que.type === "FILL_IN_THE_BLANKS" && (
            <FormGroup className="mb-1">
              <div className="row align-items-start justify-content-start">
                <div className="col-2"></div>
                <div className="col-8">
                  <span style={{ color: "#abadae" }}>
                    Use 4 underscores `____` for mentioning blanks.
                  </span>
                </div>
              </div>
            </FormGroup>
          )}
          <FormGroup>
            <div className="row align-items-start justify-content-start">
              <div className="col-2">
                <Label
                  htmlFor="questionType"
                  className="m-0 mt-2 font-weight-bold text-dark"
                >
                  Question
                </Label>
              </div>
              <div className="col-8" style={{ position: "relative" }}>
                <textarea
                  value={que.text}
                  onChange={e => {
                    const value = e.target.value
                    let newQuestion = que
                    newQuestion.text = value
                    this.setState({ newQuestion })
                  }}
                  rows={5}
                  placeholder="Enter your Question here"
                  id="quizInstructions"
                  name="quizInstructions"
                  className="bg-white"
                  style={{
                    padding: "0.375rem 0.75rem",
                    border: "1px solid #ced4da",
                    width: "100%",
                    borderRadius: 5,
                  }}
                />

                {que.attachment && (
                  <div className="rounded border border-muted d-flex flex-column mt-3">
                    <Document
                      file={
                        que.attachment.lastModified
                          ? que.attachment
                          : que.attachment.pdfURL
                      }
                      onLoadSuccess={pdfPageNo => this.setState({ pdfPageNo })}
                      options={{}}
                    >
                      {Array.from(
                        new Array(this.state.pdfPageNo),
                        (el, index) => (
                          <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                          />
                        )
                      )}
                    </Document>
                    <div className="px-3 py-2 mt-1 rounded-lg d-flex align-items-center border-top border-muted">
                      <img
                        style={{
                          width: 20,
                          height: 20,
                          objectFit: "contain",
                          objectPosition: "center",
                        }}
                        src={require("../../../assets/images/file.png")}
                        alt=""
                      />
                      <p className="text-dark ml-3 mr-auto my-0">
                        {que.attachment.name}
                      </p>
                      <span className="text-black">
                        {this.bytesToSize(que.attachment.size)}
                      </span>
                      <UncontrolledDropdown direction="right">
                        <DropdownToggle
                          tag="i"
                          className="fas fa-ellipsis-v text-muted cursor-pointer p-2 mx-2"
                        ></DropdownToggle>
                        <DropdownMenu
                          tag="div"
                          className="rounded border-1 border-muted px-2 py-2"
                        >
                          <div className="d-flex align-items-center px-2 py-1 cursor-pointer">
                            <i className="fas fa-download text-muted" />
                            <span className="ml-2">Download</span>
                          </div>
                          <div
                            className="d-flex align-items-center px-2 py-1 cursor-pointer"
                            onClick={() => {
                              let newQuestion = que
                              newQuestion.attachment = ""
                              this.setState({ newQuestion })
                            }}
                          >
                            <i className="fas fa-times text-muted" />
                            <span className="ml-2">Remove</span>
                          </div>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </FormGroup>

          <div style={{ maxWidth: 700 }}>
            {/* True false */}
            {que.type === "TRUE_OR_FALSE" && (
              <>
                <div className="d-flex mb-2">
                  <div className="col-8">
                    <h6 className="text-dark ">Options</h6>
                  </div>
                  <div className="col-4 d-flex justify-content-center">
                    <h6 className="text-dark ">Correct Answer</h6>
                  </div>
                </div>

                <div
                  className="d-flex px-2 py-3 rounded mb-3"
                  style={{ border: "1px solid #e7e7e7" }}
                >
                  <div className="col-8">
                    <h5 className="text-dark m-0">True</h5>
                  </div>
                  <div className="col-4 d-flex align-items-center justify-content-center">
                    <Input
                      type="radio"
                      className="mb-1"
                      checked={que.answers[0] === "true"}
                      onChange={e => {
                        const checked = e.target.checked
                        if (checked) {
                          let newQuestion = que
                          newQuestion.answers[0] = "true"
                          this.setState({ newQuestion })
                        }
                      }}
                    />
                  </div>
                </div>
                <div
                  className="d-flex px-2 py-3 rounded mb-3"
                  style={{ border: "1px solid #e7e7e7" }}
                >
                  <div className="col-8">
                    <h5 className="text-dark m-0">False</h5>
                  </div>
                  <div className="col-4 d-flex align-items-center justify-content-center">
                    <Input
                      type="radio"
                      className="mb-1"
                      checked={que.answers[0] === "false"}
                      onChange={e => {
                        const checked = e.target.checked
                        if (checked) {
                          let newQuestion = que
                          newQuestion.answers[0] = "false"
                          this.setState({ newQuestion })
                        }
                      }}
                    />
                  </div>
                </div>
              </>
            )}

            {/* Multiple choice */}
            {que.type === "MULTIPLE_CHOICE" && (
              <>
                <div className="d-flex mb-2">
                  <div className="col-8">
                    <h5 className="text-dark ">Options</h5>
                  </div>
                  <div className="col-4 d-flex justify-content-center">
                    <h5 className="text-dark ">Correct Answer</h5>
                  </div>
                </div>
                {que.questions.map((ans, aIndex) => {
                  const isSelected = que.answers[0] === ans
                  return (
                    <div
                      key={aIndex}
                      className="d-flex px-2 py-1 rounded mb-3"
                      style={{ border: "1px solid #e7e7e7" }}
                    >
                      <div className="col-8">
                        <Input
                          value={ans}
                          onChange={e => {
                            let newQuestion = que
                            newQuestion.questions[aIndex] = e.target.value
                            if (isSelected) {
                              newQuestion.answers[0] = e.target.value
                            }
                            this.setState({ newQuestion })
                          }}
                          type="text"
                          name="option"
                          placeholder="Enter Answer"
                          className="bg-white border-0"
                        />
                      </div>
                      <div className="col-4 d-flex align-items-center justify-content-center">
                        <Input
                          type="radio"
                          className="mb-1 mr-3"
                          checked={ans && isSelected}
                          onChange={e => {
                            let newQuestion = que
                            if (e.target.checked) {
                              newQuestion.answers[0] = ans
                            }
                            this.setState({ newQuestion })
                          }}
                        />
                        <button
                          className="mb-0 ml-auto"
                          style={{
                            fontSize: "1.2em",
                            display:
                              que.questions.length === 1 ? "none" : "block",
                          }}
                          onClick={() => {
                            let newQuestion = que
                            newQuestion.questions = newQuestion.questions.filter(
                              qu => qu !== ans
                            )
                            this.setState({ newQuestion })
                          }}
                        >
                          &times;
                        </button>
                      </div>
                    </div>
                  )
                })}
                <div>
                  <button
                    className="btn btn-light text-muted px-2 py-1"
                    onClick={() => {
                      let newQuestion = que
                      newQuestion.questions.push("")
                      this.setState({ newQuestion })
                    }}
                  >
                    Add Option
                  </button>
                </div>
              </>
            )}
            {/* Multiple Answers Option */}
            {que.type === "MULTIPLE_ANSWER" && (
              <>
                <div className="d-flex mb-2">
                  <div className="col-8">
                    <h5 className="text-dark ">Options</h5>
                  </div>
                  <div className="col-4 d-flex justify-content-center">
                    <h5 className="text-dark ">Correct Answer</h5>
                  </div>
                </div>
                {que.questions.map((ans, aIndex) => {
                  const isSelected = que.answers.includes(ans)
                  return (
                    <div
                      key={aIndex}
                      className="d-flex px-2 py-1 rounded mb-3"
                      style={{ border: "1px solid #e7e7e7" }}
                    >
                      <div className="col-8">
                        <Input
                          value={ans}
                          onChange={e => {
                            let newQuestion = que
                            newQuestion.questions[aIndex] = e.target.value
                            if (isSelected) {
                              const ansIndex = que.answers.findIndex(
                                val => val === ans
                              )
                              newQuestion.answers[ansIndex] = e.target.value
                            }
                            this.setState({ newQuestion })
                          }}
                          type="text"
                          name="option"
                          placeholder="Enter Answer"
                          className="bg-white border-0"
                        />
                      </div>
                      <div className="col-4 d-flex align-items-center justify-content-center">
                        <Input
                          type="checkbox"
                          className="mb-1 mr-3"
                          checked={ans && isSelected}
                          onChange={e => {
                            let newQuestion = que
                            if (e.target.checked) {
                              newQuestion.answers.push(ans)
                            } else {
                              newQuestion.answers = newQuestion.answers.filter(
                                as => as !== ans
                              )
                            }
                            this.setState({ newQuestion })
                          }}
                        />
                        <button
                          className="mb-0 ml-auto"
                          style={{
                            fontSize: "1.2em",
                            display:
                              que.questions.length === 1 ? "none" : "block",
                          }}
                          onClick={() => {
                            let newQuestion = que
                            newQuestion.questions = newQuestion.questions.filter(
                              qu => qu !== ans
                            )
                            this.setState({ newQuestion })
                          }}
                        >
                          &times;
                        </button>
                      </div>
                    </div>
                  )
                })}
                <div>
                  <button
                    className="btn btn-light text-muted px-2 py-1"
                    onClick={() => {
                      let newQuestion = que
                      newQuestion.questions.push("")
                      this.setState({ newQuestion })
                    }}
                  >
                    Add Option
                  </button>
                </div>
              </>
            )}

            {que.type === "FILL_IN_THE_BLANKS" && (
              <>
                <div className="d-flex mb-2">
                  <div className="col-3 d-flex align-items-center">
                    <h5 className="text-dark ">Answer</h5>
                  </div>
                  <div className="col-9 d-flex align-items-center">
                    <Input
                      placeholder="Correct Answer"
                      value={que.answers[0]}
                      onChange={e => {
                        const value = e.target.value
                        let newQuestion = que
                        newQuestion.answers[0] = value
                        this.setState({ newQuestion })
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            {que.type === "SEQUENCING" && (
              <>
                <div className="d-flex mb-2">
                  <div className="col-4">
                    <h5 className="text-dark">Order</h5>
                  </div>
                  <div className="col-8 d-flex justify-content-center">
                    <h5 className="text-dark ">Answer</h5>
                  </div>
                </div>
                {que.questions.map((quest, aIndex) => {
                  return (
                    <div
                      key={aIndex}
                      className="d-flex px-2 py-1 rounded mb-3"
                      style={{ border: "1px solid #e7e7e7" }}
                    >
                      <div className="col-4 d-flex align-items-center">
                        <h6 className="text-dark mb-0">{quest}</h6>
                      </div>
                      <div className="col-8 d-flex align-items-center justify-content-center">
                        <Input
                          value={que.answers[aIndex]}
                          onChange={e => {
                            let newQuestion = que
                            newQuestion.answers[aIndex] = e.target.value
                            this.setState({ newQuestion })
                          }}
                          type="text"
                          name="option"
                          placeholder="Enter Answer"
                          className="bg-white border-0"
                        />
                        <button
                          className="mb-0 ml-auto"
                          style={{
                            fontSize: "1.2em",
                            display:
                              que.questions.length === 1 ? "none" : "block",
                          }}
                          onClick={() => {
                            let newQuestion = que
                            newQuestion.questions.splice(aIndex, 1)
                            newQuestion.questions = newQuestion.questions.map(
                              (_, index) => `${index + 1}`
                            )
                            newQuestion.answers.splice(aIndex, 1)
                            this.setState({ newQuestion })
                          }}
                        >
                          &times;
                        </button>
                      </div>
                    </div>
                  )
                })}
                <div>
                  <button
                    className="btn btn-light text-muted px-2 py-1"
                    onClick={() => {
                      let newQuestion = que
                      newQuestion.questions.push(`${que.questions.length + 1}`)
                      newQuestion.answers.push("")
                      this.setState({ newQuestion })
                    }}
                  >
                    Add Option
                  </button>
                </div>
              </>
            )}
            {que.type === "MATCHING" && (
              <>
                <div className="d-flex mb-2">
                  <div className="col-4">
                    <h5 className="text-dark">Match</h5>
                  </div>
                  <div className="col-8 d-flex justify-content-center">
                    <h5 className="text-dark ">Answer</h5>
                  </div>
                </div>
                {que.questions.map((quest, aIndex) => {
                  return (
                    <div
                      key={aIndex}
                      className="d-flex px-2 py-1 rounded mb-3"
                      style={{ border: "1px solid #e7e7e7" }}
                    >
                      <div className="col-6 d-flex align-items-center">
                        <Input
                          value={que.questions[aIndex]}
                          onChange={e => {
                            let newQuestion = que
                            newQuestion.questions[aIndex] = e.target.value
                            this.setState({ newQuestion })
                          }}
                          type="text"
                          name="option"
                          placeholder="Enter Match"
                          className="bg-white border-0"
                        />
                      </div>
                      <div className="col-6 d-flex align-items-center justify-content-center">
                        <Input
                          value={que.answers[aIndex]}
                          onChange={e => {
                            let newQuestion = que
                            newQuestion.answers[aIndex] = e.target.value
                            this.setState({ newQuestion })
                          }}
                          type="text"
                          name="option"
                          placeholder="Enter Answer"
                          className="bg-white border-0"
                        />
                        <button
                          className="mb-0 ml-auto"
                          style={{
                            fontSize: "1.2em",
                            display:
                              que.questions.length === 1 ? "none" : "block",
                          }}
                          onClick={() => {
                            let newQuestion = que
                            newQuestion.questions.splice(aIndex, 1)
                            newQuestion.answers.splice(aIndex, 1)
                            this.setState({ newQuestion })
                          }}
                        >
                          &times;
                        </button>
                      </div>
                    </div>
                  )
                })}
                <div>
                  <button
                    className="btn btn-light text-muted px-2 py-1"
                    onClick={() => {
                      let newQuestion = que
                      newQuestion.questions.push("")
                      newQuestion.answers.push("")
                      this.setState({ newQuestion })
                    }}
                  >
                    Add Option
                  </button>

                  <h5 className="mt-4 mb-1 text-dark">Additonal answers</h5>
                  <p className="m-0 mb-2 text-muted">
                    You can create additional answers to increase the difficulty
                    level
                  </p>
                  {que.distractorAnswers &&
                    que.distractorAnswers.map((quest, aIndex) => {
                      return (
                        <div
                          key={aIndex}
                          className="d-flex px-2 py-1 rounded mb-3"
                          style={{ border: "1px solid #e7e7e7" }}
                        >
                          <div className="col-6 d-flex align-items-center">
                            <Input
                              value={quest}
                              onChange={e => {
                                let newQuestion = que
                                newQuestion.distractorAnswers[aIndex] =
                                  e.target.value
                                this.setState({ newQuestion })
                              }}
                              type="text"
                              name="option"
                              placeholder="Enter additonal answer"
                              className="bg-white border-0"
                            />
                          </div>
                          <div className="col-6 d-flex align-items-center justify-content-center">
                            <button
                              className="mb-0 ml-auto"
                              style={{
                                fontSize: "1.2em",
                              }}
                              onClick={() => {
                                let newQuestion = que
                                newQuestion.distractorAnswers.splice(aIndex, 1)
                                this.setState({ newQuestion })
                              }}
                            >
                              &times;
                            </button>
                          </div>
                        </div>
                      )
                    })}
                  <button
                    className="btn btn-light text-muted px-2 py-1"
                    onClick={() => {
                      let newQuestion = que
                      newQuestion.distractorAnswers
                        ? newQuestion.distractorAnswers.push("")
                        : (newQuestion.distractorAnswers = [""])
                      this.setState({ newQuestion })
                    }}
                  >
                    Add additional answer
                  </button>
                </div>
              </>
            )}
          </div>

          <div className="d-flex align-items-center mt-4">
            <h5 className="text-dark m-0 mr-2">Mark</h5>
            <Input
              value={que.mark}
              onChange={e => {
                const value = e.target.valueAsNumber
                if (value < 0) return
                let newQuestion = que
                newQuestion.mark = value
                this.setState({ newQuestion })
              }}
              type="number"
              style={{ width: 60, borderRadius: 8 }}
            />

            <h5 className="text-dark m-0 ml-4">Difficulty Level</h5>
            <select
              className="ml-2 mr-auto custom-select"
              style={{ width: 105, borderRadius: 8 }}
              value={que.difficulty}
              onChange={e => {
                const value = e.target.value
                if (value < 0) return
                let newQuestion = que
                newQuestion.difficulty = value
                this.setState({ newQuestion })
              }}
            >
              <option value="EASY">Easy</option>
              <option value="MEDIUM">Medium</option>
              <option value="DIFFICULT">Difficult</option>
            </select>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-end mt-4">
          <LoadingButton
            className="btn add-question-btn mr-2"
            isLoading={this.state.savingQuestion === "save-another"}
            disabled={this.state.savingQuestion}
            onClick={() => {
              this.saveQuizQuestion("save-another", () =>
                this.setState({
                  modalTitle: "",
                  newQuestion: defaultQuestion,
                })
              )
            }}
          >
            Save & Add Another
          </LoadingButton>
          <LoadingButton
            className="btn bg-green text-light"
            style={{ borderRadius: 8 }}
            isLoading={this.state.savingQuestion === "done"}
            disabled={this.state.savingQuestion}
            onClick={() => {
              this.saveQuizQuestion("done", () =>
                this.setState({
                  modalTitle: "",
                  newQuestion: defaultQuestion,
                  isAddQuestionModalOpen: false,
                })
              )
            }}
          >
            Done
          </LoadingButton>
        </div>
      </Modal>
    )
  }

  renderQuestionPreview = que => {
    return (
      <div>
        {/* True false */}
        {que.type === "TRUE_OR_FALSE" && (
          <>
            <div className="d-flex mb-2">
              <div className="col-8">
                <h6 className="text-dark ">Options</h6>
              </div>
              <div className="col-4 d-flex justify-content-center">
                <h6 className="text-dark ">Correct Answer</h6>
              </div>
            </div>

            <div
              className="d-flex px-2 py-3 rounded mb-3"
              style={{ border: "1px solid #e7e7e7" }}
            >
              <div className="col-8">
                <h6 className="text-dark m-0">True</h6>
              </div>
              <div className="col-4 d-flex align-items-center justify-content-center">
                <Input
                  type="radio"
                  className="mb-1"
                  checked={que.answers[0] === "true"}
                />
              </div>
            </div>
            <div
              className="d-flex px-2 py-3 rounded mb-3"
              style={{ border: "1px solid #e7e7e7" }}
            >
              <div className="col-8">
                <h6 className="text-dark m-0">False</h6>
              </div>
              <div className="col-4 d-flex align-items-center justify-content-center">
                <Input
                  type="radio"
                  className="mb-1"
                  checked={que.answers[0] === "false"}
                />
              </div>
            </div>
          </>
        )}
        {/* Multiple choice */}
        {que.type === "MULTIPLE_CHOICE" && (
          <>
            <div className="d-flex mb-2">
              <div className="col-8">
                <h6 className="text-dark ">Options</h6>
              </div>
              <div className="col-4 d-flex justify-content-center">
                <h6 className="text-dark ">Correct Answer</h6>
              </div>
            </div>
            {Array.isArray(que.questions) &&
              que.questions.map((ans, aIndex) => {
                const isSelected = que.answers[0] === ans
                return (
                  <div
                    key={aIndex}
                    className="d-flex px-2 py-1 rounded mb-3"
                    style={{ border: "1px solid #e7e7e7" }}
                  >
                    <div className="col-8">
                      <Input
                        value={ans}
                        type="text"
                        name="option"
                        placeholder="Enter Answer"
                        className="bg-white border-0"
                      />
                    </div>
                    <div className="col-4 d-flex align-items-center justify-content-center">
                      <Input
                        type="radio"
                        className="mb-1 mr-3"
                        checked={ans && isSelected}
                      />
                      <button
                        className="mb-0 ml-auto"
                        style={{
                          fontSize: "1.2em",
                          display:
                            que.questions.length === 1 ? "none" : "block",
                        }}
                      >
                        &times;
                      </button>
                    </div>
                  </div>
                )
              })}
          </>
        )}
        {/* Multiple Answers Option */}
        {que.type === "MULTIPLE_ANSWER" && (
          <>
            <div className="d-flex mb-2">
              <div className="col-8">
                <h6 className="text-dark ">Options</h6>
              </div>
              <div className="col-4 d-flex justify-content-center">
                <h6 className="text-dark ">Correct Answer</h6>
              </div>
            </div>
            {que.questions.map((ans, aIndex) => {
              const isSelected = que.answers.includes(ans)
              return (
                <div
                  key={aIndex}
                  className="d-flex px-2 py-1 rounded mb-3"
                  style={{ border: "1px solid #e7e7e7" }}
                >
                  <div className="col-8">
                    <Input
                      value={ans}
                      type="text"
                      name="option"
                      placeholder="Enter Answer"
                      className="bg-white border-0"
                    />
                  </div>
                  <div className="col-4 d-flex align-items-center justify-content-center">
                    <Input
                      type="checkbox"
                      className="mb-1 mr-3"
                      checked={ans && isSelected}
                    />
                  </div>
                </div>
              )
            })}
          </>
        )}

        {que.type === "FILL_IN_THE_BLANKS" && (
          <>
            <div className="d-flex mb-2">
              <div className="col-3 d-flex align-items-center">
                <h6 className="text-dark ">Answer</h6>
              </div>
              <div className="col-9 d-flex align-items-center">
                <Input placeholder="Correct Answer" value={que.answers[0]} />
              </div>
            </div>
          </>
        )}
        {que.type === "SEQUENCING" && (
          <>
            <div className="d-flex mb-2">
              <div className="col-4">
                <h6 className="text-dark">Order</h6>
              </div>
              <div className="col-8 d-flex justify-content-center">
                <h6 className="text-dark ">Answer</h6>
              </div>
            </div>
            {que.questions.map((quest, aIndex) => {
              return (
                <div
                  key={aIndex}
                  className="d-flex px-2 py-1 rounded mb-3"
                  style={{ border: "1px solid #e7e7e7" }}
                >
                  <div className="col-4 d-flex align-items-center">
                    <h6 className="text-dark mb-0">{quest}</h6>
                  </div>
                  <div className="col-8 d-flex align-items-center justify-content-center">
                    <Input
                      value={que.answers[aIndex]}
                      type="text"
                      name="option"
                      placeholder="Enter Answer"
                      className="bg-white border-0"
                    />
                  </div>
                </div>
              )
            })}
          </>
        )}
        {que.type === "MATCHING" && (
          <>
            <div className="d-flex mb-2">
              <div className="col-4">
                <h6 className="text-dark">Match</h6>
              </div>
              <div className="col-8 d-flex justify-content-center">
                <h6 className="text-dark ">Answer</h6>
              </div>
            </div>
            {que.questions.map((quest, aIndex) => {
              return (
                <div
                  key={aIndex}
                  className="d-flex px-2 py-1 rounded mb-3"
                  style={{ border: "1px solid #e7e7e7" }}
                >
                  <div className="col-6 d-flex align-items-center">
                    <Input
                      value={que.questions[aIndex]}
                      type="text"
                      name="option"
                      placeholder="Enter Match"
                      className="bg-white border-0"
                    />
                  </div>
                  <div className="col-6 d-flex align-items-center justify-content-center">
                    <Input
                      value={que.answers[aIndex]}
                      type="text"
                      name="option"
                      placeholder="Enter Answer"
                      className="bg-white border-0"
                    />
                  </div>
                </div>
              )
            })}
            <div>
              <h5 className="mt-4 mb-1 text-dark">Additional answers</h5>
              {que.distractorAnswers &&
                que.distractorAnswers.map((quest, aIndex) => {
                  return (
                    <div
                      key={aIndex}
                      className="d-flex px-2 py-1 rounded mb-3"
                      style={{ border: "1px solid #e7e7e7" }}
                    >
                      <div className="col-6 d-flex align-items-center">
                        <Input
                          value={quest}
                          type="text"
                          name="option"
                          placeholder="Enter additonal answer"
                          className="bg-white border-0"
                        />
                      </div>
                      <div className="col-6 d-flex align-items-center justify-content-center"></div>
                    </div>
                  )
                })}
            </div>
          </>
        )}
      </div>
    )
  }

  rowHighlight = id => {
    let rowid = document.getElementById(id)
    rowid.style.backgroundColor = "rgb(25, 159, 132,0.2)"
  }

  rowUnHighlight = id => {
    let rowid = document.getElementById(id)
    rowid.style.backgroundColor = "white"
  }

  renderQuestionItem = (item, index) => {
    const isOpen = this.state.previewQue === item.id
    const setOpen = val => this.setState({ previewQue: val })
    return (
      <div
        style={{
          width: "100%",
          opacity: this.state.deletingIndex === index ? "0.4" : "1",
        }}
        id={item.id}
        key={item.id}
      >
        <div
          className="row question-bank-que hover-parent rounded py-2 px-4 cursor-pointer"
          onClick={() => {
            if (!this.props.onQuestionSelect) return
            let newSelectedQuestions = this.state.selectedQuestions
            if (newSelectedQuestions[item.id]) {
              this.rowUnHighlight(item.id)
              delete newSelectedQuestions[item.id]
            } else {
              this.rowHighlight(item.id)
              newSelectedQuestions[item.id] = item
            }
            this.setState({ selectedQuestions: newSelectedQuestions })
          }}
        >
          <div className={`col-3 ${!isOpen && "font-weight-bold"}`}>
            {item.text
              ? String(item.text).length > 80
                ? String(item.text).slice(0, 70) + "..."
                : item.text
              : "No data"}
          </div>
          <div className="col">
            <i
              className={
                !isOpen
                  ? "far fa-eye ml-2 cursor-pointer"
                  : "far fa-eye-slash ml-2 cursor-pointer"
              }
              onClick={() => (!isOpen ? setOpen(item.id) : setOpen(""))}
              style={{ color: "#06b191" }}
            />
          </div>
          <div className="col">
            {" "}
            {item.subjectChapter ? item.subjectChapter.name : "None"}
          </div>
          <div className="col">
            {item.subjectTopic ? item.subjectTopic.name : "None"}
          </div>
          <div className="col">
            {String(item.type)
              .toLowerCase()
              .split("_")
              .join(" ")}
          </div>
          <div className="col"> {item.difficulty}</div>
          <div className="col">{item.mark}</div>
          {this.state.selectedTab === "my" && (
            <div className="col">
              <div className="d-flex">
                <UncontrolledDropdown direction="">
                  <DropdownToggle
                    tag="i"
                    className="hover-child text-muted fas fa-ellipsis-v px-2 m-auto cursor-pointer"
                    onClick={e => e.stopPropagation()}
                  />
                  <DropdownMenu>
                    <DropdownItem
                      onClick={e => {
                        e.stopPropagation()
                        this.loadQueInModal(item)
                      }}
                    >
                      Edit
                    </DropdownItem>
                    <DropdownItem
                      onClick={e => {
                        e.stopPropagation()
                        this.loadQueInModal(item, "dublicate")
                      }}
                    >
                      Duplicate
                    </DropdownItem>
                    <DropdownItem
                      onClick={e => {
                        e.stopPropagation()
                        this.setState({ deletingIndex: index })
                        this.props.deleteQuizQuestionBank(
                          { id: item.id },
                          (success, response) => {
                            this.setState({ deletingIndex: null })
                            if (success) {
                              this.loadQuestionBank()
                            }
                          }
                        )
                      }}
                    >
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          )}
        </div>

        <Collapse isOpen={isOpen} className="mx-5 rounded">
          <p className="text-dark mb-4 fs-17 font-weight-bold">{item.text}</p>

          <div className="row align-items-center justify-content-between">
            <div className="col-6">{this.renderQuestionPreview(item)}</div>
          </div>
        </Collapse>

        <div
          className="mx-1"
          style={{ height: 1, backgroundColor: "#d9d6d6" }}
        />
      </div>
    )
  }

  renderQuestionItemAssessment = (item, index) => {
    const isOpen = this.state.previewQue === item.id
    const setOpen = val => this.setState({ previewQue: val })

    return (
      <div
        style={{
          width: "100%",
          opacity: this.state.deletingIndex === index ? "0.4" : "1",
        }}
        id={item.id}
        key={item.id}
      >
        <div
          className="row question-bank-que hover-parent rounded py-2 px-4 cursor-pointer"
          onClick={() => {
            if (!this.props.onQuestionSelect) return
            let newSelectedQuestions = this.state.selectedQuestions
            if (newSelectedQuestions[item.id]) {
              this.rowUnHighlight(item.id)
              delete newSelectedQuestions[item.id]
            } else {
              this.rowHighlight(item.id)
              newSelectedQuestions[item.id] = item
            }
            this.setState({ selectedQuestions: newSelectedQuestions })
          }}
        >
          <div className={`col-3 ${!isOpen && "font-weight-bold"}`}>
            {item.text
              ? String(item.text).length > 80
                ? String(item.text).slice(0, 70) + "..."
                : item.text
              : "No data"}
          </div>
          <div
            className="col"
            onClick={() => (!isOpen ? setOpen(item.id) : setOpen(""))}
          >
            <i
              className={
                !isOpen
                  ? "far fa-eye ml-2 cursor-pointer"
                  : "far fa-eye-slash ml-2 cursor-pointer"
              }
              onClick={() => (!isOpen ? setOpen(item.id) : setOpen(""))}
              style={{ color: "#06b191" }}
            />
          </div>
          <div className="col">
            {" "}
            {item.subjectChapter ? item.subjectChapter.name : "None"}
          </div>
          <div className="col">
            {item.subjectTopic ? item.subjectTopic.name : "None"}
          </div>
          <div className="col">
            {String(item.type)
              .toLowerCase()
              .split("_")
              .join(" ")}
          </div>
          <div className="col"> {item.difficulty}</div>
          <div className="col">&nbsp;{item.mark}</div>
        </div>

        <Collapse isOpen={isOpen} className="mx-5 rounded">
          <p className="text-dark mb-4 fs-17 font-weight-bold">{item.text}</p>

          <div className="row align-items-center justify-content-between">
            <div className="col-6">{this.renderQuestionPreview(item)}</div>
          </div>
        </Collapse>

        <div
          className="mx-1"
          style={{ height: 1, backgroundColor: "#d9d6d6" }}
        />
      </div>
    )
  }

  renderSelectFilters = () => (
    <div className="d-flex justify-content-between">
      <div className="d-flex align-items-center justify-content-start mb-4">
        {!this.state.isLoadedFromCreateAssessement && (
          <div className="mr-2" style={{ width: "12rem" }}>
            <select
              className="custom-select mr-2"
              style={{ borderRadius: 8 }}
              value={this.state.selectedClass}
              onChange={e => {
                const value = e.target.value
                let findClass = this.state.classesList.find(
                  item => item.value === e.target.value
                )
                const subjectsList = value
                  ? this.state.subjectsPerSection[value] || []
                  : []
                this.setState(
                  {
                    selectedClass: value,
                    selectedBoard: findClass ? findClass.board : "",
                    selectedClassName: findClass ? findClass.className : "",
                    selectedSubject: "",
                    selectedLesson: "",
                    selectedTopic: "",
                    lessonsList: [],
                    topicsList: [],
                    subjectsList: subjectsList.map(item => ({
                      value: item.subjectId,
                      label: item.subjectName,
                      masterSubjectId: item.masterSubjectId,
                    })),
                  },
                  () => {
                    this.loadQuestionBank()
                  }
                )
              }}
            >
              <option value="">Select Class</option>
              {this.state.classesList &&
                this.state.classesList.map(option => (
                  <option value={option.value}>{option.label}</option>
                ))}
            </select>
          </div>
        )}
        {!this.state.isLoadedFromCreateAssessement && (
          <div className="mr-2" style={{ width: "12rem" }}>
            <select
              className="custom-select mr-2"
              style={{ borderRadius: 8 }}
              value={this.state.selectedSubject}
              disabled={!this.state.selectedClass}
              onChange={e => {
                const value = e.target.value
                let findSubject = this.state.subjectsList.find(
                  item => item.value === e.target.value
                )
                this.setState(
                  {
                    selectedSubject: value,
                    selectedMasterSubjectId: findSubject
                      ? findSubject.masterSubjectId
                      : "",
                    lessonsList: [],
                    selectedLesson: "",
                    topicsList: [],
                    selectedTopic: "",
                  },
                  () => {
                    this.loadQuestionBank()
                  }
                )

                this.props.getChapters(
                  {
                    subjectId: findSubject ? findSubject.masterSubjectId : "",
                    sectionId: this.state.selectedClassName,
                    board: this.state.selectedBoard,
                  },
                  (success, response) => {
                    if (success) {
                      const lessonsList = response.data.chaptersList.map(
                        item => ({
                          value: item.id,
                          label: item.name,
                        })
                      )
                      const topicsPerLesson = {}
                      response.data.chaptersList.forEach(
                        item => (topicsPerLesson[item.id] = item.subjectTopics)
                      )
                      this.setState({ lessonsList, topicsPerLesson })
                    }
                  }
                )
              }}
            >
              <option value="">Select Subject</option>
              {this.state.subjectsList &&
                this.state.subjectsList.map(option => (
                  <option value={option.value}>{option.label}</option>
                ))}
            </select>
          </div>
        )}

        <div className="mr-2" style={{ width: "12rem" }}>
          <select
            className="custom-select mr-2"
            style={{ borderRadius: 8 }}
            value={this.state.selectedLesson}
            disabled={!this.state.lessonsList}
            onChange={e => {
              const value = e.target.value
              this.props.getChapters(
                {
                  chapterId: value,
                },
                (success, response) => {
                  if (success) {
                    const topicsList = response.data.topicsList.map(item => ({
                      value: item.id,
                      label: item.name,
                    }))
                    this.setState({ topicsList })
                  }
                }
              )
              this.setState(
                {
                  selectedLesson: value,
                  selectedTopic: "",
                },
                () => {
                  this.loadQuestionBank()
                }
              )
            }}
          >
            <option value="">Select Chapter</option>
            {this.state.lessonsList &&
              this.state.lessonsList.map(option => (
                <option value={option.id ? option.id : option.value}>
                  {option.name ? option.name : option.label}
                </option>
              ))}
          </select>
        </div>

        <div className="mr-2" style={{ width: "12rem" }}>
          <select
            className="custom-select mr-2"
            style={{ borderRadius: 8 }}
            value={this.state.selectedTopic}
            disabled={!this.state.selectedLesson}
            onChange={e =>
              this.setState(
                {
                  selectedTopic: e.target.value,
                },
                () => {
                  this.loadQuestionBank()
                }
              )
            }
          >
            <option value="">Select Topic</option>
            {this.state.topicsList &&
              this.state.topicsList.map(option => (
                <option value={option.value}>{option.label}</option>
              ))}
          </select>
        </div>

        <div className="mr-2" style={{ width: "12rem" }}>
          <select
            className="custom-select mr-2"
            style={{ borderRadius: 8 }}
            value={this.state.selectedQuestionType}
            disabled={!this.state.selectedTopic}
            onChange={e =>
              this.setState(
                {
                  selectedQuestionType: e.target.value,
                },
                () => {
                  this.loadQuestionBank()
                }
              )
            }
          >
            <option value="">Select Ques. Type</option>
            {this.state.questionTypes &&
              this.state.questionTypes.map(option => (
                <option value={option.value}>{option.label}</option>
              ))}
          </select>
        </div>

        <div style={{ width: "12rem" }}>
          <select
            className="custom-select mr-2"
            style={{ borderRadius: 8 }}
            value={this.state.selectedDiffcultyLevel}
            disabled={!this.state.selectedQuestionType}
            onChange={e =>
              this.setState(
                {
                  selectedDiffcultyLevel: e.target.value,
                },
                () => {
                  this.loadQuestionBank()
                }
              )
            }
          >
            <option value="">Select Diff. Level</option>
            {this.state.difficultyLevels &&
              this.state.difficultyLevels.map(option => (
                <option value={option.value}>{option.label}</option>
              ))}
          </select>
        </div>
      </div>

      {!this.state.isLoadedFromCreateAssessement && (
        <div>
          <button
            className="btn bg-green text-light border-0"
            style={{ borderRadius: 8 }}
            onClick={() =>
              this.setState({
                newQuestion: defaultQuestion,
                isAddQuestionModalOpen: true,
                modalTitle: "",
              })
            }
          >
            Add Question
          </button>
        </div>
      )}
    </div>
  )

  renderQuestionListAssessment = () => {
    const { totalRecords, limit } = this.state

    return (
      <>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="d-flex align-items-start">
            {this.state.tabs.map(tab => {
              const isSelected = tab.key === this.state.selectedTab
              return (
                <div
                  key={tab.key}
                  className={`py-1 mr-3 mb-0 cursor-pointer ${
                    isSelected ? "text-green" : ""
                  }`}
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: 700,
                    color: !isSelected && "#757575",
                    borderBottom: `4px solid ${
                      isSelected ? "#04b191" : "transparent"
                    }`,
                  }}
                  onClick={() => {
                    this.setState({ selectedTab: tab.key }, () => {
                      this.loadQuestionBank()
                    })
                  }}
                >
                  {tab.title}
                </div>
              )
            })}
          </div>

          {this.props.onQuestionSelect && (
            <div
              className="px-3 py-2 d-flex align-items-center"
              style={{
                backgroundColor: "#D2F1EB",
                color: "#199F84",
                borderRadius: 8,
              }}
            >
              <i className="fal fa-box" style={{ fontSize: "1.75rem" }} />
              <span className="ml-3 mr-1">Selected questions: </span>
              <span style={{ fontSize: "1.2rem", fontWeight: "700" }}>
                {Object.keys(this.state.selectedQuestions).length}
              </span>
              <span className="ml-3 mr-1">Total Marks: </span>
              <span style={{ fontSize: "1.2rem", fontWeight: "700" }}>
                {Object.values(this.state.selectedQuestions).reduce(
                  (val, item) => val + item.mark,
                  0
                )}
              </span>
            </div>
          )}
        </div>

        <div className="d-flex flex-column" style={{ width: "100%" }}>
          <div className="d-flex flex-row bg-brand text-light font-weight-bold py-3 px-4">
            <div className="col-3">
              Questions{" "}
              {this.state.questionsList &&
                `(${this.state.questionsList.length})`}
            </div>
            <div className="col"></div>
            <div className="col">Chapter</div>
            <div className="col">Topic</div>
            <div className="col">Type</div>
            <div className="col">Level</div>
            <div className="col">Mark</div>
          </div>

          <div
            style={{
              maxHeight: window.innerHeight - 340 + "px",
              overflowY: "auto",
              overflowX: "clip",
            }}
          >
            {this.state.isLoading ? (
              <>
                <Loader opacity={1} />
                <Loader opacity={0.68} />
                <Loader opacity={0.5} />
              </>
            ) : this.state.questionsList &&
              this.state.questionsList.length > 0 ? (
              this.state.questionsList.map(this.renderQuestionItemAssessment)
            ) : (
              <div className="text-center">No records found</div>
            )}
          </div>
        </div>

        {!this.state.isLoading && this.state.questionsList.length > 0 && (
          <div className="d-flex align-items-center justify-content-center mt-3">
            <Pagination
              count={Math.ceil(totalRecords / limit)}
              page={this.state.currentPage}
              onChange={this.paginateMaterial}
              defaultPage={1}
              color="primary"
              size="small"
              variant="outlined"
              shape="rounded"
            />

            <div className="d-flex flex-row ml-5">
              <div className="mt-2">Per page </div>
              <div>
                <select
                  className="form-control"
                  value={this.state.limit}
                  style={{ width: "3rem", padding: 0, height: "2rem" }}
                  onChange={e => {
                    this.setState({ limit: e.target.value }, () => {
                      this.loadQuestionBank()
                    })
                  }}
                >
                  {this.state.perPage.map((number, index) => (
                    <option value={number} key={index}>
                      {number}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }

  sortingChapter = questionsList => {
    const list = questionsList
    list.sort((a, b) => {
      const achapter = a.subjectChapter ? String(a.subjectChapter.name) : ""
      const bchapter = b.subjectChapter ? String(b.subjectChapter.name) : ""
      return achapter > bchapter || a.type > b.type ? 1 : -1
    })
    return list
  }

  renderQuestionList = () => {
    const { totalRecords, limit } = this.state

    return (
      <>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="d-flex align-items-start">
            {this.state.tabs.map(tab => {
              const isSelected = tab.key === this.state.selectedTab
              return (
                <div
                  key={tab.key}
                  className={`py-1 mr-3 mb-0 cursor-pointer ${
                    isSelected ? "text-green" : ""
                  }`}
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: 700,
                    color: !isSelected && "#757575",
                    borderBottom: `4px solid ${
                      isSelected ? "#04b191" : "transparent"
                    }`,
                  }}
                  onClick={() => {
                    this.setState({ selectedTab: tab.key }, () => {
                      this.loadQuestionBank()
                    })
                  }}
                >
                  {tab.title}
                </div>
              )
            })}
          </div>
        </div>

        <div className="d-flex flex-column" style={{ width: "100%" }}>
          <div className="d-flex flex-row bg-brand text-light font-weight-bold py-3 px-4">
            <div className="col-3">
              Questions{" "}
              {this.state.questionsList &&
                `(${this.state.questionsList.length})`}
            </div>
            <div className="col"></div>
            <div className="col">Chapter</div>
            <div className="col">Topic</div>
            <div className="col">Type</div>
            <div className="col">Level</div>
            <div className="col">Mark</div>

            {this.state.selectedTab === "my" && <div className="col"></div>}
          </div>

          <div
            style={{
              overflowY: "auto",
              overflowX: "clip",
              minHeight: "6rem",
            }}
          >
            {this.state.isLoading ? (
              <>
                <Loader opacity={1} />
                <Loader opacity={0.68} />
                <Loader opacity={0.5} />
              </>
            ) : this.state.questionsList &&
              this.state.questionsList.length > 0 ? (
              this.state.questionsList &&
              this.sortingChapter(this.state.questionsList).map(
                this.renderQuestionItem
              )
            ) : (
              <div className="text-center">No records found</div>
            )}
          </div>
        </div>

        {!this.state.isLoading && this.state.questionsList.length > 0 && (
          <div className="d-flex align-items-center justify-content-center mt-3 mb-4">
            <Pagination
              count={Math.ceil(totalRecords / limit)}
              page={this.state.currentPage}
              onChange={this.paginateMaterial}
              defaultPage={1}
              color="primary"
              size="small"
              variant="outlined"
              shape="rounded"
            />

            <div className="d-flex flex-row ml-5">
              <div className="mt-2">Per page </div>
              <div>
                <select
                  className="form-control ml-2 mt-1"
                  value={this.state.limit}
                  style={{ width: "3rem", padding: 0, height: "2rem" }}
                  onChange={e => {
                    this.setState({ limit: e.target.value }, () => {
                      this.loadQuestionBank()
                    })
                  }}
                >
                  {this.state.perPage.map((number, index) => (
                    <option value={number} key={index}>
                      {number}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }

  render() {
    if (this.props.onQuestionSelect) {
      return (
        <>
          {this.renderAddQuestionModal()}
          <div className="row align-items-center mb-4">
            <div className="col">
              <h4 className="text-dark font-weight-bold mb-0">
                Select Question
              </h4>
            </div>
            <div className="col d-flex align-items-center justify-content-end">
              {!this.state.isLoadedFromCreateAssessement && (
                <button
                  className="btn bg-green text-light border-0"
                  style={{ borderRadius: 8 }}
                  onClick={() =>
                    this.setState({
                      newQuestion: defaultQuestion,
                      isAddQuestionModalOpen: true,
                      modalTitle: "",
                    })
                  }
                >
                  Add Question
                </button>
              )}
            </div>
          </div>

          {this.renderSelectFilters()}
          {this.renderQuestionListAssessment()}

          <div className="d-flex align-items-center justify-content-end mt-2">
            <Button
              className="mr-3 px-4 py-1 text-muted font-weight-bold border-0"
              style={{ borderRadius: 8, backgroundColor: "#D0F0EA" }}
              onClick={() => this.props.onClose && this.props.onClose()}
            >
              Cancel
            </Button>
            <Button
              className="px-4 py-1 bg-green text-light font-weight-bold border-0"
              style={{ borderRadius: 8 }}
              onClick={() => {
                this.props.onQuestionSelect(
                  Object.values(this.state.selectedQuestions)
                )
              }}
            >
              Done
            </Button>
          </div>
        </>
      )
    } else {
      return (
        <>
          {this.renderAddQuestionModal()}
          <AppWrapper
            teacherDashboard
            showTeacherThree
            showTeacherLogo
            screenName={"Question Bank"}
          >
            {this.renderSelectFilters()}
            {this.renderQuestionList()}
          </AppWrapper>
        </>
      )
    }
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getQuizQuestionBank,
      getChapters,
      saveQuizAssigns,
      getTeacherAssignedDetails,
      getClassStudents,
      showNotification,
      saveQuizQuestionBank,
      deleteQuizQuestionBank,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  dispatchToProps
)(withRouter(QuestionBank))
