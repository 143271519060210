/*************************************************
 * RedInk
 * @exports
 * @class Wrapper.js
 * @extends Component
 * @author Sasidharan // on 04/06/2020
 * @copyright © RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import moment from "moment"
import store from "store"
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap"

import {
  saveCurrentAssignmentDetailsInStore,
  saveAssignmentToggleModalDetailsInStore,
  updateMarkAsReadAssignment,
  updateViewedAssignment,
} from "../../actions/parent/AssignmentAction"
import {
  getNotificationList,
  clearNotification,
} from "../../actions/parent/DashboardAction"
import { URL_PATH } from "../../config/urlPath"
import { logout } from "../../utils/Utility"
import PATH_URL from "../../config/index"

import attlogohighwtPic from "../../assets/images/attendance-logo---high-weightage-font.png"
import notificationPic from "../../assets/images/assignmentNotification.png"
import redinkLogo from "../../assets/images/redink/redink_logo.png"
import girlPic from "../../assets/images/girl.png"
import boyPic from "../../assets/images/boy.png"

class Wrapper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      notificationList: [],
      isOpenToggle: false,
    }
  }

  componentDidMount() {
    this.getNotification()
  }

  getNotification = () => {
    this.props.getNotificationList((success, response) => {
      if (success && response.data) {
        this.setState({ notificationList: response.data })
      }
    })
  }

  render() {
    return (
      <div className="modern">
        <header className="header fixed-top">
          <nav className="navbar navbar-expand bg-white d-flex justify-content-between">
            <ul className="navbar-nav site-logo px-2">
              <li
                className="nav-item p-1 cursor-pointer show-mdsm d-none mr-2"
                onClick={() =>
                  (document.getElementById("overlayNav").style.width = "100%")
                }
              >
                <i className="far fa-bars"></i>
              </li>
              <li className="nav-item px-2 row">
                <div className="navbar-brand text-theme font-weight-bolder mt-2 fs-14">
                  {this.props.screenName}
                </div>
              </li>
            </ul>
            <ul className="navbar-nav site-logo px-2 navbar-expand-sm">
              <li
                className={`nav-item px-2 mr-2 ${
                  this.state.notificationList &&
                  this.state.notificationList.length > 0
                    ? ""
                    : "disabled"
                }`}
                title="Notifications"
              >
                <UncontrolledDropdown
                  isOpen={this.state.isOpenToggle}
                  className="notification-scrollbars"
                >
                  <DropdownToggle
                    tag="a"
                    className="notification-icon"
                    onClick={() => {
                      this.setState({ isOpenToggle: !this.state.isOpenToggle })
                    }}
                  >
                    <div className="disabled nav-link text-dark">
                      <i className="far fa-bell" />
                      <div className="badge badge-light ml-1">
                        {this.state.notificationList &&
                          this.state.notificationList.length}
                      </div>
                    </div>
                  </DropdownToggle>
                  <DropdownMenu
                    right
                    style={{ maxHeight: "80vh", overflowY: "scroll" }}
                    className="px-3"
                  >
                    <div
                      className="d-flex justify-content-end cursor-pointer"
                      onClick={() => {
                        this.props.clearNotification(() => {
                          this.getNotification()
                          this.setState({ isOpenToggle: false })
                        })
                      }}
                    >
                      Clear All
                    </div>
                    {this.state.notificationList &&
                      this.state.notificationList
                        .sort((a, b) => a.subModule < b.subModule)
                        .map((assignment, i) => (
                          <DropdownItem
                            key={i}
                            onClick={() => {
                              this.setState({ isOpenToggle: false })
                              this.props.updateMarkAsReadAssignment(
                                { notificationId: assignment.notificationId },
                                () => {
                                  this.props.getNotificationList(
                                    (success, response) => {
                                      if (success && response.data) {
                                        this.setState({
                                          notificationList: response.data,
                                        })
                                      }
                                    }
                                  )
                                }
                              )
                              if (assignment.module === "assignment") {
                                this.setState({
                                  notificationList: this.state.notificationList.filter(
                                    item => item.id !== assignment.id
                                  ),
                                })
                                this.props.saveCurrentAssignmentDetailsInStore(
                                  assignment
                                )
                                this.props.history.push({
                                  pathname: URL_PATH.PARENT_STUDENT_ASSIGNMENT,
                                  state: { assignment },
                                })
                                this.props.saveAssignmentToggleModalDetailsInStore(
                                  true
                                )
                                this.props.updateViewedAssignment(
                                  assignment,
                                  () => {}
                                )
                              }
                              if (assignment.module === "assessment") {
                                if (
                                  assignment.subModule == null ||
                                  assignment.subModule === "new" ||
                                  assignment.subModule === "live" ||
                                  assignment.subModule === "reminder" ||
                                  assignment.subModule === "retake"
                                ) {
                                  let assessment = assignment
                                  this.setState({
                                    notificationList: this.state.notificationList.filter(
                                      item => item.id !== assignment.id
                                    ),
                                  })
                                  this.props.history.push({
                                    pathname: URL_PATH.PARENT_STUDENT_TESTS,
                                    state: { assessment },
                                  })
                                }

                                if (assignment.subModule === "published") {
                                  let assessment = assignment
                                  this.setState({
                                    notificationList: this.state.notificationList.filter(
                                      item => item.id !== assignment.id
                                    ),
                                  })
                                  this.props.history.push({
                                    pathname: URL_PATH.STUDENT_TESTS_REPORT,
                                    state: {
                                      quizAssignInfoId: assessment.id,
                                      isOnline:
                                        assessment.submissionType === "ONLINE",
                                    },
                                  })
                                }
                              }

                              if (assignment.module === "schedule") {
                                if (
                                  assignment.subModule == null ||
                                  assignment.subModule === "new" ||
                                  assignment.subModule === "live" ||
                                  assignment.subModule === "reminder"
                                ) {
                                  let schedule = assignment
                                  this.setState({
                                    notificationList: this.state.notificationList.filter(
                                      item => item.id !== schedule.id
                                    ),
                                  })
                                  this.props.history.push({
                                    pathname: URL_PATH.PARENT_STUDENT_SCHEDULE,
                                    state: { schedule },
                                  })
                                }
                              }
                            }}
                            tag="div"
                            className="shadow bg-white br-10 mx-0 my-4 d-flex px-2 w-100 text-light align-items-center"
                            style={{ minWidth: "500px" }}
                          >
                            <div
                              className="shadow bg-theme-light br-10"
                              style={{
                                height: "60px",
                                width: "60px",
                                padding: "8px",
                              }}
                            >
                              {assignment.module !== "attendance" ? (
                                <img
                                  src={notificationPic}
                                  style={{
                                    objectFit: "contain",
                                    objectPosition: "center",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  alt="notification"
                                />
                              ) : (
                                <img
                                  src={attlogohighwtPic}
                                  style={{
                                    objectFit: "contain",
                                    objectPosition: "center",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  alt=""
                                />
                              )}
                            </div>
                            {assignment.module === "assignment" ? (
                              <div
                                className="ml-3 text-dark"
                                style={{ flex: 1 }}
                              >
                                <div className="d-flex  align-items-center">
                                  <div
                                    className="firstLetterUpper"
                                    style={{
                                      fontSize: "0.85rem",
                                      color: "#094353",
                                      fontWeight: "bold",
                                      textTransform: "lowercase",
                                    }}
                                  >
                                    {assignment.assignmentDetail &&
                                      assignment.assignmentDetail.typeOfWork}
                                  </div>
                                  {assignment.approvalStatus === "REWORK" ? (
                                    <div
                                      className={`badge mx-1 ml-3`}
                                      style={
                                        assignment.approvalStatus === "REWORK"
                                          ? {
                                              backgroundColor: "#fdc1138A",
                                              color: "#806228",
                                            }
                                          : {}
                                      }
                                    >
                                      {assignment.approvalStatus}
                                    </div>
                                  ) : null}
                                  <div
                                    className="ml-auto text-muted"
                                    style={{ fontSize: "0.8rem" }}
                                  >
                                    {moment().to(assignment.updatedOn)}
                                  </div>
                                </div>
                                <div
                                  className="my-1"
                                  style={{ fontSize: "0.9rem" }}
                                >
                                  <b>
                                    {assignment.subject &&
                                      assignment.subject.subjectName}
                                  </b>{" "}
                                  -{" "}
                                  {assignment.assignmentDetail &&
                                  assignment.assignmentDetail.title &&
                                  assignment.assignmentDetail.title.length < 25
                                    ? assignment.assignmentDetail.title
                                    : assignment.assignmentDetail.title.substring(
                                        0,
                                        25
                                      ) + "...."}{" "}
                                  {assignment.approvalStatus === "APPROVED" &&
                                  assignment.mark !== ""
                                    ? "has beed graded " +
                                      assignment.mark +
                                      "/" +
                                      assignment.assignmentDetail.maxMark
                                    : ""}
                                </div>
                                <div className="d-flex mb-1 align-items-center">
                                  <div
                                    className="text-muted mr-auto"
                                    style={{ fontSize: "0.85rem" }}
                                  >
                                    Due on{" "}
                                    {moment(assignment.durationTo).format(
                                      "DD MMM YYYY"
                                    )}
                                  </div>
                                  {assignment.submitStatus === "OVERDUE" && (
                                    <>
                                      <button className="btn py-0 px-3 rounded-pill btn-sm bg-green text-light">
                                        Submit
                                      </button>{" "}
                                      <button className="btn ml-1 py-0 px-3 rounded-pill btn-sm bg-light text-green ">
                                        I'll do it later
                                      </button>
                                    </>
                                  )}
                                </div>
                              </div>
                            ) : assignment.module === "assessment" ? (
                              <div
                                className="ml-3 text-dark"
                                style={{ flex: 1 }}
                              >
                                <div className="d-flex  align-items-center">
                                  <div
                                    className="firstLetterUpper"
                                    style={{
                                      fontSize: "0.85rem",
                                      color: "#094353",
                                      fontWeight: "bold",
                                      textTransform: "lowercase",
                                    }}
                                  >
                                    {assignment.submissionType &&
                                    assignment.submissionType === "ONLINE"
                                      ? "Online Test"
                                      : "Inclass Test"}

                                    {assignment.subModule &&
                                    assignment.subModule === "live" ? (
                                      <div
                                        className="d-flex align-items-center"
                                        style={{
                                          float: "right",
                                          paddingLeft: "10px",
                                        }}
                                      >
                                        <div
                                          className="rounded-pill"
                                          style={{
                                            width: "10px",
                                            height: "10px",
                                            backgroundColor: "#d25164",
                                          }}
                                        ></div>
                                        <div
                                          className="ml-1"
                                          style={{ color: "#d25164" }}
                                        >
                                          LIVE
                                        </div>
                                      </div>
                                    ) : null}

                                    {assignment.subModule &&
                                    assignment.subModule === "reminder" ? (
                                      <div
                                        className={`badge mx-1 ml-3`}
                                        style={{
                                          backgroundColor: "#f4afaf",
                                          color: "#d25164",
                                        }}
                                      >
                                        REMINDER
                                      </div>
                                    ) : null}

                                    {assignment.subModule &&
                                    assignment.subModule === "retake" ? (
                                      <div
                                        className={`badge mx-1 ml-3`}
                                        style={{
                                          backgroundColor: "#fdc11385",
                                          color: "#806228",
                                        }}
                                      >
                                        RETEST
                                      </div>
                                    ) : null}
                                  </div>
                                  <div
                                    className="ml-auto text-muted"
                                    style={{ fontSize: "0.8rem" }}
                                  >
                                    {moment().to(assignment.updatedOn)}
                                  </div>
                                </div>
                                <div
                                  className="my-1"
                                  style={{ fontSize: "0.9rem" }}
                                >
                                  {assignment.subModule &&
                                    assignment.subModule === "retake" &&
                                    " requested you to Retake test"}
                                  <b>
                                    {assignment.subject &&
                                      assignment.subject.subjectName}
                                  </b>{" "}
                                  -{" "}
                                  {assignment.quizSet &&
                                  assignment.quizSet.title &&
                                  assignment.quizSet.title.length < 25
                                    ? assignment.quizSet.title
                                    : assignment.quizSet.title.substring(
                                        0,
                                        25
                                      ) + "...."}{" "}
                                  {assignment.subModule &&
                                  assignment.subModule === "live"
                                    ? " test is live now"
                                    : assignment.subModule &&
                                      assignment.subModule === "reminder"
                                    ? " test is going to start in next " +
                                      moment().to(
                                        assignment.quizSubmissions &&
                                          store.get("quickInfo") &&
                                          assignment.quizSubmissions.filter(
                                            item =>
                                              item.studentId ===
                                              store.get("quickInfo").studentId
                                          )[0]
                                          ? assignment.quizSubmissions.filter(
                                              item =>
                                                item.studentId ===
                                                store.get("quickInfo").studentId
                                            )[0].startTime
                                          : assignment.startTime
                                      )
                                    : assignment.subModule &&
                                      assignment.subModule === "published"
                                    ? " test has been graded"
                                    : assignment.subModule &&
                                      assignment.subModule === "published"
                                    ? " test assigned for class"
                                    : null}
                                </div>
                                <div className="d-flex mb-1 align-items-center">
                                  {assignment.subModule !== "published" && (
                                    <div
                                      className="text-muted mr-auto"
                                      style={{ fontSize: "0.85rem" }}
                                    >
                                      Starts on{" "}
                                      {moment(
                                        assignment.quizSubmissions &&
                                          store.get("quickInfo") &&
                                          assignment.quizSubmissions.filter(
                                            item =>
                                              item.studentId ===
                                              store.get("quickInfo").studentId
                                          )[0]
                                          ? assignment.quizSubmissions.filter(
                                              item =>
                                                item.studentId ===
                                                store.get("quickInfo").studentId
                                            )[0].startTime
                                          : assignment.startTime
                                      ).format("DD MMM YYYY hh:mm a")}
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : assignment.module === "schedule" ? (
                              <div
                                className="ml-3 text-dark"
                                style={{ flex: 1 }}
                              >
                                <div className="d-flex  align-items-center">
                                  <div
                                    className="firstLetterUpper"
                                    style={{
                                      fontSize: "0.85rem",
                                      color: "#094353",
                                      fontWeight: "bold",
                                      textTransform: "lowercase",
                                    }}
                                  >
                                    {assignment.scheduleType &&
                                    assignment.scheduleType === "online"
                                      ? "Online Lecture"
                                      : "Inclass Lecture"}

                                    {assignment.subModule &&
                                    assignment.subModule === "live" ? (
                                      <div
                                        className="d-flex align-items-center"
                                        style={{
                                          float: "right",
                                          paddingLeft: "10px",
                                        }}
                                      >
                                        <div
                                          className="rounded-pill"
                                          style={{
                                            width: "10px",
                                            height: "10px",
                                            backgroundColor: "#d25164",
                                          }}
                                        ></div>
                                        <div
                                          className="ml-1"
                                          style={{ color: "#d25164" }}
                                        >
                                          LIVE
                                        </div>
                                      </div>
                                    ) : null}

                                    {assignment.subModule &&
                                    assignment.subModule === "reminder" ? (
                                      <div
                                        className={`badge mx-1 ml-3`}
                                        style={{
                                          backgroundColor: "#f4afaf",
                                          color: "#d25164",
                                        }}
                                      >
                                        REMINDER
                                      </div>
                                    ) : null}
                                  </div>
                                  <div
                                    className="ml-auto text-muted"
                                    style={{ fontSize: "0.8rem" }}
                                  >
                                    {moment().to(assignment.updatedOn)}
                                  </div>
                                </div>
                                <div
                                  className="my-1"
                                  style={{ fontSize: "0.9rem" }}
                                >
                                  <b>
                                    {assignment.subject &&
                                      assignment.subject.subjectName}
                                  </b>{" "}
                                  -{" "}
                                  {assignment.title &&
                                  assignment.title.length < 25
                                    ? assignment.title
                                    : assignment.title.substring(0, 25) +
                                      "...."}{" "}
                                  {assignment.subModule &&
                                  assignment.subModule === "live"
                                    ? " test is live now"
                                    : assignment.subModule &&
                                      assignment.subModule === "reminder"
                                    ? " schedule is going to start in next " +
                                      moment().to(assignment.startTime)
                                    : null}
                                </div>
                              </div>
                            ) : (
                              <div
                                className="ml-3 text-dark"
                                style={{ flex: 1 }}
                              >
                                <div className="d-flex  align-items-center">
                                  <div
                                    className="text-info"
                                    style={{ fontSize: "0.85rem" }}
                                  >
                                    Attendance
                                  </div>
                                  <div
                                    className="ml-auto text-muted"
                                    style={{ fontSize: "0.8rem" }}
                                  >
                                    {moment().to(assignment.updatedOn)}
                                  </div>
                                </div>
                                <div
                                  className="my-1"
                                  style={{ fontSize: "0.9rem" }}
                                >
                                  <b>
                                    {assignment.studentDetail &&
                                      assignment.studentDetail.studentFirstName}
                                    !
                                  </b>{" "}
                                  {moment(
                                    assignment.attendanceDetail.attendanceDate
                                  ).isSame(new Date(), "day") ? (
                                    <>
                                      Your presence is missed, you are marked as{" "}
                                      <b>Absent</b> for the day
                                    </>
                                  ) : (
                                    `You are marked as absent for ${moment(
                                      assignment.attendanceDetail.attendanceDate
                                    ).format("DD-MM-YYYY")}`
                                  )}
                                </div>
                              </div>
                            )}
                          </DropdownItem>
                        ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
              <li>
                <UncontrolledDropdown>
                  <DropdownToggle tag="a" className="nav-link">
                    <img
                      className="rounded-circle"
                      src={
                        store.get("quickInfo") &&
                        store.get("quickInfo").studentGender === "F"
                          ? girlPic
                          : boyPic
                      }
                      alt="Profile"
                      width={32}
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    {/* <DropdownItem header>Settings</DropdownItem> */}
                    <DropdownItem
                      onClick={() => {
                        this.props.history.push({
                          pathname: URL_PATH.PARENT_PROFILE,
                          state: {
                            studentId:
                              store.get("quickInfo") &&
                              store.get("quickInfo").studentId,
                          },
                        })
                      }}
                    >
                      Profile
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        this.props.history.push(URL_PATH.PARENT_CHANGEPASSWORD)
                      }}
                    >
                      Change Password
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => logout()}>Logout</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          </nav>
        </header>
        {/* Overlay Navbar for tab and mobile */}
        <div id="overlayNav" className="overlay d-none">
          <span
            className="closebtn"
            onClick={() =>
              (document.getElementById("overlayNav").style.width = "0%")
            }
          >
            &times;
          </span>
          <div className="overlay-content">
            <div className="app-sidebar__inner">
              <ul className="vertical-nav-menu text-light">
                <li
                  //   className="mt-4"
                  className={
                    URL_PATH.PARENT === window.location.pathname
                      ? "menu-active"
                      : ""
                  }
                >
                  <Link to={URL_PATH.PARENT}>PARENT</Link>
                </li>
                <li
                  className={
                    URL_PATH.PARENT_COMMUNICATION === window.location.pathname
                      ? "menu-active"
                      : ""
                  }
                >
                  <Link to={URL_PATH.PARENT_COMMUNICATION}>Communication</Link>
                </li>
                {/* <li
                className={
                    URL_PATH.PATENT_CLASS_ROOM === window.location.pathname || URL_PATH.TEACHER_VIDEO_CALL_SCREEN === window.location.pathname
                      ? "menu-active"
                      : ""
                  }>
                <Link
                  to={URL_PATH.PATENT_CLASS_ROOM}
                >
                  Online Class Room
                </Link>
              </li> */}
              </ul>
            </div>
          </div>
        </div>
        {/* Overlay Navbar for tab and mobile ends*/}
        {/* Overlay Navbar for pc starts*/}
        <aside className="aside-left">
          <nav className="menu d-flex flex-column justify-content-between">
            <ul>
              <li>
                <Link
                  to={URL_PATH.PARENT}
                  className={`d-flex flex-column justify-content-between`}
                >
                  <img
                    className="img-fluid"
                    src={redinkLogo}
                    alt="Redink"
                    width="40"
                    height="40"
                  />
                </Link>
              </li>
              <li>
                <Link
                  to={URL_PATH.PARENT}
                  className={
                    URL_PATH.PARENT === window.location.pathname ||
                    URL_PATH.PARENT_CHANGEPASSWORD === window.location.pathname
                      ? "menu-active"
                      : ""
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-dashboard"
                    width="26"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#bdc7cb"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <circle cx="12" cy="13" r="2" />
                    <line x1="13.45" y1="11.55" x2="15.5" y2="9.5" />
                    <path d="M6.4 20a9 9 0 1 1 11.2 0Z" />
                  </svg>
                  <span>Dashboard</span>
                </Link>
              </li>
              {/* <li>
                <Link
                  to={URL_PATH.PARENT_COMMUNICATION}
                  className={
                    URL_PATH.PARENT_COMMUNICATION === window.location.pathname
                      ? "menu-active"
                      : ""
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-message"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#bdc7cb"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4" />
                    <line x1="8" y1="9" x2="16" y2="9" />
                    <line x1="8" y1="13" x2="14" y2="13" />
                  </svg>
                  <span>Communication</span>
                </Link>
              </li> */}
              <li>
                <Link
                  to={URL_PATH.PARENT_STUDENT_ASSIGNMENT}
                  className={
                    URL_PATH.PARENT_STUDENT_ASSIGNMENT ===
                    window.location.pathname
                      ? "menu-active"
                      : ""
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-file-invoice"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#bdc7cb"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                    <line x1="9" y1="7" x2="10" y2="7" />
                    <line x1="9" y1="13" x2="15" y2="13" />
                    <line x1="13" y1="17" x2="15" y2="17" />
                  </svg>
                  <span>Assignment</span>
                </Link>
              </li>
              {PATH_URL.showAssessment && (
                <li>
                  <Link
                    to={URL_PATH.PARENT_STUDENT_TESTS}
                    className={
                      URL_PATH.PARENT_STUDENT_TESTS === window.location.pathname
                        ? "menu-active"
                        : ""
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-clipboard-check"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#bdc7cb"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <path d="M9 5H7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2V7a2 2 0 0 0 -2 -2h-2" />
                      <rect x="9" y="3" width="6" height="4" rx="2" />
                      <path d="M9 14l2 2l4 -4" />
                    </svg>
                    <span>Tests</span>
                  </Link>
                </li>
              )}
              {PATH_URL.showSchedule && (
                <li>
                  <Link
                    to={URL_PATH.PARENT_STUDENT_SCHEDULE}
                    className={
                      URL_PATH.PARENT_STUDENT_SCHEDULE ===
                      window.location.pathname
                        ? "menu-active"
                        : ""
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-calendar-time"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#bdc7cb"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4" />
                      <circle cx="18" cy="18" r="4" />
                      <path d="M15 3v4" />
                      <path d="M7 3v4" />
                      <path d="M3 11h16" />
                      <path d="M18 16.496v1.504l1 1" />
                    </svg>
                    <span>Schedule</span>
                  </Link>
                </li>
              )}
              {/* <li>
                <Link
                  to={URL_PATH.PATENT_CLASS_ROOM}
                  className={
                    URL_PATH.PATENT_CLASS_ROOM === window.location.pathname || URL_PATH.TEACHER_VIDEO_CALL_SCREEN === window.location.pathname
                      ? "menu-active"
                      : ""
                  }
                >
                  <i className="metismenu-icon far fa-users" />
                  <span>Online Class Room</span>
                </Link>
              </li> */}
            </ul>
          </nav>
        </aside>
        <div
          className={`app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header closed-sidebar ${
            this.state.toggleMobileSidemenu ? "sidebar-mobile-open" : ""
          }`}
        >
          <div className="app-main bg-white">
            <div className="app-main__outer">
              <div className="app-main__inner">{this.props.children}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      saveCurrentAssignmentDetailsInStore,
      saveAssignmentToggleModalDetailsInStore,
      getNotificationList,
      updateMarkAsReadAssignment,
      updateViewedAssignment,
      clearNotification,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(withRouter(Wrapper))
