/*************************************************
 * RedInk
 * @exports
 * @class Hierarchy.js
 * @extends Component
 * @author Sasidharan // on 02/02/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { HashLoader } from "react-spinners"
import { connect } from "react-redux"
import moment from "moment"
import store from "store"

import ExamHierarchySettings from "./ExamHierarchySettings"
import HierarchyItem from "./HierarchyItem"
import MessageModal from "../MessageModal"
import AppWrapper from "../Wrapper"

import {
  gradeBookLoaded,
  updateSelectedIndices,
  getSubjectListDetails,
  getExamHierarchyDetails,
  saveExamHierarchyFAsList,
  saveExamHierarchyFAsDate,
  saveExamHierarchyTermsList,
  saveExamHierarchySubjectList,
  saveExamHierarchyActivityList,
  deleteExamHierarchyFAsDetails,
  getActivityConditionListDetails,
  saveExamHierarchyFAsListInStore,
  deleteExamHierarchyTermsDetails,
  saveExamHierarchyTermsListInStore,
  deleteExamHierarchySubjectDetails,
  deleteExamHierarchyActivityDetails,
  saveExamHierarchySubjectListInStore,
  saveExamHierarchyActivityListInStore,
  saveExamHierarchySettingsDetailsInStore,
  saveExamHierarchyTheoryAndPracticalListInStore,
} from "../../../actions/teacher/HierarchyAction"
import { DANGER_TOAST, VALIDATION_MSG } from "../../../utils/Constants"
import { confirmAlertPopUp } from "../../../utils/Utility"
import { showNotification } from "../../../actions/CommonAction"
import { getClassList } from "../../../actions/teacher/TakeAttendanceAction"
import { URL_PATH } from "../../../config/urlPath"

class ExamHierarchy extends Component {
  constructor(props) {
    super(props)
    document.title = "GRADEBOOK | Redink"
    this.state = {
      viewFADock: true,
      newFADock: true,
      viewSubjectsDock: true,
      newSubjectsDock: false,
      isShowSettings: false,
      selectedClass: "",
      selectedClassName: "",
      hierarchyName: "",
      viewActivitiesDock: true,
      hierarchyObject: {},
      hierarchyIndex: -1,
      isShowFullLoading: false,
      isClassTeacherForThisSection: false,
    }
  }

  componentDidMount() {
    this.getClassList()
  }

  getClassList = () => {
    if (this.props.isGradeBookLoaded) {
      this.setState({
        selectedClass: this.props.hierarchyDetails.sectionId,
        selectedClassName: this.props.classList.find(
          it => it.id === this.props.hierarchyDetails.sectionId
        ).value,
      })
      this.getExamHierarchyDetailsService(this.props.hierarchyDetails.sectionId)
      return
    }

    this.props.getClassList((success, response) => {
      if (success) {
        this.setState({
          selectedClass: response[0].id,
          selectedClassName: response[0].value,
        })
        this.getExamHierarchyDetailsService(response[0].id)
      }
    })
  }

  getExamHierarchyDetailsService = id => {
    let object = {
      sectionId: id,
    }
    this.setState(
      { isShowFullLoading: true, isClassTeacherForThisSection: false },
      () => {
        this.props.getSubjectListDetails(object, () => {})
        this.props.getActivityConditionListDetails(() => {})
        this.props.getExamHierarchyDetails(object, (success, res) => {
          if (success) {
            this.setState({
              isClassTeacherForThisSection: res.isClassTeacherForThisSection,
            })
          }
          this.setState({ isShowFullLoading: false })
          this.props.gradeBookLoaded(true)
        })
      }
    )
  }

  saveNewBlock = (hierarchyName, index) => {
    let hierarchyArray = []
    let object = {
      name: "",
      shortName: "",
      isSelected: false,
      isLocked: false,
    }
    if (hierarchyName === "hierarchyTerms") {
      hierarchyArray = this.props.hierarchyTerms
      object.date = []
      object.FAs = []
      hierarchyArray.push(object)
      this.props.saveExamHierarchyTermsListInStore(hierarchyArray)
    }
    if (hierarchyName === "hierarchyFAs") {
      let dummyHierarchyTerms = this.props.hierarchyTerms
      hierarchyArray = this.props.hierarchyFAs
      object.date = []
      object.subjects = []
      object["termScoreEvaluation"] = false
      object["mustPass"] = false
      object["term"] = dummyHierarchyTerms[index].termId
      object["isNewBox"] = true
      object["isClassSpecific"] = true
      hierarchyArray.push(object)
      dummyHierarchyTerms[index].FAs.push(object)
      this.props.saveExamHierarchyTermsListInStore(dummyHierarchyTerms)
      this.props.saveExamHierarchyFAsListInStore(hierarchyArray)
    }
    if (hierarchyName === "hierarchySubjects") {
      let dummyHierarchyFAs = this.props.hierarchyFAs
      hierarchyArray = this.props.hierarchySubjects
      object.date = ""
      object["grades"] = [{ gradeName: "", gradeFrom: "", gradeTo: "" }]
      object["maxMark"] = ""
      object["minMark"] = ""
      object["passMark"] = ""
      object["distinctionMark"] = ""
      object["convertMark"] = ""
      object["needToConvertMark"] = false
      object["considerMarkToAssessmentTotal"] = false
      object["requiredToPassToGetRank"] = false
      object["term"] = dummyHierarchyFAs[index].termId
      object["fa"] = dummyHierarchyFAs[index].faId
      object["isNewBox"] = true
      object["theoryAndPractical"] = []
      hierarchyArray.push(object)
      dummyHierarchyFAs[index].subjects.push(object)
      this.props.saveExamHierarchyFAsListInStore(dummyHierarchyFAs)
      this.props.saveExamHierarchySubjectListInStore(hierarchyArray)
    }
    if (hierarchyName === "hierarchyTheoryAndPractical") {
      let dummyHierarchySubjects = this.props.hierarchySubjects
      hierarchyArray = this.props.hierarchyTheoryAndPractical
      object.date = ""
      object.activities = []
      object["grades"] = [{ gradeName: "", gradeFrom: "", gradeTo: "" }]
      object["maxMark"] = ""
      object["minMark"] = ""
      object["passMark"] = ""
      object["distinctionMark"] = ""
      object["convertMark"] = ""
      object["needToConvertMark"] = false
      object["includeMarkToSubjectTotal"] = false
      object["term"] = dummyHierarchySubjects[index].termId
      object["fa"] = dummyHierarchySubjects[index].faId
      object["subject"] = dummyHierarchySubjects[index].subjectId
      object["classSubjectId"] = dummyHierarchySubjects[index].classSubjectId
      object["isNewBox"] = true
      hierarchyArray.push(object)
      dummyHierarchySubjects[index].theoryAndPractical.push(object)
      this.props.saveExamHierarchySubjectListInStore(dummyHierarchySubjects)
      this.props.saveExamHierarchyTheoryAndPracticalListInStore(hierarchyArray)
    }
    if (hierarchyName === "hierarchyActivities") {
      let dummyHierarchyTheoryAndPractical = this.props
        .hierarchyTheoryAndPractical
      hierarchyArray = this.props.hierarchyActivities
      object.date = ""
      object.activities = []
      object["grades"] = [{ gradeName: "", gradeFrom: "", gradeTo: "" }]
      object["maxMark"] = ""
      object["minMark"] = ""
      object["passMark"] = ""
      object["distinctionMark"] = ""
      object["convertMark"] = ""
      object["needToConvertMark"] = false
      object["includeMarkToSubjectTotal"] = false
      object["term"] = dummyHierarchyTheoryAndPractical[index].termId
      object["fa"] = dummyHierarchyTheoryAndPractical[index].faId
      object["subject"] = dummyHierarchyTheoryAndPractical[index].subjectId
      object["classSubjectId"] =
        dummyHierarchyTheoryAndPractical[index].classSubjectId
      object["practicalId"] =
        dummyHierarchyTheoryAndPractical[index].practicalId
      object["isNewBox"] = true
      hierarchyArray.push(object)
      dummyHierarchyTheoryAndPractical[index].activities.push(object)
      this.props.saveExamHierarchyTheoryAndPracticalListInStore(
        dummyHierarchyTheoryAndPractical
      )
      this.props.saveExamHierarchyActivityListInStore(hierarchyArray)
    }
  }

  saveExamHierarchyTerms = object => {
    object.termFrom = moment(object.date[0]).format("YYYY-MM-DD")
    object.termTo = moment(object.date[1]).format("YYYY-MM-DD")
    object.classId = this.state.selectedClass
    this.props.saveExamHierarchyTermsList(object, () => {})
  }

  saveExamHierarchyFAs = object => {
    if (object.date.length > 0) {
      object.assessmentFrom = moment(object.date[0]).format("YYYY-MM-DD")
      object.assessmentTo = moment(object.date[1]).format("YYYY-MM-DD")
    } else {
      object.assessmentFrom = ""
      object.assessmentTo = ""
    }
    object.classId = this.state.selectedClass
    if (object.assessmentFrom === "") {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.OOPS,
        "Need to add date for Assessments..."
      )
    } else this.props.saveExamHierarchyFAsDate(object, () => {})
  }

  saveExamHierarchySubject = object => {
    object.examDay = moment(object.date).format("YYYY-MM-DD")
    object.classId = this.state.selectedClass
    this.props.saveExamHierarchySubjectList(object, () => {})
  }

  saveExamHierarchyActivity = object => {
    object.activityDay = moment(object.date).format("YYYY-MM-DD")
    object.classId = this.state.selectedClass
    this.props.saveExamHierarchyActivityList(object, () => {})
  }

  closeMessageModal = () => this.setState({ isMessageModalOpen: false })

  onClickMessageIcon = () => {
    this.setState({ isMessageModalOpen: !this.state.isMessageModalOpen })
  }

  onSubmitMessage = () => this.closeMessageModal()

  onChangeSelectedClass = e => {
    this.props.updateSelectedIndices({
      termIndex: 0,
      faIndex: 0,
      subjectIndex: 0,
      theoryAndPracticalIndex: 0,
      activityIndex: 0,
    })
    this.setState(
      {
        isShowSettings: false,
        hierarchyName: "",
        selectedClass: e.target.value,
      },
      () => {
        let findClass = this.props.classList.find(
          item => item.id === this.state.selectedClass
        )
        this.setState({ selectedClassName: findClass.value })
        this.getExamHierarchyDetailsService(this.state.selectedClass)
      }
    )
  }

  // Terms
  viewTermDock = () => {
    // this.setState({ viewFADock: true })
    // let subjectName = ""
    // let faName = ""
    // let termName = item.name
    // this.props.history.push({
    //   pathname: URL_PATH.TEACHER_TERM_MARKS,
    //   state: {
    //     academicName: this.props.hierarchyDetails
    //       .name,
    //     termName,
    //     faName,
    //     subjectName,
    //     academicId: item.academicId,
    //     termId: item.termId,
    //     faId: item.faId,
    //     subjectId: item.subjectId,
    //     classId: this.state.selectedClass,
    //     className: this.state.selectedClassName,
    //     hierarchySubjectDetails: item,
    //     isFromActivity: false,
    //   },
    // })
  }

  deleteTermDock = index => {
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT,
      yes => {
        if (yes) {
          let dummyHierarchyTerms = this.props.hierarchyTerms
          let object = {
            id: dummyHierarchyTerms[index].termId,
          }
          this.props.deleteExamHierarchyTermsDetails(
            object,
            dummyHierarchyTerms[index].classId,
            () => {
              dummyHierarchyTerms.splice(index, 1)
              this.props.saveExamHierarchyTermsListInStore(dummyHierarchyTerms)
              this.props.saveExamHierarchyFAsListInStore([])
              this.props.saveExamHierarchySubjectListInStore([])
              this.props.saveExamHierarchyTheoryAndPracticalListInStore([])
              this.props.saveExamHierarchyActivityListInStore([])
            }
          )
        }
      }
    )
  }

  hierarchyItemSelectedForTerm = index => {
    this.setState({ isShowSettings: true })
    let selectedTerm = this.props.hierarchyTerms[index]

    if (selectedTerm) {
      this.props.updateSelectedIndices({
        termIndex: index,
        faIndex: 0,
        subjectIndex: 0,
        theoryAndPracticalIndex: 0,
        activityIndex: 0,
      })

      this.setState({
        hierarchyName: "TERM",
        hierarchyIndex: index,
        hierarchyObject: selectedTerm,
      })

      let FAsList = []
      let subjectsList = []
      let theoryAndPracticalList = []
      let activitiesList = []

      if (selectedTerm.FAs.length) {
        FAsList = selectedTerm.FAs

        if (FAsList[0].subjects.length) {
          subjectsList = FAsList[0].subjects

          if (subjectsList[0].theoryAndPractical.length) {
            theoryAndPracticalList = subjectsList[0].theoryAndPractical

            if (theoryAndPracticalList[0].activities.length) {
              activitiesList = theoryAndPracticalList[0].activities
            }
          }
        }
      }

      this.props.saveExamHierarchySettingsDetailsInStore(selectedTerm)
      this.props.saveExamHierarchyFAsListInStore(FAsList)
      this.props.saveExamHierarchySubjectListInStore(subjectsList)
      this.props.saveExamHierarchyTheoryAndPracticalListInStore(
        theoryAndPracticalList
      )
      this.props.saveExamHierarchyActivityListInStore(activitiesList)
    }
  }

  // FAs
  viewFAsDock = item => {
    let subjectName = ""
    let faName = item.name
    let termName = item.termName
    this.props.history.push({
      pathname: URL_PATH.TEACHER_EXAMS_MARKS,
      state: {
        academicName: this.props.hierarchyDetails.name,
        termName,
        faName,
        subjectName,
        academicId: item.academicId,
        termId: item.termId,
        faId: item.faId,
        subjectId: item.subjectId,
        classId: this.state.selectedClass,
        className: this.state.selectedClassName,
        hierarchySubjectDetails: item,
        isFromActivity: false,
      },
    })
  }

  deleteFAsDock = index => {
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT,
      yes => {
        if (yes) {
          let dummyHierarchyFAs = this.props.hierarchyFAs
          let object = {
            id: dummyHierarchyFAs[index].faId,
          }
          if (
            dummyHierarchyFAs[index].faId &&
            dummyHierarchyFAs[index].faId !== ""
          ) {
            this.props.deleteExamHierarchyFAsDetails(
              object,
              dummyHierarchyFAs[index].sectionId,
              () => {
                dummyHierarchyFAs.splice(index, 1)
                this.props.saveExamHierarchyFAsListInStore(dummyHierarchyFAs)
                this.props.saveExamHierarchySubjectListInStore([])
                this.props.saveExamHierarchyTheoryAndPracticalListInStore([])
                this.props.saveExamHierarchyActivityListInStore([])
              }
            )
          } else {
            let dummyHierarchyTerms = this.props.hierarchyTerms
            let alreadySelectedIndex = dummyHierarchyTerms.findIndex(
              item => item.isSelected === true
            )
            if (alreadySelectedIndex !== -1) {
              dummyHierarchyTerms[alreadySelectedIndex].FAs.splice(index, 1)
              this.props.saveExamHierarchyTermsListInStore(dummyHierarchyTerms)
            }
            dummyHierarchyFAs.splice(index, 1)
            this.props.saveExamHierarchyFAsListInStore(dummyHierarchyFAs)
            this.props.saveExamHierarchySubjectListInStore([])
            this.props.saveExamHierarchyTheoryAndPracticalListInStore([])
            this.props.saveExamHierarchyActivityListInStore([])
          }
        }
      }
    )
  }

  hierarchyItemSelectedForFAs = index => {
    this.setState({ isShowSettings: true })
    let selectedFA = this.props.hierarchyFAs[index]

    if (selectedFA) {
      this.props.updateSelectedIndices({
        faIndex: index,
        subjectIndex: 0,
        theoryAndPracticalIndex: 0,
        activityIndex: 0,
      })

      this.setState({
        hierarchyName: "ASSESSMENT",
        hierarchyIndex: index,
        hierarchyObject: selectedFA,
      })

      let subjectsList = []
      let theoryAndPracticalList = []
      let activitiesList = []

      if (selectedFA.subjects.length) {
        subjectsList = selectedFA.subjects

        if (subjectsList[0].theoryAndPractical.length) {
          theoryAndPracticalList = subjectsList[0].theoryAndPractical

          if (theoryAndPracticalList[0].activities.length) {
            activitiesList = theoryAndPracticalList[0].activities
          }
        }
      }

      this.props.saveExamHierarchySettingsDetailsInStore(selectedFA)
      this.props.saveExamHierarchySubjectListInStore(subjectsList)
      this.props.saveExamHierarchyTheoryAndPracticalListInStore(
        theoryAndPracticalList
      )
      this.props.saveExamHierarchyActivityListInStore(activitiesList)
    }
  }

  // Subjects
  viewSubjectsDock = item => {
    if (
      item.maxMark !== "" &&
      item.distinctionMark !== "" &&
      item.passMark !== ""
    ) {
      let subjectName = item.name
      let faName = item.faName
      let termName = item.termName
      this.props.history.push({
        pathname: URL_PATH.TEACHER_MARKS,
        state: {
          academicName: this.props.hierarchyDetails.name,
          termName,
          faName,
          subjectName,
          academicId: item.academicId,
          termId: item.termId,
          faId: item.faId,
          subjectId: item.subjectId,
          classId: this.state.selectedClass,
          className: this.state.selectedClassName,
          hierarchySubjectDetails: item,
          isFromActivity: false,
        },
      })
    }
  }

  hierarchyItemSelectedForSubject = index => {
    this.setState({ isShowSettings: true })
    let selectedSubject = this.props.hierarchySubjects[index]

    if (selectedSubject) {
      this.props.updateSelectedIndices({
        subjectIndex: index,
        theoryAndPracticalIndex: 0,
        activityIndex: 0,
      })

      this.setState({
        hierarchyName: "SUBJECT",
        hierarchyIndex: index,
        hierarchyObject: selectedSubject,
        hierarchyClassSubjectId: selectedSubject.classSubjectId,
      })

      let theoryAndPracticalList = []
      let activitiesList = []

      if (selectedSubject.theoryAndPractical.length) {
        theoryAndPracticalList = selectedSubject.theoryAndPractical

        if (theoryAndPracticalList[0].activities.length) {
          activitiesList = theoryAndPracticalList[0].activities
        }
      }

      this.props.saveExamHierarchySettingsDetailsInStore(selectedSubject)
      this.props.saveExamHierarchyTheoryAndPracticalListInStore(
        theoryAndPracticalList
      )
      this.props.saveExamHierarchyActivityListInStore(activitiesList)
    }
  }

  deleteSubjectDock = index => {
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT,
      yes => {
        if (yes) {
          let dummyHierarchySubjects = this.props.hierarchySubjects
          let object = {
            id: dummyHierarchySubjects[index].subjectId,
          }
          if (
            dummyHierarchySubjects[index].subjectId &&
            dummyHierarchySubjects[index].subjectId !== ""
          ) {
            this.props.deleteExamHierarchySubjectDetails(
              object,
              dummyHierarchySubjects[index].classId,
              () => {
                dummyHierarchySubjects.splice(index, 1)
                this.props.saveExamHierarchySubjectListInStore(
                  dummyHierarchySubjects
                )
                this.props.saveExamHierarchyTheoryAndPracticalListInStore([])
                this.props.saveExamHierarchyActivityListInStore([])
              }
            )
          } else {
            let dummyHierarchyFAs = this.props.hierarchyFAs
            let alreadySelectedIndex = dummyHierarchyFAs.findIndex(
              item => item.isSelected === true
            )
            if (alreadySelectedIndex !== -1) {
              dummyHierarchyFAs[alreadySelectedIndex].subjects.splice(index, 1)
              this.props.saveExamHierarchyFAsListInStore(dummyHierarchyFAs)
            }
            dummyHierarchySubjects.splice(index, 1)
            this.props.saveExamHierarchySubjectListInStore(
              dummyHierarchySubjects
            )
            this.props.saveExamHierarchyTheoryAndPracticalListInStore([])
            this.props.saveExamHierarchyActivityListInStore([])
          }
        }
      }
    )
  }

  errorSubjectsDock = () => {
    this.props.showNotification(
      DANGER_TOAST,
      VALIDATION_MSG.OOPS,
      "Need to add date for Assessments..."
    )
  }

  // TheoryAndPractical
  viewTheoryAndPracticalDock = item => {
    // if (
    //   item.maxMark !== "" &&
    //   item.distinctionMark !== "" &&
    //   item.passMark !== ""
    // ) {
    let subjectName = item.subjectName
    let faName = item.faName
    let termName = item.termName
    this.props.history.push({
      pathname: URL_PATH.TEACHER_MARKS,
      state: {
        academicName: this.props.hierarchyDetails.name,
        termName,
        faName,
        subjectName,
        theoryOrPracticalName: item.name,
        academicId: item.academicId,
        termId: item.termId,
        faId: item.faId,
        subjectId: item.subjectId,
        classId: this.state.selectedClass,
        className: this.state.selectedClassName,
        hierarchySubjectDetails: item,
        isFromActivity: false,
      },
    })
  }

  hierarchyItemSelectedForTAndP = index => {
    this.setState({ isShowSettings: true })
    let selectedTheoryAndPractical = this.props.hierarchyTheoryAndPractical[
      index
    ]

    if (selectedTheoryAndPractical) {
      this.props.updateSelectedIndices({
        theoryAndPracticalIndex: index,
        activityIndex: 0,
      })

      this.setState({
        hierarchyName: "THEORY AND PRACTICAL",
        hierarchyIndex: index,
        hierarchyObject: selectedTheoryAndPractical,
        hierarchyClassSubjectId: selectedTheoryAndPractical.classSubjectId,
      })

      let activitiesList = selectedTheoryAndPractical.activities || []

      this.props.saveExamHierarchySettingsDetailsInStore(
        selectedTheoryAndPractical
      )
      this.props.saveExamHierarchyActivityListInStore(activitiesList)
    }
  }

  // Activities
  viewActivitiesDock = item => {
    if (
      item.maxMark !== "" &&
      item.distinctionMark !== "" &&
      item.passMark !== ""
    ) {
      let subjectName = item.subjectName
      let faName = item.faName
      let termName = item.termName
      let activityName = item.name
      this.props.history.push({
        pathname: URL_PATH.TEACHER_MARKS,
        state: {
          academicName: this.props.hierarchyDetails.name,
          termName,
          faName,
          subjectName,
          theoryOrPracticalName: "Practical",
          activityName,
          academicId: item.academicId,
          termId: item.termId,
          faId: item.faId,
          subjectId: item.subjectId,
          classId: this.state.selectedClass,
          className: this.state.selectedClassName,
          hierarchySubjectDetails: item,
          isFromActivity: true,
        },
      })
    }
  }

  hierarchyItemSelectedForActivities = index => {
    this.setState({ isShowSettings: true })
    let selectedActivity = this.props.hierarchyActivities[index]

    if (selectedActivity) {
      this.props.updateSelectedIndices({
        activityIndex: index,
      })

      this.setState({
        hierarchyName: "ACTIVITY",
        hierarchyIndex: index,
        hierarchyObject: selectedActivity,
        hierarchyClassSubjectId: selectedActivity.classSubjectId,
      })

      this.props.saveExamHierarchySettingsDetailsInStore(selectedActivity)
    }
  }

  deleteActivityDock = index => {
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT,
      yes => {
        if (yes) {
          let dummyHierarchyActivities = this.props.hierarchyActivities
          let object = {
            id: dummyHierarchyActivities[index].activityId,
          }
          if (
            dummyHierarchyActivities[index].activityId &&
            dummyHierarchyActivities[index].activityId !== ""
          ) {
            this.props.deleteExamHierarchyActivityDetails(
              object,
              dummyHierarchyActivities[index].classId,
              () => {
                dummyHierarchyActivities.splice(index, 1)
                this.props.saveExamHierarchyActivityListInStore(
                  dummyHierarchyActivities
                )
              }
            )
          } else {
            let dummyHierarchyTheoryAndPractical = this.props
              .hierarchyTheoryAndPractical
            let alreadySelectedIndex = dummyHierarchyTheoryAndPractical.findIndex(
              item => item.isSelected === true
            )
            if (alreadySelectedIndex !== -1) {
              dummyHierarchyTheoryAndPractical[
                alreadySelectedIndex
              ].activities.splice(index, 1)
              this.props.saveExamHierarchyTheoryAndPracticalListInStore(
                dummyHierarchyTheoryAndPractical
              )
            }
            dummyHierarchyActivities.splice(index, 1)
            this.props.saveExamHierarchyActivityListInStore(
              dummyHierarchyActivities
            )
          }
        }
      }
    )
  }

  onClkSubjectViewBtn = () => {
    if (
      this.state.hierarchyName === "SUBJECT" &&
      this.state.hierarchyObject.subjectId
    ) {
      if (this.state.hierarchyObject.enteredAssessmentDate)
        this.viewSubjectsDock(this.state.hierarchyObject)
      else this.errorSubjectsDock()
    }

    if (this.state.hierarchyName === "THEORY AND PRACTICAL") {
      this.viewTheoryAndPracticalDock(this.state.hierarchyObject)
    }

    if (
      this.state.hierarchyName === "ACTIVITY" &&
      this.state.hierarchyObject.activityId
    ) {
      this.viewActivitiesDock(this.state.hierarchyObject)
    }
  }

  render() {
    return (
      <AppWrapper
        openMessageModal={this.onClickMessageIcon}
        showTeacherThree
        showTeacherLogo
        sideMenuCollapsed
        screenName="GRADEBOOK"
      >
        <MessageModal
          isModalOpen={this.state.isMessageModalOpen}
          closeModal={this.onClickMessageIcon}
          onSubmitMessage={this.onSubmitMessage}
        />

        <div className="d-flex justify-content-between mx-3">
          <div className="mr-3">
            <select
              className="form-control"
              value={this.state.selectedClass}
              onChange={this.onChangeSelectedClass}
            >
              {this.props.classList &&
                this.props.classList.map(item => (
                  <option key={item.id} value={item.id}>
                    {item.value}
                  </option>
                ))}
            </select>
          </div>
          <div className="mb-2 text-right">
            {/* <select
               className="mr-3 mb-2"
               value={this.state.selectedPlan}
               onChange={e => this.setState({ selectedPlan: e.target.value })}
             >
               <option>SCHOLASTIC</option>
               <option>CO-SCHOLASTIC</option>
             </select> */}

            {/* <button
               type="button"
               className="btn btn-sm btn-redink px-4 rounded-pill mr-3 box-shadow-none"
               //   onClick={() => this.setState({ isStudentDetailsModalOpen: true })}
             >
               <i className="fas fa-plus mr-2" />
               Create Plan
             </button> */}
          </div>
        </div>

        {this.state.isShowFullLoading || !this.props.isGradeBookLoaded ? (
          <div
            className="d-flex justify-content-center"
            style={{ position: "absolute", left: "50%", top: "50%" }}
          >
            <HashLoader size={35} color="#084353" loading sizeUnit="px" />
          </div>
        ) : (
          <div className={`row ${this.props.isShowLoading ? "disabled" : ""}`}>
            <div className="col-lg-9">
              {/* Hierarchy Starts */}
              <div className="my-1 h-100">
                {/* Academic box */}
                <div className="d-flex justify-content-center">
                  {this.props.hierarchyDetails !== {} && (
                    <div
                      className="outer-box-hierarchy-academic gr-outer-box"
                      id="academicYear"
                      onClick={() => {
                        this.setState({
                          isShowSettings: false,
                          hierarchyName: "",
                          hierarchyIndex: "",
                        })
                      }}
                    >
                      <div className="inner-box-hierarchy-academic gr-inner-box gr-selected">
                        <div className="text-center">
                          <b>{this.props.hierarchyDetails.name}</b>
                        </div>
                        <div className="gr-line"></div>
                      </div>
                    </div>
                  )}
                </div>

                {/* Term box */}
                <div className="d-flex justify-content-center mt-4">
                  {this.props.hierarchyTerms.length > 0 && (
                    <div className="outer-box-hierarchy gr-outer-box cursor-pointer">
                      {this.props.hierarchyTerms.map((item, index) => (
                        <HierarchyItem
                          item={item}
                          index={index}
                          hierarchyTerms
                          hierarchyName="hierarchyTerms"
                          selectedIndex={this.props.selectedTermIndex}
                          newFADock={() =>
                            this.saveNewBlock("hierarchyFAs", index)
                          }
                          viewTermDock={this.viewTermDock}
                          deleteTermDock={() => this.deleteTermDock(index)}
                          hierarchyItemSelected={() =>
                            this.hierarchyItemSelectedForTerm(index)
                          }
                        />
                      ))}
                    </div>
                  )}
                </div>

                {/* FA boxes */}
                {this.state.viewFADock && (
                  <div className="d-flex justify-content-center mt-4">
                    {this.props.hierarchyFAs.length > 0 && (
                      <div className="outer-box-hierarchy gr-outer-box cursor-pointer">
                        {this.props.hierarchyFAs.map((item, index) => (
                          <HierarchyItem
                            item={item}
                            index={index}
                            hierarchyFAs
                            hierarchyName="hierarchyFAs"
                            selectedIndex={this.props.selectedFAIndex}
                            newSubjectsDock={() =>
                              this.saveNewBlock("hierarchySubjects", index)
                            }
                            noAccessToView={
                              (store.get("userSession") &&
                                store.get("userSession").userInfo &&
                                store.get("userSession").userInfo.role === 4) ||
                              this.state.isClassTeacherForThisSection === false
                            }
                            viewFAsDock={() => this.viewFAsDock(item)}
                            deleteFAsDock={() => this.deleteFAsDock(index)}
                            hierarchyItemSelected={() =>
                              this.hierarchyItemSelectedForFAs(index)
                            }
                          />
                        ))}
                      </div>
                    )}
                  </div>
                )}

                {/* Subject boxes */}
                {this.state.viewSubjectsDock && (
                  <div className="d-flex justify-content-center mt-4">
                    {this.props.hierarchySubjects.length > 0 && (
                      <div className="outer-box-hierarchy gr-outer-box cursor-pointer">
                        {this.props.hierarchySubjects.map((item, index) => (
                          <HierarchyItem
                            item={item}
                            index={index}
                            hierarchySubjects
                            hierarchyName="hierarchySubjects"
                            selectedIndex={this.props.selectedSubjectIndex}
                            errorSubjectsDock={this.errorSubjectsDock}
                            viewSubjectsDock={() => this.viewSubjectsDock(item)}
                            deleteSubjectDock={() =>
                              this.deleteSubjectDock(index)
                            }
                            newActivitiesDock={() =>
                              this.saveNewBlock(
                                "hierarchyTheoryAndPractical",
                                index
                              )
                            }
                            hierarchyItemSelected={() =>
                              this.hierarchyItemSelectedForSubject(index)
                            }
                          />
                        ))}
                      </div>
                    )}
                  </div>
                )}

                {/* TheoryAndPractical boxes */}
                <div className="d-flex justify-content-center mt-4">
                  {this.props.hierarchyTheoryAndPractical.length > 0 && (
                    <div className="outer-box-hierarchy gr-outer-box cursor-pointer">
                      {this.props.hierarchyTheoryAndPractical.map(
                        (item, index) => (
                          <HierarchyItem
                            item={item}
                            index={index}
                            hierarchyTheoryAndPractical
                            hierarchyName="hierarchyTheoryAndPractical"
                            selectedIndex={
                              this.props.selectedTheoryAndPracticalndex
                            }
                            newActivitiesDock={() =>
                              this.saveNewBlock("hierarchyActivities", index)
                            }
                            viewTheoryAndPracticalDock={() =>
                              this.viewTheoryAndPracticalDock(item)
                            }
                            hierarchyItemSelected={() =>
                              this.hierarchyItemSelectedForTAndP(index)
                            }
                          />
                        )
                      )}
                    </div>
                  )}
                </div>

                {/* Activities boxes */}
                {this.state.viewActivitiesDock && (
                  <div className="d-flex justify-content-center mt-4">
                    {this.props.hierarchyActivities &&
                      this.props.hierarchyActivities.length > 0 && (
                        <div className="outer-box-hierarchy gr-outer-box cursor-pointer">
                          {this.props.hierarchyActivities.map((item, index) => (
                            <HierarchyItem
                              item={item}
                              index={index}
                              hierarchyActivities
                              hierarchyName="hierarchyActivities"
                              selectedIndex={this.props.selectedActivityIndex}
                              deleteActivityDock={() =>
                                this.deleteActivityDock(index)
                              }
                              viewActivitiesDock={() =>
                                this.viewActivitiesDock(item)
                              }
                              hierarchyItemSelected={() =>
                                this.hierarchyItemSelectedForActivities(index)
                              }
                            />
                          ))}
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>

            {this.state.isShowSettings ? (
              <div className="position-absolute gr-book-setting-abs-wrapper">
                <ExamHierarchySettings
                  name={this.state.hierarchyName}
                  index={this.state.hierarchyIndex}
                  object={this.state.hierarchyObject}
                  classId={this.state.selectedClass}
                  className={this.state.className}
                  academicId={this.props.hierarchyDetails.id}
                  academicName={this.props.hierarchyDetails.name}
                  classSubjectId={this.state.hierarchyClassSubjectId}
                  onClkSubjectViewBtn={this.onClkSubjectViewBtn}
                  closeSettings={() => this.setState({ isShowSettings: false })}
                />
              </div>
            ) : this.state.hierarchyName !== "" ? (
              <div
                className="cent-btn"
                onClick={() => {
                  this.setState({ isShowSettings: true })
                }}
              >
                <i className="far fa-cog fs-20 cursor-pointer text-white align-self-center" />
              </div>
            ) : null}
          </div>
        )}
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    isGradeBookLoaded: state.hierarchyState.isGradeBookLoaded,
    classList: state.takeAttendanceState.teacherClassList,
    hierarchyDetails: state.hierarchyState.hierarchyDetails,
    hierarchyTerms: state.hierarchyState.hierarchyTerms,
    hierarchyFAs: state.hierarchyState.hierarchyFAs,
    hierarchySubjects: state.hierarchyState.hierarchySubjects,
    hierarchyTheoryAndPractical:
      state.hierarchyState.hierarchyTheoryAndPractical,
    hierarchyActivities: state.hierarchyState.hierarchyActivity,
    isShowLoading: state.loginState.isShowLoading,
    selectedTermIndex: state.hierarchyState.selected.termIndex,
    selectedFAIndex: state.hierarchyState.selected.faIndex,
    selectedSubjectIndex: state.hierarchyState.selected.subjectIndex,
    selectedTheoryAndPracticalndex:
      state.hierarchyState.selected.theoryAndPracticalIndex,
    selectedActivityIndex: state.hierarchyState.selected.activityIndex,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getClassList,
      gradeBookLoaded,
      updateSelectedIndices,
      getSubjectListDetails,
      getActivityConditionListDetails,
      getExamHierarchyDetails,
      saveExamHierarchySubjectListInStore,
      saveExamHierarchyFAsListInStore,
      saveExamHierarchyTermsListInStore,
      saveExamHierarchyActivityListInStore,
      deleteExamHierarchyTermsDetails,
      deleteExamHierarchyFAsDetails,
      deleteExamHierarchySubjectDetails,
      deleteExamHierarchyActivityDetails,
      saveExamHierarchySettingsDetailsInStore,
      saveExamHierarchyTermsList,
      saveExamHierarchyFAsList,
      saveExamHierarchySubjectList,
      saveExamHierarchyActivityList,
      saveExamHierarchyFAsDate,
      saveExamHierarchyTheoryAndPracticalListInStore,
      showNotification,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(ExamHierarchy)
