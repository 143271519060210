import React, { useState } from "react"
import UpgradeModal from "./UpgradeModal"
import "./index.css"

import stu from "../../../../assets/images/admin/stu.png"

function UpgradeStudentsClass({ getStudents }) {
  const [open, setOpen] = useState(false)

  const updateModal = () => setOpen(p => !p)

  return (
    <>
      <div className="d-flex flex-column align-items-center justify-content-center my-5 text-center">
        <img src={stu} alt="" className="mt-5 mb-2" />

        <p className="mb-3 fs-18 fw-600">No Students Data found</p>

        <p>
          Looks like there is no student available <br />
          in this academic year
        </p>

        <button
          className="mb-5 px-4 py-2 fw-500 text-white bg-5DD1AD br-8px"
          onClick={updateModal}
        >
          Add Students
        </button>
      </div>

      {open && (
        <UpgradeModal getStudents={getStudents} closeModal={updateModal} />
      )}
    </>
  )
}

export default UpgradeStudentsClass
