/*************************************************
 * RedInk
 * @exports
 * @class index.js
 * @extends Component
 * @author Sasidharan // on 01/02/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import AppWrapper from "./Wrapper"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { changePassword } from "../../actions/LoginAction"
import { VALIDATION_MSG, DANGER_TOAST } from "../../utils/Constants"
import { showNotification } from "../../actions/CommonAction"
import { URL_PATH } from "../../config/urlPath"

class ChangePasswordScreen extends Component {
  constructor(props) {
    super(props)
    document.title = "Change Password | Redink"
    this.state = {
      newPassword: "",
      confirmPassword: "",
      isSubmitting: false,
      isPasswordVisible: false,
      isConfirmPasswordVisible: false,
    }
  }

  componentDidMount = () => {}

  handleChange = event => {
    const { name, value } = event.target
    this.setState({
      [name]: value,
    })
    this.setState({ isInvalidPassword: false })
  }
  validatePassword = password => {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*_=+-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
    return re.test(password)
  }

  changePassword = () => {
    if (this.state.newPassword.trim() !== "") {
      if (!this.validatePassword(this.state.newPassword)) {
        this.setState({ isInvalidPassword: true })
        return
      } else {
        this.setState({ isInvalidPassword: false })
      }
      if (this.state.newPassword !== this.state.confirmNewPassword) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Password don't match"
        )
        return
      } else {
        this.setState({ isSubmitting: true })
        this.props.changePassword(
          this.state.newPassword,
          (success, response) => {
            if (success) {
              this.props.history.push({
                pathname: URL_PATH.PARENT,
                state: {},
              })
              this.setState({
                newPassword: "",
                confirmNewPassword: "",
              })
            }
            this.setState({ isSubmitting: false })
          }
        )
      }
    } else {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.OOPS,
        "Fill all fields"
      )
    }
  }

  render() {
    const { isInvalidPassword, isSubmitting } = this.state
    return (
      <AppWrapper
        teacherStudentRecordSidemenu
        showTeacherThree
        showTeacherLogo
        screenName="Change password"
      >
        <div className="row align-items-center h-75">
          <div className="card offset-sm-2 mt-sm-5 p-5 col-sm-8">
            {/* <div className="card-title ml-5 mt-3 text-dark ">
              <h2 className="text-dark">Change your Password</h2>
            </div> */}
            <div className="card-body row justify-content-around">
              <form className="form col-sm-6">
                <div className="form-group">
                  <label>New Password</label>
                  {/* <input
                    type="password"
                    name="newPassword"
                    onChange={this.handleChange}
                    value={this.state.newPassword}
                    className={`form-control ${
                      isInvalidPassword ? "is-invalid" : ""
                    }`}
                  /> */}
                  {this.state.isPasswordVisible ? (
                    <div
                      className="d-flex align-items-center"
                      style={{ position: "relative" }}
                    >
                      <input
                        type="text"
                        name="newPassword"
                        onChange={this.handleChange}
                        value={this.state.newPassword}
                        className={`form-control ${
                          isInvalidPassword ? "is-invalid" : ""
                        }`}
                      />
                      <i
                        className="fas fa-eye-slash cursor-pointer text-dark"
                        style={{
                          position: "absolute",
                          left: "89%",
                          // top: "22%",
                        }}
                        aria-hidden="true"
                        onClick={() =>
                          this.setState({ isPasswordVisible: false })
                        }
                      ></i>
                    </div>
                  ) : (
                    <div
                      className="d-flex align-items-center"
                      style={{ position: "relative" }}
                    >
                      <input
                        type="password"
                        name="newPassword"
                        onChange={this.handleChange}
                        value={this.state.newPassword}
                        className={`form-control ${
                          isInvalidPassword ? "is-invalid" : ""
                        }`}
                      />
                      <i
                        className="fas fa-eye cursor-pointer text-dark"
                        style={{
                          position: "absolute",
                          left: "89%",
                        }}
                        aria-hidden="true"
                        onClick={() =>
                          this.setState({ isPasswordVisible: true })
                        }
                      ></i>
                    </div>
                  )}
                  <div className="invalid-feedback">
                    Password creteria does not match.
                  </div>
                </div>
                <div className="form-group">
                  <label>Confirm New Password</label>

                  {/* <input
                    type="password"
                    className="form-control"
                    name="confirmNewPassword"
                    onChange={this.handleChange}
                    value={this.state.confirmNewPassword}
                  /> */}
                </div>
                {this.state.isConfirmPasswordVisible ? (
                  <div
                    className="d-flex align-items-center"
                    style={{ position: "relative" }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      name="confirmNewPassword"
                      onChange={this.handleChange}
                      value={this.state.confirmNewPassword}
                    />
                    <i
                      className="fas fa-eye-slash cursor-pointer text-dark"
                      style={{
                        position: "absolute",
                        left: "89%",
                        // top: "22%",
                      }}
                      aria-hidden="true"
                      onClick={() =>
                        this.setState({ isConfirmPasswordVisible: false })
                      }
                    ></i>
                  </div>
                ) : (
                  <div
                    className="d-flex align-items-center"
                    style={{ position: "relative" }}
                  >
                    <input
                      type="password"
                      className="form-control"
                      name="confirmNewPassword"
                      onChange={this.handleChange}
                      value={this.state.confirmNewPassword}
                    />
                    <i
                      className="fas fa-eye cursor-pointer text-dark"
                      style={{
                        position: "absolute",
                        left: "89%",
                      }}
                      aria-hidden="true"
                      onClick={() =>
                        this.setState({ isConfirmPasswordVisible: true })
                      }
                    ></i>
                  </div>
                )}
                <button
                  type="button"
                  className="btn mt-4 bg-theme text-white btn-block d-flex justify-content-center"
                  onClick={() => {
                    return this.changePassword()
                  }}
                >
                  {isSubmitting && <div className="loader" />}
                  Change my password
                </button>
              </form>
              <div className="mt-3 col-sm-4">
                <p>
                  <strong>Password must contain</strong>
                </p>
                <p>Atleast 1 upper case letter (A-Z)</p>
                <p>At least 1 special character</p>
                <p>At least 1 number (0-9)</p>
                <p>At least 8 characters</p>
              </div>
            </div>
          </div>
        </div>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      changePassword,
      showNotification,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(ChangePasswordScreen)
