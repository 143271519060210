import React from "react"
import AppWrapper from "../Wrapper"
import {
  Button,
  FormGroup,
  Label,
  // Input,
  Modal,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import { DatePicker } from "antd"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { URL_PATH } from "../../../config/urlPath"
import moment from "moment"
// import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import {
  getSingleQuizAssign,
  updateQuiz,
  updateQuizMarks,
  getMarkReport,
  retakeStudentQuizSubmission,
} from "../../../actions/teacher/QuizAction"
import { myLog, confirmAlertPopUp } from "../../../utils/Utility"
import { HashLoader } from "react-spinners"
// import  Modal,{ModalTransition } from "@atlaskit/modal-dialog"
import { showNotification } from "../../../actions/CommonAction"
import { DANGER_TOAST, VALIDATION_MSG } from "../../../utils/Constants"
// import "../../../assets/css/publishLater.css"
// import ModalHeader from "reactstrap/lib/ModalHeader"
// import ModalFooter from "reactstrap/lib/ModalFooter"
// import ModalBody from "reactstrap/lib/ModalBody"

const start = moment()
const remainder = 30 - (start.minute() % 30)

const quizStartTime = moment(start)
  .add(remainder, "minutes")
  .set({ second: 0, millisecond: 0 })
const quizEndTime = moment(quizStartTime)
  .add(60, "minutes")
  .set({ second: 0, millisecond: 0 })

const { RangePicker } = DatePicker
// TimePicker,

// const styles = {
//   backgroundColor: "red",
// }

class MarkEntryScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      quizAssignId: "",
      isShowLoading: true,
      isEditing: false,
      title: "",
      className: "",
      sectionName: "",
      subjectName: "",
      isOnline: null,
      assignedStudents: [],
      updateStudents: [],
      linkToExam: null,
      locked: null,
      published: null,
      postedOn: null,
      timeLimit: null,
      startTime: null,
      endTime: null,
      isPublishing: false,
      isLocking: false,
      isLinking: false,
      isDownloadLoading: false,
      selectedStatus: "",
      selectedApprovalStatus: "",
      isNotViewedModalOpen: false,
      isInstructionModalOpen: false,
      instructions: null,
      retakeStartTime: quizStartTime,
      retakeEndTime: quizEndTime,
      isRetakeModalOpen: false,
      retakeStudentIndex: null,
      isPublishLaterModalOpen: false,
      publishLaterTime: quizEndTime,
    }
  }

  componentDidMount() {
    const { quizAssignInfoId } =
      (this.props.location && this.props.location.state) || {}
    myLog("quizAssignInfoId", quizAssignInfoId)
    if (!quizAssignInfoId) {
      this.props.history.push(URL_PATH.TEACHER_TESTS)
    } else {
      this.props.getSingleQuizAssign(quizAssignInfoId, (success, data) => {
        if (success) {
          this.setSingleQuiz(data)
          this.setState({
            quizAssignId: quizAssignInfoId,
          })
        } else {
          this.props.history.push(URL_PATH.TEACHER_TESTS)
        }
        this.setState({ isShowLoading: false })
      })
    }
  }

  errorHandling = message => {
    this.props.showNotification(DANGER_TOAST, VALIDATION_MSG.OOPS, message)
  }

  setSingleQuiz = data => {
    const quizAssign = data
    myLog("single quiz", data)
    const {
      teacherAssignedDetail,
      submissionType,
      quizSet,
      assignedStudents,
      linkToExam,
      locked,
      publishLater,
      published,
      createdOn,
      timeLimit,
      startTime,
      endTime,
    } = quizAssign || {}
    const { section, subject } = teacherAssignedDetail || {}

    const { className, sectionName } = section || {}
    const { subjectName } = subject || {}
    const isOnline = submissionType === "ONLINE"

    this.setState({
      quizAssign: quizAssign,
      quizSet,
      className: className || "",
      sectionName: sectionName || "",
      subjectName: subjectName || "",
      isOnline: isOnline || "",
      assignedStudents: assignedStudents || [],
      linkToExam,
      locked,
      publishLater,
      published,
      postedOn: moment(createdOn),
      timeLimit: timeLimit ? moment.duration(timeLimit).asMinutes() : null,
      startTime: moment(startTime),
      endTime: moment(endTime),
    })
  }

  updateQuiz = (body, toggle) => {
    this.setState({ [toggle]: true })
    myLog("update quiz body", body)
    this.props.updateQuiz(body, (success, data) => {
      myLog("update quiz", data, success)
      if (success) this.setSingleQuiz(data)
      this.setState({ [toggle]: false, isShowLoading: false })
    })
  }

  publishQuiz = () => {
    confirmAlertPopUp(
      "Publish Results",
      "Are you sure want to publish the results?",
      yes => {
        if (yes) {
          this.updateQuiz(
            { id: this.state.quizAssignId, published: 1, graded: 1 },
            "isPublishing"
          )
          this.setState({ isEditing: false })
        }
      },
      //custom ui code
      {
        customUI: ({ onClose }) => {
          return (
            <div
              className="custom"
              style={{
                backgroundColor: "#fff",

                borderBottomRightRadius: "12px",
                borderBottomLeftRadius: "12px",
              }}
            >
              <div
                className="bg-brand text-light d-flex"
                style={{
                  padding: "0.5rem 2rem",
                  borderTopRightRadius: "12px",
                  borderTopLeftRadius: "12px",
                }}
              >
                <div className="mx-auto" style={{ fontSize: "18px" }}>
                  Publish Results
                </div>
              </div>

              <div
                className="mt-3 "
                style={{
                  padding: "0.5rem 1.5rem",
                }}
              >
                <div className="text-muted" style={{ fontSize: "15px" }}>
                  Are you sure you want to publish the results?
                </div>
                <div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
                  <button
                    className="bg-brand text-light px-4 py-1 w-25 d-flex align-items-center justify-content-center yes-button"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      this.updateQuiz(
                        {
                          id: this.state.quizAssignId,
                          published: 1,
                          graded: 1,
                        },
                        "isPublishing"
                      )
                      onClose()
                      this.setState({ isEditing: false })
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-brand text-light px-4 py-1 ml-4 w-25 d-flex align-items-center justify-content-center"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={onClose}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )
        },
      }
    )
  }

  publishLater = () => {
    confirmAlertPopUp(
      "Publish Results",
      "Are you sure want to publish the results?",
      yes => {
        if (yes) {
          this.updateQuiz(
            {
              id: this.state.quizAssignId,
              published: 1,
              graded: 1,
              publishLater: this.state.publishLaterTime,
            },
            "isPublishing"
          )
          this.setState({ isEditing: false })
        }
      },
      {
        customUI: ({ onClose }) => {
          return (
            <div
              className="custom"
              style={{
                backgroundColor: "#fff",

                borderBottomRightRadius: "12px",
                borderBottomLeftRadius: "12px",
              }}
            >
              <div
                className="bg-brand text-light d-flex"
                style={{
                  padding: "0.5rem 2rem",
                  borderTopRightRadius: "12px",
                  borderTopLeftRadius: "12px",
                }}
              >
                <div className="mx-auto" style={{ fontSize: "18px" }}>
                  Publish Results
                </div>
              </div>

              <div
                className="mt-3 "
                style={{
                  padding: "0.5rem 1.5rem",
                }}
              >
                <div className="text-muted" style={{ fontSize: "15px" }}>
                  Are you sure you want to publish the results?
                </div>
                <div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
                  <button
                    className="bg-brand text-light px-4 py-1 w-25 d-flex align-items-center justify-content-center yes-button"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      this.updateQuiz(
                        {
                          id: this.state.quizAssignId,
                          published: 1,
                          graded: 1,
                          publishLater: this.state.publishLaterTime,
                        },
                        "isPublishing"
                      )
                      onClose()
                      this.setState({ isEditing: false })
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-brand text-light px-4 py-1 ml-4 w-25 d-flex align-items-center justify-content-center"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={onClose}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )
        },
      }
    )
  }

  reloadSingleQuiz = () => {
    this.setState({ isShowLoading: true })
    if (!this.state.quizAssignId) {
      this.props.history.push(URL_PATH.TEACHER_TESTS)
    } else {
      this.props.getSingleQuizAssign(
        this.state.quizAssignId,
        (success, data) => {
          if (success) {
            this.setSingleQuiz(data)
          }
          this.setState({ isShowLoading: false })
        }
      )
    }
  }

  unpublishQuiz = () => {
    confirmAlertPopUp(
      "Unpublish Results",
      "Are you sure want to unpublish the results?",
      yes => {
        if (yes) {
          this.updateQuiz(
            { id: this.state.quizAssignId, published: 0 },
            "isPublishing"
          )
        }
      },
      {
        customUI: ({ onClose }) => {
          return (
            <div
              className="custom"
              style={{
                backgroundColor: "#fff",

                borderBottomRightRadius: "12px",
                borderBottomLeftRadius: "12px",
              }}
            >
              <div
                className="bg-brand text-light d-flex"
                style={{
                  padding: "0.5rem 2rem",
                  borderTopRightRadius: "12px",
                  borderTopLeftRadius: "12px",
                }}
              >
                <div className="mx-auto" style={{ fontSize: "18px" }}>
                  Unpublish Results
                </div>
              </div>

              <div
                className="mt-3 "
                style={{
                  padding: "0.5rem 1.5rem",
                }}
              >
                <div className="text-muted" style={{ fontSize: "15px" }}>
                  Are you sure you want to Unpublish the results?
                </div>
                <div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
                  <button
                    className="bg-brand text-light px-4 py-1 w-25 d-flex align-items-center justify-content-center yes-button"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      this.updateQuiz(
                        { id: this.state.quizAssignId, published: 0 },
                        "isPublishing"
                      )
                      onClose()
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-brand text-light px-4 py-1 ml-4 w-25 d-flex align-items-center justify-content-center"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={onClose}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )
        },
      }
    )
  }

  lockQuiz = () => {
    this.updateQuiz({ id: this.state.quizAssignId, locked: 1 }, "isLocking")
  }

  unlockQuiz = () => {
    this.updateQuiz({ id: this.state.quizAssignId, locked: 0 }, "isLocking")
  }

  linkToExam = () => {
    this.updateQuiz({ id: this.state.quizAssignId, linkToExam: 1 }, "isLinking")
  }

  unlinkToExam = () => {
    this.updateQuiz({ id: this.state.quizAssignId, linkToExam: 0 }, "isLinking")
  }

  quizHashLoader = () => (
    <div
      className="d-flex justify-content-center"
      style={{ position: "absolute", left: "50%", top: "50%" }}
    >
      <HashLoader
        sizeUnit={"px"}
        size={35}
        color={"#084353"}
        loading={this.state.isShowLoading}
      />
    </div>
  )

  updateQuizMarks = () => {
    this.setState({ isSaving: true })
    let hasError = false
    for (let item of this.state.assignedStudents) {
      if (
        item.gradedMark
          ? item.gradedMark > this.state.quizSet.totalMark ||
            item.gradedMark < 0
          : false
      ) {
        hasError = true
        break
      }
    }
    if (hasError) {
      this.props.showNotification(
        DANGER_TOAST,
        null,
        "Marks cannot be more than max marks"
      )
      this.setState({ isSaving: false })
    } else {
      this.props.updateQuizMarks(
        this.state.quizAssignId,
        { assignedStudents: this.state.assignedStudents },
        (success, data) => {
          if (success) {
            this.setSingleQuiz(data)
          }
          this.setState({ isSaving: false, isEditing: false })
        }
      )
    }
  }

  /* to update marks */
  setUpdateMarks = () => {
    this.setState(state => ({
      updateStudents: state.assignedStudents,
    }))
  }

  onRetake = () => {
    const { assignedStudents } = this.state
    if (!this.state.retakeStartTime || !this.state.retakeEndTime) return
    const selectedStudent = assignedStudents[this.state.retakeStudentIndex]
    // myLog("selectedStudent", selectedStudent)
    this.setState({ isRetakeLoading: true })
    this.props.retakeStudentQuizSubmission(
      {
        id: selectedStudent.id,
        startTime: moment(this.state.retakeStartTime, "YYYY-MM-DD HH:mm:ss"),
        endTime: moment(this.state.retakeEndTime, "YYYY-MM-DD HH:mm:ss"),
      },
      (success, repsonse) => {
        this.setState({
          isRetakeModalOpen: false,
          retakeStartTime: quizStartTime,
          retakeEndTime: quizEndTime,
          isSaving: false,
          isEditing: false,
        })
        this.reloadSingleQuiz()
      }
    )
  }

  // onRetake = () => {
  //   const retakeAssignedStudent1 = this.state.assignedStudents[
  //     this.state.retakeStudentIndex
  //   ]
  //   let retakeAssignedStudent = {}
  //   retakeAssignedStudent.retake = 1
  //   retakeAssignedStudent.startTime = this.state.retakeStartTime
  //   retakeAssignedStudent.endTime = this.state.retakeEndTime
  //   retakeAssignedStudent.id = retakeAssignedStudent1.id
  //   this.setState({
  //     retakeStartTime: quizStartTime,
  //     retakeEndTime: quizEndTime,
  //     isRetakeModalOpen: false,
  //   })

  //   this.props.updateQuizMarks(
  //     this.state.quizAssignId,
  //     { assignedStudents: [retakeAssignedStudent] },
  //     (success, data) => {
  //       if (success) {
  //         this.setSingleQuiz(data)
  //       }
  //       this.setState({ isSaving: false, isEditing: false })
  //     }
  //   )
  // }

  downloadMarkReport = () => {
    this.setState({ isDownloadLoading: true })
    this.props.getMarkReport(
      this.state.quizAssignId,
      async (success, response) => {
        if (success) {
          const file = new Blob([response], {
            type: "application/pdf",
          })
          const browserUrl = window.webkitURL || window.URL
          const fileUrl = browserUrl.createObjectURL(file)
          const a = document.createElement("a")
          a.href = fileUrl
          a.download = "mark-report.pdf"
          a.click()
          browserUrl.revokeObjectURL(fileUrl)
        }
        this.setState({ isDownloadLoading: false })
      }
    )
  }

  renderAssignedStudent = (assign, index) => {
    const { isOnline } = this.state
    const { id, student, gradedMark, comment } = assign
    const { submitted } = assign
    // var { gender, studentName, fullName } = student
    if (student !== null) {
      var { gender, studentName, fullName } = student
    }

    const hasError =
      // (typeof gradedMark !== "number" &&
      //   gradedMark !== "" &&
      //   gradedMark !== null) ||
      gradedMark > this.state.quizSet.totalMark
    return (
      <div
        key={id}
        className="row align-items-center my-2 py-2"
        style={{ borderRadius: 16, border: "1px solid #e2e2e2" }}
      >
        {/* <div className="col-1"></div> */}
        <div
          className={`col-3 d-flex ${isOnline ? "pl-2" : "pl-4"}`}
          style={{ alignItems: "center" }}
        >
          <img
            src={
              gender === "M"
                ? require("../../../assets/images/boy.png")
                : require("../../../assets/images/girl.png")
            }
            style={{ width: 35, height: 35 }}
            alt=""
          />
          <span className="ml-2">{studentName || fullName}</span>
        </div>
        {isOnline ? (
          <div className="col-2">
            <span>
              {!isOnline ? " - " : null}
              {isOnline && submitted ? "Submitted" : null}
              {isOnline && !submitted ? "Not Submitted" : null}
            </span>
          </div>
        ) : null}

        {isOnline ? (
          <div className={`col-1 ${submitted ? " pl-0" : "pl-4"}`}>
            {submitted ? (
              <Link
                onClick={e => {
                  e.preventDefault()
                  if (this.state.published) {
                    this.errorHandling("Unpublish result to view Students work")
                  } else {
                    this.props.history.push({
                      pathname: URL_PATH.TEACHER_TESTS_MARK_EVALUATION,
                      state: {
                        studentAssign: assign,
                        currentStudent: this.state.assignedStudents.findIndex(
                          ({ id: aid }) => aid === id
                        ),
                        assignedStudents: this.state.assignedStudents,
                        quizAssign: this.state.quizAssign,
                        quizAssignId: this.state.quizAssignId,
                      },
                    })
                  }
                }}
                className="ml-3"
                style={{ textDecoration: "underline", color: "#04B191" }}
              >
                View
              </Link>
            ) : (
              <div className="text-muted"> - </div>
            )}
          </div>
        ) : (
          <div className="col-1 text-center"></div>
        )}

        <div className="col-2">
          <input
            type="number"
            className={`form-control ${hasError && "border border-danger"}`}
            id={`mark-${id}`}
            name={`mark-${id}`}
            style={{ width: "100%" }}
            value={gradedMark}
            onChange={e => {
              const value = e.target.value
              // myLog("value", e.target)
              let assignedStudents = this.state.assignedStudents
              assignedStudents[index].gradedMark = value
              this.setState({ assignedStudents })
            }}
            disabled={!this.state.isEditing || this.state.isOnline}
          />
        </div>
        {!isOnline ? <div className="col-1"></div> : null}
        <div className="col-2">
          <input
            type="text"
            className="form-control"
            id={`comment-${id}`}
            name={`comment-${id}`}
            style={{ width: "100%" }}
            value={comment || ""}
            onChange={e => {
              const value = e.target.value
              let assignedStudents = this.state.assignedStudents
              assignedStudents[index].comment = value
              this.setState({ assignedStudents })
            }}
            disabled={!this.state.isEditing}
          />
        </div>
        {!isOnline ? <div className="col-1"></div> : null}
        <div className="col-2">
          <div className="d-flex align-items-center justify-content-center">
            {this.state.isEditing ? (
              <>
                <i
                  className={`far fa-check-circle mr-3 ${
                    assign.graded ? "text-green" : "cursor-pointer text-muted"
                  }`}
                  onClick={() => {
                    let { assignedStudents } = this.state
                    assignedStudents[index].graded = 1
                    assignedStudents[index].retake = null
                    this.setState({ assignedStudents })
                  }}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Approved"
                />
                <i
                  className={`far fa-redo mr-3 ${
                    assign.retake ? "text-warning" : "cursor-pointer text-muted"
                  }`}
                  onClick={() => {
                    // let { assignedStudents } = this.state
                    // assignedStudents[index].graded = 0
                    // assignedStudents[index].retake = 1
                    // this.setState({ assignedStudents })
                    if (assign.retake === 0) {
                      this.setState({
                        isRetakeModalOpen: true,
                        retakeStudentIndex: index,
                      })
                    }
                  }}
                  data-toggle="tooltip"
                  data-placement="top"
                  title={
                    assign.retake === 1
                      ? "Rework Scheduled " +
                        moment(assign.startTime).format(`DD MMM YYYY HH:mm a`)
                      : "Rework"
                  }
                />
                <i
                  className={`far fa-clock mr-3 ${
                    !assign.retake && !assign.submitted && !assign.graded
                      ? "text-danger"
                      : "cursor-pointer text-muted"
                  }`}
                  onClick={() => {
                    let { assignedStudents } = this.state
                    assignedStudents[index].graded = 0
                    assignedStudents[index].retake = null
                    this.setState({ assignedStudents })
                  }}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Awaiting"
                />
              </>
            ) : assign.graded && !assign.retake ? (
              <div>
                <i
                  className={`far fa-check-circle mr-3 text-green`}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Graded"
                />
                <span>Graded</span>
              </div>
            ) : assign.retake ? (
              <div>
                <i
                  className={`far fa-redo mr-3 text-warning`}
                  data-toggle="tooltip"
                  data-placement="top"
                  title={
                    assign.retake === 1
                      ? "Retake Scheduled " +
                        moment(assign.startTime).format(`DD MMM YYYY HH:mm a`)
                      : "Retake"
                  }
                />
                <span>Retake</span>
              </div>
            ) : !assign.submitted || !assign.graded ? (
              <div className="d-flex">
                <i
                  className={`far fa-clock mr-3 text-danger`}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Awaiting"
                />
                <span>Awaiting</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    )
  }

  retakeModal = () => (
    <Modal
      isOpen={this.state.isRetakeModalOpen}
      backdropClassName="modal-backdrop-show"
      modalClassName="modal-dialog-shadow-none"
      contentClassName="border-14px"
      centered={true}
    >
      <div className="modal-body p-0">
        <header
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            padding: "15px 0 0 24px",
          }}
        >
          Select Retest Date
        </header>
        <div className="form-group px-4 col-sm-12">
          <div className="pt-3 pl-4" style={{ backgroundColor: "aliceblue" }}>
            <FormGroup style={{ marginRight: "10px", height: "11vh" }}>
              <Label className="mb-1 text-brand font-weight-bold" htmlFor="">
                Retake Date & Time
                <i
                  className="ml-2 fas fa-question-circle text-muted"
                  id="dateTimeTooltip"
                />
              </Label>
              <UncontrolledTooltip placement="top" target="dateTimeTooltip">
                Date & Time is the period where the retake test will be open.
              </UncontrolledTooltip>
              <div
                className={`d-flex form-control ${this.state.retakeEndTime}`}
              >
                <RangePicker
                  showTime={{ user12hours: true }}
                  bordered={false}
                  // style={{ width: "100%" }}
                  value={[this.state.retakeStartTime, this.state.retakeEndTime]}
                  onChange={value => {
                    const [startTime, endTime] = value || [moment(), moment()]
                    this.setState({
                      retakeStartTime: startTime,
                      retakeEndTime: endTime,
                    })
                  }}
                  format="DD MMM YYYY, hh:mm A"
                  disabledDate={current =>
                    current && current < moment().startOf("day")
                  }
                />
              </div>
            </FormGroup>
            {""}
          </div>
        </div>
        <div className="col-md-12 pb-3 text-right">
          <Button
            style={{
              backgroundColor: "#DBDBDB",
              color: "#06B191",
              width: "6rem",
              fontSize: "15px",
              borderRadius: "11px",
              borderStyle: "none",
              marginRight: "15px",
            }}
            onClick={() => this.setState({ isRetakeModalOpen: false })}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: "#06b191",
              width: "6rem",
              fontSize: "15px",
              borderRadius: "11px",
              borderStyle: "none",
              marginRight: "15px",
            }}
            onClick={() =>
              this.setState({ isRetakeModalOpen: false }, () => this.onRetake())
            }
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
    //   <ModalTransition>
    //     {this.state.isRetakeModalOpen && (
    //       <Modal
    //         heading="Retake Quiz"
    //         actions={[
    //           {
    //             text: "Save",
    //             onClick: () =>
    //               this.setState({ isRetakeModalOpen: false }, () =>
    //                 this.onRetake()
    //               ),
    //           },
    //           {
    //             text: "Cancel",
    //             onClick: () => this.setState({ isRetakeModalOpen: false }),
    //           },
    //         ]}
    //       >
    // <div className="form-group px-2 col-sm-12">
    // <div className="retake-date">
    // <div className="w-100">
    //     <FormGroup style={{marginRight:'10px'}}>
    //       <Label className="mb-1 text-brand font-weight-bold" htmlFor="">
    //         Retake Date & Time
    //         <i
    //           className="ml-2 fas fa-question-circle text-muted"
    //           id="dateTimeTooltip"
    //         />
    //       </Label>
    //       <UncontrolledTooltip placement="top" target="dateTimeTooltip">
    //         Date & Time is the period where the retake test will be open.
    //       </UncontrolledTooltip>
    //       <div
    //         className={`d-flex form-control ${this.state.retakeEndTime}`}
    //       >
    //         <RangePicker
    //           showTime={{ user12hours: true }}
    //           bordered={false}
    //           // style={{ width: "100%" }}
    //           value={[
    //             this.state.retakeStartTime,
    //             this.state.retakeEndTime,
    //           ]}
    //           onChange={value => {
    //             const [startTime, endTime] = value || [moment(), moment()]
    //             this.setState({
    //               retakeStartTime: startTime,
    //               retakeEndTime: endTime,
    //             })
    //           }}
    //           format="DD MMM YYYY, hh:mm A"
    //           disabledDate={current =>
    //             current && current < moment().startOf("day")
    //           }
    //         />
    //       </div>
    //     </FormGroup>
    //   </div>
    // </div>
    // </div>
    //       </Modal>
    //     )}
    //   </ModalTransition>
  )

  renderNotViewedModal = (notViewedData = []) => (
    <Modal
      isOpen={this.state.isNotViewedModalOpen}
      backdropClassName="modal-backdrop-show"
      modalClassName="modal-dialog-shadow-none"
      contentClassName="border-14px"
      centered
    >
      <div className="modal-body">
        <div className="d-flex justify-content-between px-2">
          <div className="font-weight-bold" style={{ fontSize: "1.2rem" }}>
            {`Not Viewed (${notViewedData.length})`}
          </div>
          <div></div>
          <div
            className="text-brand mt-2"
            onClick={() => this.setState({ isNotViewedModalOpen: false })}
          >
            <i className="fas fa-times" style={{ cursor: "pointer" }} />
          </div>
          <div></div>
        </div>
        <div style={{ height: "50vh", overflowY: "scroll" }}>
          {notViewedData.map((item, index) => (
            <div key={index} className="d-flex align-items-center px-2 py-2">
              <img
                src={
                  item.student
                    ? item.student.gender === "M"
                      ? require("../../../assets/images/boy.png")
                      : require("../../../assets/images/girl.png")
                    : require("../../../assets/images/boy.png")
                }
                alt="Student"
                className="rounded-pill"
                style={{ width: 34, height: 34 }}
              />
              <div className="text-dark ml-3" style={{ fontSize: "1rem" }}>
                {item.student ? item.student.fullName : ""}
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-end px-2 py-2">
          <Button
            className="rounded-pill"
            onClick={() => this.setState({ isNotViewedModalOpen: false })}
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
    //   <ModalTransition>
    //     {this.state.isNotViewedModalOpen && (
    //       <Modal
    //         heading={`Not Viewed (${notViewedData.length})`}
    //         actions={[
    //           {
    //             text: "Done",
    //             onClick: () => this.setState({ isNotViewedModalOpen: false }),
    //           },
    //         ]}
    //       >
    // {notViewedData.map((item, index) => (
    //   <div key={index} className="d-flex align-items-center px-2 py-2">
    //     <img
    //       src={
    //         item.student.gender === "M"
    //           ? require("../../../assets/images/boy.png")
    //           : require("../../../assets/images/girl.png")
    //       }
    //       alt="Student"
    //       className="rounded-pill"
    //       style={{ width: 34, height: 34 }}
    //     />
    //     <div className="text-dark ml-3" style={{ fontSize: "1rem" }}>
    //       {item.student.fullName}
    //     </div>
    //   </div>
    // ))}
    //       </Modal>
    //     )}
    //   </ModalTransition>
  )

  publishLaterModal = () => (
    <Modal
      isOpen={this.state.isPublishLaterModalOpen}
      backdropClassName="modal-backdrop-show"
      modalClassName="modal-dialog-shadow-none"
      contentClassName="border-14px"
      centered={true}
    >
      <div className="modal-body p-0">
        <header
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            padding: "15px 0 0 24px",
          }}
        >
          Select Publish Later Date & Time
        </header>
        <div className="form-group px-4 col-sm-12">
          <div className="pt-3 pl-4" style={{ backgroundColor: "aliceblue" }}>
            <label htmlFor="endTime" className="font-weight-bold">
              Select Date
            </label>
            <div className="w-100">
              <DatePicker
                showTime={{ use12Hours: true }}
                value={this.state.publishLaterTime}
                onChange={value => this.setState({ publishLaterTime: value })}
                disabledDate={current =>
                  current && current < moment().startOf("day")
                }
                format="DD MMM YYYY, hh:mm A"
              />
            </div>
            <br />
          </div>
        </div>
        <div className="col-md-12 pb-3 text-right">
          <Button
            style={{
              backgroundColor: "#DBDBDB",
              color: "#06B191",
              width: "6rem",
              fontSize: "15px",
              borderRadius: "11px",
              borderStyle: "none",
              marginRight: "15px",
            }}
            onClick={() => this.setState({ isPublishLaterModalOpen: false })}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: "#06b191",
              width: "6rem",
              fontSize: "15px",
              borderRadius: "11px",
              borderStyle: "none",
              marginRight: "15px",
            }}
            onClick={() =>
              this.setState({ isPublishLaterModalOpen: false }, () =>
                this.publishLater()
              )
            }
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
    //   <ModalTransition>
    //     {this.state.isPublishLaterModalOpen && (
    //       <Modal
    //       className="bg-green text-light publish-later"
    //         heading="Select Publish Later Date & Time"
    //         actions={[

    //           {
    //             text: "Save",
    //             onClick: () =>
    //               this.setState({ isPublishLaterModalOpen: false }, () =>
    //                 this.publishLater(),
    //               ),
    //               // appearance:{styles}
    //           },
    //           { className:"publish-cancel",
    //           text: "Cancel",
    //           onClick: () => this.setState({ isPublishLaterModalOpen: false }),
    //         },
    //         ]}
    //       >
    // <div className="form-group px-2 col-sm-12">
    // <div className="publish-date">
    // <label htmlFor="endTime" className="font-weight-bold">
    //     Select Date
    //   </label>
    //   <div className="w-100">
    //     <DatePicker
    //       showTime
    //       value={this.state.publishLaterTime}
    //       onChange={value => this.setState({ publishLaterTime: value })}
    //       disabledDate={current =>
    //         current && current < moment().startOf("day")
    //       }
    //     />
    //   </div>
    //   <br/>
    // </div>
    // </div>
    //       </Modal>
    //     )}
    //   </ModalTransition>
  )

  renderInstructionModal = () => (
    <Modal
      backdropClassName="modal-backdrop-show"
      modalClassName="modal-dialog-shadow-none"
      contentClassName="border-14px"
      centered={true}
      isOpen={this.state.isInstructionModalOpen}
    >
      <div className="modal-body p-0">
        <header
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            padding: "15px 0 0 24px",
          }}
        >
          Instructions
        </header>
        <p
          style={{
            height: "22vh",
            resize: "height",
            overflowY: "auto",
            padding: "15px 20px 0 24px",
          }}
        >
          {this.state.instructions}
        </p>
        {/* <div style={{ float: "right", padding: "0 15px 3px 0" }}> */}
        <div className="col-md-12 pb-3 text-right">
          <Button
            className="btn btn-secondary"
            onClick={() => this.setState({ isInstructionModalOpen: false })}
            style={{
              // backgroundColor: "#d7d7d7",
              // color: "#8d8d8d",
              width: "6rem",
              fontSize: "15px",
              borderRadius: "11px",
              borderStyle: "none",
            }}
          >
            Close
          </Button>
        </div>
      </div>{" "}
    </Modal>
    //   <ModalTransition>
    //     {this.state.isInstructionModalOpen && (
    //       <Modal
    //         heading="Instructions"
    //         actions={[
    //           {
    //             text: "Close",
    //             onClick: () => this.setState({ isInstructionModalOpen: false }),
    //           },
    //         ]}
    //       >
    //         <div className="form-group px-2 col-sm-8">
    //           <p>{this.state.instructions}</p>
    //         </div>
    //       </Modal>
    //     )}
    //   </ModalTransition>
  )

  render() {
    const {
      className,
      sectionName,
      subjectName,
      quizSet,
      isOnline,
      assignedStudents,
      linkToExam,
      locked,
      published,
      postedOn,
      timeLimit,
      startTime,
      endTime,
      isShowLoading,
    } = this.state
    const { title, instruction, totalMark } = quizSet || {}
    if (isShowLoading) {
      return (
        <AppWrapper
          teacherDashboard
          showTeacherThree
          showTeacherLogo
          screenName={"TESTS"}
        >
          {this.quizHashLoader()}
          {/*<div className="row">
            <div className="col">
              <h5
                className="font-weight-bold"
                style={{ color: "rgba(0,0,0,0)" }}
              >
                <Skeleton height="calc(1.2 * 1.25rem)" width={160} />
              </h5>
            </div>
          </div>

          <SkeletonTheme color="#094251" highlightColor="rgba(1,1,1,0.05)">
            <div className="row">
              <div className="col-6">
                <Skeleton
                  height="calc(95px + 2rem)"
                  css={{ borderRadius: 16 }}
                />
              </div>

              <div className="col-4">
                <Skeleton
                  height="calc(95px + 2rem)"
                  css={{ borderRadius: 16 }}
                />
              </div>

              <div className="col-2 ml-auto" style={{ maxWidth: 170 }}>
                <span style={{}}>
                  <SkeletonTheme
                    color="#e2e6ea"
                    highlightColor="rgba(0,0,0,0.05)"
                  >
                    <Skeleton height="calc((1.5 * 1rem) + (2 * 0.375rem))" />
                  </SkeletonTheme>
                </span>
                <span style={{ marginTop: "0.5rem" }}>
                  <Skeleton height="calc((1.5 * 1rem) + (2 * 0.375rem))" />
                </span>
                <span style={{ marginTop: "0.5rem" }}>
                  <Skeleton height="calc((1.5 * 1rem) + (2 * 0.375rem))" />
                </span>
              </div>
            </div>
          </SkeletonTheme>*/}
        </AppWrapper>
      )
    }

    let notViewedData = []
    let gradedNo = 0
    // let retestNo = 0
    let awaitingNo = 0
    let retakeStudents = []
    assignedStudents.forEach(item => {
      if (!item.viewed) {
        notViewedData.push(item)
      }
      if (item.retake) {
        // retestNo += 1
        retakeStudents.push(item)
      } else if (item.graded) {
        gradedNo += 1
      } else if (!item.submitted || !item.graded) {
        awaitingNo += 1
      }
    })
    // myLog("not viewed data",notViewedData)
    return (
      <>
        {this.renderNotViewedModal(notViewedData)}
        {this.renderInstructionModal()}
        {this.retakeModal()}
        {this.publishLaterModal()}
        <AppWrapper teacherDashboard showTeacherThree showTeacherLogo>
          <div className="row">
            <div className="col">
              <h5 className="text-dark font-weight-bold">
                {`Class ${className}${sectionName} | ${subjectName} | ${
                  isOnline ? "Online" : "InClass"
                }`}
              </h5>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div
                className="bg-brand text-light p-3"
                style={{ borderRadius: 16 }}
              >
                <div className="d-flex align-items-center">
                  <h5 className="font-weight-bold mb-0 mr-2">{title}</h5>
                  <i
                    className="fas fa-info-circle cursor-pointer"
                    style={{ color: "#04B191" }}
                    title={instruction}
                    onClick={e =>
                      this.setState({
                        isInstructionModalOpen: true,
                        instructions: instruction,
                      })
                    }
                  />
                  <p className="m-0 ml-auto">
                    Posted on {postedOn && postedOn.format("DD MMM YYYY")}
                  </p>
                </div>
                <p className="mx-0 my-2">
                  {isOnline ? `Duration: ${timeLimit} mins` : ""}
                </p>
                <div className="d-flex align-items-center">
                  <p className="m-0">
                    {startTime && startTime.format("DD MMM YYYY hh:mm a")}
                  </p>
                  <i
                    className="fas fa-circle text-muted mx-2"
                    style={{ fontSize: 4 }}
                  />
                  <p className="m-0">{endTime && endTime.format("hh:mm a")}</p>
                  <div
                    className="btn text-light ml-auto px-3 py-2 rounded-pill"
                    style={{ backgroundColor: "#04B191" }}
                    onClick={e => {
                      e.preventDefault()
                      this.props.history.push({
                        pathname: URL_PATH.TEACHER_TESTS_REPORT,
                        state: { quizAssignId: this.state.quizAssignId },
                      })
                    }}
                  >
                    View Analysis
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-4 d-flex bg-brand text-light p-3"
              style={{ borderRadius: 16 }}
            >
              <div
                className="px-3 d-flex flex-column align-items-center"
                style={{ flex: 1 }}
              >
                <p>Graded</p>
                <div
                  className="border border-success text-light rounded-pill d-flex align-items-center justify-content-center"
                  style={{ width: 40, height: 40 }}
                >
                  <h4 className="m-0">{gradedNo}</h4>
                </div>
              </div>
              <Dropdown
                style={{ flex: 1 }}
                direction="bottom"
                isOpen={this.state.isRetestPreviewOpen}
                toggle={() =>
                  this.setState({
                    isRetestPreviewOpen: !this.state.isRetestPreviewOpen,
                  })
                }
              >
                <DropdownToggle
                  tag="div"
                  className="px-3 d-flex flex-column align-items-center cursor-pointer"
                  style={{ flex: 1 }}
                  onMouseOver={() =>
                    this.setState({ isRetestPreviewOpen: true })
                  }
                  onMouseLeave={() =>
                    this.setState({ isRetestPreviewOpen: false })
                  }
                >
                  <p>Retest</p>
                  <div
                    className="border border-warning text-light rounded-pill d-flex align-items-center justify-content-center"
                    style={{ width: 40, height: 40 }}
                  >
                    <h4 className="m-0">{retakeStudents.length}</h4>
                  </div>
                </DropdownToggle>
                <DropdownMenu style={{ width: "35vw", left: "-50%" }}>
                  <div className="card-title mb-0">
                    <h5 className="pt-2 pl-3 mb-0 text-dark font-weight-bold">
                      Retake
                    </h5>
                  </div>
                  <div className="card-body">
                    {retakeStudents.length > 0 ? (
                      <>
                        <h6 className="text-dark mb-4">
                          You have assigned retake for the following students
                        </h6>
                        {retakeStudents.map((item, index) => {
                          return (
                            <div className="d-flex justify-content-around mt-3">
                              <div
                                className="text-truncate"
                                style={{ flex: 3 }}
                              >
                                {item.student.fullName}
                              </div>
                              <div className="mx-2" style={{ flex: 2 }}>
                                {moment(item.endTime).format("DD MMM YYYY")}
                              </div>
                              <div style={{ flex: 2 }}>
                                {item.submitted === 0
                                  ? "-  Not Submitted"
                                  : "-  Submitted"}
                              </div>
                            </div>
                          )
                        })}
                      </>
                    ) : (
                      <div className="text-center">Not yet assigned</div>
                    )}
                  </div>
                </DropdownMenu>
              </Dropdown>
              <div
                className="px-3 d-flex flex-column align-items-center"
                style={{ flex: 1 }}
              >
                <p>Awaiting</p>
                <div
                  className="border border-danger text-light rounded-pill d-flex align-items-center justify-content-center"
                  style={{ width: 40, height: 40 }}
                >
                  <h4 className="m-0">{awaitingNo}</h4>
                </div>
              </div>
            </div>

            <div className="col-2 ml-auto" style={{ maxWidth: 170 }}>
              <UncontrolledDropdown direction="left">
                {this.state.isLockingon && (
                  <DropdownToggle
                    tag="button"
                    className="btn btn-block btn-light d-flex align-items-center"
                    disabled={this.state.isLocking}
                  >
                    {this.state.isLocking ? (
                      <HashLoader
                        sizeUnit={"px"}
                        size={18}
                        color={"#084353"}
                        loading={this.state.isLocking}
                      />
                    ) : (
                      <>
                        <span className="mx-auto">
                          {locked ? "Unlock" : "Lock"} test
                        </span>
                        <i
                          className={`fas ${
                            locked ? "fa-lock-alt" : "fa-unlock-alt"
                          } text-muted`}
                        />
                        <i className="fas fa-caret-down text-dark ml-1" />
                      </>
                    )}
                  </DropdownToggle>
                )}
                <DropdownMenu>
                  <DropdownItem
                    onClick={this.unlockQuiz}
                    disabled={!locked}
                    className="d-flex align-items-center"
                  >
                    <i
                      className="fas fa-unlock-alt text-muted"
                      style={{ fontSize: "1.2rem" }}
                    />
                    <div className="ml-2">
                      <div>Unlock</div>
                      <div className="text-muted">
                        Students can take this quiz
                      </div>
                    </div>
                  </DropdownItem>
                  <DropdownItem
                    onClick={this.lockQuiz}
                    disabled={locked}
                    className="d-flex align-items-center"
                  >
                    <i
                      className="fas fa-lock-alt text-muted"
                      style={{ fontSize: "1.2rem" }}
                    />
                    <div className="ml-2">
                      <div>Lock</div>
                      <div className="text-muted">
                        Students cannot take this quiz
                      </div>
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <button
                className="btn btn-block bg-brand text-light text-center mt-1"
                disabled={this.state.published}
                onClick={() =>
                  this.setState({
                    isEditing: !this.state.isEditing,
                  })
                }
              >
                Assign Marks
              </button>
              <button
                className="btn btn-block bg-brand text-light text-center d-flex align-items-center justify-content-center"
                onClick={this.downloadMarkReport}
                // disabled={this.state.isDownloadLoading}
              >
                {/* {this.state.isDownloadLoading ? (
                  <HashLoader
                    sizeUnit={"px"}
                    size={18}
                    color={"#094251"}
                    loading={this.state.isDownloadLoading}
                  />
                ) : (
                  "Download"
                )} */}
                Download
              </button>
            </div>
          </div>

          <div className="row mt-4 mb-3 mx-2">
            <div className="col d-flex align-items-center">
              <p className="m-0 text-muted">
                <i className="fas fa-exclamation-triangle text-warning mr-2" />
                <span
                  className="text-danger cursor-pointer"
                  style={{ textDecoration: "underline" }}
                  onClick={() => this.setState({ isNotViewedModalOpen: true })}
                >
                  {notViewedData.length > 1
                    ? `${notViewedData.length} students`
                    : `${notViewedData.length} student`}
                </span>

                <span> haven't viewed this assessment</span>
              </p>
            </div>
            <div className="col d-flex align-items-center justify-content-end">
              <select
                className="custom-select"
                onChange={e =>
                  this.setState({ selectedStatus: e.target.value })
                }
              >
                <option value="">Select Status</option>
                <option value="1">Submitted</option>
                <option value="0">Not Submitted</option>
              </select>
              <select
                className="custom-select ml-2"
                onChange={e =>
                  this.setState({ selectedApprovalStatus: e.target.value })
                }
              >
                <option value="">Select Approval Status</option>
                <option value="APPROVED">Approved</option>
                <option value="RETEST">Retest</option>
                <option value="AWAITING">Awaiting</option>
              </select>
            </div>
          </div>

          <div className="mx-2 mb-5">
            <div
              className="row align-items-center bg-brand text-light my-3 py-2"
              style={{ borderRadius: 16 }}
            >
              {/* <div className="col-1"></div> */}
              <div className={`col-3 ${isOnline ? "pl-5" : "text-center"}`}>
                <span>Student Name</span>
              </div>

              {isOnline ? (
                <div className="col-2 ">
                  <span className="ml-4">Status</span>
                </div>
              ) : null}

              {isOnline ? (
                <div className="col-1 pl-0">
                  <span>Submission</span>
                </div>
              ) : (
                <div className="col-1"></div>
              )}

              <div className="col-2 text-center">
                <span>Mark</span>
                <br />
                <span className="text-muted">Out of {totalMark}</span>
              </div>
              {!isOnline ? <div className="col-1"></div> : ""}
              <div className="col-2 text-center">
                <span>Remark</span>
              </div>
              {!isOnline ? <div className="col-1"></div> : ""}
              <div className="col-2 text-center">
                <span>Approval Status</span>
              </div>
            </div>

            {assignedStudents
              .filter(item => {
                let filter = true
                filter =
                  filter && this.state.selectedStatus
                    ? item.submitted === parseInt(this.state.selectedStatus)
                    : true
                if (this.state.selectedApprovalStatus === "AWAITING") {
                  filter =
                    filter && !item.retake && !item.submitted && !item.graded
                } else if (this.state.selectedApprovalStatus === "RETEST") {
                  filter = filter && item.retake
                } else if (this.state.selectedApprovalStatus === "APPROVED") {
                  filter = filter && item.graded
                }
                return filter
              })
              .map(this.renderAssignedStudent)}
            <div style={{ marginBottom: "100px" }}>
              <div className="d-flex align-items-center justify-content-end my-3">
                {linkToExam ? (
                  <button
                    className="btn bg-brand text-light mr-2 py-1 px-3 rounded-pill"
                    onClick={this.unlinkToExam}
                    disabled={this.state.isLinking}
                    style={{ display: "none" }}
                  >
                    {this.state.isLinking ? (
                      <HashLoader
                        sizeUnit={"px"}
                        size={18}
                        color={"#084353"}
                        loading={this.state.isLinking}
                      />
                    ) : (
                      "Unmap to Exam Hierarchy"
                    )}
                  </button>
                ) : (
                  <button
                    className="btn bg-brand text-light mr-2 py-1 px-3 rounded-pill"
                    onClick={this.linkToExam}
                    disabled={this.state.isLinking}
                    style={{ display: "none" }}
                  >
                    {this.state.isLinking ? (
                      <HashLoader
                        sizeUnit={"px"}
                        size={18}
                        color={"#084353"}
                        loading={this.state.isLinking}
                      />
                    ) : (
                      "Map to Exam Hierarchy"
                    )}
                  </button>
                )}

                {this.state.isEditing && (
                  <button
                    className="btn mr-2 py-1 px-3 rounded-pill text-light"
                    onClick={this.updateQuizMarks}
                    style={{ backgroundColor: "#06b191" }}
                    disabled={!this.state.isEditing || this.state.isSaving}
                  >
                    {this.state.isSaving ? (
                      <HashLoader
                        sizeUnit={"px"}
                        size={18}
                        color={"#084353"}
                        loading={this.state.isSaving}
                      />
                    ) : (
                      "Save"
                    )}
                  </button>
                )}

                {!this.state.published && (
                  <div
                    className="p-0 rounded-pill d-flex text-light"
                    style={{ backgroundColor: "#06b191" }}
                  >
                    <UncontrolledDropdown direction="left">
                      <DropdownToggle
                        tag="span"
                        className="d-block py-1 px-3 cursor-pointer"
                      >
                        <span className="mr-3">
                          {this.state.isPublishing ? (
                            <HashLoader
                              sizeUnit={"px"}
                              size={18}
                              color={"#084353"}
                              loading={this.state.isPublishing}
                            />
                          ) : published ? (
                            "Publish Result"
                          ) : (
                            "Publish Result"
                          )}
                        </span>
                        <i className="fas fa-caret-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={this.publishQuiz}>
                          <span>Publish Now</span>
                        </DropdownItem>
                        <DropdownItem
                          onClick={e =>
                            this.setState({ isPublishLaterModalOpen: true })
                          }
                        >
                          <span>Publish Later</span>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                )}
                {this.state.published &&
                this.state.publishLater &&
                moment(this.state.publishLater) > moment() ? (
                  <div
                    style={{
                      display: "block",
                      marginBottom: "20px",
                      height: "35px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#d0f0ea",
                        color: "#358f86",
                        float: "right",
                        borderRadius: "5px",
                      }}
                      className="p-2 pl-3 pr-3"
                    >
                      Scheduled on{" "}
                      {moment(this.state.publishLater).format(
                        "DD MMM YYYY, hh:mm a"
                      )}{" "}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {this.state.published ? (
                <div style={{ display: "block" }}>
                  <div
                    className="py-1 px-3 text-light ml-auto rounded-pill cursor-pointer"
                    style={{
                      backgroundColor: "#04B191",
                      float: "right",
                      marginBottom: "100px",
                    }}
                    onClick={e => {
                      e.preventDefault()
                      this.unpublishQuiz()
                    }}
                  >
                    Unpublish Results
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </AppWrapper>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getSingleQuizAssign,
      updateQuiz,
      updateQuizMarks,
      getMarkReport,
      retakeStudentQuizSubmission,
      showNotification,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  dispatchToProps
)(withRouter(MarkEntryScreen))
