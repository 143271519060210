/*************************************************
 * RedInk
 * @exports
 * @class ClassDetailscreen.js
 * @extends Component
 * @author Sasidharan // on 08/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import AppWrapper from "../Wrapper"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  getClassDetails,
  saveClassDetails,
  deleteClassDetails,
  updateClassDetails,
  saveClassDetailsInStore,
} from "../../../actions/superAdmin/ClassDetailsAction"
import { myLog } from "../../../utils/Utility"
import { getSchoolsListDetails } from "../../../actions/superAdmin/schoolDetailsAction"
import { Input } from "antd"

export const sections = [
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "C", label: "C" },
  { value: "D", label: "D" },
  { value: "E", label: "E" },
  { value: "F", label: "F" },
  { value: "G", label: "G" },
  { value: "H", label: "H" },
  { value: "I", label: "I" },
  { value: "J", label: "J" },
  { value: "K", label: "K" },
  { value: "L", label: "L" },
  { value: "M", label: "M" },
  { value: "N", label: "N" },
  { value: "O", label: "O" },
  { value: "P", label: "P" },
  { value: "Q", label: "Q" },
  { value: "R", label: "R" },
  { value: "S", label: "S" },
  { value: "T", label: "T" },
  { value: "U", label: "U" },
  { value: "V", label: "V" },
  { value: "W", label: "W" },
  { value: "X", label: "X" },
  { value: "Y", label: "Y" },
  { value: "Z", label: "Z" },
]
export const classes = [
  { value: "JKG", label: "JKG" },
  { value: "SKG", label: "SKG" },
  { value: "LKG", label: "LKG" },
  { value: "UKG", label: "UKG" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
]

class ClassDetailscreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      savedClasses: false,
      selectedClassesAndSections: [],
      saveSelectedClassesAndSections: [],
      searchClasses: "",
      classDummyList: "",
      classesLists: [],
    }
  }

  static getDerivedStateFromProps(props, state) {
    // myLog("Derived state classes",props.classesList )
    return {
      classesLists: props.classesList,
    }
  }

  componentDidMount = () => {
    this.props.getSchoolsListDetails((schoolSuccess, schoolResponse) => {
      this.getClassDetailsService(schoolResponse[0].branchId)
    })
  }

  getClassDetailsService = branchId => {
    let object = {
      branchId,
    }
    this.props.getClassDetails(object, (success, response) => {
      if (success) {
        this.setState({ classDummyList: response })
        // myLog("Class details", response)
      }
    })
  }

  addClassesAndSubject = () => {
    myLog(this.state.selectedClasses, this.state.selectedSections)
    let array = this.state.selectedClassesAndSections
    let string = this.state.selectedClasses.value + "-"
    let alreayAvailableClassindex = -1
    let classes = this.state.saveSelectedClassesAndSections
    let classObject = {
      class: this.state.selectedClasses.value,
    }
    let sectionsList = []

    for (let j = 0; j < array.length; j++) {
      let dummyString = array[j].classesAndSections
      let splitString = dummyString.split("-")
      if (splitString[0] === this.state.selectedClasses.value) {
        alreayAvailableClassindex = j
        string = dummyString
      }
    }

    for (let i = 0; i < this.state.selectedSections.length; i++) {
      string = string + this.state.selectedSections[i].value + ","
      sectionsList.push(this.state.selectedSections[i].value)
    }

    classObject.sections = sectionsList
    classes.push(classObject)

    if (alreayAvailableClassindex !== -1) {
      array.splice(alreayAvailableClassindex, 1)
    }
    let object = {
      classesAndSections: string,
    }
    array.push(object)
    this.cancelSubmit()
    this.setState({
      selectedClassesAndSections: array,
      saveSelectedClassesAndSections: classes,
    })
  }

  cancelSubmit = () => {
    this.setState({
      selectedClasses: [],
      selectedSections: [],
      selectedClassesAndSections: [],
    })
  }
  /**
   * Delete a subject
   */
  deleteClassesAndSections = index => {
    if (this.state.selectedClassesAndSections.length > 0) {
      let arrselectedClassesAndSections = this.state.selectedClassesAndSections
      arrselectedClassesAndSections.splice(index, 1)
      this.setState(
        {
          selectedClassesAndSections: arrselectedClassesAndSections,
        },
        () => {
          myLog(this.state.selectedClassesAndSections)
        }
      )
    }
  }

  // updateClassInputData = (classDetails) =>{
  //   this.setState({

  //   })
  // }

  /**
   * Delete a subject from db
   */
  deleteClassFromDb = item => {
    myLog(item.id)
    this.props.deleteClassDetails({ id: item.id }, () => {})
  }

  /**
   * saveSubject
   */
  saveClass = () => {
    myLog(this.state.selectedClassesAndSections)
    if (this.state.selectedClassesAndSections.length > 0) {
      let classObject = {
        classes: this.state.saveSelectedClassesAndSections,
      }
      myLog(classObject)
      this.setState(
        {
          isShowLoading: true,
        },
        () => {
          this.props.saveClassDetails(classObject, success => {
            this.setState({
              isShowLoading: false,
            })
            myLog("success", success)
            if (success) {
              this.cancelSubmit()
              this.setState({ savedClasses: true })
            }
          })
        }
      )
    } else {
      this.setState({ hasError: true })
      myLog("error", this.state.className)
    }
  }

  searchClass = e => {
    this.setState({ searchClasses: e.target.value })
    let classLists = this.state.classDummyList
    let filteredArray =
      classLists.length &&
      classLists.filter(
        data =>
          JSON.stringify(data.className)
            .toLowerCase()
            .indexOf(e.target.value.toLowerCase()) !== -1
      )

    this.props.saveClassDetailsInStore(
      JSON.parse(JSON.stringify(filteredArray))
    )

    this.setState(
      { classesLists: JSON.parse(JSON.stringify(filteredArray)) }
      // () => {
      //   myLog("class filtered array", this.state.classesLists)
      // }
    )
    // myLog("filteredArray",typeof(classLists))
  }

  // updateClass = () =>{
  //   myLog(this.state.className);
  //   if(this.state.className !== ""){
  //     let classObject = {

  //     }
  //     myLog(classObject);
  //     this.setState({
  //       isShowLoading: true,
  //     },() =>{
  //     //   this.props.updateClassDetails(classObject, (success)=>{
  //     //     this.setState({
  //     //       isShowLoading: false,
  //     //     })
  //     //     myLog('success',success);
  //     //     if(success){
  //     //       this.cancelSubmit();
  //     //     }
  //     //   });
  //     })
  //   } else {
  //     myLog('error',this.state.className);
  //   }
  // }

  render() {
    return (
      <AppWrapper
        showProfileSidemenu
        showBackToDashboard
        onChangeSchoolName={item => {
          myLog(item)
          this.getClassDetailsService(item.branchId)
        }}
      >
        <div className="card bg-light p-3 shadow">
          <span className="font-weight-bold fs-25">CLASS DETAILS</span>
          {/* <div
            className={`container ${this.state.savedClasses ? "d-none" : ""}`}
          >
            <div className="row my-3">
              <div className="col-sm-5">
                <Select
                  placeholder="Select class"
                  name="classes"
                  isClearable
                  value={this.state.selectedClasses}
                  options={classes}
                  className="basic-multi-select mb-3"
                  classNamePrefix="select"
                  onChange={(selectedOption)=>{
                    myLog(selectedOption);
                    this.setState({selectedClasses: selectedOption})
                  }}
                />
                <Select
                  placeholder="Select sections"
                  isMulti
                  value={this.state.selectedSections}
                  name="sections"
                  options={sections}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(selectedOption)=>{
                    myLog(selectedOption);
                    this.setState({selectedSections: selectedOption})
                  }}
                />
                <button type="button" className="btn btn-dark my-3 btn-block" onClick={()=>{
                  this.addClassesAndSubject();
                }}>
                  <i className="fas fa-arrow-right mr-2" />
                  Add
                </button>
              </div>
              <div className="col-sm-7">
                <ul className="list-group">
                  {this.state.selectedClassesAndSections && this.state.selectedClassesAndSections.map((item, index)=>{
                    return(
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        {item.classesAndSections}
                        <div>
                          <button type="button" onClick={()=>{
                            this.deleteClassesAndSections(index);
                          }}>
                          <i className="fas fa-minus-circle text-danger"></i>
                          </button>
                        </div>
                      </li>
                    );
                  })}
                  {this.state.selectedClassesAndSections.length === 0 && this.state.hasError?
                    <p className="text-danger">Classes is required.</p>
                  :
                  null}
                </ul>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-secondary mt-3 mr-2 rounded-pill"
                onClick={() => {this.cancelSubmit(); this.setState({ savedClasses: true })}}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-success mt-3 rounded-pill"
                onClick={() => {this.saveClass();}}
              >
                <i className="fas fa-save mr-2" />
                Save
              </button>
            </div>
          </div> */}
          <div className="mt-3">
            {/* <div className="row"> */}
            {/* <div className="col-sm"> */}
            <Input
              type="text"
              className="px-3 py-2 shadow rounded-pill border-0 classSearchBox"
              placeholder="Search"
              value={this.state.searchClasses}
              onChange={e => {
                this.searchClass(e)
              }}
              allowClear
              style={{ width: "calc(100% - 69rem)" }}
            />
            {/* </div> */}
            {/* <div className="col-sm text-right">
                <button
                  type="button"
                  className="btn btn-sm btn-dark rounded-pill addGradeButton"
                  onClick={() => this.setState({ savedClasses: false })}
                >
                  <i className="fas fa-plus mr-2" />
                  Add Grade
                </button>
              </div> */}
            {/* </div> */}
            <div className="row mt-4 d-flex justify-content-around">
              {/* Class card */}
              <div className="container">
                <div className="row">
                  {this.state.classesLists &&
                    this.state.classesLists.map(item => {
                      return (
                        <div className="col-sm-4 my-2">
                          <div className={`card mx-2 p-2 border-left-color`}>
                            <div className="d-flex justify-content-between mt-2">
                              <span>Class {item.className}</span>
                              {/* <UncontrolledDropdown>
                              <DropdownToggle tag="a" className="nav-link">
                                  <i className="fas fa-ellipsis-v cursor-pointer"></i>
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={() => {this.setState({isShowAddSubjectInput: true, isShowUpdateSubjectInput: true, subjectsSet: [item.subjectName], updateSubjectDetailId: item.id})}}>Update</DropdownItem> 
                                <DropdownItem onClick={() => { this.deleteClassFromDb(item)}}>Delete</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown> */}
                              {/* <i className="fas fa-ellipsis-v cursor-pointer"></i> */}
                            </div>
                            <div className="my-2 d-flex justify-content-between">
                              <div className="text-center">
                                <b>{item.studentsCount}</b>
                                <br></br>
                                <small className="text-secondary">
                                  Students
                                </small>
                              </div>
                              <div className="text-center">
                                <b>{item.teacherCount}</b>
                                <br></br>
                                <small className="text-secondary">
                                  Teachers
                                </small>
                              </div>
                              <div className="text-center">
                                <b>{item.subjectsCount}</b>
                                <br></br>
                                <small className="text-secondary">
                                  Subjects
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    subjectList: state.subjectDetailsState.subjectList,
    classesList: state.classDetailsState.classList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getSchoolsListDetails,
      getClassDetails,
      saveClassDetails,
      deleteClassDetails,
      updateClassDetails,
      saveClassDetailsInStore,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(ClassDetailscreen)
