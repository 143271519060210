/*************************************************
 * RedInk
 * @exports
 * @class QuickAnalytics.js
 * @extends Component
 * @author Sasidharan // on 22/03/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import {
  getStudentsPerformersListBySubjectDetails,
  getStudentPerformanceReportDetails,
  getStudentsPerformanceMarkBySubjectDetails,
  getGradePerformanceListBySubjectDetails,
} from "../../../actions/teacher/QuickAnalyticsAction"
import { getExamListDetails } from "../../../actions/teacher/HierarchyAction"
import { getClassList } from "../../../actions/teacher/TakeAttendanceAction"

import QuickAnalyticsComponent from "./QuickAnalyticsComponent"
import MessageModal from "../MessageModal"
import AppWrapper from "../Wrapper"

class QuickAnalytics extends Component {
  static getDerivedStateFromProps(props, state) {
    return {
      studentPerformanceReportList: props.studentPerformanceReport,
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      hierarchySubjectDetails:
        this.props.location && this.props.location.hasOwnProperty("state")
          ? this.props.location.state.hierarchySubjectDetails
          : {},
      studentPerformanceRange: [50, 75],
      studentPerformanceReportList: [],
      isShowViewData: false,
    }
  }

  /**
   * Handles message modal
   */
  closeMessageModal = () => this.setState({ isMessageModalOpen: false })

  /**
   * handles on click message icon in sidebar
   */
  onClickMessageIcon = () => {
    this.setState({ isMessageModalOpen: !this.state.isMessageModalOpen })
  }
  /**
   * handles message submit
   */
  onSubmitMessage = () => {
    this.closeMessageModal()
  }

  render() {
    return (
      <AppWrapper
        openMessageModal={() => this.onClickMessageIcon()}
        showTeacherThree
        showTeacherLogo
        sideMenuCollapsedBackExam
        screenName={"Quick Analytics"}
      >
        <MessageModal
          isModalOpen={this.state.isMessageModalOpen}
          closeModal={() => this.onClickMessageIcon()}
          onSubmitMessage={() => this.onSubmitMessage()}
        />
        <QuickAnalyticsComponent {...this.props} />
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    subjectList: state.subjectDetailsState.subjectList,
    classesList: state.classDetailsState.classList,
    examList: state.hierarchyState.examList,
    studentPerformanceReport:
      state.hierarchyState.studentPerformanceReportDetails,
    studentsPerformerObject: state.quickAnalyticsState.studentsPerformerObject,
    studentsPerformanceMarkBySubjectDetails:
      state.quickAnalyticsState.studentsPerformanceMarkBySubjectDetails,
    gradePerformerObject: state.quickAnalyticsState.gradePerformerObject,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getClassList,
      getExamListDetails,
      getStudentPerformanceReportDetails,
      getStudentsPerformanceMarkBySubjectDetails,
      getStudentsPerformersListBySubjectDetails,
      getGradePerformanceListBySubjectDetails,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(QuickAnalytics)
