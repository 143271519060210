/*************************************************
 * RedInk
 * @exports
 * @class SchoolDetailsDashboard.js
 * @extends Component
 * @author Sasidharan // on 31/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import AppWrapper from "../Wrapper"
import { getSchoolDetails } from "../../../actions/superAdmin/DashboardAction"
import { updateProfileDetails } from "../../../actions/superAdmin/ProfileAction"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { HashLoader } from "react-spinners"
import { myLog, validateEmail } from "../../../utils/Utility"
import redinkLogo from "../../../assets/images/redink/redink_logo.png"
import { showNotification } from "../../../actions/CommonAction"
import { DANGER_TOAST, VALIDATION_MSG } from "../../../utils/Constants"

const CheckIsValidDomain = domain => {
  // var re = new RegExp(
  //   /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/
  // )
  var re = new RegExp(
    /^(?!\-)(?:[a-zA-Z\d\-]{0,62}[a-zA-Z\d]\.){1,126}(?!\d+)[a-zA-Z\d]{1,63}$/
  )
  return domain.match(re)
}

const CheckOnlyLetters = text => {
  var re = new RegExp(/^[A-Za-z\.\- ]+$/)
  return text.match(re)
}

const checkSpecialChars = char => {
  var re = new RegExp(/[*|\":<>[\]{}`\\';@&$]/)
  return char.match(re)
}
class SchoolDetailsDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowLoading: false,
      showSideMenu: true,
      schoolName: "",
      chairmanName: "",
      schoolRegId: "",
      landLine: "",
      website: "",
      yearFounded: "",
      contactNumber: "",
      schoolEmail: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
      leftPanelName: "",
      leftPanelEmail: "",
      leftPanelPhone: "",
    }
  }
  componentDidMount = () => {
    this.props.getSchoolDetails((success, response) => {
      if (success) {
        this.setState({
          schoolName: response.schoolName,
          chairmanName: response.chairmanName,
          schoolRegId: response.schoolRegId,
          landLine: response.landLine,
          website: response.website,
          yearFounded: response.yearFounded,
          contactNumber: response.contactNumber,
          schoolEmail: response.schoolEmail,
          addressLine1: response.addressLine1,
          addressLine2: response.addressLine2,
          city: response.city,
          state: response.state,
          country: response.country,
          zipCode: response.zipCode,
          leftPanelName: response.chairmanName,
          leftPanelEmail: response.schoolEmail,
          leftPanelPhone: response.contactNumber,
        })
        myLog(response)
      }
    })
  }

  onUpdate = () => {
    if (this.state.chairmanName !== "" && this.state.chairmanName !== null) {
      if (!CheckOnlyLetters(this.state.chairmanName)) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.ALERT,
          "Chairman name invalid"
        )
        return
      }
    }

    //  if (this.state.schoolRegId !== "" && this.state.schoolRegId !== null) {
    //    if (!CheckOnlyLetters(this.state.schoolRegId)) {
    //      this.props.showNotification(
    //        DANGER_TOAST,
    //        VALIDATION_MSG.ALERT,
    //        "Invalid School Reg.Id"
    //      )
    //      return
    //    }
    //  }

    if (this.state.landLine !== "" && this.state.landLine !== null) {
      if (
        String(this.state.landLine).length < 8 ||
        String(this.state.landLine).length > 13
      ) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.ALERT,
          "Landline number invalid"
        )
        return
      }
    }

    if (this.state.yearFounded !== "" && this.state.yearFounded !== null) {
      if (String(this.state.yearFounded).length !== 4) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.ALERT,
          "Invalid Founded year"
        )
        return
      }
    }

    if (this.state.contactNumber !== "" && this.state.contactNumber !== null) {
      if (
        String(this.state.contactNumber).length < 8 ||
        String(this.state.contactNumber).length > 13
      ) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.ALERT,
          "Contact number invalid"
        )
        return
      }
    }

    if (this.state.website !== "" && this.state.website !== null) {
      if (!CheckIsValidDomain(this.state.website)) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.ALERT,
          "Website name invalid"
        )
        return
      }
    }

    if (this.state.schoolEmail !== "" && this.state.schoolEmail !== null) {
      if (!validateEmail(this.state.schoolEmail)) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.ALERT,
          "Email Id invalid "
        )
        return
      }
    }

    if (this.state.addressLine1 !== "" && this.state.addressLine1 !== null) {
      if (checkSpecialChars(this.state.addressLine1)) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.ALERT,
          "Address Line1 Invalid"
        )
        return
      }
    }

    if (this.state.addressLine2 !== "" && this.state.addressLine2 !== null) {
      if (checkSpecialChars(this.state.addressLine2)) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.ALERT,
          "Address Line2 Invalid"
        )
        return
      }
    }

    if (this.state.city !== "" && this.state.city !== null) {
      if (!CheckOnlyLetters(this.state.city)) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.ALERT,
          "City name invalid"
        )
        return
      }
    }

    if (this.state.state !== "" && this.state.state !== null) {
      if (!CheckOnlyLetters(this.state.state)) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.ALERT,
          "State name invalid"
        )
        return
      }
    }

    if (this.state.country !== "" && this.state.country !== null) {
      if (!CheckOnlyLetters(this.state.country)) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.ALERT,
          "Country name invalid"
        )
        return
      }
    }

    if (this.state.zipCode !== "" && this.state.zipCode !== null) {
      if (String(this.state.zipCode).length < 6) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.ALERT,
          "Invalid Zipcode"
        )
        return
      }
    }

    this.setState({ isShowLoading: true })
    let object = {
      chairmanName: this.state.chairmanName ? this.state.chairmanName : null,
      schoolRegId: this.state.schoolRegId ? this.state.schoolRegId : null,
      landLine: this.state.landLine ? this.state.landLine : null,
      website: this.state.website ? this.state.website : null,
      yearFounded: this.state.yearFounded ? this.state.yearFounded : null,
      contactNumber: this.state.contactNumber ? this.state.contactNumber : null,
      schoolEmail: this.state.schoolEmail ? this.state.schoolEmail : null,
      addressLine1: this.state.addressLine1 ? this.state.addressLine1 : null,
      addressLine2: this.state.addressLine2 ? this.state.addressLine2 : null,
      city: this.state.city ? this.state.city : null,
      state: this.state.state ? this.state.state : null,
      country: this.state.country ? this.state.country : null,
      zipCode: this.state.zipCode ? this.state.zipCode : null,
    }
    this.props.updateProfileDetails(object, (success, response) => {
      if (success) {
        myLog(response)
        this.componentDidMount()
        this.setState({ isShowLoading: false })
      } else {
        myLog("REQUEST CODE:", response)
        this.setState({ isShowLoading: false })
      }
    })
  }

  handleChange = () => event => {
    this.setState({ [event.target.name]: event.target.value })
  }
  /**
   * Renders super admin school details dashboard
   */
  render() {
    return (
      <AppWrapper
        showProfileSidemenu
        showBackToDashboard
        onChangeSchoolName={item => {
          myLog(item)
        }}
      >
        <div className="row">
          <div className="col-sm-3">
            <div className="cardone bg-white p-3 shadow profile">
              <img src={redinkLogo} className="proimg img1" alt="" />
              <br />
              &emsp;
              <h3 className="text-dark"> {this.state.schoolName} </h3>
              <br />
              <p style={{ fontWeight: "bold", fontSize: "1vw" }}>
                {this.state.leftPanelName}
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "1vw",
                  wordBreak: "break-word",
                }}
              >
                {this.state.leftPanelEmail}
              </p>
              <p style={{ fontWeight: "bold", fontSize: "1vw" }}>
                {this.state.leftPanelPhone}
              </p>
            </div>
          </div>
          <div className="col-sm-9 ">
            <div className="card bg-white border-0 p-3 shadow profile">
              <h5 className="myskills text-dark">
                <b>Personal Details</b>
              </h5>
              <button
                type="button"
                className="btn text-light mybutton  rounded-pill"
                style={{
                  backgroundColor: "#094353",
                  border: "none",
                  fontSize: "11px",
                }}
                onClick={this.onUpdate}
              >
                {this.state.isShowLoading ? (
                  <HashLoader
                    sizeUnit={"px"}
                    size={16}
                    color={"#ffffff"}
                    loading={this.state.isShowLoading}
                    //  css={{ marginLeft: "16px" }}
                  />
                ) : (
                  "Update"
                )}
              </button>
              <div className="row personaldetails">
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label>Correspondent/Chairman :</label>
                  <input
                    type="text"
                    className="form-control"
                    name="chairmanName"
                    maxLength="25"
                    value={this.state.chairmanName}
                    onChange={this.handleChange()}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label>School Reg. ID :</label>
                  <input
                    type="text"
                    className="form-control"
                    name="schoolRegId"
                    maxLength="25"
                    value={this.state.schoolRegId}
                    onChange={e => {
                      e.target.value = e.target.value.replace(
                        /[^A-Za-z0-9./ ]/gi,
                        ""
                      )
                      this.setState({ schoolRegId: e.target.value })
                    }}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label>Landline :</label>
                  <input
                    type="number"
                    className="form-control"
                    name="landLine"
                    value={this.state.landLine}
                    onChange={e => {
                      this.setState({ landLine: e.target.value })
                    }}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label> Website :</label>
                  <input
                    type="website"
                    className="form-control"
                    name="website"
                    value={this.state.website}
                    onChange={this.handleChange()}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label>Year founded :</label>
                  <input
                    type="number"
                    className="form-control"
                    name="yearFounded"
                    value={this.state.yearFounded}
                    onChange={this.handleChange()}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label>Contact Number:</label>
                  <input
                    type="number"
                    className="form-control"
                    name="contactNumber"
                    value={this.state.contactNumber}
                    onChange={e => {
                      this.setState({ contactNumber: e.target.value })
                    }}
                  />
                </div>
                <div className="form-group col-sm-12 col-md-12 col-xl-12 col-lg-12">
                  <label>Email ID:</label>
                  <input
                    type="email"
                    className="form-control"
                    name="schoolEmail"
                    disabled="true"
                    value={this.state.schoolEmail}
                    onChange={this.handleChange()}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label>Address Line 1:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="addressLine1"
                    maxLength="100"
                    value={this.state.addressLine1}
                    onChange={this.handleChange()}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label>Address Line 2:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="addressLine2"
                    maxLength="100  "
                    value={this.state.addressLine2}
                    onChange={this.handleChange()}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label>City:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="city"
                    value={this.state.city}
                    maxLength="30"
                    onChange={this.handleChange()}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label>State:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="state"
                    maxLength="30"
                    value={this.state.state}
                    onChange={this.handleChange()}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label>Country:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="country"
                    maxLength="30"
                    value={this.state.country}
                    onChange={this.handleChange()}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label>Zip Code:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="zipCode"
                    maxLength="12"
                    value={this.state.zipCode}
                    onChange={e => {
                      e.target.value = e.target.value.replace(/[^0-9\- ]/gi, "")
                      this.setState({ zipCode: e.target.value })
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getSchoolDetails,
      updateProfileDetails,
      showNotification,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(SchoolDetailsDashboard)
