import React from "react"
import { HashLoader } from "react-spinners"

/**
 * @typedef CustomLoadingButtonProps
 * @property {true|false} [isLoading]
 * @property {true|false} [disabled]
 * @property {Function} [onClick]
 * @property {number} [loaderSize]
 * @property {"px"|"em"|"vh"|"vw"} [loaderSizeUnit]
 * @property {string} [loaderColor]
 * @property {string} [className]
 * @property {string} [innerClassName]
 * @property {{}} [innerStyle]
 * @property {{}} [innerProps]
 *
 * @typedef {CustomLoadingButtonProps} LoadingButtonProps
 */

/**
 * A simple button with loading state
 * @param {LoadingButtonProps} props
 */
export default function LoadingButton({
  innerClassName,
  innerStyle,
  innerProps,
  disabled,
  isLoading,
  loaderSize,
  loaderSizeUnit,
  loaderColor,
  children,
  ...props
}) {
  return (
    <button {...props} disabled={isLoading || disabled}>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <HashLoader
          sizeUnit={loaderSizeUnit || "px"}
          size={loaderSize || 16}
          color={loaderColor || "#fff"}
          loading={isLoading}
        />
      </div>
      <div
        className={innerClassName}
        style={{
          opacity: isLoading ? "0" : "1",
          ...(innerStyle || {}),
        }}
        {...innerProps}
      >
        {children}
      </div>
    </button>
  )
}
