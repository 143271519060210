/*************************************************
 * RedInk
 * @exports
 * @class PerformanceReport.js
 * @extends Component
 * @author Sasidharan // on 21/03/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import { UncontrolledTooltip } from "reactstrap"
import { bindActionCreators } from "redux"
import { HashLoader } from "react-spinners"
import { Progress } from "react-sweet-progress"
import { connect } from "react-redux"
import { Range } from "react-range"
import Skeleton from "react-loading-skeleton"
import Chart from "chart.js"
import _ from "lodash"

import "react-circular-progressbar/dist/styles.css"
import "react-sweet-progress/lib/style.css"

import {
  getExamListDetails,
  getClassPerformanceReportDetails,
  getSchoolPerformanceReportDetails,
  getStudentPerformanceReportDetails,
  getSubjectPerformanceReportDetails,
  getSchoolPerformancePassFailReportDetails,
  saveStudentPerformanceReportDetailsInStore,
  saveDisplayAdminStudentPerformanceReportDetailsInStore,
} from "../../../actions/admin/PerformanceReportAction"
import { getSubjectDetailsToPR } from "../../../actions/admin/SubjectDetailsAction"
import { getClassDetails } from "../../../actions/admin/ClassDetailsAction"

import MessageModal from "../MessageModal"
import AppWrapper from "../Wrapper1"

let mySecHigh = null
let myppp = null
let myMiddle = null

Chart.Chart.pluginService.register({
  beforeDraw: function(chart) {
    if (chart.config.options.elements.center) {
      //Get ctx from string
      var ctx = chart.chart.ctx

      //Get options from the center object in options
      var centerConfig = chart.config.options.elements.center
      var fontStyle = centerConfig.fontStyle || "Arial"
      var txt = centerConfig.text
      var color = centerConfig.color || "#000"
      var sidePadding = centerConfig.sidePadding || 20
      var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
      //Start with a base font of 30px
      ctx.font = "40px " + fontStyle

      //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
      var stringWidth = ctx.measureText(txt).width
      var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated

      // Find out how much the font can grow in width.
      var widthRatio = elementWidth / stringWidth
      var newFontSize = Math.floor(30 * widthRatio)
      var elementHeight = chart.innerRadius * 2

      // Pick a new font size so it will not be larger than the height of label.
      var fontSizeToUse = Math.min(newFontSize, elementHeight)

      //Set font settings to draw it correctly.
      ctx.textAlign = "center"
      ctx.textBaseline = "middle"
      var centerX = (chart.chartArea.left + chart.chartArea.right) / 2
      var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2
      ctx.font = fontSizeToUse + "px " + fontStyle
      ctx.fillStyle = color

      //Draw text in center
      ctx.fillText(txt, centerX, centerY)
    }
  },
})

class PerformanceReport extends Component {
  static getDerivedStateFromProps(props, state) {
    if (
      props.displayAdminStudentPerformanceReportDetails !==
      state.displayAdminStudentPerformanceReportDetails
    ) {
      return {
        displayAdminStudentPerformanceReportDetails:
          props.displayAdminStudentPerformanceReportDetails,
        sortedStudentsArray: props.displayAdminStudentPerformanceReportDetails,
      }
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      isMessageModalOpen: false,
      studentPerformanceRange: [0, 100],
      startSliderValue: 0,
      endSliderValue: 100,
      sliderTotalStudent: 0,
      totalStudent: 0,
      displayStudentPerformanceReportDetails: [],
      sortedStudentsArray: [],
      selectedExamOverAllSchoolName: "",
      outOffMark: "0",
      sortOrderByNames: 0,
      sortOrderByMarks: 0,
      sortOrderByGrades: 0,
      isShowLoadingPassFail: true,
      isShowLoadingSchoolPerformance: true,
      isShowLoadingSubjectPerformance: true,
      isShowLoadingStudentPerformance: true,
      isShowNoSubjectPerformanceList: false,
      selectedSubjectStudentAcademicPerformance: "ALL",
    }
  }

  componentDidMount = () => {
    // this.props.getExamListDetails({}, (success, response) => {
    //   if (success) {
    //     this.setState({ selectedExam: response[0].id });
    //     this.props.getClassDetails({},(classSuccess, classResponse) => {
    //       if (classSuccess) {
    //         this.setState({
    //           selectedClass: classResponse[0].id,
    //           selectedClassName: classResponse[0].value,
    //         })
    //         this.props.getSubjectDetailsToPR({},(subjectSuccess, subjectResponse) => {
    //           if (subjectSuccess) {
    //             this.setState({
    //               selectedSubject: subjectResponse[0].id,
    //               selectedSubjectName: subjectResponse[0].value,
    //             })
    //             this.getPerformanceReport(
    //               response[0].id,
    //               subjectResponse[0].id,
    //               classResponse[0].id
    //             )
    //           }
    //         })
    //       }
    //     })
    //   }
    // })
    this.getReport()
  }

  scrollToMyRef = section => {
    this.myRef.scrollIntoView({ block: "center", behavior: "smooth" })
    this.setState({
      selectedSubjectStudentAcademicPerformance: section.subjectId,
      selectedExamStudentAcademicPerformance: section.assessmentId,
      selectedClassStudentAcademicPerformance: section.sectionId,
    })
    this.studentPerformanceReportService(
      section.assessmentId,
      section.subjectId,
      section.sectionId
    )
  }

  getReport = () => {
    this.props.getExamListDetails(
      { sortBy: "latestAssessmentPerformanceUpdatedOn" },
      (success, response) => {
        if (success) {
          this.setState({
            selectedExam: response[0].assessmentId,
            selectedExamOverAllSchoolName: response[0].value,
            selectedExamOverAllSchool: response[0].assessmentId,
            selectedExamStudentAcademicPerformance: response[0].assessmentId,
            selectedExamAcademicSubjectPerformance: response[0].assessmentId,
          })
          this.props.getClassDetails({}, (classSuccess, classResponse) => {
            if (classSuccess) {
              this.setState({
                selectedClass: classResponse[0].id,
                selectedClassStudentAcademicPerformance: classResponse[0].id,
                selectedClassName: classResponse[0].value,
              })
              this.props.getSubjectDetailsToPR(
                {},
                (subjectSuccess, subjectResponse) => {
                  if (subjectSuccess) {
                    this.setState({
                      selectedSubject: subjectResponse[0].id,
                      selectedSubjectStudentAcademicPerformance: "ALL",
                      selectedSubjectAcademicSubjectPerformance:
                        subjectResponse[0].id,
                      selectedSubjectName: subjectResponse[0].value,
                    })
                    this.getPerformanceReport(
                      response[0].assessmentId,
                      subjectResponse[0].id,
                      classResponse[0].id
                    )
                  }
                }
              )
            }
          })
        }
      }
    )
  }

  getPerformanceReport = (id, subjectId, classId) => {
    this.schoolPerformanceReportService(id, subjectId, classId)
  }

  schoolPerformanceReportService = (id, subjectId, classId) => {
    let object = { id }
    this.setState(
      { isShowLoadingPassFail: true, isShowLoadingSchoolPerformance: true },
      () => {
        this.props.getSchoolPerformancePassFailReportDetails(object, () => {
          this.setState({ isShowLoadingPassFail: false })
        })
        this.props.getSchoolPerformanceReportDetails(object, () => {
          this.setState({ isShowLoadingSchoolPerformance: false }, () => {
            this.showSecondaryHigherGraph()
            this.showPrePrimaryGraph()
            this.showMiddleGraph()
          })
        })
        this.subjectPerformanceReportService(id, subjectId)
        this.studentPerformanceReportService(id, "ALL", classId)
      }
    )
  }

  subjectPerformanceReportService = (id, subjectId) => {
    let subjectPerformanceObject = {
      assessmentId: id,
      subjectId,
    }
    this.setState(
      {
        isShowLoadingSubjectPerformance: true,
        isShowNoSubjectPerformanceList: false,
      },
      () => {
        this.props.getSubjectPerformanceReportDetails(
          subjectPerformanceObject,
          (success, response) => {
            if (success)
              if (response.responseCode !== 0) {
                this.setState({ isShowNoSubjectPerformanceList: true })
              }
            this.setState({ isShowLoadingSubjectPerformance: false })
          }
        )
      }
    )
  }

  studentPerformanceReportService = (assessmentId, hSubjectId, sectionId) => {
    let tablePerformanceObject = {
      assessmentId,
      hSubjectId,
      sectionId,
    }
    this.setState({ isShowLoadingStudentPerformance: true }, () => {
      this.props.getStudentPerformanceReportDetails(
        tablePerformanceObject,
        (success, response) => {
          this.setState({ isShowLoadingStudentPerformance: false })
          if (success)
            this.setState({
              sliderTotalStudent: response.studentRankList.length,
              absentStudentCount: response.absent,
              totalStudent: response.studentRankList.length,
              outOffMark: response.outOffMark,
            })
        }
      )
    })
  }

  closeMessageModal = () => this.setState({ isMessageModalOpen: false })

  onClickMessageIcon = () =>
    this.setState({ isMessageModalOpen: !this.state.isMessageModalOpen })

  onSubmitMessage = () => this.closeMessageModal()

  customFilter = sliderValue => {
    const dummyStudents = this.props.studentPerformanceReport
    let array = []
    if (dummyStudents && dummyStudents.length > 0) {
      array = dummyStudents.filter(item => {
        return (
          item.percentage >= sliderValue[0] && item.percentage <= sliderValue[1]
        )
      })
      this.setState({
        sortedStudentsArray: array,
        startSliderValue: sliderValue[0],
        endSliderValue: sliderValue[1],
        sliderTotalStudent: array.length,
      })
      this.props.saveDisplayAdminStudentPerformanceReportDetailsInStore(array)
    }
  }

  sortStudentListBy = (column, state) => {
    this.setState(
      {
        [state]: this.state[state] === 0 ? 1 : this.state[state] === 1 ? -1 : 0,
      },
      () => {
        let sortArray = this.state.displayAdminStudentPerformanceReportDetails
        let newArray = []
        if (this.state[state] !== 0) {
          let sortBy = this.state[state] === -1 ? ["desc"] : ["asc"]
          newArray = _.orderBy(sortArray, [column], sortBy) // Use Lodash to sort array by key
          this.setState({ sortedStudentsArray: newArray })
        } else {
          this.setState({
            sortedStudentsArray: this.state
              .displayAdminStudentPerformanceReportDetails,
          })
        }
      }
    )
  }

  sortingByName = () => {
    this.setState({ sortOrderByNames: !this.state.sortOrderByNames }, () => {
      let sortArray = this.state.displayStudentPerformanceReportDetails
      let newArray = []
      if (this.state.sortOrderByNames === true) {
        newArray = sortArray.sort((a, b) => {
          let nameA = a.name.toUpperCase() // ignore upper and lowercase
          let nameB = b.name.toUpperCase() // ignore upper and lowercase
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          // names must be equal
          return 0
        })
      } else {
        newArray = sortArray.sort((a, b) => {
          let nameA = a.name.toUpperCase() // ignore upper and lowercase
          let nameB = b.name.toUpperCase() // ignore upper and lowercase
          if (nameA > nameB) {
            return -1
          }
          if (nameA < nameB) {
            return 1
          }
          // names must be equal
          return 0
        })
      }
      this.setState({ displayStudentPerformanceReportDetails: newArray })
    })
  }

  sortingByGrade = () => {
    this.setState({ sortOrderByGrades: !this.state.sortOrderByGrades }, () => {
      let sortArray = this.state.displayStudentPerformanceReportDetails
      let newArray = []
      if (this.state.sortOrderByGrades === true) {
        newArray = sortArray.sort((a, b) => {
          let nameA = a.grade.toUpperCase() // ignore upper and lowercase
          let nameB = b.grade.toUpperCase() // ignore upper and lowercase
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          // names must be equal
          return 0
        })
      } else {
        newArray = sortArray.sort((a, b) => {
          let nameA = a.grade.toUpperCase() // ignore upper and lowercase
          let nameB = b.grade.toUpperCase() // ignore upper and lowercase
          if (nameA > nameB) {
            return -1
          }
          if (nameA < nameB) {
            return 1
          }
          // names must be equal
          return 0
        })
      }
      this.setState({ displayStudentPerformanceReportDetails: newArray })
    })
  }

  sortingByMark = () => {
    this.setState({ sortOrderByMarks: !this.state.sortOrderByMarks }, () => {
      let sortArray = this.state.displayStudentPerformanceReportDetails
      let newArray = []
      if (this.state.sortOrderByMarks === true) {
        newArray = sortArray.sort((a, b) => {
          return a.mark - b.mark
        })
      } else {
        newArray = sortArray.sort((a, b) => {
          return a.mark - b.mark
        })
      }
      this.setState({ displayStudentPerformanceReportDetails: newArray })
    })
  }

  showSecondaryHigherGraph = () => {
    if (this.props.secondaryPerformanceReport) {
      const secondaryHigherGraph = this.secondaryHigherGraph
      if (mySecHigh != null) {
        mySecHigh.destroy()
      }
      mySecHigh = new Chart(secondaryHigherGraph, {
        type: "doughnut",
        data: {
          labels: this.props.secondaryPerformanceReport.classes,
          datasets: [
            {
              label: "Count",
              data: this.props.secondaryPerformanceReport.percentage,
              backgroundColor: this.props.secondaryPerformanceReport.colorCode,
              fill: false,
              borderWidth: 0,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          title: {
            display: true,
            text: "SECONDARY & HIGHER SECONDARY",
            fontColor: "#479997",
          },
          legend: {
            display: true,
            position: "right",
            labels: {
              usePointStyle: true,
              fontSize: 8,
              fontColor: "white",
            },
          },
          elements: {
            center: {
              text:
                this.props.secondaryPerformanceReport.averagePercentage + "%",
              color: "#ffffff", //Default black
              fontStyle: "Helvetica", //Default Arial
              sidePadding: 0, //Default 20 (as a percentage)
            },
          },
          tooltips: {
            callbacks: {
              title: function(tooltipItem, data) {
                return data["labels"][tooltipItem[0]["index"]]
              },
              label: function(tooltipItem, data) {
                // myLog(data, tooltipItem)
                var dataset = data["datasets"][0]
                var percent = dataset.data[tooltipItem.index]
                return percent + "%"
              },
            },
            xAlign: "left",
          },
        },
      })
    }
  }

  showMiddleGraph = () => {
    if (this.props.middlePerformanceReport) {
      const middleGraph = this.middleGraph
      if (myMiddle != null) {
        myMiddle.destroy()
      }

      myMiddle = new Chart(middleGraph, {
        type: "doughnut",
        data: {
          labels: this.props.middlePerformanceReport.classes,
          datasets: [
            {
              label: "Count",
              data: this.props.middlePerformanceReport.percentage,
              backgroundColor: this.props.middlePerformanceReport.colorCode,
              fill: false,
              borderWidth: 0,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          title: {
            display: true,
            fontColor: "#479997",
            text: "MIDDLE",
          },
          legend: {
            display: true,
            position: "right",
            labels: {
              usePointStyle: true,
              fontSize: 8,
              fontColor: "white",
            },
          },
          elements: {
            center: {
              text: this.props.middlePerformanceReport.averagePercentage + "%",
              color: "#ffffff", //Default black
              fontStyle: "Helvetica", //Default Arial
              sidePadding: 0, //Default 20 (as a percentage)
            },
          },
          tooltips: {
            callbacks: {
              title: function(tooltipItem, data) {
                return data["labels"][tooltipItem[0]["index"]]
              },
              label: function(tooltipItem, data) {
                // myLog(data, tooltipItem)
                var dataset = data["datasets"][0]
                var percent = dataset.data[tooltipItem.index]
                return percent + "%"
              },
            },
            xAlign: "left",
          },
        },
      })
    }
  }

  showPrePrimaryGraph = () => {
    if (this.props.primaryPerformanceReport) {
      const prePrimaryGraph = this.prePrimaryGraph
      if (myppp != null) {
        myppp.destroy()
      }
      myppp = new Chart(prePrimaryGraph, {
        type: "doughnut",
        data: {
          labels: this.props.primaryPerformanceReport.classes,
          datasets: [
            {
              label: "Count",
              data: this.props.primaryPerformanceReport.percentage,
              backgroundColor: this.props.primaryPerformanceReport.colorCode,
              fill: false,
              borderWidth: 0,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          title: {
            display: true,
            fontColor: "#479997",
            text: "PRE PRIMARY & PRIMARY",
          },
          legend: {
            display: true,
            position: "right",
            labels: {
              usePointStyle: true,
              fontSize: 8,
              fontColor: "white",
            },
          },
          elements: {
            center: {
              text: this.props.primaryPerformanceReport.averagePercentage + "%",
              color: "#ffffff", //Default black
              fontStyle: "Helvetica", //Default Arial
              sidePadding: 0, //Default 20 (as a percentage)
            },
          },
          tooltips: {
            callbacks: {
              title: function(tooltipItem, data) {
                return data["labels"][tooltipItem[0]["index"]]
              },
              label: function(tooltipItem, data) {
                // myLog(data, tooltipItem)
                var dataset = data["datasets"][0]
                var percent = dataset.data[tooltipItem.index]
                return percent + "%"
              },
            },
            xAlign: "left",
          },
        },
      })
    }
  }

  onChangeSelectedExamOverAllSchool = e => {
    this.setState(
      {
        selectedExamOverAllSchool: e.target.value,
        selectedExamAcademicSubjectPerformance: e.target.value,
        selectedExamStudentAcademicPerformance: e.target.value,
      },
      () => {
        let alreadySelectedExamIndex = this.props.examList.findIndex(item => {
          return item.assessmentId === this.state.selectedExamOverAllSchool
        })
        if (alreadySelectedExamIndex !== -1) {
          this.setState({
            selectedExamOverAllSchoolName: this.props.examList[
              alreadySelectedExamIndex
            ].value,
          })
        }
        // myLog(this.state.selectedExamOverAllSchoolName)
        this.schoolPerformanceReportService(
          this.state.selectedExamOverAllSchool,
          this.state.selectedSubject,
          this.state.selectedClass
        )
      }
    )
  }

  render() {
    return (
      <AppWrapper
        openMessageModal={this.onClickMessageIcon}
        showSchoolLogo
        showAdminThree
        adminDashboard
        screenName="Performance Report"
      >
        <MessageModal
          isModalOpen={this.state.isMessageModalOpen}
          closeModal={this.onClickMessageIcon}
          onSubmitMessage={this.onSubmitMessage}
        />
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <h5 className="text-theme font-weight-bold">
              OVERALL SCHOOL PERFORMANCE
            </h5>
            <span className="text-theme mx-3">|</span>
            <h5 className="text-theme font-weight-bold">
              {this.state.selectedExamOverAllSchoolName}
            </h5>
          </div>
          <div className="row mr-5">
            {/* <label>EXAMS</label> */}
            <select
              className="form-control"
              value={this.state.selectedExamOverAllSchool}
              onChange={this.onChangeSelectedExamOverAllSchool}
            >
              {this.props.examList &&
                this.props.examList.map(item => (
                  <option key={item.assessmentId} value={item.assessmentId}>
                    {item.value}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div
          className="bg-theme-light mt-3 px-5 py-3"
          style={{ borderRadius: 10 }}
        >
          <div className="row">
            <div className="bg-theme-verylight p-2 col-sm mr-3 br-10">
              {this.state.isShowLoadingSchoolPerformance ? (
                <div
                  className="d-flex justify-content-center"
                  style={{ marginTop: 30, paddingTop: 20, paddingBottom: 20 }}
                >
                  <HashLoader
                    sizeUnit="px"
                    loading={this.state.isShowLoadingSchoolPerformance}
                    color="#fff"
                    size={20}
                  />
                </div>
              ) : (
                <div className="row col-sm-12 h-100">
                  <div className="col-sm-4 my-auto">
                    <canvas
                      ref={prePrimaryGraph =>
                        (this.prePrimaryGraph = prePrimaryGraph)
                      }
                    />
                  </div>
                  <div className="col-sm-4 my-auto">
                    <canvas
                      ref={middleGraph => (this.middleGraph = middleGraph)}
                    />
                  </div>
                  <div className="col-sm-4 my-auto">
                    <canvas
                      ref={secondaryHigherGraph =>
                        (this.secondaryHigherGraph = secondaryHigherGraph)
                      }
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="col-sm-3 p-0">
              <div className="d-flex flex-column">
                <div
                  className="bg-theme-verylight p-3 d-flex"
                  style={{ borderRadius: 10 }}
                >
                  <div style={{ width: "60px", height: "60px" }}>
                    <CircularProgressbar
                      value={
                        this.props.schoolPerformanceReport &&
                        this.props.schoolPerformanceReport.average
                      }
                      text={`${
                        this.props.schoolPerformanceReport !== undefined
                          ? this.props.schoolPerformanceReport &&
                            this.props.schoolPerformanceReport.average
                          : 0
                      }%`}
                      styles={buildStyles({
                        pathColor: `#0fb293`,
                        textColor: "#ffffff",
                        trailColor: "rgba(71, 153, 151, .22)",
                      })}
                    />
                  </div>
                  <div className="text-light align-self-center ml-3">
                    School Average
                  </div>
                </div>
                <div
                  className="bg-theme-verylight p-2 mt-3"
                  style={{ borderRadius: 10 }}
                >
                  {this.state.isShowLoadingPassFail ? (
                    <div
                      className="d-flex justify-content-center"
                      style={{
                        marginTop: 0,
                        paddingTop: 20,
                        paddingBottom: 20,
                      }}
                    >
                      <HashLoader
                        sizeUnit="px"
                        loading={this.state.isShowLoadingPassFail}
                        color="#fff"
                        size={20}
                      />
                    </div>
                  ) : (
                    <div>
                      <div className="text-center font-weight-bold fs-12 text-light">
                        OVERALL PASS PERCENTAGE
                      </div>
                      <Progress
                        percent={
                          this.props.schoolPerformanceReportPassFailDetails &&
                          this.props.schoolPerformanceReportPassFailDetails
                            .passPercent
                        }
                        status="active"
                        className="my-2"
                        symbolClassName="d-none"
                        theme={{
                          active: {
                            symbol: "-",
                            trailColor: "#f77272",
                            color: "#0fb293",
                          },
                        }}
                      />
                      <div className="text-center d-flex justify-content-around fs-12 font-weight-bold text-light">
                        <span>
                          <span
                            className="text-theme-light"
                            role="img"
                            aria-label=""
                          >
                            <i className="fas fa-circle fs-7 p-2" />
                          </span>
                          Pass -{" "}
                          {this.props.schoolPerformanceReportPassFailDetails &&
                            this.props.schoolPerformanceReportPassFailDetails
                              .passPercent}
                          %
                        </span>
                        <span>
                          <span
                            className="text-theme-red"
                            role="img"
                            aria-label=""
                          >
                            <i className="fas fa-circle fs-7 p-2" />
                          </span>
                          Fail -{" "}
                          {this.props.schoolPerformanceReportPassFailDetails &&
                            this.props.schoolPerformanceReportPassFailDetails
                              .failPercent}
                          %
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr></hr>
        {/* ACADEMIC SUBJECT PERFORMANCE */}
        <div className="d-flex justify-content-between my-4 font-weight-bold">
          <div className="d-flex">
            <h5 className="text-theme font-weight-bold">SUBJECT PERFORMANCE</h5>
          </div>
          <div className="row mr-5">
            <div className="p-2">
              <select
                className="form-control"
                value={this.state.selectedSubjectAcademicSubjectPerformance}
                onChange={e => {
                  this.setState(
                    {
                      selectedSubjectAcademicSubjectPerformance: e.target.value,
                    },
                    () => {
                      this.subjectPerformanceReportService(
                        this.state.selectedExamAcademicSubjectPerformance,
                        this.state.selectedSubjectAcademicSubjectPerformance,
                        this.state.selectedClass
                      )
                    }
                  )
                }}
              >
                {this.props.subjectList &&
                  this.props.subjectList.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.value}
                    </option>
                  ))}
              </select>
            </div>

            <div className="p-2">
              <select
                className="form-control"
                value={this.state.selectedExamAcademicSubjectPerformance}
                onChange={e => {
                  this.setState(
                    { selectedExamAcademicSubjectPerformance: e.target.value },
                    () => {
                      // myLog(this.state.selectedExamAcademicSubjectPerformance)
                      this.subjectPerformanceReportService(
                        this.state.selectedExamAcademicSubjectPerformance,
                        this.state.selectedSubjectAcademicSubjectPerformance,
                        this.state.selectedClass
                      )
                    }
                  )
                }}
              >
                {this.props.examList &&
                  this.props.examList.map(item => (
                    <option key={item.assessmentId} value={item.assessmentId}>
                      {item.value}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>

        {this.state.isShowLoadingSubjectPerformance ? (
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: 20, paddingBottom: 20 }}
          >
            <HashLoader
              sizeUnit="px"
              loading={this.state.isShowLoadingSubjectPerformance}
              color="#084353"
              size={30}
            />
          </div>
        ) : this.state.isShowNoSubjectPerformanceList ? (
          <div className="d-flex justify-content-center">No data found</div>
        ) : (
          <div className="table-responsive">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th width="25" scope="col">
                    {/* <small>Classes / Sections</small> */}
                  </th>
                  {this.props.sectionPerformanceReport &&
                    this.props.sectionPerformanceReport.map((item, i) => (
                      <th key={i} scope="col">
                        <div className="bg-lightgray px-2 py-1 text-center">
                          {item.sectionName}
                        </div>
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {this.props.classSectionPerformanceReport &&
                  this.props.classSectionPerformanceReport.map(
                    (classes, classesIndex) => (
                      <tr key={classesIndex}>
                        <th scope="row">
                          <b className="bg-lightgray px-2 py-1 d-flex justify-content-center">
                            {classes.name}
                          </b>
                        </th>
                        {classes.sections &&
                          classes.sections.map((section, sectionIndex) => (
                            <td key={sectionIndex}>
                              <div
                                className={`text-light py-1 px-1 border border-3 ${
                                  section.highlightLow ? "border-danger" : ""
                                } ${
                                  section.highlightHigh ? "border-success" : ""
                                }`}
                                onClick={() => {
                                  this.scrollToMyRef(section)
                                }}
                              >
                                <UncontrolledTooltip
                                  placement="top"
                                  target={`prekg-a-pass${classesIndex}${sectionIndex}`}
                                >
                                  {section.sectionPassPercentage}
                                  {" % students passed"}
                                </UncontrolledTooltip>
                                <UncontrolledTooltip
                                  placement="top"
                                  target={`prekg-a-fail${classesIndex}${sectionIndex}`}
                                >
                                  {section.sectionFailPercentage}
                                  {" % students failed"}
                                </UncontrolledTooltip>
                                <div className="class-wise-progress">
                                  <div
                                    className="progress-bar bg-pass"
                                    role="progressbar"
                                    id={`prekg-a-pass${classesIndex}${sectionIndex}`}
                                    style={{
                                      width:
                                        section.sectionPassPercentage + "%",
                                    }}
                                    aria-valuenow={
                                      section.sectionPassPercentage
                                    }
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  >
                                    {/* {section.sectionPassPercentage} */}
                                  </div>
                                  <div
                                    className="progress-bar bg-fail"
                                    role="progressbar"
                                    id={`prekg-a-fail${classesIndex}${sectionIndex}`}
                                    style={{
                                      width:
                                        section.sectionFailPercentage + "%",
                                    }}
                                    aria-valuenow={
                                      section.sectionFailPercentage
                                    }
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  >
                                    {/* {section.sectionFailPercentage} */}
                                  </div>
                                </div>
                              </div>
                            </td>
                          ))}
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          </div>
        )}
        <hr></hr>
        {/* Students Academic Performance */}

        <div
          className="d-flex justify-content-between my-3 pt-5 "
          ref={refData => (this.myRef = refData)}
        >
          <div className="d-flex">
            <h5 className="text-theme font-weight-bold">STUDENT PERFORMANCE</h5>
          </div>
          <div className="row mr-5">
            <div className="p-2">
              {/* <label htmlFor="exampleFormControlSelect1">Exams</label> */}
              <select
                className="form-control"
                value={this.state.selectedExamStudentAcademicPerformance}
                onChange={e => {
                  this.setState(
                    {
                      selectedExamStudentAcademicPerformance: e.target.value,
                      sortOrderByNames: 0,
                      sortOrderByMarks: 0,
                      sortOrderByGrades: 0,
                    },
                    () => {
                      // myLog(this.state.selectedExamStudentAcademicPerformance)
                      this.studentPerformanceReportService(
                        this.state.selectedExamStudentAcademicPerformance,
                        this.state.selectedSubjectStudentAcademicPerformance,
                        this.state.selectedClassStudentAcademicPerformance,
                        this.state.selectedClass
                      )
                    }
                  )
                }}
              >
                {this.props.examList &&
                  this.props.examList.map(item => (
                    <option key={item.assessmentId} value={item.assessmentId}>
                      {item.value}
                    </option>
                  ))}
              </select>
            </div>
            <div className="p-2">
              {/* <label htmlFor="exampleFormControlSelect1">Subject</label> */}
              <select
                className="form-control"
                value={this.state.selectedSubjectStudentAcademicPerformance}
                onChange={e => {
                  this.setState(
                    {
                      selectedSubjectStudentAcademicPerformance: e.target.value,
                      sortOrderByNames: 0,
                      sortOrderByMarks: 0,
                      sortOrderByGrades: 0,
                    },
                    () => {
                      // myLog(
                      //   this.state.selectedSubjectStudentAcademicPerformance
                      // )
                      this.studentPerformanceReportService(
                        this.state.selectedExamStudentAcademicPerformance,
                        this.state.selectedSubjectStudentAcademicPerformance,
                        this.state.selectedClassStudentAcademicPerformance,
                        this.state.selectedClass
                      )
                    }
                  )
                }}
              >
                <option key={-1} value="ALL">
                  ALL
                </option>
                {this.props.subjectList &&
                  this.props.subjectList.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.value}
                    </option>
                  ))}
              </select>
            </div>
            <div className="p-2">
              {/* <label htmlFor="exampleFormControlSelect1">Class</label> */}
              <select
                className="form-control"
                value={this.state.selectedClassStudentAcademicPerformance}
                onChange={e => {
                  this.setState(
                    {
                      selectedClassStudentAcademicPerformance: e.target.value,
                      sortOrderByNames: 0,
                      sortOrderByMarks: 0,
                      sortOrderByGrades: 0,
                    },
                    () => {
                      // myLog(this.state.selectedClassStudentAcademicPerformance)
                      this.studentPerformanceReportService(
                        this.state.selectedExamStudentAcademicPerformance,
                        this.state.selectedSubjectStudentAcademicPerformance,
                        this.state.selectedClassStudentAcademicPerformance,
                        this.state.selectedClass
                      )
                    }
                  )
                }}
              >
                {this.props.classesList &&
                  this.props.classesList.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.value}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          {/* <div className="form-group col-sm-3">
            <label>Sections</label>
            <Select
              placeholder="Sections"
              isClearable
              isMulti
              options={[
                { value: "LKG", label: "LKG" },
                { value: "UKG", label: "UKG" },
                { value: "1", label: "1" },
              ]}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div> */}
        </div>
        <div className="d-flex justify-content-between my-3">
          <div className="w-50">
            <label>PERCENT SLIDER</label>
            <Range
              step={0.1}
              min={0}
              max={100}
              values={this.state.studentPerformanceRange}
              onChange={studentPerformanceRange => {
                this.setState({ studentPerformanceRange })
                this.customFilter(studentPerformanceRange)
              }}
              renderTrack={({ props, children }) => (
                <div
                  onMouseDown={props.onMouseDown}
                  onTouchStart={props.onTouchStart}
                  style={{
                    ...props.style,
                    height: "36px",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <div
                    ref={props.ref}
                    style={{
                      height: "5px",
                      width: "50%",
                      borderRadius: "4px",
                      background: "#00434c",
                    }}
                  >
                    {children}
                  </div>
                </div>
              )}
              renderThumb={({ index, props }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "20px",
                    width: "20px",
                    borderRadius: "50%",
                    display: "flex",
                    backgroundColor: "#479997",
                    justifyContent: "center",
                    color: "white",
                    fontSize: 10,
                    alignItems: "center",
                    boxShadow: "0px 2px 6px #AAA",
                  }}
                >
                  {Math.round(
                    this.state.studentPerformanceRange[index].toFixed(1)
                  )}
                </div>
              )}
            />
          </div>

          {/* <div>
            <form className="navbar-form" role="search">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                />
                <div className="input-group-btn">
                  <button className="btn btn-theme" type="button">
                    <i className="fal fa-search" />
                  </button>
                </div>
              </div>
            </form>
          </div> */}
        </div>
        <div className="d-flex justify-content-end mb-3">
          <div className="bg-theme-light p-2 table-top-exclam">
            <i className="fas fa-exclamation table-top-exclam-color" />
          </div>
          <div className="bg-lightblue-td br-10 px-2 py-1">
            {this.state.sliderTotalStudent} of {this.state.totalStudent}{" "}
            students have secured marks between {this.state.startSliderValue}%
            and {this.state.endSliderValue}%
          </div>
        </div>
        <div className="table-responsive mb-4">
          <table className="table table-borderless" cellPadding="3">
            <thead>
              <tr className="text-light">
                <th scope="col" className="first-child-curved text-center">
                  ROLL NO
                </th>
                <th
                  scope="col"
                  className="bg-theme-light text-center cursor-pointer"
                  onClick={() => {
                    this.sortStudentListBy("name", "sortOrderByNames")
                  }}
                >
                  NAME
                  {this.state.sortOrderByNames === 1 ? (
                    <i className="fas fa-caret-up ml-3" />
                  ) : this.state.sortOrderByNames === -1 ? (
                    <i className="fas fa-caret-down ml-3" />
                  ) : (
                    <i className="fas fa-sort ml-3" />
                  )}
                </th>
                <th scope="col" className="bg-theme-light text-center">
                  SECTION
                </th>
                <th
                  scope="col"
                  className="bg-theme-light text-center cursor-pointer"
                  onClick={() => {
                    this.sortStudentListBy("mark", "sortOrderByMarks")
                  }}
                >
                  MARKS <small>out of {this.state.outOffMark}</small>
                  {this.state.sortOrderByMarks === 1 ? (
                    <i className="fas fa-caret-up ml-3" />
                  ) : this.state.sortOrderByMarks === -1 ? (
                    <i className="fas fa-caret-down ml-3" />
                  ) : (
                    <i className="fas fa-sort ml-3" />
                  )}
                </th>
                <th scope="col" className="bg-theme-light text-center">
                  PERCENTAGE(%)
                </th>
                <th
                  scope="col"
                  className="bg-theme-light text-center cursor-pointer"
                  // onClick={() => {
                  //   this.sortStudentListBy("grade", "sortOrderByGrades")
                  // }}
                >
                  GRADE
                  {/* {this.state.sortOrderByGrades === 1 ? (
                    <i className="fas fa-caret-up ml-3" />
                  ) : this.state.sortOrderByGrades === -1 ? (
                    <i className="fas fa-caret-down ml-3" />
                  ) : (
                    <i className="fas fa-sort ml-3" />
                  )} */}
                </th>
                <th
                  scope="col"
                  className="bg-theme-light text-center cursor-pointer"
                  onClick={() => {
                    this.sortStudentListBy("rank", "sortOrderByRanks")
                  }}
                >
                  RANK
                  {this.state.sortOrderByRanks === 1 ? (
                    <i className="fas fa-caret-up ml-3" />
                  ) : this.state.sortOrderByRanks === -1 ? (
                    <i className="fas fa-caret-down ml-3" />
                  ) : (
                    <i className="fas fa-sort ml-3" />
                  )}
                </th>
                <th scope="col" className="last-child-curved text-center">
                  SCHOOL RANK
                </th>
              </tr>
            </thead>
            {!this.state.isShowLoadingStudentPerformance ? (
              <tbody>
                {this.state.sortedStudentsArray &&
                  this.state.sortedStudentsArray.map((item, index) => (
                    <React.Fragment key={index}>
                      <div className="m-2"></div>
                      <tr>
                        <td className="first-child-curved-td text-center">
                          {item.rollNo}
                        </td>
                        <td
                          className="bg-lightblue-td text-center"
                          // onClick={() => {
                          //   this.props.history.push({
                          //     pathname: URL_PATH.ADMIN_STUDENT_PROFILE,
                          //     state: { studentId: item.studentId },
                          //   })
                          // }}
                        >
                          {item.name}
                        </td>
                        <td className="bg-lightblue-td text-center">
                          {item.section}
                        </td>
                        <td className="bg-lightblue-td text-center">
                          {item.mark}
                        </td>
                        <td className="bg-lightblue-td text-center">
                          {`${item.percentage}`.slice(0, 5)}
                        </td>
                        <td className="d-flex justify-content-center bg-lightblue-td text-center">
                          <div
                            className="grade-a1 fs-12"
                            style={{
                              background: item.gradeColor,
                              padding: 3,
                              height: 22,
                              width: 22,
                              borderRadius: "15px",
                            }}
                          >
                            {item.grade}
                          </div>
                        </td>
                        <td className="bg-lightblue-td text-center">
                          <span>
                            {item.rank}
                            {/* {item.rankStatus === "increase" ? (
                            <i className="fas fa-caret-up ml-2 text-success" />
                          ) : (
                            <i className="fas fa-caret-down ml-2 text-danger" />
                          )} */}
                          </span>
                        </td>
                        <td className="last-child-curved-td text-center">
                          <span>
                            {item.classRank}
                            {/* {item.schoolRankStatus === "increase" ? (
                            <i className="fas fa-caret-up ml-2 text-success" />
                          ) : (
                            <i className="fas fa-caret-down ml-2 text-danger" />
                          )} */}
                          </span>
                        </td>
                      </tr>
                      <div className="m-2"></div>
                    </React.Fragment>
                  ))}
              </tbody>
            ) : null}
          </table>

          {!this.state.isShowLoadingStudentPerformance &&
            this.state.sortedStudentsArray &&
            this.state.sortedStudentsArray.length === 0 && (
              <div className="d-flex justify-content-center">No data found</div>
            )}

          {this.state.isShowLoadingStudentPerformance && (
            <Skeleton count={5} height={30} width="100%" />
          )}
        </div>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    subjectList: state.subjectDetailsState.subjectList,
    classesList: state.classDetailsState.classList,
    examList: state.adminPerformanceReportDetailsState.examList,

    schoolPerformanceReport:
      state.adminPerformanceReportDetailsState.schoolPerformanceReportDetails
        .schoolAverage,
    schoolPerformanceReportPassFailDetails:
      state.adminPerformanceReportDetailsState
        .schoolPerformanceReportPassFailDetails,
    primaryPerformanceReport:
      state.adminPerformanceReportDetailsState.schoolPerformanceReportDetails
        .primary,
    middlePerformanceReport:
      state.adminPerformanceReportDetailsState.schoolPerformanceReportDetails
        .middle,
    secondaryPerformanceReport:
      state.adminPerformanceReportDetailsState.schoolPerformanceReportDetails
        .secondary,

    classPerformanceReport:
      state.adminPerformanceReportDetailsState.classPerformanceReportDetails
        .classes,
    classSchoolGraphPerformanceReport:
      state.adminPerformanceReportDetailsState.classPerformanceReportDetails
        .schools,

    sectionPerformanceReport:
      state.adminPerformanceReportDetailsState.subjectPerformanceReportDetails
        .sections,
    classSectionPerformanceReport:
      state.adminPerformanceReportDetailsState.subjectPerformanceReportDetails
        .classes,

    studentPerformanceReport:
      state.adminPerformanceReportDetailsState.studentPerformanceReportDetails,
    displayAdminStudentPerformanceReportDetails:
      state.adminPerformanceReportDetailsState
        .displayAdminStudentPerformanceReportDetails,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getClassDetails,
      getExamListDetails,
      getSubjectDetailsToPR,
      getClassPerformanceReportDetails,
      getSchoolPerformanceReportDetails,
      getSubjectPerformanceReportDetails,
      getStudentPerformanceReportDetails,
      getSchoolPerformancePassFailReportDetails,
      saveStudentPerformanceReportDetailsInStore,
      saveDisplayAdminStudentPerformanceReportDetailsInStore,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(PerformanceReport)
