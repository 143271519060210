/*************************************************
 * RedInk
 * @exports
 * @class Wrapper.js
 * @extends Component
 * @author Sasidharan // on 04/06/2020
 * @copyright © RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { Link } from "react-router-dom"

import { URL_PATH } from "../../config/urlPath"
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap"
import profilePic from "../../assets/images/admin.png"
import redinkLogo from "../../assets/images/redink/redink_logo.png"
import { logout } from "../../utils/Utility"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router"

class Wrapper extends Component {
  /**
   * Creates an instance of HOC.
   * @param {any} props
   * @memberof HOC
   */
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <div className="modern">
        <header className="header fixed-top">
          <nav className="bg-white navbar navbar-expand d-flex justify-content-between">
            <ul className="px-2 navbar-nav site-logo">
              <li
                className="p-1 mr-2 cursor-pointer nav-item show-mdsm d-none"
                onClick={() =>
                  (document.getElementById("overlayNav").style.width = "100%")
                }
              >
                <i className="far fa-bars"></i>
              </li>
              <li className="px-2 nav-item row">
                <div className="navbar-brand text-theme font-weight-bolder mt-2 fs-14">
                  REDINK
                </div>
              </li>
            </ul>
            <ul className="px-2 navbar-nav site-logo navbar-expand-sm">
              <li
                className="px-2 mr-2 nav-item notification-icon"
                title="Notifications"
              >
                <div className="nav-link text-dark ">
                  <i className="far fa-bell"></i>
                </div>
              </li>
              <li>
                <UncontrolledDropdown>
                  <DropdownToggle tag="a" className="nav-link">
                    <Link
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={e => e.preventDefault()}
                      to=""
                      className="p-0 btn d-flex justify-content-between"
                    >
                      <img
                        className="rounded-circle"
                        src={profilePic}
                        alt="Profile"
                        width={32}
                      />
                    </Link>
                  </DropdownToggle>
                  <DropdownMenu>
                    {/* <DropdownItem
                      onClick={() => {
                        this.props.history.push(URL_PATH.FOUNDER_CHANGEPASSWORD)
                      }}
                    >
                      Change Password
                    </DropdownItem> */}
                    <DropdownItem divider />
                    <DropdownItem onClick={() => logout()}>Logout</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          </nav>
        </header>
        {/* Overlay Navbar for tab and mobile */}
        <div id="overlayNav" className="overlay d-none">
          <span
            className="closebtn"
            onClick={() =>
              (document.getElementById("overlayNav").style.width = "0%")
            }
          >
            &times;
          </span>
          <div className="overlay-content">
            <div className="app-sidebar__inner">
              <ul className="vertical-nav-menu text-light">
                <li
                  className={
                    URL_PATH.FOUNDER === window.location.pathname
                      ? "menu-active"
                      : ""
                  }
                >
                  <Link to={URL_PATH.FOUNDER}>Founder</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Overlay Navbar for tab and mobile ends*/}
        {/* Overlay Navbar for pc starts*/}
        <aside className="aside-left">
          <nav className="menu d-flex flex-column justify-content-between">
            <ul>
              <li>
                <Link
                  to={URL_PATH.FOUNDER}
                  className={`d-flex flex-column justify-content-between`}
                >
                  <img
                    className="img-fluid"
                    src={redinkLogo}
                    alt="Redink"
                    width="40"
                    height="40"
                  />
                </Link>
              </li>

              <li>
                <Link
                  to={URL_PATH.FOUNDER}
                  className={`d-flex justify-content-center
                    ${
                      URL_PATH.FOUNDER === window.location.pathname ||
                      URL_PATH.FOUNDER_PROFILE === window.location.pathname ||
                      URL_PATH.FOUNDER_CHANGEPASSWORD ===
                        window.location.pathname
                        ? "menu-active"
                        : ""
                    }
                  `}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-dashboard"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#bdc7cb"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <circle cx="12" cy="13" r="2" />
                    <line x1="13.45" y1="11.55" x2="15.5" y2="9.5" />
                    <path d="M6.4 20a9 9 0 1 1 11.2 0Z" />
                  </svg>
                  <span>Dashboard</span>
                </Link>
              </li>
              <li>
                <Link
                  to={URL_PATH.FOUNDER_SUPER_ADMIN}
                  className={`d-flex justify-content-center
                    ${
                      URL_PATH.FOUNDER_SUPER_ADMIN ===
                        window.location.pathname ||
                      URL_PATH.FOUNDER_EDIT_SUPER_ADMIN ===
                        window.location.pathname
                        ? "menu-active"
                        : ""
                    }
                  `}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-school"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#ffffff"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M22 9l-10 -4l-10 4l10 4l10 -4v6" />
                    <path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4" />
                  </svg>
                  <span>Schools</span>
                </Link>
              </li>
              <li>
                <Link
                  to={URL_PATH.FOUNDER_ADMIN}
                  className={`d-flex justify-content-center
                    ${
                      URL_PATH.FOUNDER_ADMIN === window.location.pathname
                        ? "menu-active"
                        : ""
                    }
                  `}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-user-check"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#ffffff"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 11l2 2l4 -4" />
                  </svg>
                  <span>Admin</span>
                </Link>
              </li>
              <li>
                <Link
                  to={URL_PATH.FOUNDER_TEACHER}
                  className={`d-flex justify-content-center
                    ${
                      URL_PATH.FOUNDER_TEACHER === window.location.pathname
                        ? "menu-active"
                        : ""
                    }
                  `}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-friends"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#bdc7cb"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <circle cx="7" cy="5" r="2" />
                    <path d="M5 22v-5l-1-1v-4a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4l-1 1v5" />
                    <circle cx="17" cy="5" r="2" />
                    <path d="M15 22v-4h-2l2 -6a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1l2 6h-2v4" />
                  </svg>
                  <span>Teachers</span>
                </Link>
              </li>
              <li>
                <Link
                  to={URL_PATH.FOUNDER_STUDENT}
                  className={`d-flex justify-content-center
                    ${
                      URL_PATH.FOUNDER_STUDENT === window.location.pathname
                        ? "menu-active"
                        : ""
                    }
                  `}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-users"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#ffffff"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                  </svg>
                  <span>Students</span>
                </Link>
              </li>
              <li>
                <Link
                  to={URL_PATH.FOUNDER_QUESTIONBANK}
                  className={`d-flex justify-content-center
                    ${
                      URL_PATH.FOUNDER_QUESTIONBANK === window.location.pathname
                        ? "menu-active"
                        : ""
                    }
                  `}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-file-invoice"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#bdc7cb"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z"></path>
                    <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"></path>
                    <line x1="9" y1="7" x2="10" y2="7"></line>
                    <line x1="9" y1="13" x2="15" y2="13"></line>
                    <line x1="13" y1="17" x2="15" y2="17"></line>
                  </svg>
                  <span>Question Bank</span>
                </Link>
              </li>

              <li>
                <Link
                  to={URL_PATH.FOUNDER_BEHAVIOUR}
                  className={`d-flex justify-content-center
                    ${
                      URL_PATH.FOUNDER_BEHAVIOUR === window.location.pathname
                        ? "menu-active"
                        : ""
                    }
                  `}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-file-invoice"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#bdc7cb"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z"></path>
                    <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"></path>
                    <line x1="9" y1="7" x2="10" y2="7"></line>
                    <line x1="9" y1="13" x2="15" y2="13"></line>
                    <line x1="13" y1="17" x2="15" y2="17"></line>
                  </svg>
                  <span>Behaviour</span>
                </Link>
              </li>
            </ul>
          </nav>
        </aside>
        <div
          className={`app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header closed-sidebar ${
            this.state.toggleMobileSidemenu ? "sidebar-mobile-open" : ""
          }`}
        >
          <div className="bg-white app-main">
            <div className="app-main__outer">
              <div className="app-main__inner">{this.props.children}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, dispatchToProps)(withRouter(Wrapper))
