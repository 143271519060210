/*************************************************
 * RedInk
 * @exports
 * @class SchoolDetailsDashboard.js
 * @extends Component
 * @author Sasidharan // on 31/12/2019
 * @copyright Â© 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { HashLoader } from "react-spinners"
import { connect } from "react-redux"

import "../../../assets/css/dev.css"

import {
  saveAdminSchoolProfile,
  getAdminSchoolProfile,
} from "../../../actions/admin/ProfileAction"
import { saveStudentDatabaseDetailInStore } from "../../../actions/teacher/StudentDatabaseAction"
import { DANGER_TOAST, VALIDATION_MSG } from "../../../utils/Constants"
import { getSchoolDetailsAdmin } from "../../../actions/admin/DashboardAction"
import { uploadFile, getFile } from "../../../actions/CommonAction"
import { showNotification } from "../../../actions/CommonAction"
import { validateEmail } from "../../../utils/Utility"

import AppWrapper from "../Wrapper1"

import placeholder_bg from "../../../assets/images/redink/placeholder_bg.jpg"

const CheckIsValidDomain = domain => {
  var re = new RegExp(
    /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/
  )
  return domain.match(re)
}

class SchoolDetailsDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showSideMenu: false,
      isShowLoading: true,
      schoolDetailsAdmin: {},
      profileLogo: null,
      schoolLogo: "",
      showChooseLogo: true,
      schoolLogoURL: "",
      isLoadingSchoolLogoURL: true,
      customFromAddress: null,
      affiliation: "",
      schoolRegId: "",
      landLine: "",
      website: "",
      yearFounded: "",
      contactNumber: "",
      emailId: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      country: "",
      zipCode: "",
      state: "",
      mailSender: "",
      mailSenderDetails: "",
    }
  }

  componentDidMount = () => {
    this.setState({ isShowLoading: false })
    this.props.getSchoolDetailsAdmin((success, response) => {
      if (success) {
        if (response.schoolLogo !== "") {
          this.getSchoolLogoURL(response.schoolLogo)
        }
        this.setState({
          isLoadingSchoolLogoURL: false,
          schoolDetailsAdmin: response,
          contactNumber: response.contactNumber,
          affiliation: response.affiliation,
          schoolRegId: response.schoolRegId,
          landLine: response.landLine,
          website: response.website,
          yearFounded: response.yearFounded,
          emailId: response.emailId,
          addressLine1: response.addressLine1,
          addressLine2: response.addressLine2,
          city: response.city,
          country: response.country,
          zipCode: response.zipCode,
          state: response.state,
          schoolLogo: response.schoolLogo,
          mailSender: response.mailSender,
          mailSenderDetails: response.mailSenderDetails,
        })
      }
    })
    setTimeout(() => {
      let el = document.getElementById("regId")
      if (el) {
        el.focus()
      }
    }, 500)
  }

  getSchoolLogoURL = url => {
    this.props.getFile(url, (success, response) => {
      if (success) {
        this.setState({
          schoolLogoURL: response.url || "",
          isLoadingSchoolLogoURL: false,
        })
      }
    })
  }

  saveAdminSchoolProfile = () => {
    if (this.state.landLine !== "" && this.state.landLine !== null) {
      if (
        String(this.state.landLine).length < 8 ||
        String(this.state.landLine).length > 13
      ) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.ALERT,
          "Landline No. invalid"
        )
        return
      }
    }

    if (this.state.yearFounded !== "" && this.state.yearFounded !== null) {
      if (String(this.state.yearFounded).length !== 4) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.ALERT,
          "Invalid Founded year"
        )
        return
      }
    }

    // if (this.state.schoolRegId !== "" || this.state.schoolRegId !== null) {
    //   this.props.showNotification(
    //     DANGER_TOAST,
    //     VALIDATION_MSG.ALERT,
    //     "school Reg.Id cannot be empty"
    //   )
    //   return
    // }

    // if (this.state.addressLine1 === "" || this.state.addressLine1 == null) {
    //   this.props.showNotification(
    //     DANGER_TOAST,
    //     VALIDATION_MSG.ALERT,
    //     "Address Line1 cannot be empty"
    //   )
    //   return
    // }
    // if (this.state.addressLine2 === "" || this.state.addressLine2 == null) {
    //   this.props.showNotification(
    //     DANGER_TOAST,
    //     VALIDATION_MSG.ALERT,
    //     "Address Line2 cannot be empty"
    //   )
    //   return
    // }

    if (this.state.website !== "" && this.state.website !== null) {
      if (!CheckIsValidDomain(this.state.website)) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.ALERT,
          "Website name invalid"
        )
        return
      }
    }

    if (this.state.emailId) {
      if (!validateEmail(this.state.emailId)) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.ALERT,
          "Email id invalid"
        )
        return
      }
    }

    if (this.state.contactNumber !== "" && this.state.contactNumber !== null) {
      if (
        String(this.state.contactNumber).length < 8 ||
        String(this.state.contactNumber).length >= 12
      ) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.ALERT,
          "Contact no invalid"
        )
        return
      }
    }

    // if (this.state.city === "") {
    //   this.props.showNotification(
    //     DANGER_TOAST,
    //     VALIDATION_MSG.ALERT,
    //     "City name cannot be empty  "
    //   )
    //   return
    // }

    // if (this.state.country === "") {
    //   this.props.showNotification(
    //     DANGER_TOAST,
    //     VALIDATION_MSG.ALERT,
    //     "Country cannot be empty  "
    //   )
    //   return
    // }
    // if (this.state.state === "") {
    //   this.props.showNotification(
    //     DANGER_TOAST,
    //     VALIDATION_MSG.ALERT,
    //     "State cannot be empty  "
    //   )
    //   return
    // }
    if (this.state.zipCode !== "" && this.state.zipCode !== null) {
      if (String(this.state.zipCode).length < 6) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.ALERT,
          "Invalid Zip code "
        )
        return
      }
    }

    if (this.state.mailSender === "customfrom") {
      if (
        !this.state.mailSenderDetails ||
        !validateEmail(this.state.mailSenderDetails)
      ) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Please enter valid Email Address for Customer From Address"
        )
        this.setState({ isShowLoading: false })
        return
      }
    }

    this.setState({ isShowLoading: true })
    let obj = {
      contactNumber: this.state.contactNumber ? this.state.contactNumber : "",
      // affiliation: this.state.affiliation,
      schoolRegId: this.state.schoolRegId ? this.state.schoolRegId : "",
      landLine: this.state.landLine ? this.state.landLine : "",
      website: this.state.website ? this.state.website : "",
      yearFounded: this.state.yearFounded ? this.state.yearFounded : "",
      emailId: this.state.emailId ? this.state.emailId : "",
      addressLine1: this.state.addressLine1 ? this.state.addressLine1 : "",
      addressLine2: this.state.addressLine2 ? this.state.addressLine2 : "",
      city: this.state.city ? this.state.city : "",
      country: this.state.country ? this.state.country : "",
      zipCode: this.state.zipCode ? this.state.zipCode : "",
      state: this.state.state ? this.state.state : "",
      schoolLogo: this.state.schoolLogo ? this.state.schoolLogo : "",
      mailSender: this.state.mailSender,
      mailSenderDetails:
        this.state.mailSender === "customfrom"
          ? this.state.mailSenderDetails
          : "",
    }

    this.props.saveAdminSchoolProfile(obj, (success, response) => {
      if (success) {
        this.componentDidMount()
        this.setState({ isShowLoading: false })
      } else {
        this.setState({ isShowLoading: false })
      }
    })
  }

  openSite = e => {
    window.open(e.target.value)
  }

  imageHandler = async e => {
    await new Promise(resolve =>
      this.props.uploadFile(
        e.target.files[0],
        "school/logos",
        (success, response) =>
          resolve(
            { success, response },
            this.setState({ schoolLogo: response.name }, () => {
              this.getSchoolLogoURL(response.name)
              this.saveAdminSchoolProfile()
            })
          )
      )
    )
  }

  render() {
    return (
      <AppWrapper
        showAdminProfileSidemenu
        showAdminBackToDashboard
        screenName="School Details"
      >
        {this.state.isLoadingSchoolLogoURL ? (
          <div>
            <HashLoader
              loading
              css={{
                position: "fixed",
                width: "100%",
                height: "100%",
                left: "0px",
                top: "0px",
              }}
              size={30}
              color="#084353"
              sizeUnit="px"
            />
          </div>
        ) : (
          <div className="row">
            <div className="col-sm-3">
              <div className="cardone bg-white p-3 shadow profile">
                {/* own code */}
                <div className="img-holder">
                  <label htmlFor="input">
                    {this.state.schoolLogo === "" ||
                    this.state.schoolLogoURL === "" ? (
                      <>
                        <img
                          src={placeholder_bg}
                          className="proimg img1 rounded-circle cursor-pointer"
                          style={{
                            width: "200px",
                            height: "200px",
                          }}
                          alt="NA"
                          htmlFor="input"
                        />
                        {/* <label htmlFor="input">Upload Your School Logo</label> */}
                      </>
                    ) : (
                      <div className="scl-logo">
                        <img
                          src={this.state.schoolLogoURL}
                          className="proimg img1 rounded-circle cursor-pointer"
                          style={{
                            border: "1px solid lightgray",
                            width: "200px",
                            height: "200px",
                          }}
                          alt="NA"
                          htmlFor="input"
                        />
                        <div className="middle">
                          <img
                            src={placeholder_bg}
                            className="img1 rounded-circle cursor-pointer"
                            style={{
                              border: "1px solid lightgray",
                              width: "200px",
                              height: "200px",
                            }}
                            alt="NA"
                            htmlFor="input"
                          />
                        </div>
                      </div>
                    )}
                  </label>
                  <input
                    type="file"
                    name="imageUpload"
                    id="input"
                    accept="png,gif,jpeg,jpg"
                    onChange={this.imageHandler}
                    style={{ display: "none" }}
                  />
                </div>
                <br />
                &emsp;
                <h3 className="text-dark">
                  {this.state.schoolDetailsAdmin.schoolName}
                </h3>
                <br />
                <p style={{ fontWeight: "bold", fontSize: "1vw" }}>
                  {this.state.schoolDetailsAdmin.city}
                </p>
              </div>
            </div>
            <div className="col-sm-9">
              <div className="cardone bg-white p-3 shadow profile">
                <div className="d-flex justify-content-end">
                  {/* <h5 className="myskills text-dark">
                    <b>Personal Details</b>
                  </h5> */}
                  <button
                    type="button"
                    disabled={this.state.isShowLoading}
                    className="btn btn-sm text-light rounded-pill"
                    style={{ backgroundColor: "#094353" }}
                    onClick={() => this.saveAdminSchoolProfile()}
                  >
                    {this.state.isShowLoading ? (
                      <HashLoader
                        size={16}
                        color="#fff"
                        loading={this.state.isShowLoading}
                        sizeUnit="px"
                      />
                    ) : (
                      "Update"
                    )}
                  </button>
                </div>
                <div className="row personaldetails">
                  <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                    <label htmlFor="board">Board :</label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={this.state.affiliation || ""}
                    ></input>
                  </div>
                  <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                    <label htmlFor="regId">School Reg. ID :</label>
                    <input
                      type="text"
                      className="form-control"
                      id="regId"
                      maxLength="25"
                      value={this.state.schoolRegId}
                      onChange={e => {
                        this.setState({
                          schoolRegId: e.target.value.replace(
                            /[^A-Za-z0-9./ ]/gi,
                            ""
                          ),
                        })
                      }}
                      onKeyDown={e => {
                        if (e.key === "Enter" && e.target.value) {
                          let el = document.getElementById("landline")
                          el.focus()
                        }
                      }}
                    />
                  </div>
                  <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                    <label htmlFor="landline">Landline :</label>
                    <input
                      type="text"
                      className="form-control"
                      id="landline"
                      value={this.state.landLine}
                      onChange={e => {
                        this.setState({
                          landLine: e.target.value.replace(
                            /[^0-9\+(\)\- ]/gi,
                            ""
                          ),
                        })
                      }}
                      onKeyDown={e => {
                        if (e.key === "Enter" && e.target.value) {
                          let el = document.getElementById("email")
                          el.focus()
                        }
                      }}
                    />
                  </div>
                  <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                    <label htmlFor="email"> Website :</label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      value={this.state.website}
                      onChange={e => {
                        this.setState({ website: e.target.value })
                      }}
                      onKeyDown={e => {
                        if (e.key === "Enter" && e.target.value) {
                          let el = document.getElementById("yearFounded")
                          el.focus()
                        }
                      }}
                    />
                  </div>
                  <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                    <label htmlFor="yearFounded">Year founded :</label>
                    <input
                      type="number"
                      className="form-control"
                      id="yearFounded"
                      value={this.state.yearFounded}
                      onChange={e =>
                        this.setState({ yearFounded: e.target.value })
                      }
                      onKeyDown={e => {
                        if (e.key === "Enter" && e.target.value) {
                          let el = document.getElementById("contact")
                          el.focus()
                        }
                      }}
                    />
                  </div>
                  <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                    <label htmlFor="contact">Contact Number:</label>
                    <input
                      pattern="^\d{10}$"
                      type="number"
                      className="form-control"
                      id="contact"
                      value={this.state.contactNumber}
                      onChange={e =>
                        this.setState({ contactNumber: e.target.value })
                      }
                      onKeyDown={e => {
                        if (e.key === "Enter" && e.target.value) {
                          let el = document.getElementById("address1")
                          el.focus()
                        }
                      }}
                    />
                  </div>
                  <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                    <label htmlFor="emailId">Email ID:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="emailId"
                      disabled
                      value={this.state.emailId || ""}
                    />
                  </div>
                  <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                    <label htmlFor="address1">Address Line 1:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="address1"
                      maxLength="100"
                      value={this.state.addressLine1}
                      onChange={e => {
                        this.setState({
                          addressLine1: e.target.value.replace(
                            /[^A-Za-z0-9,\.\-/\(\) ]/gi,
                            ""
                          ),
                        })
                      }}
                      onKeyDown={e => {
                        if (e.key === "Enter" && e.target.value) {
                          let el = document.getElementById("address2")
                          el.focus()
                        }
                      }}
                    />
                  </div>
                  <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                    <label htmlFor="address2">Address Line 2:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="address2"
                      maxLength="100"
                      value={this.state.addressLine2}
                      onChange={e => {
                        this.setState({
                          addressLine2: e.target.value.replace(
                            /[^A-Za-z0-9,\.\-/\(\) ]/gi,
                            ""
                          ),
                        })
                      }}
                      onKeyDown={e => {
                        if (e.key === "Enter" && e.target.value) {
                          let el = document.getElementById("city")
                          el.focus()
                        }
                      }}
                    />
                  </div>
                  <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                    <label htmlFor="city">City:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="city"
                      maxLength="30"
                      value={this.state.city}
                      onChange={e => {
                        this.setState({
                          city: e.target.value.replace(/[^A-Za-z ]/gi, ""),
                        })
                      }}
                      onKeyDown={e => {
                        if (e.key === "Enter" && e.target.value) {
                          let el = document.getElementById("country")
                          el.focus()
                        }
                      }}
                    />
                  </div>
                  <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                    <label htmlFor="country">Country:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="country"
                      maxLength="30"
                      value={this.state.country}
                      onChange={e => {
                        this.setState({
                          country: e.target.value.replace(
                            /[^A-Za-z.\- ]/gi,
                            ""
                          ),
                        })
                      }}
                      onKeyDown={e => {
                        if (e.key === "Enter" && e.target.value) {
                          let el = document.getElementById("zipCode")
                          el.focus()
                        }
                      }}
                    />
                  </div>
                  <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                    <label htmlFor="zipCode">Zip Code:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="zipCode"
                      maxLength="13"
                      value={this.state.zipCode}
                      onChange={e => {
                        this.setState({
                          zipCode: e.target.value.replace(/[^0-9\- ]/gi, ""),
                        })
                      }}
                      onKeyDown={e => {
                        if (e.key === "Enter" && e.target.value) {
                          let el = document.getElementById("state")
                          el.focus()
                        }
                      }}
                    />
                  </div>
                  <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                    <label htmlFor="state">State:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="state"
                      maxLength="30"
                      value={this.state.state}
                      onChange={e => {
                        this.setState({
                          state: e.target.value.replace(/[^A-Za-z.\- ]/gi, ""),
                        })
                      }}
                      onKeyDown={e => {
                        if (e.key === "Enter" && e.target.value) {
                          let el = document.getElementById("default")
                          el.focus()
                        }
                      }}
                    />
                  </div>
                  <div className="form-group col-12 mt-2">
                    <h5 className="text-dark">Email Communication Settings</h5>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="mailSender"
                        id="default"
                        value={this.state.mailSender}
                        checked={this.state.mailSender === "default"}
                        onChange={e => {
                          this.setState({ mailSender: "default" })
                        }}
                      />
                      <label className="form-check-label" htmlFor="default">
                        Default
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="mailSender"
                        id="customfrom"
                        value={this.state.mailSender}
                        checked={this.state.mailSender === "customfrom"}
                        onChange={e => {
                          this.setState({ mailSender: "customfrom" })
                        }}
                        onKeyDown={e => {
                          if (e.key === "Enter" && e.target.value) {
                            let el = document.getElementById(
                              "mailSenderDetails"
                            )
                            el.focus()
                          }
                        }}
                      />
                      <label className="form-check-label" htmlFor="customfrom">
                        Custom From Address
                      </label>
                    </div>
                  </div>
                  {this.state.mailSender === "customfrom" && (
                    <div className="row px-3">
                      <div className="form-group col-12">
                        <label htmlFor="mailSenderDetails">
                          Email Address:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="mailSenderDetails"
                          value={this.state.mailSenderDetails}
                          onChange={e =>
                            this.setState({ mailSenderDetails: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    studentData: state.studentDatabaseState.studentDetail,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      saveStudentDatabaseDetailInStore,
      getSchoolDetailsAdmin,
      getAdminSchoolProfile,
      saveAdminSchoolProfile,
      showNotification,
      uploadFile,
      getFile,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(SchoolDetailsDashboard)
