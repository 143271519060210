import React, { useState } from "react"
// import { HashLoader } from "react-spinners"
import { Modal } from "reactstrap"
import pdfMake from "pdfmake"

const fonts = {
  Roboto: {
    normal:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
    bold:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
    italics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
    bolditalics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
  },
}

const numToRoman = {
  1: "I",
  2: "II",
  3: "III",
  4: "IV",
  5: "V",
  6: "VI",
  7: "VII",
  8: "VIII",
  9: "IX",
  10: "X",
  11: "XI",
  12: "XII",
}

const prestyle = { wordBreak: "break-word", whiteSpace: "pre-wrap" }
const wrapperSyle = { maxHeight: "80vh", overflowY: "auto" }

const questions = [
  {
    label: "Multiple Choice Question",
    qVal: "mcq",
    qMark: "mcqMark",
  },
  {
    label: "Short Answer",
    qVal: "shortAns",
    qMark: "shortAnsMark",
  },
  {
    label: "Long Answer",
    qVal: "longAns",
    qMark: "longAnsMark",
  },
  {
    label: "Fill in the blanks",
    qVal: "fillBlank",
    qMark: "fillBlankMark",
  },
  {
    label: "True or False",
    qVal: "trueOrFalse",
    qMark: "trueOrFalseMark",
  },
  {
    label: "Match the following",
    qVal: "matchFollowing",
    qMark: "matchFollowingMark",
  },
  {
    label: "Circle the odd one",
    qVal: "circleOdd",
    qMark: "circleOddMark",
  },
  {
    label: "Complete the analogy",
    qVal: "analogy",
    qMark: "analogyMark",
  },
]

function split(text = "", by = "") {
  return {
    margin: [0, 0, 0, 8],
    columns: text.split(by).map(s => ({
      text: s,
      width: s ? "*" : 16,
    })),
  }
}

function titleSplit(text = "") {
  const splited = text.split("(")
  return {
    margin: [0, 0, 0, 8],
    columns: [
      {
        text: splited[0],
        width: "*",
        bold: true,
      },
      {
        text: "(" + splited[1],
        width: "auto",
        bold: true,
      },
    ],
  }
}

function calMark(details) {
  let keys = [
    "matchFollowing",
    "trueOrFalse",
    "fillBlank",
    "circleOdd",
    "shortAns",
    "longAns",
    "analogy",
    "mcq",
  ]
  return keys.reduce((prev, curr) => {
    if (details[curr] && details[curr + "Mark"]) {
      return prev + details[curr] * details[curr + "Mark"]
    }
    return prev
  }, 0)
}

function Input({ label, value, name, onChange, disabled }) {
  return (
    <div className="mb-2">
      <label htmlFor="" className="fs-13 clr-929392" style={{ width: "10rem" }}>
        {label}
      </label>

      <input
        type="text"
        name={name}
        className="fs-15 border-C4C4C4 px-2 py-1"
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  )
}

function Step1({ details, onChange }) {
  return (
    <div>
      <Input
        label="Board"
        name="board"
        value={details.board}
        onChange={onChange}
      />
      <Input
        label="Section"
        name="section"
        value={details.section}
        onChange={onChange}
      />
      <Input
        label="Subject"
        name="subject"
        value={details.subject}
        onChange={onChange}
      />
    </div>
  )
}

function Step2({ details, onChange }) {
  return (
    <div>
      <Input
        label="Chapter"
        name="chapter"
        value={details.chapter}
        onChange={onChange}
      />
    </div>
  )
}

function Header() {
  return (
    <div
      className="d-flex align-items-center fs-13 fw-600 clr-929392"
      style={{ gap: "1rem", marginBottom: "-16px" }}
    >
      <p style={{ width: "12rem" }}>Question</p>
      <p style={{ width: "32px", textAlign: "center" }}>No.</p>
      <p style={{ width: "32px", textAlign: "center" }}>Mark</p>
    </div>
  )
}

function Step3({ details, onChange }) {
  return (
    <div className="d-grid grid-col-2" style={{ gap: "1rem" }}>
      <Header />
      <Header />
      {questions.map(q => (
        <div
          key={q.qVal}
          className="d-flex align-items-center fs-13 clr-929392"
          style={{ gap: "1rem" }}
        >
          <label htmlFor="" style={{ width: "12rem" }}>
            {q.label}
          </label>
          <input
            type="text"
            name={q.qVal}
            className="fs-15 border-C4C4C4 px-2 py-1"
            value={details[q.qVal]}
            onChange={onChange}
            style={{ width: "32px", textAlign: "center" }}
          />
          <input
            type="text"
            name={q.qMark}
            className="fs-15 border-C4C4C4 px-2 py-1"
            value={details[q.qMark]}
            onChange={onChange}
            style={{ width: "32px", textAlign: "center" }}
          />
        </div>
      ))}
    </div>
  )
}

function GenerateQuestionPaper() {
  const [downloading, setDownloading] = useState(false)
  const [original, setOriginal] = useState("")
  const [step, setStep] = useState(1)
  const [ans, setAns] = useState("")

  const [details, setDetails] = useState({
    board: "Tamilnadu",
    section: "9",
    subject: "Maths",
    chapter: "2,3,7",

    matchFollowing: "4",
    trueOrFalse: "1",
    fillBlank: "3",
    circleOdd: "",
    shortAns: "3",
    longAns: "2",
    analogy: "2",
    mcq: "3",

    matchFollowingMark: "1",
    trueOrFalseMark: "1",
    fillBlankMark: "1",
    circleOddMark: "1",
    shortAnsMark: "5",
    longAnsMark: "10",
    analogyMark: "2",
    mcqMark: "1",

    topics: "topics, Factorisation, Volume of Cuboid and Cube",
  })

  const onChange = e => {
    e.persist()
    setDetails(p => ({
      ...p,
      [e.target.name]: e.target.value,
    }))
  }

  const onNext = async () => {
    if (step < 4) {
      setStep(p => p + 1)
    }

    if (step === 3) {
      const response = await fetch("http://localhost:5000/stream", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(details),
      })

      const reader = response.body?.getReader()
      let buffer = ""

      reader.read().then(function processResult(result) {
        if (result.done) {
          return
        }

        buffer += new TextDecoder().decode(result.value)
        setAns(buffer.replace(/---|\|\|/g, "     "))
        setOriginal(buffer)

        return reader.read().then(processResult)
      })
    }

    if (step === 4) {
      setDownloading(true)
      let paras = original.split("\n")

      let titleReg = /(.*?)(\(\d+\s*[x*]\s*\d+\s*=\s*\d+\))/

      const docDefinition = {
        content: [
          {
            margin: [0, 0, 0, 8],
            columns: [
              {
                text: `STD: ${numToRoman[details.section]}`,
                fontSize: 11,
                width: "auto",
                bold: true,
              },
              {
                text: details.subject.toUpperCase(),
                fontSize: 11,
                width: "*",
                bold: true,
                alignment: "center",
              },
              {
                text: `MARKS: ${calMark(details)}`,
                fontSize: 11,
                width: "auto",
                bold: true,
              },
            ],
          },
          ...paras.map(p => {
            if (titleReg.test(p)) return titleSplit(p)
            if (p.startsWith("---")) return split(p, "---")
            if (p.includes("||")) return split(p, "||")

            return {
              text: p,
              margin: [0, 0, 0, 8],
            }
          }),
        ],
        pageSize: "A4",
        pageMargins: 20,
        defaultStyle: {
          fontSize: 10,
        },
      }

      pdfMake.createPdf(docDefinition, null, fonts).getBase64(async data => {
        const blob = await fetch(
          `data:application/pdf;base64,${data}`
        ).then(res => res.blob())
        const browserUrl = window.webkitURL || window.URL
        const pdfURL = browserUrl.createObjectURL(blob)
        const a = document.createElement("a")
        a.href = pdfURL
        a.download = `${details.section}-${details.subject} Question paper.pdf`
        a.click()
        browserUrl.revokeObjectURL(pdfURL)
        setDownloading(false)
      })
    }
  }

  return (
    <Modal
      isOpen
      backdropClassName="modal-backdrop-show"
      modalClassName="modal-dialog-shadow-none"
      contentClassName="p-4"
      centered
      size="lg"
    >
      <div style={wrapperSyle}>
        {step === 1 && <Step1 details={details} onChange={onChange} />}
        {step === 2 && <Step2 details={details} onChange={onChange} />}
        {step === 3 && <Step3 details={details} onChange={onChange} />}
        {step === 4 && (
          <div>
            <pre style={prestyle}>{ans}</pre>
          </div>
        )}

        <div className="d-flex align-items-center justify-content-around mt-5">
          <button
            className="btn btn-secondary"
            onClick={() => setStep(p => p - 1)}
            disabled={step === 1}
          >
            Back
          </button>

          <button
            className="btn btn-primary"
            onClick={onNext}
            disabled={step === 5 || downloading}
          >
            {step < 4 ? "Next" : "Download"}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default GenerateQuestionPaper
