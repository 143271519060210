/*************************************************
 * RedInk
 * @exports
 * @class SubjectsScreen.js
 * @extends Component
 * @author Sasidharan // on 06/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { myLog } from "../../../utils/Utility"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { FormGroup, Label, Input } from "reactstrap"
import {
  saveAcademicYearDetails,
  updateAcademicYearDetails,
  saveOnBoardingAcademicYearDetailsInStore,
  getAcademicYearDetails,
} from "../../../actions/admin/AcademicDetailsAction"
import { showNotification } from "../../../actions/CommonAction"
import { VALIDATION_MSG, DANGER_TOAST } from "../../../utils/Constants"
import moment from "moment"
// import { HashLoader } from "react-spinners"
import { DatePicker } from "antd"
import "antd/dist/antd.css"

const { RangePicker } = DatePicker

class AcademicScreen extends Component {
  static getDerivedStateFromProps = (props, state) => {
    if (props.academicYearList && props.academicYearList.length > 0) {
      return {
        name: props.academicYearList[0].academicName,
        shortName: props.academicYearList[0].academicShortName,
        duration: props.academicYearList[0].duration,
        updateAcademicDetailId: props.academicYearList[0].academicId,
      }
    } else {
      return {
        name: "",
        shortName: "",
        duration: [],
        updateAcademicDetailId: "",
      }
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      // advanceOpen: false,
      // selectedTab: "academic",
      isShowLoading: false,
      newAcademicOpen: false,
      name:
        this.props.academicYearList && this.props.academicYearList.length > 0
          ? this.props.academicYearList[0].academicName
          : "",
      shortName:
        this.props.academicYearList && this.props.academicYearList.length > 0
          ? this.props.academicYearList[0].academicShortName
          : "",
      duration:
        this.props.academicYearList && this.props.academicYearList.length > 0
          ? this.props.academicYearList[0].duration
          : [],
      updateAcademicDetailId:
        this.props.academicYearList && this.props.academicYearList.length > 0
          ? this.props.academicYearList[0].academicId
          : "",
      // date: [],
      // isChangeMarkOpen: false,
      // selectedChangeMaxMarkIndex: 0,
      allocationDetails: [
        { sectionGroups: [], resultCondition: "", changeMaxMark: [] },
      ],
      // indexChangeMark: 0,
      // maxMarkList: [],
    }
  }

  componentDidMount = () => {
    this.getAcademicYear()
  }

  getAcademicYear = () => {
    this.props.getAcademicYearDetails((success, response) => {
      if (success) {
        // let array = []
        // array.push(moment(response[0].academicFrom))
        // array.push(moment(response[0].academicTo))
        // this.setState({
        //   name: response.duration,
        //   shortName: response.duration,
        //   duration: response.duration
        // })
      }
    })
  }

  // componentDidUpdate(prevProps, prevState) { }

  saveAcademic = callback => {
    const { name, shortName, duration, allocationDetails } = this.state
    if (name !== "" && shortName !== "" && duration) {
      this.setState({ isShowLoading: true })
      // const object = {
      //   name, shortName, allocationDetails,
      //   fromYear: moment(this.state.duration[0]).format("YYYY-MM-DD"),
      //   toYear: moment(this.state.duration[1]).format("YYYY-MM-DD"),
      // } // prettier-ignore
      if (this.props.updateAcademicDetailId) {
        const object = {
          academicName: name,
          academicShortName: shortName,
          sectionGroups: [],
          allocationDetails,
          academicFrom: moment(this.state.duration[0]).format("YYYY-MM-DD"),
          academicTo: moment(this.state.duration[1]).format("YYYY-MM-DD"),
        }
        object.academicId = this.props.updateAcademicDetailId
        this.props.updateAcademicYearDetails(object, success => {
          // myLog("success", success)
          this.setState({
            isShowLoading: false,
          })
          if (success) {
            this.props.onNext()
            this.setState({
              newAcademicOpen: false,
            })
          }
        })
      } else {
        const object = {
          name,
          shortName,
          allocationDetails,
          fromYear: moment(this.state.duration[0]).format("YYYY-MM-DD"),
          toYear: moment(this.state.duration[1]).format("YYYY-MM-DD"),
        }
        this.props.saveAcademicYearDetails(object, success => {
          // myLog("success", success)
          this.setState({ isShowLoading: false })
          if (success) {
            this.props.onNext()

            // this.setState({
            //   newAcademicOpen: false,
            // })
            // this.setState({
            //   // newAcademicOpen: true,
            //   // isShowUpdateSubjectInput: false,
            //   // // name: "",
            //   // // shortName: "",
            //   // date: [],
            //   // duration: [],
            //   // isChangeMarkOpen: false,
            //   // selectedChangeMaxMarkIndex: 0,
            //   // allocationDetails: [
            //   //   {
            //   //     sectionGroups: [],
            //   //     resultCondition: "",
            //   //     changeMaxMark: [],
            //   //   },
            //   // ],
            // })
          }
        })
      }
    } else {
      this.setState({ hasError: true })
      myLog("error", this.state.name)
    }
  }

  onSubmit = () => {
    let dur = this.state.duration || []
    if (this.state.name && this.state.shortName && dur.length !== 0) {
      this.saveAcademic()
    } else {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.OOPS,
        "Fill all fields"
      )
    }
  }

  render() {
    return (
      <>
        <div></div>
        <div className="row justify-content-center align-items-center flex-wrap">
          <div
            className="col-md-6 d-flex align-items-center justify-content-start"
            style={{ minWidth: 250 }}
          >
            <img
              src={require("../../../assets/images/Calendar illustration.png")}
              style={{ width: 270, height: 270 }}
              alt=""
            />
          </div>
          <div className="col-md-5" style={{ minWidth: 250 }}>
            <h3 className="text-brand font-weight-bold mb-5 text-align-right">
              Academic Details
            </h3>
            <FormGroup>
              <Label
                className="text-brand font-weight-bold"
                htmlFor="academicName"
              >
                Academic Name
              </Label>
              <Input
                type="text"
                name="academicName"
                id="academicName"
                value={this.state.name}
                onChange={e => {
                  let onboardingAcademic = this.props.academicYearList
                  if (onboardingAcademic.length > 0) {
                    onboardingAcademic[0].name = e.target.value
                    onboardingAcademic[0].academicName = e.target.value
                  } else {
                    let object = {
                      name: e.target.value,
                      academicName: e.target.value,
                    }
                    onboardingAcademic.push(object)
                  }
                  this.props.saveOnBoardingAcademicYearDetailsInStore(
                    onboardingAcademic
                  )
                  this.setState({ name: e.target.value })
                }}
                placeholder="Eg. Academic Year 2020-2021"
              />
            </FormGroup>
            <FormGroup>
              <Label
                className="text-brand font-weight-bold"
                htmlFor="academicName"
              >
                Short Name
              </Label>
              <Input
                type="text"
                name="shortName"
                id="shortName"
                value={this.state.shortName}
                onChange={e => {
                  let onboardingAcademic = this.props.academicYearList
                  if (onboardingAcademic.length > 0) {
                    onboardingAcademic[0].shortName = e.target.value
                    onboardingAcademic[0].academicShortName = e.target.value
                  } else {
                    let object = {
                      shortName: e.target.value,
                      academicShortName: e.target.value,
                    }
                    onboardingAcademic.push(object)
                  }
                  this.props.saveOnBoardingAcademicYearDetailsInStore(
                    onboardingAcademic
                  )
                  this.setState({ shortName: e.target.value })
                }}
                placeholder="Eg. AY 2020-2021"
              />
            </FormGroup>
            <FormGroup>
              <Label className="text-brand font-weight-bold">
                Select Acedemic year
              </Label>
              <div>
                <RangePicker
                  disabled={this.state.isShowUpdateSubjectInput}
                  onChange={duration => {
                    myLog(duration)
                    let onboardingAcademic = this.props.academicYearList
                    if (onboardingAcademic.length > 0) {
                      onboardingAcademic[0].duration = duration
                    } else {
                      let object = {
                        duration,
                      }
                      onboardingAcademic.push(object)
                    }
                    this.props.saveOnBoardingAcademicYearDetailsInStore(
                      onboardingAcademic
                    )
                    this.setState({ duration })
                  }}
                  value={this.state.duration}
                  format="DD-MM-YYYY"
                />
              </div>
            </FormGroup>

            {/* <button
               type="button"
               style={{ borderRadius: 12 }}
               className="btn bg-brand text-light px-5 mx-auto rounded-lg"
               onClick={() => this.saveAcademic()}
             >
               {this.state.isShowLoading ? (
                 <HashLoader
                   sizeUnit={"px"}
                   size={16}
                   color={"#ffffff"}
                   loading={this.state.isShowLoading}
                 />
               ) : (
                 <span>Save</span>
               )}
             </button> */}
          </div>
        </div>

        <div className="d-flex align-items-center">
          <button
            onClick={() => this.props.onPrevious()}
            className="btn text-light d-flex align-items-centers"
            style={{ backgroundColor: "#04B08F", borderRadius: 12 }}
            disabled={this.state.isShowLoading}
          >
            <div className="d-flex align-items-center">
              <i className="fas fa-chevron-left" />
              <span className="ml-2">Previous</span>
            </div>
          </button>

          <button
            onClick={this.onSubmit}
            className="btn text-light d-flex align-items-center ml-auto"
            style={{ backgroundColor: "#04B08F", borderRadius: 12 }}
            disabled={this.state.isShowLoading}
          >
            <>
              <span className="mr-2">
                {this.props.isLast ? "Save & Proceed" : "Next Step"}
              </span>
              <i className="fas fa-chevron-right" />
            </>
          </button>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    academicYearList:
      state.adminAcademicYearDetailsState.OnboardingAcademicYearList,
    updateAcademicDetailId:
      state.adminAcademicYearDetailsState &&
      state.adminAcademicYearDetailsState.OnboardingAcademicYearList.length > 0
        ? state.adminAcademicYearDetailsState.OnboardingAcademicYearList[0]
            .academicId
        : "",
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      saveAcademicYearDetails,
      getAcademicYearDetails,
      updateAcademicYearDetails,
      saveOnBoardingAcademicYearDetailsInStore,
      showNotification,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(AcademicScreen)
