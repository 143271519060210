/*************************************************
 * RedInk
 * @exports
 * @class AttendanceReportReducer.js
 * @extends Component
 * @author vijayakumar // on 18/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from "../../actions/types"
import { myLog } from "../../utils/Utility"

const INTIAL_STATE = {
  userInfo: {},
  attedenceList: [],
  termList: [],
}

export const attendanceReportReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.USER_INFO:
      myLog("payload", action.payload)
      return {
        ...state,
        userInfo: action.payload,
      }

    case ACTION_TYPES.TERM_LIST:
      myLog("payload", action.payload)
      return {
        ...state,
        termList: action.payload,
      }

    case ACTION_TYPES.STUDENT_ATTENDENCE_LIST_REPORT:
      myLog("payload", action.payload)
      return {
        ...state,
        attedenceList: action.payload,
      }

    default:
      return state
  }
}
