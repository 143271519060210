import React, { useState } from "react"
import LinearProgress from "@material-ui/core/LinearProgress"
import Calendar from "react-calendar"
import "react-calendar/dist/Calendar.css"
import "./rc-css.css"

import useOutSideClick from "../../../../hooks/useOutSideClick"
import { ReactComponent as Arrow } from "../../../../assets/images/arrow.svg"

function Header({
  next,
  prev,
  goto,
  title,
  gotoToday,
  changeView,
  isLoading,
  isTodayVisible,
}) {
  const { wrapperRef, isOpen, onOpenChange } = useOutSideClick()
  const [value, setValue] = useState(new Date())
  const [view, setView] = useState("timeGridThreeDay")

  const onViewChange = val => {
    if (val !== view) {
      setView(val)
      changeView(val)
    }
  }

  const onDateClick = val => {
    goto(val)
    setValue(val)
    onOpenChange()
  }

  return (
    <>
      <div className="cla-header">
        <div
          className={`cp today ${isTodayVisible ? "canRefresh" : ""}`}
          onClick={gotoToday}
        >
          Today
        </div>

        <div onClick={prev} className="cp cla-arr">
          <Arrow className="prev-arr" />
        </div>

        <div onClick={next} className="cp cla-arr">
          <Arrow className="next-arr" />
        </div>

        <div className="cp cla-title" ref={wrapperRef}>
          <div onClick={onOpenChange}>{title}</div>
          <div className={`cla-head-calender ${isOpen ? "open" : ""}`}>
            <Calendar onChange={onDateClick} value={value} />
          </div>
        </div>

        <select
          className="cp"
          onChange={e => onViewChange(e.target.value)}
          value={view}
        >
          <option value="timeGridThreeDay">Three Days</option>
          <option value="timeGridDay">Day</option>
          <option value="timeGridSevenDay">Week</option>
        </select>
      </div>

      {isLoading && <LinearProgress />}
    </>
  )
}

export default Header
