import React from "react"
import ChangePassword from "./ChangePassword"
import ClassScreen from "./ClassScreen"
import ClassGroupScreen from "./ClassGroupScreen"
import AcademicScreen from "./AcademicScreen"
import { URL_PATH } from "../../../config/urlPath"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setOnBoardSteps } from "../../../actions/admin/OnboardingActions"
// import { getAcademicYearDetails } from "../../../actions/admin/AcademicDetailsAction"
import { getQuickInfoDetails } from "../../../actions/CommonAction"
import { HashLoader } from "react-spinners"

class AdminOnboarding extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      stage: this.props.currentStep || 0,
      isNextStepLoading: false,
      isLoadingOnboarding: false,
    }
  }

  STAGES = [
    {
      key: "change-password",
      title: "Change Password",
      subtitle: "Change your password to something memorable",
      component: (isLast, onNext, isNextStepLoading, onPrevious) => (
        <ChangePassword
          isLast={isLast}
          onNext={onNext}
          isNextStepLoading={isNextStepLoading}
          onPrevious={onPrevious}
        />
      ),
    },
    {
      key: "academic-details",
      title: "Academic Details",
      subtitle: "Enter Current Academic Details",
      component: (isLast, onNext, isNextStepLoading, onPrevious) => (
        <AcademicScreen
          isLast={isLast}
          onNext={onNext}
          isNextStepLoading={isNextStepLoading}
          onPrevious={onPrevious}
        />
      ),
    },
    {
      key: "create-class",
      title: "Create Class",
      subtitle: "Enter Class Details",
      component: (isLast, onNext, isNextStepLoading, onPrevious) => (
        <ClassScreen
          isLast={isLast}
          onNext={onNext}
          isNextStepLoading={isNextStepLoading}
          onPrevious={onPrevious}
        />
      ),
    },
    {
      key: "create-group",
      title: "Create Class Group",
      subtitle: "Create Class grouping based on levels",
      component: (isLast, onNext, isNextStepLoading, onPrevious) => (
        <ClassGroupScreen
          isLast={isLast}
          onNext={onNext}
          isNextStepLoading={isNextStepLoading}
          onPrevious={onPrevious}
        />
      ),
    },
  ]

  componentDidMount = () => {
    this.setState({ isLoadingOnboarding: true }, () => {
      this.props.getQuickInfoDetails((success, response) => {
        if (success) {
          this.setState({
            stage: response.currentStep,
            isLoadingOnboarding: false,
          })
        }
      })
    })
    // this.getAcademicYear()
  }

  // getAcademicYear = () => {
  //   this.props.getAcademicYearDetails((success, response) => { })
  // }

  loadNextStep = stepNo => {
    stepNo === this.STAGES.length
      ? this.props.history.push(URL_PATH.ADMIN)
      : this.setState({ stage: stepNo })
    this.setState({ isNextStepLoading: true })
    this.props.setOnBoardSteps(stepNo, success => {
      this.setState({ isNextStepLoading: false })
    })
  }

  loadPreviousStep = stepNo => {
    stepNo === this.STAGES.length
      ? this.props.history.push(URL_PATH.ADMIN)
      : this.setState({ stage: stepNo })
  }

  renderSidebar = () => (
    <div
      className="d-flex bg-brand flex-column"
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        width: "30vw",
        minWidth: 400,
      }}
    >
      <div className="d-flex mx-auto mt-4 align-items-center">
        <img
          src={require("../../../assets/images/redink/redink_logo.png")}
          alt="Redink logo"
          style={{ height: 50, width: 50 }}
        />
        <h2 className="text-light ml-2 mb-0">Redink</h2>
      </div>

      <div className="my-auto" style={{ zIndex: 100 }}>
        {this.STAGES.map((stage, index) => (
          <div
            key={index}
            className="p-3 d-flex align-items-start cursor-pointer"
            onClick={() => {
              // Commmented out to restrict not to move to another screen or back screen
              // this.setState({ stage: index })
            }}
          >
            <div
              className="rounded-pill d-flex align-items-center justify-content-center mr-4"
              style={{
                width: 26,
                height: 26,
                backgroundColor:
                  index === this.state.stage ? "#04B08F" : "#c2c2c2",
                transition: "150ms background-color ease-in",
              }}
            >
              <span
                className={
                  index === this.state.stage ? "text-light" : "text-muted"
                }
                style={{
                  fontWeight: "bold",
                  transition: "150ms background-color ease-in",
                }}
              >
                {index + 1}
              </span>
            </div>
            <div style={{ flex: 1 }}>
              <h4>{stage.title}</h4>
              <span className="text-muted">{stage.subtitle}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )

  renderMainContent = () => (
    <div
      style={{
        position: "absolute",
        top: 18,
        bottom: 18,
        left: 18,
        zIndex: 50,
        borderRadius: 30,
        backgroundColor: "#FDF0F0",
        border: "1px solid #c6c6c6",
        // screen width - sidebar width + sidebar padding + 1/2 button size - left offset
        width: "calc((100vw - max(400px, 30vw)) + 1rem + 12px - 18px)",
      }}
    >
      {this.STAGES.map((stage, index) => {
        const isSelected = index === this.state.stage
        const isLast = index === this.STAGES.length - 1
        return (
          <div
            className={`onboarding-stage ${
              isSelected ? "onboarding-stage-active" : ""
            }`}
          >
            <div className="onboarding-stage-content">
              {stage.component(
                isLast,
                () => this.loadNextStep(index + 1),
                this.state.isNextStepLoading,
                () => this.loadPreviousStep(index - 1)
              )}
            </div>
          </div>
        )
      })}
    </div>
  )

  render() {
    return (
      <div className="onboarding">
        {!this.state.isLoadingOnboarding ? (
          <>
            {this.renderMainContent()}
            {this.renderSidebar()}
          </>
        ) : (
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: 150 }}
          >
            <HashLoader
              sizeUnit={"px"}
              size={35}
              color={"#084353"}
              loading={this.state.isShowLoading}
            />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentStep: state.loginState.userInfo.currentStep,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setOnBoardSteps,
      // getAcademicYearDetails,
      getQuickInfoDetails,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  dispatchToProps
)(withRouter(AdminOnboarding))
