import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  saveStudentGroup,
  updateStudentGroup,
  deleteStudentInGroup,
  saveStudentCurrentGroupDetailInStore,
} from "../../actions/teacher/StudentDatabaseAction"
import { showNotification } from "../../actions/CommonAction"
import { FormGroup, Input, Label, Button, Form } from "reactstrap" // CustomInput,
import { DANGER_TOAST, VALIDATION_MSG } from "../../utils/Constants"

class CreateGroupModal extends Component {
  static getDerivedStateFromProps(props, state) {
    const { groupData } = props
    return {
      groupId: groupData.groupId,
      groupName: groupData.groupName,
      studentList: groupData.studentList,
    }
  }

  state = { groupId: null, groupName: "", studentList: [], saving: false }

  // componentDidUpdate = (prevProps, prevState) => {
  //   const { groupData } = this.props
  //   if (groupData && groupData.groupId !== this.state.groupId) {
  //     this.setState({
  //       groupId: groupData.groupId,
  //       groupName: groupData.groupName,
  //       studentList: groupData.studentList,
  //     })
  //   }
  // }

  errorHandling = message => {
    this.props.showNotification(DANGER_TOAST, VALIDATION_MSG.OOPS, message)
  }

  selectOrRemoveStudent = (isSelected, student) => {
    const { studentList } = this.state
    if (isSelected) {
      this.setState(
        {
          studentList: studentList.filter(mem => mem.studentId !== student.id),
        },
        () => {
          let object = this.props.groupData
          object.studentList = studentList.filter(
            mem => mem.studentId !== student.id
          )
          this.props.saveStudentCurrentGroupDetailInStore(object)
          if (this.state.groupId) {
            let object = {
              studentId: student.id,
              groupId: this.state.groupId,
            }
            this.props.deleteStudentInGroup(object, () => {})
          }
        }
      )
    } else {
      const newStudent = { studentName: student.name, studentId: student.id }
      this.setState({ studentList: [newStudent, ...studentList] })
      let object = this.props.groupData
      object.studentList = [newStudent, ...studentList]
      this.props.saveStudentCurrentGroupDetailInStore(object)
    }
  }

  submitForm = e => {
    if (e) e.preventDefault()
    this.setState({ saving: true })
    let studentGroup = {
      groupName: this.state.groupName.trim(),
      studentList: this.state.studentList,
    }
    if (studentGroup.studentList.length === 0) {
      this.setState({ saving: false })
      return this.errorHandling("Select Students...")
    }
    if (this.state.groupId !== "") {
      studentGroup.groupId = this.state.groupId
      this.props.updateStudentGroup(studentGroup, success => {
        this.setState({
          saving: false,
        })
        if (success) {
          this.setState({
            groupName: "",
            studentList: [],
            groupId: "",
          })
          document.getElementById("closeCreateGroupModal").click()
          this.props.saveStudentCurrentGroupDetailInStore({
            groupName: "",
            studentList: [],
            groupId: "",
          })
          this.props.onEditFinish()
        }
      })
    } else {
      this.props.saveStudentGroup(studentGroup, success => {
        this.setState({
          saving: false,
        })
        if (success) {
          this.setState({
            groupName: "",
            studentList: [],
            groupId: "",
          })
          this.props.saveStudentCurrentGroupDetailInStore({
            groupName: "",
            studentList: [],
            groupId: "",
          })
          document.getElementById("closeCreateGroupModal").click()
          this.props.onEditFinish()
        }
      })
    }
  }

  clearCloseModal = () => {
    this.props.saveStudentCurrentGroupDetailInStore({
      groupName: "",
      studentList: [],
      groupId: "",
    })
    this.props.onEditFinish()
  }
  render() {
    const { groupName, studentList, saving } = this.state

    return (
      <div
        className="modal right slide-in-right fade mt-0"
        id="createGroupModal"
        role="dialog"
        aria-labelledby="createGroupModal"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content scrollbar">
            <div className="modal-header">
              <h4 className="modal-title text-dark" id="myModalLabel2">
                {this.state.groupId ? "Edit" : "Create"} Group
              </h4>
              <button
                type="button"
                className="close"
                id="closeCreateGroupModal"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container">
                <Form onSubmit={this.submitForm}>
                  <div className="row">
                    <FormGroup style={{ flex: 1, marginRight: 12 }}>
                      <Label htmlFor="newGroupName">Group Name</Label>
                      <Input
                        type="text"
                        name="newGroupName"
                        id="newGroupName"
                        value={groupName}
                        onChange={e => {
                          this.setState({ groupName: e.target.value })
                          let object = this.props.groupData
                          object.groupName = e.target.value
                          this.props.saveStudentCurrentGroupDetailInStore(
                            object
                          )
                        }}
                        required
                      />
                    </FormGroup>
                    {/* <FormGroup className="custom-image-input">
                      <Label htmlFor="newGroupImage">New Group Icon</Label>
                      <input
                        type="file"
                        name="newGroupImage"
                        id="newGroupImage"
                      />
                    </FormGroup> */}
                  </div>
                  <div className="row justify-content-between">
                    <h6 className="text-dark">Add Students</h6>
                    <h6 className="text-dark">
                      {studentList.length} / {this.props.studentList.length}{" "}
                      Students Selected
                    </h6>
                  </div>
                  {this.props.studentList &&
                    this.props.studentList.length > 0 &&
                    this.props.studentList.map((student, i) => {
                      const isSelected =
                        studentList.filter(mem => mem.studentId === student.id)
                          .length > 0

                      return (
                        <div key={student.id} className="d-flex">
                          {/* <CustomInput
                            className="mt-2"
                            type="checkbox"
                            id={`${student.id}-isInGroup`}
                            label={student.name}
                            checked={isSelected}
                            onChange={() =>
                              this.selectOrRemoveStudent(isSelected, student)
                            }
                          /> */}
                          <input
                            type="checkbox"
                            className="mt-2 subject-checkbox"
                            id={`${student.id}-isInGroup`}
                            label={student.name}
                            checked={isSelected}
                            onChange={() =>
                              this.selectOrRemoveStudent(isSelected, student)
                            }
                          />
                          <label
                            htmlFor={`${student.id}-isInGroup`}
                            style={{ marginLeft: "6px", marginTop: "2px" }}
                          >
                            {" "}
                            {student.name}
                          </label>
                        </div>
                      )
                    })}
                  <Button
                    style={{
                      backgroundColor: "#0eb292",
                      border: "none",
                      color: "white",
                    }}
                    className="mt-3 mx-auto"
                    type="submit"
                    disabled={saving}
                  >
                    {saving ? "Saving" : "Save"}
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    studentList: state.studentDatabaseState.studentList,
    groupData: state.studentDatabaseState.studentCurrentGroupDetail,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      saveStudentGroup,
      updateStudentGroup,
      deleteStudentInGroup,
      saveStudentCurrentGroupDetailInStore,
      showNotification,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(CreateGroupModal)
