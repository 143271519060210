import React, { useEffect, useState } from "react"
import { HashLoader } from "react-spinners"
import { useDispatch } from "react-redux"
import { Modal } from "reactstrap"

import { getStudentAttendanceList } from "../../../actions/teacher/TakeAttendanceAction"
import { ReactComponent as Close } from "../../../assets/images/close.svg"

function AbsenteeModal({ id, date, classSecName, dateInReadable, closeModal }) {
  const [isLoading, setIsLoading] = useState(true)
  const [students, setStudents] = useState([])
  const dispatch = useDispatch()
  const absentees = students.filter(s => !s.isPresent)

  useEffect(() => {
    let param = {
      date,
      id,
    }

    dispatch(
      getStudentAttendanceList(param, (success, data) => {
        if (success) {
          console.log(data)
          setStudents(data.attendanceList)
          setIsLoading(false)
        }
      })
    )
  }, [id, date, dispatch])

  return (
    <Modal
      isOpen
      backdropClassName="modal-backdrop-show"
      modalClassName="modal-dialog-shadow-none"
      contentClassName="report-modal-wrapper py-4 px-5"
      centered
      size="md"
    >
      <div className="d-flex align-items-center">
        <h1 className="report-modal-wrapper-h1 mb-0 fw-600">{classSecName}</h1>

        <Close
          className="report-modal-close-icon ml-auto"
          onClick={closeModal}
        />
      </div>

      {isLoading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "400px" }}
        >
          <HashLoader size={30} color="#084353" sizeUnit="px" loading />
        </div>
      ) : (
        <>
          <div className="mt-3 mb-4">
            <strong className="fs-30 clr-EF395C">{absentees.length}</strong>{" "}
            <span className="fs-15 clr-1E1E1E">
              ABSENTEES on {dateInReadable}{" "}
            </span>
          </div>

          <div className="bg-89898926 br-8px mb-4">
            {absentees.map((a, i) => (
              <p key={a.id} className="mb-0 px-4 py-2 fs-15 br-8px">
                <span className="clr-828181">{i + 1}.</span> &nbsp;
                <span className="clr-050202 fw-500">{a.name}</span>
              </p>
            ))}
          </div>
        </>
      )}
    </Modal>
  )
}

export default AbsenteeModal
