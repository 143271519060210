/*************************************************
 * RedInk
 * @exports
 * @class subjectDetailsReducer.js
 * @extends Component
 * @author Vijayakumar // on 18/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from "../../actions/types"

const INTIAL_STATE = {
  gradeDetail: [{ gradeName: "", gradeFrom: "", gradeTo: "", gradePoint: "" }],
}

export const gradeDetailsReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.ADMIN_GRADE_LIST:
      return {
        ...state,
        gradeDetail: [...action.payload],
      }
    default:
      return state
  }
}
