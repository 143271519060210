import React, { useEffect, useState } from "react"
import { HashLoader } from "react-spinners"

import { getQpList } from "../../../actions/admin/QuestionGeneration"

import { ReactComponent as Arrow } from "../../../assets/images/arrow.svg"
import Preview from "./Modals/Preview"

function IndividualClass({ classId, goBack, sectionName }) {
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState("")
  const [data, setData] = useState([])

  useEffect(() => {
    getQpList(`?classId=${classId}`, (success, res) => {
      if (success) {
        setLoading(false)
        setData(res)
      }
    })
  }, [classId])

  if (loading) {
    return (
      <div className="manual-quest-wrapper d-flex align-items-center justify-content-center p-5 br-8px">
        <HashLoader size={30} color="#084353" sizeUnit="px" loading />
      </div>
    )
  }

  return (
    <>
      <button
        className="back-btn-report-card d-flex align-items-center justify-content-center bg-white"
        onClick={goBack}
        style={{ border: "1px solid #E5E5D7" }}
      >
        <Arrow width={16} height={16} style={{ transform: "rotateZ(90deg)" }} />
        Back
      </button>

      <div className="reportCard-card" style={{ borderRadius: "32px" }}>
        <div className="reportCard-card-ind-sec-header d-flex align-items-center mt-4">
          <h1 className="fw-500">Class {sectionName}</h1>
        </div>

        <table className="reportCard-card-ind-sec-table table-fixed w-100">
          <tbody>
            <tr className="bg-F2F4F8 clr-848484">
              <td className="py-3 fw-500">
                <div className="ml-5">Question paper Title</div>
              </td>
              <td className="py-3 fw-500 text-center">Subject</td>
              <td className="py-3 fw-500 text-center">Total marks</td>
              <td className="py-3 fw-500 text-center">Action</td>
            </tr>

            {data.map(d => (
              <tr key={d.id}>
                <td className="pt-3 pb-2 fs-15 fw-500 clr-1F1F1F text-center">
                  {d.title}
                </td>

                <td className="pt-3 pb-2 fs-15 fw-500 clr-898989 text-center">
                  {d.subjectNames || ""}
                </td>

                <td className="pt-3 pb-2 fs-15 fw-500 clr-898989 text-center">
                  {d.totalMarks}
                </td>

                <td className="pt-3 pb-2 text-center fw-500">
                  <button className="clr-4E5BF4" onClick={() => setOpen(d.id)}>
                    Preview
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {open && <Preview id={open} closeModal={() => setOpen("")} />}
    </>
  )
}

export default IndividualClass
