/*************************************************
 * RedInk
 * @exports
 * @class index.js
 * @extends Component
 * @author Sasidharan // on 01/02/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import { UncontrolledTooltip } from "reactstrap"
import { bindActionCreators } from "redux"
import { Calendar } from "react-modern-calendar-datepicker"
import { Progress } from "react-sweet-progress"
import { Carousel } from "react-bootstrap"
import { connect } from "react-redux"
import Chart from "chart.js"
import store from "store"

import "react-circular-progressbar/dist/styles.css"
import "react-modern-calendar-datepicker/lib/DatePicker.css"

import {
  getTeacherClassInfoDetails,
  getTeacherDashboardEarlyWarningDetails,
  getDashboardStudentAttendanceReportList,
  getTeacherDashboardTopPerformingStudentsDetails,
  getTeacherDashboardClassAllSectionsPerformanceDetails,
  getTeacherDashboardClassPerformanceByAssessmentDetails,
} from "../../actions/teacher/DashboardAction"
import { getQuickInfoDetails } from "../../actions/CommonAction"
import { URL_PATH } from "../../config/urlPath"
import { myLog } from "../../utils/Utility"

import earlyWarningSvg from "../../assets/images/earlywarning.svg"
import noDataPic from "../../assets/images/nodata.png"
import girlPic from "../../assets/images/girl.png"
import boyPic from "../../assets/images/boy.png"

import MessageModal from "./MessageModal"
import AppWrapper from "./Wrapper"

let myspGraph = null
let myATGraph = null

class TeacherDashboard extends Component {
  constructor(props) {
    super(props)
    document.title = "Dashboard | Redink"
    this.state = {
      isMessageModalOpen: false,
      isSchoolDetailsModalOpen: false,

      clearSchoolDetailsModal: false,
      savingSchoolDetails: false,
      classAttendanceReport: {},
      topPerformingStudents: [],
      attentionNeededStudents: [],
      classAllSectionsGraph: null,
      classPerformanceByAssessment: null,
      date: null,
    }
  }

  componentDidMount = () => {
    this.props.getQuickInfoDetails(() => {
      this.props.getTeacherClassInfoDetails(() => {})
      this.props.getDashboardStudentAttendanceReportList(
        {},
        (success, response) => {
          if (success) {
            this.setState({ classAttendanceReport: response })
          }
        }
      )
      this.props.getTeacherDashboardTopPerformingStudentsDetails(
        (success, response) => {
          if (success) {
            this.setState({
              performanceReportAssessment: response.assessment,
              topPerformingStudents: response.topPerformers,
              attentionNeededStudents: response.attentionNeedStudents,
              // earlyWarning: response.earlyWarningStudent,
            })
          }
        }
      )
      this.props.getTeacherDashboardClassPerformanceByAssessmentDetails(
        (success, response) => {
          if (success) {
            this.setState({ classPerformanceByAssessment: response })
            if (store.get("userSession").userInfo.role !== 4) {
              this.showAttendanceTimelineGraph()
            }
          }
        }
      )
      this.props.getTeacherDashboardEarlyWarningDetails((success, response) => {
        if (success) {
          this.setState({ earlyWarning: response.earlyWarningStudents })
        }
      })
      this.props.getTeacherDashboardClassAllSectionsPerformanceDetails(
        (success, response) => {
          if (success) {
            this.setState({ classAllSectionsGraph: response })
            if (store.get("userSession").userInfo.role !== 4) {
              this.showSchoolPerformanceGraph()
            }
          }
        }
      )
    })
  }

  findRouter = dashboardAlertMessage => {
    if (dashboardAlertMessage === "STUDENT") {
      return URL_PATH.TEACHER_STUDENT_RECORD
    }
  }

  showSchoolPerformanceGraph = () => {
    const schoolPerformanceGraph = this.schoolPerformanceGraph
    if (myspGraph != null) {
      myspGraph.destroy()
    }

    myspGraph = new Chart(schoolPerformanceGraph, {
      type: "doughnut",
      data: {
        labels: this.state.classAllSectionsGraph.sectionNames,
        datasets: [
          {
            label: "Count",
            data: this.state.classAllSectionsGraph.sectionMarks,
            backgroundColor: this.state.classAllSectionsGraph.backgroundColors,
            fill: false,
            borderWidth: 0,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "right",
          labels: {
            usePointStyle: true,
            fontSize: 8,
            fontColor: "white",
          },
        },
        tooltips: {
          callbacks: {
            title: function(tooltipItem, data) {
              return data["labels"][tooltipItem[0]["index"]]
            },
            label: function(tooltipItem, data) {
              var dataset = data["datasets"][0]
              var percent = dataset.data[tooltipItem.index]
              return percent + "%"
            },
          },
          xAlign: "left",
        },
      },
    })
  }

  showAttendanceTimelineGraph = () => {
    if (myATGraph != null) {
      myATGraph.destroy()
    }
    myATGraph = new Chart(this.attendanceTimelineGraph, {
      type: "line",
      data: {
        labels: this.state.classPerformanceByAssessment.assessments,
        datasets: [
          {
            fill: true,
            borderWidth: 8,
            backgroundColor: "#479997",
            pointRadius: 4,
            data: this.state.classPerformanceByAssessment.marks,
          },
        ],
      },
      options: {
        responsive: true,
        legend: {
          display: false,
          labels: {
            usePointStyle: true,
            fontSize: 10,
            fontColor: "white",
          },
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: "#FFFFFF",
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                display: false,
                beginAtZero: true,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            title: function(tooltipItem, data) {
              return data["labels"][tooltipItem[0]["index"]]
            },
            label: function(tooltipItem, data) {
              myLog(data, tooltipItem)
              var dataset = data["datasets"][0]
              var percent = dataset.data[tooltipItem.index]
              return percent + "%"
            },
          },
          position: "average",
        },
      },
    })
  }
  /**
   * Handles school details modal
   */
  closeSchoolDetailsModal = () =>
    this.setState({ isSchoolDetailsModalOpen: false })
  /**
   * Handles message modal
   */
  closeMessageModal = () => this.setState({ isMessageModalOpen: false })

  /**
   * handles on click message icon in sidebar
   */
  onClickMessageIcon = () => {
    this.setState({ isMessageModalOpen: !this.state.isMessageModalOpen })
  }
  /**
   * handles message submit
   */
  onSubmitMessage = () => {
    this.closeMessageModal()
  }

  render() {
    return (
      <AppWrapper
        openMessageModal={() => this.onClickMessageIcon()}
        teacherDashboard
        showTeacherThree
        showTeacherLogo
        screenName={
          store.get("quickInfo") ? store.get("quickInfo").schoolName : ""
        }
      >
        <MessageModal
          isModalOpen={this.state.isMessageModalOpen}
          closeModal={() => this.onClickMessageIcon()}
          onSubmitMessage={() => this.onSubmitMessage()}
        />
        <div className="row mr-2" style={{ gap: "1rem" }}>
          {store.get("quickInfo") && store.get("quickInfo").dashoardAlert ? (
            <div
              className="px-3 py-2 col-md bg-theme-light br-10"
              style={{ backgroundColor: "#ff5050" }}
            >
              <div className="d-flex justify-content-between">
                <div className="text-left text-light">
                  <h5>
                    Alert{" "}
                    {store.get("quickInfo")
                      ? store.get("quickInfo").teacherName
                      : ""}{" "}
                    !
                  </h5>
                  <p>
                    Please create{" "}
                    {store.get("quickInfo") &&
                    store.get("quickInfo").dashboardAlertMessage
                      ? store.get("quickInfo").dashboardAlertMessage
                      : ""}{" "}
                    in records tab in left menu.
                  </p>
                </div>
                <div>
                  <button
                    type="button"
                    className="mt-3 mr-2 btn btn-primary rounded-pill"
                    onClick={() => {
                      this.props.history.push({
                        pathname: this.findRouter(
                          store.get("quickInfo") &&
                            store.get("quickInfo").dashboardAlertMessage
                            ? store.get("quickInfo").dashboardAlertMessage
                            : ""
                        ),
                        state: {},
                      })
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="px-3 py-2 col-md bg-theme-light br-10">
              <div className="text-left text-light">
                <h5 className="font-weight-bold">
                  Welcome{" "}
                  {store.get("quickInfo")
                    ? store.get("quickInfo").teacherName
                    : ""}{" "}
                  !
                </h5>
                <p>
                  Its gonna be a great day, We are excited same as you are !
                </p>
              </div>
            </div>
          )}

          <div className="px-3 py-2 col-sm bg-theme-light br-10">
            <div className="text-left font-weight-bold fs-15 text-light">
              TODAY'S ATTENDANCE
            </div>
            <UncontrolledTooltip placement="top" target="prekg-a-pass1">
              {this.state.classAttendanceReport
                ? this.state.classAttendanceReport.presentPercentage + "%"
                : "0%"}
              {" students present"}
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="prekg-a-fail2">
              {this.state.classAttendanceReport
                ? this.state.classAttendanceReport.absentPercentage + "%"
                : "0%"}
              {" students absent"}
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target="prekg-a-fail3">
              {this.state.classAttendanceReport
                ? this.state.classAttendanceReport.unmarkedPercentage + "%"
                : "0%"}
              {" students unmarked"}
            </UncontrolledTooltip>
            <div className="progress mt-3">
              <div
                className="progress-bar bg-present"
                role="progressbar"
                id="prekg-a-pass1"
                style={{
                  width: this.state.classAttendanceReport
                    ? this.state.classAttendanceReport.presentPercentage + "%"
                    : "0",
                }}
                aria-valuenow={
                  this.state.classAttendanceReport
                    ? this.state.classAttendanceReport.presentPercentage
                    : "0"
                }
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {/* {'80%'} */}
              </div>
              <div
                className="progress-bar bg-absent"
                role="progressbar"
                id="prekg-a-fail2"
                style={{
                  width: this.state.classAttendanceReport
                    ? this.state.classAttendanceReport.absentPercentage + "%"
                    : "0",
                }}
                aria-valuenow={
                  this.state.classAttendanceReport
                    ? this.state.classAttendanceReport.absentPercentage
                    : "0"
                }
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {/* {'20%'} */}
              </div>
              <div
                className="progress-bar bg-unmarked"
                role="progressbar"
                id="prekg-a-fail3"
                style={{
                  width: this.state.classAttendanceReport
                    ? this.state.classAttendanceReport.unmarkedPercentage + "%"
                    : "0",
                }}
                aria-valuenow={
                  this.state.classAttendanceReport
                    ? this.state.classAttendanceReport.unmarkedPercentage
                    : "0"
                }
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {/* {'20%'} */}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="py-4 text-center">
                  <b className="text-center text-white">
                    {this.state.classAttendanceReport &&
                    this.state.classAttendanceReport.presentCount
                      ? this.state.classAttendanceReport.presentCount
                      : "0"}
                  </b>
                  <div className="mt-1 text-center border-top-present">
                    <small className="text-white">Present</small>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="py-4 text-center">
                  <b className="text-center text-white">
                    {this.state.classAttendanceReport &&
                    this.state.classAttendanceReport.absentCount
                      ? this.state.classAttendanceReport.absentCount
                      : "0"}
                  </b>
                  <div className="mt-1 text-center border-top-absent">
                    <small className="text-white">Absent</small>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="py-4 text-center">
                  <b className="text-center text-white">
                    {this.state.classAttendanceReport &&
                    this.state.classAttendanceReport.unmarkedCount
                      ? this.state.classAttendanceReport.unmarkedCount
                      : "0"}
                  </b>
                  <div className="mt-1 text-center border-top-unmarked">
                    <small className="text-white">Unmarked</small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="px-3 py-2 col-sm bg-theme-light br-10">
            <div className="row text-light">
              <div className="text-center col d-flex flex-column">
                <b>
                  {this.props.teacherClassDetail &&
                  this.props.teacherClassDetail.students
                    ? this.props.teacherClassDetail.students
                    : "0"}
                </b>
                <span>Students</span>
              </div>
              <div className="text-center col d-flex flex-column">
                <b>
                  {this.props.teacherClassDetail &&
                  this.props.teacherClassDetail.boys
                    ? this.props.teacherClassDetail.boys
                    : "0"}
                </b>
                <span>Boys</span>
              </div>
              <div className="text-center col d-flex flex-column">
                <b>
                  {this.props.teacherClassDetail &&
                  this.props.teacherClassDetail.girls
                    ? this.props.teacherClassDetail.girls
                    : "0"}
                </b>
                <span>Girls</span>
              </div>
            </div>
            <hr
              style={{
                borderColor: "black",
                backgroundColor: "black",
                color: "black",
              }}
            ></hr>
            <div className="row text-light">
              <div className="text-center col d-flex flex-column">
                <b>
                  {this.props.teacherClassDetail &&
                  this.props.teacherClassDetail.teachers
                    ? this.props.teacherClassDetail.teachers
                    : "0"}
                </b>
                <span>Teachers</span>
              </div>
              <div className="text-center col d-flex flex-column">
                <b>
                  {this.props.teacherClassDetail &&
                  this.props.teacherClassDetail.male
                    ? this.props.teacherClassDetail.male
                    : "0"}
                </b>
                <span>Male</span>
              </div>
              <div className="text-center col d-flex flex-column">
                <b>
                  {this.props.teacherClassDetail &&
                  this.props.teacherClassDetail.female
                    ? this.props.teacherClassDetail.female
                    : "0"}
                </b>
                <span>Female</span>
              </div>
            </div>
          </div>
        </div>

        {/* 2nd */}
        {store.get("userSession").userInfo.role !== 4 && (
          <div className="my-3 row mr-2" style={{ gap: "1rem" }}>
            <div className="px-3 py-2 col-md col-lg-4 bg-theme-light br-10 d-flex flex-column">
              <div className="d-flex justify-content-between align-items-center">
                <div className="text-left font-weight-bold fs-15 text-light">
                  SECTION WISE COMPARISION
                </div>
                <div className="px-3 badge bg-theme-verylight br-10 text-light">
                  {this.state.performanceReportAssessment}
                </div>
              </div>
              <div className="p-3 my-auto align-items-center">
                {this.state.classAllSectionsGraph ? (
                  <canvas
                    ref={schoolPerformanceGraph =>
                      (this.schoolPerformanceGraph = schoolPerformanceGraph)
                    }
                  />
                ) : (
                  <div className="p-3 text-center rounded bg-brand">
                    <img
                      src={noDataPic}
                      alt="No Data Found"
                      style={{ width: 100 }}
                    />
                    <div className="mt-4 text-center text-white">
                      No Data Found
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="px-3 py-2 col-sm-6 col-md-6 col-lg-4 bg-theme-light br-10 d-flex flex-column">
              <div className="text-left font-weight-bold fs-15 text-light">
                CLASS PROGRESS
              </div>
              <div className="p-3 my-auto align-items-center">
                {this.state.classPerformanceByAssessment ? (
                  <canvas
                    ref={attendanceTimelineGraph =>
                      (this.attendanceTimelineGraph = attendanceTimelineGraph)
                    }
                  />
                ) : (
                  <div className="p-3 my-auto align-items-center">
                    <div className="p-3 text-center rounded bg-brand">
                      <img
                        src={noDataPic}
                        alt="No Data Found"
                        style={{ width: 100 }}
                      />
                      <div className="mt-4 text-center text-white">
                        No Data Found
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="px-3 py-2 col-sm col-md col-lg bg-theme-light br-10 d-flex flex-column justify-content-between">
              <div className="text-left font-weight-bold fs-15 text-light">
                SCHEDULE
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <Calendar
                  value={this.state.date}
                  // onChange={val => this.setState({ date: val })}
                  // value={{ day: "", month: "", year: "" }}
                  colorPrimary="#9c88ff"
                  calendarClassName="custom-calendar" // and this
                  calendarTodayClassName="custom-today-day" // also this
                  shouldHighlightWeekends
                />
              </div>
            </div>

            {/* <div className="p-0 mr-2 col-sm d-inline-block bg-theme-light br-10">
              <div className="px-4 py-2 text-left font-weight-bold fs-12 text-light">
                SCHEDULES
              </div>
              <Calendar
                id="Calendar"
                className="w-auto py-2 mb-0 rounded shadow br-0 col-sm"
                style={{ marginBottom: "0%" }}
                onChange={this.onChange}
                value={this.state.date}
              />
            </div> */}
            {/* <div className="p-0 mr-2 br-10">
              <div
                className="px-4 py-2 "
                style={{
                  backgroundColor: "#084353",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}
              >
                <div className="text-left font-weight-bold fs-12">
                  SCHEDULES
                </div>
              </div>
            </div> */}
          </div>
        )}

        {/* 3rd */}
        <div className="my-3 row mr-2" style={{ gap: "1rem" }}>
          <div className="px-3 py-2 col-sm col-lg-4 bg-theme-light br-10 d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center">
              <div className="text-left font-weight-bold fs-15 text-light">
                TOP PERFORMING STUDENTS
              </div>
              <div className="px-3 badge bg-theme-verylight br-10 text-light">
                {this.state.performanceReportAssessment}
              </div>
            </div>
            {this.state.topPerformingStudents &&
            this.state.topPerformingStudents.length ? (
              this.state.topPerformingStudents.map((item, i) => (
                <div
                  key={i}
                  className="mt-3 d-flex bg-theme-verylight br-10 text-light fs-12 align-items-center "
                >
                  <div className="text-center col-sm-5 d-flex flex-column">
                    <b>{item.studentName}</b>
                    <span>
                      {item.studentAssessmentMark} out of{" "}
                      {item.totalAssessmentMark}
                    </span>
                  </div>
                  <div className="col-sm">
                    <b>{item.className}</b>
                  </div>
                  <div className="py-2 col-sm">
                    <div style={{ width: "60px", height: "60px" }}>
                      <CircularProgressbar
                        value={item.percentage}
                        text={`${item.percentage}%`}
                        styles={buildStyles({
                          pathColor: `#0fb293`,
                          textColor: "#ffffff",
                          trailColor: "rgba(71, 153, 151, .22)",
                        })}
                      />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="p-3 my-auto align-items-center">
                <div className="p-3 text-center rounded bg-brand">
                  <img
                    src={noDataPic}
                    alt="No Data Found"
                    style={{ width: 100 }}
                  />
                  <div className="mt-4 text-center text-white">
                    No Data Found
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="px-3 py-2 col-sm col-lg-4 bg-theme-light br-10 d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center">
              <div className="text-left font-weight-bold fs-15 text-light">
                ATTENTION NEEDED
              </div>
              <div className="px-3 badge bg-theme-verylight br-10 text-light">
                {this.state.performanceReportAssessment}
              </div>
            </div>
            {this.state.attentionNeededStudents &&
            this.state.attentionNeededStudents.length ? (
              <div className="mt-3 table-responsive">
                <table className="table text-white table-borderless">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-1 text-center first-child-curved-td bg-lightblue-td fs-12"
                      >
                        STUDENT NAME
                      </th>
                      <th
                        scope="col"
                        className="py-1 text-center bg-lightblue-td fs-12"
                      >
                        CLASS
                      </th>
                      <th
                        scope="col"
                        className="py-1 text-center last-child-curved-td fs-12"
                      >
                        PERCENTAGE
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-center text-light fs-12">
                    {this.state.attentionNeededStudents &&
                      this.state.attentionNeededStudents.map((item, i) => (
                        <tr key={i}>
                          <td>{item.studentName}</td>
                          <td>{item.className}</td>
                          <td>{item.percentage}%</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="p-3 my-auto align-items-center">
                <div className="p-3 text-center rounded bg-brand">
                  <img
                    src={noDataPic}
                    alt="No Data Found"
                    style={{ width: 100 }}
                  />
                  <div className="mt-4 text-center text-white">
                    No Data Found
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="px-3 py-2 col-sm bg-theme-light br-10 d-flex flex-column justify-content-between">
            <div className="d-flex justify-content-between align-items-center">
              <div className="text-left font-weight-bold fs-15 text-light">
                <i className="fas fa-exclamation-triangle text-warning" /> EARLY
                WARNING (
                {this.state.earlyWarning ? this.state.earlyWarning.length : "0"}
                )
              </div>
              <div className="px-3 badge bg-theme-verylight br-10 text-light">
                {this.state.performanceReportAssessment}
              </div>
            </div>
            {this.state.earlyWarning && this.state.earlyWarning.length ? (
              <Carousel
                indicators={false}
                controls={this.state.earlyWarning.length !== 1}
              >
                {this.state.earlyWarning.map((earlyWarning, i) => (
                  <Carousel.Item key={i}>
                    <div className="py-1 mt-2 bg-theme-verylight br-10 text-light fs-12 align-items-center">
                      <div className="px-5 mt-4 ml-5 row">
                        <div className="ml-3">
                          <img
                            className="rounded-circle"
                            src={
                              earlyWarning.studentGender === "M"
                                ? boyPic
                                : girlPic
                            }
                            alt="logo"
                            width={50}
                          />
                        </div>
                        <div className="mt-3 ml-4 justify-content-center">
                          <p>{earlyWarning.studentName}</p>
                        </div>
                      </div>
                      <div
                        className="px-5 mt-3 table-responsive m-auto"
                        style={{ width: "80%" }}
                      >
                        <table className="table table-borderless">
                          <tbody className="text-light fs-12">
                            <tr>
                              <td className="pro-name">Attendance</td>
                              <td width="40%" className="pro-td">
                                <Progress
                                  percent={earlyWarning.attendancePercentage}
                                  status="active"
                                  className="my-1"
                                  symbolClassName="d-none"
                                  theme={{
                                    active: {
                                      trailColor: "rgba(71, 153, 151, .22)",
                                      color: "#18a489",
                                    },
                                  }}
                                />
                              </td>
                              <td>{earlyWarning.attendancePercentage + "%"}</td>
                            </tr>
                            <tr>
                              <td className="pro-name">Academic</td>
                              <td className="pro-td">
                                <Progress
                                  percent={earlyWarning.academicPercentage}
                                  status={"active"}
                                  className="my-1"
                                  symbolClassName="d-none"
                                  theme={{
                                    active: {
                                      trailColor: "rgba(71, 153, 151, .22)",
                                      color: "#18a489",
                                    },
                                  }}
                                />
                              </td>
                              <td>{earlyWarning.academicPercentage + "%"}</td>
                            </tr>
                            {/* <tr>
                    <td>Behaviour</td>
                    <td>
                      <Progress
                        percent={
                          this.state.earlyWarning &&
                          this.state.earlyWarning.behaviourPercentage
                        }
                        status={"active"}
                        className="my-1"
                        symbolClassName="d-none"
                        theme={{
                          active: {
                            trailColor: "rgba(71, 153, 151, .22)",
                            color: "#18a489",
                          },
                        }}
                      />
                    </td>
                    <td>
                      {this.state.earlyWarning &&
                        this.state.earlyWarning.behaviourPercentage + "%"}
                    </td>
                  </tr> */}
                            <tr>
                              <td className="pro-name">Assignment</td>
                              <td className="pro-td">
                                <Progress
                                  percent={
                                    earlyWarning.assignmentPercentage
                                      ? earlyWarning.assignmentPercentage
                                      : "0"
                                  }
                                  status={"active"}
                                  className="my-1"
                                  symbolClassName="d-none"
                                  theme={{
                                    active: {
                                      trailColor: "rgba(71, 153, 151, .22)",
                                      color: "#18a489",
                                    },
                                  }}
                                />
                              </td>
                              <td>
                                {earlyWarning.assignmentPercentage
                                  ? earlyWarning.assignmentPercentage + "%"
                                  : 0 + "%"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            ) : (
              <div className="my-auto align-items-center">
                <div className="p-3 text-center rounded bg-brand">
                  <img src={earlyWarningSvg} alt="earlywarning" height={150} />
                  <div className="mt-4 text-center text-white">
                    No Data Found
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    studentList: state.studentDatabaseState.studentList,
    teacherClassDetail: state.teacherDashboardState.teacherClassDetail,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getQuickInfoDetails,
      getTeacherClassInfoDetails,
      getDashboardStudentAttendanceReportList,
      getTeacherDashboardTopPerformingStudentsDetails,
      getTeacherDashboardClassAllSectionsPerformanceDetails,
      getTeacherDashboardClassPerformanceByAssessmentDetails,
      getTeacherDashboardEarlyWarningDetails,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(TeacherDashboard)
