/*************************************************
 * RedInk
 * @exports
 * @class ClassDetailsAction.js
 * @extends Component
 * @author vijayakumar // on 05/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { ACTION_TYPES } from "../types"
import { showNotification } from "../CommonAction"
import {
  SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"

export const saveClassDetailsInStore = ClassDetails => {
  return {
    type: ACTION_TYPES.CLASS_LIST,
    payload: ClassDetails,
  }
}

export const getClassDetails = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_CLASS_DETAILS, object, true)
      if (response) {
        myLog("====getClassDetails RESPONSE===::::", response)
        dispatch(saveClassDetailsInStore(response.classList))
        callBack(true, response.classList)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getClassDetails ERROR===????", error)
    }
  }
}

export const getDashboardClassDetails = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_CLASS_DETAILS, object, true)
      if (response) {
        myLog("====getDashboardClassDetails RESPONSE===::::", response)
        let array = []
        for (let i = 0; i < response.classList.length; i++) {
          let arrayIndex = array.findIndex(
            item => item.classId === response.classList[i].classId
          )
          if (arrayIndex === -1) {
            array.push(response.classList[i])
          }
        }
        myLog("====getDashboardClassDetails array===::::", array)
        callBack(true, array)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getDashboardClassDetails ERROR===????", error)
    }
  }
}

export const saveClassDetails = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      const response = await Client.post(URL.SAVE_CLASS_DETAILS, body, true)
      if (response.responseCode === 0) {
        myLog("====saveClassDetails RESPONSE===::::", response)
        dispatch(getClassDetails({}, () => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveClassDetails ERROR===????", error)
    }
  }
}

export const updateClassDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.post(URL.UPDATE_CLASS_DETAILS, body, true)
      if (response.responseCode === 0) {
        myLog("====updateClassDetails RESPONSE===::::", response)
        dispatch(getClassDetails({}, () => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====updateClassDetails ERROR===????", error)
    }
  }
}

export const deleteClassDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(URL.DELETE_CLASS_DETAILS, body, true)
      if (response.responseCode === 0) {
        myLog("====deleteClassDetails RESPONSE===::::", response)
        dispatch(getClassDetails({}, () => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteClassDetails ERROR===????", error)
    }
  }
}

export const getClassGroup = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_CLASS_GROUP, object, true)
      if (response) {
        myLog("====getClassDetails RESPONSE===::::", response)
        dispatch({ type: ACTION_TYPES.CLASS_GROUP, payload: response.groups })
        dispatch({
          type: ACTION_TYPES.CLASS_GROUP_ALREADY_ASSIGNED,
          payload: response.alreadyGroupedSections,
        })
        callBack(true, response.classList)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getClassDetails ERROR===????", error)
    }
  }
}

const reSaveTermDetails = async groupId => {
  const termDetails = await Client.get(URL.GET_TERM_DETAILS, null, true)
  if (termDetails && termDetails.responseCode === 0) {
    for (const termInfo of termDetails.terms) {
      for (const alloc of termInfo.allocationDetails || []) {
        if (alloc.sectionGroups.find(scg => scg.groupId === groupId)) {
          const termUpdateObject = {
            termName: termInfo.termName,
            termShortName: termInfo.termShortName,
            termId: termInfo.termId,
            termFrom: alloc.termFrom,
            termTo: alloc.termTo,
            changeMaxMark: alloc.changeMaxMark,
            id: alloc.id,
            resultCondition: alloc.resultCondition,
            mainAssessmentType: alloc.mainAssessmentType,
            sectionGroups: alloc.sectionGroups,
            assessments: alloc.assessments,
          }
          await Client.post(URL.UPDATE_TERM_DETAILS, termUpdateObject, true)
        }
      }
    }
  }
}

export const saveClassGroup = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      const response = await Client.post(URL.SAVE_CLASS_GROUP, body, true)
      if (response.responseCode === 0) {
        myLog("====saveClassGroup RESPONSE===::::", response)
        dispatch(getClassGroup({}, () => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        if (body.groupId) {
          reSaveTermDetails(body.groupId)
        }
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveClassGroup ERROR===????", error)
    }
  }
}

export const updateClassGroup = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.post(URL.SAVE_CLASS_GROUP, body, true)
      if (response.responseCode === 0) {
        myLog("====updateClassGroup RESPONSE===::::", response)
        dispatch(getClassGroup({}, () => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====updateClassGroup ERROR===????", error)
    }
  }
}

export const deleteClassGroup = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(URL.DELETE_CLASS_GROUP, body, true)
      if (response.responseCode === 0) {
        myLog("====deleteClassGroup RESPONSE===::::", response)
        dispatch(getClassGroup({}, () => {}))
        dispatch(getClassDetails({}, () => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteClassGroup ERROR===????", error)
    }
  }
}

export const deleteClassFromGroup = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.DELETE_CLASS_FROM_GROUP,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteClassFromGroup RESPONSE===::::", response)
        dispatch(getClassGroup({}, () => {}))
        dispatch(getClassDetails({}, () => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteClassFromGroup ERROR===????", error)
    }
  }
}
