/*************************************************
 * Redink
 * @exports
 * @file Utility.js
 * @author Sasidharan // on 19/12/2019
 * @copyright © 2019 Redink. All rights reserved.
 *************************************************/
import store from "store"
import { confirmAlert } from "react-confirm-alert"
import "react-confirm-alert/src/react-confirm-alert.css"
import URL from "./../config/index"

export let userSessionTimer
let sessionTime = 0

export const myLog = URL.isDevelopment ? console.log : () => {}

export const parseNameFromLocation = (name, location) => {
  if (typeof name !== "string") {
    myLog("wrong name ", name)
    return ""
  }
  const removedLocation = name.replace(location, "")
  return decodeURI(removedLocation.slice(removedLocation.indexOf("_") + 1))
}

export const ExcelDateToJSDate = serial => {
  let utc_days = Math.floor(serial - 25569)
  let utc_value = utc_days * 86400
  let date_info = new Date(utc_value * 1000)

  let fractional_day = serial - Math.floor(serial) + 0.0000001

  let total_seconds = Math.floor(86400 * fractional_day)

  let seconds = total_seconds % 60

  total_seconds -= seconds

  let hours = Math.floor(total_seconds / (60 * 60))
  let minutes = Math.floor(total_seconds / 60) % 60

  return new Date(
    date_info.getFullYear(),
    date_info.getMonth(),
    date_info.getDate(),
    hours,
    minutes,
    seconds
  )
}

export const getBase64 = (file, callback) => {
  let reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = function() {
    callback(reader.result)
  }
  reader.onerror = function(error) {
    myLog("Error: ", error)
  }
}

export const isLoggedIn = () => {
  if (typeof store.get("userSession") === "object") {
    if (!userSessionTimer) {
      scheduleTokenRenewal()
    }
    return true
  }
  // if userSession object is not present return false
  return false
}

export const isSuperAdminLoggedIn = () => {
  if (typeof store.get("userSession") === "object") {
    if (!userSessionTimer) {
      scheduleTokenRenewal()
    }
    if (store.get("userSession").userInfo.role === 1) return true
  }
  // if userSession object is not present return false
  return false
}

export const isAdminLoggedIn = () => {
  if (typeof store.get("userSession") === "object") {
    if (!userSessionTimer) {
      scheduleTokenRenewal()
    }
    if (store.get("userSession").userInfo.role === 2) return true
  }
  // if userSession object is not present return false
  return false
}

export const isTeacherLoggedIn = () => {
  if (typeof store.get("userSession") === "object") {
    if (!userSessionTimer) {
      scheduleTokenRenewal()
    }
    if (
      store.get("userSession").userInfo.role === 3 ||
      store.get("userSession").userInfo.role === 4
    )
      return true
  }
  // if userSession object is not present return false
  return false
}

export const isParentLoggedIn = () => {
  if (typeof store.get("userSession") === "object") {
    if (!userSessionTimer) {
      scheduleTokenRenewal()
    }
    if (store.get("userSession").userInfo.role === 5) return true
  }
  // if userSession object is not present return false
  return false
}

export const isFounderLoggedIn = () => {
  if (typeof store.get("userSession") === "object") {
    if (!userSessionTimer) {
      scheduleTokenRenewal()
    }
    if (store.get("userSession").userInfo.role === 0) return true
  }
  // if userSession object is not present return false
  return false
}

export const validateEmail = email => {
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const confirmAlertPopUp = (title, message, callback, additional) => {
  confirmAlert({
    title,
    message,
    closeOnEscape: true,
    closeOnClickOutside: true,
    buttons: [
      {
        className: "btn-danger",
        label: "Yes",
        onClick: () => callback(true),
      },
      {
        label: "No",
        onClick: () => callback(false),
      },
    ],
    ...additional,
  })
}

export const logout = () => {
  store.clearAll()
  clearInterval(userSessionTimer)
  window.location.href = "/"
}

// logout user when user is not active for 20 mins
export const scheduleTokenRenewal = () => {
  sessionTime = 0
  userSessionTimer = setInterval(() => {
    sessionTime++
    if (sessionTime > 5000) {
      // alert("Session timeout");
      logout()
    }
  }, 5000)
}

const sessionTimeRenewal = () => {
  sessionTime = 0
}

function keyDownTextField() {
  if (isLoggedIn()) {
    sessionTimeRenewal()
  }
}

function keyDownEvent() {
  if (isLoggedIn()) {
    sessionTimeRenewal()
  }
}

function mouseMoveEvent() {
  if (isLoggedIn()) {
    sessionTimeRenewal()
  }
}

// Listen to user keypress and reset timer
document.addEventListener("keypress", keyDownTextField, false)

// Listen to user keypress and reset timer
document.addEventListener("keydown", keyDownEvent, false)

// Listen to user keypress and reset timer
document.addEventListener("mousemove", mouseMoveEvent, false)

window.addEventListener("offline", () => {
  myLog("App is in offline")
})

window.addEventListener("online", () => {
  myLog("App is in online")
})
