import React from "react"
import AppWrapper from "../Wrapper"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import { URL_PATH } from "../../../config/urlPath"
import { HashLoader } from "react-spinners"
// import { myLog } from "../../../utils/Utility"
import moment from "moment"
import { getSingleQuiz } from "../../../actions/parent/QuizAction"
import Skeleton from "react-loading-skeleton"
import Modal, { ModalTransition } from "@atlaskit/modal-dialog"

const LEGENDS_COLORS = {
  CORRECT: {
    bg: "#06b19182",
    font: "#236d66",
  },
  WRONG: {
    bg: "#ed365c6e",
    font: "#a03050",
  },
  UNGRADED: {
    bg: "#60606029",
    font: "#777777",
  },
  PARTIAL_CORRECT: {
    bg: "#e5a02582",
    font: "#7f4f08",
  },
}

const questionTypes = [
  { value: "TRUE_OR_FALSE", label: "True or false" },
  { value: "MULTIPLE_CHOICE", label: "Multiple choice" },
  { value: "MULTIPLE_ANSWER", label: "Multiple answer" },
  { value: "SHORT_ANSWER", label: "Short answer" },
  { value: "LONG_ANSWER", label: "Long answer" },
  { value: "MATCHING", label: "Matching" },
  { value: "FILL_IN_THE_BLANKS", label: "Fill in the blanks" },
  { value: "SEQUENCING", label: "Sequencing" },
]

class AssesmentResultScreen extends React.Component {
  state = {
    isLoadingStudent: true,
    answerType: "LONG_ANSWER",
    currentQuestion: 0,
    currentSection: 0,
    quizSubmissions: [],
    isInstructionsModalOpen: false,
    instructions: "",
  }

  componentDidMount = () => {
    const { quizAssignInfoId } = this.props.location.state
    this.props.getSingleQuiz(
      { id: quizAssignInfoId, solved: true },
      (success, response) => {
        this.setState({ isLoadingStudent: false })
        if (success) {
          this.setState({ ...response })
        }
      }
    )
  }

  calculateStudentMarks = () => {
    let marks = 0
    for (let section of this.state.quizSet.quizSections) {
      for (let question of section.quizQuestions) {
        const { mark } = question?.quizSubmissionAnswers?.[0] || { mark: 0 }
        marks += mark || 0
      }
    }
    return marks
  }

  calcTotalMarks = sectionIndex => {
    if (sectionIndex >= this.state.quizSet.quizSections.length) return

    return this.state.quizSet.quizSections[sectionIndex].quizQuestions.reduce(
      (val, que) => {
        // const add = typeof que.mark === "number" ? que.mark : parseInt(que.mark)
        return val + que.mark
      },
      0
    )
  }

  calcQueNumber = (
    sectionNo,
    questionNo,
    quizSections = this.state.quizSections
  ) => {
    // let number = 1

    // let totalQuizSections = quizSections.length

    // let prevSectionIndex = this.state.currentSection - 1

    // let allSectionsTotalQuestion = 0
    let prevSectionsQuestionsCount = 0
    for (const [key, value] of Object.entries(quizSections)) {
      let quizSectionsQuestionsCount = value.quizQuestions.length
      if (key == this.state.currentSection) {
        return questionNo + prevSectionsQuestionsCount + 1
      }
      prevSectionsQuestionsCount =
        prevSectionsQuestionsCount + quizSectionsQuestionsCount
    }
  }

  downloadFile = ({ url, name }) => {
    const xhr = new XMLHttpRequest()
    xhr.onload = () => {
      const file = new Blob([xhr.response], {
        type: "application/pdf",
      })
      const browserUrl = window.webkitURL || window.URL
      const fileUrl = browserUrl.createObjectURL(file)
      const a = document.createElement("a")
      a.href = fileUrl
      a.download = name
      a.click()
      browserUrl.revokeObjectURL(fileUrl)
    }
    xhr.open("GET", url, true)
    xhr.responseType = "blob"
    xhr.send(null)
  }

  /**
   * Render question icon for sidebar
   * @param {number} number
   * @param {'CORRECT'|'WRONG'|'PARTIAL'|'UNGRADED'} type
   * @param {true|false} isSelected
   * @param {Function} onClick
   */
  renderQuestionIcon = (number, type, isSelected, onClick) => (
    <div
      className="m-2 cursor-pointer"
      style={{
        borderRadius: 16,
        border: "3px solid transparent",
        borderColor: isSelected ? LEGENDS_COLORS[type].bg : "transparent",
      }}
      onClick={() => onClick(number)}
    >
      <div
        style={{
          borderRadius: 12,
          backgroundColor: LEGENDS_COLORS[type].bg,
          margin: 2,
          width: 35,
          height: 35,
          display: "flex",
        }}
      >
        <div
          className="font-weight-bold m-auto"
          style={{
            color: LEGENDS_COLORS[type].font,
            fontSize: "1.2rem",
          }}
        >
          {number}
        </div>
      </div>
    </div>
  )

  renderQuestionItem = () => {
    if (this.state.isLoadingStudent) {
      return (
        <div style={{ position: "absolute", left: "50%", top: "50%" }}>
          <HashLoader sizeUnit="px" size={32} color="#094251" loading />
        </div>
      )
    }

    const submission = this.state.quizSet
    if (!submission) return <></>

    // myLog({ submission })

    const selectedSection = submission.quizSections[this.state.currentSection]
    if (!selectedSection) return <></>

    const selectedQuestion =
      selectedSection.quizQuestions[this.state.currentQuestion]
    // myLog({ selectedSection, selectedQuestion })
    const quizSubmissionAnswer =
      selectedQuestion.quizSubmissionAnswers &&
      selectedQuestion.quizSubmissionAnswers[0]

    return (
      <div className="col-md-8 mt-2">
        <div
          className="d-flex align-items-center px-3 py-4"
          style={{ borderRadius: 8, backgroundColor: "#f7f7f7" }}
        >
          <div className="text-brand font-weight-bold m-0">
            {submission.title}
          </div>
          <i
            className="fas fa-info-circle ml-2 cursor-pointer"
            id="test-instructions-info"
            aria-hidden="true"
            onClick={e =>
              this.setState({
                isInstructionsModalOpen: true,
                instructions: submission.instruction,
              })
            }
          ></i>
        </div>

        {!!this.state.quizSet.hasSection && (
          <div
            className="d-flex mt-2"
            style={{ borderBottom: "1px solid #eaeaea" }}
          >
            {submission.quizSections.map((section, index) => (
              <div
                key={section.title}
                className="py-2 px-3 cursor-pointer text-green"
                style={{
                  borderBottom: `4px solid ${
                    this.state.currentSection === index
                      ? "#04b191"
                      : "transparent"
                  }`,
                }}
                onClick={e =>
                  this.setState({ currentSection: index, currentQuestion: 0 })
                }
              >
                {section.title}
              </div>
            ))}
          </div>
        )}

        <div className="d-flex align-items-center mt-5">
          <div
            className="bg-brand text-light d-flex align-items-center justify-content-center rounded-pill"
            style={{ width: 34, height: 34 }}
          >
            Q
            {this.calcQueNumber(
              this.state.currentSection,
              this.state.currentQuestion,
              submission.quizSections
            )}
          </div>

          <div className="mx-2" style={{ flex: 1 }}>
            <div className="text-muted">
              {
                questionTypes.find(
                  ({ value }) => value === selectedQuestion.type
                ).label
              }
            </div>
            <div className="text-brand">
              {quizSubmissionAnswer?.mark || 0} / {selectedQuestion?.mark || 1}{" "}
              Mark
            </div>
          </div>

          <div className="mr-2">
            <button
              className="btn px-4 py-1 mr-2"
              style={{
                borderRadius: 12,
                color: "#23847b",
                backgroundColor: "rgba(6, 177, 145, 0.19)",
              }}
              disabled={
                this.state.currentQuestion === 0 &&
                this.state.currentSection === 0
              }
              onClick={() => {
                if (this.state.currentQuestion === 0) {
                  const newSectionIndex = this.state.currentSection - 1
                  this.setState({
                    currentSection: newSectionIndex,
                    currentQuestion:
                      submission.quizSections[newSectionIndex].quizQuestions
                        .length - 1,
                  })
                } else {
                  this.setState({
                    currentQuestion: this.state.currentQuestion - 1,
                  })
                }
              }}
            >
              Previous
            </button>
            <button
              className="btn btn-light px-4 py-1"
              style={{ borderRadius: 12 }}
              disabled={
                this.state.currentQuestion + 1 ===
                  selectedSection.quizQuestions.length &&
                this.state.currentSection + 1 === submission.quizSections.length
              }
              onClick={() => {
                if (
                  this.state.currentQuestion + 1 ===
                  selectedSection.quizQuestions.length
                ) {
                  this.setState({
                    currentSection: this.state.currentSection + 1,
                    currentQuestion: 0,
                  })
                } else {
                  this.setState({
                    currentQuestion: this.state.currentQuestion + 1,
                  })
                }
              }}
            >
              Next
            </button>
          </div>
        </div>

        <div
          className="rounded px-4 py-5 mt-3"
          style={{ backgroundColor: "#f7f7f7" }}
        >
          {selectedQuestion.type === "TRUE_OR_FALSE" && (
            <>
              <h4 className="text-dark mb-4">{selectedQuestion.text}</h4>

              {["true", "false"].map(ques => {
                const didStudentAnswer =
                  ques === quizSubmissionAnswer?.answers?.[0]
                const isCorrect = selectedQuestion?.answers?.[0] === ques
                const border =
                  didStudentAnswer && !isCorrect
                    ? "border-danger"
                    : isCorrect
                    ? "border-success"
                    : "border-muted"
                const icon = didStudentAnswer
                  ? "far fa-dot-circle"
                  : "far fa-circle"

                return (
                  <div
                    key={ques}
                    className={`rounded px-3 py-2 d-flex align-items-center mt-2 border ${border}`}
                  >
                    <i
                      className={`far fa-${icon} text-muted`}
                      style={{ fontSize: "1.2rem" }}
                    />
                    <div className="ml-2">{ques}</div>
                  </div>
                )
              })}
            </>
          )}

          {["LONG_ANSWER", "SHORT_ANSWER"].includes(selectedQuestion.type) && (
            <>
              <h4 className="text-dark mb-4">{selectedQuestion.text}</h4>

              <div
                className="border border-muted px-3 py-3"
                style={{ borderRadius: 12 }}
              >
                {(selectedQuestion.type === "LONG_ANSWER" ||
                  selectedQuestion.type === "SHORT_ANSWER") &&
                quizSubmissionAnswer.longAnswer !== null
                  ? quizSubmissionAnswer.longAnswer
                  : quizSubmissionAnswer?.answers?.[0]}
              </div>
            </>
          )}

          {["MULTIPLE_ANSWER", "MULTIPLE_CHOICE"].includes(
            selectedQuestion.type
          ) && (
            <>
              <h4 className="text-dark mb-4">{selectedQuestion.text}</h4>

              {selectedQuestion.questions.map((ques, index) => {
                const didStudentAnswer = quizSubmissionAnswer?.answers?.includes(
                  ques
                )
                const isCorrect = selectedQuestion?.answers?.includes(ques)
                const border =
                  didStudentAnswer && isCorrect
                    ? "border-success"
                    : didStudentAnswer && !isCorrect
                    ? "border-danger"
                    : "border-muted"
                const icon =
                  selectedQuestion.type === "MULTIPLE_CHOICE"
                    ? didStudentAnswer
                      ? "far fa-dot-circle"
                      : "far fa-circle"
                    : didStudentAnswer
                    ? "fas fa-check-square"
                    : "far fa-square"

                return (
                  <div
                    className={`text-dark d-flex align-items-center border ${border} mb-2 px-3`}
                    style={{
                      borderRadius: 8,
                      height: 40,
                      backgroundColor: "#fff",
                    }}
                    key={index}
                  >
                    <i
                      className={`far fa-${icon} text-muted`}
                      style={{ fontSize: "1.2rem" }}
                    />
                    <span className="ml-4">{ques}</span>
                  </div>
                )
              })}
            </>
          )}

          {selectedQuestion.type === "FILL_IN_THE_BLANKS" && (
            <>
              <h4 className="w-100 text-dark mb-2">
                {selectedQuestion.text.split("____")?.[0]}
                <div
                  className="bg-green mx-2 text-center"
                  style={{
                    display: "inline-block",
                    borderRadius: 8,
                  }}
                >
                  <div
                    className={`text-dark px-4 py-1 border ${
                      quizSubmissionAnswer?.answers?.[0] ===
                      selectedQuestion?.answers?.[0]
                        ? "border-muted"
                        : "border-red"
                    }`}
                    style={{
                      borderRadius: 8,
                      backgroundColor: "#fff",
                    }}
                  >
                    {quizSubmissionAnswer?.answers?.[0]}
                  </div>
                  {quizSubmissionAnswer?.answers?.[0] !==
                    selectedQuestion?.answers?.[0] && (
                    <div className="px-3 font-weight-bold text-light">
                      {selectedQuestion?.answers?.[0]}
                    </div>
                  )}
                </div>
                {selectedQuestion?.text?.split("____")?.[1]}
              </h4>
            </>
          )}

          {selectedQuestion.type === "SEQUENCING" && (
            <>
              <h4 className="text-dark mb-4">{selectedQuestion.text}</h4>

              <div className="d-flex align-items-center mb-1">
                <div className="d-flex align-items-center justify-content-center">
                  <div
                    className="text-muted"
                    style={{ fontSize: "1rem", width: 40 }}
                  >
                    Order
                  </div>
                </div>
                <div style={{ flex: 1, marginLeft: 12 }}></div>
              </div>

              {selectedQuestion.questions.map((ques, index) => {
                const studentAns = quizSubmissionAnswer?.answers?.[index]
                const isCorrect =
                  studentAns === selectedQuestion?.answers?.[index]
                const correctPosition = selectedQuestion?.answers?.findIndex(
                  ee => ee === studentAns
                )

                return (
                  <div className="d-flex align-items-center mb-2" key={index}>
                    <div className="d-flex align-items-center justify-content-center">
                      <div
                        className="text-dark d-flex align-items-center justify-content-center border border-muted"
                        style={{
                          borderRadius: 8,
                          width: 40,
                          height: 40,
                          backgroundColor: "#fff",
                        }}
                      >
                        {ques}
                      </div>
                    </div>

                    <div style={{ flex: 1, marginLeft: 12 }}>
                      <div
                        className="d-flex bg-green"
                        style={{
                          height: 40,
                          overflow: "hidden",
                          borderRadius: 8,
                        }}
                      >
                        <div
                          className={`text-dark d-flex align-items-center justify-content-start px-4 border ${
                            isCorrect ? "border-success" : "border-danger"
                          }`}
                          style={{
                            borderRadius: 8,
                            flex: 1,
                            height: "100%",
                            backgroundColor: "#fff",
                          }}
                        >
                          {quizSubmissionAnswer?.answers?.[index]}
                        </div>
                        {!isCorrect && (
                          <div className="px-3 font-weight-bold text-light d-flex align-items-center">
                            {correctPosition + 1}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </>
          )}

          {selectedQuestion.type === "MATCHING" && (
            <>
              <h4 className="text-dark mb-2">{selectedQuestion.text}</h4>

              {selectedQuestion.questions.map((ques, index) => {
                const isCorrect =
                  quizSubmissionAnswer?.answers?.[index] ===
                  selectedQuestion?.answers?.[index]

                return (
                  <div
                    className="px-2 py-2 rounded d-flex align-items-start justify-content-between"
                    style={{
                      backgroundColor: "rgba(0,0,0,0.1)",
                      width: "50%",
                      minWidth: "min(100%, 500px)",
                    }}
                    key={index}
                  >
                    <div
                      className="bg-green ml-2 text-center d-flex flex-column mr-2"
                      style={{
                        flex: 1,
                        height: 53,
                        overflow: "hidden",
                        borderRadius: 8,
                      }}
                    >
                      <div
                        className="text-dark px-4 py-1 border border-muted d-flex align-items-center justify-content-center"
                        style={{
                          flex: 1,
                          borderRadius: 8,
                          backgroundColor: "#fff",
                        }}
                      >
                        {ques}
                      </div>
                    </div>

                    <div
                      className="bg-green ml-2 text-center d-flex flex-column ml-2"
                      style={{
                        flex: 1,
                        height: 53,
                        overflow: "hidden",
                        borderRadius: 8,
                      }}
                    >
                      <div
                        className={`text-dark px-4 py-1 d-flex align-items-center justify-content-center border ${
                          isCorrect ? "border-success" : "border-danger"
                        }`}
                        style={{
                          verticalAlign: "center",
                          flex: 1,
                          borderRadius: 8,
                          backgroundColor: "#fff",
                        }}
                      >
                        {quizSubmissionAnswer?.answers?.[index] || ""}
                      </div>
                      {!isCorrect && (
                        <div className="px-3 font-weight-bold text-light">
                          {selectedQuestion?.answers?.[index] || ""}
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
            </>
          )}

          {(quizSubmissionAnswer?.answers?.length === 0 ||
            quizSubmissionAnswer?.answers?.[0] === "") &&
            selectedQuestion.type !== "LONG_ANSWER" &&
            selectedQuestion.type !== "SHORT_ANSWER" && (
              <h5 className="text-muted text-center mt-3">
                Student did not answer this question
              </h5>
            )}

          {(selectedQuestion.type === "LONG_ANSWER" ||
            selectedQuestion.type === "SHORT_ANSWER") &&
            quizSubmissionAnswer.longAnswer === null &&
            quizSubmissionAnswer?.answers?.[0] === "" && (
              <h5 className="text-muted text-center mt-3">
                Student did not answer this question
              </h5>
            )}

          {selectedQuestion?.attachment && (
            <>
              <div className="text-dark mt-4">Teacher Attachment</div>
              <div
                className="d-flex align-items-center cursor-pointer rounded mt-2"
                style={{ maxWidth: 250, backgroundColor: "#ececec" }}
                onClick={() => this.downloadFile(selectedQuestion.attachment)}
              >
                <div className="p-3 bg-brand rounded">
                  <img
                    src={require("../../../assets/images/assignment/pdf.svg")}
                    alt="Attachment"
                    style={{ height: 25, width: 25 }}
                  />
                </div>
                <div className="px-2 text-truncate">
                  {selectedQuestion.attachment.name}
                </div>
              </div>
            </>
          )}

          {quizSubmissionAnswer?.attachment && (
            <>
              <div className="text-dark mt-4">Student Attachment</div>
              <div
                className="d-flex align-items-center cursor-pointer rounded mt-2"
                style={{ maxWidth: 250, backgroundColor: "#ececec" }}
                onClick={() =>
                  this.downloadFile(quizSubmissionAnswer.attachment)
                }
              >
                <div className="p-3 bg-brand rounded">
                  <img
                    src={require("../../../assets/images/assignment/pdf.svg")}
                    alt="Attachment"
                    style={{ height: 25, width: 25 }}
                  />
                </div>
                <div className="px-2 text-truncate">
                  {quizSubmissionAnswer.attachment.name}
                </div>
              </div>
            </>
          )}
        </div>

        <h5 className="text-brand mt-4 mb-2">Comments</h5>
        <textarea
          className="form-control"
          rows={5}
          placeholder="Teacher hasn't given any remarks..."
          value={quizSubmissionAnswer?.comment}
          readOnly
        >
          {selectedQuestion?.comment}
        </textarea>
      </div>
    )
  }

  renderDetailsPanel = () => {
    const submission = this.state.quizSubmissions[0]
    const quizSections = this.state.quizSet && this.state.quizSet.quizSections

    let stats = {
      CORRECT: 0,
      WRONG: 0,
      PARTIAL_CORRECT: 0,
      UNGRADED: 0,
    }

    if (this.state.quizSet && this.state.quizSet.quizSections) {
      for (let section of this.state.quizSet.quizSections) {
        for (let question of section.quizQuestions) {
          stats[question?.quizSubmissionAnswers?.[0]?.gradedStatus]++
        }
      }
    }

    return (
      <div className="col-md-4 mt-2">
        <div
          className="px-4 py-2 d-flex flex-column"
          style={{
            borderRadius: 12,
            border: "1px solid #eaeaea",
            backgroundColor: "#f7f7f7",
          }}
        >
          {submission ? (
            <>
              <div className="d-flex align-items-center">
                <img
                  src={
                    submission.student.gender === "M"
                      ? require("../../../assets/images/boy.png")
                      : require("../../../assets/images/girl.png")
                  }
                  style={{ width: 38, height: 38, borderRadius: 40 }}
                  alt=""
                />
                <div className="mx-2" style={{ flex: 1 }}>
                  <div className="text-brand mb-05">
                    {submission.student.fullName}
                  </div>
                  <div className="text-brand">
                    {submission.student.registerRollNumber}
                  </div>
                </div>
              </div>

              {submission.graded ? (
                <div className="my-2 text-light text-uppercase rounded mr-auto px-2 py-05 bg-green">
                  Graded
                </div>
              ) : (
                <div
                  className="my-2 text-light text-uppercase rounded mr-auto px-2 py-05"
                  style={{ backgroundColor: "#ed365c" }}
                >
                  Ungraded
                </div>
              )}

              <div className="my-2 d-flex text-brand align-items-end font-weight-bold">
                <p className="m-0">Total Marks</p>
                <h4 className="text-brand m-0 mx-2">
                  {this.calculateStudentMarks()}
                </h4>
                <p className="m-0">out of</p>
                <h4 className="text-brand m-0 mx-2">
                  {this.state.quizSet && this.state.quizSet.totalMark}
                </h4>
              </div>

              <div className="my-1 text-muted">
                {moment(submission.submittedAt).format("DD MMM YYYY, HH:MM A")}
              </div>

              <div className="mb-2 text-muted">
                Time Taken: {submission.timeTaken}
              </div>

              <div
                className="py-2 px-4 d-flex flex-column"
                style={{
                  margin: "1rem -1.5rem",
                  borderTop: "1px solid #eaeaea",
                }}
              >
                <div className="d-flex align-items-center mb-2">
                  <h5 className="text-brand mr-auto mb-0">
                    {(!!this.state.quizSet.hasSection &&
                      this.state.currentSection < quizSections.length &&
                      quizSections[this.state.currentSection].title) ||
                      "Questions"}{" "}
                    {!!this.state.quizSet.hasSection &&
                      `(${this.calcTotalMarks(
                        this.state.currentSection
                      )} Marks)`}
                  </h5>
                </div>

                <div className="mt-1 d-flex flex-wrap align-items-center m-auto">
                  {this.state.currentSection < quizSections.length &&
                    quizSections[
                      this.state.currentSection
                    ].quizQuestions.map((que, index) => (
                      <React.Fragment key={index}>
                        {this.renderQuestionIcon(
                          this.calcQueNumber(quizSections, index, quizSections),
                          (que.quizSubmissionAnswers &&
                            que.quizSubmissionAnswers[0] &&
                            que.quizSubmissionAnswers[0].gradedStatus) ||
                            "UNGRADED",
                          index === this.state.currentQuestion,
                          () => this.setState({ currentQuestion: index })
                        )}
                      </React.Fragment>
                    ))}
                </div>
              </div>

              <h5 className="text-brand mr-auto mt-3">Legends & Stats</h5>
              <div className="my-1">
                <div className="row">
                  <div className="col d-flex align-items-center">
                    {this.renderQuestionIcon(stats.CORRECT, "CORRECT", false)}
                    <p className="m-0">Correct Answer</p>
                  </div>

                  <div className="col d-flex align-items-center">
                    {this.renderQuestionIcon(stats.WRONG, "WRONG", false)}
                    <p className="m-0">Wrong Answer</p>
                  </div>
                </div>

                <div className="row">
                  <div className="col d-flex align-items-center">
                    {this.renderQuestionIcon(
                      stats.PARTIAL_CORRECT,
                      "PARTIAL_CORRECT",
                      false
                    )}
                    <p className="m-0">Partial Answer</p>
                  </div>

                  <div className="col d-flex align-items-center">
                    {this.renderQuestionIcon(stats.UNGRADED, "UNGRADED", false)}
                    <p className="m-0">Ungraded</p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex align-items-center">
                <Skeleton circle={true} height="38px" width="38px" />
                <div className="mx-2" style={{ flex: 1 }}>
                  <div className="text-brand mb-05">
                    <Skeleton />
                  </div>
                  <div className="text-brand">
                    <Skeleton />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    )
  }

  renderInstructionsModal = () => (
    <ModalTransition>
      {this.state.isInstructionsModalOpen && (
        <Modal
          heading="Instructions"
          actions={[
            {
              text: "Close",
              onClick: () => this.setState({ isInstructionsModalOpen: false }),
            },
          ]}
        >
          <div className="form-group px-2 col-sm-8">
            <p>{this.state.instructions}</p>
          </div>
        </Modal>
      )}
    </ModalTransition>
  )

  render() {
    return (
      <>
        {this.renderInstructionsModal()}
        <AppWrapper
          teacherDashboard
          showTeacherThree
          showTeacherLogo
          screenName="TEST Result"
        >
          <div className="d-flex mb-2 align-items-center">
            <h4 className="text-brand font-weight-bold m-0">Quiz - Result</h4>
            <button
              className="btn mx-3 my-1 py-1 px-3 bg-green text-light ml-auto"
              style={{ borderRadius: 12 }}
              onClick={() =>
                this.props.history.push({
                  pathname: URL_PATH.PARENT_STUDENT_TESTS,
                })
              }
            >
              Close
            </button>
          </div>

          <div className="row mb-4">
            {this.renderQuestionItem()}

            {this.renderDetailsPanel()}
          </div>
        </AppWrapper>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getSingleQuiz,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  dispatchToProps
)(withRouter(AssesmentResultScreen))
