import React, { useState } from "react"

function Mark({ val }) {
  const [bg] = useState(
    typeof val === "string"
      ? ""
      : val < 40
      ? "bg-DC625E"
      : val < 70
      ? "bg-FAB94B"
      : val < 90
      ? "bg-4FC1B6"
      : "bg-0A9394"
  )

  return (
    <div className="px-1" style={{ padding: "1px 0" }}>
      {typeof val === "string" && val.length > 2 ? (
        <div className="fs-8 fw-500 clr-686868 pl-2">{val}</div>
      ) : (
        <div
          className={`report-card-mark-box d-flex justify-content-center align-items-center fs-9 fw-600 mx-auto ${
            bg ? `${bg} text-white` : ""
          }`}
        >
          {val}
        </div>
      )}
    </div>
  )
}

export default Mark
