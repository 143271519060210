/*************************************************
 * RedInk
 * @exports
 * @class BranchesScreen.js
 * @extends Component
 * @author Sasidharan // on 03/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import SchoolDetailsModal from "../SchoolDetailsModal"
import AppWrapper from "../Wrapper"

import {
  saveSchoolDetails,
  getSchoolDetails,
  updateSchoolDetails,
  deleteSchoolDetails,
  saveSchoolsDetailsInStore,
} from "../../../actions/superAdmin/schoolDetailsAction"
import { connect } from "react-redux"
import Skeleton from "react-loading-skeleton"
import { confirmAlertPopUp, myLog } from "../../../utils/Utility"
import { VALIDATION_MSG } from "../../../utils/Constants"

class Branches extends Component {
  constructor(props) {
    super(props)
    document.title = "Branches | Redink"
    this.state = {
      isSchoolDetailsModalOpen: false,
      clearSchoolDetailsModal: false,
      savingSchoolDetails: false,
      isEditSchoolClicked: false,
      isCreateSchoolClicked: false,
      showSkeletonLoading: true,
      schoolDetailsData: [],
      editSchoolData: {},
    }
  }

  componentDidMount = () => {
    this.getSchoolDetails()
  }

  getSchoolDetails = () => {
    this.props.getSchoolDetails((promise, schoolDetailsData) => {
      if (promise) {
        this.setState({ schoolDetailsData })
      }
      this.setState({ showSkeletonLoading: false })
    })
  }

  /**
   * Handles school details modal
   */
  closeSchoolDetailsModal = () => {
    this.getSchoolDetails()
    this.setState({
      isSchoolDetailsModalOpen: false,
      clearSchoolDetailsModal: true,
    })
    let object = {
      schoolLogo: "",
      admins: [{ adminName: "", adminEmail: "", adminContact: "" }],
      schoolName: "",
      schoolShortName: "",
      schoolEmail: "",
      emailId: "",
      affiliation: "",
      contactNumber: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      country: "India",
      zipCode: "",
    }
    this.props.saveSchoolsDetailsInStore(object)
  }
  /**
   * Handles modal form submit
   */
  onSubmitSchoolDetails = (params, closeModal) => {
    if (this.state.isEditSchoolClicked) {
      this.setState({ savingSchoolDetails: true })
      this.props.updateSchoolDetails(params, promise => {
        this.setState({
          isEditSchoolClicked: false,
          savingSchoolDetails: false,
        })
        if (promise) {
          if (closeModal) {
            this.closeSchoolDetailsModal()
            this.getSchoolDetails()
          }
        }
      })
    } else {
      this.setState({ savingSchoolDetails: true })
      this.props.saveSchoolDetails(params, promise => {
        this.setState({ savingSchoolDetails: false })
        if (promise) {
          if (closeModal) {
            this.closeSchoolDetailsModal()
            this.getSchoolDetails()
          }
          // this.setState({ clearSchoolDetailsModal: true })
        }
      })
    }
  }

  deleteBranch = item => {
    myLog(item)
    // confirmAlertPopUp(
    //   VALIDATION_MSG.DELETE_TITLE,
    //   VALIDATION_MSG.DELETE_CONTENT,
    //   yes => {
    //     if (yes) {
    //       this.props.deleteSchoolDetails({ id: item.branchId }, success => {
    //         if (success) {
    //           this.props.getSchoolDetails((promise, schoolDetailsData) => {
    //             if (promise) {
    //               this.setState({ schoolDetailsData })
    //             }
    //             this.setState({ showSkeletonLoading: false })
    //           })
    //         }
    //       })
    //     }
    //   }
    // )
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT,
      yes => {
        if (yes) {
          this.props.deleteCommunicationLabelDetails(
            item.id,
            (success, response) => {
              if (success) this.getCommunicationLabel()
            }
          )
        }
      },
      {
        buttons: [],
        customUI: ({ onClose }) => {
          return (
            <div
              className="custom"
              style={{
                backgroundColor: "#fff",

                borderBottomRightRadius: "12px",
                borderBottomLeftRadius: "12px",
              }}
            >
              <div
                className="bg-brand text-light d-flex"
                style={{
                  padding: "0.5rem 2rem",
                  borderTopRightRadius: "12px",
                  borderTopLeftRadius: "12px",
                }}
              >
                <div className="mx-auto" style={{ fontSize: "18px" }}>
                  Delete
                </div>
              </div>

              <div
                className="mt-3 "
                style={{
                  padding: "0.5rem 1.5rem",
                }}
              >
                <div className="text-muted" style={{ fontSize: "18px" }}>
                  Are you sure you want to delete?
                </div>
                <div className="text-danger mt-3">
                  Deleting will remove all the data related to this school
                </div>
                <div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
                  <button
                    className="bg-brand text-light px-4 py-1 w-25 d-flex align-items-center justify-content-center yes-button"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      this.props.deleteSchoolDetails(
                        { id: item.branchId },
                        success => {
                          if (success) {
                            this.props.getSchoolDetails(
                              (promise, schoolDetailsData) => {
                                if (promise) {
                                  this.setState({ schoolDetailsData })
                                }
                                this.setState({ showSkeletonLoading: false })
                              }
                            )
                          }
                        }
                      )
                      onClose()
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-brand text-light px-4 py-1 ml-4 w-25 d-flex align-items-center justify-content-center"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={onClose}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )
        },
      }
    )
  }

  render() {
    return (
      <AppWrapper
        showProfileSidemenu
        showBackToDashboard
        onChangeSchoolName={item => {
          myLog(item)
        }}
      >
        <div className="card">
          <SchoolDetailsModal
            isModalOpen={this.state.isSchoolDetailsModalOpen}
            closeModal={() => this.closeSchoolDetailsModal()}
            onSubmitSchoolDetails={(params, closeModal) =>
              this.onSubmitSchoolDetails(params, closeModal)
            }
            showLoading={this.state.savingSchoolDetails}
            // clearSchoolDetailsModal={this.state.clearSchoolDetailsModal}
            isEditSchoolClicked={this.state.isEditSchoolClicked}
            isCreateSchoolClicked={this.state.isCreateSchoolClicked}
          />
          <div className="d-flex justify-content-end m-3">
            <button
              type="button"
              className="btn btn-dark"
              onClick={() =>
                this.setState({
                  isSchoolDetailsModalOpen: true,
                  isCreateSchoolClicked: true,
                })
              }
            >
              <i className="fas fa-plus mr-2" /> Create New Branch
            </button>
          </div>
          {this.state.schoolDetailsData.length ? (
            <div className="d-flex m-3">
              <table
                className="table table-hover table-borderless"
                style={{ marginBottom: "4rem" }}
              >
                <thead>
                  <tr>
                    <th scope="col" style={{ width: "30%" }}>
                      {this.state.schoolDetailsData.length > 1
                        ? "Branch Names"
                        : "Branch Name"}
                    </th>
                    <th scope="col" style={{ width: "60%" }}>
                      {this.state.schoolDetailsData.length > 1
                        ? "Admins"
                        : "Admin"}
                    </th>
                    <th scope="col" style={{ width: "10%" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.schoolDetailsData.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr
                          style={{
                            borderLeft: `3px solid ${"#" +
                              Math.random()
                                .toString(16)
                                .substr(2, 6)}`,
                          }}
                        >
                          <td>{item.schoolName}</td>
                          <td>
                            {item.admins.map((admin, i) => {
                              return (
                                <span
                                  className="bg-secondary p-1 rounded text-light mr-2"
                                  key={i}
                                >
                                  {admin.adminName.toUpperCase()}
                                </span>
                              )
                            })}
                          </td>
                          <td className="d-flex justify-content-between align-content-center">
                            <button
                              className="badge badge-pill badge-secondary mr-2"
                              onClick={() => {
                                this.setState({
                                  isSchoolDetailsModalOpen: true,
                                  isEditSchoolClicked: true,
                                })
                                if (item.admins && item.admins.length === 0) {
                                  item.admins = [
                                    {
                                      adminName: "",
                                      adminEmail: "",
                                      adminContact: "",
                                    },
                                  ]
                                }
                                this.props.saveSchoolsDetailsInStore(item)
                              }}
                            >
                              <b>
                                <i className="far fa-user-edit mr-1" />
                                Edit
                              </b>
                            </button>
                            <button
                              className="badge badge-pill badge-danger"
                              onClick={() => this.deleteBranch(item)}
                            >
                              <b>
                                <i className="far fa-user-minus mr-1" />
                                Delete
                              </b>
                            </button>
                          </td>
                        </tr>
                        <div className="m-2"></div>
                      </React.Fragment>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : null}
          {this.state.showSkeletonLoading && (
            <div className="m-3">
              <Skeleton count={10} height={30} />
            </div>
          )}
        </div>
      </AppWrapper>
    )
  }
}

export default connect(null, {
  saveSchoolDetails,
  getSchoolDetails,
  updateSchoolDetails,
  deleteSchoolDetails,
  saveSchoolsDetailsInStore,
})(Branches)
