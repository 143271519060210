import React from "react"
import AppWrapper from "../Wrapper"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  getStudentQuizSubmission,
  saveStudentQuizSubmission,
  retakeStudentQuizSubmission,
} from "../../../actions/teacher/QuizAction"
import { myLog } from "../../../utils/Utility"
import moment from "moment"
import {
  Button,
  FormGroup,
  Label,
  // Input,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
} from "reactstrap"
import { HashLoader } from "react-spinners"
// import Modal, { ModalTransition } from "@atlaskit/modal-dialog"
import { DatePicker } from "antd"
import { URL_PATH } from "../../../config/urlPath"
// import "../../../assets/css/publishLater.css"

const start = moment()
const remainder = 30 - (start.minute() % 30)

const quizStartTime = moment(start)
  .add(remainder, "minutes")
  .set({ second: 0, millisecond: 0 })
const quizEndTime = moment(quizStartTime)
  .add(60, "minutes")
  .set({ second: 0, millisecond: 0 })

const { RangePicker } = DatePicker
// TimePicker,

const LEGENDS_COLORS = {
  CORRECT: {
    bg: "#06b19182",
    font: "#236d66",
  },
  WRONG: {
    bg: "#ed365c6e",
    font: "#a03050",
  },
  UNGRADED: {
    bg: "#60606029",
    font: "#777777",
  },
  PARTIAL_CORRECT: {
    bg: "#e5a02582",
    font: "#7f4f08",
  },
}

const questionTypes = [
  { value: "TRUE_OR_FALSE", label: "True or false" },
  { value: "MULTIPLE_CHOICE", label: "Multiple choice" },
  { value: "MULTIPLE_ANSWER", label: "Multiple answer" },
  { value: "SHORT_ANSWER", label: "Short answer" },
  { value: "LONG_ANSWER", label: "Long answer" },
  { value: "MATCHING", label: "Matching" },
  { value: "FILL_IN_THE_BLANKS", label: "Fill in the blanks" },
  { value: "SEQUENCING", label: "Sequencing" },
]

class MarkEvaluationScreen extends React.Component {
  state = {
    assignedStudents: [],
    isLoadingStudent: false,
    isSubmitting: false,
    isRetakeLoading: false,
    studentSubmissions: {},
    startTime: quizStartTime,
    endTime: quizEndTime,
    currentStudent: 0,
    currentSection: 0,
    currentQuestion: 0,
    isInstructionModalOpen: false,
    instructions: null,
  }

  componentDidMount = () => {
    const {
      // studentAssign,
      assignedStudents,
      currentStudent,
      quizAssign,
      quizAssignId,
    } = this.props.location.state || {}
    this.setState({ assignedStudents, quizAssign, quizAssignId }, () => {
      this.loadStudent(currentStudent)
    })
    document.addEventListener("keydown", this._handleKeyDown)
  }

  _handleKeyDown = e => {
    let selectedStudent1 = this.state.assignedStudents[
      this.state.currentStudent
    ]
    let submission1 = this.state.studentSubmissions[selectedStudent1.id]
    let selectedSection1 = submission1.quizSections[this.state.currentSection]
    switch (e.keyCode) {
      case 39:
        //Next
        if (
          this.state.currentQuestion + 1 ===
            selectedSection1.quizQuestions.length &&
          this.state.currentSection + 1 === submission1.quizSections.length
        ) {
          return
        }
        if (
          this.state.currentQuestion + 1 ===
          selectedSection1.quizQuestions.length
        ) {
          this.setState({
            currentSection: this.state.currentSection + 1,
            currentQuestion: 0,
          })
        } else {
          this.setState({
            currentQuestion: this.state.currentQuestion + 1,
          })
        }
        break
      case 37:
        //Previous
        if (
          this.state.currentQuestion === 0 &&
          this.state.currentSection === 0
        ) {
          return
        }
        if (this.state.currentQuestion === 0) {
          let newSectionIndex1 = this.state.currentSection - 1
          this.setState({
            currentSection: newSectionIndex1,
            currentQuestion:
              submission1.quizSections[newSectionIndex1].quizQuestions.length -
              1,
          })
        } else {
          this.setState({
            currentQuestion: this.state.currentQuestion - 1,
          })
        }
        break
      default:
        break
    }
  }

  onSubmit = (redirectToAssessement = false) => {
    const selectedStudent = this.state.assignedStudents[
      this.state.currentStudent
    ]
    const studentSubmission = this.state.studentSubmissions[selectedStudent.id]
    if (studentSubmission.quizAssignInfo.quizSubmissions[0].retake) {
      if (redirectToAssessement) {
        this.props.history.push({
          pathname: URL_PATH.TEACHER_TESTS_MARK_ENTRY,
          state: { quizAssignInfoId: this.state.quizAssignId },
        })
      }
      return
    }
    if (!selectedStudent.submitted) {
      this.setState({ isSubmitting: false })
      if (redirectToAssessement) {
        this.props.history.push({
          pathname: URL_PATH.TEACHER_TESTS_MARK_ENTRY,
          state: { quizAssignInfoId: this.state.quizAssignId },
        })
      }
    } else {
      if (typeof studentSubmission === "undefined") {
        // const studentSubmission = this.state.studentSubmissions[
        //   selectedStudent.id
        // ]
      }

      let quizSubmissionAnswers = []
      // quizQuestionId: id, gradedStatus, comment, mark
      for (let section of studentSubmission.quizSections) {
        for (let question of section.quizQuestions) {
          const {
            id,
            gradedStatus,
            comment,
            mark,
          } = question.quizSubmissionAnswers[0]
          quizSubmissionAnswers.push({
            id,
            gradedStatus: gradedStatus || "UNGRADED",
            comment: comment || "",
            mark: mark || 0,
          })
        }
      }
      const data = { quizSubmissionAnswers, comment: "" }

      this.setState({ isSubmitting: true })
      this.props.saveStudentQuizSubmission(
        selectedStudent.id,
        data,
        (success, response) => {
          this.setState(
            { isSubmitting: false, isLoadingStudent: false },
            () => {
              if (redirectToAssessement) {
                this.props.history.push({
                  pathname: URL_PATH.TEACHER_TESTS_MARK_ENTRY,
                  state: { quizAssignInfoId: this.state.quizAssignId },
                })
              }
            }
          )
        }
      )
    }
  }

  calculateStudentMarks = () => {
    const selectedStudent = this.state.assignedStudents[
      this.state.currentStudent
    ]
    const studentSubmission = this.state.studentSubmissions[selectedStudent.id]

    if (!selectedStudent || !studentSubmission) return 0

    let marks = 0
    for (let section of studentSubmission.quizSections) {
      for (let question of section.quizQuestions) {
        const { mark } = question.quizSubmissionAnswers[0]
        marks += mark || 0
      }
    }
    return marks
  }

  onRetake = () => {
    const { assignedStudents, currentStudent, startTime, endTime } = this.state
    if (!endTime || !startTime) return
    const selectedStudent = assignedStudents[currentStudent]
    this.setState({ isRetakeLoading: true })
    this.props.retakeStudentQuizSubmission(
      {
        id: selectedStudent.id,
        startTime: moment(startTime, "YYYY-MM-DD HH:mm:ss"),
        endTime: moment(endTime, "YYYY-MM-DD HH:mm:ss"),
      },
      (success, response) => {
        this.setState({
          isRetakeLoading: false,
          startTime: null,
          endTime: null,
        })
        // this.loadStudent(currentStudent)
        const students = [...this.state.assignedStudents]
        const selectedStudent = students[currentStudent]
        this.setState({
          studentSubmissions: {
            ...this.state.studentSubmissions,
            [selectedStudent.id]: response,
          },
        })
      }
    )
  }

  loadStudent = index => {
    const students = [...this.state.assignedStudents]
    const selectedStudent = students[index]
    if (!selectedStudent) {
      this.setState({ isLoadingStudent: false })
      return
    }
    this.setState({ isLoadingStudent: true, currentStudent: index })
    if (!this.state.studentSubmissions[selectedStudent.id]) {
      this.props.getStudentQuizSubmission(
        selectedStudent.id,
        (success, response) => {
          this.setState({ isLoadingStudent: false })
          if (success) {
            this.setState({
              studentSubmissions: {
                ...this.state.studentSubmissions,
                [selectedStudent.id]: response,
              },
            })
          }
        }
      )
    } else {
      this.setState({ isLoadingStudent: false })
    }
  }

  setCurrentQuestion = data => {
    const selectedStudent = this.state.assignedStudents[
      this.state.currentStudent
    ]
    let studentSubmissions = { ...this.state.studentSubmissions }
    studentSubmissions[selectedStudent.id].quizSections[
      this.state.currentSection
    ].quizQuestions[this.state.currentQuestion] = {
      ...studentSubmissions[selectedStudent.id].quizSections[
        this.state.currentSection
      ].quizQuestions[this.state.currentQuestion],
      ...data,
    }
    this.setState({ studentSubmissions })
  }

  calcTotalMarks = sectionIndex => {
    const selectedStudent = this.state.assignedStudents[
      this.state.currentStudent
    ]
    let studentSubmissions = this.state.studentSubmissions[selectedStudent.id]
    return studentSubmissions.quizSections[sectionIndex].quizQuestions.reduce(
      (val, que) => {
        // const add = typeof que.mark === "number" ? que.mark : parseInt(que.mark)
        return val + que.mark
      },
      0
    )
  }

  downloadFile = ({ url, name }) => {
    const xhr = new XMLHttpRequest()
    xhr.onload = () => {
      const file = new Blob([xhr.response])
      const browserUrl = window.webkitURL || window.URL
      const fileUrl = browserUrl.createObjectURL(file)
      const a = document.createElement("a")
      a.href = fileUrl
      a.download = name
      a.click()
      browserUrl.revokeObjectURL(fileUrl)
    }
    xhr.open("GET", url, true)
    xhr.responseType = "blob"
    xhr.send(null)
  }

  calcQueNumber = (
    sectionNo,
    questionNo,
    quizSections = this.state.quizSections
  ) => {
    let number = 0

    let sectionIndex = 0
    for (const section of quizSections) {
      let questionIndex = 0
      let tobreak = false
      for (const question of section.quizQuestions) {
        number++
        if (sectionIndex === sectionNo && questionIndex === questionNo) {
          tobreak = true
          break
        }

        questionIndex++
      }
      if (tobreak) break

      sectionIndex++
    }

    return number
  }

  /**
   * Render question icon for sidebar
   * @param {number} number
   * @param {'CORRECT'|'WRONG'|'PARTIAL_CORRECT'|'UNGRADED'} type
   * @param {true|false} isSelected
   * @param {Function} onClick
   */
  renderQuestionIcon = (number, type, isSelected, onClick) => {
    return (
      <div
        className="m-2 cursor-pointer"
        style={{
          borderRadius: 16,
          border: "3px solid transparent",
          borderColor: isSelected ? LEGENDS_COLORS[type].bg : "transparent",
        }}
        onClick={() => onClick(number)}
      >
        <div
          style={{
            borderRadius: 12,
            backgroundColor: LEGENDS_COLORS[type].bg,
            margin: 2,
            width: 35,
            height: 35,
            display: "flex",
          }}
        >
          <div
            className="font-weight-bold m-auto"
            style={{
              color: LEGENDS_COLORS[type].font,
              fontSize: "1.2rem",
            }}
          >
            {number}
          </div>
        </div>
      </div>
    )
  }

  renderQuestionItem = () => {
    const selectedStudent = this.state.assignedStudents[
      this.state.currentStudent
    ]
    const submission = this.state.studentSubmissions[selectedStudent.id]
    myLog("Submission data", submission)
    if (this.state.isLoadingStudent) {
      return (
        <div className="col-md-8 mt-4 d-flex align-items-center justify-content-center">
          <HashLoader
            sizeUnit={"px"}
            size={32}
            color={"#094251"}
            loading={true}
          />
        </div>
      )
    }

    if (!selectedStudent) return null
    if (!submission) return null

    if (!selectedStudent.submitted) {
      return (
        <div className="col-md-8 mt-2">
          <div
            className="d-flex align-items-center px-3 py-4"
            style={{ borderRadius: 8, backgroundColor: "#f7f7f7" }}
          >
            <div className="text-brand font-weight-bold m-0">
              {submission.title}
            </div>
            <i className="fas fa-info-circle text-muted ml-2" />
          </div>

          <div className="my-4 d-flex align-items-center justify-content-center">
            <h5 className="text-muted text-center">
              Student hasn't submitted the tests
            </h5>
          </div>
        </div>
      )
    }

    const selectedSection = submission.quizSections[this.state.currentSection]
    const selectedQuestions = selectedSection.quizQuestions.sort(
      (a, b) => a.index > b.index
    )
    const selectedQuestion = selectedQuestions[this.state.currentQuestion]
    const quizSubmissionAnswer =
      selectedQuestion.quizSubmissionAnswers &&
      selectedQuestion.quizSubmissionAnswers[0]
    // if (
    //   selectedQuestion.type === "MATCHING" ||
    //   selectedQuestion.type === "SEQUENCING"
    // ) {
    //   let markPerAnswer =
    //     selectedQuestion.mark / selectedQuestion.answers.length
    //   let finalTotalMarks = 0
    //   selectedQuestion.questions.map((ques, index) => {
    //     let isCorrect =
    //       quizSubmissionAnswer.answers[index] ===
    //       selectedQuestion.answers[index]
    //     if (isCorrect) {
    //       finalTotalMarks += markPerAnswer
    //     }
    //   })
    //   if (finalTotalMarks == selectedQuestion.mark) {
    //     selectedQuestion.quizSubmissionAnswers[0].gradedStatus = "CORRECT"
    //   }
    //   if (finalTotalMarks < selectedQuestion.mark && finalTotalMarks > 0) {
    //     selectedQuestion.quizSubmissionAnswers[0].gradedStatus =
    //       "PARTIAL_CORRECT"
    //   }
    //   if (finalTotalMarks <= 0) {
    //     selectedQuestion.quizSubmissionAnswers[0].gradedStatus = "WRONG"
    //   }
    //   // selectedQuestion.quizSubmissionAnswers[0].mark = finalTotalMarks
    // }

    // if (selectedQuestion.type === "MULTIPLE_ANSWER") {
    //   let markPerAnswer =
    //     selectedQuestion.mark / selectedQuestion.answers.length
    //   	let finalTotalMarks = 0
    //   selectedQuestion.questions.map((ques, index) => {
    //     const didStudentAnswer = quizSubmissionAnswer.answers.includes(ques)
    //     const isCorrect = selectedQuestion.answers.includes(ques)
    //     if (isCorrect) {
    //       finalTotalMarks += markPerAnswer
    //     }
    //   })

    //   if (finalTotalMarks == selectedQuestion.mark) {
    //     selectedQuestion.quizSubmissionAnswers[0].gradedStatus = "CORRECT"
    //   }
    //   if (finalTotalMarks < selectedQuestion.mark && finalTotalMarks > 0) {
    //     selectedQuestion.quizSubmissionAnswers[0].gradedStatus =
    //       "PARTIAL_CORRECT"
    //   }
    //   if (finalTotalMarks <= 0) {
    //     selectedQuestion.quizSubmissionAnswers[0].gradedStatus = "WRONG"
    //   }
    //   selectedQuestion.quizSubmissionAnswers[0].mark = finalTotalMarks
    // }

    const queNo = this.calcQueNumber(
      this.state.currentSection,
      this.state.currentQuestion,
      submission.quizSections
    )
    return (
      <div className="col-md-8 mt-2">
        <div
          className="d-flex align-items-center px-3 py-4"
          style={{ borderRadius: 8, backgroundColor: "#f7f7f7" }}
        >
          <div className="text-brand font-weight-bold m-0">
            {submission.title}
          </div>
          <i
            className="fas fa-info-circle text-muted ml-2 cursor-pointer"
            title={submission.instruction}
            onClick={e =>
              this.setState({
                isInstructionModalOpen: true,
                instructions: submission.instruction,
              })
            }
          />
        </div>

        {submission.hasSection ? (
          <div
            className="d-flex mt-2"
            style={{ borderBottom: "1px solid #eaeaea" }}
          >
            {submission.quizSections
              .sort((a, b) => a.index > b.index)
              .map((section, index) => (
                <div
                  key={section.title}
                  className="py-2 px-3 cursor-pointer text-green"
                  style={{
                    borderBottom: `4px solid ${
                      this.state.currentSection === index
                        ? "#04b191"
                        : "transparent"
                    }`,
                  }}
                  onClick={e =>
                    this.setState({ currentSection: index, currentQuestion: 0 })
                  }
                >
                  {section.title}
                </div>
              ))}
          </div>
        ) : null}

        <div className="d-flex align-items-center mt-2 mb-2">
          <div
            className="bg-brand text-light d-flex align-items-center justify-content-center rounded-pill"
            style={{ width: 34, height: 34 }}
          >
            Q{queNo}
          </div>

          <div className="mx-2" style={{ flex: 1 }}>
            <div className="text-muted">
              {
                questionTypes.find(
                  ({ value }) => value === selectedQuestion.type
                ).label
              }
            </div>
            <div className="text-brand">
              {quizSubmissionAnswer.mark} / {selectedQuestion.mark} Mark
            </div>
          </div>

          <div className="mr-2">
            <button
              className="btn px-4 py-1 mr-2"
              style={{
                borderRadius: 12,
                color:
                  this.state.currentQuestion === 0 &&
                  this.state.currentSection === 0
                    ? "#212529"
                    : "#23847b",
                backgroundColor:
                  this.state.currentQuestion === 0 &&
                  this.state.currentSection === 0
                    ? "#f8f9fa"
                    : "rgba(6, 177, 145, 0.19)",
              }}
              disabled={
                this.state.currentQuestion === 0 &&
                this.state.currentSection === 0
              }
              onClick={() => {
                if (this.state.currentQuestion === 0) {
                  const newSectionIndex = this.state.currentSection - 1
                  this.setState({
                    currentSection: newSectionIndex,
                    currentQuestion:
                      submission.quizSections[newSectionIndex].quizQuestions
                        .length - 1,
                  })
                } else {
                  this.setState({
                    currentQuestion: this.state.currentQuestion - 1,
                  })
                }
              }}
            >
              Previous
            </button>
            <button
              className="btn btn-light px-4 py-1"
              style={{
                borderRadius: 12,
                color:
                  this.state.currentQuestion + 1 ===
                    selectedSection.quizQuestions.length &&
                  this.state.currentSection + 1 ===
                    submission.quizSections.length
                    ? "#212529"
                    : "#23847b",
                backgroundColor:
                  this.state.currentQuestion + 1 ===
                    selectedSection.quizQuestions.length &&
                  this.state.currentSection + 1 ===
                    submission.quizSections.length
                    ? "#f8f9fa"
                    : "rgba(6, 177, 145, 0.19)",
              }}
              disabled={
                this.state.currentQuestion + 1 ===
                  selectedSection.quizQuestions.length &&
                this.state.currentSection + 1 === submission.quizSections.length
              }
              onClick={() => {
                if (
                  this.state.currentQuestion + 1 ===
                  selectedSection.quizQuestions.length
                ) {
                  this.setState({
                    currentSection: this.state.currentSection + 1,
                    currentQuestion: 0,
                  })
                } else {
                  this.setState({
                    currentQuestion: this.state.currentQuestion + 1,
                  })
                }
              }}
            >
              Next
            </button>
          </div>
        </div>

        <div className="px-4 py-5" style={{ backgroundColor: "#f7f7f7" }}>
          {selectedQuestion.type === "TRUE_OR_FALSE" && (
            <>
              <h4 className="text-dark mb-4">{selectedQuestion.text}</h4>

              {["true", "false"].map(ques => {
                const didStudentAnswer =
                  ques === quizSubmissionAnswer.answers[0]
                const isCorrect = selectedQuestion.answers[0] === ques
                const border =
                  didStudentAnswer && !isCorrect
                    ? "border-danger"
                    : isCorrect
                    ? "border-success"
                    : "border-muted"
                const icon = didStudentAnswer
                  ? "far fa-dot-circle"
                  : "far fa-circle"

                return (
                  <div
                    className={`rounded px-3 py-2 d-flex align-items-center mt-2 border ${border}`}
                  >
                    <i
                      className={`far fa-${icon} text-muted`}
                      style={{ fontSize: "1.2rem" }}
                    />
                    <div className="ml-2">{ques}</div>
                  </div>
                )
              })}
            </>
          )}

          {["LONG_ANSWER", "SHORT_ANSWER"].includes(selectedQuestion.type) && (
            <>
              <h4 className="text-dark mb-4">{selectedQuestion.text}</h4>
              <div
                className="border border-muted px-3 py-3"
                style={{ borderRadius: 12 }}
              >
                {(selectedQuestion.type === "LONG_ANSWER" ||
                  selectedQuestion.type === "SHORT_ANSWER") &&
                quizSubmissionAnswer.longAnswer != null
                  ? String(quizSubmissionAnswer.longAnswer)
                      .split("\n")
                      .map((list, i) => {
                        return (
                          <li style={{ listStyle: "none" }}>
                            {list.replace("\n", "")}
                          </li>
                        )
                      })
                  : quizSubmissionAnswer.answers[0]}
              </div>
            </>
          )}

          {["MULTIPLE_ANSWER", "MULTIPLE_CHOICE"].includes(
            selectedQuestion.type
          ) && (
            <>
              <h4 className="text-dark mb-4">{selectedQuestion.text}</h4>

              {selectedQuestion.questions.map((ques, index) => {
                const didStudentAnswer = quizSubmissionAnswer.answers.includes(
                  ques
                )
                const isCorrect = selectedQuestion.answers.includes(ques)
                const border =
                  didStudentAnswer && isCorrect
                    ? "border-success"
                    : didStudentAnswer && !isCorrect
                    ? "border-danger"
                    : "border-muted"
                const icon =
                  selectedQuestion.type === "MULTIPLE_CHOICE"
                    ? didStudentAnswer
                      ? "far fa-dot-circle"
                      : "far fa-circle"
                    : didStudentAnswer
                    ? "fas fa-check-square"
                    : "far fa-square"

                return (
                  <div
                    className={`text-dark d-flex align-items-center border ${border} mb-2 px-3`}
                    style={{
                      borderRadius: 8,
                      height: 40,
                      backgroundColor: "#fff",
                    }}
                  >
                    <i
                      className={`far fa-${icon} text-muted`}
                      style={{ fontSize: "1.2rem" }}
                    />
                    <span className="ml-4">{ques}</span>
                  </div>
                )
              })}
            </>
          )}

          {selectedQuestion.type === "FILL_IN_THE_BLANKS" && (
            <>
              <h4 className="w-100 text-dark mb-2">
                {selectedQuestion.text.split("____")[0]}
                <div
                  className="bg-green mx-2 text-center"
                  style={{
                    display: "inline-block",
                    borderRadius: 8,
                  }}
                >
                  <div
                    className={`text-dark px-4 py-1 border ${
                      quizSubmissionAnswer.answers[0] ===
                      selectedQuestion.answers[0]
                        ? "border-muted"
                        : "border-red"
                    }`}
                    style={{
                      borderRadius: 8,
                      backgroundColor: "#fff",
                    }}
                  >
                    {quizSubmissionAnswer.answers[0]}
                  </div>
                  {quizSubmissionAnswer.answers[0] !==
                    selectedQuestion.answers[0] && (
                    <div className="px-3 font-weight-bold text-light">
                      {selectedQuestion.answers[0]}
                    </div>
                  )}
                </div>
                {selectedQuestion.text.split("_____")[1]}
              </h4>
            </>
          )}

          {selectedQuestion.type === "SEQUENCING" && (
            <>
              <h4 className="text-dark mb-4">{selectedQuestion.text}</h4>

              <div className="d-flex align-items-center mb-1">
                <div className="d-flex align-items-center justify-content-center">
                  <div
                    className="text-muted"
                    style={{ fontSize: "1rem", width: 40 }}
                  >
                    Order
                  </div>
                </div>
                <div style={{ flex: 1, marginLeft: 12 }}></div>
              </div>

              {selectedQuestion.questions.map((ques, index) => {
                const studentAns = quizSubmissionAnswer.answers[index]
                const isCorrect =
                  quizSubmissionAnswer.answers[index] ==
                  selectedQuestion.answers[index]
                const correctPosition = selectedQuestion.answers.findIndex(
                  ee => ee === studentAns
                )

                return (
                  <div className="d-flex align-items-center mb-2">
                    <div className="d-flex align-items-center justify-content-center">
                      <div
                        className="text-dark d-flex align-items-center justify-content-center border border-muted"
                        style={{
                          borderRadius: 8,
                          width: 40,
                          height: 40,
                          backgroundColor: "#fff",
                        }}
                      >
                        {ques}
                      </div>
                    </div>

                    <div style={{ flex: 1, marginLeft: 12 }}>
                      <div
                        className="d-flex bg-green"
                        style={{
                          height: 40,
                          overflow: "hidden",
                          borderRadius: 8,
                        }}
                      >
                        <div
                          className={`text-dark d-flex align-items-center justify-content-start px-4 border ${
                            isCorrect ? "border-success" : "border-danger"
                          }`}
                          style={{
                            borderRadius: 8,
                            flex: 1,
                            height: "100%",
                            backgroundColor: "#fff",
                          }}
                        >
                          {quizSubmissionAnswer.answers[index]}
                        </div>
                        {!isCorrect && (
                          <div className="px-3 font-weight-bold text-light d-flex align-items-center">
                            {correctPosition + 1}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </>
          )}

          {selectedQuestion.type === "MATCHING" && (
            <>
              <h4 className="text-dark mb-2">{selectedQuestion.text}</h4>

              {selectedQuestion.questions.map((ques, index) => {
                const isCorrect =
                  quizSubmissionAnswer.answers[index] ===
                  selectedQuestion.answers[index]

                return (
                  <div
                    className="px-2 py-2 rounded d-flex align-items-start justify-content-between"
                    style={{
                      backgroundColor: "rgba(0,0,0,0.1)",
                      width: "50%",
                      minWidth: "min(100%, 500px)",
                    }}
                  >
                    <div
                      className="bg-green ml-2 text-center d-flex flex-column mr-2"
                      style={{
                        flex: 1,
                        height: 53,
                        overflow: "hidden",
                        borderRadius: 8,
                      }}
                    >
                      <div
                        className="text-dark px-4 py-1 border border-muted d-flex align-items-center justify-content-center"
                        style={{
                          flex: 1,
                          borderRadius: 8,
                          backgroundColor: "#fff",
                        }}
                      >
                        {ques}
                      </div>
                    </div>

                    <div
                      className="bg-green ml-2 text-center d-flex flex-column ml-2"
                      style={{
                        flex: 1,
                        height: 53,
                        overflow: "hidden",
                        borderRadius: 8,
                      }}
                    >
                      <div
                        className={`text-dark px-4 py-1 d-flex align-items-center justify-content-center border ${
                          isCorrect ? "border-success" : "border-danger"
                        }`}
                        style={{
                          verticalAlign: "center",
                          flex: 1,
                          borderRadius: 8,
                          backgroundColor: "#fff",
                        }}
                      >
                        {quizSubmissionAnswer.answers[index]}
                      </div>
                      {!isCorrect && (
                        <div className="px-3 font-weight-bold text-light">
                          {selectedQuestion.answers[index]}
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
            </>
          )}

          {(quizSubmissionAnswer.answers.length === 0 ||
            quizSubmissionAnswer.answers[0] === "") &&
            selectedQuestion.type !== "LONG_ANSWER" &&
            selectedQuestion.type !== "SHORT_ANSWER" && (
              <h5 className="text-muted text-center mt-3">
                Student did not answer this question
              </h5>
            )}

          {(selectedQuestion.type === "LONG_ANSWER" ||
            selectedQuestion.type === "SHORT_ANSWER") &&
            !quizSubmissionAnswer.longAnswer &&
            quizSubmissionAnswer.answers[0] === "" && (
              <h5 className="text-muted text-center mt-3">
                Student did not answer this question
              </h5>
            )}

          {selectedQuestion.attachment && (
            <>
              <div className="text-dark mt-4">Teacher Attachment</div>
              <div
                className="d-flex align-items-center cursor-pointer rounded mt-2"
                style={{ maxWidth: 250, backgroundColor: "#ececec" }}
                onClick={() => this.downloadFile(selectedQuestion.attachment)}
              >
                <div className="p-3 bg-brand rounded">
                  {selectedQuestion.attachment.name.endsWith(".pdf") ? (
                    <img
                      src={require("../../../assets/images/assignment/PDF.png")}
                      alt="Attachment"
                      style={{ height: 25, width: 25 }}
                    />
                  ) : selectedQuestion.attachment.name.endsWith(".png") ? (
                    <img
                      src={require("../../../assets/images/assignment/PNG.png")}
                      alt="Attachment"
                      style={{ height: 25, width: 25 }}
                    />
                  ) : selectedQuestion.attachment.name.endsWith(".jpg") ||
                    selectedQuestion.attachment.name.endsWith(".jpeg") ? (
                    <img
                      src={require("../../../assets/images/assignment/JPG.png")}
                      alt="Attachment"
                      style={{ height: 25, width: 25 }}
                    />
                  ) : (
                    <img
                      src={require("../../../assets/images/file.png")}
                      alt="Attachment"
                      style={{ height: 25, width: 25 }}
                    />
                  )}
                </div>
                <div className="px-2 text-truncate">
                  {selectedQuestion.attachment.name}
                </div>
              </div>
            </>
          )}

          {quizSubmissionAnswer.attachment && (
            <>
              <div className="text-dark mt-4">Student Attachment</div>
              <div
                className="d-flex align-items-center cursor-pointer rounded mt-2"
                style={{ maxWidth: 250, backgroundColor: "#ececec" }}
                onClick={() =>
                  this.downloadFile(quizSubmissionAnswer.attachment)
                }
              >
                <div className="p-3 bg-brand rounded">
                  {quizSubmissionAnswer.attachment.name.endsWith(".pdf") ? (
                    <img
                      src={require("../../../assets/images/assignment/PDF.png")}
                      alt="Attachment"
                      style={{ height: 25, width: 25 }}
                    />
                  ) : quizSubmissionAnswer.attachment.name.endsWith(".png") ? (
                    <img
                      src={require("../../../assets/images/assignment/PNG.png")}
                      alt="Attachment"
                      style={{ height: 25, width: 25 }}
                    />
                  ) : quizSubmissionAnswer.attachment.name.endsWith(".jpg") ||
                    quizSubmissionAnswer.attachment.name.endsWith(".jpeg") ? (
                    <img
                      src={require("../../../assets/images/assignment/JPG.png")}
                      alt="Attachment"
                      style={{ height: 25, width: 25 }}
                    />
                  ) : (
                    <img
                      src={require("../../../assets/images/file.png")}
                      alt="Attachment"
                      style={{ height: 25, width: 25 }}
                    />
                  )}
                </div>
                <div className="px-2 text-truncate">
                  {quizSubmissionAnswer.attachment.name}
                </div>
              </div>
            </>
          )}
        </div>

        <h5 className="text-brand mt-5 mb-3">Grade</h5>
        <div className="d-flex align-items-center justify-content-start">
          <div
            className={`rounded-pill d-flex align-items-center cursor-pointer mr-2 ${
              quizSubmissionAnswer.gradedStatus === "CORRECT"
                ? "bg-brand"
                : "border border-muted"
            }`}
            style={{
              border: "1px solid transparent",
              padding: 4,
              transition: "150ms all ease-in",
            }}
            onClick={() =>
              this.setCurrentQuestion({
                quizSubmissionAnswers: [
                  {
                    ...quizSubmissionAnswer,
                    gradedStatus: "CORRECT",
                    mark: selectedQuestion.mark,
                  },
                ],
              })
            }
          >
            <div
              className={`${
                quizSubmissionAnswer.gradedStatus === "CORRECT"
                  ? "text-light"
                  : "text-muted"
              } rounded-pill d-flex`}
              style={{
                height: 30,
                width: 30,
                backgroundColor:
                  quizSubmissionAnswer.gradedStatus === "CORRECT"
                    ? "#04b191"
                    : "#fff",
              }}
            >
              <i className="fas fa-check m-auto" />
            </div>
            <div
              className={`px-2 ${
                quizSubmissionAnswer.gradedStatus === "CORRECT"
                  ? "text-light"
                  : "text-muted"
              }`}
            >
              Correct
            </div>
          </div>

          <div
            className={`rounded-pill d-flex align-items-center cursor-pointer mr-2 ${
              quizSubmissionAnswer.gradedStatus === "WRONG"
                ? "bg-brand"
                : "border border-muted"
            }`}
            style={{
              border: "1px solid transparent",
              padding: 4,
              transition: "150ms all ease-in",
            }}
            onClick={() =>
              this.setCurrentQuestion({
                quizSubmissionAnswers: [
                  {
                    ...quizSubmissionAnswer,
                    gradedStatus: "WRONG",
                    mark: 0,
                  },
                ],
              })
            }
          >
            <div
              className={`${
                quizSubmissionAnswer.gradedStatus === "WRONG"
                  ? "text-light"
                  : "text-muted"
              } rounded-pill d-flex`}
              style={{
                height: 30,
                width: 30,
                backgroundColor:
                  quizSubmissionAnswer.gradedStatus === "WRONG"
                    ? "#ed365c"
                    : "#fff",
              }}
            >
              <i className="fas fa-times m-auto" />
            </div>
            <div
              className={`px-2 ${
                quizSubmissionAnswer.gradedStatus === "WRONG"
                  ? "text-light"
                  : "text-muted"
              }`}
            >
              Wrong
            </div>
          </div>

          <div
            className={`rounded-pill d-flex align-items-center cursor-pointer mr-2 ${
              quizSubmissionAnswer.gradedStatus === "PARTIAL_CORRECT"
                ? "bg-brand"
                : "border border-muted"
            }`}
            style={{
              border: "1px solid transparent",
              padding: 4,
              transition: "150ms all ease-in",
            }}
            onClick={() =>
              this.setCurrentQuestion({
                quizSubmissionAnswers: [
                  {
                    ...quizSubmissionAnswer,
                    gradedStatus: "PARTIAL_CORRECT",
                    mark: 1,
                  },
                ],
              })
            }
          >
            <div
              className={`${
                quizSubmissionAnswer.gradedStatus === "PARTIAL_CORRECT"
                  ? "text-light"
                  : "text-muted"
              } rounded-pill d-flex`}
              style={{
                height: 30,
                width: 30,
                backgroundColor:
                  quizSubmissionAnswer.gradedStatus === "PARTIAL_CORRECT"
                    ? "#e5a025"
                    : "#fff",
              }}
            >
              <i className="fas fa-check m-auto" />
            </div>
            <div
              className={`px-2 ${
                quizSubmissionAnswer.gradedStatus === "PARTIAL_CORRECT"
                  ? "text-light"
                  : "text-muted"
              }`}
            >
              Partial
            </div>
          </div>

          {quizSubmissionAnswer.gradedStatus === "PARTIAL_CORRECT" && (
            <div
              className={`border px-4 py-1 d-flex align-items-center ml-4 ${
                quizSubmissionAnswer.mark < 0 ||
                quizSubmissionAnswer.mark > selectedQuestion.mark
                  ? "border-danger"
                  : "border-muted"
              }`}
              style={{ borderRadius: 12 }}
            >
              <input
                type="number"
                className={`form-control `}
                value={quizSubmissionAnswer.mark}
                onChange={e =>
                  this.setCurrentQuestion({
                    quizSubmissionAnswers: [
                      { ...quizSubmissionAnswer, mark: e.target.valueAsNumber },
                    ],
                  })
                }
                style={{
                  border: 0,
                  padding: 0,
                  width: 34,
                  textAlign: "center",
                  fontSize: "1.1rem",
                  height: "1.2rem",
                }}
              />
              <span className="text-dark" style={{ fontSize: "1.2rem" }}>
                {" "}
                / {selectedQuestion.mark}
              </span>
            </div>
          )}
        </div>

        <h5 className="text-brand mt-4 mb-2">Comments</h5>
        <textarea
          className="form-control"
          rows={5}
          placeholder="Write some comment..."
          value={quizSubmissionAnswer.comment || ""}
          onChange={e =>
            this.setCurrentQuestion({
              quizSubmissionAnswers: [
                {
                  ...quizSubmissionAnswer,
                  comment: e.target.value,
                },
              ],
            })
          }
        >
          {selectedQuestion.comment || ""}
        </textarea>
      </div>
    )
  }

  renderDetailsPanel = () => {
    const selectedStudent = this.state.assignedStudents[
      this.state.currentStudent
    ]
    if (!selectedStudent) return null

    const submission = this.state.studentSubmissions[selectedStudent.id]

    let stats = {
      CORRECT: 0,
      PARTIAL_CORRECT: 0,
      WRONG: 0,
      UNGRADED: 0,
    }

    if (submission) {
      for (let section of submission.quizSections) {
        for (let que of section.quizQuestions) {
          stats[
            (que.quizSubmissionAnswers &&
              que.quizSubmissionAnswers[0] &&
              que.quizSubmissionAnswers[0].gradedStatus) ||
              "UNGRADED"
          ]++
        }
      }
    }

    return (
      <div className="col-md-4 mt-2">
        <div className="bg-brand rounded d-flex py-2 px-2">
          <div
            className="bg-green rounded-pill text-light d-flex mx-2 cursor-pointer"
            style={{ width: 20, height: 20 }}
            onClick={() => {
              if (!this.state.isLoadingStudent) {
                this.setState(
                  {
                    currentSection: 0,
                    currentQuestion: 0,
                    isLoadingStudent: true,
                  },
                  () => {
                    this.loadStudent(this.state.currentStudent - 1)
                  }
                )
              }
            }}
          >
            {this.state.isLoadingStudent ? (
              <HashLoader
                sizeUnit={"px"}
                size={16}
                color={"#fff"}
                loading={this.state.isLoadingStudent}
              />
            ) : (
              <i className="fas fa-chevron-left m-auto" />
            )}
          </div>
          <div className="text-light text-center" style={{ flex: 1 }}>
            Switch Student
          </div>
          <div
            className="bg-green rounded-pill text-light d-flex mx-2 cursor-pointer"
            style={{ width: 20, height: 20 }}
            onClick={() => {
              if (!this.state.isLoadingStudent) {
                this.setState(
                  {
                    currentSection: 0,
                    currentQuestion: 0,
                    isLoadingStudent: true,
                  },
                  () => {
                    this.onSubmit()
                    this.loadStudent(this.state.currentStudent + 1)
                    let assignedStudents = this.state.assignedStudents
                    if (assignedStudents[this.state.currentStudent].submitted) {
                      assignedStudents[this.state.currentStudent].graded = true
                      this.setState({ assignedStudents })
                    }
                  }
                )
              }
            }}
          >
            {this.state.isLoadingStudent ? (
              <HashLoader
                sizeUnit={"px"}
                size={16}
                color={"#fff"}
                loading={this.state.isLoadingStudent}
              />
            ) : (
              <i className="fas fa-chevron-right m-auto" />
            )}
          </div>
        </div>

        <div
          className="px-4 py-2 d-flex flex-column mt-3"
          style={{
            borderRadius: 12,
            border: "1px solid #eaeaea",
            backgroundColor: "#f7f7f7",
          }}
        >
          <UncontrolledDropdown>
            <DropdownToggle
              tag="div"
              className="d-flex align-items-center cursor-pointer"
            >
              {selectedStudent.student ? (
                <img
                  src={
                    selectedStudent.student.gender === "M"
                      ? require("../../../assets/images/boy.png")
                      : require("../../../assets/images/girl.png")
                  }
                  style={{ width: 38, height: 38, borderRadius: 40 }}
                  alt=""
                />
              ) : (
                ""
              )}

              <div className="mx-2" style={{ flex: 1 }}>
                <div className="text-brand mb-05">
                  {selectedStudent.student
                    ? selectedStudent.student.fullName
                    : ""}
                </div>
                <div className="text-brand">
                  {selectedStudent.student
                    ? selectedStudent.student.registerRollNumber
                    : ""}{" "}
                  - {selectedStudent.submitted ? "Submitted" : "Not Submitted"}
                </div>
              </div>
              <i className="fas fa-caret-down text-brand p-1" />
            </DropdownToggle>
            <DropdownMenu style={{ height: 400, overflowY: "scroll" }}>
              {this.state.assignedStudents.map((assign, index) => (
                <DropdownItem
                  key={assign.id}
                  tag="div"
                  className="d-flex align-items-center"
                  style={{
                    minWidth: "calc(30vw - 3rem - 15px)",
                    backgroundColor:
                      this.state.currentStudent === index && "rgba(0,0,0,0.1)",
                  }}
                  onClick={() => {
                    this.onSubmit()
                    this.loadStudent(index)
                  }}
                >
                  {assign.student ? (
                    <img
                      src={
                        assign.student.gender === "M"
                          ? require("../../../assets/images/boy.png")
                          : require("../../../assets/images/girl.png")
                      }
                      style={{ width: 38, height: 38, borderRadius: 40 }}
                      alt=""
                    />
                  ) : (
                    ""
                  )}

                  <div className="mx-2" style={{ flex: 1 }}>
                    <div className="text-brand mb-05">
                      {assign.student ? assign.student.fullName : ""}
                    </div>
                    <div className="text-brand">
                      {assign.studen ? assign.student.registerRollNumber : ""} -{" "}
                      {assign.submitted ? "Submitted" : "Not Submitted"}
                    </div>
                  </div>
                  {assign.graded ? (
                    <div className="my-auto text-light text-uppercase rounded mr-auto px-2 py-05 bg-green">
                      Graded
                    </div>
                  ) : (
                    <div
                      className="my-auto text-light text-uppercase rounded mr-auto px-2 py-05"
                      style={{ backgroundColor: "#ed365c" }}
                    >
                      Ungraded
                    </div>
                  )}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>

          {selectedStudent.submitted && submission ? (
            <>
              {selectedStudent.graded ? (
                <div className="my-2 text-light text-uppercase rounded mr-auto px-2 py-05 bg-green">
                  Graded
                </div>
              ) : (
                <div
                  className="my-2 text-light text-uppercase rounded mr-auto px-2 py-05"
                  style={{ backgroundColor: "#ed365c" }}
                >
                  Ungraded
                </div>
              )}

              <div className="my-2 d-flex text-brand align-items-end font-weight-bold">
                <p className="m-0">Total Marks</p>
                <h4 className="text-brand m-0 mx-2">
                  {this.calculateStudentMarks()}
                </h4>
                <p className="m-0">out of</p>
                <h4 className="text-brand m-0 mx-2">{submission.totalMark}</h4>
              </div>

              <div className="my-1 text-muted">
                {moment(selectedStudent.submittedAt).format(
                  "DD MMM YYYY, hh:mm A"
                )}
              </div>

              <div className="mb-2 text-muted">
                Time Taken: {selectedStudent.timeTaken}
              </div>

              {!selectedStudent.retake && (
                <button
                  className="btn bg-brand text-light btn-block py-1 d-flex align-items-center justify-content-center"
                  style={{ borderRadius: 8 }}
                  disabled={this.state.isRetakeLoading}
                  onClick={() => this.setState({ isRetakeModalOpen: true })}
                >
                  {this.state.isRetakeLoading ? (
                    <HashLoader
                      sizeUnit={"px"}
                      size={18}
                      color={"#fff"}
                      loading={true}
                    />
                  ) : (
                    "Retake Quiz"
                  )}
                </button>
              )}

              <div
                className="py-2 px-4 d-flex flex-column"
                style={{
                  margin: "1rem -1.5rem",
                  borderTop: "1px solid #eaeaea",
                }}
              >
                <div className="d-flex align-items-center mb-2">
                  {submission.hasSection ? (
                    <h5 className="text-brand mr-auto mb-0">
                      {submission.quizSections[this.state.currentSection].title}{" "}
                      ({this.calcTotalMarks(this.state.currentSection)} Marks)
                    </h5>
                  ) : (
                    <h5 className="text-brand mr-auto mb-0">Questions</h5>
                  )}
                </div>

                <div className="mt-1 d-flex flex-wrap align-items-center m-auto">
                  {submission.quizSections[
                    this.state.currentSection
                  ].quizQuestions.map((que, index) =>
                    this.renderQuestionIcon(
                      this.calcQueNumber(
                        this.state.currentSection,
                        index,
                        submission.quizSections
                      ),
                      (que.quizSubmissionAnswers &&
                        que.quizSubmissionAnswers[0] &&
                        que.quizSubmissionAnswers[0].gradedStatus) ||
                        "UNGRADED",
                      index === this.state.currentQuestion,
                      () => this.setState({ currentQuestion: index })
                    )
                  )}
                </div>
              </div>
            </>
          ) : null}

          <h5 className="text-brand mr-auto mt-3">Legends & Stats</h5>

          <div className="my-1">
            <div className="row">
              <div className="col d-flex align-items-center">
                {this.renderQuestionIcon(stats.CORRECT, "CORRECT", false)}
                <p className="m-0">Correct Answer</p>
              </div>

              <div className="col d-flex align-items-center">
                {this.renderQuestionIcon(stats.WRONG, "WRONG", false)}
                <p className="m-0">Wrong Answer</p>
              </div>
            </div>

            <div className="row">
              <div className="col d-flex align-items-center">
                {this.renderQuestionIcon(
                  stats.PARTIAL_CORRECT,
                  "PARTIAL_CORRECT",
                  false
                )}
                <p className="m-0">Partial Answer</p>
              </div>

              <div className="col d-flex align-items-center">
                {this.renderQuestionIcon(stats.UNGRADED, "UNGRADED", false)}
                <p className="m-0">Ungraded</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  retakeModal = () => (
    <Modal
      isOpen={this.state.isRetakeModalOpen}
      backdropClassName="modal-backdrop-show"
      modalClassName="modal-dialog-shadow-none"
      contentClassName="border-14px"
    >
      <div className="modal-body p-0">
        <header
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            padding: "15px 0 0 24px",
          }}
        >
          Select Retest Date
        </header>
        <div className="form-group px-4 col-sm-12">
          <div className="pt-3 pl-4" style={{ backgroundColor: "aliceblue" }}>
            <FormGroup style={{ marginRight: "10px", height: "11vh" }}>
              <Label className="mb-1 text-brand font-weight-bold" htmlFor="">
                Retake Date & Time
                <i
                  className="ml-2 fas fa-question-circle text-muted"
                  id="dateTimeTooltip"
                />
              </Label>
              <UncontrolledTooltip placement="top" target="dateTimeTooltip">
                Date & Time is the period where the retake test will be open.
              </UncontrolledTooltip>
              <div className={`d-flex form-control ${this.state.endTime}`}>
                <RangePicker
                  showTime={{ user12hours: true }}
                  bordered={false}
                  // style={{ width: "100%" }}
                  value={[this.state.startTime, this.state.endTime]}
                  onChange={value => {
                    const [startTime, endTime] = value || [moment(), moment()]
                    this.setState({
                      startTime: startTime,
                      endTime: endTime,
                    })
                  }}
                  format="DD MMM YYYY, hh:mm A"
                  disabledDate={current =>
                    current && current < moment().startOf("day")
                  }
                />
              </div>
            </FormGroup>
          </div>
        </div>
        <div className="col-md-12 pb-3 text-right">
          <Button
            style={{
              backgroundColor: "#d7d7d7",
              color: "#8d8d8d",
              width: "6rem",
              fontSize: "15px",
              borderRadius: "11px",
              borderStyle: "none",
              marginRight: "15px",
            }}
            onClick={() => this.setState({ isRetakeModalOpen: false })}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: "#06b191",
              width: "6rem",
              fontSize: "15px",
              borderRadius: "11px",
              borderStyle: "none",
              marginRight: "15px",
            }}
            onClick={() =>
              this.setState({ isRetakeModalOpen: false }, () => this.onRetake())
            }
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
    //   <ModalTransition>
    //     {this.state.isRetakeModalOpen && (
    //       <Modal
    //         heading="Retake Quiz"
    //         actions={[
    //           {
    //             text: "Done",
    //             onClick: () =>
    //               this.setState({ isRetakeModalOpen: false }, () =>
    //                 this.onRetake()
    //               ),
    //           },
    //           {
    //             text: "Cancel",
    //             onClick: () => this.setState({ isRetakeModalOpen: false }),
    //           },
    //         ]}
    //       >
    //         <div className="form-group px-2 col-sm-8">
    //           <div className="w-100">
    //             <FormGroup>
    //               <Label className="mb-1 text-brand font-weight-bold" htmlFor="">
    //                 Retake Date & Time
    //                 <i
    //                   className="ml-2 fas fa-question-circle text-muted"
    //                   id="dateTimeTooltip"
    //                 />
    //               </Label>
    //               <UncontrolledTooltip placement="top" target="dateTimeTooltip">
    //                 Date & Time is the period where the retake test will be open.
    //               </UncontrolledTooltip>
    //               <div className={`d-flex form-control ${this.state.endTime}`}>
    //                 <RangePicker
    //                   showTime={{ user12hours: true }}
    //                   bordered={false}
    //                   style={{ width: "100%" }}
    //                   value={[this.state.startTime, this.state.endTime]}
    //                   onChange={value => {
    //                     const [startTime, endTime] = value || [moment(), moment()]
    //                     this.setState({
    //                       startTime: startTime,
    //                       endTime: endTime,
    //                     })
    //                   }}
    //                   format="DD MMM YYYY, hh:mm A"
    //                   disabledDate={current =>
    //                     current && current < moment().startOf("day")
    //                   }
    //                 />
    //               </div>
    //             </FormGroup>
    //           </div>
    //         </div>
    //       </Modal>
    //     )}
    //   </ModalTransition>
  )

  renderInstructionModal = () => (
    <Modal
      backdropClassName="modal-backdrop-show"
      modalClassName="modal-dialog-shadow-none"
      contentClassName="border-14px"
      isOpen={this.state.isInstructionModalOpen}
    >
      <div className="modal-body p-0">
        <header
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            padding: "15px 0 0 24px",
          }}
        >
          Instructions
        </header>
        <p
          style={{
            height: "22vh",
            resize: "height",
            overflow: "auto",
            padding: "15px 20px 0 24px",
          }}
        >
          {this.state.instructions}
        </p>
        <div className="col-md-12 pb-3 text-right">
          <Button
            onClick={() => this.setState({ isInstructionModalOpen: false })}
            style={{
              backgroundColor: "#d7d7d7",
              color: "#8d8d8d",
              width: "6rem",
              fontSize: "15px",
              borderRadius: "11px",
              borderStyle: "none",
            }}
          >
            Close
          </Button>
        </div>
      </div>{" "}
    </Modal>
    //   <ModalTransition>
    //     {this.state.isInstructionModalOpen && (
    //       <Modal
    //         heading="Instructions"
    //         actions={[
    //           {
    //             text: "Close",
    //             onClick: () => this.setState({ isInstructionModalOpen: false }),
    //           },
    //         ]}
    //       >
    //         <div className="form-group px-2 col-sm-8">
    //           <p>{this.state.instructions}</p>
    //         </div>
    //       </Modal>
    //     )}
    //   </ModalTransition>
  )

  render() {
    const { isSubmitting, isLoadingStudent } = this.state
    return (
      <>
        {this.retakeModal()}
        {this.renderInstructionModal()}
        <AppWrapper
          teacherDashboard
          showTeacherThree
          showTeacherLogo
          screenName={"TESTS"}
        >
          {!isSubmitting && (
            <div
              style={{
                zIndex: "9999999",
                color: "rgb(35, 132, 123)",
                backgroundColor: "rgba(6, 177, 145, 0.19)",
                position: "fixed",
                top: "20px",
                left: "45%",
              }}
            >
              <span>Saving...</span>
            </div>
          )}
          <div className="d-flex mb-2 align-items-center">
            <h4 className="text-brand font-weight-bold m-0">
              Quiz - Evaluation
            </h4>
            <button
              className="btn mx-3 my-1 py-1 px-3 text-green ml-auto"
              style={{ borderRadius: 12, backgroundColor: "#e8eaea" }}
              disabled={isSubmitting || isLoadingStudent}
              onClick={() => {
                this.onSubmit(true)
              }}
            >
              {isSubmitting ? (
                <HashLoader
                  sizeUnit={"px"}
                  size={16}
                  color={"#fff"}
                  loading={isSubmitting}
                />
              ) : (
                "Close"
              )}
            </button>
          </div>

          {this.state.assignedStudents.length !== 0 && (
            <div className="row mb-4">
              {this.renderQuestionItem()}

              {this.renderDetailsPanel()}
            </div>
          )}
        </AppWrapper>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getStudentQuizSubmission,
      saveStudentQuizSubmission,
      retakeStudentQuizSubmission,
    },
    dispatch
  )
}
export default connect(mapStateToProps, dispatchToProps)(MarkEvaluationScreen)
