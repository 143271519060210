/*************************************************
 * RedInk
 * @exports
 * @class NewsEventsAction.js
 * @extends Component
 * @author Kishore // on 18/07/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { showNotification } from "../CommonAction"
import {
  SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"
import { ACTION_TYPES } from "../types"

export const saveNewsListInStore = newsList => {
  return {
    type: ACTION_TYPES.ADMIN_NEWS_LIST,
    payload: newsList,
  }
}

export const saveEventListInStore = eventList => {
  return {
    type: ACTION_TYPES.ADMIN_EVENTS_LIST,
    payload: eventList,
  }
}
export const getNewsDetails = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_NEWS_DETAILS, null, true)
      if (response) {
        myLog("====getNewsDetails RESPONSE===::::", response)
        dispatch(saveNewsListInStore(response.newsDetails))
        callBack(true, response.newsDetails)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getNewsDetails ERROR===????", error)
    }
  }
}

export const getEventsDetails = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_EVENTS_DETAILS, null, true)
      if (response) {
        myLog("====getEventsDetails RESPONSE===::::", response)
        dispatch(saveEventListInStore(response.eventDetails))
        callBack(true, response.eventDetails)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getEventsDetails ERROR===????", error)
    }
  }
}

export const saveNewsDetails = (body, callBack) => {
  myLog("NEWS MODAL OBJECT ->", body)
  return async dispatch => {
    try {
      const response = await Client.post(URL.SAVE_NEWS_DETAILS, body, true)
      if (response.responseCode === 0) {
        myLog("====saveNewsDetails RESPONSE===::::", response)
        dispatch(getNewsDetails(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveNewsDetails ERROR===????", error)
    }
  }
}

export const saveEventsDetails = (body, callBack) => {
  myLog("EVENTS MODAL OBJECT ->", body)
  return async dispatch => {
    try {
      const response = await Client.post(URL.SAVE_EVENTS_DETAILS, body, true)
      if (response.responseCode === 0) {
        myLog("====saveNewsDetails RESPONSE===::::", response)
        dispatch(getEventsDetails(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveEventsDetails ERROR===????", error)
    }
  }
}

export const deleteNewsDetails = (body, callBack) => {
  myLog(body)
  return async dispatch => {
    try {
      const response = await Client.delete(URL.DELETE_NEWS_DETAILS, body, true)
      if (response.responseCode === 0) {
        myLog("====deletenewsDetails RESPONSE===::::", response)
        dispatch(getNewsDetails(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deletenewsDetails ERROR===????", error)
    }
  }
}

export const deleteEventsDetails = (body, callBack) => {
  myLog(body)
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.DELETE_EVENTS_DETAILS,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteNewsDetails RESPONSE===::::", response)
        dispatch(getEventsDetails(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteNewsDetails ERROR===????", error)
    }
  }
}
