/*************************************************
 * Redink
 * @exports
 * @file index.js
 * @author Sasidharan // on 19/12/2019
 * @copyright © 2019 redink. All rights reserved.
 *************************************************/
import { combineReducers } from "redux"
import { loginReducer } from "./LoginReducer"
import { subjectDetailsReducer } from "./superAdmin/SubjectDetailsReducer"
import { classDetailsReducer } from "./superAdmin/ClassDetailsReducer"
import { teacherDetailsReducer } from "./superAdmin/TeacherDetailsReducer"
import { takeAttendanceReducer } from "./teacher/TakeAttendanceReducer"
import { attendanceReportReducer } from "./teacher/AttendanceReportReducer"
import { studentDatabaseReducer } from "./teacher/StudentDatabaseReducer"
import { hierarchyReducer } from "./teacher/HierarchyReducer"
import { attendanceDetailsReducer } from "./superAdmin/AttendanceDetailsReducer"
import { adminAttendanceDetailsReducer } from "./admin/AttendanceDetailsReducer"
import { markUploadStatusDetailsReducer } from "./admin/MarkUploadStatusReducer"
import { performanceReportDetailsReducer } from "./superAdmin/PerformanceReportReducer"
import { adminPerformanceReportDetailsReducer } from "./admin/PerformanceReportReducer"
import { adminAcademicYearDetailsReducer } from "./admin/AcademicYearDetailsReducer"
import { markScreenReducer } from "./teacher/MarkScreenReducer"
import { quickAnalyticsReducer } from "./teacher/QuickAnalyticsReducer"
import { performanceReportReducer } from "./teacher/PerformanceReportReducer"
import { teacherSubjectAllotmentReducer } from "./teacher/SubjectAllotmentReducer"
import { gradeDetailsReducer } from "./admin/GradeDetailsReducer"
import { communicationDetailsReducer } from "./superAdmin/CommunicationDetailsReducer"
import { schoolDetailsReducer } from "./superAdmin/SchoolDetailsReducer"
import { teacherDashboardReducer } from "./teacher/DashboardReducer"
import { adminDashboardReducer } from "./admin/DashboardReducer"
import { adminTermDetailsReducer } from "./admin/TermDetailsReducer"
import { adminNewsEventsReducer } from "./admin/NewsEventsReducer"
import { teacherSubjectDetailsReducer } from "./teacher/SubjectDetailsReducer"
import { quizReducer } from "./teacher/QuizReducer"
import { founderDetailsReducer } from "./founders/foundersDetailsReducer"
import { parentAssignmentReducer } from "./parent/AssignmentReducer"
// import { subjectDetailsReducer } from "./superAdmin/SubjectDetailsReducerReducer";
import { founderQuestionBankReducer } from "./founders/questionBankReducer"
import { founderBehaviourReducer } from "./founders/behaviourDetailsReducer"

export default combineReducers({
  loginState: loginReducer,
  subjectDetailsState: subjectDetailsReducer,
  classDetailsState: classDetailsReducer,
  teacherDetailsState: teacherDetailsReducer,
  takeAttendanceState: takeAttendanceReducer,
  attendanceReportState: attendanceReportReducer,
  studentDatabaseState: studentDatabaseReducer,
  hierarchyState: hierarchyReducer,
  attendanceDetailsState: attendanceDetailsReducer,
  adminAttendanceDetailsState: adminAttendanceDetailsReducer,
  markUploadStatusDetailsState: markUploadStatusDetailsReducer,
  performanceReportDetailsState: performanceReportDetailsReducer,
  adminPerformanceReportDetailsState: adminPerformanceReportDetailsReducer,
  adminAcademicYearDetailsState: adminAcademicYearDetailsReducer,
  markScreenState: markScreenReducer,
  quickAnalyticsState: quickAnalyticsReducer,
  performanceReportState: performanceReportReducer,
  gradeDetailsState: gradeDetailsReducer,
  communicationDetailsState: communicationDetailsReducer,
  schoolDetailsState: schoolDetailsReducer,
  teacherDashboardState: teacherDashboardReducer,
  adminDashboardReducer: adminDashboardReducer,
  adminTermDetailsState: adminTermDetailsReducer,
  adminNewsEventsState: adminNewsEventsReducer,
  teacherSubjectAllotmentState: teacherSubjectAllotmentReducer,
  teacherSubjectDetailsState: teacherSubjectDetailsReducer,
  founderDetails: founderDetailsReducer,
  quiz: quizReducer,
  parentAssignmentState: parentAssignmentReducer,
  founderQuestionBankState: founderQuestionBankReducer,
  founderBehaviourState: founderBehaviourReducer,
})
