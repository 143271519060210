/*************************************************
 * RedInk
 * @exports
 * @class subjectDetailsReducer.js
 * @extends Component
 * @author Vijayakumar // on 18/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from "../../actions/types"
import { myLog } from "../../utils/Utility"

const INTIAL_STATE = {
  attendanceDetails: {},
}

export const attendanceDetailsReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.ATTENDANCE_DETAIL:
      myLog("payload", action.payload)
      return {
        ...state,
        attendanceDetails: { ...action.payload },
      }
    default:
      return state
  }
}
