/*************************************************
 * RedInk
 * @exports
 * @class subjectDetailsReducer.js
 * @extends Component
 * @author Vijayakumar // on 18/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from "../../actions/types"
import { myLog } from "../../utils/Utility"

const INTIAL_STATE = {
  AcademicYearDetail: [],
  AssessmentDetail: [],
  OnboardingAcademicYearList: [],
}

export const adminAcademicYearDetailsReducer = (
  state = INTIAL_STATE,
  action
) => {
  switch (action.type) {
    case ACTION_TYPES.ADMIN_ACADEMIC_YEAR_LIST:
      myLog("payload", action.payload)
      return {
        ...state,
        AcademicYearDetail: [...action.payload],
      }
    case ACTION_TYPES.ADMIN_ASSESSMENT_LIST:
      myLog("payload", action.payload)
      return {
        ...state,
        AssessmentDetail: [...action.payload],
      }
    case ACTION_TYPES.ADMIN_ONBOARDING_ACADEMIC_YEAR_LIST:
      myLog("payload", action.payload)
      return {
        ...state,
        OnboardingAcademicYearList: [...action.payload],
      }
    default:
      return state
  }
}
