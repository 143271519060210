/*************************************************
 * RedInk
 * @exports
 * @class index.js
 * @extends Component
 * @author Sasidharan // on 01/02/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import AppWrapper from "./Wrapper"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import malePic from "../../assets/images/men.png"

class ProfileScreen extends Component {
  constructor(props) {
    super(props)
    document.title = "Profile | Redink"
    this.state = {}
  }

  componentDidMount = () => {}

  render() {
    return (
      <AppWrapper founderDashboard>
        <div className="row">
          <div className="col-sm-3">
            <div className="cardone bg-white p-3 shadow profile">
              <img src={malePic} className="img1" alt="file" />
              <br />
              &emsp;
              <p>Nicolas Henry</p>
              <p>LA,USE</p>
              <p>Greeky Tech</p>
            </div>
          </div>
          <div className="col-sm-9 ">
            <div className="card bg-white p-3 shadow profile">
              <h5 className="myskills">
                <b>Personal Details</b>
              </h5>
              <br />
              <form className="row personaldetails">
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label htmlFor="firstname">FIRST NAME :</label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstname"
                    placeholder="First Name"
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label htmlFor="lastname">LAST NAME :</label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastname"
                    placeholder="Last Name"
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label htmlFor="department">DEPARTMENT :</label>
                  <input
                    type="text"
                    className="form-control"
                    id="department"
                    placeholder="Department"
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label htmlFor="email">EMAIL ADDRESS :</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Email@123"
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label htmlFor="password">NEW PASSWORD :</label>
                  <input
                    type="Password"
                    className="form-control"
                    id="password"
                    placeholder="Password"
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label htmlFor="confirmpassword">CONFIRM PASSWORD :</label>
                  <input
                    type="Password"
                    className="form-control"
                    id="confirmpassword"
                    placeholder="Password"
                  />
                </div>
              </form>
            </div>
          </div>

          {/* <div className="col-sm-6 skills">
            <div className="cardone bg-white p-3 shadow">
              <h3 className="myskills">
                <b>My Skills</b>
              </h3>
              <br />
              <div className="sliders">
                <div className="form-group">
                  <label htmlFor="formControlRange1">Computer Science</label>
                  <input
                    type="range"
                    className="form-control-range"
                    id="formControlRange1"
                  />
                  <h5 className="perform">Beginner Intermediate Master</h5>
                </div>
                <div className="form-group">
                  <label htmlFor="formControlRange2">Tamil</label>
                  <input
                    type="range"
                    className="form-control-range"
                    id="formControlRange2"
                  />
                  <h5 className="perform">Beginner Intermediate Master</h5>
                </div>
                <div className="form-group">
                  <label htmlFor="formControlRange3">English</label>
                  <input
                    type="range"
                    className="form-control-range"
                    id="formControlRange3"
                  />
                  <h5 className="perform">Beginner Intermediate Master</h5>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="col-sm-6 myclass">
            <div className="cardone bg-white p-3 shadow">
              <h3 className="myskills">
                <b>My Classes</b>
              </h3>
              <br />
              <div className="content">
                <div className="card-deck">
                  <div className="card1 p-2 rounded mb-5 shadow profile">
                    <p className="text-center">12-B &emsp; MATHS </p>
                    <p className="text-cenetr">Class &emsp; Subject</p>
                  </div>
                  &emsp;
                  <div className="card2 p-2 rounded mb-5 shadow profile">
                    <p className="text-center">11-B &emsp; MATHS </p>
                    <p className="text-cenetr">Class &emsp; Subject</p>
                  </div>
                  &emsp;
                  <div className="card1 p-2 rounded mb-5 shadow profile">
                    <p className="text-center">12-C &emsp; MATHS </p>
                    <p className="text-cenetr">Class &emsp; Subject</p>
                  </div>
                  &emsp;
                  <div className="card2 p-2 rounded mb-5 shadow profile">
                    <p className="text-center">11-C &emsp; MATHS </p>
                    <p className="text-cenetr">Class &emsp; Subject</p>
                  </div>
                  &emsp;
                  <div className="card1 p-2 rounded mb-5 shadow profile">
                    <p className="text-center">12-A &emsp; MATHS </p>
                    <p className="text-cenetr">Class &emsp; Subject</p>
                  </div>
                  &emsp;
                  <div className="card2 p-2 rounded mb-5 shadow profile">
                    <p className="text-center">11-A &emsp; MATHS </p>
                    <p className="text-cenetr">Class &emsp; Subject</p>
                  </div>
                  &emsp;
                  <div className="card1 p-2 rounded mb-5 shadow profile">
                    <p className="text-center">12-D &emsp; MATHS </p>
                    <p className="text-cenetr">Class &emsp; Subject</p>
                  </div>
                  &emsp;
                  <div className="card2 p-2 rounded mb-5 shadow profile">
                    <p className="text-center">11-D &emsp; MATHS </p>
                    <p className="text-cenetr">Class &emsp; Subject</p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, dispatchToProps)(ProfileScreen)
