/*************************************************
 * RedInk
 * @exports
 * @class 404.js
 * @extends Component
 * @author Sasidharan // on 17/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React from "react"
import { Link } from "react-router-dom"

import logo from "../../assets/images/404.svg"

const NotFoundPage = () => (
  <>
    <div id="notfound">
      <div className="notfound">
        <img alt="RedInk" src={logo} className="notFoundLogo" />
        <Link to="/" className="mt-5 w-50">
          Go To Homepage
        </Link>
      </div>
    </div>
  </>
)

export default NotFoundPage
