import React from "react"
import { Modal } from "reactstrap"
import { HashLoader } from "react-spinners"

import useConditions from "../useConditions"

// import { ReactComponent as Download } from "../../../assets/images/download.svg"
// import { ReactComponent as Print } from "../../../assets/images/print.svg"
import { ReactComponent as Close } from "../../../../assets/images/close.svg"
import Template from "../Template"

function Preview({ data, isOpen, updateShowPreview }) {
  const { marks, terms, isLoading, conditions, schoolDetails } = useConditions(
    data.id
  )

  return (
    <Modal
      isOpen={isOpen}
      backdropClassName="modal-backdrop-show"
      modalClassName="modal-dialog-shadow-none"
      contentClassName="report-modal-wrapper p-4"
      centered
      size="lg"
    >
      <div className="d-flex align-items-center">
        <h1 className="report-modal-wrapper-h1 mb-0 fw-600">
          Class {data.className} report card template
        </h1>

        <Close
          className="report-modal-close-icon ml-auto"
          onClick={() => updateShowPreview(false, {})}
        />
      </div>

      {isLoading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "400px" }}
        >
          <HashLoader size={30} color="#084353" sizeUnit="px" loading />
        </div>
      ) : (
        <>
          <div className="bg-F8F8F8 report-preview-modal-center my-4 py-3">
            <Template
              {...conditions}
              {...schoolDetails}
              marks={marks}
              terms={terms}
              className={data.className}
            />
          </div>

          {/* <div className="report-preview-modal-btns-wrapper d-flex align-items-center justify-content-center gap-2rem">
            <button className="report-preview-modal-print-btn d-flex align-items-center justify-content-center px-4 py-2 fs-15 fw-600 bg-bcbdc030 clr-909091 br-8px">
              <Print className="mr-2" />
              Print
            </button>

            <button className="report-preview-modal-download-btn d-flex align-items-center justify-content-center px-4 py-2 fs-15 fw-600 bg-5DD1AD text-white br-8px">
              <Download className="mr-2" />
              Download
            </button>
          </div> */}
        </>
      )}
    </Modal>
  )
}

export default Preview
