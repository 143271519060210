import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import { showNotification } from "../CommonAction"
import {
  SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"
import { ACTION_TYPES } from "../types"
import URL from "../../config"

export const saveBehaviourDetailsInState = behaviourList => {
  return {
    type: ACTION_TYPES.FOUNDER_BEHAVIOUR_LIST,
    payload: behaviourList,
  }
}

export const viewBehaviourDatabase = behaviourViewList => {
  return {
    type: ACTION_TYPES.FOUNDER_VIEW_LIST,
    payload: behaviourViewList,
  }
}

export const getAllBehaviourRecognitionDetails = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_ADMIN_ALLRECOGNITIONS,
        null,
        true,
        true
      )
      if (response) {
        myLog("====getAllBehaviourDetails RESPONSE===::::", response)
        const soretedDta = response.data.sort((a, b) =>
          a.recognitionName.localeCompare(b.recognitionName)
        )

        dispatch(viewBehaviourDatabase(soretedDta))
        callBack(true, soretedDta)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getAllBehaviourDetails ERROR===????", error)
    }
  }
}

export const deleteBehaviourRecognition = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.DELETE_ADMIN_ALLRECOGNITIONS + "/" + body,
        null,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteStudentBehaviour RESPONSE===::::", response)
        dispatch(getAllBehaviourRecognitionDetails(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      // dispatch(
      //   showNotification(
      //     DANGER_TOAST,
      //     VALIDATION_MSG.OOPS,
      //     "Something went wrong..."
      //   )
      // )
      callBack(false)
      myLog("====deleteStudentBehaviour ERROR===????", error)
    }
  }
}

export const saveBehaviourRecognition = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.post(
        URL.SAVE_ADMIN_RECOGNITION,
        body,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====saveStudentDatabaseDetails RESPONSE===::::", response)
        dispatch(getAllBehaviourRecognitionDetails(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====saveStudentDatabaseDetails ERROR===????", error)
    }
  }
}
