/*************************************************
 * RedInk
 * @exports
 * @class QuestionBankActions.js
 * @extends Component
 * @author Mahesh // on 02/05/2021
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { ACTION_TYPES } from "../types"
import { showNotification } from "../CommonAction"
import {
  SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"

// Upload file to

export const uploadQuestionBank = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      let formData = new FormData()
      formData.append("file", body.file)
      formData.append("selectedSubject", body.selectedSubject)
      formData.append("selectedBoard", body.selectedBoard)
      formData.append("selectedClass", body.selectedClass)
      if (body.selectedChapter) {
        formData.append("selectedChapter", body.selectedChapter)
      }
      if (body.selectedTopic) {
        formData.append("selectedTopic", body.selectedTopic)
      }
      myLog("Form Data", formData)
      const response = await Client.post(
        URL.UPLOAD_QUESTION_BANK_DATA,
        formData,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====uploadQuestionBank RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====uploadQuestionBank ERROR===????", error)
    }
  }
}

// Get Master Subjects
export const getMasterSubjects = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_MASTER_SUBJECTS,
        null,
        true,
        true
      )
      if (response) {
        myLog("====getMasterSubjects RESPONSE===::::", response)
        if (callBack) callBack(true, response)
        dispatch({
          type: ACTION_TYPES.MASTER_SUBJECTS,
          payload: response,
        })
      } else {
        if (callBack) callBack(false)
      }
    } catch (error) {
      if (callBack) callBack(false)
      myLog("====getMasterSubjects ERROR===????", error)
    }
  }
}

// Get Chapter Topics
export const getChapterTopics = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_CHAPTERS_TOPICS,
        object,
        true,
        true
      )
      if (response) {
        // myLog("====getChapterTopics RESPONSE===::::", response)
        if (callBack) callBack(true, response)
        dispatch({
          type: ACTION_TYPES.FOUNDER_CHAPTERS_TOPICS,
          payload: response,
        })
      } else {
        if (callBack) callBack(false)
      }
    } catch (error) {
      if (callBack) callBack(false)
      myLog("====getChapterTopics ERROR===????", error)
    }
  }
}

export const getChapterTopicsFilter = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_CHAPTERS_TOPICS,
        object,
        true,
        true
      )
      if (response) {
        // myLog("====getChapterTopics RESPONSE===::::", response)
        if (callBack) callBack(true, response)
        // dispatch({
        //   type: ACTION_TYPES.FOUNDER_CHAPTERS_TOPICS,
        //   payload: response,
        // })
      } else {
        if (callBack) callBack(false)
      }
    } catch (error) {
      if (callBack) callBack(false)
      myLog("====getChapterTopics ERROR===????", error)
    }
  }
}

export const getQuestionsAnswersFilter = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_QUESTIONS_DATA,
        object,
        true,
        true
      )
      if (response) {
        myLog("====getQuestionsAnswers RESPONSE===::::", response)
        if (callBack) callBack(true, response)
        dispatch({
          type: ACTION_TYPES.FOUNDER_QUESTIONS_DATA_FILTER,
          payload: response,
        })
      } else {
        if (callBack) callBack(false)
      }
    } catch (error) {
      if (callBack) callBack(false)
      myLog("====getQuestionsAnswers ERROR===????", error)
    }
  }
}

// Get Questions Answers Data
export const getQuestionsAnswers = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_QUESTIONS_DATA,
        object,
        true,
        true
      )
      if (response) {
        myLog("====getQuestionsAnswers RESPONSE===::::", response)
        if (callBack) callBack(true, response)
        dispatch({
          type: ACTION_TYPES.FOUNDER_QUESTIONS_DATA,
          payload: response,
        })
      } else {
        if (callBack) callBack(false)
      }
    } catch (error) {
      if (callBack) callBack(false)
      myLog("====getQuestionsAnswers ERROR===????", error)
    }
  }
}

// Delete Selected Chapters
export const deleteChapters = (object, callBack) => {
  return async dispatch => {
    try {
      myLog("object", object)
      const response = await Client.delete(
        URL.DELETE_CHAPTERS,
        object,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteChapters RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      if (callBack) callBack(false)
      myLog("====deleteChapters ERROR===????", error)
    }
  }
}

// Delete Selected Topics
export const deleteTopics = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.DELETE_TOPICS,
        object,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteTopics RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      if (callBack) callBack(false)
      myLog("====deleteTopics ERROR===????", error)
    }
  }
}

// Delete Selected Questions
export const deleteQuestions = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.DELETE_QUESTIONS,
        object,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteTopics RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      if (callBack) callBack(false)
      myLog("====deleteTopics ERROR===????", error)
    }
  }
}

export const updateChaptersTopics = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      const response = await Client.patch(
        URL.UPDATE_CHAPTERS_TOPICS,
        body,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====updateChaptersTopics RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====updateChaptersTopics ERROR===????", error)
    }
  }
}

export const updateQuestionsAnswers = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      const response = await Client.patch(
        URL.UPDATE_QUESTIONS_ANSWERS,
        body,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====updateQuestionAnswer RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====updateQuestionAnswer ERROR===????", error)
    }
  }
}
