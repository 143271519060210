/*************************************************
 * RedInk
 * @exports
 * @class Dashboard.js
 * @extends Component
 * @author Sasidharan // on 11/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import AppWrapper from "../Wrapper"
import { connect } from "react-redux"
import { DatePicker } from "antd"
import { bindActionCreators } from "redux"
import boyPic from "../../../assets/images/boy.png"
import girlPic from "../../../assets/images/girl.png"
import { URL_PATH } from "../../../config/urlPath"
import {
  getSingleStudentAssignmentDetails,
  updateSingleStudentAssignment,
  getStudentSubmissionWorkFile,
  getAssignmentAssignedDetails,
  getAssignmentDetails,
} from "../../../actions/teacher/AssignmentAction"
import { HashLoader } from "react-spinners"
import { PDFViewer } from "react-view-pdf"
import { DANGER_TOAST, VALIDATION_MSG } from "../../../utils/Constants"
import {
  showNotification,
  getFile,
  uploadFile,
} from "../../../actions/CommonAction"
import moment from "moment"
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap"
// import Modal, { ModalTransition } from "@atlaskit/modal-dialog"
import { myLog, parseNameFromLocation } from "../../../utils/Utility"
import Skeleton from "react-loading-skeleton"
import { Modal as BSModal } from "reactstrap"
import WebViewer from "@pdftron/webviewer"
import saveButton from "../../../assets/images/assignment/save.svg"

class AssignmentViewDetails extends Component {
  constructor(props) {
    super(props)
    this.pdfEditModeViewer = React.createRef(null)
    this.state = {
      isUpdateDurationOpen: false,
      currentAssignment: 0,
      studentAssignments: [],
      isLoading: true,
      isShowSaveLoading: false,
      isShowSaveReworkLoading: false,
      isSubmitting: false,
      isPdfEditMode: false,
      attachmentUrl: "",
      ...this.initialState,
    }
  }

  componentDidMount() {
    const {
      currentAssignmentIndex,
      studentAssignments,
    } = this.props.location.state
    myLog("componentDidMount", studentAssignments[currentAssignmentIndex])
    const assignmentId =
      studentAssignments[currentAssignmentIndex].assignmentDetailId
    this.setState(
      { currentAssignmentIndex, studentAssignments, assignmentId },
      () => {
        this.loadAssignment(currentAssignmentIndex)
      }
    )
  }

  loadAssignment = index => {
    const studentAssignmentId = this.state.studentAssignments[index].id
    this.setState(
      { ...this.initialState, isLoading: true, currentAssignmentIndex: index },
      () => {
        this.props.getSingleStudentAssignmentDetails(
          studentAssignmentId,
          (success, response) => {
            myLog(
              "this.props.getSingleStudentAssignmentDetails",
              success,
              response
            )
            if (success) {
              this.setAssignmentState(response.data)
              this.setState({
                fileUrl: null,
                fileName: null,
                file: null,
                prevFileUrl: null,
              })
            }
          }
        )
      }
    )
  }

  getAssignmentDetail = () => {
    this.props.getAssignmentDetails(
      this.state.assignmentId,
      (success, response) => {
        this.setState({ isShowLoading: false })
        if (success) {
          this.setState({
            assignmentDetails: response.assignment,
          })
          this.props.getAssignmentAssignedDetails(
            this.state.assignmentId,
            (success1, response1) => {
              this.setState({
                // assignmentDetails: response.assignment,
                // assignmentDetailCount: response1.count,
                // studentAssignments: response1.assignedStudents,
                studentAssignments: response1.assignedStudents,
              })
            }
          )
        }
      }
    )
  }

  initialState = {
    title: "",
    mark: "",
    remark: "",
    maxMark: "",
    submitStatus: "",
    approvalStatus: "",
    durationTo: "",
    submittedOn: "",
    onTimeSubmission: "",
    student: "",
    serverState: {
      approvalStatus: "",
      previousMark: "",
      durationTo: "",
    },
    fileUrl: "",
    fileName: "",
    file: null,
  }

  setAssignmentState = async data => {
    const {
      id,
      mark,
      remark,
      assignmentDetail,
      submitStatus,
      approvalStatus,
      durationTo,
      submittedOn,
      workFile,
      prevWorkFile,
      previousMark,
      onTimeSubmission,
      student,
    } = data
    const { title, maxMark } = assignmentDetail || {}
    const serverState = {
      approvalStatus,
      previousMark,
      durationTo: moment(durationTo),
    }
    this.setState({
      title,
      mark,
      remark,
      maxMark,
      submitStatus,
      approvalStatus,
      durationTo: moment(durationTo),
      submittedOn,
      onTimeSubmission,
      student,
      serverState,
    })
    if (submitStatus !== "TODO" && submitStatus !== "OVERDUE" && workFile) {
      /* this api is going to be removed */
      /* should be connected with file download api => // workFile -> file // or prevWorkFile -> file */
      this.getAssignmentSubmissionFile(id)
    } else {
      this.setState({ isLoading: false })
    }
    if (prevWorkFile && !workFile) {
      this.setState({ isLoading: true, showWorkFile: false })
      const { url, name } = await this.getSubmissionFile(prevWorkFile)

      this.makeRequest("GET", url)
        .then(datums => {
          const file = new Blob([datums], {
            type: "application/pdf",
          })

          const browserUrl = window.webkitURL || window.URL
          const fileUrl = browserUrl.createObjectURL(file)
          this.setState({
            prevFileName: name,
            prevFileUrl: url,
            prevFile: fileUrl,
          })
          this.setState({ isLoading: false })
        })
        .catch(err => {
          console.error("Augh, there was an error!", err)
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            "Pdf not found. Ask student to Reupload..."
          )
          this.setState({ isLoading: false })
        })
    }
  }

  getSubmissionFile = path => {
    return new Promise((resolve, reject) => {
      this.props.getFile(path, (success, response) => {
        if (success) {
          const { url, name, size } = response
          resolve({ url, name, size })
        } else {
          reject()
        }
      })
    })
  }

  getAssignmentSubmissionFile = studentAssignmentId => {
    this.props.getStudentSubmissionWorkFile(
      studentAssignmentId,
      (success, response) => {
        if (success) {
          const { url, name: fileName } = response
          this.makeRequest("GET", url)
            .then(datums => {
              const file = new Blob([datums])

              const browserUrl = window.webkitURL || window.URL
              const fileUrl = browserUrl.createObjectURL(file)
              this.setState({
                fileUrl,
                fileName,
                file,
              })
              this.setState({ isLoading: false })
            })
            .catch(err => {
              console.error("Augh, there was an error!", err)
              this.props.showNotification(
                DANGER_TOAST,
                VALIDATION_MSG.OOPS,
                "Pdf not found. Ask student to Reupload..."
              )
              this.setState({ isLoading: false })
            })
        }
      }
    )
  }

  makeRequest = (method, url) => {
    return new Promise(function(resolve, reject) {
      var xhr = new XMLHttpRequest()
      xhr.open(method, url, true)
      xhr.responseType = "blob"
      xhr.onload = function() {
        if (this.status >= 200 && this.status < 300) {
          resolve(xhr.response)
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText,
          })
        }
      }
      xhr.onerror = function() {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        })
      }
      xhr.send(null)
    })
  }

  saveStudentAssignment = callFrom => {
    const {
      durationTo,
      currentAssignmentIndex,
      mark,
      remark,
      approvalStatus,
      maxMark,
      attachmentUrl,
    } = this.state
    const studentAssignmentId = this.state.studentAssignments[
      currentAssignmentIndex
    ].id

    const errorHandling = msg =>
      this.props.showNotification(DANGER_TOAST, VALIDATION_MSG.OOPS, msg)

    if (!approvalStatus) {
      return errorHandling("Select approval status...")
    }
    if (approvalStatus === "APPROVED" && maxMark && !mark) {
      return errorHandling("Enter marks...")
    }
    if (approvalStatus && maxMark && mark && mark > maxMark) {
      return errorHandling("Marks entered are greater than maximum marks")
    }
    if (approvalStatus === "REWORK" && !durationTo) {
      return errorHandling("Select rework date...")
    }

    this.setState(
      callFrom === "Rework"
        ? { isShowSaveReworkLoading: true }
        : { isShowSaveLoading: true },
      () => {
        const submittedOn = moment.utc(moment()).format()
        const body = { remark, approvalStatus, submittedOn, attachmentUrl }
        if (maxMark) {
          body.mark = mark
        }
        if (approvalStatus === "REWORK") {
          body.durationTo = moment.utc(moment(durationTo).endOf("day")).format()
          // body.durationTo = moment(durationTo)
          //   .endOf("day")
          //   .format("YYYY-MM-DD HH:mm:ss")
        }
        this.props.updateSingleStudentAssignment(
          studentAssignmentId,
          body,
          (success, response) => {
            if (success) {
              this.getAssignmentDetail()
              this.loadAssignment(currentAssignmentIndex)
              this.setAssignmentState(response.data)
            }
            this.setState(
              callFrom === "Rework"
                ? { isShowSaveReworkLoading: false, isPdfEditMode: false }
                : { isShowSaveLoading: false }
            )
          }
        )
      }
    )
  }

  renderSideBar = () => {
    const {
      currentAssignmentIndex,
      studentAssignments,
      mark,
      remark,
      maxMark,
      approvalStatus,
      durationTo,
      submittedOn,
      serverState,
      student,
      submitStatus,
      onTimeSubmission,
    } = this.state
    const { gender, studentName, fullName, registerRollNumber } = student || {}
    const currentAssignment = studentAssignments[currentAssignmentIndex]
    if (!currentAssignment) {
      return null
    }
    myLog(
      "studentAssignments",
      this.state.studentAssignments.length,
      currentAssignmentIndex
    )
    return (
      <div className="col-md-4 mt-2">
        <div className="bg-brand rounded d-flex py-2 px-2">
          <div
            className={`rounded-pill text-light d-flex mx-2 cursor-pointer ${
              currentAssignmentIndex === 0 ? "disabled" : "bg-green"
            }`}
            style={{ width: 20, height: 20 }}
            onClick={() => {
              if (currentAssignmentIndex === 0) {
                return
              } else {
                this.setState({ isPdfEditMode: false }, () =>
                  this.loadAssignment(currentAssignmentIndex - 1)
                )
              }
            }}
          >
            <i className="fas fa-chevron-left m-auto" />
          </div>
          <div className="text-light text-center" style={{ flex: 1 }}>
            Switch Student
          </div>
          <div
            className={`rounded-pill text-light d-flex mx-2 cursor-pointer ${
              this.state.studentAssignments.length - 1 ===
              currentAssignmentIndex
                ? "disabled"
                : "bg-green"
            }`}
            style={{ width: 20, height: 20 }}
            onClick={() => {
              if (currentAssignmentIndex === studentAssignments.length - 1) {
                return
              } else {
                this.setState({ isPdfEditMode: false }, () =>
                  this.loadAssignment(currentAssignmentIndex + 1)
                )
              }
            }}
          >
            <i className="fas fa-chevron-right m-auto" />
          </div>
        </div>
        <div
          className="px-4 py-2 d-flex flex-column mt-3"
          style={{
            borderRadius: 12,
            border: "1px solid #eaeaea",
            backgroundColor: "#f7f7f7",
          }}
        >
          <UncontrolledDropdown>
            <DropdownToggle
              tag="div"
              className="d-flex align-items-center cursor-pointer"
            >
              {gender && (
                <img
                  src={gender === "M" ? boyPic : girlPic}
                  style={{ width: 38, height: 38, borderRadius: 40 }}
                  alt=""
                />
              )}
              <div className="mx-2" style={{ flex: 1 }}>
                <div className="text-brand mb-05">
                  {studentName || fullName}
                </div>
                <div className="text-brand">{registerRollNumber}</div>
              </div>
              <i className="fas fa-caret-down text-brand p-1" />
            </DropdownToggle>
            <DropdownMenu style={{ height: 400, overflowY: "scroll" }}>
              {studentAssignments.map((assign, index) => {
                const { gender, studentName, fullName, registerRollNumber } =
                  assign.student || {}
                return (
                  <DropdownItem
                    key={assign.id}
                    tag="div"
                    className="d-flex align-items-center"
                    style={{
                      minWidth: "calc(30vw - 3rem - 15px)",
                      backgroundColor:
                        currentAssignmentIndex === index && "rgba(0,0,0,0.1)",
                    }}
                    onClick={() => this.loadAssignment(index)}
                  >
                    <img
                      src={gender === "M" ? boyPic : girlPic}
                      style={{
                        width: 38,
                        height: 38,
                        borderRadius: 40,
                      }}
                      alt=""
                    />
                    <div className="mx-2" style={{ flex: 1 }}>
                      <div className="text-brand mb-05">
                        {studentName || fullName}
                      </div>
                      <div className="text-brand">{registerRollNumber}</div>
                    </div>
                    {assign.approvalStatus === "APPROVED" ? (
                      <div className="my-auto text-light text-uppercase rounded mr-auto px-2 py-05 bg-green">
                        Graded
                      </div>
                    ) : (
                      <div
                        className="my-auto text-light text-uppercase rounded mr-auto px-2 py-05"
                        style={{ backgroundColor: "#ed365c" }}
                      >
                        Ungraded
                      </div>
                    )}
                  </DropdownItem>
                )
              })}
            </DropdownMenu>
          </UncontrolledDropdown>
          <>
            {serverState.approvalStatus === "APPROVED" ? (
              <div className="my-2 text-light text-uppercase rounded mr-auto px-2 py-05 bg-green">
                Graded
              </div>
            ) : (
              <div
                className="my-2 text-light text-uppercase rounded mr-auto px-2 py-05"
                style={{ backgroundColor: "#F06783" }}
              >
                Ungraded
              </div>
            )}
            <div>
              {onTimeSubmission === "ONTIME" && "On Time Submission"}
              {onTimeSubmission === "LATE" && "Late Submission"}
              {onTimeSubmission === "MISSING" && "Missing"}
              {onTimeSubmission === "UNSUBMITTED" && "Not Submitted"}
            </div>
            <div className="text-muted fs-12 my-3">
              {submitStatus === "COMPLETED" &&
                submitStatus === "INREVIEW" &&
                (onTimeSubmission === "ONTIME" ||
                  onTimeSubmission === "LATE") &&
                submittedOn &&
                `Submitted On ${moment(submittedOn).format(
                  "DD MMM YYYY, HH:MM A"
                )}`}
            </div>

            {maxMark ? (
              <>
                <div className="my-3 d-flex text-brand align-items-end font-weight-bold">
                  <span>Mark</span>
                  <input
                    type="number"
                    required
                    className={`form-control text-brand m-0 mx-2 ${mark &&
                      maxMark &&
                      mark > maxMark &&
                      "border-danger"}`}
                    style={{ width: 60, borderRadius: 8 }}
                    value={mark !== null ? mark : ""}
                    onChange={e => {
                      const value = e.target.valueAsNumber
                      if (isNaN(value)) {
                        this.setState({ mark: "" })
                      } else {
                        this.setState({ mark: value })
                      }
                    }}
                  />
                  <span>out of</span>
                  <h4 className="text-brand m-0 mx-2">{maxMark}</h4>
                </div>
                {serverState.approvalStatus === "REWORK" ? (
                  <div className="my-3 text-secondary">
                    <span>Previous Marks</span>
                    <h4 className="text-brand m-0 mx-2">
                      {serverState.previousMark} / {maxMark}
                    </h4>
                  </div>
                ) : null}
              </>
            ) : null}
            <div className="mt-3">
              <p className="text-brand font-weight-bold m-0">Remarks</p>
              <textarea
                className="form-control"
                rows={4}
                style={{ borderRadius: 8 }}
                value={remark || ""}
                onChange={e => {
                  const value = e.target.value
                  this.setState({ remark: value })
                }}
              >
                {remark || ""}
              </textarea>
            </div>
            <div className="mt-4 pl-2">
              <div className="d-flex justify-content-center">
                <button
                  className="d-flex mx-3 px-3 py-2 align-items-center justify-content-center"
                  style={{
                    backgroundColor:
                      approvalStatus === "APPROVED" ? "#06B191" : "#D3E8ED",
                    color: approvalStatus === "APPROVED" ? "#fff" : "#094353",
                    borderRadius: "8px",
                    width: "120px",
                  }}
                  disabled={this.state.isShowSaveLoading}
                  onClick={() => {
                    this.setState({ approvalStatus: "APPROVED" }, () =>
                      this.saveStudentAssignment("Approved")
                    )
                  }}
                >
                  {this.state.isShowSaveLoading ? (
                    <HashLoader
                      sizeUnit={"px"}
                      size={16}
                      color={"#ffffff"}
                      loading={this.state.isShowSaveLoading}
                    />
                  ) : (
                    <>
                      <i
                        className="far fa-check-circle"
                        aria-hidden="true"
                        style={{
                          fontSize: "0.9rem",
                          color: "inherit",
                        }}
                      ></i>
                      <div className="ml-3 font-weight-bold">Approve</div>
                    </>
                  )}
                </button>
                <button
                  className="d-flex mx-3 px-3 py-2 ml-2 align-items-center justify-content-center"
                  style={{
                    backgroundColor:
                      approvalStatus === "REWORK" ? "#D89E2A" : "#D3E8ED",
                    color: approvalStatus === "REWORK" ? "#fff" : "#094353",
                    borderRadius: "8px",
                    width: "120px",
                  }}
                  title={
                    approvalStatus === "REWORK"
                      ? moment(durationTo).format("DD MMM YYYY HH:mm")
                      : ""
                  }
                  disabled={this.state.isShowSaveReworkLoading}
                  onClick={() => {
                    this.setState({
                      isUpdateDurationOpen: true,
                      approvalStatus: "REWORK",
                    })
                  }}
                >
                  {this.state.isShowSaveReworkLoading ? (
                    <HashLoader
                      sizeUnit={"px"}
                      size={16}
                      color={"#ffffff"}
                      loading={this.state.isShowSaveReworkLoading}
                    />
                  ) : (
                    <>
                      <i
                        className="fas fa-redo"
                        aria-hidden="true"
                        style={{ color: "inherit", fontSize: "0.9rem" }}
                      ></i>
                      <div className="ml-2 font-weight-bold">Rework</div>
                    </>
                  )}
                </button>
              </div>
              {/* <div className="d-flex mt-3 justify-content-center">
                <button
                  className="bg-brand text-light py-1 d-flex align-items-center justify-content-center"
                  style={{
                    borderRadius: "8px",
                    width: "242px",
                  }}
                  disabled={this.state.isShowSaveLoading}
                  onClick={() => {
                  }}
                >
                  {this.state.isShowSaveLoading ? (
                    <HashLoader
                      sizeUnit={"px"}
                      size={16}
                      color={"#ffffff"}
                      loading={this.state.isShowSaveLoading}
                    />
                  ) : (
                    "Done"
                  )}
                </button>
              </div> */}
            </div>
          </>
        </div>
      </div>
    )
  }

  onClickPdfEditMode = () => {
    WebViewer(
      {
        path: "/webviewer/lib",
        initialDoc: this.state.fileUrl,
      },
      this.pdfEditModeViewer.current
    )
      .then(instance => {
        const { documentViewer, annotationManager } = instance.Core
        // Add header button that will get file data on click
        instance.setHeaderItems(header => {
          header.push({
            type: "actionButton",
            img: saveButton,
            onClick: async () => {
              const doc = documentViewer.getDocument()
              const xfdfString = await annotationManager.exportAnnotations()
              let data = await doc.getFileData({
                // saves the document with annotations in it
                xfdfString,
              })
              const arr = new Uint8Array(data)
              // const arr = new Uint8Array(data);
              let blob = new Blob([arr], { type: "application/pdf" })
              // const browserUrl = window.webkitURL || window.URL
              // const url = browserUrl.createObjectURL(blob);
              // window.open(url);
              blob.name = this.state.fileName
              const upload = await new Promise(resolve =>
                this.props.uploadFile(
                  blob,
                  "assignment/rework",

                  (success, response) => resolve({ success, response })
                )
              )
              if (upload.success) {
                this.setState({ attachmentUrl: upload.response.url })
              }
            },
          })
        })
      })
      .catch(err => myLog(`err`, err))
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.isPdfEditMode !== this.state.isPdfEditMode &&
      this.state.isPdfEditMode
    ) {
      this.onClickPdfEditMode()
    }
  }
  render() {
    const {
      // durationTo,
      serverState,
      title,
      fileUrl,
      fileName,
      prevFileUrl,
      prevFile,
      prevFileName,
      isPdfEditMode,
      isLoading,
      // attachmentUrl,
    } = this.state

    // const unConfirmSubmit = !mark && !approvalStatus
    // const currentAssignment = studentAssignments[currentAssignmentIndex]

    return (
      <>
        {/* <ModalTransition>
          {this.state.isUpdateDurationOpen && (
            <Modal
              heading="Update Duration"
              actions={[
                {
                  text: "Done",
                  onClick: () => {
                    if (
                      durationTo &&
                      moment(durationTo)
                        .endOf("day")
                        .isSameOrAfter(
                          moment(serverState.durationTo).endOf("day")
                        )
                    ) {
                      this.setState({ isUpdateDurationOpen: false }, () =>
                        this.saveStudentAssignment('Rework')
                      )
                    } else {
                      this.props.showNotification(
                        DANGER_TOAST,
                        VALIDATION_MSG.OOPS,
                        `Please set due date later than or same as ${moment(
                          serverState.durationTo
                        ).format("DD MMM YYYY")}...`
                      )
                    }
                  },
                },
              ]}
            >
              <div className="form-group  px-2 col-sm-8">
                <label htmlFor="durationTo" className="font-weight-bold">
                  Due Date
                </label>
                <input
                  type="date"
                  className="form-control br-10"
                  name="durationTo"
                  required
                  value={moment(durationTo).format("YYYY-MM-DD")}
                  onChange={e => {
                    const value = e.target.value
                    this.setState({ durationTo: value })
                  }}
                />
              </div>
              <p className="text-muted">
                <span className="text-brand">Note: </span>
                Previous marks will be considered if student fails to resubmit
                the work
              </p>
            </Modal>
          )}
        </ModalTransition> */}
        <BSModal
          isOpen={this.state.isUpdateDurationOpen}
          backdropClassName="modal-backdrop-show"
          modalClassName="modal-dialog-shadow-none"
          contentClassName="border-14px"
          centered
          size="md"
        >
          <div className="px-3 pt-2 " style={{ borderTopRightRadius: 14 }}>
            <div style={{ fontSize: 24 }}>Update Duration</div>
          </div>
          <div className="px-2 mt-4">
            <div className="form-group  px-2 col-sm-8 text-brand">
              <label htmlFor="durationTo" className="font-weight-bold">
                Due Date
              </label>
              <DatePicker
                className="form-control"
                placeholder="Select date"
                value={this.state.durationTo}
                format="DD MMM YYYY"
                size="large"
                onSelect={e => {
                  this.setState({ durationTo: e })
                }}
                disabledDate={current =>
                  current && current < moment().startOf("day")
                }
              />
            </div>
            <div className="d-flex justify-content-end my-4 mr-2">
              <button
                className="bg-brand px-3 py-1 text-white"
                style={{ borderRadius: 5 }}
                onClick={() => {
                  if (
                    this.state.durationTo &&
                    moment(this.state.durationTo)
                      .endOf("day")
                      .isSameOrAfter(
                        moment(serverState.durationTo).endOf("day")
                      )
                  ) {
                    this.setState({ isUpdateDurationOpen: false }, () =>
                      this.saveStudentAssignment()
                    )
                  } else {
                    this.props.showNotification(
                      DANGER_TOAST,
                      VALIDATION_MSG.OOPS,
                      `Please set due date later than or same as ${moment(
                        serverState.durationTo
                      ).format("DD MMM YYYY")}...`
                    )
                  }
                }}
              >
                Done
              </button>
              <button
                className="px-3 py-1 ml-2"
                style={{ borderRadius: 5, backgroundColor: "#d8d8d8" }}
                onClick={() => {
                  this.setState({
                    isUpdateDurationOpen: false,
                    approvalStatus: "",
                  })
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </BSModal>
        <AppWrapper
          teacherDashboard
          showTeacherThree
          showTeacherLogo
          screenName={"Assignment Student Details"}
        >
          <div>
            <h5 className="font-weight-bold mb-2 text-dark">
              {title ? `Title: ${title}` : <Skeleton width={200} />}
            </h5>
            <div className="d-flex mb-2 align-items-center">
              <h4 className="text-brand font-weight-bold m-0">
                Assignment - Evaluation
              </h4>
              <button
                className="btn mx-3 my-1 py-1 px-3 text-green ml-auto"
                style={{ borderRadius: 12, backgroundColor: "#e8eaea" }}
                disabled={this.state.isSubmitting}
                onClick={() => {
                  this.props.history.push({
                    pathname: URL_PATH.TEACHER_ASSIGNMENT_DETAILS,
                    state: {
                      assignmentId: this.state.assignmentId,
                    },
                  })
                }}
              >
                {this.state.isSubmitting ? (
                  <HashLoader
                    sizeUnit={"px"}
                    size={16}
                    color={"#fff"}
                    loading={this.state.isSubmitting}
                  />
                ) : (
                  "Close"
                )}
              </button>
            </div>

            <div className="row">
              <div className="col-md-8">
                {isLoading ? (
                  <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: 150 }}
                  >
                    <HashLoader
                      sizeUnit={"px"}
                      size={35}
                      color={"#084353"}
                      loading={isLoading}
                    />
                  </div>
                ) : !fileUrl ? (
                  prevFileUrl ? (
                    <div style={{ position: "relative", marginTop: "2rem" }}>
                      <PDFViewer
                        url={prevFile}
                        onLoadError={err => {}}
                        style={{ width: "34rem" }}
                      />
                      <i
                        className="far fa-download cursor-pointer"
                        style={{
                          fontSize: "25px",
                          position: "absolute",
                          top: 18,
                          right: 18,
                        }}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Download File"
                        onClick={() => {
                          const a = document.createElement("a")
                          a.href = prevFileUrl
                          a.download = parseNameFromLocation(prevFileName)
                          a.click()
                        }}
                      ></i>
                      {!this.state.showWorkFile && (
                        <div
                          className="d-flex"
                          style={{
                            color: "#fff",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: "rgba(0,0,0,0.7)",
                          }}
                        >
                          <div className="m-auto">
                            This is ORIGINAL file, rework not submitted yet.{" "}
                            <span
                              className="cursor-pointer text-green"
                              onClick={() => {
                                this.setState({ showWorkFile: true })
                              }}
                            >
                              <u>View work</u>
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <h4
                      className="text-muted text-center"
                      style={{ marginTop: 150 }}
                    >
                      No file Submitted
                    </h4>
                  )
                ) : (
                  <div style={{ position: "relative", marginTop: "2rem" }}>
                    {fileName.endsWith(".pdf") && (
                      <>
                        {isPdfEditMode ? (
                          <>
                            <i
                              className="far fa-arrow-alt-circle-left cursor-pointer"
                              style={{
                                fontSize: "32px",
                                position: "absolute",
                                top: "-40px",
                              }}
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Back Default View"
                              onClick={() => {
                                this.setState({ isPdfEditMode: false })
                              }}
                            ></i>
                            <div
                              ref={this.pdfEditModeViewer}
                              style={{ width: "58rem", height: "50rem" }}
                            ></div>
                          </>
                        ) : (
                          <PDFViewer
                            url={fileUrl}
                            onLoadError={err => {}}
                            style={{ width: "34rem" }}
                          />
                        )}
                        {!isPdfEditMode && (
                          <i
                            className="far fa-edit cursor-pointer"
                            style={{
                              fontSize: "25px",
                              position: "absolute",
                              top: 18,
                              right: 53,
                            }}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Edit File"
                            onClick={() => {
                              this.setState({ isPdfEditMode: true })
                            }}
                          ></i>
                        )}
                      </>
                    )}
                    {fileName.endsWith(".png") ||
                    fileName.endsWith(".jpg") ||
                    fileName.endsWith(".jpeg") ||
                    fileName.endsWith(".gif") ? (
                      <img
                        style={{ maxWidth: "100%" }}
                        src={fileUrl}
                        onLoadError={err => {}}
                        alt=""
                      />
                    ) : null}
                    {fileName.endsWith(".mp4") && (
                      <video width="100%" height="500" controls>
                        <source src={fileUrl} type="video/mp4" />
                      </video>
                    )}
                    {fileName.endsWith(".mov") && (
                      <video width="100%" height="500" controls>
                        <source src={fileUrl} type="video/mov" />
                      </video>
                    )}
                    {fileName.endsWith("wmv") && (
                      <video width="100%" height="500" controls>
                        <source src={fileUrl} type="video/wmv" />
                      </video>
                    )}
                    {fileName.endsWith("avi") && (
                      <video width="100%" height="500" controls>
                        <source src={fileUrl} type="video/avi" />
                      </video>
                    )}
                    {fileName.endsWith("doc") ||
                    fileName.endsWith("docx") ||
                    fileName.endsWith("ppt") ||
                    fileName.endsWith("pptx") ? (
                      <div
                        className="d-flex px-2 py-4 cursor-pointer"
                        style={{ border: "solid 1px #eeeeee" }}
                        onClick={() => {
                          const a = document.createElement("a")
                          a.href = fileUrl
                          a.download = parseNameFromLocation(fileName)
                          a.click()
                        }}
                      >
                        {fileName}
                      </div>
                    ) : null}
                    {!isPdfEditMode && (
                      <i
                        className="far fa-download cursor-pointer"
                        style={{
                          fontSize: "25px",
                          position: "absolute",
                          top: 18,
                          right: 18,
                        }}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Download File"
                        onClick={() => {
                          const a = document.createElement("a")
                          a.href = fileUrl
                          a.download = parseNameFromLocation(fileName)
                          a.click()
                        }}
                      ></i>
                    )}

                    {/* <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        opacity: 0.8,
                      }}
                    >
                      This is ORIGINAL file, rework not submitted yet. view work
                    </div> */}
                  </div>
                )}
              </div>

              {this.renderSideBar()}
            </div>
          </div>
        </AppWrapper>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getFile,
      uploadFile,
      getSingleStudentAssignmentDetails,
      updateSingleStudentAssignment,
      getStudentSubmissionWorkFile,
      showNotification,
      getAssignmentAssignedDetails,
      getAssignmentDetails,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(AssignmentViewDetails)
