/*************************************************
 * RedInk
 * @exports
 * @class PerformanceReport.js
 * @extends Component
 * @author Sasidharan // on 21/03/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import AppWrapper from "../Wrapper"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip"
import { Progress } from "react-sweet-progress"
import Skeleton from "react-loading-skeleton"
import { bindActionCreators } from "redux"
import { HashLoader } from "react-spinners"
import { connect } from "react-redux"
import { Range } from "react-range"
import Chart from "chart.js"
import _ from "lodash"

import "react-circular-progressbar/dist/styles.css"
import "react-sweet-progress/lib/style.css"

import {
  getSubjectsList,
  getTeacherHierarchySubjectDetails,
  getSubjectPassFailPercentageGraphDetails,
  getGradePerformanceAssessmentByTeacherDetails,
  getClassPerformanceAssessmentMarkByTeacherDetails,
  getStudentsPerformersAssessmentListByTeacherDetails,
} from "../../../actions/teacher/PerfromanceReportAction"
import {
  getExamListDetails,
  getSubjectListByClassDetails,
} from "../../../actions/teacher/HierarchyAction"
import { getClassList } from "../../../actions/teacher/TakeAttendanceAction"
import {
  getStudentPerformanceReportDetails,
  saveDisplayStudentPerformanceReportDetailsInStore,
} from "../../../actions/teacher/QuickAnalyticsAction"
import {
  saveCheckIsGenerateAnalyticsDetail,
  saveStudentSubjectGenerateAnalyticsDetail,
} from "../../../actions/teacher/MarkScreenAction"
import store from "store"

import QuickAnalyticsComponent from "../exams/QuickAnalyticsComponent"
import NoDataFound from "../../../assets/images/NoData.gif"
import MessageModal from "../MessageModal"

let myGraphGrade = null
let myGraphsubjectAverageGrade = null
let myGraphsubjectAverageMarks = null
let myGraphSubjectPassFail = null

class PerformanceReport extends Component {
  static getDerivedStateFromProps(props, state) {
    if (
      props.displayStudentPerformanceReportDetails !==
      state.displayStudentPerformanceReportDetails
    ) {
      return {
        displayStudentPerformanceReportDetails:
          props.displayStudentPerformanceReportDetails,
        sortedStudentsArray: props.displayStudentPerformanceReportDetails,
      }
    }
  }

  constructor(props) {
    document.title = "Performance Report | Redink"

    super(props)
    this.state = {
      isMessageModalOpen: false,
      studentPerformanceRange: [0, 100],
      startSliderValue: 0,
      endSliderValue: 100,
      sliderTotalStudent: 0,
      totalStudent: 0,
      isShowToggleButton:
        store.get("userSession").userInfo.role !== 4 ? true : false,
      isClassTeacher: false,
      isSubjectTeacher: false,
      displayStudentPerformanceReportDetails: [],
      sortedStudentsArray: [],
      assementName: "",
      isShowNoDateFound: false,
      outOffMark: "0",
      sortOrderByNames: 0,
      sortOrderByMarks: 0,
      sortOrderByGrades: 0,
      isShowLoadingStudentPerformance: true,
      selectedSubjectStudentAcademicPerformance: "ALL",
      isShowGenerateAnalyticButton: false,
      isShowLoading: true,
      isShowErrorMessage: false,
      showErrorMessage: "",
    }
  }

  componentDidMount = () => {
    this.props.getExamListDetails((examSuccess, examResponse) => {
      if (examSuccess) {
        this.setState({
          selectedExam: examResponse[0].id,
          selectedExamStudentAcademicPerformance: examResponse[0].id,
          assementName: examResponse[0].value,
          isClassTeacher:
            store.get("userSession").userInfo.role !== 4 ? true : false,
        })
        this.props.getClassList((classSuccess, classResponse) => {
          if (classSuccess) {
            this.setState({
              selectedClassStudentAcademicPerformance: classResponse[0].id,
              selectedClass: classResponse[0].id,
              selectedClassName: classResponse[0].value,
            })
            // this.checkGenerateAnalytics(
            //   examResponse[0].id,
            //   classResponse[0].id
            // )
            this.getAfterGenerateAnalytics(
              examResponse[0].id,
              classResponse[0].id
            )
          }
        })
      } else {
        this.setState({ isShowLoading: false, isShowNoDateFound: true })
      }
    })
  }

  checkGenerateAnalytics = (examResponse, classResponse) => {
    this.setState(
      { isShowLoading: true, isShowErrorMessage: false, showErrorMessage: "" },
      () => {
        let subjectObject = {
          assessmentId: examResponse,
          sectionId: classResponse,
        }
        this.props.saveCheckIsGenerateAnalyticsDetail(
          subjectObject,
          (generateAnalyticSuccess, generateAnalyticResponse) => {
            if (generateAnalyticSuccess) {
              this.setState({
                isShowNoDateFound: false,
                isShowLoading: false,
              })
              if (!generateAnalyticResponse.isAssessmentGenerated) {
                if (generateAnalyticResponse.isSubjectsGenerated) {
                  this.setState({ isShowGenerateAnalyticButton: true })
                } else {
                  this.setState({
                    isShowErrorMessage: true,
                    showErrorMessage: generateAnalyticResponse.responseMessage,
                  })
                }
              } else {
                this.setState({ isShowGenerateAnalyticButton: false })
                // this.getAfterGenerateAnalytics(
                //   examResponse,
                //   classResponse
                // )
                let classObject = {
                  assessmentId: examResponse,
                  sectionId: classResponse,
                }
                this.props.getSubjectListByClassDetails(
                  classObject,
                  (subjectDetailsSuccess, subjectDetailsResponse) => {
                    if (subjectDetailsSuccess) {
                      this.setState({
                        selectedSubjectStudentAcademicPerformance: "ALL",
                      })
                      this.studentPerformanceReportService(
                        examResponse,
                        "ALL",
                        classResponse
                      )
                    }
                  }
                )
                this.getPerformanceReport(examResponse)
              }
            } else {
              this.setState({
                isShowNoDateFound: true,
                isShowLoading: false,
              })
            }
          }
        )
      }
    )
  }

  getAfterGenerateAnalytics = (examResponse, classResponse) => {
    let subjectObject = {
      assessmentId: examResponse,
      sectionId: classResponse,
    }
    this.props.getSubjectsList(
      subjectObject,
      (subjectSuccess, subjectResponse) => {
        if (subjectSuccess) {
          this.setState({
            selectedSubject: subjectResponse[0].id,
            selectedSubjectName: subjectResponse[0].value,
          })
          // let object = {
          //   assessmentId: examResponse,
          //   sectionId: classResponse,
          //   hSubjectId: subjectResponse[0].id,
          // }
          // this.props.getTeacherHierarchySubjectDetails(
          //   object,
          //   (hierarchySubjectSuccess, hierarchySubjectResponse) => {
          //     this.setState(
          //       { hierarchySubjectDetails: hierarchySubjectResponse },
          //       () => {
          //         this.setState({
          //           isSubjectTeacher:
          //             store.get("userSession").userInfo.role === 4
          //               ? true
          //               : false,
          //         })
          //       }
          //     )
          //   }
          // )
        }
      }
    )
    this.checkGenerateAnalytics(examResponse, classResponse)
    // let classObject = {
    //   assessmentId: examResponse,
    //   sectionId: classResponse,
    // }
    // this.props.getSubjectListByClassDetails(
    //   classObject,
    //   (subjectDetailsSuccess, subjectDetailsResponse) => {
    //     if (subjectDetailsSuccess) {
    //       this.setState({
    //         selectedSubjectStudentAcademicPerformance: "ALL",
    //       })
    //       this.studentPerformanceReportService(
    //         examResponse,
    //         "ALL",
    //         classResponse
    //       )
    //     }
    //   }
    // )
    // this.getPerformanceReport(examResponse)
  }

  getPerformanceReport = id => {
    this.setState({ isShowLoading: true }, () => {
      let object = {
        faId: id,
      }
      this.props.getClassPerformanceAssessmentMarkByTeacherDetails(
        object,
        (success, response) => {
          if (success) {
            this.setState(
              { isShowLoading: false, isShowNoDateFound: false },
              () => {
                if (
                  this.state.isClassTeacher &&
                  !this.state.isShowLoading &&
                  !this.state.isShowNoDateFound &&
                  !this.state.isShowGenerateAnalyticButton
                ) {
                  this.showSubjectAverageMarksGraph()
                  this.showSubjectAverageGradeGraph()
                }
              }
            )
            this.props.getGradePerformanceAssessmentByTeacherDetails(
              object,
              () => {
                if (
                  this.state.isClassTeacher &&
                  !this.state.isShowLoading &&
                  !this.state.isShowNoDateFound &&
                  !this.state.isShowGenerateAnalyticButton
                ) {
                  this.showGradeDistributionGraph()
                }
              }
            )
            this.props.getStudentsPerformersAssessmentListByTeacherDetails(
              object,
              () => {}
            )
            this.props.getSubjectPassFailPercentageGraphDetails(object, () => {
              if (
                this.state.isClassTeacher &&
                !this.state.isShowLoading &&
                !this.state.isShowNoDateFound &&
                !this.state.isShowGenerateAnalyticButton
              ) {
                this.showSubjectPassFailPercentageGraph()
              }
            })
          } else {
            this.setState({ isShowLoading: false, isShowNoDateFound: true })
          }
        }
      )
    })
  }

  studentPerformanceReportService = (id, subjectId, classId) => {
    let object = {
      assessmentId: id,
      hSubjectId: subjectId,
      sectionId: classId,
    }
    this.setState({ isShowLoadingStudentPerformance: true }, () => {
      this.props.getStudentPerformanceReportDetails(
        object,
        (success, response) => {
          this.setState({ isShowLoadingStudentPerformance: false })
          if (success)
            this.setState({
              sliderTotalStudent: response.studentRankList.length,
              totalStudent: response.studentRankList.length,
              outOffMark: response.outOffMark,
              absentStudentCount: response.absent,
            })
        }
      )
    })
  }

  sortStudentListBy = (column, state) => {
    this.setState(
      {
        [state]: this.state[state] === 0 ? 1 : this.state[state] === 1 ? -1 : 0,
      },
      () => {
        let sortArray = this.state.displayStudentPerformanceReportDetails
        let newArray = []
        if (this.state[state] !== 0) {
          let sortBy = this.state[state] === -1 ? ["desc"] : ["asc"]
          newArray = _.orderBy(sortArray, [column], sortBy) // Use Lodash to sort array by key
          this.setState({ sortedStudentsArray: newArray })
        } else {
          this.setState({
            sortedStudentsArray: this.state
              .displayStudentPerformanceReportDetails,
          })
        }
      }
    )
  }

  showGradeDistributionGraph = () => {
    if (this.props.gradePerformanceAssessmentObject.gradeGraph) {
      // if (myGraphGrade != null) {
      //   myGraphGrade.destroy()
      // }
      if (this.gradeDistributionGraph) {
        myGraphGrade = new Chart(this.gradeDistributionGraph, {
          type: "bar",
          data: {
            labels: this.props.gradePerformanceAssessmentObject.gradeGraph
              .labels,
            datasets: [
              {
                backgroundColor: this.props.gradePerformanceAssessmentObject
                  .gradeGraph.backgroundColor,
                label: "Count",
                barPercentage: 0.5,
                data: this.props.gradePerformanceAssessmentObject.gradeGraph
                  .data,
              },
            ],
          },
          options: {
            responsive: true,
            legend: {
              display: false,
              position: "bottom",
              labels: {
                fontSize: 10,
                fontColor: "white",
              },
            },
            scales: {
              xAxes: [
                {
                  barPercentage: 0.3,
                  ticks: {
                    fontColor: "#FFFFFF",
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    display: false,
                    beginAtZero: true,
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
            },
          },
        })
      }
    }
  }

  showSubjectAverageMarksGraph = () => {
    // if (myGraphsubjectAverageMarks != null) {
    //   myGraphsubjectAverageMarks.destroy()
    // }
    if (this.subjectAverageMarksGraph) {
      const totalMarks = this.props.subjectAverageMarkPercentageGraphObject.data.reduce(
        (a, b) => a + b,
        0
      )
      const labels = this.props.subjectAverageMarkPercentageGraphObject.labels
      myGraphsubjectAverageMarks = new Chart(this.subjectAverageMarksGraph, {
        type: "polarArea",
        data: {
          labels: this.props.subjectAverageMarkPercentageGraphObject.labels.map(
            item => String(item).slice(0, 3)
          ),
          datasets: [
            {
              label: "Count",
              data: this.props.subjectAverageMarkPercentageGraphObject.data,
              backgroundColor: this.props
                .subjectAverageMarkPercentageGraphObject.backgroundColor,
              fill: false,
              radius: 6,
              borderWidth: 0,
              padding: 5,
              pointRadius: 6,
            },
          ],
        },
        options: {
          responsive: true,
          // onClick: (evt, item) => {
          //   myGraphsubjectAverageMarks.update()
          //   item[0]._model.outerRadius += 10
          // },
          legend: {
            display: true,
            position: "right",
            labels: {
              usePointStyle: true,
              fontSize: 10,
              fontColor: "white",
            },
          },
          scale: {
            ticks: {
              display: false,
            },
            gridLines: {
              display: false,
            },
          },
          // scale: {
          //   display: false
          // }
          tooltips: {
            mode: "point",
            callbacks: {
              label: function(tooltipItem, data) {
                const percentage = (
                  (parseFloat(tooltipItem.yLabel) / totalMarks) *
                  100
                ).toFixed(2)
                return (
                  " " +
                  labels[tooltipItem.index] +
                  ": Marks - " +
                  parseInt(tooltipItem.yLabel) +
                  ", Percentage: " +
                  percentage +
                  "%"
                )
              },
            },
          },
        },
      })
    }
  }

  // showSubjectAverageGradeGraph = () => {
  //   // Use this for random color
  //   // "#" + (((1 << 24) * Math.random()) | 0).toString(16)
  //   if (this.props.subjectAverageGradePercentageGraphObject.grades) {
  //     const grades = this.props.subjectAverageGradePercentageGraphObject.grades;
  //     const subjects = this.props.subjectAverageGradePercentageGraphObject
  //       .subjects;
  //       if (myGraphsubjectAverageGrade != null) {
  //         myGraphsubjectAverageGrade.destroy()
  //       }
  //       myGraphsubjectAverageGrade = new Chart(this.subjectAverageGradeGraph, {
  //       type: "bubble",
  //       data: {
  //         labels: this.props.subjectAverageGradePercentageGraphObject.subjects,
  //         datasets: [
  //           {
  //             // label: "Grade",
  //             data: this.props.subjectAverageGradePercentageGraphObject.data,
  //             backgroundColor: this.props
  //               .subjectAverageGradePercentageGraphObject.backgroundColor,
  //             fill: false,
  //             borderWidth: 0
  //           }
  //         ]
  //       },
  //       options: {
  //         responsive: true,
  //         tooltips: {
  //           callbacks: {
  //             title: function(tooltipItem, data) {
  //               return data.labels[tooltipItem[0].index];
  //             },
  //             label: function(tooltipItem, data) {
  //               return "Grade - " + grades[tooltipItem.xLabel];
  //             }
  //           }
  //         },
  //         legend: {
  //           display: false,
  //           position: "bottom",
  //           labels: {
  //             usePointStyle: true,
  //             fontSize: 10,
  //             fontColor: "white"
  //           }
  //         },
  //         scales: {
  //           xAxes: [
  //             {
  //               ticks: {
  //                 display: true,
  //                 fontColor: "#FFFFFF",
  //                 stepSize: 1,
  //                 callback: function(tickValue, index, ticks) {
  //                   return subjects[index];
  //                 }
  //               },
  //               gridLines: {
  //                 display: false
  //               }
  //             }
  //           ],
  //           yAxes: [
  //             {
  //               ticks: {
  //                 fontColor: "#FFFFFF",
  //                 display: true
  //                 // callback: function(tickValue, index, ticks) {
  //                 //   return labels[index]
  //                 // },
  //               },
  //               gridLines: {
  //                 display: false
  //               }
  //             }
  //           ]
  //         }
  //       }
  //     });
  //   }
  // };

  showSubjectAverageGradeGraph = () => {
    if (this.props.subjectAverageGradePercentageGraphObject.labels) {
      const labelSubject = this.props.subjectAverageGradePercentageGraphObject.labels.map(
        item => String(item).slice(0, 3)
      )
      const dataMark = this.props.subjectAverageGradePercentageGraphObject.data
      const gradeNames = this.props.subjectAverageGradePercentageGraphObject
        .gradeNames
      const gradeValues = this.props.subjectAverageGradePercentageGraphObject
        .gradeValues
      const backgroundColor = this.props
        .subjectAverageGradePercentageGraphObject.backgroundColor
      // if (myGraphsubjectAverageGrade != null) {
      //   myGraphsubjectAverageGrade.destroy()
      // }
      let yLabels = {}
      gradeValues.forEach((value, i) => {
        yLabels[value] = gradeNames[i]
      })
      if (this.subjectAverageGradeGraph) {
        myGraphsubjectAverageGrade = new Chart(this.subjectAverageGradeGraph, {
          type: "line",
          data: {
            labels: labelSubject,
            datasets: [
              {
                fill: false,
                borderWidth: 1,
                pointBorderWidth: 1,
                pointRadius: 4,
                borderColor: "#ffffff",
                pointBackgroundColor: backgroundColor,
                data: dataMark,
              },
            ],
          },
          options: {
            responsive: true,
            legend: {
              display: false,
              labels: {
                usePointStyle: true,
                fontSize: 10,
                fontColor: "white",
              },
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    fontColor: "#FFFFFF",
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    display: true,
                    fontColor: "#FFFFFF",
                    step: 1,
                    min: Math.min.apply(Math, gradeValues),
                    callback: function(label, index, labels) {
                      return yLabels[label]
                    },
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
            },
          },
        })
      }
    }
  }

  showSubjectPassFailPercentageGraph = () => {
    // if (myGraphSubjectPassFail != null) {
    //   myGraphSubjectPassFail.destroy()
    // }
    if (this.subjectPassFailPercentageGraph) {
      myGraphSubjectPassFail = new Chart(this.subjectPassFailPercentageGraph, {
        type: "bar",
        data: {
          labels: this.props.subjectPassFailPercentageGraphObject.labels.map(
            item => String(item).slice(0, 3)
          ),
          datasets: [
            {
              label: "Pass",
              data: this.props.subjectPassFailPercentageGraphObject.passData,
              backgroundColor: "#0fb293",
              borderWidth: 2,
              borderRadius: 5,
              barThickness: 12,
              maxBarThickness: 12,
            },
            {
              label: "Fail",
              data: this.props.subjectPassFailPercentageGraphObject.failData,
              borderWidth: 2,
              borderRadius: 5,
              backgroundColor: "#f77272",
              barThickness: 12,
              maxBarThickness: 12,
            },
          ],
        },
        options: {
          responsive: true,
          legend: {
            display: false,
            position: "bottom",
            labels: {
              usePointStyle: true,
              fontSize: 10,
              fontColor: "white",
            },
          },
          scales: {
            xAxes: [
              {
                barPercentage: 0.1,
                ticks: {
                  fontColor: "#FFFFFF",
                },
                gridLines: {
                  display: false,
                },
                stacked: true,
              },
            ],
            yAxes: [
              {
                ticks: {
                  display: false,
                },
                gridLines: {
                  display: false,
                },
                stacked: true,
              },
            ],
          },
          tooltips: {
            callbacks: {
              title: function(tooltipItem, data) {
                return data["labels"][tooltipItem[0]["index"]]
              },
              label: function(tooltipItem, data) {
                var dataset = data["datasets"][tooltipItem.datasetIndex]
                var percent = dataset.data[tooltipItem.index]
                return percent + "%"
              },
            },
            xAlign: "left",
          },
        },
      })
    }
  }

  closeMessageModal = () => this.setState({ isMessageModalOpen: false })

  onClickMessageIcon = () => {
    this.setState({ isMessageModalOpen: !this.state.isMessageModalOpen })
  }

  onSubmitMessage = () => {
    this.closeMessageModal()
  }

  customFilter = sliderValue => {
    const dummyStudents = this.props.studentPerformanceReport
    let array = []
    if (dummyStudents && dummyStudents.length > 0) {
      array = dummyStudents.filter(item => {
        return (
          item.percentage >= sliderValue[0] && item.percentage <= sliderValue[1]
        )
      })
      this.setState({
        sortedStudentsArray: array,
        startSliderValue: sliderValue[0],
        endSliderValue: sliderValue[1],
        sliderTotalStudent: array.length,
      })
      this.props.saveDisplayStudentPerformanceReportDetailsInStore(array)
    }
  }

  viewAsClassTeacher = () => {
    this.setState({
      isClassTeacher: true,
      isSubjectTeacher: false,
    })
    // this.getPerformanceReport(this.state.selectedExam)
    this.checkGenerateAnalytics(
      this.state.selectedExam,
      this.state.selectedClassStudentAcademicPerformance
    )
    this.studentPerformanceReportService(
      this.state.selectedExamStudentAcademicPerformance,
      this.state.selectedSubjectStudentAcademicPerformance,
      this.state.selectedClassStudentAcademicPerformance,
      this.state.selectedBranch
    )
  }

  viewAsSubjectTeacher = () => {
    //  if(this.state.hierarchySubjectDetails !== undefined)
    let object = {
      assessmentId: this.props.examList[0].id,
      // here,it has to be selectedexam
      sectionId: this.state.selectedClassStudentAcademicPerformance,
      hSubjectId: this.state.selectedSubject,
    }
    this.props.getTeacherHierarchySubjectDetails(
      object,
      (hierarchySubjectSuccess, hierarchySubjectResponse) => {
        if (hierarchySubjectSuccess) {
          this.setState(
            {
              hierarchySubjectDetails: hierarchySubjectResponse,
            },
            () => {
              // this.setState({
              //   isSubjectTeacher:
              //     store.get("userSession").userInfo.role === 4
              //       ? true
              //       : false,
              // })
              this.setState({
                isSubjectTeacher: true,
                isClassTeacher: false,
              })
            }
          )
        }
      }
    )
    // this.setState({
    //   isSubjectTeacher: true,
    //   isClassTeacher: false,
    // })
  }

  onChangeSelectedExam = e => {
    this.setState(
      {
        selectedExam: e.target.value,
        selectedExamStudentAcademicPerformance: e.target.value,
      },
      () => {
        let assessmentIndex = this.props.examList.findIndex(item => {
          return item.id === this.state.selectedExam
        })
        if (assessmentIndex !== -1)
          this.setState({
            assementName: this.props.examList[assessmentIndex].value,
          })
        if (myGraphGrade != null) {
          myGraphGrade.destroy()
        }
        if (myGraphsubjectAverageMarks != null) {
          myGraphsubjectAverageMarks.destroy()
        }
        if (myGraphsubjectAverageGrade != null) {
          myGraphsubjectAverageGrade.destroy()
        }
        if (myGraphSubjectPassFail != null) {
          myGraphSubjectPassFail.destroy()
        }
        // this.getPerformanceReport(this.state.selectedExam)
        this.checkGenerateAnalytics(
          this.state.selectedExam,
          this.state.selectedClassStudentAcademicPerformance
        )
      }
    )
  }

  render() {
    return (
      <AppWrapper
        openMessageModal={() => this.onClickMessageIcon()}
        teacherDashboard
        showTeacherThree
        showTeacherLogo
        screenName="Performance Report"
      >
        <MessageModal
          isModalOpen={this.state.isMessageModalOpen}
          closeModal={() => this.onClickMessageIcon()}
          onSubmitMessage={() => this.onSubmitMessage()}
        />
        {this.state.isShowToggleButton && (
          <div className="d-flex justify-content-end mb-3">
            <div className="d-flex align-items-center justify-content-end">
              <div className="mr-3 font-weight-bold">VIEW AS</div>
              <div className="bg-theme-verylight p-1 br-10">
                <button
                  className={`btn btn-outline rounded ${
                    this.state.isClassTeacher ? "bg-theme-light text-light" : ""
                  }`}
                  type="button"
                  onClick={this.viewAsClassTeacher}
                >
                  CLASS TEACHER
                </button>
                <button
                  className={`btn btn-outline rounded ${
                    this.state.isSubjectTeacher
                      ? "bg-theme-light text-light"
                      : ""
                  }`}
                  type="button"
                  onClick={this.viewAsSubjectTeacher}
                >
                  SUBJECT TEACHER
                </button>
              </div>
            </div>
          </div>
        )}

        {this.state.isClassTeacher ? (
          this.state.isShowLoading ? (
            <div
              className="d-flex justify-content-center"
              style={{ position: "absolute", left: "50%", top: "50%" }}
            >
              <HashLoader
                sizeUnit="px"
                size={35}
                color="#084353"
                loading={this.state.isShowLoading}
              />
            </div>
          ) : (
            <div>
              <div className="row mr-5">
                <div className="p-2">
                  <select
                    className="form-control mr-3 mb-2"
                    value={this.state.selectedExam}
                    onChange={this.onChangeSelectedExam}
                  >
                    {this.props.examList &&
                      this.props.examList.map(item => (
                        <option key={item.id} value={item.id}>
                          {item.value}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              {this.state.isShowNoDateFound ? (
                <div className="text-center" style={{ marginTop: 50 }}>
                  <div style={{ marginTop: 20 }}>
                    <b style={{ color: "#084353", fontSize: 24 }}>
                      No Data Found
                    </b>
                  </div>
                  <div className="d-flex justify-content-center">
                    <img
                      src={NoDataFound}
                      alt="logo"
                      width={400}
                      height={400}
                    />
                  </div>
                </div>
              ) : this.state.isShowErrorMessage ? (
                <div className="text-center" style={{ marginTop: 50 }}>
                  <div style={{ marginTop: 20 }}>
                    <b style={{ color: "#084353", fontSize: 24 }}>
                      {this.state.showErrorMessage}
                    </b>
                  </div>
                  <div className="d-flex justify-content-center">
                    <img
                      src={NoDataFound}
                      alt="logo"
                      width={400}
                      height={400}
                    />
                  </div>
                </div>
              ) : this.state.isShowGenerateAnalyticButton ? (
                <div className="d-flex justify-content-center">
                  <div className=" mt-4">
                    <button
                      type="button"
                      disabled={this.state.isShowLoading}
                      className="btn btn-primary mt-3 mr-2 rounded-pill"
                      onClick={() => {
                        this.setState({ isShowLoading: true }, () => {
                          let object = {
                            sectionId: this.state.selectedClass,
                            hSubjectId: "ALL",
                            assessmentId: this.state.selectedExam,
                          }
                          this.props.saveStudentSubjectGenerateAnalyticsDetail(
                            object,
                            (success, response) => {
                              this.setState({ isShowLoading: false }, () => {})
                              if (success) {
                                this.setState({
                                  isShowGenerateAnalyticButton: false,
                                })
                                this.getAfterGenerateAnalytics(
                                  this.state.selectedExam,
                                  this.state.selectedClass
                                )
                              }
                            }
                          )
                        })
                        // this.getPerformanceReport(this.state.selectedExamStudentAcademicPerformance)
                        // this.studentPerformanceReportService(
                        //   this.state.selectedExamStudentAcademicPerformance,
                        //   'ALL',
                        //   this.state.selectedClassStudentAcademicPerformance,
                        // )
                      }}
                    >
                      Generate Analytics
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="d-flex justify-content-between mb-2">
                    <div className="d-flex justify-content-start">
                      <h5 className="text-theme font-weight-bold">
                        CLASS PERFORMANCE
                      </h5>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-self-center">
                      <h1 className="text-theme fw-1000">
                        {this.props
                          .classPerformanceAssessmentMarkByTeacherDetails
                          .classInfo &&
                          this.props
                            .classPerformanceAssessmentMarkByTeacherDetails
                            .classInfo.label}
                      </h1>
                      <div className="border-theme-light border-right-theme mx-3"></div>
                      <h3 className="text-theme fw-500 align-self-center">
                        {this.state.assementName}
                      </h3>
                    </div>
                    <div className="d-flex">
                      <div className="text-center">
                        <div className="d-flex justify-content-center">
                          <div className="theme-rounded">
                            {this.props
                              .classPerformanceAssessmentMarkByTeacherDetails
                              .classInfo &&
                              this.props
                                .classPerformanceAssessmentMarkByTeacherDetails
                                .classInfo.total}
                          </div>
                        </div>
                        <small>
                          <b>TOTAL</b>
                        </small>
                      </div>
                      <div className="text-center mx-3">
                        <div className="d-flex justify-content-center">
                          <div className="theme-rounded">
                            {this.props
                              .classPerformanceAssessmentMarkByTeacherDetails
                              .classInfo &&
                              this.props
                                .classPerformanceAssessmentMarkByTeacherDetails
                                .classInfo.attended}
                          </div>
                        </div>
                        <small>
                          <b>ATTENDED</b>
                        </small>
                      </div>
                      <div className="text-center">
                        <div className="d-flex justify-content-center">
                          <div className="theme-rounded">
                            {this.props
                              .classPerformanceAssessmentMarkByTeacherDetails
                              .classInfo &&
                              this.props
                                .classPerformanceAssessmentMarkByTeacherDetails
                                .classInfo.absent}
                          </div>
                        </div>
                        <small>
                          <b>ABSENT</b>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex bg-theme-light p-3 mt-3 border-bottom-0 bt-10">
                    <div className="d-flex col-sm-9">
                      <div
                        className="bg-theme-verylight text-center p-3 br-10 text-light col-sm-2"
                        style={{ color: "#B6D4DB", borderRadius: 30 }}
                      >
                        <div style={{ color: "#B6D4DB" }}>Avg.Grade</div>
                        <div className="d-flex justify-content-center">
                          <div
                            className="grade-b1 text-dark"
                            style={{
                              backgroundColor: this.props
                                .classPerformanceAssessmentMarkByTeacherDetails
                                .averageGrade
                                ? this.props
                                    .classPerformanceAssessmentMarkByTeacherDetails
                                    .averageGrade.gradeColor
                                : "",
                            }}
                          >
                            {this.props
                              .classPerformanceAssessmentMarkByTeacherDetails
                              .averageGrade
                              ? this.props
                                  .classPerformanceAssessmentMarkByTeacherDetails
                                  .averageGrade.grade
                              : ""}
                          </div>
                        </div>
                      </div>

                      {this.props.classPerformanceAssessmentMarkByTeacherDetails
                        .mark &&
                        this.props.classPerformanceAssessmentMarkByTeacherDetails.mark.map(
                          (item, i) => (
                            <div
                              key={i}
                              style={{ borderRadius: 30 }}
                              className="bg-theme-verylight text-center p-3 br-10 text-light d-flex justify-content-between ml-3"
                            >
                              <div style={{ width: "60px", height: "60px" }}>
                                <CircularProgressbar
                                  value={item.percentage}
                                  text={`${item.percentage}%`}
                                  styles={buildStyles({
                                    pathColor: `#0fb293`,
                                    textColor: "#ffffff",
                                    trailColor: "rgba(71, 153, 151, .22)",
                                  })}
                                />
                              </div>
                              <div className="d-flex flex-column justify-content-center ml-3">
                                <div style={{ color: "#B6D4DB" }}>
                                  {item.name}
                                </div>
                                <div className="fs-20">{item.mark}</div>
                              </div>
                            </div>
                          )
                        )}
                    </div>
                    <div className="p-2 br-10 col-sm">
                      <div id="pass-percentage">
                        <Progress
                          percent={
                            this.props
                              .classPerformanceAssessmentMarkByTeacherDetails
                              .passFailPercentage &&
                            this.props
                              .classPerformanceAssessmentMarkByTeacherDetails
                              .passFailPercentage.passPercentage
                          }
                          status="active"
                          className="my-2"
                          symbolClassName="d-none"
                          theme={{
                            active: {
                              symbol: "-",
                              trailColor: "#f77272",
                              color: "#0fb293",
                            },
                          }}
                        />
                      </div>
                      <UncontrolledTooltip target="pass-percentage">
                        <div className="text-center d-flex justify-content-around fs-12 font-weight-bold text-light">
                          <span>
                            <span className="text-light">
                              {"Pass: "}
                              {this.props
                                .classPerformanceAssessmentMarkByTeacherDetails
                                .passFailPercentage &&
                                this.props
                                  .classPerformanceAssessmentMarkByTeacherDetails
                                  .passFailPercentage.passPercentage}
                              %
                            </span>
                          </span>
                          <span>
                            <span className="text-light ml-2">
                              {"Fail: "}
                              {this.props
                                .classPerformanceAssessmentMarkByTeacherDetails
                                .passFailPercentage &&
                                this.props
                                  .classPerformanceAssessmentMarkByTeacherDetails
                                  .passFailPercentage.failPercentage}
                              %
                            </span>
                          </span>
                        </div>
                      </UncontrolledTooltip>
                      <div className="text-center d-flex justify-content-around fs-12 font-weight-bold text-light">
                        <span>
                          <i
                            className="fas fa-circle mr-1"
                            style={{ color: "#0fb293" }}
                          ></i>
                          Pass
                        </span>
                        <span>
                          <i
                            className="fas fa-circle mr-1"
                            style={{ color: "#f77272" }}
                          ></i>
                          Fail
                        </span>
                      </div>
                      <div className="text-center d-flex justify-content-around fs-12 font-weight-bold text-light">
                        <span>
                          <span className="text-light ml-3 pl-3 fs-20">
                            {this.props
                              .classPerformanceAssessmentMarkByTeacherDetails
                              .passFailPercentage &&
                              this.props
                                .classPerformanceAssessmentMarkByTeacherDetails
                                .passFailPercentage.passCount}
                          </span>
                        </span>
                        <span>
                          <span className="text-light ml-3 pl-3 fs-20">
                            {this.props
                              .classPerformanceAssessmentMarkByTeacherDetails
                              .passFailPercentage &&
                              this.props
                                .classPerformanceAssessmentMarkByTeacherDetails
                                .passFailPercentage.failCount}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="bg-theme-light p-3 bb-10 d-flex justify-content-between">
                    <div className="bg-theme-verylight br-10 col-sm mx-3 p-2">
                      <h6
                        className="text-right text-light p-2"
                        style={{ color: "#B6D4DB" }}
                      >
                        <div style={{ color: "#B6D4DB" }}>
                          SUBJECT AVERAGE MARKS
                        </div>
                      </h6>
                      <div
                        className="row col-sm-12 h-100"
                        style={{
                          width: "451px",
                          height: "350px",
                        }}
                      >
                        <canvas
                          style={{
                            width: "451px",
                            height: "350px",
                          }}
                          ref={subjectAverageMarksGraph =>
                            (this.subjectAverageMarksGraph = subjectAverageMarksGraph)
                          }
                        />
                        {/* <FullOption
                           animationDuration={500}
                           animationEasing="ease-out"
                           data={[
                             {
                               color: "#E38627",
                               title: 10,
                               value: 10,
                             },
                             {
                               color: "#C13C37",
                               title: 15,
                               value: 15,
                             },
                             {
                               color: "#6A2135",
                               title: 20,
                               value: 20,
                             },
                             {
                               color: "#677135",
                               title: 20,
                               value: 20,
                             },
                             {
                               color: "#699135",
                               title: 20,
                               value: 20,
                             },
                           ]}
                           label={function noRefCheck() {}}
                           labelPosition={25}
                           labelStyle={{
                             fontFamily: "sans-serif",
                             fontSize: "5px",
                           }}
                           lengthAngle={360}
                           lineWidth={60}
                           paddingAngle={10}
                           radius={25}
                           segmentsShift={function noRefCheck() {}}
                           startAngle={0}
                         /> */}
                      </div>
                    </div>
                    <div className="bg-theme-verylight br-10 col-sm mr-3 p-2">
                      <h6
                        className="text-right text-light p-2"
                        style={{ color: "#B6D4DB" }}
                      >
                        <div style={{ color: "#B6D4DB" }}>
                          SUBJECT AVERAGE GRADE
                        </div>
                      </h6>
                      <div className="my-auto">
                        <canvas
                          ref={subjectAverageGradeGraph =>
                            (this.subjectAverageGradeGraph = subjectAverageGradeGraph)
                          }
                        />
                      </div>
                    </div>
                  </div>

                  {/* 2nd*/}
                  <div className="bg-theme-light p-3 bt-10 d-flex justify-content-between mt-3">
                    <div className="bg-theme-verylight p-2 br-10 col-sm-2 text-center">
                      {/* <div className="d-flex justify-content-between mx-3 align-items-center mb-1">
               <div className="grade-a1 mr-1">A1</div>
               <span className="text-light">91-100</span>
             </div> */}
                      {this.props.gradePerformanceAssessmentObject.grades &&
                        this.props.gradePerformanceAssessmentObject.grades.map(
                          (item, i) => (
                            <div key={i}>
                              {/* <div
                                 className="grade-a1 rounded-circle "
                                 style={{ backgroundColor: item.gradeColor }}
                               >
                                 {item.grade}
                               </div>
                               <div
                                 className="mt-1"
                                 style={{ color: "#B6D4DB" }}
                               >
                                 {item.gradeFrom} - {item.gradeTo}
                               </div> */}
                              <table
                                className="table table-borderless table-sm"
                                style={{ marginBottom: "0px" }}
                              >
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td>
                                      <div
                                        className="grade-a1 rounded-circle "
                                        style={{
                                          backgroundColor: item.gradeColor,
                                          // width: "2.5rem",
                                        }}
                                      >
                                        {item.grade}
                                      </div>
                                    </td>
                                    <td style={{ verticalAlign: "middle" }}>
                                      <div style={{ color: "#B6D4DB" }}>
                                        <span>
                                          {item.gradeFrome} - {item.gradeTo}
                                        </span>
                                      </div>
                                    </td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          )
                        )}
                    </div>
                    <div className="bg-theme-verylight p-3 br-10 col-sm mx-3 text-light d-flex flex-column justify-content-between">
                      <h6
                        className="text-right text-light p-2"
                        style={{ color: "#B6D4DB" }}
                      >
                        <div style={{ color: "#B6D4DB" }}>
                          GRADE DISTRIBUTION
                        </div>
                      </h6>
                      <div className="mt-3">
                        <canvas
                          ref={gradeDistributionGraph =>
                            (this.gradeDistributionGraph = gradeDistributionGraph)
                          }
                        />
                      </div>
                    </div>
                    <div className="bg-theme-verylight p-2 br-10 col-sm d-flex flex-column justify-content-between ">
                      <h6
                        className="text-right text-light p-2"
                        style={{ color: "#B6D4DB" }}
                      >
                        <div style={{ color: "#B6D4DB" }}>
                          SUBJECTS PASS - FAIL PERCENTAGE
                        </div>
                      </h6>
                      <div className="mt-3">
                        <div>
                          <canvas
                            ref={subjectPassFailPercentageGraph =>
                              (this.subjectPassFailPercentageGraph = subjectPassFailPercentageGraph)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ​last */}
                  <div className="bg-theme-light p-3 bb-10 d-flex justify-content-between mb-4">
                    <div className="bg-theme-verylight p-2 br-10 col-sm d-flex flex-column">
                      <div
                        className="text-light fw-900 text-left p-2"
                        style={{ color: "#B6D4DB" }}
                      >
                        <div style={{ color: "#B6D4DB" }}>
                          <i className="fas fa-trophy mr-2 award-color" />
                          TOP PERFORMERS
                        </div>
                      </div>
                      <div className="table-responsive text-left">
                        <table className="table table-borderless text-light font-weight-bold">
                          <tbody>
                            {this.props.studentsPerformerAssessmentObject
                              .topPerformers &&
                              this.props.studentsPerformerAssessmentObject.topPerformers.map(
                                (item, i) => (
                                  <tr key={i} style={{ color: "#B6D4DB" }}>
                                    <td>
                                      <div>{item.studentName}</div>
                                    </td>
                                    <td>
                                      <div>
                                        {item.mark} <small>out of</small>{" "}
                                        {item.outOffMark}{" "}
                                        <small>({item.percentage}%)</small>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="bg-theme-verylight p-2 br-10 col-sm mx-3 text-light d-flex flex-column">
                      <div
                        className="text-light fw-900 text-left p-2"
                        style={{ color: "#B6D4DB" }}
                      >
                        <div style={{ color: "#B6D4DB" }}>
                          <i className="fas fa-exclamation-triangle mr-2 attention-color"></i>
                          ATTENTION NEEDED
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table table-borderless text-light font-weight-bold">
                          <tbody>
                            {this.props.studentsPerformerAssessmentObject
                              .attentionNeedStudents &&
                              this.props.studentsPerformerAssessmentObject.attentionNeedStudents.map(
                                (item, i) => (
                                  <tr key={i} style={{ color: "#B6D4DB" }}>
                                    <td>{item.studentName}</td>
                                    <td>
                                      {item.mark} <small>out of</small>{" "}
                                      {item.outOffMark}{" "}
                                      <small>({item.percentage}%)</small>
                                    </td>
                                  </tr>
                                )
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="bg-theme-verylight p-2 br-10 col-sm text-light">
                      <div
                        className="text-light fw-900 text-left p-2"
                        style={{ color: "#B6D4DB" }}
                      >
                        <div style={{ color: "#B6D4DB" }}>
                          <img
                            src={require("../../../assets/images/absentees.svg")}
                            alt="absentees"
                            style={{ width: 20, marginRight: 8 }}
                          />
                          ABSENTEES
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table table-borderless text-light font-weight-bold">
                          <tbody>
                            {this.props.studentsPerformerAssessmentObject
                              .absenteesArray &&
                            this.props.studentsPerformerAssessmentObject
                              .absenteesArray.length > 0 ? (
                              this.props.studentsPerformerAssessmentObject.absenteesArray.map(
                                (item, i) => (
                                  <div
                                    key={i}
                                    style={{
                                      overflowX: "hidden",
                                      overflowY: "hidden",
                                    }}
                                  >
                                    <tr className="row">
                                      <td className="col-lg-3 ml-3">
                                        {item.studentName}
                                      </td>
                                      <td className="col d-flex flex-wrap">
                                        {item.subjects.map(subj => (
                                          <div
                                            key={subj.subjectId}
                                            style={{
                                              padding: "4px 12px",
                                              margin: "2px 6px",
                                              backgroundColor:
                                                "rgba(6,177,145,0.25)",
                                              color: "#ffffff",
                                            }}
                                            className="rounded-pill"
                                          >
                                            {subj.subjectName}
                                          </div>
                                        ))}
                                      </td>
                                    </tr>
                                  </div>
                                )
                              )
                            ) : (
                              <div>
                                <div className="d-flex justify-content-center">
                                  <img
                                    src={require("../../../assets/images/noabsentee.svg")}
                                    alt="noabsentee"
                                    style={{ width: 125 }}
                                  />
                                </div>
                                <div className="text-white text-center mt-4">
                                  No Absentees
                                </div>
                              </div>
                            )}
                          </tbody>
                        </table>
                      </div>
                      {/* <div className="text-light fw-900 text-left p-2 d-flex vstart align-items-center">
                         <span
                           className="mr-4 bg-theme greet-round"
                           role="img"
                           aria-label=""
                         >
                           👏
                         </span>
                         <div style={{ color: "#B6D4DB" }}>
                           CONGRATULATIONS !
                         </div>
                       </div>
                       <div className="text-light fw-900 text-left p-2">
                         <span>
                           {this.props.studentsPerformerAssessmentObject &&
                             this.props.studentsPerformerAssessmentObject
                               .feedbackContent}
                         </span>
                       </div> */}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between my-4 ">
                    <div className="d-flex">
                      <h5 className="text-theme font-weight-bold">
                        STUDENT PERFORMANCE
                      </h5>
                    </div>
                    <div className="row mr-5">
                      <div className="p-2">
                        <select
                          className="form-control"
                          value={
                            this.state.selectedExamStudentAcademicPerformance
                          }
                          onChange={e => {
                            this.setState(
                              {
                                selectedExamStudentAcademicPerformance:
                                  e.target.value,
                                sortOrderByNames: 0,
                                sortOrderByMarks: 0,
                                sortOrderByGrades: 0,
                              },
                              () => {
                                this.studentPerformanceReportService(
                                  this.state
                                    .selectedExamStudentAcademicPerformance,
                                  this.state
                                    .selectedSubjectStudentAcademicPerformance,
                                  this.state
                                    .selectedClassStudentAcademicPerformance,
                                  this.state.selectedBranch
                                )
                              }
                            )
                          }}
                        >
                          {this.props.examList &&
                            this.props.examList.map((item, index) => {
                              return (
                                <option key={index} value={item.id}>
                                  {item.value}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                      <div className="p-2">
                        <select
                          className="form-control"
                          value={
                            this.state.selectedSubjectStudentAcademicPerformance
                          }
                          onChange={e => {
                            this.setState(
                              {
                                selectedSubjectStudentAcademicPerformance:
                                  e.target.value,
                                sortOrderByNames: 0,
                                sortOrderByMarks: 0,
                                sortOrderByGrades: 0,
                              },
                              () => {
                                this.studentPerformanceReportService(
                                  this.state
                                    .selectedExamStudentAcademicPerformance,
                                  this.state
                                    .selectedSubjectStudentAcademicPerformance,
                                  this.state
                                    .selectedClassStudentAcademicPerformance,
                                  this.state.selectedBranch
                                )
                              }
                            )
                          }}
                        >
                          <option key={-1} value={"ALL"}>
                            ALL
                          </option>
                          {this.props.teachersSubjectList &&
                            this.props.teachersSubjectList.map((item, i) => (
                              <option key={i} value={item.hSubjectId}>
                                {item.value}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between my-4">
                    <div className="w-50">
                      <label>PERCENT SLIDER</label>
                      <Range
                        step={0.1}
                        min={0}
                        max={100}
                        values={this.state.studentPerformanceRange}
                        onChange={studentPerformanceRange => {
                          this.setState({ studentPerformanceRange })
                          this.customFilter(studentPerformanceRange)
                        }}
                        renderTrack={({ props, children }) => (
                          <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                              ...props.style,
                              height: "36px",
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <div
                              ref={props.ref}
                              style={{
                                height: "5px",
                                width: "50%",
                                borderRadius: "4px",
                                background: "#00434c",
                              }}
                            >
                              {children}
                            </div>
                          </div>
                        )}
                        renderThumb={({ index, props }) => (
                          <div
                            {...props}
                            style={{
                              ...props.style,
                              height: "20px",
                              width: "20px",
                              borderRadius: "50%",
                              display: "flex",
                              backgroundColor: "#479997",
                              justifyContent: "center",
                              color: "white",
                              fontSize: 10,
                              alignItems: "center",
                              boxShadow: "0px 2px 6px #AAA",
                            }}
                          >
                            {Math.round(
                              this.state.studentPerformanceRange[index].toFixed(
                                1
                              )
                            )}
                          </div>
                        )}
                      />
                    </div>

                    {/* <div>
                       <form className="navbar-form" role="search">
                         <div className="input-group">
                           <input
                             type="text"
                             className="form-control"
                             placeholder="Search"
                           />
                           <div className="input-group-btn">
                             <button className="btn btn-theme" type="button">
                               <i className="fal fa-search" />
                             </button>
                           </div>
                         </div>
                       </form>
                     </div> */}
                  </div>
                  <div className="d-flex justify-content-end mb-3">
                    <div className="bg-theme-light p-2 table-top-exclam">
                      <i className="fas fa-exclamation table-top-exclam-color" />
                    </div>
                    <div className="bg-lightblue-td br-10 px-2 py-1">
                      {this.state.sliderTotalStudent} of{" "}
                      {this.state.totalStudent} students have secured marks
                      between {this.state.startSliderValue}% and{" "}
                      {this.state.endSliderValue}%
                    </div>
                  </div>
                  <div className="table-responsive mb-4">
                    <table
                      className="table table-borderless text-center"
                      cellPadding="3"
                    >
                      <thead>
                        <tr className="text-light">
                          <th
                            scope="col"
                            className="first-child-curved text-center"
                          >
                            ROLL NO
                          </th>
                          <th
                            scope="col"
                            className="bg-theme-light text-center cursor-pointer"
                            onClick={() => {
                              this.sortStudentListBy("name", "sortOrderByNames")
                            }}
                          >
                            NAME
                            {this.state.sortOrderByNames === 1 ? (
                              <i className="fas fa-caret-up ml-3" />
                            ) : this.state.sortOrderByNames === -1 ? (
                              <i className="fas fa-caret-down ml-3" />
                            ) : (
                              <i className="fas fa-sort ml-3" />
                            )}
                          </th>
                          <th
                            scope="col"
                            className="bg-theme-light text-center"
                          >
                            SECTION
                          </th>
                          <th
                            scope="col"
                            className="bg-theme-light text-center cursor-pointer"
                            onClick={() => {
                              this.sortStudentListBy("mark", "sortOrderByMarks")
                            }}
                          >
                            MARKS <small>out of {this.state.outOffMark}</small>
                            {this.state.sortOrderByMarks === 1 ? (
                              <i className="fas fa-caret-up ml-3" />
                            ) : this.state.sortOrderByMarks === -1 ? (
                              <i className="fas fa-caret-down ml-3" />
                            ) : (
                              <i className="fas fa-sort ml-3" />
                            )}
                          </th>
                          <th
                            scope="col"
                            className="bg-theme-light text-center"
                          >
                            PERCENTAGE(%)
                          </th>
                          <th
                            scope="col"
                            className="bg-theme-light text-center cursor-pointer"
                            // onClick={() => {
                            //   this.sortStudentListBy(
                            //     "grade",
                            //     "sortOrderByGrades"
                            //   )
                            // }}
                          >
                            GRADE
                            {/* {this.state.sortOrderByGrades === 1 ? (
                               <i className="fas fa-caret-up ml-3" />
                             ) : this.state.sortOrderByGrades === -1 ? (
                               <i className="fas fa-caret-down ml-3" />
                             ) : (
                               <i className="fas fa-sort ml-3" />
                             )} */}
                          </th>
                          <th
                            scope="col"
                            className="bg-theme-light text-center cursor-pointer"
                            onClick={() => {
                              this.sortStudentListBy("rank", "sortOrderByRanks")
                            }}
                          >
                            RANK
                            {this.state.sortOrderByRanks === 1 ? (
                              <i className="fas fa-caret-up ml-3" />
                            ) : this.state.sortOrderByRanks === -1 ? (
                              <i className="fas fa-caret-down ml-3" />
                            ) : (
                              <i className="fas fa-sort ml-3" />
                            )}
                          </th>
                          <th
                            scope="col"
                            className="last-child-curved text-center"
                          >
                            SCHOOL RANK
                          </th>
                        </tr>
                      </thead>

                      {!this.state.isShowLoadingStudentPerformance && (
                        <tbody>
                          {this.state.sortedStudentsArray &&
                            this.state.sortedStudentsArray.map(
                              (item, index) => (
                                <>
                                  <div className="m-2"></div>
                                  <tr>
                                    <td className="first-child-curved-td text-center">
                                      {item.rollNo}
                                    </td>
                                    <td className="bg-lightblue-td text-left">
                                      <span className="ml-5">{item.name}</span>
                                    </td>
                                    <td className="bg-lightblue-td text-center">
                                      {item.section}
                                    </td>
                                    <td className="bg-lightblue-td text-center">
                                      {item.mark}
                                    </td>
                                    <td className="bg-lightblue-td text-center">
                                      {String(item.percentage).length > 5
                                        ? String(item.percentage).slice(0, 5)
                                        : item.percentage}
                                    </td>
                                    <td className="d-flex justify-content-center bg-lightblue-td text-center">
                                      <div
                                        className="grade-a1 fs-12 text-center"
                                        style={{
                                          background: item.gradeColor,
                                          padding: 3,
                                          height: 22,
                                          width: 22,
                                          borderRadius: "15px",
                                        }}
                                      >
                                        {item.grade}
                                      </div>
                                    </td>
                                    <td className="bg-lightblue-td text-center">
                                      <span>
                                        {item.rank}
                                        {/* {item.rankStatus === "increase" ? (
                                 <i className="fas fa-caret-up ml-2 text-success" />
                               ) : (
                                 <i className="fas fa-caret-down ml-2 text-danger" />
                               )} */}
                                      </span>
                                    </td>
                                    <td className="last-child-curved-td text-center">
                                      <span>
                                        {item.classRank}
                                        {/* {item.schoolRankStatus === "increase" ? (
                                 <i className="fas fa-caret-up ml-2 text-success" />
                               ) : (
                                 <i className="fas fa-caret-down ml-2 text-danger" />
                               )} */}
                                      </span>
                                    </td>
                                  </tr>
                                  <div className="m-2"></div>
                                </>
                              )
                            )}
                        </tbody>
                      )}
                    </table>

                    {this.state.sortedStudentsArray &&
                      this.state.sortedStudentsArray.length === 0 &&
                      !this.state.isShowLoadingStudentPerformance && (
                        <div className="d-flex justify-content-center">
                          No data found
                        </div>
                      )}

                    {this.state.isShowLoadingStudentPerformance && (
                      <Skeleton count={5} height={30} width={"100%"} />
                    )}
                  </div>
                </div>
              )}
            </div>
          )
        ) : null}

        {this.state.isSubjectTeacher && (
          <div>
            {/* <div className="row mr-5">
             <div className="p-2">
               <select
                 className="form-control mr-3 mb-2"
                 value={this.state.selectedExam}
                 onChange={e => {
                   this.setState({ selectedExam: e.target.value }, () => {
                   });
                 }}
               >
                 {this.props.examList &&
                   this.props.examList.map((item, index) => {
                     return (
                       <option key={index} value={item.id}>
                         {item.value}
                       </option>
                     );
                   })}
               </select>
             </div>
             <div className="p-2">
             <select
               className="form-control"
               value={this.state.selectedClass}
               onChange={e => {
                 this.setState({ selectedClass: e.target.value }, () => {
                 })
               }}
             >
               {this.props.classes &&
                 this.props.classes.map((item, index) => {
                   return (
                     <option key={index} value={item.id}>
                       {item.value}
                     </option>
                   )
                 })}
             </select>
             </div>
             <div className="p-2">
               <select
                 className="form-control mr-3 mb-2"
                 value={this.state.selectedSubject}
                 onChange={e => {
                   this.setState({ selectedSubject: e.target.value }, () => {
                   });
                 }}
               >
                 {this.props.teachersSubjectList &&
                   this.props.teachersSubjectList.map((item, index) => {
                     return (
                       <option key={index} value={item.id}>
                         {item.value}
                       </option>
                     );
                   })}
               </select>
             </div>
           </div> */}
            <QuickAnalyticsComponent
              {...this.props}
              selectedAssementName={this.state.assementName}
              selectedClassName={this.state.selectedClassName}
              selectedSubjectName={this.state.selectedSubjectName}
              isFromPerformanceReport={true}
              hierarchySubjectDetails={this.state.hierarchySubjectDetails}
            />
          </div>
        )}
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    examList: state.hierarchyState.examList,
    classes: state.takeAttendanceState.teacherClassList,

    classPerformanceAssessmentMarkByTeacherDetails:
      state.performanceReportState
        .classPerformanceAssessmentMarkByTeacherDetails,
    gradePerformanceAssessmentObject:
      state.performanceReportState.gradePerformanceAssessmentObject,
    studentsPerformerAssessmentObject:
      state.performanceReportState.studentsPerformerAssessmentObject,
    subjectPassFailPercentageGraphObject:
      state.performanceReportState.subjectPassFailPercentageGraphObject,
    subjectAverageMarkPercentageGraphObject:
      state.performanceReportState.subjectAverageMarkPercentageGraphObject,
    subjectAverageGradePercentageGraphObject:
      state.performanceReportState.subjectAverageGradePercentageGraphObject,
    teachersSubjectList: state.hierarchyState.subjectList,
    studentPerformanceReport:
      state.hierarchyState.studentPerformanceReportDetails,
    displayStudentPerformanceReportDetails:
      state.hierarchyState.displayStudentPerformanceReportDetails,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getExamListDetails,
      getClassList,
      getSubjectsList,
      getSubjectListByClassDetails,
      getTeacherHierarchySubjectDetails,
      getClassPerformanceAssessmentMarkByTeacherDetails,
      getGradePerformanceAssessmentByTeacherDetails,
      getStudentsPerformersAssessmentListByTeacherDetails,
      getSubjectPassFailPercentageGraphDetails,
      getStudentPerformanceReportDetails,
      saveDisplayStudentPerformanceReportDetailsInStore,
      saveCheckIsGenerateAnalyticsDetail,
      saveStudentSubjectGenerateAnalyticsDetail,
      // getSubjectAverageMarkPercentageGraphDetails,
      // getSubjectAverageGradePercentageGraphDetails
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(PerformanceReport)
