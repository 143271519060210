import React from "react"
import { Collapse } from "reactstrap"
import { ReactComponent as Arrow } from "../../../assets/images/arrow.svg"
import reportCardTempImg from "../../../assets/images/thumbnail.png"

function Card({
  list = [],
  title,
  selected,
  setSelected,
  updateShowEdit,
  updateShowPreview,
  updateSelectedClass,
}) {
  return (
    <div className="reportCard-card">
      <div
        onClick={() => setSelected(pr => (pr === title ? "" : title))}
        className={`d-flex align-items-center fs-17 cursor-pointer ${
          selected === title ? "fw-600" : "fw-500"
        }`}
      >
        Class {title}
        <Arrow
          className={`reportCard-title-svg ml-auto ${
            selected === title ? "rotate-Z-180" : ""
          }`}
        />
      </div>

      <Collapse isOpen={selected === title}>
        <div>
          <div className="reportCard-card-collapsed-title fs-15 fw-600">
            Create or choose template
          </div>

          <div className="d-flex align-items-center gap-2rem">
            <div className="report-template-img">
              <img src={reportCardTempImg} alt="" />
            </div>

            <div className="report-template-wrapper">
              <h6 className="fs-17 fw-500">Class {title} template</h6>
              <div className="d-flex align-items-center gap-1rem fs-15">
                <button onClick={updateShowEdit}>Edit template</button>
                <span className="fs-18">|</span>
                <button onClick={updateShowPreview}>Preview template</button>
              </div>
            </div>
          </div>

          <div className="reportCard-sections-title fs-15 fw-600">Sections</div>

          <div className="reportCard-sections-card-wrapper d-flex align-items-center">
            {list.map(l => (
              <div
                key={l.id}
                className="reportCard-sections-card d-flex align-items-center position-relative cursor-pointer"
                onClick={() => updateSelectedClass(true, l)}
              >
                <p className="mb-0 ml-2 fw-500">{l.className}</p>

                <span className="position-absolute fs-15 cursor-pointer">
                  View <Arrow />
                </span>
              </div>
            ))}
          </div>
        </div>
      </Collapse>
    </div>
  )
}

export default Card
