/*************************************************
 * RedInk
 * @exports
 * @class QuickAnalyticsAction.js
 * @extends Component
 * @author vijayakumar // on 05/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { ACTION_TYPES } from "../types"

export const saveStudentsPerformanceMarkBySubjectInStore = studentsPerformanceMarkBySubjectDetails => {
  return {
    type: ACTION_TYPES.TEACHER_STUDENTS_PERFORMANCE_MARK_SUBJECT_DETAILS,
    payload: studentsPerformanceMarkBySubjectDetails,
  }
}

export const saveStudentsPerformersListBySubjectInStore = studentsPerformerObject => {
  return {
    type: ACTION_TYPES.TEACHER_STUDENTS_PERFORMER_OBJECT,
    payload: studentsPerformerObject,
  }
}

export const saveGradePerformanceListBySubjectInStore = gradePerformerObject => {
  return {
    type: ACTION_TYPES.TEACHER_GRADE_PERFORMER_OBJECT,
    payload: gradePerformerObject,
  }
}

export const saveStudentPerformanceReportDetailsInStore = studentPerformanceReportDetails => {
  return {
    type: ACTION_TYPES.TEACHER_STUDENT_PERFORMANCE_REPORT_DETAILS,
    payload: studentPerformanceReportDetails,
  }
}

export const saveDisplayStudentPerformanceReportDetailsInStore = displayStudentPerformanceReportDetails => {
  return {
    type: ACTION_TYPES.TEACHER_DISPLAY_STUDENT_PERFORMANCE_REPORT_DETAILS,
    payload: displayStudentPerformanceReportDetails,
  }
}

export const getStudentsPerformanceMarkBySubjectDetails = (body, callBack) => {
  return async dispatch => {
    try {
      myLog("====getMarkSubjectDetails body===::::", body)
      const response = await Client.get(
        URL.GET_STUDENTS_PERFORMANCE_MARK_SUBJECT,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====getMarkSubjectDetails RESPONSE===::::", response)
        dispatch(
          saveStudentsPerformanceMarkBySubjectInStore(
            response.studentsPerformanceMarkBySubjectDetails
          )
        )
        callBack(true, response)
      } else {
        callBack(false)
        dispatch(saveStudentsPerformanceMarkBySubjectInStore({}))
      }
    } catch (error) {
      callBack(false)
      myLog("====getMarkSubjectDetails ERROR===????", error)
    }
  }
}

export const getStudentsPerformersListBySubjectDetails = (body, callBack) => {
  return async dispatch => {
    try {
      myLog("====getStudentsPerformersListBySubjectDetails body===::::", body)

      const response = await Client.get(
        URL.GET_STUDENTS_PERFORMERS_LIST,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog(
          "====getStudentsPerformersListBySubjectDetails RESPONSE===::::",
          response
        )
        dispatch(
          saveStudentsPerformersListBySubjectInStore(
            response.studentsPerformerObject
          )
        )
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getStudentsPerformersListBySubjectDetails ERROR===????", error)
    }
  }
}

export const getGradePerformanceListBySubjectDetails = (body, callBack) => {
  return async dispatch => {
    try {
      myLog("====getGradePerformanceListBySubjectDetails body===::::", body)
      const response = await Client.get(
        URL.GET_STUDENTS_GRADE_PERFORMANCE_LIST_SUBJECT,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog(
          "====getGradePerformanceListBySubjectDetails RESPONSE===::::",
          response
        )
        dispatch(
          saveGradePerformanceListBySubjectInStore(
            response.gradeDistributionObject
          )
        )
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getGradePerformanceListBySubjectDetails ERROR===????", error)
    }
  }
}

export const getStudentPerformanceReportDetails = (body, callBack) => {
  return async dispatch => {
    try {
      myLog("====getStudentPerformanceReportDetails===::::")
      // let response = {
      //   studentPerformanceReportDetails: [
      //     {
      //       rollNo: "1234",
      //       studentName: "vijay",
      //       class: "LKG-A",
      //       mark: "50",
      //       percentage: "30",
      //       grade: "A2",
      //       gradeColor: "#000000"
      //       rank: "5",
      //       rankStatus: "increase",
      //       schoolRank: "10",
      //       schoolRankStatus: "descrease",
      //     },
      //     {
      //       rollNo: "1234",
      //       studentName: "sasi",
      //       class: "LKG-A",
      //       mark: "50",
      //       percentage: "70",
      //       grade: "A2",
      //       gradeColor: "#000000"
      //       rank: "5",
      //       rankStatus: "increase",
      //       schoolRank: "10",
      //       schoolRankStatus: "descrease",
      //     },
      //     {
      //       rollNo: "1234",
      //       studentName: "kumar",
      //       class: "LKG-A",
      //       mark: "50",
      //       percentage: "60",
      //       grade: "A2",
      //       gradeColor: "#000000"
      //       rank: "5",
      //       rankStatus: "increase",
      //       schoolRank: "10",
      //       schoolRankStatus: "descrease",
      //     },
      //     {
      //       rollNo: "1234",
      //       studentName: "vijay",
      //       class: "LKG-A",
      //       mark: "50",
      //       percentage: "80",
      //       grade: "A2",
      //       gradeColor: "#000000"
      //       rank: "5",
      //       rankStatus: "increase",
      //       schoolRank: "10",
      //       schoolRankStatus: "descrease",
      //     },
      //   ],
      // }
      // myLog("====getStudentPerformanceReportDetails===::::", response)
      // dispatch(
      //   saveStudentPerformanceReportDetailsInStore(
      //     response.studentPerformanceReportDetails
      //   )
      // )
      // callBack(true, response.studentPerformanceReportDetails)
      const response = await Client.get(
        URL.GET_STUDENT_PERFORMANCE_TABLE_REPORT_lIST,
        body,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog(
          "====getStudentPerformanceReportDetails RESPONSE===::::",
          response
        )
        // let sortArray = response.studentRankList
        // let newArray = []
        // newArray = sortArray.sort((a,b) => {
        // let nameA = a.name.toUpperCase(); // ignore upper and lowercase
        // let nameB = b.name.toUpperCase(); // ignore upper and lowercase
        // if (nameA < nameB) {
        //   return -1;
        // }
        // if (nameA > nameB) {
        //   return 1;
        // }
        // // names must be equal
        // return 0;
        // })
        dispatch(
          saveStudentPerformanceReportDetailsInStore(response.studentRankList)
        )
        dispatch(
          saveDisplayStudentPerformanceReportDetailsInStore(
            response.studentRankList
          )
        )
        callBack(true, response)
      } else {
        callBack(false)
        dispatch(saveStudentPerformanceReportDetailsInStore([]))
        dispatch(saveDisplayStudentPerformanceReportDetailsInStore([]))
      }
    } catch (error) {
      callBack(false)
      myLog("====getStudentPerformanceReportDetails ERROR===????", error)
    }
  }
}
