import React, { useEffect, useState } from "react"
import { HashLoader } from "react-spinners"

import { getSubjectsChaptersTopics } from "../../../../actions/admin/QuestionGeneration"
import { ReactComponent as Arrow } from "../../../../assets/images/arrow.svg"

function Step2({
  setStep,
  chapters,
  selectedClass,
  selectedBoard,
  selectedSubject,
  selectedChapter,
  setSelectedChapter,
  setChapters,
}) {
  const [isLoading, setIsLoading] = useState(true)
  const [topOpen, setTopOpen] = useState("")
  const [open, setOpen] = useState("")

  useEffect(() => {
    let param = {
      selectedBoard,
      selectedClass,
      selectedSubject: selectedSubject.map(s => s.masterSubjectId).join(","),
    }

    getSubjectsChaptersTopics(param, (success, res) => {
      if (success) {
        setChapters(res)
        setIsLoading(false)
      }
    })
  }, [selectedClass, selectedSubject, selectedBoard])

  const onSelectAll = (chapterId, topics) => {
    setSelectedChapter(prev => {
      const isAllSelected = prev[chapterId]?.length === topics?.length

      return {
        ...prev,
        [chapterId]: isAllSelected ? [] : topics.map(t => t.topicId),
      }
    })
  }

  const onClikBtn = (chapterId, topicId) => {
    setSelectedChapter(prev => {
      const isPresent = prev?.[chapterId]?.includes(topicId)
      const newTopics = prev?.[chapterId] || []

      return {
        ...prev,
        [chapterId]: isPresent
          ? newTopics.filter(n => n !== topicId)
          : [...newTopics, topicId],
      }
    })
  }

  if (isLoading) {
    return (
      <div className="manual-quest-wrapper d-flex align-items-center justify-content-center p-5 bg-F0F0F099 br-8px">
        <HashLoader size={30} color="#084353" sizeUnit="px" loading />
      </div>
    )
  }

  return (
    <>
      <div className="manual-quest-wrapper p-5 bg-F0F0F099 br-8px">
        <div className="mb-4 fs-18 fw-600 clr-201F1F">
          Select Chapter & Topic
        </div>

        {chapters.map(sub => (
          <div
            key={sub.masterSubjectId}
            className="p-4 mb-5 bg-ffffff9e br-5px"
          >
            <div
              className="d-flex align-items-center pb-3 cursor-pointer"
              onClick={() =>
                setTopOpen(p =>
                  p === sub.masterSubjectId ? "" : sub.masterSubjectId
                )
              }
            >
              <div>
                <p className="mb-0 fs-17 fw-500 clr-201F1F">
                  {sub.masterSubjectName}
                </p>
                <p className="mb-0 fs-13 clr-929292">
                  {sub.chapters.length} Chapters
                </p>
              </div>
              <Arrow
                className={`ml-auto mr-4 ${
                  topOpen === sub.masterSubjectId ? "rotate-Z-180" : ""
                }`}
              />
            </div>

            {topOpen === sub.masterSubjectId &&
              sub.chapters.map(c => (
                <div
                  key={c.chapterId}
                  className="mx-4 mb-3 br-5px"
                  style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.04)" }}
                >
                  <div
                    className="d-flex align-items-center p-3 bg-white br-5px gap-2rem cursor-pointer"
                    onClick={() =>
                      setOpen(p => (p === c.chapterId ? "" : c.chapterId))
                    }
                  >
                    <input
                      type="checkbox"
                      className="subject-checkbox cursor-pointer"
                      checked={!!selectedChapter[c.chapterId]?.length}
                      onChange={() => onSelectAll(c.chapterId, c.topics)}
                      onClick={e => e.stopPropagation()}
                    />
                    <div>
                      <p className="mb-0 fs-14 fw-500 clr-201F1F">
                        {c.chapterName}
                      </p>
                      <p className="mb-0 fs-13 clr-929292">
                        {c.topics.length} Topics
                      </p>
                    </div>
                    <Arrow
                      className={`ml-auto mr-4 ${
                        open === c.chapterId ? "rotate-Z-180" : ""
                      }`}
                    />
                  </div>

                  {open === c.chapterId && (
                    <div className="pt-2 pb-5 pl-5 pr-3 bg-EDEDED">
                      <div className="my-2 fs-14 clr-201F1F">
                        {c.topics.length > 0
                          ? "Select Topics"
                          : "No Topics found"}
                      </div>

                      <div
                        className="d-flex align-items-center flex-wrap"
                        style={{ gap: "14px 16px" }}
                      >
                        {c.topics.length > 0 && (
                          <button
                            className={`select-class-btn px-4 py-2 clr-201F1F br-5px ${
                              selectedChapter[c.chapterId]?.length ===
                              c.topics?.length
                                ? "active fw-500 clr-30876E"
                                : "bg-white"
                            }`}
                            onClick={() => onSelectAll(c.chapterId, c.topics)}
                          >
                            All Sub topics
                          </button>
                        )}
                        {c.topics.map(t => (
                          <button
                            key={t.topicId}
                            onClick={() => onClikBtn(c.chapterId, t.topicId)}
                            className={`select-class-btn px-4 py-2 clr-201F1F br-5px ${
                              selectedChapter?.[c.chapterId]?.includes(
                                t.topicId
                              )
                                ? "active fw-500 clr-30876E"
                                : "bg-white"
                            }`}
                          >
                            {t.topicName}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>
        ))}
      </div>

      <div className="d-flex align-items-center justify-content-center gap-18px mt-4">
        <button
          className="px-5 py-2 fs-16 fw-500 bg-F7F7F7 clr-8A8A8A br-8px"
          onClick={() => setStep(1)}
        >
          Back
        </button>

        <button
          className="dis-grayed px-5 py-2 fs-16 fw-500 bg-50AE93 text-white br-8px"
          onClick={() => setStep(3)}
          disabled={Object.values(selectedChapter).flat().length === 0}
        >
          Next
        </button>
      </div>
    </>
  )
}

export default Step2
