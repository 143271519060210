// @flow strict

import * as React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Button, Modal, FormGroup, Label, Input } from "reactstrap"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { HashLoader } from "react-spinners"
import AppWrapper from "../Wrapper"
import {
  getSingleQuiz,
  submitQuiz,
  getPresignedUrl,
} from "../../../actions/parent/QuizAction"
import { myLog } from "../../../utils/Utility"
import moment from "moment"
import { URL_PATH } from "../../../config/urlPath"
import { showNotification } from "../../../actions/CommonAction"
import {
  SUCCESS_TOAST,
  DANGER_TOAST,
  VALIDATION_MSG,
  MAX_FILE_UPLOAD,
} from "../../../utils/Constants"

const LEGENDS_COLORS = {
  ANSWERED: {
    bg: "#7ED5C5",
    font: "#236d66",
  },
  NOTANSWERED: {
    bg: "#F3A3B4",
    font: "#a03050",
  },
  NOTVISITED: {
    bg: "#E8E8E8",
    font: "#777777",
  },
  FLAGGED: {
    bg: "#B3C4EC",
    font: "#274882",
  },
}

const questionTypes = [
  { value: "TRUE_OR_FALSE", label: "True or false" },
  { value: "MULTIPLE_CHOICE", label: "Multiple choice" },
  { value: "MULTIPLE_ANSWER", label: "Multiple answer" },
  { value: "SHORT_ANSWER", label: "Short answer" },
  { value: "LONG_ANSWER", label: "Long answer" },
  { value: "MATCHING", label: "Matching" },
  { value: "FILL_IN_THE_BLANKS", label: "Fill in the blanks" },
  { value: "SEQUENCING", label: "Sequencing" },
]

class TakeAssessmentScreen extends React.Component {
  state = {
    isLoading: true,
    isSubmitting: false,
    isRead: false,
    isPreviewModalOpen: false,
    currentSection: 0,
    currentQuestion: 0,
    startedAt: moment(),
    attachments: {},
    id: "",
    leftTime: null,
    leftMinutes: null,
    timeLimit: "",
    immediateResult: "",
    submissionType: null,
    isSubmissionConfirmOpen: false,
    freezeQuizSubmission: false,
    takingStudentId: null,
  }

  componentDidMount = () => {
    window.history.pushState(null, document.title, window.location.href)
    window.addEventListener("popstate", function(event) {
      window.history.pushState(null, document.title, window.location.href)
    })
    if (!this.props.location.state) {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.SUCCESS,
        "Invalid Request"
      )
      return
    }
    const { assesment } = this.props.location.state || {}
    const { quizAssignInfoId } = assesment.quizSet
    this.props.getSingleQuiz(quizAssignInfoId, (success, response) => {
      if (success) {
        this.setState({
          takingStudentId: response.quizSubmissions[0]
            ? response.quizSubmissions[0].studentId
            : "",
        })
        // takingStudentId
        myLog("this.props.getSingleQuiz", this.state.takingStudentId)
        const { id, immediateResult, timeLimit, submissionType } = response
        // const startTime = moment(response.startTime)
        const startTimePlusMaxTime = moment().add(
          response.timeLimit.split(":")[1],
          "minutes"
        )
        const endTime = response.quizSubmissions[0].endTime
          ? moment(response.quizSubmissions[0].endTime)
          : moment(response.endTime)
        // const endTime = moment(response.endTime)
        const finalEndTime = endTime.isBefore(startTimePlusMaxTime)
          ? endTime
          : startTimePlusMaxTime

        const diffTime = finalEndTime.diff(moment())
        const quizDuration = Math.abs(
          Math.ceil(moment.duration(diffTime).asMinutes())
        )

        const defaultData = {
          currentSection: 0,
          currentQuestion: 0,
          answers: {},
          endTime: "",
        }
        const savedData = JSON.parse(
          localStorage.getItem(
            `assessment-${this.state.takingStudentId}-${quizAssignInfoId}`
          ) || JSON.stringify(defaultData)
        )
        const {
          answers: savedAnswers,
          endTime: savedEndTime,
          currentSection,
          currentQuestion,
        } = savedData

        let counter = 0

        const finalQuizSections = response.quizSet.quizSections.map(
          section => ({
            ...section,
            quizQuestions: response.shuffle
              ? this.shuffleQuestions(section.quizQuestions).map(ques => {
                  counter++
                  const saved = savedAnswers[ques.id] || {}
                  return {
                    ...ques,
                    possibleAnswers: ques.answers,
                    answers: ques.type === "MATCHING" ? [] : ques.answers || [],
                    ...saved,
                  }
                })
              : section.quizQuestions
                  .sort((a, b) => a.index - b.index)
                  .map(ques => {
                    counter++
                    const saved = savedAnswers[ques.id] || {}
                    return {
                      ...ques,
                      possibleAnswers: ques.answers,
                      answers:
                        ques.type === "MATCHING" ? [] : ques.answers || [],
                      ...saved,
                    }
                  }),
          })
        )

        this.setState({
          started: false,
          isLoading: false,
          isPreviewModalOpen: true,
          ...response,
          id,
          timeLimit,
          immediateResult,
          submissionType,
          duration: quizDuration,
          endTime: savedEndTime ? moment(savedEndTime) : finalEndTime,
          quizSections: finalQuizSections,
          startedAt: moment(),
          totalQuestions: counter,
          currentSection,
          currentQuestion,
        })
      }
    })
  }

  shuffleQuestions = array => {
    myLog("array before", array)
    for (var i = array.length - 1; i > 0; i--) {
      // Generate random number
      var j = Math.floor(Math.random() * (i + 1))

      var temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }
    myLog("array after", array)
    return array
  }

  onTimeLimitUp = () => {
    this.stopTimer()
    this.setState({ leftTime: "Time is up...", leftMinutes: 0 })
    this.onSubmit()
  }

  startTimer = endTime => {
    const timer = setInterval(() => {
      const diffTime = endTime.diff(moment())
      const formatted = moment(diffTime)
        .utc()
        .format("HH:mm:ss")
      const leftMinutes = Math.abs(
        Math.ceil(moment.duration(diffTime).asMinutes())
      )
      this.setState(
        { leftTime: formatted, leftMinutes },
        this.saveToLocalStorage
      )
      if (diffTime < 0) {
        this.onTimeLimitUp()
      }
    }, 1000)
    this.setState({ timer })
  }

  stopTimer = () => {
    const { timer } = this.state
    if (timer) {
      clearInterval(timer)
    }
  }

  showErrorNoti = message => {
    this.props.showNotification(DANGER_TOAST, VALIDATION_MSG.OOPS, message)
  }

  uploadFile = file => {
    return new Promise(async (resolve, reject) => {
      myLog("fileUpload: ", file)
      this.props.getPresignedUrl(file.name, async (success, resp) => {
        myLog("this.props.getPresignedUrl", success, resp)
        if (success) {
          const url = resp.data.url
          const xhr = new XMLHttpRequest()
          xhr.open("put", url, true)
          xhr.setRequestHeader("Content-Type", "application/pdf")
          xhr.onload = () => {
            if (xhr.status === 200) {
              resolve(resp.data.name)
            }
          }
          xhr.onerror = () => {
            reject({ error: true })
          }
          xhr.send(file)
        }
      })
    })
  }

  onSubmit = async () => {
    this.setState({ isSubmitting: true, freezeQuizSubmission: true })
    let {
      startedAt,
      quizSections,
      quizSet: { quizAssignInfoId },
    } = this.state
    let diff = new Date(moment().diff(startedAt, "ms", true))
    let timeTaken = diff
      .toJSON()
      .split("T")[1]
      .split(".")[0]

    let quizSubmissionAnswers = []
    for (let section of quizSections) {
      for (let question of section.quizQuestions) {
        const { id, type, answers, viewed, flagged, studAttachment } = question
        const attachment = studAttachment
          ? await this.uploadFile(studAttachment)
          : ""
        if (type === "LONG_ANSWER" || type === "SHORT_ANSWER") {
          quizSubmissionAnswers.push({
            quizQuestionId: id,
            longAnswer: answers[0],
            viewed: viewed ? "1" : "0",
            flagged: flagged ? "1" : "0",
            attachment,
          })
        } else {
          quizSubmissionAnswers.push({
            answers,
            quizQuestionId: id,
            viewed: viewed ? "1" : "0",
            flagged: flagged ? "1" : "0",
            attachment,
          })
        }
      }
    }

    const data = { timeTaken, quizSubmissionAnswers }
    // myLog("data", data)
    this.setState({ isSubmitting: true })
    this.props.submitQuiz(quizAssignInfoId, data, (success, response) => {
      this.setState({ isSubmitting: false })
      myLog("this.props.submitQuiz", success, response)
      if (success) {
        this.props.showNotification(
          SUCCESS_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Successfully Submitted"
        )
        this.stopTimer()
        const {
          quizSet: { quizAssignInfoId },
        } = this.state
        localStorage.removeItem(
          `assessment-${this.state.takingStudentId}-${quizAssignInfoId}`
        )
        if (this.state.immediateResult) {
          const isOnline = this.state.submissionType === "ONLINE"
          this.props.history.push({
            pathname: URL_PATH.STUDENT_TESTS_REPORT,
            state: { quizAssignInfoId: quizAssignInfoId, isOnline },
          })
        } else {
          this.props.history.push(URL_PATH.PARENT_STUDENT_TESTS)
        }
      }
    })
  }

  bytesToSize = bytes => {
    let sizes = ["Bytes", "KB", "MB", "GB", "TB"]
    if (bytes === 0) return "0 Byte"
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i]
  }

  calcTotalMarks = sectionIndex => {
    return this.state.quizSections[sectionIndex].quizQuestions.reduce(
      (val, que) => {
        // const add = typeof que.mark === "number" ? que.mark : parseInt(que.mark)
        return val + que.mark
      },
      0
    )
  }

  calcQueNumber = (
    sectionNo,
    questionNo,
    quizSections = this.state.quizSections
  ) => {
    let number = 0

    let sectionIndex = 0
    for (const section of quizSections) {
      let questionIndex = 0
      let tobreak = false
      for (const question of section.quizQuestions) {
        number++
        if (sectionIndex === sectionNo && questionIndex === questionNo) {
          tobreak = true
          break
        }

        questionIndex++
      }
      if (tobreak) break

      sectionIndex++
    }

    return number
  }

  onDragEnd = result => {
    const { destination, source } = result
    const { currentQuestion, currentSection } = this.state

    if (
      !destination ||
      (destination.draggableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return
    }

    if (destination.droppableId === "SEQUENCING") {
      let quizSections = this.state.quizSections
      let selectedQuestion =
        quizSections[currentSection].quizQuestions[currentQuestion]

      selectedQuestion.answers.splice(
        destination.index,
        0,
        selectedQuestion.answers.splice(source.index, 1)[0]
      )

      quizSections[currentSection].quizQuestions[
        currentQuestion
      ] = selectedQuestion
      this.setState({ quizSections })
    } else if (
      ["answers", "possibleAnswers"].includes(destination.droppableId) &&
      destination.droppableId !== source.droppableId
    ) {
      let quizSections = this.state.quizSections
      let selectedQuestion =
        quizSections[currentSection].quizQuestions[currentQuestion]

      selectedQuestion[destination.droppableId][
        destination.index
      ] = selectedQuestion[source.droppableId].splice(source.index, 1)[0]

      quizSections[currentSection].quizQuestions[
        currentQuestion
      ] = selectedQuestion
      this.setState({ quizSections })
    } else if (
      ["answers", "possibleAnswers"].includes(destination.droppableId) &&
      destination.droppableId === source.droppableId
    ) {
      let quizSections = this.state.quizSections
      let selectedQuestion =
        quizSections[currentSection].quizQuestions[currentQuestion]

      selectedQuestion[destination.droppableId].splice(
        destination.index,
        0,
        selectedQuestion[source.droppableId].splice(source.index, 1)[0]
      )

      quizSections[currentSection].quizQuestions[
        currentQuestion
      ] = selectedQuestion

      this.setState({ quizSections })
    }
  }

  downloadFile = ({ url, name }) => {
    const xhr = new XMLHttpRequest()
    xhr.onload = () => {
      const file = new Blob([xhr.response])
      const browserUrl = window.webkitURL || window.URL
      const fileUrl = browserUrl.createObjectURL(file)
      const a = document.createElement("a")
      a.href = fileUrl
      a.download = name
      a.click()
      browserUrl.revokeObjectURL(fileUrl)
    }
    xhr.open("GET", url, true)
    xhr.responseType = "blob"
    xhr.send(null)
  }

  /**
   * Render question icon for sidebar
   * @param {number} number
   * @param {'ANSWERED'|'NOTANSWERED'|'NOTVISITED'|'FLAGGED'} type
   * @param {true|false} isSelected
   * @param {Function} onClick
   */
  renderQuestionIcon = (number, type, isSelected, onClick) => (
    <div
      key={number}
      className="m-2 cursor-pointer"
      style={{
        borderRadius: 16,
        border: "3px solid transparent",
        borderColor: isSelected ? LEGENDS_COLORS[type].bg : "transparent",
      }}
      onClick={() => onClick && onClick(number)}
    >
      <div
        style={{
          borderRadius: 12,
          backgroundColor: LEGENDS_COLORS[type].bg,
          margin: 2,
          width: 35,
          height: 35,
          display: "flex",
        }}
      >
        <div
          className="font-weight-bold m-auto"
          style={{
            color: LEGENDS_COLORS[type].font,
            fontSize: "1.2rem",
          }}
        >
          {number}
        </div>
      </div>
    </div>
  )

  renderQuestionItem = selectedQuestion => {
    const { currentQuestion, currentSection } = this.state
    if (!selectedQuestion.viewed) {
      let quizSections = this.state.quizSections
      quizSections[currentSection].quizQuestions[currentQuestion].viewed = 1
      this.setState({ quizSections })
    }

    if (selectedQuestion.type === "TRUE_OR_FALSE") {
      const isTrueSelected =
        selectedQuestion.answers && selectedQuestion.answers[0] === "true"
      const isFalseSelected =
        selectedQuestion.answers && selectedQuestion.answers[0] === "false"

      return (
        <div>
          <h4 className="text-dark mb-4">{selectedQuestion.text}</h4>

          <div
            className="rounded px-3 py-3 d-flex align-items-center mt-3"
            style={{
              cursor: "pointer",
              border: "1px solid #CAEAE5",
              backgroundColor: isTrueSelected && "#CAEAE5",
            }}
            onClick={() => {
              let quizSections = this.state.quizSections
              quizSections[currentSection].quizQuestions[
                currentQuestion
              ].answers = ["true"]
              this.setState({ quizSections })
            }}
          >
            <i
              className={`text-muted ${
                isTrueSelected ? "far fa-dot-circle" : "far fa-circle"
              }`}
            />
            <div className="ml-4">True</div>
          </div>

          <div
            className="rounded px-3 py-3 d-flex align-items-center mt-3"
            style={{
              cursor: "pointer",
              border: "1px solid #CAEAE5",
              backgroundColor: isFalseSelected && "#CAEAE5",
            }}
            onClick={() => {
              let quizSections = this.state.quizSections
              quizSections[currentSection].quizQuestions[
                currentQuestion
              ].answers = ["false"]
              this.setState({ quizSections })
            }}
          >
            <i
              className={`text-muted ${
                isFalseSelected ? "far fa-dot-circle" : "far fa-circle"
              }`}
            />
            <div className="ml-4">False</div>
          </div>
        </div>
      )
    }

    if (["LONG_ANSWER", "SHORT_ANSWER"].includes(selectedQuestion.type)) {
      return (
        <div>
          <h4 className="text-dark mb-4">{selectedQuestion.text}</h4>

          <div style={{ position: "relative" }}>
            <textarea
              placeholder="Enter your answer"
              id="answer"
              name="answer"
              className="bg-white"
              rows={selectedQuestion.type === "LONG_ANSWER" ? 8 : 3}
              style={{
                backgroundColor: "transparent",
                padding: "0.375rem 0.75rem",
                border: "1px solid #e7e7e7",
                width: "100%",
                borderRadius: 5,
              }}
              value={
                selectedQuestion.answers
                  ? selectedQuestion.answers[0] || ""
                  : ""
              }
              onChange={e => {
                const value = e.target.value
                let quizSections = this.state.quizSections
                quizSections[currentSection].quizQuestions[
                  currentQuestion
                ].answers = [value]
                this.setState({ quizSections })
              }}
            >
              {selectedQuestion.answers
                ? selectedQuestion.answers[0] || ""
                : ""}
            </textarea>
            <div
              style={{
                position: "relative",
              }}
            >
              <Button
                color={selectedQuestion.studAttachment ? "info" : "light"}
                size="sm"
                className="rounded-pill px-3 ml-auto mt-2"
                style={{ position: "relative" }}
              >
                <i className="fas fa-paperclip"></i>
                <span className="ml-2">
                  {selectedQuestion.studAttachment
                    ? selectedQuestion.studAttachment.name
                    : "Attach files"}
                </span>
                <input
                  type="file"
                  accept=".pdf,.gif,.jpg,.jpeg,.png"
                  style={{
                    opacity: 0,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  onChange={e => {
                    const selectedFile = e.target.files[0]
                    myLog("selectedFile", selectedFile)
                    if (selectedFile.size > MAX_FILE_UPLOAD) {
                      this.showErrorNoti("File is too big (limit: 50MB)")
                      return
                    }

                    if (
                      selectedFile.name.endsWith(".pdf") ||
                      selectedFile.name.endsWith(".gif") ||
                      selectedFile.name.endsWith(".jpg") ||
                      selectedFile.name.endsWith(".jpeg") ||
                      selectedFile.name.endsWith(".png")
                    ) {
                      const quizSections = this.state.quizSections
                      quizSections[currentSection].quizQuestions[
                        currentQuestion
                      ].studAttachment = selectedFile
                      this.setState({ quizSections })
                    } else {
                      this.props.showNotification(
                        DANGER_TOAST,
                        VALIDATION_MSG.OOPS,
                        "Attach a pdf/image file..."
                      )
                    }
                  }}
                />
              </Button>
              {selectedQuestion.studAttachment &&
              selectedQuestion.studAttachment.name !== "" ? (
                <span
                  className="px-2 py-1 mt-2 cursor-pointer"
                  onClick={() => {
                    const quizSections = this.state.quizSections
                    quizSections[currentSection].quizQuestions[
                      currentQuestion
                    ].studAttachment = ""
                    this.setState({ quizSections })
                  }}
                >
                  <i className="fas fa-times text-muted mt-2" />
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )
    }

    if (selectedQuestion.type === "SEQUENCING") {
      return (
        <div>
          <h4 className="text-dark mb-4">{selectedQuestion.text}</h4>

          <div className="d-flex align-items-center mb-1">
            <div className="d-flex align-items-center justify-content-center">
              <div
                className="text-muted"
                style={{ fontSize: "1rem", width: 40 }}
              >
                Order
              </div>
            </div>
            <div style={{ flex: 1, marginLeft: 12 }}>
              <p className="m-0 ml-2 text-muted">
                Drag & drop answer choices to matching answers
              </p>
            </div>
          </div>

          <div className="d-flex align-items-start">
            <div className="mr-3">
              {selectedQuestion.questions.map((ques, index) => (
                <div
                  className="d-flex align-items-center justify-content-center mb-2"
                  key={ques}
                >
                  <div
                    className="text-dark d-flex align-items-center justify-content-center border border-muted"
                    style={{
                      borderRadius: 8,
                      width: 40,
                      height: 40,
                      backgroundColor: "transparent",
                    }}
                  >
                    {index + 1}
                  </div>
                </div>
              ))}
            </div>

            <Droppable droppableId="SEQUENCING">
              {provided => (
                <div
                  style={{ flex: 1 }}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {selectedQuestion.answers.map((ques, index) => (
                    <Draggable draggableId={ques} index={index} key={ques}>
                      {provided => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <div
                            className="d-flex align-items-center px-3 border border-muted mb-2"
                            style={{
                              height: 40,
                              overflow: "hidden",
                              borderRadius: 8,
                              backgroundColor: "#fafafa",
                            }}
                          >
                            <i
                              className="fas fa-grip-vertical mr-3"
                              style={{
                                color: "rgba(0,0,0,0.7)",
                                fontSize: 20,
                              }}
                            />
                            <span>{ques}</span>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
      )
    }

    if (
      ["MULTIPLE_ANSWER", "MULTIPLE_CHOICE"].includes(selectedQuestion.type)
    ) {
      return (
        <div>
          <h4 className="text-dark mb-4">{selectedQuestion.text}</h4>

          {selectedQuestion.questions.map((que, index) => {
            const isSelected = selectedQuestion.answers.find(val => val === que)

            const icon =
              selectedQuestion.type === "MULTIPLE_CHOICE"
                ? isSelected
                  ? "far fa-dot-circle"
                  : "far fa-circle"
                : isSelected
                ? "fas fa-check-square"
                : "far fa-square"

            const onClick = () => {
              if (selectedQuestion.type === "MULTIPLE_CHOICE" && isSelected)
                return
              else if (
                selectedQuestion.type === "MULTIPLE_CHOICE" &&
                !isSelected
              ) {
                let quizSections = this.state.quizSections
                quizSections[currentSection].quizQuestions[
                  currentQuestion
                ].answers = [que]
                this.setState({ quizSections })
              } else if (
                selectedQuestion.type === "MULTIPLE_ANSWER" &&
                !isSelected
              ) {
                let quizSections = this.state.quizSections
                quizSections[currentSection].quizQuestions[
                  currentQuestion
                ].answers.push(que)
                this.setState({ quizSections })
              } else if (
                selectedQuestion.type === "MULTIPLE_ANSWER" &&
                isSelected
              ) {
                let quizSections = this.state.quizSections
                quizSections[currentSection].quizQuestions[
                  currentQuestion
                ].answers = quizSections[currentSection].quizQuestions[
                  currentQuestion
                ].answers.filter(val => val !== que)
                this.setState({ quizSections })
              }
            }

            return (
              <div
                key={index}
                className={`text-dark d-flex align-items-center mb-2 px-3 py-3 border border-muted`}
                style={{
                  cursor: "pointer",
                  backgroundColor: isSelected && "#CAEAE5",
                  borderRadius: 8,
                }}
                onClick={onClick}
              >
                <i className={`text-muted ${icon}`} />
                <span className="ml-4">{que}</span>
              </div>
            )
          })}
        </div>
      )
    }

    if (selectedQuestion.type === "FILL_IN_THE_BLANKS") {
      return (
        <div>
          <div className="d-flex align-items-center">
            <h4 className="w-100 text-dark mb-0">
              {selectedQuestion.text.split("____")[0]}
              <input
                name="answer"
                id="answer"
                className="form-control mx-2"
                placeholder="Your answer..."
                style={{
                  width: 150,
                  backgroundColor: "transparent",
                  display: "inline",
                }}
                value={
                  selectedQuestion.answers
                    ? selectedQuestion.answers[0] || ""
                    : ""
                }
                onChange={e => {
                  const value = e.target.value
                  if (value.trim() !== "" || value === "") {
                    let quizSections = this.state.quizSections
                    quizSections[currentSection].quizQuestions[
                      currentQuestion
                    ].answers = [value]
                    this.setState({ quizSections })
                  }
                }}
              />
              {selectedQuestion.text.split("____")[1]}
            </h4>
          </div>
        </div>
      )
    }

    if (selectedQuestion.type === "MATCHING") {
      return (
        <div>
          <h4 className="text-dark mb-2">{selectedQuestion.text}</h4>

          <div className="d-flex">
            <div style={{ flex: 2 }}>
              <p className="m-0 mb-2 text-muted">
                Drag & drop answer choices to matching answers
              </p>

              <div
                className="px-2 py-2 rounded d-flex align-items-strech justify-content-around mb-2"
                style={{
                  width: "50%",
                  minWidth: "min(100%, 500px)",
                }}
              >
                <div style={{ flex: 1 }}>
                  {selectedQuestion.questions.map((ques, index) => (
                    <div
                      className="bg-green ml-2 text-center d-flex flex-column mr-2 mb-2"
                      style={{
                        flex: 1,
                        overflow: "hidden",
                        borderRadius: 8,
                        height: 52,
                      }}
                    >
                      <div
                        className="text-dark px-4 py-3 border border-muted d-flex align-items-center justify-content-center"
                        style={{
                          flex: 1,
                          borderRadius: 8,
                          backgroundColor: "#fff",
                        }}
                      >
                        {ques}
                      </div>
                    </div>
                  ))}
                </div>

                <Droppable droppableId="answers">
                  {provided => (
                    <div
                      style={{ flex: 1 }}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {selectedQuestion.questions.map((abs, index) => {
                        const ans = selectedQuestion.answers[index]
                        return (
                          <Draggable
                            draggableId={ans || abs}
                            index={index}
                            key={ans || abs}
                          >
                            {(provided, snapshot) => (
                              <div
                                className="text-dark d-flex align-items-center justify-content-start mb-2 matching-ans"
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                {...{ style: snapshot.isDragging ? {} : {} }}
                              >
                                <div
                                  className="text-dark px-4 py-3 d-flex align-items-center justify-content-center"
                                  style={{
                                    flex: 1,
                                    borderRadius: 8,
                                    height: 52,
                                    border: "2px dotted #eaeaea",
                                    backgroundColor: "#fff",
                                  }}
                                >
                                  {ans}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </div>

            <div style={{ flex: 1, marginLeft: 12 }}>
              <p className="m-0 mb-2 text-muted">Answers</p>

              <Droppable droppableId="possibleAnswers">
                {provided => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {selectedQuestion.possibleAnswers.map((ans, index) => (
                      <Draggable draggableId={ans} index={index} key={ans}>
                        {provided => (
                          <div
                            className="text-dark px-4 py-3 border border-muted d-flex align-items-center justify-content-start mb-2 matching-ans"
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <i
                              className="fas fa-grip-vertical mr-3"
                              style={{
                                color: "rgba(0,0,0,0.7)",
                                fontSize: 20,
                              }}
                            />
                            <span>{ans}</span>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </div>
        </div>
      )
    }
  }

  renderTakeQuiz = () => {
    if (!this.state.quizSections) return null

    const selectedSection = this.state.quizSections[this.state.currentSection]

    if (!selectedSection) return null
    const selectedQuestions = selectedSection.quizQuestions
    const selectedQuestion = selectedQuestions[this.state.currentQuestion]

    if (!selectedQuestion) return null

    const isFirstQuestion =
      this.state.currentQuestion === 0 && this.state.currentSection === 0
    const isLastQuestion =
      this.state.currentSection === this.state.quizSections.length - 1 &&
      this.state.currentQuestion === selectedSection.quizQuestions.length - 1

    let stats = {
      answered: 0,
      notanswered: 0,
      notvisited: 0,
      flagged: 0,
    }

    for (let section of this.state.quizSections) {
      for (let question of section.quizQuestions) {
        question.flagged
          ? stats.flagged++
          : question.viewed &&
            ((question.type !== "SEQUENCING" &&
              question.answers.length === 0) ||
              (question.type !== "SEQUENCING" && question.answers[0] === "") ||
              (question.type === "SEQUENCING" &&
                JSON.stringify(question.answers) ===
                  JSON.stringify(JSON.parse(question.allAnswers))))
          ? stats.notanswered++
          : question.viewed
          ? stats.answered++
          : stats.notvisited++
      }
    }

    return (
      <>
        <div className="mt-2">
          <div
            className="d-flex align-items-center px-3 py-4"
            style={{ borderRadius: 8, backgroundColor: "#f7f7f7" }}
          >
            <div className="text-brand font-weight-bold m-0">
              {this.state.quizSet && this.state.quizSet.title}
            </div>
            <i
              className="fas fa-info-circle text-green ml-3 cursor-pointer"
              style={{ fontSize: "1.2rem" }}
              onClick={() =>
                this.setState({ isPreviewModalOpen: true, infoModal: true })
              }
            />
          </div>

          <div
            className="d-flex align-items-center"
            style={{ borderBottom: "1px solid #eaeaea" }}
          >
            {this.state.quizSet && this.state.quizSet.hasSection
              ? this.state.quizSections &&
                this.state.quizSections
                  .sort((a, b) => a.index > b.index)
                  .map((section, index) => {
                    const isSelected = index === this.state.currentSection

                    return (
                      <div
                        key={index}
                        onClick={() =>
                          this.setState({
                            currentSection: index,
                            currentQuestion: 0,
                          })
                        }
                        className={`py-2 px-3 cursor-pointer ${
                          isSelected ? "text-green" : "text-muted"
                        }`}
                        style={{
                          borderBottom: `4px solid ${
                            isSelected ? "#04b191" : "transparent"
                          }`,
                        }}
                      >
                        {section.title}
                      </div>
                    )
                  })
              : null}
            <div className="text-muted py-2 ml-auto mr-3">
              Total Questions: {this.state.totalQuestions}
            </div>
            <div className="text-muted py-2">
              Total Marks: {this.state.quizSet && this.state.quizSet.totalMark}
            </div>
          </div>

          <div
            className="row"
            style={{
              minHeight: "max(50vh, 400px)",
              marginLeft: 0,
              marginRight: 0,
              backgroundColor: "rgba(0, 0, 0, 0.02)",
            }}
          >
            <div className="col-md-8 py-4 px-3">
              <div className="d-flex align-items-center">
                <div
                  className="bg-brand text-light d-flex align-items-center justify-content-center rounded-pill"
                  style={{ width: 34, height: 34 }}
                >
                  Q
                  {this.calcQueNumber(
                    this.state.currentSection,
                    this.state.currentQuestion,
                    this.state.quizSections
                  )}
                </div>
                <div className="mx-2" style={{ flex: 1 }}>
                  <div className="text-muted">
                    {
                      questionTypes.find(
                        ({ value }) => value === selectedQuestion.type
                      ).label
                    }{" "}
                    ({selectedQuestion.mark} marks)
                  </div>
                </div>
                <div className="mr-2">
                  <button
                    className={`px-4 py-1 mr-2 ${
                      selectedQuestion.flagged === 1
                        ? "text-primary"
                        : "text-muted"
                    } d-flex align-items-center`}
                    style={{ borderRadius: 12 }}
                    onClick={() => {
                      let quizSections = this.state.quizSections
                      quizSections[this.state.currentSection].quizQuestions[
                        this.state.currentQuestion
                      ].flagged = selectedQuestion.flagged ? 0 : 1
                      this.setState({ quizSections })
                    }}
                  >
                    <i
                      className={`${
                        selectedQuestion.flagged === 1 ? "fas" : "far"
                      } fa-bookmark`}
                    ></i>
                    <span className="ml-2">Flag</span>
                  </button>
                </div>
              </div>

              <div className="pt-5 mt-3 pb-2">
                {this.renderQuestionItem(selectedQuestion)}
              </div>

              {selectedQuestion.attachment && (
                <>
                  <div className="text-dark mt-4">Teacher Attachment</div>
                  <div
                    className="d-flex align-items-center cursor-pointer rounded mt-2"
                    style={{ maxWidth: 280, backgroundColor: "#ececec" }}
                    onClick={() =>
                      this.downloadFile(selectedQuestion.attachment)
                    }
                  >
                    <div className="p-3 bg-brand rounded">
                      {selectedQuestion.attachment.name &&
                      (selectedQuestion.attachment.name.endsWith(".jpeg") ||
                        selectedQuestion.attachment.name.endsWith(".jpg")) ? (
                        <img
                          src={require("../../../assets/images/assignment/JPG.png")}
                          alt="Attachment"
                          style={{ height: 25, width: 25 }}
                        />
                      ) : selectedQuestion.attachment.name &&
                        selectedQuestion.attachment.name.endsWith(".png") ? (
                        <img
                          src={require("../../../assets/images/assignment/PNG.png")}
                          alt="Attachment"
                          style={{ height: 25, width: 25 }}
                        />
                      ) : selectedQuestion.attachment.name &&
                        selectedQuestion.attachment.name.endsWith(".pdf") ? (
                        <img
                          src={require("../../../assets/images/assignment/PDF.png")}
                          alt="Attachment"
                          style={{ height: 25, width: 25 }}
                        />
                      ) : (
                        <img
                          src={require("../../../assets/images/assignmentNotification.png")}
                          alt="Attachment"
                          style={{ height: 25, width: 25 }}
                        />
                      )}
                    </div>
                    <div className="px-2 text-tuncate">
                      {selectedQuestion.attachment.name
                        ? selectedQuestion.attachment.name
                        : ""}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="col-md-4 border-left border-muted px-4 py-4 d-flex flex-column">
              <div className="d-flex align-items-center mb-2">
                {this.state.quizSet && this.state.quizSet.hasSection ? (
                  <h5 className="text-brand mr-auto mb-0">
                    {selectedSection.title} (
                    {this.calcTotalMarks(this.state.currentSection)} Marks)
                  </h5>
                ) : (
                  <h5 className="text-brand mr-auto mb-0">Questions</h5>
                )}
              </div>

              <div className="my-1 d-flex flex-wrap align-items-center mx-auto">
                {selectedSection.quizQuestions.map((ques, index) =>
                  this.renderQuestionIcon(
                    this.calcQueNumber(
                      this.state.currentSection,
                      index,
                      this.state.quizSections
                    ),
                    ques.flagged
                      ? "FLAGGED"
                      : ques.viewed &&
                        ((ques.type !== "SEQUENCING" &&
                          ques.answers.length === 0) ||
                          (ques.type !== "SEQUENCING" &&
                            ques.answers[0] === "") ||
                          (ques.type === "SEQUENCING" &&
                            JSON.stringify(ques.answers) ===
                              JSON.stringify(JSON.parse(ques.allAnswers))))
                      ? "NOTANSWERED"
                      : ques.viewed
                      ? "ANSWERED"
                      : "NOTVISITED",
                    index === this.state.currentQuestion,
                    () => this.setState({ currentQuestion: index })
                  )
                )}
              </div>

              <h5 className="text-brand mr-auto">Legends & Stats</h5>

              <div className="my-1">
                <div className="row">
                  <div className="col d-flex align-items-center">
                    {this.renderQuestionIcon(stats.answered, "ANSWERED", false)}
                    <p className="m-0">Answered</p>
                  </div>

                  <div className="col d-flex align-items-center">
                    {this.renderQuestionIcon(
                      stats.notanswered,
                      "NOTANSWERED",
                      false
                    )}
                    <p className="m-0">Not Answered</p>
                  </div>
                </div>

                <div className="row">
                  <div className="col d-flex align-items-center">
                    {this.renderQuestionIcon(
                      stats.notvisited,
                      "NOTVISITED",
                      false
                    )}
                    <p className="m-0">Not Visited</p>
                  </div>

                  <div className="col d-flex align-items-center">
                    {this.renderQuestionIcon(stats.flagged, "FLAGGED", false)}
                    <p className="m-0">Flagged</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-8">
              <div className="d-flex align-items-center my-4">
                <button
                  className="btn px-4 py-1 mr-2"
                  style={{
                    borderRadius: 12,
                    color: "#23847b",
                    backgroundColor: "rgba(6, 177, 145, 0.19)",
                  }}
                  disabled={isFirstQuestion}
                  onClick={() => {
                    if (this.state.currentQuestion === 0) {
                      const newSectionIndex = this.state.currentSection - 1
                      this.setState({
                        currentSection: newSectionIndex,
                        currentQuestion:
                          this.state.quizSections[newSectionIndex].quizQuestions
                            .length - 1,
                      })
                    } else {
                      this.setState({
                        currentQuestion: this.state.currentQuestion - 1,
                      })
                    }
                  }}
                >
                  Previous
                </button>
                <button
                  className="btn btn-light px-4 py-1"
                  style={{ borderRadius: 12 }}
                  // disabled={selectedQuestion.answers.length === 0}
                  onClick={() => {
                    // let quizSections = this.state.quizSections
                    let quizSections = this.state.quizSections
                    if (
                      quizSections[this.state.currentSection].quizQuestions[
                        this.state.currentQuestion
                      ].type === "SEQUENCING"
                    ) {
                      quizSections[this.state.currentSection].quizQuestions[
                        this.state.currentQuestion
                      ].answers = JSON.parse(
                        quizSections[this.state.currentSection].quizQuestions[
                          this.state.currentQuestion
                        ].allAnswers
                      )
                    } else if (
                      quizSections[this.state.currentSection].quizQuestions[
                        this.state.currentQuestion
                      ].type === "MATCHING"
                    ) {
                      // quizSections[this.state.currentSection].quizQuestions[
                      //   this.state.currentQuestion
                      // ].possibleAnswers = [
                      //   ...quizSections[this.state.currentSection]
                      //     .quizQuestions[this.state.currentQuestion]
                      //     .possibleAnswers,
                      //   ...quizSections[
                      //     this.state.currentSection
                      //   ].quizQuestions[
                      //     this.state.currentQuestion
                      //   ].answers.filter(val => !!val),
                      // ]

                      quizSections[this.state.currentSection].quizQuestions[
                        this.state.currentQuestion
                      ].possibleAnswers = JSON.parse(
                        quizSections[this.state.currentSection].quizQuestions[
                          this.state.currentQuestion
                        ].allAnswers
                      )

                      quizSections[this.state.currentSection].quizQuestions[
                        this.state.currentQuestion
                      ].answers = []

                      quizSections[this.state.currentSection].quizQuestions[
                        this.state.currentQuestion
                      ].questions =
                        quizSections[this.state.currentSection].quizQuestions[
                          this.state.currentQuestion
                        ].questions
                    } else {
                      quizSections[this.state.currentSection].quizQuestions[
                        this.state.currentQuestion
                      ].answers = []
                    }
                    this.setState({
                      quizSections,
                      currentQuestion: this.state.currentQuestion,
                    })
                  }}
                >
                  Clear
                </button>

                <button
                  className={`btn text-light px-4 py-1 ml-auto bg-${
                    isLastQuestion ? "secondary" : "green"
                  }`}
                  style={{ borderRadius: 12 }}
                  disabled={isLastQuestion}
                  onClick={() => {
                    if (
                      this.state.currentQuestion + 1 ===
                      selectedSection.quizQuestions.length
                    ) {
                      this.setState({
                        currentSection: this.state.currentSection + 1,
                        currentQuestion: 0,
                      })
                    } else {
                      this.setState({
                        currentQuestion: this.state.currentQuestion + 1,
                      })
                    }
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  renderInstructions = () => {
    const quizSet = this.state.quizSet || {}
    const { started, endTime, infoModal } = this.state
    // duration,

    return (
      <Modal
        isOpen={this.state.isPreviewModalOpen}
        backdropClassName="modal-backdrop-show"
        modalClassName="modal-dialog-shadow-none"
        contentClassName="border-14px"
        centered={true}
      >
        <div className="modal-body p-0">
          <div
            className="bg-brand text-light d-flex"
            style={{
              padding: "0.5rem 2rem",
              borderTopRightRadius: "14px",
              borderTopLeftRadius: "14px",
            }}
          >
            <div>
              Duration: {moment.duration(this.state.timeLimit).asMinutes()}{" "}
              Mins.
            </div>
            <div className="mx-auto">
              Total Questions: {this.state.totalQuestions}
            </div>
            <div>Total Marks: {quizSet.totalMark}</div>
          </div>

          <div style={{ padding: "2rem" }}>
            <h5
              className="text-brand font-weight-bold"
              style={{ lineBreak: "anywhere" }}
            >
              {quizSet.title}
            </h5>
            <p className="mt-4">{quizSet.instruction}</p>

            {this.state.quizSet && this.state.quizSet.hasSection
              ? this.state.quizSections &&
                this.state.quizSections
                  .sort((a, b) => a.index > b.index)
                  .map((section, index) => (
                    <div
                      className="border-top border-muted py-3 mt-3"
                      key={index}
                    >
                      <div className="d-flex align-items-center">
                        <h5
                          className="text-brand m-0"
                          style={{ width: "30%", wordBreak: "break-all" }}
                        >
                          {section.title}
                        </h5>
                        <div
                          className="mx-auto text-muted font-weight-bold"
                          style={{ width: "30%" }}
                        >
                          {section.quizQuestions.length} Questions
                        </div>
                        <div
                          className="d-flex align-items-center"
                          style={{ width: "30%" }}
                        >
                          <div
                            className="d-flex align-items-center justify-content-center"
                            style={{
                              backgroundColor: "rgba(0,0,0,0.2)",
                              height: "2rem",
                              width: "2rem",
                              borderRadius: 6,
                            }}
                          >
                            {this.calcTotalMarks(index)}
                          </div>
                          <div className="ml-2">Marks</div>
                        </div>
                      </div>
                      <p className="m-0 mt-3" style={{ lineBreak: "anywhere" }}>
                        {section.description}
                      </p>
                    </div>
                  ))
              : null}

            <div className="border-top border-muted my-3" />

            {infoModal ? (
              <div className="d-flex justify-content-end mt-3">
                <button
                  className="btn btn-secondary px-3 py-1"
                  style={{ borderRadius: 12 }}
                  onClick={() => {
                    this.setState({ isPreviewModalOpen: false })
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <form
                onSubmit={e => {
                  e.preventDefault()
                  this.setState({ isPreviewModalOpen: false })
                  if (!started) {
                    this.startTimer(endTime)
                    this.setState({ started: true })
                  }
                }}
              >
                <FormGroup check>
                  <Input
                    id="read"
                    name="read"
                    type="checkbox"
                    value={this.state.isRead}
                    onChange={e => this.setState({ isRead: e.target.checked })}
                    required
                  />
                  <Label htmlFor="read" check className="text-muted">
                    I have read and understood the given instructions
                  </Label>
                </FormGroup>

                <div className="d-flex justify-content-end mt-3">
                  <button
                    className="btn btn-secondary px-3 py-1"
                    style={{ borderRadius: 12 }}
                    type="submit"
                  >
                    Start Quiz
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </Modal>
    )
  }

  renderSubmissionConfirm = () => {
    let stats = {
      answered: 0,
      notanswered: 0,
      notvisited: 0,
      flagged: 0,
    }

    if (this.state.quizSections) {
      for (let section of this.state.quizSections) {
        for (let question of section.quizQuestions) {
          question.flagged
            ? stats.flagged++
            : question.viewed &&
              (question.answers.length === 0 || question.answers[0] === "")
            ? stats.notanswered++
            : question.viewed
            ? stats.answered++
            : stats.notvisited++
        }
      }
    }

    return (
      <Modal
        isOpen={this.state.isSubmissionConfirmOpen}
        backdropClassName="modal-backdrop-show"
        modalClassName="modal-dialog-shadow-none"
        contentClassName="border-14px"
        centered={true}
      >
        <div className="modal-body p-0">
          <div className="d-flex">
            <div className="mx-auto mt-2">
              <img
                src={require("../../../assets/images/confirm_submit.png")}
                alt="Confirm submission"
                style={{ width: 50 }}
              />
            </div>
          </div>

          <div className="mt-2">
            <h5
              className="text-brand font-weight-bold"
              style={{ lineBreak: "anywhere", textAlign: "center" }}
            >
              Heads up! Do you want to Submit?
            </h5>
            <div className="my-5 mx-5">
              <div className="row">
                <div className="col d-flex align-items-center">
                  {this.renderQuestionIcon(stats.answered, "ANSWERED", false)}
                  <p className="m-0">Answered</p>
                </div>

                <div className="col d-flex align-items-center">
                  {this.renderQuestionIcon(
                    stats.notanswered,
                    "NOTANSWERED",
                    false
                  )}
                  <p className="m-0">Not Answered</p>
                </div>
              </div>

              <div className="row">
                <div className="col d-flex align-items-center">
                  {this.renderQuestionIcon(
                    stats.notvisited,
                    "NOTVISITED",
                    false
                  )}
                  <p className="m-0">Not Visited</p>
                </div>

                <div className="col d-flex align-items-center">
                  {this.renderQuestionIcon(stats.flagged, "FLAGGED", false)}
                  <p className="m-0">Flagged</p>
                </div>
              </div>
            </div>

            <div className="my-3" />
            <div className="d-flex justify-content-center my-3">
              <button
                className="btn border-green bg-white text-green px-3 py-1 mx-2"
                style={{ borderRadius: 12 }}
                onClick={() => {
                  this.setState({ isSubmissionConfirmOpen: false })
                }}
              >
                Resume Test
              </button>
              <button
                className="btn text-light bg-green px-3 py-1 mx-2"
                style={{ borderRadius: 12 }}
                onClick={() => {
                  this.setState({ isSubmissionConfirmOpen: false }, () => {
                    this.onSubmit()
                  })
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  saveToLocalStorage = () => {
    let answersToSave = {}
    const {
      quizSections,
      quizSet,
      endTime,
      currentSection,
      currentQuestion,
    } = this.state
    if (!quizSet) return
    quizSections.forEach(section => {
      section.quizQuestions.forEach(que => {
        const { questions, answers, possibleAnswers, viewed, flagged } = que
        answersToSave[que.id] = {
          questions,
          answers,
          possibleAnswers,
          viewed,
          flagged,
        }
      })
    })
    const { quizAssignInfoId } = quizSet
    localStorage.setItem(
      `assessment-${this.state.takingStudentId}-${quizAssignInfoId}`,
      JSON.stringify({
        answers: answersToSave,
        endTime,
        currentSection,
        currentQuestion,
      })
    )
  }

  render() {
    const { leftTime } = this.state

    return (
      <>
        {this.renderInstructions()}
        {this.renderSubmissionConfirm()}
        <AppWrapper
          teacherDashboard
          showTeacherThree
          showTeacherLogo
          screenName="Take TESTS"
        >
          {/* {this.state.freezeQuizSubmission && (
            <div
              style={{
                top: "0",
                left: "0",
                position: "fixed",
                width: "100%",
                height: "100%",
                zIndex: "99999999999",
                backgroundColor: "#cccccc",
                opacity: "0.6",
              }}
            ></div>
          )} */}
          <div className="d-flex align-items-center">
            <h2 className="text-dark mr-auto">Quiz</h2>

            <div className="d-flex align-items-center mr-3">
              <i className="far fa-clock" style={{ fontSize: "1rem" }} />
              <div
                className="ml-2"
                style={{ fontSize: "1.05rem", color: ":#094353" }}
              >
                {leftTime}
              </div>
            </div>
            <Button
              className="px-4 py-1"
              style={{
                borderRadius: 14,
                backgroundColor: "#06B191",
                color: "#f7f7f7",
                border: 0,
              }}
              // onClick={this.onSubmit}
              onClick={() => {
                this.setState({ isSubmissionConfirmOpen: true })
              }}
              disabled={this.state.isSubmitting || this.state.isLoading}
            >
              {this.state.isSubmitting ? (
                <HashLoader
                  sizeUnit={"px"}
                  size={16}
                  color={"#ffffff"}
                  loading={this.state.isSubmitting}
                />
              ) : (
                "Submit"
              )}
            </Button>
          </div>

          <DragDropContext onDragEnd={this.onDragEnd}>
            {this.state.isLoading ? (
              <div
                className="d-flex justify-content-center my-4"
                style={{ position: "absolute", left: "50%", top: "50%" }}
              >
                <HashLoader
                  sizeUnit="px"
                  size={35}
                  color="#094251"
                  loading={this.state.isLoading}
                />
              </div>
            ) : (
              this.renderTakeQuiz()
            )}
          </DragDropContext>
        </AppWrapper>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getSingleQuiz,
      submitQuiz,
      getPresignedUrl,
      showNotification,
    },
    dispatch
  )
}
export default connect(mapStateToProps, dispatchToProps)(TakeAssessmentScreen)
