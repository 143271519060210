import React from "react"
import { alphas } from "./utils"

function Match({ question, match }) {
  return question.map((q, i) => (
    <p
      key={i}
      className="d-flex align-items-center gap-1rem pl-4 ml-4 mb-0 fs-14 clr-201F1F"
    >
      <span style={{ width: "12rem" }}>
        {alphas[i]}
        {q}
      </span>
      <span> - </span>
      <span>{match[i] || ""}</span>
    </p>
  ))
}

export default Match
