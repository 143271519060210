/*************************************************
 * RedInk
 * @exports
 * @class index.js
 * @extends Component
 * @author Sasidharan // on 01/02/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import AppWrapper from "../Wrapper"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { URL_PATH } from "../../../config/urlPath"
import { getSuperAdminDetails } from "../../../actions/founder/SuperAdminDetailsAction"
import { myLog } from "../../../utils/Utility"

class SuperAdminDashboard extends Component {
  componentDidMount = () => {
    // if (!this.props.superAdminList)
    this.props.getSuperAdminDetails((success, response) => {
      if (success) {
        myLog("Teacher Data in Profile", response.teacherDetails)
      }
    })
  }

  handleChange = () => event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  //Add Super Admin
  addSuperAdmin = school => {
    this.props.history.push({
      pathname: URL_PATH.FOUNDER_EDIT_SUPER_ADMIN,
      state: {
        schoolDetails: "",
      },
    })
    myLog("ADD SUPER ADMIN DETAILS ==>", school)
  }

  // edit function
  editChange = school => {
    this.props.history.push({
      pathname: URL_PATH.FOUNDER_EDIT_SUPER_ADMIN,
      state: {
        schoolDetails: school,
      },
    })
    myLog("EDIT SUPER ADMIN DETAILS ==>", school)
  }

  render() {
    return (
      <div>
        <AppWrapper founderDashboard>
          <div className="d-flex justify-content-end mx-3">
            <div className="row">
              <div className="mb-2 align-self-center">
                <button
                  type="button"
                  onClick={() => this.addSuperAdmin()}
                  className="btn btn-sm btn-light rounded-pill box-shadow-none"
                >
                  <i className="fas fa-plus mr-2" />
                  Add Super Admin
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-1 justify-content-end mx-3">
            <table className="table table-hover">
              <thead
                className="text-white"
                style={{ backgroundColor: "#073e4d" }}
              >
                <tr>
                  <th scope="col">School Name</th>
                  <th scope="col">Chairman Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">City</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {this.props.superAdminList &&
                  this.props.superAdminList.map(school => (
                    <tr key={school.id}>
                      <td>{school.tentantFullName}</td>
                      <td>{school.chairmanName}</td>
                      <td>{school.tentantEmail}</td>
                      <td>{school.tentantPhone}</td>
                      <td>{school.city}</td>
                      <td>
                        <button
                          className="btn badge badge-primary p-1"
                          onClick={() => this.editChange(school)}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </AppWrapper>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    superAdminList: state.founderDetails.superAdminList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getSuperAdminDetails,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(SuperAdminDashboard)
