/*************************************************
 * RedInk
 * @exports
 * @class Dashboard.js
 * @extends Component
 * @author Sasidharan // on 11/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { HashLoader } from "react-spinners"
import { confirmAlertPopUp } from "../../../utils/Utility"
import { VALIDATION_MSG } from "../../../utils/Constants"
import {
  getNewsDetails,
  deleteNewsDetails,
} from "../../../actions/admin/NewsEventsAction"
import NoDataFound from "../../../assets/images/NoData.gif"
class NewsScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showSideMenu: true,
      isMessageModalOpen: false,
      isShowLoading: true,
      isNews: true,
      isEvents: false,
      currentNewsTitle: "",
      currentNewsSendBy: "",
      currentNewsTime: "",
      currentNewsDesc: "",
      currentNewsPhoto: "",
      newsDetails: [],
    }
  }

  componentDidMount() {
    //this.setState({ isShowLoading: false })
    this.getNewsDetail()
  }

  getNewsDetail = () => {
    this.props.getNewsDetails((success, response) => {
      if (success && response.length > 0) {
        this.setState({
          currentNewsTitle: response[0].title,
          currentNewsSendBy: response[0].sendBy,
          currentNewsTime: response[0].time,
          currentNewsDesc: response[0].description,
          currentNewsPhoto: response[0].photo,
          isShowLoading: false,
        })
      } else {
        this.setState({
          isShowLoading: false,
        })
      }
    })
  }

  /**
   * handles on click message icon in sidebar
   */
  onClickMessageIcon = () => {
    this.setState({ isMessageModalOpen: !this.state.isMessageModalOpen })
  }

  onClickCreateNewAndModalIcon = () => {
    this.setState({
      isCreateEventModalOpen: false,
      isCreateNewsModalOpen: false,
      isCreateTasksModalOpen: false,
    })
  }

  /**
   * Handles message modal
   */
  closeMessageModal = () => this.setState({ isMessageModalOpen: false })

  /**
   * handles message submit
   */
  onSubmitMessage = () => {
    this.closeMessageModal()
  }

  onNewsClick = (event, news) => {
    this.setState({
      currentNewsTitle: news.title,
      currentNewsSendBy: news.sendBy,
      currentNewsTime: news.time,
      currentNewsDesc: news.description,
      currentNewsPhoto: news.photo,
    })
  }

  onDeleteNews = news => {
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT,
      yes => {
        if (yes) {
          this.props.deleteNewsDetails({ id: news.id }, () => {})
        }
      }
    )
  }

  render() {
    const currentNews = (
      <div className="col-sm-7 news-panel">
        <div className="col p-0">
          <img
            draggable="false"
            src={this.state.currentNewsPhoto}
            id="current-news-img"
            alt=""
            height="350px"
          />
        </div>
        <div className="p-2 ml-4">
          <h2 className="pl-1 pt-1 text-dark">{this.state.currentNewsTitle}</h2>
          <div className="row">
            <small className="pl-4 update" style={{ fontSize: "0.8vw" }}>
              {this.state.currentNewsSendBy} . {this.state.currentNewsTime}
            </small>
          </div>
          <p className="news-article mt-2" style={{ width: "92%" }}>
            {" "}
            {this.state.currentNewsDesc}{" "}
          </p>
        </div>
      </div>
    )

    const newsSide = this.props.newsList.map(news => {
      return (
        <div
          className="d-flex news-side-tile mb-3 flex-end justify-content-between"
          onClick={event => this.onNewsClick(event, news)}
        >
          <div className="col-sm-4 px-0">
            <img draggable="false" src={news.photo} id="img-news" alt="" />
          </div>
          <div className="col-sm-8 py-3 px-0 d-flex flex-column justify-content-between">
            <div>
              <h2 className="news-head ">{news.title}</h2>
              <div className="row">
                <small className="pl-4" style={{ fontSize: "0.8vw" }}>
                  <i className="fa fa-clock" style={{ fontSize: "0.8vw" }}></i>{" "}
                  &nbsp; {`${news.sendBy}. ${news.time}`}
                </small>
              </div>
              <span
                type="button"
                className="btn badge-pill py-0 text-light bg-danger"
                onClick={() => this.onDeleteNews(news)}
                style={{ fontSize: "0.8vw" }}
              >
                Delete
              </span>
            </div>
          </div>
        </div>
      )
    })

    return (
      <div>
        {this.state.isShowLoading ? (
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: "10%" }}
          >
            <HashLoader
              sizeUnit={"px"}
              size={35}
              color={"#084353"}
              loading={this.state.isShowLoading}
            />
          </div>
        ) : (
          <div className="card  mb-3">
            <div className="container px-5 my-3">
              <div className="row float-right mb-3">
                <button
                  data-toggle="modal"
                  data-target="#newsModal"
                  className="btn btn-sm btn-light px-4 rounded-pill mr-3 box-shadow-none"
                >
                  <i className="fa fa-plus mr-2" />
                  Add News
                </button>
              </div>
              {this.props.newsList.length > 0 ? (
                <div className="container-fluid news-container p-0 row d-flex justify-content-between">
                  {currentNews}
                  <div className="col-sm-5 mb-5  news-list">
                    <div className="tl mb-3"></div>
                    <div
                      className="news-side scrollbar overflow-y-scroll"
                      style={{ height: "70vh" }}
                    >
                      {this.props.newsList.length > 0 ? (
                        newsSide
                      ) : (
                        <div>No data found</div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-center" style={{ marginTop: 50 }}>
                  <div style={{ marginTop: 20 }}>
                    <b style={{ color: "#084353", fontSize: 24 }}>
                      No Data Found
                    </b>
                  </div>
                  <div className="d-flex justify-content-center">
                    <img
                      src={NoDataFound}
                      alt="logo"
                      width={400}
                      height={400}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    newsList: state.adminNewsEventsState.newsList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getNewsDetails,
      deleteNewsDetails,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(NewsScreen)
