/*************************************************
 * RedInk
 * @exports
 * @class MessageModal.js
 * @extends Component
 * @author Sasidharan // on 11/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import Modal, { ModalTransition } from "@atlaskit/modal-dialog"
import Select from "react-select"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  getTeachersDetails,
  getStudentsDetails,
} from "../../actions/superAdmin/CommunicationDetailsAction"
import { myLog } from "../../utils/Utility"

class MessageModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      messageSubject: "",
      messageBody: "",
      sendEmail: true,
      sendSms: false,
      assignedRoleId: "",
      assignedRoleName: "",
      assignedClassId: "",
      assignedClassName: "",
      assignedStudentId: "",
      assignedStudentName: "",
      assignedTeacherId: "",
      assignedTeacherName: "",
      assignedSubjectId: "",
      assignedSubjectName: "",
    }
  }

  clearObject = () => {
    this.setState({
      messageSubject: "",
      messageBody: "",
      sendEmail: true,
      sendSms: false,
      assignedRoleId: "",
      assignedRoleName: "",
      assignedClassId: "",
      assignedClassName: "",
      assignedStudentId: "",
      assignedStudentName: "",
      assignedTeacherId: "",
      assignedTeacherName: "",
      assignedSubjectId: "",
      assignedSubjectName: "",
      assignedRole: {},
      assignedClass: {},
      assignedStudents: {},
      assignedTeachers: {},
      assignedSubjects: {},
    })
  }

  clickSend = () => {
    let object = {
      sendBy: this.state.sendEmail ? "email" : "sms",
      role: this.state.assignedRoleId,
      roleName: this.state.assignedRoleName,
      classId: this.state.assignedClassId,
      className: this.state.assignedClassName,
      studentId: this.state.assignedStudentId,
      studentName: this.state.assignedStudentName,
      teacherId: this.state.assignedTeacherId,
      teacherName: this.state.assignedTeacherName,
      subjectId: this.state.assignedSubjectId,
      subjectName: this.state.assignedSubjectName,
      subject: this.state.messageSubject,
      content: this.state.messageBody,
    }
    myLog(object)
    this.props.onSubmitMessage(object)
    this.clearObject()
  }

  render() {
    return (
      <ModalTransition>
        {this.props.isModalOpen && (
          <Modal
            autoFocus={false}
            width="80%"
            heading="COMPOSE MESSAGE"
            actions={[
              {
                text: "Send",
                onClick: () => {
                  this.clickSend()
                },
                style: {
                  borderRadius: 7,
                },
              },
              {
                text: "Close",
                onClick: this.props.closeModal,
                style: { borderRadius: 7 },
              },
            ]}
          >
            <div className="container mt-4">
              <div className="form-group row">
                <label className="col-sm-2 text-center col-form-label">
                  To
                </label>
                <div className="col-sm-3">
                  <Select
                    placeholder="Select"
                    isClearable
                    value={this.state.assignedRole}
                    options={[
                      { value: "0", label: "Parents" },
                      { value: "1", label: "Class Teachers" },
                      { value: "2", label: "Subject Teachers" },
                    ]}
                    onChange={selectedOption => {
                      myLog(selectedOption)
                      this.setState({
                        assignedRole: selectedOption,
                        assignedRoleId: selectedOption.value,
                        assignedRoleName: selectedOption.label,
                      })
                    }}
                    className="basic-multi-select mb-3"
                    classNamePrefix="select"
                  />
                </div>
                <div className="col-sm-3">
                  {this.state.assignedRoleId === "0" ? (
                    <Select
                      placeholder="Select Class"
                      isClearable
                      value={this.state.assignedClass}
                      options={this.props.classesList}
                      className="basic-multi-select mb-3"
                      classNamePrefix="select"
                      onChange={selectedOption => {
                        myLog(selectedOption)
                        this.setState({
                          assignedClass: selectedOption,
                          assignedClassId: selectedOption.id,
                          assignedClassName: selectedOption.value,
                        })
                        this.props.getStudentsDetails(
                          selectedOption.id,
                          () => {}
                        )
                      }}
                    />
                  ) : this.state.assignedRoleId === "2" ? (
                    <Select
                      placeholder="Select Subject"
                      isClearable
                      value={this.state.assignedSubjects}
                      options={this.props.subjectList}
                      className="basic-multi-select mb-3"
                      classNamePrefix="select"
                      onChange={selectedOption => {
                        myLog(selectedOption)
                        this.setState({
                          assignedSubjects: selectedOption,
                          assignedSubjectId: selectedOption.id,
                          assignedSubjectName: selectedOption.value,
                        })
                        this.props.getTeachersDetails(
                          selectedOption.id,
                          () => {}
                        )
                      }}
                    />
                  ) : null}
                </div>
                <div className="col-sm-4">
                  {this.state.assignedRoleId === "0" ? (
                    <Select
                      placeholder="Select Student"
                      name="classes"
                      isClearable
                      value={this.state.assignedStudents}
                      options={this.props.studentsList}
                      className="basic-multi-select mb-3"
                      classNamePrefix="select"
                      onChange={selectedOption => {
                        myLog(selectedOption)
                        this.setState({
                          assignedStudents: selectedOption,
                          assignedStudentId: selectedOption.id,
                          assignedStudentName: selectedOption.value,
                        })
                      }}
                    />
                  ) : this.state.assignedRoleId === "2" ? (
                    <Select
                      placeholder="Select Teacher"
                      isClearable
                      value={this.state.assignedTeachers}
                      options={this.props.teachersList}
                      className="basic-multi-select mb-3"
                      classNamePrefix="select"
                      onChange={selectedOption => {
                        myLog(selectedOption)
                        this.setState({
                          assignedTeachers: selectedOption,
                          assignedTeacherId: selectedOption.id,
                          assignedTeacherName: selectedOption.value,
                        })
                        this.props.getTeachersDetails(
                          selectedOption.id,
                          () => {}
                        )
                      }}
                    />
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-2 text-center col-form-label">
                  Subject
                </label>
                <div className="col-sm-10">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control mr-2 rounded"
                      value={this.state.messageSubject}
                      onChange={e =>
                        this.setState({ messageSubject: e.target.value })
                      }
                    />
                    <div>
                      <button
                        className={`btn btn-outline-info rounded-0 ${
                          this.state.sendEmail ? "bg-info text-light" : ""
                        }`}
                        type="button"
                        onClick={() =>
                          this.setState({ sendEmail: true, sendSms: false })
                        }
                      >
                        <i className="fas fa-at mr-2"></i>EMAIL
                      </button>
                      <button
                        className={`btn btn-outline-info rounded-0 ${
                          this.state.sendSms ? "bg-info text-light" : ""
                        }`}
                        type="button"
                        onClick={() =>
                          this.setState({ sendSms: true, sendEmail: false })
                        }
                      >
                        <i className="fas fa-mobile-alt mr-2"></i>SMS
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-2 text-center col-form-label">
                  Message
                </label>
                <div className="col-sm-10">
                  <textarea
                    rows={8}
                    className="form-control"
                    value={this.state.messageBody}
                    onChange={e =>
                      this.setState({ messageBody: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
          </Modal>
        )}
      </ModalTransition>
    )
  }
}

const mapStateToProps = state => {
  return {
    subjectList: state.communicationDetailsState.subjectList,
    classesList: state.communicationDetailsState.classesList,
    teachersList: state.communicationDetailsState.teachersList,
    studentsList: state.communicationDetailsState.studentsList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getTeachersDetails,
      getStudentsDetails,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(MessageModal)
