/*************************************************
 * RedInk
 * @exports
 * @class SchoolDetailsModal.js
 * @extends Component
 * @author Sasidharan // on 18/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import Modal, { ModalTransition } from "@atlaskit/modal-dialog"
import { getBase64, validateEmail } from "../../utils/Utility"
import { countryList } from "../../utils/Countries"
import { connect } from "react-redux"
import { Modal as BSModal } from "reactstrap"
import { bindActionCreators } from "redux"
import {
  saveSchoolsDetailsInStore,
  deleteAssignedSchoolAdminDetails,
} from "../../actions/superAdmin/schoolDetailsAction"
import {
  getCheckEmailAvailableDetails,
  showNotification,
} from "../../actions/CommonAction"
import { DANGER_TOAST, VALIDATION_MSG } from "../../utils/Constants"
import { myLog } from "../../utils/Utility"

class SchoolDetailsModal extends Component {
  static getDerivedStateFromProps(props, state) {
    myLog("Derived schoolDetailsData", props.schoolDetailsData)
    return {
      schoolLogo: props.schoolDetailsData.schoolLogo,
      assignAdminSet: props.schoolDetailsData.admins,
      schoolName: props.schoolDetailsData.schoolName,
      schoolShortName: props.schoolDetailsData.schoolShortName,
      schoolEmail: props.schoolDetailsData.emailId
        ? props.schoolDetailsData.emailId
        : "",
      affiliation: props.schoolDetailsData.affiliation,
      contactNumber: props.schoolDetailsData.contactNumber,
      addressLine1: props.schoolDetailsData.addressLine1,
      addressLine2: props.schoolDetailsData.addressLine2,
      city: props.schoolDetailsData.city,
      state: props.schoolDetailsData.state,
      country: props.schoolDetailsData.country,
      zipCode: props.schoolDetailsData.zipCode,
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      showChooseLogo: false,
      hasError: false,
      isEmailAlreadyExist: false,
      isSchoolEmail: false,
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.schoolDetailsData !== this.props.schoolDetailsData) {
  //     this.setState({
  //       schoolLogo: this.props.schoolDetailsData.schoolLogo,
  //       assignAdminSet: this.props.schoolDetailsData.admins,
  //       schoolName: this.props.schoolDetailsData.schoolName,
  //       schoolShortName: this.props.schoolDetailsData.schoolShortName,
  //       schoolEmail: this.props.schoolDetailsData.schoolEmailId,
  //       affiliation: this.props.schoolDetailsData.affiliation,
  //       contactNumber: this.props.schoolDetailsData.schoolContactNumber,
  //       addressLine1: this.props.schoolDetailsData.addressLine1,
  //       addressLine2: this.props.schoolDetailsData.addressLine2,
  //       city: this.props.schoolDetailsData.city,
  //       state: this.props.schoolDetailsData.state,
  //       country: this.props.schoolDetailsData.country,
  //       zipCode: this.props.schoolDetailsData.zipCode,
  //     })
  //   }
  //   if (
  //     nextProps.clearSchoolDetailsModal !== this.props.clearSchoolDetailsModal
  //   ) {
  //     this.setState({
  //       schoolLogo: "",
  //       assignAdminSet: [{ adminName: "", adminEmail: "", adminContact: "" }],
  //       schoolName: "",
  //       schoolShortName: "",
  //       schoolEmail: "",
  //       affiliation: "",
  //       contactNumber: "",
  //       addressLine1: "",
  //       addressLine2: "",
  //       city: "",
  //       state: "",
  //       country: "India",
  //       zipCode: "",
  //     })
  //   }
  // }

  /**
   * Open File Explorer on click logo area
   */
  selectSchoolLogoClicked = () => {
    this.refs.schoolLogoUpload.click()
  }
  /**
   * After choosing image from explorer
   */
  onSelectSchoolLogo = file => {
    getBase64(file, schoolLogo => {
      this.setState({ schoolLogo })
      let object = this.props.schoolDetailsData
      object.schoolLogo = schoolLogo
      this.props.saveSchoolsDetailsInStore(object)
    })
  }
  /**
   * Add new assign admin form
   */
  addAssignAdminForm = index => {
    const tempARR = this.state.assignAdminSet
    tempARR.push({
      adminName: "",
      adminEmail: "",
      adminContact: "",
    })
    this.setState({ assignAdminSet: tempARR })
  }
  /**
   * Delete new assign admin form
   */
  deleteAssignAdminForm = index => {
    if (this.state.assignAdminSet.length > 1) {
      let arrAdmin = this.state.assignAdminSet
      if (arrAdmin[index].adminId && arrAdmin[index].adminId !== "") {
        let object = {
          id: arrAdmin[index].adminId,
        }
        this.props.deleteAssignedSchoolAdminDetails(object, () => {})
      }
      arrAdmin.splice(index, 1)
      this.setState({
        assignAdminSet: arrAdmin,
      })
    }
  }

  /**
   * Handles on submit school details
   */
  onSubmitSchoolDetails = closeModal => {
    myLog("this.props.schoolDetailsData", this.props.schoolDetailsData)

    if (this.state.schoolName === "" && this.state.affiliation === "") {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.SUCCESS,
        "Please fill out required fields."
      )
      return
    } else {
      //  if (this.state.isSchoolEmail) {
      //    this.props.showNotification(
      //      DANGER_TOAST,
      //      VALIDATION_MSG.SUCCESS,
      //      "School Email id already exists."
      //    )
      //    return
      //  }
      if (this.state.schoolName === "") {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SUCCESS,
          "School name cannot be empty."
        )
        return
      }

      //  if (this.state.schoolShortName === "" && this.state.schoolShortName === null) {
      //    this.props.showNotification(
      //      DANGER_TOAST,
      //      VALIDATION_MSG.SUCCESS,
      //      "School short name cannot be empty."
      //    )
      //    return
      //  }

      if (this.state.affiliation === "") {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Affiliation cannot be empty."
        )
        return
      }

      if (this.state.schoolEmail !== "" && this.state.schoolEmail !== null) {
        if (!validateEmail(this.state.schoolEmail)) {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Invalid Email id."
          )
          return
        }
      } else {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Email id. cannot be empty"
        )
        return
      }

      if (
        this.state.contactNumber !== "" &&
        this.state.contactNumber !== null
      ) {
        if (
          String(this.state.contactNumber).length < 10 ||
          String(this.state.contactNumber).length > 15
        ) {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Invalid Contact number"
          )
          return
        }
      }
      //  else {
      //   this.props.showNotification(
      //     DANGER_TOAST,
      //     VALIDATION_MSG.SUCCESS,
      //     "Contact number cannot be empty"
      //   )
      //   return
      // }

      // if (this.state.addressLine1 === "") {
      //   this.props.showNotification(
      //     DANGER_TOAST,
      //     VALIDATION_MSG.SUCCESS,
      //     "Address Line 1 cannot be empty."
      //   )
      //   return
      // }

      // if (this.state.addressLine2 === "") {
      //   this.props.showNotification(
      //     DANGER_TOAST,
      //     VALIDATION_MSG.SUCCESS,
      //     "Address Line 2 cannot be empty."
      //   )
      //   return
      // }

      // if (this.state.city === "") {
      //   this.props.showNotification(
      //     DANGER_TOAST,
      //     VALIDATION_MSG.SUCCESS,
      //     "City cannot be empty."
      //   )
      //   return
      // }

      // if (this.state.state === "") {
      //   this.props.showNotification(
      //     DANGER_TOAST,
      //     VALIDATION_MSG.SUCCESS,
      //     "State cannot be empty."
      //   )
      //   return
      // }

      // if (this.state.country === "") {
      //   this.props.showNotification(
      //     DANGER_TOAST,
      //     VALIDATION_MSG.SUCCESS,
      //     "Country cannot be empty."
      //   )
      //   return
      // }

      if (this.state.zipCode !== "" && this.state.zipCode !== null) {
        if (String(this.state.zipCode).length <= 4) {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Invalid ZipCode"
          )
          return
        }
      }
    }

    if (this.state.assignAdminSet[0].adminName === "") {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.SUCCESS,
        "Admin Name cannot be empty."
      )
      return
    }

    if (
      this.state.assignAdminSet[0].adminEmail !== "" &&
      this.state.assignAdminSet[0].adminEmail !== null
    ) {
      if (!validateEmail(this.state.assignAdminSet[0].adminEmail)) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Admin Email cannot be empty."
        )
        return
      }
    } else {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.SUCCESS,
        "Admin Email cannot be empty."
      )
      return
    }

    if (
      this.state.assignAdminSet[0].adminContact !== "" &&
      this.state.assignAdminSet[0].adminContact !== null
    ) {
      if (
        String(this.state.assignAdminSet[0].adminContact).length <= 9 ||
        String(this.state.assignAdminSet[0].adminContact).length >= 15
      ) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Invalid Admin Contact."
        )
        return
      }
    } else {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.SUCCESS,
        "Admin Contact cannot be empty."
      )
      return
    }

    if (
      // this.state.schoolLogo === "" ||
      this.state.assignAdminSet[0].adminName === "" ||
      this.state.assignAdminSet[0].adminEmail === "" ||
      this.state.assignAdminSet[0].adminContact === "" ||
      this.state.schoolName === "" ||
      // this.state.schoolShortName === "" ||
      // this.state.schoolEmail === "" ||
      // !validateEmail(this.state.schoolEmail) ||
      this.state.affiliation === ""
      // this.state.contactNumber === ""
      // this.state.addressLine1 === "" ||
      // this.state.addressLine2 === "" ||
      // this.state.city === "" ||
      // this.state.state === "" ||
      // this.state.country === "" ||
      // this.state.zipCode === ""
    ) {
      this.setState({ hasError: true })
    } else {
      let hasAssignAdminError = false
      for (let i = 0; i < this.state.assignAdminSet.length; i++) {
        if (
          this.state.assignAdminSet[i].adminEmail === "" ||
          !validateEmail(this.state.assignAdminSet[i].adminEmail) ||
          this.state.assignAdminSet[i].adminName === "" ||
          this.state.assignAdminSet[i].adminContact === ""
        ) {
          this.setState({ hasErrorAssignAdmin: true, hasError: true })
          hasAssignAdminError = true
        }
      }
      // if (!this.props.isEditSchoolClicked) {
      //   if (this.state.schoolEmail !== "") {
      //     this.props.getCheckEmailAvailableDetails(
      //       this.state.schoolEmail,
      //       (success, response) => {
      //         if (response) {
      //           this.setState({ hasError: true })
      //           this.props.showNotification(
      //             DANGER_TOAST,
      //             VALIDATION_MSG.SUCCESS,
      //             "School Email id already exists."
      //           )
      //           return
      //         }
      //       }
      //     )
      //   }
      // }

      if (!hasAssignAdminError) {
        let assignError = false
        for (let i = 0; i < this.state.assignAdminSet.length - 1; i++) {
          for (let j = 1; j < this.state.assignAdminSet.length; j++) {
            if (
              this.state.assignAdminSet[i].adminEmail ===
                this.state.assignAdminSet[j].adminEmail ||
              this.state.assignAdminSet[i].adminName ===
                this.state.assignAdminSet[j].adminName ||
              this.state.assignAdminSet[i].adminContact ===
                this.state.assignAdminSet[j].adminContact
            ) {
              assignError = true
            }
          }
        }
        if (assignError) {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Email id already exists."
          )
        } else {
          // let assignAdmin = []
          // for (let i = 0; i < this.state.assignAdminSet.length; i++) {
          //   if (this.state.assignAdminSet[i].adminEmail !== '' && this.state.assignAdminSet[i].adminName !== ''
          //     && this.state.assignAdminSet[i].adminContact !== '') {
          //     assignAdmin.push(this.state.assignAdminSet[i]);
          //   }
          // }
          let params = {
            schoolName: this.state.schoolName,
            schoolShortName: this.state.schoolShortName
              ? this.state.schoolShortName
              : null,
            emailId: this.state.schoolEmail ? this.state.schoolEmail : null,
            affiliation: this.state.affiliation.toUpperCase(),
            schoolLogo: "image",
            contactNumber: this.state.contactNumber
              ? this.state.contactNumber
              : null,
            addressLine1: this.state.addressLine1
              ? this.state.addressLine1
              : null,
            addressLine2: this.state.addressLine2
              ? this.state.addressLine2
              : null,
            city: this.state.city ? this.state.city : null,
            state: this.state.state ? this.state.state : null,
            country: this.state.country ? this.state.country : null,
            zipCode: this.state.zipCode ? this.state.zipCode : null,
            assignedAdmin: this.state.assignAdminSet,
          }
          if (
            this.props.schoolDetailsData &&
            this.props.schoolDetailsData.branchId
          ) {
            params.branchId = this.props.schoolDetailsData.branchId
          }
          this.props.onSubmitSchoolDetails(params, closeModal)
          this.setState({ hasError: false, hasErrorAssignAdmin: false })
        }
      }
    }
  }
  // emailCheck = e => {
  //   this.props.getCheckEmailAvailableDetails(
  //     e.target.value,
  //     (success, response) => {
  //       myLog("Success", success)
  //       myLog("Response", response)
  //       if (response) {
  //         this.props.showNotification(
  //           DANGER_TOAST,
  //           VALIDATION_MSG.SUCCESS,
  //           "Email id already exists."
  //         )
  //       }
  //     }
  //   )
  // }
  render() {
    return (
      <>
        <BSModal
          isOpen={this.props.isModalOpen}
          backdropClassName="modal-backdrop-show"
          modalClassName="modal-dialog-shadow-none"
          contentClassName="border-14px"
          centered={true}
          size="lg"
        >
          <div className="modal-body p-0">
            <div
              className="bg-brand text-light d-flex"
              style={{
                padding: "0.5rem 2rem",
                borderTopRightRadius: "14px",
                borderTopLeftRadius: "14px",
              }}
            >
              <div className="mx-auto">
                {this.props.schoolDetailsData.branchId
                  ? "Edit Your School Details"
                  : "Enter Your School Details"}
              </div>
            </div>
            <div className="p-4">
              {/* <div className="d-flex justify-content-center align-items-center mb-4">
                 {this.state.schoolLogo === "" ? (
                   <span
                     onClick={() => this.selectSchoolLogoClicked()}
                     onMouseEnter={() => this.setState({ showChooseLogo: true })}
                     onMouseLeave={() =>
                       this.setState({ showChooseLogo: false })
                     }
                     className="school-form-logo"
                   >
                     {!this.state.showChooseLogo &&
                     this.state.schoolLogo === "" ? (
                       <i className="fal fa-image"></i>
                     ) : null}
                   </span>
                 ) : null}
                 {this.state.schoolLogo !== "" ? (
                   <img
                     onMouseEnter={() => this.setState({ showChooseLogo: true })}
                     onMouseLeave={() =>
                       this.setState({ showChooseLogo: false })
                     }
                     style={{ objectFit: "cover", width: 100, height: 100 }}
                     onClick={() => this.selectSchoolLogoClicked()}
                     src={this.state.schoolLogo}
                     className="img-fluid rounded-circle"
                     alt="logo"
                   ></img>
                 ) : null}
                 {this.state.showChooseLogo ? (
                   <small
                     className="p-1 cursor-pointer"
                     style={{
                       position: "absolute",
                       fontWeight: "bold",
                       backgroundColor: "white",
                       borderRadius: 5,
                     }}
                     onClick={() => this.selectSchoolLogoClicked()}
                     onMouseEnter={() => this.setState({ showChooseLogo: true })}
                     onMouseLeave={() =>
                       this.setState({ showChooseLogo: false })
                     }
                   >
                     CHOOSE LOGO
                     <input
                       type="file"
                       onChange={event =>
                         this.onSelectSchoolLogo(event.target.files[0])
                       }
                       ref="schoolLogoUpload"
                       className="d-none"
                       accept="image/x-png,image/gif,image/jpeg"
                     />
                   </small>
                 ) : null}
               </div> */}
              <div className="d-flex justify-content-center">
                {/* {this.state.schoolLogo === "" && this.state.hasError ? (
                 <p className="text-danger">School Logo is required.</p>
               ) : null} */}
              </div>
              <div className="d-flex flex-column justify-content-start">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="form-group" style={{ flex: 1 }}>
                    <small>
                      School Name <span style={{ color: "red" }}>*</span>
                    </small>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.schoolName}
                      onChange={e => {
                        this.setState({ schoolName: e.target.value })
                        let object = this.props.schoolDetailsData
                        object.schoolName = e.target.value
                        object.schoolName = object.schoolName.replace(
                          /[^A-Za-z.,@ ]/gi,
                          ""
                        )
                        this.props.saveSchoolsDetailsInStore(object)
                      }}
                    />
                    {/* {this.state.schoolName === "" && this.state.hasError ? (
                       <p className="text-danger">School Name is required.</p>
                     ) : null} */}
                  </div>
                  <div className="form-group px-2" style={{ flex: 1 }}>
                    <small>Short School Name</small>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.schoolShortName}
                      onChange={e => {
                        this.setState({ schoolShortName: e.target.value })
                        let object = this.props.schoolDetailsData
                        object.schoolShortName = e.target.value
                        object.schoolShortName = object.schoolShortName.replace(
                          /[^A-Za-z./@ ]/gi,
                          ""
                        )
                        this.props.saveSchoolsDetailsInStore(object)
                      }}
                    />
                    {/* {this.state.schoolShortName === "" &&
                     this.state.hasError ? (
                       <p className="text-danger">School Name is required.</p>
                     ) : null} */}
                  </div>
                  <div className="form-group" style={{ flex: 1 }}>
                    <small>
                      Affiliation <span style={{ color: "red" }}>*</span>
                    </small>
                    <select
                      className="form-control"
                      value={this.state.affiliation}
                      onChange={e => {
                        e.target.value = e.target.value.replace(
                          /[^A-Za-z. ]/gi,
                          ""
                        )
                        this.setState({ affiliation: e.target.value })
                        let object = this.props.schoolDetailsData
                        object.affiliation = e.target.value
                        this.props.saveSchoolsDetailsInStore(object)
                      }}
                    >
                      <option value="">Select a Affiliation</option>
                      <option value="CBSE">CBSE</option>
                      <option value="IB">IB</option>
                      <option value="ICSE">ICSE</option>
                      <option value="IGSE">IGSE</option>
                      <option value="IGCSE">IGCSE</option>
                      <option value="SB">STATE BOARD</option>
                    </select>
                    {/* {this.state.affiliation === "" && this.state.hasError ? (
                       <p className="text-danger">Affiliation is required.</p>
                     ) : null} */}
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="form-group pr-2" style={{ flex: 1 }}>
                    <small>
                      Email Id <span style={{ color: "red" }}>*</span>
                    </small>
                    <input
                      type="email"
                      className="form-control"
                      value={this.state.schoolEmail}
                      onChange={e => {
                        this.setState({ schoolEmail: e.target.value })
                        let object = this.props.schoolDetailsData
                        object.schoolEmail = e.target.value
                        object.emailId = e.target.value
                        this.props.saveSchoolsDetailsInStore(object)
                        if (this.props.isEditSchoolClicked) {
                          this.props.getCheckEmailAvailableDetails(
                            e.target.value,
                            (success, response) => {
                              if (response) {
                                this.setState({ isSchoolEmail: true })
                                // this.props.showNotification(
                                //   DANGER_TOAST,
                                //   VALIDATION_MSG.SUCCESS,
                                //   "Email id already exists."
                                // )
                                // return
                              }
                            }
                          )
                        }
                      }}
                    />
                    {/* {(this.state.schoolEmail === "" ||
                       !validateEmail(this.state.schoolEmail)) &&
                     this.state.hasError ? (
                       <p className="text-danger">Email is required.</p>
                     ) : null} */}
                  </div>
                  <div className="form-group" style={{ flex: 1 }}>
                    <small>Contact number</small>
                    <input
                      type="number"
                      className="form-control"
                      value={this.state.contactNumber}
                      maxLength={15}
                      onChange={e => {
                        this.setState({ contactNumber: e.target.value })
                        let object = this.props.schoolDetailsData
                        object.contactNumber = e.target.value
                        this.props.saveSchoolsDetailsInStore(object)
                      }}
                    />
                    {/* {this.state.contactNumber === "" && this.state.hasError ? (
                       <p className="text-danger">Contact Number is required.</p>
                     ) : null} */}
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="form-group pr-2" style={{ flex: 1 }}>
                    <small>Address Line 1</small>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.addressLine1}
                      onChange={e => {
                        e.target.value = e.target.value.replace(
                          /[^A-Za-z0-9,\.\-/ ]/gi,
                          ""
                        )
                        this.setState({ addressLine1: e.target.value })
                        let object = this.props.schoolDetailsData
                        object.addressLine1 = e.target.value
                        this.props.saveSchoolsDetailsInStore(object)
                      }}
                    />
                    {/* {this.state.addressLine1 === "" && this.state.hasError ? (
                       <p className="text-danger">Address Line 1 is required.</p>
                     ) : null} */}
                  </div>

                  <div className="form-group" style={{ flex: 1 }}>
                    <small>Address Line 2</small>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.addressLine2}
                      onChange={e => {
                        e.target.value = e.target.value.replace(
                          /[^A-Za-z0-9,\.\-/ ]/gi,
                          ""
                        )
                        this.setState({ addressLine2: e.target.value })
                        let object = this.props.schoolDetailsData
                        object.addressLine2 = e.target.value
                        this.props.saveSchoolsDetailsInStore(object)
                      }}
                    />
                    {/* {this.state.addressLine2 === "" && this.state.hasError ? (
                       <p className="text-danger">Address Line 2 is required.</p>
                     ) : null} */}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="form-group mr-2">
                  <small>City</small>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.city}
                    onChange={e => {
                      let value = e.target.value
                      value = value.replace(/[^A-Za-z]/gi, "")
                      this.setState({ city: value })
                      let object = this.props.schoolDetailsData
                      object.city = value
                      this.props.saveSchoolsDetailsInStore(object)
                    }}
                  />
                  {/* {this.state.city === "" && this.state.hasError ? (
                     <p className="text-danger">City is required.</p>
                   ) : null} */}
                </div>
                <div className="form-group mr-2">
                  <small>State</small>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.state}
                    onChange={e => {
                      let value = e.target.value
                      value = value.replace(/[^A-Za-z ]/gi, "")
                      this.setState({ state: value })
                      let object = this.props.schoolDetailsData
                      object.state = value
                      this.props.saveSchoolsDetailsInStore(object)
                    }}
                  />
                  {/* {this.state.state === "" && this.state.hasError ? (
                     <p className="text-danger">State is required.</p>
                   ) : null} */}
                </div>
                <div className="form-group mr-2">
                  <small>Country</small>
                  <select
                    className="form-control"
                    value={this.state.country}
                    onChange={e => {
                      this.setState({ country: e.target.value })
                      let object = this.props.schoolDetailsData
                      object.country = e.target.value
                      this.props.saveSchoolsDetailsInStore(object)
                    }}
                  >
                    <option value="India">India</option>
                    {countryList.map(country => {
                      return <option value={country}>{country}</option>
                    })}
                  </select>
                  {/* {this.state.country === "" && this.state.hasError ? (
                     <p className="text-danger">Country is required.</p>
                   ) : null} */}
                </div>
                <div className="form-group">
                  <small>Zip Code</small>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.zipCode}
                    pattern="\d{10}"
                    maxLength="10"
                    onChange={e => {
                      this.setState({ zipCode: e.target.value })
                      let object = this.props.schoolDetailsData
                      object.zipCode = e.target.value
                      object.zipCode = object.zipCode.replace(/[^0-9 ]/gi, "")
                      this.props.saveSchoolsDetailsInStore(object)
                    }}
                  />
                  {/* {this.state.zipCode === "" && this.state.hasError ? (
                     <p className="text-danger">Zip Code is required.</p>
                   ) : null} */}
                </div>
              </div>
              <hr></hr>
              <h5 className="text-center text-dark">ASSIGN ADMIN</h5>
              {this.state.assignAdminSet && this.state.assignAdminSet.length > 0
                ? this.state.assignAdminSet.map((item, index) => {
                    return (
                      <div className="d-flex justify-content-between">
                        <div className="form-group w-100 mr-2">
                          <small>
                            Admin Name <span style={{ color: "red" }}>*</span>
                          </small>
                          <input
                            type="text"
                            className="form-control"
                            value={item.adminName}
                            onChange={e => {
                              let temp = this.state.assignAdminSet
                              e.target.value = e.target.value.replace(
                                /[^A-Za-z. ]/gi,
                                ""
                              )
                              temp[index].adminName = e.target.value
                              this.setState(
                                {
                                  assignAdminSet: temp,
                                  hasErrorAssignAdmin: false,
                                },
                                () => {
                                  let object = this.props.schoolDetailsData
                                  object.admins = this.state.assignAdminSet
                                  this.props.saveSchoolsDetailsInStore(object)
                                }
                              )
                            }}
                          />
                        </div>
                        <div className="form-group w-100 mr-2">
                          <small>
                            Email Id <span style={{ color: "red" }}>*</span>
                          </small>
                          <input
                            type="email"
                            className="form-control"
                            value={item.adminEmail}
                            onChange={e => {
                              this.setState({ isEmailAlreadyExist: false })
                              let temp = this.state.assignAdminSet
                              temp[index].adminEmail = e.target.value
                              this.setState(
                                {
                                  assignAdminSet: temp,
                                  hasErrorAssignAdmin: false,
                                },
                                () => {
                                  let object = this.props.schoolDetailsData
                                  object.admins = this.state.assignAdminSet
                                  this.props.saveSchoolsDetailsInStore(object)
                                }
                              )
                              // if (this.props.isEditSchoolClicked) {
                              //   this.emailCheck(e)
                              // }
                              // this.props.getCheckEmailAvailableDetails(
                              //   e.target.value,
                              //   (success, response) => {
                              //     if (response) {
                              //       this.props.showNotification(
                              //         DANGER_TOAST,
                              //         VALIDATION_MSG.SUCCESS,
                              //         "Email id already exists."
                              //       )
                              //       this.setState({ isEmailAlreadyExist: true })
                              //     }
                              //   }
                              // )
                            }}
                          />
                        </div>
                        <div className="form-group w-100 mr-2">
                          <small>
                            Contact Number{" "}
                            <span style={{ color: "red" }}>*</span>
                          </small>
                          <input
                            type="number"
                            className="form-control"
                            value={item.adminContact}
                            onChange={e => {
                              let temp = this.state.assignAdminSet
                              temp[index].adminContact = e.target.value
                              this.setState(
                                {
                                  assignAdminSet: temp,
                                  hasErrorAssignAdmin: false,
                                },
                                () => {
                                  let object = this.props.schoolDetailsData
                                  object.admins = this.state.assignAdminSet
                                  this.props.saveSchoolsDetailsInStore(object)
                                }
                              )
                            }}
                          />
                        </div>
                        <div
                          onClick={() => this.addAssignAdminForm(index)}
                          className="d-flex justify-content-center align-items-center text-info pt-2 fs-20 mx-3"
                        >
                          <i
                            className="far fa-plus-circle"
                            style={{ cursor: "pointer" }}
                          ></i>
                        </div>
                        <div
                          onClick={() => this.deleteAssignAdminForm(index)}
                          className="d-flex justify-content-center align-items-center text-danger pt-2 fs-20"
                        >
                          <i
                            className="far fa-trash-alt"
                            style={{ cursor: "pointer" }}
                          ></i>
                        </div>
                      </div>
                    )
                  })
                : null}
              {/* <div className="d-flex justify-content-center">
                 {this.state.assignAdminSet &&
                 this.state.hasError &&
                 this.state.hasErrorAssignAdmin ? (
                   <p className="text-danger">
                     Valid admin details is required.
                   </p>
                 ) : null}
               </div> */}
            </div>
          </div>
          <div className="modal-footer">
            <button
              style={{
                backgroundColor: "#DBDBDB",
                color: "#06B191",
                border: "none",
              }}
              className="btn font-weight-bold  rounded-pill"
              onClick={() => {
                if (
                  this.props.isCreateSchoolClicked ||
                  this.props.isEditSchoolClicked
                ) {
                  this.setState({ hasError: false, isSchoolEmail: false })
                  this.props.closeModal()
                } else {
                  this.onSubmitSchoolDetails(false)
                }
              }}
            >
              {this.props.isCreateSchoolClicked ||
              this.props.isEditSchoolClicked
                ? "Cancel"
                : "Add Another Branch"}
            </button>
            <button
              style={{
                backgroundColor: "#06B191",
                color: "white",
                border: "none",
              }}
              className="btn rounded-pill"
              onClick={() => this.onSubmitSchoolDetails(true)}
            >
              {this.props.showLoading ? "Saving" : "Save"}
            </button>
          </div>
        </BSModal>
      </>
    )
  }
  oldrender() {
    const { isModalOpen } = this.props
    return (
      <ModalTransition>
        {isModalOpen && (
          <Modal
            width="80%"
            heading="ENTER YOUR SCHOOL DETAILS"
            actions={[
              {
                text: this.props.showLoading ? "Submitting" : "Submit",
                className:
                  this.props.showLoading || this.state.isEmailAlreadyExist
                    ? "disabled"
                    : "",
                onClick: () => this.onSubmitSchoolDetails(true),
                style: {
                  borderRadius: 5,
                },
              },
              {
                text:
                  this.props.isCreateSchoolClicked ||
                  this.props.isEditSchoolClicked
                    ? "Cancel"
                    : "Add Another Branch",
                onClick: () => {
                  if (
                    this.props.isCreateSchoolClicked ||
                    this.props.isEditSchoolClicked
                  ) {
                    this.props.closeModal()
                  } else {
                    this.onSubmitSchoolDetails(false)
                  }
                },
                style: { borderRadius: 5 },
              },
            ]}
          ></Modal>
        )}
      </ModalTransition>
    )
  }
}

const mapStateToProps = state => {
  return {
    schoolDetailsData: state.schoolDetailsState.SchoolsDetails,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      saveSchoolsDetailsInStore,
      deleteAssignedSchoolAdminDetails,
      getCheckEmailAvailableDetails,
      showNotification,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(SchoolDetailsModal)
