export const questList = [
  "MCQ",
  "True or False",
  "Fill in the blanks",
  "Match the following",
  "Short answer",
  "Long answer",
]

export let alphas = {
  0: "a) ",
  1: "b) ",
  2: "c) ",
  3: "d) ",
  4: "e) ",
  5: "f) ",
  6: "g) ",
}

export function getRandomNumbers(n, max) {
  let numbers = []
  let i = 0

  while (i < n) {
    let randomNum = Math.floor(Math.random() * max) + 1
    if (!numbers.includes(randomNum)) {
      numbers.push(randomNum)
      i++
    }
  }

  return numbers
}

export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

export function formatTime(minutes) {
  if (minutes < 60) return `${minutes}M`

  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  if (remainingMinutes === 0) return `${hours}Hr`
  return `${hours}Hr ${remainingMinutes}M`
}

export function change(q, i) {
  if (!q) return ""
  if(typeof q === 'number') {
    q = String(q)
  }
  return `${alphas[i]}${
    q?.split(")").length === 1 ? q?.trim() : q?.split(")")?.[1]?.trim()
  }`
}
