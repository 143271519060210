/*************************************************
 * RedInk
 * @exports
 * @Class CommunicationDetailsAction.js
 * @extends Component
 * @author vijayakumar // on 05/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { ACTION_TYPES } from "../types"
import store from "store"
import { showNotification } from "../CommonAction"
import {
  SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"

export const saveCommunicationDetailsInStore = CommunicationDetails => {
  return {
    type: ACTION_TYPES.COMMUNICATION_LIST,
    payload: CommunicationDetails,
  }
}

export const saveSubjectDetailsInStore = subjectDetails => {
  return {
    type: ACTION_TYPES.COMMUNICATION_SUBJECT_LIST,
    payload: subjectDetails,
  }
}

export const saveClassDetailsInStore = ClassDetails => {
  return {
    type: ACTION_TYPES.COMMUNICATION_CLASS_LIST,
    payload: ClassDetails,
  }
}

export const saveTeachersDetailsInStore = subjectDetails => {
  return {
    type: ACTION_TYPES.COMMUNICATION_TEACHER_LIST,
    payload: subjectDetails,
  }
}

export const saveStudentsDetailsInStore = ClassDetails => {
  return {
    type: ACTION_TYPES.COMMUNICATION_STUDENT_LIST,
    payload: ClassDetails,
  }
}

export const getClassDetails = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_CLASS_DETAILS, object, true)
      if (response) {
        myLog("====getClassDetails RESPONSE===::::", response)
        dispatch(saveClassDetailsInStore(response.classList))
        callBack(true, response.classList)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getClassDetails ERROR===????", error)
    }
  }
}

export const getSubjectDetails = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_SUBJECT_DETAILS, object, true)
      if (response) {
        myLog("====getSubjectDetails RESPONSE===::::", response)
        dispatch(saveSubjectDetailsInStore(response.subjects))
        callBack(true, response.subjects)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getSubjectDetails ERROR===????", error)
    }
  }
}

export const getStudentsDetails = callBack => {
  return async dispatch => {
    try {
      let object = {
        sectionId: store.get("quickInfo").classTeacherFor,
      }
      const response = await Client.get(
        URL.GET_TEACHERS_STUDENT_DATABASE_LIST,
        object,
        true
      )
      if (response) {
        myLog("====getStudentsDetails RESPONSE===::::", response)
        dispatch(saveStudentsDetailsInStore(response.studentDetails))
        callBack(true, response.studentDetails)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getStudentsDetails ERROR===????", error)
    }
  }
}

export const getTeachersDetails = (subjectId, callBack) => {
  return async dispatch => {
    try {
      let response = {
        teachersList: [
          {
            id: "",
            teacherName: "Vijay",
            label: "Vijay",
            value: "Vijay",
          },
          {
            id: "",
            teacherName: "Vijay",
            label: "Vijay",
            value: "Vijay",
          },
          {
            id: "",
            teacherName: "Vijay",
            label: "Vijay",
            value: "Vijay",
          },
        ],
      }
      //   const response = await Client.get(URL.GET_COMMUNICATION_DETAILS, null, true)
      if (response) {
        myLog("====getTeachersDetails RESPONSE===::::", response)
        dispatch(saveTeachersDetailsInStore(response.teachersList))
        callBack(true, response.teachersList)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getTeachersDetails ERROR===????", error)
    }
  }
}

export const getCommunicationDetails = (object, callBack) => {
  return async dispatch => {
    try {
      // let response = {
      //     CommunicationList : [
      //         {
      //             id: "",
      //             sendBy: "sms",
      //             role: '0',
      //             classId: "",
      //             className: "1-A",
      //             studentId: "",
      //             studentName: "Vijayakumar",
      //             teacherId: "",
      //             teacherName: "",
      //             subjectId: "",
      //             subjectName: "",
      //             isStarred: true,
      //             fromId: "",
      //             fromName: "SuperAdmin",
      //             subject: "Absent Intimation",
      //             content: "Hi, your child is absent today...",
      //             date: "1 Dec",
      //         },
      //         {
      //             id: "",
      //             sendBy: "email",
      //             role: '2',
      //             classId: "",
      //             className: "",
      //             studentId: "",
      //             studentName: "",
      //             teacherId: "",
      //             teacherName: "Teacher1",
      //             subjectId: "",
      //             subjectName: "English",
      //             isStarred: true,
      //             fromId: "",
      //             fromName: "SuperAdmin",
      //             subject: "Absent Intimation",
      //             content: "Hi, your child is absent today...",
      //             date: "4 Dec",
      //         },
      //         {
      //             id: "",
      //             sendBy: "email",
      //             role: '1',
      //             classId: "",
      //             className: "1-A",
      //             studentId: "",
      //             studentName: "",
      //             teacherId: "",
      //             teacherName: "",
      //             subjectId: "",
      //             subjectName: "",
      //             isStarred: true,
      //             fromId: "",
      //             fromName: "SuperAdmin",
      //             subject: "Absent Intimation",
      //             content: "Hi, your child is absent today...",
      //             date: "1 Mar",
      //         }
      //     ]
      // }
      const response = await Client.get(
        URL.GET_TEACHER_COMMUNICATION_DETAILS,
        object,
        true
      )
      if (response) {
        myLog("====getCommunicationDetails RESPONSE===::::", response)
        dispatch(saveCommunicationDetailsInStore(response.communications))
        callBack(true, response.communications)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getCommunicationDetails ERROR===????", error)
    }
  }
}

export const saveCommunicationDetails = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      const response = await Client.post(
        URL.SAVE_TEACHER_COMMUNICATION_DETAILS,
        body,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====saveCommunicationDetails RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveCommunicationDetails ERROR===????", error)
    }
  }
}

export const deleteCommunicationDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.DELETE_COMMUNICATION_DETAILS,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteCommunicationDetails RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteCommunicationDetails ERROR===????", error)
    }
  }
}

export const saveCommunicationStarredDetails = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      const response = await Client.get(
        URL.SAVE_TEACHER_COMMUNICATION_STARRED_DETAILS,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====saveCommunicationStarredDetails RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveCommunicationStarredDetails ERROR===????", error)
    }
  }
}

export const getCommunicationCountDetails = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_TEACHER_COMMUNICATION_COUNT_DETAILS,
        null,
        true
      )
      if (response) {
        myLog("====getCommunicationCountDetails RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getCommunicationCountDetails ERROR===????", error)
    }
  }
}
