/*************************************************
 * RedInk
 * @exports
 * @class index.js
 * @extends Component
 * @author Sasidharan // on 15/02/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { UncontrolledTooltip } from "reactstrap"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import moment from "moment"

import { getAttendanceDetails } from "../../../actions/admin/AttendanceDetailsAction"

import AbsenteeModal from "./AbsenteeModal"
import MessageModal from "../MessageModal"
import ReportModal from "./ReportModal"
import AppWrapper from "../Wrapper1"

class AdminAttendanceReport extends Component {
  static getDerivedStateFromProps(props) {
    return {
      present: props.schoolAttendanceDetail
        ? props.schoolAttendanceDetail.presentCount
        : "0",
      absent: props.schoolAttendanceDetail
        ? props.schoolAttendanceDetail.absentCount
        : "0",
      unmark: props.schoolAttendanceDetail
        ? props.schoolAttendanceDetail.unmarkedCount
        : "0",
    }
  }

  constructor(props) {
    super(props)
    document.title = "Attendance Report | Redink"
    this.state = {
      isMessageModalOpen: false,
      reportDate: "",
      present: 0,
      absent: 0,
      unmark: 0,
      onHover: false,
      onHoverIndex: -1,
      showReportModal: false,
      showAbsenteeModal: "",
      selectedClassName: "",
    }
  }

  componentDidMount = () => {
    this.getAttendance(moment(new Date()).format("YYYY-MM-DD"))
  }

  getAttendance = reportDate => {
    this.setState({ reportDate, displayReportDate: new Date() }, () => {
      this.props.getAttendanceDetails(reportDate, () => {
        // this.showAttendanceReportGraph()
      })
    })
  }

  // showAttendanceReportGraph = () => {
  //   new Chart(this.attendanceReportGraph, {
  //     type: "horizontalBar",
  //     data: {
  //       labels: ["Present", "Absent", "Unmarked"],
  //       datasets: [
  //         {
  //           label: "Count",
  //           data: [this.state.present, this.state.absent, this.state.unmark],
  //           backgroundColor: ["#479997", "#FF3059"],
  //           fill: false,
  //           borderWidth: 1,
  //         },
  //       ],
  //     },
  //     options: {
  //       scales: {
  //         yAxes: [
  //           {
  //             ticks: {
  //               beginAtZero: true,
  //               maintainAspectRatio: true,
  //             },
  //             display: true,
  //             gridLines: {
  //               display: false,
  //             },
  //           },
  //         ],
  //         xAxes: [
  //           {
  //             display: true,
  //             gridLines: {
  //               display: false,
  //             },
  //           },
  //         ],
  //       },
  //       legend: {
  //         display: false,
  //       },
  //     },
  //   })
  // }

  closeMessageModal = () => this.setState({ isMessageModalOpen: false })

  onClickMessageIcon = () => {
    this.setState({ isMessageModalOpen: !this.state.isMessageModalOpen })
  }

  updateShowModal = () => {
    this.setState({ showReportModal: !this.state.showReportModal })
  }

  updateShowAbsenteeModal = (id, classSecName) => {
    this.setState({
      showAbsenteeModal: id,
      selectedClassName: classSecName,
    })
  }

  render() {
    return (
      <AppWrapper
        openMessageModal={this.onClickMessageIcon}
        showSchoolLogo
        showAdminThree
        adminDashboard
        screenName="Attendance Report"
      >
        <MessageModal
          isModalOpen={this.state.isMessageModalOpen}
          closeModal={this.onClickMessageIcon}
          onSubmitMessage={this.closeMessageModal}
        />

        <div className="d-flex flex-wrap justify-content-end mb-2">
          <div className="d-flex row">
            <div
              className="mt-1 px-3"
              onClick={() =>
                this.getAttendance(
                  moment(this.state.reportDate)
                    .subtract(1, "days")
                    .format("YYYY-MM-DD")
                )
              }
            >
              <i className="far fa-angle-left text-black fa-2x mr-1"></i>
            </div>
            <input
              type="date"
              className="p-2 rounded align-items-center unstyled"
              value={this.state.reportDate}
              onChange={e => {
                this.setState({ reportDate: e.target.value })
                this.getAttendance(e.target.value)
              }}
            />
            <div
              className="mt-1 px-3"
              onClick={() =>
                this.getAttendance(
                  moment(this.state.reportDate)
                    .add(1, "days")
                    .format("YYYY-MM-DD")
                )
              }
            >
              <i className="far fa-angle-right text-black fa-2x mr-1"></i>
            </div>
          </div>

          <button
            className="ml-4 px-3 py-1 fs-15 fw-600 text-white bg-099F97 br-10"
            onClick={this.updateShowModal}
          >
            View/Download Report
          </button>
        </div>

        <div className="row mx-0">
          <div
            className="card my-2 col-sm-3"
            style={{ backgroundColor: "#084353", color: "#ffffff" }}
          >
            <b className="p-2">Basic Info</b>
            <div className="d-flex flex-column">
              <div className="mt-3 p-3">
                <span>
                  <i className="far fa-building mr-2 fs-15" />
                  Number of Classes
                </span>
                <h2 className="font-weight-bold mt-3">
                  {this.props.basicInfo ? this.props.basicInfo.classCount : "0"}
                </h2>
              </div>
              <div className="mt-3 p-3">
                <span>
                  <i className="far fa-users-class mr-2 fs-15" />
                  Number of Students
                </span>
                <h2 className="font-weight-bold mt-3">
                  {this.props.basicInfo
                    ? this.props.basicInfo.studentCount
                    : "0"}
                </h2>
              </div>
            </div>
          </div>

          <div className="card my-2 col-sm">
            <b className="p-2">Today's Attendance</b>
            <div className="d-flex justify-content-center mt-3">
              {/* <div style={{ width: "300px" }}>
                <canvas
                  style={{ height: "100px" }}
                  ref={attendanceReportGraph =>
                    (this.attendanceReportGraph = attendanceReportGraph)
                  }
                />
              </div> */}
              <div
                className="justify-content-center mt-5"
                style={{ width: "500px" }}
              >
                <UncontrolledTooltip placement="top" target={`prekg-a-pass1`}>
                  {this.props.schoolAttendanceDetail
                    ? this.props.schoolAttendanceDetail.presentPercentage + "%"
                    : "0"}
                  {" students present"}
                </UncontrolledTooltip>

                <UncontrolledTooltip placement="top" target={`prekg-a-fail2`}>
                  {this.props.schoolAttendanceDetail
                    ? this.props.schoolAttendanceDetail.absentPercentage + "%"
                    : "0"}
                  {" students absent"}
                </UncontrolledTooltip>

                <UncontrolledTooltip placement="top" target={`prekg-a-fail3`}>
                  {this.props.schoolAttendanceDetail
                    ? this.props.schoolAttendanceDetail.unmarkPercentage + "%"
                    : "0"}
                  {" students unmarked"}
                </UncontrolledTooltip>

                <div className="progress">
                  <div
                    className="progress-bar bg-present"
                    role="progressbar"
                    id={`prekg-a-pass1`}
                    style={{
                      width: this.props.schoolAttendanceDetail
                        ? this.props.schoolAttendanceDetail.presentPercentage +
                          "%"
                        : "0",
                    }}
                    aria-valuenow={
                      this.props.schoolAttendanceDetail
                        ? this.props.schoolAttendanceDetail.presentPercentage
                        : "0"
                    }
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>

                  <div
                    className="progress-bar bg-absent"
                    role="progressbar"
                    id={`prekg-a-fail2`}
                    style={{
                      width: this.props.schoolAttendanceDetail
                        ? this.props.schoolAttendanceDetail.absentPercentage +
                          "%"
                        : "0",
                    }}
                    aria-valuenow={
                      this.props.schoolAttendanceDetail
                        ? this.props.schoolAttendanceDetail.absentPercentage
                        : "0"
                    }
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>

                  <div
                    className="progress-bar"
                    role="progressbar"
                    id={`prekg-a-fail3`}
                    style={{
                      width: this.props.schoolAttendanceDetail
                        ? this.props.schoolAttendanceDetail.unmarkPercentage +
                          "%"
                        : "0",
                      backgroundColor: "#D9D9D947",
                    }}
                    aria-valuenow={
                      this.props.schoolAttendanceDetail
                        ? this.props.schoolAttendanceDetail.unmarkPercentage
                        : "0"
                    }
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>

            <div className="row mt-3 text-center">
              <div className="col-sm">
                <p className="mb-1 fs-30 fw-700 clr-464747">
                  {this.props.schoolAttendanceDetail
                    ? this.props.schoolAttendanceDetail.unmarkedCount
                    : "0"}
                </p>
                <p className="mb-0 fs-15 clr-878787">Total</p>
              </div>

              <div className="col-sm">
                <p className="mb-1 fs-30 fw-700 clr-00A69B">
                  {this.props.schoolAttendanceDetail
                    ? this.props.schoolAttendanceDetail.presentCount
                    : "0"}
                </p>
                <p className="mb-0 fs-15 clr-878787">Present</p>
              </div>

              <div className="col-sm">
                <p className="mb-1 fs-30 fw-700 clr-EF395C">
                  {this.props.schoolAttendanceDetail
                    ? this.props.schoolAttendanceDetail.absentCount
                    : "0"}
                </p>
                <p className="mb-0 fs-15 clr-878787">Absent</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          {this.props.classList &&
            this.props.classList.map(item => (
              <div key={item.name} className="col-sm-4 my-2 position-relative">
                <div className="card p-3 br-8px">
                  {!item.attendanceTakenToday && (
                    <div
                      className="d-flex flex-column align-items-center justify-content-center position-absolute br-8px"
                      style={{
                        backgroundColor: "rgba(10,0,0,0.86)",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                      }}
                    >
                      <div className="m-auto d-flex flex-column align-items-center justify-content-center">
                        <div className="text-white">{item.name}</div>
                        <div className="clr-ff3059">Attendance not taken</div>
                      </div>
                    </div>
                  )}

                  <div className="mt-1 ml-1 fw-700 clr-020C26">{item.name}</div>

                  <div className="my-4 d-flex justify-content-around text-center">
                    <div>
                      <p className="mb-1 fs-25 fw-700 clr-464747">
                        {item.totalStudents}
                      </p>
                      <p className="mb-0 fs-15 clr-878787">Total</p>
                    </div>

                    <div>
                      <p className="mb-1 fs-25 fw-700 clr-00A69B">
                        {item.presentCount}
                      </p>
                      <p className="mb-0 fs-15 clr-878787">Present</p>
                    </div>

                    <div>
                      <p className="mb-1 fs-25 fw-700 clr-EF395C">
                        {item.absentCount}
                      </p>
                      <p className="mb-0 fs-15 clr-878787">Absent</p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end">
                    <button
                      className="fs-14 clr-065B98"
                      onClick={() =>
                        this.updateShowAbsenteeModal(item.sectionId, item.name)
                      }
                    >
                      View Details {`>`}
                    </button>
                  </div>
                </div>
              </div>
            ))}

          {this.state.showReportModal && (
            <ReportModal closeModal={this.updateShowModal} />
          )}

          {this.state.showAbsenteeModal && (
            <AbsenteeModal
              id={this.state.showAbsenteeModal}
              date={moment(this.state.reportDate).format("YYYY-MM-DD")}
              dateInReadable={moment(this.state.reportDate).format(
                "DD-MM-YYYY"
              )}
              classSecName={this.state.selectedClassName}
              closeModal={() => this.updateShowAbsenteeModal("", "")}
            />
          )}
        </div>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    classList: state.adminAttendanceDetailsState.attendanceDetails.classes,
    basicInfo: state.adminAttendanceDetailsState.attendanceDetails.basicInfo,
    schoolAttendanceDetail:
      state.adminAttendanceDetailsState.attendanceDetails
        .schoolAttendanceDetail,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAttendanceDetails,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(AdminAttendanceReport)
