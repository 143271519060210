/*************************************************
 * RedInk
 * @exports
 * @Class CommunicationDetailsAction.js
 * @extends Component
 * @author vijayakumar // on 05/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { ACTION_TYPES } from "../types"
import { showNotification } from "../CommonAction"
import {
  SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"

export const saveCommunicationDetailsInStore = CommunicationDetails => {
  return {
    type: ACTION_TYPES.COMMUNICATION_LIST,
    payload: CommunicationDetails,
  }
}

export const saveSubjectDetailsInStore = subjectDetails => {
  return {
    type: ACTION_TYPES.COMMUNICATION_SUBJECT_LIST,
    payload: subjectDetails,
  }
}

export const saveClassDetailsInStore = ClassDetails => {
  return {
    type: ACTION_TYPES.COMMUNICATION_CLASS_LIST,
    payload: ClassDetails,
  }
}

export const saveOpenMessageModalInStore = openMessageModal => {
  return {
    type: ACTION_TYPES.OPEN_MESSAGE_MODAL,
    payload: openMessageModal,
  }
}

export const saveTeachersDetailsInStore = subjectDetails => {
  return {
    type: ACTION_TYPES.COMMUNICATION_TEACHER_LIST,
    payload: subjectDetails,
  }
}

export const saveStudentsDetailsInStore = ClassDetails => {
  return {
    type: ACTION_TYPES.COMMUNICATION_STUDENT_LIST,
    payload: ClassDetails,
  }
}

export const getClassDetails = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_CLASS_DETAILS, object, true)
      if (response) {
        myLog("====getClassDetails RESPONSE===::::", response)
        dispatch(saveClassDetailsInStore(response.classList))
        callBack(true, response.classList)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getClassDetails ERROR===????", error)
    }
  }
}

export const getSubjectDetails = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_SUBJECT_DETAILS, object, true)
      if (response) {
        myLog("====getSubjectDetails RESPONSE===::::", response)
        dispatch(saveSubjectDetailsInStore(response.subjects))
        callBack(true, response.subjects)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getSubjectDetails ERROR===????", error)
    }
  }
}

export const getStudentsDetails = (classId, callBack) => {
  return async dispatch => {
    try {
      myLog("====getStudentsDetails classId===::::", classId)
      let object = {
        sectionId: classId,
      }
      const response = await Client.get(
        URL.GET_COMMUNICATION_STUDENT_LIST_DETAILS,
        object,
        true
      )
      if (response) {
        myLog("====getStudentsDetails RESPONSE===::::", response)
        dispatch(saveStudentsDetailsInStore(response.students))
        callBack(true, response.students)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getStudentsDetails ERROR===????", error)
    }
  }
}

export const getTeachersDetails = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_COMMUNICATION_TEACHER_LIST_DETAILS,
        object,
        true
      )
      if (response) {
        myLog("====getTeachersDetails RESPONSE===::::", response)
        dispatch(saveTeachersDetailsInStore(response.teachers))
        callBack(true, response.teachers)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getTeachersDetails ERROR===????", error)
    }
  }
}

export const getCommunicationDetails = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_COMMUNICATION_DETAILS,
        object,
        true,
        true
      )
      if (response) {
        myLog("====getCommunicationDetails RESPONSE===::::", response)
        dispatch(saveCommunicationDetailsInStore(response.list))
        callBack(true, response.list)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            "Something went wrong..."
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====getCommunicationDetails ERROR===????", error)
    }
  }
}

export const getCommunicationAttachments = (id, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_COMMUNICATION_DETAILS + id + "/attachments",
        null,
        true,
        true
      )
      if (response && response.attachments) {
        myLog(
          "====getCommunicationAttachments RESPONSE===::::",
          response.attachments
        )
        callBack(true, response.attachments)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getCommunicationAttachments ERROR===????", error)
    }
  }
}

export const saveCommunicationDetails = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      const response = await Client.post(
        URL.SAVE_COMMUNICATION_DETAILS,
        body,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====saveCommunicationDetails RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveCommunicationDetails ERROR===????", error)
    }
  }
}

export const deleteCommunicationToTrashDetails = (id, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.SAVE_COMMUNICATION_DETAILS + "/" + id + "/trash",
        null,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteCommunicationToTrashDetails RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteCommunicationToTrashDetails ERROR===????", error)
    }
  }
}

export const restoreCommunicationDetails = (id, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        `${URL.SAVE_COMMUNICATION_DETAILS}/${id}/trash-revoke`,
        null,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====restoreCommunicationDetails RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====restoreCommunicationDetails ERROR===????", error)
    }
  }
}

export const deleteCommunicationDetails = (id, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.SAVE_COMMUNICATION_DETAILS + "/" + id,
        null,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteCommunicationDetails RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteCommunicationDetails ERROR===????", error)
    }
  }
}

export const saveCommunicationStarredDetails = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      const response = await Client.get(
        URL.SAVE_COMMUNICATION_STARRED_DETAILS,
        body,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====saveCommunicationStarredDetails RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveCommunicationStarredDetails ERROR===????", error)
    }
  }
}

export const getCommunicationCountDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_COMMUNICATION_COUNT_DETAILS,
        body,
        true,
        true
      )
      if (response) {
        myLog("====getCommunicationCountDetails RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getCommunicationCountDetails ERROR===????", error)
    }
  }
}

export const saveCommunicationLabelDetails = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      const response = await Client.post(
        URL.SAVE_COMMUNICATION_LABEL_DETAILS,
        body,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====saveCommunicationLabelDetails RESPONSE===::::", response)
        // dispatch(getCommunicationLabelDetails({}, () => { }));
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveCommunicationLabelDetails ERROR===????", error)
    }
  }
}

export const getCommunicationLabelDetails = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_COMMUNICATION_LABEL_DETAILS,
        null,
        true,
        true
      )
      if (response) {
        myLog("====getCommunicationLabelDetails RESPONSE===::::", response)
        callBack(true, response.list)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getCommunicationLabelDetails ERROR===????", error)
    }
  }
}

export const getCommunicationByLabelDetails = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_COMMUNICATION_DETAILS,
        object,
        true,
        true
      )
      if (response) {
        myLog("====getCommunicationByLabelDetails RESPONSE===::::", response)
        dispatch(saveCommunicationDetailsInStore(response.list))
        callBack(true, response.list)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getCommunicationByLabelDetails ERROR===????", error)
    }
  }
}

export const deleteCommunicationLabelDetails = (id, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.GET_COMMUNICATION_LABEL_DETAILS + "/" + id,
        null,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteCommunicationLabelDetails RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteCommunicationLabelDetails ERROR===????", error)
    }
  }
}

export const updateCommunicationByLabelDetails = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      const response = await Client.post(
        URL.GET_COMMUNICATION_DETAILS + body.id + "/label",
        body,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====updateCommunicationByLabelDetails RESPONSE===::::", response)
        // dispatch(getCommunicationLabelDetails({}, () => { }));
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====updateCommunicationByLabelDetails ERROR===????", error)
    }
  }
}
