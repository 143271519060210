/*************************************************
 * RedInk
 * @exports
 * @class SubjectsScreen.js
 * @extends Component
 * @author Sasidharan // on 06/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component, useState, useEffect } from "react"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import Select, { components } from "react-select"
import { bindActionCreators } from "redux"
import { HashLoader } from "react-spinners"
import makeAnimated from "react-select/animated"
import { connect } from "react-redux"
import _ from "lodash"
import {
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Collapse,
  Label,
  Input,
  Modal,
} from "reactstrap"

import {
  getSubjectGroups,
  newSubjectGroups,
  saveSubjectGroup,
  getSubjectDetails,
  saveSubjectDetails,
  deleteSubjectGroup,
  createMasterSubject,
  updateSubjectDetails,
  deleteSubjectDetails,
  getSubjectMasterList,
  deleteSubjectGroupClass,
  deleteSubjectGroupSubject,
} from "../../../actions/admin/SubjectDetailsAction"
import {
  DANGER_TOAST,
  SUCCESS_TOAST,
  VALIDATION_MSG,
} from "../../../utils/Constants"
import { confirmAlertPopUp } from "../../../utils/Utility"
import { showNotification } from "../../../actions/CommonAction"
import { getClassDetails } from "../../../actions/admin/ClassDetailsAction"

import { MySelect } from "../../common/MySelect"
import AppWrapper from "../Wrapper1"

const Option = props => (
  <div>
    <components.Option {...props}>
      <div className="d-flex align-items-center">
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          className="subject-checkbox"
        />{" "}
        <label className="ml-2 mt-2">{props.label}</label>
      </div>
    </components.Option>
  </div>
)

const allOption = {
  label: "Select all",
  value: "*",
}

const MultiValue = props => {
  let labelToBeDisplayed = `${props.data.label}, `
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = "Selected All"
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  )
}

const ValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue()
  let toBeRendered = children
  if (currentValues.some(val => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]]
  }

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  )
}

const animatedComponents = makeAnimated()

const colorStyle = {
  control: styles => ({ ...styles, borderColor: "#084353", borderWidth: 1 }),
  dropdownIndicator: styles => ({ ...styles, color: "#084353" }),
}

class SubjectsScreen extends Component {
  static getDerivedStateFromProps = (props, state) => {
    return {
      subjectList: props.subjectList,
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedTab: "Subjects",
      addSubjectGroupOpen: false,
      isDeleteSubjectGroupModalOpen: false,
      isCreateSubjectModalOpen: false,
      subjectsSet: [""],
      isShowAddSubjectInput: false,
      updateSubjectDetailId: "",
      isShowLoading: true,
      typeOfSubjects: [
        { label: "CORE", value: "CORE" },
        { label: "LANGUAGE", value: "LANGUAGE" },
        { label: "OTHER", value: "OTHER" },
      ],
      type: "scholastic",
      groupAdding: false,
      editingGroupId: null,
      newGroupName: "",
      selectedGroupSubjects: [],
      selectedGroupClasses: [],
      isShowSubjectGroupLoading: true,
      recentUpdatedId: null,
      masterSubject: null,
      masterSubjectId: "",
      subjectName: "",
      shortName: "",
      subjectCode: "",
      subjectType: {},
      groupSubjectOptions: [],
      groupClassOptions: [],
      newClasses: [],
      isHigherSecondarySubjects: null,
      canCreateGroup: null,
      coreSubjects: [],
      languageSubjects: [],
      otherSubjects: [],
      subjectList: [],
      masterSubjectName: "",
      isMasterSubjectLoading: false,
    }
  }

  componentDidMount = async () => {
    this.props.getClassDetails({}, () => {})
    this.getSubjects()
    this.props.getSubjectGroups([], () => {
      this.setState({ isShowSubjectGroupLoading: false, isShowLoading: false })
    })
    this.newSubjectGroups() // to get higher secondary info
    if (!this.props.subjectMasterList) await this.props.getSubjectMasterList()
  }

  style = {
    option: (provided, state) => ({
      ...provided,
      color: "black",
      backgroundColor: "white",
    }),
  }

  newSubjectGroups = () => {
    this.props.newSubjectGroups({}, (success, response) => {
      if (success) {
        const { newSubjects, containHigherSecondarySubject } = response.data
        this.setState({
          isHigherSecondarySubjects: !!containHigherSecondarySubject,
          canCreateGroup: newSubjects.length > 0,
          groupSubjectOptions: newSubjects,
        })
      }
    })
  }

  getSubjects = () => {
    this.props.getSubjectDetails({}, (success, response) => {
      if (success) {
        this.setState({
          coreSubjects: response.CORE ? response.CORE : [],
          languageSubjects: response.LANGUAGE ? response.LANGUAGE : [],
          otherSubjects: response.OTHER ? response.OTHER : [],
        })
        if (response.CORE.length > 0) {
          this.setState({ isShowAddSubjectInput: false })
        }
      }
    })
  }

  addSubject = () => {
    const tempARR = this.state.subjectsSet
    tempARR.push("")
    this.setState({ subjectsSet: tempARR }, () => {
      this.newSubjectGroups()
    })
  }

  deleteSubject = index => {
    if (this.state.subjectsSet.length > 1) {
      let arrSubject = this.state.subjectsSet
      arrSubject.splice(index, 1)
      this.setState(
        {
          subjectsSet: arrSubject,
        },
        () => {
          this.newSubjectGroups()
        }
      )
    }
  }

  deleteSubjectFromDb = item => {
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT +
        "When a subject is deleted, classes and teacher who are associated with that subject lose the subject too",
      yes => {
        if (yes) {
          this.props.deleteSubjectDetails({ id: item.id }, () => {
            this.getSubjects()
          })
        }
      },
      {
        buttons: [],
        customUI: ({ onClose }) => {
          return (
            <div
              className="custom"
              style={{
                backgroundColor: "#fff",
                borderBottomRightRadius: "12px",
                borderBottomLeftRadius: "12px",
              }}
            >
              <div
                className="bg-brand text-light d-flex"
                style={{
                  padding: "0.5rem 2rem",
                  borderTopRightRadius: "12px",
                  borderTopLeftRadius: "12px",
                }}
              >
                <div className="mx-auto fs-18">Delete</div>
              </div>

              <div
                className="mt-3 "
                style={{
                  padding: "0.5rem 1.5rem",
                }}
              >
                <div className="text-muted fs-15">
                  Are you sure you want to delete?
                </div>
                <div
                  className="mt-2 text-muted"
                  style={{ fontSize: "14px", width: "350px" }}
                >
                  When a subject is deleted, classes and teacher who are
                  associated with that subject lose the subject too
                </div>
                <div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
                  <button
                    className="px-4 py-1 bg-brand text-light w-25 d-flex align-items-center justify-content-center yes-button"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      this.props.deleteSubjectDetails({ id: item.id }, () => {
                        this.getSubjects()
                      })
                      onClose()
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="px-4 py-1 ml-4 bg-brand text-light w-25 d-flex align-items-center justify-content-center"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={onClose}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )
        },
      }
    )
  }

  saveSubject = () => {
    if (
      this.state.subjectName !== "" &&
      this.state.subjectType &&
      this.state.shortName !== ""
    ) {
      let classArray = []
      for (let k = 0; k < this.state.newClasses.length; k++) {
        if (this.state.newClasses[k].label !== "Select all") {
          classArray.push(this.state.newClasses[k])
        }
      }
      if (classArray.length === 0) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Please assign the class"
        )
        return
      }
      this.setState(
        {
          isShowLoading: true,
        },
        () => {
          let object = {
            masterSubjectId: this.state.masterSubjectId,
            subjectName: this.state.subjectName,
            subjectShortName: this.state.shortName,
            subjectCode: this.state.subjectCode,
            subjectType: this.state.subjectType.value,
            assignClass: classArray,
          }
          this.props.saveSubjectDetails(object, (success, newItem) => {
            this.setState({
              isShowLoading: false,
            })
            if (success) {
              this.getSubjects()
              this.setState({
                masterSubjectId: "",
                subjectName: "",
                shortName: "",
                subjectCode: "",
                subjectType: {},
                newClasses: [],
                isShowAddSubjectInput: false,
              })
            }
          })
        }
      )
    } else {
      this.setState({ hasError: true })
    }
  }

  updateSubject = () => {
    if (this.state.subjectName !== "") {
      let classArray = []
      for (let k = 0; k < this.state.newClasses.length; k++) {
        if (this.state.newClasses[k].label !== "Select all") {
          classArray.push(this.state.newClasses[k])
        }
      }
      if (classArray.length === 0) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Please assign the class"
        )
        return
      }
      this.setState(
        {
          isShowLoading: true,
        },
        async () => {
          let object = {
            id: this.state.updateSubjectDetailId,
            masterSubjectId: this.state.masterSubjectId,
            subjectName: this.state.subjectName,
            subjectShortName: this.state.shortName,
            subjectCode: this.state.subjectCode,
            subjectType: this.state.subjectType.value,
            assignClass: classArray,
          }

          this.props.updateSubjectDetails(object, success => {
            this.setState({
              isShowLoading: false,
            })
            if (success) {
              this.getSubjects()
              this.setState(
                {
                  masterSubjectId: "",
                  subjectName: "",
                  shortName: "",
                  subjectCode: "",
                  subjectType: {},
                  isShowAddSubjectInput: false,
                  isShowUpdateSubjectInput: false,
                  newClasses: [],
                },
                () => {
                  this.newSubjectGroups()
                }
              )
            }
          })
        }
      )
    } else {
      this.setState({ hasError: true })
    }
  }

  saveSubjectGroup = () => {
    const {
      editingGroupId,
      newGroupName,
      selectedGroupClasses,
      selectedGroupSubjects,
    } = this.state

    if (
      newGroupName !== "" &&
      selectedGroupClasses.length > 0 &&
      selectedGroupSubjects.length > 0
    ) {
      this.setState({ groupAdding: true })
      const group = {
        name: newGroupName,
        sectionIds: selectedGroupClasses.filter(e => e.value).map(e => e.value),
        subjectIds: selectedGroupSubjects
          .filter(e => e.value)
          .map(e => e.value),
      }
      if (editingGroupId) group.id = editingGroupId

      this.props.saveSubjectGroup(group, (success, newItem) => {
        if (success) {
          this.setState({
            addSubjectGroupOpen: false,
            editingGroupId: null,
            newGroupName: "",
            selectedGroupClasses: [],
            selectedGroupSubjects: [],
            recentUpdatedId: newItem.id,
          })
          setTimeout(() => this.setState({ recentUpdatedId: null }), 1000) // 0.4second
        }
        this.setState({ groupAdding: false })
      })
    } else {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.SUCCESS,
        "Fill out all fields..."
      )
    }
  }

  renderItem = item => {
    return (
      <div key={item.id} className="col-sm-3">
        <div className="p-2 m-2 text-center rounded text-light bg-midnight-bloom">
          <div className="d-flex justify-content-between">
            <div className="text-left">
              <span>{item.subjectName}</span>
              <br></br>
              <small>
                {item.teacherCount} Teacher{item.teacherCount > 1 && "s"}
              </small>
            </div>
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="nav-link">
                <i className="cursor-pointer fas fa-ellipsis-v"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    this.setState({
                      isShowAddSubjectInput: true,
                      isShowUpdateSubjectInput: true,
                      subjectsSet: [item.subjectName],
                      updateSubjectDetailId: item.id,
                      masterSubjectId: item.masterSubjectId || "",
                      subjectName: item.subjectName || "",
                      shortName: item.subjectShortName || "",
                      subjectCode: item.subjectCode || "",
                      subjectType: {
                        value: item.subjectType,
                        label: item.subjectType,
                      },
                      newClasses: item.assignClass,
                      oldClasses: item.assignClass,
                    })
                    setTimeout(() => {
                      let el = document.getElementById("admin-sp-sd-subject")
                      el.focus()
                    }, 500)
                  }}
                >
                  Edit
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    this.deleteSubjectFromDb(item)
                  }}
                >
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </div>
    )
  }

  codeExists = e => {
    this.state.subjectList.map(code => {
      if (code.subjectCode === e.target.value) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Subject code exists"
        )
        return true
      }
      return true
    })
  }

  createNewMasterSubject = () => {
    this.setState({ isMasterSubjectLoading: true })
    this.props.createMasterSubject(
      { subjectName: this.state.masterSubjectName },
      success => {
        this.setState({
          isCreateSubjectModalOpen: false,
          isMasterSubjectLoading: false,
          masterSubjectName: "",
        })
        this.props.showNotification(
          SUCCESS_TOAST,
          "",
          "Subject created successfully"
        )
      }
    )
  }

  render() {
    const disabledGroups = !this.state.isHigherSecondarySubjects

    return (
      <>
        <AppWrapper
          showAdminProfileSidemenu
          showAdminBackToDashboard
          screenName={
            this.state.selectedTab === "Subjects"
              ? "Subject Details"
              : "Subject Group"
          }
        >
          <Modal
            isOpen={this.state.isDeleteSubjectGroupModalOpen}
            backdropClassName="modal-backdrop-show"
            modalClassName="modal-dialog-shadow-none"
            contentClassName="border-14px"
            centered
            size="sm"
          >
            <div className="p-4">
              <h2 className="mt-3 text-dark">Delete</h2>
              <div className="mt-3">Are you sure you want to delete?</div>
              <div className="mt-3 d-flex text-light">
                <button
                  className="px-4 py-2 cursor-pointer"
                  style={{ backgroundColor: "#084353", borderRadius: 8 }}
                >
                  Yes
                </button>
                <button
                  className="px-4 py-2 ml-3 cursor-pointer"
                  style={{ backgroundColor: "#084353", borderRadius: 8 }}
                  onClick={() => {
                    this.setState({ isDeleteSubjectGroupModalOpen: false })
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={this.state.isCreateSubjectModalOpen}
            backdropClassName="modal-backdrop-show"
            modalClassName="modal-dialog-shadow-none"
            contentClassName="border-14px"
            centered
            size="sm"
          >
            <div className="p-4">
              <h4 className="mt-3 text-dark text-center">Create new subject</h4>

              <FormGroup className="my-4">
                <Input
                  type="text"
                  name="masterSubjectName"
                  id="masterSubjectName"
                  value={this.state.masterSubjectName}
                  placeholder="Enter Subject Name"
                  onChange={e =>
                    this.setState({ masterSubjectName: e.target.value })
                  }
                />
              </FormGroup>

              <div className="d-flex align-items-center justify-content-center">
                <button
                  className="px-4 py-1 btn btn-sm rounded-pill"
                  style={{
                    backgroundColor: "#DBDBDB",
                    color: "#06B191",
                    border: "none",
                  }}
                  onClick={() =>
                    this.setState({ isCreateSubjectModalOpen: false })
                  }
                >
                  Cancel
                </button>

                <button
                  className="px-4 py-1 ml-3 btn btn-sm btn-success rounded-pill"
                  style={{ background: "#06b191", border: "none" }}
                  onClick={this.createNewMasterSubject}
                >
                  {this.state.isMasterSubjectLoading ? (
                    <HashLoader loading size={20} color="#fff" sizeUnit="px" />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </Modal>

          <div className="mx-3 row">
            <div className="col justify-content-start align-items-center">
              {/* {this.state.selectedTab === "Subjects" && (
            <span className="font-weight-bold fs-20">SUBJECT DETAILS</span>
          )}

          {this.state.selectedTab === "Groups" && (
            <span className="font-weight-bold fs-20">SUBJECT GROUP</span>
          )} */}
              {/* <i
            className="fas fa-info-circle ml-3"
            id="subject-info"
            aria-hidden="true"
          ></i>
          <UncontrolledTooltip
            placement="top"
            className="bg-danger"
            target="subject-info"
          >
            Create a customized group with specific subjects which lets you,
            group students, by their choice of subjects
          </UncontrolledTooltip> */}
            </div>
            <div className="px-3 col-lg-auto">
              <div className="top-tab-pills">
                <button
                  onClick={() => this.setState({ selectedTab: "Subjects" })}
                  className={`btn btn-sm ${this.state.selectedTab ===
                    "Subjects" && "active"}`}
                  id="subject-tooltip"
                >
                  <UncontrolledTooltip
                    placement="top"
                    className="bg-danger"
                    target="subject-tooltip"
                  >
                    View subject details
                  </UncontrolledTooltip>
                  Subjects
                </button>
                <button
                  onClick={() => {
                    if (!disabledGroups) {
                      this.setState({
                        selectedTab: "Groups",
                      })
                      this.newSubjectGroups()
                    }
                  }}
                  className={`${!disabledGroups && "btn btn-sm"} ${this.state
                    .selectedTab === "Groups" && "active"}`}
                  id="subject-group-info"
                >
                  <UncontrolledTooltip
                    placement="top"
                    className="bg-danger"
                    target="subject-group-info"
                  >
                    Assign 11 & 12 class to subjects before proceed.
                  </UncontrolledTooltip>
                  Groups
                </button>
              </div>
            </div>
            <div className="col row align-items-center justify-content-end">
              {this.state.selectedTab === "Subjects" && (
                <button
                  id="add-tooltip"
                  type="button"
                  className="px-4 btn btn-sm bg-brand text-light rounded-pill"
                  onClick={() => {
                    this.setState({
                      isShowAddSubjectInput: true,
                      subjectsSet: [""],
                      isShowUpdateSubjectInput: false,
                    })
                    setTimeout(() => {
                      let el = document.getElementById("admin-sp-sd-subject")
                      el.focus()
                    }, 500)
                  }}
                >
                  <UncontrolledTooltip
                    placement="top"
                    className="bg-danger"
                    target="add-tooltip"
                  >
                    Add subject
                  </UncontrolledTooltip>
                  Add
                </button>
              )}
              {this.state.selectedTab === "Groups" &&
                this.state.canCreateGroup &&
                !this.state.editingGroupId && (
                  <>
                    <button
                      type="button"
                      className="px-4 mr-3 btn btn-sm btn-light rounded-pill box-shadow-none"
                      onClick={() => {
                        this.setState({ addSubjectGroupOpen: true })
                        setTimeout(() => {
                          let el = document.getElementById("groupName")
                          el.focus()
                        }, 500)
                      }}
                    >
                      <i className="mr-2 fas fa-plus"></i>Create Subject group
                    </button>
                  </>
                )}
              {this.state.selectedTab === "Groups" &&
                !this.state.canCreateGroup && (
                  <>
                    <div className="px-4 mr-3 rounded-pill box-shadow-none">
                      <i className="mr-2 fas fa-plus"></i>No more higher
                      secondary class to create groups
                    </div>
                  </>
                )}
            </div>
          </div>

          {this.state.selectedTab === "Subjects" && (
            <>
              <Collapse isOpen={this.state.isShowAddSubjectInput}>
                <div className="container ml-3">
                  <div className="form-row">
                    <div className="col-sm-4">
                      <div className="form-group w-100">
                        <small>Subject</small>
                        <select
                          id="admin-sp-sd-subject"
                          className="mr-3 form-control"
                          value={this.state.masterSubjectId}
                          onChange={e => {
                            let findSubject = this.props.subjectMasterList.data.find(
                              item =>
                                item.masterSubjectId === Number(e.target.value)
                            )
                            this.setState({
                              subjectName: findSubject.subjectName,
                              masterSubjectId: Number(e.target.value),
                            })
                          }}
                          onKeyDown={e => {
                            if (e.key === "Enter" && e.target.value) {
                              let el = document.getElementById(
                                "admin-sp-sd-shortname"
                              )
                              el.focus()
                            }
                          }}
                        >
                          <option value="" disabled>
                            Select Subject
                          </option>
                          {this.props.subjectMasterList &&
                            this.props.subjectMasterList.data.map(
                              ({ subjectName, masterSubjectId }) => (
                                <option
                                  value={masterSubjectId}
                                  key={masterSubjectId}
                                >
                                  {subjectName}
                                </option>
                              )
                            )}
                        </select>
                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                          <button
                            className="p-0"
                            onClick={() =>
                              this.setState({ isCreateSubjectModalOpen: true })
                            }
                          >
                            <small style={{ color: "#06b191" }}>
                              Create new Subject
                            </small>
                          </button>

                          {this.state.subjectName === "" &&
                            this.state.hasError && (
                              <small className="text-danger">
                                Subject is required.
                              </small>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-sm-4">
                      <div className="form-group w-100">
                        <small>Subject Short Name</small>
                        <input
                          id="admin-sp-sd-shortname"
                          type="text"
                          value={this.state.shortName}
                          className="form-control form-control-sm input-border-color"
                          placeholder="Short Name"
                          onChange={e => {
                            this.setState({
                              shortName: e.target.value,
                            })
                          }}
                          onKeyDown={e => {
                            if (e.key === "Enter" && e.target.value) {
                              let el = document.getElementById(
                                "admin-sp-sd-sub-code"
                              )
                              el.focus()
                            }
                          }}
                        />
                        <div className="d-flex justify-content-center">
                          {this.state.shortName === "" &&
                            this.state.hasError && (
                              <p className="text-danger">
                                Short Name is required.
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-sm-4">
                      <div className="form-group w-100">
                        <small>Subject Code</small>
                        <input
                          id="admin-sp-sd-sub-code"
                          type="text"
                          value={this.state.subjectCode}
                          className="form-control form-control-sm input-border-color"
                          placeholder="Subject Code (optional)"
                          onChange={e => {
                            this.setState({
                              subjectCode: e.target.value,
                            })
                            this.codeExists(e)
                          }}
                        />
                        {/* <div className="d-flex justify-content-center">
                          {this.state.subjectCode === "" &&
                            this.state.hasError &&
                            <p className="text-danger">
                              Subject code is required.
                            </p>
                          }
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-sm-4">
                      <div className="form-group w-100">
                        <small>Subject Type</small>
                        <Select
                          placeholder="Select Subject"
                          name="classes"
                          isClearable
                          value={this.state.subjectType}
                          options={this.state.typeOfSubjects}
                          className="mb-3 basic-multi-select"
                          styles={colorStyle}
                          classNamePrefix="select"
                          onChange={selectedOption => {
                            if (selectedOption !== null) {
                              this.setState({
                                subjectType: selectedOption,
                                assignedStudentId: selectedOption.id,
                                assignedStudentName: selectedOption.value,
                              })
                            } else {
                              this.setState({ subjectType: selectedOption })
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-sm-4">
                      <div className="form-group w-100">
                        <small>Assign classes</small>
                        <MySelect
                          placeholder="Select Class"
                          isClearable
                          value={this.state.newClasses}
                          styles={this.style}
                          options={this.props.classesList}
                          className="mb-3 basic-multi-select"
                          hideSelectedOptions={false}
                          closeMenuOnSelect={false}
                          allowSelectAll
                          isMulti
                          components={{
                            Option,
                            MultiValue,
                            ValueContainer,
                            animatedComponents,
                          }}
                          classNamePrefix="select"
                          onChange={selectedOption => {
                            if (selectedOption !== null) {
                              this.setState({
                                newClasses: selectedOption,
                              })
                            } else {
                              this.setState({
                                newClasses: [],
                              })
                            }
                          }}
                        />
                      </div>
                      <p>
                        <b>Warning</b> : Removing class from subject will remove
                        all data related and assigned teacher to this subject.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end col-sm-4">
                    <div
                      className="px-4 py-1 cursor-pointer rounded-pill"
                      style={{ backgroundColor: "#DBDBDB", color: "#06B191" }}
                      onClick={() => {
                        this.setState({
                          subjectName: "",
                          shortName: "",
                          subjectCode: "",
                          subjectType: {},
                          newClasses: [],
                          isShowAddSubjectInput: false,
                          masterSubjectId: "",
                          hasError: false,
                        })
                      }}
                    >
                      Cancel
                    </div>
                    {this.state.selectedTab === "Subjects" && (
                      <>
                        {this.state.isShowAddSubjectInput ? (
                          this.state.isShowUpdateSubjectInput ? (
                            <button
                              type="button"
                              disabled={this.state.isShowLoading}
                              className="px-4 ml-3 btn btn-sm btn-success rounded-pill"
                              style={{ background: "#06b191", border: "none" }}
                              onClick={() => this.updateSubject()}
                            >
                              {this.state.isShowLoading ? (
                                <HashLoader
                                  size={16}
                                  color="#fff"
                                  loading={this.state.isShowLoading}
                                  sizeUnit="px"
                                />
                              ) : (
                                "Save"
                              )}
                            </button>
                          ) : (
                            <button
                              type="button"
                              disabled={this.state.isShowLoading}
                              className="px-4 ml-3 btn btn-sm btn-success rounded-pill"
                              style={{ background: "#06b191", border: "none" }}
                              onClick={() => this.saveSubject()}
                            >
                              {this.state.isShowLoading ? (
                                <HashLoader
                                  size={16}
                                  color="#fff"
                                  loading={this.state.isShowLoading}
                                  sizeUnit="px"
                                />
                              ) : (
                                "Save"
                              )}
                            </button>
                          )
                        ) : (
                          <button
                            type="button"
                            className="px-4 btn btn-sm bg-brand text-light rounded-pill"
                            style={{ background: "#084353" }}
                            onClick={() =>
                              this.setState({
                                subjectsSet: [""],
                                isShowAddSubjectInput: true,
                                isShowUpdateSubjectInput: false,
                              })
                            }
                          >
                            <i className="mr-2 fas fa-save" id="add-tooltip" />
                            <UncontrolledTooltip
                              placement="top"
                              className="bg-danger"
                              target="add-tooltip"
                            >
                              Add subject
                            </UncontrolledTooltip>
                            Add
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Collapse>
              {// this.state.coreSubjects &&
              // this.state.coreSubjects.length === 0 &&
              // this.state.languageSubjects &&
              // this.state.languageSubjects.length === 0 &&
              // this.state.otherSubjects &&
              // this.state.otherSubjects.length === 0 ? (
              // <div className="m-3 mt-5 text-center">
              //   <div className="font-weight-bold">
              //     Subject's not available
              //   </div>
              // </div>

              this.state.isShowLoading ? (
                <div
                  className="d-flex justify-content-center"
                  style={{ position: "absolute", left: "50%", top: "50%" }}
                >
                  <HashLoader
                    size={35}
                    color="#084353"
                    loading={this.state.isShowLoading}
                    sizeUnit="px"
                  />
                </div>
              ) : this.state.coreSubjects &&
                this.state.coreSubjects.length === 0 &&
                this.state.languageSubjects &&
                this.state.languageSubjects.length === 0 &&
                this.state.otherSubjects &&
                this.state.otherSubjects.length === 0 ? (
                <div className="m-3 mt-5 text-center">
                  <div className="font-weight-bold">
                    Subject's not available
                  </div>
                </div>
              ) : (
                <div>
                  <div className="m-3">
                    <div className="font-weight-bold">Core Subjects</div>
                    <div className="m-3 row">
                      {this.state.coreSubjects &&
                        this.state.coreSubjects.map(item => {
                          return this.renderItem(item)
                        })}
                    </div>
                  </div>
                  <div className="m-3">
                    {this.state.languageSubjects &&
                      this.state.languageSubjects.length > 0 && (
                        <div className="font-weight-bold">
                          Language Subjects
                        </div>
                      )}
                    <div className="m-3 row">
                      {this.state.languageSubjects &&
                        this.state.languageSubjects.map(item => {
                          return this.renderItem(item)
                        })}
                    </div>
                  </div>
                  <div className="m-3">
                    {this.state.otherSubjects &&
                      this.state.otherSubjects.length > 0 && (
                        <div className="font-weight-bold">Other Subjects</div>
                      )}
                    <div className="m-3 row">
                      {this.state.otherSubjects &&
                        this.state.otherSubjects.map(item => {
                          return this.renderItem(item)
                        })}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {this.state.selectedTab === "Groups" && (
            <>
              <Collapse isOpen={this.state.addSubjectGroupOpen}>
                <div className="my-3 shadow-sm card">
                  <div className="p-3 card-body d-flex">
                    <div className="col-md-7">
                      <FormGroup>
                        <Label htmlFor="groupName">Group Name</Label>
                        <Input
                          type="text"
                          name="groupName"
                          id="groupName"
                          value={this.state.newGroupName}
                          onChange={e =>
                            this.setState({ newGroupName: e.target.value })
                          }
                        />
                      </FormGroup>

                      <div className="d-flex align-items-start justify-content-start">
                        <FormGroup className="mr-2" style={{ flex: 1 }}>
                          <Label htmlFor="groupName">Subjects</Label>
                          <Select
                            placeholder="Select Subjects (Only for 11th & 12th)"
                            isClearable
                            value={this.state.selectedGroupSubjects}
                            options={this.state.groupSubjectOptions.map(c => ({
                              label: `${c.subjectName}`,
                              value: c.subjectId,
                            }))}
                            className="mb-3 basic-multi-select"
                            isMulti
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                            components={{ Option, MultiValue }}
                            classNamePrefix="select"
                            onChange={selectedOption => {
                              this.setState({
                                selectedGroupSubjects: selectedOption,
                              })
                              const Ids = (selectedOption || []).map(
                                e => e.value
                              )
                              const updatedClassList = _.uniqBy(
                                (
                                  this.state.groupSubjectOptions.filter(e =>
                                    Ids.includes(e.subjectId)
                                  ) || []
                                )
                                  .map(e => e.sections)
                                  .flat(),
                                "sectionId"
                              )

                              this.setState({
                                groupClassOptions: updatedClassList,
                              })
                            }}
                          />
                        </FormGroup>
                        <FormGroup style={{ flex: 1 }}>
                          <Label htmlFor="groupName">Classes</Label>
                          <Select
                            placeholder="Select Class (Only for 11th & 12th)"
                            isClearable
                            value={this.state.selectedGroupClasses}
                            options={this.state.groupClassOptions.map(c => ({
                              label: `${c.className}-${c.sectionName}`,
                              value: c.sectionId,
                            }))}
                            className="mb-3 basic-multi-select"
                            isMulti
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                            components={{ Option, MultiValue }}
                            classNamePrefix="select"
                            onChange={selectedOption => {
                              this.setState({
                                selectedGroupClasses: selectedOption,
                              })
                            }}
                          />
                        </FormGroup>
                      </div>
                      <div className="d-flex justify-content-end ">
                        <div
                          className="px-4 py-1 cursor-pointer rounded-pill"
                          style={{
                            backgroundColor: "#DBDBDB",
                            color: "#06B191",
                          }}
                          onClick={() => {
                            this.setState({
                              addSubjectGroupOpen: false,
                              selectedGroupClasses: [],
                              selectedGroupSubjects: [],
                              newGroupName: [],
                              editingGroupId: null,
                            })
                          }}
                        >
                          Cancel
                        </div>

                        <button
                          type="button"
                          disabled={this.state.groupAdding}
                          className="px-4 ml-3 btn btn-sm btn-success rounded-pill"
                          style={{ background: "#06b191", border: "none" }}
                          onClick={this.saveSubjectGroup}
                        >
                          {this.state.groupAdding ? (
                            <HashLoader
                              size={20}
                              color="#fff"
                              loading={this.state.groupAdding}
                              sizeUnit="px"
                            />
                          ) : (
                            "Save"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
              <h5 className="mt-3 text-dark font-weight-bold">
                Group Subjects
              </h5>
              {!this.state.isShowSubjectGroupLoading ? (
                this.props.subjectGroupsList &&
                this.props.subjectGroupsList.length > 0 ? (
                  <div className="row">
                    {this.props.subjectGroupsList.map(group => (
                      <SubjectGroup
                        key={group.id}
                        {...group}
                        recentUpdated={this.state.recentUpdatedId === group.id}
                        onClickUpdate={() => {
                          this.setState({
                            addSubjectGroupOpen: true,
                            editingGroupId: group.id,
                            newGroupName: group.name,
                          })
                          const updatedSubjectList = group.subjects.map(c => ({
                            label: `${c.subjectName}`,
                            value: c.subjectId,
                          }))
                          const updatedClassList = group.sections.map(c => ({
                            label: `${c.className}-${c.sectionName}`,
                            value: c.sectionId,
                          }))
                          this.setState({
                            selectedGroupSubjects: updatedSubjectList,
                            selectedGroupClasses: updatedClassList,
                          })
                          setTimeout(() => {
                            let el = document.getElementById("groupName")
                            el.focus()
                          }, 500)
                        }}
                        onClickDelete={cb =>
                          this.props.deleteSubjectGroup(group, success => {
                            if (!success) return
                            cb()
                          })
                        }
                        modalOpen={() => {
                          this.setState({ isDeleteSubjectGroupModalOpen: true })
                        }}
                        deleteSubjectGroupSubject={(id, cb) => {
                          this.props.deleteSubjectGroupSubject(
                            { id },
                            success => {
                              if (!success) return
                              cb()
                            }
                          )
                        }}
                        deleteSubjectGroupClass={(id, cb) => {
                          this.props.deleteSubjectGroupClass(
                            { id },
                            success => {
                              if (!success) return
                              cb()
                            }
                          )
                        }}
                      />
                    ))}
                  </div>
                ) : (
                  <h4 className="mx-auto mt-3 text-dark">
                    No groups yet, start adding groups!
                  </h4>
                )
              ) : (
                <SkeletonTheme
                  color="#084353"
                  highlightColor="rgba(255,255,255,0.2)"
                >
                  <div className="d-flex align-items-start justify-content-evenly">
                    <div className="m-3">
                      <Skeleton width={250} height={87} />
                    </div>
                  </div>
                </SkeletonTheme>
              )}
            </>
          )}
        </AppWrapper>
      </>
    )
  }
}

function SubjectGroup({
  name,
  subjects,
  sections,
  teacherCount,
  recentUpdated,
  onClickUpdate,
  // modalOpen,
  onClickDelete,
  deleteSubjectGroupSubject,
  deleteSubjectGroupClass,
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (recentUpdated) setIsOpen(true)
  }, [recentUpdated])

  return (
    <>
      <div className="w-20 m-3" style={{ opacity: isLoading && 0.5 }}>
        <div className="p-3 rounded bg-midnight-bloom">
          <div className="d-flex justify-content-between">
            <h5>
              {name}
              <small className="ml-3 text-muted">
                {"- " +
                  subjects
                    .slice(0, 3)
                    .map(subj => subj.subjectName.substr(0, 3))
                    .join(", ") +
                  subjects.length >
                3
                  ? "..."
                  : ""}
              </small>
            </h5>
            {!isLoading && (
              <UncontrolledDropdown>
                <DropdownToggle tag="a" className="nav-link">
                  <i className="text-white cursor-pointer fas fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={onClickUpdate}>Update</DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      confirmAlertPopUp(
                        VALIDATION_MSG.DELETE_TITLE,
                        VALIDATION_MSG.DELETE_CONTENT,
                        yes => {
                          if (yes) {
                            setIsLoading(true)
                            onClickDelete(() => setIsLoading(false))
                          }
                        },
                        {
                          buttons: [],
                          customUI: ({ onClose }) => {
                            return (
                              <div
                                className="custom"
                                style={{
                                  backgroundColor: "#fff",
                                  borderBottomRightRadius: "12px",
                                  borderBottomLeftRadius: "12px",
                                }}
                              >
                                <div
                                  className="bg-brand text-light d-flex"
                                  style={{
                                    padding: "0.5rem 2rem",
                                    borderTopRightRadius: "12px",
                                    borderTopLeftRadius: "12px",
                                  }}
                                >
                                  <div className="mx-auto fs-18">Delete</div>
                                </div>

                                <div
                                  className="mt-3"
                                  style={{
                                    padding: "0.5rem 1.5rem",
                                  }}
                                >
                                  <div className="text-muted fs-15">
                                    Are you sure you want to delete?
                                  </div>
                                  <div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
                                    <button
                                      className="px-4 py-1 bg-brand text-light w-25 d-flex align-items-center justify-content-center yes-button"
                                      style={{
                                        borderRadius: "5px",
                                      }}
                                      onClick={() => {
                                        setIsLoading(true)
                                        onClickDelete(() => setIsLoading(false))
                                        onClose()
                                      }}
                                    >
                                      Yes
                                    </button>
                                    <button
                                      className="px-4 py-1 ml-4 bg-brand text-light w-25 d-flex align-items-center justify-content-center"
                                      style={{
                                        borderRadius: "5px",
                                      }}
                                      onClick={onClose}
                                    >
                                      No
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )
                          },
                        }
                      )
                    }}
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
          </div>

          <div className="mt-1 text-white">
            {teacherCount} Teacher{teacherCount > 1 && "s"}
          </div>

          {!isOpen && (
            <div
              onClick={() => setIsOpen(true)}
              style={{
                height: 20,
                width: 20,
                backgroundColor: "rgba(156,156,156,0.4)",
              }}
              className="mx-auto mt-1 text-white cursor-pointer d-flex align-items-center justify-content-center rounded-pill"
            >
              <i className="m-0 fas fa-angle-down"></i>
            </div>
          )}
        </div>

        {isOpen && (
          <div
            className="p-3 mx-3"
            style={{
              borderBottomRightRadius: 24,
              borderBottomLeftRadius: 24,
              backgroundColor: "#e2e0e0",
            }}
          >
            <h6 className="subjectgroup-title font-weight-bold text-dark">
              Subjects
            </h6>
            <ul
              style={{ listStyleType: "none" }}
              className="flex-wrap p-0 m-0 d-flex"
            >
              {subjects.map(subj => (
                <li
                  key={subj.subjectId}
                  style={{
                    padding: "4px 12px",
                    margin: "2px 6px",
                    backgroundColor: "rgba(6,177,145,0.25)",
                    color: "#022636",
                  }}
                  className="rounded-pill"
                >
                  {subj.subjectName}
                  {!isLoading && (
                    <i
                      className="ml-2 fa fa-times text-dark"
                      onClick={() => {
                        if (!isLoading) {
                          setIsLoading(true)
                          deleteSubjectGroupSubject(
                            subj.subjectGroupSubject.id,
                            () => setIsLoading(false)
                          )
                        }
                      }}
                    />
                  )}
                </li>
              ))}
            </ul>

            <div className=" subjectgroup-title font-weight-bold">
              Assigned Classes
            </div>

            <ul
              style={{ listStyleType: "none" }}
              className="flex-wrap p-0 m-0 d-flex"
            >
              {sections.map(cls => (
                <li
                  key={cls.sectionId}
                  style={{
                    padding: "4px 12px",
                    margin: "2px 6px",
                    backgroundColor: "rgba(6,177,145,0.25)",
                    color: "#022636",
                  }}
                  className="rounded-pill"
                >
                  {cls.className} {cls.sectionName}
                  {!isLoading && (
                    <i
                      className="ml-2 fa fa-times text-dark"
                      onClick={() => {
                        if (!isLoading) {
                          setIsLoading(true)
                          deleteSubjectGroupClass(
                            cls.subjectGroupClass.id,
                            () => setIsLoading(false)
                          )
                        }
                      }}
                    />
                  )}
                </li>
              ))}
            </ul>

            <div
              onClick={() => setIsOpen(false)}
              style={{
                height: 20,
                width: 20,
                margin: "0px auto",
                backgroundColor: "rgba(7,75,88,0.82)",
              }}
              className="mt-3 text-white cursor-pointer d-flex align-items-center justify-content-center rounded-pill"
            >
              <i className="m-0 fas fa-angle-up"></i>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    subjectGroupsList: state.subjectDetailsState.subjectGroupsList,
    classesList: state.classDetailsState.classList,
    subjectMasterList: state.subjectDetailsState.subjectMasterList,
    subjectList: state.subjectDetailsState.subjectList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      deleteSubjectGroupSubject,
      deleteSubjectGroupClass,
      updateSubjectDetails,
      deleteSubjectDetails,
      getSubjectMasterList,
      createMasterSubject,
      saveSubjectDetails,
      deleteSubjectGroup,
      getSubjectDetails,
      getSubjectGroups,
      newSubjectGroups,
      saveSubjectGroup,
      showNotification,
      getClassDetails,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(SubjectsScreen)
