import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import { showNotification } from "../CommonAction"
import {
  SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"
import URL from "../../config"

export const getTeacherSchedule = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.TEACHER_SCHEDULE,
        object,
        true,
        true
      )
      if (response) {
        myLog("====getTeacherSchedule RESPONSE===::::", response)
        // dispatch(saveClassDetailsInStore(response.teacherClasses))
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getTeacherSchedule ERROR===????", error)
    }
  }
}

export const saveSchedule = (body, callBack) => {
  return async dispatch => {
    try {
      let response
      if (body.isUpdate) {
        response = await Client.put(URL.TEACHER_SCHEDULE, body, true, true)
      } else {
        response = await Client.post(URL.TEACHER_SCHEDULE, body, true, true)
      }
      if (response.responseCode === 0) {
        myLog("====saveSchedule RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true, response.data)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveSchedule ERROR===????", error)
    }
  }
}

export const getSingleScheduleDetails = (id, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.TEACHER_SCHEDULE + "/" + id,
        null,
        true,
        true
      )
      if (response) {
        myLog("====getSingleScheduleDetails RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====getSingleScheduleDetails ERROR===????", error)
    }
  }
}

export const deleteScheduleDetails = (id, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.TEACHER_SCHEDULE + "/" + id,
        null,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteScheduleDetails RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteScheduleDetails ERROR===????", error)
    }
  }
}

export const checkIntegration = (id, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.TEACHER_SCHEDULE + "/checkIntegration",
        null,
        true,
        true
      )
      if (response) {
        myLog("====checkIntegration RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====checkIntegration ERROR===????", error)
    }
  }
}
