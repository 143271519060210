import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { showNotification } from "../CommonAction"
import {
  SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"

import { ACTION_TYPES } from "../types"
export const saveAdminSchoolDetailsInStore = adminSchoolDetail => {
  return {
    type: ACTION_TYPES.ADMIN_DASBOARD_SCHOOL_DETAIL,
    payload: adminSchoolDetail,
  }
}

export const saveAdminSchoolProfile = (body, callBack) => {
  return async dispatch => {
    try {
      myLog("haha", body)
      const response = await Client.post(URL.GET_ADMIN_PROFILE, body, true)
      if (response.responseCode === 0) {
        myLog("====saveAdminSchoolProfile RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "something is wrong"
        )
      )
      callBack(false)
      myLog("====saveAdminSchoolProfile ERROR===????", error)
    }
  }
}

export const getAdminSchoolProfile = (body, callBack) => {
  return async () => {
    try {
      myLog("haha", body)
      const response = await Client.get(URL.GET_ADMIN_PROFILE, body, true)
      if (response.responseCode === 0) {
        myLog("====getAdminSchoolProfile RESPONSE===::::", response)

        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getAdminSchoolProfile ERROR===????", error)
    }
  }
}

//Teacher Profile Get
export const getAdminTeacherProfile = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_ADMIN_TEACHER_PROFILE,
        body,
        true
      )
      if (response) {
        myLog("====getTeacherProfile RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getTeacherProfile ERROR===????", error)
    }
  }
}
