/*************************************************
 * RedInk
 * @exports
 * @class Dashboard.js
 * @extends Component
 * @author Sasidharan // on 11/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import Select, { components } from "react-select"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import {
  deleteTask,
  saveTask,
  getTask,
} from "../../../actions/teacher/TaskManagementAction"
import { myLog } from "../../../utils/Utility"

import MessageModal from "../MessageModal"
import AppWrapper from "../Wrapper"

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.value} </label>
      </components.Option>
    </div>
  )
}

const MultiValue = props => {
  return (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  )
}

class TaskDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showSideMenu: true,
      isMessageModalOpen: false,
      isShowAll: true,
      isStudentTask: false,
      isShowStarred: false,
      isCompleted: false,
      isDueTask: false,
      isShowToday: false,
      isShowMonth: false,
      todayCount: 0,
      thismonthCount: 0,
      TaskList: [],
      studentsList: [
        { id: "1", value: "LKG-A", label: "LKG-A" },
        { id: "2", value: "LKG-B", label: "LKG-B" },
      ],
      taskAssignee: [],
    }
  }

  componentDidMount() {
    this.props.getTask((success, response) => {
      myLog(response)
      if (success) {
        this.setState({
          TaskList: response,
        })
      }
    })
  }

  /**
   * Handles message modal
   */
  closeMessageModal = () => this.setState({ isMessageModalOpen: false })

  /**
   * handles on click message icon in sidebar
   */
  onClickMessageIcon = () => {
    this.setState({ isMessageModalOpen: !this.state.isMessageModalOpen })
  }
  /**
   * handles message submit
   */
  onSubmitMessage = () => {
    this.closeMessageModal()
  }

  saveTask = () => {
    let obj = {
      tasktitle: this.state.tasktitle,
      taskDescription: this.state.taskDescription,
      taskDate: this.state.taskDate,
      taskAssignee: this.state.taskAssignee,
      taskPriority: this.state.taskPriority,
    }
    this.props.saveTask(obj, (success, response) => {
      if (success) {
        myLog(response)
        this.setState({
          tasktitle: "",
          taskDescription: "",
          taskDate: "",
          taskAssignee: [],
          taskPriority: "",
        })
      } else {
        myLog("REQUEST CODE:", response)
      }
    })
  }

  deleteTask = () => {
    myLog("haha")
  }

  render() {
    return (
      // <AppWrapper
      // openMessageModal={() => this.onClickMessageIcon()}
      // showAdminProfileSidemenu
      // showAdminBackToDashboard>
      <div>
        <div
          className="modal right slide-in-right fade mt-0"
          data-backdrop="static"
          id="taskModal"
          role="dialog"
          aria-labelledby="myModalLabel2"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel2">
                  Create Task
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group pb-4">
                    <label htmlFor="taskTitle">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      name="taskTitle"
                      placeholder="Enter Title"
                      value={this.state.tasktitle}
                      onChange={e =>
                        this.setState({ tasktitle: e.target.value })
                      }
                      required
                    />
                  </div>
                  <div className="form-group pb-4">
                    <label htmlFor="taskDescription">Description</label>
                    <textarea
                      className="form-control"
                      name="taskDescription"
                      rows="5"
                      value={this.state.taskDescription}
                      onChange={e =>
                        this.setState({ taskDescription: e.target.value })
                      }
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <label htmlFor="example-date-input">Due Date</label>
                    <input
                      className="form-control"
                      type="date"
                      id="example-date-input"
                      value={this.state.taskDate}
                      onChange={e =>
                        this.setState({ taskDate: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <Select
                      placeholder="Select Student"
                      name="classes"
                      isClearable
                      isMulti
                      components={{ Option, MultiValue }}
                      value={this.state.taskAssignee}
                      options={this.state.studentsList}
                      className="basic-multi-select mb-3"
                      classNamePrefix="select"
                      hideSelectedOptions={false}
                      closeMenuOnSelect={false}
                      onChange={selectedOption => {
                        myLog(selectedOption)
                        if (selectedOption !== null) {
                          this.setState({ taskAssignee: selectedOption })
                        }
                      }}
                    />
                  </div>
                  {/* <div className="form-group row">
                    <label className="col-sm-2 text-center col-form-label">
                      PRIORITY
                    </label>
                    <div className="col-sm-1" style={{marginLeft: "15px"}}>
                      <button className="form-control text-light text-center" style={{backgroundColor:"#469896"}}>!!!</button>
                    </div>
                    <div className="col-sm-1">
                    <button className="form-control text-light text-center" style={{backgroundColor:"#469896"}}>!!</button>
                    </div>
                    <div className="col-sm-1">
                    <button className="form-control text-light text-center" style={{backgroundColor:"#469896"}}>!</button>
                    </div>
                  </div> */}
                  <div className="d-flex align-content-end">
                    <button
                      type="button"
                      className="btn btn-theme align-self-end"
                      onClick={() => this.saveTask()}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <AppWrapper
          openMessageModal={() => this.onClickMessageIcon()}
          teacherDashboard
          showTeacherThree
          showTeacherLogo
        >
          <MessageModal
            isModalOpen={this.state.isMessageModalOpen}
            closeModal={() => this.onClickMessageIcon()}
            onSubmitMessage={() => this.onSubmitMessage()}
          />

          <div className="mx-2">
            <div className="row">
              <div className="col-sm-3 pl-0">
                <div className="card shadow">
                  <div className="card-header">
                    <button
                      className="btn btn-dark btn-block rounded-pill"
                      type="button"
                      data-toggle="modal"
                      data-target="#taskModal"
                      onClick={() => {
                        //   this.setState({
                        //     isMessageModalOpen: true,
                        //     isShowAll: true,
                        //     isShowStarred: false,
                        //     isShowMonth: false,
                        //     isShowToday: false,
                        // isStudentTask: false,
                        // isShowStarred: false,
                        // isCompleted: false,
                        // isDueTask: false,
                        //   })
                      }}
                    >
                      <i className="fas fa-edit mr-2" />
                      Create Task
                    </button>
                  </div>
                  <ul className="list-group list-group-flush">
                    <li
                      className={`list-group-item ${
                        this.state.isShowAll ? "li-bg-color" : ""
                      }`}
                      onClick={() => {
                        this.setState({
                          isShowAll: true,
                          isShowStarred: false,
                          isShowMonth: false,
                          isShowToday: false,
                          isStudentTask: false,
                          isCompleted: false,
                          isDueTask: false,
                        })
                        //   this.getCommunication()
                      }}
                    >
                      All
                    </li>
                    <li
                      className={`list-group-item ${
                        this.state.isStudentTask ? "li-bg-color" : ""
                      }`}
                      onClick={() => {
                        this.setState({
                          isShowAll: false,
                          isShowStarred: false,
                          isShowMonth: false,
                          isShowToday: false,
                          isStudentTask: true,
                          isCompleted: false,
                          isDueTask: false,
                        })
                        //   this.getCommunication(2)
                      }}
                    >
                      Student's task
                    </li>
                    <li
                      className={`list-group-item ${
                        this.state.isShowStarred ? "li-bg-color" : ""
                      }`}
                      onClick={() => {
                        this.setState({
                          isShowAll: false,
                          isShowStarred: true,
                          isShowMonth: false,
                          isShowToday: false,
                          isStudentTask: false,
                          isCompleted: false,
                          isDueTask: false,
                        })
                        //   this.getCommunication(2)
                      }}
                    >
                      Starred
                    </li>
                    <li
                      className={`list-group-item ${
                        this.state.isCompleted ? "li-bg-color" : ""
                      }`}
                      onClick={() => {
                        this.setState({
                          isShowAll: false,
                          isShowStarred: false,
                          isShowMonth: false,
                          isShowToday: false,
                          isStudentTask: false,
                          isCompleted: true,
                          isDueTask: false,
                        })
                        //   this.getCommunication(2)
                      }}
                    >
                      Completed
                    </li>
                  </ul>
                </div>
                <div className="card my-3 shadow">
                  <div className="card-header">
                    <i className="fas fa-inbox mr-2"></i>
                    Summary
                  </div>
                  <ul className="list-group list-group-flush">
                    <li
                      className={`list-group-item justify-content-between d-flex align-items-center ${
                        this.state.isDueTask ? "li-bg-color" : ""
                      }`}
                      onClick={() => {
                        this.setState({
                          isShowAll: false,
                          isShowStarred: false,
                          isShowMonth: false,
                          isShowToday: false,
                          isStudentTask: false,
                          isCompleted: false,
                          isDueTask: true,
                        })
                        //   this.getCommunication(1)
                      }}
                    >
                      Due task
                      <span className="badge badge-info">
                        {this.state.todayCount}
                      </span>
                      <span className="sr-only">unread messages</span>
                    </li>
                    <li
                      className={`list-group-item justify-content-between d-flex align-items-center ${
                        this.state.isShowToday ? "li-bg-color" : ""
                      }`}
                      onClick={() => {
                        this.setState({
                          isShowAll: false,
                          isShowStarred: false,
                          isShowMonth: false,
                          isShowToday: true,
                          isStudentTask: false,
                          isCompleted: false,
                          isDueTask: false,
                        })
                        //   this.getCommunication(1)
                      }}
                    >
                      Today
                      <span className="badge badge-info">
                        {this.state.todayCount}
                      </span>
                      <span className="sr-only">unread messages</span>
                    </li>
                    <li
                      className={`list-group-item justify-content-between d-flex align-items-center ${
                        this.state.isShowMonth ? "li-bg-color" : ""
                      }`}
                      onClick={() => {
                        this.setState({
                          isShowAll: false,
                          isShowStarred: false,
                          isShowMonth: true,
                          isShowToday: false,
                          isStudentTask: false,
                          isCompleted: false,
                          isDueTask: false,
                        })
                        //   this.getCommunication(3)
                      }}
                    >
                      This week
                      <span className="badge badge-info">
                        {this.state.thismonthCount}
                      </span>
                      <span className="sr-only">unread messages</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-9 pl-0">
                <div className="card shadow">
                  <div className="table-responsive rounded">
                    <p className="mt-2 mx-3 fs-20">Sent Messages</p>
                    <table className="text-nowrap table-lg m-0 table table-hover">
                      <tbody>
                        {this.state.TaskList &&
                          this.state.TaskList.map((item, index) => {
                            return (
                              <tr data-toggle="modal" data-target="#taskModal">
                                <td
                                  className="text-center"
                                  style={{ width: "78px" }}
                                >
                                  <div className="custom-checkbox custom-control">
                                    <input
                                      type="checkbox"
                                      id="eCheckbox1"
                                      className="custom-control-input"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="eCheckbox1"
                                    >
                                      &nbsp;
                                    </label>
                                  </div>
                                </td>
                                <td className="text-center pl-1">
                                  {item.isStarred === 0 ? (
                                    <div
                                      onClick={() => {
                                        //   let array = this.state.TaskList
                                        //   array[index].isStarred = 1
                                        //   this.state.saveCommunicationDetailsInStore(
                                        //     array
                                        //   )
                                        //   let object = {
                                        //     communicationId: item.id,
                                        //   }
                                        //   this.state.saveCommunicationStarredDetails(
                                        //     object,
                                        //     () => {}
                                        //   )
                                      }}
                                    >
                                      <i className="far fa-star" />
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() => {
                                        //   let array = this.state.TaskList
                                        //   array[index].isStarred = 0
                                        //   this.state.saveCommunicationDetailsInStore(
                                        //     array
                                        //   )
                                        //   let object = {
                                        //     communicationId: item.id,
                                        //   }
                                        //   this.state.saveCommunicationStarredDetails(
                                        //     object,
                                        //     () => {}
                                        //   )
                                      }}
                                    >
                                      <i className="fa fa-star text-dark" />
                                    </div>
                                  )}
                                </td>
                                <td>
                                  <div className="widget-content p-0">
                                    <div className="widget-content-wrapper">
                                      <div className="widget-content-left">
                                        {item.sentNames &&
                                          item.sentNames.map(name => {
                                            return (
                                              <div className="widget-heading rounded bg-primary text-white px-3">
                                                {/* {item.role === '0'?
                                        item.studentName
                                        :
                                        item.role === '1' ? 
                                          item.className
                                          :
                                          item.role === '2' ?
                                            item.teacherName
                                            :
                                            null
                                      } */}
                                                {name}
                                              </div>
                                            )
                                          })}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="text-left">
                                  {item.messageSubject}
                                </td>
                                <td className="text-center">
                                  <i className="fa fa-calendar-alt opacity-4 mr-2" />
                                  {item.sentOn}
                                </td>
                                <td>
                                  <button
                                    className="badge badge-pill badge-danger"
                                    onClick={() => this.deleteTask()}
                                  >
                                    <small>
                                      <i className="far fa-user-minus mr-1"></i>
                                      Delete
                                    </small>
                                  </button>
                                </td>
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AppWrapper>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    subjectList: state.communicationDetailsState.subjectList,
    classesList: state.communicationDetailsState.classList,
    teachersList: state.communicationDetailsState.teachersList,
    studentsList: state.communicationDetailsState.studentsList,
    TaskList: state.communicationDetailsState.TaskList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      saveTask,
      deleteTask,
      getTask,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(TaskDashboard)
