import React, { Fragment, useMemo } from "react"
import getRandom from "../../../../utils/getRandom"
// import wrongImg from "../../../../assets/images/wrong.png"
// import tickImg from "../../../../assets/images/tick.png"
import Mark from "./Mark"

const remarks = [
  "Good in grammar, need to focus on hand writing",
  "Take time to check the spelling mistakes",
  "Improve solving skills",
  "Improve practical skills",
  "Handle descriptive answers properly",
]

const clrData = [
  {
    bg: "bg-DC625E",
    val: 0,
  },
  {
    bg: "bg-FAB94B",
    val: 40,
  },
  {
    bg: "bg-4FC1B6",
    val: 70,
  },
  {
    bg: "bg-0A9394",
    val: 90,
  },
]

function Detail({ id = "", val = "" }) {
  return (
    <div className="d-flex align-items-center">
      <p
        style={id === "Class" ? { width: "58px" } : {}}
        className="mb-0 mr-4 fs-10 fw-500 clr-5E5F5F"
      >
        {id}
      </p>
      <p className="mb-0 fs-11 fw-600 clr-2B2B2B">{val}</p>
    </div>
  )
}

function Template({
  displayStudentName = true,
  displaySectionName = true,
  displayTeacherName = true,
  displayRollNo = true,
  displayDOB = true,

  displayClassTeacherSignature = true,
  displayPrincipalSignature = true,
  displayParentsSignature = true,

  // displayOverallGrade = true,
  // displayOverallPercentage = true,
  displayOverallRemarks = true,

  displayStudentRank = true,
  displayScholasticScore = true,
  displayScholasticGrade = true,
  displayScholasticSubjectRemarks = true,

  reportCardTitle = "Academic Year 2022 - 2023",
  selectedAssessments = [],
  // selectedTerms = [],
  gradeDetail = [],

  terms = [],
  marks = [],

  className,
  schoolLogo,
  schoolName,
  city,
}) {
  const [
    fTerms,
    fAssessments,
    fMarkPlaceHolders,
    fMarks,
    // fTotalRow,
    fculativeDetail,
    fOverAll,
  ] = useMemo(() => {
    let finalTerms = []
    let finalAssessments = []

    let markPlaceHolder = []
    let finalMarkPlaceHolder = []

    let finalMarks = []
    // let totalRow = []

    let termCumulation = []
    let cumlationDetail = null

    let overAll = []

    let details = {}
    let width = 0

    if (displayScholasticScore) {
      width = width + 44
      markPlaceHolder.push({
        name: "(100)",
        width: 44,
      })
    }
    if (displayScholasticGrade) {
      width = width + 44
      markPlaceHolder.push({
        name: "Grade",
        width: 44,
      })
    }
    if (displayScholasticSubjectRemarks) {
      width = width + 250
      markPlaceHolder.push({
        name: "Subject Remark",
        width: 250,
      })
    }

    terms.forEach(t => {
      let decideOverAll = []
      let currentlySelectedAssessments = []

      t.assementList.forEach(a => {
        if (selectedAssessments.includes(a.assessmentName)) {
          if (details[t.termName]) {
            details[t.termName] = details[t.termName] + 1
          } else {
            details[t.termName] = 1
          }
          currentlySelectedAssessments.push({
            ...a,
            termName: t.termName,
            termId: t.termId,
            width,
            span: markPlaceHolder.length,
          })
          decideOverAll.push(a.assessmentName)
        }
      })

      if (details[t.termName]) {
        let isAloneAssessment =
          currentlySelectedAssessments.length === 1 &&
          details[t.termName] !== t.assementList.length

        if (isAloneAssessment) {
          currentlySelectedAssessments[0].isDisabled = true
        }

        if (
          details[t.termName] === t.assementList.length &&
          t.resultCondition
        ) {
          currentlySelectedAssessments = currentlySelectedAssessments.filter(
            csa => csa.assessmentType !== t.mainAssessmentType
          )
          currentlySelectedAssessments.unshift({
            assessmentId: `${getRandom(100, 1000)}-${getRandom(100, 1000)}`,
            assessmentName: `${t.mainAssessmentType}s`,
            assessmentShortName: `${t.mainAssessmentType}s`,
            termName: t.termName,
            termId: t.termId,
            width,
            span: markPlaceHolder.length,
          })
        }

        finalAssessments.push(...currentlySelectedAssessments)

        currentlySelectedAssessments.forEach(a => {
          finalMarkPlaceHolder.push(...markPlaceHolder)
        })

        let currentTermDetails = {
          termName: isAloneAssessment
            ? currentlySelectedAssessments[0].assessmentName
            : t.termName,
          rowSpan: isAloneAssessment ? 2 : 1,
          termId: t.termId,
          width: currentlySelectedAssessments.length * width,
          span: currentlySelectedAssessments.length * markPlaceHolder.length,
        }

        if (details[t.termName] === t.assementList.length) {
          decideOverAll = [t.termName]
          finalAssessments.push({
            isTermCumulative: true,
            assessmentName: t.termName,
            termName: t.termName,
            termId: t.termId,
            width,
            span: markPlaceHolder.length,
          })

          currentTermDetails.width = currentTermDetails.width + width
          currentTermDetails.span =
            currentTermDetails.span + markPlaceHolder.length
          finalMarkPlaceHolder.push(...markPlaceHolder)

          termCumulation.push({
            termShortName: t.termShortName,
            termName: t.termName,
            termId: t.termId,
          })
        }

        finalTerms.push(currentTermDetails)
      }

      if (decideOverAll.length) {
        overAll.push(...decideOverAll)
      }
    })

    marks.forEach(m => {
      finalMarks.push({
        subject: m.subject,
        list: m.list.filter(l =>
          finalAssessments.some(f => f.assessmentName === l.assessment)
        ),
      })
    })

    if (termCumulation.length > 1) {
      cumlationDetail = {
        title: termCumulation.reduce(
          (prev, curr) =>
            prev ? prev + "+" + curr.termShortName : curr.termShortName,
          ""
        ),
        span: markPlaceHolder.length,
      }
      finalMarks.forEach(f => {
        let lastColSumedMark = f.list.reduce((prev, curr) => {
          let desidedMark = !curr.isTermCumulative ? curr.mark : 0
          return prev + desidedMark
        }, 0)

        let calculatedMark = Math.floor(
          lastColSumedMark / selectedAssessments.length
        )

        let grade = gradeDetail.find(
          g => calculatedMark <= Number(g.gradeScoreTo)
        )?.gradeName

        f.list.push({
          id: `${getRandom(100, 1000)}-${getRandom(100, 1000)}`,
          mark: calculatedMark,
          grade: grade,
        })
      })
    }

    // finalMarks[0].list.forEach((f, i) => {
    //   let reducedTotal = finalMarks.reduce(
    //     (prev, curr) => prev + curr.list[i].mark,
    //     0
    //   )
    //   totalRow.push({
    //     total: reducedTotal,
    //     span: markPlaceHolder.length,
    //   })
    // })

    overAll = overAll.map(o => {
      let mark = getRandom(10, 100)
      let grade = gradeDetail.find(g => mark <= Number(g.gradeScoreTo))
        ?.gradeName

      let details = {
        mark,
        grade,
        title: o,
      }

      return details
    })

    return [
      finalTerms,
      finalAssessments,
      finalMarkPlaceHolder,
      finalMarks,
      // totalRow,
      cumlationDetail,
      overAll,
    ]
  }, [
    terms,
    marks,
    selectedAssessments,
    displayScholasticGrade,
    displayScholasticScore,
    displayScholasticSubjectRemarks,
  ])

  return (
    <div className="template-wrapper p-4 rounded-top bg-white font-plusJak">
      <div className="d-flex align-items-center">
        <img
          className="template-wrapper-school-img"
          width="56"
          height="56"
          src={schoolLogo}
          alt=""
        />
        <div className="ml-2 mb-2">
          <h1 className="my-1 fs-13 fw-700 clr-121212">{schoolName}</h1>
          <p className="fs-10 fw-500 clr-5E5F5F">{city}</p>
        </div>
      </div>

      <div className="grid-col-3 gap-1rem my-4" style={{ gap: "6px" }}>
        {displayStudentName && <Detail id="Student Name" val="Peter Parker" />}
        {displaySectionName && <Detail id="Class" val={`${className}-A`} />}
        {displayDOB && <Detail id="DOB" val="28.01.1999" />}
        {displayTeacherName && <Detail id="Teacher Name" val="Maria Joseph" />}
        {displayRollNo && <Detail id="Roll Number" val="2345678" />}
      </div>

      <div className="template-wrapper-mark-cont border-9393932e">
        <h2 className="py-2 fs-11 fw-700 text-white bg-0A9394 text-center">
          {reportCardTitle}
        </h2>

        <div className="px-2 pb-2 fs-7 clr-2B2B2B">
          <div className="d-flex align-items-center">
            {clrData.map(v => (
              <div key={v.val} className="col px-0 fw-600">
                <div className={`py-1 ${v.bg}`}></div>
                <div className="d-flex justify-content-between">
                  <span>{v.val}</span>
                  {v.val === 90 && (
                    <span className="d-inline-block text-right">100</span>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="fw-500">(All Values are in %)</div>
        </div>

        <table className="rc-mark-table w-100" cellSpacing={0}>
          <tbody>
            <tr
              className={`fs-8 fw-700 clr-171717 text-center ${
                !fTerms.length ? "last-td-right-border" : ""
              }`}
            >
              <td rowSpan={3} className="text-left">
                <div className="first-col">Subject Name</div>
              </td>

              {fTerms.map(t => (
                <td
                  key={t.termName}
                  colSpan={t.span}
                  style={{ width: `${t.width}px` }}
                  rowSpan={t.rowSpan}
                >
                  {t.termName}
                </td>
              ))}

              {fculativeDetail && (
                <td rowSpan={3} colSpan={fculativeDetail.span}>
                  <div className="d-flex flex-column align-items-center justify-content-center flex-wrap">
                    <span>{fculativeDetail.title}&nbsp;</span>
                    <span>Out of 100</span>
                  </div>
                </td>
              )}
            </tr>

            <tr
              className={`clr-2B2B2B text-center ${
                fculativeDetail ? "last-td-right-border" : ""
              }`}
            >
              {fAssessments.map(a => (
                <td
                  key={a.assessmentName}
                  style={{ width: `${a.width}px` }}
                  colSpan={a.span}
                  className={`fs-9 fw-600 ${a.isDisabled ? "d-none" : ""}`}
                >
                  {a.assessmentName}
                </td>
              ))}
            </tr>

            <tr
              className={`fs-7 fw-600 clr-2B2B2B text-center ${
                fculativeDetail ? "last-td-right-border" : ""
              }`}
            >
              {fMarkPlaceHolders.map((m, i) => (
                <td key={i} style={{ width: `${m.width}px` }}>
                  {m.name}
                </td>
              ))}
            </tr>

            {fMarks.map((fm, fi) => (
              <tr key={fm.subject}>
                <td className="fs-9 fw-500 clr-2B2B2B">
                  <div className="first-col">{fm.subject}</div>
                </td>
                {fm.list.map(m => (
                  <Fragment key={m.id}>
                    {displayScholasticScore && (
                      <td>
                        <Mark val={m.mark} />
                      </td>
                    )}
                    {displayScholasticGrade && (
                      <td>
                        <Mark val={m.grade} />
                      </td>
                    )}
                    {displayScholasticSubjectRemarks && (
                      <td>
                        <Mark val={remarks[fi]} />
                      </td>
                    )}
                  </Fragment>
                ))}
              </tr>
            ))}

            {/* <tr>
              <td>
                <div className="first-col fs-9 fw-900 clr-4F4F4F">Total</div>
              </td>

              {fTotalRow.map((f, i) => (
                <td key={i} colSpan={f.span} className="text-center py-2">
                  <div className="d-flex flex-column align-items-center justify-content-center flex-wrap">
                    <span className="fs-9 fw-900 clr-4F4F4F">
                      {f.total}&nbsp;
                    </span>
                    <span className="fs-6 fw-500 clr-9B9A9A">Out of 500</span>
                  </div>
                </td>
              ))}
            </tr> */}
          </tbody>
        </table>
      </div>

      <table
        className={`rc-score-review-table border-9393932e w-100 mt-4 text-center ${
          fOverAll.length > 4 ? "" : "table-fixed"
        }`}
        cellSpacing={0}
      >
        <tbody>
          <tr className="fs-9 fw-900 clr-4F4F4F">
            <td>
              <div className="first-col ml-3 text-left">Overall</div>
            </td>
            {fOverAll.map(o => (
              <td key={o.title}>{o.title}</td>
            ))}
          </tr>
          <tr className="fs-10 fw-500 clr-2B2B2B">
            <td>
              <div className="first-col ml-1 text-left">Percentage</div>
            </td>
            {fOverAll.map(o => (
              <td key={o.title}>{o.mark}</td>
            ))}
          </tr>
          <tr className="fs-10 fw-500 clr-2B2B2B">
            <td>
              <div className="first-col ml-3 text-left">Grade</div>
            </td>
            {fOverAll.map(o => (
              <td key={o.title}>{o.grade}</td>
            ))}
          </tr>
          {displayStudentRank && (
            <tr className="fs-10 fw-500 clr-2B2B2B">
              <td>
                <div className="first-col ml-3 text-left">Rank</div>
              </td>
              {fOverAll.map(o => (
                <td key={o.title}>{o.grade}</td>
              ))}
            </tr>
          )}
        </tbody>
      </table>

      {displayOverallRemarks && (
        <div className="d-flex align-items-center mt-5 mb-2">
          <div className="flex-1">
            <h4 className="mb-1 fs-9 fw-700 clr-435092">Remarks</h4>
            <hr className="mt-0 mb-2 bg-B6C9E8" />

            <div className="fs-7 fw-600 clr-838384">
              Peter jose should concentrate on English Subject
            </div>
          </div>

          {/* <div>
          <h4 className="mb-1 ml-1 fs-9 fw-700 clr-435092">Results</h4>

          <div className="d-flex align-items-center fs-15 fw-700 clr-2B2B2B">
            <img
              className="template-wrapper-result-img"
              width={20}
              height={20}
              src={res.resultPassStatus ? tickImg : wrongImg}
              alt=""
            />
            <p className="ml-1 mb-0">
              {res.resultPassStatus ? "PASS" : "FAIL"}
            </p>
          </div>
        </div> */}
        </div>
      )}

      {displayScholasticGrade && (
        <div className="p-2 mb-5 mt-4 fs-7 clr-838384 border-9393932e rounded text-center">
          Grading Scale:
          {gradeDetail
            .map(
              gr =>
                `${gr.gradeName} (${gr.gradeScoreFrom}%-${gr.gradeScoreTo}%)`
            )
            .join("")}
        </div>
      )}

      <div className="d-flex align-items-center justify-content-between mt-5 fs-10 fw-700 clr-606161">
        {displayParentsSignature && <div>Parent Signature</div>}
        {displayClassTeacherSignature && <div>Class teacher Signature</div>}
        {displayPrincipalSignature && <div>Principal Signature</div>}
      </div>
    </div>
  )
}

export default Template
