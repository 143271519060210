import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { HashLoader } from "react-spinners"

import { getSchoolDetailsAdmin } from "../../../../actions/admin/DashboardAction"
import { getClassDetails } from "../../../../actions/admin/QuestionGeneration"

import { ReactComponent as Arrow } from "../../../../assets/images/arrow.svg"
import GenerateQuestionPaper from "../../GenerateQuestionPaper"
import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"
import Step4 from "./Step4"

const list = [
  {
    num: 1,
    title: "Classes & Subject",
  },
  {
    num: 2,
    title: "Chapter & topic",
  },
  {
    num: 3,
    title: "Question type & counts",
  },
]

function ManualModal({ toHome }) {
  const [showAIModal, setShowAIModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [step, setStep] = useState(1)
  const dispatch = useDispatch()

  const [data, setData] = useState([]) // final question data
  const [chapters, setChapters] = useState([])
  const [classList, setClassList] = useState([])
  const [schoolDetails, setSchoolDetails] = useState({})
  const [selectedSubject, setSelectedSubject] = useState([])
  const [selectedChapter, setSelectedChapter] = useState({})
  const [selectedClass, setSelectedClass] = useState("")
  const [selectedBoard, setSelectedBoard] = useState("")
  const [selectedQuest, setSelectedQuest] = useState([
    {
      id: 0,
      quest: "MCQ",
      count: 10,
    },
  ])

  useEffect(() => {
    document.body.classList.remove("bg-gray-for-report")
    dispatch(
      getSchoolDetailsAdmin((success, res) => {
        if (success) {
          setSchoolDetails(res)
          let param = {
            selectedBoard: res.affiliation,
            includeAcademicClassesOnly: true,
          }
          getClassDetails(param, (suc, res1) => {
            if (suc) {
              setSelectedBoard(res.affiliation)
              setIsLoading(false)
              setClassList(
                res1.map(e => ({
                  ...e,
                  subjects: e.subjects.map(s => s.masterSubjectDetails),
                }))
              )
            }
          })
        }
      })
    )
  }, [dispatch])

  if (isLoading) {
    return (
      <div className="manual-quest-wrapper d-flex align-items-center justify-content-center p-5 br-8px">
        <HashLoader size={30} color="#084353" sizeUnit="px" loading />
      </div>
    )
  }

  return (
    <>
      {step === 4 && (
        <button
          className="back-btn-report-card d-flex align-items-center justify-content-center bg-white"
          onClick={() => setStep(3)}
          style={{ border: "1px solid #E5E5D7" }}
        >
          <Arrow
            width={16}
            height={16}
            style={{ transform: "rotateZ(90deg)" }}
          />
          Back
        </button>
      )}

      <div className="quest-paper-geration-wrapper">
        <div className="px-5 mt-4">
          {step !== 4 && (
            <div className="px-5 mb-5">
              <div className="d-flex align-items-center justify-content-between">
                {list.map(l => (
                  <p
                    key={l.num}
                    className={`d-flex align-items-center gap-1rem mb-0 fw-600 ${
                      l.num === step ? "clr-767676" : "clr-A6A7A8"
                    } position-relative`}
                  >
                    <span
                      className={`manual-top-header-circle position-absolute ${
                        l.num === step ? "active" : ""
                      }`}
                    ></span>
                    {l.title}
                  </p>
                ))}
              </div>

              <div className="dashed-boder-in-gp"></div>
            </div>
          )}

          {step === 1 && (
            <Step1
              setStep={setStep}
              classList={classList}
              selectedClass={selectedClass}
              selectedSubject={selectedSubject}
              setSelectedClass={setSelectedClass}
              setSelectedSubject={setSelectedSubject}
            />
          )}

          {step === 2 && (
            <Step2
              setStep={setStep}
              chapters={chapters}
              selectedBoard={selectedBoard}
              selectedClass={selectedClass}
              selectedSubject={selectedSubject}
              selectedChapter={selectedChapter}
              setSelectedChapter={setSelectedChapter}
              setChapters={setChapters}
            />
          )}

          {step === 3 && (
            <Step3
              setStep={setStep}
              setData={setData}
              setSelectedQuest={setSelectedQuest}
              selectedBoard={selectedBoard}
              selectedClass={selectedClass}
              selectedQuest={selectedQuest}
              selectedSubject={selectedSubject}
              selectedChapter={selectedChapter}
              aiBtn={
                <button
                  className="px-5 py-2 fs-16 fw-500 bg-50AE93 text-white br-8px"
                  onClick={() => setShowAIModal(true)}
                >
                  Generate using AI
                </button>
              }
            />
          )}

          {step === 4 && (
            <Step4
              data={data}
              chapters={chapters}
              classList={classList}
              selectedBoard={selectedBoard}
              selectedClass={selectedClass}
              selectedQuest={selectedQuest}
              selectedSubject={selectedSubject}
              selectedChapter={selectedChapter}
              schoolDetails={schoolDetails}
              setData={setData}
              toHome={toHome}
            />
          )}
        </div>
      </div>

      {showAIModal && <GenerateQuestionPaper />}
    </>
  )
}

export default ManualModal
