/*************************************************
 * RedInk
 * @exports
 * @class AttendanceReportAction.js
 * @extends Component
 * @author vijayakumar // on 05/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { ACTION_TYPES } from "../types"
import { DANGER_TOAST, VALIDATION_MSG } from "../../utils/Constants"
import { showNotification } from "../CommonAction"

export const saveTermListInStore = termList => {
  return {
    type: ACTION_TYPES.TERM_LIST,
    payload: termList,
  }
}

export const saveStudentAttendanceReportListInStore = studentAttendanceReport => {
  return {
    type: ACTION_TYPES.STUDENT_ATTENDENCE_LIST_REPORT,
    payload: studentAttendanceReport,
  }
}

export const getTermList = (params, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_TEACHERS_TERM_LIST,
        params,
        true
      )
      if (response) {
        myLog("====getTermList RESPONSE===::::", response)
        dispatch(saveTermListInStore(response.terms))
        callBack(true, response.terms)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getTermList ERROR===????", error)
    }
  }
}

export const getStudentAttendanceReportList = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_TEACHERS_STUDENT_ATTENDANCE_REPORT_LIST,
        body,
        true,
        true
      )
      if (response && response.responseCode === 0) {
        myLog("====getStudentAttendanceReportList RESPONSE===::::", response)
        callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getStudentAttendanceReportList ERROR===????", error)
    }
  }
}

export const getStudentAttendanceReportGraph = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_TEACHERS_STUDENT_ATTENDANCE_REPORT_GRAPH,
        body,
        true,
        true
      )
      if (response && response.responseCode === 0) {
        myLog("====getStudentAttendanceReportGraph RESPONSE===::::", response)
        callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getStudentAttendanceReportGraph ERROR===????", error)
    }
  }
}

export const getStudentAttendanceReportHolidayList = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_TEACHERS_STUDENT_ATTENDANCE_REPORT_HOLIDAY_LIST,
        body,
        true,
        true
      )
      if (response && response.responseCode === 0) {
        myLog(
          "====getStudentAttendanceReportHolidayList RESPONSE===::::",
          response
        )
        callBack(true, response.data.dates)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getStudentAttendanceReportHolidayList ERROR===????", error)
    }
  }
}

export const getAcademicMonthList = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_ACADEMIC_MONTH_LIST,
        null,
        true,
        true
      )
      if (response && response.responseCode === 0) {
        myLog("====getAcademicMonthList RESPONSE===::::", response)
        callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getAcademicMonthList ERROR===????", error)
    }
  }
}

export const getStudentFrequentAbsentList = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_TEACHERS_STUDENT_FREQUENT_ABSENT_LIST,
        null,
        true
      )
      if (response) {
        myLog("====getStudentFrequentAbsentList RESPONSE===::::", response)
        //   dispatch(saveClassDetailsInStore(response.classList));
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getStudentFrequentAbsentList ERROR===????", error)
    }
  }
}
