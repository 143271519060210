/*************************************************
 * RedInk
 * @exports
 * @class index.js
 * @extends Component
 * @author Sasidharan // on 01/02/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import { HashLoader } from "react-spinners"
import { URL_PATH } from "../../../config/urlPath"
import { connect } from "react-redux"
import { Modal } from "reactstrap"
import Skeleton from "react-loading-skeleton"
import moment from "moment"

import {
  getQuizList,
  updateViewedAssessment,
} from "../../../actions/parent/QuizAction"

import AppWrapper from "../Wrapper"

const COMPLETED = "COMPLETED"
const MISSED = "MISSED"
const YET_TO_GRADE = "YET_TO_GRADE"
const UPCOMING = "UPCOMING"
const LIVE = "LIVE"

const approvalStatusLabel = {
  COMPLETED: "Completed",
  MISSED: "Missed",
  YET_TO_GRADE: "Yet to Grade",
  UPCOMING: "Upcoming",
  LIVE: "Live",
}

const Assessment = ({
  subject,
  id,
  testTitle,
  type,
  date_time,
  score,
  status,
  onClick,
}) => {
  let statusColor = "#fff"
  if (status === COMPLETED) {
    statusColor = "rgba(13,177,145,0.43)"
  } else if (status === YET_TO_GRADE) {
    statusColor = "rgb(224, 173, 83)"
  } else if (status === UPCOMING) {
    statusColor = "rgba(9,67,83,0.35)"
  } else if (status === MISSED) {
    statusColor = "rgba(236,56,93,0.47)"
  } else if (status === LIVE) {
    statusColor = "rgba(236,56,93,1)"
  } else {
    statusColor = "#fff"
  }

  return (
    <div style={styles.assessment} onClick={onClick}>
      <div style={{ color: "#111", display: "flex", flex: 1 }}>{subject}</div>
      <div
        style={{
          color: "#111",
          display: "flex",
          flex: 1,
          lineBreak: "anywhere",
          whiteSpace: "pre-wrap",
          marginRight: 8,
        }}
      >
        {testTitle}
      </div>
      <div
        style={{
          color: "#111",
          display: "flex",
          flex: 1,
          justifyContent: "center",
        }}
      >
        {type}
      </div>
      <div
        style={{
          color: "#111",
          display: "flex",
          flex: 2,
          justifyContent: "center",
        }}
      >
        {date_time}
      </div>
      <div
        style={{
          color: "#111",
          display: "flex",
          flex: 1,
          justifyContent: "flex-end",
        }}
      >
        {score}
      </div>
      <div
        style={{
          color: "#111",
          display: "flex",
          flex: 1,
          justifyContent: "flex-end",
        }}
      >
        <p
          className="m-0 rounded px-2 py-05"
          style={{
            backgroundColor: statusColor,
            textAlign: "center",
            fontSize: "11px",
            width: "7rem",
            color:
              status === "LIVE"
                ? "#fff"
                : status === "MISSED"
                ? "white"
                : status === "COMPLETED"
                ? "white"
                : status === "UPCOMING"
                ? "#0b4353"
                : status === "YET_TO_GRADE"
                ? "white"
                : "fff",
          }}
        >
          {status.replaceAll("_", " ")}
        </p>
      </div>
    </div>
  )
}

const Circle = ({ number, color, label, loading }) => {
  return (
    <div style={styles.circle_container}>
      {/* <div style={styles.circleShadow}></div> */}
      {loading ? (
        <Skeleton circle={true} height={60} width={60} />
      ) : (
        <div style={styles.circle}>
          <p style={{ fontSize: "19px", fontWeight: "bold", margin: 0 }}>
            {number}
          </p>
        </div>
      )}
      <p style={styles.circle_labels}>
        {loading ? <Skeleton width={60} /> : label}
      </p>
    </div>
  )
}

class StudentAssessmentScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: null,
      quizList: [],
      page: 1,
      prevPage: null,
      nextPage: null,
      search: "",
      liveCnt: null,
      upcomingCnt: null,
      missedCnt: null,
      completedCnt: null,
      yetToGradeCnt: null,
      isAssesmentModalOpen: false,
      selectedAssesment: null,
      types: [],
      statuses: [],
      subjects: [],
      selectedType: "",
      selectedSubject: "",
      selectedStatus: "",
    }
  }

  intervalID

  componentDidMount = () => {
    this.getData()
    this.intervalID = setInterval(this.getData.bind(this), 15000)
  }

  componentWillUnmount = () => {
    clearTimeout(this.intervalID)
  }

  getData = () => {
    const { page } = this.state
    const query = {
      page,
      limit: 50,
    }
    let statuses = []
    let subjects = []
    let types = []
    this.setState({ isLoading: true })
    this.props.getQuizList(query, (success, data) => {
      if (success) {
        const {
          data: quizzes,
          pagination: { nextPage },
        } = data
        // myLog("Quizes ", quizzes)
        for (const quiz of quizzes) {
          const {
            // quizSubmissions,
            approvalStatus,
            submissionType,
            teacherAssignedDetail: {
              subject: { subjectName },
            },
          } = quiz
          // const { startTime, endTime, graded, submitted } = quizSubmissions[0] // only one
          const isOfType = submissionType === "ONLINE" ? "ONLINE" : "IN_SCHOOL"

          !subjects.includes(subjectName) && subjects.push(subjectName)
          !statuses.includes(approvalStatus) && statuses.push(approvalStatus)
          !types.includes(isOfType) && types.push(isOfType)
        }
        this.setState(
          {
            quizList: quizzes,
            nextPage,
            prevPage: page > 1 ? page - 1 : null,
            types,
            statuses,
            subjects,
          },
          () => {
            const { state } = this.props.location
            if (state && state.assessment) {
              const quiz = this.state.quizList.filter(
                item => item.id === state.assessment.id
              )
              this.setState({
                isAssesmentModalOpen: true,
                selectedAssesment: quiz[0],
              })
              // this.props.updateViewedAssessment({ id: state.assessment.id }, (success, response) => {
              //   this.setState({
              //     isAssesmentModalOpen: true,
              //     selectedAssesment: response.data,
              //   })
              // })
            }
            // myLog("Statuses", statuses)
          }
        )
      }
      this.setState({ isLoading: false })
    })
  }

  renderAssesmentModal = () => {
    const {
      id,
      quizSet,
      timeLimit,
      submissionType,
      quizSubmissions,
      approvalStatus,
      locked,
      immediateResult,
      published,
      teacherAssignedDetail: {
        subject: { subjectName },
      },
    } = this.state.selectedAssesment || {
      quizSet: {},
      teacherAssignedDetail: { subject: {} },
    }
    const { startTime, endTime } = quizSubmissions
      ? quizSubmissions[0]
      : { startTime: moment(), endTime: moment() }
    const start = moment(startTime).format("DD MMM  HH:mm a")
    const end = moment(endTime).format("DD MMM  HH:mm a")
    const isOnline = submissionType === "ONLINE"
    const type = isOnline ? "Online" : "In Class"

    const upcoming = approvalStatus === UPCOMING
    const live = approvalStatus === LIVE
    const yetToGrade = approvalStatus === YET_TO_GRADE
    const missed = approvalStatus === MISSED
    const completed = approvalStatus === COMPLETED
    // const submitted = isOnline && quizSubmissions[0].submitted

    let statusColor = ""
    let statusTextColor = "white"
    if (completed) {
      statusColor = "rgb(6, 177, 145)"
    } else if (yetToGrade) {
      statusColor = "rgb(224, 173, 83)"
    } else if (upcoming) {
      statusColor = "#94DDD0"
    } else if (missed) {
      statusColor = "#F6A0B2"
    } else if (live) {
      statusColor = "rgba(236,56,93,1)"
      statusTextColor = "rgba(255,255,255,0.75)"
    }

    let questionsCount = 0
    if (isOnline && quizSet && quizSet.quizSections) {
      for (const section of quizSet.quizSections) {
        questionsCount += (section.quizQuestions || []).length
      }
    }
    // var instructions = quizSet.instruction
    // var instructionSplit = instructions.split('.')
    // myLog(instructions+''.length)

    return (
      <Modal
        isOpen={this.state.isAssesmentModalOpen}
        modalClassName="right slide-in-right fade"
      >
        <div className="modal-header d-flex align-items-center">
          <h5 className="modal-title text-brand">{subjectName}</h5>
          {/* <i
            className={`ml-3 fas fa-circle ${ isOnline ? "text-green" :<div style={{color:'rgb(241, 109, 111)'}}> </div>}`
          }
            style={{ fontSize: 8 }}
          /> */}
          <div className="px-3 d-flex align-items-center">
            <div
              className="rounded-pill"
              style={{
                width: 10,
                height: 10,
                backgroundColor: isOnline ? "#15af8f" : "#f16d6f",
              }}
            />
            <div className="ml-1" style={{ fontSize: "smaller" }}>
              {type}
            </div>
          </div>
          {/* <span className="ml-2" style={{ fontSize: "0.9rem" }}>
            {type}
          </span> */}
          {/* <div className="rounded-pill" style={{color:'rgb(241, 109, 111)'}}>
             </div> */}

          <button
            type="button"
            className="close ml-auto"
            aria-label="Close"
            onClick={() => this.setState({ isAssesmentModalOpen: false })}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <div className="d-flex align-items-center">
            <h5 className="text-brand mb-0">{quizSet.title}</h5>
            <div className="ml-auto text-muted">
              <span className="mr-2">Status</span>
              <span
                className="px-3 py-1 rounded"
                style={{
                  backgroundColor: statusColor,
                  color: statusTextColor,
                  textTransform: "capitalize",
                }}
              >
                {approvalStatusLabel[approvalStatus]}
              </span>
            </div>
          </div>

          <div className="d-flex align-items-center text-muted my-2">
            {isOnline && <div className="mr-2">{questionsCount} Questions</div>}
            <div className="mr-2">{quizSet.totalMark} Marks</div>
            {timeLimit && <div className="mr-2">{timeLimit}</div>}
          </div>

          <div className="text-brand">
            {start} - {end}
          </div>

          <div className="d-flex align-items-center text-muted mt-3">
            <i className="fas fa-align-left"></i>
            <span className="ml-2">Description</span>
          </div>

          {quizSet.instruction !== "" ? (
            <div className="mx-o my-2" style={{ minHeight: "160px" }}>
              {String(quizSet.instruction)
                .split("\n")
                .map((list, i) => (
                  <li style={{ listStyle: "none" }} key={i}>
                    {list.replace("\n", "")}
                  </li>
                ))}
            </div>
          ) : (
            ""
          )}

          <div className="d-flex align-items-center justify-content-around">
            <span></span>
            {!!(upcoming || live) && (
              <button
                className={`btn ${
                  isOnline && live ? "bg-green" : "disabled bg-secondary"
                } text-light`}
                style={{ borderRadius: 8 }}
                disabled={!live || locked}
                onClick={() =>
                  this.props.history.push({
                    pathname: URL_PATH.STUDENT_TAKE_TESTS,
                    state: { assesment: this.state.selectedAssesment },
                  })
                }
              >
                {!!isOnline && live && "Start Test"}
                {!!isOnline && !live && "Upcoming"}
                {!isOnline && !live && "Upcoming"}
                {/*!isOnline && live && "Unsubmitted"*/}
              </button>
            )}
            {!!(yetToGrade || missed || completed || immediateResult) && (
              <>
                {(yetToGrade && immediateResult === 0) ||
                live ||
                missed ||
                upcoming ? (
                  <></>
                ) : (
                  <button
                    className="btn bg-green text-light"
                    style={{ borderRadius: 8 }}
                    onClick={() =>
                      this.props.history.push({
                        pathname: URL_PATH.STUDENT_TESTS_REPORT,
                        state: { quizAssignInfoId: id, isOnline },
                      })
                    }
                  >
                    View Analysis
                  </button>
                )}
                {!!(
                  isOnline &&
                  (yetToGrade ||
                    completed ||
                    (immediateResult === 1 &&
                      !live &&
                      !upcoming &&
                      this.state.selectedStatus !== "MISSED"))
                ) ? (
                  <button
                    className="btn btn-light border-green text-green"
                    style={{ borderRadius: 8 }}
                    disabled={locked || (!immediateResult && !published)}
                    onClick={() =>
                      this.props.history.push({
                        pathname: URL_PATH.STUDENT_TESTS_RESULT,
                        state: { quizAssignInfoId: id },
                      })
                    }
                  >
                    Review Test
                  </button>
                ) : (
                  <></>
                )}
              </>
            )}
            <span></span>
          </div>
        </div>
      </Modal>
    )
  }

  render() {
    const { quizList, isLoading } = this.state
    const filteredQuizList = quizList.filter(quiz => {
      let include = true
      if (this.state.selectedType) {
        include = include && quiz.submissionType === this.state.selectedType
      }
      if (this.state.selectedStatus) {
        include = include && quiz.approvalStatus === this.state.selectedStatus
      }
      // myLog("selected status", this.state.selectedStatus)
      // myLog(
      //   "logging filter",
      //   quizList.filter(
      //     quiz => quiz.approvalStatus === this.state.selectedStatus
      //   )
      // )
      if (this.state.selectedSubject) {
        include =
          include &&
          quiz.teacherAssignedDetail.subject.subjectName ===
            this.state.selectedSubject
      }
      if (this.state.search) {
        include =
          include &&
          quiz.quizSet.title
            .toLowerCase()
            .includes(this.state.search.toLowerCase())
      }
      return include
    })

    // myLog("filtered quiz list", filteredQuizList)
    filteredQuizList.sort((a, b) => a.startTime < b.startTime)

    let upcomingCnt = 0,
      liveCnt = 0,
      yetToGradeCnt = 0,
      missedCnt = 0,
      completedCnt = 0

    for (let quiz of filteredQuizList) {
      const { approvalStatus, published } = quiz

      const { submitted } = quiz.quizSubmissions[0]
      const isPublishLater = quiz.publishLater
        ? moment(quiz.publishLater) > moment()
        : false
      if (
        approvalStatus === COMPLETED &&
        !isPublishLater &&
        published === 1 &&
        submitted
      )
        completedCnt++
      if (approvalStatus === MISSED) missedCnt++
      if (
        approvalStatus === YET_TO_GRADE ||
        isPublishLater ||
        (published === 0 && submitted)
      )
        yetToGradeCnt++
      if (approvalStatus === UPCOMING) upcomingCnt++
      if (approvalStatus === LIVE) liveCnt++
    }

    return (
      <>
        {this.renderAssesmentModal()}
        <AppWrapper
          teacherStudentRecordSidemenu
          showTeacherThree
          showTeacherLogo
          screenName="TESTS"
        >
          <div className="main_div p-2">
            <div className="header_div d-flex">
              <div style={styles.circle_div}>
                <Circle
                  number={upcomingCnt}
                  label={approvalStatusLabel.UPCOMING}
                  loading={isLoading}
                />
                <Circle
                  number={yetToGradeCnt}
                  label={approvalStatusLabel.YET_TO_GRADE}
                  loading={isLoading}
                />
                <Circle
                  number={missedCnt}
                  label={approvalStatusLabel.MISSED}
                  loading={isLoading}
                />
                <Circle
                  number={completedCnt}
                  label={approvalStatusLabel.COMPLETED}
                  loading={isLoading}
                />
              </div>
              <div
                style={{ flex: 2, display: "flex", justifyContent: "flex-end" }}
              >
                <div style={{ width: "200px" }}>
                  <input
                    placeholder="Search tests..."
                    value={this.state.search}
                    onChange={e => this.setState({ search: e.target.value })}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            {this.state.isLoading ? (
              <div style={{ position: "absolute", left: "50%", top: "50%" }}>
                <HashLoader
                  sizeUnit={"px"}
                  size={35}
                  color={"#084353"}
                  loading={this.state.isShowLoading}
                />
              </div>
            ) : (
              <>
                <div className="card px-4">
                  <div
                    style={styles.options_div}
                    className="row align-items-center justify-content-end mt-3"
                  >
                    <div className="col-4 d-flex">
                      <select
                        className="custom-select bg-light border-0 py-1 px-2"
                        value={this.state.selectedSubject}
                        onChange={e =>
                          this.setState({ selectedSubject: e.target.value })
                        }
                      >
                        <option value="">Subject</option>
                        {this.state.subjects.map(subj => (
                          <option value={subj} key={subj}>
                            {subj.replace("_", " ")}
                          </option>
                        ))}
                      </select>
                      <select
                        className="custom-select bg-light border-0 py-1 px-2 ml-4"
                        value={this.state.selectedType}
                        onChange={e => {
                          this.setState({ selectedType: e.target.value })
                        }}
                      >
                        <option value="">Type</option>
                        {this.state.types.map(type => (
                          <option value={type} key={type}>
                            {type.replace("_", " ").replace("SCHOOL", "CLASS")}
                          </option>
                        ))}
                      </select>
                      <select
                        className="custom-select bg-light border-0 py-1 ml-4"
                        value={this.state.selectedStatus}
                        onChange={e =>
                          this.setState({ selectedStatus: e.target.value })
                        }
                      >
                        <option value="">Status</option>
                        {this.state.statuses.map(stat => (
                          <option value={stat} key={stat}>
                            {stat.replaceAll("_", " ")}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {/* <table className="table table-hover mt-3"> */}
                  {/* <thead className=" bg-brand text-light">
                  <tr style={{ height: "4rem" }}>
                    <th scope="col" style={{ verticalAlign: "middle" }}>
                      Subject
                    </th>
                    <th
                      scope="col"
                      className="pl-5"
                      style={{ verticalAlign: "middle" }}
                    >
                      Test Title
                    </th>
                    <th
                      scope="col"
                      className="text-center"
                      style={{ verticalAlign: "middle" }}
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="text-center"
                      style={{ verticalAlign: "middle" }}
                    >
                      Date & Time
                    </th>
                    <th
                      scope="col"
                      className="text-center"
                      style={{ verticalAlign: "middle" }}
                    >
                      Score
                    </th>
                    <th
                      scope="col"
                      className="text-center"
                      style={{ verticalAlign: "middle" }}
                    >
                      Status
                    </th>
                  </tr>
                </thead> */}

                  <div style={styles.body_header_div} className="mt-3">
                    <div style={{ color: "#fff", display: "flex", flex: 1 }}>
                      Subject
                    </div>
                    <div style={{ color: "#fff", display: "flex", flex: 1 }}>
                      Test Title
                    </div>
                    <div
                      style={{
                        color: "#fff",
                        display: "flex",
                        flex: 1,
                        justifyContent: "center",
                      }}
                    >
                      Type
                    </div>
                    <div
                      style={{
                        color: "#fff",
                        display: "flex",
                        flex: 2,
                        justifyContent: "center",
                      }}
                    >
                      Date & Time
                    </div>
                    <div
                      style={{
                        color: "#fff",
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-end",
                      }}
                    >
                      Score
                    </div>
                    <div
                      style={{
                        color: "#fff",
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-end",
                      }}
                    >
                      Status
                    </div>
                  </div>
                  {/* style={styles.body_div} */}

                  {!this.state.isLoading &&
                    filteredQuizList.map((quiz, idx) => {
                      let {
                        id,
                        submissionType,
                        quizSet,
                        quizSubmissions,
                        teacherAssignedDetail,
                        approvalStatus,
                        published,
                        assignLater,
                        publishLater,
                      } = quiz

                      const { title, totalMark } = quizSet || {}
                      const {
                        startTime,
                        endTime,
                        gradedMark,
                        submitted,
                      } = quizSubmissions[0] // only one
                      const { subject } = teacherAssignedDetail
                      // const { className, sectionName } = section
                      const { subjectName } = subject

                      const isOnline = submissionType === "ONLINE"
                      const start = moment(startTime).format("DD MMM hh:mm a")
                      const end = moment(endTime).format("DD MMM hh:mm a")
                      const isAssignLater = assignLater
                        ? moment(assignLater).format("DD MMM YYYY HH:mm") >=
                          moment().format("DD MMM YYYY HH:mm")
                        : false
                      const isPublishLater = publishLater
                        ? moment(publishLater) > moment()
                        : false

                      if (isPublishLater || (!published && submitted)) {
                        approvalStatus = "YET_TO_GRADE"
                        quiz.approvalStatus = "YET_TO_GRADE"
                      }
                      if (isAssignLater) {
                        return null
                      }
                      const dateTime = isOnline
                        ? `${start} - ${end}`
                        : `${start} - ${end}`
                      const type = isOnline ? "Online" : "In Class"

                      const score = published
                        ? `${gradedMark || "-"} / ${totalMark || "-"}`
                        : `- / ${totalMark || "-"}`

                      // let statusColor = "#fff"
                      // if (approvalStatus === COMPLETED) {
                      //   statusColor = "rgba(13,177,145,0.43)"
                      // } else if (approvalStatus === YET_TO_GRADE) {
                      //   statusColor = "rgb(224, 173, 83)"
                      // } else if (approvalStatus === UPCOMING) {
                      //   statusColor = "rgba(9,67,83,0.35)"
                      // } else if (approvalStatus === MISSED) {
                      //   statusColor = "rgba(236,56,93,0.47)"
                      // } else if (approvalStatus === LIVE) {
                      //   statusColor = "rgba(236,56,93,1)"
                      // } else {
                      //   statusColor = "#fff"
                      // }

                      return (
                        <Assessment
                          key={id}
                          id={id}
                          subject={subjectName}
                          testTitle={title}
                          date_time={dateTime}
                          type={type}
                          score={score}
                          status={approvalStatus}
                          onClick={() => {
                            this.setState({
                              isAssesmentModalOpen: true,
                              selectedAssesment: quiz,
                            })
                            this.props.updateViewedAssessment(
                              { id: quiz.id },
                              () => {}
                            )
                          }}
                        />
                        // <tbody>
                        //   <tr
                        //     style={{ cursor: "pointer", height: "5rem" }}
                        //     onClick={() => {
                        //       this.setState({
                        //         isAssesmentModalOpen: true,
                        //         selectedAssesment: quiz,
                        //       })
                        //       this.props.updateViewedAssessment(
                        //         { id: quiz.id },
                        //         () => {}
                        //       )
                        //     }}
                        //   >
                        //     <td
                        //       className="font-weight-bold"
                        //       style={{ width: "14rem", verticalAlign: "middle" }}
                        //     >
                        //       {subjectName}
                        //     </td>
                        //     <td
                        //       className="font-weight-bolder"
                        //       style={{ verticalAlign: "middle" }}
                        //     >
                        //       {title}
                        //     </td>
                        //     <td
                        //       className="text-center"
                        //       style={{ verticalAlign: "middle" }}
                        //     >
                        //       {type}
                        //     </td>
                        //     <td
                        //       className="text-center"
                        //       style={{ verticalAlign: "middle" }}
                        //     >
                        //       {dateTime}
                        //     </td>
                        //     <td
                        //       className="text-center font-weight-bold"
                        //       style={{ verticalAlign: "middle" }}
                        //     >
                        //       {score}
                        //     </td>
                        //     <td
                        //       className="text-center"
                        //       style={{ verticalAlign: "middle" }}
                        //     >
                        //       <p
                        //         className="m-0 rounded  mx-auto"
                        //         style={{
                        //           width: "7rem",
                        //           backgroundColor: statusColor,
                        //           fontSize: "11px",
                        //           color:
                        //             approvalStatus === "LIVE"
                        //               ? "#fff"
                        //               : approvalStatus === "MISSED"
                        //               ? "#8a2757"
                        //               : approvalStatus === "COMPLETED"
                        //               ? "#097862"
                        //               : approvalStatus === "UPCOMING"
                        //               ? "white"
                        //               : approvalStatus === "YET_TO_GRADE"
                        //               ? "#735324"
                        //               : "fff",
                        //         }}
                        //       >
                        //         {approvalStatus.replaceAll("_", " ")}{" "}
                        //       </p>
                        //     </td>
                        //   </tr>
                        // </tbody>
                      )
                    })}
                  {/* </table> */}
                </div>
              </>
            )}
          </div>
        </AppWrapper>
      </>
    )
  }
}
const styles = {
  assessment: {
    backgroundColor: "rgb(215, 233, 232,0.3)",
    padding: "15px 30px",
    borderRadius: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: "#111",
    marginBottom: "10px",
    cursor: "pointer",
  },
  body_div: {
    width: "100%",
  },
  body_header_div: {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "20px",
    marginBottom: "10px",
    backgroundColor: "rgb(9,67,83)",
    padding: "15px 30px",
  },
  circle_div: {
    display: "flex",
    flex: 1,
  },
  circle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    height: "60px",
    width: "60px",
    borderRadius: "30px",
    backgroundColor: "rgb(9,67,83)",
  },

  circle_container: {
    display: "flex",
    flex: 1,
    height: "100%",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  circle_labels: {
    fontSize: "13px",
    fontWeight: "bold",
    textAlign: "center",
    marginTop: "10px",
    color: "rgb(9,67,83)",
  },
  options_div: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 0,
    flex: 1,
  },
  inclass_bg: {
    color: "rgb(241, 109, 111)",
  },
}

const mapStateToProps = state => {
  return {
    quizAssignList: state.quizAssignList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getQuizList,
      updateViewedAssessment,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  dispatchToProps
)(withRouter(StudentAssessmentScreen))
