/*************************************************
 * RedInk
 * @exports
 * @class GradeMasterAction.js
 * @extends Component
 * @author Mahesh S // on 02/09/2021
 * @copyright © 2021 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
// import { ACTION_TYPES } from "../types"
import { showNotification } from "../CommonAction"
import {
  SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"

export const getGradeMasterDetails = (id, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.ADMIN_GRADE_MASTER + "/" + id,
        {},
        true,
        true
      )
      if (response) {
        myLog("====getGradeMasterDetails RESPONSE===::::", response)
        callBack(true, response.data)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getGradeMasterDetails ERROR===????", error)
    }
  }
}

export const getGradeMasterList = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.ADMIN_GRADE_MASTER,
        object,
        true,
        true
      )
      if (response) {
        myLog("====getGradeMasterList RESPONSE===::::", response)
        callBack(true, response.data)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getGradeMasterList ERROR===????", error)
    }
  }
}

export const saveGradeMaster = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      myLog("====saveGradeMaster body===::::", body)
      let response
      if (body.isUpdate) {
        response = await Client.put(URL.ADMIN_GRADE_MASTER, body, true, true)
      } else {
        response = await Client.post(URL.ADMIN_GRADE_MASTER, body, true, true)
      }
      if (response.responseCode === 0) {
        myLog("====saveGradeMaster RESPONSE===::::", response)
        // dispatch(getGradeMasterList({}, () => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveGradeMaster ERROR===????", error)
    }
  }
}

export const updateGradeClasses = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      myLog("====updateGradeClasses body===::::", body)
      let response
      response = await Client.put(
        URL.ADMIN_GRADE_MASTER + "/manage-default",
        body,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====updateGradeClasses RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveGradeMaster ERROR===????", error)
    }
  }
}

export const deleteGradeMaster = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.ADMIN_GRADE_MASTER + "/" + body.id,
        {},
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteGradeMaster RESPONSE===::::", response)
        // dispatch(getGradeMasterList({}, () => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteGradeMaster ERROR===????", error)
    }
  }
}
