/*************************************************
 * RedInk
 * @exports
 * @class Dashboard.js
 * @extends Component
 * @author Sasidharan // on 11/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"

import MessageModal from "../MessageModal"
import AppWrapper from "../Wrapper"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  getStudentsDetails,
  saveCommunicationStarredDetails,
  saveCommunicationDetailsInStore,
  saveCommunicationDetails,
} from "../../../actions/teacher/CommunicationDetailsAction"
import { showNotification } from "../../../actions/CommonAction"
import {
  getCommunicationDetails,
  getCommunicationAttachments,
  getCommunicationCountDetails,
  saveCommunicationLabelDetails,
  getCommunicationLabelDetails,
  deleteCommunicationLabelDetails,
  restoreCommunicationDetails,
  deleteCommunicationDetails,
  deleteCommunicationToTrashDetails,
  saveOpenMessageModalInStore,
  updateCommunicationByLabelDetails,
} from "../../../actions/admin/CommunicationDetailsAction"
import profilePic from "../../../assets/images/admin.png"
import store from "store"
import Modal, { ModalTransition } from "@atlaskit/modal-dialog"
import { confirmAlertPopUp } from "../../../utils/Utility"
import { DANGER_TOAST, VALIDATION_MSG } from "../../../utils/Constants"
import moment from "moment"
import { HashLoader } from "react-spinners"
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap"

const EMAIL = "EMAIL"
const SMS = "SMS"

class CommunicationDashboard extends Component {
  constructor(props) {
    document.title = "Communication | Redink"

    super(props)
    this.state = {
      showSideMenu: true,
      isShowAll: true,
      isShowStarred: false,
      isShowToday: false,
      isShowMonth: false,
      isSelectedItem: false,
      isMailOpen: true,
      isSmsOpen: false,
      isNotificationOpen: false,
      isSentOpen: true,
      currentReport: {},
      report: [],
      isModalOpen: false,
      labelName: "",
      labelColor: "#084353",
      filterByType: EMAIL,
      filterByTrash: 0,
      filterByLabel: null,
      isShowLoading: true,
      attachments: [],
      isAttachmentLoading: null,
      statistics: this.initialStatistics,
      LABEL_COLORS: [
        { value: "#0b4353" },
        { value: "#6c757d" },
        { value: "#06B191" },
        { value: "#ef375c" },
        { value: "#D9A028" },
        { value: "#17a2b8" },
        { value: "#f8f9fa" },
        { value: "#343a40" },
        { value: "#7C858D" },
        { value: "#dee2e6" },
      ],
    }
  }

  componentDidMount() {
    this.props.getStudentsDetails(() => {})
    this.getCommunicationLabel()
    this.getCommunication()
    this.getStatistics()
  }

  initialStatistics = {
    email: {
      todayCount: 0,
      thisWeekCount: 0,
      thisMonthCount: 0,
    },
    sms: {
      todayCount: 0,
      thisWeekCount: 0,
      thisMonthCount: 0,
    },
  }

  getAttachments = id => {
    this.setState({ isAttachmentLoading: true })
    this.props.getCommunicationAttachments(id, async (success, response) => {
      if (success) {
        this.setState({ attachments: response })
      } else {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Server error. Try again."
        )
      }
      this.setState({ isAttachmentLoading: false })
    })
  }

  onMailClick = (event, report, index) => {
    this.setState({
      isSelectedItem: true,
      currentReport: report,
      selectedId: report.id,
    })
    this.setState({ attachments: [] })
    if (report.attachments.length > 0) {
      this.getAttachments(report.id)
    }
  }

  onDownload = (url, name) => {
    this.setState({ isAttachmentLoading: true }, async () => {
      const xhr = new XMLHttpRequest()
      const res = await new Promise((resolve, reject) => {
        xhr.onload = () => {
          if (xhr.status === 501) reject("not found")

          const file = new Blob([xhr.response], {
            type: "application/pdf",
          })
          resolve({ success: true, file })
        }
        xhr.open("GET", url, true)
        xhr.responseType = "blob"
        xhr.send(null)
      }).catch(err => ({ success: false, err }))

      const { success, file } = res

      if (success) {
        const browserUrl = window.webkitURL || window.URL
        const fileUrl = browserUrl.createObjectURL(file)
        const a = document.createElement("a")
        a.href = fileUrl
        a.download = name
        a.click()
        browserUrl.revokeObjectURL(fileUrl)
      } else {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Failed to download. Try again."
        )
      }
      this.setState({ isAttachmentLoading: false })
    })
  }

  getCommunicationLabel = () => {
    this.props.getCommunicationLabelDetails((success, response) => {
      if (success) {
        this.setState({ labelList: response })
      }
    })
  }

  getCommunication = () => {
    this.setState({ isShowListLoading: false }, () => {
      const object = {}
      object.wayOfMessage = this.state.filterByType === EMAIL ? "email" : "sms"
      object.onTrash = this.state.filterByTrash
      // object.pageNumber = 0
      this.props.getCommunicationDetails(object, () => {
        this.setState({
          isShowListLoading: false,
        })
      })
    })
  }

  getStatistics = sentData => {
    if (sentData) {
      const statistics = this.state.statistics
      const { emailCount, smsCount } = sentData
      statistics.email.todayCount += emailCount
      statistics.email.thisWeekCount += emailCount
      statistics.email.thisMonthCount += emailCount
      statistics.sms.todayCount += smsCount
      statistics.sms.thisWeekCount += smsCount
      statistics.sms.thisMonthCount += smsCount
      return this.setState({ statistics })
    }

    moment.updateLocale("en", {
      week: {
        dow: 1, // Monday is the first day of the week.
      },
    })
    const startOfMonth = moment().startOf("month")
    const startOfWeek = moment().startOf("week")
    const startOfDay = moment().startOf("day")

    this.props.getCommunicationCountDetails(
      { startOfMonth: startOfMonth.format("YYYY-MM-DD HH:mm:ss") },
      (success, response) => {
        if (!success || !response.data) {
          return
        }
        const data = response.data
        const { email, sms } = JSON.parse(
          JSON.stringify(this.initialStatistics)
        )
        if (data["EMAIL"]) {
          for (const item of data["EMAIL"]) {
            const { sentCnt } = item
            const sentAt = moment(item.sentAt)
            if (startOfDay < sentAt) email.todayCount += sentCnt
            if (startOfWeek < sentAt) email.thisWeekCount += sentCnt
            if (startOfMonth < sentAt) email.thisMonthCount += sentCnt
          }
        }
        if (data["SMS"]) {
          for (const item of data["SMS"]) {
            const { sentCnt } = item
            const sentAt = moment(item.sentAt)
            if (startOfDay < sentAt) sms.todayCount += sentCnt
            if (startOfWeek < sentAt) sms.thisWeekCount += sentCnt
            if (startOfMonth < sentAt) sms.thisMonthCount += sentCnt
          }
        }
        const statistics = { email, sms }
        this.setState({ statistics })
      }
    )
  }

  clickSendLabel = () => {
    if (this.state.labelName.trim() === "") {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.OOPS,
        "Label name cannot be empty"
      )
      this.setState({ isModalOpen: true })
    } else {
      this.setState(
        { isModalOpen: false, isShowSaveLabelLoading: true },
        () => {
          let object = {
            name: this.state.labelName,
            color: this.state.labelColor,
          }
          this.props.saveCommunicationLabelDetails(
            object,
            (success, response) => {
              this.setState({ isShowSaveLabelLoading: false })
              if (success) {
                this.setState({ labelName: "", labelColor: "" })
                this.getCommunicationLabel()
              }
            }
          )
        }
      )
    }
  }

  moveToRestore = () => {
    this.props.restoreCommunicationDetails(
      this.state.currentReport.id,
      (success, response) => {
        if (success) {
          this.setState({
            currentReport: {
              ...this.state.currentReport,
              onTrash: response.data.onTrash,
              updatedOn: response.data.updatedOn,
            },
            filterByTrash: 0,
          })
          this.getCommunication()
        }
      }
    )
  }

  moveToTrash = () => {
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT,
      yes => {
        if (yes) {
          this.setState({
            isSelectedItem: false,
          })
          if (this.state.currentReport.onTrash) {
            this.props.deleteCommunicationDetails(
              this.state.currentReport.id,
              (success, response) => {
                if (success) {
                  this.setState({
                    isSelectedItem: false,
                    currentReport: {},
                    // filterByTrash: 0,
                  })
                  this.getCommunication()
                }
              }
            )
          } else {
            this.props.deleteCommunicationToTrashDetails(
              this.state.currentReport.id,
              (success, response) => {
                if (success) {
                  this.setState({
                    // currentReport: {
                    //   ...this.state.currentReport,
                    //   onTrash: response.data.onTrash,
                    //   updatedOn: response.data.updatedOn,
                    // },
                    // filterByTrash: 1,
                    isSelectedItem: false,
                    currentReport: {},
                  })
                  this.getCommunication()
                }
              }
            )
          }
        }
      }
    )
  }

  bytesToSize = bytes => {
    let sizes = ["Bytes", "KB", "MB", "GB", "TB"]
    if (bytes === 0) return "0 Byte"
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i]
  }

  handleClick = () => {
    this.props.saveOpenMessageModalInStore(true)
  }

  render() {
    const { email, sms } = this.state.statistics

    return (
      <div>
        <ModalTransition>
          {this.state.isModalOpen && (
            <Modal
              autoFocus={false}
              width="30%"
              heading="CREATE LABEL"
              actions={[
                {
                  text: "Save",
                  onClick: () => {
                    this.clickSendLabel()
                  },
                  style: {
                    borderRadius: 7,
                  },
                },
                {
                  text: "Cancel",
                  onClick: () => {
                    this.setState({ isModalOpen: false })
                    this.setState({ labelName: " ", labelColor: "" })
                  },
                  style: { borderRadius: 7 },
                },
              ]}
            >
              <div className="container mt-4">
                <div className="form-group row">
                  <label className="text-center col-sm-4 col-form-label">
                    Label Name
                  </label>
                  <div className="col-sm-8">
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="Enter label Name"
                        className="mr-2 rounded form-control"
                        value={this.state.labelName}
                        onChange={e => {
                          this.setState({ labelName: e.target.value })
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group row align-items-center">
                  <label className="text-center col-sm-4 col-form-label">
                    Label Color
                  </label>
                  <div className="col-sm-8">
                    <div className="input-group">
                      {this.state.LABEL_COLORS.map(({ value }) => (
                        <div
                          onClick={() => {
                            this.setState({ labelColor: value })
                          }}
                          className="mr-1 mt-2"
                          style={{
                            height: 25,
                            width: 25,
                            backgroundColor: value,
                            border:
                              this.state.labelColor === value
                                ? "5px solid #0052CC"
                                : value,
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          )}
        </ModalTransition>
        <MessageModal
          dailyEmailUsage={email.todayCount}
          dailySmsUsage={sms.todayCount}
          labelList={this.state.labelList}
          afterSave={(wayOfMessage, data) => {
            this.setState({
              filterByType: wayOfMessage[0],
              filterByTrash: 0,
            })
            this.getCommunication()
            this.getStatistics(data)
          }}
        />
        <AppWrapper
          teacherDashboard
          showTeacherThree
          showTeacherLogo
          screenName={"Communication"}
        >
          <div className="ml-3 container-fluid row" style={{ height: "88vh" }}>
            <div className="col-sm-6 row communication-mail-list">
              <div className="col-sm-4 communication-inner-dash">
                <button
                  // data-toggle="modal"
                  // data-target="#messageModal"
                  className="btn btn-block text-light br-10"
                  style={{ backgroundColor: "#E85A58" }}
                  onClick={this.handleClick}
                >
                  COMPOSE
                </button>
                <div style={{ marginTop: "2vh" }}>
                  {/* <p className="pl-3 communication-panel" type="button">
                    Drafts{" "}
                  </p>
                  <p className="pl-3 communication-panel" type="button">
                    Templates{" "}
                  </p>
                  <p className="pl-3 communication-panel" type="button">
                    Scheduled{" "}
                  </p> */}
                  <p
                    className={`communication-panel pl-3 ${
                      this.state.filterByTrash === 0
                        ? "communication-active"
                        : ""
                    }`}
                    type="button"
                    onClick={() => {
                      this.setState(
                        {
                          filterByTrash: 0,
                          filterByLabel: null,
                          selectedId: null,
                          isSelectedItem: false,
                        },
                        () => {
                          this.getCommunication()
                        }
                      )
                    }}
                  >
                    Sent{" "}
                  </p>
                  <p
                    className={`communication-panel pl-3 ${
                      this.state.filterByTrash === 1
                        ? "communication-active"
                        : ""
                    }`}
                    type="button"
                    onClick={() => {
                      this.setState(
                        {
                          filterByTrash: 1,
                          filterByLabel: null,
                          isSelectedItem: false,
                        },
                        () => {
                          this.getCommunication()
                        }
                      )
                    }}
                  >
                    Trash{" "}
                  </p>
                </div>
                <div style={{ marginTop: "5vh", listStyle: "none" }}>
                  <p>
                    <strong>LABELS</strong>
                    <i
                      type="button"
                      className="ml-3 fa fa-plus"
                      aria-hidden="true"
                      onClick={() => {
                        this.setState({
                          isModalOpen: true,
                        })
                      }}
                    ></i>
                  </p>
                  {this.state.labelList && this.state.labelList.length > 0
                    ? this.state.labelList.map((item, index) => {
                        return (
                          <div
                            key={item.id}
                            className={`d-flex  justify-content-between ${
                              this.state.filterByLabel === item.id
                                ? "communication-active"
                                : ""
                            }`}
                            style={{
                              paddingRight: "10px",
                              paddingLeft: "10px",
                              paddingTop: "2px",
                            }}
                          >
                            <div
                              className="row w-100"
                              onClick={() => {
                                const off = this.state.filterByLabel === item.id
                                this.setState(
                                  {
                                    filterByLabel: off ? null : item.id,
                                    isSelectedLabelTab: off ? -1 : index,
                                    filterByTrash: 0,
                                    isSelectedItem: false,
                                  },
                                  () => {
                                    const object = {}
                                    object.labelId = item.id
                                    object.wayOfMessage =
                                      this.state.filterByType === EMAIL
                                        ? "email"
                                        : "sms"
                                    object.onTrash = this.state.filterByTrash
                                    this.props.getCommunicationDetails(
                                      object,
                                      () => {
                                        this.setState({
                                          isShowListLoading: false,
                                        })
                                      }
                                    )
                                  }
                                )
                              }}
                            >
                              <div
                                className="report-label"
                                style={{ backgroundColor: item.color }}
                              ></div>
                              <li className="mb-2 ml-2 report">{item.name} </li>
                            </div>
                            <div>
                              <i
                                type="button"
                                className="ml-3 fa fa-trash"
                                aria-hidden="true"
                                onClick={() => {
                                  confirmAlertPopUp(
                                    VALIDATION_MSG.DELETE_TITLE,
                                    VALIDATION_MSG.DELETE_CONTENT,
                                    yes => {
                                      if (yes) {
                                        this.props.deleteCommunicationLabelDetails(
                                          item.id,
                                          (success, response) => {
                                            if (success) {
                                              this.setState(
                                                {
                                                  filterByLabel: null,
                                                  isSelectedLabelTab: -1,
                                                },
                                                () => {
                                                  this.getCommunicationLabel()
                                                  this.getCommunication()
                                                }
                                              )
                                            }
                                          }
                                        )
                                      }
                                    },
                                    {
                                      buttons: [],
                                      customUI: ({ onClose }) => {
                                        return (
                                          <div
                                            className="custom"
                                            style={{
                                              backgroundColor: "#fff",

                                              borderBottomRightRadius: "12px",
                                              borderBottomLeftRadius: "12px",
                                            }}
                                          >
                                            <div
                                              className="bg-brand text-light d-flex"
                                              style={{
                                                padding: "0.5rem 2rem",
                                                borderTopRightRadius: "12px",
                                                borderTopLeftRadius: "12px",
                                              }}
                                            >
                                              <div
                                                className="mx-auto"
                                                style={{ fontSize: "18px" }}
                                              >
                                                Delete
                                              </div>
                                            </div>

                                            <div
                                              className="mt-3 "
                                              style={{
                                                padding: "0.5rem 1.5rem",
                                              }}
                                            >
                                              <div
                                                className="text-muted"
                                                style={{ fontSize: "15px" }}
                                              >
                                                Are you sure you want to delete?
                                              </div>
                                              <div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
                                                <button
                                                  className="px-4 py-1 bg-brand text-light w-25 d-flex align-items-center justify-content-center"
                                                  style={{
                                                    borderRadius: "5px",
                                                  }}
                                                  onClick={() => {
                                                    this.props.deleteCommunicationLabelDetails(
                                                      item.id,
                                                      (success, response) => {
                                                        if (success) {
                                                          this.setState(
                                                            {
                                                              filterByLabel: null,
                                                              isSelectedLabelTab: -1,
                                                            },
                                                            () => {
                                                              this.getCommunicationLabel()
                                                              this.getCommunication()
                                                            }
                                                          )
                                                        }
                                                      }
                                                    )
                                                    onClose()
                                                  }}
                                                >
                                                  Yes
                                                </button>
                                                <button
                                                  className="px-4 py-1 ml-4 bg-brand text-light w-25 d-flex align-items-center justify-content-center"
                                                  style={{
                                                    borderRadius: "5px",
                                                  }}
                                                  onClick={onClose}
                                                >
                                                  No
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      },
                                    }
                                  )
                                  // confirmAlertPopUp(
                                  //   VALIDATION_MSG.DELETE_TITLE,
                                  //   VALIDATION_MSG.DELETE_CONTENT,
                                  //   yes => {
                                  //     if (yes) {
                                  //       this.props.deleteCommunicationLabelDetails(
                                  //         item.id,
                                  //         (success, response) => {
                                  //           if (success)
                                  //             this.getCommunicationLabel()
                                  //         }
                                  //       )
                                  //     }
                                  //   }
                                  // )
                                }}
                              ></i>
                            </div>
                          </div>
                        )
                      })
                    : null}
                </div>
                <div style={{ marginTop: "3vh" }}>
                  <p
                    style={{
                      backgroundColor: "#479997",
                      marginLeft: "-30px",
                      borderRadius: "2px",
                      width: "calc(100% + 30px)",
                      paddingLeft: "30px",
                    }}
                  >
                    Email Sent
                  </p>
                  <div
                    className="mb-3 d-flex justify-content-between br-10"
                    style={{ border: "white solid 1px" }}
                  >
                    <div className="py-2 pl-3" style={{ fontSize: "12px" }}>
                      Today
                    </div>
                    <div className="px-3 py-2 bg-theme-verylight br-10">
                      {email.todayCount}
                    </div>
                  </div>
                  <div
                    className="mb-3 d-flex justify-content-between br-10"
                    style={{ border: "white solid 1px" }}
                  >
                    <div className="py-2 pl-3" style={{ fontSize: "12px" }}>
                      This Week
                    </div>
                    <div className="px-3 py-2 bg-theme-verylight br-10">
                      {email.thisWeekCount}
                    </div>
                  </div>
                  <div
                    className="d-flex justify-content-between br-10"
                    style={{ border: "white solid 1px" }}
                  >
                    <div className="py-2 pl-3" style={{ fontSize: "12px" }}>
                      This Month
                    </div>
                    <div className="px-3 py-2 bg-theme-verylight br-10">
                      {email.thisMonthCount}
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: "3vh" }}>
                  <p
                    style={{
                      backgroundColor: "#479997",
                      marginLeft: "-30px",
                      borderRadius: "2px",
                      width: "calc(100% + 30px)",
                      paddingLeft: "30px",
                    }}
                  >
                    SMS Sent
                  </p>
                  <div
                    className="mb-3 d-flex justify-content-between br-10"
                    style={{ border: "white solid 1px" }}
                  >
                    <div className="py-2 pl-3" style={{ fontSize: "12px" }}>
                      Today
                    </div>
                    <div className="px-3 py-2 bg-theme-verylight br-10">
                      {sms.todayCount}
                    </div>
                  </div>
                  <div
                    className="mb-3 d-flex justify-content-between br-10"
                    style={{ border: "white solid 1px" }}
                  >
                    <div className="py-2 pl-3" style={{ fontSize: "12px" }}>
                      This Week
                    </div>
                    <div className="px-3 py-2 bg-theme-verylight br-10">
                      {sms.thisWeekCount}
                    </div>
                  </div>
                  <div
                    className="d-flex justify-content-between br-10"
                    style={{ border: "white solid 1px" }}
                  >
                    <div className="py-2 pl-3" style={{ fontSize: "12px" }}>
                      This Month
                    </div>
                    <div className="px-3 py-2 bg-theme-verylight br-10">
                      {sms.thisMonthCount}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-8" style={{ height: "80vh" }}>
                <div
                  className="text-center row bg-theme-light "
                  style={{ borderRadius: "25px" }}
                >
                  <div
                    className={`col py-3 ${
                      this.state.filterByType === EMAIL
                        ? "selected-mail-tab"
                        : "unselected-mail-tab"
                    }`}
                    onClick={() => {
                      this.setState(
                        {
                          filterByType: EMAIL,
                          isSelectedItem: false,
                          currentReport: null,
                          selectedId: null,
                        },
                        () => {
                          if (this.state.filterByLabel) {
                            const object = {}
                            object.labelId = this.state.filterByLabel
                            object.onTrash = this.state.filterByTrash
                            object.wayOfMessage =
                              this.state.filterByType === EMAIL
                                ? "email"
                                : "sms"
                            this.props.getCommunicationDetails(object, () => {
                              this.setState({
                                isShowListLoading: false,
                              })
                            })
                          } else {
                            this.getCommunication()
                          }
                        }
                      )
                    }}
                  >
                    Email
                  </div>
                  <div
                    className={`col py-3 ${
                      this.state.filterByType === SMS
                        ? "selected-mail-tab"
                        : "unselected-mail-tab"
                    }`}
                    onClick={() =>
                      this.setState(
                        {
                          filterByType: SMS,
                          isSelectedItem: false,
                          currentReport: null,
                          selectedId: null,
                        },
                        () => {
                          if (this.state.filterByLabel) {
                            const object = {}
                            object.labelId = this.state.filterByLabel
                            object.wayOfMessage =
                              this.state.filterByType === EMAIL
                                ? "email"
                                : "sms"
                            this.props.getCommunicationDetails(object, () => {
                              this.setState({
                                isShowListLoading: false,
                              })
                            })
                          } else {
                            this.getCommunication()
                          }
                        }
                      )
                    }
                  >
                    SMS
                  </div>
                  {/* <div
                    className={`col py-3 ${
                      this.state.filterByType === 'notification'
                        ? "selected-mail-tab"
                        : "unselected-mail-tab"
                    }`}
                    onClick={() =>
                      this.setState({
                        filterByType: 'notification',
                      })
                    }
                  >
                    Notification
                  </div> */}
                </div>
                <div
                  className="scrollbar"
                  style={{ height: "80vh", position: "relative" }}
                >
                  {this.state.isShowListLoading ? (
                    <div
                      className="d-flex justify-content-center"
                      style={{ marginTop: 150, position: "absolute" }}
                    >
                      <HashLoader
                        sizeUnit={"px"}
                        size={35}
                        color={"#084353"}
                        loading={this.state.isShowLoading}
                      />
                    </div>
                  ) : (
                    this.props.communicationList &&
                    this.props.communicationList.length > 0 &&
                    this.props.communicationList.map((report, index) => {
                      return (
                        <div
                          key={report.id}
                          className={`card mail-card ml-2 mt-3 mb-2 ${
                            this.state.selectedId === report.id
                              ? "mail-card-active"
                              : ""
                          }`}
                          onClick={event =>
                            this.onMailClick(event, report, index)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <div className="pt-3 pb-0 pl-1 pr-4 mb-0 d-flex justify-content-around">
                            <div className="d-flex justify-content-around">
                              <h5 className="pb-0 pr-3 mb-0  text-dark">
                                {report.recipientRole === 5
                                  ? "Parent"
                                  : report.recipientRole === 3
                                  ? "Class Teacher"
                                  : "Subject Teacher"}
                              </h5>
                              <span
                                className="px-3"
                                style={{
                                  fontSize: "13px",
                                  marginTop: "2px",
                                  backgroundColor: "#94DACF",
                                  height: "20px",
                                  borderRadius: "6px",
                                }}
                              >
                                {report.recipients.length}{" "}
                                {report.recipientRole === 5
                                  ? "Parents"
                                  : "Teachers"}
                              </span>
                            </div>
                            <div className="row">
                              <p>
                                {report.sentAt
                                  ? moment(report.sentAt).format("DD-MM-YYYY")
                                  : ""}
                              </p>
                            </div>
                          </div>
                          <div className="pt-0 mt-0 card-body ml-3">
                            <div className="d-flex">
                              <h6 className="text-dark">
                                <strong>{report.title}</strong>
                              </h6>
                              {report.label && (
                                <div
                                  className="report-label"
                                  style={{
                                    backgroundColor: report.label.color,
                                  }}
                                ></div>
                              )}
                            </div>
                            <div className="is-clipped">{report.content}</div>
                          </div>
                        </div>
                      )
                    })
                  )}
                  {this.props.communicationList &&
                    this.props.communicationList.length === 0 && (
                      <div
                        className="d-flex"
                        style={{
                          textAlign: "center",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          fontWeight: "bold",
                          fontSize: "20",
                        }}
                      >
                        No Data
                      </div>
                    )}
                </div>
              </div>
            </div>
            {this.state.isSelectedItem && (
              <div className="p-5 col-sm-6">
                <div className="d-flex justify-content-between">
                  <div className="d-flex justify-content-around flex-center">
                    <img
                      className="rounded-circle"
                      src={profilePic}
                      alt="Profile"
                      width={32}
                    />
                    <div className="mt-1 ml-3" style={{ fontSize: "1.2vw" }}>
                      {store.get("quickInfo")
                        ? store.get("quickInfo").adminName
                        : ""}{" "}
                    </div>
                  </div>
                  <div className="mt-3 d-flex justify-content-between">
                    {!!this.state.filterByTrash && (
                      <div
                        type="button"
                        title="Restore?"
                        className="mr-3 fas fa-trash-restore text-black-50"
                        onClick={() => {
                          this.moveToRestore()
                        }}
                      ></div>
                    )}
                    <div
                      type="button"
                      title={
                        this.state.filterByTrash
                          ? "Permanently delete?"
                          : "Move to trash?"
                      }
                      className="mr-3 fa fa-trash text-black-50"
                      onClick={() => {
                        this.moveToTrash()
                      }}
                    ></div>
                  </div>
                </div>
                <div
                  className="mt-3 d-flex justify-content-between"
                  style={{ fontSize: "18px" }}
                >
                  <div>
                    To:{" "}
                    {this.state.currentReport.recipientRole === 5
                      ? "Parent"
                      : this.state.currentReport.recipientRole === 3
                      ? "Class Teacher"
                      : "Subject Teacher"}
                    <span
                      className="px-3"
                      style={{
                        fontSize: "16px",
                        backgroundColor: "#94DACF",
                        marginLeft: "5px",
                        height: "20px",
                        borderRadius: "6px",
                      }}
                    >
                      {" "}
                      {this.state.currentReport.recipients.length}{" "}
                      {this.state.currentReport.recipientRole === 5
                        ? "Parents"
                        : "Teachers"}
                    </span>
                  </div>
                  <div>
                    <small>
                      <strong>
                        {this.state.currentReport.sentAt &&
                          moment(this.state.currentReport.sentAt).format(
                            "llll"
                          )}
                      </strong>
                    </small>
                  </div>
                </div>
                <div
                  className="mt-3 d-flex justify-content-between"
                  style={{ fontSize: "18px" }}
                >
                  <div>
                    <strong>{this.state.currentReport.title}</strong>
                  </div>
                  {this.state.currentReport.label ? (
                    <UncontrolledDropdown>
                      <DropdownToggle tag="a" className="nav-link">
                        <p
                          className="p-0 px-2 btn badge-pill d-flex text-dark"
                          style={{
                            border: `${this.state.currentReport.label.color} solid 1px`,
                            borderRadius: "20px",
                          }}
                        >
                          <div
                            className="report-label-2"
                            style={{
                              backgroundColor: this.state.currentReport.label
                                .color,
                            }}
                          ></div>
                          {this.state.currentReport.label.name}
                        </p>
                      </DropdownToggle>

                      <DropdownMenu>
                        {this.state.labelList && this.state.labelList.length > 0
                          ? this.state.labelList.map((item, index) => {
                              return (
                                <DropdownItem
                                  onClick={() => {
                                    let object = {
                                      labelId: item.id,
                                      id: this.state.currentReport.id,
                                    }
                                    this.props.updateCommunicationByLabelDetails(
                                      object,
                                      (success, response) => {
                                        if (success) {
                                          let object = this.state.currentReport
                                          object["label"] = item
                                          object["labelId"] = item.id
                                          this.setState({
                                            currentReport: object,
                                          })
                                        }
                                      }
                                    )
                                  }}
                                >
                                  <div
                                    className="report-label-2"
                                    style={{
                                      backgroundColor: item.color,
                                    }}
                                  ></div>
                                  {item.name}
                                </DropdownItem>
                              )
                            })
                          : null}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  ) : (
                    <UncontrolledDropdown>
                      <DropdownToggle tag="a" className="nav-link">
                        <p
                          className="p-0 px-2 btn badge-pill d-flex text-dark"
                          style={{
                            border: `#0DB191 solid 1px`,
                            borderRadius: "20px",
                          }}
                        >
                          <i
                            className="fa fa-plus"
                            style={{
                              marginTop: "2px",
                              fontSize: "16px",
                              color: "#0DB191",
                            }}
                          ></i>
                          Add Label
                        </p>
                      </DropdownToggle>

                      <DropdownMenu>
                        {this.state.labelList && this.state.labelList.length > 0
                          ? this.state.labelList.map((label, index) => {
                              return (
                                <DropdownItem
                                  onClick={() => {
                                    let object = {
                                      labelId: label.id,
                                      id: this.state.currentReport.id,
                                    }
                                    this.props.updateCommunicationByLabelDetails(
                                      object,
                                      (success, response) => {
                                        if (success) {
                                          let object = this.state.currentReport
                                          object["label"] = label
                                          object["labelId"] = label.id
                                          this.setState({
                                            currentReport: object,
                                          })
                                        }
                                      }
                                    )
                                  }}
                                >
                                  <div
                                    className="report-label-2"
                                    style={{
                                      backgroundColor: label.color,
                                    }}
                                  ></div>
                                  {label.name}
                                </DropdownItem>
                              )
                            })
                          : null}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}
                </div>
                <div className="mt-2" style={{ fontSize: "18px" }}>
                  {this.state.currentReport.content}
                </div>
                {this.state.currentReport.attachments.length > 0 ? (
                  <div style={{ width: "30vh", marginTop: "60px" }}>
                    <div className="d-flex justify-content-between">
                      <div href="">Attachment</div>
                    </div>
                    <div>
                      <HashLoader
                        sizeUnit={"px"}
                        size={35}
                        color={"#084353"}
                        loading={this.state.isAttachmentLoading}
                      />
                      {!this.state.isAttachmentLoading &&
                        this.state.attachments.map((attachment, index) => (
                          <div
                            key={index}
                            className="pr-3 attachments d-flex"
                            onClick={() =>
                              this.onDownload(attachment.url, attachment.name)
                            }
                          >
                            <div
                              className="card d-flex align-items-center col-3"
                              style={{
                                borderRadius: "15px",
                                paddingTop: "0.8vw",
                                backgroundColor: "#0B4353",
                              }}
                            >
                              <i
                                className="fa fa-paperclip "
                                style={{
                                  fontSize: "2.2vw",
                                  color: "#0DB191",
                                }}
                              ></i>
                            </div>
                            <div className="pt-1 pl-1 pr-0 col-9 is-clipped-att">
                              <small style={{ color: "#0A1414" }}>
                                {attachment.name}
                              </small>
                              <br />
                              <small style={{ color: "#0A1414" }}>
                                {this.bytesToSize(attachment.size)}
                              </small>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </AppWrapper>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    subjectList: state.communicationDetailsState.subjectList,
    classesList: state.communicationDetailsState.classList,
    teachersList: state.communicationDetailsState.teachersList,
    studentsList: state.communicationDetailsState.studentsList,
    communicationList: state.communicationDetailsState.communicationList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getCommunicationDetails,
      getCommunicationAttachments,
      getStudentsDetails,
      saveCommunicationStarredDetails,
      saveCommunicationDetailsInStore,
      saveCommunicationDetails,
      getCommunicationCountDetails,
      saveCommunicationLabelDetails,
      getCommunicationLabelDetails,
      deleteCommunicationLabelDetails,
      restoreCommunicationDetails,
      deleteCommunicationDetails,
      deleteCommunicationToTrashDetails,
      saveOpenMessageModalInStore,
      updateCommunicationByLabelDetails,
      showNotification,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(CommunicationDashboard)
