/*************************************************
 * RedInk
 * @exports
 * @class StudentDatabaseAction.js
 * @extends Component
 * @author vijayakumar // on 05/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { ACTION_TYPES } from "../types"
import {
  DANGER_TOAST,
  VALIDATION_MSG,
  SUCCESS_TOAST,
} from "../../utils/Constants"
import { showNotification } from "../CommonAction"

export const saveStudentDatabaseInStore = studentList => {
  return {
    type: ACTION_TYPES.STUDENT_DATABASE_LIST,
    payload: studentList,
  }
}

export const saveStudentDatabaseDetailInStore = studentDetail => {
  return {
    type: ACTION_TYPES.STUDENT_DATABASE_DETAIL,
    payload: studentDetail,
  }
}

export const saveAdminStudentDatabaseDetailInStore = adminStudentList => {
  return {
    type: ACTION_TYPES.ADMIN_STUDENT_DATABASE_LIST,
    payload: adminStudentList,
  }
}

export const saveAdminStudentDetailInStore = adminStudentDetails => {
  return {
    type: ACTION_TYPES.ADMIN_STUDENT_DETAILS,
    payload: adminStudentDetails,
  }
}

export const saveStudentCurrentGroupDetailInStore = studentDetail => {
  return {
    type: ACTION_TYPES.STUDENT_CURRENT_GROUP_DETAIL,
    payload: studentDetail,
  }
}

export const saveStudentDatabaseDetailInitialInStore = () => {
  return {
    type: ACTION_TYPES.STUDENT_DATABASE_DETAIL_INITIAL,
  }
}

export const getAllStudentsDatabaseList = (
  object = {},
  callBack,
  saveInStore = true
) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_ADMIN_STUDENT_DATABASE_LIST,
        object,
        true,
        true
      )
      if (response) {
        myLog("====getAllStudentsDatabaseList RESPONSE===::::", response)
        if (saveInStore) {
          dispatch(saveAdminStudentDatabaseDetailInStore(response.data.rows))
        }
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getAllStudentsDatabaseList ERROR===????", error)
    }
  }
}

export const getStudentDatabaseList = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_TEACHERS_STUDENT_DATABASE_LIST,
        null,
        true
      )
      if (response) {
        myLog("====getStudentDatabaseList RESPONSE===::::", response)
        dispatch(saveStudentDatabaseInStore(response.studentDetails))
        callBack(true, response.studentDetails)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getStudentDatabaseList ERROR===????", error)
    }
  }
}

export const saveStudentDatabaseDetails = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      const response = await Client.post(
        URL.SAVE_TEACHERS_STUDENT_DATABASE_LIST,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====saveStudentDatabaseDetails RESPONSE===::::", response)
        let object = { limit: 50, offset: 0 }
        dispatch(getAllStudentsDatabaseList(object, () => {}))
        dispatch(getStudentDatabaseList(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====saveStudentDatabaseDetails ERROR===????", error)
    }
  }
}

export const updateStudentDatabaseDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.post(
        URL.UPDATE_TEACHERS_STUDENT_DATABASE_LIST,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog(
          "====updateStudentDatabaseDetails RESPONSE===::::",
          response,
          body
        )
        let object = { limit: 50, offset: 0 }
        dispatch(getAllStudentsDatabaseList(object, () => {}))
        dispatch(getStudentDatabaseList(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====updateStudentDatabaseDetails ERROR===????", error)
    }
  }
}

export const deleteStudentDatabaseDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.DELETE_TEACHERS_STUDENT_DATABASE_LIST,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteStudentDatabaseDetails RESPONSE===::::", response)
        dispatch(getStudentDatabaseList(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteStudentDatabaseDetails ERROR===????", error)
    }
  }
}

export const deleteBulkStudentDatabaseDetails = (students, callBack) => {
  return async dispatch => {
    try {
      const responses = []
      for (const student of students) {
        const response = await Client.delete(
          URL.DELETE_TEACHERS_STUDENT_DATABASE_LIST,
          { id: student },
          true
        )
        responses.push(response)
      }

      if (responses.every(res => res.responseCode === 0)) {
        dispatch(getStudentDatabaseList(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Students Deleted Successfully !"
          )
        )
        callBack && callBack(true)
      } else {
        responses.forEach(res => {
          if (res.responseCode === 1) {
            dispatch(
              showNotification(
                DANGER_TOAST,
                VALIDATION_MSG.OOPS,
                res.responseMessage
              )
            )
            callBack && callBack(false)
          }
        })
      }
      // if (response.responseCode === 0) {
      //   myLog("====deleteStudentDatabaseDetails RESPONSE===::::", response)
      //   dispatch(getStudentDatabaseList(() => {}))
      //   dispatch(
      //     showNotification(
      //       SUCCESS_TOAST,
      //       VALIDATION_MSG.SUCCESS,
      //       response.responseMessage
      //     )
      //   )
      //   callBack(true)
      // } else {
      //   dispatch(
      //     showNotification(
      //       DANGER_TOAST,
      //       VALIDATION_MSG.OOPS,
      //       response.responseMessage
      //     )
      //   )
      //   callBack(false)
      // }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack && callBack(false)
      myLog("====deleteStudentDatabaseDetails ERROR===????", error)
    }
  }
}

export const getStudentProfileDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_STUDENT_PROFILE_DETAILS,
        body,
        true
      )
      if (response) {
        myLog("====getStudentProfileDetails RESPONSE===::::", response)
        callBack(true, response.studentDetails)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getStudentProfileDetails ERROR===????", error)
    }
  }
}

export const sendStudentCredentials = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.post(
        URL.SEND_ADMIN_STUDENT_CREDENTIALS,
        body,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====sendStudentCredentials RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====sendStudentCredentials ERROR===????", error)
    }
  }
}

export const saveStudentGroup = (body, callback) => {
  return async dispatch => {
    try {
      const reqData = body
      myLog("saveGroupRequest reqData", reqData)
      const saveGroupRequest = await Client.post(
        URL.SAVE_STUDENTS_GROUP,
        reqData,
        true
      )
      myLog(
        "====saveStudentGroup saveGroupRequest RESPONSE===::::",
        saveGroupRequest
      )
      if (saveGroupRequest.responseCode !== 0) {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SOMETHING_WENT_WRONG,
            saveGroupRequest.responseMessage
          )
        )
        callback(false)
      } else {
        const getUpdatedGroupsRequest = await Client.get(
          URL.GET_STUDENTS_GROUPS,
          null,
          true
        )
        myLog(
          "====saveStudentGroup getUpdatedGroupsRequest RESPONSE===::::",
          getUpdatedGroupsRequest
        )
        const newStudentGroups = getUpdatedGroupsRequest.groups
        dispatch({
          type: ACTION_TYPES.TEACHER_UPDATE_STUDENT_GROUPS,
          payload: { studentGroups: newStudentGroups },
        })

        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Student group added"
          )
        )
        callback(true)
      }
    } catch (e) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SOMETHING_WENT_WRONG,
          "Something went wrong..."
        )
      )
      myLog("====saveStudentGroup ERROR===????", e)
    }
  }
}

export const updateStudentGroup = (body, callback) => {
  return async dispatch => {
    try {
      const reqData = body
      myLog("saveGroupRequest reqData", reqData)
      const saveGroupRequest = await Client.post(
        URL.UPDATE_STUDENTS_GROUP,
        reqData,
        true
      )
      myLog(
        "====updateStudentGroup saveGroupRequest RESPONSE===::::",
        saveGroupRequest
      )
      if (saveGroupRequest.responseCode !== 0) {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SOMETHING_WENT_WRONG,
            saveGroupRequest.responseMessage
          )
        )
        callback(false)
      } else {
        const getUpdatedGroupsRequest = await Client.get(
          URL.GET_STUDENTS_GROUPS,
          null,
          true
        )
        myLog(
          "====updateStudentGroup getUpdatedGroupsRequest RESPONSE===::::",
          getUpdatedGroupsRequest
        )
        const newStudentGroups = getUpdatedGroupsRequest.groups
        dispatch({
          type: ACTION_TYPES.TEACHER_UPDATE_STUDENT_GROUPS,
          payload: { studentGroups: newStudentGroups },
        })

        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Student group added"
          )
        )
        callback(true)
      }
    } catch (e) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SOMETHING_WENT_WRONG,
          "Something went wrong..."
        )
      )
      myLog("====updateStudentGroup ERROR===????", e)
    }
  }
}

export const deleteStudentInGroup = (body, callback) => {
  return async dispatch => {
    try {
      myLog("deleteStudentInGroup reqData", body)
      const deleteGroupRequest = await Client.delete(
        URL.DELETE_STUDENTS_IN_GROUP,
        body,
        true
      )
      myLog(
        "====deleteStudentInGroup deleteGroupRequest RESPONSE===::::",
        deleteGroupRequest
      )
      if (deleteGroupRequest.responseCode !== 0) {
        throw new Error("An unexpected error occured")
      }
      const getUpdatedGroupsRequest = await Client.get(
        URL.GET_STUDENTS_GROUPS,
        null,
        true
      )
      myLog(
        "====deleteStudentInGroup getUpdatedGroupsRequest RESPONSE===::::",
        getUpdatedGroupsRequest
      )
      const newStudentGroups = getUpdatedGroupsRequest.groups
      dispatch({
        type: ACTION_TYPES.TEACHER_UPDATE_STUDENT_GROUPS,
        payload: { studentGroups: newStudentGroups },
      })
      if (callback) callback()
      dispatch(
        showNotification(
          SUCCESS_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Student deleted from group"
        )
      )
    } catch (e) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SOMETHING_WENT_WRONG,
          "Something went wrong..."
        )
      )
      myLog("====deleteStudentInGroup ERROR===????", e)
    }
  }
}

export const deleteStudentGroup = (body, callback) => {
  return async dispatch => {
    try {
      const reqData = { groupId: body.groupId }
      myLog("deleteStudentGroup reqData", reqData)
      const deleteGroupRequest = await Client.delete(
        URL.DELETE_STUDENTS_GROUP,
        reqData,
        true
      )
      myLog(
        "====deleteStudentGroup deleteGroupRequest RESPONSE===::::",
        deleteGroupRequest
      )
      if (deleteGroupRequest.responseCode !== 0) {
        throw new Error("An unexpected error occured")
      }
      const getUpdatedGroupsRequest = await Client.get(
        URL.GET_STUDENTS_GROUPS,
        null,
        true
      )
      myLog(
        "====deleteStudentGroup getUpdatedGroupsRequest RESPONSE===::::",
        getUpdatedGroupsRequest
      )
      const newStudentGroups = getUpdatedGroupsRequest.groups
      dispatch({
        type: ACTION_TYPES.TEACHER_UPDATE_STUDENT_GROUPS,
        payload: { studentGroups: newStudentGroups },
      })
      if (callback) callback()
      dispatch(
        showNotification(
          SUCCESS_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Student group deleted"
        )
      )
    } catch (e) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SOMETHING_WENT_WRONG,
          "Something went wrong..."
        )
      )
      myLog("====deleteStudentGroup ERROR===????", e)
    }
  }
}

export const getStudentGroups = (body, callback) => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_STUDENTS_GROUPS, null, true)
      myLog("====getStudentGroups RESPONSE===::::", response)
      const newStudentGroups = response.groups
      dispatch({
        type: ACTION_TYPES.TEACHER_UPDATE_STUDENT_GROUPS,
        payload: { studentGroups: newStudentGroups },
      })
      if (callback) callback()
    } catch (e) {
      myLog("====getStudentGroups ERROR===????", e)
    }
  }
}
