import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import { showNotification } from "../CommonAction"
import {
  // SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"
import URL from "../../config"
// import { ACTION_TYPES } from "../types"
// const {} = ACTION_TYPES

export const getQuizReport = (body, callBack = () => {}) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        `${URL.GET_STUDENT_QUIZ_REPORT}/${body}`,
        {},
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====getQuizReport RESPONSE===::::", response)
        callBack(true, response.data)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====getQuizReport ERROR===????", error)
    }
  }
}

export const getQuizList = (body, callBack = () => {}) => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.STUDENT_QUIZ, body, true, true)
      if (response.responseCode === 0) {
        myLog("====getQuizList RESPONSE===::::", response)
        callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====getQuizList ERROR===????", error)
    }
  }
}

export const getSingleQuiz = (body, callBack = () => {}) => {
  const id = typeof body === "object" ? body.id : body
  const solved = typeof body === "object" ? body.solved : false

  return async dispatch => {
    try {
      const response = await Client.get(
        `${URL.STUDENT_QUIZ}/${id}?solved=${solved}`,
        null,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====getSingleQuiz RESPONSE===::::", response)
        callBack(true, response.data)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====getSingleQuiz ERROR===????", error)
    }
  }
}

export const submitQuiz = (id, data, callBack = () => {}) => {
  return async dispatch => {
    try {
      const response = await Client.post(
        URL.STUDENT_QUIZ + "/" + id + "/submit",
        data,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====submitQuiz RESPONSE===::::", response)
        callBack(true, response.data)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====submitQuiz ERROR===????", error)
    }
  }
}

export const getPresignedUrl = (fileName, callBack = () => {}) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_STUDENT_QUIZ_FILE_UPLOAD + `/?name=${fileName}`,
        null,
        true,
        true
      )
      if (response) {
        myLog("====getPresignedUrl RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getPresignedUrl ERROR===????", error)
    }
  }
}

export const updateViewedAssessment = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_STUDENT_QUIZZES + "/" + body.id + "/view",
        null,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====updateViewedAssessment RESPONSE===::::", response)
        callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====updateViewedAssessment ERROR===????", error)
    }
  }
}
