/*************************************************
 * RedInk
 * @exports
 * @class ClassDetailscreen.js
 * @extends Component
 * @author Sasidharan // on 08/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import Select, { components } from "react-select"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  getClassDetails,
  saveClassDetails,
  deleteClassDetails,
  updateClassDetails,
  getClassGroup,
  saveClassGroup,
  deleteClassGroup,
  updateClassGroup,
} from "../../../actions/admin/ClassDetailsAction"
import { myLog, confirmAlertPopUp } from "../../../utils/Utility"
import { FormGroup, Label, Input } from "reactstrap"
import { HashLoader } from "react-spinners"
import { DANGER_TOAST, VALIDATION_MSG } from "../../../utils/Constants"
import { showNotification } from "../../../actions/CommonAction"

export const sections = [
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "C", label: "C" },
  { value: "D", label: "D" },
  { value: "E", label: "E" },
  { value: "F", label: "F" },
  { value: "G", label: "G" },
  { value: "H", label: "H" },
  { value: "I", label: "I" },
  { value: "J", label: "J" },
  { value: "K", label: "K" },
  { value: "L", label: "L" },
  { value: "M", label: "M" },
  { value: "N", label: "N" },
  { value: "O", label: "O" },
  { value: "P", label: "P" },
  { value: "Q", label: "Q" },
  { value: "R", label: "R" },
  { value: "S", label: "S" },
  { value: "T", label: "T" },
  { value: "U", label: "U" },
  { value: "V", label: "V" },
  { value: "W", label: "W" },
  { value: "X", label: "X" },
  { value: "Y", label: "Y" },
  { value: "Z", label: "Z" },
]
export const classes = [
  { value: "JKG", label: "JKG" },
  { value: "SKG", label: "SKG" },
  { value: "LKG", label: "LKG" },
  { value: "UKG", label: "UKG" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
]

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.value} </label>
      </components.Option>
    </div>
  )
}
const MultiValue = props => {
  return (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  )
}

class ClassGroupScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTab: "Classes",
      createGroupOpen: true,
      groupAdding: false,
      editingGroupId: null,
      newGroupName: "",
      newGroupClasses: [],
      savedClasses: false,
      selectedClasses: [],
      selectedSections: [],
      selectedClassesAndSections: [],
      saveSelectedClassesAndSections: [],
      isShowLoading: false,
    }
  }

  componentDidMount = () => {
    this.props.getClassDetails({}, (success, response) => {})
    this.props.getClassGroup({}, (success, response) => {})
  }

  addClassesAndSubject = () => {
    myLog(this.state.selectedClasses, this.state.selectedSections)
    if (
      this.state.selectedClasses &&
      this.state.selectedSections &&
      this.state.selectedSections.length > 0
    ) {
      let array = this.state.selectedClassesAndSections
      let string = this.state.selectedClasses.value + "-"
      let alreayAvailableClassindex = -1
      let alreaySavedSelectedClassesAndSectionIndex = -1
      let classes = this.state.saveSelectedClassesAndSections
      let sectionsList = []

      for (let j = 0; j < array.length; j++) {
        let dummyString = array[j].classesAndSections
        let splitString = dummyString.split("-")
        if (splitString[0] === this.state.selectedClasses.value) {
          alreayAvailableClassindex = j
          string = dummyString
        }
      }

      for (let k = 0; k < array.length; k++) {
        let dummyString = classes[k].class
        if (dummyString === this.state.selectedClasses.value) {
          alreaySavedSelectedClassesAndSectionIndex = k
          for (let m = 0; m < classes[k].sections.length; m++) {
            sectionsList.push(classes[k].sections[m])
          }
        }
      }

      for (let i = 0; i < this.state.selectedSections.length; i++) {
        string = string + this.state.selectedSections[i].value + ","
        sectionsList.push(this.state.selectedSections[i].value)
      }

      if (alreaySavedSelectedClassesAndSectionIndex !== -1) {
        classes.splice(alreaySavedSelectedClassesAndSectionIndex, 1)
      }
      let classObject = {
        class: this.state.selectedClasses.value,
      }
      classObject.sections = sectionsList
      classes.push(classObject)

      if (alreayAvailableClassindex !== -1) {
        array.splice(alreayAvailableClassindex, 1)
      }
      let object = {
        classesAndSections: string,
      }
      array.push(object)

      this.cancelSubmit()
      this.setState({
        selectedClassesAndSections: array,
        saveSelectedClassesAndSections: classes,
      })
    }
  }

  cancelSubmit = () => {
    this.setState({
      selectedClasses: [],
      selectedSections: [],
      selectedClassesAndSections: [],
      saveSelectedClassesAndSections: [],
    })
  }
  /**
   * Delete a subject
   */
  deleteClassesAndSections = index => {
    if (this.state.selectedClassesAndSections.length > 0) {
      let arrselectedClassesAndSections = this.state.selectedClassesAndSections
      let arrSaveSelectedClassesAndSections = this.state
        .saveSelectedClassesAndSections
      arrSaveSelectedClassesAndSections.splice(index, 1)
      arrselectedClassesAndSections.splice(index, 1)
      this.setState(
        {
          selectedClassesAndSections: arrselectedClassesAndSections,
          saveSelectedClassesAndSections: arrSaveSelectedClassesAndSections,
        },
        () => {
          myLog(this.state.selectedClassesAndSections)
        }
      )
    }
  }

  /**
   * Delete a subject from db
   */
  deleteClassFromDb = item => {
    myLog(item.id)
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT,
      yes => {
        if (yes) {
          this.props.deleteClassDetails({ id: item.id }, () => {})
        }
      }
    )
  }

  /**
   * saveSubject
   */
  saveClass = () => {
    myLog(this.state.saveSelectedClassesAndSections)
    if (this.state.saveSelectedClassesAndSections.length > 0) {
      let classObject = {
        classes: this.state.saveSelectedClassesAndSections,
      }
      myLog(classObject)
      this.setState(
        {
          isShowLoading: true,
        },
        () => {
          this.props.saveClassDetails(classObject, success => {
            this.setState({
              isShowLoading: false,
            })
            myLog("success", success)
            if (success) {
              this.cancelSubmit()
              this.setState({
                savedClasses: false,
                saveSelectedClassesAndSections: [],
              })
            }
          })
        }
      )
    } else {
      this.setState({ hasError: true })
      myLog("error", this.state.className)
    }
  }

  saveClassGroup = () => {
    this.setState({ groupAdding: true })
    let { editingGroupId, newGroupName, newGroupClasses } = this.state
    newGroupClasses = newGroupClasses.map(val => ({
      sectionId: val.id || val.sectionId,
    }))
    let data = {
      groupName: newGroupName.trim(),
      classList: newGroupClasses,
    }
    if (editingGroupId) data.groupId = editingGroupId
    this.props.saveClassGroup(data, success => {
      myLog("saveClassGroup success", success)
      this.setState({
        createGroupOpen: false,
        groupAdding: false,
        editingGroupId: null,
        newGroupName: "",
        newGroupClasses: [],
      })
    })
  }

  render() {
    return (
      <>
        <h3 className="text-brand font-weight-bold mb-5 text-align-right">
          Create Class Group
        </h3>

        <div className="row mb-auto" style={{ minHeight: "60%" }}>
          <div
            className="col-md-5 d-flex flex-column pt-3"
            style={{ borderRight: "2px solid #eaeaea" }}
          >
            <FormGroup>
              <Label
                className="text-brand font-weight-bold"
                htmlFor="groupName"
              >
                Group Name
              </Label>
              <Input
                type="text"
                name="groupName"
                id="groupName"
                value={this.state.newGroupName}
                onChange={e => this.setState({ newGroupName: e.target.value })}
                placeholder="Eg. Primary"
              />
            </FormGroup>

            <FormGroup>
              <Label className="text-brand font-weight-bold">Classes</Label>
              <Select
                placeholder="Select Class"
                isClearable
                value={this.state.newGroupClasses}
                options={this.props.classesList.filter(cl => {
                  let filterme = true
                  for (const cg of this.props.classGroupsList) {
                    for (const cls of cg.classes) {
                      if (cls.sectionId === cl.id) {
                        filterme = false
                        break
                      }
                    }
                    if (!filterme) break
                  }
                  return filterme
                })}
                className="basic-multi-select mb-3"
                isMulti
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                components={{ Option, MultiValue }}
                classNamePrefix="select"
                onChange={selectedOption => {
                  if (selectedOption !== null) {
                    this.setState({
                      newGroupClasses: selectedOption,
                    })
                  } else {
                    this.setState({
                      newGroupClasses: selectedOption,
                    })
                  }
                }}
              />
            </FormGroup>

            <button
              type="button"
              style={{ borderRadius: 12 }}
              className="btn bg-brand text-light px-5 mx-auto rounded-lg"
              onClick={() => this.saveClassGroup()}
            >
              {this.state.groupAdding ? (
                <HashLoader
                  sizeUnit={"px"}
                  size={16}
                  color={"#ffffff"}
                  loading={this.state.groupAdding}
                />
              ) : (
                <span>Add</span>
              )}
            </button>
          </div>
          <div className="col-sm-7 pt-3">
            <ul className="list-group">
              {this.props.classGroupsList &&
                this.props.classGroupsList.map((item, index) => {
                  return (
                    <li
                      className="list-group-item d-flex justify-content-between align-items-center"
                      style={{ backgroundColor: "transparent", border: 0 }}
                    >
                      {item.groupName}
                      <div>
                        <button
                          type="button"
                          onClick={() => {
                            this.props.deleteClassGroup(
                              { groupId: item.groupId },
                              () => {}
                            )
                          }}
                        >
                          <i className="fas fa-minus-circle text-danger"></i>
                        </button>
                      </div>
                    </li>
                  )
                })}
            </ul>
          </div>
        </div>

        <div className="d-flex align-items-center">
          <button
            onClick={() => {
              this.props.onPrevious()
            }}
            className="btn text-light d-flex align-items-centers"
            style={{ backgroundColor: "#04B08F", borderRadius: 12 }}
            disabled={this.state.isShowLoading || this.state.isNextStepLoading}
          >
            <div className="d-flex align-items-center">
              <i className="fas fa-chevron-left" />
              <span className="ml-2">Previous</span>
            </div>
          </button>

          <button
            onClick={() => {
              if (
                this.props.classGroupsList &&
                this.props.classGroupsList.length > 0
              )
                this.props.onNext()
              else
                this.props.showNotification(
                  DANGER_TOAST,
                  VALIDATION_MSG.OOPS,
                  "Add class group..."
                )
            }}
            className="btn text-light d-flex align-items-center ml-auto"
            style={{ backgroundColor: "#04B08F", borderRadius: 12 }}
            disabled={this.state.isShowLoading || this.state.isNextStepLoading}
          >
            <>
              <span className="mr-2">
                {this.props.isLast ? "Save & Proceed" : "Next Step"}
              </span>
              <i className="fas fa-chevron-right" />
            </>
          </button>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    subjectList: state.subjectDetailsState.subjectList,
    classesList: state.classDetailsState.classList,
    classGroupsList: state.classDetailsState.classGroupsList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getClassDetails,
      saveClassDetails,
      deleteClassDetails,
      updateClassDetails,
      getClassGroup,
      saveClassGroup,
      deleteClassGroup,
      updateClassGroup,
      showNotification,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(ClassGroupScreen)
