/*************************************************
 * RedInk
 * @exports
 * @class MarkScreen.js
 * @extends Component
 * @author Sasidharan // on 15/03/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { CustomInput, Button } from "reactstrap"
import { bindActionCreators } from "redux"
import { HashLoader } from "react-spinners"
import readXlsxFile from "read-excel-file"
import { connect } from "react-redux"
import Skeleton from "react-loading-skeleton"
import fileSaver from "file-saver"
import pdfMake from "pdfmake"
import store from "store"

import MessageModal from "../MessageModal"
import CommentBox from "./CommentBox"
import AppWrapper from "../Wrapper"

import {
  saveStudentSubjectGenerateAnalyticsDetail,
  saveStudentSubjectMarkUploadLockDetail,
  saveCheckIsGenerateAnalyticsDetail,
  saveStudentSubjectMarkObject,
  saveMarkStudentListInStore,
  saveStudentSubjectReMark,
  getMarkSubjectDetails,
  getMarkStudentList,
  getXlsheetTemplate,
  getXlsheetReport,
} from "../../../actions/teacher/MarkScreenAction"
import { VALIDATION_MSG, DANGER_TOAST } from "../../../utils/Constants"
import { confirmAlertPopUp } from "../../../utils/Utility"
import { showNotification } from "../../../actions/CommonAction"
import { URL_PATH } from "../../../config/urlPath"

const fonts = {
  Roboto: {
    normal:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
    bold:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
    italics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
    bolditalics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
  },
}

class MarkScreen extends Component {
  static getDerivedStateFromProps(props, state) {
    return {
      teacherName: props.markSubjectDetails
        ? props.markSubjectDetails.teacherName
        : "",
      date: props.markSubjectDetails ? props.markSubjectDetails.date : "",
      maxMark: props.markSubjectDetails
        ? props.markSubjectDetails.maxMark !== null
          ? props.markSubjectDetails.maxMark
          : "0"
        : "",
      minMark: props.markSubjectDetails
        ? props.markSubjectDetails.minMark !== null
          ? props.markSubjectDetails.minMark
          : ""
        : "",
      hasActivity: props.markSubjectDetails
        ? props.markSubjectDetails.hasActivity !== null
          ? props.markSubjectDetails.hasActivity
          : ""
        : "",
      passMark: props.markSubjectDetails
        ? props.markSubjectDetails.passMark !== null
          ? props.markSubjectDetails.passMark
          : "0"
        : "",
      distinctionMark: props.markSubjectDetails
        ? props.markSubjectDetails.distinctionMark !== null
          ? props.markSubjectDetails.distinctionMark
          : "0"
        : "",
      convertMark: props.markSubjectDetails
        ? props.markSubjectDetails.convertMark !== null
          ? props.markSubjectDetails.convertMark
          : "0"
        : "",
      allowQuickAnalytics: state.allowQuickAnalytics,
      theoryMark: props.markSubjectDetails
        ? props.markSubjectDetails.theoryMark !== null
          ? props.markSubjectDetails.theoryMark
          : ""
        : "",
      theoryConvertMarkTo: props.markSubjectDetails
        ? props.markSubjectDetails.theoryConvertMarkTo !== null
          ? props.markSubjectDetails.theoryConvertMarkTo
          : ""
        : "",
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedEnterAs: "MARK",
      selectedViewAs: [{ value: "MARK", label: "MARK" }],
      hierarchySubjectDetails: this.props.location.state
        .hierarchySubjectDetails,
      isShowGrade: false,
      isShowMark: true,
      isShowConvertedMark: false,
      isShowPercentage: false,
      allowQuickAnalytics: false,
      isShowLoading: false,
      isLocked: false,
      isCompleted: false,
      isShowGenerateAnalyticsLoading: false,
      isGeneratedAnalytics: false,
      isFromActivity: this.props.location.state.isFromActivity,
      invalidMarkIndex: -1,
    }
  }

  componentDidMount() {
    this.props.getMarkSubjectDetails(
      this.state.hierarchySubjectDetails,
      () => {}
    )
    this.getMarkList()
  }

  getMarkList = () => {
    this.setState({ isLoading: true }, () => {
      this.props.getMarkStudentList(
        this.state.hierarchySubjectDetails,
        (success, response) => {
          if (success) {
            this.setState({
              isLoading: false,
              allowQuickAnalytics:
                response.completed && response.isLocked ? true : false,
              isLocked: response.isLocked || false,
              isCompleted: response.completed,
              isGeneratedAnalytics: response.isGenerated,
              columnNames: response.headers,
            })
          }
        }
      )
    })
  }

  componentWillUnmount() {
    this.props.saveMarkStudentListInStore([])
  }

  displayConditon = selectedOption => {
    if (selectedOption && selectedOption.length > 0) {
      let alreadySelectedGradeIndex = selectedOption.findIndex(item => {
        return item.value === "GRADE"
      })
      if (alreadySelectedGradeIndex !== -1) {
        this.setState({ isShowGrade: true })
      } else {
        this.setState({ isShowGrade: false })
      }
      let alreadySelectedMarkIndex = selectedOption.findIndex(item => {
        return item.value === "MARK"
      })
      if (alreadySelectedMarkIndex !== -1) {
        this.setState({ isShowMark: true })
      } else {
        this.setState({ isShowMark: false })
      }
      let alreadySelectedPercentageIndex = selectedOption.findIndex(item => {
        return item.value === "PERCENTAGE"
      })
      if (alreadySelectedPercentageIndex !== -1) {
        this.setState({ isShowPercentage: true })
      } else {
        this.setState({ isShowPercentage: false })
      }
      let alreadySelectedConvertedMarkIndex = selectedOption.findIndex(item => {
        return item.value === "CONVERTED MARKS"
      })
      if (alreadySelectedConvertedMarkIndex !== -1) {
        this.setState({ isShowConvertedMark: true })
      } else {
        this.setState({ isShowConvertedMark: false })
      }
    } else {
      this.setState({
        isShowGrade: false,
        isShowMark: false,
        isShowConvertedMark: false,
        isShowPercentage: false,
      })
    }
  }

  saveStudentSubjectMark = () => {
    // let numberRegex = /^[a-zA-Z0-9]+$/
    // let hasError = false
    // for (let i = 0; i < this.props.markStudentList.length; i++) {
    //   let item = this.props.markStudentList[i]
    //   for (let j = 0; j < item.row.length; j++) {
    //     if (j === 2) {
    //       let mark = item.row[j]
    //       if (mark !== "AB" && mark !== "ab") {
    //         if (
    //           !numberRegex.test(mark) ||
    //           !(mark <= parseInt(this.state.maxMark))
    //         ) {
    //           this.setState({ invalidMarkIndex: i })
    //           hasError = true
    //           break
    //         } else {
    //           this.setState({ invalidMarkIndex: -1 })
    //           hasError = false
    //         }
    //       } else {
    //         this.setState({ invalidMarkIndex: -1 })
    //         hasError = false
    //       }
    //     }
    //   }
    //   if (hasError) {
    //     break
    //   }
    // }

    let object = {
      subject: this.state.hierarchySubjectDetails,
      sectionId: this.state.hierarchySubjectDetails.classId,
      hSubjectId: this.state.hierarchySubjectDetails.subjectId,
      assessmentId: this.state.hierarchySubjectDetails.faId,
      markStudentList: this.props.markStudentList,
      activityId: this.state.hierarchySubjectDetails.activityId,
      theoryId: this.state.hierarchySubjectDetails.theoryId,
      practicalId: this.state.hierarchySubjectDetails.practicalId,
      key: this.state.hierarchySubjectDetails.key,
    }
    this.setState({ isShowLoading: true }, () => {
      if (
        this.state.hierarchySubjectDetails.key === "S" &&
        this.state.hierarchySubjectDetails.examType !== "0"
      ) {
        this.props.saveStudentSubjectReMark(object, (success, response) => {
          this.setState({ isShowLoading: false })
          if (success) {
            this.getMarkList()
          }
        })
      } else {
        this.props.saveStudentSubjectMarkObject(object, (success, response) => {
          this.setState({ isShowLoading: false })
          if (success) {
            this.getMarkList()
          }
        })
      }
    })
  }

  closeMessageModal = () => this.setState({ isMessageModalOpen: false })

  onClickMessageIcon = () => {
    this.setState({ isMessageModalOpen: !this.state.isMessageModalOpen })
  }

  onSubmitMessage = () => {
    this.closeMessageModal()
  }

  // getXlsheet = () => {
  //   this.props.getXlsheet((response)=>{
  //     var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //     fileSaver.saveAs(blob, 'student.xlsx');
  //   })
  // }

  isNumber = n => /^-?[\d.]+(?:e-?\d+)?$/.test(n)

  handleMarkEntry = (event, index, rowIndex) => {
    let studentArray = this.props.markStudentList
    studentArray[index].row[rowIndex] = event.target.value
    this.props.saveMarkStudentListInStore(studentArray)

    let isNum = this.isNumber(event.target.value)
    let invalidMarkIndex = -1

    if (isNum && Number(event.target.value) > Number(this.state.maxMark)) {
      invalidMarkIndex = index
    }

    if (!isNum && event.target.value !== "ab" && event.target.value !== "AB") {
      invalidMarkIndex = index
    }

    this.setState({ invalidMarkIndex })
  }

  downloadXlSheetTemplate = () => {
    const { hierarchySubjectDetails } = this.state
    this.props.getXlsheetTemplate(
      hierarchySubjectDetails.subjectId,
      response => {
        var blob = new Blob([response], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
        fileSaver.saveAs(blob, "studentRemarksTemplate.xlsx")
      }
    )
  }

  convertToRequestArray = rows => {
    delete rows[0]
    if (Array.isArray(rows)) {
      const requestKeys = ["roll_no", "student_name", "mark", "remark"]
      let marksArray = []
      rows.forEach(row => {
        let tempObj = {}
        row.forEach((data, i) => {
          tempObj[requestKeys[i]] = data
        })
        marksArray.push(tempObj)
      })
      let studentArray = this.props.markStudentList
      for (let i = 0; i < marksArray.length; i++) {
        for (let j = 0; j < studentArray.length; j++) {
          let found = marksArray[i].roll_no === studentArray[j].row[0]
          if (found) {
            studentArray[j].row[1] = marksArray[i].student_name
            studentArray[j].row[2] = marksArray[i].mark
            studentArray[j].row[3] = marksArray[i].remark
          }
        }
      }
      this.props.saveMarkStudentListInStore(studentArray)
    }
  }

  handleDrop = async files => {
    readXlsxFile(files[0])
      .then(rows => {
        this.convertToRequestArray(rows)
      })
      .catch(() => {
        this.setState({
          excelError: true,
          excelFileName: "",
          excelTitleData: [],
          uploadedExcelFile: {},
        })
      })
  }

  downloadXlSheetReport = () => {
    const { hierarchySubjectDetails } = this.state
    this.props.getXlsheetReport(hierarchySubjectDetails.subjectId, response => {
      var blob = new Blob([response], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
      fileSaver.saveAs(blob, "studentRemarksReport.xlsx")
    })
  }

  downloadAsPdf = () => {
    const { teacherName, date } = this.state
    const {
      markSubjectDetails: { convertMark, maxMark },
    } = this.props
    const {
      subjectName,
      className,
      academicName,
      termName,
      faName,
      theoryOrPracticalName,
      activityName,
    } = this.props.location.state
    const schoolName = store.get("quickInfo").schoolShortName

    let location = [`${academicName}`]
    const addArrow = () =>
      location.push({
        image: "caret-right",
        width: 10,
        height: 10,
        margin: [2, 2, 2, 2],
      })
    if (termName) {
      addArrow()
      location.push(`${termName}`)
    }
    if (faName) {
      addArrow()
      location.push(`${faName}`)
    }
    if (subjectName) {
      addArrow()
      location.push(`${subjectName}`)
    }
    if (theoryOrPracticalName) {
      addArrow()
      location.push(`${theoryOrPracticalName}`)
    }
    if (activityName) {
      addArrow()
      location.push(`${activityName}`)
    }

    const finalMark = convertMark || maxMark

    const head = [
      { text: "Roll no", style: ["cell", "center"] },
      { text: "Student Name", style: "cell" },
      { text: `Total Mark (${finalMark})`, style: ["cell", "center"] },
    ]
    const body = this.props.markStudentList.map(item => [
      { text: item.row[0], style: ["cell", "center"] },
      { text: item.row[1], style: "cell" },
      { text: item.row[3], style: ["cell", "center"] },
    ])
    const title = `${className} ${subjectName}`

    const docDefinition = {
      content: [
        {
          table: {
            headerRows: 0,
            widths: location.map(item =>
              typeof item === "string" ? "auto" : 10
            ),
            body: [location],
          },
          layout: "noBorders",
        },
        {
          columns: [
            { text: title, style: "header" },
            { text: schoolName, style: "header", alignment: "right" },
          ],
        },
        {
          columns: [
            { text: date || "21 Aug 2020", style: "subheader" },
            {
              text: `No. of Students: ${body.length}`,
              style: "subheader",
              alignment: "right",
            },
          ],
        },
        {
          style: "table",
          table: {
            headerRows: 1,
            widths: [90, "*", 180],
            body: [head, ...body],
          },
          layout: {
            hLineWidth: function(i, node) {
              return 1
            },
            vLineWidth: function(i, node) {
              return 1
            },
            hLineColor: function(i, node) {
              return "#bcbcbc"
            },
            vLineColor: function(i, node) {
              return "#bcbcbc"
            },
          },
        },
        {
          table: {
            headerRows: 0,
            widths: ["*"],
            body: [
              [
                {
                  columns: [
                    {
                      text: "Ab - Absent",
                      style: "bottomText",
                      alignment: "left",
                    },
                    [
                      {
                        text: "Signature",
                        style: "bottomText",
                        margin: [0, 8, 0, 28],
                      },
                      {
                        text: teacherName || "Hailey Banner",
                        style: "bottomText",
                      },
                    ],
                  ],
                  style: "cell",
                },
              ],
              [
                {
                  image: "redink-logo",
                  height: 20,
                  width: 70,
                  alignment: "center",
                },
              ],
            ],
          },
          layout: {
            hLineWidth: function(i, node) {
              return i === 0 || i === 2 ? 1 : 0
            },
            vLineWidth: function(i, node) {
              return 1
            },
            hLineColor: function(i, node) {
              return "#bcbcbc"
            },
            vLineColor: function(i, node) {
              return "#bcbcbc"
            },
          },
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 12, 0, 12],
        },
        subheader: {
          fontSize: 12,
          color: "#5e5e5e",
          margin: [0, 0, 0, 10],
        },
        table: {
          margin: [0, 5, 0, -1],
        },
        cell: {
          margin: [12, 6, 12, 6],
        },
        center: {
          alignment: "center",
        },
        bottomText: {
          alignment: "center",
          margin: [0, 8, 0, 8],
        },
      },
      images: {
        "caret-right": require("../../../assets/images/caret-right_base64")
          .default,
        "redink-logo": require("../../../assets/images/redink_logo_base64")
          .default,
      },
    }

    pdfMake.createPdf(docDefinition, null, fonts).getBase64(async data => {
      const blob = await fetch(
        `data:application/pdf;base64,${data}`
      ).then(res => res.blob())
      const browserUrl = window.webkitURL || window.URL
      const pdfURL = browserUrl.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = pdfURL
      a.download = `${title} Exam Marks.pdf`
      a.click()
      browserUrl.revokeObjectURL(pdfURL)
    })
  }

  saveMarks = () => {
    if (!this.state.isLocked) {
      if (this.state.invalidMarkIndex === -1) {
        this.saveStudentSubjectMark()
      } else {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Invalid mark entry"
        )
      }
    } else
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.OOPS,
        "Mark upload locked"
      )
  }

  render() {
    const arr = this.props.markStudentList.map(item => item.row[3])

    return (
      <AppWrapper
        openMessageModal={this.onClickMessageIcon}
        showTeacherThree
        showTeacherLogo
        sideMenuCollapsedBackExam
        screenName="Subject Mark Entry Screen"
      >
        <MessageModal
          isModalOpen={this.state.isMessageModalOpen}
          closeModal={this.onClickMessageIcon}
          onSubmitMessage={this.onSubmitMessage}
        />

        <div className="d-flex flex-column gr-inner-mark-wrapper">
          <div className="d-none d-sm-block mb-2">
            <div className="d-flex row">
              {this.props.location.state.academicName}
              <i className="px-2 py-1 fas fa-caret-right" />
              {this.props.location.state.termName}
              {this.props.location.state.faName && (
                <div>
                  <i className="px-2 py-1 fas fa-caret-right" />
                  {this.props.location.state.faName}
                </div>
              )}
              {this.props.location.state.subjectName && (
                <div>
                  <i className="px-2 py-1 fas fa-caret-right" />
                  {this.props.location.state.subjectName}
                </div>
              )}
              {this.props.location.state.theoryOrPracticalName && (
                <div>
                  <i className="px-2 py-1 fas fa-caret-right" />
                  {this.props.location.state.theoryOrPracticalName}
                </div>
              )}
              {this.props.location.state.activityName && (
                <div>
                  <i className="px-2 py-1 fas fa-caret-right" />
                  {this.props.location.state.activityName}
                </div>
              )}
            </div>
          </div>

          <div
            className="p-4 row bg-theme-light text-light"
            style={{ borderRadius: 20 }}
          >
            <div className="col-sm-3">
              <h4 className="mb-3 text-white font-weight-bold">
                {this.props.location.state.className}{" "}
                {this.props.location.state.subjectName}
              </h4>
              <div className="d-none d-sm-block mt-3 font-weight-bold text-muted">
                Teacher name: {this.state.teacherName || "John Jacobs"}
              </div>
              <div className="d-none d-sm-block mt-3 font-weight-bold text-muted">
                Date: {this.state.date || "21st August 2020"}
              </div>
              {/* <div className="mx-3 border-right" />
                 <div className="col-sm"> */}
              {/* <div className="row">ENTER AS</div>
                   <Select
                     placeholder=""
                     name="classes"
                     isClearable
                     isMulti
                     // value={this.state.selectedEnterAs}
                     options={[
                       { value: "MARK", label: "MARK" },
                       { value: "GRADE", label: "GRADE" },
                     ]}
                     className="my-3 basic-multi-select text-dark"
                     classNamePrefix="select"
                     onChange={selectedOption => {
                       this.displayConditon(selectedOption)
                     }}
                   /> */}
              {/* <div className="row">VIEW AS</div>
                   <Select
                     placeholder=""
                     name="viewAs"
                     isMulti
                     isClearable
                     value={this.state.selectedViewAs}
                     options={[
                       { value: "MARK", label: "MARK" },
                       { value: "GRADE", label: "GRADE" },
                       { value: "CONVERTED MARKS", label: "CONVERTED MARKS" },
                       { value: "PERCENTAGE", label: "PERCENTAGE" },
                     ]}
                     className="mt-3 basic-multi-select text-dark"
                     classNamePrefix="select"
                     onChange={selectedOption1 => {
                       this.setState({selectedViewAs: selectedOption1})
                       this.displayConditon(selectedOption1)
                     }}
                   /> 
                 </div>*/}
            </div>

            <div className="col-sm-3 border-right">
              <div className="d-flex row">
                <div
                  className="mr-3 d-flex flex-column align-items-center"
                  style={{ flex: 1 }}
                >
                  <h5 className="mb-2 text-bold clr-BEBFBF">
                    {this.state.maxMark}
                  </h5>
                  <p className="text-bold clr-BEBFBF">Max Marks</p>
                </div>

                <div
                  className="mr-3 d-flex flex-column align-items-center"
                  style={{ flex: 1 }}
                >
                  <h5 className="mb-2 text-bold clr-BEBFBF">
                    {this.state.passMark}
                  </h5>
                  <p className="text-bold clr-BEBFBF">Pass Marks</p>
                </div>
              </div>

              <div className="d-flex row">
                <div
                  className="mr-3 d-flex flex-column align-items-center"
                  style={{ flex: 1 }}
                >
                  <h5 className="mb-2 text-bold clr-BEBFBF">
                    {this.state.convertMark}
                  </h5>
                  <p className="text-bold clr-BEBFBF">Convert Mark</p>
                </div>

                <div
                  className="mr-3 d-flex flex-column align-items-center"
                  style={{ flex: 1 }}
                >
                  <h5 className="mb-2 text-bold clr-BEBFBF">
                    {this.state.distinctionMark}
                  </h5>
                  <p className="text-bold clr-BEBFBF">Distinction</p>
                </div>
              </div>
              {/* <div>
                 <div>
                   <span className="pr-5">MAX MARK</span>
                   <input
                     disabled
                     type="text"
                     className="form-control"
                     value={this.state.maxMark}
                     onChange={e => this.setState({ maxMark: e.target.value })}
                   />
                 </div>
               </div>
             </div>
             <div className="col-sm">
               <div>
                 <span className="pr-5">PASS MARK</span>
                 <input
                   disabled
                   type="text"
                   className="form-control"
                   value={this.state.passMark}
                   onChange={e => this.setState({ passMark: e.target.value })}
                 />
               </div>
               <div className="mt-2">
                 <div>
                   DISTINCTION
                   <input
                     disabled
                     type="text"
                     className="form-control"
                     value={this.state.distinctionMark}
                     onChange={e =>
                       this.setState({ distinctionMark: e.target.value })
                     }
                   />
                 </div>
               </div> */}
            </div>

            <div className="col-sm-3">
              {/* <FormGroup className="mr-3">
                 <Label htmlFor="viewSelect" className="text-white-50">
                   VIEW
                 </Label>
                 <Input
                   type="select"
                   name="select"
                   id="viewSelect"
                   value={this.state.selectedViewAs}
                   onChange={e => {
                     const selectedOption1 = e.target.value
                     this.setState({ selectedViewAs: selectedOption1 })
                     this.displayConditon(selectedOption1)
                   }}
                 >
                   <option value="MARK">MARK</option>
                   <option value="GRADE">GRADE</option>
                   <option value="CONVERTED MARKS">CONVERTED MARKS</option>
                   <option value="PERCENTAGE">PERCENTAGE</option>
                 </Input>
               </FormGroup> */}
            </div>

            <div className="col-sm-3">
              <div className="d-flex flex-column">
                {/* <Button
                   style={{ backgroundColor: "343A40" }}
                   className="mb-2"
                   onClick={() => {
                     this.downloadXlSheetTemplate()
                   }}
                 >
                   Download Template
                 </Button>
                 <Button className="mb-2" style={{ backgroundColor: "343A40" }}>
                   Upload marks
                   <input
                     type="file"
                     className="cursor-pointer"
                     onChange={event => this.handleDrop(event.target.files)}
                     accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                     style={{
                       opacity: 0.0,
                       position: "absolute",
                       top: 0,
                       left: 0,
                       bottom: 0,
                       right: 0,
                       width: "100%",
                       height: "100%",
                     }}
                   />
                 </Button> */}
                {arr.every(item => item !== "") && this.state.isLocked && (
                  <Button
                    style={{ backgroundColor: "343A40" }}
                    className="mb-2"
                    onClick={this.downloadAsPdf}
                  >
                    Download As Pdf
                  </Button>
                )}
                {/* {!this.state.isLocked && (
                   <Button
                     style={{ backgroundColor: "343A40" }}
                     className="mb-2"
                     onClick={() => {
                       // this.downloadXlSheetReport()
                       this.downloadAsPdf()
                     }}
                   >
                     Download As Pdf
                   </Button>
                 )} */}
              </div>
            </div>
          </div>

          {this.state.isLoading ? (
            <div className="mb-4">
              <Skeleton count={10} height={30} width="100%" />
            </div>
          ) : (
            <div
              className="p-0 mt-4 row table-responsive col-sm-12"
              style={{ flex: 1, overflowY: "scroll", margin: "0px auto" }}
            >
              <table className="table table-bordered mark-entry-table">
                <thead>
                  <tr>
                    {this.state.columnNames &&
                      this.state.columnNames.map((item, index) => {
                        if (index === 2) {
                          return (
                            <th key={index} width="150" scope="col">
                              {item}
                            </th>
                          )
                        }

                        return (
                          <th key={index} scope="col">
                            {item}
                          </th>
                        )
                      })}
                  </tr>
                </thead>
                <tbody>
                  {this.props.markStudentList &&
                    this.props.markStudentList.map((item, index) => (
                      <tr key={item.id}>
                        {item.row &&
                          item.row.map((rowItem, rowIndex) => (
                            <td key={rowIndex}>
                              {this.state.hierarchySubjectDetails &&
                              this.state.hierarchySubjectDetails.key === "P" &&
                              this.state.hasActivity === 1 ? (
                                <p>
                                  {rowIndex > 1 ? Math.round(rowItem) : rowItem}
                                </p>
                              ) : this.state.hierarchySubjectDetails &&
                                this.state.hierarchySubjectDetails.key ===
                                  "S" &&
                                (this.state.hierarchySubjectDetails.examType ===
                                  "1" ||
                                  this.state.hierarchySubjectDetails
                                    .examType === "2") ? (
                                item.row.length - 1 === rowIndex ? (
                                  <div className="position-relative">
                                    <CommentBox
                                      i={index}
                                      gender={item.gender}
                                      stuName={item.row[1]}
                                      disabled={
                                        this.state.isLocked ||
                                        rowIndex === 0 ||
                                        rowIndex === 1
                                          ? true
                                          : this.state.isGeneratedAnalytics
                                          ? true
                                          : false
                                      }
                                      onChange={value => {
                                        if (item.row.length - 1 !== rowIndex) {
                                          this.handleMarkEntry(
                                            { target: { value } },
                                            index,
                                            rowIndex
                                          )
                                        } else {
                                          let studentArray = this.props
                                            .markStudentList
                                          studentArray[index].row[
                                            rowIndex
                                          ] = value
                                          this.props.saveMarkStudentListInStore(
                                            studentArray
                                          )
                                        }
                                      }}
                                    />

                                    <input
                                      disabled={
                                        this.state.isLocked ||
                                        rowIndex === 0 ||
                                        rowIndex === 1
                                          ? true
                                          : this.state.isGeneratedAnalytics
                                          ? true
                                          : false
                                      }
                                      type="text"
                                      className="form-control pl-5"
                                      value={rowItem}
                                      onChange={e => {
                                        if (item.row.length - 1 !== rowIndex) {
                                          this.handleMarkEntry(
                                            e,
                                            index,
                                            rowIndex
                                          )
                                        } else {
                                          let studentArray = this.props
                                            .markStudentList
                                          studentArray[index].row[rowIndex] =
                                            e.target.value
                                          this.props.saveMarkStudentListInStore(
                                            studentArray
                                          )
                                        }
                                      }}
                                    />
                                    {rowIndex === 2 &&
                                      this.state.invalidMarkIndex === index && (
                                        <small className="text-danger">
                                          Only AB/ab and mark not exceed maximum
                                          mark are allowed.
                                        </small>
                                      )}
                                  </div>
                                ) : (
                                  <p>
                                    {rowIndex > 1
                                      ? Math.round(rowItem)
                                      : rowItem}
                                  </p>
                                )
                              ) : rowIndex === 2 ||
                                item.row.length - 1 === rowIndex ? (
                                <div className="position-relative">
                                  {item.row.length - 1 === rowIndex && (
                                    <CommentBox
                                      i={index}
                                      gender={item.gender}
                                      stuName={item.row[1]}
                                      disabled={
                                        this.state.isLocked ||
                                        rowIndex === 0 ||
                                        rowIndex === 1
                                          ? true
                                          : this.state.isGeneratedAnalytics
                                          ? true
                                          : false
                                      }
                                      onChange={value => {
                                        if (item.row.length - 1 !== rowIndex) {
                                          this.handleMarkEntry(
                                            { target: { value } },
                                            index,
                                            rowIndex
                                          )
                                        } else {
                                          let studentArray = this.props
                                            .markStudentList
                                          studentArray[index].row[
                                            rowIndex
                                          ] = value
                                          this.props.saveMarkStudentListInStore(
                                            studentArray
                                          )
                                        }
                                      }}
                                    />
                                  )}

                                  <input
                                    disabled={
                                      this.state.isLocked ||
                                      rowIndex === 0 ||
                                      rowIndex === 1
                                        ? true
                                        : this.state.isGeneratedAnalytics
                                        ? true
                                        : false
                                    }
                                    type="text"
                                    className={`form-control ${
                                      item.row.length - 1 === rowIndex
                                        ? "pl-5"
                                        : ""
                                    }`}
                                    id={`id-in-${index}`}
                                    value={rowItem}
                                    onKeyDown={e => {
                                      if (e.key === "Enter" && e.target.value) {
                                        if (
                                          this.state.invalidMarkIndex !== index
                                        ) {
                                          const nextEl = document.getElementById(
                                            `id-in-${index + 1}`
                                          )
                                          if (nextEl) {
                                            nextEl.focus()
                                          } else {
                                            this.saveMarks()
                                          }
                                        }
                                      }
                                    }}
                                    onChange={e => {
                                      if (item.row.length - 1 !== rowIndex) {
                                        this.handleMarkEntry(e, index, rowIndex)
                                      } else {
                                        let studentArray = this.props
                                          .markStudentList
                                        studentArray[index].row[rowIndex] =
                                          e.target.value
                                        this.props.saveMarkStudentListInStore(
                                          studentArray
                                        )
                                      }
                                    }}
                                  />
                                  {rowIndex === 2 &&
                                    this.state.invalidMarkIndex === index && (
                                      <small className="text-danger">
                                        Only AB/ab and mark not exceed maximum
                                        mark are allowed.
                                      </small>
                                    )}
                                </div>
                              ) : (
                                <p className="mt-2 mb-0">
                                  {rowIndex > 1 ? Math.round(rowItem) : rowItem}
                                </p>
                              )}
                            </td>
                          ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
          <div className="mb-3 row justify-content-center">
            {this.state.hierarchySubjectDetails &&
            this.state.hierarchySubjectDetails.key === "P" ? (
              this.state.hasActivity === 0 ? (
                <Button
                  className="mr-3"
                  style={{
                    paddingRight: 24,
                    paddingLeft: 24,
                    backgroundColor: "#06B191",
                  }}
                  disabled={this.state.isShowLoading}
                  onClick={this.saveMarks}
                >
                  {this.state.isShowLoading ? (
                    <HashLoader
                      sizeUnit="px"
                      size={16}
                      color="#fff"
                      loading={this.state.isShowLoading}
                    />
                  ) : (
                    "Save"
                  )}
                </Button>
              ) : null
            ) : (
              <Button
                className="mr-3"
                style={{
                  paddingRight: 24,
                  paddingLeft: 24,
                  backgroundColor: this.state.isLocked ? "#333" : "#06B191",
                }}
                disabled={this.state.isShowLoading || this.state.isLocked}
                onClick={this.saveMarks}
              >
                {this.state.isShowLoading ? (
                  <HashLoader
                    size={16}
                    color="#fff"
                    sizeUnit="px"
                    loading={this.state.isShowLoading}
                  />
                ) : (
                  "Save"
                )}
              </Button>
            )}
            <div className="mx-3 d-flex align-items-center">
              <i
                className="fas fa-lock-open"
                style={{
                  fontSize: "1.75em",
                  color: this.state.isLocked ? "#C3CBC3" : "#06B191",
                }}
              ></i>
              <CustomInput
                className="ml-3 mr-2"
                type="switch"
                id="exampleCustomSwitch"
                name="customSwitch"
                bsSize="lg"
                checked={this.state.isLocked}
                onChange={() => {
                  if (this.state.isLocked) {
                    let object = {
                      isLocked: !this.state.isLocked,
                      subjectId: this.state.hierarchySubjectDetails.subjectId,
                      activityId: this.state.hierarchySubjectDetails.activityId,
                      key: this.state.hierarchySubjectDetails.key,
                      detail: this.state.hierarchySubjectDetails,
                    }
                    this.props.saveStudentSubjectMarkUploadLockDetail(
                      object,
                      (success, response) => {
                        if (success) {
                          this.getMarkList()
                        } else {
                          this.setState({
                            allowQuickAnalytics: this.state.allowQuickAnalytics,
                            isLocked: this.state.isLocked,
                          })
                        }
                      }
                    )
                  } else {
                    if (this.state.isCompleted) {
                      confirmAlertPopUp(
                        VALIDATION_MSG.ALERT,
                        VALIDATION_MSG.MARK_UPLOAD_LOCK,
                        yes => {
                          if (yes) {
                            // this.setState({ isLocked: !this.state.isLocked })
                            let object = {
                              isLocked: !this.state.isLocked,
                              subjectId: this.state.hierarchySubjectDetails
                                .subjectId,
                              activityId: this.state.hierarchySubjectDetails
                                .activityId,
                              key: this.state.hierarchySubjectDetails.key,
                              detail: this.state.hierarchySubjectDetails,
                            }
                            this.props.saveStudentSubjectMarkUploadLockDetail(
                              object,
                              (success, response) => {
                                if (success) {
                                  this.getMarkList()
                                } else {
                                  this.setState({
                                    allowQuickAnalytics: this.state
                                      .allowQuickAnalytics,
                                    isLocked: this.state.isLocked,
                                  })
                                }
                              }
                            )
                          }
                        },
                        {
                          customUI: ({ onClose }) => {
                            return (
                              <div
                                className="custom"
                                style={{
                                  backgroundColor: "#fff",
                                  borderBottomRightRadius: "12px",
                                  borderBottomLeftRadius: "12px",
                                }}
                              >
                                <div
                                  className="bg-brand text-light d-flex"
                                  style={{
                                    padding: "0.5rem 2rem",
                                    borderTopRightRadius: "12px",
                                    borderTopLeftRadius: "12px",
                                  }}
                                >
                                  <div className="mx-auto fs-18">Alert</div>
                                </div>

                                <div
                                  className="mt-3"
                                  style={{ padding: "0.5rem 1.5rem" }}
                                >
                                  <div className="text-muted fs-15">
                                    Are you sure, you want to lock?
                                  </div>
                                  <div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
                                    <button
                                      className="bg-brand text-light px-4 py-1 w-25 d-flex align-items-center justify-content-center yes-button"
                                      style={{ borderRadius: "5px" }}
                                      onClick={() => {
                                        let object = {
                                          isLocked: !this.state.isLocked,
                                          subjectId: this.state
                                            .hierarchySubjectDetails.subjectId,
                                          activityId: this.state
                                            .hierarchySubjectDetails.activityId,
                                          key: this.state
                                            .hierarchySubjectDetails.key,
                                          detail: this.state
                                            .hierarchySubjectDetails,
                                        }
                                        this.props.saveStudentSubjectMarkUploadLockDetail(
                                          object,
                                          (success, response) => {
                                            if (success) {
                                              this.getMarkList()
                                            } else {
                                              this.setState({
                                                allowQuickAnalytics: this.state
                                                  .allowQuickAnalytics,
                                                isLocked: this.state.isLocked,
                                              })
                                            }
                                          }
                                        )
                                        onClose()
                                      }}
                                    >
                                      Yes
                                    </button>
                                    <button
                                      className="bg-brand text-light px-4 py-1 ml-4 w-25 d-flex align-items-center justify-content-center"
                                      style={{ borderRadius: "5px" }}
                                      onClick={onClose}
                                    >
                                      No
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )
                          },
                        }
                      )
                    } else {
                      this.props.showNotification(
                        DANGER_TOAST,
                        VALIDATION_MSG.OOPS,
                        "Need to complete the mark upload"
                      )
                    }
                  }
                }}
              />
              <i
                className="fas fa-lock"
                style={{
                  fontSize: "1.75em",
                  color: this.state.isLocked ? "#06B191" : "#C3CBC3",
                }}
              ></i>
            </div>
            {this.state.hierarchySubjectDetails &&
            (this.state.hierarchySubjectDetails.key === "T" ||
              this.state.hierarchySubjectDetails.key === "P") ? null : (
              <div className="ml-3">
                {this.state.isFromActivity ? null : this.state
                    .allowQuickAnalytics ? (
                  <div>
                    <button
                      className="text-white shadow-sm btn"
                      style={{ backgroundColor: "#06B191", color: "#3D3D3D" }}
                      disabled={this.state.isShowGenerateAnalyticsLoading}
                      onClick={() => {
                        if (!this.state.isGeneratedAnalytics) {
                          this.setState(
                            { isShowGenerateAnalyticsLoading: true },
                            () => {
                              let object = {
                                sectionId: this.state.hierarchySubjectDetails
                                  .classId,
                                hSubjectId: this.state.hierarchySubjectDetails
                                  .subjectId,
                                assessmentId: this.state.hierarchySubjectDetails
                                  .faId,
                              }
                              this.props.saveStudentSubjectGenerateAnalyticsDetail(
                                object,
                                (success, response) => {
                                  this.setState({
                                    isShowGenerateAnalyticsLoading: false,
                                  })
                                  if (success) {
                                    this.props.saveCheckIsGenerateAnalyticsDetail(
                                      object,
                                      (success1, res2) => {
                                        if (
                                          success1 &&
                                          res2.isSubjectsGenerated &&
                                          !res2.isAssessmentGenerated
                                        ) {
                                          this.props.saveStudentSubjectGenerateAnalyticsDetail(
                                            { ...object, hSubjectId: "ALL" },
                                            () => {}
                                          )
                                        }
                                      }
                                    )
                                    this.props.history.push({
                                      pathname:
                                        URL_PATH.TEACHER_MARKS_ANALYTICS,
                                      state: {
                                        academicName: this.props.location.state
                                          .academicName,
                                        termName: this.props.location.state
                                          .termName,
                                        faName: this.props.location.state
                                          .faName,
                                        subjectName: this.props.location.state
                                          .subjectName,
                                        activityName: this.props.location.state
                                          .activityName,
                                        academicId: "",
                                        termId: "",
                                        faId: "",
                                        subjectId: "",
                                        classId: this.props.location.state
                                          .classId,
                                        className: this.props.location.state
                                          .className,
                                        hierarchySubjectDetails: this.state
                                          .hierarchySubjectDetails,
                                      },
                                    })
                                  }
                                }
                              )
                            }
                          )
                        } else {
                          this.props.history.push({
                            pathname: URL_PATH.TEACHER_MARKS_ANALYTICS,
                            state: {
                              academicName: this.props.location.state
                                .academicName,
                              termName: this.props.location.state.termName,
                              faName: this.props.location.state.faName,
                              subjectName: this.props.location.state
                                .subjectName,
                              activityName: this.props.location.state
                                .activityName,
                              academicId: "",
                              termId: "",
                              faId: "",
                              subjectId: "",
                              classId: this.props.location.state.classId,
                              className: this.props.location.state.className,
                              hierarchySubjectDetails: this.state
                                .hierarchySubjectDetails,
                            },
                          })
                        }
                      }}
                    >
                      {this.state.isShowGenerateAnalyticsLoading ? (
                        <HashLoader
                          sizeUnit="px"
                          loading={this.state.isShowGenerateAnalyticsLoading}
                          color="#fff"
                          size={16}
                        />
                      ) : (
                        "Generate Analytics"
                      )}
                    </button>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>

        {/* <div>
             <p className="text-muted">
               TEACHER NAME:{" "}
               <span className="text-white">
                 {this.state.teacherName}
               </span>
             </p>
             <p className="text-muted">
               DATE:{" "}
               <span className="text-white">{this.state.date}</span>
             </p>
           </div> */}
        {/* <div className="pr-0 mt-4 col-sm">
               <div className="p-3 mb-2 shadow justify-content-center card">
                 <div
                   className="mb-4 cursor-pointer border-bottom-primary card box-shadow-none text-dark"
                   id="UncontrolledTooltipAttendanceTemplate"
                   // href={attendanceTemplate}
                   download
                 >
                   <span
                     className="px-3 py-1"
                     onClick={() => {
                       this.downloadXlSheetTemplate()
                     }}
                   >
                     <i className="mr-2 fal fa-table"></i>
                     Download Template
                   </span>
                 </div>
                 <UncontrolledTooltip
                   placement="top"
                   className="bg-danger"
                   // modifiers={tooltipModifiers}
                   target="UncontrolledTooltipAttendanceTemplate"
                 >
                   Template to fill & upload the student marks
                 </UncontrolledTooltip>
                 <div className="mb-2 cursor-pointer border-bottom card box-shadow-none">
                   <span className="px-3 py-1">
                     <i className="mr-2 fal fa-file-upload"></i>
                     Upload Marks
                     <input
                       type="file"
                       className="cursor-pointer"
                       onChange={event => this.handleDrop(event.target.files)}
                       accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                       style={{
                         opacity: 0.0,
                         position: "absolute",
                         top: 0,
                         left: 0,
                         bottom: 0,
                         right: 0,
                         width: "100%",
                         height: "100%",
                       }}
                     />
                   </span>
                 </div>
                 <div className="mt-3 cursor-pointer card box-shadow-none text-dark">
                   <span
                     className="px-3 py-1"
                     onClick={() => {
                       this.downloadXlSheetReport()
                     }}
                   >
                     <i className="mr-2 fal fa-download"></i>
                     Download As
                   </span>
                 </div>
               </div>
               {this.state.hierarchySubjectDetails &&
               this.state.hierarchySubjectDetails.key === "P" &&
               this.state.hasActivity === 1 ? null : (
                 <div>
                   <div className="p-3 mb-2 card">
                     <span>Mark Entry</span>
                     <CustomInput
                       className="mt-3"
                       type="switch"
                       id="exampleCustomSwitch"
                       name="customSwitch"
                       label="Lock"
                       checked={this.state.isLocked}
                       onChange={() => {
                         if (this.state.isCompleted) {
                           // if (!this.state.isLocked) {
                           confirmAlertPopUp(
                             VALIDATION_MSG.ALERT,
                             VALIDATION_MSG.MARK_UPLOAD_LOCK,
                             yes => {
                               if (yes) {
                                 // this.setState({ isLocked: !this.state.isLocked })
                                 let object = {
                                   isLocked: !this.state.isLocked,
                                   subjectId: this.state.hierarchySubjectDetails
                                     .subjectId,
                                   activityId: this.state.hierarchySubjectDetails
                                     .activityId,
                                   key: this.state.hierarchySubjectDetails.key,
                                   detail: this.state.hierarchySubjectDetails,
                                 }
                                 this.props.saveStudentSubjectMarkUploadLockDetail(
                                   object,
                                   (success, response) => {
                                     if (success) {
                                       this.props.getMarkStudentList(
                                         this.state.hierarchySubjectDetails,
                                         (success, response) => {
                                           if (success) {
                                             this.setState({
                                               allowQuickAnalytics:
                                                 response.completed &&
                                                 response.isLocked
                                                   ? true
                                                   : false,
                                               isLocked: response.isLocked
                                                 ? response.isLocked
                                                 : false,
                                               isCompleted: response.completed,
                                               isGeneratedAnalytics:
                                                 response.isGenerated,
                                               columnNames: response.headers,
                                             })
                                           }
                                         }
                                       )
                                       // this.setState({
                                       //   allowQuickAnalytics: !this.state.isLocked ? true : false,
                                       //   isLocked: !this.state.isLocked,
                                       // })
                                     } else {
                                       this.setState({
                                         allowQuickAnalytics: this.state
                                           .allowQuickAnalytics,
                                         isLocked: this.state.isLocked,
                                       })
                                     }
                                   }
                                 )
                               }
                             }
                           )
                           // }
                         } else {
                           this.props.showNotification(
                             DANGER_TOAST,
                             VALIDATION_MSG.OOPS,
                             "Need to complete the mark upload"
                           )
                         }
                       }}
                     />
                   </div>
                   <div className="p-3 card">
                     <button
                       className="btn btn-outline-save"
                       disabled={this.state.isShowLoading}
                       onClick={() => {
                         if (!this.state.isLocked) {
                           if (this.state.invalidMarkIndex === -1)
                             this.saveStudentSubjectMark()
                         } else
                           this.props.showNotification(
                             DANGER_TOAST,
                             VALIDATION_MSG.OOPS,
                             "Mark upload locked"
                           )
                       }}
                     >
                       {this.state.isShowLoading ? (
                         <div className="btn">
                           <HashLoader
                             sizeUnit={"px"}
                             size={16}
                             color={"#ffffff"}
                             loading={this.state.isShowLoading}
                           />
                         </div>
                       ) : (
                         <div>
                           Save
                           <i className="p-1 fas fa-save"></i>
                         </div>
                       )}
                     </button>
                   </div>
                   {this.state.hierarchySubjectDetails &&
                   this.state.hierarchySubjectDetails.key === "T" ? null : (
                     <div>
                       {this.state.isFromActivity ? null : this.state
                           .allowQuickAnalytics ? (
                         <div className="p-3 card">
                           <button
                             className="btn btn-outline-quick"
                             disabled={this.state.isShowGenerateAnalyticsLoading}
                             onClick={() => {
                               if (!this.state.isGeneratedAnalytics) {
                                 this.setState(
                                   { isShowGenerateAnalyticsLoading: true },
                                   () => {
                                     let object = {
                                       sectionId: this.state
                                         .hierarchySubjectDetails.classId,
                                       hSubjectId: this.state
                                         .hierarchySubjectDetails.subjectId,
                                       assessmentId: this.state
                                         .hierarchySubjectDetails.faId,
                                     }
                                     this.props.saveStudentSubjectGenerateAnalyticsDetail(
                                       object,
                                       (success, response) => {
                                         this.setState(
                                           {
                                             isShowGenerateAnalyticsLoading: false,
                                           },
                                           () => {}
                                         )
                                         if (success) {
                                           this.props.history.push({
                                             pathname:
                                               URL_PATH.TEACHER_MARKS_ANALYTICS,
                                             state: {
                                               academicName: this.props.location
                                                 .state.academicName,
                                               termName: this.props.location
                                                 .state.termName,
                                               faName: this.props.location.state
                                                 .faName,
                                               subjectName: this.props.location
                                                 .state.subjectName,
                                               activityName: this.props.location
                                                 .state.activityName,
                                               academicId: "",
                                               termId: "",
                                               faId: "",
                                               subjectId: "",
                                               classId: this.props.location.state
                                                 .classId,
                                               className: this.props.location
                                                 .state.className,
                                               hierarchySubjectDetails: this
                                                 .state.hierarchySubjectDetails,
                                             },
                                           })
                                         }
                                       }
                                     )
                                   }
                                 )
                               } else {
                                 this.props.history.push({
                                   pathname: URL_PATH.TEACHER_MARKS_ANALYTICS,
                                   state: {
                                     academicName: this.props.location.state
                                       .academicName,
                                     termName: this.props.location.state
                                       .termName,
                                     faName: this.props.location.state.faName,
                                     subjectName: this.props.location.state
                                       .subjectName,
                                     activityName: this.props.location.state
                                       .activityName,
                                     academicId: "",
                                     termId: "",
                                     faId: "",
                                     subjectId: "",
                                     classId: this.props.location.state.classId,
                                     className: this.props.location.state
                                       .className,
                                     hierarchySubjectDetails: this.state
                                       .hierarchySubjectDetails,
                                   },
                                 })
                               }
                             }}
                           >
                             {this.state.isShowGenerateAnalyticsLoading ? (
                               <div className="btn">
                                 <HashLoader
                                   sizeUnit={"px"}
                                   size={16}
                                   color={"#ffffff"}
                                   loading={
                                     this.state.isShowGenerateAnalyticsLoading
                                   }
                                 />
                               </div>
                             ) : (
                               <div>
                                 Generate Analytics
                                 <i className="p-1 fas fa-chart-line"></i>
                               </div>
                             )}
                           </button>
                         </div>
                       ) : null}
                     </div>
                   )}
                 </div>
               )}
             </div> */}
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    markSubjectDetails: state.markScreenState.markSubjectDetails,
    markStudentList: state.markScreenState.markStudentList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getMarkSubjectDetails,
      getMarkStudentList,
      saveStudentSubjectMarkObject,
      saveMarkStudentListInStore,
      saveStudentSubjectMarkUploadLockDetail,
      showNotification,
      saveStudentSubjectGenerateAnalyticsDetail,
      getXlsheetTemplate,
      getXlsheetReport,
      saveStudentSubjectReMark,
      saveCheckIsGenerateAnalyticsDetail,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(MarkScreen)
