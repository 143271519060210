/*************************************************
 * RedInk
 * @exports
 * @class AttendanceDetailsAction.js
 * @extends Component
 * @author vijayakumar // on 05/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { ACTION_TYPES } from "../types"

export const saveMarkUploadStatusDetailsInStore = markUploadStatusDetails => {
  return {
    type: ACTION_TYPES.ADMIN_MARK_UPLOAD_STATUS_DETAIL,
    payload: markUploadStatusDetails,
  }
}

export const getMarkUploadStatusDetails = (object, callBack) => {
  return async dispatch => {
    try {
      myLog("====getMarkUploadStatusDetails===::::")
      // let response = {
      //   markUploadStatusDetails: {
      //     classes: [
      //       {
      //         name: "Class LKG-A",
      //         classStatus: "NOT_STARTED",
      //         subjects: [
      //           {
      //             subjectName: "Eng",
      //             subjectStatus: "NOT_STARTED",
      //           },
      //           {
      //             subjectName: "Tam",
      //             subjectStatus: "NOT_STARTED",
      //           },
      //           {
      //             subjectName: "Mat",
      //             subjectStatus: "NOT_STARTED",
      //           },
      //         ],
      //       },
      //       {
      //         name: "Class LKG-B",
      //         classStatus: "IN_PROGRESS",
      //         subjects: [
      //           {
      //             subjectName: "Eng",
      //             subjectStatus: "NOT_STARTED",
      //           },
      //           {
      //             subjectName: "Tam",
      //             subjectStatus: "IN_PROGRESS",
      //           },
      //           {
      //             subjectName: "Mat",
      //             subjectStatus: "COMPLETED",
      //           },
      //         ],
      //       },
      //       {
      //         name: "Class LKG-C",
      //         classStatus: "COMPLETED",
      //         subjects: [
      //           {
      //             subjectName: "Eng",
      //             subjectStatus: "COMPLETED",
      //           },
      //           {
      //             subjectName: "Tam",
      //             subjectStatus: "COMPLETED",
      //           },
      //           {
      //             subjectName: "Mat",
      //             subjectStatus: "COMPLETED",
      //           },
      //         ],
      //       },
      //     ],
      //   },
      // }
      // myLog("====getMarkUploadStatusDetails===::::", response)
      // dispatch(
      //   saveMarkUploadStatusDetailsInStore(response.markUploadStatusDetails)
      // )
      // callBack(true, response.markUploadStatusDetails)
      const response = await Client.get(
        URL.GET_MARK_UPLOAD_STATUS_DETAILS,
        object,
        true
      )
      if (response) {
        myLog("====getMarkUploadStatusDetails RESPONSE===::::", response)
        dispatch(
          saveMarkUploadStatusDetailsInStore(response.markUploadStatusDetails)
        )
        callBack(true, response.markUploadStatusDetails)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getMarkUploadStatusDetails ERROR===????", error)
    }
  }
}
