/*************************************************
 * RedInk
 * @exports
 * @class TeacherDetails.js
 * @extends Component
 * @author Pathetic Geek // on 04/10/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"

/**
 * Returns branch list in callback
 * @param {{ tentantId: string }} options Pass in tentantId
 * @param {Function} callBack Returns the data, null if has error
 */
export const getBranchList = (options, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        `${URL.GET_BRANCH_LIST}?tentantId=${options.tentantId}`,
        null,
        true,
        true
      )
      myLog("hahaha", response)
      if (response) {
        myLog("====getBranchList RESPONSE===::::", response)
        callBack(true, response.data)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getBranchList ERROR===????", error)
    }
  }
}

/**
 * Returns teachers details in callback
 * @param {{ tentantId: string, branchId: string }} options Pass in tentantId, branchId
 * @param {Function} callBack Returns the data, null if has error
 */
export const getTeacherDetails = (options, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        `${URL.FOUNDER_GET_TEACHERS_DETAILS}?tentantId=${options.tentantId}&branchId=${options.branchId}`,
        null,
        true,
        true
      )
      if (response) {
        myLog("====getTeacherDetails RESPONSE===::::", response)
        callBack(true, response.data)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getTeacherDetails ERROR===????", error)
    }
  }
}
