/*************************************************
 * Redink
 * @exports
 * @class AuthScreen.js
 * @extends Component
 * @author Sasidharan // on 28/05/2020
 * @copyright © 2020 Redink. All rights reserved.
 *************************************************/
import React from "react"

import illus from "../../assets/gifs/loginAnimation.gif"
import curve from "../../assets/images/curve.svg"

const AuthScreen = props => {
  return (
    <div className="d-flex position-fixed h-100 w-100">
      <img
        src={curve}
        alt="Logo"
        draggable={false}
        style={{ height: "100%" }}
        className="position-absolute auth-curve"
      />
      <div className="p-5 flex-grow-1 col-9 text-dark d-sm-flex d-none md-d-none">
        <div className="align-self-center">
          <div className="d-flex justify-content-between align-items-center">
            <img src={illus} alt="Logo" draggable={false} />
            <div className="hide-1024">
              <h1 className="d-flex">Welcome back</h1>
            </div>
          </div>
        </div>
      </div>
      {props.children}
    </div>
  )
}

export default AuthScreen
