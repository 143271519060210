/*************************************************
 * RedInk
 * @exports
 * @class DashboardAction.js
 * @extends Component
 * @author kishore // on 11/07/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import { showNotification } from "../CommonAction"
import { getSchoolDetails } from "./DashboardAction"
import {
  SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"
import URL from "../../config"

export const updateProfileDetails = (body, callBack) => {
  myLog("CAME TO ACTION and object -> ", body)
  return async dispatch => {
    try {
      const response = await Client.post(
        URL.UPDATE_PROFILE_DETAILS_SUPER_ADMIN,
        body,
        true
      )
      myLog(response)
      if (response.responseCode === 0) {
        myLog("====updatePersonDetails RESPONSE===::::", response)
        callBack(true, response)
        dispatch(getSchoolDetails(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
      } else {
        callBack(false)
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
      }
    } catch (error) {
      callBack(false)
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      myLog("====updatePersonDetails ERROR===????", error)
    }
  }
}
