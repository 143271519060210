import React, { Component } from "react"
import AppWrapper from "../Wrapper"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  getQuizAssignList,
  getTeacherAssignedDetails,
  getClassStudents,
  deleteQuizAssign,
} from "../../../actions/teacher/QuizAction"
import { withRouter } from "react-router-dom"
import { HashLoader } from "react-spinners"
import moment from "moment"
import { myLog, confirmAlertPopUp } from "../../../utils/Utility"
import { VALIDATION_MSG } from "../../../utils/Constants" // DANGER_TOAST,
import {
  Input,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap"
import { URL_PATH } from "../../../config/urlPath"

class TeacherViewAllAssesments extends Component {
  state = {
    search: "",
    selectedClass: "",
    selectedType: "",
    selectedStatus: "",
  }

  componentDidMount = () => {
    const { classesList } = this.props.location.state || {}
    // myLog("classesList", classesList)
    if (!this.props.quizAssignList || this.props.quizAssignList.length === 0) {
      this.setState({ isShowLoading: true })
      this.props.getTeacherAssignedDetails({}, (success, data) => {
        // myLog("this.props.getTeacherAssignedDetails", success, data)
        if (success) {
          // myLog("View all")
          this.setState({
            classesList: data.sections || [],
            subjectsPerSection: data.assignedSubject || {},
          })
        }
      })

      this.getQuizAssignList()
    } else {
      this.setState({ classesList })
    }
  }

  deteleQuiz = id => {
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT + "",
      yes => {
        if (yes) {
          this.deteleQuizAction(id)
        }
      },
      {
        customUI: ({ onClose }) => {
          return (
            <div
              className="custom"
              style={{
                backgroundColor: "#fff",

                borderBottomRightRadius: "12px",
                borderBottomLeftRadius: "12px",
              }}
            >
              <div
                className="bg-brand text-light d-flex"
                style={{
                  padding: "0.5rem 2rem",
                  borderTopRightRadius: "12px",
                  borderTopLeftRadius: "12px",
                }}
              >
                <div className="mx-auto" style={{ fontSize: "18px" }}>
                  Delete
                </div>
              </div>

              <div
                className="mt-3 "
                style={{
                  padding: "0.5rem 1.5rem",
                }}
              >
                <div className="text-muted" style={{ fontSize: "15px" }}>
                  Are you sure you want to delete?
                </div>
                <div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
                  <button
                    className="bg-brand text-light px-4 py-1 w-25 d-flex align-items-center justify-content-center yes-button"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      this.deteleQuizAction(id)
                      onClose()
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-brand text-light px-4 py-1 ml-4 w-25 d-flex align-items-center justify-content-center"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={onClose}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )
        },
      }
    )
  }

  deteleQuizAction = id => {
    this.setState({ deletingItemId: id })
    this.props.deleteQuizAssign({ id }, (success, _) => {
      this.setState({ deletingItemId: null, deletedItem: true })
      if (success) {
        this.getQuizAssignList()
      }
    })
  }

  getQuizAssignList = (querySection = "") => {
    const query = {}
    if (querySection) query.assignedClass = querySection

    this.setState({ isShowLoading: true })
    this.props.getQuizAssignList(query, success => {
      this.setState({ isShowLoading: false })
      if (success) {
      }
    })
  }

  render() {
    return (
      <AppWrapper
        teacherDashboard
        showTeacherThree
        showTeacherLogo
        screenName={"TESTS"}
      >
        <div className="row align-items-center mx-3">
          <div className="col-4">
            <h5 className="text-brand mb-0 font-weight-bold">TEST HISTORY</h5>
          </div>

          <div className="col-8 d-flex align-items-center justify-content-end">
            <Input
              type="text"
              placeholder="Search"
              value={this.state.search}
              onChange={e => this.setState({ search: e.target.value })}
              className="mr-2"
              style={{ borderRadius: 8 }}
            />
            <select
              className="custom-select mr-2"
              style={{
                borderRadius: 8,
                backgroundColor: "#DBDCDD",
                width: "130px",
              }}
              value={this.state.selectedClass}
              onChange={e => this.setState({ selectedClass: e.target.value })}
            >
              <option value="">Select Class</option>
              {this.state.classesList &&
                this.state.classesList.map(cls => (
                  <option
                    value={cls.className + cls.sectionName}
                    key={cls.className + cls.sectionName}
                  >
                    {cls.className + cls.sectionName}
                  </option>
                ))}
            </select>
            <select
              className="custom-select mr-2"
              style={{
                borderRadius: 8,
                backgroundColor: "#DBDCDD",
                width: "130px",
              }}
              value={this.state.selectedType}
              onChange={e => this.setState({ selectedType: e.target.value })}
            >
              <option value="">Select Type</option>
              <option value="ONLINE">Online</option>
              <option value="IN_SCHOOL">InClass</option>
            </select>
            <select
              className="custom-select"
              style={{
                borderRadius: 8,
                backgroundColor: "#DBDCDD",
                width: "130px",
              }}
              value={this.state.selectedStatus}
              onChange={e => this.setState({ selectedStatus: e.target.value })}
            >
              <option value="">Select Status</option>
              <option value="UPCOMING">Upcoming</option>
              <option value="YET TO GRADE">Yet to grade</option>
              <option value="COMPLETED">Completed</option>
            </select>
          </div>
        </div>

        <div className="row mx-3 mb-5">
          <div
            className="col-12 mt-4 mb-2 py-3 d-flex align-items-center bg-brand text-light"
            style={{ borderRadius: 16 }}
          >
            <div className="text-left pl-3" style={{ flex: 2 }}>
              Test Title
            </div>
            <div className="text-center" style={{ flex: 1 }}>
              Class
            </div>
            <div className="text-center" style={{ flex: 1 }}>
              Type
            </div>
            <div className="text-center" style={{ flex: 3 }}>
              Date & time
            </div>
            <div className="text-center" style={{ flex: 1 }}>
              Submission
            </div>
            <div className="text-center" style={{ flex: 2 }}>
              Status
            </div>
          </div>

          {this.state.isShowLoading && (
            <div className="d-flex align-items-center justify-content-center mt-4 col-12">
              <HashLoader
                sizeUnit={"px"}
                size={32}
                color={"#094251"}
                loading={true}
              />
            </div>
          )}
          {this.props.quizAssignList
            .sort((a, b) => moment(b.startTime) - moment(a.startTime))
            .filter(assign => {
              let include = true
              if (this.state.search) {
                include =
                  include &&
                  assign.quizSet &&
                  assign.quizSet.title
                    .toLowerCase()
                    .includes(this.state.search.toLowerCase())
              }
              if (this.state.selectedType) {
                include =
                  include && assign.submissionType === this.state.selectedType
              }
              if (this.state.selectedStatus === "UPCOMING") {
                include =
                  include && !assign.graded && assign.status === "UPCOMING"
              } else if (this.state.selectedStatus === "YET TO GRADE") {
                include =
                  include &&
                  !assign.graded &&
                  moment(assign.startTime) < moment()
              } else if (this.state.selectedStatus === "COMPLETED") {
                include =
                  include &&
                  assign.graded &&
                  moment(assign.startTime) < moment()
              }
              if (this.state.selectedClass) {
                include =
                  include &&
                  assign.teacherAssignedDetail.section.className +
                    assign.teacherAssignedDetail.section.sectionName ===
                    this.state.selectedClass
              }
              return include
            })
            .map(assign => {
              myLog("Assign ", assign)
              const { endTime, startTime, quizSet } = assign

              let assignedStudentsCount = 0
              let submittedStudentsCount = 0
              assign.assignedStudents.forEach(stdnt => {
                assignedStudentsCount += 1
                submittedStudentsCount += stdnt.submitted ? 1 : 0
              })
              let status = ""
              let bgColor = ""

              const isDraft = quizSet.draft === 1
              const now = moment()
              const isLive =
                !isDraft &&
                moment(startTime).isSameOrBefore(now) &&
                moment(endTime).isAfter(now)

              if (isLive) {
                status = "LIVE"
                bgColor = "#dc3545"
                // } else if (assign.status === "UPCOMING") {
              } else if (moment(startTime).isAfter(now)) {
                status = "UPCOMING"
                bgColor = "#094353"
              } else if (!assign.published) {
                status = "YET TO GRADE"
                bgColor = "rgb(224, 173, 83)"
              } else {
                status = "COMPLETED"
                bgColor = "rgb(6, 177, 145)"
              }

              const {
                id,
                // assignLater,
                // published,
                submissionType,
                // teacherAssignedDetail,
                // assignedStudents,
              } = assign

              // const { section, subject } = teacherAssignedDetail
              // const { className, sectionName } = section
              // const { subjectName } = subject

              const isOnline = submissionType === "ONLINE"

              return (
                <div
                  key={assign.id}
                  className="col-12 mb-2 py-2 d-flex align-items-center"
                  style={{
                    borderRadius: 16,
                    backgroundColor: "rgb(215, 233, 232, 0.4)",
                  }}
                >
                  <div className="text-left pl-3" style={{ flex: 2 }}>
                    <h5 className="mb-0 mt-1 font-weight-bold text-brand">
                      {assign.quizSet &&
                      String(assign.quizSet.title).length > 25
                        ? String(assign.quizSet.title)
                            .slice(0, 25)
                            .concat("...")
                        : assign.quizSet.title}
                    </h5>
                    <p className="m-0">
                      {assign.teacherAssignedDetail.subject.subjectName}
                    </p>
                  </div>
                  <div className="text-center" style={{ flex: 1 }}>
                    {assign.teacherAssignedDetail.section.className}
                    {assign.teacherAssignedDetail.section.sectionName}
                  </div>
                  <div className="text-center" style={{ flex: 1 }}>
                    {assign.submissionType === "IN_SCHOOL"
                      ? "InClass"
                      : "Online"}
                  </div>
                  <div className="text-center" style={{ flex: 3 }}>
                    {moment(assign.startTime).format("DD MMM")}{" "}
                    {moment(assign.startTime).format("hh:mm a")} -{" "}
                    {moment(assign.endTime).format("DD MMM")}{" "}
                    {moment(assign.endTime).format("hh:mm a")}
                  </div>
                  <div className="text-center" style={{ flex: 1 }}>
                    {submittedStudentsCount} / {assignedStudentsCount}
                  </div>
                  <div className="text-center d-flex" style={{ flex: 2 }}>
                    <div
                      className="text-light rounded mx-auto py-1 px-2"
                      style={{
                        backgroundColor: bgColor,
                        fontSize: "smaller",
                        width: "7rem",
                      }}
                    >
                      {status}
                    </div>
                    {!isLive ? (
                      <UncontrolledDropdown direction="left">
                        <DropdownToggle
                          tag="a"
                          onClick={e => {
                            e.stopPropagation()
                          }}
                          className="nav-link p-0 pr-1"
                        >
                          <i className="px-2 py-1 cursor-pointer text-brand fas fa-ellipsis-v teacher-test-preview-buttons" />
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            className={
                              !isOnline || status !== "UPCOMING"
                                ? "disabled"
                                : ""
                            }
                            onClick={e => {
                              if (isOnline) {
                                e.stopPropagation()
                                this.props.history.push({
                                  pathname: URL_PATH.TEACHER_CREATE_TESTS,
                                  state: { isEdit: true, quizData: assign },
                                })
                              }
                            }}
                          >
                            Edit questions
                          </DropdownItem>
                          <DropdownItem
                            data-toggle="modal"
                            data-target="#createTestModal"
                            className={status !== "UPCOMING" ? "disabled" : ""}
                            onClick={e => {
                              e.stopPropagation()
                              this.props.history.push({
                                pathname: URL_PATH.TEACHER_TESTS,
                                state: {
                                  fromViewAllType: "edit",
                                  reuseQuizData: assign,
                                },
                              })
                            }}
                          >
                            Edit details
                          </DropdownItem>
                          <DropdownItem
                            className={
                              !assign.quizSet || status === "UPCOMING"
                                ? "disabled"
                                : ""
                            }
                            onClick={e => {
                              if (assign.quizSet) {
                                e.stopPropagation()
                                this.props.history.push({
                                  pathname: URL_PATH.TEACHER_TESTS_MARK_ENTRY,
                                  state: { quizAssignInfoId: assign.id },
                                })
                              }
                            }}
                          >
                            Enter/view marks
                          </DropdownItem>
                          <DropdownItem
                            className={
                              !assign.quizSet || status === "UPCOMING"
                                ? "disabled"
                                : ""
                            }
                            onClick={e => {
                              if (
                                [
                                  "COMPLETED",
                                  "UNGRADED",
                                  "YET TO GRADE",
                                ].includes(status) &&
                                assign.quizSet
                              ) {
                                e.stopPropagation()
                                this.props.history.push({
                                  pathname: URL_PATH.TEACHER_TESTS_REPORT,
                                  state: { quizAssignId: assign.id },
                                })
                              }
                            }}
                          >
                            View Analysis
                          </DropdownItem>
                          <DropdownItem
                            data-toggle="modal"
                            data-target="#createTestModal"
                            onClick={e => {
                              e.stopPropagation()
                              this.props.history.push({
                                pathname: URL_PATH.TEACHER_TESTS,
                                state: {
                                  fromViewAllType: "reuse",
                                  reuseQuizData: assign,
                                },
                              })
                            }}
                          >
                            Reuse test
                          </DropdownItem>
                          <DropdownItem
                            onClick={e => {
                              e.stopPropagation()
                              this.deteleQuiz(id)
                            }}
                          >
                            Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    ) : null}
                  </div>
                </div>
              )
            })}
        </div>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    quizAssignList: state.quiz.quizAssignList,
    quizAssign: state.quiz.quizAssign,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getQuizAssignList,
      getTeacherAssignedDetails,
      getClassStudents,
      deleteQuizAssign,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  dispatchToProps
)(withRouter(TeacherViewAllAssesments))
