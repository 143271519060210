/*************************************************
 * RedInk
 * @exports
 * @class CommonAction.js
 * @extends Component
 * @author Sasidharan // on 18/04/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
// import { store } from "react-notifications-component"
import toast from "react-hot-toast"
import { myLog } from "../utils/Utility"
import { ACTION_TYPES } from "./types"
import Client from "../utils/axiosInstance"
import URL from "../config"
import storeBrowser from "store"
import { SUCCESS_TOAST, DANGER_TOAST } from "../utils/Constants"
import React from "react"

export const showNotification = (type, _, message) => {
  if (type === SUCCESS_TOAST)
    return () =>
      toast(
        t => (
          <div onClick={() => toast.dismiss(t.id)} className="toast__custom">
            <div className="icon-success">
              <i className="fas fa-check-circle text-light" />
            </div>
            <div className="message">{message}</div>
          </div>
        ),
        {
          style: {
            padding: 0,
            boxShadow: "7px 8px 15px 0px rgba(50, 44, 50, 0.58)",
            WebkitBoxShadow: "7px 8px 15px 0px rgba(50, 44, 50, 0.58)",
            MozBoxShadow: "7px 8px 15px 0px rgba(50, 44, 50, 0.58)",
          },
        }
      )
  else if (type === DANGER_TOAST)
    return () =>
      toast(
        t => (
          <div onClick={() => toast.dismiss(t.id)} className="toast__custom">
            <div className="icon-error">
              <i className="fas fa-times-circle fs-30 text-light" />
            </div>
            <div className="message">{message}</div>
          </div>
        ),
        {
          style: {
            padding: 0,
            boxShadow: "7px 8px 15px 0px rgba(50, 44, 50, 0.58)",
            WebkitBoxShadow: "7px 8px 15px 0px rgba(50, 44, 50, 0.58)",
            MozBoxShadow: "7px 8px 15px 0px rgba(50, 44, 50, 0.58)",
          },
        }
      )
  else
    return () =>
      toast(t => <span onClick={() => toast.dismiss(t.id)}>{message}</span>)
}

// export const showNotification = (type, title, message) => {
// return ()=>{
// store.addNotification({
//   title,
//   message,
//   type,
//   insert: "top",
//   container: "top-right",
//   animationIn: ["animated", "fadeIn"],
//   animationOut: ["animated", "fadeOut"],
//   isMobile: true,
//   dismiss: {
//     duration: 5000,
//     onScreen: true,
//     pauseOnHover: true,
//   },
// })}

export const saveQuickInfoDetailsInStore = QuickInfoDetails => {
  return {
    type: ACTION_TYPES.QUICK_INFO_DETAIL,
    payload: QuickInfoDetails,
  }
}

export const getQuickInfoDetails = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_QUICK_INFO_DETAILS, null, true)
      if (response) {
        myLog("====getQuickInfoDetails RESPONSE===::::", response)
        dispatch(saveQuickInfoDetailsInStore(response.info))
        storeBrowser.set("quickInfo", response.info)
        callBack(true, response.info)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getAttendanceDetails ERROR===????", error)
    }
  }
}

export const getCheckEmailAvailableDetails = (email, callBack) => {
  return async dispatch => {
    try {
      let object = {
        email,
      }
      const response = await Client.get(
        URL.CHECK_EMAIL_AVAILABILITY,
        object,
        true
      )
      if (response) {
        myLog(
          "====getCheckEmailAvailableDetails RESPONSE===::::",
          response.alreadyExists
        )
        callBack(true, response.alreadyExists)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getCheckEmailAvailableDetails ERROR===????", error)
    }
  }
}

/**
 * @callback ActionCallback
 * @param {true|false} success
 * @param {null|{}} response
 */

/**
 * Upload file to s3
 * @param {{ name: String, uri: String, type: String }} file File to upload
 * @param {String} path Path to put file at
 * @param {ActionCallback} [cb] Callback after upload complete
 */
export const uploadFile = (file, path, cb = () => {}) => {
  return async dispatch => {
    try {
      myLog("====uploadFile args=== ", { file, path })

      // Get presigned url to upload file at
      const params = new URLSearchParams({
        name: file.name, path,
      }) // prettier-ignore
      const response = await Client.get(
        `${URL.FILE_UPLOAD}?${params.toString()}`,
        null,
        true,
        true
      )
      const {
        data: { name, url },
      } = response
      myLog("====uploadFile response.data===::::", { name, url })

      // Convert file to blob
      const browserUrl = window.webkitURL || window.URL
      const uri = browserUrl.createObjectURL(file)
      const blob = await fetch(uri).then(res => res.blob())
      browserUrl.revokeObjectURL(uri)

      // Upload file
      const xhr = new XMLHttpRequest()
      xhr.open("PUT", response.data.url, true)
      xhr.setRequestHeader("Content-Type", file.type)
      xhr.onload = () => {
        myLog("====uploadFile xhr.onload====")
        if (xhr.status === 200) {
          cb(true, { name, url })
        } else {
          cb(false)
        }
      }
      xhr.onerror = () => {
        myLog("====uploadFile xhr.onerror====")
        cb(false)
      }
      xhr.send(blob)
    } catch (e) {
      myLog("====uploadFile ERROR===????", e)
      cb(false)
    }
  }
}

/**
 * Get file details
 * @param {String} path Path of file
 * @param {ActionCallback} [cb] Callback after action
 */
export const getFile = (path, cb = () => {}) => {
  return async dispatch => {
    try {
      myLog("====getFile args=== ", { path })

      const params = new URLSearchParams({
        name: path,
      })
      const response = await Client.get(
        `${URL.FILE_INFO}?${params.toString()}`,
        null,
        true,
        true
      )
      myLog("====getFile response===::::", response)
      if (!response || response.responseCode !== 0) {
        cb(false)
      } else {
        cb(true, response)
      }
    } catch (e) {
      myLog("====getFile ERROR===????", e)
      cb(false)
    }
  }
}
