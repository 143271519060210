/*************************************************
 * RedInk
 * @exports
 * @class AttendanceDetailsAction.js
 * @extends Component
 * @author vijayakumar // on 05/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import { myLog } from "../../utils/Utility"
import { ACTION_TYPES } from "../types"
import Client from "../../utils/axiosInstance"
import URL from "../../config"

export const saveAttendanceDetailsInStore = attendanceDetails => {
  return {
    type: ACTION_TYPES.ATTENDANCE_DETAIL,
    payload: attendanceDetails,
  }
}

export const getAttendanceDetails = (date, callBack) => {
  return async dispatch => {
    try {
      myLog("====getAttendanceDetails===::::", date)
      let object = {
        date,
      }
      // let response = {
      //   attendanceDetails: {
      //     schoolAttendanceDetail: {
      //       absentCount: "40",
      //       presentCount: "100",
      //       unmarkedCount: "5",
      //     },
      //     schools: [
      //       {
      //         name: "School 1",
      //         absentPercentage: "10",
      //         presentPercentage: "90",
      //         absentCount: "10",
      //         presentCount: "90",
      //         absentPercentageStatus: "decreased",
      //         presentPercentageStatus: "increased",
      //       },
      //       {
      //         name: "School 2",
      //         absentPercentage: "35",
      //         presentPercentage: "65",
      //         absentCount: "35",
      //         presentCount: "65",
      //         absentPercentageStatus: "increased",
      //         presentPercentageStatus: "decreased",
      //       },
      //       {
      //         name: "School 3",
      //         absentPercentage: "10",
      //         presentPercentage: "90",
      //         absentCount: "10",
      //         presentCount: "90",
      //         absentPercentageStatus: "decreased",
      //         presentPercentageStatus: "increased",
      //       },
      //     ],
      //   },
      // }
      // myLog("====getAttendanceDetails===::::", response)
      // dispatch(saveAttendanceDetailsInStore(response.attendanceDetails))
      // callBack(true, response.attendanceDetails)
      const response = await Client.get(
        URL.GET_ATTENDANCE_DETAILS,
        object,
        true
      )
      if (response) {
        myLog("====getAttendanceDetails RESPONSE===::::", response)
        dispatch(saveAttendanceDetailsInStore(response.attendanceDetails))
        callBack(true, response.attendanceDetails)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getAttendanceDetails ERROR===????", error)
    }
  }
}
