import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import AppWrapper from "./Wrapper"
// import { myLog } from "../../utils/Utility"
import TextField from "@material-ui/core/TextField"
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import { v4 as uuid } from "uuid"
import {
  getStudentProfileExtendInfo,
  updateStudentProfileExtendInfo,
  updateParentExtendInfo,
} from "../../actions/common/StudentProfileAction"
import { showNotification } from "../../actions/CommonAction"
import { DANGER_TOAST, VALIDATION_MSG } from "../../utils/Constants"
import { validateEmail } from "../../utils/Utility"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#094251",
    },
  },
})

class FormDetails extends Component {
  constructor(props) {
    super(props)
    document.title = "Profile | Redink"
    this.state = {
      primaryParent: "",
      studentFirstName: "",
      studentLastName: "",
      studentGender: "",
      admissionNo: "",
      rollNo: "",
      joiningDate: "",
      birthDate: "",
      studentEmail: "",
      studentHeight: "",
      studentWeight: "",
      studentContactNumber: "",
      studentAddressStreet: "",
      studentCountry: "",
      studentState: "",
      studentCity: "",
      studentCityPincode: "",
      studentBloodGroup: "",
      studentSchoolHouse: "",
      studentNationality: "",
      studentMotherTongue: "",
      studentReligion: "",
      studentPrevSchoolId: "",
      studentPreviousSchoolName: "",
      studentPreviousBoard: "",
      studentPassOutClass: "",
      studentPreviousClassPercent: "",
      selectedTab: "Personal",
      isAddExtendedDetailsShown: true,
      isPreviousSchoolDetailsShown: true,

      //parentsTab

      //father
      isFatherEmployeeOfSchool: false,
      fatherFirstName: "",
      fatherLastName: "",
      fatherDOB: "",
      fatherEmail: "",
      fatherOccupation: "",
      fatherPhone: "",
      fatherQualification: "",
      fatherAnnualIncome: "",
      //mother
      isMotherEmployeeOfSchool: false,
      motherFirstName: "",
      motherLastName: "",
      motherDOB: "",
      motherEmail: "",
      motherOccupation: "",
      motherPhone: "",
      motherQualification: "",
      motherAnnualIncome: "",
      //Local Guardian
      localGuardianFirstName: "",
      localGuardianLastName: "",
      localGuardianRelation: "",
      localGuardianCity: "",
      localGuardianStreet: "",
      localGuardianCountry: "",
      localGuardianState: "",
      localGuardianEmail: "",
      localGuardianZipCode: "",
      localGuardianPhone: "",
      localGuardianGender: "",
      isLocalGuardianDetailsShown: true,
      //Legal Guardian
      legalGuardianFirstName: "",
      legalGuardianLastName: "",
      legalGuardianRelation: "",
      legalGuardianCity: "",
      legalGuardianStreet: "",
      legalGuardianCountry: "",
      legalGuardianState: "",
      legalGuardianEmail: "",
      legalGuardianZipCode: "",
      legalGuardianPhone: "",
      legalGuardianGender: "",
      isLegalGuardianDetailsShown: true,

      //Sibling
      studyingInSameSchool: "",
      siblingFirstName: "",
      siblingLastName: "",
      siblingGender: "",
      siblingDOB: "",
      siblingStudyingInstitution: "",
      siblingStudyingCourse: "",
      siblingEmail: "",
      siblingMobileNo: "",
      isSiblingDetailsShown: true,
      siblings: [],
      siblingsId: "",
      hasError: 0,
      hasPrimaryparent: false,
    }
  }

  componentDidMount = () => {
    this.getStudentProfile()
  }

  getStudentProfile = () => {
    let object = {
      studentId: this.props.location.state.studentId,
    }
    this.props.getStudentProfileExtendInfo(object, (success, response) => {
      if (success) {
        const studentDetails = response.studentDetail
        const prevSchoolDetails = response.prevSchoolDetails[0]
        const parentDetails = response.studentDetail.parents[0]
        const siblingDetails = response.studentDetail.siblings[0]
        this.setState({
          studentFirstName: studentDetails.studentFirstName
            ? studentDetails.studentFirstName
            : "",
          studentLastName: studentDetails.studentLastName
            ? studentDetails.studentLastName
            : "",
          studentGender:
            studentDetails.gender === "M"
              ? "Male"
              : studentDetails.gender === "F"
              ? "Male"
              : studentDetails.gender === "O"
              ? "Others"
              : "",
          admissionNo: studentDetails.admissionNumber
            ? studentDetails.admissionNumber
            : "",
          rollNo: studentDetails.registerRollNumber
            ? studentDetails.registerRollNumber
            : "",
          joiningDate: studentDetails.dateOfJoining
            ? studentDetails.dateOfJoining
            : "",
          birthDate: studentDetails.dob ? studentDetails.dob : "",
          studentEmail: studentDetails.studentEmail
            ? studentDetails.studentEmail
            : "",
          studentHeight: studentDetails.studentHeight
            ? studentDetails.studentHeight
            : "",
          studentWeight: studentDetails.studentWeight
            ? studentDetails.studentWeight
            : "",
          studentContactNumber: studentDetails.studentPhone
            ? studentDetails.studentPhone
            : "",
          studentAddressStreet: studentDetails.street
            ? studentDetails.street
            : "",
          primaryParent: studentDetails.primaryParent
            ? studentDetails.primaryParent
            : "",
          studentCountry: studentDetails.country ? studentDetails.country : "",
          studentState: studentDetails.state ? studentDetails.state : "",
          studentCity: studentDetails.city ? studentDetails.city : "",
          studentCityPincode: studentDetails.postalCode
            ? studentDetails.postalCode
            : "",
          studentBloodGroup: studentDetails.bloodGroup
            ? studentDetails.bloodGroup
            : "",
          studentSchoolHouse: studentDetails.studentSchoolHouse
            ? studentDetails.studentSchoolHouse
            : "",
          studentNationality: studentDetails.nationality
            ? studentDetails.nationality
            : "",
          studentMotherTongue: studentDetails.motherTongue
            ? studentDetails.motherTongue
            : "",
          studentReligion: studentDetails.religion
            ? studentDetails.religion
            : "",
          studentPrevSchoolId: prevSchoolDetails ? prevSchoolDetails.id : "",
          studentPreviousSchoolName: prevSchoolDetails.schoolName
            ? prevSchoolDetails.schoolName
            : "",
          studentPreviousBoard: prevSchoolDetails.board
            ? prevSchoolDetails.board
            : "",
          studentPassOutClass: prevSchoolDetails.passOutClass
            ? prevSchoolDetails.passOutClass
            : "",
          studentPreviousClassPercent: prevSchoolDetails.percentageMark
            ? prevSchoolDetails.percentageMark
            : "",
          isFatherEmployeeOfSchool: parentDetails.isFatherEmployeeOfSchool,
          parentId: parentDetails.parentId,
          fatherFirstName: parentDetails.fatherFirstName,
          fatherLastName: parentDetails.fatherLastName,
          fatherDOB: parentDetails.fatherDOB,
          fatherEmail: parentDetails.fatherEmail,
          fatherOccupation: parentDetails.fatherOccupation,
          fatherPhone: parentDetails.fatherPhone,
          fatherQualification: parentDetails.fatherQualification,
          fatherAnnualIncome: parentDetails.fatherAnnualIncome,
          //mother
          isMotherEmployeeOfSchool: parentDetails.isMotherEmployeeOfSchool,
          motherFirstName: parentDetails.motherFirstName,
          motherLastName: parentDetails.motherLastName,
          motherDOB: parentDetails.motherDOB,
          motherEmail: parentDetails.motherEmail,
          motherOccupation: parentDetails.motherOccupation,
          motherPhone: parentDetails.motherPhone,
          motherQualification: parentDetails.motherQualification,
          motherAnnualIncome: parentDetails.motherAnnualIncome,
          //Local Guardian
          localGuardianFirstName: parentDetails.localGuardianFirstName,
          localGuardianLastName: parentDetails.localGuardianLastName,
          localGuardianRelation: parentDetails.localGuardianRelation,
          localGuardianCity: parentDetails.localGuardianCity,
          localGuardianStreet: parentDetails.localGuardianStreet,
          localGuardianCountry: parentDetails.localGuardianCountry,
          localGuardianState: parentDetails.localGuardianState,
          localGuardianEmail: parentDetails.localGuardianEmail,
          localGuardianZipCode: parentDetails.localGuardianZipCode,
          localGuardianPhone: parentDetails.localGuardianPhone,
          localGuardianGender: parentDetails.localGuardianGender,
          isLocalGuardianDetailsShown: true,
          //Legal Guardian
          legalGuardianFirstName: parentDetails.legalGuardianFirstName,
          legalGuardianLastName: parentDetails.legalGuardianLastName,
          legalGuardianRelation: parentDetails.legalGuardianRelation,
          legalGuardianCity: parentDetails.legalGuardianCity,
          legalGuardianStreet: parentDetails.legalGuardianStreet,
          legalGuardianCountry: parentDetails.legalGuardianCountry,
          legalGuardianState: parentDetails.legalGuardianState,
          legalGuardianEmail: parentDetails.legalGuardianEmail,
          legalGuardianZipCode: parentDetails.legalGuardianZipCode,
          legalGuardianPhone: parentDetails.legalGuardianPhone,
          legalGuardianGender: parentDetails.legalGuardianGender,
          isLegalGuardianDetailsShown: true,

          //Sibling
          isSiblingDetailsShown: siblingDetails ? false : true,
          siblingsId: siblingDetails ? siblingDetails.id : "",
          studyingInSameSchool:
            siblingDetails &&
            parseInt(siblingDetails.studyingInSameSchool) === 1
              ? true
              : false,
          siblingFirstName: siblingDetails
            ? siblingDetails.siblingFirstName
            : "",
          siblingLastName: siblingDetails ? siblingDetails.siblingLastName : "",
          siblingGender: siblingDetails ? siblingDetails.gender : "",
          siblingDOB: siblingDetails ? siblingDetails.dob : "",
          siblingStudyingInstitution: siblingDetails
            ? siblingDetails.institution
            : "",
          siblingStudyingCourse: siblingDetails ? siblingDetails.course : "",
          siblingEmail: siblingDetails ? siblingDetails.email : "",
          siblingMobileNo: siblingDetails ? siblingDetails.phone : "",
        })
      }
    })
  }

  onSaveStudentDetails = () => {
    let object = {
      studentId: this.props.location.state.studentId,
      studentFirstName: this.state.studentFirstName,
      studentLastName: this.state.studentLastName,
      gender: this.state.studentGender,
      admissionNumber: this.state.admissionNo,
      registerRollNumber: this.state.rollNo,
      dateOfJoining: this.state.joiningDate,
      dob: this.state.birthDate,
      studentEmail: this.state.studentEmail,
      studentHeight: this.state.studentHeight,
      studentWeight: this.state.studentWeight,
      studentPhone: this.state.studentContactNumber,
      street: this.state.studentAddressStreet,
      country: this.state.studentCountry,
      state: this.state.studentState,
      city: this.state.studentCity,
      postalCode: this.state.studentCityPincode,
      bloodGroup: this.state.studentBloodGroup,
      studentSchoolHouse: this.state.studentSchoolHouse,
      nationality: this.state.studentNationality,
      motherTongue: this.state.studentMotherTongue,
      religion: this.state.studentReligion,
      prevSchoolDetails: [
        {
          id: this.state.studentPrevSchoolId
            ? this.state.studentPrevSchoolId
            : uuid(),
          schoolName: this.state.studentPreviousSchoolName,
          board: this.state.studentPreviousBoard,
          passOutClass: this.state.studentPassOutClass,
          percentageMark: this.state.studentPreviousClassPercent,
        },
      ],
    }
    this.props.updateStudentProfileExtendInfo(object, (success, response) => {
      if (success) {
        this.getStudentProfile()
      }
    })
  }

  onSaveParentDetails = () => {
    if (
      this.state.primaryParent !== "" &&
      this.state.primaryParent !== undefined
    ) {
      if (this.state.primaryParent === "FATHER") {
        if (this.state.fatherFirstName === "") {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Enter father's first name"
          )
          return true
        }
        if (this.state.fatherLastName === "") {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Enter father's last name"
          )
          return true
        }
        if (
          this.state.fatherOccupation === "" ||
          this.state.fatherOccupation === undefined
        ) {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Enter father's occupation"
          )
          return
        }
        if (
          this.state.fatherAnnualIncome === "" ||
          this.state.fatherAnnualIncome === undefined
        ) {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Enter father's Annual Income"
          )
          return
        }
        if (!validateEmail(this.state.fatherEmail)) {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Enter Valid Email"
          )
          return
        }
        if (
          this.state.fatherPhone === "" ||
          this.state.fatherPhone.length !== 10
        ) {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Enter father's Annual Income"
          )
          return
        }
      }
      if (this.state.primaryParent === "MOTHER") {
        if (this.state.motherFirstName === "") {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Enter mother's first name"
          )
          return
        }
        if (this.state.motherLastName === "") {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Enter mother's last name"
          )
          return
        }
        if (this.state.motherOccupation === "") {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Enter mother's Occupation"
          )
          return
        }
        if (this.state.motherAnnualIncome === "") {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Enter mother's Annual Income"
          )
          return
        }
        if (!validateEmail(this.state.motherEmail)) {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Enter Valid Email"
          )
          return
        }
        if (
          this.state.motherPhone === "" ||
          this.state.motherPhone.length !== 10
        ) {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Enter father's Annual Income"
          )
          return
        }
      }
      if (this.state.primaryParent === "LOG") {
        if (this.state.localGuardianFirstName === "") {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Enter Local Guardian's first name"
          )
          return
        }
        if (this.state.localGuardianLastName === "") {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Enter Local Guardian's last name"
          )
          return
        }
        if (!validateEmail(this.state.localGuardianEmail)) {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Enter Local Guardian Valid Email"
          )
          return
        }
        if (
          this.state.localGuardianPhone === "" ||
          this.state.localGuardianPhone.length !== 10
        ) {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Enter Guardian's valid Number"
          )
          return
        }
      }
      if (this.state.primaryParent === "LEG") {
        if (this.state.legalGuardianFirstName === "") {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Enter Legal Guardian's first name"
          )
          return
        }
        if (this.state.legalGuardianLastName === "") {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Enter Legal Guardian's last name"
          )
          return
        }
        if (!validateEmail(this.state.legalGuardianEmail)) {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Enter Legal Guardian Valid Email"
          )
          return
        }
        if (
          this.state.legalGuardianPhone === "" ||
          this.state.legalGuardianPhone.length !== 10
        ) {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Enter Guardian's valid Number"
          )
          return
        }
      }
      // let hasError = false
      //   if(!hasError){
      let object = {
        studentId: this.props.location.state.studentId,
        parentId: this.state.parentId,
        primaryParent: this.state.primaryParent,
        isFatherEmployeeOfSchool: this.state.isFatherEmployeeOfSchool,
        fatherFirstName: this.state.fatherFirstName,
        fatherLastName: this.state.fatherLastName,
        fatherDOB: this.state.fatherDOB,
        fatherEmail: this.state.fatherEmail,
        fatherOccupation: this.state.fatherOccupation,
        fatherPhone: this.state.fatherPhone,
        fatherQualification: this.state.fatherQualification,
        fatherAnnualIncome: this.state.fatherAnnualIncome,
        isMotherEmployeeOfSchool: this.state.isMotherEmployeeOfSchool,
        motherFirstName: this.state.motherFirstName,
        motherLastName: this.state.motherLastName,
        motherDOB: this.state.motherDOB,
        motherEmail: this.state.motherEmail,
        motherOccupation: this.state.motherOccupation,
        motherPhone: this.state.motherPhone,
        motherQualification: this.state.motherQualification,
        motherAnnualIncome: this.state.motherAnnualIncome,
        localGuardianFirstName: this.state.localGuardianFirstName,
        localGuardianLastName: this.state.localGuardianLastName,
        localGuardianRelation: this.state.localGuardianRelation,
        localGuardianCity: this.state.localGuardianCity,
        localGuardianStreet: this.state.localGuardianStreet,
        localGuardianCountry: this.state.localGuardianCountry,
        localGuardianState: this.state.localGuardianState,
        localGuardianEmail: this.state.localGuardianEmail,
        localGuardianZipCode: this.state.localGuardianZipCode,
        localGuardianPhone: this.state.localGuardianPhone,
        localGuardianGender: this.state.localGuardianGender,
        isLocalGuardianDetailsShown: false,
        legalGuardianFirstName: this.state.legalGuardianFirstName,
        legalGuardianLastName: this.state.legalGuardianLastName,
        legalGuardianRelation: this.state.legalGuardianRelation,
        legalGuardianCity: this.state.legalGuardianCity,
        legalGuardianStreet: this.state.legalGuardianStreet,
        legalGuardianCountry: this.state.legalGuardianCountry,
        legalGuardianState: this.state.legalGuardianState,
        legalGuardianEmail: this.state.legalGuardianEmail,
        legalGuardianZipCode: this.state.legalGuardianZipCode,
        legalGuardianPhone: this.state.legalGuardianPhone,
        legalGuardianGender: this.state.legalGuardianGender,
        isLegalGuardianDetailsShown: false,
        siblings: [
          {
            id: this.state.siblingsId ? this.state.siblingsId : uuid(),
            studyingInSameSchool: this.state.studyingInSameSchool,
            siblingFirstName: this.state.siblingFirstName,
            siblingLastName: this.state.siblingLastName,
            gender: this.state.siblingGender,
            dob: this.state.siblingDOB,
            institution: this.state.siblingStudyingInstitution,
            course: this.state.siblingStudyingCourse,
            email: this.state.siblingEmail,
            phone: this.state.siblingMobileNo,
          },
        ],
      }
      this.props.updateParentExtendInfo(object, (success, response) => {
        if (success) {
          this.getStudentProfile()
        }
      })
      // }
    }
  }

  addSibling = () => {
    let newsibling = this.state.siblings
    newsibling.push({})
    this.setstate({ siblings: newsibling })
  }
  formCancel = () => {
    let object = {
      studentId: this.props.location.state.studentId,
    }
    this.props.getStudentProfileExtendInfo(object, (success, response) => {
      if (success) {
        const studentDetails = response.studentDetail
        this.setState({ rollNo: studentDetails.registerRollNumber })
        document.getElementById(
          "outlined-rollNo-input"
        ).value = this.state.rollNo
      }
    })
  }

  render() {
    // const { classes } = this.props
    return (
      <AppWrapper
        teacherDashboard
        showTeacherThree
        showTeacherLogo
        screenName={"Student Profile Details"}
      >
        <ThemeProvider theme={theme}>
          <div
            className="p-3 mx-auto br-10"
            style={{ backgroundColor: "#fafcff", width: "70%" }}
          >
            <div className="d-flex">
              <div
                className={`cursor-pointer font-weight-bold px-2 py-1`}
                style={{
                  color:
                    this.state.selectedTab === "Personal"
                      ? "rgb(9, 177, 144)"
                      : "#bcbcbc",
                  borderBottom:
                    this.state.selectedTab === "Personal"
                      ? "3px solid rgb(9, 177, 144)"
                      : "3px solid transparent",
                }}
                onClick={() => this.setState({ selectedTab: "Personal" })}
              >
                Personal
              </div>
              <div
                className={`cursor-pointer font-weight-bold px-2 py-1 ml-3`}
                style={{
                  color:
                    this.state.selectedTab === "Parents"
                      ? "rgb(9, 177, 144)"
                      : "#bcbcbc",
                  borderBottom:
                    this.state.selectedTab === "Parents"
                      ? "3px solid rgb(9, 177, 144)"
                      : "3px solid transparent",
                }}
                onClick={() => this.setState({ selectedTab: "Parents" })}
              >
                Parents
              </div>
            </div>
            {this.state.selectedTab === "Personal" && (
              <>
                <div className="d-flex my-4 align-items-center">
                  <img
                    style={{ height: 50, width: 50, borderRadius: 50 }}
                    src={require("../../assets/images/blankdp.png")}
                    alt=""
                  />
                  <div
                    className="ml-3 text-brand font-weight-bold"
                    style={{ fontSize: 18 }}
                  >
                    Student
                  </div>
                </div>
                <form>
                  <div className="mt-3 card bg-white p-3 br-10">
                    <div className="form-row ">
                      <div className="col-sm">
                        <TextField
                          size="small"
                          margin="normal"
                          id="outlined-firstName-input"
                          label="First Name"
                          // className={classes.textField}
                          type="text"
                          name="name"
                          variant="outlined"
                          fullWidth
                          value={this.state.studentFirstName}
                          onChange={e =>
                            this.setState({ studentFirstName: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-sm-1"></div>
                      <div className="col-sm">
                        <TextField
                          id="outlined-lastName-input"
                          margin="normal"
                          label="Last Name"
                          size="small"
                          // className={classes.textField}
                          type="text"
                          name="name"
                          variant="outlined"
                          fullWidth
                          value={this.state.studentLastName}
                          onChange={e =>
                            this.setState({ studentLastName: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-sm">
                        <TextField
                          id="outlined-select-studentGender-native"
                          select
                          size="small"
                          label="Gender"
                          margin="normal"
                          fullWidth
                          value={this.state.studentGender}
                          onChange={e => {
                            this.setState({ studentGender: e.target.value })
                          }}
                          SelectProps={{
                            native: true,
                          }}
                          variant="outlined"
                        >
                          <option>Select</option>
                          <option>Male</option>
                          <option>Female</option>
                          <option>Others</option>
                        </TextField>
                      </div>
                      <div className="col-sm-1"></div>
                      <div className="col-sm">
                        <TextField
                          id="outlined-dateOfBirth-input"
                          label="Date of Birth."
                          size="small"
                          // className={classes.textField}
                          type="date"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={this.state.birthDate}
                          InputLabelProps={{ shrink: true }}
                          onChange={e => {
                            this.setState({ birthDate: e.target.value })
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-sm">
                        <TextField
                          id="outlined-admissionNo-input"
                          label="Admission No."
                          // className={classes.textField}
                          type="number"
                          size="small"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={this.state.admissionNo}
                          onChange={e =>
                            this.setState({ admissionNo: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-sm-1"></div>
                      <div className="col-sm">
                        <TextField
                          id="outlined-dateOfJoining-input"
                          label="Date of joining."
                          size="small"
                          // className={classes.textField}
                          type="date"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={this.state.joiningDate}
                          InputLabelProps={{ shrink: true }}
                          onChange={e =>
                            this.setState({ joiningDate: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-sm">
                        <TextField
                          id="outlined-rollNo-input"
                          label="Roll No."
                          size="small"
                          // className={classes.textField}
                          type="number"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={this.state.rollNo}
                          onChange={e =>
                            this.setState({ rollNo: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-sm-1"></div>
                      <div className="col-sm">
                        <TextField
                          id="outlined-studentContactNumber-input"
                          label="Contact No."
                          size="small"
                          // className={classes.textField}
                          type="number"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={this.state.studentContactNumber}
                          onChange={e =>
                            this.setState({
                              studentContactNumber: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-sm">
                        <TextField
                          id="outlined-studentEmail-input"
                          label="Email"
                          size="small"
                          // className={classes.textField}
                          type="email"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={this.state.studentEmail}
                          onChange={e =>
                            this.setState({ studentEmail: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-sm-1" />
                      <div className="col-sm" />
                    </div>
                  </div>
                  <div className="text-brand mt-5 font-weight-bold">
                    Extended details
                  </div>
                  <div className="card bg-white p-3 br-10">
                    {this.state.isAddExtendedDetailsShown ? (
                      <>
                        <div className="form-row">
                          <div className="col-sm">
                            <TextField
                              id="outlined-select-studentBloodGroup-native"
                              select
                              size="small"
                              label="Blood Group"
                              margin="normal"
                              fullWidth
                              value={this.state.studentBloodGroup}
                              onChange={e =>
                                this.setState({
                                  studentBloodGroup: e.target.value,
                                })
                              }
                              SelectProps={{
                                native: true,
                              }}
                              variant="outlined"
                            >
                              {this.state.studentBloodGroup && (
                                <option value={this.state.studentBloodGroup}>
                                  {this.state.studentBloodGroup}
                                </option>
                              )}
                              <option>Select</option>
                              <option>A+</option>
                              <option>B-</option>
                              <option>O+</option>
                            </TextField>
                          </div>
                          <div className="col-sm-1"></div>
                          <div className="col-sm">
                            <TextField
                              size="small"
                              margin="normal"
                              id="outlined-studentSchoolHouse-input"
                              label="School House"
                              // className={classes.textField}
                              type="text"
                              name="name"
                              variant="outlined"
                              fullWidth
                              value={this.state.studentSchoolHouse}
                              onChange={e =>
                                this.setState({
                                  studentSchoolHouse: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-sm">
                            <TextField
                              id="outlined-studentHeight-input"
                              label="Height"
                              // className={classes.textField}
                              type="number"
                              size="small"
                              variant="outlined"
                              fullWidth
                              margin="normal"
                              value={this.state.studentHeight}
                              onChange={e =>
                                this.setState({ studentHeight: e.target.value })
                              }
                            />
                          </div>
                          <div className="col-sm-1"></div>
                          <div className="col-sm">
                            <TextField
                              id="outlined-studentWeight-input"
                              label="Weight"
                              // className={classes.textField}
                              type="number"
                              size="small"
                              variant="outlined"
                              fullWidth
                              margin="normal"
                              value={this.state.studentWeight}
                              onChange={e =>
                                this.setState({ studentWeight: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-sm">
                            <TextField
                              id="outlined-select-studentMotherTongue-native"
                              select
                              size="small"
                              label="Mother Tongue"
                              margin="normal"
                              fullWidth
                              value={this.state.studentMotherTongue}
                              onChange={e =>
                                this.setState({
                                  studentMotherTongue: e.target.value,
                                })
                              }
                              SelectProps={{
                                native: true,
                              }}
                              variant="outlined"
                            >
                              {this.state.studentMotherTongue && (
                                <option value={this.state.studentMotherTongue}>
                                  {this.state.studentMotherTongue}
                                </option>
                              )}
                              <option>Select</option>
                              <option value="Hindi">Hindi</option>
                              <option value="English">English</option>
                              <option value="Gujarati">Gujarati</option>
                              <option value="Tamil">Tamil</option>
                              <option value="Marathi">Marathi</option>
                            </TextField>
                          </div>
                          <div className="col-sm-1" />
                          <div className="col-sm">
                            <TextField
                              size="small"
                              margin="normal"
                              id="outlined-studentNationality-input"
                              label="Nationality"
                              // className={classes.textField}
                              type="text"
                              name="name"
                              variant="outlined"
                              fullWidth
                              value={this.state.studentNationality}
                              onChange={e =>
                                this.setState({
                                  studentNationality: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-sm">
                            <TextField
                              id="outlined-select-studentReligion-native"
                              select
                              size="small"
                              label="Religion"
                              margin="normal"
                              fullWidth
                              value={this.state.studentReligion}
                              onChange={e =>
                                this.setState({
                                  studentReligion: e.target.value,
                                })
                              }
                              SelectProps={{
                                native: true,
                              }}
                              variant="outlined"
                            >
                              {this.state.studentReligion && (
                                <option value={this.state.studentReligion}>
                                  {this.state.studentReligion}
                                </option>
                              )}
                              <option>Select</option>
                              <option value="Hinduism">Hinduism</option>
                              <option value="Jainism">Jainism</option>
                              <option value="Sikhism">Sikhism</option>
                              <option value="Christianity">Christianity</option>
                              <option value="Buddhism">Buddhism</option>
                              <option value="Other">Other</option>
                            </TextField>
                          </div>
                          <div className="col-sm-1" />
                          <div className="col-sm" />
                        </div>
                      </>
                    ) : (
                      <div
                        className="d-flex text-brand align-items-center cursor-pointer"
                        onClick={() =>
                          this.setState({
                            isAddExtendedDetailsShown: !this.state
                              .isAddExtendedDetailsShown,
                          })
                        }
                      >
                        <i className="fas fa-plus-circle fa-lg"></i>
                        <div className="ml-3">Add extended details</div>
                      </div>
                    )}
                  </div>
                  <div className="text-brand mt-5 font-weight-bold">
                    Address
                  </div>
                  <div className="card bg-white p-3 br-10">
                    <div className="form-row">
                      <div className="col-sm">
                        <TextField
                          size="small"
                          margin="normal"
                          id="outlined-studentAddressStreet-input"
                          label="Street"
                          type="text"
                          name="name"
                          variant="outlined"
                          fullWidth
                          value={this.state.studentAddressStreet}
                          onChange={e =>
                            this.setState({
                              studentAddressStreet: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-sm-1" />
                      <div className="col-sm">
                        <TextField
                          size="small"
                          margin="normal"
                          id="outlined-studentCity-input"
                          label="City"
                          type="text"
                          name="name"
                          variant="outlined"
                          fullWidth
                          value={this.state.studentCity}
                          onChange={e =>
                            this.setState({
                              studentCity: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-sm">
                        <TextField
                          size="small"
                          margin="normal"
                          id="outlined-studentState-input"
                          label="State"
                          type="text"
                          name="name"
                          variant="outlined"
                          fullWidth
                          value={this.state.studentState}
                          onChange={e =>
                            this.setState({
                              studentState: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-sm-1" />
                      <div className="col-sm">
                        <TextField
                          size="small"
                          margin="normal"
                          id="outlined-studentCountry-input"
                          label="Country"
                          type="text"
                          name="name"
                          variant="outlined"
                          fullWidth
                          value={this.state.studentCountry}
                          onChange={e =>
                            this.setState({
                              studentCountry: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-sm">
                        <TextField
                          id="outlined-studentCityPincode-input"
                          label="Zip/Postal Code"
                          // className={classes.textField}
                          type="number"
                          size="small"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={this.state.studentCityPincode}
                          onChange={e =>
                            this.setState({
                              studentCityPincode: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-sm-1" />
                      <div className="col-sm" />
                    </div>
                  </div>
                  <div className="text-brand mt-5 font-weight-bold">
                    Previous School Details
                  </div>
                  <div className="card bg-white p-3 br-10">
                    {this.state.isPreviousSchoolDetailsShown ? (
                      <>
                        <div className="form-row">
                          <div className="col-sm">
                            <TextField
                              size="small"
                              margin="normal"
                              id="outlined-studentPreviousSchoolName-input"
                              label="School Name"
                              // className={classes.textField}
                              type="text"
                              name="name"
                              variant="outlined"
                              fullWidth
                              value={this.state.studentPreviousSchoolName}
                              onChange={e =>
                                this.setState({
                                  studentPreviousSchoolName: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="col-sm-1" />
                          <div className="col-sm">
                            <TextField
                              id="outlined-select-studentPreviousBoard-native"
                              select
                              size="small"
                              label="Board"
                              margin="normal"
                              fullWidth
                              value={this.state.studentPreviousBoard}
                              onChange={e =>
                                this.setState({
                                  studentPreviousBoard: e.target.value,
                                })
                              }
                              SelectProps={{
                                native: true,
                              }}
                              variant="outlined"
                            >
                              {this.state.studentPreviousBoard && (
                                <option value={this.state.studentPreviousBoard}>
                                  {this.state.studentPreviousBoard}
                                </option>
                              )}
                              <option>Select</option>
                              <option value="CBSE">CBSE</option>
                              <option value="ICSE">ICSE</option>
                              <option value="State Board">State Board</option>
                            </TextField>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-sm">
                            <TextField
                              size="small"
                              margin="normal"
                              id="outlined-studentPassOutClass-input"
                              label="Passing Out Class"
                              // className={classes.textField}
                              type="text"
                              name="name"
                              variant="outlined"
                              fullWidth
                              value={this.state.studentPassOutClass}
                              onChange={e =>
                                this.setState({
                                  studentPassOutClass: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="col-sm-1" />
                          <div className="col-sm">
                            <TextField
                              id="outlined-studentPreviousClassPercent-input"
                              label="Percentage Mark"
                              // className={classes.textField}
                              type="number"
                              size="small"
                              variant="outlined"
                              fullWidth
                              margin="normal"
                              value={this.state.studentPreviousClassPercent}
                              onChange={e =>
                                this.setState({
                                  studentPreviousClassPercent: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        {/* <div
                          className="d-flex mt-4 py-2 cursor-pointer px-3 align-items-center br-10"
                          style={{
                            backgroundColor: "rgba(9, 177, 144,0.29)",
                            color: "#09b190",
                            maxWidth: "23%",
                          }}
                        >
                          <i className="fal fa-plus fa-lg"></i>
                          <div className="ml-3">Attach Report Card</div>
                        </div> */}
                      </>
                    ) : (
                      <div
                        className="d-flex text-brand align-items-center cursor-pointer"
                        onClick={() =>
                          this.setState({
                            isPreviousSchoolDetailsShown: !this.state
                              .isPreviousSchoolDetailsShown,
                          })
                        }
                      >
                        <i className="fas fa-plus-circle fa-lg"></i>
                        <div className="ml-3">Add Previous School Details</div>
                      </div>
                    )}
                  </div>
                  <div className="d-flex mt-4">
                    <div className="d-flex ml-auto">
                      <div
                        className=" py-2 cursor-pointer px-3 align-items-center br-10 rounded-pill"
                        style={{
                          backgroundColor: "#DBDBDB",
                          color: "#06B191",
                        }}
                        onClick={this.formCancel}
                      >
                        Cancel
                      </div>

                      <div
                        className=" py-2 cursor-pointer px-3 align-items-center br-10 ml-3 rounded-pill "
                        style={{
                          background: "#06b191",
                          border: "none",
                          color: "white",
                        }}
                        onClick={() => {
                          this.onSaveStudentDetails()
                        }}
                      >
                        Save
                      </div>
                    </div>
                  </div>
                </form>
              </>
            )}

            {this.state.selectedTab === "Parents" && (
              <>
                <div className="d-flex align-items-center mt-3">
                  <div>primary Contact</div>
                  <div className="form-check form-check-inline ml-5">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="primaryContact"
                      id="Father"
                      value="Father"
                      checked={this.state.primaryParent === "FATHER"}
                      onChange={e => {
                        this.setState({
                          primaryParent: "FATHER",
                          hasPrimaryparent: true,
                        })
                      }}
                    />
                    <label className="form-check-label" htmlFor="Father">
                      Father
                    </label>
                  </div>
                  <div className="form-check form-check-inline ml-5">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="primaryContact"
                      id="Mother"
                      value="Mother"
                      checked={this.state.primaryParent === "MOTHER"}
                      onChange={e => {
                        this.setState({
                          primaryParent: "MOTHER",
                          hasPrimaryparent: true,
                        })
                      }}
                    />
                    <label className="form-check-label" htmlFor="Mother">
                      Mother
                    </label>
                  </div>
                  <div className="form-check form-check-inline ml-5">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="primaryContact"
                      id="localGuardian"
                      value="localGuardian"
                      checked={this.state.primaryParent === "LOG"}
                      onChange={e => {
                        this.setState({
                          primaryParent: "LOG",
                          hasPrimaryparent: true,
                        })
                      }}
                    />
                    <label className="form-check-label" htmlFor="localGuardian">
                      Local Guardian
                    </label>
                  </div>
                  <div className="form-check form-check-inline ml-5">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="primaryContact"
                      id="legalGuardian"
                      value="legalGuardian"
                      checked={this.state.primaryParent === "LEG"}
                      onChange={e => {
                        this.setState({
                          primaryParent: "LEG",
                          hasPrimaryparent: true,
                        })
                      }}
                    />
                    <label className="form-check-label" htmlFor="legalGuardian">
                      Legal Guardian
                    </label>
                  </div>
                </div>
                <div className="text-muted my-5" style={{ fontSize: 14 }}>
                  School will use the primary contact only to communicate any
                  announcements
                </div>
                <div className="d-flex align-items-center text-brand">
                  <div className="d-flex align-items-center">
                    <img
                      style={{ height: 50, width: 50, borderRadius: 50 }}
                      src={require("../../assets/images/blankdp.png")}
                      alt=""
                    />
                    <div className="ml-3 font-weight-bold fs-18">Father</div>
                  </div>
                  <div className="d-flex align-items-center ml-auto">
                    <div className="fs-18">Employee of School</div>
                    <div className="custom-control custom-switch ml-4">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="isFatherEmployeeOfSchool"
                        value={this.state.isFatherEmployeeOfSchool}
                        checked={this.state.isFatherEmployeeOfSchool}
                        onChange={e =>
                          this.setState({
                            isFatherEmployeeOfSchool: !this.state
                              .isFatherEmployeeOfSchool,
                          })
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="isFatherEmployeeOfSchool"
                      ></label>
                    </div>
                  </div>
                </div>
                <form>
                  <div className="mt-3 card bg-white p-3 br-10">
                    <div className="form-row ">
                      <div className="col-sm">
                        <TextField
                          size="small"
                          margin="normal"
                          id="outlined-fatherFirstName-input"
                          label="First Name"
                          // className={classes.textField}
                          type="text"
                          name="name"
                          variant="outlined"
                          fullWidth
                          value={this.state.fatherFirstName}
                          onChange={e =>
                            this.setState({ fatherFirstName: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-sm-1"></div>
                      <div className="col-sm">
                        <TextField
                          id="outlined-fatherLastName-input"
                          margin="normal"
                          label="Last Name"
                          size="small"
                          // className={classes.textField}
                          type="text"
                          name="name"
                          variant="outlined"
                          fullWidth
                          value={this.state.fatherLastName}
                          onChange={e =>
                            this.setState({ fatherLastName: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-sm">
                        <TextField
                          id="outlined-fatherDOB-input"
                          label="Date of Birth."
                          size="small"
                          // className={classes.textField}
                          type="date"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={this.state.fatherDOB}
                          InputLabelProps={{ shrink: true }}
                          onChange={e =>
                            this.setState({ fatherDOB: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-sm-1"></div>
                      <div className="col-sm">
                        <TextField
                          id="outlined-select-fatherQualification-native"
                          select
                          size="small"
                          label="Qualification"
                          margin="normal"
                          fullWidth
                          value={this.state.fatherQualification}
                          onChange={e =>
                            this.setState({
                              fatherQualification: e.target.value,
                            })
                          }
                          SelectProps={{
                            native: true,
                          }}
                          variant="outlined"
                        >
                          <option>Select</option>
                          <option value="bachelors">Bachelors</option>
                          <option value="masters">Masters</option>
                          <option value="BBA">BBA</option>
                          <option value="MBA">MBA</option>
                        </TextField>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-sm">
                        <TextField
                          id="outlined-select-fatherOccupation-native"
                          select
                          size="small"
                          label="Occupation"
                          margin="normal"
                          fullWidth
                          value={this.state.fatherOccupation}
                          onChange={e =>
                            this.setState({ fatherOccupation: e.target.value })
                          }
                          SelectProps={{
                            native: true,
                          }}
                          variant="outlined"
                        >
                          <option>Select</option>
                          <option value="business">Business</option>
                          <option value="engineer">Engineer</option>
                          <option value="doctor">Doctor</option>
                        </TextField>
                      </div>
                      <div className="col-sm-1"></div>
                      <div className="col-sm">
                        <TextField
                          id="outlined-select-fatherAnnualIncome-native"
                          select
                          size="small"
                          label="Annual Income"
                          margin="normal"
                          fullWidth
                          value={this.state.fatherAnnualIncome}
                          onChange={e =>
                            this.setState({
                              fatherAnnualIncome: e.target.value,
                            })
                          }
                          SelectProps={{
                            native: true,
                          }}
                          variant="outlined"
                        >
                          <option>Select</option>
                          <option value="A">A</option>
                          <option value="B">B</option>
                          <option value="C">C</option>
                          <option value="D">D</option>
                        </TextField>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-sm">
                        <TextField
                          id="outlined-fatherEmail-input"
                          label="Email"
                          size="small"
                          // className={classes.textField}
                          type="email"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={this.state.fatherEmail}
                          onChange={e =>
                            this.setState({ fatherEmail: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-sm-1"></div>
                      <div className="col-sm">
                        <TextField
                          id="outlined-fatherPhone-input"
                          label="Contact No."
                          size="small"
                          // className={classes.textField}
                          type="number"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={this.state.fatherPhone}
                          onChange={e =>
                            this.setState({
                              fatherPhone: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex my-4 align-items-center text-brand">
                    <div className="d-flex align-items-center">
                      <img
                        style={{ height: 50, width: 50, borderRadius: 50 }}
                        src={require("../../assets/images/blankdp.png")}
                        alt=""
                      />
                      <div className="ml-3 font-weight-bold fs-18">Mother</div>
                    </div>
                    <div className="d-flex align-items-center ml-auto">
                      <div className="fs-18">Employee of School</div>
                      <div className="custom-control custom-switch ml-4">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="isMotherEmployeeOfSchool"
                          value={this.state.isMotherEmployeeOfSchool}
                          checked={this.state.isMotherEmployeeOfSchool}
                          onChange={e =>
                            this.setState({
                              isMotherEmployeeOfSchool: !this.state
                                .isMotherEmployeeOfSchool,
                            })
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="isMotherEmployeeOfSchool"
                        ></label>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 card bg-white p-3 br-10">
                    <div className="form-row ">
                      <div className="col-sm">
                        <TextField
                          size="small"
                          margin="normal"
                          id="outlined-motherFirstName-input"
                          label="First Name"
                          // className={classes.textField}
                          type="text"
                          name="name"
                          variant="outlined"
                          fullWidth
                          value={this.state.motherFirstName}
                          onChange={e =>
                            this.setState({ motherFirstName: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-sm-1"></div>
                      <div className="col-sm">
                        <TextField
                          id="outlined-motherLastName-input"
                          margin="normal"
                          label="Last Name"
                          size="small"
                          // className={classes.textField}
                          type="text"
                          name="name"
                          variant="outlined"
                          fullWidth
                          value={this.state.motherLastName}
                          onChange={e =>
                            this.setState({ motherLastName: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-sm">
                        <TextField
                          id="outlined-motherDOB-input"
                          label="Date of Birth."
                          size="small"
                          // className={classes.textField}
                          type="date"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={this.state.motherDOB}
                          InputLabelProps={{ shrink: true }}
                          onChange={e =>
                            this.setState({ motherDOB: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-sm-1"></div>
                      <div className="col-sm">
                        <TextField
                          id="outlined-select-motherQualification-native"
                          select
                          size="small"
                          label="Qualification"
                          margin="normal"
                          fullWidth
                          value={this.state.motherQualification}
                          onChange={e =>
                            this.setState({
                              motherQualification: e.target.value,
                            })
                          }
                          SelectProps={{
                            native: true,
                          }}
                          variant="outlined"
                        >
                          <option>Select</option>
                          <option value="bachelors">Bachelors</option>
                          <option value="masters">Masters</option>
                          <option value="BBA">BBA</option>
                          <option value="MBA">MBA</option>
                        </TextField>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-sm">
                        <TextField
                          id="outlined-select-motherOccupation-native"
                          select
                          size="small"
                          label="Occupation"
                          margin="normal"
                          fullWidth
                          value={this.state.motherOccupation}
                          onChange={e =>
                            this.setState({ motherOccupation: e.target.value })
                          }
                          SelectProps={{
                            native: true,
                          }}
                          variant="outlined"
                        >
                          <option>Select</option>
                          <option value="business">Business</option>
                          <option value="engineer">Engineer</option>
                          <option value="doctor">Doctor</option>
                        </TextField>
                      </div>
                      <div className="col-sm-1"></div>
                      <div className="col-sm">
                        <TextField
                          id="outlined-select-motherAnnualIncome-native"
                          select
                          size="small"
                          label="Annual Income"
                          margin="normal"
                          fullWidth
                          value={this.state.motherAnnualIncome}
                          onChange={e =>
                            this.setState({
                              motherAnnualIncome: e.target.value,
                            })
                          }
                          SelectProps={{
                            native: true,
                          }}
                          variant="outlined"
                        >
                          <option>Select</option>
                          <option value="A">A</option>
                          <option value="B">B</option>
                          <option value="C">C</option>
                          <option value="D">D</option>
                        </TextField>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-sm">
                        <TextField
                          id="outlined-motherEmail-input"
                          label="Email"
                          size="small"
                          // className={classes.textField}
                          type="email"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={this.state.motherEmail}
                          onChange={e =>
                            this.setState({ motherEmail: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-sm-1"></div>
                      <div className="col-sm">
                        <TextField
                          id="outlined-motherPhone-input"
                          label="Contact No."
                          size="small"
                          // className={classes.textField}
                          type="number"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={this.state.motherPhone}
                          onChange={e =>
                            this.setState({
                              motherPhone: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex my-4 align-items-center text-brand">
                    <img
                      style={{ height: 50, width: 50, borderRadius: 50 }}
                      src={require("../../assets/images/blankdp.png")}
                      alt=""
                    />
                    <div
                      className="ml-3 font-weight-bold"
                      style={{ fontSize: 18 }}
                    >
                      Local Guardian
                    </div>
                  </div>
                  <div className="mt-3 card bg-white p-3 br-10">
                    {this.state.isLocalGuardianDetailsShown ? (
                      <>
                        <div className="form-row ">
                          <div className="col-sm">
                            <TextField
                              size="small"
                              margin="normal"
                              id="outlined-localGuardianFirstName-input"
                              label="First Name"
                              // className={classes.textField}
                              type="text"
                              name="name"
                              variant="outlined"
                              fullWidth
                              value={this.state.localGuardianFirstName}
                              onChange={e =>
                                this.setState({
                                  localGuardianFirstName: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="col-sm-1"></div>
                          <div className="col-sm">
                            <TextField
                              id="outlined-localGuardianLastName-input"
                              margin="normal"
                              label="Last Name"
                              size="small"
                              // className={classes.textField}
                              type="text"
                              name="name"
                              variant="outlined"
                              fullWidth
                              value={this.state.localGuardianLastName}
                              onChange={e =>
                                this.setState({
                                  localGuardianLastName: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-sm">
                            <TextField
                              size="small"
                              margin="normal"
                              id="outlined-localGuardianRelation-input"
                              label="Relation"
                              // className={classes.textField}
                              type="text"
                              name="name"
                              variant="outlined"
                              fullWidth
                              value={this.state.localGuardianRelation}
                              onChange={e =>
                                this.setState({
                                  localGuardianRelation: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="col-sm-1"></div>
                          <div className="col-sm">
                            <TextField
                              size="small"
                              margin="normal"
                              id="outlined-localGuardianStreet-input"
                              label="Street"
                              // className={classes.textField}
                              type="text"
                              name="name"
                              variant="outlined"
                              fullWidth
                              value={this.state.localGuardianStreet}
                              onChange={e =>
                                this.setState({
                                  localGuardianStreet: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-sm">
                            <TextField
                              id="outlined-select-localGuardianCity-native"
                              select
                              size="small"
                              label="City"
                              margin="normal"
                              fullWidth
                              value={this.state.localGuardianCity}
                              onChange={e =>
                                this.setState({
                                  localGuardianCity: e.target.value,
                                })
                              }
                              SelectProps={{
                                native: true,
                              }}
                              variant="outlined"
                            >
                              <option>Select</option>
                              <option value="AHM">Ahmedabad</option>
                              <option value="GN">Gandhinagar</option>
                              <option value="SU">Surat</option>
                            </TextField>
                          </div>
                          <div className="col-sm-1"></div>
                          <div className="col-sm">
                            <TextField
                              id="outlined-select-localGuardianCountry-native"
                              select
                              size="small"
                              label="Country"
                              margin="normal"
                              fullWidth
                              value={this.state.localGuardianCountry}
                              onChange={e =>
                                this.setState({
                                  localGuardianCountry: e.target.value,
                                })
                              }
                              SelectProps={{
                                native: true,
                              }}
                              variant="outlined"
                            >
                              <option>Select</option>
                              <option value="India">India</option>
                              <option value="US">Untied States</option>
                              <option value="UK">United Kingdom</option>
                            </TextField>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-sm">
                            <TextField
                              id="outlined-localGuardianZipCode-input"
                              label="Zip/Postal Code"
                              // className={classes.textField}
                              type="number"
                              size="small"
                              variant="outlined"
                              fullWidth
                              margin="normal"
                              value={this.state.localGuardianZipCode}
                              onChange={e =>
                                this.setState({
                                  localGuardianZipCode: e.target.value,
                                })
                              }
                            />
                          </div>

                          <div className="col-sm-1"></div>
                          <div className="col-sm">
                            <TextField
                              id="outlined-localGuardianEmail-input"
                              label="Email"
                              size="small"
                              // className={classes.textField}
                              type="email"
                              variant="outlined"
                              fullWidth
                              margin="normal"
                              value={this.state.localGuardianEmail}
                              onChange={e =>
                                this.setState({
                                  localGuardianEmail: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-sm">
                            <TextField
                              id="outlined-localGuardianPhone-input"
                              label="Contact No."
                              size="small"
                              // className={classes.textField}
                              type="number"
                              variant="outlined"
                              fullWidth
                              margin="normal"
                              value={this.state.localGuardianPhone}
                              onChange={e =>
                                this.setState({
                                  localGuardianPhone: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="col-sm-1" />
                          <div className="col-sm">
                            <TextField
                              id="outlined-select-localGuardianGender-native"
                              select
                              size="small"
                              label="Gender"
                              margin="normal"
                              fullWidth
                              value={this.state.localGuardianGender}
                              onChange={e =>
                                this.setState({
                                  localGuardianGender: e.target.value,
                                })
                              }
                              SelectProps={{
                                native: true,
                              }}
                              variant="outlined"
                            >
                              <option>Select</option>
                              <option>Male</option>
                              <option>Female</option>
                              <option>Others</option>
                            </TextField>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div
                        className="d-flex text-brand align-items-center cursor-pointer"
                        onClick={() =>
                          this.setState({
                            isLocalGuardianDetailsShown: !this.state
                              .isLocalGuardianDetailsShown,
                          })
                        }
                      >
                        <i className="fas fa-plus-circle fa-lg"></i>
                        <div className="ml-3">Add Local Guardian</div>
                      </div>
                    )}
                  </div>
                  {/* Local Guardian */}
                  <div className="d-flex my-4 align-items-center text-brand">
                    <img
                      style={{ height: 50, width: 50, borderRadius: 50 }}
                      src={require("../../assets/images/blankdp.png")}
                      alt=""
                    />
                    <div
                      className="ml-3 font-weight-bold"
                      style={{ fontSize: 18 }}
                    >
                      Legal Guardian
                    </div>
                  </div>
                  <div className="mt-3 card bg-white p-3 br-10">
                    {this.state.isLegalGuardianDetailsShown ? (
                      <>
                        <div className="form-row ">
                          <div className="col-sm">
                            <TextField
                              size="small"
                              margin="normal"
                              id="outlined-legalGuardianFirstName-input"
                              label="First Name"
                              // className={classes.textField}
                              type="text"
                              name="name"
                              variant="outlined"
                              fullWidth
                              value={this.state.legalGuardianFirstName}
                              onChange={e =>
                                this.setState({
                                  legalGuardianFirstName: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="col-sm-1"></div>
                          <div className="col-sm">
                            <TextField
                              id="outlined-legalGuardianLastName-input"
                              margin="normal"
                              label="Last Name"
                              size="small"
                              // className={classes.textField}
                              type="text"
                              name="name"
                              variant="outlined"
                              fullWidth
                              value={this.state.legalGuardianLastName}
                              onChange={e =>
                                this.setState({
                                  legalGuardianLastName: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-sm">
                            <TextField
                              size="small"
                              margin="normal"
                              id="outlined-legalGuardianRelation-input"
                              label="Relation"
                              // className={classes.textField}
                              type="text"
                              name="name"
                              variant="outlined"
                              fullWidth
                              value={this.state.legalGuardianRelation}
                              onChange={e =>
                                this.setState({
                                  legalGuardianRelation: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="col-sm-1"></div>
                          <div className="col-sm">
                            <TextField
                              size="small"
                              margin="normal"
                              id="outlined-legalGuardianStreet-input"
                              label="Street"
                              // className={classes.textField}
                              type="text"
                              name="name"
                              variant="outlined"
                              fullWidth
                              value={this.state.legalGuardianStreet}
                              onChange={e =>
                                this.setState({
                                  legalGuardianStreet: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-sm">
                            <TextField
                              id="outlined-select-legalGuardianCity-native"
                              select
                              size="small"
                              label="City"
                              margin="normal"
                              fullWidth
                              value={this.state.legalGuardianCity}
                              onChange={e =>
                                this.setState({
                                  legalGuardianCity: e.target.value,
                                })
                              }
                              SelectProps={{
                                native: true,
                              }}
                              variant="outlined"
                            >
                              <option>Select</option>
                              <option value="AHM">Ahmedabad</option>
                              <option value="GN">Gandhinagar</option>
                              <option value="SU">Surat</option>
                            </TextField>
                          </div>
                          <div className="col-sm-1"></div>
                          <div className="col-sm">
                            <TextField
                              id="outlined-select-legalGuardianCountry-native"
                              select
                              size="small"
                              label="Country"
                              margin="normal"
                              fullWidth
                              value={this.state.legalGuardianCountry}
                              onChange={e =>
                                this.setState({
                                  legalGuardianCountry: e.target.value,
                                })
                              }
                              SelectProps={{
                                native: true,
                              }}
                              variant="outlined"
                            >
                              <option>Select</option>
                              <option value="India">India</option>
                              <option value="US">Untied States</option>
                              <option value="UK">United Kingdom</option>
                            </TextField>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-sm">
                            <TextField
                              id="outlined-legalGuardianZipCode-input"
                              label="Zip/Postal Code"
                              // className={classes.textField}
                              type="number"
                              size="small"
                              variant="outlined"
                              fullWidth
                              margin="normal"
                              value={this.state.legalGuardianZipCode}
                              onChange={e =>
                                this.setState({
                                  legalGuardianZipCode: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="col-sm-1"></div>
                          <div className="col-sm">
                            <TextField
                              id="outlined-legalGuardianEmail-input"
                              label="Email"
                              size="small"
                              // className={classes.textField}
                              type="email"
                              variant="outlined"
                              fullWidth
                              margin="normal"
                              value={this.state.legalGuardianEmail}
                              onChange={e =>
                                this.setState({
                                  legalGuardianEmail: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-sm">
                            <TextField
                              id="outlined-legalGuardianPhone-input"
                              label="Contact No."
                              size="small"
                              // className={classes.textField}
                              type="number"
                              variant="outlined"
                              fullWidth
                              margin="normal"
                              value={this.state.legalGuardianPhone}
                              onChange={e =>
                                this.setState({
                                  legalGuardianPhone: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="col-sm-1" />
                          <div className="col-sm">
                            <TextField
                              id="outlined-select-legalGuardianGender-native"
                              select
                              size="small"
                              label="Gender"
                              margin="normal"
                              fullWidth
                              value={this.state.legalGuardianGender}
                              onChange={e =>
                                this.setState({
                                  legalGuardianGender: e.target.value,
                                })
                              }
                              SelectProps={{
                                native: true,
                              }}
                              variant="outlined"
                            >
                              <option>Select</option>
                              <option>Male</option>
                              <option>Female</option>
                              <option>Others</option>
                            </TextField>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div
                        className="d-flex text-brand align-items-center cursor-pointer"
                        onClick={() =>
                          this.setState({
                            isLegalGuardianDetailsShown: !this.state
                              .isLegalGuardianDetailsShown,
                          })
                        }
                      >
                        <i className="fas fa-plus-circle fa-lg"></i>
                        <div className="ml-3">Add Legal Guardian</div>
                      </div>
                    )}
                  </div>
                  <div className="d-flex my-4 align-items-center text-brand">
                    <div className="d-flex align-items-center">
                      <img
                        style={{ height: 50, width: 50, borderRadius: 50 }}
                        src={require("../../assets/images/blankdp.png")}
                        alt=""
                      />
                      <div className="ml-3 font-weight-bold fs-18">Sibling</div>
                    </div>
                    <div className="d-flex align-items-center ml-auto">
                      <div className="fs-18">Studying in same school</div>
                      <div className="custom-control custom-switch ml-4">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="studyingInSameSchool"
                          checked={this.state.studyingInSameSchool}
                          value={this.state.studyingInSameSchool}
                          onChange={e => {
                            if (e.target.checked) {
                              this.setState({ studyingInSameSchool: true })
                            } else {
                              this.setState({ studyingInSameSchool: false })
                            }
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="studyingInSameSchool"
                        ></label>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 card bg-white p-3 br-10">
                    {this.state.isSiblingDetailsShown ? (
                      <div
                        className="d-flex text-brand align-items-center cursor-pointer"
                        onClick={() =>
                          this.setState({
                            isSiblingDetailsShown: !this.state
                              .isSiblingDetailsShown,
                          })
                        }
                      >
                        <i className="fas fa-plus-circle fa-lg"></i>
                        <div className="ml-3">Add Sibling</div>
                      </div>
                    ) : (
                      <>
                        <div className="form-row ">
                          <div className="col-sm">
                            <TextField
                              size="small"
                              margin="normal"
                              id="outlined-siblingFirstName-input"
                              label="First Name"
                              // className={classes.textField}
                              type="text"
                              name="name"
                              variant="outlined"
                              fullWidth
                              value={this.state.siblingFirstName}
                              onChange={e =>
                                this.setState({
                                  siblingFirstName: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="col-sm-1"></div>
                          <div className="col-sm">
                            <TextField
                              id="outlined-siblingLastName-input"
                              margin="normal"
                              label="Last Name"
                              size="small"
                              // className={classes.textField}
                              type="text"
                              name="name"
                              variant="outlined"
                              fullWidth
                              value={this.state.siblingLastName}
                              onChange={e =>
                                this.setState({
                                  siblingLastName: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-sm">
                            <TextField
                              id="outlined-select-siblingGender-native"
                              select
                              size="small"
                              label="Gender"
                              margin="normal"
                              fullWidth
                              value={this.state.siblingGender}
                              onChange={e =>
                                this.setState({ siblingGender: e.target.value })
                              }
                              SelectProps={{
                                native: true,
                              }}
                              variant="outlined"
                            >
                              <option>Select</option>
                              <option>Male</option>
                              <option>Female</option>
                              <option>Others</option>
                            </TextField>
                          </div>
                          <div className="col-sm-1"></div>
                          <div className="col-sm">
                            <TextField
                              id="outlined-siblingDOB-input"
                              label="Date of Birth."
                              size="small"
                              // className={classes.textField}
                              type="date"
                              variant="outlined"
                              fullWidth
                              margin="normal"
                              value={this.state.siblingDOB}
                              InputLabelProps={{ shrink: true }}
                              onChange={e =>
                                this.setState({ siblingDOB: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-sm">
                            <TextField
                              size="small"
                              margin="normal"
                              id="outlined-siblingStudyingInstitution-input"
                              label="Institution/Firm"
                              // className={classes.textField}
                              type="text"
                              name="name"
                              variant="outlined"
                              fullWidth
                              value={this.state.siblingStudyingInstitution}
                              onChange={e =>
                                this.setState({
                                  siblingStudyingInstitution: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="col-sm-1" />
                          <div className="col-sm">
                            <TextField
                              id="outlined-select-siblingStudyingCourse-native"
                              select
                              size="small"
                              label="Course/Designation"
                              margin="normal"
                              fullWidth
                              value={this.state.siblingStudyingCourse}
                              onChange={e =>
                                this.setState({
                                  siblingStudyingCourse: e.target.value,
                                })
                              }
                              SelectProps={{
                                native: true,
                              }}
                              variant="outlined"
                            >
                              <option>Select</option>
                              <option value="A">A</option>
                              <option value="B">B</option>
                              <option value="C">C</option>
                            </TextField>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-sm">
                            <TextField
                              id="outlined-siblingEmail-input"
                              label="Email"
                              size="small"
                              // className={classes.textField}
                              type="email"
                              variant="outlined"
                              fullWidth
                              margin="normal"
                              value={this.state.siblingEmail}
                              onChange={e =>
                                this.setState({ siblingEmail: e.target.value })
                              }
                            />
                          </div>
                          <div className="col-sm-1"></div>
                          <div className="col-sm">
                            <TextField
                              id="outlined-siblingMobileNo-input"
                              label="Contact No."
                              size="small"
                              // className={classes.textField}
                              type="number"
                              variant="outlined"
                              fullWidth
                              margin="normal"
                              value={this.state.siblingMobileNo}
                              onChange={e =>
                                this.setState({
                                  siblingMobileNo: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </form>
                <div
                  className="d-flex mt-4 "
                  style={{ justifyContent: "flex-end" }}
                >
                  <div
                    className=" py-2 cursor-pointer px-3 align-items-center br-10 rounded-pill"
                    style={{
                      backgroundColor: "#DBDBDB",
                      color: "#06B191",
                    }}
                  >
                    Cancel
                  </div>

                  <div
                    className=" py-2 cursor-pointer px-3 align-items-center br-10 ml-3 rounded-pill"
                    style={{
                      background: "#06b191",
                      border: "none",
                      color: "white",
                    }}
                    onClick={() => {
                      this.onSaveParentDetails()
                    }}
                  >
                    Save
                  </div>
                </div>
              </>
            )}
          </div>
        </ThemeProvider>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getStudentProfileExtendInfo,
      updateStudentProfileExtendInfo,
      updateParentExtendInfo,
      showNotification,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(FormDetails)
