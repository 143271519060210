import React from "react"
import { alphas, change } from "../../common/utils"

export function Header({
  schoolName,
  address,
  exam,
  standard,
  time,
  subject,
  mark,
  instruction = "",
}) {
  return (
    <>
      <div className="text-center">
        <p className="mb-0 fs-14">{schoolName}</p>
        <p className="margb-2 fs-13">{address}</p>
        <h3 className="mb-1">{exam}</h3>
        <h3 className="margb-2">Class {standard}</h3>
      </div>

      <div className="df justify-between margb-2_5">
        <h3 className="mb-0">Time: {time}.</h3>
        <h3 className="mb-0 text-center">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {subject}
        </h3>
        <h3 className="mb-0">Total Marks: {mark}</h3>
      </div>

      <div className="instrction">
        <p className="mb-0 fw-700">General Instructions:</p>
        {instruction.split("\n").map((d, i) => (
          <p key={i} className="mb-0">
            {d}
          </p>
        ))}
      </div>
    </>
  )
}

export function ParaType({
  sectioNo,
  sectionName = "",
  mark,
  count,
  questions = [],
  prevNum = 0,
}) {
  return (
    <div className="margb-12">
      <h3 className="df justify-between">
        <span>
          SECTION {sectioNo}: {sectionName}
        </span>
        <span>
          {count} x {mark} = {count * mark}
        </span>
      </h3>
      {questions.map((q, i) => (
        <p key={i} style={{ whiteSpace: "pre-wrap" }}>
          {prevNum + i + 1}. {q.text}
        </p>
      ))}
    </div>
  )
}

export function ChooseType({
  sectioNo,
  sectionName = "Choose the correct answer",
  mark = 1,
  count,
  questions = [],
  prevNum = 0,
}) {
  return (
    <div className="margb-12">
      <h3 className="df justify-between">
        <span>
          SECTION {sectioNo}: {sectionName}
        </span>
        <span>
          {count} x {mark} = {count * mark}
        </span>
      </h3>

      {questions.map((q, i) => (
        <React.Fragment key={i}>
          <p className="margb-1" style={{ whiteSpace: "pre-wrap" }}>
            {prevNum + i + 1}. {q.text}
          </p>
          <ul>
            <li>{change(q.question[0], 0)}</li>
            <li>{change(q.question[1], 1)}</li>
            <li></li>
            <li></li>
            <li>{change(q.question[2], 2)}</li>
            <li>{change(q.question[3], 3)}</li>
          </ul>
        </React.Fragment>
      ))}
    </div>
  )
}

export function Mathchtype({
  sectioNo,
  count,
  sectionName = "Match the following",
  mark = 1,
  questions = [],
  prevNum = 0,
}) {
  const questionsTransfered = questions.map(({ question, match, text }) => ({
    text,
    data: question.map((q, i) => ({
      question: q,
      option: match[i] || "",
    })),
  }))

  return (
    <div className="margb-12">
      <h3 className="df justify-between">
        <span>
          SECTION {sectioNo}: {sectionName}
        </span>
        <span>
          {count} x {mark} = {count * mark}
        </span>
      </h3>

      {questionsTransfered.map((que, pI) => (
        <div key={pI}>
          <p>
            {prevNum + pI + 1}. {que.text}
          </p>

          {que.data.map((q, i) => (
            <p className="df match-quest pl-2" key={i}>
              <span>
                {alphas[i]} {q.question}
              </span>
              <span> - </span>
              <span>{q.option}</span>
            </p>
          ))}
        </div>
      ))}
    </div>
  )
}
