export const ACTION_TYPES = {
  LOGIN_USER: "LOGIN_USER",
  USER_INFO: "USER_INFO",
  QUICK_INFO_DETAIL: "QUICK_INFO_DETAIL",
  SUBJECT_LIST: "SUBJECT_LIST",
  SUBJECT_DETAIL: "SUBJECT_DETAIL",
  TEACHER_LIST: "TEACHER_LIST",
  TEACHER_DETAIL: "TEACHER_DETAIL",
  CLASS_LIST: "CLASS_LIST",
  CLASS_DETAIL: "CLASS_DETAIL",
  CLASS_GROUP: "CLASS_GROUP",
  CLASS_GROUP_ALREADY_ASSIGNED: "CLASS_GROUP_ALREADY_ASSIGNED",
  ATTENDANCE_DETAIL: "ATTENDANCE_DETAIL",
  IS_SHOW_LOADING: "IS_SHOW_LOADING",
  SUPER_ADMIN_SCHOOL_LIST_DETAILS: "SUPER_ADMIN_SCHOOL_LIST_DETAILS",
  SUPER_ADMIN_SCHOOL_DETAILS: "SUPER_ADMIN_SCHOOL_DETAILS",
  SUPER_ADMIN_DISPLAY_SCHOOL_NAME_DETAILS:
    "SUPER_ADMIN_DISPLAY_SCHOOL_NAME_DETAILS",
  SCHOOL_PERFORMANCE_REPORT_DETAILS: "SCHOOL_PERFORMANCE_REPORT_DETAILS",
  CLASS_PERFORMANCE_REPORT_DETAILS: "CLASS_PERFORMANCE_REPORT_DETAILS",
  SUBJECT_PERFORMANCE_REPORT_DETAILS: "SUBJECT_PERFORMANCE_REPORT_DETAILS",
  EXAM_LIST_DETAILS: "EXAM_LIST_DETAILS",
  TEACHER_DASBOARD_CLASS_DETAIL: "TEACHER_DASBOARD_CLASS_DETAIL",

  ADMIN_DASBOARD_SCHOOL_DETAIL: "ADMIN_DASBOARD_SCHOOL_DETAIL",
  ADMIN_SCHOOL_PERFORMANCE_REPORT_DETAILS:
    "ADMIN_SCHOOL_PERFORMANCE_REPORT_DETAILS",
  ADMIN_DISPLAY_STUDENT_PERFORMANCE_REPORT_DETAILS:
    "ADMIN_DISPLAY_STUDENT_PERFORMANCE_REPORT_DETAILS",
  ADMIN_SCHOOL_PERFORMANCE_PASS_FAIL_REPORT_DETAILS:
    "ADMIN_SCHOOL_PERFORMANCE_PASS_FAIL_REPORT_DETAILS",
  ADMIN_CLASS_PERFORMANCE_REPORT_DETAILS:
    "ADMIN_CLASS_PERFORMANCE_REPORT_DETAILS",
  ADMIN_SUBJECT_PERFORMANCE_REPORT_DETAILS:
    "ADMIN_SUBJECT_PERFORMANCE_REPORT_DETAILS",
  ADMIN_ATTENDANCE_DETAIL: "ADMIN_ATTENDANCE_DETAIL",
  ADMIN_MARK_UPLOAD_STATUS_DETAIL: "ADMIN_MARK_UPLOAD_STATUS_DETAIL",
  ADMIN_EXAM_LIST_DETAILS: "ADMIN_EXAM_LIST_DETAILS",
  ADMIN_STUDENT_PERFORMANCE_REPORT_DETAILS:
    "ADMIN_STUDENT_PERFORMANCE_REPORT_DETAILS",
  ADMIN_ACADEMIC_YEAR_LIST: "ADMIN_ACADEMIC_YEAR_LIST",
  ADMIN_ONBOARDING_ACADEMIC_YEAR_LIST: "ADMIN_ONBOARDING_ACADEMIC_YEAR_LIST",
  ADMIN_ASSESSMENT_LIST: "ADMIN_ASSESSMENT_LIST",
  ADMIN_GRADE_LIST: "ADMIN_GRADE_LIST",
  ADMIN_TERM_LIST: "ADMIN_TERM_LIST",
  ADMIN_NEWS_LIST: "ADMIN_NEWS_LIST",
  ADMIN_EVENTS_LIST: "ADMIN_EVENTS_LIST",
  ADD_SUBJECT_GROUP: "ADD_SUBJECT_GROUP",
  DELETE_SUBJECT_GROUP: "DELETE_SUBJECT_GROUP",
  UPDATE_SUBJECT_GROUP: "UPDATE_SUBJECT_GROUP",
  ADMIN_STUDENT_DATABASE_LIST: "ADMIN_STUDENT_DATABASE_LIST",
  ADMIN_STUDENT_DETAILS: "ADMIN_STUDENT_DETAILS",

  STUDENT_DATABASE_LIST: "STUDENT_DATABASE_LIST",
  STUDENT_DATABASE_DETAIL: "STUDENT_DATABASE_DETAIL",
  STUDENT_CURRENT_GROUP_DETAIL: "STUDENT_CURRENT_GROUP_DETAIL",
  STUDENT_DATABASE_DETAIL_INITIAL: "STUDENT_DATABASE_DETAIL_INITIAL",
  TAKE_ATTENDENCE: "TAKE_ATTENDENCE",
  VIEW_ATTENDENCE: "VIEW_ATTENDENCE",
  TEACHER_CLASS_LIST: "TEACHER_CLASS_LIST",
  TERM_LIST: "TERM_LIST",
  STUDENT_ATTENDENCE_LIST_REPORT: "STUDENT_ATTENDENCE_LIST_REPORT",
  EXAM_HIERARCHY_DETAILS: "EXAM_HIERARCHY_DETAILS",
  IS_GRADEBOOK_LOADED: "IS_GRADEBOOK_LOADED",
  SELECTED_GRADEBOOK_BOX_IINDEICES: "SELECTED_GRADEBOOK_BOX_IINDEICES",
  EXAM_HIERARCHY_TERMS_LIST: "EXAM_HIERARCHY_TERMS_LIST",
  EXAM_HIERARCHY_FAS_LIST: "EXAM_HIERARCHY_FAS_LIST",
  EXAM_HIERARCHY_SUBJECT_LIST: "EXAM_HIERARCHY_SUBJECT_LIST",
  EXAM_HIERARCHY_ACTIVITY_DETAILS: "EXAM_HIERARCHY_ACTIVITY_DETAILS",
  EXAM_HIERARCHY_THEORYANDPRACTICAL_DETAILS:
    "EXAM_HIERARCHY_THEORYANDPRACTICAL_DETAILS",
  TEACHER_EXAM_LIST_DETAILS: "TEACHER_EXAM_LIST_DETAILS",
  TEACHER_STUDENT_PERFORMANCE_REPORT_DETAILS:
    "TEACHER_STUDENT_PERFORMANCE_REPORT_DETAILS",
  TEACHER_DISPLAY_STUDENT_PERFORMANCE_REPORT_DETAILS:
    "TEACHER_DISPLAY_STUDENT_PERFORMANCE_REPORT_DETAILS",
  TEACHER_SUBJECT_LIST_DETAILS: "TEACHER_SUBJECT_LIST_DETAILS",
  TEACHER_EXAM_HIERARCHY_SETTINGS_DETAILS:
    "TEACHER_EXAM_HIERARCHY_SETTINGS_DETAILS",
  TEACHER_ACTIVITY_CONDITION_DETAILS: "TEACHER_ACTIVITY_CONDITION_DETAILS",
  TEACHER_MARK_SUBJECT_DETAILS: "TEACHER_MARK_SUBJECT_DETAILS",
  TEACHER_MARK_STUDENT_LIST: "TEACHER_MARK_STUDENT_LIST",
  TEACHER_REMARK_ASSESSMENT_MARK_SCREEN:
    "TEACHER_REMARK_ASSESSMENT_MARK_SCREEN",
  TEACHER_STUDENTS_PERFORMANCE_MARK_SUBJECT_DETAILS:
    "TEACHER_STUDENTS_PERFORMANCE_MARK_SUBJECT_DETAILS",
  TEACHER_STUDENTS_PERFORMER_OBJECT: "TEACHER_STUDENTS_PERFORMER_OBJECT",
  TEACHER_GRADE_PERFORMER_OBJECT: "TEACHER_GRADE_PERFORMER_OBJECT",
  TEACHER_CLASS_PERFORMANCE_ASSESSMENT_MARK_DETAILS:
    "TEACHER_CLASS_PERFORMANCE_ASSESSMENT_MARK_DETAILS",
  TEACHER_GRADE_PERFORMANCE_ASSESSMENT_OBJECT:
    "TEACHER_GRADE_PERFORMANCE_ASSESSMENT_OBJECT",
  TEACHER_STUDENTS_PERFORMER_ASSESSMENT_OBJECT:
    "TEACHER_STUDENTS_PERFORMER_ASSESSMENT_OBJECT",
  TEACHER_SUBJECT_PASS_FAIL_PERCENTAGE_ASSESSMENT_OBJECT:
    "TEACHER_SUBJECT_PASS_FAIL_PERCENTAGE_ASSESSMENT_OBJECT",
  TEACHER_SUBJECT_AVERAGE_MARK_PERCENTAGE_ASSESSMENT_OBJECT:
    "TEACHER_SUBJECT_AVERAGE_MARK_PERCENTAGE_ASSESSMENT_OBJECT",
  TEACHER_SUBJECT_AVERAGE_GRADE_PERCENTAGE_ASSESSMENT_OBJECT:
    "TEACHER_SUBJECT_AVERAGE_GRADE_PERCENTAGE_ASSESSMENT_OBJECT",
  TEACHER_SUBJECTS_LIST: "TEACHER_SUBJECTS_LIST",
  TEACHER_UPDATE_STUDENT_GROUPS: "TEACHER_UPDATE_STUDENT_GROUPS",
  TEACHER_SUBJECT_ALLOTMENT_LIST_UPDATE:
    "TEACHER_SUBJECT_ALLOTMENT_LIST_UPDATE",
  TEACHER_GET_SUBJECT_LIST: "TEACHER_GET_SUBJECT_LIST",
  TEACHER_GET_SUBJECT_GROUPS: "TEACHER_GET_SUBJECT_GROUPS",
  GET_QUIZ_ASSIGN_LIST: "GET_QUIZ_ASSIGN_LIST",
  GET_SINGLE_QUIZ_ASSIGN: "GET_SINGLE_QUIZ_ASSIGN",
  ADD_QUIZ_ASSIGN: "ADD_QUIZ_ASSIGN",
  UPDATE_SINGLE_QUIZ_ASSIGN: "UPDATE_SINGLE_QUIZ_ASSIGN",
  DELETE_QUIZ_ASSIGN: "DELETE_QUIZ_ASSIGN",
  GET_QUIZ_LIST: "GET_QUIZ_LIST",
  ADD_QUIZ: "ADD_QUIZ",
  GET_SINGLE_QUIZ: "GET_SINGLE_QUIZ",
  UPDATE_SINGLE_QUIZ: "UPDATE_SINGLE_QUIZ",
  DELETE_QUIZ: "DELETE_QUIZ",

  COMMUNICATION_LIST: "COMMUNICATION_LIST",
  COMMUNICATION_CLASS_LIST: "COMMUNICATION_CLASS_LIST",
  COMMUNICATION_SUBJECT_LIST: "COMMUNICATION_SUBJECT_LIST",
  COMMUNICATION_STUDENT_LIST: "COMMUNICATION_STUDENT_LIST",
  COMMUNICATION_TEACHER_LIST: "COMMUNICATION_TEACHER_LIST",
  OPEN_MESSAGE_MODAL: "OPEN_MESSAGE_MODAL",

  FOUNDER_SUPER_ADMIN_DETAILS: "FOUNDER_SUPER_ADMIN_DETAILS",
  FOUNDER_CHAPTERS_TOPICS: "FOUNDER_CHAPTERS_TOPICS",
  FOUNDER_QUESTIONS_DATA: "FOUNDER_QUESTIONS_DATA",
  FOUNDER_QUESTIONS_DATA_FILTER: "FOUNDER_QUESTIONS_DATA_FILTER",
  FOUNDER_DETELE_CHAPTERS: "FOUNDER_DETELE_CHAPTERS",
  FOUNDER_DETELE_TOPICS: "FOUNDER_DETELE_TOPICS",
  FOUNDER_DETELE_QUESTIONS: "FOUNDER_DETELE_QUESTIONS",
  FOUNDER_UPDATE_CHAPTERS_TOPICS: "FOUNDER_UPDATE_CHAPTERS_TOPICS",
  FOUNDER_UPDATE_QUESTIONS_CHAPTERS: "FOUNDER_UPDATE_QUESTIONS_CHAPTERS",
  STUDENT_CURRENT_ASSIGNMENT: "STUDENT_CURRENT_ASSIGNMENT",
  STUDENT_ASSIGNMENT_TOGGLE_MODAL: "STUDENT_ASSIGNMENT_TOGGLE_MODAL",
  MASTER_SUBJECTS: "MASTER_SUBJECTS",
  FOUNDER_BEHAVIOUR_LIST: "FOUNDER_BEHAVIOUR_LIST",
  FOUNDER_VIEW_LIST: "FOUNDER_VIEW_LIST",
}
