/*************************************************
 * RedInk
 * @exports
 * @class StudentDetailsModal.js
 * @extends Component
 * @author Sasidharan // on 01/02/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import Modal, { ModalTransition } from "@atlaskit/modal-dialog"
import ModalFooter from "reactstrap/lib/ModalFooter"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import moment from "moment"

import { getClassList } from "../../actions/teacher/TakeAttendanceAction"
import { DANGER_TOAST, VALIDATION_MSG } from "../../utils/Constants"
import { myLog } from "../../utils/Utility"
import { getBase64, validateEmail } from "../../utils/Utility"
import {
  saveStudentDatabaseDetailInStore,
  saveStudentDatabaseDetailInitialInStore,
} from "../../actions/teacher/StudentDatabaseAction"
import {
  getCheckEmailAvailableDetails,
  showNotification,
} from "../../actions/CommonAction"

class StudentDetailsModal extends Component {
  static getDerivedStateFromProps(props, state) {
    myLog("stutend Modal", props.studentData)
    // let studentDataObject = Object.keys(props.studentData).length > 0
    return {
      studentImage: "",
      firstName: props.studentData.firstName,
      lastName: props.studentData.lastName,
      name: props.studentData.name,
      studentEmail: props.studentData.studentEmail,
      studentPhone: props.studentData.studentPhone,
      gender: props.studentData.gender,
      dob: props.studentData.dob,
      registerNumber: props.studentData.registerNumber,
      parentsList: props.studentData.parentsList,
      oldName: props.studentData.oldName,
      admissionNumber: props.studentData.admissionNumber,
      dateOfJoining: props.studentData.dateOfJoining,
      primaryParent: props.studentData.primaryParent,
      fatherName: props.studentData.fatherName,
      fatherFirstName: props.studentData.fatherFirstName,
      fatherLastName: props.studentData.fatherLastName,
      fatherEmail: props.studentData.fatherEmail,
      fatherPhone: props.studentData.fatherPhone,
      motherName: props.studentData.motherName,
      motherFirstName: props.studentData.motherFirstName,
      motherLastName: props.studentData.motherLastName,
      motherEmail: props.studentData.motherEmail,
      motherPhone: props.studentData.motherPhone,
      legalGuardianEmail: props.studentData.legalGuardianEmail,
      legalGuardianName: props.studentData.legalGuardianName,
      legalGuardianFirstName: props.studentData.legalGuardianFirstName,
      legalGuardianLastName: props.studentData.legalGuardianLastName,
      legalGuardianPhone: props.studentData.legalGuardianPhone,
      localGuardianEmail: props.studentData.localGuardianEmail,
      localGuardianName: props.studentData.localGuardianName,
      localGuardianFirstName: props.studentData.localGuardianFirstName,
      localGuardianLastName: props.studentData.localGuardianLastName,
      localGuardianPhone: props.studentData.localGuardianPhone,
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      showChooseLogo: true,
      studentImage: "",
      name: "",
      gender: "",
      dob: "",
      registerNumber: "",
      parentsList: "",
      firstName: "",
      lastName: "",
      studentEmail: "",
      studentPhone: "",
      admissionNumber: "",
      dateOfJoining: "",
      isRollNumberExist: false,
      primaryParent: "",
      fatherName: "",
      fatherFirstName: "",
      fatherLastName: "",
      fatherEmail: "",
      fatherPhone: "",
      motherName: "",
      motherFirstName: "",
      motherLastName: "",
      motherEmail: "",
      motherPhone: "",
      legalGuardianEmail: "",
      legalGuardianName: "",
      legalGuardianFirstName: "",
      legalGuardianLastName: "",
      legalGuardianPhone: "",
      localGuardianEmail: "",
      localGuardianName: "",
      localGuardianFirstName: "",
      localGuardianLastName: "",
      localGuardianPhone: "",
    }
  }

  componentDidMount = () => {
    this.getClassList()
  }

  getClassList = () => {
    this.props.getClassList((success, response) => {
      myLog(response)
    })
  }

  /**
   * Open File Explorer on click logo area
   */
  selectStudentImageClicked = () => {
    this.refs.schoolLogoUpload.click()
  }

  /**
   * After choosing image from explorer
   */
  onSelectStudentImage = file => {
    getBase64(file, studentImage => {
      let object = this.props.studentData
      object.studentImage = studentImage
      this.props.saveStudentDatabaseDetailInStore(object)
    })
  }

  /**
   * Add new parent form
   */
  addParentForm = index => {
    const tempARR = this.state.parentsList
    tempARR.push({
      parentName: "",
      parentEmail: "",
      parentPhone: "",
    })
    this.setState({ parentsList: tempARR })
  }

  /**
   * Delete parent form
   */
  deleteParentForm = index => {
    if (this.state.parentsList.length > 1) {
      let arrAdmin = this.state.parentsList
      arrAdmin.splice(index, 1)
      this.setState({
        parentsList: arrAdmin,
      })
    }
  }

  clearState() {
    let studentDetail = {
      name: "",
      gender: "",
      dob: "",
      registerNumber: "",
      riId: "",
      studentImage: "",
      firstName: "",
      lastName: "",
      admissionNumber: "",
      dateOfJoining: "",
      primaryParent: "",
      fatherName: "",
      fatherFistName: "",
      fatherLastName: "",
      fatherEmail: "",
      fatherPhone: "",
      motherName: "",
      motherFirstName: "",
      motherLastName: "",
      motherEmail: "",
      motherPhone: "",
      legalGuardianEmail: "",
      legalGuardianName: "",
      legalGuardianFirstName: "",
      legalGuardianLastName: "",
      legalGuardianPhone: "",
      localGuardianEmail: "",
      localGuardianName: "",
      localGuardianFirstName: "",
      localGuardianLastName: "",
      localGuardianPhone: "",
      parentsList: [{ parentName: "", parentEmail: "", parentPhone: "" }],
    }
    this.setState({ isRollNumberExist: false })
    this.props.saveStudentDatabaseDetailInStore(studentDetail)
  }

  saveAddNext = () => {
    if (!this.state.isRollNumberExist) {
      if (
        this.state.firstName !== "" &&
        this.state.firstName !== undefined &&
        //   this.state.registerNumber !== "" &&
        //   this.state.registerNumber !== undefined &&
        //   this.state.lastName !== "" &&
        //   this.state.lastName !== undefined &&
        // this.state.gender !== "" &&
        // this.state.gender !== undefined
        //   this.state.dob !== "" &&
        //   this.state.dob !== undefined &&
        //   this.state.studentEmail !== "" &&
        //   this.state.studentEmail !== undefined &&
        this.state.studentPhone !== "" &&
        this.state.studentPhone !== undefined
        // this.state.admissionNumber !== "" &&
        // this.state.admissionNumber !== undefined &&
        // this.state.dateOfJoining !== "" &&
        // this.state.dateOfJoining !== undefined &&
        // this.state.primaryParent !== "" &&
        // this.state.primaryParent !== undefined
        // this.state.fatherName !== "" &&
        // this.state.fatherName !== undefined &&
        // this.state.fatherEmail !== "" &&
        // this.state.fatherEmail !== undefined &&
        // this.state.fatherPhone !== "" &&
        // this.state.fatherPhone !== undefined &&
        // this.state.motherName !== "" &&
        // this.state.motherName !== undefined &&
        // this.state.motherEmail !== "" &&
        // this.state.motherEmail !== undefined &&
        // this.state.motherPhone !== "" &&
        // this.state.motherPhone !== undefined
      ) {
        if (
          this.state.dateOfJoining > moment(new Date()).format("YYYY-MM-DD")
        ) {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Date of joining greater than today"
          )
          return
        }

        if (this.state.dob >= moment(new Date()).format("YYYY-MM-DD")) {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Date of birth Invalid"
          )
          return
        }

        if (this.state.firstName === "" || this.state.firstName === undefined) {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Student name cannot be empty"
          )
          return
        }

        if (
          this.state.studentEmail !== undefined &&
          this.state.studentEmail !== ""
        ) {
          if (!validateEmail(this.state.studentEmail)) {
            this.props.showNotification(
              DANGER_TOAST,
              VALIDATION_MSG.SUCCESS,
              "Student mail id invalid"
            )
            return
          }
        }

        if (
          this.state.studentPhone !== "" &&
          this.state.studentPhone !== undefined
        ) {
          if (
            String(this.state.studentPhone).length <= 9 ||
            String(this.state.studentPhone).length >= 15
          ) {
            this.props.showNotification(
              DANGER_TOAST,
              VALIDATION_MSG.SUCCESS,
              "Student Phone number Invalid"
            )
            return
          }
        } else {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Student Phone number cannot be empty"
          )
          return
        }

        if (this.state.dob === "" || this.state.dob === undefined) {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Student DOB cannot be empty"
          )
          return
        }

        if (this.state.gender === "" || this.state.gender === undefined) {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Gender cannot be empty"
          )
          return
        }
        // if (this.state.primaryParent === "FATHER") {
        //   if (
        //     this.state.fatherFirstName === "" ||
        //     this.state.fatherFirstName === undefined
        //   ) {
        //     this.props.showNotification(
        //       DANGER_TOAST,
        //       VALIDATION_MSG.SUCCESS,
        //       "Father first name cannot be empty"
        //     )
        //     return
        //   }
        //   if (this.state.fatherLastName === "") {
        //     this.props.showNotification(
        //       DANGER_TOAST,
        //       VALIDATION_MSG.SUCCESS,
        //       "Father last name cannot be empty"
        //     )
        //     return
        //   }

        if (
          this.state.fatherEmail !== "" &&
          this.state.fatherEmail !== undefined
        ) {
          if (!validateEmail(this.state.fatherEmail)) {
            this.props.showNotification(
              DANGER_TOAST,
              VALIDATION_MSG.SUCCESS,
              "Father mail id invalid"
            )
            return
          }
        }

        if (
          this.state.fatherPhone !== "" &&
          this.state.fatherPhone !== undefined
        ) {
          if (
            String(this.state.fatherPhone).length <= 9 ||
            String(this.state.fatherPhone).length >= 15
          ) {
            this.props.showNotification(
              DANGER_TOAST,
              VALIDATION_MSG.SUCCESS,
              "Father Phone number invalid"
            )
            return
          }
        }
        // }
        //father details
        // if (this.state.primaryParent === "MOTHER") {
        //   if (this.state.motherFirstName === "") {
        //     this.props.showNotification(
        //       DANGER_TOAST,
        //       VALIDATION_MSG.SUCCESS,
        //       "Mother first name cannot be empty"
        //     )
        //     return
        //   }
        //   if (this.state.motherLastName === "") {
        //     this.props.showNotification(
        //       DANGER_TOAST,
        //       VALIDATION_MSG.SUCCESS,
        //       "Mother last name cannot be empty"
        //     )
        //     return
        //   }

        if (
          this.state.motherEmail !== "" &&
          this.state.motherEmail !== undefined
        ) {
          if (!validateEmail(this.state.motherEmail)) {
            this.props.showNotification(
              DANGER_TOAST,
              VALIDATION_MSG.SUCCESS,
              "Mother mail id invalid"
            )
            return
          }
        }

        if (
          this.state.motherPhone !== "" &&
          this.state.motherPhone !== undefined
        ) {
          if (
            String(this.state.motherPhone).length < 10 ||
            String(this.state.motherPhone).length >= 15
          ) {
            this.props.showNotification(
              DANGER_TOAST,
              VALIDATION_MSG.SUCCESS,
              "Mother Phone number invalid"
            )
            return
          }
        }
        // } // mother details
        if (
          this.state.legalGuardianEmail &&
          this.state.legalGuardianEmail !== ""
        ) {
          if (!validateEmail(this.state.legalGuardianEmail)) {
            this.props.showNotification(
              DANGER_TOAST,
              VALIDATION_MSG.SUCCESS,
              "Legal Guardian mail id invalid"
            )
            return
          }
        }
        if (
          this.state.legalGuardianPhone &&
          this.state.legalGuardianPhone !== ""
        ) {
          if (
            String(this.state.legalGuardianPhone).length < 10 ||
            String(this.state.legalGuardianPhone).length >= 15
          ) {
            this.props.showNotification(
              DANGER_TOAST,
              VALIDATION_MSG.SUCCESS,
              "Legal Gaurdian Phone number invalid"
            )
            return
          }
        }
        if (
          this.state.localGuardianEmail &&
          this.state.localGuardianEmail !== ""
        ) {
          if (!validateEmail(this.state.localGuardianEmail)) {
            this.props.showNotification(
              DANGER_TOAST,
              VALIDATION_MSG.SUCCESS,
              "Local Guardian mail id invalid"
            )
            return
          }
        }

        if (
          this.state.localGuardianPhone &&
          this.state.localGuardianPhone !== ""
        ) {
          if (
            String(this.state.localGuardianPhone.length) < 10 ||
            String(this.state.localGuardianPhone.length) >= 15
          ) {
            this.props.showNotification(
              DANGER_TOAST,
              VALIDATION_MSG.SUCCESS,
              "Local Guardian Phone number invalid"
            )
            return
          }
        }
        let hasError = false
        // if (
        //   this.state.primaryParent === "LEG" &&
        //   (this.state.legalGuardianEmail === "" ||
        //     this.state.legalGuardianEmail === undefined ||
        //     this.state.legalGuardianFirstName === "" ||
        //     this.state.legalGuardianFirstName === undefined ||
        //     this.state.legalGuardianLastName === "" ||
        //     this.state.legalGuardianLastName === undefined ||
        //     this.state.legalGuardianPhone === "" ||
        //     this.state.legalGuardianPhone === undefined)
        // ) {
        //   this.props.showNotification(
        //     DANGER_TOAST,
        //     VALIDATION_MSG.SUCCESS,
        //     "Enter Legal Guardian Name, Email, Phone"
        //   )
        //   return
        // }
        // if (
        //   this.state.primaryParent === "LOG" &&
        //   (this.state.localGuardianEmail === "" ||
        //     this.state.localGuardianEmail === undefined ||
        //     this.state.localGuardianFirstName === "" ||
        //     this.state.localGuardianFirstName === undefined ||
        //     this.state.localGuardianLastName === "" ||
        //     this.state.localGuardianLastName === undefined ||
        //     this.state.localGuardianPhone === "" ||
        //     this.state.localGuardianPhone === undefined)
        // ) {
        //   this.props.showNotification(
        //     DANGER_TOAST,
        //     VALIDATION_MSG.SUCCESS,
        //     "Enter Local Guardian Name, Email, Phone"
        //   )
        //   return
        // }

        if (!hasError) {
          let object = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            studentEmail: this.state.studentEmail,
            studentPhone: this.state.studentPhone,
            admissionNumber: this.state.admissionNumber,
            dateOfJoining: this.state.dateOfJoining,
            // primaryParent:
            //   this.state.primaryParent == "" ? null : this.state.primaryParent,
            gender: this.state.gender,
            dob: this.state.dob,
            registerNumber: this.state.registerNumber
              ? this.state.registerNumber
              : "-",
            studentImage: this.state.studentImage,
            parentsList: [
              {
                id:
                  this.props.studentData &&
                  this.props.studentData.parentDetailId
                    ? this.props.studentData.parentDetailId
                    : "",
                fatherName:
                  this.state.fatherFirstName + " " + this.state.fatherLastName,
                fatherFirstName: this.state.fatherFirstName,
                fatherLastName: this.state.fatherLastName,
                fatherEmail: this.state.fatherEmail,
                fatherPhone: this.state.fatherPhone,
                motherName:
                  this.state.motherFirstName + " " + this.state.motherLastName,
                motherFirstName: this.state.motherFirstName,
                motherLastName: this.state.motherLastName,
                motherEmail: this.state.motherEmail,
                motherPhone: this.state.motherPhone,
                legalGuardianEmail: this.state.legalGuardianEmail,
                legalGuardianName:
                  this.state.legalGuardianFirstName +
                  "" +
                  this.state.legalGuardianLastName,
                legalGuardianFirstName: this.state.legalGuardianFirstName,
                legalGuardianLastName: this.state.legalGuardianLastName,
                legalGuardianPhone: this.state.legalGuardianPhone,
                localGuardianEmail: this.state.localGuardianEmail,
                localGuardianName:
                  this.state.localGuardianFirstName +
                  " " +
                  this.state.localGuardianLastName,
                localGuardianFirstName: this.state.localGuardianFirstName,
                localGuardianLastName: this.state.localGuardianLastName,
                localGuardianPhone: this.state.localGuardianPhone,
              },
            ],
          }
          if (this.state.primaryParent === "FATHER") {
            if (
              !this.state.fatherFirstName &&
              !this.state.fatherLastName &&
              !this.state.fatherEmail &&
              !this.state.fatherPhone
            ) {
              object.primaryParent = null
            }
          }
          if (this.state.primaryParent === "MOTHER") {
            if (
              !this.state.motherFirstName &&
              !this.state.motherLastName &&
              !this.state.motherEmail &&
              !this.state.motherPhone
            ) {
              object.primaryParent = null
            }
          }
          if (this.state.primaryParent === "LOG") {
            if (
              !this.state.localGuardianFirstName &&
              !this.state.localGuardianLastName &&
              !this.state.localGuardianEmail &&
              !this.state.localGuardianPhone
            ) {
              object.primaryParent = null
            }
          }
          if (this.state.primaryParent === "LEG") {
            if (
              !this.state.legalGuardianFirstName &&
              !this.state.legalGuardianLastName &&
              !this.state.localGuardianEmail &&
              !this.state.legalGuardianPhone
            ) {
              object.primaryParent = null
            }
          }

          // else{
          //   object.primaryParent = null
          // }
          if (this.props.studentData && this.props.studentData.id) {
            object.id = this.props.studentData.id
          }
          this.props.onSubmitStudentDetails(false, object)
          this.clearState()
        } else {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Fill out respective primary contact details..."
          )
        }
      } else {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Fill out required fields"
        )
      }
    }
    // else {
    //   if (this.state.registerNumber === "") {
    //     this.props.showNotification(
    //       DANGER_TOAST,
    //       VALIDATION_MSG.SUCCESS,
    //       "Roll Number cannot be empty..."
    //     )
    //   } else {
    //     this.props.showNotification(
    //       DANGER_TOAST,
    //       VALIDATION_MSG.SUCCESS,
    //       "Roll Number already exists."
    //     )
    //   }
    // }
  }

  render() {
    const { isModalOpen, closeModal } = this.props

    return (
      <ModalTransition>
        {isModalOpen && (
          <Modal
            width="80%"
            heading={
              this.props.studentData && this.props.studentData.id
                ? "STUDENT DETAILS"
                : "ADD NEW STUDENT DETAILS"
            }
            actions={[
              {
                text: this.props.isShowLoading ? "LOADING..." : "SAVE",
                onClick: this.saveAddNext,
                // style:{
                //   backgroundColor:'#06B191',
                //   color:'white important',
                //   borderRadius:'15px'
                // }
              },
              {
                text: "CANCEL",
                onClick: () => {
                  this.clearState()
                  closeModal()
                },
                style: {
                  // backgroundColor:'#DBDBDB',
                  // color:'#06B191 !important'
                },
              },
            ]}
          >
            <small>
              {this.state.firstName !== "" ? "" : this.state.oldName}
            </small>
            <div className="row px-3">
              <div className="col-sm-9 p-0 pr-4 border-right">
                <div className="form-row">
                  <div className="form-group col-sm">
                    <small>
                      First Name <span style={{ color: "red" }}>*</span>
                    </small>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.firstName}
                      onChange={e => {
                        let object = this.props.studentData
                        object.firstName = e.target.value
                        object.firstName = object.firstName.replace(
                          /[^A-Za-z ]/gi,
                          ""
                        )
                        this.props.saveStudentDatabaseDetailInStore(object)
                        // this.setState({name: e.target.value })
                      }}
                    />
                  </div>
                  <div className="form-group col-sm">
                    <small>Last Name</small>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.lastName}
                      onChange={e => {
                        let object = this.props.studentData
                        object.lastName = e.target.value
                        object.lastName = object.lastName.replace(
                          /[^A-Za-z]/gi,
                          ""
                        )
                        this.props.saveStudentDatabaseDetailInStore(object)
                        // this.setState({name: e.target.value })
                      }}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-sm">
                    <small>Email Id</small>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.studentEmail}
                      onChange={e => {
                        let object = this.props.studentData
                        object.studentEmail = e.target.value
                        this.props.saveStudentDatabaseDetailInStore(object)
                        // this.setState({name: e.target.value })
                      }}
                    />
                  </div>
                  <div className="form-group col-sm">
                    <small>
                      Mobile Number <span style={{ color: "red" }}>*</span>
                    </small>
                    <input
                      type="number"
                      className="form-control"
                      value={this.state.studentPhone}
                      onChange={e => {
                        let object = this.props.studentData
                        object.studentPhone = e.target.value
                        // object.studentPhone = object.studentPhone.replace(
                        //   /[^0-9 ]/gi,
                        //   ""
                        // )
                        this.props.saveStudentDatabaseDetailInStore(object)
                        // this.setState({name: e.target.value })
                      }}
                    />
                  </div>
                </div>
                {/* <div className="form-row">
                  <div className="form-group col-sm">
                    <small>Name</small>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.name}
                      onChange={e => {
                        let object = this.props.studentData;
                        object.name= e.target.value;
                        this.props.saveStudentDatabaseDetailInStore(object);
                        // this.setState({name: e.target.value })
                      }}
                    />
                  </div>
                </div> */}
                <div className="form-row">
                  <div className="form-group col-sm">
                    <small>Roll/Registration Number </small>
                    <input
                      type="text"
                      maxLength="20"
                      className="form-control"
                      value={this.state.registerNumber}
                      onChange={e => {
                        this.setState({ isRollNumberExist: false })
                        let object = this.props.studentData
                        object.registerNumber = e.target.value
                        this.props.saveStudentDatabaseDetailInStore(object)
                        this.props.getCheckEmailAvailableDetails(
                          e.target.value,
                          (success, response) => {
                            if (response) {
                              this.setState({ isRollNumberExist: true })
                              // this.props.showNotification(
                              //   DANGER_TOAST,
                              //   VALIDATION_MSG.SUCCESS,
                              //   "Roll Number already exist..."
                              // )
                            }
                          }
                        )
                      }}
                    />
                  </div>
                  <div className="form-group col-sm">
                    <small>Admission No.</small>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.admissionNumber}
                      onChange={e => {
                        if (e.target.value >= 0) {
                          let object = this.props.studentData
                          object.admissionNumber = e.target.value
                          this.props.saveStudentDatabaseDetailInStore(object)
                        }
                      }}
                    />
                  </div>

                  <div className="form-group col-sm">
                    <small>Date of Admission</small>
                    <input
                      max="9999-12-31"
                      type="date"
                      className="form-control unstyled"
                      value={this.state.dateOfJoining}
                      onChange={e => {
                        let object = this.props.studentData
                        object.dateOfJoining = e.target.value
                        this.props.saveStudentDatabaseDetailInStore(object)
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-3">
                <div className="form-group col-sm">
                  <small>
                    Gender <span style={{ color: "red" }}>*</span>
                  </small>
                  <select
                    className="form-control"
                    value={this.state.gender}
                    onChange={e => {
                      let object = this.props.studentData
                      object.gender = e.target.value
                      this.props.saveStudentDatabaseDetailInStore(object)
                    }}
                  >
                    <option value="">Select</option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                    <option value="O">Other</option>
                  </select>
                </div>
                <div className="form-group col-sm">
                  <small>
                    Date Of Birth <span style={{ color: "red" }}>*</span>
                  </small>
                  <input
                    max="9999-12-31"
                    type="date"
                    className="form-control unstyled"
                    value={this.state.dob}
                    onChange={e => {
                      let object = this.props.studentData
                      object.dob = e.target.value
                      this.props.saveStudentDatabaseDetailInStore(object)
                    }}
                  />
                </div>
              </div>
              {/* <div className="col-sm-3 text-center align-self-center">
                <div className="d-flex justify-content-center align-items-center mb-4">
                  {this.state.studentImage === "" ? (
                    <span
                      onClick={() => this.selectStudentImageClicked()}
                      onMouseEnter={() =>
                        this.setState({ showChooseLogo: true })
                      }
                      onMouseLeave={() =>
                        this.setState({ showChooseLogo: false })
                      }
                      className="school-form-logo"
                    >
                      {!this.state.showChooseLogo &&
                      this.state.studentImage === "" ? (
                        <i className="fal fa-image"></i>
                      ) : null}
                    </span>
                  ) : null}
                  {this.state.studentImage !== "" ? (
                    <img
                      onMouseEnter={() =>
                        this.setState({ showChooseLogo: true })
                      }
                      onMouseLeave={() =>
                        this.setState({ showChooseLogo: false })
                      }
                      style={{ objectFit: "cover", width: 100, height: 100 }}
                      onClick={() => this.selectStudentImageClicked()}
                      src={this.state.studentImage}
                      className="img-fluid rounded-circle"
                      alt="logo"
                    ></img>
                  ) : null}
                  {this.state.showChooseLogo ? (
                    <small
                      className="p-1"
                      style={{
                        position: "absolute",
                        fontWeight: "bold",
                        backgroundColor: "white",
                        borderRadius: 5,
                      }}
                      onClick={() => this.selectStudentImageClicked()}
                      onMouseEnter={() =>
                        this.setState({ showChooseLogo: true })
                      }
                      onMouseLeave={() =>
                        this.setState({ showChooseLogo: false })
                      }
                    >
                      CHOOSE IMAGE
                      <input
                        type="file"
                        onChange={event =>
                          this.onSelectStudentImage(event.target.files[0])
                        }
                        ref="schoolLogoUpload"
                        className="d-none"
                        accept="image/x-png,image/gif,image/jpeg"
                      />
                    </small>
                  ) : null}
                </div>
                <small>Upload Student Image (jpeg/png)</small>
              </div> */}
            </div>

            <hr className="dotted"></hr>
            <h5 className="my-3 text-dark">Parent's details</h5>

            {/* {this.state.parentsList &&
              this.state.parentsList.length > 0 &&
              this.state.parentsList.map((item, index) => {
                return (
                  <div className="d-flex justify-content-between">
                    <div className="form-group w-100 mr-2">
                      <small>Name</small>
                      <input
                        type="text"
                        className="form-control"
                        value={item.parentName}
                        onChange={e => {
                          let g = this.state.parentsList
                          g[index].parentName = e.target.value
                          this.setState({ parentsList: g })
                        }}
                      />
                    </div>
                    <div className="form-group w-100 mr-2">
                      <small>Email Id</small>
                      <input
                        type="text"
                        className="form-control"
                        value={item.parentEmail}
                        onChange={e => {
                          let g = this.state.parentsList
                          g[index].parentEmail = e.target.value
                          this.setState({ parentsList: g })
                        }}
                        onBlur={() => {
                          // this.props.getCheckEmailAvailableDetails(e.target.value, (success,response)=>{
                          //   if(response){
                          //     this.props.showNotification(
                          //       DANGER_TOAST,
                          //       VALIDATION_MSG.SUCCESS,
                          //       "Email id already exist..."
                          //     );
                          //   }
                          // })
                        }}
                      />
                    </div>
                    <div className="form-group w-100 mr-2">
                      <small>Mobile Number</small>
                      <input
                        type="number"
                        className="form-control"
                        value={item.parentPhone}
                        onChange={e => {
                          let g = this.state.parentsList
                          g[index].parentPhone = e.target.value
                          this.setState({ parentsList: g })
                        }}
                      />
                    </div>
                    <div
                      onClick={() => this.addParentForm(index)}
                      className="d-flex justify-content-center align-items-center text-info pt-2 fs-20 mx-3"
                    >
                      <i
                        className="far fa-plus-circle"
                        style={{ cursor: "pointer" }}
                      ></i>
                    </div>
                    <div
                      onClick={() => this.deleteParentForm(index)}
                      className="d-flex justify-content-center align-items-center text-danger pt-2 fs-20"
                    >
                      <i
                        className="far fa-trash-alt"
                        style={{ cursor: "pointer" }}
                      ></i>
                    </div>
                  </div>
                )
              })} */}
            {/* {this.state.primaryParent === "FATHER" ? (
              <>
                <div className="d-flex justify-content-between">
                  <div className="form-group w-100 mr-2">
                    <small>Father First Name</small>{" "}
                    <span style={{ color: "red" }}>*</span>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.fatherFirstName}
                      onChange={e => {
                        let object = this.props.studentData
                        object.fatherFirstName = e.target.value
                        object.fatherFirstName = object.fatherFirstName.replace(
                          /[^A-Za-z ]/gi,
                          ""
                        )
                        this.props.saveStudentDatabaseDetailInStore(object)
                      }}
                    />
                  </div>
                  <div className="form-group w-100 mr-2">
                    <small>Father Last Name</small>{" "}
                    <span style={{ color: "red" }}>*</span>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.fatherLastName}
                      onChange={e => {
                        let object = this.props.studentData
                        object.fatherLastName = e.target.value
                        object.fatherLastName = object.fatherLastName.replace(
                          /[^A-Za-z ]/gi,
                          ""
                        )
                        this.props.saveStudentDatabaseDetailInStore(object)
                      }}
                    />
                  </div>
                  <div className="form-group w-100 mr-2">
                    <small>Email Id</small>{" "}
                    <span style={{ color: "red" }}>*</span>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.fatherEmail}
                      onChange={e => {
                        let object = this.props.studentData
                        object.fatherEmail = e.target.value
                        this.props.saveStudentDatabaseDetailInStore(object)
                      }}
                    />
                  </div>
                  <div className="form-group w-100 mr-2">
                    <small>Mobile Number</small>{" "}
                    <span style={{ color: "red" }}>*</span>
                    <input
                      type="text"
                      maxLength="10"
                      className="form-control"
                      value={this.state.fatherPhone}
                      onChange={e => {
                        let object = this.props.studentData
                        object.fatherPhone = e.target.value
                        object.fatherPhone = object.fatherPhone.replace(
                          /[^0-9 ]/gi,
                          ""
                        )
                        this.props.saveStudentDatabaseDetailInStore(object)
                      }}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <div className="form-group w-100 mr-2">
                    <small>Mother First Name</small>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.motherFirstName}
                      onChange={e => {
                        let object = this.props.studentData
                        object.motherFirstName = e.target.value
                        object.motherFirstName = object.motherFirstName.replace(
                          /[^A-Za-z ]/gi,
                          ""
                        )
                        this.props.saveStudentDatabaseDetailInStore(object)
                      }}
                    />
                  </div>
                  <div className="form-group w-100 mr-2">
                    <small>Mother Last Name</small>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.motherLastName}
                      onChange={e => {
                        let object = this.props.studentData
                        object.motherLastName = e.target.value
                        object.motherLastName = object.motherLastName.replace(
                          /[^A-Za-z]/gi,
                          ""
                        )
                        this.props.saveStudentDatabaseDetailInStore(object)
                      }}
                    />
                  </div>
                  <div className="form-group w-100 mr-2">
                    <small>Email Id</small>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.motherEmail}
                      onChange={e => {
                        let object = this.props.studentData
                        object.motherEmail = e.target.value
                        this.props.saveStudentDatabaseDetailInStore(object)
                      }}
                    />
                  </div>
                  <div className="form-group w-100 mr-2">
                    <small>Mobile Number</small>
                    <input
                      type="text"
                      maxLength="10"
                      className="form-control"
                      value={this.state.motherPhone}
                      onChange={e => {
                        let object = this.props.studentData
                        object.motherPhone = e.target.value
                        object.motherPhone = object.motherPhone.replace(
                          /[^0-9 ]/gi,
                          ""
                        )
                        this.props.saveStudentDatabaseDetailInStore(object)
                      }}
                    />
                  </div>
                </div>
              </>
            ) : //primary parent is mother
            this.state.primaryParent === "MOTHER" ? (
              <>
                <div className="d-flex justify-content-between">
                  <div className="form-group w-100 mr-2">
                    <small>Father First Name</small>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.fatherFirstName}
                      onChange={e => {
                        let object = this.props.studentData
                        object.fatherFirstName = e.target.value
                        object.fatherFirstName = object.fatherFirstName.replace(
                          /[^A-Za-z ]/gi,
                          ""
                        )
                        this.props.saveStudentDatabaseDetailInStore(object)
                      }}
                    />
                  </div>
                  <div className="form-group w-100 mr-2">
                    <small>Father Last Name</small>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.fatherLastName}
                      onChange={e => {
                        let object = this.props.studentData
                        object.fatherLastName = e.target.value
                        object.fatherLastName = object.fatherLastName.replace(
                          /[^A-Za-z ]/gi,
                          ""
                        )
                        this.props.saveStudentDatabaseDetailInStore(object)
                      }}
                    />
                  </div>
                  <div className="form-group w-100 mr-2">
                    <small>Email Id</small>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.fatherEmail}
                      onChange={e => {
                        let object = this.props.studentData
                        object.fatherEmail = e.target.value
                        this.props.saveStudentDatabaseDetailInStore(object)
                      }}
                    />
                  </div>
                  <div className="form-group w-100 mr-2">
                    <small>Mobile Number</small>
                    <input
                      type="number"
                      className="form-control"
                      value={this.state.fatherPhone}
                      onChange={e => {
                        let object = this.props.studentData
                        object.fatherPhone = e.target.value
                        object.fatherPhone = object.fatherPhone.replace(
                          /[^0-9 ]/gi,
                          ""
                        )
                        this.props.saveStudentDatabaseDetailInStore(object)
                      }}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <div className="form-group w-100 mr-2">
                    <small>Mother First Name</small>{" "}
                    <span style={{ color: "red" }}>*</span>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.motherFirstName}
                      onChange={e => {
                        let object = this.props.studentData
                        object.motherFirstName = e.target.value
                        object.motherFirstName = object.motherFirstName.replace(
                          /[^A-Za-z ]/gi,
                          ""
                        )
                        this.props.saveStudentDatabaseDetailInStore(object)
                      }}
                    />
                  </div>
                  <div className="form-group w-100 mr-2">
                    <small>Mother Last Name</small>{" "}
                    <span style={{ color: "red" }}>*</span>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.motherLastName}
                      onChange={e => {
                        let object = this.props.studentData
                        object.motherLastName = e.target.value
                        object.motherLastName = object.motherLastName.replace(
                          /[^A-Za-z ]/gi,
                          ""
                        )
                        this.props.saveStudentDatabaseDetailInStore(object)
                      }}
                    />
                  </div>
                  <div className="form-group w-100 mr-2">
                    <small>Email Id</small>{" "}
                    <span style={{ color: "red" }}>*</span>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.motherEmail}
                      onChange={e => {
                        let object = this.props.studentData
                        object.motherEmail = e.target.value
                        this.props.saveStudentDatabaseDetailInStore(object)
                      }}
                    />
                  </div>
                  <div className="form-group w-100 mr-2">
                    <small>Mobile Number</small>{" "}
                    <span style={{ color: "red" }}>*</span>
                    <input
                      type="text"
                      maxLength="10"
                      className="form-control"
                      value={this.state.motherPhone}
                      onChange={e => {
                        let object = this.props.studentData
                        object.motherPhone = e.target.value
                        object.motherPhone = object.motherPhone.replace(
                          /[^0-9 ]/gi,
                          ""
                        )
                        this.props.saveStudentDatabaseDetailInStore(object)
                      }}
                    />
                  </div>
                </div>
              </>
            ) : ( */}

            <>
              <div className="d-flex justify-content-between">
                <div className="form-group w-100 mr-2">
                  <small>Father First Name</small>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.fatherFirstName}
                    onChange={e => {
                      let object = this.props.studentData
                      object.fatherFirstName = e.target.value
                      object.fatherFirstName = object.fatherFirstName.replace(
                        /[^A-Za-z ]/gi,
                        ""
                      )
                      this.props.saveStudentDatabaseDetailInStore(object)
                    }}
                  />
                </div>
                <div className="form-group w-100 mr-2">
                  <small>Father Last Name</small>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.fatherLastName}
                    onChange={e => {
                      let object = this.props.studentData
                      object.fatherLastName = e.target.value
                      object.fatherLastName = object.fatherLastName.replace(
                        /[^A-Za-z ]/gi,
                        ""
                      )
                      this.props.saveStudentDatabaseDetailInStore(object)
                    }}
                  />
                </div>
                <div className="form-group w-100 mr-2">
                  <small>Email Id</small>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.fatherEmail}
                    onChange={e => {
                      let object = this.props.studentData
                      object.fatherEmail = e.target.value
                      this.props.saveStudentDatabaseDetailInStore(object)
                    }}
                  />
                </div>
                <div className="form-group w-100 mr-2">
                  <small>Mobile Number</small>
                  <input
                    type="number"
                    className="form-control"
                    value={this.state.fatherPhone}
                    onChange={e => {
                      let object = this.props.studentData
                      object.fatherPhone = e.target.value
                      // object.fatherPhone = object.fatherPhone.replace(
                      //   /[^0-9 ]/gi,
                      //   ""
                      // )
                      this.props.saveStudentDatabaseDetailInStore(object)
                    }}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <div className="form-group w-100 mr-2">
                  <small>Mother First Name</small>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.motherFirstName}
                    onChange={e => {
                      let object = this.props.studentData
                      object.motherFirstName = e.target.value
                      object.motherFirstName = object.motherFirstName.replace(
                        /[^A-Za-z ]/gi,
                        ""
                      )
                      this.props.saveStudentDatabaseDetailInStore(object)
                    }}
                  />
                </div>
                <div className="form-group w-100 mr-2">
                  <small>Mother Last Name</small>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.motherLastName}
                    onChange={e => {
                      let object = this.props.studentData
                      object.motherLastName = e.target.value
                      object.motherLastName = object.motherLastName.replace(
                        /[^A-Za-z ]/gi,
                        ""
                      )
                      this.props.saveStudentDatabaseDetailInStore(object)
                    }}
                  />
                </div>
                <div className="form-group w-100 mr-2">
                  <small>Email Id</small>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.motherEmail}
                    onChange={e => {
                      let object = this.props.studentData
                      object.motherEmail = e.target.value
                      this.props.saveStudentDatabaseDetailInStore(object)
                    }}
                  />
                </div>
                <div className="form-group w-100 mr-2">
                  <small>Mobile Number</small>
                  <input
                    type="number"
                    className="form-control"
                    value={this.state.motherPhone}
                    onChange={e => {
                      let object = this.props.studentData
                      object.motherPhone = e.target.value
                      // object.motherPhone = object.motherPhone.replace(
                      //   /[^0-9 ]/gi,
                      //   ""
                      // )
                      this.props.saveStudentDatabaseDetailInStore(object)
                    }}
                  />
                </div>
              </div>
            </>
            {/* )
            // } */}
            <div className="d-flex align-items-center">
              <h5 className="my-3 text-dark">Guardian details</h5>
              <h6 className="my-3 text-muted ml-3">(Not mandatory)</h6>
            </div>
            <div className="d-flex justify-content-between">
              <div className="form-group w-100 mr-2">
                <small>Local Guardian First Name </small>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.localGuardianFirstName}
                  onChange={e => {
                    let object = this.props.studentData
                    object.localGuardianFirstName = e.target.value
                    object.localGuardianFirstName = object.localGuardianFirstName.replace(
                      /[^A-Za-z ]/gi,
                      ""
                    )
                    this.props.saveStudentDatabaseDetailInStore(object)
                  }}
                />
              </div>
              <div className="form-group w-100 mr-2">
                <small>Local Guardian Last Name</small>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.localGuardianLastName}
                  onChange={e => {
                    let object = this.props.studentData
                    object.localGuardianLastName = e.target.value
                    object.localGuardianLastName = object.localGuardianLastName.replace(
                      /[^A-Za-z ]/gi,
                      ""
                    )
                    this.props.saveStudentDatabaseDetailInStore(object)
                  }}
                />
              </div>
              <div className="form-group w-100 mr-2">
                <small>Email Id</small>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.localGuardianEmail}
                  onChange={e => {
                    let object = this.props.studentData
                    object.localGuardianEmail = e.target.value
                    this.props.saveStudentDatabaseDetailInStore(object)
                  }}
                />
              </div>
              <div className="form-group w-100 mr-2">
                <small>Mobile Number</small>
                <input
                  type="text"
                  maxLength="10"
                  className="form-control"
                  value={this.state.localGuardianPhone}
                  onChange={e => {
                    let object = this.props.studentData
                    object.localGuardianPhone = e.target.value
                    object.localGuardianPhone = object.localGuardianPhone.replace(
                      /[^0-9 ]/gi,
                      ""
                    )
                    this.props.saveStudentDatabaseDetailInStore(object)
                  }}
                />
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <div className="form-group w-100 mr-2">
                <small>Legal Guardian First Name</small>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.legalGuardianFirstName}
                  onChange={e => {
                    let object = this.props.studentData
                    object.legalGuardianFirstName = e.target.value
                    object.legalGuardianFirstName = object.legalGuardianFirstName.replace(
                      /[^A-Za-z]/gi,
                      ""
                    )
                    this.props.saveStudentDatabaseDetailInStore(object)
                  }}
                />
              </div>
              <div className="form-group w-100 mr-2">
                <small>Legal Guardian Last Name</small>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.legalGuardianLastName}
                  onChange={e => {
                    let object = this.props.studentData
                    object.legalGuardianLastName = e.target.value
                    object.legalGuardianLastName = object.legalGuardianLastName.replace(
                      /[^A-Za-z ]/gi,
                      ""
                    )
                    this.props.saveStudentDatabaseDetailInStore(object)
                  }}
                />
              </div>
              <div className="form-group w-100 mr-2">
                <small>Email Id</small>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.legalGuardianEmail}
                  onChange={e => {
                    let object = this.props.studentData
                    object.legalGuardianEmail = e.target.value
                    this.props.saveStudentDatabaseDetailInStore(object)
                  }}
                />
              </div>
              <div className="form-group w-100 mr-2">
                <small>Mobile Number</small>
                <input
                  type="text"
                  maxLength="10"
                  className="form-control"
                  value={this.state.legalGuardianPhone}
                  onChange={e => {
                    let object = this.props.studentData
                    object.legalGuardianPhone = e.target.value
                    object.legalGuardianPhone = object.legalGuardianPhone.replace(
                      /[^0-9 ]/gi,
                      ""
                    )
                    this.props.saveStudentDatabaseDetailInStore(object)
                  }}
                />
              </div>
            </div>
            <div className="d-flex align-items-center mt-3">
              <div>primary Contact</div>
              <div className="form-check form-check-inline ml-5">
                <input
                  className="form-check-input"
                  type="radio"
                  name="primaryContact"
                  id="Father"
                  value={this.state.primaryParent}
                  checked={this.state.primaryParent === "FATHER"}
                  onChange={e => {
                    let object = this.props.studentData
                    object.primaryParent = "FATHER"
                    this.props.saveStudentDatabaseDetailInStore(object)
                  }}
                />
                <label className="form-check-label" htmlFor="Father">
                  Father
                </label>
              </div>
              <div className="form-check form-check-inline ml-5">
                <input
                  className="form-check-input"
                  type="radio"
                  name="primaryContact"
                  id="Mother"
                  value={this.state.primaryParent}
                  checked={this.state.primaryParent === "MOTHER"}
                  onChange={e => {
                    let object = this.props.studentData
                    object.primaryParent = "MOTHER"
                    this.props.saveStudentDatabaseDetailInStore(object)
                  }}
                />
                <label className="form-check-label" htmlFor="Mother">
                  Mother
                </label>
              </div>
              <div className="form-check form-check-inline ml-5">
                <input
                  className="form-check-input"
                  type="radio"
                  name="primaryContact"
                  id="localGuardian"
                  value="localGuardian"
                  checked={this.state.primaryParent === "LOG"}
                  onChange={e => {
                    let object = this.props.studentData
                    object.primaryParent = "LOG"
                    this.props.saveStudentDatabaseDetailInStore(object)
                  }}
                />
                <label className="form-check-label" htmlFor="localGuardian">
                  Local Guardian
                </label>
              </div>
              <div className="form-check form-check-inline ml-5">
                <input
                  className="form-check-input"
                  type="radio"
                  name="primaryContact"
                  id="legalGuardian"
                  value="legalGuardian"
                  checked={this.state.primaryParent === "LEG"}
                  onChange={e => {
                    let object = this.props.studentData
                    object.primaryParent = "LEG"
                    this.props.saveStudentDatabaseDetailInStore(object)
                  }}
                />
                <label className="form-check-label" htmlFor="legalGuardian">
                  Legal Guardian
                </label>
              </div>
            </div>
            <div className="text-muted my-4" style={{ fontSize: 14 }}>
              School will use the primary contact only to communicate any
              announcements
            </div>
            <ModalFooter></ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    )
  }
}

const mapStateToProps = state => {
  return {
    studentData: state.studentDatabaseState.studentDetail,
    classesList: state.takeAttendanceState.teacherClassList,
    isShowLoading: state.loginState.isShowLoading,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      saveStudentDatabaseDetailInStore,
      saveStudentDatabaseDetailInitialInStore,
      getClassList,
      getCheckEmailAvailableDetails,
      showNotification,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(StudentDetailsModal)
