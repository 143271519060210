/*************************************************
 * RedInk
 * @exports
 * @class subjectDetailsReducer.js
 * @extends Component
 * @author Vijayakumar // on 18/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from "../../actions/types"
import { myLog } from "../../utils/Utility"
const INTIAL_STATE = {
  subjectList: [],
  subjectDetail: {},
  subjectGroupsList: null,
  subjectMasterList: null,
}

export const subjectDetailsReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.SUBJECT_LIST:
      myLog("SUBJECT_LIST", action.payload)
      return {
        ...state,
        subjectList: action.payload,
      }
    case ACTION_TYPES.SUBJECT_DETAIL:
      myLog("payload", action.payload)
      return {
        ...state,
        subjectDetail: { ...action.payload },
      }
    case ACTION_TYPES.UPDATE_SUBJECT_GROUP:
      myLog("UPDATE_SUBJECT_GROUP", action.payload)
      return {
        ...state,
        subjectGroupsList: action.payload,
      }
    case ACTION_TYPES.MASTER_SUBJECTS:
      myLog("MASTER_SUBJECTS", action.payload)
      return {
        ...state,
        subjectMasterList: action.payload,
      }
    default:
      return state
  }
}
