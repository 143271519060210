/**
 * @default LOCAL_SERVER
 */
let API_BASE_URL = "http://159.89.53.143:8080/redink"
let OAUTH_BASE_URL = "http://159.89.53.143:8080/redink/oauth/token"
let COMMUNICATION_API_BASE_URL = "http://localhost:4000"
let isDev = true
let isProd = false
let showAssessment = true
let showSchedule = true
let showNewGrading = false
let hideRecentActivity = true
/**
 * @default DEV_SERVER
 */
// let API_BASE_URL = "http://"
// let OAUTH_BASE_URL = ""
// let isDevelopment = false
let STUDENT_DATABASE_DETAIL_XLSX =
  "https://redink-assets.s3.ap-south-1.amazonaws.com/Student_Template_data.xlsx"
// "https://redink-assets.s3.ap-south-1.amazonaws.com/Student_Record_Template_Latest.xlsx"
let ATTENDANCE_TEMPLATE_XLSX =
  "https://redink-assets.s3.ap-south-1.amazonaws.com/Attendance_Template.xlsx"

if (window.location.hostname === "localhost") {
  API_BASE_URL = "https://devapi.redink.app/redink"
  OAUTH_BASE_URL = "https://devapi.redink.app/redink/oauth/token"
  // API_BASE_URL = "https://api.redink.app/redink"
  // OAUTH_BASE_URL = "https://api.redink.app/redink/oauth/token"
  COMMUNICATION_API_BASE_URL = "https://devnapi.redink.app/"
  // API_BASE_URL = "http://localhost:8080/redink"
  // OAUTH_BASE_URL = "http://localhost:8080/redink/oauth/token"
  isDev = true
  showAssessment = true
  showSchedule = true
  showNewGrading = true
} else if (window.location.hostname === "dev.redink.app") {
  API_BASE_URL = "https://devapi.redink.app/redink"
  COMMUNICATION_API_BASE_URL = "https://devnapi.redink.app/"
  OAUTH_BASE_URL = "https://devapi.redink.app/redink/oauth/token"
  isDev = true
  showAssessment = true
  showSchedule = true
  showNewGrading = true
} else if (window.location.hostname === "stage.redink.app") {
  API_BASE_URL = "https://stageapi.redink.app/redink"
  COMMUNICATION_API_BASE_URL = "https://stagenapi.redink.app"
  OAUTH_BASE_URL = "https://stageapi.redink.app/redink/oauth/token"
  isDev = false
  showAssessment = true
} else if (window.location.hostname === "school.redink.app") {
  /********************************
   * Don't change anything
   ********************************/
  API_BASE_URL = "https://api.redink.app/redink"
  OAUTH_BASE_URL = "https://api.redink.app/redink/oauth/token"
  COMMUNICATION_API_BASE_URL = "https://napi.redink.app"
  isDev = false
  isProd = true
  showAssessment = true
  hideRecentActivity = false
  /*******************************
   * Don't change anything
   *******************************/
} else {
  API_BASE_URL = "https://api.redink.app/redink"
  OAUTH_BASE_URL = "https://api.redink.app/redink/oauth/token"
  COMMUNICATION_API_BASE_URL = "https://napi.redink.app"
  isDev = false
  isProd = true
  showAssessment = true
}

export default URL = {
  isDevelopment: isDev,
  isProduction: isProd,
  showAssessment: showAssessment,
  showSchedule: showSchedule,
  hideRecentActivity: hideRecentActivity,
  showNewGrading: showNewGrading,
  API_BASE_URL: API_BASE_URL,
  OAUTH: OAUTH_BASE_URL,
  COMMUNICATION_API_BASE_URL: COMMUNICATION_API_BASE_URL,

  //S3
  STUDENT_DATABASE_DETAIL_XLSX: STUDENT_DATABASE_DETAIL_XLSX,
  ATTENDANCE_TEMPLATE_XLSX: ATTENDANCE_TEMPLATE_XLSX,

  // List of APIs used

  GET_QUICK_INFO_DETAILS: "/api/getUserQuickInfo",
  CHANGE_PASSWORD: "/api/changePassword",
  FORGET_PASSWORD: "/forgotPassword",
  RESET_PASSWORD: "/resetPassword",

  FILE_UPLOAD: "/api/file/upload",
  FILE_INFO: "/api/file/download",

  // Super Admin Module
  GET_SCHOOL_LIST_DETAILS: "/api/1/getSchoolsList",
  GET_SCHOOL_DETAILS: "/api/1/getSchoolDetails",
  SAVE_SCHOOL_DETAILS: "/api/1/saveSchoolDetails",
  UPDATE_SCHOOL_DETAILS: "/api/1/updateSchoolDetails",
  DELETE_SCHOOL_DETAILS: "/api/1/deleteSchoolDetails",
  DELETE_ASSIGNED_BRANCH_ADMIN_DETAILS: "/api/1/deleteSchoolAdmin",
  GET_SUBJECT_DETAILS: "/api/1/getSubjectDetails",
  SAVE_SUBJECT_DETAILS: "/api/1/saveSubjectDetails",
  UPDATE_SUBJECT_DETAILS: "/api/1/updateSubjectDetails",
  DELETE_SUBJECT_DETAILS: "/api/1/deleteSubjectDetails",
  GET_CLASS_DETAILS: "/api/1/getClasses",
  SAVE_CLASS_DETAILS: "/api/1/saveClassDetails",
  UPDATE_CLASS_DETAILS: "/api/1/updateClassDetails",
  DELETE_CLASS_DETAILS: "/api/1/deleteClass",
  GET_TEACHERS_DETAILS: "/api/1/getTeacherDetails",
  GET_TEACHERS_REMOTE_LOGIN: "/api/remoteLogin",
  GET_STUDENT_REMOTE_LOGIN: "/api/remoteLogin",
  SAVE_TEACHERS_DETAILS: "/api/1/saveTeacherDetails",
  UPDATE_TEACHERS_DETAILS: "/api/1/updateTeacherDetails",
  DELETE_TEACHERS_DETAILS: "/api/1/deleteTeacherDetails",
  DELETE_ASSIGNED_TEACHER_CLASS_DETAILS: "/api/1/deleteAssignedClassAndSubject",
  GET_TEACHERS_LIST: "/api/1/getTeachersList",
  CHECK_CLASS_TEACHER_AVAILABILITY:
    "/api/1/teacher/checkClassTeacherAvailability",
  CHECK_CLASS_SUBJECT_AVAILABILITY:
    "/api/1/teacher/checkClassAndSubjectAvailability",
  GET_ATTENDANCE_DETAILS: "/api/1/getAttendanceReport",
  GET_SUPER_ADMIN_SCHOOL_PERFORMANCE_REPORT_DETAILS:
    "/api/1/getSchoolWisePerformance",
  GET_SCHOOL_DEATILS_SUPER_ADMIN: "/api/1/getProfileInfo",

  UPDATE_PROFILE_DETAILS_SUPER_ADMIN: "api/1/updateSuperAdminProfile",
  GET_PROFILE_INFO_SUPER_ADMIN: "",
  GET_MASTER_SUBJECTS: "/api/super-user/master-subjects",
  UPLOAD_QUESTION_BANK_DATA: "/api/super-user/default-questions-data",
  GET_CHAPTERS_TOPICS: "/api/super-user/default-questions-table-data",
  GET_QUESTIONS_DATA: "/api/super-user/default-questions-data",
  DELETE_CHAPTERS: "/api/super-user/delete-chapters",
  DELETE_TOPICS: "/api/super-user/delete-topics",
  DELETE_QUESTIONS: "/api/super-user/delete-questions",
  UPDATE_CHAPTERS_TOPICS: "/api/super-user/update-chapters-topics",
  UPDATE_QUESTIONS_ANSWERS: "/api/super-user/update-questions-answers",

  //Admin module
  SET_ONBOARD_STEPS: "/api/2/onBoardSteps",
  GET_SCHOOL_DEATILS_ADMIN: "/api/2/getProfileInfo",
  GET_MARK_UPLOAD_STATUS_DETAILS: "/api/2/getMarkUploadStatus",
  SAVE_ACADEMIC_YEAR_DETAILS: "/api/2/saveAcademicYear",
  GET_ACADEMIC_YEAR_DETAILS: "/api/2/getAcademicYearList",
  UPDATE_ACADEMIC_YEAR_DETAILS: "/api/2/updateAcademicYear",
  DELETE_ACADEMIC_YEAR_DETAILS: "/api/2/deleteAcademicYear",
  DELETE_DETAILS_ACADEMIC_YEAR: "/api/2/deleteAcademicYearDetail",
  SAVE_TERM_DETAILS: "/api/2/saveTerm",
  GET_TERM_DETAILS: "/api/2/getTermList",
  UPDATE_TERM_DETAILS: "/api/2/updateTerm",
  DELETE_DETAIL_TERM: "/api/2/deleteTermForSectionGroup",
  DELETE_TERM_DETAILS: "/api/2/deleteTerm",
  SAVE_ASSESSMENT_DETAILS: "/api/2/saveAssessment",
  UPDATE_ASSESSMENT_DETAILS: "/api/2/updateAssessment",
  DELETE_DETAILS_ASSESSMENT: "/api/2/deleteAssessmentForSectionGroup",
  DELETE_ASSESSMENT_DETAILS: "/api/2/deleteAssessment",
  GET_ADMIN_ASSESSMENT_DETAILS: "/api/2/getAssessment",
  GET_SCHOOL_OVERALL_PERFORMANCE_REPORT: "/api/2/getSchoolOverallPerformance",
  GET_SCHOOL_OVERALL_PERFORMANCE_PASS_FAIL_REPORT:
    "/api/2/getSchoolOverallPassFail",
  GET_SCHOOL_OVERALL_PERFORMANCE_SUBJECT_REPORT:
    "/api/2/getAcademicSubjectPerformance",
  GET_ADMIN_STUDENT_PERFORMANCE_TABLE_DETAILS:
    "api/3/analytics/getClassStudentList",
  SAVE_GRADE_DETAILS: "/api/2/saveGrades",
  UPDATE_GRADE_DETAILS: "",
  GET_GRADE_DETAILS: "/api/2/getGrades",
  DELETE_GRADE_DETAILS: "/api/2/deleteGrade",
  SAVE_COMMUNICATION_DETAILS: "api/admin/communication/",
  GET_COMMUNICATION_DETAILS: "api/admin/communication/",
  GET_COMMUNICATION_STUDENT_LIST_DETAILS: "/api/2/getStudentListByClass",
  GET_COMMUNICATION_TEACHER_LIST_DETAILS: "/api/2/getTeacherListBySubjects",
  SAVE_COMMUNICATION_STARRED_DETAILS: "/api/2/communication/makeStar",
  GET_COMMUNICATION_COUNT_DETAILS: "api/admin/communication/logs",
  SAVE_COMMUNICATION_LABEL_DETAILS: "api/admin/communication-labels",
  GET_COMMUNICATION_LABEL_DETAILS: "api/admin/communication-labels",
  GET_MASTER_SUBJECTS_ADMIN: "/api/admin/master-subjects",
  ADMIN_GRADE_MASTER: "/api/admin/grade-master",
  ADMIN_CREATE_MASTER_SUBJECT: "/api/admin/master-subjects",
  ADMIN_GET_CHAPTERS_TOPICS: "/api/admin/default-questions-table-data",
  ADMIN_GET_QUESTIONS_DATA: "/api/admin/default-questions-data",
  ADMIN_GET_QP_LIST: "/api/admin/question-paper",

  //Admin dashboard
  GET_DASHBOARD_ADMIN_ATTENDANCE_REPORT:
    "/api/2/dashboard/getTodaysSchoolAttendance",
  GET_DASHBOARD_ADMIN_SCHOOL_INFO: "/api/2/dashboard/getSchoolMemberCounts",
  GET_DASHBOARD_ADMIN_SCHOOL_PERFORMERS:
    "/api/2/dashboard/getSchoolPerformance",
  GET_DASHBOARD_ADMIN_SCHOOL_ATTENDANCE_TIMELINE:
    "/api/2/dashboard/getSchoolAttendanceTimeline",
  GET_DASHBOARD_ADMIN_SCHOOL_TOP_PERFORMERING_CLASSES:
    "/api/2/dashboard/getSchoolTopPerformingClasses",
  GET_DASHBOARD_ADMIN_SCHOOL_TOP_PERFORMERING_TEACHERS:
    "/api/2/dashboard/getSchoolTopPerformingTeachers",
  GET_DASHBOARD_ADMIN_SCHOOL_TOP_PERFORMERING_STUDENTS:
    "/api/2/dashboard/getSchoolTopPerformingStudents",
  GET_ADMIN_STUDENT_DATABASE_LIST: "/api/admin/students",
  SEND_ADMIN_STUDENT_CREDENTIALS: "/api/admin/students/sendcredentials",

  // admin behaviour
  GET_ADMIN_ALLRECOGNITIONS: "api/admin/behaviour/recognitions",
  DELETE_ADMIN_ALLRECOGNITIONS: "api/admin/behaviour/recognition",
  SAVE_ADMIN_RECOGNITION: "api/admin/behaviour/recognition/saveRecognition",

  // admin academic year upgradation
  UPDATE_ACADEMIC_YEAR: "/api/6/admin/acadamic_year/new/save",
  GET_OLD_ACADEMIC_LIST: "/api/6/admin/acadamic_year/list",
  GET_CLASSES_IN_UPGRADATION: "/api/1/getClasses",
  GET_STUDENTS_LIST_FOR_UPGRADE: "/api/admin/students",
  PROMOTE_STUDENTS: "/api/admin/students/promoteToNewAcademic",
  IMPORT_ACADAMIC: "/api/6/admin/acadamic_year/import-configs?academicFrom=",

  // admin question paper generation
  GET_CLASSES_IN_QUEST_PAPER_GEN:
    "/api/admin/default-questions-data/classes-subjects",
  ADMIN_CREATE_QP: "/api/admin/question-paper",
  GET_SUBJECT_CHAPTERS_TOPICS:
    "/api/admin/default-questions-table-data/chapters-topics",

  //Teachers Module
  GET_TEACHERS_CLASS_lIST: "/api/3/getTeacherClasses",
  GET_TEACHERS_STUDENT_ATTENDANCE_LIST: "/api/3/getAttendanceList",
  SAVE_MARK_AS_HOLIDAY: "/api/1/markHoliday",
  GET_TEACHERS_STUDENT_PRESENT_ABSENT_LIST: "/api/3/getAttendanceListByDate",
  SAVE_TEACHERS_STUDENT_ATTENDANCE_LIST: "/api/3/saveAttendance",
  UPDATE_TEACHERS_STUDENT_ATTENDANCE_LIST:
    "/api/1/teacher/updateStudentAttendenceList",
  GET_TEACHERS_TERM_LIST: "/api/3/getTermList",
  GET_TEACHERS_STUDENT_ATTENDANCE_REPORT_LIST: "api/3/attendance/report",
  GET_TEACHERS_STUDENT_ATTENDANCE_REPORT_HOLIDAY_LIST:
    "api/3/attendance/holidays",
  GET_ACADEMIC_MONTH_LIST: "api/3/attendance/getAcademicMonths",
  GET_TEACHERS_STUDENT_ATTENDANCE_REPORT_GRAPH: "api/3/attendance/reportGraph",
  GET_TEACHERS_STUDENT_FREQUENT_ABSENT_LIST:
    "/api/1/teacher/getStudentFrequentAbsentReportList",
  GET_TEACHERS_STUDENT_DATABASE_LIST: "/api/3/getStudentList",
  GET_TEACHERS_STUDENT_DATABASE_DETAILS: "/api/3/getStudentDetails",
  SAVE_TEACHERS_STUDENT_DATABASE_LIST: "/api/3/saveStudentDetails",
  UPDATE_TEACHERS_STUDENT_DATABASE_LIST: "/api/3/updateStudentDetails",
  DELETE_TEACHERS_STUDENT_DATABASE_LIST: "/api/3/deleteStudentDetails",
  GET_SUBJECT_LIST_DETAILS: "/api/3/getSubjectList",
  GET_SUBJECT_LIST_BY_CLASS_DETAILS: "/api/3/getAllSubjectsByClass",
  GET_ACTIVITY_CONDITION_LIST_DETAILS: "/api/3/getActivityConditions",
  GET_EXAM_HIERARCHY_DETAILS: "/api/3/getHierarchy",
  SAVE_EXAM_HIERARCHY_ACADEMIC_OBJECT: "/api/3/saveAcademic",
  SAVE_EXAM_HIERARCHY_TERMS_LIST: "/api/3/saveTerm",
  SAVE_EXAM_HIERARCHY_FAS_LIST: "/api/3/saveAssessment",
  SAVE_EXAM_HIERARCHY_FAS_DATE: "/api/3/saveAssessmentDate",
  SAVE_EXAM_HIERARCHY_SUBJECT_LIST: "/api/3/saveHierarchySubject",
  SAVE_EXAM_HIERARCHY_ACTIVITY_DETAILS: "/api/3/saveActivity",
  SAVE_EXAM_HIERARCHY_PRACTICAL_DETAILS: "/api/3/updatePractical",
  SAVE_EXAM_HIERARCHY_THEORY_DETAILS: "/api/3/updateTheory",
  DELETE_EXAM_HIERARCHY_TERMS_LIST: "/api/3/deleteTerm",
  DELETE_EXAM_HIERARCHY_FAS_LIST: "/api/3/deleteAssessment",
  DELETE_EXAM_HIERARCHY_SUBJECT_LIST: "/api/3/deleteHierarchySubject",
  DELETE_EXAM_HIERARCHY_ACTIVITY_DETAILS: "/api/3/deleteActivity",
  SAVE_STUDENT_SUBJECT_MARK_OBJECT: "/api/3/saveMarkDetails",
  SAVE_ASSESSMENT_OVERALL_MARK: "/api/3/analytics/setAssessmentMarkDetails",
  SAVE_STUDENT_SUBJECT_MARK_UPLOAD_LOCK_DETAIL: "/api/3/lockSubjectMarkEntry",
  SAVE_STUDENT_SUBJECT_GENERATE_ANALYTICS_DETAIL:
    "/api/3/generateStudentAnalytics",
  GET_MARK_STUDENT_lIST: "/api/3/analytics/getMarkDetails",
  GET_ASSESSMENT_MARK_STUDENT_lIST: "/api/3/getAssessmentMarks",
  GET_MARK_SUBJECT_DETAILS: "/api/3/getMarkSubjectDetails",
  GET_STUDENTS_PERFORMANCE_MARK_SUBJECT:
    "/api/3/getStudentsPerformanceMarkBySubjectDetails",
  GET_STUDENTS_PERFORMERS_LIST: "/api/3/getPerformersList",
  GET_STUDENTS_GRADE_PERFORMANCE_LIST_SUBJECT:
    "/api/3/getGradeDistributionCount",
  GET_STUDENT_PERFORMANCE_TABLE_REPORT_lIST:
    "/api/3/analytics/getClassStudentList",
  GET_ASSESSMENT_DETAILS: "/api/3/getAssessmentList",

  GET_TEACHERS_CLASS_PERFORMANCE_ASSESSMENT_MARK_OBJECT:
    "/api/3/performance/getClassPerformanceBasicInfo",
  GET_CLASS_TEACHERS_SUBJECT_PASS_FAIL_PERCENTAGE_GRAPH_OBJECT:
    "/api/3/performance/getClassPassFailPercentageBySubject",
  GET_CLASS_TEACHERS_GRADE_PERFORMANCE_DISTRIBUTION_OBJECT:
    "/api/3/performance/getGradeDistribution",
  GET_CLASS_TEACHERS_PERFORMER_OBJECT:
    "/api/3/performance/getClassPerformersList",

  GET_TEACHERS_SUBJECT_LIST: "/api/3/getTeacherSubjects",
  GET_TEACHER_HIERARCHY_SUBJECT_DETAILS: "/api/3/getHierarchySubjectDetails",
  SAVE_TEACHER_COMMUNICATION_DETAILS: "api/admin/communication",
  GET_TEACHER_COMMUNICATION_DETAILS: "/api/3/getCommunications",
  GET_TEACHER_COMMUNICATION_STUDENT_LIST_DETAILS:
    "/api/3/getStudentListByClass",
  GET_TEACHER_COMMUNICATION_TEACHER_LIST_DETAILS:
    "/api/3/getTeacherListBySubject",
  SAVE_TEACHER_COMMUNICATION_STARRED_DETAILS: "/api/3/communication/makeStar",
  GET_TEACHER_COMMUNICATION_COUNT_DETAILS: "/api/3/getCommunicationCounts",
  CHECK_EMAIL_AVAILABILITY: "/api/checkEmailAvailability",
  GET_DASHBOARD_TEACHER_CLASS_INFO: "/api/3/dashboard/getClassMemberCounts",
  GET_DASHBOARD_TEACHER_ATTENDANCE_REPORT:
    "/api/3/dashboard/getTodaysClassAttendance",
  GET_DASHBOARD_TEACHER_PERFORMERS: "/api/3/dashboard/getSectionPerformers",
  GET_DASHBOARD_TEACHER_SECTION_WISE_AVERAGE:
    "/api/3/dashboard/getSectionWiseAverage",
  GET_DASHBOARD_TEACHER_CLASS_PERFORMANCE_BY_ASSESSMENT:
    "/api/3/dashboard/getSectionProgress",
  GET_DASHBOARD_TEACHER_EARLY_WARNING: "/api/3/dashboard/getEarlyWarning",
  GET_PROFILE_INFO_TEACHER: "/api/3/getProfileInfo",
  GET_CHECK_IS_GENERATED_ANALYTICS: "/api/3/checkIsGeneratedAnalytics",
  GET_STUDENT_LIST_BY_SECTIONS: "/api/3/getStudentListForSections",
  GET_SUBJECT_LIST_BY_SECTIONS: "/api/3/getSubjectListForSections",
  TEACHER_GET_SUBJECT_GROUPS: "api/3/subject-group",
  TEACHER_GET_SUBJECT_LIST: "api/3/subjects",
  STUDENT_GET_SUBJECT_LIST: "api/5/subjects",
  TEACHER_SUBJECT_ALLOTMENT: "api/3/subject-allotment",
  ADMIN_SUBJECT_GROUPS: "api/admin/subject-group",
  ADMIN_SUBJECT_GROUP_SUBJECT: "api/admin/subject-group/subject",
  ADMIN_SUBJECT_GROUP_SECTION: "api/admin/subject-group/section",
  GET_MAX_MARKS_LIST: "/api/2/getAssessmentMaxMark",
  SAVE_STUDENT_SUBJECT_REMARK: "/api/3/saveSubjectRemarks",
  GET_CLASS_GROUP: "/api/2/getClassGroup",
  SAVE_CLASS_GROUP: "/api/2/saveClassGroup",
  DELETE_CLASS_GROUP: "/api/2/deleteClassGroup",
  DELETE_CLASS_FROM_GROUP: "/api/2/deleteClassFromGroup",
  DELETE_CLASS_FROM_SUBJECT: "api/1/deleteClassFromSubject",
  UPDATE_STUDENT_PROFILE_ACTIVE_INFO: "/api/student-profile/toggleStatus",
  CONSOLIDATE_REPORT_DOWNLOAD: "api/admin/report-card/students/considated",

  //Teacher Quiz
  SAVE_QUIZ: "/api/3/quizzes",
  QUIZ_ASSIGN_DETAILS: "api/3/quiz-assigns",
  GET_TEACHER_ASSIGNED_DETAILS: "api/3/assigned-details",
  GET_TEACHER_CLASS_STUDENTS: "api/3/class-students",
  GET_TEACHER_QUIZ_REPORT: "api/3/quiz-report",
  GET_QUIZ_FILE_UPLOAD_URL: "api/3/quizzes/upload-url",
  GET_QUIZ_TOPICS: "/api/3/subject-topics",
  GET_STUDENT_QUIZ_SUBMISSION: "api/3/quiz-evaluations",
  GET_QUIZ_QUESTION_BANK: "api/3/quiz-question-banks",
  GET_SUBJECT_CHAPTERS: "api/3/subject-chapters/",
  GET_FOUNDER_SUBJECT_CHAPTERS_TOPICS: "api/3/default-data/chapters-topics",

  //Teacher Schedule
  TEACHER_SCHEDULE: "/api/3/schedule",
  STUDENT_SCHEDULE: "/api/5/schedule",

  STUDENT_BEHAVIOUR: "/api/behaviour/student",

  //Student Quiz
  STUDENT_QUIZ: "api/5/quizzes",
  GET_STUDENT_QUIZ_REPORT: "api/5/quiz-report",
  GET_STUDENT_QUIZ_FILE_UPLOAD: "api/5/quizzes/upload-url",

  //Admin Profile
  GET_ADMIN_PROFILE: "/api/2/updateAdminProfile",
  GET_ADMIN_TEACHER_PROFILE: "/api/3/getProfileInfo",
  REPORT_CARD_TEMPLATES: "/api/admin/report-card/template?classId=",
  STUDENT_LIST_IN_REPORT_CARD: "/api/admin/report-card/students?sectionId=",
  GET_TERM_LIST_IN_REPORT_CARD:
    "/api/admin/report-card/term-and-assesments?classId=",

  //Teacher Profile
  GET_TEACHER_PROFILE: "api/3/getProfileInfo",
  UPDATE_TEACHER_PROFILE: "api/3/updateTeacherProfile",
  DELETE_TEACHER_QUALIFICATION: "api/3/deleteTeacherQualification",
  DELETE_TEACHER_SKILL: "api/3/deleteTeacherSkill",
  GET_STUDENTS_GROUPS: "api/3/getStudentsGroup",
  DELETE_STUDENTS_GROUP: "api/3/deleteStudentGroup",
  DELETE_STUDENTS_IN_GROUP: "api/3/deleteStudentFromGroup",
  SAVE_STUDENTS_GROUP: "api/3/saveStudentGroup",
  UPDATE_STUDENTS_GROUP: "api/3/updateStudentGroup",

  //Task Management
  SAVE_TASK: "/api/3/saveTaskDetails",
  DELETE_TASK: "api/3/deleteTasksDetails",
  GET_TASK: "/api/3/getTaskList",

  //Assignment Details
  GET_ASSIGNMENT_COUNT: "api/3/assignment/count",
  GET_ASSIGNMENT_DETAILS: "api/3/assignment",
  SEARCH_ASSIGNMENTS: "api/3/assignment/search/",
  SEARCH_STUDENT_DETAILS: "api/2/searchStudent",
  GET_STUDENT_PROFILE_DETAILS: "api/3/getStudentProfileInfo",
  SAVE_ASSIGN_DETAILS: "api/3/assignment",
  DELETE_ASSIGNMENT_DETAIL: "api/3/assignment",
  GET_DUES_DETAILS: "api/3/assignment/dues",
  GET_ASSIGNMENT_DETAIL_STATUS_COUNT: "api/3/assignment",
  DELETE_STUDENT_ASSIGNMENT_DETAIL: "api/3/assignment/assigned-students",
  UPDATE_STUDENT_ASSIGNMENT_DETAILS: "api/3/assignment",
  GET_STUDENT_ASSIGNMENT_DETAIL: "api/3/assignment/assigned-students",
  TEACHER_MOBILE_ASSIGNMENT: "api/mobile/3/assignment",
  //student login
  GET_STUDENT_ASSIGNMENT_DETAILS: "api/5/student-assignment",
  SAVE_STUDENT_SUBMIT_DETAILS: "api/5/student-assignment",
  SAVE_STUDENT_SUBMIT_DETAILS_MOBILE: "api/mobile/5/student-assignment",
  STUDENT_ASSIGNMENT_YEAR: "api/5/student-assignment/academic-year-graph",
  GET_STUDENT_QUIZZES: "/api/5/quizzes",

  //SUPERADMIN DETAILS -- Alex
  GET_SUPERADMIN_DETAILS: "/api/0/getAllTentants",
  GET_CHECK_TENTANT_AVAILABILITY: "/api/0/checkTentantAvailability",
  ADD_SUPERADMIN: "/api/0/saveTentant",
  UPDATE_SUPERADMIN: "",
  GET_ADMIN_DETAILS: "api/super-user/admins",
  GET_BRANCH_LIST: "api/super-user/branch-list",
  FOUNDER_GET_TEACHERS_DETAILS: "api/super-user/teachers",
  GET_SECTIONS_LIST: "api/super-user/section-list",
  GET_STUDENTS_DETAILS: "api/super-user/students",

  //News
  SAVE_NEWS_DETAILS: "api/2/saveNewsDetails",
  GET_NEWS_DETAILS: "api/2/getNewsList",
  DELETE_NEWS_DETAILS: "api/2/deleteNewsDetails",

  //Events
  SAVE_EVENTS_DETAILS: "api/2/saveEventDetails",
  GET_EVENTS_DETAILS: "api/2/getEventList",
  DELETE_EVENTS_DETAILS: "api/2/deleteEventDetails",

  //Communication server
  GET_STUDENT_REMARKS_XL_SHEET_TEMPLATE: "student/report/template",
  GET_STUDENT_REMARKS_XL_SHEET_REPORT: "student/report",

  //Behaviour
  SAVE_BEHAVIOUR_DETAILS: "api/3/behaviour/saveStudentsBehaviour",
  GET_BEHAVIOUR_DETAILS: "api/3/behaviour/getStudentsBehaviour",
  SAVE_BEHAVIOUR_RECOGNITION_DETAILS: "api/3/behaviour/createRecognition",
  GET_BEHAVIOUR_RECOGNITION_DETAILS: "api/3/behaviour/getAllRecognitions",
  DELETE_BEHAVIOUR_DETAIL: "api/3/behaviour/deleteRecognitions",

  //Notifications
  GET_TEACHER_NOTIFICATIONS: "api/3/notifications",
  GET_STUDENT_NOTIFICATIONS: "api/5/notifications",
  UPDATE_MARK_AS_READ_ASSIGNMENT: "api/5/notifications/markAsRead",
  UPDATE_TEACHER_MARK_AS_READ_ASSIGNMENT: "api/3/notifications/markAsRead",

  GET_STUDENT_PROFILE_BASIC_INFO: "api/student-profile/basic",
  GET_STUDENT_PROFILE_EXAM_INFO: "api/student-profile/exam",
  GET_STUDENT_PROFILE_ASSESSMENTS: "api/student-profile/assessments",
  GET_STUDENT_PROFILE_ATTENDANCE_INFO: "api/student-profile/attendance",
  GET_STUDENT_PROFILE_ASSIGNMENT_INFO: "api/student-profile/assignment",
  GET_STUDENT_PROFILE_EXTEND_INFO: "api/student-profile/extended",
  UPDATE_STUDENT_PROFILE_EXTEND_INFO: "api/student-profile/extended/update",
  UPDATE_PARENT_EXTEND_INFO: "api/student-profile/extended/parent",

  //founder behaviour
  GET_FOUNDER_ALLRECOGNITIONS: "api/super-user/recognitions",
  DELETE_FOUNDER_ALLRECOGNITIONS: "api/super-user/recognition",
  SAVE_FOUNDER_RECOGNITION: "api/super-user/recognition/saveRecognition",
}
