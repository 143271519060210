import React, { useEffect, useState } from "react"
import { HashLoader } from "react-spinners"
import { useDispatch } from "react-redux"

import {
  getStudentListInReportCard,
  GenerateStudentReportCardList,
  getSignedReportCardUrl,
} from "../../../actions/admin/ReportCardActions"
import extractBucketNameAndPath from "../../../utils/extractBucketNameAndPath"
import { showNotification } from "../../../actions/CommonAction"
import { SUCCESS_TOAST } from "../../../utils/Constants"

import { ReactComponent as Download } from "../../../assets/images/download.svg"
import { ReactComponent as Search } from "../../../assets/images/search.svg"
import { ReactComponent as Tick } from "../../../assets/images/tick_icon.svg"
import GeneratedSuccessModal from "./Modals/GeneratedSuccess"
import ConsolidatedReport from "./Modals/ConsolidatedReport"

function IndividualClass({ selectedClass, updateStuShowPreview }) {
  const [lastGeneratedReportCardUrl, setLastGeneratedReportCardUrl] = useState(
    null
  )
  const [isReoprtGenerated, setIsReoprtGenerated] = useState(true)
  const [showConsolidate, setShowConsolidate] = useState(false)
  const [isDownLoading, setDownIsLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [filterBy, setFilterBy] = useState("")
  const [data, setData] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    const cb = (success, res) => {
      if (success && res) {
        setData(res.data)
        setIsReoprtGenerated(res.reportCardGenerated)
        setLastGeneratedReportCardUrl(res.lastGeneratedReportCardUrl)
        setIsLoading(false)
      }
    }

    getStudentListInReportCard(selectedClass.id, cb)
  }, [])

  const updateShowConsolidate = () => setShowConsolidate(p => !p)

  const btnClk = () => {
    if (
      isReoprtGenerated &&
      lastGeneratedReportCardUrl &&
      extractBucketNameAndPath(lastGeneratedReportCardUrl)
    ) {
      setDownIsLoading(true)
      getSignedReportCardUrl(
        extractBucketNameAndPath(lastGeneratedReportCardUrl).key,
        (success, res) => {
          if (success) {
            const xhr = new XMLHttpRequest()
            xhr.onload = () => {
              const file = new Blob([xhr.response])
              const browserUrl = window.webkitURL || window.URL
              const fileUrl = browserUrl.createObjectURL(file)
              const a = document.createElement("a")
              a.href = fileUrl
              a.download = `${selectedClass.className}.pdf`
              a.click()
              browserUrl.revokeObjectURL(fileUrl)
              setDownIsLoading(false)
              dispatch(
                showNotification(SUCCESS_TOAST, "", "Download successful")
              )
            }
            xhr.open("GET", res.url, true)
            xhr.responseType = "blob"
            xhr.send(null)
          }
        }
      )
    } else {
      generateReportCard()
    }
  }

  const generateReportCard = () => {
    setDownIsLoading(true)
    GenerateStudentReportCardList(
      selectedClass.id,
      isReoprtGenerated,
      success => {
        if (success) {
          getStudentListInReportCard(selectedClass.id, (success2, res) => {
            if (success2 && res) {
              setLastGeneratedReportCardUrl(res.lastGeneratedReportCardUrl)
              setIsReoprtGenerated(true)
              setData(res.data)

              setTimeout(() => {
                setDownIsLoading(false)
              }, 1500)
            }
          })
        }
      }
    )
  }

  if (isLoading)
    return (
      <HashLoader
        css={{
          position: "fixed",
          width: "100%",
          height: "100%",
          left: "0px",
          top: "0px",
        }}
        size={30}
        color="#084353"
        sizeUnit="px"
        loading
      />
    )

  return (
    <div className="reportCard-card" style={{ borderRadius: "32px" }}>
      <div className="reportCard-card-ind-sec-header d-flex align-items-center mt-4">
        <h1 className="mb-0 fw-500">Class {selectedClass.className}</h1>

        <button
          className="ml-auto px-4 py-2 mr-2 fs-15 fw-600 br-8px"
          style={{ textDecoration: "underline", color: "#5DD1AD" }}
          onClick={updateShowConsolidate}
        >
          Get Consolidated report
        </button>

        <button
          onClick={btnClk}
          className="disabled-btn min-w-150px d-flex align-items-center px-4 py-2 fs-15 fw-600 text-white bg-5DD1AD br-8px"
          disabled={isDownLoading}
        >
          {isReoprtGenerated ? (
            <>
              <Download /> Download All
            </>
          ) : (
            `Generate ${selectedClass.className} report cards`
          )}
        </button>
      </div>

      <div className="reportCard-card-ind-sec-input-wrapper d-flex align-items-center my-4 p-2 bg-F2F4F8">
        <Search className="mx-2" />

        <input
          type="text"
          placeholder="Search for students"
          className="w-100 fs-13"
          value={filterBy}
          onChange={e => setFilterBy(e.target.value)}
        />
      </div>

      <table className="reportCard-card-ind-sec-table table-fixed w-100">
        <tbody>
          <tr className="bg-F2F4F8 clr-848484">
            <td className="py-3 fw-500">
              <div className="ml-5">Student name</div>
            </td>
            <td className="py-3 fw-500 text-center">Status</td>
            <td className="py-3 fw-500 text-center">Action</td>
          </tr>

          {data
            .filter(stu =>
              `${stu.studentFirstName} ${stu.studentLastName}`
                .toLowerCase()
                .match(filterBy.toLowerCase())
            )
            .map(stu => (
              <tr key={stu.studentId}>
                <td className="pt-3 pb-2">
                  <div className="ml-5 py-2">
                    <p className="clr-232323 mb-0 fs-15 fw-500">
                      {stu.studentFirstName} {stu.studentLastName}
                    </p>
                    {/* <p className="clr-898989 mb-0 fs-13">
                      {stu.registerRollNumber}
                    </p> */}
                  </div>
                </td>

                <td className="pt-3 pb-2 text-center clr-898989">
                  {isReoprtGenerated ? (
                    <div className="d-flex align-items-center justify-content-center">
                      <Tick className="mr-2" width={16} height={16} /> Generated
                    </div>
                  ) : (
                    "Not generated"
                  )}
                </td>

                <td className="pt-3 pb-2 text-center fw-500">
                  {isReoprtGenerated && (
                    <button
                      className="clr-4E5BF4"
                      onClick={() =>
                        updateStuShowPreview(true, {
                          ...selectedClass,
                          reportCardUrl: stu?.reportCard?.reportCardUrl || "",
                          studentName: `${stu.studentFirstName} ${stu.studentLastName}`,
                        })
                      }
                    >
                      Preview
                    </button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {isDownLoading && (
        <GeneratedSuccessModal isReoprtGenerated={isReoprtGenerated} />
      )}

      {showConsolidate && (
        <ConsolidatedReport
          classId={selectedClass.classId}
          sectionId={selectedClass.id}
          className={selectedClass.className}
          updateShowConsolidate={updateShowConsolidate}
        />
      )}
    </div>
  )
}

export default IndividualClass
