/*****************
 * RedInk
 * @exports
 * @class url_path.js
 * @extends Component
 * @author Sasidharan // on 17/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *****************/
export const URL_PATH = {
  NOT_FOUND: "404",
  ZOOM_INTEGRATION: "/connect/zoom",

  // INDEX_PAGE: "/superadmin",
  LOGIN: "/",
  FORGET_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",

  SUPER_ADMIN: "/superadmin",
  SUPER_ADMIN_SCHOOL_PROFILE_ABOUT: "/school-profile/about",
  SUPER_ADMIN_SCHOOL_PROFILE_BRANCHES: "/school-profile/branches",
  SUPER_ADMIN_SCHOOL_PROFILE_SUBJECTS: "/school-profile/subjects",
  SUPER_ADMIN_SCHOOL_PROFILE_CLASS_DETAILS: "/school-profile/classes",
  SUPER_ADMIN_SCHOOL_PROFILE_TEACHERS: "/school-profile/teachers",
  ATTENDANCE_REPORT: "/attendance-report",
  COMMUNICATION_DASHBOARD: "/communication",
  NEWS_EVENTS: "/news-events",
  PERFORMANCE_REPORT: "/performance-report",
  MARK_UPLOAD_STATUS: "/mark-upload-status",
  SUPER_ADMIN_CHANGEPASSWORD: "/superadmin/changepassword",
  SUPER_ADMIN_TEACHER_PROFILE: "/superadmin/teacherProfile",

  ADMIN: "/admin",
  ADMIN_SCHOOL_PROFILE_ABOUT: "/admin/school-profile/about",
  ADMIN_SCHOOL_PROFILE_SUBJECTS: "/admin/school-profile/subjects",
  ADMIN_SCHOOL_PROFILE_CLASS_DETAILS: "/admin/school-profile/classes",
  ADMIN_SCHOOL_PROFILE_TEACHERS: "/admin/school-profile/teachers",
  ADMIN_SCHOOL_PROFILE_STUDENTS: "/admin/school-profile/students",
  ADMIN_SCHOOL_PROFILE_ACADEMICYEAR: "/admin/school-profile/academicYear",
  ADMIN_UPGRADE_ACADEMICYEAR: "/admin/upgrade-academic-year",
  ADMIN_SCHOOL_PROFILE_TERM: "/admin/school-profile/term",
  ADMIN_SCHOOL_PROFILE_GRADE: "/admin/school-profile/grade",
  ADMIN_ATTENDANCE_REPORT: "/admin/attendance-report",
  ADMIN_COMMUNICATION_DASHBOARD: "/admin/communication",
  ADMIN_NEWS_EVENTS: "/admin/news-events",
  ADMIN_PERFORMANCE_REPORT: "/admin/performance-report",
  ADMIN_MARK_UPLOAD_STATUS: "/admin/mark-upload-status",
  ADMIN_TIMETABLE_DASHBOARD: "/admin/timetable",
  ADMIN_CHANGEPASSWORD: "/admin/changepassword",
  ADMIN_STUDENT_PROFILE: "/admin/studentProfile",
  ADMIN_ACADEMIC_SCREEN: "/admin/academic",
  ADMIN_CLASS_SCREEN: "/admin/class",
  ADMIN_CLASS_GROUP_SCREEN: "/admin/classGroup",
  ADMIN_CHANGE_PASSWORD: "/admin/changePassword",
  ADMIN_ONBOARDING: "/admin/onboarding",
  ADMIN_TEACHER_PROFILE: "/admin/teacherProfile",
  ADMIN_STUDENT_PROFILE_FORM_DETAIL: "/admin/student/formDetail",
  ADMIN_GRADE_MASTER: "/admin/grade-master",
  ADMIN_REPORT_CARD: "/admin/report-card",
  ADMIN_BEHAVIOUR: "/admin/behaviour",
  ADMIN_QUEST_GEN: "/admin/question-paper-generation",

  TEACHER: "/teacher",
  TEACHER_PROFILE: "/teacher/profile",
  TEACHER_STUDENT_RECORD: "/teacher/students/records",
  TEACHER_STUDENT_PROFILE: "/teacher/student/profile",
  TEACHER_COMMUNICATION: "/teacher/communication",
  TEACHER_ATTENDANCE: "/teacher/attendance",
  TEACHER_VIEW_ATTENDANCE: "/teacher/viewAttendance",
  TEACHER_ATTENDANCE_REPORT: "/teacher/attendance/report",
  TEACHER_GRADEBOOK: "/teacher/gradeBook",
  TEACHER_EXAMS_MARKS: "/teacher/exams/marks",
  TEACHER_TERM_MARKS: "/teacher/term/marks",
  TEACHER_MARKS: "/teacher/marks",
  TEACHER_MARKS_ANALYTICS: "/teacher/marks/quick-analytics",
  TEACHER_PERFORMANCE_REPORT: "/teacher/performance-report",
  TEACHER_QUICK_REPORT: "/teacher/quick-report",
  TEACHER_COMMUNICATION_DASHBOARD: "/teacher/communication",
  TEACHER_VIDEO_CALL_SCREEN: "/teacher/videoCall",
  TEACHER_TASK_MANAGEMENT_SCREEN: "/teacher/taskManagement",
  TEACHER_BEHAVIOUR_DASHBOARD: "/teacher/behaviour",
  TEACHER_CHANGEPASSWORD: "/teacher/changepassword",
  TEACHER_ASSIGNMENT: "/teacher/assignment",
  TEACHER_ASSIGNMENT_DETAILS: "/teacher/assignmentDetail",
  TEACHER_SUBJECT_DETAILS: "/teacher/subjectDetail",
  TEACHER_SUBJECT_ALLOTMENT: "/teacher/subjectAllotment",
  TEACHER_ASSIGNMENT_VIEW_DETAILS: "/teacher/assignmentViewDetail",
  TEACHER_TESTS: "/teacher/tests",
  TEACHER_CREATE_TESTS: "/teacher/createTests",
  TEACHER_TESTS_MARK_ENTRY: "/teacher/tests/markEntry",
  TEACHER_TESTS_MARK_EVALUATION: "/teacher/tests/markEvaluation",
  TEACHER_TESTS_REPORT: "/teacher/tests/report",
  TEACHER_TESTS_VIEW_ALL: "/teacher/tests/viewAll",
  TEACHER_TESTS_QUESTION_BANK: "/teacher/tests/questionbank",
  TEACHER_STUDENT_FORM_DETAILS: "/teacher/student/formDetails",
  TEACHER_SCHEDULE: "/teacher/schedule",

  PARENT: "/student",
  PARENT_PROFILE: "/student/profile",
  PARENT_COMMUNICATION: "/student/communication",
  PARENT_VIDEO_CALL_SCREEN: "/student/videoCall",
  PARENT_CHANGEPASSWORD: "/student/changepassword",
  PARENT_STUDENT_ASSIGNMENT: "/student/assignments",
  PARENT_STUDENT_TESTS: "/student/tests",
  STUDENT_TAKE_TESTS: "/student/takeTests",
  STUDENT_TESTS_REPORT: "/student/tests/report",
  STUDENT_TESTS_RESULT: "/student/tests/result",
  STUDENT_PROFILE_FORM_DETAIL: "/student/profile/formDetail",
  PARENT_STUDENT_SCHEDULE: "/student/schedule",

  FOUNDER: "/founder",
  FOUNDER_COMMUNICATION: "/founder/communication",
  FOUNDER_PROFILE: "/founder/profile",
  FOUNDER_CHANGEPASSWORD: "/founder/changepassword",
  FOUNDER_SUPER_ADMIN: "/founder/superAdmin",
  FOUNDER_EDIT_SUPER_ADMIN: "/founder/superAdmin/edit",
  FOUNDER_ADMIN: "/founder/admin",
  FOUNDER_TEACHER: "/founder/teacher",
  FOUNDER_STUDENT: "/founder/student",
  FOUNDER_QUESTIONBANK: "/founder/questionbank",
  FOUNDER_BEHAVIOUR: "/founder/behaviour",
}
