/*************************************************
 * RedInk
 * @exports
 * @class SubjectsScreen.js
 * @extends Component
 * @author Sasidharan // on 06/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { HashLoader } from "react-spinners"
import { components } from "react-select"
import { DatePicker } from "antd"
import makeAnimated from "react-select/animated"
import { connect } from "react-redux"
import moment from "moment"
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Collapse,
  Button,
  Label,
  Input,
  Table,
} from "reactstrap"

import "antd/dist/antd.css"

import {
  getAssessmentDetails,
  saveAssessmentDetails,
  updateAssessmentDetails,
  deleteAssessmentDetails,
  deleteDetailAssessmentDetails,
} from "../../../../actions/admin/AssessmentDetailsAction"
import { myLog, confirmAlertPopUp } from "../../../../utils/Utility"
import { VALIDATION_MSG } from "../../../../utils/Constants"
import { getClassGroup } from "../../../../actions/admin/ClassDetailsAction"
import { URL_PATH } from "../../../../config/urlPath"

import { MySelect } from "../../../common/MySelect"

const { RangePicker } = DatePicker
const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  )
}

const allOption = {
  label: "Select all",
  value: "*",
}

const MultiValue = props => {
  let labelToBeDisplayed = `${props.data.label}, `
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = "Selected All"
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  )
}

const ValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue()
  let toBeRendered = children
  if (currentValues.some(val => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]]
  }

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  )
}

const animatedComponents = makeAnimated()

class AssesmentDetailsScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      advanceOpen: true,
      isShowLoading: false,
      isLoading: true,
      assessmentName: "",
      assessmentShortName: "",
      assessmentType: "",
      termScoreEvaluation: true,
      mustPass: true,
      numberOfAssessment: 1,
      allocationDetail: [{ sectionGroups: [], maxMark: 0 }],
      addAssesmentOpen: false,
      assessmentList: [
        {
          id: 1,
          value: "FA",
          keyName: "Formative assessment",
        },
        {
          id: 2,
          value: "CA",
          keyName: "Continuous assessment",
        },
        {
          id: 3,
          value: "SA",
          keyName: "Summative assessment",
        },
        {
          id: 4,
          value: "SBA",
          keyName: "Subjective assessment",
        },
        {
          id: 5,
          value: "SEM",
          keyName: "Semester assessment",
        },

        {
          id: 6,
          value: "CT",
          keyName: "Cycle test",
        },
        {
          id: 7,
          value: "UT",
          keyName: "Unit test",
        },
        {
          id: 8,
          value: "ACT",
          keyName: "Activities",
        },
        {
          id: 9,
          value: "QAU",
          keyName: "Quarterly",
        },
        {
          id: 10,
          value: "HY",
          keyName: "Half-yearly",
        },
        {
          id: 11,
          value: "FE",
          keyName: "Final exams",
        },
        {
          id: 12,
          value: "ME",
          keyName: "Model exam",
        },
        {
          id: 13,
          value: "WT",
          keyName: "Weekly test",
        },
        {
          id: 14,
          value: "MOT",
          keyName: "Monthly test",
        },
        {
          id: 15,
          value: "MT",
          keyName: "Mid-term",
        },
        {
          id: 16,
          value: "PT",
          keyName: "Periodic Test",
        },
        {
          id: 17,
          value: "Sub En",
          keyName: "Subject Enrichment",
        },
        {
          id: 18,
          value: "NB",
          keyName: "Notebook",
        },
      ],
    }
  }

  componentDidMount = () => {
    this.props.getAssessmentDetails((success, response) => {
      if (success) {
        this.props.getClassGroup({}, () => {})
        this.setState({ addAssesmentOpen: false, isLoading: false })
      }
    })
  }

  deleteAssessmentDetailFromDb = (item, index) => {
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT,
      yes => {
        if (yes) {
          this.props.deleteDetailAssessmentDetails(
            {
              assessmentId: item.assessmentId,
              groupId: item.sectionGroups[0].groupId,
            },
            success => {
              if (success) {
                let array = this.state.allocationDetail
                array.splice(index, 1)
                this.setState({ allocationDetail: array }, () => {})
              }
            }
          )
        }
      }
    )
  }

  deleteAssessmentFromDb = item => {
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT,
      yes => {
        if (yes) {
          this.props.deleteAssessmentDetails(
            {
              assessmentId: item.assessmentId,
            },
            () => {}
          )
        }
      },
      {
        buttons: [],
        customUI: ({ onClose }) => {
          return (
            <div
              className="custom"
              style={{
                backgroundColor: "#fff",
                borderBottomRightRadius: "12px",
                borderBottomLeftRadius: "12px",
              }}
            >
              <div
                className="bg-brand text-light d-flex"
                style={{
                  padding: "0.5rem 2rem",
                  borderTopRightRadius: "12px",
                  borderTopLeftRadius: "12px",
                }}
              >
                <div className="mx-auto fs-18">Delete</div>
              </div>

              <div
                className="mt-3"
                style={{
                  padding: "0.5rem 1.5rem",
                }}
              >
                <div className="text-muted fs-15">
                  Are you sure you want to delete?
                </div>
                <div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
                  <button
                    className="px-4 py-1 bg-brand text-light w-25 d-flex align-items-center justify-content-center yes-button"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      this.props.deleteAssessmentDetails(
                        {
                          assessmentId: item.assessmentId,
                        },
                        () => {}
                      )
                      onClose()
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="px-4 py-1 ml-4 bg-brand text-light w-25 d-flex align-items-center justify-content-center"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={onClose}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )
        },
      }
    )
  }

  saveSubject = () => {
    const {
      assessmentName,
      assessmentShortName,
      assessmentType,
      termScoreEvaluation,
      mustPass,
      allocationDetail,
    } = this.state
    if (
      assessmentName.trim() !== "" &&
      assessmentShortName.trim() !== "" &&
      allocationDetail.length > 0 &&
      allocationDetail[0].sectionGroups.length > 0 &&
      allocationDetail[0].maxMark > 0
    ) {
      this.setState({ isShowLoading: true })

      if (this.state.isShowLoading) return
      let object = {
        assessmentName, assessmentShortName,
        assessmentType, termScoreEvaluation, allocationDetails: allocationDetail.map(alloc => ({
          ...alloc,
          assessmentFrom: moment(alloc.assessmentFrom).format("YYYY-MM-DD"),
          assessmentTo: moment(alloc.assessmentTo).format("YYYY-MM-DD"),
        })), mustPass
      } // prettier-ignore
      this.props.saveAssessmentDetails(object, success => {
        this.setState({ isShowLoading: false })
        if (success) {
          this.clearData()
          this.setState({
            addAssesmentOpen: false,
            hasError: false,
          })
        }
      })
    } else {
      this.setState({ hasError: true })
    }
  }

  updateAssessment = () => {
    if (
      this.state.assessmentName !== "" &&
      this.state.assessmentShortName !== "" &&
      this.state.allocationDetail.length > 0 &&
      this.state.allocationDetail[0].sectionGroups.length > 0 &&
      this.state.allocationDetail[0].maxMark > 0
    ) {
      this.setState({
        isShowLoading: true,
      })
      if (this.state.isShowLoading) return
      let object = {
        assessmentName: this.state.assessmentName,
        assessmentShortName: this.state.assessmentShortName,
        assessmentType: this.state.assessmentType,
        termScoreEvaluation: this.state.termScoreEvaluation,
        mustPass: this.state.mustPass,
        assessmentId: this.state.updateSubjectDetailId,
        allocationDetails: this.state.allocationDetail.map(alloc => ({
          ...alloc,
          assessmentFrom: moment(alloc.assessmentFrom).format("YYYY-MM-DD"),
          assessmentTo: moment(alloc.assessmentTo).format("YYYY-MM-DD"),
        })),
      }
      myLog("Update call back", this.state.isShowLoading)
      this.props.updateAssessmentDetails(object, success => {
        this.setState({
          isShowLoading: false,
        })
        if (success) {
          this.clearData()
          this.setState({
            addAssesmentOpen: false,
            hasError: false,
          })
        }
      })
    } else {
      this.setState({ hasError: true })
    }
  }

  clearData = () => {
    this.setState({
      assessmentName: "",
      assessmentShortName: "",
      assessmentType: "",
      termScoreEvaluation: true,
      mustPass: true,
      allocationDetail: [
        { assessmentFrom: "", assessmentTo: "", sectionGroups: [], maxMark: 0 },
      ],
      isShowUpdateSubjectInput: false,
    })
  }

  editAssesment = item => {
    let array = []
    this.setState({
      addAssesmentOpen: true,
      isShowUpdateSubjectInput: true,
      updateSubjectDetailId: item.assessmentId,
      assessmentType: item.assessmentType,
      assessmentName: item.assessmentName,
      assessmentShortName: item.assessmentShortName,
      termScoreEvaluation: item.termScoreEvaluation,
      mustPass: item.mustPass,
      // allocationDetails: [
      //   {
      //     sectionGroups: item.sectionGroups ? item.sectionGroups : [],
      //     maxMark: item.maxMark,
      //     allocationId: item.allocationId,
      //     termId: item.termId,
      //     termName: item.termName,
      //     termShortName: item.termShortName,
      //   },
      // ],
      allocationDetail: item.allocationDetails,
      date: array,
    })
    setTimeout(() => {
      let el = document.getElementById("admin-sp-ay-ad-type")
      el.focus()
    }, 500)
  }

  cloneAssesment = item => {
    let array = []

    for (let i = 0; i < item.allocationDetails.length; i++) {
      let object = {
        sectionGroups: item.allocationDetails[i].sectionGroups,
        maxMark: item.allocationDetails[i].maxMark,
      }
      array.push(object)
    }
    this.setState({
      addAssesmentOpen: true,
      isShowUpdateSubjectInput: false,
      assessmentType: item.assessmentType,
      assessmentName: item.assessmentName + "(copy)",
      assessmentShortName: item.assessmentShortName + "(copy)",
      termScoreEvaluation: item.termScoreEvaluation,
      mustPass: item.mustPass,
      // allocationDetails: [
      //   {
      //     sectionGroups: item.sectionGroups ? item.sectionGroups : [],
      //     maxMark: item.maxMark,
      //     allocationId: item.allocationId,
      //     termId: item.termId,
      //     termName: item.termName,
      //     termShortName: item.termShortName,
      //   },
      // ],
      allocationDetail: array,
    })
  }

  addMoreAllocations = () => {
    let array = this.state.allocationDetail
    array.push({
      sectionGroups: [], maxMark: 0, assessmentFrom: "", assessmentTo: "",
    }) // prettier-ignore
    this.setState({ allocationDetail: array })
  }

  deleteAllocation = i => {
    if (this.state.allocationDetail.length > 1) {
      let array = this.state.allocationDetail
      if (
        this.state.allocationDetail[i].allocationId &&
        this.state.allocationDetail[i].allocationId !== ""
      ) {
        this.deleteAssessmentDetailFromDb(this.state.allocationDetail[i], i)
      } else {
        array.splice(i, 1)
        this.setState({ allocationDetail: array }, () => {})
      }
    }
  }

  onRadioChange = e => {
    this.setState({
      considerAsTSE: e.target.value,
    })
  }

  createNewAssessment = () => {
    this.setState({
      addAssesmentOpen: true,
      assessmentName: "",
      assessmentShortName: "",
      assessmentType: "",
      termScoreEvaluation: true,
      mustPass: true,
      isShowUpdateSubjectInput: false,
      hasError: false,
      allocationDetail: [
        {
          sectionGroups: [],
          maxMark: 0,
          assessmentFrom: "",
          assessmentTo: "",
        },
      ],
    })
    setTimeout(() => {
      let el = document.getElementById("admin-sp-ay-ad-type")
      el.focus()
    }, 500)
  }

  render() {
    return (
      <>
        <div className="row mb-2">
          <div className="col d-flex align-items-center justify-content-end">
            <Button
              size="sm"
              color="light"
              onClick={this.createNewAssessment}
              className="rounded-pill"
            >
              <i className="fas fa-plus text-dark"></i> Create New Assessment
            </Button>
          </div>
        </div>

        <Collapse isOpen={this.state.addAssesmentOpen}>
          <div className="d-flex">
            <div className="col-md-4">
              {/* <FormGroup>
                <select
                  className="form-control mr-3 mb-2"
                  value={this.state.selectedSubmitStatus}
                  onChange={e => {
                    this.setState(
                      { selectedSubmitStatus: e.target.value },
                      () => {
                        let array = []
                        if (this.state.selectedSubmitStatus === "ALL")
                          array = this.state.displayStudentAssignment
                        else
                          array = this.state.displayStudentAssignment.filter(
                            item =>
                              item.isSubmitted ==
                              this.state.selectedSubmitStatus
                          )
                        this.setState({ studentAssignment: array })
                      }
                    )
                  }}
                >
                  <option value="ALL">Select Term</option>
                  {this.state.submitStatusList &&
                    this.state.submitStatusList.map((item, index) => {
                      return (
                        <option key={index} value={item.status}>
                          {item.name}
                        </option>
                      )
                    })}
                </select>
              </FormGroup> */}
              <FormGroup>
                <Label htmlFor="assessmentType">Assessment Type</Label>
                <select
                  id="admin-sp-ay-ad-type"
                  className="form-control mr-3 mb-2"
                  value={this.state.assessmentType}
                  onChange={e => {
                    // let findClass = this.state.assessmentList.find(item => {
                    //   return item.value === e.target.value
                    // })
                    this.setState({
                      assessmentType: e.target.value,
                      // assessmentName: findClass.keyName,
                      // assessmentShortName: e.target.value,
                    })
                  }}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      let el = document.getElementById("assessmentName")
                      el.focus()
                    }
                  }}
                >
                  <option value="">Select Assessment Type</option>
                  {this.state.assessmentList &&
                    this.state.assessmentList.map(item => (
                      <option key={item.id} value={item.value}>
                        {item.keyName}
                      </option>
                    ))}
                </select>
              </FormGroup>
              <div className="d-flex justify-content-center">
                {this.state.assessmentType === "" && this.state.hasError && (
                  <p className="text-danger">Assessment Type is required.</p>
                )}
              </div>
              <FormGroup>
                <Label htmlFor="assessmentName">Assessment Name</Label>
                <Input
                  type="text"
                  name="assessmentName"
                  id="assessmentName"
                  value={this.state.assessmentName}
                  onChange={e =>
                    this.setState({ assessmentName: e.target.value })
                  }
                  onKeyDown={e => {
                    if (e.key === "Enter" && e.target.value) {
                      let el = document.getElementById("assessmentShortName")
                      el.focus()
                    }
                  }}
                />
              </FormGroup>
              <div className="d-flex justify-content-center">
                {this.state.assessmentName === "" && this.state.hasError && (
                  <p className="text-danger">Assessment Name is required.</p>
                )}
              </div>
              <FormGroup>
                <Label htmlFor="assessmentShortName">Short Name</Label>
                <Input
                  type="text"
                  name="assessmentShortName"
                  id="assessmentShortName"
                  value={this.state.assessmentShortName}
                  onChange={e =>
                    this.setState({ assessmentShortName: e.target.value })
                  }
                  onKeyDown={e => {
                    if (e.key === "Enter" && e.target.value) {
                      let el = document.getElementById("admin-sp-ay-ad-term")
                      el.focus()
                    }
                  }}
                />
              </FormGroup>
              <div className="d-flex justify-content-center">
                {this.state.assessmentShortName === "" &&
                  this.state.hasError && (
                    <p className="text-danger">
                      Assessment Short Name is required.
                    </p>
                  )}
              </div>
              <FormGroup>
                <Label htmlFor="termScoreEvaluation" className="mr-5">
                  Consider this assessment for Term Score Evaluation
                </Label>
                <div className="row">
                  <div className="radio px-5">
                    <label>
                      <input
                        id="admin-sp-ay-ad-term"
                        type="radio"
                        name="termScoreEvaluation"
                        value="Yes"
                        checked={this.state.termScoreEvaluation}
                        onChange={e =>
                          this.setState({
                            termScoreEvaluation: true,
                          })
                        }
                        onKeyDown={e => {
                          if (e.key === "Enter") {
                            let el = document.getElementById(
                              "admin-sp-ay-ad-rank"
                            )
                            el.focus()
                          }
                        }}
                      />
                      &ensp;Yes
                    </label>
                  </div>
                  <div className="radio px-5">
                    <label>
                      <input
                        type="radio"
                        name="termScoreEvaluation"
                        value="No"
                        checked={!this.state.termScoreEvaluation}
                        onChange={e =>
                          this.setState({
                            termScoreEvaluation: false,
                          })
                        }
                        onKeyDown={e => {
                          if (e.key === "Enter") {
                            let el = document.getElementById(
                              "admin-sp-ay-ad-rank"
                            )
                            el.focus()
                          }
                        }}
                      />
                      &ensp;No
                    </label>
                  </div>
                </div>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="mustPass" className="mr-5">
                  Must pass this exam to get term rank?
                </Label>
                <div className="row">
                  <div className="radio px-5">
                    <label>
                      <input
                        id="admin-sp-ay-ad-rank"
                        type="radio"
                        name="mustPass"
                        value="Yes"
                        checked={this.state.mustPass}
                        onChange={e =>
                          this.setState({
                            mustPass: true,
                          })
                        }
                      />
                      &ensp;Yes
                    </label>
                  </div>
                  <div className="radio px-5">
                    <label>
                      <input
                        type="radio"
                        name="mustPass"
                        value="No"
                        checked={!this.state.mustPass}
                        onChange={e =>
                          this.setState({
                            mustPass: false,
                          })
                        }
                      />
                      &ensp;No
                    </label>
                  </div>
                </div>
              </FormGroup>
              {/* <Button
                className="d-flex align-items-center"
                color="light"
                onClick={() =>
                  this.setState({ advanceOpen: !this.state.advanceOpen })
                }
              >
                {this.state.advanceOpen ? "Hide" : "Show"} Advance Settings
              </Button> */}
            </div>
            <div className="col-md"></div>
          </div>
          {this.props.classGroupsList &&
          this.props.classGroupsList.length > 0 ? (
            this.state.advanceOpen ? (
              <>
                <div className="d-flex justify-content-start mt-3">
                  <div className="col-md" style={{ maxWidth: 300 }}>
                    <h5 className="text-dark font-weight-bold">Assign class</h5>
                  </div>
                  <div className="col-sm-3">
                    <h5 className="text-dark font-weight-bold">
                      Assessment Date
                    </h5>
                  </div>
                  <div className="col-sm" style={{ maxWidth: 230 }}>
                    <h5 className="text-dark font-weight-bold">Max Marks</h5>
                  </div>
                  <div className="col-sm"></div>
                </div>

                {this.state.allocationDetail &&
                  this.state.allocationDetail.map((item, index) => (
                    <div
                      className="d-flex justify-content-start mb-3"
                      key={index}
                    >
                      <div
                        className="col-md d-flex align-items-center"
                        style={{ maxWidth: 300 }}
                      >
                        <div className="my-auto" style={{ flex: 1 }}>
                          <MySelect
                            // isDisabled={
                            //   this.state.isShowUpdateSubjectInput &&
                            //   item.sectionGroups.length !== 0
                            // }
                            placeholder="Select Class"
                            isClearable
                            value={item.sectionGroups.map(it => ({
                              value: it.groupName ? it.groupName : it.value,
                              label: it.groupName ? it.groupName : it.label,
                              id: it.groupId,
                            }))}
                            options={this.props.classGroupsList.map(it => ({
                              value: it.groupName ? it.groupName : it.value,
                              label: it.groupName ? it.groupName : it.label,
                              id: it.groupId,
                            }))}
                            className="basic-multi-select"
                            isMulti
                            allowSelectAll
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                            components={{
                              Option,
                              MultiValue,
                              ValueContainer,
                              animatedComponents,
                            }}
                            classNamePrefix="select"
                            onChange={selectedOption => {
                              if (selectedOption !== null) {
                                let array = []
                                for (
                                  let i = 0;
                                  i < selectedOption.length;
                                  i++
                                ) {
                                  if (
                                    selectedOption[i].label !== "Select all"
                                  ) {
                                    array.push({
                                      groupId: selectedOption[i].id,
                                      groupName: selectedOption[i].label,
                                    })
                                  }
                                }
                                this.setState(state => {
                                  state.allocationDetail[
                                    index
                                  ].sectionGroups = array
                                  return state
                                })
                              } else {
                                this.setState(state => {
                                  state.allocationDetail[
                                    index
                                  ].sectionGroups = []
                                  return state
                                })
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3 d-flex flex-column justify-content-center">
                        <RangePicker
                          onChange={([assessmentFrom, assessmentTo]) => {
                            this.setState(state => {
                              state.allocationDetail[
                                index
                              ].assessmentFrom = assessmentFrom
                              state.allocationDetail[
                                index
                              ].assessmentTo = assessmentTo
                              return state
                            })
                          }}
                          value={[
                            item.assessmentFrom
                              ? moment(item.assessmentFrom)
                              : "",
                            item.assessmentTo ? moment(item.assessmentTo) : "",
                          ]}
                          format="DD-MM-YYYY"
                        />
                      </div>
                      <div
                        className="col-sm d-flex align-items-center"
                        style={{ maxWidth: 230 }}
                      >
                        <Input
                          type="number"
                          name="maxMark"
                          id="maxMark"
                          value={item.maxMark}
                          onChange={e => {
                            const value = e.target.value
                            if (value.length <= 3) {
                              let array = this.state.allocationDetail
                              array[index].maxMark = value
                              this.setState({ allocationDetail: array })
                            }
                          }}
                        />
                      </div>
                      <div className="col-sm d-flex align-items-center">
                        <button
                          className="btn btn-light btn-lg mr-3"
                          onClick={() => this.addMoreAllocations(index)}
                        >
                          <i className="fas fa-plus text-dark"></i>
                        </button>
                        <Button
                          color="light"
                          className="mr-3"
                          size="lg"
                          onClick={() => this.deleteAllocation(index)}
                        >
                          <i className="far fa-trash-alt text-danger"></i>
                        </Button>
                      </div>
                    </div>
                  ))}
                <div className="d-flex mr-5">
                  {this.state.allocationDetail[0].sectionGroups.length === 0 &&
                    this.state.allocationDetail[0].maxMark === 0 &&
                    this.state.hasError && (
                      <p className="text-danger">
                        Assessment Details is required.
                      </p>
                    )}
                </div>
              </>
            ) : (
              <></>
            )
          ) : (
            <div className="d-flex mt-3 mb-2 ml-2">
              <div className="alert alert-info fade show">
                Please create classes and class group in records tab in left
                menu or{" "}
                <div
                  onClick={() => {
                    this.props.history.push({
                      pathname: URL_PATH.ADMIN_SCHOOL_PROFILE_CLASS_DETAILS,
                      state: {},
                    })
                  }}
                >
                  click here
                </div>
              </div>
            </div>
          )}

          <div className="d-flex align-items-center justify-content-end my-3">
            <Button
              style={{
                backgroundColor: "#DBDBDB",
                color: "#06B191",
                border: "none",
                width: "5rem",
              }}
              className="rounded-pill"
              onClick={() => {
                this.props.getAssessmentDetails(() => {})
                this.setState({ addAssesmentOpen: false })
              }}
            >
              cancel
            </Button>
            <Button
              style={{
                backgroundColor: "#0eb292",
                border: "none",
                width: "5rem",
              }}
              className="rounded-pill ml-3"
              onClick={() => {
                if (!this.state.isShowUpdateSubjectInput) this.saveSubject()
                else this.updateAssessment()
              }}
            >
              {this.state.isShowLoading ? (
                <HashLoader
                  css={{ paddingLeft: "40px" }}
                  size={16}
                  color="#fff"
                  loading={this.state.isShowLoading}
                  sizeUnit="px"
                />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </Collapse>

        <Table>
          <thead
            style={{
              backgroundColor: "#022636",
              textTransform: "uppercase",
              color: "#fff",
            }}
          >
            <tr>
              <th className="text-center">Assessment Name</th>
              {/* <th className="text-center">Max. Mark</th> */}
              <th>Assigned Classes</th>
              <th></th>
            </tr>
          </thead>

          {this.state.isLoading ? (
            <HashLoader
              css={{
                position: "fixed",
                width: "100%",
                height: "100%",
                left: "0px",
                top: "0px",
              }}
              size={30}
              color="#084353"
              loading
              sizeUnit="px"
            />
          ) : (
            <tbody>
              {this.props.assessmentDetailList &&
                this.props.assessmentDetailList.map(item => (
                  <tr key={item.id}>
                    <td className="text-center">
                      <h5 className="m-0 text-dark">
                        {item.assessmentShortName}
                      </h5>
                    </td>
                    {/* <td className="text-center">
                    <h5 className="m-0 text-dark">
                      {item.maxMark !== "" ? item.maxMark : "-"}
                    </h5>
                  </td> */}
                    <td className="text-center">
                      <div className="d-flex flex-wrap">
                        {item.allocationDetails &&
                          item.allocationDetails.map((subject, j) => (
                            <div
                              key={j}
                              className="badge badge-info px-3 py-2 my-1 mr-2"
                            >
                              {subject.sectionGroups[0] &&
                                subject.sectionGroups[0].label}
                            </div>
                          ))}
                      </div>
                    </td>
                    <td>
                      <UncontrolledDropdown direction="left">
                        <DropdownToggle tag="button" className="nav-link">
                          <i className="fas fa-ellipsis-v text-black cursor-pointer"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => this.editAssesment(item)}
                          >
                            Edit
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => this.cloneAssesment(item)}
                          >
                            Clone
                          </DropdownItem>
                          {/* <DropdownItem
                          onClick={() => {
                            this.deleteAssessmentDetailFromDb(item)
                          }}
                        >
                          Delete
                        </DropdownItem> */}
                          <DropdownItem
                            onClick={() => {
                              this.deleteAssessmentFromDb(item)
                            }}
                          >
                            Delete Assessment
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </Table>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    assessmentDetailList: state.adminAcademicYearDetailsState.AssessmentDetail,
    classGroupsList: state.classDetailsState.classGroupsList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAssessmentDetails,
      saveAssessmentDetails,
      updateAssessmentDetails,
      deleteAssessmentDetails,
      deleteDetailAssessmentDetails,
      getClassGroup,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(AssesmentDetailsScreen)
