import React, { useState } from "react"
import { SortableContext, useSortable } from "@dnd-kit/sortable"
import { DndContext } from "@dnd-kit/core"
import { CSS } from "@dnd-kit/utilities"
import { Modal } from "reactstrap"

import { ReactComponent as Delete } from "../../../../assets/images/delete-gp.svg"
import { ReactComponent as Close } from "../../../../assets/images/close.svg"
import dots6 from "../../../../assets/images/6dots.png"

function Wrapper({ id, secNo, title, mark, updateData, deleteSec }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    isDragging,
  } = useSortable({ id })

  return (
    <div
      ref={setNodeRef}
      style={{ transform: CSS.Translate.toString(transform) }}
      className="add-or-edit-qp-input-wapper d-flex align-items-center gap-18px mb-4 px-4 py-3 bg-white br-5px"
    >
      <button
        {...listeners}
        {...attributes}
        style={{ cursor: isDragging ? "grabbing" : "grab" }}
      >
        <img src={dots6} alt="" />
      </button>

      <div className="mb-5 flex-1">
        <button className="px-2 py-0 fs-13 br-5px bg-8C8C8C text-white">
          Section {secNo}
        </button>

        <div className="d-flex gap-2rem mt-1">
          <div>
            <label className="clr-A1A1A1">Section name</label>
            <input
              type="text"
              className="w-270px fw-600 br-5px clr-201F1F"
              value={title}
              onChange={e => updateData(id, "displayName", e.target.value)}
            />
          </div>

          <div className="mx-auto">
            <label className="clr-A1A1A1">Mark per question</label>
            <input
              min={1}
              type="number"
              className="w-124px fw-600 br-5px clr-201F1F text-center"
              value={mark}
              onChange={e => updateData(id, "mark", e.target.valueAsNumber)}
            />
          </div>

          <button
            style={{ marginTop: "25px", marginRight: "8px" }}
            onClick={deleteSec}
          >
            <Delete />
          </button>
        </div>
      </div>
    </div>
  )
}

function AddOrEditSection({ data, setData, closeModal }) {
  const [dublicate, setDublicate] = useState([...data])

  function handleDragEnd({ active, over }) {
    const activeIndex = active?.data?.current?.sortable?.index
    const overIndex = over?.data?.current?.sortable?.index
    const itemsCopy = [...data]
    const [reorderedItem] = itemsCopy.splice(activeIndex, 1)
    itemsCopy.splice(overIndex, 0, reorderedItem)
    setDublicate(itemsCopy)
  }

  const updateData = (id, key, val) => {
    setDublicate(prev =>
      prev.map(p => {
        if (p.id === id) {
          return {
            ...p,
            [key]: val,
          }
        }

        return p
      })
    )
  }

  const deleteSec = id => setDublicate(prev => prev.filter(p => p.id !== id))

  const onDone = () => {
    setData([...dublicate])
    closeModal()
  }

  return (
    <Modal
      isOpen
      backdropClassName="modal-backdrop-show"
      modalClassName="modal-dialog-shadow-none report-card-modal-full-wrapper"
      contentClassName="report-modal-wrapper py-4 px-5 bg-F6F7F8"
      centered
      size="lg"
    >
      <div className="d-flex align-items-center mb-4">
        <p className="mb-0 fs-18 fw-700">Edit Sections</p>

        <Close
          width={28}
          height={28}
          onClick={closeModal}
          className="ml-auto cursor-pointer"
        />
      </div>

      <div className="manual-quest-wrapper">
        <DndContext onDragEnd={handleDragEnd}>
          <SortableContext id="add-edit" items={dublicate.map(d => d.id)}>
            {dublicate.map((t, i) => (
              <Wrapper
                key={t.id}
                id={t.id}
                title={t.displayName}
                mark={t.mark}
                secNo={i + 1}
                updateData={updateData}
                deleteSec={() => deleteSec(t.id)}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>

      <div className="d-flex align-items-center justify-content-center gap-18px mt-4">
        {/* <button className="px-5 py-2 fs-16 fw-500 bg-E5E5E5 clr-939191 br-8px">
          +Add Section
        </button> */}

        <button
          className="px-5 py-2 fs-16 fw-500 bg-50AE93 text-white br-8px"
          onClick={onDone}
        >
          Done
        </button>
      </div>
    </Modal>
  )
}

export default AddOrEditSection
