/*************************************************
 * RedInk
 * @exports
 * @class Dashboard.js
 * @extends Component
 * @author Sasidharan // on 11/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import AppWrapper from "../Wrapper"
import { connect } from "react-redux"
import moment from "moment"
import { bindActionCreators } from "redux"
import { DANGER_TOAST, VALIDATION_MSG } from "../../../utils/Constants"
import {
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  FormGroup,
  Label,
  Modal,
  Button,
} from "reactstrap"
import {
  getTeacherSchedule,
  saveSchedule,
  getSingleScheduleDetails,
  deleteScheduleDetails,
  checkIntegration,
} from "../../../actions/teacher/ScheduleAction"
import {
  getTeacherAssignedDetails,
  getClassStudents,
} from "../../../actions/teacher/QuizAction"
import { confirmAlertPopUp } from "../../../utils/Utility" // myLog,
import { DatePicker, TimePicker } from "antd"
import "antd/dist/antd.css"
import Select, { components } from "react-select"
import { HashLoader } from "react-spinners"
import makeAnimated from "react-select/animated"
import { MySelect } from "../../common/MySelect"
import { showNotification } from "../../../actions/CommonAction"
import { uploadFile, getFile } from "../../../actions/CommonAction"
import { v4 as uuid } from "uuid"
import FullCalendar from "./Calendar"
import Skeleton from "react-loading-skeleton"

const Option = props => (
  <div>
    <components.Option {...props}>
      <div className="d-flex align-items-center">
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          className="subject-checkbox"
        />{" "}
        <label className="ml-2 mt-2">{props.label}</label>
      </div>
    </components.Option>
  </div>
)

const allOption = {
  id: null,
  label: "Select all",
  value: "*",
}

const ValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue()
  let toBeRendered = children
  if (currentValues.some(val => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]]
  }

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  )
}

const animatedComponents = makeAnimated()

const MultiValue = props => {
  let labelToBeDisplayed = `${props.data.label}, `
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = "Selected All"
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  )
}

class ScheduleScreen extends Component {
  constructor(props) {
    document.title = "Schedule | Redink"
    super(props)
    this.state = {
      createScheduleModalOpen: false,
      viewScheduleModalOpen: false,
      isAttachmentModalOpen: false,
      showIntegrationModal: false,

      events: [],
      forUpdatingEnts: false,

      classesList: null,
      selectedSchedule: null,
      scheduleType: "OFFLINE",
      title: "",
      description: "",
      attachments: [],
      selectedClass: {},
      selectedSubject: null,
      selectedStudents: [],
      startTime: moment(new Date()).add("5", "minutes"),
      duration: 30,
      onlineMeetingProvider: null,
      onlineMeetingDetails: null,
      repeat: null,
      isSavingSchedule: false,
      youtubeURL: null,
      attachmentFile: null,
      attachmentType: "file",
      isDownloadingAttachment: false,
      isUpdate: false,
      dataLoading: true,
    }
  }

  styleStudent = {
    option: (provided, state) => ({
      ...provided,
      color: "black",
      backgroundColor: "white",
    }),
  }

  componentDidMount() {
    this.props.getTeacherAssignedDetails({}, (success, data) => {
      if (success) {
        this.setState({
          classesList: data.sections || [],
          subjectsPerSection: data.assignedSubject || {},
          dataLoading: false,
        })
      }
    })
  }

  preselection = () => {
    const subjectsList = this.state.subjectsPerSection[
      this.state.classesList ? this.state.classesList[0].sectionId : ""
    ]

    this.setState({
      selectedClass: {
        id: this.state.classesList ? this.state.classesList[0].sectionId : "",
        label: this.state.classesList
          ? `${this.state.classesList[0].className}${this.state.classesList[0].sectionName}`
          : "",
        value: this.state.classesList
          ? `${this.state.classesList[0].className}${this.state.classesList[0].sectionName}`
          : "",
      },
      selectedSubject: {
        id: subjectsList ? subjectsList[0].subjectId : "",
        label: subjectsList ? subjectsList[0].subjectName : "",
        value: subjectsList ? subjectsList[0].subjectName : "",
      },
      startTime: moment(new Date()).add("5", "minutes"),
      duration: 30,
    })
    this.getStudents(
      this.state.classesList ? this.state.classesList[0].sectionId : ""
    )
  }

  changeSubject = selectedOption => {
    const subjectsList = this.state.subjectsPerSection[selectedOption.id]

    this.setState({
      selectedClass: selectedOption,
      subjectsList: subjectsList,
      // selectedSubject: "",
      selectedSubject: {
        id: subjectsList ? subjectsList[0].subjectId : "",
        label: subjectsList ? subjectsList[0].subjectName : "",
        value: subjectsList ? subjectsList[0].subjectName : "",
      },
    })
    this.getStudents(selectedOption.id)
  }

  updateEvents = payload => {
    this.setState({ events: payload })
  }

  errorHandling = message => {
    this.props.showNotification(DANGER_TOAST, VALIDATION_MSG.OOPS, message)
    this.setState({ isSavingSchedule: false })
  }

  clearData = () => {
    this.setState({
      selectedSchedule: null,
      scheduleType: "OFFLINE",
      startTime: moment(new Date()).add("5", "minutes"),
      title: "",
      description: "",
      attachments: [],
      duration: 30,
      onlineMeetingProvider: null,
      onlineMeetingDetails: null,
      repeat: null,
      youtubeURL: null,
      attachmentFile: null,
      attachmentType: null,
      selectedClass: [],
      selectedSubject: [],
      selectedStudents: [],
      // isUpdate: false,
    })
  }

  checkIntegration = () => {
    this.props.checkIntegration({}, (success, response) => {
      if (success && response.responseCode == 1) {
        this.setState({ showIntegrationModal: true })
      }
    })
  }

  parseFileNameFromURL = (fileName, locationLength) => {
    if (typeof fileName !== "string") return ""
    const locations = fileName.split("?")[0].split("/")
    ;[...Array(locationLength)].forEach(() => locations.shift())
    const removedLocation = locations.join("")
    const removedId = removedLocation.slice(removedLocation.indexOf("_") + 1)
    return decodeURIComponent(decodeURI(removedId))
  }

  disabledHours = () => {
    let hours = []
    for (let i = 0; i < moment().hour(); i++) {
      if (moment(this.state.startTime) <= moment()) {
        hours.push(i)
      }
    }
    return hours
  }

  disabledMinutes = selectedHour => {
    let minutes = []
    if (selectedHour === moment().hour()) {
      for (let i = 0; i < moment().minute(); i++) {
        if (moment(this.state.startTime) <= moment()) {
          minutes.push(i)
        }
      }
    }
    return minutes
  }

  getSingleScheduleDetails = (id, viewModal = false) => {
    this.props.getSingleScheduleDetails(id, (success, response) => {
      this.clearData()
      this.setState(
        { isShowLoading: false, selectedSchedule: response.data },
        () => {
          const {
            // selectedSchedule,
            scheduleType,
            title,
            description,
            duration,
            repeat,
            onlineMeetingProvider,
            onlineMeetingDetails,
          } = this.state.selectedSchedule
          const selectedClass = {}
          selectedClass.id = this.state.selectedSchedule.section.sectionId
          selectedClass.label =
            this.state.selectedSchedule.section.className +
            this.state.selectedSchedule.section.sectionName
          selectedClass.value =
            this.state.selectedSchedule.section.className +
            this.state.selectedSchedule.section.sectionName

          const selectedSubject = {}
          selectedSubject.id = this.state.selectedSchedule.subject.subjectId
          selectedSubject.label = this.state.selectedSchedule.subject.subjectName
          selectedSubject.value = this.state.selectedSchedule.subject.subjectName

          const selectedStudents = []
          for (const student of this.state.selectedSchedule.scheduleStudents) {
            selectedStudents.push({
              id: student.studentId,
              label:
                student?.student?.studentName ||
                student?.student?.fullName ||
                "",
              value:
                student?.student?.studentName ||
                student?.student?.fullName ||
                "",
            })
          }

          this.getStudents(selectedClass.id)
          this.setState(
            {
              scheduleType: scheduleType.toUpperCase(),
              title,
              description,
              duration,
              repeat,
              startTime: moment(this.state.selectedSchedule.startTime),
              attachments: this.state.selectedSchedule.scheduleAttachments,
              selectedClass,
              selectedSubject,
              selectedStudents,
              onlineMeetingProvider,
              onlineMeetingDetails,
              subjectsList: this.state.subjectsPerSection[selectedClass.id],
              // studentsList: this.getStudents(selectedClass.id),
            },
            () => {
              this.setState({ viewScheduleModalOpen: viewModal })
            }
          )
        }
      )
    })
  }

  parseFileName = (fileName, location) => {
    if (typeof fileName !== "string") return ""

    let removedLocation = fileName.replace(location, "")
    removedLocation = removedLocation.slice(
      0,
      removedLocation.indexOf("?X-Amz")
    )
    return decodeURI(removedLocation.slice(removedLocation.indexOf("_") + 1))
  }

  getAttachment = url => {
    this.props.getFile(url, (success, response) => {
      if (success) {
        let filename = this.parseFileName(
          response.url,
          "schedule/teacherUploads"
        )
        this.setState({ isDownloadingAttachment: true })
        const xhr = new XMLHttpRequest()
        xhr.onload = () => {
          const file = new Blob([xhr.response])
          const browserUrl = window.webkitURL || window.URL
          const fileUrl = browserUrl.createObjectURL(file)
          const a = document.createElement("a")
          a.href = fileUrl
          a.download = filename
          a.click()
          browserUrl.revokeObjectURL(fileUrl)
          this.setState({ isDownloadingAttachment: false })
        }
        xhr.open("GET", response.url, true)
        xhr.responseType = "blob"
        xhr.send(null)
      }
    })
  }

  deleteScheduleDetails = id => {
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT + "",
      yes => {
        if (yes) {
          this.props.deleteScheduleDetails(id, (success, response) => {
            if (success) {
              this.setState(prev => ({
                viewScheduleModalOpen: false,
                events: prev.events.filter(ev => ev.id !== id),
              }))
            }
          })
        }
      },
      {
        customUI: ({ onClose }) => {
          return (
            <div
              className="custom"
              style={{
                backgroundColor: "#fff",
                borderBottomRightRadius: "12px",
                borderBottomLeftRadius: "12px",
              }}
            >
              <div
                className="bg-brand text-light d-flex"
                style={{
                  padding: "0.5rem 2rem",
                  borderTopRightRadius: "12px",
                  borderTopLeftRadius: "12px",
                }}
              >
                <div className="mx-auto" style={{ fontSize: "18px" }}>
                  Delete
                </div>
              </div>

              <div
                className="mt-3"
                style={{
                  padding: "0.5rem 1.5rem",
                }}
              >
                <div className="text-muted" style={{ fontSize: "15px" }}>
                  Are you sure you want to delete?
                </div>
                <div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
                  <button
                    className="bg-brand text-light px-4 py-1 w-25 d-flex align-items-center justify-content-center yes-button"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      this.props.deleteScheduleDetails(
                        id,
                        (success, response) => {
                          if (success) {
                            this.setState(prev => ({
                              viewScheduleModalOpen: false,
                              events: prev.events.filter(ev => ev.id !== id),
                            }))
                          }
                        }
                      )
                      onClose()
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-brand text-light px-4 py-1 ml-4 w-25 d-flex align-items-center justify-content-center"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={onClose}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )
        },
      }
    )
  }

  saveSchedule = async () => {
    if (this.state.isSavingSchedule) return
    const {
      // selectedSchedule,
      scheduleType,
      title,
      description,
      attachments,
      selectedClass,
      selectedSubject,
      selectedStudents,
      startTime,
      duration,
      // repeat,
    } = this.state

    if (!title) {
      return this.errorHandling("Enter Title")
    }
    if (!selectedClass || !selectedClass.id) {
      return this.errorHandling("Select class...")
    }
    if (!selectedSubject || !selectedSubject.id) {
      return this.errorHandling("Select subject...")
    }
    if (!selectedStudents || selectedStudents.filter(e => e.id).length === 0) {
      return this.errorHandling("Select students...")
    }
    if (!startTime) {
      return this.errorHandling("Select date & time...")
    }
    if (!duration) {
      return this.errorHandling("Enter Duration...")
    }
    if (
      this.state.isUpdate === false &&
      moment(this.state.startTime) < moment()
    ) {
      return this.errorHandling("Select correct Date & Time...")
    }

    this.setState({ isSavingSchedule: true })

    const evData = () => {
      return new Promise((resolve, reject) => {
        let toQuery = {
          startTime: moment
            .utc(startTime)
            .startOf("day")
            .format("YYYY-MM-DD HH:mm:ss"),
          endTime: moment
            .utc(startTime)
            .endOf("day")
            .format("YYYY-MM-DD HH:mm:ss"),
        }
        this.props.getTeacherSchedule(toQuery, (success, res) => {
          if (success) {
            let mappedData = res.data.map(r => ({
              id: r.id,
              start: moment(r.startTime),
              end: moment(r.startTime).add(r.duration, "minutes"),
            }))

            if (this.state.isUpdate) {
              mappedData = mappedData.filter(
                r => r.id !== this.state.selectedSchedule.id
              )
            }

            let reduced = mappedData.reduce((prev, current) => {
              let check1 = moment(startTime).isBetween(
                current.start,
                current.end,
                "[]"
              )
              let check2 = moment(startTime)
                .add(duration, "minutes")
                .isBetween(current.start, current.end, "[]")
              return prev || check1 || check2
            }, false)
            resolve(reduced)
          }
        })
      })
    }

    const canProcced = await evData()

    if (canProcced) {
      return this.errorHandling(
        "Already have a schedule in the selected time..."
      )
    }

    let newAttachmentObj = []

    for (let attachment of attachments) {
      let attachmentUrl = ""
      if (attachment.attachmentType === "file") {
        let attachObj = {}
        if (typeof attachment.attachment === "string") {
          attachObj = {
            id: attachment.id,
            url: attachment.attachment,
            type: attachment.attachmentType,
            visible: attachment.visibility,
          }
        } else {
          const upload = await new Promise(resolve =>
            this.props.uploadFile(
              attachment.attachment,
              "schedule/teacherUploads",
              (success, response) => resolve({ success, response })
            )
          )
          if (upload.success) {
            attachmentUrl = upload.response.name
          }
          attachObj = {
            id: attachment.id,
            url: attachmentUrl,
            type: attachment.attachmentType,
            visible: attachment.visibility,
          }
        }
        newAttachmentObj.push(attachObj)
      }
      if (attachment.attachmentType === "youtube") {
        const attachObj = {
          id: attachment.id,
          url: attachment.attachment,
          type: attachment.attachmentType,
          visible: attachment.visibility,
        }
        newAttachmentObj.push(attachObj)
      }
    }

    let students = []
    selectedStudents.forEach(item => {
      if (item.label !== "Select all") {
        students.push(item.id)
      }
    })

    const body = {
      sectionId: selectedClass.id,
      subjectId: selectedSubject.id,
      scheduleType: scheduleType.toLowerCase(),
      title: title,
      description: description,
      startTime: moment.utc(moment(startTime, "YYYY-MM-DD HH:mm:ss")),
      duration: duration,
      studentIds: students,
      attachments: newAttachmentObj,
    }

    if (this.state.isUpdate) {
      body.id = this.state.selectedSchedule.id
      body.isUpdate = this.state.isUpdate
    }

    this.props.saveSchedule(body, (success, scheduleData) => {
      if (success) {
        this.setState(prev => ({
          createScheduleModalOpen: false,
          isSavingSchedule: false,
          forUpdatingEnts: !prev.forUpdatingEnts,
        }))
        this.clearData()
      }
      this.setState({ isSavingSchedule: false, isUpdate: false })
    })
  }

  toggleDropdown = () => {
    this.setState({
      isDropDownopen: !this.state.isDropDownopen,
    })
  }

  parseFileName = (fileName, location) => {
    if (typeof fileName !== "string") return ""

    const removedLocation = fileName.replace(location, "")
    return decodeURI(removedLocation.slice(removedLocation.indexOf("_") + 1))
  }

  getStudents = classId => {
    this.props.getClassStudents(classId, (success, data) => {
      if (success) {
        this.setState({
          studentsList: data,
          selectedStudents: [{ label: "All Students", value: "*" }].concat(
            data.map(student => ({
              label: student.studentName || student.fullName,
              value: student.studentName || student.fullName,
              id: student.studentId,
            }))
          ),
        })
      }
    })
  }

  selectFile = e => {
    const allowed = [
      "pdf",
      "gif",
      "jpg",
      "jpeg",
      "png",
      "doc",
      "docx",
      "xls",
      "xlsx",
      "ppt",
      "pptx",
    ]
    const allFiles = Object.values(e.target.files).map(file => file)
    for (let i = 0; i < allFiles.length; i++) {
      const ext = allFiles[i].name.split(".").pop()
      if (!allowed.includes(ext)) {
        return this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Attach a pdf/image/doc/excel file..."
        )
      }
    }
    this.setState({ attachmentFile: allFiles })
  }

  onInsertAttachments = () => {
    if (this.state.attachmentType === "youtube" && !this.state.youtubeURL) {
      return this.errorHandling("Enter URL...")
    }
    if (this.state.attachmentType === "file" && !this.state.attachmentFile) {
      return this.errorHandling("Select a File...")
    }

    const newAttachment = []
    if (this.state.attachmentType === "youtube") {
      newAttachment.push({
        id: uuid(),
        attachment: this.state.youtubeURL || "",
        attachmentType: "youtube",
        visibility: true,
      })
    } else {
      this.state.attachmentFile.forEach(file => {
        newAttachment.push({
          id: uuid(),
          attachment: file,
          attachmentType: "file",
          visibility: true,
        })
      })
    }

    this.setState({
      attachments: [...newAttachment, ...this.state.attachments],
      isAttachmentModalOpen: false,
      attachmentType: null,
      attachmentFile: null,
    })
  }

  renderIntegrationModal = () => (
    <Modal
      isOpen={this.state.showIntegrationModal}
      backdropClassName="modal-backdrop-show"
      modalClassName="modal-dialog-shadow-none"
      contentClassName="border-14px"
      centered={true}
    >
      <div
        className="modal-body p-4"
        style={{ backgroundColor: "#F1F7F7", borderRadius: 12 }}
      >
        <h5 className="mb-4 text-brand font-weight-bold">
          Zoom integration Required
        </h5>
        <div className="row align-items-center justify-content-between mx-3 mb-3">
          <p>
            To add new online lecture schedule you have to integrate with ZOOM
            Meeting
          </p>
        </div>
        <div className="d-flex align-items-center justify-content-around mt-4">
          <span></span>
          <Button
            className="bg-brand text-light px-4 py-1"
            loading={this.state.isSavingSchedule}
            style={{ borderRadius: 12, border: 0 }}
            onClick={() => {
              // this.props.history.push({pathname: URL_PATH.ZOOM_INTEGRATION})
              window.location.href =
                "https://zoom.us/oauth/authorize?response_type=code&client_id=npW2qm3SoQNxiEaF2mg&redirect_uri=https://school.redink.app/connect/zoom"

              // window.location.href = "https://zoom.us/oauth/authorize?response_type=code&client_id=9fC5627ZR6yIEstpzHHM5w&redirect_uri=https://dev.redink.app/connect/zoom"
            }}
          >
            <span>Proceed to Integration</span>
          </Button>
          <Button
            color="light"
            className="text-muted px-4 py-1"
            style={{ borderRadius: 12, border: 0 }}
            data-dismiss="modal"
            onClick={() => {
              this.setState({
                showIntegrationModal: false,
              })
            }}
          >
            Cancel
          </Button>
          <span></span>
        </div>
      </div>
    </Modal>
  )

  renderCreateScheduleModal = () => (
    <Modal
      isOpen={this.state.createScheduleModalOpen}
      modalClassName="right slide-in-right fade"
    >
      <div
        className="modal-body p-4"
        style={{ backgroundColor: "#F1F7F7", borderRadius: 12 }}
      >
        <h5 className="mb-4 text-brand font-weight-bold">
          {this.state.isUpdate ? "UPDATE" : "CREATE"} SCHEDULE
        </h5>
        {this.state.isShowModalLoading ? (
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: 150 }}
          >
            <HashLoader
              sizeUnit="px"
              size={35}
              color="#084353"
              loading={this.state.isShowModalLoading}
            />
          </div>
        ) : (
          <>
            <div
              className="row align-items-center mx-3 mb-3"
              style={{ justifyContent: "space-evenly" }}
            >
              <Button
                onClick={() => this.setState({ scheduleType: "OFFLINE" })}
                style={{
                  borderRadius: 8,
                  backgroundColor:
                    this.state.scheduleType === "OFFLINE"
                      ? "#52C7B2"
                      : "#E6E8E7",
                  color:
                    this.state.scheduleType === "OFFLINE" ? "#fff" : "#919191",
                  border: 0,
                }}
              >
                <i className="fas fa-map-marker-alt" />
                <span className="ml-2">InClass Lecture</span>
              </Button>
              <Button
                onClick={() => this.setState({ scheduleType: "ONLINE" })}
                style={{
                  borderRadius: 8,
                  backgroundColor:
                    this.state.scheduleType === "ONLINE"
                      ? "#52C7B2"
                      : "#E6E8E7",
                  color:
                    this.state.scheduleType === "ONLINE" ? "#fff" : "#919191",
                  border: 0,
                }}
              >
                <i className="fas fa-video" />
                <span className="ml-2">Online Lecture</span>
              </Button>
            </div>

            <div className="d-flex mb-3">
              <div style={{ flex: 1, marginRight: 12 }}>
                <FormGroup>
                  <Label
                    className="mb-1 text-brand font-weight-bold"
                    htmlFor="class"
                  >
                    Class <span>*</span>
                  </Label>
                  <Select
                    placeholder="Select Class"
                    isClearable
                    value={this.state.selectedClass}
                    options={(this.state.classesList || []).map(c => ({
                      id: c.sectionId,
                      label: `${c.className}${c.sectionName}`,
                      value: `${c.className}${c.sectionName}`,
                    }))}
                    className="basic-multi-select mb-3"
                    classNamePrefix="select"
                    hideSelectedOptions={false}
                    closeMenuOnSelect={true}
                    onChange={selectedOption => {
                      if (selectedOption) {
                        this.changeSubject(selectedOption)
                      } else {
                        this.setState({
                          selectedClass: null,
                          subjectsList: [],
                          selectedSubject: "",
                          selectedStudents: [],
                        })
                      }
                    }}
                  />
                </FormGroup>
              </div>
              <div style={{ flex: 1 }}>
                <FormGroup>
                  <Label
                    className="mb-1 text-brand font-weight-bold"
                    htmlFor="subject"
                  >
                    Subject <span>*</span>
                  </Label>
                  <Select
                    placeholder="Select Subject"
                    isClearable
                    value={this.state.selectedSubject}
                    options={(this.state.subjectsList || []).map(c => ({
                      id: c.subjectId,
                      label: `${c.subjectName}`,
                      value: `${c.subjectName}`,
                    }))}
                    className="basic-multi-select mb-3"
                    classNamePrefix="select"
                    hideSelectedOptions={false}
                    closeMenuOnSelect={true}
                    onChange={selectedOption => {
                      if (selectedOption) {
                        this.setState({
                          selectedSubject: selectedOption,
                          selectedSubjectMasterId:
                            selectedOption.masterSubjectId,
                        })
                      } else {
                        this.setState({
                          selectedSubject: "",
                          selectedSubjectMasterId: null,
                        })
                      }
                    }}
                  />
                </FormGroup>
              </div>
            </div>

            <div className="mb-3">
              <FormGroup>
                <Label
                  className="mb-1 text-brand font-weight-bold"
                  htmlFor="subject"
                >
                  Students <span>*</span>
                </Label>
                <MySelect
                  placeholder="Select Students"
                  isClearable
                  value={this.state.selectedStudents}
                  options={(this.state.studentsList || []).map(c => ({
                    id: c.studentId,
                    label: c.studentName || c.fullName,
                    value: c.studentName || c.fullName,
                  }))}
                  className="basic-multi-select mb-3"
                  classNamePrefix="select"
                  isMulti
                  allowSelectAll={true}
                  components={{
                    Option,
                    MultiValue,
                    ValueContainer,
                    animatedComponents,
                  }}
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  onChange={selectedOption => {
                    this.setState({ selectedStudents: selectedOption })
                  }}
                  styles={this.styleStudent}
                />
              </FormGroup>
              <div className="mb-3">
                <FormGroup>
                  <Label
                    className="mb-1 text-brand font-weight-bold"
                    htmlFor="title"
                  >
                    Title <span>*</span>
                  </Label>
                  <Input
                    type="text"
                    id="title"
                    name="title"
                    placeholder="Enter Title"
                    value={this.state.title}
                    onChange={e => this.setState({ title: e.target.value })}
                  />
                </FormGroup>
              </div>
              <div className="mb-3">
                <FormGroup>
                  <Label
                    className="mb-1 text-brand font-weight-bold"
                    htmlFor="title"
                  >
                    Description
                  </Label>
                  <textarea
                    className="form-control"
                    name="Description"
                    placeholder="Enter Description"
                    rows="3"
                    value={this.state.description}
                    onChange={e =>
                      this.setState({ description: e.target.value })
                    }
                  ></textarea>
                </FormGroup>
              </div>
              <div className="mb-3">
                <FormGroup>
                  <Label
                    className="mb-1 text-brand font-weight-bold"
                    htmlFor=""
                  >
                    Date & Time <span>*</span>
                  </Label>
                  <div style={{ display: "block" }}>
                    {this.state.isUpdate == false ||
                    this.state.selectedSchedule == undefined ||
                    moment() < moment(this.state.selectedSchedule.startTime) ? (
                      <div>
                        <DatePicker
                          placeholder="select date"
                          style={{ width: "150px", display: "inline-flex" }}
                          value={this.state.startTime}
                          format="DD MMM YYYY"
                          size="large"
                          allowClear
                          onChange={e => this.setState({ startTime: e })}
                          disabledDate={current =>
                            current && current < moment().startOf("day")
                          }
                        />
                        <TimePicker
                          placeholder="select time"
                          style={{ width: "123px", display: "inline-flex" }}
                          value={this.state.startTime}
                          format="h:mm a"
                          minuteStep={1}
                          size="large"
                          allowClear
                          onChange={e => this.setState({ startTime: e })}
                          // onChange={this.disabledHours}
                          disabledHours={this.disabledHours}
                          disabledMinutes={this.disabledMinutes}
                        />
                      </div>
                    ) : (
                      <div className="ml-3">
                        {this.state.selectedSchedule &&
                          moment(this.state.selectedSchedule.startTime).format(
                            "ddd, MMM D, YYYY"
                          )}
                        <br />
                        {this.state.selectedSchedule &&
                          moment(this.state.selectedSchedule.startTime).format(
                            "h:mm a"
                          )}{" "}
                        -{" "}
                        {this.state.selectedSchedule &&
                          moment(this.state.selectedSchedule.startTime)
                            .add(this.state.duration, "minutes")
                            .format("h:mm a")}{" "}
                        ({this.state.duration}mins)
                      </div>
                    )}
                  </div>
                </FormGroup>
              </div>

              {/*
                this.state.isUpdate == false ||
                this.state.selectedSchedule == undefined ||
                moment() < moment(this.state.selectedSchedule.startTime && 
              */}
              <div className="mb-3">
                <FormGroup>
                  <Label
                    className="mb-1 text-brand font-weight-bold"
                    htmlFor="duration"
                  >
                    Duration (mins) <span>*</span>
                  </Label>
                  <Input
                    type="number"
                    id="duration"
                    name="duration"
                    value={this.state.duration}
                    style={{ width: 100 }}
                    onChange={e => this.setState({ duration: e.target.value })}
                  />
                </FormGroup>
              </div>

              <div className="mb-3 row">
                <div className="flex-row-reverse p-0">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      tag="a"
                      isOpen={false}
                      onClick={this.toggleDropdown}
                      className="p-0 pr-1 nav-link"
                    >
                      <div className="text-black">
                        <i className="pl-1 ml-3 fa fa-paperclip col-sm-1 text-primary fs-15" />{" "}
                        <u>Add Attachment</u>
                      </div>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={e => {
                          this.setState({
                            isAttachmentModalOpen: true,
                            attachmentType: "youtube",
                          })
                        }}
                      >
                        <i className="pl-1 ml-0 mr-2 fas fa-video fs-15" />{" "}
                        Insert Youtube Video
                      </DropdownItem>
                      <DropdownItem
                        onClick={e => {
                          this.setState({
                            isAttachmentModalOpen: true,
                            attachmentType: "file",
                          })
                        }}
                      >
                        <i className="pl-1 ml-0 mr-2 fas fa-file-alt fs-15" />{" "}
                        Insert File
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                <span className="ml-2 col-sm font-weight-bold fs-12 text-primary"></span>
              </div>
            </div>

            {this.state.attachments.length > 0 && (
              <div className="d-flex mb-4">
                <div
                  className="ml-3"
                  style={{
                    width: "100%",
                  }}
                >
                  {this.state.attachments.length > 0 &&
                    this.state.attachments
                      // .sort((a, b) => a.id < b.id)
                      // .sort((a, b) =>
                      //   a.id.localeCompare(b.id, "en", { numeric: true })
                      // )
                      .map((item, index) => {
                        let attach_name =
                          !this.state.isUpdate && item.attachment.name
                            ? item.attachment.name
                            : item.attachment.name
                            ? item.attachment.name
                            : item.attachment
                        return (
                          <div
                            className="mt-3 px-3 py-2"
                            style={{
                              borderRadius: "10px",
                              border: "solid 1px #eeeeee",
                            }}
                            key={item.id}
                          >
                            <div className="row">
                              <div className="col-6">
                                {item.attachmentType == "youtube" ? (
                                  <div>
                                    <i className="fas fa-video fs-15 text-warning" />{" "}
                                    <span
                                      className="text-brand"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Youtube Video
                                    </span>
                                  </div>
                                ) : attach_name &&
                                  (attach_name.endsWith(".xls") ||
                                    attach_name.endsWith(".xlsx")) ? (
                                  <div>
                                    <i className="fas fa-file-excel fs-15 text-warning" />{" "}
                                    <span
                                      className="text-brand"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Excel
                                    </span>
                                  </div>
                                ) : attach_name &&
                                  attach_name.endsWith(".pdf") ? (
                                  <div>
                                    <img
                                      src={require("../../../assets/images/assignment/PDF.png")}
                                      alt="pdf"
                                      width="20"
                                    />{" "}
                                    <span
                                      className="text-brand"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      PDF
                                    </span>
                                  </div>
                                ) : attach_name &&
                                  attach_name.endsWith(".png") ? (
                                  <div>
                                    <img
                                      src={require("../../../assets/images/assignment/PNG.png")}
                                      alt="png"
                                      width="20"
                                    />{" "}
                                    <span
                                      className="text-brand"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Image
                                    </span>
                                  </div>
                                ) : attach_name &&
                                  (attach_name.endsWith(".jpg") ||
                                    attach_name.endsWith(".jpeg")) ? (
                                  <div>
                                    <img
                                      src={require("../../../assets/images/assignment/JPG.png")}
                                      alt="jpg"
                                      width="20"
                                    />{" "}
                                    <span
                                      className="text-brand"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Image
                                    </span>
                                  </div>
                                ) : attach_name &&
                                  attach_name.endsWith(".mp4") ? (
                                  <div>
                                    <img
                                      src={require("../../../assets/images/assignment/MP4.png")}
                                      alt="video"
                                      width="20"
                                    />{" "}
                                    <span
                                      className="text-brand"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Video
                                    </span>
                                  </div>
                                ) : attach_name &&
                                  (attach_name.endsWith(".doc") ||
                                    attach_name.endsWith(".docx")) ? (
                                  <div>
                                    <img
                                      src={require("../../../assets/images/assignment/DOC.png")}
                                      alt="document"
                                      width="20"
                                    />{" "}
                                    <span
                                      className="text-brand"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Document
                                    </span>
                                  </div>
                                ) : attach_name &&
                                  (attach_name.endsWith(".ppt") ||
                                    attach_name.endsWith(".pptx")) ? (
                                  <div>
                                    <img
                                      src={require("../../../assets/images/assignment/PPT.png")}
                                      alt="ppt"
                                      width="20"
                                    />{" "}
                                    <span
                                      className="text-brand"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      PPT
                                    </span>
                                  </div>
                                ) : (
                                  <div>
                                    <img
                                      src={require("../../../assets/images/file.png")}
                                      alt="File"
                                      width="20"
                                    />{" "}
                                    <span
                                      className="text-brand"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      File
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div className="col-6 text-right">
                                <div
                                  style={{ position: "absolute" }}
                                  className="pl-3 text-muted"
                                >
                                  Visible to Students
                                </div>
                                <div className="custom-control custom-switch">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input ml-5"
                                    id={index}
                                    checked={item.visibility}
                                    onChange={e => {
                                      let attachments = this.state.attachments
                                      const currAttachment = attachments.filter(
                                        item1 => item1.id == item.id
                                      )
                                      let filteredAttachment = attachments.filter(
                                        item1 => item1.id !== item.id
                                      )
                                      const modifiedAttachment = {
                                        id: currAttachment[0].id,
                                        attachmentType:
                                          currAttachment[0].attachmentType,
                                        attachment:
                                          currAttachment[0].attachment,
                                        visibility: e.target.checked,
                                      }
                                      this.setState({
                                        attachments: [
                                          modifiedAttachment,
                                          ...filteredAttachment,
                                        ],
                                      })
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={index}
                                  ></label>
                                </div>
                              </div>
                            </div>
                            <div
                              className="mt-2 px-3 py-2"
                              style={{
                                borderRadius: "10px",
                                border: "solid 1px #eeeeee",
                              }}
                            >
                              {item.attachmentType == "youtube" ? (
                                <div>
                                  <a
                                    href={item.attachment}
                                    target="_blank"
                                    className="text-dark"
                                    rel="noreferrer"
                                  >
                                    {item.attachment.substr(0, 35)}...
                                  </a>
                                </div>
                              ) : (
                                <div>
                                  {item.attachmentType === "youtube"
                                    ? item.attachment.substr(0, 25)
                                    : item.attachmentType === "file" &&
                                      item.attachment.name
                                    ? item.attachment.name.substr(0, 35)
                                    : this.parseFileNameFromURL(
                                        item.attachment
                                      )}
                                </div>
                              )}
                              <div
                                style={{ float: "right", marginTop: "-20px" }}
                              >
                                <i
                                  className="cursor-pointer fas fa-trash text-dark"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Delete attachment"
                                  onClick={() => {
                                    let attachments = this.state.attachments
                                    const filteredAttachment = attachments.filter(
                                      item1 => item1.id !== item.id
                                    )
                                    this.setState({
                                      attachments: filteredAttachment,
                                    })
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )
                      })}
                </div>
              </div>
            )}

            <div className="d-flex align-items-center justify-content-around mt-4">
              <span></span>
              <Button
                className="bg-brand text-light px-4 py-1"
                loading={this.state.isSavingSchedule}
                style={{ borderRadius: 12, border: 0 }}
                disabled={this.state.isSavingSchedule}
                onClick={() => {
                  this.saveSchedule()
                }}
              >
                {this.state.isSavingSchedule ? (
                  <HashLoader
                    sizeUnit={"px"}
                    size={20}
                    color={"#ffffff"}
                    loading={this.state.isSavingSchedule}
                  />
                ) : this.state.scheduleType === "ONLINE" &&
                  !this.state.scheduleId ? (
                  [<span>{this.state.isUpdate ? "UPDATE" : "CREATE"}</span>]
                ) : (
                  <span>{this.state.isUpdate ? "UPDATE" : "CREATE"}</span>
                )}
              </Button>
              <Button
                color="light"
                className="text-muted px-4 py-1"
                style={{ borderRadius: 12, border: 0 }}
                data-dismiss="modal"
                onClick={() => {
                  // this.clearData()
                  this.setState({
                    createScheduleModalOpen: false,
                  })
                }}
              >
                Cancel
              </Button>
              <span></span>
            </div>
          </>
        )}
      </div>
    </Modal>
  )

  renderViewScheduleModal = () => (
    <Modal
      isOpen={this.state.viewScheduleModalOpen}
      modalClassName="right slide-in-right fade"
    >
      <div
        className="modal-body p-4"
        style={{ backgroundColor: "#F1F7F7", borderRadius: 12 }}
      >
        <div className="row mb-3">
          <div className="col-6">
            <h5 className="mb-4 text-brand font-weight-bold">
              {this.state.selectedSubject && this.state.selectedSubject.label}{" "}
              {this.state.selectedClass && this.state.selectedClass.label}
            </h5>
          </div>
          <div className="col-6 text-right">
            {this.state.selectedSchedule != null &&
            moment() >= moment(this.state.selectedSchedule.startTime) &&
            moment() <=
              moment(this.state.selectedSchedule.startTime).add(
                this.state.duration,
                "minutes"
              ) ? null : (
              <Button
                color="light"
                className="btn btn-outline-light text-dark px-2 py-1 mr-2"
                style={{ borderRadius: 12, border: 1 }}
                onClick={() => {
                  this.getSingleScheduleDetails(this.state.selectedSchedule.id)
                  this.setState({
                    createScheduleModalOpen: true,
                    viewScheduleModalOpen: false,
                    isUpdate: true,
                  })
                }}
              >
                <i className="fas fa-edit"></i>
              </Button>
            )}
            <Button
              color="light"
              className="btn btn-outline-light px-2 py-1 text-danger mr-2"
              style={{ borderRadius: 12, border: 0 }}
              onClick={() => {
                this.deleteScheduleDetails(this.state.selectedSchedule.id)
              }}
            >
              <i className="fas fa-trash"></i>
            </Button>
            <Button
              color="light"
              className="btn btn-outline-light px-2 py-1 text-muted"
              style={{ borderRadius: 12, border: 0 }}
              data-dismiss="modal"
              onClick={() => {
                this.setState({
                  viewScheduleModalOpen: false,
                })
              }}
            >
              <i className="fas fa-times"></i>
            </Button>
          </div>
        </div>
        {this.state.isShowModalLoading ? (
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: 150 }}
          >
            <HashLoader
              sizeUnit={"px"}
              size={35}
              color={"#084353"}
              loading={this.state.isShowModalLoading}
            />
          </div>
        ) : (
          <>
            <div className="row mb-3">
              <div className="col-12">
                {this.state.scheduleType == "ONLINE" ? (
                  <div
                    style={{
                      borderRadius: 8,
                      backgroundColor: "rgba(82, 199, 178, 0.2)",
                      padding: "5px 10px",
                      display: "inline-block",
                    }}
                  >
                    <div
                      className="font-weight-bold m-auto"
                      style={{
                        fontWeight: "normal",
                        color: "#08A083",
                        fontSize: "0.8rem",
                      }}
                    >
                      <i className="fas fa-video" />
                      <span className="ml-2">Online Lecture</span>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      borderRadius: 8,
                      backgroundColor: "rgba(255, 175, 192, 0.43)",
                      padding: "5px 10px",
                      display: "inline-block",
                    }}
                  >
                    <div
                      className="font-weight-bold m-auto"
                      style={{
                        fontWeight: "normal",
                        color: "rgb(206, 90, 123)",
                        fontSize: "0.8rem",
                      }}
                    >
                      <i className="fas fa-map-marker-alt" />
                      <span className="ml-2">InClass Lecture</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex mb-3">
              <i className="fas fa-calendar-alt mt-1 text-muted"></i>
              <div className="ml-3">
                {this.state.selectedSchedule &&
                  moment(this.state.selectedSchedule.startTime).format(
                    "ddd, MMM D, YYYY"
                  )}
                <br />
                {this.state.selectedSchedule &&
                  moment(this.state.selectedSchedule.startTime).format(
                    "h:mm a"
                  )}{" "}
                -{" "}
                {this.state.selectedSchedule &&
                  moment(this.state.selectedSchedule.startTime)
                    .add(this.state.duration, "minutes")
                    .format("h:mm a")}{" "}
                ({this.state.duration}mins)
              </div>
            </div>

            <div className="d-flex mb-3">
              <i className="fas fa-dot-circle mt-1 text-muted"></i>
              <div className="ml-3">
                <span className="text-muted">Title</span>
                <br />
                <span
                  className="mt-3"
                  style={{ color: "#006063 !important", fontSize: "1.2rem" }}
                >
                  {this.state.title}
                </span>
              </div>
            </div>

            <div className="d-flex mb-3">
              <i className="fas fa-file-alt mt-1 text-muted"></i>
              <div className="ml-3">
                <span className="text-muted">Description</span>
                <br />
                <span className="mt-3 text-dark" style={{ fontSize: "1rem" }}>
                  {this.state.description}
                </span>
              </div>
            </div>

            {this.state.attachments.length > 0 && (
              <div className="d-flex mb-4">
                <i className="fas fa-paperclip mt-1 text-muted"></i>
                <div
                  className="ml-3"
                  style={{
                    width: "100%",
                  }}
                >
                  <span className="text-muted">Attachments</span>
                  <br />
                  {this.state.attachments.length > 0 &&
                    this.state.attachments.map((item, index) => {
                      return (
                        <div
                          className="mt-3 px-3 py-2"
                          style={{
                            borderRadius: "10px",
                            border: "solid 1px #eeeeee",
                          }}
                          key={item.id}
                        >
                          <div className="row">
                            <div className="col-6">
                              {item.attachmentType === "youtube" ? (
                                <div>
                                  <i className="fas fa-video fs-15 text-warning" />{" "}
                                  <span
                                    className="text-brand"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Youtube Video
                                  </span>
                                </div>
                              ) : this.parseFileNameFromURL(
                                  item.attachment
                                ).endsWith(".xls") ||
                                this.parseFileNameFromURL(
                                  item.attachment
                                ).endsWith(".xlsx") ? (
                                <div>
                                  <i className="fas fa-file-excel fs-15 text-warning" />{" "}
                                  <span
                                    className="text-brand"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Excel
                                  </span>
                                </div>
                              ) : this.parseFileNameFromURL(
                                  item.attachment
                                ).endsWith(".pdf") ? (
                                <div>
                                  <img
                                    src={require("../../../assets/images/assignment/PDF.png")}
                                    alt="pdf"
                                    width="20"
                                  />{" "}
                                  <span
                                    className="text-brand"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    PDF
                                  </span>
                                </div>
                              ) : this.parseFileNameFromURL(
                                  item.attachment
                                ).endsWith(".jpg") ||
                                this.parseFileNameFromURL(
                                  item.attachment
                                ).endsWith(".jpeg") ? (
                                <div>
                                  <img
                                    src={require("../../../assets/images/assignment/JPG.png")}
                                    alt="jpeg"
                                    width="20"
                                  />{" "}
                                  <span
                                    className="text-brand"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Image
                                  </span>
                                </div>
                              ) : this.parseFileNameFromURL(
                                  item.attachment
                                ).endsWith(".png") ? (
                                <div>
                                  <img
                                    src={require("../../../assets/images/assignment/PNG.png")}
                                    alt="png"
                                    width="20"
                                  />{" "}
                                  <span
                                    className="text-brand"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Image
                                  </span>
                                </div>
                              ) : this.parseFileNameFromURL(
                                  item.attachment
                                ).endsWith(".mp4") ? (
                                <div>
                                  <img
                                    src={require("../../../assets/images/assignment/MP4.png")}
                                    alt="video"
                                    width="20"
                                  />{" "}
                                  <span
                                    className="text-brand"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Video
                                  </span>
                                </div>
                              ) : this.parseFileNameFromURL(
                                  item.attachment
                                ).endsWith(".doc") ||
                                this.parseFileNameFromURL(
                                  item.attachment
                                ).endsWith(".docx") ? (
                                <div>
                                  <img
                                    src={require("../../../assets/images/assignment/DOC.png")}
                                    alt="document"
                                    width="20"
                                  />{" "}
                                  <span
                                    className="text-brand"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Document
                                  </span>
                                </div>
                              ) : this.parseFileNameFromURL(
                                  item.attachment
                                ).endsWith(".ppt") ||
                                this.parseFileNameFromURL(
                                  item.attachment
                                ).endsWith(".pptx") ? (
                                <div>
                                  <img
                                    src={require("../../../assets/images/assignment/PPT.png")}
                                    alt="ppt"
                                    width="20"
                                  />{" "}
                                  <span
                                    className="text-brand"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    PPT
                                  </span>
                                </div>
                              ) : (
                                <div>
                                  <img
                                    src={require("../../../assets/images/file.png")}
                                    alt="file"
                                    width="20"
                                  />{" "}
                                  <span
                                    className="text-brand"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    File
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="col-6 text-right">
                              <div
                                style={{ position: "absolute" }}
                                className="pl-3 text-muted"
                              >
                                Visible to Students
                              </div>
                              <div className="custom-control custom-switch">
                                <input
                                  type="checkbox"
                                  className="custom-control-input ml-5"
                                  disabled
                                  // id={index}
                                  defaultChecked={item.visibility}
                                />
                                <label
                                  className="custom-control-label"
                                  // htmlFor={index}
                                ></label>
                              </div>
                            </div>
                          </div>
                          <div
                            className="mt-2 px-3 py-2"
                            style={{
                              borderRadius: "10px",
                              border: "solid 1px #eeeeee",
                            }}
                          >
                            {item.attachmentType === "youtube" ? (
                              <div>
                                <a
                                  href={item.attachment}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-dark"
                                >
                                  {decodeURIComponent(
                                    item.attachment.substr(0, 35)
                                  )}
                                  ...
                                </a>
                              </div>
                            ) : (
                              <div
                                className="cursor-pointer"
                                onClick={() => {
                                  if (this.state.isDownloadingAttachment) return
                                  this.getAttachment(item.attachment)
                                }}
                              >
                                {this.parseFileNameFromURL(item.attachment, 2)}
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
            )}

            {moment() >= moment(this.state.startTime) &&
              moment() <=
                moment(this.state.startTime).add(this.state.duration, "m") &&
              this.state.scheduleType == "ONLINE" &&
              this.state.onlineMeetingProvider == "ZOOM" && (
                <div className="d-flex align-items-center justify-content-around mt-4">
                  <span></span>
                  <Button
                    className="bg-brand text-light px-4 py-1"
                    style={{ borderRadius: 12, border: 0 }}
                    onClick={() => {
                      const onlineMeetingDetails = JSON.parse(
                        this.state.onlineMeetingDetails
                      )
                      if (onlineMeetingDetails.start_url !== undefined) {
                        window.open(onlineMeetingDetails.start_url, "_blank")
                      }
                    }}
                  >
                    Start
                  </Button>
                  <span></span>
                </div>
              )}

            <div className="d-flex align-items-center justify-content-around mt-4"></div>
          </>
        )}
      </div>
    </Modal>
  )

  attachementURLModal = () => (
    <Modal
      isOpen={this.state.isAttachmentModalOpen}
      backdropClassName="modal-backdrop-show"
      modalClassName="modal-dialog-shadow-none"
      contentClassName="border-14px"
      centered
    >
      <div className="modal-body p-0">
        <div
          className="bg-brand text-light d-flex"
          style={{
            padding: "0.5rem 2rem",
            borderTopRightRadius: "14px",
            borderTopLeftRadius: "14px",
          }}
        >
          <div>
            Insert{" "}
            {this.state.attachmentType === "youtube" ? "Youtube URL" : "File"}
          </div>
        </div>
        <div className="form-group px-2 col-sm-8">
          <div className="w-100">
            {this.state.attachmentType === "youtube" && (
              <FormGroup>
                <Label
                  className="mb-1 text-brand font-weight-bold p-3"
                  htmlFor=""
                >
                  URL
                  <i
                    className="ml-2 fas fa-question-circle text-muted"
                    id="attachementTooltip"
                  />
                </Label>
                <UncontrolledTooltip
                  placement="top"
                  target="attachementTooltip"
                >
                  Enter Youtube video URL
                </UncontrolledTooltip>
                <Input
                  type="text"
                  id="attachmentURL"
                  name="attachmentURL"
                  placeholder="Enter URL"
                  value={this.state.youtubeURL}
                  className="ml-3"
                  onChange={e => {
                    this.setState({ youtubeURL: e.target.value })
                  }}
                />
              </FormGroup>
            )}
            {this.state.attachmentType === "file" && (
              <label className="mt-3 ml-3">
                <input
                  multiple
                  type="file"
                  accept=".pdf,.gif,.jpg,.jpeg,.png,.doc,.docx,.xlsx,.xls,.ppt,.pptx"
                  onChange={this.selectFile}
                />
              </label>
            )}
          </div>
        </div>
        <div className="text-right">
          <span></span>
          <Button
            className="bg-green text-light py-1 mb-3 ml-4"
            loading={this.state.isSavingSchedule}
            style={{ borderRadius: 12, border: 0 }}
            onClick={this.onInsertAttachments}
          >
            <span>Insert</span>
          </Button>
          <Button
            className="bg-light text-muted py-1 mb-3 ml-4 mr-4"
            loading={this.state.isSavingSchedule}
            style={{ borderRadius: 12, border: 0 }}
            onClick={() => {
              this.setState({
                isAttachmentModalOpen: false,
              })
            }}
          >
            <span>Cancel</span>
          </Button>
        </div>
      </div>
    </Modal>
  )

  render() {
    return (
      <div className="schedule">
        {this.renderCreateScheduleModal()}
        {this.renderViewScheduleModal()}
        {this.attachementURLModal()}
        {this.renderIntegrationModal()}
        <AppWrapper
          teacherDashboard
          showTeacherThree
          showTeacherLogo
          screenName="Schedule"
        >
          <div className="row">
            <div className="col-md-6 d-flex align-items-center justify-content-start"></div>
            <div className="col-md-6 d-flex align-items-start justify-content-end pb-2">
              {this.state.dataLoading ? (
                <Skeleton height={30} width={138} />
              ) : (
                <Button
                  onClick={() => {
                    this.setState({
                      createScheduleModalOpen: true,
                      isUpdate: false,
                    })
                    this.clearData()
                    this.checkIntegration()
                    this.preselection()
                  }}
                  className="bg-brand text-light border-0"
                  style={{ borderRadius: 8 }}
                >
                  Create Schedule
                </Button>
              )}
            </div>
          </div>

          <FullCalendar
            events={this.state.events}
            updateEvents={this.updateEvents}
            forUpdatingEnts={this.state.forUpdatingEnts}
            getSingleScheduleDetails={this.getSingleScheduleDetails}
          />
        </AppWrapper>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      showNotification,
      getTeacherAssignedDetails,
      getClassStudents,
      getTeacherSchedule,
      uploadFile,
      saveSchedule,
      getSingleScheduleDetails,
      deleteScheduleDetails,
      checkIntegration,
      getFile,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(ScheduleScreen)
