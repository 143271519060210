import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { UncontrolledTooltip, Modal } from "reactstrap"
import {
  getAssignmentList,
  getAssignmentOverview,
  getAssignmentYearlyGraph,
  getAssignmentDetailTeacherAttachment,
  getAssignmentDetailStudentAttachment,
  saveStudentSubmitAssignmentDetails,
  saveStudentUnSubmitAssignmentDetails,
  saveCurrentAssignmentDetailsInStore,
  saveAssignmentToggleModalDetailsInStore,
  updateViewedAssignment,
} from "../../../actions/parent/AssignmentAction"
import AppWrapper from "../Wrapper"
import Skeleton from "react-loading-skeleton"
import moment from "moment"
import Chart from "chart.js"
import { HashLoader } from "react-spinners"
import { DANGER_TOAST, VALIDATION_MSG } from "../../../utils/Constants"
import { parseNameFromLocation } from "../../../utils/Utility" // myLog,
import { showNotification } from '../../../actions/CommonAction'

let myexGraph = null
let myspGraph = null

class StudentAssignmentScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDownloadingTeacherFile: false,
      isDownloadingStudentFile: false,
      pageLoading: false,
      uploadingFile: false,
      workFile: "",
      attachment: "",
      assignments: null,
      page: 1,
      limit: 50,
      isPrevPage: null,
      isNextPage: null,
      selectedSubject: "ALL",
      selectedStatus: "ALL",
      selectedTask: "ALL",
      overviewDatas: [],
      overviewPercentage: "",
      overviewCount: "",
      statusList: ["TODO", "OVERDUE", "INREVIEW", "COMPLETED", "MISSED"],
      taskList: ["PROJECT", "HOMEWORK", "ASSIGNMENT"]
    }
  }

  toggleAssignmentDetailModal = () => {
    this.props.saveAssignmentToggleModalDetailsInStore(!this.props.toggleModal)
  }

  componentDidMount() {
    const { state } = this.props.location
    if (state && state.assignment) {
      this.setCurrentAssignment(state.assignment)
    }
    this.getAllAssignment()
  }

  getAllAssignment = () => {
    const initialTime = !this.state.assignments
    this.setState({ isShowLoading: initialTime }, () => {
      const { selectedSubject, selectedStatus, selectedTask } = this.state
      const query = {
        page: this.state.page,
        limit: this.state.limit,
        subject: selectedSubject !== "ALL" ? selectedSubject : undefined,
        submitStatus: selectedStatus !== "ALL" ? selectedStatus : undefined,
        typeOfWork: selectedTask !== "ALL" ? selectedTask : undefined,
      }
      this.props.getAssignmentList(query, (success, response) => {
        /* canvas needs to be displayed first */
        this.setState({ isShowLoading: false })
        if (initialTime) {
          this.getAssignmentOverview()
          this.setMonthlyRatio()
        }

        if (success && response.data) {
          const assignments = response.data
          const { nextPage } = response.pagination
          this.setState({ isNextPage: nextPage })
          this.setState({ isPrevPage: this.state.page > 1 })
          this.setState({ assignments })
          this.setFilter(assignments, initialTime)
        }
        this.setState({ pageLoading: false })
      })
    })
  }

  getAnalyticCountPercentage = () => {
    const { overviewDatas, selectedStatus } = this.state
    const totalCnt = overviewDatas.length
    const selectedCnt = overviewDatas.reduce((acc, e) => {
      return (
        acc +
        !!(
          (selectedStatus === "ALL" && e.submitStatus === "COMPLETED") ||
          (selectedStatus !== "ALL" && e.submitStatus === selectedStatus)
        )
      )
    }, 0)
    this.setState({
      overviewPercentage: Math.round((selectedCnt / totalCnt) * 1000) / 10,
      overviewCount: selectedCnt,
    })
  }

  getAssignmentOverview = () => {
    // const initialTime = !this.state.assignments
    let query = {}
    this.props.getAssignmentOverview(query, (success, response) => {
      if (!success || !response.data) return

      this.setState({ overviewDatas: response.data }, () => {
        this.renderStatsGraph()
        this.getAnalyticCountPercentage()
      })
    })
  }

  setMonthlyRatio = () => {
    let query = {}
    if (this.state.selectedSubject !== "ALL")
      query.subjectId = this.state.selectedSubject
    this.props.getAssignmentYearlyGraph(query, (success, response) => {
      if (!success || !response.data) return

      const { academicYear, monthlyData } = response.data
      const { academicFrom, academicTo } = academicYear
      const month = []
      const completed = []
      const notCompleted = []
      /* canvas needs to be displayed first */
      const startMonth = Number(moment(academicFrom).format("M"))
      let endMonth = Number(moment(academicTo).format("M"))
      if (startMonth >= endMonth && moment(academicFrom) <= moment(academicTo))
        endMonth += 11
      for (let i = startMonth; i <= endMonth; i++) {
        const m = ((i - 1) % 12) + 1
        const { completeCnt, incompleteCnt } = monthlyData[m] || {
          completeCnt: 0,
          incompleteCnt: 0,
        }
        month.push(moment.monthsShort(m - 1))
        completed.push(completeCnt)
        notCompleted.push(incompleteCnt)
      }
      this.setState(
        {
          month,
          completed,
          notCompleted,
        },
        () => {
          this.showExamPerformanceGraph()
        }
      )
    })
  }

  setFilter = (assignments, initialTime) => {
    let uniqueSubjectArray = []
    let uniqueStatusArray = new Set()
    let uniqueTaskArray = new Set()

    assignments.forEach(response => {
      uniqueStatusArray.add(response.submitStatus)
      uniqueTaskArray.add(response.assignmentDetail.typeOfWork)
      if (
        !uniqueSubjectArray.find(
          item => item.subjectId === response.subject.subjectId
        )
      ) {
        uniqueSubjectArray.push(response.subject)
      }
    })

    if (initialTime) {
      this.setState({
        subjectList: uniqueSubjectArray,
        // statusList: [...uniqueStatusArray],
        // taskList: [...uniqueTaskArray],
      })
    }
  }

  clearData = () => {
    this.setState({})
  }

  getTeacherAttachment = () => {
    this.setState({ isDownloadingTeacherFile: true })
    this.props.getAssignmentDetailTeacherAttachment(
      this.props.currentRecord.id,
      (success, response) => {
        if (success) {
          const { url, name: fileName } = response
          const xhr = new XMLHttpRequest()
          xhr.onload = () => {
            const file = new Blob([xhr.response])
            const browserUrl = window.webkitURL || window.URL
            const fileUrl = browserUrl.createObjectURL(file)
            const a = document.createElement("a")
            a.href = fileUrl
            a.download = fileName
            a.click()
            browserUrl.revokeObjectURL(fileUrl)
            this.setState({ isDownloadingTeacherFile: false })
          }
          xhr.open("GET", url, true)
          xhr.responseType = "blob"
          xhr.send(null)
        }
      }
    )
  }

  statsGraphRef = null

  renderStatsGraph = () => {
    Chart.defaults.RoundedDoughnut = Chart.helpers.clone(
      Chart.defaults.doughnut
    )
    Chart.controllers.RoundedDoughnut = Chart.controllers.doughnut.extend({
      draw: function (ease) {
        var ctx = this.chart.ctx
        var easingDecimal = ease || 1
        var arcs = this.getMeta().data
        Chart.helpers.each(arcs, function (arc, i) {
          arc.transition(easingDecimal).draw()

          var pArc = arcs[i === 0 ? arcs.length - 1 : i - 1]
          var pColor = pArc._view.backgroundColor

          var vm = arc._view
          var radius = (vm.outerRadius + vm.innerRadius) / 2
          var thickness = (vm.outerRadius - vm.innerRadius) / 2
          var startAngle = Math.PI - vm.startAngle - Math.PI / 2
          var angle = Math.PI - vm.endAngle - Math.PI / 2

          ctx.save()
          ctx.translate(vm.x, vm.y)

          ctx.fillStyle = i === 0 ? vm.backgroundColor : pColor
          ctx.beginPath()
          ctx.arc(
            radius * Math.sin(startAngle),
            radius * Math.cos(startAngle),
            thickness,
            0,
            2 * Math.PI
          )
          ctx.fill()

          ctx.fillStyle = vm.backgroundColor
          ctx.beginPath()
          ctx.arc(
            radius * Math.sin(angle),
            radius * Math.cos(angle),
            thickness,
            0,
            2 * Math.PI
          )
          ctx.fill()

          ctx.restore()
        })
      },
    })
    const statsGraphRef = this.statsGraphRef
    if (!statsGraphRef) return
    if (myspGraph != null) {
      myspGraph.destroy()
    }

    const { overviewDatas } = this.state
    if (!overviewDatas) return

    const getPercentage = status => {
      const totalCnt = overviewDatas.length
      const selectedCnt = overviewDatas.filter(e => e.submitStatus === status)
        .length
      return Math.round((selectedCnt / totalCnt) * 1000) / 10
    }

    const todoRate = getPercentage("TODO")
    const overdueRate = getPercentage("OVERDUE")
    const inreviewRate = getPercentage("INREVIEW")
    const completedRate = getPercentage("COMPLETED")
    const missedRate = getPercentage("MISSED")
    const dataSource = [
      {
        label: "TODO",
        data: todoRate,
        color: "#094353",
      },
      {
        label: "OVERDUE",
        data: overdueRate,
        color: "#ef7069",
      },
      {
        label: "IN REVIEW",
        data: inreviewRate,
        color: "#e0ad53",
      },
      {
        label: "MISSED",
        data: missedRate,
        color: "#f76387",
      },
      {
        label: "COMPLETED",
        data: completedRate,
        color: "#06b191",
      },
    ]
    const label = []
    const data = []
    const backgroundColor = []
    dataSource.forEach(el => {
      if (el.data > 0) {
        label.push(el.label)
        data.push(el.data)
        backgroundColor.push(el.color)
      }
    })

    myspGraph = new Chart(statsGraphRef, {
      type: "RoundedDoughnut",
      data: {
        labels: label,
        datasets: [
          {
            label: "Count",
            data: data,
            backgroundColor: backgroundColor,
            fill: false,
            borderWidth: 0,
          },
        ],
      },
      options: {
        cutoutPercentage: 85,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            title: function (tooltipItem, data) {
              return data["labels"][tooltipItem[0]["index"]]
            },
            label: function (tooltipItem, data) {
              var dataset = data["datasets"][0]
              var percent = dataset.data[tooltipItem.index]
              return percent + "%"
            },
          },
          xAlign: "center",
        },
      },
    })
  }

  getStudentAttachment = () => {
    // this.setState({ isDownloadingStudentFile: true })
    this.props.getAssignmentDetailStudentAttachment(
      this.props.currentRecord.id,
      (success, response) => {
        if (success) {
          const { url, name } = response
          const xhr = new XMLHttpRequest()
          xhr.onload = () => {
            const file = new Blob([xhr.response])
            const browserUrl = window.webkitURL || window.URL
            const fileUrl = browserUrl.createObjectURL(file)
            const a = document.createElement("a")
            a.href = fileUrl
            a.download = name
            a.click()
            browserUrl.revokeObjectURL(fileUrl)
            this.setState({ isDownloadingStudentFile: false })
          }
          xhr.open("GET", url, true)
          xhr.responseType = "blob"
          xhr.send(null)
        }

      }
    )
  }

  saveSubmitAssignment = id => {
    this.setState({ isDownloadingStudentFile: true, isShowSaveLoading: true }, () => {
      let formdata = new FormData()
      if (this.props.workFile) {
        formdata.append("file", this.props.workFile)
        this.setState({ uploadingFile: true })
      }
      this.props.saveStudentSubmitAssignmentDetails(
        id,
        formdata,
        (success, response) => {
          if (success && response.data) {
            const { workFile } = response.data
            this.setState({ workFile: "" })
            // let currentdata = this.props.currentRecord;
            // currentdata.workFile = ""
            // currentdata.prevWorkFile = ""
            // this.props.saveCurrentAssignmentDetailsInStore(currentdata)
            this.setState(state => ({
              assignments: state.assignments.map(e =>
                e.id === id ? response.data : e
              ),
              currentRecord: {
                ...this.props.currentRecord,
                ...response.data,
              },
            }))
            this.getAssignmentOverview()

            if (workFile) this.setState({ workFile })
            this.toggleAssignmentDetailModal()
          }
          this.setState({ isShowSaveLoading: false, uploadingFile: false })
        }
      )
    })
  }

  saveUnSubmitAssignment = id => {
    this.setState({ isDownloadingStudentFile: false, isShowSaveLoading: true }, () => {
      this.props.saveStudentUnSubmitAssignmentDetails(
        id,
        (success, response) => {
          if (success && response.data) {
            this.setState({ workFile: "" })
            let currentdata = this.props.currentRecord;
            currentdata.workFile = ""
            currentdata.prevWorkFile = ""
            this.props.saveCurrentAssignmentDetailsInStore(currentdata)
            this.setState(state => ({
              assignments: state.assignments.map(e =>
                e.id === id ? response.data : e
              ),
              currentRecord: {
                ...this.props.currentRecord,
                ...response.data,
              },
            }))
            this.getAssignmentOverview()
            this.toggleAssignmentDetailModal()
          }
          this.setState({ isShowSaveLoading: false })
        }
      )
    })
  }

  showExamPerformanceGraph = () => {
    const examPerformanceGraph = this.examPerformanceGraph
    if (!examPerformanceGraph) return

    if (myexGraph != null) {
      myexGraph.destroy()
    }
    myexGraph = new Chart(examPerformanceGraph, {
      type: "bar",
      data: {
        labels: this.state.month || [],
        datasets: [
          {
            label: "Completed",
            data: this.state.completed || [],
            backgroundColor: "#094353",
            borderRadius: 150,
            borderWidth: 2,
            barThickness: 12,
            maxBarThickness: 12,
          },
          {
            label: "Not Completed",
            data: this.state.notCompleted || [],
            backgroundColor: "rgba(9,67,83, 0.33)",
            borderRadius: 150,
            borderWidth: 2,
            barThickness: 12,
            maxBarThickness: 12,
          },
        ],
      },
      options: {
        responsive: true,
        legend: {
          display: false,
          position: "bottom",
          labels: {
            usePointStyle: true,
            fontSize: 10,
            fontColor: "white",
          },
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              let dataset0 = data["datasets"][0]
              let dataset1 = data["datasets"][1]
              let completed = dataset0.data[tooltipItem.index]
              let notCompeted = dataset1.data[tooltipItem.index]
              let total = completed + notCompeted
              // const { yLabel } = tooltipItem
              let percentage = Math.round((completed / total) * 1000) / 10
              return `${completed}/${total} (${percentage}%)`
            },
            labelColor: function (tooltipItem, chart) {
              return {
                backgroundColor: "#094353",
              }
            },
          },
        },
        scales: {
          xAxes: [
            {
              barPercentage: 0.1,
              ticks: {
                fontColor: "#084353",
              },
              gridLines: {
                display: false,
              },
              stacked: true,
            },
          ],
          yAxes: [
            {
              ticks: {
                display: false,
              },
              gridLines: {
                display: false,
              },
              stacked: true,
            },
          ],
        },
      },
    })
  }

  findBgColor = status => {
    if (status === "TODO") {
      return "#094353"
    } else if (status === "COMPLETED") {
      return "#06b191"
    } else if (status === "OVERDUE") {
      return "#ef7069"
    } else if (status === "INREVIEW") {
      return "#e0ad53"
    } else if (status === "MISSED") {
      return "#F76387"
    } else if (status === "TODO") {
      return "#094353"
    }
  }

  findColor = typeOfWork => {
    let color = ""
    if (typeOfWork === "ASSIGNMENT") {
      color = "#86CAE2"
    } else if (typeOfWork === "TEST") {
      color = "#E5C17A"
    } else if (typeOfWork === "HOMEWORK") {
      color = "#79C9B0"
    } else if (typeOfWork === "PROJECT") {
      color = "#919DC6"
    }
    return color
  }

  renderStatusIcon = (status, size = 24) => {
    return status === "TODO" ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-checkbox"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="#ffffff"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <polyline points="9 11 12 14 20 6" />
        <path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9" />
      </svg>
    ) : status === "COMPLETED" ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-thumb-up"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="#ffffff"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M7 11v8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3" />
      </svg>
    ) : status === "OVERDUE" ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-alert-triangle"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="#ffffff"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M12 9v2m0 4v.01" />
        <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" />
      </svg>
    ) : status === "INREVIEW" ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-eye"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="#ffffff"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <circle cx="12" cy="12" r="2" />
        <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
      </svg>
    ) : status === "MISSED" ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-thumb-down"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="#ffffff"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M7 13v-8a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v7a1 1 0 0 0 1 1h3a4 4 0 0 1 4 4v1a2 2 0 0 0 4 0v-5h3a2 2 0 0 0 2 -2l-1 -5a2 3 0 0 0 -2 -2h-7a3 3 0 0 0 -3 3" />
      </svg>
    ) : null
  }

  setCurrentAssignment = item => {
    this.setState({
      currentRecordId: item.id,
      currentRecord: item,
      workFile: item.workFile || item.prevWorkFile || '',
      attachment: "",
    })
    if (item.assignmentDetail.attachment) {
      this.props.getAssignmentDetailTeacherAttachment(
        item.id,
        (success, response) => {
          if (success) {
            const { url, fileName } = response
            this.setState({
              attachment: {
                serverUrl: item.assignmentDetail.attachment,
                url,
                fileName,
              },
            })
          }
        }
      )
    }

  }

  onUpload = e => {
    const file = e.target.files[0]
    if (file.size > 50 * 1000 * 1000) {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.OOPS,
        "File is too big (limit: 50MB)"
      )
      return
    }
    if (file.name.endsWith(".pdf") || file.name.endsWith(".gif") || file.name.endsWith(".jpg") || file.name.endsWith(".jpeg") || file.name.endsWith(".png") || file.name.endsWith(".doc") || file.name.endsWith(".docx") || file.name.endsWith(".ppt") || file.name.endsWith(".pptx") || file.name.endsWith(".mp4") || file.name.endsWith(".mov") || file.name.endsWith(".wmv") || file.name.endsWith(".avi")) {
      this.setState({ workFile: file })
      let currentdata = this.props.currentRecord;
      currentdata.workFile = file
      this.props.saveCurrentAssignmentDetailsInStore(currentdata)
    } else {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.OOPS,
        "Attach a pdf/image/doc/video file..."
      )
    }
  }

  renderCurrentAssignmentModal = () => {
    const currentRecord = this.props.currentRecord || {
      assignmentDetail: {},
      subject: {},
    }
    const status = currentRecord.submitStatus

    return (
      <Modal
        isOpen={this.props.toggleModal}
        toggle={this.toggleAssignmentDetailModal}
        modalClassName="right slide-in-right fade mt-0"
        contentClassName="scrollbar"
      >
        {Object.keys(currentRecord).length > 0 &&
          currentRecord.constructor === Object &&
          currentRecord && (
            <>
              <div className="modal-header">
                <div className="row ml-1">
                  <span className="align-middle mr-2 text-dark-green font-weight-bold fs-15">
                    {currentRecord.subject.subjectName}
                  </span>
                  <span
                    className="br-10 text-center px-2"
                    style={{
                      backgroundColor: this.findColor(
                        currentRecord.assignmentDetail.typeOfWork
                      ),
                    }}
                  >
                    {currentRecord.assignmentDetail.typeOfWork}
                  </span>
                </div>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                  onClick={() => {
                    this.setState({ workFile: "" })
                    // let currentdata = this.props.currentRecord;
                    // currentdata.workFile = ""
                    // currentdata.prevWorkFile = ""
                    // this.props.saveCurrentAssignmentDetailsInStore(currentdata)
                    this.toggleAssignmentDetailModal()
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <span
                    className="col-6 text-dark-green font-weight-bold fs-15"
                    style={{ wordBreak: "break-all", lineBreak: "anywhere" }}
                  >
                    {currentRecord.assignmentDetail.title}
                  </span>
                  <div className="col-6 d-flex align-items-center justify-content-end text-dark-green font-weight-light fs-15">
                    <div className="mr-2">Status</div>
                    <div
                      className="d-flex border-theme rounded fs-12 text-center"
                      style={{
                        borderColor: this.findBgColor(status),
                      }}
                    >
                      <div
                        className="px-1"
                        style={{
                          fontSize: "1rem",
                          backgroundColor: this.findBgColor(status.replace(" ", "")),
                        }}
                      >
                        {this.renderStatusIcon(status.replace(" ", ""))}
                      </div>
                      <div className="ml-2 mr-2 text-center pt-1">{status.replace(" ", "")}</div>
                    </div>
                  </div>
                </div>

                <div className="row mb-3 mt-2">
                  <span className="col-6 text-dark-green font-weight-light fs-12">
                    {currentRecord.submittedOn &&
                      `Assigned on ${moment(currentRecord.submittedOn).format(
                        "DD MMM YYYY"
                      )}`}
                  </span>
                  <span className="col-6 text-right text-dark-green  fs-12 ">
                    Due on{" "}
                    {moment.utc(moment(currentRecord.durationTo)).utcOffset(330).format("DD MMM YYYY")}
                  </span>
                </div>

                {currentRecord.onTimeSubmission === "LATE" ? (
                  <div className="my-2 text-danger">Late Submission</div>
                ) :
                  currentRecord.onTimeSubmission === "ONTIME" ? (
                    <div className="my-2 text-green">On time Submission</div>
                  ) : currentRecord.approvalStatus === "REWORK" ? (
                    <div className="my-2 text-danger">Rework</div>
                  ) : null}

                <div className="row">
                  <span className="col-3 font-weight-light fs-12">
                    <i className="fas fa-bars col-sm-1 ml-0 pl-0 text-dark " />
                    Description
                  </span>
                </div>

                <div className="row mb-5 ml-1 mt-2">
                  <span className="text-dark-green">
                    {currentRecord.assignmentDetail.description}
                  </span>
                </div>

                {currentRecord.assignmentDetail.attachment &&
                  <div className="d-flex align-items-center font-weight-bold text-dark-green mx-2 mb-2">
                    <i className="fas fa-paperclip text-dark mr-2" />
                    <span>Attachment from Teacher</span>
                  </div>
                }

                <div className="d-flex flex-column justify-content-start mb-4">
                  {currentRecord.assignmentDetail.attachment &&
                    <div
                      className="m-2 mt-0 d-flex bg-theme-verylight rounded-lg align-items-center cursor-pointer"
                      onClick={() => {
                        if (this.state.isDownloadingTeacherFile) return
                        this.getTeacherAttachment()
                      }}
                    >
                      <div className="bg-theme-light rounded p-3">
                        {parseNameFromLocation(currentRecord.assignmentDetail.attachment).endsWith('.jpg') ||
                          parseNameFromLocation(currentRecord.assignmentDetail.attachment).endsWith('.jpeg') ?
                          <img
                            src={require("../../../assets/images/assignment/JPG.png")}
                            alt="pdf"
                            width="30"
                          /> :
                          parseNameFromLocation(currentRecord.assignmentDetail.attachment).endsWith('.png') ?
                            <img
                              src={require("../../../assets/images/assignment/PNG.png")}
                              alt="pdf"
                              width="30"
                            />
                            :
                            parseNameFromLocation(currentRecord.assignmentDetail.attachment).endsWith('.pdf') ?
                              <img
                                src={require("../../../assets/images/assignment/PDF.png")}
                                alt="pdf"
                                width="30"
                              />
                              :
                              parseNameFromLocation(currentRecord.assignmentDetail.attachment).endsWith('.doc') || parseNameFromLocation(currentRecord.assignmentDetail.attachment).endsWith('.docx') ?
                                <img
                                  src={require("../../../assets/images/assignment/DOC.png")}
                                  alt="pdf"
                                  width="30"
                                />
                                :
                                parseNameFromLocation(currentRecord.assignmentDetail.attachment).endsWith('.ppt') || parseNameFromLocation(currentRecord.assignmentDetail.attachment).endsWith('.pptx') ?
                                  <img
                                    src={require("../../../assets/images/assignment/PPT.png")}
                                    alt="pdf"
                                    width="30"
                                  />
                                  :
                                  <img
                                    src={require("../../../assets/images/assignment/MP4.png")}
                                    alt="pdf"
                                    width="30"
                                  />
                        }
                      </div>
                      <div className="ml-3" style={{ wordBreak: 'break-word' }}>
                        {this.state.isDownloadingTeacherFile
                          ? "Loading..."
                          : parseNameFromLocation(
                            currentRecord.assignmentDetail.attachment
                          )}
                      </div>
                    </div>
                  }
                </div>

                {status !== "COMPLETED" && currentRecord &&
                  currentRecord.assignmentDetail.fileSubmission === 1 && (
                    <div className="row mb-2">
                      <span className="col-sm ml-2 font-weight-bold text-dark-green">
                        Submit your work
                      </span>
                    </div>
                  )}

                {currentRecord &&
                  currentRecord.assignmentDetail.fileSubmission === 1 &&
                  this.props.workFile === "" &&
                  <div className="row mb-2">
                    <span className="col-sm ml-2 font-weight-bold fs-12 text-primary">
                      <label>
                        <input
                          type="file"
                          accept=".pdf,.gif,.jpg,.jpeg,.png,.doc,.docx,.ppt,.pptx,.mp4,.mov,.wmv,.avi"
                          style={{ display: "none" }}
                          onChange={this.onUpload}
                        />
                        <u>+ Add Attachment</u>
                        <i className="fa fa-paperclip pl-1 col-sm-1 ml-0 pl-0 text-primary fs-15" />
                      </label>
                    </span>
                  </div>
                }

                {this.props.workFile !== "" &&
                  <div>
                    <div className="row mb-2">
                      <span className="col-sm ml-2 font-weight-bold text-dark-green">
                        Your work
                      </span>
                    </div>
                    <div className="m-2 mt-0 d-flex bg-theme-verylight rounded-lg align-items-center cursor-pointer"
                    >
                      <div className="bg-theme-light rounded p-3"
                        onClick={() => {
                          if (!this.state.isDownloadingStudentFile && typeof (this.props.workFile) !== 'object') {
                            this.getStudentAttachment()
                          }
                        }}
                      >
                        {parseNameFromLocation(typeof (this.props.workFile) === 'object' ? this.props.workFile.name : this.props.workFile).endsWith('.pdf') ?
                          <img
                            src={require("../../../assets/images/assignment/PDF.png")}
                            alt="pdf"
                            width="30"
                          />
                          :
                          parseNameFromLocation(this.props.workFile.name).endsWith('.png') ?
                            <img
                              src={require("../../../assets/images/assignment/PNG.png")}
                              alt="pdf"
                              width="30"
                            />
                            :
                            parseNameFromLocation(typeof (this.props.workFile) === 'object' ? this.props.workFile.name : this.props.workFile).endsWith('.jpg') || parseNameFromLocation(typeof (this.props.workFile) === 'object' ? this.props.workFile.name : this.props.workFile).endsWith('.jpeg') ?
                              <img
                                src={require("../../../assets/images/assignment/JPG.png")}
                                alt="pdf"
                                width="30"
                              />
                              :
                              parseNameFromLocation(typeof (this.props.workFile) === 'object' ? this.props.workFile.name : this.props.workFile).endsWith('.doc') || parseNameFromLocation(typeof (this.props.workFile) === 'object' ? this.props.workFile.name : this.props.workFile).endsWith('.docx') ?
                                <img
                                  src={require("../../../assets/images/assignment/DOC.png")}
                                  alt="pdf"
                                  width="30"
                                /> :
                                parseNameFromLocation(typeof (this.props.workFile) === 'object' ? this.props.workFile.name : this.props.workFile).endsWith('.ppt') || parseNameFromLocation(typeof (this.props.workFile) === 'object' ? this.props.workFile.name : this.props.workFile).endsWith('.pptx') ?
                                  <img
                                    src={require("../../../assets/images/assignment/PPT.png")}
                                    alt="pdf"
                                    width="30"
                                  /> :
                                  <img
                                    src={require("../../../assets/images/assignment/MP4.png")}
                                    alt="pdf"
                                    width="30"
                                  />}
                      </div>

                      <div className="ml-3"
                        onClick={() => {
                          if (!this.state.isDownloadingStudentFile && typeof (this.props.workFile) !== 'object') {
                            this.getStudentAttachment()
                          }
                        }}
                      >
                        {
                          this.state.isDownloadingStudentFile
                            ? parseNameFromLocation(
                              typeof this.props.workFile === "string"
                                ? parseNameFromLocation(this.props.workFile)
                                : this.props.workFile.name
                            ) : parseNameFromLocation(
                              typeof this.props.workFile === "string"
                                ? parseNameFromLocation(this.props.workFile)
                                : this.props.workFile.name
                            )}
                      </div>
                      {currentRecord && !currentRecord.isSubmitted && (
                        <div className="ml-auto mr-3 p-2">
                          <i
                            className="far fa-times fa-clock-hover cursor-pointer"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Remove file"
                            onClick={() => {
                              this.setState({ workFile: "" })
                              let currentdata = this.props.currentRecord;
                              currentdata.workFile = ""
                              currentdata.prevWorkFile = ""
                              this.props.saveCurrentAssignmentDetailsInStore(currentdata)
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                }

                {currentRecord.approvalStatus !== "AWAITING" && (
                  <div className="mt-2">
                    <div className="row">
                      <div className="col-sm-4 mt-3">
                        <div
                          className="ml-2 bg-lightblue p-3"
                          style={{ borderRadius: 10, overflow: "hidden" }}
                        >
                          <div className="row ml-2 text-dark">
                            <b>Marks</b>
                            <div className="ml-2">
                              {currentRecord.mark}/
                              {currentRecord &&
                                currentRecord.assignmentDetail.maxMark}{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-8 mt-2">
                        <div
                          className="ml-2 bg-lightblue p-3"
                          style={{ borderRadius: 10, overflow: "hidden" }}
                        >
                          <div className="row ml-2 text-dark">
                            <b>Remarks</b>
                            <div className="ml-2">{currentRecord.remark} </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {currentRecord &&
                  !(
                    currentRecord.approvalStatus === "APPROVED" ||
                    currentRecord.assignmentDetail.isMarkCompleted
                  ) && (
                    <div className="row mt-4">
                      <span className="col-4"></span>
                      {currentRecord && currentRecord.isSubmitted === 0 ? (
                        <button
                          className={`text-center br-10 p-1 px-2 text-light ${this.props.workFile === "" &&
                            currentRecord.assignmentDetail.fileSubmission === 1
                            ? "bg-lightgray"
                            : currentRecord &&
                              currentRecord.approvalStatus === "REWORK"
                              ? "bg-absent"
                              : " bg-present"
                            }`}
                          disabled={
                            this.props.workFile === "" &&
                            currentRecord.assignmentDetail.fileSubmission === 1
                          }
                          onClick={() => {
                            this.saveSubmitAssignment(currentRecord.id)
                          }}
                        >
                          {this.state.isShowSaveLoading ? (
                            <HashLoader
                              sizeUnit={"px"}
                              size={16}
                              color={"#ffffff"}
                              loading={this.state.isShowSaveLoading}
                            />
                          ) : currentRecord.approvalStatus === "REWORK" ? (
                            "Resubmit"
                          ) : currentRecord.assignmentDetail.fileSubmission ===
                            0 ? (
                            "Mark As Done"
                          ) : (
                            "Submit"
                          )}
                        </button>
                      ) : (
                        <div
                          className="text-center br-10 bg-absent p-1 px-2 text-light cursor-pointer"
                          onClick={() => {
                            this.saveUnSubmitAssignment(currentRecord.id)
                          }}
                        >
                          {this.state.isShowSaveLoading ? (
                            <HashLoader
                              sizeUnit="px"
                              size={16}
                              color="#fff"
                              loading={this.state.isShowSaveLoading}
                            />
                          ) : currentRecord.assignmentDetail.fileSubmission ===
                            0 ? (
                            "Undo"
                          ) : (
                            "Unsubmit"
                          )}
                        </div>
                      )}
                      {this.state.uploadingFile && (
                        <div className="ml-3">
                          <span>Uploading file...</span>
                        </div>
                      )}
                      {/* {currentRecord &&
                    currentRecord.assignmentDetail.fileSubmission === 1 ? (
                      currentRecord.isSubmitted === 0 ? (
                        <button
                          className={`text-center br-10 p-1 px-2 text-light ${
                            this.props.workFile === ""
                              ? "bg-lightgray"
                              : currentRecord &&
                                currentRecord.approvalStatus === "REWORK"
                              ? "bg-absent"
                              : " bg-present"
                          }`}
                          disabled={this.props.workFile === ""}
                          onClick={() => {
                            this.saveSubmitAssignment(currentRecord.id)
                          }}
                        >
                          {this.state.isShowSaveLoading ? (
                            <HashLoader
                              sizeUnit={"px"}
                              size={16}
                              color={"#ffffff"}
                              loading={this.state.isShowSaveLoading}
                            />
                          ) : currentRecord &&
                            currentRecord.approvalStatus === "REWORK" ? (
                            "Resubmit"
                          ) : (
                            "Submit"
                          )}
                        </button>
                      ) : (
                        <div
                          className="text-center br-10 bg-absent p-1 px-2 text-light cursor-pointer"
                          onClick={() => {
                            this.saveUnSubmitAssignment(currentRecord.id)
                          }}
                        >
                          {this.state.isShowSaveLoading ? (
                            <HashLoader
                              sizeUnit={"px"}
                              size={16}
                              color={"#ffffff"}
                              loading={this.state.isShowSaveLoading}
                            />
                          ) : currentRecord.assignmentDetail.fileSubmission ===
                            0 ? (
                            "Undo"
                          ) : (
                            "Unsubmit"
                          )}
                        </div>
                      )
                    ) : (
                      <div
                        className="text-center br-10 bg-present p-1 px-2 text-light cursor-pointer"
                        onClick={() => {
                          this.saveSubmitAssignment(currentRecord.id)
                        }}
                      >
                        {this.state.isShowSaveLoading ? (
                          <HashLoader
                            sizeUnit={"px"}
                            size={16}
                            color={"#ffffff"}
                            loading={this.state.isShowSaveLoading}
                          />
                        ) : currentRecord.assignmentDetail.fileSubmission ===
                          0 ? (
                          "Mark as Done"
                        ) : (
                          "Submit your work"
                        )}
                      </div>
                    )} */}
                      <span className="col-4"></span>
                    </div>
                  )}
              </div>
            </>
          )}
      </Modal>
    )
  }

  render() {
    // myLog("work file", this.props.workFile)

    return (
      <>
        {this.renderCurrentAssignmentModal()}
        <AppWrapper
          openMessageModal={() => this.onClickMessageIcon()}
          teacherDashboard
          showTeacherThree
          showTeacherLogo
          screenName="Assignment"
        >
          {this.state.isShowLoading ? (
            <div
              className="d-flex justify-content-center"
              style={{ position: 'absolute', top: '50%', left: '50%' }}
            >
              <HashLoader
                sizeUnit={"px"}
                size={35}
                color={"#084353"}
                loading={this.state.isShowLoading}
              />
            </div>
          ) : (
            <div>
              <div className="row col-12 my-4">
                <div className="col-12 col-lg-8">
                  <h5 className="text-brand font-weight-bold">Overview</h5>
                  <div
                    className="d-flex br-3"
                    style={{
                      boxShadow: "0px 0px 6px #bdb9bd",
                      borderRadius: "15px",
                    }}
                  >
                    <div className="mt-3 px-3">
                      <div
                        style={{
                          position: "relative",
                          height: "150px",
                          width: "150px",
                        }}
                      >
                        <div
                          className="d-flex flex-column align-items-center justify-content-center"
                          style={{
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            position: "absolute",
                            pointerEvents: "none",
                          }}
                        >
                          <h5 className="m-0 mb-2 text-brand font-weight-bold">
                            {this.state.overviewPercentage || 0}%
                          </h5>
                          <div className="text-center ">
                            {`${this.state.overviewCount} 
                            out of 
                            ${this.state.overviewDatas.length}`}
                          </div>
                        </div>
                        <canvas
                          ref={ref => (this.statsGraphRef = ref)}
                          style={{
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            position: "absolute",
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-flex px-3" style={{ minWidth: "360px" }}>
                      <div className=" d-flex flex-column mt-3 ml-5 ">
                        <div className="d-flex align-items-center ">
                          <div className=" fs-12">
                            <div
                              className="p-2"
                              style={{
                                backgroundColor: this.findBgColor("TODO"),
                                borderRadius: "50%",
                                overflow: "hidden",
                                border: "5px solid #96AFB5",
                              }}
                            >
                              {this.renderStatusIcon("TODO")}
                            </div>
                          </div>
                          <div className="ml-3">
                            Todo
                            <div
                              style={{ fontSize: "24px", fontWeight: "1000" }}
                            >
                              {
                                (this.state.overviewDatas || []).filter(
                                  e => e.submitStatus === "TODO"
                                ).length
                              }
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center mt-5">
                          <div className="fs-12">
                            <div
                              className="p-2"
                              style={{
                                backgroundColor: this.findBgColor("INREVIEW"),
                                borderRadius: "50%",
                                overflow: "hidden",
                                border: "5px solid #ECD4A2",
                              }}
                            >
                              {this.renderStatusIcon("INREVIEW")}
                            </div>
                          </div>
                          <div className="ml-3">
                            In Review
                            <div
                              style={{ fontSize: "24px", fontWeight: "1000" }}
                            >
                              {
                                (this.state.overviewDatas || []).filter(
                                  e => e.submitStatus === "INREVIEW"
                                ).length
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-column mt-3 ml-5 ">
                        <div className="d-flex  align-items-center">
                          <div className="fs-12">
                            <div
                              className="p-2"
                              style={{
                                backgroundColor: this.findBgColor("OVERDUE"),
                                borderRadius: "50%",
                                overflow: "hidden",
                                border: "5px solid #F6C0BD",
                              }}
                            >
                              {this.renderStatusIcon("OVERDUE")}
                            </div>
                          </div>
                          <div className="ml-3">
                            Over Due
                            <div
                              style={{ fontSize: "24px", fontWeight: "1000" }}
                            >
                              {
                                (this.state.overviewDatas || []).filter(
                                  e => e.submitStatus === "OVERDUE"
                                ).length
                              }
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center mt-5 ">
                          <div className="fs-12">
                            <div
                              className="p-2"
                              style={{
                                backgroundColor: this.findBgColor("MISSED"),
                                borderRadius: "50%",
                                overflow: "hidden",
                                border: "5px solid #F9B1C3",
                              }}
                            >
                              {this.renderStatusIcon("MISSED")}
                            </div>
                          </div>
                          <div className="ml-3">
                            Missed
                            <div
                              style={{ fontSize: "24px", fontWeight: "1000" }}
                            >
                              {
                                (this.state.overviewDatas || []).filter(
                                  e => e.submitStatus === "MISSED"
                                ).length
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="d-flex row ml-auto my-2 mr-1 p-3 align-items-center"
                      style={{
                        backgroundColor: "#E2F2F1",
                        height: "190px",
                        width: "190px",
                        borderRadius: "15px",
                        minWidth: "183px",
                      }}
                    >
                      <div className="row mx-auto ">
                        <div className="mt-3 fs-12 ">
                          <div
                            className="p-2"
                            style={{
                              backgroundColor: this.findBgColor("COMPLETED"),
                              borderRadius: "50%",
                              overflow: "hidden",
                              border: "5px solid #86D7C9",
                            }}
                          >
                            {this.renderStatusIcon("COMPLETED", 35)}
                          </div>
                        </div>
                        <div className="ml-3 d-flex flex-column align-items-center justify-content-center">
                          <div style={{ fontSize: "24px", fontWeight: "1000" }}>
                            {
                              (this.state.overviewDatas || []).filter(
                                e => e.submitStatus === "COMPLETED"
                              ).length
                            }
                          </div>
                          <div>Completed</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 " style={{ minWidth: "380px" }}>
                  <h5 className="text-brand font-weight-bold">
                    Monthly Progress{" "}
                    {this.state.selectedSubject !== "ALL" && (
                      <span className="font-weight-normal">
                        -{" "}
                        {
                          this.state.subjectList.find(
                            e => e.subjectId === this.state.selectedSubject
                          ).subjectName
                        }
                      </span>
                    )}
                  </h5>
                  <div className="p-3" style={{ height: "20%" }}>
                    <canvas
                      ref={examPerformanceGraph =>
                        (this.examPerformanceGraph = examPerformanceGraph)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="row mb-2 mr-4 justify-content-end">
                <div className="pl-2">
                  <select
                    className="custom-select"
                    id="subject-tooltip"
                    value={this.state.selectedSubject}
                    onChange={e => {
                      this.setState(
                        { selectedSubject: e.target.value, page: 1 },
                        () => {
                          this.getAllAssignment()
                          this.setMonthlyRatio()
                        }
                      )
                    }}
                  >
                    <UncontrolledTooltip
                      placement="top"
                      className="bg-danger"
                      target="subject-tooltip"
                    >
                      Filter tasks with subjects
                    </UncontrolledTooltip>

                    <option value="ALL">Select Subject</option>
                    {this.state.subjectList &&
                      this.state.subjectList.map((item, index) => {
                        return (
                          /* for api query, subject name should not be value */
                          <option key={index} value={item.subjectId}>
                            {item.subjectName}
                          </option>
                        )
                      })}
                  </select>
                </div>
                <div className="pl-2">
                  <select
                    className="custom-select mr-3 mb-2"
                    id="select-tooltip"
                    value={this.state.selectedStatus}
                    onChange={e => {
                      this.setState(
                        { selectedStatus: e.target.value, page: 1 },
                        () => {
                          this.getAllAssignment()
                          this.getAnalyticCountPercentage()
                        }
                      )
                    }}
                  >
                    <UncontrolledTooltip
                      placement="top"
                      className="bg-danger"
                      target="select-tooltip"
                    >
                      Filter tasks with completion status
                    </UncontrolledTooltip>

                    <option value="ALL">Select Status</option>
                    {this.state.statusList &&
                      this.state.statusList.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        )
                      })}
                  </select>
                </div>

                <div className="pl-2">
                  <select
                    className="custom-select mr-3 mb-2"
                    id="task-tooltip"
                    value={this.state.selectedTask}
                    onChange={e => {
                      this.setState({ selectedTask: e.target.value }, () => {
                        this.getAllAssignment()
                      })
                    }}
                  >
                    <UncontrolledTooltip
                      placement="top"
                      className="bg-danger"
                      target="task-tooltip"
                    >
                      Filter tasks with task type
                    </UncontrolledTooltip>

                    <option value="ALL">Select Task</option>
                    {this.state.taskList &&
                      this.state.taskList.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        )
                      })}
                  </select>
                </div>
              </div>

              <div className="table-responsive mb-4">
                <table className="table table-borderless" cellPadding="3">
                  <thead>
                    <tr className="text-light">
                      <th
                        scope="col"
                        className="first-child-curved text-center"
                      >
                        Subject
                      </th>
                      <th
                        scope="col"
                        className="bg-theme-light text-center cursor-pointer"
                        onClick={() => { }}
                      >
                        Title
                      </th>
                      <th scope="col" className="bg-theme-light text-center">
                        Task Type
                      </th>
                      <th
                        scope="col"
                        className="bg-theme-light text-center cursor-pointer"
                        onClick={() => { }}
                      >
                        Due Date
                      </th>
                      <th scope="col" className="bg-theme-light text-center">
                        Marks
                      </th>
                      <th scope="col" className="bg-theme-light text-center">
                        Remark
                      </th>
                      <th
                        scope="col"
                        className="bg-theme-light text-center cursor-pointer last-child-curved"
                      >
                        Status
                      </th>
                    </tr>
                  </thead>

                  {!this.state.showLoading &&
                    <tbody>
                      {this.state.assignments &&
                        this.state.assignments.map(item => {
                          const bgColor =
                            this.props.currentRecordId === item.id
                              ? "#9CE5E0"
                              : "#47999738"
                          const status = item.submitStatus

                          return (
                            <React.Fragment key={item.id}>
                              <div className="my-2"></div>
                              <tr
                                className="cursor-pointer my-2"
                                onClick={() => {
                                  this.props.updateViewedAssignment(item, () => { });
                                  this.setCurrentAssignment(item)
                                  this.props.saveCurrentAssignmentDetailsInStore(
                                    item
                                  )
                                  this.toggleAssignmentDetailModal()
                                }}
                              >
                                <td
                                  className="first-child-curved-td text-center"
                                  style={{ backgroundColor: bgColor }}
                                >
                                  {item.subject.subjectName}
                                </td>
                                <td
                                  className=" text-center"
                                  style={{ backgroundColor: bgColor }}
                                >
                                  <span className="row ml-5">
                                    <span
                                      className="text-truncate"
                                      style={{ maxWidth: 200 }}
                                    >
                                      {item.assignmentDetail.title}
                                    </span>
                                    {item.assignmentDetail.attachment !== "" &&
                                      item.assignmentDetail.attachment !==
                                      null ? (
                                      <i
                                        className="fa align-self-center fa-paperclip ml-2"
                                        style={{ color: "#c5c3c3" }}
                                      ></i>
                                    ) : null}
                                  </span>
                                </td>
                                <td
                                  className="text-center"
                                  style={{ backgroundColor: bgColor }}
                                >
                                  {item.assignmentDetail.typeOfWork}
                                </td>
                                <td
                                  className="text-center"
                                  style={{ backgroundColor: bgColor }}
                                >
                                  <div>
                                    {moment.utc(moment(item.durationTo)).utcOffset(330).format(
                                      "DD MMM YYYY"
                                    )}
                                  </div>
                                  {["TODO", "INREVIEW"].includes(status) ? (
                                    <div
                                      className="text-brand"
                                      style={{ fontSize: "0.8rem" }}
                                    >
                                      {moment.utc(moment(item.durationTo)).utcOffset(330).format(
                                        "DD MMM YYYY"
                                      )}
                                    </div>
                                  ) : null}
                                </td>
                                <td
                                  className="text-center "
                                  style={{
                                    backgroundColor: bgColor,
                                  }}
                                >
                                  <div className="d-flex flex-column align-items-center">
                                    <div>
                                      {item.mark !== null ? item.mark : "-"}/
                                      {item.assignmentDetail.maxMark !== null
                                        ? item.assignmentDetail.maxMark
                                        : "-"}
                                    </div>
                                    {item.onTimeSubmission === "LATE" ? (
                                      <div
                                        className="rounded-pill mt-1 px-2 py-05 font-weight-bold"
                                        style={{
                                          backgroundColor: "#DFACB8",
                                          color: "#CE7E94",
                                          width: "50px",
                                        }}
                                      >
                                        LATE
                                      </div>
                                    ) : null}
                                  </div>
                                </td>
                                <td
                                  className=" text-center"
                                  style={{ backgroundColor: bgColor }}
                                >
                                  {item.remark !== null ? item.remark : "-"}
                                </td>
                                <td
                                  className="last-child-curved text-center"
                                  style={{ backgroundColor: bgColor }}
                                >
                                  <div className="d-flex justify-content-center">
                                    <div
                                      className="d-flex border-theme rounded fs-12  text-center align-items-center "
                                      style={{
                                        width: "120px",
                                        borderColor: this.findBgColor(status),
                                      }}
                                    >
                                      <div
                                        className="px-1"
                                        style={{
                                          fontSize: "1rem",
                                          backgroundColor: this.findBgColor(
                                            status
                                          ),
                                        }}
                                      >
                                        {this.renderStatusIcon(status)}
                                      </div>
                                      <div className="ml-2 mr-2 text-center">
                                        {status}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </React.Fragment>
                          )
                        })}
                    </tbody>
                  }
                </table>

                {
                  this.state.showLoading &&
                  <Skeleton count={5} height={30} width={"100%"} />
                }

                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className={`${!this.state.isPrevPage || this.state.pageLoading
                      ? "text-muted"
                      : ""
                      }`}
                    disabled={!this.state.isPrevPage || this.state.pageLoading}
                    onClick={() => {
                      this.setState(
                        { pageLoading: true, page: this.state.page - 1 },
                        () => {
                          this.getAllAssignment()
                        }
                      )
                    }}
                  >
                    {" "}
                    {"<"}prev{" "}
                  </button>
                  <button
                    type="button"
                    className={`${!this.state.isNextPage || this.state.pageLoading
                      ? "text-muted"
                      : ""
                      }`}
                    disabled={!this.state.isNextPage || this.state.pageLoading}
                    onClick={() => {
                      this.setState(
                        { pageLoading: true, page: this.state.page + 1 },
                        () => {
                          this.getAllAssignment()
                        }
                      )
                    }}
                  >
                    {" "}
                    next{">"}{" "}
                  </button>
                </div>
              </div>
            </div>
          )}
        </AppWrapper>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentRecord: state.parentAssignmentState.currentAssignment,
    currentRecordId: state.parentAssignmentState.currentAssignment.id,
    workFile: state.parentAssignmentState.currentAssignment.workFile || state.parentAssignmentState.currentAssignment.prevWorkFile || '',
    toggleModal: state.parentAssignmentState.toggleModal,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAssignmentList,
      getAssignmentOverview,
      getAssignmentYearlyGraph,
      getAssignmentDetailTeacherAttachment,
      getAssignmentDetailStudentAttachment,
      saveStudentSubmitAssignmentDetails,
      saveStudentUnSubmitAssignmentDetails,
      saveCurrentAssignmentDetailsInStore,
      saveAssignmentToggleModalDetailsInStore,
      updateViewedAssignment,
      showNotification
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  dispatchToProps
)(StudentAssignmentScreen)
