/*************************************************
 * RedInk
 * @exports
 * @class StudentRecord.js
 * @extends Component
 * @author Sasidharan // on 01/02/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog"
import { bindActionCreators } from "redux"
import readXlsxFile from "read-excel-file"
import { connect } from "react-redux"
import moment from "moment"
import jsPDF from "jspdf"
import {
  Button,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledTooltip,
  UncontrolledDropdown,
} from "reactstrap"

import { confirmAlertPopUp, ExcelDateToJSDate } from "../../utils/Utility"
import {
  getStudentDatabaseList,
  saveStudentDatabaseDetails,
  updateStudentDatabaseDetails,
  deleteStudentDatabaseDetails,
  saveStudentDatabaseDetailInStore,
  saveStudentCurrentGroupDetailInStore,
  deleteStudentGroup,
  getStudentGroups,
} from "../../actions/teacher/StudentDatabaseAction"
import { DANGER_TOAST, VALIDATION_MSG } from "../../utils/Constants"
import { saveIsShowLoadingInStore } from "../../actions/LoginAction"
import { showNotification } from "../../actions/CommonAction"
import { URL_PATH } from "../../config/urlPath"
import URL from "../../config"

import StudentDetailsModal from "./StudentDetailsModal"
import CreateGroupModal from "./CreateGroupModal"
import MessageModal from "./MessageModal"
import AppWrapper from "./Wrapper"

import NoDataPic from "../../assets/images/NoData.gif"
import groupPic from "../../assets/images/group.png"
import girlPic from "../../assets/images/girl.png"
import boyPic from "../../assets/images/boy.png"

const tooltipModifiers = {
  flip: { behaviour: ["top"] },
  preventOverflow: { boundariesElement: "viewport" },
}

class StudentRecord extends Component {
  constructor(props) {
    super(props)
    document.title = "Records | Redink"
    this.state = {
      tab: "students",
      isCreateGroupModalOpen: false,
      isMessageModalOpen: false,
      isStudentDetailsModalOpen: false,
      modalData: {},
      isShowExcelLoading: false,
      isCreatingStudentGroup: false,
      groupDetailModalData: {},
      groupToEditData: null,
      files: false,
      isUploadModal: false,
      selectedMethod: "",
      buttonEnable: "",
    }
  }

  componentDidMount = () => {
    this.props.getStudentDatabaseList(() => {})
    this.props.getStudentGroups()
  }

  closeSchoolDetailsModal = () => this.setState({ isMessageModalOpen: false })

  onSubmitSchoolDetails = () => this.closeSchoolDetailsModal()

  onClickMessageIcon = () =>
    this.setState({ isMessageModalOpen: !this.state.isMessageModalOpen })

  onSubmitStudentDetails = (toggle, data) => {
    if (data.hasOwnProperty("id")) {
      this.props.saveIsShowLoadingInStore(true)
      this.props.updateStudentDatabaseDetails(data, res => {
        this.props.saveIsShowLoadingInStore(false)
        if (res)
          this.setState({ isStudentDetailsModalOpen: toggle, modalData: {} })
      })
    } else {
      this.props.saveIsShowLoadingInStore(true)
      this.props.saveStudentDatabaseDetails(data, res => {
        this.props.saveIsShowLoadingInStore(false)
        if (res)
          this.setState({ isStudentDetailsModalOpen: toggle, modalData: {} })
      })
    }
  }

  deleteStudent = student => {
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT,
      yes => {
        if (yes) {
          this.props.deleteStudentDatabaseDetails({ id: student.id }, () => {})
        }
      },
      {
        buttons: [],
        customUI: ({ onClose }) => {
          return (
            <div
              className="custom"
              style={{
                backgroundColor: "#fff",
                borderBottomRightRadius: "12px",
                borderBottomLeftRadius: "12px",
              }}
            >
              <div
                className="bg-brand text-light d-flex"
                style={{
                  padding: "0.5rem 2rem",
                  borderTopRightRadius: "12px",
                  borderTopLeftRadius: "12px",
                }}
              >
                <div className="mx-auto fs-18">Delete</div>
              </div>

              <div className="mt-3" style={{ padding: "0.5rem 1.5rem" }}>
                <div className="text-muted fs-15">
                  Are you sure you want to delete?
                </div>
                <div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
                  <button
                    className="bg-brand text-light px-4 py-1 w-25 d-flex align-items-center justify-content-center yes-button"
                    style={{ borderRadius: "5px" }}
                    onClick={() => {
                      this.props.deleteStudentDatabaseDetails(
                        { id: student.id },
                        onClose
                      )
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-brand text-light px-4 py-1 ml-4 w-25 d-flex align-items-center justify-content-center"
                    style={{ borderRadius: "5px" }}
                    onClick={onClose}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )
        },
      }
    )
  }

  closeStudentDetailsModal = () => {
    this.setState({
      isStudentDetailsModalOpen: false,
    })
  }

  exportPDF = () => {
    const unit = "pt"
    const size = "A4" // Use A1, A2, A3 or A4
    const orientation = "portrait" // portrait or landscape

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size)

    doc.setFontSize(15)

    const title = "Students Report"
    const headers = [["Name", "Gender", "Roll No.", "RI Id"]]

    const data = this.props.studentList.map((elt, i) => [
      elt.name,
      elt.gender,
      elt.registerNumber,
      `RI000${i + 1}`,
    ])

    let content = {
      startY: 50,
      head: headers,
      body: data,
    }

    doc.text(title, marginLeft, 40)
    doc.autoTable(content)
    doc.save("report.pdf")
  }

  convertToRequestArray = rows => {
    delete rows[0]
    delete rows[1]
    if (Array.isArray(rows)) {
      const requestKeys = [
        // "name",
        "firstName",
        "lastName",
        "gender",
        "dob",
        "studentPhone",

        "registerNumber",
        "dateOfJoining",
        "studentEmail",
        "admissionNumber",
        "primaryParent",
        // "parent1",
        // "parent1email",
        // "parent1Phone",
        // "parent2",
        // "parent2email",
        // "parent2Phone",
        "fatherFirstName",
        "fatherLastName",
        "fatherEmail",
        "fatherPhone",
        "motherFirstName",
        "motherLastName",
        "motherEmail",
        "motherPhone",
        "legalGuardianFirstName",
        "legalGuardianLastName",
        "legalGuardianEmail",
        "legalGuardianPhone",
        "localGuardianFirstName",
        "localGuardianLastName",
        "localGuardianEmail",
        "localGuardianPhone",
      ]
      let finalArray = []
      let studentDetailsArray = []
      rows.forEach(row => {
        let tempObj = {}
        row.forEach((data, i) => {
          tempObj[requestKeys[i]] = data
        })
        finalArray.push(tempObj)
      })

      for (let i = 0; i < finalArray.length; i++) {
        let object = {
          name: finalArray[i].name,
          firstName: finalArray[i].firstName,
          lastName: finalArray[i].lastName,
          studentEmail: finalArray[i].studentEmail,
          studentPhone: finalArray[i].studentPhone,
          registerNumber: finalArray[i].registerNumber,
          primaryParent: finalArray[i].primaryParent,
          admissionNumber: finalArray[i].admissionNumber,
        }
        if (finalArray[i].gender == null || finalArray[i].gender === "") {
          object.gender = null
        } else {
          object.gender =
            finalArray[i].gender.toUpperCase() === "MALE" ||
            finalArray[i].gender.toUpperCase() === "M"
              ? "M"
              : "F"
        }
        if (finalArray[i].dob == null || finalArray[i].dob === "") {
          object.dob = ""
        } else {
          object.dob =
            // typeof finalArray[i].dob === "string"
            //   ?
            moment(finalArray[i].dob, "DD-MM-YYYYY").format("YYYY-MM-DD")
          //   :
          // moment(ExcelDateToJSDate(finalArray[i].dob)).format(
          //     "YYYY-MM-DD"
          //   )
        }
        if (
          finalArray[i].dateOfJoining == null ||
          finalArray[i].dateOfJoining === ""
        ) {
          object.dateOfJoining = ""
        } else {
          object.dateOfJoining =
            typeof finalArray[i].dateOfJoining === "string"
              ? moment(finalArray[i].dateOfJoining, "DD-MM-YYYYY").format(
                  "YYYY-MM-DD"
                )
              : moment(ExcelDateToJSDate(finalArray[i].dateOfJoining)).format(
                  "YYYY-MM-DD"
                )
        }
        let array = []
        array.push({
          fatherFirstName: finalArray[i].fatherFirstName,
          fatherLastName: finalArray[i].fatherLastName,
          fatherEmail: finalArray[i].fatherEmail,
          fatherPhone: finalArray[i].fatherPhone,
          motherFirstName: finalArray[i].motherFirstName,
          motherLastName: finalArray[i].motherLastName,
          motherEmail: finalArray[i].motherEmail,
          motherPhone: finalArray[i].motherPhone,
          legalGuardianEmail: finalArray[i].legalGuardianEmail,
          legalGuardianFirstName: finalArray[i].legalGuardianFirstName,
          legalGuardianLastName: finalArray[i].legalGuardianLastName,
          legalGuardianPhone: finalArray[i].legalGuardianPhone,
          localGuardianEmail: finalArray[i].localGuardianEmail,
          localGuardianFirstName: finalArray[i].localGuardianFirstName,
          localGuardianLastName: finalArray[i].localGuardianLastName,
          localGuardianPhone: finalArray[i].localGuardianPhone,
        })
        object["parentsList"] = array
        studentDetailsArray.push(object)
      }
      this.setState({
        studentsList: studentDetailsArray,
        isStudentListModalOpen: true,
      })
    }
  }

  convertToRequestArrayEMSI = rows => {
    delete rows[0]
    if (Array.isArray(rows)) {
      const requestKeys = [
        // "name",
        "firstName",
        "lastName",
        "gender",
        "studentPhone",
        "dob",
        "registerNumber",
        "dateOfJoining",
        "studentEmail",
        "admissionNumber",
        "primaryParent",
        // "parent1",
        // "parent1email",
        // "parent1Phone",
        // "parent2",
        // "parent2email",
        // "parent2Phone",
        "fatherFirstName",
        "fatherLastName",
        "fatherEmail",
        "fatherPhone",
        "motherFirstName",
        "motherLastName",
        "motherEmail",
        "motherPhone",
        "legalGuardianFirstName",
        "legalGuardianLastName",
        "legalGuardianEmail",
        "legalGuardianPhone",
        "localGuardianFirstName",
        "localGuardianLastName",
        "localGuardianEmail",
        "localGuardianPhone",
      ]
      let finalArray = []
      let studentDetailsArray = []
      rows.forEach(row => {
        let tempObj = {}
        row.forEach((data, i) => {
          tempObj[requestKeys[i]] = data
        })
        finalArray.push(tempObj)
      })
      for (let i = 0; i < finalArray.length; i++) {
        let object = {
          name: "",
          firstName: finalArray[i].lastName,
          lastName: "",
          studentEmail: "",
          studentPhone: finalArray[i].fatherEmail,
          registerNumber: finalArray[i].firstName,
          primaryParent: "",
          admissionNumber: "",
          // gender:finalArray[i].motherFirstName
        }

        if (!finalArray[i].motherFirstName) {
          object.gender = null
        } else {
          object.gender =
            finalArray[i].motherFirstName.toUpperCase() === "MALE" ||
            finalArray[i].motherFirstName.toUpperCase() === "M"
              ? "M"
              : "F"
        }
        if (!finalArray[i].fatherPhone) {
          object.dob = ""
        } else {
          object.dob =
            // typeof finalArray[i].fatherPhone === "string"
            //   ?
            moment(finalArray[i].fatherPhone, "DD-MM-YYYYY").format(
              "YYYY-MM-DD"
            )
          // : moment(ExcelDateToJSDate(finalArray[i].fatherPhone)).format(
          //     "YYYY-MM-DD"
          //   )
        }
        if (!finalArray[i].dateOfJoining) {
          object.dateOfJoining = ""
        } else {
          object.dateOfJoining = ""
          // typeof finalArray[i].dateOfJoining === "string"
          //   ? moment(finalArray[i].dateOfJoining, "DD-MM-YYYYY").format(
          //       "YYYY-MM-DD"
          //     )
          //   : moment(ExcelDateToJSDate(finalArray[i].dateOfJoining)).format(
          //       "YYYY-MM-DD"
          //     )
        }
        let array = []
        array.push({
          fatherFirstName: "",
          fatherLastName: "",
          fatherEmail: "",
          fatherPhone: "",
          motherFirstName: "",
          motherLastName: "",
          motherEmail: "",
          motherPhone: "",
          legalGuardianEmail: "",
          legalGuardianFirstName: "",
          legalGuardianLastName: "",
          legalGuardianPhone: "",
          localGuardianEmail: "",
          localGuardianFirstName: "",
          localGuardianLastName: "",
          localGuardianPhone: "",
        })
        object["parentsList"] = array
        studentDetailsArray.push(object)
      }
      this.setState({
        studentsList: studentDetailsArray,
        isStudentListModalOpen: true,
      })
    }
  }

  saveExcelUpload = () => {
    this.setState({ files: false })
    let hasError = false

    if (this.state.studentsList.length === 0) {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.SUCCESS,
        "Fill out all details"
      )
      return
    }
    for (let i = 0; i < this.state.studentsList.length; i++) {
      let list = this.state.studentsList[i]
      // for (let j = 0; j < list.parentsList.length; j++) {
      if (
        list.firstName === "" ||
        // list.lastName === "" ||
        list.firstName === null ||
        // list.lastName === null ||
        // list.studentEmail === "" ||
        // list.studentEmail === null ||
        // list.studentPhone === null ||
        // list.studentPhone === "" ||
        // list.registerNumber === null ||
        // list.admissionNumber === null ||
        // list.primaryParent === "" ||
        // list.primaryParent === null ||
        // list.gender === null ||
        // list.dob === null ||
        // list.dateOfJoining === null ||
        // list.registerNumber === "" ||
        // list.admissionNumber === "" ||
        list.gender === "" ||
        list.dob === ""
        // list.dateOfJoining === "" ||
        // list.parentsList[j].fatherFirstName === "" ||
        // list.parentsList[j].fatherFirstName === null ||
        // list.parentsList[j].fatherLastName === "" ||
        // list.parentsList[j].fatherLastName === null ||
        // list.parentsList[j].fatherEmail === "" ||
        // list.parentsList[j].fatherEmail === null ||
        // list.parentsList[j].fatherPhone === "" ||
        // list.parentsList[j].fatherPhone === null ||
        // list.parentsList[j].motherFirstName === "" ||
        // list.parentsList[j].motherFirstName === null ||
        // list.parentsList[j].motherLastName === "" ||
        // list.parentsList[j].motherLastName === null ||
        // list.parentsList[j].motherEmail === "" ||
        // list.parentsList[j].motherEmail === null ||
        // list.parentsList[j].motherPhone === "" ||
        // list.parentsList[j].motherPhone === null
      ) {
        hasError = true
      }
      //  if (list.studentPhone.toString().length !== 10) {
      //    this.props.showNotification(
      //      DANGER_TOAST,
      //      VALIDATION_MSG.SUCCESS,
      //      " Student phone number is invalid"
      //    )
      //    return
      //  }
      // if (!validateEmail(list.studentEmail)) {
      //   this.props.showNotification(
      //     DANGER_TOAST,
      //     VALIDATION_MSG.SUCCESS,
      //     " Student mail id invalid"
      //   )
      //   return
      // }
      // if (list.primaryParent === "MOTHER") {
      //   if (
      //     list.parentsList[j].motherFirstName === "" ||
      //     list.parentsList[j].motherFirstName === null ||
      //     list.parentsList[j].motherLastName === "" ||
      //     list.parentsList[j].motherLastName === null
      //   ) {
      //     this.props.showNotification(
      //       DANGER_TOAST,
      //       VALIDATION_MSG.SUCCESS,
      //       list.firstName + " - Mother first name and last name is required"
      //     )
      //     return
      //   }
      //   if (list.parentsList[j].motherPhone.toString().length !== 10) {
      //     this.props.showNotification(
      //       DANGER_TOAST,
      //       VALIDATION_MSG.SUCCESS,
      //       list.firstName + " - Mother phone number is invalid"
      //     )
      //     return
      //   }
      //   if (!validateEmail(list.parentsList[j].motherEmail)) {
      //     this.props.showNotification(
      //       DANGER_TOAST,
      //       VALIDATION_MSG.SUCCESS,
      //       list.firstName + " - Mother mail id invalid"
      //     )
      //     return
      //   }
      // }
      // if (list.primaryParent === "FATHER") {
      //   if (
      //     list.parentsList[j].fatherFirstName === "" ||
      //     list.parentsList[j].fatherFirstName === null ||
      //     list.parentsList[j].fatherLastName === "" ||
      //     list.parentsList[j].fatherLastName === null
      //   ) {
      //     this.props.showNotification(
      //       DANGER_TOAST,
      //       VALIDATION_MSG.SUCCESS,
      //       list.firstName + " - Father first name and last name is required"
      //     )
      //     return
      //   }
      //   if (list.parentsList[j].fatherPhone.toString().length !== 10) {
      //     this.props.showNotification(
      //       DANGER_TOAST,
      //       VALIDATION_MSG.SUCCESS,
      //       list.firstName + " - Father phone number is invalid"
      //     )
      //     return
      //   }
      //   if (!validateEmail(list.parentsList[j].fatherEmail)) {
      //     this.props.showNotification(
      //       DANGER_TOAST,
      //       VALIDATION_MSG.SUCCESS,
      //       list.firstName + " - Father mail id invalid"
      //     )
      //     return
      //   }
      // }
      // if (list.primaryParent === "LEG") {
      //   if (
      //     list.parentsList[j].legalGuardianFirstName === "" ||
      //     list.parentsList[j].legalGuardianFirstName === null ||
      //     list.parentsList[j].legalGuardianLastName === "" ||
      //     list.parentsList[j].legalGuardianLastName === null
      //   ) {
      //     this.props.showNotification(
      //       DANGER_TOAST,
      //       VALIDATION_MSG.SUCCESS,
      //       list.firstName +
      //         " - Legal Guardian First Name & Last Name is required"
      //     )
      //     return
      //   }
      //   if (list.parentsList[j].legalGuardianPhone.toString().length !== 10) {
      //     this.props.showNotification(
      //       DANGER_TOAST,
      //       VALIDATION_MSG.SUCCESS,
      //       list.firstName + " - Legal Guardian phone number is invalid"
      //     )
      //     return
      //   }
      //   if (!validateEmail(list.parentsList[j].legalGuardianEmail)) {
      //     this.props.showNotification(
      //       DANGER_TOAST,
      //       VALIDATION_MSG.SUCCESS,
      //       list.firstName + " - Legal Guardian mail id invalid"
      //     )
      //     return
      //   }
      // }
      // if (list.primaryParent === "LOG") {
      //   if (
      //     list.parentsList[j].localGuardianFirstName === "" ||
      //     list.parentsList[j].localGuardianFirstName === null ||
      //     list.parentsList[j].localGuardianLastName === "" ||
      //     list.parentsList[j].localGuardianLastName === null
      //   ) {
      //     this.props.showNotification(
      //       DANGER_TOAST,
      //       VALIDATION_MSG.SUCCESS,
      //       list.firstName +
      //         " - Local Guardian First Name & Last Name is required"
      //     )
      //     return
      //   }
      //   if (list.parentsList[j].localGuardianPhone.toString().length !== 10) {
      //     this.props.showNotification(
      //       DANGER_TOAST,
      //       VALIDATION_MSG.SUCCESS,
      //       list.firstName + " - Local Guardian phone number is invalid"
      //     )
      //     return
      //   }
      //   if (!validateEmail(list.parentsList[j].localGuardianEmail)) {
      //     this.props.showNotification(
      //       DANGER_TOAST,
      //       VALIDATION_MSG.SUCCESS,
      //       list.firstName + " - Local Guardian mail id invalid"
      //     )
      //     return
      //   }
      // }
      // if (list.dateOfJoining > moment(new Date()).format("YYYY-MM-DD")) {
      //   this.props.showNotification(
      //     DANGER_TOAST,
      //     VALIDATION_MSG.SUCCESS,
      //     list.firstName + " - Date of joining greater than today"
      //   )
      //   return
      // }
      // if (list.dob > moment(new Date()).format("YYYY-MM-DD")) {
      //   this.props.showNotification(
      //     DANGER_TOAST,
      //     VALIDATION_MSG.SUCCESS,
      //     list.firstName + " - Date of birth greater than today"
      //   )
      //   return
      // }
      // if (list.dateOfJoining !== null && list.dateOfJoining !== "") {
      //   if (list.dob > list.dateOfJoining) {
      //     this.props.showNotification(
      //       DANGER_TOAST,
      //       VALIDATION_MSG.SUCCESS,
      //       list.firstName + " - Date of birth greater than date of joining"
      //     )
      //     return
      //   }
      // }
      // }
    }
    let duplicateError = false
    // for (let i = 0; i < this.state.studentsList.length - 1; i++) {
    //   for (let j = 1; j < this.state.studentsList.length; j++) {
    //     if (i !== j) {
    //       if (
    //         this.state.studentsList[i].registerNumber ===
    //         this.state.studentsList[j].registerNumber
    //       ) {
    //         duplicateError = true
    //         break
    //       }
    //     }
    //   }
    //   if (duplicateError) break
    // }
    if (!hasError) {
      if (!duplicateError) {
        let studentListObject = {
          studentsList: this.state.studentsList,
        }
        this.setState(
          {
            isShowExcelLoading: true,
          },
          () => {
            this.props.saveStudentDatabaseDetails(studentListObject, res => {
              this.setState({
                isShowExcelLoading: false,
              })
              if (res) {
                this.setState({
                  isStudentListModalOpen: false,
                })
              }
            })
          }
        )
      } else {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Found duplicate register number..."
        )
      }
    } else {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.SUCCESS,
        "Fill out all fields..."
      )
    }
  }

  handleDrop = async files => {
    readXlsxFile(files[0])
      .then(rows => {
        this.convertToRequestArray(rows)
      })
      .catch(e => {
        this.setState({
          excelError: true,
          excelFileName: "",
          excelTitleData: [],
          uploadedExcelFile: {},
        })
      })
  }

  handleDropEMSI = async files => {
    readXlsxFile(files[0])
      .then(rows => {
        this.convertToRequestArrayEMSI(rows)
      })
      .catch(e => {
        this.setState({
          excelError: true,
          excelFileName: "",
          excelTitleData: [],
          uploadedExcelFile: {},
        })
      })
  }

  clearCloseModal = () => {
    this.props.saveStudentCurrentGroupDetailInStore({
      groupName: "",
      studentList: [],
      groupId: "",
    })
    this.setState({ groupToEditData: null })
  }

  onEditStudentDetail = student => {
    // if (student.parentsList.length === 0) {
    //   student.parentsList = [
    //     {
    //       parentName: "",
    //       parentEmail: "",
    //       parentPhone: "",
    //     },
    //   ]
    // }
    let object = student

    object.parentDetailId = object.parentsList[0].id || ""

    object.admissionNumber = object.admissionNumber || ""
    object.primaryParent = object.primaryParent || ""
    object.dateOfJoining = object.dateOfJoining || ""
    object.studentPhone = object.studentPhone || ""
    object.studentEmail = object.studentEmail || ""
    object.studentPhone = object.studentPhone || ""
    object.firstName = object.firstName || ""
    object.lastName = object.lastName || ""
    object.gender = object.gender || ""
    object.dob = object.dob || ""

    object.fatherFirstName = object.parentsList[0].fatherFirstName || ""
    object.fatherLastName = object.parentsList[0].fatherLastName || ""
    object.fatherEmail = object.parentsList[0].fatherEmail || ""
    object.fatherPhone = object.parentsList[0].fatherPhone || ""
    object.fatherName = object.parentsList[0].fatherName || ""

    object.motherFirstName = object.parentsList[0].motherFirstName || ""
    object.motherLastName = object.parentsList[0].motherLastName || ""
    object.motherEmail = object.parentsList[0].motherEmail || ""
    object.motherPhone = object.parentsList[0].motherPhone || ""
    object.motherName = object.parentsList[0].motherName || ""

    object.legalGuardianFirstName =
      object.parentsList[0].legalGuardianFirstName || ""
    object.legalGuardianLastName =
      object.parentsList[0].legalGuardianLastName || ""
    object.legalGuardianPhone = object.parentsList[0].legalGuardianPhone || ""
    object.legalGuardianEmail = object.parentsList[0].legalGuardianEmail || ""
    object.legalGuardianName = object.parentsList[0].legalGuardianName || ""

    object.localGuardianFirstName =
      object.parentsList[0].localGuardianFirstName || ""
    object.localGuardianLastName =
      object.parentsList[0].localGuardianLastName || ""
    object.localGuardianPhone = object.parentsList[0].localGuardianPhone || ""
    object.localGuardianEmail = object.parentsList[0].localGuardianEmail || ""
    object.localGuardianName = object.parentsList[0].localGuardianName || ""

    this.props.saveStudentDatabaseDetailInStore(object)
    this.setState({ modalData: student }, () => {
      this.setState({
        isStudentDetailsModalOpen: true,
      })
    })
  }

  render() {
    const { studentList, studentGroups } = this.props

    return (
      <>
        <CreateGroupModal
          groupData={this.state.groupToEditData}
          onEditFinish={() => this.setState({ groupToEditData: null })}
        />
        {/* Group details modal */}
        <div
          className="modal right slide-in-right fade mt-0"
          aria-labelledby="groupDetailModal"
          id="groupDetailModal"
          role="dialog"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content scrollbar">
              <div className="modal-header">
                <h4 className="modal-title text-dark" id="myModalLabel2">
                  Group Detail
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {this.state.groupDetailModalData.studentList && (
                  <div className="container">
                    <div className="row align-items-center justify-content-between">
                      <div>
                        <h4 className="text-dark">
                          {this.state.groupDetailModalData.groupName}
                        </h4>
                        {this.state.groupDetailModalData.studentList && (
                          <h5 className="text-dark">
                            {this.state.groupDetailModalData.studentList.length}{" "}
                            Students
                          </h5>
                        )}
                      </div>
                      <img
                        src={groupPic}
                        alt="Group icon"
                        className="d-block"
                        style={{
                          width: 100,
                          height: 100,
                          borderRadius: 100,
                          backgroundColor: "#eaeaea",
                        }}
                      />
                    </div>
                    {this.state.groupDetailModalData.studentList.length > 0 && (
                      <ol>
                        {this.state.groupDetailModalData.studentList.map(
                          member => (
                            <li key={member.studentId} className="mt-3">
                              {member.studentName}
                            </li>
                          )
                        )}
                      </ol>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Group details modal */}

        <AppWrapper
          openStudentDetailsModal={() =>
            this.setState({ isStudentDetailsModalOpen: true })
          }
          openMessageModal={() => this.onClickMessageIcon()}
          teacherStudentRecordSidemenu
          teacherDashboard
          showTeacherThree
          showTeacherLogo
          screenName="Students Record"
        >
          <MessageModal
            isModalOpen={this.state.isMessageModalOpen}
            closeModal={() => this.onClickMessageIcon()}
            onSubmitMessage={() => this.onSubmitMessage()}
          />
          <StudentDetailsModal
            isModalOpen={this.state.isStudentDetailsModalOpen}
            closeModal={() => this.closeStudentDetailsModal()}
            onSubmitStudentDetails={(toggle, data) =>
              this.onSubmitStudentDetails(toggle, data)
            }
          />
          <div className="row mx-3">
            <div className="col-sm-4"></div>
            <div className="col-lg-auto px-2">
              <div className="top-tab-pills">
                <button
                  onClick={() => this.setState({ tab: "students" })}
                  className={`btn btn-sm ${this.state.tab === "students" &&
                    "active"}`}
                >
                  Students
                </button>
                <button
                  onClick={() => this.setState({ tab: "groups" })}
                  className={`btn btn-sm ${this.state.tab === "groups" &&
                    "active"}`}
                >
                  Groups
                </button>
              </div>
            </div>
            <div className="col row" style={{ justifyContent: "flex-end" }}>
              {this.state.tab === "groups" && (
                <div className="align-self-center">
                  <button
                    type="button"
                    className="btn btn-sm btn-light px-4 rounded-pill mr-3 box-shadow-none"
                    data-toggle="modal"
                    data-target="#createGroupModal"
                    onClick={() => {
                      this.clearCloseModal()
                      this.setState({
                        groupToEditData: {
                          groupName: "",
                          studentList: [],
                          groupId: "",
                        },
                      })
                    }}
                  >
                    <i className="fas fa-plus mr-2" />
                    Create group
                  </button>
                </div>
              )}
              {this.state.tab === "students" && (
                <div className="d-flex flex-colum">
                  <div className="mb-2 align-self-center">
                    <button
                      type="button"
                      className="btn btn-sm bg-brand text-light px-4 rounded-pill mr-3 box-shadow-none"
                      onClick={() =>
                        this.setState({
                          isStudentDetailsModalOpen: true,
                          modalData: {},
                        })
                      }
                      style={{ color: "rgb(19, 175, 143)" }}
                    >
                      <i className="fas fa-plus mr-2" />
                      Add Student
                    </button>
                  </div>
                  <a
                    className="border-bottom-primary card mr-3 cursor-pointer mb-2 text-dark"
                    id="UncontrolledTooltipTemplate"
                    href={URL.STUDENT_DATABASE_DETAIL_XLSX}
                    download
                  >
                    <span className="px-3 py-1">
                      <i className="fal fa-table mr-2"></i>
                      Template
                    </span>
                  </a>
                  <UncontrolledTooltip
                    placement="top"
                    className="bg-danger"
                    modifiers={tooltipModifiers}
                    target="UncontrolledTooltipTemplate"
                  >
                    Download offline template to add bulk student details
                    offline and upload them later
                  </UncontrolledTooltip>
                  {/* {!this.state.files ? (
                     <div className="border-bottom card cursor-pointer mb-2 mr-3">
                       <span className="px-3 py-1">
                         <i className="fal fa-file-upload mr-2"></i>
                         Upload Student Database
                         <input
                           type="file"
                           className="cursor-pointer"
                           onChange={event => {
                             this.setState({ files: true })
                             this.handleDrop(event.target.files)
                           }}
                           accept=".csv,.xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                           style={{
                             opacity: 0.0,
                             position: "absolute",
                             top: 0,
                             left: 0,
                             bottom: 0,
                             right: 0,
                             width: "100%",
                             height: "100%",
                           }}
                         />
                       </span>
                     </div>
                   ) : null} */}
                  <div className="border-bottom-primary card cursor-pointer mb-2 mr-3">
                    <button
                      style={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        width: "100%",
                        height: "100%",
                      }}
                      onClick={() => this.setState({ isUploadModal: true })}
                    >
                      Upload Students
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <ModalTransition>
            {this.state.isUploadModal && (
              <Modal width="40%" heading="UPLOAD STUDENT DETAILS">
                <div className="d-flex flex-row">
                  <div
                    className="font-weight-bold mr-4"
                    style={{ marginTop: "2px", fontSize: "large" }}
                  >
                    Choose
                  </div>

                  {!this.state.files ? (
                    <div
                      className="border-bottom card cursor-pointer mb-2 mr-3"
                      style={{ display: "none" }}
                    >
                      <span className="px-3 py-1">
                        <i className="fal fa-file-upload mr-2"></i>
                        Upload Student Database
                        <input
                          id="ownmethod"
                          type="file"
                          className="cursor-pointer"
                          onChange={event => {
                            this.setState({
                              files: true,
                              isUploadModal: false,
                              selectedMethod: "",
                              buttonEnable: false,
                            })
                            this.handleDrop(event.target.files)
                          }}
                          accept=".csv,.xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          style={{
                            opacity: 0.0,
                            position: "absolute",
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      </span>
                    </div>
                  ) : null}
                  {!this.state.files ? (
                    <div
                      className="border-bottom card cursor-pointer mb-2 mr-3"
                      style={{ display: "none" }}
                    >
                      <span className="px-3 py-1" id="ownmethod">
                        <i className="fal fa-file-upload mr-2"></i>
                        Upload EMSI Database
                        <input
                          id="emis"
                          type="file"
                          className="cursor-pointer"
                          onChange={event => {
                            this.setState({
                              files: true,
                              isUploadModal: false,
                              selectedMethod: "",
                              buttonEnable: false,
                            })
                            this.handleDropEMSI(event.target.files)
                          }}
                          accept=".csv,.xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          style={{
                            opacity: 0.0,
                            position: "absolute",
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      </span>
                    </div>
                  ) : null}

                  <div style={{ width: "80%" }}>
                    <select
                      className="form-control input-border-color"
                      value={this.state.selectedMethod}
                      onChange={e => {
                        this.setState({
                          selectedMethod: e.target.value,
                          buttonEnable: true,
                        })
                      }}
                    >
                      <option value="">Select Method</option>
                      <option value="ownmethod"> Template Data</option>
                      <option value="emis">EMIS Student Data</option>
                    </select>
                  </div>
                </div>

                <ModalFooter style={{ float: "right" }}>
                  {this.state.buttonEnable ? (
                    <div>
                      <label
                        htmlFor={this.state.selectedMethod}
                        className="text-light"
                        style={{
                          width: "5rem",
                          border: "1px solid",
                          cursor: "pointer",
                          borderRadius: "15px",
                          textAlign: "center",
                          marginRight: "10px",
                          marginTop: "6px",
                          backgroundColor: "#06B191",
                        }}
                      >
                        NEXT
                      </label>
                    </div>
                  ) : null}

                  <Button
                    color="light"
                    onClick={() =>
                      this.setState({
                        isUploadModal: false,
                        selectedMethod: "",
                        buttonEnable: false,
                      })
                    }
                    className="text-muted px-4 py-1"
                    style={{ borderRadius: 12, border: 0 }}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            )}
          </ModalTransition>
          {this.state.tab === "students" && (
            <div className="row text-center ml-2 mt-3">
              {studentList &&
                studentList.length > 0 &&
                studentList
                  .sort((a, b) => a.firstName.localeCompare(b.firstName))
                  .map(student => (
                    <div
                      className="records student-behaviour-card card p-0 mr-3 mb-4 student-behaviour-card-min-ht"
                      key={student.id}
                    >
                      <div className="card-body p-2">
                        <div className="student-record1">
                          <img
                            src={
                              student.gender === "M" ||
                              student.gender === "Male"
                                ? boyPic
                                : girlPic
                            }
                            alt="gender"
                            className="student-pic center mx-auto d-block"
                            style={{
                              width: "50%",
                              height: "50%",
                              marginTop: "1rem",
                            }}
                          />
                          <p className="card-subtitle text-white pt-4 pb-3 px-2 text-center">
                            {student.name}
                          </p>
                          <div className="d-flex justify-content-around">
                            <div>
                              <i className="card-plus fa fa-plus ml-3"></i>
                              <span className="text-white p-2">0</span>
                            </div>
                            <div>
                              <i className="card-minus fa fa-minus"></i>
                              <span className="text-white p-2">0</span>
                            </div>
                          </div>
                          {/* <i className="card-plus fa fa-plus ml-3"></i>
                         <span className="text-white p-2">0</span>
                         <div className="float-right mr-3 pb-3">
                           <i className="card-minus fa fa-minus"></i>
                           <span className="text-white p-2">0</span>
                         </div> */}
                        </div>

                        <div className="student-record2 text-black py-2">
                          <div className="d-flex flex-row-reverse p-0">
                            <UncontrolledDropdown direction="left">
                              <DropdownToggle
                                tag="a"
                                className="nav-link p-0 pr-1"
                              >
                                <i
                                  className="fa fa-ellipsis-v text-black"
                                  style={{ fontSize: "12px", color: "black" }}
                                ></i>
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={() =>
                                    this.onEditStudentDetail(student)
                                  }
                                >
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => this.deleteStudent(student)}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                          <p
                            className="text-black text-center mb-0"
                            style={{
                              fontWeight: "bold",
                              fontSize: "120%",
                              color: "#084353",
                            }}
                          >
                            {student.name}
                          </p>
                          {/*
                       <div className=" mt-1" style={{ fontWeight: "bold" }}>
                         {student.redinkId}
                       </div>                        
                     */}

                          <div
                            className=" mt-0"
                            style={{
                              fontWeight: "bold",
                              fontSize: "80%",
                              color: "#084353",
                            }}
                          >
                            Roll No: {student.registerNumber}
                          </div>
                          <div
                            className="my-1"
                            style={{
                              fontWeight: "bold",
                              fontSize: "80%",
                              color: "#084353",
                            }}
                          >
                            RI ID: {student.redinkId}
                          </div>
                          <div
                            className="mt-0 text-center"
                            style={{ fontSize: "80%", color: "#084353" }}
                          >
                            <svg
                              id="Icons"
                              height="20"
                              viewBox="0 0 74 74"
                              width="20"
                              className="mr-3"
                              strokeWidth="1.5"
                              stroke="#084353"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="m70.306 72h-66.612a1 1 0 0 1 -1-1v-17.633a6.885 6.885 0 0 1 6.877-6.877h54.858a6.885 6.885 0 0 1 6.877 6.877v17.633a1 1 0 0 1 -1 1zm-65.612-2h64.612v-16.633a4.883 4.883 0 0 0 -4.877-4.877h-54.858a4.883 4.883 0 0 0 -4.877 4.877z" />
                              <path d="m70.306 60.245a10.008 10.008 0 0 1 -6.087-1.934 8.565 8.565 0 0 0 -10.02 0 10.557 10.557 0 0 1 -12.179 0 8.57 8.57 0 0 0 -10.023 0 10.016 10.016 0 0 1 -6.089 1.933 10.023 10.023 0 0 1 -6.093-1.933 8.016 8.016 0 0 0 -5.015-1.617 8.016 8.016 0 0 0 -5.015 1.618 10.022 10.022 0 0 1 -6.092 1.933 1 1 0 0 1 0-2 8.016 8.016 0 0 0 5.014-1.618 10.027 10.027 0 0 1 6.093-1.933 10.023 10.023 0 0 1 6.093 1.933 8.016 8.016 0 0 0 5.015 1.618 8.006 8.006 0 0 0 5.011-1.618 10.558 10.558 0 0 1 12.18 0 8.57 8.57 0 0 0 10.022 0 10.552 10.552 0 0 1 12.176 0 8 8 0 0 0 5.009 1.618 1 1 0 0 1 0 2z" />
                              <path d="m62.908 48.49h-51.24a1 1 0 0 1 -1-1v-14.09a5.528 5.528 0 0 1 5.521-5.521h42.2a5.527 5.527 0 0 1 5.519 5.521v14.09a1 1 0 0 1 -1 1zm-50.24-2h49.24v-13.09a3.525 3.525 0 0 0 -3.521-3.521h-42.2a3.526 3.526 0 0 0 -3.519 3.521z" />
                              <path d="m62.908 39.673a7.9 7.9 0 0 1 -4.808-1.523 6.36 6.36 0 0 0 -7.458 0 8.352 8.352 0 0 1 -9.618 0 6.364 6.364 0 0 0 -7.461 0 8.355 8.355 0 0 1 -9.619 0 5.975 5.975 0 0 0 -3.733-1.208 5.978 5.978 0 0 0 -3.732 1.208 7.909 7.909 0 0 1 -4.812 1.523 1 1 0 0 1 0-2 5.975 5.975 0 0 0 3.733-1.208 7.912 7.912 0 0 1 4.811-1.523 7.909 7.909 0 0 1 4.812 1.523 6.367 6.367 0 0 0 7.463 0 8.351 8.351 0 0 1 9.617 0 6.366 6.366 0 0 0 7.462 0 8.346 8.346 0 0 1 9.614 0 5.965 5.965 0 0 0 3.729 1.208 1 1 0 0 1 0 2z" />
                              <path d="m50.714 29.877h-7.836a1 1 0 0 1 -1-1v-12.734a1 1 0 0 1 1-1h7.836a1 1 0 0 1 1 1v12.734a1 1 0 0 1 -1 1zm-6.836-2h5.836v-10.734h-5.836z" />
                              <path d="m46.8 17.143a1 1 0 0 1 -1-1v-3.168a1 1 0 0 1 2 0v3.168a1 1 0 0 1 -1 1z" />
                              <path d="m46.8 13.975a4.163 4.163 0 0 1 -4.158-4.158c0-1.932 2.757-6.458 3.31-7.345a1.038 1.038 0 0 1 1.7 0c.553.887 3.31 5.413 3.31 7.345a4.163 4.163 0 0 1 -4.162 4.158zm0-9.019c-1.071 1.877-2.158 4.071-2.158 4.861a2.158 2.158 0 1 0 4.316 0c-.004-.79-1.091-2.984-2.158-4.861z" />
                              <path d="m31.122 29.877h-7.836a1 1 0 0 1 -1-1v-12.734a1 1 0 0 1 1-1h7.836a1 1 0 0 1 1 1v12.734a1 1 0 0 1 -1 1zm-6.836-2h5.836v-10.734h-5.836z" />
                              <path d="m27.2 17.143a1 1 0 0 1 -1-1v-3.168a1 1 0 0 1 2 0v3.168a1 1 0 0 1 -1 1z" />
                              <path d="m27.2 13.975a4.163 4.163 0 0 1 -4.158-4.158c0-1.932 2.757-6.458 3.31-7.345a1.038 1.038 0 0 1 1.7 0c.553.887 3.31 5.413 3.31 7.345a4.163 4.163 0 0 1 -4.162 4.158zm0-9.019c-1.071 1.877-2.158 4.071-2.158 4.861a2.158 2.158 0 1 0 4.316 0c.004-.79-1.083-2.984-2.158-4.861z" />
                            </svg>
                            {moment(student.dob).format("DD-MM-YYYY")}
                          </div>

                          {student.parentsList &&
                            student.parentsList.length > 0 && (
                              <div
                                className="mt-0 text-center"
                                style={{ fontSize: "80%", color: "#084353" }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon icon-tabler icon-tabler-phone mr-2"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="#084353"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path stroke="none" d="M0 0h24v24H0z" />
                                  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                                </svg>
                                {student.primaryParentDetails &&
                                student.primaryParentDetails.primaryParentPhone
                                  ? student.primaryParentDetails
                                      .primaryParentPhone
                                  : student.studentPhone}
                              </div>
                            )}

                          <button
                            className="mt-0"
                            style={{ color: "#284BAD" }}
                            onClick={() => {
                              this.props.history.push({
                                pathname: URL_PATH.TEACHER_STUDENT_PROFILE,
                                state: {
                                  studentId: student.id,
                                },
                              })
                            }}
                          >
                            view profile
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          )}
          {this.state.tab === "groups" && (
            <div className="row text-center ml-2 mt-3">
              {studentGroups && studentGroups.length > 0 ? (
                studentGroups.map(group => (
                  <div
                    className="student-behaviour-card records card p-0 mr-3 mb-4 group-behaviour-min-ht"
                    key={group.groupId}
                  >
                    <div className="card-body p-2">
                      <div className="student-record1">
                        <div className="d-flex flex-row-reverse p-0">
                          <UncontrolledDropdown direction="left">
                            <DropdownToggle
                              tag="a"
                              className="nav-link p-0 pr-1"
                            >
                              <i
                                className="fa fa-ellipsis-v text-white"
                                style={{ fontSize: "12px", color: "black" }}
                              ></i>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() =>
                                  this.props.deleteStudentGroup(group)
                                }
                              >
                                Delete
                              </DropdownItem>
                              {/* <DropdownItem
                               data-toggle="modal"
                               data-target="#createGroupModal"
                               onClick={() => {
                                 this.setState({ groupToEditData: group });
                               }}
                             >
                               Edit
                           </DropdownItem> */}
                              <DropdownItem
                                data-toggle="modal"
                                data-target="#groupDetailModal"
                                onClick={() => {
                                  this.setState({ groupDetailModalData: group })
                                }}
                              >
                                View Details
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                        <img
                          src={groupPic}
                          alt="Group icon"
                          className="student-pic center mx-auto d-block"
                          style={{
                            width: "50%",
                            height: "50%",
                            borderRadius: 100,
                            backgroundColor: "#eaeaea",
                            marginTop: "2rem",
                          }}
                        />
                        <p className="card-title text-white pt-4 pb-1 px-3 text-center">
                          {group.groupName}
                        </p>
                        <p className="card-subtitle text-white pb-3 px-3 text-center">
                          {group.studentList.length} Members
                        </p>
                      </div>

                      <div className="student-record2 text-black py-2">
                        <div className="d-flex flex-row-reverse p-0">
                          {/* <UncontrolledDropdown direction="left">
                               <DropdownToggle
                                 tag="a"
                                 className="nav-link p-0 pr-1"
                               >
                                 <i
                                   className="fa fa-ellipsis-v text-black"
                                   style={{ fontSize: "12px", color: "black" }}
                                 ></i>
                               </DropdownToggle>
                               <DropdownMenu>
                                 <DropdownItem
                                   onClick={() => {
                                     this.props.saveStudentDatabaseDetailInStore(
                                       student
                                     )
                                     this.setState(
                                       {
                                         modalData: student,
                                       },
                                       () => {
                                         this.setState({
                                           isStudentDetailsModalOpen: true,
                                         })
                                       }
                                     )
                                   }}
                                 >
                                   Edit
                                 </DropdownItem>
                                 <DropdownItem
                                   onClick={() => {
                                     this.deleteStudent(student)
                                   }}
                                 >
                                   Delete
                                 </DropdownItem>
                               </DropdownMenu>
                             </UncontrolledDropdown> */}
                        </div>
                        <div className="d-flex flex-row-reverse p-0">
                          <UncontrolledDropdown direction="left">
                            <DropdownToggle
                              tag="a"
                              className="nav-link p-0 pr-1"
                            >
                              <i
                                className="fa fa-ellipsis-v text-black"
                                style={{ fontSize: "12px", color: "black" }}
                              ></i>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => {
                                  // this.props.deleteStudentGroup(group)
                                  confirmAlertPopUp(
                                    VALIDATION_MSG.DELETE_TITLE,
                                    VALIDATION_MSG.DELETE_CONTENT,
                                    yes => {
                                      if (yes) {
                                        this.props.deleteStudentGroup(group)
                                      }
                                    },
                                    {
                                      buttons: [],
                                      customUI: ({ onClose }) => {
                                        return (
                                          <div
                                            className="custom"
                                            style={{
                                              backgroundColor: "#fff",

                                              borderBottomRightRadius: "12px",
                                              borderBottomLeftRadius: "12px",
                                            }}
                                          >
                                            <div
                                              className="bg-brand text-light d-flex"
                                              style={{
                                                padding: "0.5rem 2rem",
                                                borderTopRightRadius: "12px",
                                                borderTopLeftRadius: "12px",
                                              }}
                                            >
                                              <div
                                                className="mx-auto"
                                                style={{ fontSize: "18px" }}
                                              >
                                                Delete
                                              </div>
                                            </div>

                                            <div
                                              className="mt-3 "
                                              style={{
                                                padding: "0.5rem 1.5rem",
                                              }}
                                            >
                                              <div
                                                className="text-muted"
                                                style={{ fontSize: "15px" }}
                                              >
                                                Are you sure you want to delete?
                                              </div>
                                              <div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
                                                <button
                                                  className="bg-brand text-light px-4 py-1 w-25 d-flex align-items-center justify-content-center yes-button"
                                                  style={{
                                                    borderRadius: "5px",
                                                  }}
                                                  onClick={() => {
                                                    this.props.deleteStudentGroup(
                                                      group
                                                    )
                                                    onClose()
                                                  }}
                                                >
                                                  Yes
                                                </button>
                                                <button
                                                  className="bg-brand text-light px-4 py-1 ml-4 w-25 d-flex align-items-center justify-content-center"
                                                  style={{
                                                    borderRadius: "5px",
                                                  }}
                                                  onClick={onClose}
                                                >
                                                  No
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      },
                                    }
                                  )
                                }}
                              >
                                Delete
                              </DropdownItem>
                              <DropdownItem
                                data-toggle="modal"
                                data-target="#createGroupModal"
                                onClick={() => {
                                  this.props.saveStudentCurrentGroupDetailInStore(
                                    group
                                  )
                                  this.setState({ groupToEditData: group })
                                }}
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem
                                data-toggle="modal"
                                data-target="#groupDetailModal"
                                onClick={() => {
                                  this.setState({ groupDetailModalData: group })
                                }}
                              >
                                View Detail
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                        <p
                          className="text-black text-center mb-0"
                          style={{
                            // fontWeight: "bold",
                            // fontSize: "120%",
                            color: "#084353",
                          }}
                        >
                          Creating groups allows you to classify students
                          according to their specific needs. You can assign
                          tasks to each group specifically.
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="container text-center">
                  <img
                    src={NoDataPic}
                    style={{ marginTop: "", width: "100%", maxWidth: 500 }}
                    alt="NoData"
                  />
                  <h4 className="text-dark" style={{ fontWeight: "bold" }}>
                    No Group Exists !!
                  </h4>
                </div>
              )}
            </div>
          )}
          <ModalTransition>
            {this.state.isStudentListModalOpen && (
              <Modal
                width="60%"
                heading="STUDENT LIST"
                actions={[
                  {
                    text: this.state.isShowExcelLoading ? "LOADING..." : "SAVE",
                    onClick: this.saveExcelUpload,
                  },
                  {
                    text: "CANCEL",
                    onClick: () => {
                      this.setState({ files: false })
                      this.setState({
                        isStudentListModalOpen: false,
                        isShowExcelLoading: false,
                      })
                    },
                    style: {
                      fontWeight: "bold",
                    },
                  },
                ]}
              >
                <div className="my-3 table-responsive">
                  <table className="table table-hover">
                    <thead className="bg-lightblue-custom">
                      <tr>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Gender</th>
                        <th scope="col">Dob</th>
                        {/* <th scope="col">Register Number</th>
                         <th scope="col">DOJ</th>
                         <th scope="col">Email Id</th> */}
                        <th scope="col">Phone number</th>
                        {/* <th scope="col">Admission Number</th>
                         <th scope="col">Primary Parent</th>
                         <th scope="col">Father First Name</th>
                         <th scope="col">Father Last Name</th>
                         <th scope="col">Father Email</th>
                         <th scope="col">Father Phone</th>
                         <th scope="col">Mother First Name</th>
                         <th scope="col">Mother Last Name</th>
                         <th scope="col">Mother Email</th>
                         <th scope="col">Mother Phone</th> */}

                        <th scope="col" width="20%">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.studentsList &&
                        this.state.studentsList.length > 0 &&
                        this.state.studentsList.map((student, i) => (
                          <tr key={i}>
                            <td>{student.firstName}</td>
                            <td>{student.lastName}</td>
                            <td>{student.gender}</td>
                            <td>{student.dob}</td>
                            {/*<td>{student.registerNumber}</td> 
                             <td>{student.dateOfJoining}</td>
                             <td>{student.studentEmail}</td>*/}
                            <td>{student.studentPhone}</td>
                            {/* <td>{student.admissionNumber}</td>
                             <td>{student.primaryParent}</td>
                             <td>{student.parentsList[0].fatherFirstName}</td>
                             <td>{student.parentsList[0].fatherLastName}</td>
                             <td>{student.parentsList[0].fatherEmail}</td>
                             <td>{student.parentsList[0].fatherPhone}</td>
                             <td>{student.parentsList[0].motherFirstName}</td>
                             <td>{student.parentsList[0].motherLastName}</td>
                             <td>{student.parentsList[0].motherEmail}</td>
                             <td>{student.parentsList[0].motherPhone}</td> */}
                            <td>
                              <button
                                className="badge badge-pill badge-danger"
                                onClick={() => {
                                  // this.deleteStudent(student)
                                  let student = this.state.studentsList
                                  student.splice(i, 1)
                                  this.setState({ studentsList: student })
                                }}
                              >
                                <small>
                                  <i className="far fa-user-minus mr-1" />
                                  Delete
                                </small>
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </Modal>
            )}
          </ModalTransition>
        </AppWrapper>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    studentList: state.studentDatabaseState.studentList,
    studentGroups: state.studentDatabaseState.studentGroups,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getStudentDatabaseList,
      saveStudentDatabaseDetails,
      saveStudentDatabaseDetailInStore,
      updateStudentDatabaseDetails,
      deleteStudentDatabaseDetails,
      saveIsShowLoadingInStore,
      deleteStudentGroup,
      getStudentGroups,
      showNotification,
      saveStudentCurrentGroupDetailInStore,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(StudentRecord)
