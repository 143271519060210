import React, { Component } from "react"
import AppWrapper from "../Wrapper"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  getSubjectAllotmentList,
  saveSubjectAllotment,
} from "../../../actions/teacher/SubjectAllotmentActions"
import {
  getSubjectsList,
  getSubjectGroupsList,
} from "../../../actions/teacher/SubjectDetailsActions"
import { Button, FormGroup, Input, ModalFooter } from "reactstrap"
import Modal, { ModalTransition } from "@atlaskit/modal-dialog"
import { HashLoader } from "react-spinners"
import { myLog } from "../../../utils/Utility"
import { showNotification } from "../../../actions/CommonAction"
import { DANGER_TOAST, VALIDATION_MSG } from "../../../utils/Constants"
import { default as ReactSelect, components } from "react-select"

const Option = props => (
  <div>
    <components.Option {...props}>
      <div className="d-flex align-items-center">
        <input
          type="checkbox"
          className="subject-checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label className="ml-2 mt-2">{props.label}</label>
      </div>
    </components.Option>
  </div>
)

class SubjectAllotment extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isEditing: false,
      isSaving: false,
      selected: [],
      copiedSubjectAllotmentList: [],
      subjectAllotmentList: [],
      isUpdate2ndLangModalOpen: false,
      selectedSecondLang: {},
      isGroupEditOpen: false,
      isUpdate3ndLangModalOpen: false,
      selectedThirdLang: {},
      isUpdateElectiveModalOpen: false,
      selectedSubjectGroup: null,
      isSubjectGroupModalOpen: false,
      isShowLoading: true,

      selectedElectives: [],
      subjectGroupsList: [],
      subjectsList: {
        core: [],
        language: [],
        other: [],
      },
      higherSecondary: false,
      sameLanguage: false,
      secondOne: "",
      thirdOne: "",
    }
  }

  componentDidMount = () => {
    this.props.getSubjectAllotmentList({}, (success, data) => {
      if (success) {
        const subjectAllotmentList = data.map(e => ({ ...e, updated: false }))
        const deepCopied = JSON.parse(JSON.stringify(subjectAllotmentList))
        this.setState({
          subjectAllotmentList,
          copiedSubjectAllotmentList: deepCopied,
          isShowLoading: false,
        })
      } else {
        this.setState({ subjectAllotmentList: [] })
      }
    })
    this.props.getSubjectsList({}, (success, data) => {
      if (success) {
        const { CORE, LANGUAGE, OTHER, higherSecondary } = data
        this.setState({
          subjectsList: {
            core: CORE || [],
            language: LANGUAGE || [],
            other: OTHER || [],
          },
          higherSecondary,
        })
      }
    })
    this.props.getSubjectGroupsList({}, (success, response) => {
      // myLog("this.props.getSubjectGroupsList", success, response)
      if (success) {
        this.setState({ subjectGroupsList: response })
      }
    })
  }

  onSave = () => {
    const { secondOne, thirdOne } = this.state
    // myLog("Languages onSave", secondOne, thirdOne)
    if (secondOne || thirdOne) {
      if (secondOne === thirdOne) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Same Language"
        )
        return
      }
    }

    this.setState({ isSaving: true })
    this.props.saveSubjectAllotment(
      this.state.subjectAllotmentList.filter(e => e.updated),
      (success, data) => {
        if (success) {
          const subjectAllotmentList = data.map(e => ({
            ...e,
            updated: false,
          }))
          const deepCopied = JSON.parse(JSON.stringify(subjectAllotmentList))
          this.setState({
            subjectAllotmentList,
            copiedSubjectAllotmentList: deepCopied,
            isSaving: false,
            isEditing: false,
          })
        } else {
          this.setState({ isSaving: false })
        }
      }
    )
  }

  onCancel = () => {
    const deepCopied = JSON.parse(
      JSON.stringify(this.state.copiedSubjectAllotmentList)
    )
    this.setState({
      isEditing: false,
      selected: [],
      selectedSecondLang: {},
      selectedThirdLang: {},
      selectedElectives: [],
      subjectGroupsList: [],
      subjectAllotmentList: deepCopied,
    })
  }

  checkLanguage = () => {
    const { secondOne, thirdOne } = this.state
    // myLog("Languages", secondOne, thirdOne)
    if (secondOne && thirdOne) {
      if (secondOne === thirdOne) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Same Language cannot be allotted"
        )
        this.setState({ secondOne: "", thirdOne: "" })
        // myLog("Same language")
        return
      }
    }
  }

  styles = {
    option: (provided, state) => ({
      ...provided,
      color: "black",
      backgroundColor: "white",
    }),
    multiValueRemove: () => ({
      display: "none",
    }),
  }

  onSave2Lang = () => {
    this.setState(state => ({
      ...state,
      selected: [],
      selectedSecondLang: {},
      isUpdate2ndLangModalOpen: false,
      subjectAllotmentList: state.subjectAllotmentList.map(stud => {
        if (state.selected.includes(stud.studentId)) {
          if (
            stud.thirdLanguage &&
            state.selectedSecondLang &&
            stud.thirdLanguage.subjectId === state.selectedSecondLang.subjectId
          ) {
            return {
              ...stud,
              secondLanguage: state.selectedSecondLang,
              thirdLanguage: { subjectId: -1 },
              updated: true,
            }
          } else {
            return {
              ...stud,
              secondLanguage: state.selectedSecondLang,
              updated: true,
            }
          }
        } else {
          return stud
        }
      }),
    }))
  }

  onSave3Lang = () => {
    this.setState(state => ({
      ...state,
      selected: [],
      selectedThirdLang: {},
      isUpdate3ndLangModalOpen: false,
      subjectAllotmentList: state.subjectAllotmentList.map(stud => {
        if (state.selected.includes(stud.studentId)) {
          if (
            stud.secondLanguage &&
            state.selectedThirdLang &&
            stud.secondLanguage.subjectId === state.selectedThirdLang.subjectId
          ) {
            return {
              ...stud,
              thirdLanguage: state.selectedThirdLang,
              secondLanguage: { subjectId: -1 },
              updated: true,
            }
          } else {
            return {
              ...stud,
              thirdLanguage: state.selectedThirdLang,
              updated: true,
            }
          }
        } else {
          return stud
        }
      }),
    }))
  }

  onSaveElective = () => {
    this.setState(state => {
      return {
        ...state,
        selected: [],
        selectedElectives: [],
        isUpdateElectiveModalOpen: false,
        subjectAllotmentList: state.subjectAllotmentList.map(stud => {
          if (state.selected.includes(stud.studentId)) {
            return {
              ...stud,
              electives: state.selectedElectives.slice(),
              updated: true,
            }
          } else {
            return stud
          }
        }),
      }
    })
  }

  onSaveByGroup = () => {
    this.setState(state => ({
      ...state,
      selected: [],
      selectedSecondLang: {},
      selectedThirdLang: {},
      selectedElectives: [],
      isGroupEditOpen: false,
      subjectAllotmentList: state.subjectAllotmentList.map(stud => {
        if (state.selected.includes(stud.studentId)) {
          return {
            ...stud,
            secondLanguage: state.selectedSecondLang,
            thirdLanguage: state.selectedThirdLang,
            subjectGroup: state.selectedSubjectGroup,
            electives: state.selectedElectives,
            updated: true,
          }
        } else {
          return stud
        }
      }),
    }))
  }

  render() {
    const isEditingEnabled =
      this.state.isEditing && this.state.selected.length > 0

    return (
      <>
        <ModalTransition>
          {this.state.isUpdate2ndLangModalOpen && (
            <Modal
            // heading="Update 2nd Language"
            // actions={[
            //   {
            //     text: "Save",
            //     onClick: () => {
            //       this.setState(state => ({
            //         ...state,
            //         isUpdate2ndLangModalOpen: false,
            //         subjectAllotmentList: state.subjectAllotmentList.map(
            //           stud => {
            //             if (state.selected.includes(stud.studentId)) {
            //               if (
            //                 stud.thirdLanguage &&
            //                 state.selectedSecondLang &&
            //                 stud.thirdLanguage.subjectId ===
            //                   state.selectedSecondLang.subjectId
            //               ) {
            //                 return {
            //                   ...stud,
            //                   secondLanguage: state.selectedSecondLang,
            //                   thirdLanguage: { subjectId: -1 },
            //                   updated: true,
            //                 }
            //               } else {
            //                 return {
            //                   ...stud,
            //                   secondLanguage: state.selectedSecondLang,
            //                   updated: true,
            //                 }
            //               }
            //             } else {
            //               return stud
            //             }
            //           }
            //         ),
            //       }))
            //       this.setState({ selected: [] })
            //     },
            //   },
            //   {
            //     text: "cancel",
            //     onClick: () =>
            //       this.setState({ isUpdate2ndLangModalOpen: false }),
            //   },
            // ]}
            >
              <div>
                <div className="fs-25">Update 2nd Language</div>
                <select
                  className="custom-select"
                  value={
                    this.state.selectedSecondLang
                      ? this.state.selectedSecondLang.subjectId
                      : ""
                  }
                  onChange={e => {
                    const value = e.target.value
                    this.setState(state => {
                      const selectedLang = state.subjectsList.language.find(
                        lang => lang.subjectId === value
                      )
                      return {
                        ...state,
                        selectedSecondLang: selectedLang
                          ? {
                              subjectId: selectedLang.subjectId,
                              subjectName: selectedLang.subjectName,
                            }
                          : null,
                      }
                    })
                  }}
                >
                  <option>Select L2</option>
                  {this.state.subjectsList.language.map(subj => (
                    <option value={subj.subjectId} key={subj.subjectId}>
                      {subj.subjectName}
                    </option>
                  ))}
                </select>

                <ModalFooter style={{ background: "none", border: "none" }}>
                  <div className="d-flex">
                    <Button
                      style={{
                        backgroundColor: "#0eb292",
                        border: "none",
                        width: "5rem",
                      }}
                      className="rounded-pill"
                      onClick={this.onSave2Lang}
                    >
                      Save
                    </Button>

                    <Button
                      className="rounded-pill ml-3"
                      style={{
                        backgroundColor: "#DBDBDB",
                        color: "#06B191",
                        border: "none",
                        width: "5rem",
                      }}
                      onClick={() => {
                        this.setState({
                          isUpdate2ndLangModalOpen: false,
                          selectedSecondLang: {},
                        })
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </ModalFooter>
              </div>
            </Modal>
          )}

          {this.state.isUpdate3ndLangModalOpen && (
            <Modal
            // heading="Update 3rd Language"
            // actions={[
            //   {
            //     text: "Save",
            //     onClick: () => {
            //       this.setState(state => ({
            //         ...state,
            //         isUpdate3ndLangModalOpen: false,
            //         subjectAllotmentList: state.subjectAllotmentList.map(
            //           stud => {
            //             if (state.selected.includes(stud.studentId)) {
            //               if (
            //                 stud.secondLanguage &&
            //                 state.selectedThirdLang &&
            //                 stud.secondLanguage.subjectId ===
            //                   state.selectedThirdLang.subjectId
            //               ) {
            //                 return {
            //                   ...stud,
            //                   thirdLanguage: state.selectedThirdLang,
            //                   secondLanguage: { subjectId: -1 },
            //                   updated: true,
            //                 }
            //               } else {
            //                 return {
            //                   ...stud,
            //                   thirdLanguage: state.selectedThirdLang,
            //                   updated: true,
            //                 }
            //               }
            //             } else {
            //               return stud
            //             }
            //           }
            //         ),
            //       }))
            //       this.setState({ selected: [] })
            //     },
            //   },
            //   {
            //     text: "cancel",
            //     onClick: () =>
            //       this.setState({ isUpdate3ndLangModalOpen: false }),
            //   },
            // ]}
            >
              <div className="fs-25">Update 3rd Language</div>
              <select
                className="custom-select"
                value={
                  this.state.selectedThirdLang
                    ? this.state.selectedThirdLang.subjectId
                    : ""
                }
                onChange={e => {
                  const value = e.target.value
                  this.setState(state => {
                    const selectedLang = state.subjectsList.language.find(
                      lang => lang.subjectId === value
                    )
                    return {
                      ...state,
                      selectedThirdLang: selectedLang
                        ? {
                            subjectId: selectedLang.subjectId,
                            subjectName: selectedLang.subjectName,
                          }
                        : null,
                    }
                  })
                }}
              >
                <option>Select L3</option>
                {this.state.subjectsList.language.map(subj => (
                  <option value={subj.subjectId} key={subj.subjectId}>
                    {subj.subjectName}
                  </option>
                ))}
              </select>

              <ModalFooter style={{ background: "none", border: "none" }}>
                <div className="d-flex">
                  <Button
                    style={{
                      backgroundColor: "#0eb292",
                      border: "none",
                      width: "5rem",
                    }}
                    className="rounded-pill"
                    onClick={this.onSave3Lang}
                  >
                    Save
                  </Button>
                  <Button
                    className="rounded-pill ml-3"
                    style={{
                      backgroundColor: "#DBDBDB",
                      color: "#06B191",
                      border: "none",
                      width: "5rem",
                    }}
                    onClick={() =>
                      this.setState({
                        isUpdate3ndLangModalOpen: false,
                        selectedThirdLang: {},
                      })
                    }
                  >
                    Cancel
                  </Button>
                </div>
              </ModalFooter>
            </Modal>
          )}

          {this.state.isUpdateElectiveModalOpen && (
            <Modal
            // actions={[
            //   {
            //     text: "Save",
            //     onClick: () => {
            //       this.setState(state => {
            //         return {
            //           ...state,
            //           isUpdateElectiveModalOpen: false,
            //           subjectAllotmentList: state.subjectAllotmentList.map(
            //             stud => {
            //               if (state.selected.includes(stud.studentId)) {
            //                 return {
            //                   ...stud,
            //                   electives: state.selectedElectives.slice(),
            //                   updated: true,
            //                 }
            //               } else {
            //                 return stud
            //               }
            //             }
            //           ),
            //         }
            //       })
            //       this.setState({ selected: [] })
            //     },
            //   },
            //   {
            //     text: "cancel",
            //     onClick: () =>
            //       this.setState({ isUpdateElectiveModalOpen: false }),
            //   },
            // ]}
            >
              <div className="fs-25">Update Elective</div>
              <select
                className="custom-select"
                onChange={e => {
                  const value = e.target.value
                  const selectedElectives = value
                    ? [
                        this.state.subjectsList.other.find(
                          val => val.subjectId === value
                        ),
                      ]
                    : []
                  this.setState({ selectedElectives })
                }}
              >
                <option>Select Elective</option>
                {this.state.subjectsList.other.map(subj => (
                  <option key={subj.subjectId} value={subj.subjectId}>
                    {subj.subjectName}
                  </option>
                ))}
              </select>
              <ModalFooter style={{ background: "none", border: "none" }}>
                <div className="d-flex">
                  <Button
                    style={{
                      backgroundColor: "#0eb292",
                      border: "none",
                      width: "5rem",
                    }}
                    className="rounded-pill"
                    onClick={this.onSaveElective}
                  >
                    Save
                  </Button>
                  <Button
                    className="rounded-pill ml-3"
                    style={{
                      backgroundColor: "#DBDBDB",
                      color: "#06B191",
                      border: "none",
                      width: "5rem",
                    }}
                    onClick={() =>
                      this.setState({
                        isUpdateElectiveModalOpen: false,
                        selectedElectives: [],
                      })
                    }
                  >
                    Cancel
                  </Button>
                </div>
              </ModalFooter>
            </Modal>
          )}

          {this.state.isSubjectGroupModalOpen && (
            <Modal
              heading="Update Subject Group"
              actions={[
                {
                  text: "Save",
                  onClick: () => {
                    this.setState(state => ({
                      ...state,
                      selected: [],
                      isSubjectGroupModalOpen: false,
                      subjectAllotmentList: state.subjectAllotmentList.map(
                        stud => {
                          if (state.selected.includes(stud.studentId)) {
                            return {
                              ...stud,
                              subjectGroup: state.selectedSubjectGroup,
                              updated: true,
                            }
                          } else {
                            return stud
                          }
                        }
                      ),
                    }))
                    // this.setState({ selected: [] })
                  },
                },
                {
                  text: "cancel",
                  onClick: () =>
                    this.setState({
                      isSubjectGroupModalOpen: false,
                      subjectGroupsList: [],
                    }),
                },
              ]}
            >
              <select
                className="custom-select"
                value={
                  this.state.selectedSubjectGroup
                    ? this.state.selectedSubjectGroup.id
                    : ""
                }
                onChange={e => {
                  const value = e.target.value ? e.target.value : ""
                  this.setState(state => {
                    const selectedSubjectGroup = state.subjectGroupsList.find(
                      lang => lang.id === value
                    )
                    return {
                      ...state,
                      selectedSubjectGroup: selectedSubjectGroup
                        ? {
                            id: selectedSubjectGroup.id,
                            name: selectedSubjectGroup.name,
                          }
                        : null,
                    }
                  })
                }}
              >
                <option>Select Subject Group</option>
                {this.state.subjectGroupsList &&
                  this.state.subjectGroupsList.map(subj => (
                    <option value={subj.id} key={subj.id}>
                      {subj.name}
                    </option>
                  ))}
              </select>
            </Modal>
          )}

          {this.state.isGroupEditOpen && (
            <Modal
              scrollBehavior="outside"
              // heading="Group Update"
              // actions={[
              //   {
              //     text: "Save",
              //     onClick: () => {
              //       this.setState(state => ({
              //         ...state,
              //         isGroupEditOpen: false,
              //         subjectAllotmentList: state.subjectAllotmentList.map(
              //           stud => {
              //             if (state.selected.includes(stud.studentId)) {
              //               return {
              //                 ...stud,
              //                 secondLanguage: state.selectedSecondLang,

              //                 thirdLanguage: state.selectedThirdLang,

              //                 electives: state.selectedElectives,
              //                 subjectGroup: state.selectedSubjectGroup,
              //                 updated: true,
              //               }
              //             } else {
              //               return stud
              //             }
              //           }
              //         ),
              //       }))
              //       this.setState({ selected: [] })
              //     },
              //   },
              //   {
              //     text: "Cancel",
              //     onClick: () => this.setState({ isGroupEditOpen: false }),
              //   },
              // ]}
            >
              <div className="fs-25">Group Update</div>
              <div className="d-flex mr-2 align-items-center justify-content-center mb-3">
                <div className="w-50">Update 2nd Language</div>
                <select
                  className="custom-select "
                  value={
                    this.state.selectedSecondLang
                      ? this.state.selectedSecondLang.subjectId
                      : ""
                  }
                  onChange={e => {
                    const value = e.target.value
                    this.setState(state => {
                      const selectedLang = state.subjectsList.language.find(
                        lang => lang.subjectId === value
                      )
                      return {
                        ...state,
                        selectedSecondLang: selectedLang
                          ? {
                              subjectId: selectedLang.subjectId,
                              subjectName: selectedLang.subjectName,
                            }
                          : null,
                      }
                    })
                  }}
                >
                  <option>Select L2</option>
                  {this.state.subjectsList.language
                    .filter(val =>
                      this.state.selectedThirdLang
                        ? val.subjectId !==
                          this.state.selectedThirdLang.subjectId
                        : true
                    )
                    .map(subj => (
                      <option value={subj.subjectId} key={subj.subjectId}>
                        {subj.subjectName}
                      </option>
                    ))}
                </select>
              </div>
              <div className="d-flex mr-2 align-items-center justify-content-center mb-3">
                <div className="w-50">Update 3rd Language</div>
                <select
                  className="custom-select"
                  value={
                    this.state.selectedThirdLang
                      ? this.state.selectedThirdLang.subjectId
                      : ""
                  }
                  onChange={e => {
                    const value = e.target.value
                    this.setState(state => {
                      const selectedLang = state.subjectsList.language.find(
                        lang => lang.subjectId === value
                      )
                      return {
                        ...state,
                        selectedThirdLang: selectedLang
                          ? {
                              subjectId: selectedLang.subjectId,
                              subjectName: selectedLang.subjectName,
                            }
                          : null,
                      }
                    })
                  }}
                >
                  <option>Select L3</option>
                  {this.state.subjectsList.language
                    .filter(val =>
                      this.state.selectedSecondLang
                        ? val.subjectId !==
                          this.state.selectedSecondLang.subjectId
                        : true
                    )
                    .map(subj => (
                      <option value={subj.subjectId} key={subj.subjectId}>
                        {subj.subjectName}
                      </option>
                    ))}
                </select>
              </div>
              <div className="d-flex mr-2 align-items-center  mb-3">
                <div className="w-50">Select Elective</div>
                {/* <select
                  className="custom-select"
                  onChange={e => {
                    const value = e.target.value
                    myLog("value in group",value)
                    const selectedElectives = value
                      ? [
                          this.state.subjectsList.other.find(
                            val => val.subjectId === value
                          ),
                        ]
                      : []
                    this.setState({ selectedElectives }, ()=>myLog("selected electives",selectedElectives))
                  }}
                >
                  <option>Select Elective</option>
                  {this.state.subjectsList.other.map(subj => (
                    <option key={subj.subjectId} value={subj.subjectId}>
                      {subj.subjectName}
                    </option>
                  ))}
                </select> */}
                <div style={{ width: "40rem" }}>
                  <ReactSelect
                    isMulti
                    onChange={selected => {
                      // myLog("value in group", selected)
                      let newElectives = selected.map(select => ({
                        subjectId: select.value,
                        subjectName: select.label,
                      }))

                      // const selectedElectives = this.state.subjectsList ?
                      //       [this.state.subjectsList.other.find(
                      //         val => val.subjectId == selected.map(item=>(item.value))
                      //       )  ] : []
                      // myLog("new electives", newElectives)
                      this.setState({ selectedElectives: newElectives })
                      // () =>
                      //  myLog(
                      //    "selected electives",
                      //    this.state.selectedElectives
                      //  )
                    }}
                    value={
                      this.state.selectedElectives
                        ? this.state.selectedElectives.map(subj => ({
                            value: subj.subjectId,
                            label: subj.subjectName,
                          }))
                        : []
                    }
                    options={this.state.subjectsList.other.map(subj => ({
                      value: subj.subjectId,
                      label: subj.subjectName,
                    }))}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    allowSelectAll={true}
                    components={{
                      Option,
                    }}
                    isClearable={false}
                    styles={this.styles}
                  />
                </div>
              </div>
              {this.state.subjectGroupsList && (
                <div className="d-flex mr-2 align-items-center justify-content-center mb-3">
                  <div className="w-50">Select Subject Group</div>
                  <select
                    className="custom-select"
                    value={
                      this.state.selectedSubjectGroup
                        ? this.state.selectedSubjectGroup.id
                        : ""
                    }
                    onChange={e => {
                      const value = e.target.value || ""
                      this.setState(state => {
                        const selectedSubjectGroup = state.subjectGroupsList.find(
                          lang => lang.id === value
                        )
                        return {
                          ...state,
                          selectedSubjectGroup: selectedSubjectGroup
                            ? {
                                id: selectedSubjectGroup.id,
                                name: selectedSubjectGroup.name,
                              }
                            : null,
                        }
                      })
                    }}
                  >
                    <option>Select Subject Group</option>
                    {this.state.subjectGroupsList &&
                      this.state.subjectGroupsList.map(subj => (
                        <option value={subj.id} key={subj.id}>
                          {subj.name}
                        </option>
                      ))}
                  </select>
                </div>
              )}

              <ModalFooter style={{ background: "none", border: "none" }}>
                <div className="d-flex">
                  <Button
                    style={{
                      backgroundColor: "#0eb292",
                      border: "none",
                      width: "5rem",
                    }}
                    className="rounded-pill"
                    onClick={this.onSaveByGroup}
                  >
                    Save
                  </Button>
                  <Button
                    className="rounded-pill ml-3"
                    style={{
                      backgroundColor: "#DBDBDB",
                      color: "#06B191",
                      border: "none",
                      width: "5rem",
                    }}
                    onClick={() => {
                      this.setState({
                        isGroupEditOpen: false,
                        selectedSecondLang: {},
                        selectedThirdLang: {},
                        selectedElectives: [],
                        subjectGroupsList: [],
                      })
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </ModalFooter>
            </Modal>
          )}
        </ModalTransition>

        <AppWrapper
          teacherDashboard
          showTeacherThree
          showTeacherLogo
          screenName="Subject Allotment"
        >
          {/* <h4 className="text-dark font-weight-bold mb-3">Subject Allotment</h4> */}
          {this.state.subjectAllotmentList ? (
            this.state.isShowLoading ? (
              <div
                className="d-flex justify-content-center"
                style={{ position: "absolute", left: "50%", top: "50%" }}
              >
                <HashLoader
                  sizeUnit="px"
                  size={35}
                  color="#084353"
                  loading={this.state.isShowLoading}
                />
              </div>
            ) : (
              <div className="card px-3 py-3">
                <div className="d-flex align-items-center mb-3">
                  {this.state.isEditing ? (
                    <div className="ml-auto">
                      <Button
                        style={{
                          backgroundColor: "#DBDBDB",
                          color: "#06B191",
                          border: "none",
                        }}
                        className="px-4 rounded-pill ml-auto"
                        color="dark"
                        onClick={this.onCancel}
                      >
                        <span>cancel</span>
                      </Button>

                      <Button
                        className="px-4 rounded-pill ml-3"
                        style={{ backgroundColor: "#0eb292", border: "none" }}
                        onClick={this.onSave}
                        disabled={this.state.isSaving}
                      >
                        {this.state.isSaving ? (
                          <HashLoader
                            sizeUnit="px"
                            size={18}
                            color="#fff"
                            loading={this.state.isSaving}
                          />
                        ) : (
                          <>
                            <span>Save</span>
                          </>
                        )}
                      </Button>
                    </div>
                  ) : (
                    <Button
                      className="px-4 rounded-pill ml-auto"
                      style={{ backgroundColor: "#094353" }}
                      onClick={() => this.setState({ isEditing: true })}
                      disabled={!this.state.subjectAllotmentList}
                    >
                      <span>Edit</span>
                    </Button>
                  )}
                </div>

                {this.state.isEditing && (
                  <div className="d-flex align-items-center mb-3">
                    <Button
                      color="light"
                      className="mr-2 px-3 py-1 border-secondary text-secondary"
                      disabled={!isEditingEnabled}
                      onClick={() =>
                        this.setState({ isUpdate2ndLangModalOpen: true })
                      }
                    >
                      Update 2nd language
                    </Button>

                    <Button
                      color="light"
                      className="mr-2 px-3 py-1 border-secondary text-secondary"
                      disabled={!isEditingEnabled}
                      onClick={() =>
                        this.setState({ isUpdate3ndLangModalOpen: true })
                      }
                    >
                      Update 3rd language
                    </Button>
                    <Button
                      color="light"
                      className="mr-2 px-3 py-1 border-secondary text-secondary"
                      disabled={!isEditingEnabled}
                      onClick={() =>
                        this.setState({ isUpdateElectiveModalOpen: true })
                      }
                    >
                      Update Elective
                    </Button>
                    {this.state.subjectGroupsList && (
                      <Button
                        color="light"
                        className="mr-2 px-3 py-1 border-secondary text-secondary"
                        disabled={!isEditingEnabled}
                        onClick={() =>
                          this.setState({ isSubjectGroupModalOpen: true })
                        }
                      >
                        Update Subject group
                      </Button>
                    )}
                    <Button
                      color="light"
                      className="mr-2 px-3 py-1 border-secondary text-secondary"
                      disabled={!isEditingEnabled}
                      onClick={() => this.setState({ isGroupEditOpen: true })}
                    >
                      Group Update
                    </Button>
                  </div>
                )}

                <table className="table">
                  <thead className="bg-theme-light text-white">
                    <tr>
                      {this.state.isEditing && (
                        <th
                          className="px-3 py-2"
                          style={{ verticalAlign: "middle" }}
                        >
                          <FormGroup>
                            <Input
                              type="checkbox"
                              className="subject-checkbox m-auto"
                              checked={
                                this.state.subjectAllotmentList.length ===
                                this.state.selected.length
                              }
                              onChange={e => {
                                if (e.target.checked) {
                                  this.setState({
                                    selected: this.state.subjectAllotmentList.map(
                                      ({ studentId }) => studentId
                                    ),
                                  })
                                } else {
                                  this.setState({ selected: [] })
                                }
                              }}
                            />
                          </FormGroup>
                        </th>
                      )}
                      <th>Student Roll No</th>
                      <th>Student Name</th>
                      {this.state.subjectGroupsList &&
                        this.state.subjectGroupsList.length > 0 && (
                          <th>Subject Group</th>
                        )}
                      {this.state.subjectAllotmentList &&
                        this.state.subjectAllotmentList.length > 0 &&
                        this.state.subjectAllotmentList[0].coreSubjects && (
                          <th>Core Subjects</th>
                        )}
                      <th>2nd Language L2</th>
                      <th>3rd Language L3</th>
                      <th className={this.state.isEditing ? "text-center" : ""}>
                        Electives
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.subjectAllotmentList.map((item, index) => {
                      return (
                        <tr key={item.studentId}>
                          {this.state.isEditing && (
                            <td className="px-3 py-2">
                              <Input
                                type="checkbox"
                                className="m-0 ml-2 mt-2 subject-checkbox"
                                checked={
                                  !!this.state.selected.includes(item.studentId)
                                }
                                onChange={e => {
                                  if (e.target.checked) {
                                    this.setState({
                                      selected: [
                                        item.studentId,
                                        ...this.state.selected,
                                      ],
                                    })
                                  } else {
                                    this.setState({
                                      selected: this.state.selected.filter(
                                        str => str !== item.studentId
                                      ),
                                    })
                                  }
                                }}
                              />
                            </td>
                          )}
                          <td>{item.registerRollNumber}</td>
                          <td>{item.studentName || item.fullName}</td>
                          {this.state.higherSecondary &&
                            this.state.subjectGroupsList &&
                            this.state.subjectGroupsList.length > 0 && (
                              <td>
                                {this.state.isEditing ? (
                                  <select
                                    className="custom-select"
                                    value={
                                      item.subjectGroup
                                        ? item.subjectGroup.id
                                        : ""
                                    }
                                    onChange={e => {
                                      const value = e.target.value
                                      this.setState(state => {
                                        const subjectAllotmentList = JSON.parse(
                                          JSON.stringify(
                                            state.subjectAllotmentList
                                          )
                                        )
                                        let subjectGroup = null
                                        if (value !== "Select Subject Group") {
                                          const {
                                            id,
                                            name,
                                          } = state.subjectGroupsList.find(
                                            lang => lang.id === value
                                          )
                                          subjectGroup = {
                                            id,
                                            name,
                                          }
                                        }
                                        subjectAllotmentList[index] = {
                                          ...subjectAllotmentList[index],
                                          subjectGroup,
                                          updated: true,
                                        }
                                        return {
                                          ...state,
                                          subjectAllotmentList,
                                        }
                                      })
                                    }}
                                  >
                                    <option>Select Subject Group</option>
                                    {this.state.subjectGroupsList.map(sg => (
                                      <option key={sg.id} value={sg.id}>
                                        {sg.name}
                                      </option>
                                    ))}
                                  </select>
                                ) : item.subjectGroup ? (
                                  item.subjectGroup.name
                                ) : (
                                  "Not Assigned"
                                )}
                              </td>
                            )}
                          {item.coreSubjects && (
                            <td className="text-muted">
                              {item.coreSubjects
                                ? item.coreSubjects
                                    .map(subj => subj.subjectName.substr(0, 3))
                                    .join(", ")
                                : "Not Assigned"}
                            </td>
                          )}
                          <td>
                            {this.state.isEditing ? (
                              <select
                                className={`custom-select ${
                                  (item.secondLanguage
                                    ? item.secondLanguage.subjectId
                                    : "") ===
                                  (item.thirdLanguage
                                    ? item.thirdLanguage.subjectId
                                    : "a")
                                    ? "border border-danger"
                                    : ""
                                }`}
                                value={
                                  item.secondLanguage
                                    ? item.secondLanguage.subjectId
                                    : ""
                                }
                                data-value={
                                  item.secondLanguage
                                    ? item.secondLanguage.subjectId
                                    : ""
                                }
                                onChange={e => {
                                  const value = e.target.value
                                  this.setState(state => {
                                    const subjectAllotmentList = JSON.parse(
                                      JSON.stringify(state.subjectAllotmentList)
                                    )
                                    let secondLanguage = null
                                    if (value !== "Select L2") {
                                      // const {secondOne} = state
                                      state.secondOne = value
                                      const {
                                        subjectId,
                                        subjectName,
                                      } = state.subjectsList.language.find(
                                        lang => lang.subjectId === value
                                      )
                                      secondLanguage = {
                                        subjectId,
                                        subjectName,
                                      }
                                      this.checkLanguage()
                                    }
                                    subjectAllotmentList[index] = {
                                      ...subjectAllotmentList[index],
                                      secondLanguage,
                                      updated: true,
                                    }
                                    myLog("State in l2", state)
                                    return {
                                      ...state,
                                      subjectAllotmentList,
                                    }
                                  })
                                }}
                              >
                                <option>Select L2</option>
                                {this.state.subjectsList.language
                                  // .filter(subj =>
                                  //   item.thirdLanguage
                                  //     ? subj.subjectId !==
                                  //       item.thirdLanguage.subjectId
                                  //     : true
                                  // )
                                  .map(subj => (
                                    <option
                                      key={subj.subjectId}
                                      value={subj.subjectId}
                                    >
                                      {subj.subjectName}
                                    </option>
                                  ))}
                              </select>
                            ) : item.secondLanguage ? (
                              item.secondLanguage.subjectName
                            ) : (
                              "Not assigned"
                            )}
                          </td>
                          <td>
                            {this.state.isEditing ? (
                              <select
                                className={`custom-select ${
                                  (item.secondLanguage
                                    ? item.secondLanguage.subjectId
                                    : "") ===
                                  (item.thirdLanguage
                                    ? item.thirdLanguage.subjectId
                                    : "a")
                                    ? "border border-danger"
                                    : ""
                                }`}
                                value={
                                  item.thirdLanguage
                                    ? item.thirdLanguage.subjectId
                                    : ""
                                }
                                data-value={
                                  item.thirdLanguage
                                    ? item.thirdLanguage.subjectId
                                    : ""
                                }
                                onChange={e => {
                                  const value = e.target.value
                                  this.setState(state => {
                                    const subjectAllotmentList = JSON.parse(
                                      JSON.stringify(state.subjectAllotmentList)
                                    )
                                    let thirdLanguage = null
                                    if (value !== "Select L3") {
                                      state.thirdOne = value
                                      const {
                                        subjectId,
                                        subjectName,
                                      } = state.subjectsList.language.find(
                                        lang => lang.subjectId === value
                                      )
                                      thirdLanguage = {
                                        subjectId,
                                        subjectName,
                                      }
                                      this.checkLanguage()
                                    }
                                    subjectAllotmentList[index] = {
                                      ...subjectAllotmentList[index],
                                      thirdLanguage,
                                      updated: true,
                                    }
                                    return { ...state, subjectAllotmentList }
                                  })
                                }}
                              >
                                <option>Select L3</option>
                                {this.state.subjectsList.language
                                  // .filter(subj =>
                                  //   item.secondLanguage
                                  //     ? subj.subjectId !==
                                  //       item.secondLanguage.subjectId
                                  //     : true
                                  // )
                                  .map(subj => (
                                    <option
                                      key={subj.subjectId}
                                      value={subj.subjectId}
                                    >
                                      {subj.subjectName}
                                    </option>
                                  ))}
                              </select>
                            ) : item.thirdLanguage ? (
                              item.thirdLanguage.subjectName
                            ) : (
                              "Not assigned"
                            )}
                          </td>
                          <td
                            className={
                              this.state.isEditing
                                ? "checkbox-td"
                                : "d-flex flex-wrap"
                            }
                          >
                            {this.state.isEditing ? (
                              <ReactSelect
                                isMulti
                                isClearable={false}
                                onChange={selected =>
                                  this.setState(state => {
                                    const subjectAllotmentList = JSON.parse(
                                      JSON.stringify(state.subjectAllotmentList)
                                    )
                                    let electives = []
                                    if (selected) {
                                      const newElectives = selected.map(
                                        select => ({
                                          subjectId: select.value,
                                          subjectName: select.label,
                                        })
                                      )
                                      electives = newElectives
                                    }
                                    subjectAllotmentList[index] = {
                                      ...subjectAllotmentList[index],
                                      electives,
                                      updated: true,
                                    }
                                    return { ...state, subjectAllotmentList }
                                  })
                                }
                                value={
                                  item.electives
                                    ? item.electives.map(subj => ({
                                        value: subj.subjectId,
                                        label: subj.subjectName,
                                      }))
                                    : []
                                }
                                options={this.state.subjectsList.other.map(
                                  subj => ({
                                    value: subj.subjectId,
                                    label: subj.subjectName,
                                  })
                                )}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                allowSelectAll={true}
                                components={{
                                  Option,
                                }}
                                styles={this.styles}
                              />
                            ) : item.electives ? (
                              item.electives.length > 0 ? (
                                item.electives.map(value => (
                                  <>
                                    <div className="electives">
                                      {value.subjectName}
                                    </div>
                                  </>
                                ))
                              ) : (
                                "Not assigned"
                              )
                            ) : (
                              "Not assigned"
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            )
          ) : (
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: 150 }}
            >
              {/* <HashLoader
                sizeUnit={"px"}
                size={35}
                color={"#084353"}
                loading={this.state.isShowLoading}
              /> */}
              No Data found
            </div>
          )}
        </AppWrapper>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    subjectAllotmentList:
      state.teacherSubjectAllotmentState.subjectAllotmentList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getSubjectAllotmentList,
      getSubjectGroupsList,
      getSubjectsList,
      saveSubjectAllotment,
      showNotification,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(SubjectAllotment)
