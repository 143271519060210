import React from "react"

function Step2({ downloadGPPdf, setStep }) {
  return (
    <>
      <div className="fs-18 fw-600 text-center">
        Question paper generated successfullly
      </div>

      <div className="d-flex align-items-center justify-content-center gap-18px mt-4">
        <button
          className="px-5 py-1 fs-16 fw-500 bg-E1E1E1 clr-666 br-8px"
          onClick={() => setStep(3)}
        >
          PDF Preview
        </button>

        <button
          className="px-5 py-2 fs-16 fw-500 bg-50AE93 text-white br-8px"
          onClick={downloadGPPdf}
        >
          Download
        </button>
      </div>
    </>
  )
}

export default Step2
