/*************************************************
 * RedInk
 * @exports
 * @class AttendanceDetailsAction.js
 * @extends Component
 * @author vijayakumar // on 05/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { ACTION_TYPES } from "../types"

export const saveAdminAttendanceDetailsInStore = attendanceDetails => {
  return {
    type: ACTION_TYPES.ADMIN_ATTENDANCE_DETAIL,
    payload: attendanceDetails,
  }
}

export const getAttendanceDetails = (date, callBack) => {
  return async dispatch => {
    try {
      myLog("====getAttendanceDetails===::::", date)
      let object = {
        date,
      }
      // let response = {
      //   attendanceDetails: {
      //     schoolAttendanceDetail: {
      //       absentCount: "40",
      //       presentCount: "100",
      //       unmarkedCount: "5",
      //     },
      //     basicInfo: {
      //       classCount: 3,
      //       studentCount: 145,
      //     },
      //     classes: [
      //       {
      //         name: "Class LKG-A",
      //         absentPercentage: "10",
      //         presentPercentage: "80",
      //         unmarkedPercentage: "10",
      //         absentCount: "10",
      //         presentCount: "80",
      //         unmarkedCount: "10",
      //         absentPercentageStatus: "decreased",
      //         presentPercentageStatus: "increased",
      //         unmarkedPercentageStastus: "decreased",
      //         totalStudents: 40,
      //       },
      //       {
      //         name: "Class LKG-B",
      //         absentPercentage: "10",
      //         presentPercentage: "70",
      //         unmarkedPercentage: "20",
      //         absentCount: "10",
      //         presentCount: "70",
      //         unmarkedCount: "20",
      //         absentPercentageStatus: "decreased",
      //         presentPercentageStatus: "increased",
      //         unmarkedPercentageStastus: "increased",
      //         totalStudents: 55,
      //       },
      //       {
      //         name: "Class LKG-C",
      //         absentPercentage: "10",
      //         presentPercentage: "80",
      //         unmarkedPercentage: "10",
      //         absentCount: "10",
      //         presentCount: "80",
      //         unmarkedCount: "10",
      //         absentPercentageStatus: "decreased",
      //         presentPercentageStatus: "increased",
      //         unmarkedPercentageStastus: "decreased",
      //         totalStudents: 50,
      //       },
      //     ],
      //   },
      // }
      // myLog("====getAttendanceDetails===::::", response)
      // dispatch(saveAdminAttendanceDetailsInStore(response.attendanceDetails))
      // callBack(true, response.attendanceDetails)
      const response = await Client.get(
        URL.GET_ATTENDANCE_DETAILS,
        object,
        true
      )
      if (response) {
        myLog("====getAttendanceDetails RESPONSE===::::", response)
        dispatch(saveAdminAttendanceDetailsInStore(response.attendanceDetails))
        callBack(true, response.attendanceDetails)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getAttendanceDetails ERROR===????", error)
    }
  }
}
