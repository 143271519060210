/*************************************************
 * RedInk
 * @exports
 * @class CommonAction.js
 * @extends Component
 * @author Sasidharan // on 05/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { ACTION_TYPES } from "../types"
import { showNotification } from "../CommonAction"
import {
  SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"

export const saveSchoolsListDetailsInStore = SchoolsListDetails => {
  return {
    type: ACTION_TYPES.SUPER_ADMIN_SCHOOL_LIST_DETAILS,
    payload: SchoolsListDetails,
  }
}

export const saveDisplaySchoolNameInStore = displaySchoolName => {
  return {
    type: ACTION_TYPES.SUPER_ADMIN_DISPLAY_SCHOOL_NAME_DETAILS,
    payload: displaySchoolName,
  }
}

export const saveSchoolsDetailsInStore = SchoolsDetails => {
  return {
    type: ACTION_TYPES.SUPER_ADMIN_SCHOOL_DETAILS,
    payload: SchoolsDetails,
  }
}

export const getSchoolsListDetails = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_SCHOOL_LIST_DETAILS, null, true)
      myLog("====getSchoolsListDetails RESPONSE===::::", response)
      if (response) {
        myLog("====getSchoolsListDetails RESPONSE===::::", response.schoolsList)
        dispatch(saveSchoolsListDetailsInStore(response.schoolsList))
        dispatch(saveDisplaySchoolNameInStore(response.schoolsList[0]))
        callBack(true, response.schoolsList)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getSchoolsListDetails ERROR===????", error)
    }
  }
}

export const getSchoolDetails = callBack => {
  return async () => {
    try {
      const response = await Client.get(URL.GET_SCHOOL_DETAILS, null, true)
      if (response.schoolDetails) {
        myLog("====getSchoolDetails RESPONSE===::::", response)
        callBack(true, response.schoolDetails)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getSchoolDetails ERROR===????", error)
    }
  }
}

export const saveSchoolDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.post(URL.SAVE_SCHOOL_DETAILS, body, true)
      if (response.responseCode === 0) {
        myLog("====saveSchoolDetails RESPONSE===::::", response)
        // dispatch(getSchoolDetails(()=>{}));
        dispatch(getSchoolsListDetails(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveSchoolDetails ERROR===????", error)
    }
  }
}
export const updateSchoolDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.post(URL.UPDATE_SCHOOL_DETAILS, body, true)
      if (response.responseCode === 0) {
        myLog("====updateSchoolDetails RESPONSE===::::", response)
        // dispatch(getSchoolDetails(()=>{}));
        dispatch(getSchoolsListDetails(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====updateSchoolDetails ERROR===????", error)
    }
  }
}
export const deleteSchoolDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.DELETE_SCHOOL_DETAILS,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteSchoolDetails RESPONSE===::::", response)

        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteSchoolDetails ERROR===????", error)
    }
  }
}

export const deleteAssignedSchoolAdminDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.DELETE_ASSIGNED_BRANCH_ADMIN_DETAILS,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteAssignedClassDetails RESPONSE===::::", response)
        dispatch(getSchoolDetails(() => {}))
        dispatch(getSchoolsListDetails(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteAssignedClassDetails ERROR===????", error)
    }
  }
}
