import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import AppWrapper from "../Wrapper"
import { Modal } from "reactstrap"
// import Skeleton from "react-loading-skeleton"
import {
  getQuizReport,
  getQuestionWiseQuizReport,
} from "../../../actions/teacher/QuizAction"
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip"
import { HashLoader } from "react-spinners"
import { myLog } from "../../../utils/Utility"

const questionTypes = [
  { value: "TRUE_OR_FALSE", label: "True or false" },
  { value: "MULTIPLE_CHOICE", label: "Multiple choice" },
  { value: "MULTIPLE_ANSWER", label: "Multiple answer" },
  { value: "SHORT_ANSWER", label: "Short answer" },
  { value: "LONG_ANSWER", label: "Long answer" },
  { value: "MATCHING", label: "Matching" },
  { value: "FILL_IN_THE_BLANKS", label: "Fill in the blanks" },
  { value: "SEQUENCING", label: "Sequencing" },
]

const CORRECT = "CORRECT"
const WRONG = "WRONG"
const PARTIAL = "PARTIAL"
const UNGRADED = "UNGRADED"

const correctColor = {
  CORRECT: {
    color: "#07af8f96",
  },
  PARTIAL: {
    color: "#e49f2680",
  },
  WRONG: {
    color: "#ec365cb5",
  },
  UNGRADED: {
    color: "#605f5f66",
  },
}

class AssessmentReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      quizAssignId: "1ab20c6c-804b-43e3-b3bd-aa89f7ebc3b8",
      isShowLoading: null,
      isShowTopicWiseModal: false,
      isShowQuestionWiseModalOpen: false,
      questionData: null,
      report: null,
      className: "",
      sectionName: "",
      subjectName: "",
      title: "",
      instruction: "",
      isOnline: "",
      totalMark: "",
      highestMark: null,
      highestPercentage: null,
      averageMark: null,
      averagePercentage: null,
      lowestMark: null,
      lowestPercentage: null,
      topPerformers: [],
      weekPerformers: [],
      gradedCount: null,
      ungradedCount: null,
      unsubmittedCount: null,
      absentees: [],
      questionWiseAnalysis: [],
      questionWiseAnalysisCount: 10,
      topicWiseAnalysis: [],
      weekestTopicName: null,
      weekestTopicPercentage: null,
      questionWiseModalIndex: null,
      questionWiseOption: "",
    }
  }

  componentDidMount() {
    const { quizAssignId } = this.props.location.state
    this.setState({ isShowLoading: true })
    this.props.getQuizReport(quizAssignId, (success, data) => {
      if (success) {
        const {
          section,
          subject,
          submissionType,
          quizSet,
          markRate,
          topPerformers,
          weekPerformers,
          gradedStatus,
          absentees,
          strongTopics,
          weakTopics,
        } = data

        let strongestTopicName = null
        let strongestTopicPercentage = null
        if (strongTopics.length > 0) {
          const strongestTopic = strongTopics.sort(
            (a, b) => a.correctRatio > b.correctRatio
          )
          strongestTopicName = strongestTopic[0].topic.name
          strongestTopicPercentage = strongestTopic[0].correctRatio
        }

        let weakestTopicName = null
        let weakestTopicPercentage = null

        if (weakTopics.length > 0) {
          const weakestTopic = weakTopics.sort(
            (a, b) => a.correctRatio - b.correctRatio
          )
          weakestTopicName = weakestTopic[0].topic.name
          weakestTopicPercentage = weakestTopic[0].correctRatio
        }

        const { title, instruction, totalMark } = quizSet
        const { className, sectionName } = section
        const { subjectName } = subject
        const {
          graded: gradedCount,
          ungraded: ungradedCount,
          unsubmitted: unsubmittedCount,
        } = gradedStatus
        const { highest, average, lowest } = markRate
        const { mark: highestMark, percentage: highestPercentage } = highest
        const { mark: averageMark, percentage: averagePercentage } = average
        const { mark: lowestMark, percentage: lowestPercentage } = lowest
        this.setState({
          quizAssignId,
          report: data,
          className,
          sectionName,
          subjectName,
          isOnline: submissionType === "ONLINE",
          title,
          instruction,
          totalMark,
          highestMark,
          highestPercentage: this.numberOrDash(highestPercentage),
          averageMark,
          averagePercentage: this.numberOrDash(averagePercentage),
          lowestMark,
          lowestPercentage: this.numberOrDash(lowestPercentage),
          topPerformers,
          weekPerformers,
          gradedCount,
          ungradedCount,
          unsubmittedCount,
          absentees,
          strongTopics,
          weakTopics,
          strongestTopicName,
          strongestTopicPercentage,
          weakestTopicName,
          weakestTopicPercentage,
        })

        this.props.getQuestionWiseQuizReport(quizAssignId, (success, data) => {
          if (success) {
            const { questionWiseAnalysis, topicWiseAnalysis } = data

            // let weekestTopicName = null
            // let weekestTopicPercentage = null
            // if (topicWiseAnalysis.length > 0) {
            //   weekestTopicName = topicWiseAnalysis[0].topic.name
            //   weekestTopicPercentage = topicWiseAnalysis[0].correctRatio
            // }
            this.setState({
              questionWiseAnalysis,
              questionWiseAnalysisCount: questionWiseAnalysis.length,
              topicWiseAnalysis,
              // weekestTopicName,
              // weekestTopicPercentage,
              isShowLoading: false,
            })
          }
        })
      }
      /* else: redirect to /teacher/assessment */
    })
  }

  numberOrDash = number => {
    if (number === null || number === undefined) return " - "
    return number
  }

  renderPerformers = (performer, index) => {
    const { id, gradedMark, student } = performer
    const { gender, studentName, fullName } = student
    const { totalMark } = this.state
    // const markRate = (Math.round((gradedMark / totalMark) * 10) / 10) * 100
    const markRate = ((gradedMark / totalMark) * 100).toFixed(1)
    return (
      <div
        key={id}
        className="bg-light border mr-2 d-flex flex-column align-items-center justify-content-center"
        style={{
          minWidth: 180,
          position: "relative",
          marginTop: 35,
          borderRadius: 18,
          padding: "3rem 1rem",
        }}
      >
        <div
          className="rounded-pill border bg-light"
          style={{
            height: 70,
            width: 70,
            position: "absolute",
            top: -35,
            left: "calc(50% - 35px)",
          }}
        >
          <img
            src={
              gender === "M"
                ? require("../../../assets/images/boy.png")
                : require("../../../assets/images/girl.png")
            }
            className="rounded-pill"
            style={{
              height: "calc(100% - 0.25rem)",
              width: "calc(100% - 0.25rem)",
              margin: "0.125rem",
            }}
            alt=""
          />
        </div>
        <h5 className="text-brand text-center mt-4 mb-2">
          {studentName || fullName}
        </h5>
        <div className="mt-1">
          <h3 className="text-brand" style={{ display: "inline" }}>
            {gradedMark === null ? "-" : gradedMark}
          </h3>
          <span className="text-brand">{` (${markRate}%)`}</span>
        </div>
      </div>
    )
  }

  renderAbsentees = (performer, index) => {
    const { grade, gender, studentName, fullName } = performer
    return (
      <div
        key={grade}
        className="bg-light border mr-2 d-flex flex-column align-items-center justify-content-center"
        style={{
          minWidth: 180,
          position: "relative",
          marginTop: 35,
          borderRadius: 18,
          padding: "3rem 1rem",
        }}
      >
        <div
          className="rounded-pill border bg-light"
          style={{
            height: 70,
            width: 70,
            position: "absolute",
            top: -35,
            left: "calc(50% - 35px)",
          }}
        >
          <img
            src={
              gender === "M"
                ? require("../../../assets/images/boy.png")
                : require("../../../assets/images/girl.png")
            }
            className="rounded-pill"
            style={{
              height: "calc(100% - 0.25rem)",
              width: "calc(100% - 0.25rem)",
              margin: "0.125rem",
            }}
            alt=""
          />
        </div>
        <h5 className="text-brand text-center mt-4 mb-2">
          {studentName || fullName}
        </h5>
      </div>
    )
  }

  calcPercentage = (a, b) => {
    if (b === 0) return 0
    return Math.round((a * 1000) / b) / 10
  }

  renderQuestionwise = (data, i) => {
    const {
      question,
      totalQuestionsCountPrevSection,
      correctStudents,
      incorrectStudents,
      partialCorrects,
      ungradedStudents,
    } = data
    const totalStudentCount =
      correctStudents.length +
      incorrectStudents.length +
      partialCorrects.length +
      ungradedStudents.length
    const { id } = question
    const questionLabelWidth =
      this.state.questionWiseAnalysisCount > 9
        ? "35px"
        : this.state.questionWiseAnalysisCount > 90
        ? "42px"
        : "auto"
    return (
      <li
        key={id}
        className="py-3 px-4 m-0 d-flex align-items-center"
        style={{ borderBottom: "1px solid #eaeaea" }}
      >
        <div
          className="text-dark font-weight-bold"
          style={{ width: questionLabelWidth }}
        >
          Q{" "}
          {parseInt(question.index + totalQuestionsCountPrevSection || "0") + 1}
        </div>
        <div
          className="rounded mx-3 d-flex"
          style={{
            height: 20,
            flex: 1,
            overflow: "hidden",
          }}
        >
          <div
            id={`que-${i}-incorrect`}
            style={{
              height: "100%",
              width: `${this.calcPercentage(
                incorrectStudents.length,
                totalStudentCount
              )}%`,
              backgroundColor: correctColor[WRONG].color,
            }}
          />
          <UncontrolledTooltip placement="top" target={`que-${i}-incorrect`}>
            Wrong ({incorrectStudents.length})
          </UncontrolledTooltip>
          <div
            id={`que-${i}-partial`}
            style={{
              height: "100%",
              width: `${this.calcPercentage(
                partialCorrects.length,
                totalStudentCount
              )}%`,
              backgroundColor: correctColor[PARTIAL].color,
            }}
          />
          <UncontrolledTooltip placement="top" target={`que-${i}-partial`}>
            Partially Correct ({partialCorrects.length})
          </UncontrolledTooltip>
          <div
            id={`que-${i}-correct`}
            style={{
              height: "100%",
              width: `${this.calcPercentage(
                correctStudents.length,
                totalStudentCount
              )}%`,
              backgroundColor: correctColor[CORRECT].color,
            }}
          />
          <UncontrolledTooltip placement="top" target={`que-${i}-correct`}>
            Correct ({correctStudents.length})
          </UncontrolledTooltip>
          <div
            id={`que-${i}-ungraded`}
            style={{
              height: "100%",
              width: `${this.calcPercentage(
                ungradedStudents.length,
                totalStudentCount
              )}%`,
              backgroundColor: correctColor[UNGRADED].color,
            }}
          />
          <UncontrolledTooltip placement="top" target={`que-${i}-ungraded`}>
            Ungraded ({ungradedStudents.length})
          </UncontrolledTooltip>
        </div>
        <button
          className="btn btn-sm btn-light py-05 px-3 rounded-pill"
          disabled={this.state.questionWiseModalIndex === i}
          onClick={() =>
            this.setState(
              {
                questionData: data,
              },
              () => {
                this.setState({
                  isShowQuestionWiseModalOpen: true,
                  questionWiseModalIndex: i,
                  questionWiseOption:
                    incorrectStudents.length > 0
                      ? "WRONG"
                      : partialCorrects.length > 0
                      ? "PARTIAL"
                      : "CORRECT",
                })
              }
            )
          }
        >
          View
        </button>
      </li>
    )
  }

  renderTopicWiseModal = () => {
    const topicWiseAnalysis = this.state.topicWiseAnalysis.sort(
      (a, b) => a.correctRatio - b.correctRatio
    )
    return (
      <Modal
        isOpen={this.state.isShowTopicWiseModal}
        backdropClassName="modal-backdrop-show"
        contentClassName="border-28px"
        modalClassName="modal-dialog-shadow-none"
        centered={true}
      >
        <div className="modal-body" style={{ padding: "2rem 3rem" }}>
          <div className="d-flex justify-content-between">
            <div
              className="text-brand font-weight-bold mb-4"
              style={{ fontSize: "1.2rem" }}
            >
              Topic Wise Performance
            </div>
            <div
              className="text-brand mt-2"
              onClick={() => this.setState({ isShowTopicWiseModal: false })}
            >
              <i className="fas fa-times" style={{ cursor: "pointer" }} />
            </div>
          </div>

          {topicWiseAnalysis.map((item, index) => (
            <div className="my-4" key={item.topic.id}>
              <div className="d-flex" style={{ fontSize: "1rem" }}>
                <div className="test-primary font-weight-bold">
                  {item.topic.name}
                </div>
                <div
                  className={
                    "ml-auto " +
                    (item.correctRatio < 40 ? "text-danger" : "text-green")
                  }
                >
                  {item.correctRatio}%
                </div>
              </div>
              <div
                style={{
                  height: 10,
                  backgroundColor: "rgba(0, 0, 0, 0.03)",
                  borderRadius: 12,
                }}
              >
                <div
                  className="bg-green"
                  style={{
                    height: "100%",
                    width: `${item.correctRatio}%`,
                    borderRadius: 12,
                  }}
                />
              </div>
            </div>
          ))}
          <div className="d-flex justify-content-end">
            <button
              className="btn bg-brand text-light px-4 py-05 mt-4"
              style={{ borderRadius: 8 }}
              onClick={() => this.setState({ isShowTopicWiseModal: false })}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    )
  }

  renderOnlineTest = () => {
    const {
      questionWiseAnalysis,
      // strongestTopicName,
      // strongestTopicPercentage,
      weakestTopicName,
      weakestTopicPercentage,
      highestMark,
      highestPercentage,
      averageMark,
      averagePercentage,
      lowestMark,
      lowestPercentage,
      topPerformers,
      weekPerformers,
      absentees,
    } = this.state

    return (
      <>
        <div
          className="row flex-wrap align-items-start justify-content-center"
          style={{ maxWidth: "calc(99vw - 100px)" }}
        >
          <div
            className="col-md-5 mt-3"
            style={{
              minWidth: "calc(330px + 3rem + 2rem + 3rem)",
              maxWidth: 500,
            }}
          >
            <div
              className="d-flex border border-muted px-3 py-4 d-flex justify-content-around"
              style={{ borderRadius: 16, width: "100%" }}
            >
              <div
                style={{ height: 110, width: 110, position: "relative" }}
                className="mr-3"
              >
                <div
                  style={{
                    height: "110%",
                    width: "110%",
                    position: "absolute",
                    top: "-5%",
                    left: "-5%",
                    transform: "rotate(-12deg)",
                    backgroundColor: "#bcbcbc",
                    borderRadius: 12,
                  }}
                />
                <div
                  className="bg-brand d-flex flex-column align-items-center justify-content-end py-3"
                  style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    borderRadius: 12,
                  }}
                >
                  <div className="text-success font-weight-bold mb-2">
                    <span
                      style={{
                        display: "inline",
                        color: "#4eddc1",
                        fontSize: "1.75rem",
                      }}
                    >
                      {highestMark}
                    </span>
                    <span className="text-light">{` (${highestPercentage}%)`}</span>
                  </div>
                  <div className="text-light font-weight-bold">
                    Highest Mark
                  </div>
                </div>
              </div>

              <div
                style={{ height: 110, width: 110, position: "relative" }}
                className="mr-3"
              >
                <div
                  style={{
                    height: "110%",
                    width: "110%",
                    position: "absolute",
                    top: "-5%",
                    left: "-5%",
                    transform: "rotate(-12deg)",
                    backgroundColor: "#bcbcbc",
                    borderRadius: 12,
                  }}
                />
                <div
                  className="bg-brand d-flex flex-column align-items-center justify-content-end py-3"
                  style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    borderRadius: 12,
                  }}
                >
                  <div className="text-success font-weight-bold mb-2">
                    <span
                      style={{
                        display: "inline",
                        color: "#f9b03a",
                        fontSize: "1.75rem",
                      }}
                    >
                      {averageMark}
                    </span>
                    <span className="text-light">{` (${averagePercentage}%)`}</span>
                  </div>
                  <div className="text-light font-weight-bold">
                    Class Average
                  </div>
                </div>
              </div>

              <div style={{ height: 110, width: 110, position: "relative" }}>
                <div
                  style={{
                    height: "110%",
                    width: "110%",
                    position: "absolute",
                    top: "-5%",
                    left: "-5%",
                    transform: "rotate(-12deg)",
                    backgroundColor: "#bcbcbc",
                    borderRadius: 12,
                  }}
                />
                <div
                  className="bg-brand d-flex flex-column align-items-center justify-content-end py-3"
                  style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    borderRadius: 12,
                  }}
                >
                  <div className="text-success font-weight-bold mb-2">
                    <span
                      style={{
                        display: "inline",
                        color: "#f75c85",
                        fontSize: "1.75rem",
                      }}
                    >
                      {lowestMark}
                    </span>
                    <span className="text-light">{` (${lowestPercentage}%)`}</span>
                  </div>
                  <div className="text-light font-weight-bold">Lowest Mark</div>
                </div>
              </div>
            </div>

            <h4 className="mt-3 text-brand font-weight-bold">
              Questions wise Analysis
            </h4>
            <p className="text-muted my-2">
              Questions are arranged based on most wrong attempts
            </p>
            <div className="border border-muted" style={{ borderRadius: 16 }}>
              <div className="d-flex py-3 px-4">
                <div className="d-flex align-items-center mr-3">
                  <div
                    className="rounded-pill mr-2"
                    style={{
                      width: 12,
                      height: 12,
                      backgroundColor: correctColor[CORRECT].color,
                      border: "1px solid #07af8f",
                    }}
                  />
                  <span>Correct</span>
                </div>

                <div className="d-flex align-items-center mr-3">
                  <div
                    className="rounded-pill mr-2"
                    style={{
                      width: 12,
                      height: 12,
                      backgroundColor: correctColor[WRONG].color,
                      border: "1px solid #ec365c",
                    }}
                  />
                  <span>Wrong</span>
                </div>

                <div className="d-flex align-items-center mr-3">
                  <div
                    className="rounded-pill mr-2"
                    style={{
                      width: 12,
                      height: 12,
                      backgroundColor: correctColor[PARTIAL].color,
                      border: "1px solid #e49f26",
                    }}
                  />
                  <span>Partial</span>
                </div>

                <div className="d-flex align-items-center mr-3">
                  <div
                    className="rounded-pill mr-2"
                    style={{
                      width: 12,
                      height: 12,
                      backgroundColor: correctColor[UNGRADED].color,
                      border: "1px solid #a3a7a7",
                    }}
                  />
                  <span>Ungraded</span>
                </div>
              </div>

              <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                {questionWiseAnalysis
                  .sort(
                    (a, b) =>
                      b.incorrectStudents.length - a.incorrectStudents.length
                  )
                  .map(this.renderQuestionwise)}
              </ul>
            </div>
          </div>

          <div className="col-md-7 mt-3">
            <div
              className="px-4 py-3 mb-3 custom-scrollbars"
              style={{
                borderRadius: 16,
                backgroundColor: "rgba(0,0,0,0.075)",
              }}
            >
              <h5 className="text-brand font-weight-muted mb-2">
                <b>Top performers</b>
                {` (${topPerformers.length})`}
              </h5>
              <div
                className="d-flex align-items-strech justify-content-start py-2"
                style={{ overflowX: "scroll" }}
              >
                {topPerformers.length > 0 ? (
                  topPerformers
                    .sort((a, b) => a.gradedMark > b.gradedMark)
                    .map(this.renderPerformers)
                ) : (
                  <h4 className="text-muted text-center mx-auto my-4">
                    No Top Performers
                  </h4>
                )}
              </div>
            </div>

            <div
              className="px-4 py-3 mb-3 custom-scrollbars"
              style={{
                borderRadius: 16,
                backgroundColor: "rgba(0,0,0,0.075)",
              }}
            >
              <h5 className="text-brand font-weight-muted mb-2">
                <b>Weak Performers</b>
                {` (${weekPerformers.length})`}
              </h5>
              <div
                className="d-flex align-items-strech justify-content-start py-2"
                style={{ overflowX: "scroll" }}
              >
                {weekPerformers.length > 0 ? (
                  weekPerformers
                    .sort((a, b) => a.gradedMark - b.gradedMark)
                    .map(this.renderPerformers)
                ) : (
                  <h4 className="text-muted text-center mx-auto my-4">
                    No Weak Performers
                  </h4>
                )}
              </div>
            </div>

            {absentees.length > 0 && (
              <div
                className="px-4 py-3 mb-3 custom-scrollbars"
                style={{
                  borderRadius: 16,
                  backgroundColor: "rgba(0,0,0,0.075)",
                }}
              >
                <h5 className="text-brand font-weight-muted mb-2">
                  <b>Absentees</b>
                  {` (${absentees.length})`}
                </h5>
                <div
                  className="d-flex align-items-strech justify-content-start py-2"
                  style={{ overflowX: "scroll" }}
                >
                  {absentees.length > 0 ? (
                    absentees.map(this.renderAbsentees)
                  ) : (
                    <h4 className="text-muted text-center mx-auto my-4">
                      No Absentees
                    </h4>
                  )}
                </div>
              </div>
            )}

            {weakestTopicName && (
              <div
                className="border px-3 py-2 d-flex align-items-center"
                style={{
                  borderRadius: 16,
                  backgroundColor: "rgba(0,0,0,0.03)",
                }}
              >
                <img
                  src={require("../../../assets/images/bulb_red.png")}
                  style={{ width: 40, height: 40 }}
                  className="mr-2"
                  alt=""
                />
                <p className="my-0 mx-2">
                  Your student weakest topic is{" "}
                  <span className="text-danger">{weakestTopicName}</span> with
                  accuracy of{" "}
                  <span className="text-danger">{weakestTopicPercentage}%</span>
                </p>
                <button
                  className="btn btn-sm bg-brand text-light rounded-pill ml-4"
                  onClick={() => this.setState({ isShowTopicWiseModal: true })}
                >
                  View Topic wise Analysis
                </button>
              </div>
            )}
          </div>
        </div>
      </>
    )
  }

  renderInSchoolTest = () => {
    const {
      highestMark,
      highestPercentage,
      averageMark,
      averagePercentage,
      lowestMark,
      lowestPercentage,
      topPerformers,
      weekPerformers,
      absentees,
    } = this.state
    return (
      <>
        <div
          className="d-flex border border-muted px-3 py-4 d-flex justify-content-around"
          style={{
            borderRadius: 16,
            width: "100%",
            minWidth: "calc(330px + 3rem + 2rem + 3rem)",
            maxWidth: 500,
          }}
        >
          <div
            style={{ height: 110, width: 110, position: "relative" }}
            className="mr-3"
          >
            <div
              style={{
                height: "110%",
                width: "110%",
                position: "absolute",
                top: "-5%",
                left: "-5%",
                transform: "rotate(-12deg)",
                backgroundColor: "#bcbcbc",
                borderRadius: 12,
              }}
            />
            <div
              className="bg-brand d-flex flex-column align-items-center justify-content-end py-3"
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
                borderRadius: 12,
              }}
            >
              <div className="text-success font-weight-bold mb-2">
                <span
                  style={{
                    display: "inline",
                    color: "#4eddc1",
                    fontSize: "1.75rem",
                  }}
                >
                  {highestMark}
                </span>
                <span className="text-light">{` (${highestPercentage}%)`}</span>
              </div>
              <div className="text-light font-weight-bold">Highest Mark</div>
            </div>
          </div>

          <div
            style={{ height: 110, width: 110, position: "relative" }}
            className="mr-3"
          >
            <div
              style={{
                height: "110%",
                width: "110%",
                position: "absolute",
                top: "-5%",
                left: "-5%",
                transform: "rotate(-12deg)",
                backgroundColor: "#bcbcbc",
                borderRadius: 12,
              }}
            />
            <div
              className="bg-brand d-flex flex-column align-items-center justify-content-end py-3"
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
                borderRadius: 12,
              }}
            >
              <div className="text-success font-weight-bold mb-2">
                <span
                  style={{
                    display: "inline",
                    color: "#f9b03a",
                    fontSize: "1.75rem",
                  }}
                >
                  {averageMark}
                </span>
                <span className="text-light">{` (${averagePercentage}%)`}</span>
              </div>
              <div className="text-light font-weight-bold">Class Average</div>
            </div>
          </div>

          <div style={{ height: 110, width: 110, position: "relative" }}>
            <div
              style={{
                height: "110%",
                width: "110%",
                position: "absolute",
                top: "-5%",
                left: "-5%",
                transform: "rotate(-12deg)",
                backgroundColor: "#bcbcbc",
                borderRadius: 12,
              }}
            />
            <div
              className="bg-brand d-flex flex-column align-items-center justify-content-end py-3"
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
                borderRadius: 12,
              }}
            >
              <div className="text-success font-weight-bold mb-2">
                <span
                  style={{
                    display: "inline",
                    color: "#f75c85",
                    fontSize: "1.75rem",
                  }}
                >
                  {lowestMark}
                </span>
                <span className="text-light">{` (${lowestPercentage}%)`}</span>
              </div>
              <div className="text-light font-weight-bold">Lowest Mark</div>
            </div>
          </div>
        </div>

        <div className="row" style={{ maxWidth: "calc(100vw - 100px)" }}>
          <div className="col-md-7 mt-3">
            <div
              className="px-4 py-3 mb-3 custom-scrollbars"
              style={{
                borderRadius: 16,
                backgroundColor: "rgba(0,0,0,0.075)",
              }}
            >
              <h5 className="text-brand font-weight-muted mb-2">
                <b>Top performers</b>
                {` (${topPerformers.length})`}
              </h5>
              <div
                className="d-flex align-items-strech justify-content-start py-2"
                style={{ overflowX: "scroll" }}
              >
                {topPerformers.length > 0 ? (
                  topPerformers.map(this.renderPerformers)
                ) : (
                  <h4 className="text-muted text-center mx-auto my-4">
                    No Top Performers
                  </h4>
                )}
              </div>
            </div>

            <div
              className="px-4 py-3 mb-3 custom-scrollbars"
              style={{
                borderRadius: 16,
                backgroundColor: "rgba(0,0,0,0.075)",
              }}
            >
              <h5 className="text-brand font-weight-muted mb-2">
                <b>Weak Performers</b>
                {` (${weekPerformers.length})`}
              </h5>
              <div
                className="d-flex align-items-strech justify-content-start py-2"
                style={{ overflowX: "scroll" }}
              >
                {weekPerformers.length > 0 ? (
                  weekPerformers.map(this.renderPerformers)
                ) : (
                  <h4 className="text-muted text-center mx-auto my-4">
                    No Weak Performers
                  </h4>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-5">
            <div
              className="px-4 py-3 my-3 custom-scrollbars"
              style={{
                height: "calc(100vh - 340px)",
                borderRadius: 16,
                backgroundColor: "rgba(0,0,0,0.075)",
              }}
            >
              <h5 className="text-danger font-weight-muted mb-2">
                <b>Absentees </b>
                {` (${absentees.length})`}
              </h5>
              <div
                className="d-flex flex-column align-items-strech justify-content-start py-2"
                style={{
                  overflowY: "scroll",
                  maxHeight: "calc(100% - 21px - 0.5rem - 0.5rem)",
                }}
              >
                {absentees.length > 0 ? (
                  absentees.map((student, index) => (
                    <div
                      className="border border-muted d-flex align-items-center bg-light px-3 py-2 my-2"
                      style={{ borderRadius: 8 }}
                    >
                      <img
                        src={
                          student.gender === "M"
                            ? require("../../../assets/images/boy.png")
                            : require("../../../assets/images/girl.png")
                        }
                        alt="Student"
                        className="rounded-pill mr-2"
                        style={{ height: 30, width: 30 }}
                      />
                      <div>{student.fullName}</div>
                    </div>
                  ))
                ) : (
                  <h4 className="text-muted text-center mx-auto my-4">
                    No Absentees
                  </h4>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  renderQuestionWiseStudents = (students, option, index) =>
    students && (
      <>
        <div className="text-green mb-4">
          <span style={{ fontSize: "1.5rem", fontWeight: "700" }}>
            {students.length}
          </span>{" "}
          students {option !== "UNGRADED" ? "answered" : ""}{" "}
          {option.toLowerCase()} for question {index + 1}
        </div>

        {students.map(student =>
          student ? (
            <div className="d-flex flex-wrap align-items-center justify-content-start">
              <div
                className="rounded-pill border border-muted d-flex align-items-center mb-3"
                style={{ backgroundColor: "#E3E9EA", padding: 6 }}
              >
                <img
                  src={
                    student.gender === "M"
                      ? require("../../../assets/images/boy.png")
                      : require("../../../assets/images/girl.png")
                  }
                  alt="Student Name"
                  style={{ width: 30, height: 30, borderRadius: 30 }}
                />
                <span className="mx-2">
                  {student.studentName || student.fullName}
                </span>
              </div>
            </div>
          ) : (
            ""
          )
        )}
      </>
    )

  renderQuestionwiseQuestion = selectedQuestion => {
    if (!selectedQuestion) {
      return null
    }

    return (
      <>
        {selectedQuestion.type === "TRUE_OR_FALSE" && (
          <div
            className="rounded px-4 py-5 mt-3"
            style={{ backgroundColor: "#f7f7f7" }}
          >
            <h4 className="text-dark mb-4">{selectedQuestion.text}</h4>

            <div
              className="rounded px-3 py-2 d-flex align-items-center mt-2"
              style={{
                border: `2px solid ${
                  selectedQuestion.answers[0] === "true" ? "#bcbcbc" : "#eaeaea"
                }`,
              }}
            >
              <i
                className={`far fa-${
                  selectedQuestion.answers[0] === "true"
                    ? "dot-circle"
                    : "circle"
                } text-muted`}
                style={{ fontSize: "1.2rem" }}
              />
              <div className="ml-2">True</div>
            </div>

            <div
              className="rounded px-3 py-2 d-flex align-items-center mt-2"
              style={{
                border: `2px solid ${
                  selectedQuestion.answers[0] === "false"
                    ? "#bcbcbc"
                    : "#eaeaea"
                }`,
              }}
            >
              <i
                className={`far fa-${
                  selectedQuestion.answers[0] === "false"
                    ? "dot-circle"
                    : "circle"
                } text-muted`}
                style={{ fontSize: "1.2rem" }}
              />
              <div className="ml-2">False</div>
            </div>
          </div>
        )}

        {["LONG_ANSWER", "SHORT_ANSWER"].includes(selectedQuestion.type) && (
          <div
            className="rounded px-4 py-5 mt-3"
            style={{ backgroundColor: "#f7f7f7" }}
          >
            <h4 className="text-dark mb-4">{selectedQuestion.text}</h4>

            {/* <div
            className="border border-muted px-3 py-3"
            style={{ borderRadius: 12 }}
          >
          	{ (selectedQuestion.type === "LONG_ANSWER" || selectedQuestion.type === "SHORT_ANSWER") && quizSubmissionAnswer.longAnswer != null 
				? quizSubmissionAnswer.longAnswer
				: quizSubmissionAnswer.answers[0]
			}
          </div> */}
          </div>
        )}

        {["MULTIPLE_ANSWER", "MULTIPLE_CHOICE"].includes(
          selectedQuestion.type
        ) && (
          <div
            className="rounded px-4 py-5 mt-3"
            style={{ backgroundColor: "#f7f7f7" }}
          >
            <h4 className="text-dark mb-4">{selectedQuestion.text}</h4>

            {selectedQuestion.questions.map((ques, index) => {
              const isCorrect = selectedQuestion.answers.includes(ques)
              const border = isCorrect ? "border-success" : "border-muted"
              const icon =
                selectedQuestion.type === "MULTIPLE_CHOICE"
                  ? isCorrect
                    ? "far fa-dot-circle"
                    : "far fa-circle"
                  : isCorrect
                  ? "fas fa-check-square"
                  : "far fa-check-square"

              return (
                <div
                  className={`text-dark d-flex align-items-center border ${border} mb-2 px-3`}
                  style={{
                    borderRadius: 8,
                    height: 40,
                    backgroundColor: "#fff",
                  }}
                >
                  <i
                    className={`far fa-${icon} text-muted`}
                    style={{ fontSize: "1.2rem" }}
                  />
                  <span className="ml-4">{ques}</span>
                </div>
              )
            })}
          </div>
        )}

        {selectedQuestion.type === "FILL_IN_THE_BLANKS" && (
          <div
            className="rounded px-4 py-5 mt-3"
            style={{ backgroundColor: "#f7f7f7" }}
          >
            <div className="d-flex">
              <h4 className="text-dark mb-2">{selectedQuestion.text}</h4>

              <div
                className="bg-green ml-2 text-center"
                style={{
                  overflow: "hidden",
                  borderRadius: 8,
                }}
              >
                <div
                  className="text-dark px-4 py-1 border border-muted"
                  style={{
                    borderRadius: 8,
                    backgroundColor: "#fff",
                  }}
                >
                  {selectedQuestion.answers[0]}
                </div>
              </div>
            </div>
          </div>
        )}

        {selectedQuestion.type === "SEQUENCING" && (
          <div
            className="rounded px-4 py-5 mt-3"
            style={{ backgroundColor: "#f7f7f7" }}
          >
            <h4 className="text-dark mb-4">{selectedQuestion.text}</h4>

            <div className="d-flex align-items-center mb-1">
              <div className="d-flex align-items-center justify-content-center">
                <div
                  className="text-muted"
                  style={{ fontSize: "1rem", width: 40 }}
                >
                  Order
                </div>
              </div>
              <div style={{ flex: 1, marginLeft: 12 }}></div>
            </div>

            {selectedQuestion.questions.map((ques, index) => {
              return (
                <div className="d-flex align-items-center mb-2">
                  <div className="d-flex align-items-center justify-content-center">
                    <div
                      className="text-dark d-flex align-items-center justify-content-center border border-muted"
                      style={{
                        borderRadius: 8,
                        width: 40,
                        height: 40,
                        backgroundColor: "#fff",
                      }}
                    >
                      {ques}
                    </div>
                  </div>
                  <div style={{ flex: 1, marginLeft: 12 }}>
                    <div
                      className="d-flex bg-green"
                      style={{
                        height: 40,
                        overflow: "hidden",
                        borderRadius: 8,
                      }}
                    >
                      <div
                        className="text-dark d-flex align-items-center justify-content-start px-4 border border-muted"
                        style={{
                          borderRadius: 8,
                          flex: 1,
                          height: "100%",
                          backgroundColor: "#fff",
                        }}
                      >
                        {selectedQuestion.answers[index]}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}

        {selectedQuestion.type === "MATCHING" && (
          <div
            className="rounded px-4 py-5 mt-3"
            style={{ backgroundColor: "#f7f7f7" }}
          >
            <h4 className="text-dark mb-2">{selectedQuestion.text}</h4>
            {selectedQuestion.questions.map((ques, index) => {
              return (
                <div
                  className="px-2 py-2 rounded d-flex align-items-start justify-content-between"
                  style={{
                    backgroundColor: "rgba(0,0,0,0.1)",
                    width: "50%",
                    minWidth: "min(100%, 500px)",
                  }}
                >
                  <div
                    className="bg-green ml-2 text-center d-flex flex-column mr-2"
                    style={{
                      flex: 1,
                      height: 53,
                      overflow: "hidden",
                      borderRadius: 8,
                    }}
                  >
                    <div
                      className="text-dark px-4 py-1 border border-muted d-flex align-items-center justify-content-center"
                      style={{
                        flex: 1,
                        borderRadius: 8,
                        backgroundColor: "#fff",
                      }}
                    >
                      {ques}
                    </div>
                  </div>

                  <div
                    className="bg-green ml-2 text-center d-flex flex-column ml-2"
                    style={{
                      flex: 1,
                      height: 53,
                      overflow: "hidden",
                      borderRadius: 8,
                    }}
                  >
                    <div
                      className="text-dark px-4 py-1 border border-muted d-flex align-items-center justify-content-center"
                      style={{
                        verticalAlign: "center",
                        flex: 1,
                        borderRadius: 8,
                        backgroundColor: "#fff",
                      }}
                    >
                      {selectedQuestion.answers[index]}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </>
    )
  }

  renderQuestionWiseModal = () => {
    const {
      // quizAssignId,
      // report,
      // className,
      // sectionName,
      // subjectName,
      // isOnline,
      // title,
      // instruction,
      // totalMark,
      // highestMark,
      // highestPercentage,
      // averageMark,
      // averagePercentage,
      // lowestMark,
      // lowestPercentage,
      // topPerformers,
      // weekPerformers,
      // gradedCount,
      // ungradedCount,
      // unsubmittedCount,
      // absentees,
      questionData,
      questionWiseOption,
    } = this.state
    const {
      question,
      correctStudents,
      incorrectStudents,
      partialCorrects,
      ungradedStudents,
      // totalStudentCount,
    } = questionData || {}
    const {
      // id,
      // questions,
      // answers,
      // attachment,
      mark,
      // text,
      type,
      index,
      // quizTopic,
      // quizSubmissionAnswers,
    } = question || {}

    myLog("Question data", questionData)

    return (
      <Modal
        modalClassName="right slide-in-right fade"
        className="w-50vw"
        size="lg"
        isOpen={this.state.isShowQuestionWiseModalOpen}
      >
        <div
          className="modal-body p-0"
          style={{ backgroundColor: "#F1F7F7", borderRadius: 12 }}
        >
          <div className="py-3 px-4">
            <div className="d-flex align-items-center">
              <div
                className="bg-brand text-light d-flex align-items-center justify-content-center rounded-pill"
                style={{ width: 34, height: 34 }}
              >
                Q{index + 1}
              </div>
              <div className="text-muted ml-2">
                {question &&
                  questionTypes.find(({ value }) => value === type).label}{" "}
                ({mark} Mark)
              </div>

              <button
                className="btn rounded-pill bg-green text-light px-3 ml-auto"
                onClick={() =>
                  this.setState({
                    isShowQuestionWiseModalOpen: false,
                    questionWiseModalIndex: null,
                    questionWiseOption: "",
                  })
                }
              >
                <i className="fas fa-times" />
                <span className="ml-2">Close</span>
              </button>
            </div>

            {this.renderQuestionwiseQuestion(question)}
          </div>
          <div className="border-top border-dark py-3 px-4">
            <div className="questionWiseOption d-flex align-items-center mb-3">
              <h5 className="text-brand font-weight-bold mb-0">
                Student Attempt Record
              </h5>
              <select
                className="ml-auto custom-select border-muted"
                style={{
                  borderRadius: 8,
                  maxWidth: 150,
                  backgroundColor: "transparent",
                }}
                value={questionWiseOption}
                key={questionWiseOption}
                onChange={e => {
                  const value = e.target.value
                  this.setState({ questionWiseOption: value })
                }}
              >
                <option value="" key="">
                  Choose
                </option>
                <option value="CORRECT" key="CORRECT">
                  Correct Answer
                </option>
                <option value="WRONG" key="WRONG">
                  Wrong Answer
                </option>
                <option value="PARTIAL" key="PARTIAL">
                  Partial Answer
                </option>
                <option value="UNGRADED" key="UNGRADED">
                  Ungraded
                </option>
              </select>
            </div>

            {questionWiseOption === CORRECT
              ? this.renderQuestionWiseStudents(correctStudents, CORRECT, index)
              : null}
            {questionWiseOption === WRONG
              ? this.renderQuestionWiseStudents(incorrectStudents, WRONG, index)
              : null}
            {questionWiseOption === PARTIAL
              ? this.renderQuestionWiseStudents(partialCorrects, PARTIAL, index)
              : null}
            {questionWiseOption === UNGRADED
              ? this.renderQuestionWiseStudents(
                  ungradedStudents,
                  UNGRADED,
                  index
                )
              : null}
          </div>
        </div>
      </Modal>
    )
  }

  render() {
    const {
      className,
      sectionName,
      subjectName,
      title,
      instruction,
      isOnline,
      totalMark,
      gradedCount,
      ungradedCount,
      unsubmittedCount,
      isShowLoading,
    } = this.state

    if (isShowLoading) {
      return (
        <AppWrapper
          teacherDashboard
          showTeacherThree
          showTeacherLogo
          screenName={"TESTS"}
        >
          <div
            className="d-flex justify-content-center"
            style={{ position: "absolute", top: "50%", left: "50%" }}
          >
            <HashLoader
              sizeUnit={"px"}
              size={35}
              color={"#084353"}
              loading={this.state.isShowLoading}
            />
          </div>
          {/*<div className="row">
            <div className="col">
              <h5 className="text-dark font-weight-bold">
                <Skeleton width="540px" />
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex align-items-center justify-content-start">
              <Skeleton width="240px" height="1.5rem" />
            </div>
            <div className="col d-flex align-items-center justify-content-end">
              <Skeleton width="330px" height="1rem" />
            </div>
          </div>*/}
        </AppWrapper>
      )
    }

    return (
      <>
        {this.renderQuestionWiseModal()}
        <div className="modal-backdrop-show">{this.renderTopicWiseModal()}</div>
        <AppWrapper teacherDashboard showTeacherThree showTeacherLogo>
          <div className="row">
            <div className="col">
              <h5 className="text-dark font-weight-bold">
                {`Class ${className}${sectionName} | ${subjectName} | ${
                  isOnline ? "Online" : "InSchool"
                }`}
              </h5>
            </div>
          </div>

          <div className="row my-3 mx-2">
            <div className="col d-flex align-items-center justify-content-start">
              <h4 className="text-dark font-weight-bold m-0 mr-2">{title}</h4>
              <i
                className="fas fa-info-circle"
                style={
                  instruction ? { color: "#04B191" } : { color: "#cbcbcb" }
                }
                title={instruction}
              />
              <i
                className="fas fa-circle text-muted mx-2"
                style={{ fontSize: 4 }}
              />
              <p className="text-brand m-0">Total Marks {totalMark}</p>
            </div>
            {isOnline && (
              <div className="col d-flex align-items-center justify-content-end">
                <div> Graded </div>
                <p className="text-brand m-0 ml-1 font-weight-bold">
                  {gradedCount}
                </p>
                <i
                  className="fas fa-circle text-muted mx-2"
                  style={{ fontSize: 4 }}
                />
                <div>Ungraded</div>
                <p className="text-brand m-0 ml-1 font-weight-bold">
                  {ungradedCount}
                </p>
                <i
                  className="fas fa-circle text-muted mx-2"
                  style={{ fontSize: 4 }}
                />
                <div>Not Submitted</div>
                <p className="text-brand m-0 ml-1 font-weight-bold">
                  {unsubmittedCount}
                </p>
              </div>
            )}
          </div>

          {isOnline ? this.renderOnlineTest() : this.renderInSchoolTest()}
        </AppWrapper>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getQuizReport,
      getQuestionWiseQuizReport,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(AssessmentReport)
