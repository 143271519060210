/*************************************************
 * RedInk
 * @exports
 * @class foundersDetailsReducer.js.js
 * @extends Component
 * @author Pathetic Geek // on 18/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from "../../actions/types"
import { myLog } from "../../utils/Utility"

const INTIAL_STATE = {
  superAdminList: null,
}

export const founderDetailsReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.FOUNDER_SUPER_ADMIN_DETAILS:
      myLog("FOUNDER_SUPER_ADMIN_DETAILS payload", action.payload)
      return {
        ...state,
        superAdminList: action.payload,
      }
    default:
      return state
  }
}
