/*************************************************
 * RedInk
 * @exports
 * @class ClassDetailsAction.js
 * @extends Component
 * @author vijayakumar // on 05/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { ACTION_TYPES } from "../types"
import { showNotification } from "../CommonAction"
import {
  SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"

export const saveGradeDetailsInStore = GradeDetail => {
  return {
    type: ACTION_TYPES.ADMIN_GRADE_LIST,
    payload: GradeDetail,
  }
}

export const getGradeDetails = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_GRADE_DETAILS, object, true)
      if (response) {
        myLog("====getGradeDetails RESPONSE===::::", response)
        dispatch(
          saveGradeDetailsInStore(
            response.grades.length > 0
              ? response.grades
              : [{ gradeName: "", gradeFrom: "", gradeTo: "", gradePoint: "" }]
          )
        )
        callBack(true, response.grades)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getGradeDetails ERROR===????", error)
    }
  }
}

export const saveGradeDetails = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      myLog("====saveGradeDetails body===::::", body)
      const response = await Client.post(URL.SAVE_GRADE_DETAILS, body, true)
      if (response.responseCode === 0) {
        myLog("====saveGradeDetails RESPONSE===::::", response)
        dispatch(getGradeDetails({}, () => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveGradeDetails ERROR===????", error)
    }
  }
}

export const updateGradeDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.post(URL.UPDATE_GRADE_DETAILS, body, true)
      if (response.responseCode === 0) {
        myLog("====updateGradeDetails RESPONSE===::::", response)
        dispatch(getGradeDetails({}, () => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====updateGradeDetails ERROR===????", error)
    }
  }
}

export const deleteGradeDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(URL.DELETE_GRADE_DETAILS, body, true)
      if (response.responseCode === 0) {
        myLog("====deleteGradeDetails RESPONSE===::::", response)
        dispatch(getGradeDetails({}, () => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteGradeDetails ERROR===????", error)
    }
  }
}
