/*************************************************
 * RedInk
 * @exports
 * @class ClassDetailsAction.js
 * @extends Component
 * @author vijayakumar // on 05/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { ACTION_TYPES } from "../types"
import { showNotification } from "../CommonAction"
import {
  SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"
export const saveAssessmentDetailsInStore = AssessmentDetail => {
  return {
    type: ACTION_TYPES.ADMIN_ASSESSMENT_LIST,
    payload: AssessmentDetail,
  }
}

export const getAssessmentDetails = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_ADMIN_ASSESSMENT_DETAILS,
        null,
        true
      )
      if (response) {
        myLog("====getAssessmentDetails RESPONSE===::::", response)
        let assessmentArray = response.assessments
        // let allocationArray = []
        // if (assessmentArray && assessmentArray.length > 0) {
        //   for (let i = 0; i < assessmentArray.length; i++) {
        //     if (
        //       assessmentArray[i].allocationDetails &&
        //       assessmentArray[i].allocationDetails.length > 0
        //     ) {
        //       for (
        //         let j = 0;
        //         j < assessmentArray[i].allocationDetails.length;
        //         j++
        //       ) {
        //         assessmentArray[i].allocationDetails[j]["assessmentId"] =
        //           assessmentArray[i].assessmentId
        //         assessmentArray[i].allocationDetails[j]["assessmentType"] =
        //           assessmentArray[i].assessmentType
        //         assessmentArray[i].allocationDetails[j]["termScoreEvaluation"] =
        //           assessmentArray[i].termScoreEvaluation
        //         assessmentArray[i].allocationDetails[j]["mustPass"] =
        //           assessmentArray[i].mustPass
        //         allocationArray.push(assessmentArray[i].allocationDetails[j])
        //       }
        //     } else {
        //       let object = {
        //         allocationId: "",
        //         assessmentName: assessmentArray[i].assessmentName,
        //         assessmentShortName: assessmentArray[i].assessmentShortName,
        //         maxMark: "",
        //         sections: [],
        //         assessmentId: assessmentArray[i].assessmentId,
        //         assessmentType: assessmentArray[i].assessmentType,
        //         termScoreEvaluation: assessmentArray[i].termScoreEvaluation,
        //         mustPass: assessmentArray[i].mustPass,
        //       }
        //       allocationArray.push(object)
        //     }
        //   }
        // }
        myLog(
          "====getTermDetails RESPONSE===:::: allocationArray",
          assessmentArray
        )
        dispatch(saveAssessmentDetailsInStore(assessmentArray))
        callBack(true, response.assessments)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getAssessmentDetails ERROR===????", error)
    }
  }
}

export const saveAssessmentDetails = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      myLog("====saveAssessmentDetails body===::::", body)
      const response = await Client.post(
        URL.SAVE_ASSESSMENT_DETAILS,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====saveAssessmentDetails RESPONSE===::::", response)
        dispatch(getAssessmentDetails(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveAssessmentDetails ERROR===????", error)
    }
  }
}

export const updateAssessmentDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.post(
        URL.UPDATE_ASSESSMENT_DETAILS,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====updateAssessmentDetails RESPONSE===::::", response)
        dispatch(getAssessmentDetails(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====updateAssessmentDetails ERROR===????", error)
    }
  }
}

export const deleteAssessmentDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.DELETE_ASSESSMENT_DETAILS,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteAssessmentDetails RESPONSE===::::", response)
        dispatch(getAssessmentDetails(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteAssessmentDetails ERROR===????", error)
    }
  }
}

export const deleteDetailAssessmentDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.DELETE_DETAILS_ASSESSMENT,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteDetailAssessmentDetails RESPONSE===::::", response)
        dispatch(getAssessmentDetails(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteDetailAssessmentDetails ERROR===????", error)
    }
  }
}
