/*************************************************
 * RedInk
 * @exports
 * @class QuickAnalyticsReducer.js
 * @extends Component
 * @author vijayakumar // on 18/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from "../../actions/types"
import { myLog } from "../../utils/Utility"

const INTIAL_STATE = {
  studentsPerformanceMarkBySubjectDetails: {},
  studentsPerformerObject: {},
  gradePerformerObject: {},
}

export const quickAnalyticsReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.TEACHER_STUDENTS_PERFORMANCE_MARK_SUBJECT_DETAILS:
      myLog("payload", action.payload)
      return {
        ...state,
        studentsPerformanceMarkBySubjectDetails: { ...action.payload },
      }

    case ACTION_TYPES.TEACHER_STUDENTS_PERFORMER_OBJECT:
      myLog("payload", action.payload)
      return {
        ...state,
        studentsPerformerObject: { ...action.payload },
      }

    case ACTION_TYPES.TEACHER_GRADE_PERFORMER_OBJECT:
      myLog("payload", action.payload)
      return {
        ...state,
        gradePerformerObject: { ...action.payload },
      }

    default:
      return state
  }
}
