/*************************************************
 * RedInk
 * @exports
 * @class AttendanceReportReducer.js
 * @extends Component
 * @author suhy // on 03/12/2020
 * @copyright © 2020 RedInk. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from "../../actions/types"
const {
  GET_QUIZ_ASSIGN_LIST,
  ADD_QUIZ_ASSIGN,
  GET_SINGLE_QUIZ_ASSIGN,
  UPDATE_SINGLE_QUIZ_ASSIGN,
  DELETE_QUIZ_ASSIGN,
} = ACTION_TYPES

const INTIAL_STATE = {
  quizAssignList: [],
  quizAssign: {},
}

export const quizReducer = (state = INTIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_QUIZ_ASSIGN_LIST:
      return {
        ...state,
        quizAssignList: payload,
      }
    case GET_SINGLE_QUIZ_ASSIGN:
      return {
        ...state,
        quizAssign: payload,
      }
    case ADD_QUIZ_ASSIGN:
      return {
        ...state,
        quizAssignList: [payload, ...state.quizAssignList],
      }
    case UPDATE_SINGLE_QUIZ_ASSIGN:
      return {
        ...state,
        quizAssignList: state.quizAssignList.map(e =>
          e.id === payload.id ? payload : e
        ),
      }
    case DELETE_QUIZ_ASSIGN:
      return {
        ...state,
        quizAssign: {},
        quizAssignList: state.quizAssignList.filter(e => e.id !== payload.id),
      }
    default:
      return state
  }
}
