import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { HashLoader } from "react-spinners"
import toast from "react-hot-toast"

import {
  getClasses,
  getStudentLists,
  getOldAcademicList,
  updateStudentSections,
} from "../../../../../actions/admin/UpgradeAcademicYear"

import arrow from "../../../../../assets/images/admin/dot-arrow.png"
import tick from "../../../../../assets/images/tick2.png"
import { getAllStudentsDatabaseList } from "../../../../../actions/teacher/StudentDatabaseAction"

function toastMsg() {
  toast(
    t => (
      <div
        onClick={() => toast.dismiss(t.id)}
        className="imported-toast-msg d-flex align-items-center fw-500 bg-4DB784 text-white"
      >
        Students imported Succesfully
      </div>
    ),
    { style: { padding: 0, margin: 0, borderRadius: "15px" } }
  )
}

function PreviousYear({ onSuccess }) {
  const [selctedAcademicId, setSelectedAcademicId] = useState("")
  const [currentAcademicId, setCurrectAcademicId] = useState("")
  const [classFromId, setClassFromId] = useState("")
  const [classToId, setClassToId] = useState("")

  const [alreadySelectedStudentList, setAlreadySelectedStudentList] = useState(
    []
  )
  const [selectedStudents, setSelectedStudents] = useState([])
  const [academicList, setAcademicList] = useState([])
  const [studentList, setStudentList] = useState([])
  const [classListTo, setClassListTo] = useState([])
  const [classList, setClassList] = useState([])

  const [isLoading, setIsLoading] = useState("")
  const [step, setStep] = useState(1)
  const dispatch = useDispatch()

  useEffect(() => {
    getOldAcademicList((success, data) => {
      if (success) {
        const { branchId, academicId } = data.find(d => d.isActiveAcademicYear)
        const params = {
          branchId,
          academicId,
        }
        getClasses(params, (success, res) => {
          if (success) {
            setClassListTo(res)
          }
        })

        setCurrectAcademicId(academicId)
        setAcademicList(data)
      }
    })
  }, [])

  const onChageAcademicId = e => {
    setSelectedAcademicId(e.target.value)
    const branchId = academicList.find(a => a.academicId === e.target.value)
      ?.branchId
    const params = {
      branchId,
      academicId: e.target.value,
    }
    getClasses(params, (success, res) => {
      if (success) {
        setClassList(res)
      }
    })
  }

  const proceed = () => {
    if (selctedAcademicId && classFromId && classToId) {
      setIsLoading("fetchingStudent")
      setStep(2)
      const params = {
        academicId: selctedAcademicId,
        sectionId: classFromId,
        offset: 0,
        limit: 100,
      }
      const stuParam = {
        sectionId: classToId,
        offset: 0,
        limit: 100,
      }
      dispatch(
        getAllStudentsDatabaseList(
          stuParam,
          (success, res) => {
            if (success) {
              const selectedStu = res.data?.rows?.map(r => r.studentId) || []
              setAlreadySelectedStudentList(selectedStu)
              getStudentLists(params, (success1, res1) => {
                if (success1) {
                  setSelectedStudents(
                    res1
                      .filter(r => selectedStu.includes(r.studentId))
                      .map(s => s.studentId)
                  )
                  setStudentList(res1)
                  setIsLoading("")
                }
              })
            }
          },
          false
        )
      )
    }
  }

  const importStudents = () => {
    const list = selectedStudents
      .filter(s => !alreadySelectedStudentList.includes(s))
      .map(s => ({
        newAcademicId: currentAcademicId,
        newSectionId: classToId,
        studentId: s,
      }))

    updateStudentSections(list, () => {
      toastMsg()
      onSuccess()
    })
  }

  const selectAll = () => {
    setSelectedStudents(
      selectedStudents.length === studentList.length
        ? [...alreadySelectedStudentList]
        : studentList.map(a => a.studentId)
    )
  }

  return (
    <>
      {step === 1 && (
        <>
          <div className="pre-year-content">
            <div>
              <p className="fw-600">Academic year</p>
              <p className="fs-14 clr-5B5B5B">
                Select academic year to import students from
              </p>
              <select
                value={selctedAcademicId}
                onChange={onChageAcademicId}
                className="modern-arrow form-control"
              >
                <option value="" disabled></option>
                {academicList
                  .filter(a => a.academicId !== currentAcademicId)
                  .map(a => (
                    <option key={a.academicId} value={a.academicId}>
                      {a.academicName}
                    </option>
                  ))}
              </select>
            </div>

            <div className="d-flex align-items-center">
              <div>
                <p className="mb-0 fw-600">Add student from</p>
                <p className="mb-0 fs-14 clr-5B5B5B">
                  Class in previous academic year
                </p>
                <select
                  value={classFromId}
                  disabled={!selctedAcademicId}
                  onChange={e => setClassFromId(e.target.value)}
                  className="modern-arrow form-control"
                >
                  <option value="" disabled></option>
                  {classList.map(c => (
                    <option key={c.className} value={c.id}>
                      {c.className}
                    </option>
                  ))}
                </select>
              </div>

              <img src={arrow} alt="" />

              <div>
                <p className="mb-0 fw-600">Add student to</p>
                <p className="mb-0 fs-14 clr-5B5B5B">
                  Class in current academic year
                </p>
                <select
                  value={classToId}
                  disabled={!selctedAcademicId}
                  onChange={e => setClassToId(e.target.value)}
                  className="modern-arrow form-control"
                >
                  <option value="" disabled></option>
                  {classListTo.map(c => (
                    <option key={c.className} value={c.id}>
                      {c.className}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div>
            <button
              className="d-block mx-auto px-4 py-2 fw-500 text-white bg-50AE93 br-8px"
              onClick={proceed}
            >
              Proceed
            </button>
          </div>
        </>
      )}

      {step === 2 && (
        <>
          <div className="pre-year-content">
            <div className="d-flex justify-content-between">
              <p className="mb-2 fw-600">Import Students</p>

              <p className="mb-2 fs-15 fw-500 clr-868686">
                Selected - {selectedStudents.length}
              </p>
            </div>

            <div className="mb-2 clr-5B5B5B">
              Select students to import to class{" "}
              {classList.find(c => c.id === classToId)?.className}
            </div>

            <div
              className={`pre-year-content-btns-wrapper ${
                isLoading === "fetchingStudent"
                  ? "d-flex align-items-center justify-content-center"
                  : ""
              }`}
            >
              {isLoading === "fetchingStudent" && (
                <HashLoader sizeUnit="px" color="#50AE93" size={24} loading />
              )}

              {isLoading !== "fetchingStudent" && (
                <button
                  className={`select-check d-flex align-items-center fw-500 bg-white br-8px ${
                    studentList.length === selectedStudents.length
                      ? "active"
                      : ""
                  }`}
                  onClick={selectAll}
                >
                  <span className="d-flex align-items-center justify-content-center w-18 h-18">
                    <img width={10} src={tick} alt="tick" />
                  </span>
                  Select all
                </button>
              )}

              {isLoading !== "fetchingStudent" &&
                studentList.map(s => (
                  <button
                    key={s.studentId}
                    className={`select-check d-flex align-items-center fw-500 bg-white ${
                      selectedStudents.includes(s.studentId) ? "active" : ""
                    }`}
                    onClick={() =>
                      setSelectedStudents(pr =>
                        pr.includes(s.studentId)
                          ? pr.filter(p => p !== s.studentId)
                          : [...pr, s.studentId]
                      )
                    }
                    disabled={alreadySelectedStudentList.includes(s.studentId)}
                  >
                    <span className="d-flex align-items-center justify-content-center w-18 h-18">
                      <img width={10} src={tick} alt="tick" />
                    </span>
                    {s.studentFirstName} {s.studentLastName}
                  </button>
                ))}
            </div>
          </div>

          <div>
            <button
              className="d-block mx-auto px-4 py-2 fw-500 text-white bg-50AE93 br-8px"
              onClick={importStudents}
            >
              Import Students
            </button>
          </div>
        </>
      )}
    </>
  )
}

export default PreviousYear
