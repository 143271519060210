import React, { useState } from "react"
import { Modal } from "reactstrap"

import { ReactComponent as Close } from "../../../../../assets/images/close.svg"
import AddNewStudent from "./AddNewStudent"
import PreviousYear from "./PreviousYear"

const tabs = ["Add New Student", "From Previous year"]

function UpgradeModal({ getStudents, closeModal }) {
  const [selcted, setSelected] = useState("Add New Student")

  const onSuccess = () => {
    closeModal()
    getStudents()
  }

  return (
    <Modal
      isOpen
      backdropClassName="modal-backdrop-show"
      modalClassName="modal-dialog-shadow-none upgrade-stu-modal"
      contentClassName="py-4 px-5 relative"
      centered
      size="xl"
    >
      <button
        onClick={closeModal}
        className="upgrade-stu-modal-close absolute top-0 right-0 p-0 ml-auto"
      >
        <Close />
      </button>

      <div className="upgrade-tabs-border-b">
        {tabs.map(t => (
          <button
            key={t}
            onClick={() => setSelected(t)}
            className={`pb-2 px-4 fs-18 fw-600 ${
              t === selcted
                ? "upgrade-tabs-selected-border-b clr-0E7D5D"
                : "clr-8D8D8D"
            }`}
          >
            {t}
          </button>
        ))}
      </div>

      {selcted === "From Previous year" ? (
        <PreviousYear onSuccess={onSuccess} />
      ) : (
        <AddNewStudent onSuccess={onSuccess} />
      )}
    </Modal>
  )
}

export default UpgradeModal
