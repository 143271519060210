/*************************************************
 * Redink
 * @exports
 * @file Constants.js
 * @author Sasidharan // on 19/12/2019
 * @copyright © 2019 Redink. All rights reserved.
 *************************************************/

export const VALIDATION_MSG = {
  REQ_FAILED: "Request failed.",
  OOPS: "Oops !",
  SUCCESS: "Hooray !",
  SOMETHING_WENT_WRONG: "Something went wrong",
  DELETE_CONTENT: "Are you sure want to delete?",
  DELETE_TITLE: "Delete",
  AUTH_FAILED: "Password does not match.",
  ALERT: "ALERT",
  MARK_UPLOAD_LOCK: "Are you sure, You want to lock?",
}

export const HTTP_CODE = {
  SUCCESS: 200,
  INSERT_SUCESS: 201,
  AUTHENTICATION_FAILURE: 401,
  REQUIRED_MISSING: 403,
  REQUEST_TIMED_OUT_FAILURE: 500,
  INPUT_VALIDATION_ERROR: 400,
  NO_DATA_FOUND: 404,
}

export const SUCCESS_TOAST = "success"
export const DANGER_TOAST = "danger"
export const INFO_TOAST = "info"
export const WARNING_TOAST = "warning"

export const PER_PAGE = 10

export const MAX_FILE_UPLOAD = 50 * 1000 * 1000 // 20MB
