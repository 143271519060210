/*************************************************
 * RedInk
 * @exports
 * @class MarkScreen.js
 * @extends Component
 * @author Sasidharan // on 15/03/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import AppWrapper from "../Wrapper"
import { UncontrolledTooltip } from "reactstrap"
import fileSaver from "file-saver"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  getMarkSubjectDetails,
  getMarkStudentList,
  saveStudentSubjectMarkObject,
  saveStudentSubjectMarkUploadLockDetail,
  saveMarkStudentListInStore,
  saveStudentSubjectGenerateAnalyticsDetail,
  getXlsheetTemplate,
  getXlsheetReport,
} from "../../../actions/teacher/MarkScreenAction"
import MessageModal from "../MessageModal"
import { showNotification } from "../../../actions/CommonAction"
import { myLog } from "../../../utils/Utility"
import readXlsxFile from "read-excel-file"

class TermMarkScreen extends Component {
  static getDerivedStateFromProps(props, state) {
    return {
      teacherName: props.markSubjectDetails
        ? props.markSubjectDetails.teacherName
        : "",
      date: props.markSubjectDetails ? props.markSubjectDetails.date : "",
      maxMark: props.markSubjectDetails ? props.markSubjectDetails.maxMark : "",
      minMark: props.markSubjectDetails ? props.markSubjectDetails.minMark : "",
      passMark: props.markSubjectDetails
        ? props.markSubjectDetails.passMark
        : "",
      distinctionMark: props.markSubjectDetails
        ? props.markSubjectDetails.distinctionMark
        : "",
      convertMark: props.markSubjectDetails
        ? props.markSubjectDetails.convertMark
        : "",
      allowQuickAnalytics: state.allowQuickAnalytics,
      theoryMark: props.markSubjectDetails
        ? props.markSubjectDetails.theoryMark
        : "",
      theoryConvertMarkTo: props.markSubjectDetails
        ? props.markSubjectDetails.theoryConvertMarkTo
        : "",
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedEnterAs: "MARK",
      selectedViewAs: [{ value: "MARK", label: "MARK" }],
      hierarchySubjectDetails: this.props.location.state
        .hierarchySubjectDetails,
      isShowGrade: false,
      isShowMark: true,
      isShowConvertedMark: false,
      isShowPercentage: false,
      allowQuickAnalytics: false,
      isShowLoading: false,
      isLocked: false,
      isCompleted: false,
      isShowGenerateAnalyticsLoading: false,
      isGeneratedAnalytics: false,
      isFromActivity: this.props.location.state.isFromActivity,
      invalidMarkIndex: -1,
    }
    myLog(this.state.hierarchySubjectDetails, ";;;;;;;;;;;;;;;;;;;;")
  }

  componentDidMount() {
    this.props.getMarkSubjectDetails(
      this.state.hierarchySubjectDetails,
      () => {}
    )
    this.props.getMarkStudentList(
      this.state.hierarchySubjectDetails,
      (success, response) => {
        if (success) {
          myLog(response.completed)
          myLog(response.isLocked ? response.isLocked : "none")
          this.setState({
            allowQuickAnalytics:
              response.completed && response.isLocked ? true : false,
            isLocked: response.isLocked ? response.isLocked : false,
            isCompleted: response.completed,
            isGeneratedAnalytics: response.isGenerated,
            columnNames: response.activities,
          })
        }
      }
    )
  }

  componentWillUnmount() {
    this.props.saveMarkStudentListInStore([])
  }

  /**
   * Handles message modal
   */
  closeMessageModal = () => this.setState({ isMessageModalOpen: false })

  /**
   * handles on click message icon in sidebar
   */
  onClickMessageIcon = () => {
    this.setState({ isMessageModalOpen: !this.state.isMessageModalOpen })
  }
  /**
   * handles message submit
   */
  onSubmitMessage = () => {
    this.closeMessageModal()
  }

  /**
   * handles mark entry
   */
  handleMarkEntry = (event, index, rowIndex) => {
    let numberRegex = /^[a-zA-Z0-9]+$/
    let studentArray = this.props.markStudentList
    studentArray[index].row[rowIndex] = event.target.value
    this.props.saveMarkStudentListInStore(studentArray)
    if (event.target.value !== "AB" && event.target.value !== "NT") {
      if (
        !numberRegex.test(event.target.value) ||
        !(
          event.target.value <=
          parseInt(
            this.state.isFromActivity
              ? this.state.maxMark
              : this.state.theoryMark
          )
        )
      ) {
        this.setState({ invalidMarkIndex: index })
      } else {
        this.setState({ invalidMarkIndex: -1 })
      }
    } else {
      this.setState({ invalidMarkIndex: -1 })
    }
  }

  downloadXlSheetTemplate = () => {
    const { hierarchySubjectDetails } = this.state
    this.props.getXlsheetTemplate(
      hierarchySubjectDetails.subjectId,
      response => {
        var blob = new Blob([response], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
        fileSaver.saveAs(blob, "studentRemarksTemplate.xlsx")
      }
    )
  }

  convertToRequestArray = rows => {
    delete rows[0]
    if (Array.isArray(rows)) {
      const requestKeys = ["roll_no", "student_name", "mark", "remark"]
      let marksArray = []
      rows.forEach(row => {
        let tempObj = {}
        row.forEach((data, i) => {
          tempObj[requestKeys[i]] = data
        })
        marksArray.push(tempObj)
      })
      let studentArray = this.props.markStudentList
      for (let i = 0; i < marksArray.length; i++) {
        for (let j = 0; j < studentArray.length; j++) {
          let found = marksArray[i].roll_no === studentArray[j].row[0]
          if (found) {
            studentArray[j].row[1] = marksArray[i].student_name
            studentArray[j].row[2] = marksArray[i].mark
            studentArray[j].row[3] = marksArray[i].remark
          }
        }
      }
      this.props.saveMarkStudentListInStore(studentArray)
    }
  }

  handleDrop = async files => {
    readXlsxFile(files[0])
      .then(rows => {
        myLog("EXCEl", rows)
        this.convertToRequestArray(rows)
      })
      .catch(() => {
        this.setState({
          excelError: true,
          excelFileName: "",
          excelTitleData: [],
          uploadedExcelFile: {},
        })
      })
  }

  downloadXlSheetReport = () => {
    const { hierarchySubjectDetails } = this.state
    this.props.getXlsheetReport(hierarchySubjectDetails.subjectId, response => {
      var blob = new Blob([response], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
      fileSaver.saveAs(blob, "studentRemarksReport.xlsx")
    })
  }

  render() {
    return (
      <AppWrapper
        openMessageModal={() => this.onClickMessageIcon()}
        showTeacherThree
        showTeacherLogo
        sideMenuCollapsedBackExam
        screenName={"Term Mark Entry Screen"}
      >
        <MessageModal
          isModalOpen={this.state.isMessageModalOpen}
          closeModal={() => this.onClickMessageIcon()}
          onSubmitMessage={() => this.onSubmitMessage()}
        />
        <div className="ml-2 mr-3">
          <div className="row bg-theme-light p-4 rounded text-light">
            <div className="col-sm-4">
              <div className="row">
                <div className="col-sm">
                  <div className="d-flex row">
                    {this.props.location.state.academicName}
                    <i className="fas fa-caret-right py-1 px-2" />
                    {this.props.location.state.termName}
                  </div>
                  <div className="d-flex row my-3">
                    <h1 className="font-weight-bold text-white">
                      {this.props.location.state.className}{" "}
                      {this.props.location.state.termName}
                    </h1>
                  </div>
                  {/* <div>
                    <p className="text-muted">
                      TEACHER NAME:{" "}
                      <span className="text-white">
                        {this.state.teacherName}
                      </span>
                    </p>
                    <p className="text-muted">
                      DATE:{" "}
                      <span className="text-white">{this.state.date}</span>
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
            {/* <div className="border-right mx-3" /> */}
          </div>

          <div className="row">
            <div className="table-responsive mt-4 col-sm-9 p-0">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    {this.state.columnNames &&
                      this.state.columnNames.map((item, index) => {
                        return (
                          <>
                            {index === 2 ? (
                              <th width="150" scope="col">
                                {item}
                              </th>
                            ) : (
                              <th scope="col">{item}</th>
                            )}
                          </>
                        )
                      })}
                  </tr>
                </thead>
                <tbody>
                  {this.props.markStudentList &&
                    this.props.markStudentList.map((item, index) => {
                      return (
                        <tr>
                          {item.row.map((rowItem, rowIndex) => {
                            return (
                              <td>
                                {rowIndex === 2 ||
                                item.row.length - 1 === rowIndex ? (
                                  <>
                                    <input
                                      disabled={
                                        rowIndex === 0 || rowIndex === 1
                                          ? true
                                          : this.state.isGeneratedAnalytics
                                          ? true
                                          : false
                                      }
                                      type="text"
                                      className="form-control input-border-color"
                                      value={rowItem}
                                      onChange={e =>
                                        this.handleMarkEntry(e, index, rowIndex)
                                      }
                                    />
                                    {rowIndex === 2 &&
                                      this.state.invalidMarkIndex === index && (
                                        <small className="text-danger">
                                          Only AB, NT and mark not exceed
                                          maximum mark are allowed.
                                        </small>
                                      )}
                                  </>
                                ) : (
                                  <p>{rowItem}</p>
                                )}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
            <div className="col-sm mt-4 pr-0">
              <div className="justify-content-center card shadow p-3 mb-2">
                <div
                  className="border-bottom-primary card cursor-pointer mb-4 box-shadow-none text-dark"
                  id="UncontrolledTooltipAttendanceTemplate"
                  // href={attendanceTemplate}
                  download
                >
                  <span
                    className="px-3 py-1"
                    onClick={() => {
                      this.downloadXlSheetTemplate()
                    }}
                  >
                    <i className="fal fa-table mr-2"></i>
                    Download Template
                  </span>
                </div>
                <UncontrolledTooltip
                  placement="top"
                  className="bg-danger"
                  // modifiers={tooltipModifiers}
                  target="UncontrolledTooltipAttendanceTemplate"
                >
                  Download offline template to add bulk student marks offline
                  and upload them later
                </UncontrolledTooltip>
                <div className="border-bottom card cursor-pointer mb-2 box-shadow-none">
                  <span className="px-3 py-1">
                    <i className="fal fa-file-upload mr-2"></i>
                    Upload Marks
                    <input
                      type="file"
                      className="cursor-pointer"
                      onChange={event => this.handleDrop(event.target.files)}
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      style={{
                        opacity: 0.0,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </span>
                </div>
                <div className="card cursor-pointer mt-3 box-shadow-none text-dark">
                  <span
                    className="px-3 py-1"
                    onClick={() => {
                      this.downloadXlSheetReport()
                    }}
                  >
                    <i className="fal fa-download mr-2"></i>
                    Download As
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    markSubjectDetails: state.markScreenState.markSubjectDetails,
    markStudentList: state.markScreenState.markStudentList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getMarkSubjectDetails,
      getMarkStudentList,
      saveStudentSubjectMarkObject,
      saveMarkStudentListInStore,
      saveStudentSubjectMarkUploadLockDetail,
      showNotification,
      saveStudentSubjectGenerateAnalyticsDetail,
      getXlsheetTemplate,
      getXlsheetReport,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(TermMarkScreen)
