/*************************************************
 * RedInk
 * @exports
 * @Class StudentSearchAction.js
 * @extends Component
 * @author Alex Ezhil Arasu // on 30/08/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { showNotification } from "../CommonAction"
import { DANGER_TOAST, VALIDATION_MSG } from "../../utils/Constants"
import { ACTION_TYPES } from "../types"
import store from "store"
import _ from "lodash"

export const getStudentSearchDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.SEARCH_STUDENT_DETAILS, body, true)
      if (response) {
        myLog("====getStudentSearchDetails RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getStudentSearchDetails ERROR===????", error)
    }
  }
}

export const getStudentProfileDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_STUDENT_PROFILE_DETAILS,
        body,
        true
      )
      if (response) {
        myLog("====getStudentProfileDetails RESPONSE===::::", response)
        if (response.responseCode === 0) callBack(true, response.studentDetails)
        else callBack(false)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getStudentProfileDetails ERROR===????", error)
    }
  }
}

export const getStudentRemoteLogin = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_STUDENT_REMOTE_LOGIN,
        object,
        true
      )
      if (
        _.has(response.remoteLoginData, "access_token") &&
        _.has(response.remoteLoginData, "token_type") &&
        _.has(response.remoteLoginData, "refresh_token") &&
        _.has(response.remoteLoginData, "expires_in") &&
        _.has(response.remoteLoginData, "userInfo")
      ) {
        store.set("userSession", response.remoteLoginData)
        store.set(
          "expiryTime",
          Client.tokenExpires(response.remoteLoginData.expires_in)
        )
        myLog("====LOGIN RESPONSE===::::", response.remoteLoginData)
        dispatch({
          type: ACTION_TYPES.LOGIN_USER,
          payload: response.remoteLoginData,
        })
        dispatch({
          type: ACTION_TYPES.USER_INFO,
          payload: response.remoteLoginData.userInfo,
        })
        callBack(true, response.remoteLoginData)
      } else {
        callBack(false)
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            VALIDATION_MSG.AUTH_FAILED
          )
        )
      }
    } catch (error) {
      callBack(false)
      myLog("====getStudentRemoteLogin ERROR===????", error)
    }
  }
}
