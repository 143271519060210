/*************************************************
 * RedInk
 * @exports
 * @class Dashboard.js
 * @extends Component
 * @author Sasidharan // on 11/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { createRef, Component } from "react"
import Select, { components } from "react-select"
import { bindActionCreators } from "redux"
import { HashLoader } from "react-spinners"
import { DatePicker } from "antd"
import makeAnimated from "react-select/animated"
import { connect } from "react-redux"
import Skeleton from "react-loading-skeleton"
import debounce from "lodash/debounce"
import moment from "moment"
import {
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Button,
  Input,
  Label,
  Modal,
} from "reactstrap"

import "antd/dist/antd.css"

import {
  getAssignmentList,
  getAssignmentDetails,
  deleteAssignDetails,
  saveAssignDetails,
  getDues,
  getAssignmentAssignedDetails,
} from "../../../actions/teacher/AssignmentAction"
import {
  getTeacherAssignedDetails,
  getClassStudents,
} from "../../../actions/teacher/QuizAction"
import { DANGER_TOAST, VALIDATION_MSG } from "../../../utils/Constants"
import { confirmAlertPopUp } from "../../../utils/Utility"
import { showNotification } from "../../../actions/CommonAction"
import { uploadFile } from "../../../actions/CommonAction"
import { URL_PATH } from "../../../config/urlPath"

import { SelectAssignment } from "../../common/MySelect"
import AppWrapper from "../Wrapper"

import schedule from "../../../assets/images/schedule.png"
import overdue from "../../../assets/images/Overdue.png"

import pdfIc from "../../../assets/images/assignment/pdf.svg"
import pngIc from "../../../assets/images/assignment/PNG.png"
import jpgIc from "../../../assets/images/assignment/JPG.png"
import docIc from "../../../assets/images/assignment/DOC.png"
import pptIc from "../../../assets/images/assignment/PPT.png"
import mp4Ic from "../../../assets/images/assignment/MP4.png"

const { TimePicker } = DatePicker

const Option = props => (
  <div>
    <components.Option {...props}>
      <div className="d-flex align-items-center">
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          className="subject-checkbox"
        />{" "}
        <label className="ml-2 mt-2">{props.label}</label>
      </div>
    </components.Option>
  </div>
)

const allOption = {
  label: "All Students",
  value: "*",
}

const MultiValue = props => {
  let labelToBeDisplayed = `${props.data.label} `
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = "All Students"
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  )
}

const ValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue()
  let toBeRendered = children
  if (currentValues.some(val => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]]
  }

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  )
}

const animatedComponents = makeAnimated()

class AssignmentScreen extends Component {
  constructor(props) {
    document.title = "Assignment | Redink"
    super(props)
    this.descRef = createRef()

    this.state = {
      pastDate: null,
      isDropDownopen: true,
      work: "",
      isMaxMark: false,
      colors: {
        assignment: "#86CAE2",
        homework: "#79C9B0",
        test: "#E5C17A",
        project: "#919DC6",
      },
      assignments: [],
      durationFrom: "",
      attachment: "",
      deleteAttachment: null,
      isShowLoading: true,
      isShowSaveLoading: false,
      isShowModalLoading: false,
      isShowError: false,
      assignedStudents: [],
      assignedSubjects: {},
      isModalClose: false,
      isAssignLaterActive: false,
      studentList: [],
      searchinput: "",
      today: false,
      completed: false,
      upcoming: true,
      overdues: true,
      durationTo: `${moment(new Date(), "YYYY-MM-DD").add(1, "days")}`,
      description: "",
      typeOfWork: "HOMEWORK",
      attachFile: "Yes",
      updateAssignmentId: "",
      weekState: 0,
      savedAssignFrom: null,
      isSearchInputFocused: false,
      createAssignmentModalOpen: false,
      isCurrentWeekVisible: true,
      maxMark: "10",
      noMark: "No marks",
      viewMark: true,
      defaultValue: "",
      subjectDefaultName: "",
      assignWorkLoading: true,
      newData: [],
    }
  }

  sortClassOrder = classess => {
    const classOrder = [
      "-",
      "JKG",
      "LKG",
      "SKG",
      "UKG",
      ...Array(12)
        .fill()
        .map((_, idx) => `${1 + idx}`),
    ]
    const classes = classess
    classes.sort((a, b) => {
      const aClass = a.className || "-"
      const aSection = a.sectionName || "-"
      const bClass = b.className || "-"
      const bSection = b.sectionName || "-"
      if (aClass === bClass) return aSection < bSection ? -1 : 1
      else return classOrder.indexOf(aClass) - classOrder.indexOf(bClass)
    })
    return classes
  }

  componentDidMount() {
    const { firstOfWeek, lastOfWeek } = this.weekPicker(moment())
    this.setState({ recordDate: [firstOfWeek, lastOfWeek] }, () => {
      this.getAllAssignment([firstOfWeek, lastOfWeek])
    })
    this.props.getTeacherAssignedDetails({}, (success, response) => {
      if (success) {
        this.setState(
          {
            classesList: this.sortClassOrder(response.sections),
            subjectsForSections: response.assignedSubject,
          },
          () => {
            const subjectList = this.state.subjectsForSections[
              this.state.classesList ? this.state.classesList[0].sectionId : ""
            ]

            this.setState({
              subjectList,
              assignWorkLoading: false,
            })
          }
        )
      }
    })
    this.props.getDues((success, response) => {
      if (success) {
        this.setState({ assignmentDues: response })
      }
    })

    // this.setState({assignedStudents:this.state.assignedStudents.concat([{label:'All Students', value:'*'}])})
  }

  getWeekState = date => {
    if (this.state.weekState === 0) {
      return "Current Week"
    } else if (this.state.weekState === -1) {
      return "Previous Week"
    } else if (this.state.weekState === 1) {
      return "Next Week"
    } else if (this.state.weekState === "Create") {
      return (
        moment(date[0]).format("DD MMM YYYY") +
        "    -    " +
        moment(date[1]).format("DD MMM YYYY")
      )
    } else {
      return (
        moment(date[0]).format("DD MMM YYYY") +
        "    -    " +
        moment(date[1]).format("DD MMM YYYY")
      )
    }
  }

  clearData = () => {
    this.setState({
      // assignedClass: "",
      // assignedSubjects: "",
      title: "",
      description: "",
      attachment: "",
      deleteAttachment: null,
      typeOfWork: "HOMEWORK",
      isMaxMark: "",
      maxMark: "10",
      durationTo: `${moment(new Date(), "YYYY-MM-DD").add(1, "days")}`,
      isAssignLaterActive: "",
      durationFrom: "",
      durationFromTime: "",
      // assignedStudents: "",
      attachFile: "Yes",
      updateAssignmentId: "",
    })
  }

  defaultPassing = () => {
    let nameClass = this.state.classesList[0].className
    let nameSection = this.state.classesList[0].sectionName
    const classSection = nameClass + "-" + nameSection
    this.setState(prevState => ({
      assignedClass: {
        ...prevState.assignedClass,
        className: nameClass,
        label: classSection,
        school: { board: this.state?.classesList?.[0]?.school?.board || "" },
        sectionId: this.state?.classesList?.[0]?.sectionId || "",
        sectionName: nameSection || "",
        value: this.state?.classesList?.[0]?.sectionId || "",
      },
      assignedSubjects: {
        ...prevState.assignedSubjects,
        label: this.state?.subjectList?.[0]?.subjectName || "",
        masterSubjectId: this.state?.subjectList?.[0]?.masterSubjectId || "",
        subjectId: this.state?.subjectList?.[0]?.subjectId || "",
        subjectName: this.state?.subjectList?.[0]?.subjectName || "",
        value: this.state?.subjectList?.[0]?.subjectId || "",
      },
    }))

    // setTimeout(() => {
    this.getStudentsBySections(
      this.state.classesList ? this.state.classesList[0] : ""
    )
    // }, 1000)
  }

  getAssignedSubject = event => {
    this.setState(prevState => ({
      assignedSubjects: {
        ...prevState.assignedSubjects,
        label: event ? event[0].subjectName : "",
        masterSubjectId: event ? event[0].masterSubjectId : "",
        subjectId: event ? event[0].subjectId : "",
        subjectName: event ? event[0].subjectName : "",
        value: event ? event[0].subjectId : "",
      },
    }))
  }

  getOneDayAssignment = date => {
    this.setState({ isShowLoading: true }, () => {
      let query = {
        startDate: date.startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        endDate: date.endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      }
      if (this.state.today) query.today = true
      if (this.state.completed) query.completed = 1
      if (this.state.upcoming) query.upcoming = true
      if (this.state.overdues) query.overdues = true
      this.props.getAssignmentList(query, (success, response) => {
        if (success) {
          const assignments = response.data
          this.setState({ assignments })
          this.setCountOfType(assignments)
        }
        this.setState({ isShowLoading: false })
      })
    })
  }

  getAllAssignment = (date, search = "") => {
    // myLog("icon Date", date)
    this.setState({ isShowLoading: true }, () => {
      const { completed, searchinput } = this.state

      const dateRange = [moment(date[0]), moment(date[1])]
      const weekRange = [moment(date[0]), moment(date[1])]

      const today = !!this.state.today
      const upcoming = !!this.state.upcoming
      const overdues = !!this.state.overdues

      // const currDate = moment()

      // const startOfDay = moment().startOf("day")
      // const endOfDay = moment().endOf("day")

      // if (
      //   !(upcoming && overdues) &&
      //   currDate > dateRange[0] &&
      //   currDate < dateRange[1]
      // ) {
      //   if (upcoming) dateRange[0] = currDate
      //   if (overdues) dateRange[1] = currDate
      //   if (today && !upcoming && !overdues) {
      //     dateRange[0] = startOfDay
      //     dateRange[1] = endOfDay
      //   } else if (today) {
      //     if (dateRange[0] > startOfDay) dateRange[0] = startOfDay
      //     if (dateRange[1] < endOfDay) dateRange[1] = endOfDay
      //   }
      // } else if (
      //   (currDate > dateRange[1] && !upcoming) ||
      //   (currDate < dateRange[0] && !overdues)
      // ) {
      //   dateRange[0] = dateRange[1] = currDate
      // }

      const query = {}
      if (upcoming) {
        query.startDate = moment(dateRange[0]).format("YYYY-MM-DD HH:mm:ss")
        query.endDate = moment(dateRange[1]).format("YYYY-MM-DD HH:mm:ss")
      }

      if (overdues) {
        query.startDate = moment(dateRange[0]).format("YYYY-MM-DD HH:mm:ss")
        query.endDate = moment(dateRange[1]).format("YYYY-MM-DD HH:mm:ss")
        query.overdues = true
      }

      if (today) {
        query.startDate = moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
        query.endDate = moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
      }
      if (completed) {
        query.completed = 1
        query.startWeek = moment(weekRange[0]).format("YYYY-MM-DD HH:mm:ss")
        query.endWeek = moment(weekRange[1]).format("YYYY-MM-DD HH:mm:ss")
      }

      if (upcoming && completed) {
        query.completed = 1
        query.startWeek = moment(weekRange[0]).format("YYYY-MM-DD HH:mm:ss")
        query.endWeek = moment(weekRange[1]).format("YYYY-MM-DD HH:mm:ss")
      }

      if (today && completed) {
        query.startDate = moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
        query.endDate = moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
        query.completed = 1
      }
      if (today && overdues) {
        query.startDate = moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
        query.endDate = moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
        query.overdues = true
      }

      if (overdues && completed) {
        query.startDate = moment(dateRange[0]).format("YYYY-MM-DD HH:mm:ss")
        query.endDate = moment(dateRange[1]).format("YYYY-MM-DD HH:mm:ss")
        query.overdues = true
        query.completed = 1
      }

      if (search || searchinput) {
        query.q = (search || searchinput).trim()
      }

      this.props.getAssignmentList(query, (success, response) => {
        if (this.state.searchinput !== search) {
          // myLog(
          //   "Not setting state as search input is diff",
          //   this.state.searchinput,
          //   search
          // )
          return
        }
        if (success) {
          const assignments = response.data
          this.setState({ assignments })
          this.setCountOfType(assignments)
        }
        this.setState({ isShowLoading: false })
      })
    })
  }

  setCountOfType = assignments => {
    const assignmentCount = { HOMEWORK: 0, ASSIGNMENT: 0, TEST: 0, PROJECT: 0 }
    for (const { assignmentDetails } of assignments) {
      for (const assignment of assignmentDetails) {
        assignmentCount[assignment.typeOfWork]++
      }
    }
    this.setState({ assignmentCount })
  }

  weekPicker = date => ({
    firstOfWeek: moment(date)
      .startOf("day")
      .isoWeekday("Monday"),
    lastOfWeek: moment(date)
      .endOf("day")
      .isoWeekday("Sunday"),
  })

  getStudentsBySections = selectedSections => {
    this.setState({ studentList: [] })

    this.props.getClassStudents(
      selectedSections.sectionId,
      (success, response) => {
        if (success) {
          this.setState({
            studentList: response,
            assignedStudents: [{ label: "All Students", value: "*" }].concat(
              response.map(obj => ({
                // ...obj,
                fullname: obj.fullName,
                label: obj.fullName,
                value: obj.studentId,
                grade: obj.grade,
                studentId: obj.studentId,
                studentName: obj.studentName,
                gender: obj.gender,
                registerRollNumber: obj.registerRollNumber,
              }))
            ),
            // assignedStudents:[{label:'All Students', value:'*'}].concat(response.map(obj=>obj) )]
          })
        }
      }
    )
  }

  onRadioChange = e => {
    this.setState({
      typeOfWork: e.target.value,
    })
  }

  onRadioAttachFileChange = e => {
    this.setState({
      attachFile: e.target.value,
    })
  }

  findColor = typeOfWork => {
    let color = ""
    if (typeOfWork === "ASSIGNMENT") {
      color = "#86CAE2"
    } else if (typeOfWork === "TEST") {
      color = "#E5C17A"
    } else if (typeOfWork === "HOMEWORK") {
      color = "#79C9B0"
    } else if (typeOfWork === "PROJECT") {
      color = "#919DC6"
    }
    return color
  }

  toggleDropdown = () => {
    this.setState({
      isDropDownopen: !this.state.isDropDownopen,
    })
  }

  parseFileName = (fileName, location) => {
    if (typeof fileName !== "string") return ""

    const removedLocation = fileName.replace(location, "")
    return decodeURI(removedLocation.slice(removedLocation.indexOf("_") + 1))
  }

  saveAssignment = async () => {
    const {
      updateAssignmentId,
      assignedSubjects,
      assignedStudents,
      assignedClass,
      title,
      description,
      attachment,
      isAssignLaterActive,
      typeOfWork,
      attachFile,
      maxMark,
      isMaxMark,
      savedAssignFrom,
      durationFromTime,
    } = this.state
    const durationTo = moment(this.state.durationTo).endOf("day")

    let durationFrom = this.state.durationFrom

    if (durationFrom) {
      durationFrom = durationFromTime
        ? `${durationFrom} ${durationFromTime}`
        : durationFrom
      durationFrom = moment(durationFrom)
    }

    const errorHandling = msg =>
      this.props.showNotification(DANGER_TOAST, VALIDATION_MSG.OOPS, msg)

    if (!assignedClass) {
      return errorHandling("Select a class...")
    }
    if (!assignedSubjects) {
      return errorHandling("Select a subject...")
    }
    if (assignedStudents.length === 0) {
      return errorHandling("Select at least one student...")
    }
    if (!title) {
      return errorHandling("Enter the title field...")
    }
    if (title?.length > 255) {
      return errorHandling("Title is limited to 255 characters...")
    }
    if (this.state.isAssignLaterActive && !this.state.durationFrom) {
      return errorHandling("Select date & time to assign later ")
    }
    if (description.length > 255) {
      return errorHandling("Description is limited to 255 characters...")
    }
    if (typeOfWork === "") {
      return errorHandling("Select type of work...")
    }
    if (attachFile === "") {
      return errorHandling("Select student attach any file for this work...")
    }
    if (!this.state.durationTo) {
      return errorHandling("Select due date...")
    }
    if (!updateAssignmentId && durationTo < moment()) {
      return errorHandling("Select correct due date...")
    }
    if (
      !updateAssignmentId &&
      durationFrom &&
      moment(durationFrom).isAfter(durationTo)
    ) {
      return errorHandling("Select correct assign later and due date...")
    }
    if (
      savedAssignFrom &&
      durationFrom &&
      moment(savedAssignFrom).isAfter(durationFrom)
    ) {
      return errorHandling("Assign later date cannot be preponed...")
    }
    if (isMaxMark && !maxMark) {
      return errorHandling("Select max mark...")
    }
    if (maxMark < 0) {
      return errorHandling("Max mark cannot be negative")
    }
    if (isAssignLaterActive && durationFrom === "" && durationFromTime === "") {
      return errorHandling("Add assign later date and time...")
    }

    this.setState({ isShowError: false, isShowSaveLoading: true })

    let students = []
    assignedStudents.forEach(item => {
      if (item.label !== "All Students") {
        students.push(item.studentId)
      }
    })
    let attachmentUrl = ""

    if (attachment && !attachment.size) {
      // if updating item with an old attachment, attachment = { name }

      attachmentUrl = attachment.name
    } else if (attachment) {
      const upload = await new Promise(resolve =>
        this.props.uploadFile(
          attachment,
          "assignment/teacherUploads",
          (success, response) => resolve({ success, response })
        )
      )
      if (upload.success) attachmentUrl = upload.response.name
    }

    let data = {
      title,
      description,
      sectionId: assignedClass.sectionId,
      subjectId: assignedSubjects.subjectId,
      attachment: attachmentUrl,
      typeOfWork: typeOfWork,
      fileSubmission: attachFile === "No" ? 0 : 1,
      maxMark: maxMark || "",
      studentIds: students,
    }
    if (updateAssignmentId) data.id = updateAssignmentId
    if (durationFrom) {
      data.durationFrom = moment.utc(moment(durationFrom)).format()
    }
    if (durationTo) {
      data.durationTo = moment.utc(moment(durationTo).endOf("day")).format()
    }

    this.props.saveAssignDetails(data, (success, response) => {
      if (success) {
        let isInCurrentWeek = durationTo.isSame(new Date(), "week")
        const { firstOfWeek, lastOfWeek } = this.weekPicker(
          isInCurrentWeek ? moment() : moment(durationTo)
        )
        this.setState(
          {
            recordDate: [firstOfWeek, lastOfWeek],
            weekState: isInCurrentWeek ? 0 : "Create",
          },
          () => {
            this.getAllAssignment([firstOfWeek, lastOfWeek])
          }
        )
        this.clearData()
        this.setState({
          isShowSaveLoading: false,
          createAssignmentModalOpen: false,
        })
      } else {
        this.setState({ isShowSaveLoading: false })
      }
    })
  }

  setUpdateAssignmentModal = assignmentId => {
    this.setState({ isShowModalLoading: true })

    this.props.getAssignmentDetails(assignmentId, (success, response) => {
      this.props.getAssignmentAssignedDetails(
        assignmentId,
        (success1, response1) => {
          const assignedStudents = response1.assignedStudents.map(e => {
            const { studentId, studentName, fullName } = e.student
            const name = studentName || fullName
            return {
              grade: { id: sectionId },
              id: studentId,
              studentId,
              label: name,
              value: studentId,
              name: name,
              updateId: e.id,
            }
          })
          this.setState({
            assignedStudents,
          })
        }
      )
      this.setState({ isShowModalLoading: false })
      this.setState({ updateAssignmentId: assignmentId })
      const { assignment } = response
      // const { studentAssignments } = assignment
      const { section, subject } = assignment
      const { className, sectionName, sectionId } = section
      const { subjectName, subjectId } = subject

      this.props.getClassStudents(sectionId, (success, response) => {
        if (success) {
          this.setState({ studentList: response })
        }
      })
      this.props.getTeacherAssignedDetails({}, (success, response) => {
        if (success) {
          this.setState({
            classesList: response.sections,
            subjectsForSections: response.assignedSubject,
            subjectList: response.assignedSubject[sectionId],
          })
        }
      })

      const assignedClass = {
        id: sectionId,
        sectionId,
        class: `${className}-${sectionName}`,
        label: `${className}-${sectionName}`,
        value: `${className}-${sectionName}`,
      }

      const assignedSubjects = {
        id: subjectId,
        subjectId: subjectId,
        label: subjectName,
        value: subjectName,
        subjectName: subjectName,
      }

      // const assignedStudents = studentAssignments.map(e => {
      //   const { studentId, studentName, fullName } = e.student
      //   const name = studentName || fullName

      //   return {
      //     grade: { id: sectionId },
      //     id: studentId,
      //     studentId,
      //     label: name,
      //     value: studentId,
      //     name: name,
      //     updateId: e.id,
      //   }
      // })

      const {
        title,
        description,
        attachment,
        typeOfWork,
        maxMark,
        fileSubmission,
        durationFrom,
        durationTo,
      } = assignment

      const isAssignLaterActive =
        durationFrom && moment(durationFrom) > moment()

      this.setState({
        assignedClass,
        assignedSubjects,
        title,
        description,
        typeOfWork,
        isMaxMark: maxMark ? true : false,
        // maxMark : assignment.maxMark === null ? 'UnMarked' : assignment.maxMark ,
        maxMark:
          assignment.maxMark == "0" ? this.state.noMark : assignment.maxMark,
        attachFile: fileSubmission ? "Yes" : "No",
        durationTo: moment(durationTo).format("YYYY-MM-DD"),
        isAssignLaterActive,
        savedAssignFrom: moment(durationFrom),
        durationFrom: isAssignLaterActive && moment(durationFrom),
        durationFromTime:
          isAssignLaterActive && moment(durationFrom).format("HH:mm:ss"),
        // assignedStudents,
      })
      if (attachment) this.setState({ attachment: { name: attachment } })
    })
  }

  deteleAssignment = ass => {
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT,
      yes => {
        if (yes) {
          this.props.deleteAssignDetails(ass.id, (success, response) => {
            if (success) this.getAllAssignment(this.state.recordDate)
          })
        }
      },
      {
        customUI: ({ onClose }) => {
          return (
            <div
              className="custom"
              style={{
                backgroundColor: "#fff",

                borderBottomRightRadius: "12px",
                borderBottomLeftRadius: "12px",
              }}
            >
              <div
                className="bg-brand text-light d-flex"
                style={{
                  padding: "0.5rem 2rem",
                  borderTopRightRadius: "12px",
                  borderTopLeftRadius: "12px",
                }}
              >
                <div className="mx-auto" style={{ fontSize: "18px" }}>
                  Delete
                </div>
              </div>

              <div
                className="mt-3"
                style={{
                  padding: "0.5rem 1.5rem",
                }}
              >
                <div className="text-muted" style={{ fontSize: "15px" }}>
                  Are you sure you want to delete?
                </div>
                <div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
                  <button
                    className="bg-brand text-light px-4 py-1 w-25 d-flex align-items-center justify-content-center yes-button"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      this.props.deleteAssignDetails(
                        ass.id,
                        (success, response) => {
                          if (success)
                            this.getAllAssignment(this.state.recordDate)
                        }
                      )
                      onClose()
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-brand text-light px-4 py-1 ml-4 w-25 d-flex align-items-center justify-content-center"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={onClose}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )
        },
      }
    )
  }

  getNotCompletedPercentage = assign => {
    let value = 100 - assign.submissionProgress.percentage
    return value + "%"
  }

  searchFunction = debounce(
    () => this.getAllAssignment(this.state.recordDate, this.state.searchinput),
    600
  )

  viewMark = () => {
    var field = document.getElementsByClassName("noMark")[0]
    this.setState({ viewMark: !this.state.viewMark })
    this.state.viewMark
      ? (field.style.display = "block")
      : (field.style.display = "none")
  }

  styles = {
    option: (provided, state) => ({
      ...provided,
      color: "black",
      backgroundColor: "white",
    }),
    multiValueRemove: () => ({
      display: "none",
    }),
  }

  styleStudent = {
    option: (provided, state) => ({
      ...provided,
      color: "black",
      backgroundColor: "white",
    }),
    multiValueRemove: () => ({
      display: "none",
    }),
  }

  focusDesc = () => {
    this.descRef.current.focus()
  }

  render() {
    const { firstOfWeek, lastOfWeek } = this.weekPicker(moment())
    const isTodaySelected =
      moment(new Date()).format("YYYY-MM-DD") !==
      moment(this.state.pastDate).format("YYYY-MM-DD")
        ? false
        : true

    return (
      <div>
        <Modal
          isOpen={this.state.createAssignmentModalOpen}
          modalClassName="right slide-in-right fade"
        >
          <div
            className="modal-body p-2"
            style={{ backgroundColor: "#F1F7F7", borderRadius: 12 }}
          >
            <div className="d-flex justify-content-between px-4 pt-3">
              <h5
                className="mb-4 text-brand font-weight-bold"
                id="myModalLabel2"
                onClick={() => {}}
              >
                Assign Work
              </h5>
              <div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    this.clearData()
                    this.setState({ createAssignmentModalOpen: false })
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>

            <div className="modal-body">
              {this.state.isShowModalLoading && (
                <div
                  className="d-flex justify-content-center"
                  style={{ marginTop: 150 }}
                >
                  <HashLoader
                    size={35}
                    color="#084353"
                    sizeUnit="px"
                    loading={this.state.isShowModalLoading}
                  />
                </div>
              )}
              {!this.state.isShowModalLoading && (
                <div>
                  <div className="px-2 row">
                    <div className="form-group col-sm-6">
                      <label htmlFor="class" className="font-weight-bold">
                        Class<span>*</span>
                      </label>
                      <Select
                        placeholder="Select Class"
                        isClearable
                        value={this.state.assignedClass}
                        options={
                          this.state.classesList
                            ? this.state.classesList.map(item => ({
                                value: item.sectionId,
                                label: `${item.className}-${item.sectionName} `,
                                ...item,
                              }))
                            : []
                        }
                        className="mb-3 basic-multi-select"
                        classNamePrefix="select"
                        hideSelectedOptions={false}
                        closeMenuOnSelect={true}
                        isDisabled={
                          !!this.state.updateAssignmentId &&
                          moment(new Date()).format("YYYY-MM-DD") >
                            this.state.durationTo
                        }
                        defaultValue={this.state.assignedClass}
                        onChange={selectedOption => {
                          if (selectedOption !== null) {
                            this.setState({
                              assignedClass: selectedOption,
                              subjectList: this.state.subjectsForSections[
                                selectedOption.sectionId
                              ],
                              // assignedSubjects: {
                              //   // ...prevState.assignedSubjects,
                              //   label: this.state.subjectList ? this.state.subjectList[0].subjectName : '',
                              //   masterSubjectId: this.state.subjectList ? this.state.subjectList[0].masterSubjectId : '',
                              //   subjectId: this.state.subjectList ? this.state.subjectList[0].subjectId : '',
                              //   subjectName: this.state.subjectList ? this.state.subjectList[0].subjectName : '',
                              //   value: this.state.subjectList ? this.state.subjectList[0].subjectId : '',
                              // }
                            })
                            this.getAssignedSubject(
                              this.state.subjectsForSections[
                                selectedOption.sectionId
                              ]
                            )
                            this.getStudentsBySections(selectedOption)
                          } else {
                            this.setState({ assignedClass: selectedOption })
                          }
                        }}
                      />
                    </div>
                    <div className="px-3 form-group col-sm-6">
                      <label htmlFor="Subject" className="font-weight-bold">
                        Subject<span>*</span>
                      </label>
                      <Select
                        placeholder="Select Subject"
                        isClearable
                        value={this.state.assignedSubjects}
                        options={
                          this.state.subjectList
                            ? this.state.subjectList.map(item => ({
                                value: item.subjectId,
                                label: item.subjectName,
                                ...item,
                              }))
                            : []
                        }
                        className="mb-3 basic-multi-select"
                        classNamePrefix="select"
                        closeMenuOnSelect={true}
                        hideSelectedOptions={false}
                        isDisabled={
                          !!this.state.updateAssignmentId &&
                          moment(new Date()).format("YYYY-MM-DD") >
                            this.state.durationTo
                        }
                        onChange={selectedOption => {
                          if (selectedOption !== null) {
                            this.setState({
                              assignedSubjects: selectedOption,
                              assignedSubjectId: selectedOption.id,
                              assignedSubjectName: selectedOption.value,
                            })
                          } else {
                            this.setState({
                              assignedSubjects: selectedOption,
                            })
                          }
                        }}
                        defaultValue={this.state.assignedSubjects}
                        styles={this.styles}
                      />
                    </div>
                  </div>
                  <div className="px-2 row">
                    <div className="form-group col-sm-12">
                      <label htmlFor="class" className="font-weight-bold">
                        Student<span>*</span>
                      </label>
                      <SelectAssignment
                        placeholder="Select Student"
                        name="classes"
                        isClearable
                        value={this.state.assignedStudents}
                        options={
                          this.state.studentList
                            ? this.state.studentList.map(item => ({
                                value: item.studentId,
                                label: item.fullName,
                                ...item,
                              }))
                            : []
                        }
                        className="mb-3 basic-multi-select"
                        classNamePrefix="select"
                        isMulti
                        allowSelectAll={true}
                        components={{
                          Option,
                          MultiValue,
                          ValueContainer,
                          animatedComponents,
                        }}
                        hideSelectedOptions={false}
                        closeMenuOnSelect={false}
                        isDisabled={
                          !!this.state.updateAssignmentId &&
                          moment(new Date()).format("YYYY-MM-DD") >
                            this.state.durationTo
                        }
                        onChange={selectedOption => {
                          this.setState({
                            assignedStudents: selectedOption,
                          })
                        }}
                        styles={this.styleStudent}
                      />
                    </div>
                  </div>
                  <div className="px-2 form-group">
                    <label htmlFor="Title" className="font-weight-bold">
                      Title<span>*</span>
                    </label>
                    <input
                      disabled={
                        !!this.state.updateAssignmentId &&
                        moment(new Date()).format("YYYY-MM-DD") >
                          this.state.durationTo
                      }
                      type="text"
                      className="form-control br-10"
                      name="Title"
                      value={this.state.title}
                      onChange={e => this.setState({ title: e.target.value })}
                      required
                      autoFocus
                      onKeyDown={e => {
                        if (e.key === "Enter") {
                          this.focusDesc()
                        }
                      }}
                    />
                  </div>
                  <div className="px-2 form-group">
                    <label htmlFor="Description" className="font-weight-bold">
                      Description
                    </label>
                    <textarea
                      ref={this.descRef}
                      disabled={
                        !!this.state.updateAssignmentId &&
                        moment(new Date()).format("YYYY-MM-DD") >
                          this.state.durationTo
                      }
                      className="form-control br-10"
                      name="Description"
                      rows="5"
                      value={this.state.description}
                      onChange={e =>
                        this.setState({ description: e.target.value })
                      }
                    ></textarea>
                  </div>

                  {this.state.attachment === "" && (
                    <div className="mb-2 row">
                      <span className="ml-2 col-sm font-weight-bold fs-12 text-primary">
                        <label>
                          <input
                            disabled={
                              !!this.state.updateAssignmentId &&
                              moment(new Date()).format("YYYY-MM-DD") >
                                this.state.durationTo
                            }
                            type="file"
                            accept=".pdf,.gif,.jpg,.jpeg,.png,.doc,.docx,.ppt,.pptx,.mp4,.mov,.wmv,.avi"
                            style={{ display: "none" }}
                            onChange={e => {
                              const file = e.target.files[0]
                              if (file.size > 50 * 1000 * 1000) {
                                this.props.showErrorNoti(
                                  DANGER_TOAST,
                                  VALIDATION_MSG.OOPS,
                                  "File is too big (limit: 50MB)"
                                )
                                return
                              }
                              if (
                                file.name.endsWith(".pdf") ||
                                file.name.endsWith(".gif") ||
                                file.name.endsWith(".jpg") ||
                                file.name.endsWith(".jpeg") ||
                                file.name.endsWith(".png") ||
                                file.name.endsWith(".doc") ||
                                file.name.endsWith(".docx") ||
                                file.name.endsWith(".ppt") ||
                                file.name.endsWith(".pptx") ||
                                file.name.endsWith(".mp4") ||
                                file.name.endsWith(".mov") ||
                                file.name.endsWith(".wmv") ||
                                file.name.endsWith(".avi")
                              ) {
                                this.setState({
                                  attachment: file,
                                  deleteAttachment: false,
                                })
                              } else {
                                this.props.showNotification(
                                  DANGER_TOAST,
                                  VALIDATION_MSG.OOPS,
                                  "Attach a pdf/image/doc/video file..."
                                )
                              }
                            }}
                          />
                          <u>+ Add Attachment</u>
                          <i className="pl-0 pl-1 ml-0 fa fa-paperclip col-sm-1 text-primary fs-15" />
                        </label>
                      </span>
                    </div>
                  )}

                  {this.state.attachment && (
                    <div className="m-2 mt-0 rounded-lg d-flex bg-theme-verylight align-items-center">
                      <div className="p-3 rounded bg-theme-light">
                        {this.state.attachment.name.endsWith(".pdf") ? (
                          <img src={pdfIc} alt="pdf" width="30" height="30" />
                        ) : this.state.attachment.name.endsWith(".png") ? (
                          <img src={pngIc} alt="pdf" width="30" height="30" />
                        ) : this.state.attachment.name.endsWith(".jpg") ||
                          this.state.attachment.name.endsWith(".jpeg") ? (
                          <img src={jpgIc} alt="pdf" width="30" height="30" />
                        ) : this.state.attachment.name.endsWith(".doc") ||
                          this.state.attachment.name.endsWith(".docx") ? (
                          <img src={docIc} alt="pdf" width="30" height="30" />
                        ) : this.state.attachment.name.endsWith(".ppt") ||
                          this.state.attachment.name.endsWith(".pptx") ? (
                          <img src={pptIc} alt="pdf" width="30" height="30" />
                        ) : (
                          <img src={mp4Ic} alt="pdf" width="30" height="30" />
                        )}
                      </div>
                      <div className="ml-3">
                        {this.parseFileName(
                          this.state.attachment.name,
                          "assignment/teacherUploads/"
                        )}
                      </div>
                      <div className="p-2 ml-auto mr-3">
                        <i
                          className="cursor-pointer far fa-times fa-clock-hover"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Delete file"
                          onClick={() => {
                            this.setState({
                              attachment: "",
                              deleteAttachment: true,
                            })
                          }}
                        />
                      </div>
                    </div>
                  )}

                  <div className="px-2 form-group">
                    <label htmlFor="work" className="font-weight-bold">
                      Type of work<span>*</span>
                    </label>
                    {/* <div className="row">
                       <div className="ml-3 radio">
                         <label>
                           <input
                             disabled={!!this.state.updateAssignmentId}
                             type="radio"
                             name="work"
                             value="HOMEWORK"
                             checked={this.state.typeOfWork === "HOMEWORK"}
                             onChange={this.onRadioChange}
                           />
                           &ensp;
                           <span style={{ verticalAlign: "text-bottom" }}>
                             Homework
                           </span>
                         </label>
                       </div>
                       <div className="px-5 radio">
                         <label>
                           <input
                             disabled={!!this.state.updateAssignmentId}
                             type="radio"
                             name="work"
                             value="PROJECT"
                             checked={this.state.typeOfWork === "PROJECT"}
                             onChange={this.onRadioChange}
                           />
                           &ensp;
                           <span style={{ verticalAlign: "text-bottom" }}>
                             Project
                           </span>
                         </label>
                       </div>
                       <div className="radio">
                         <label>
                           <input
                             disabled={!!this.state.updateAssignmentId}
                             className="work-type"
                             type="radio"
                             name="work"
                             value="ASSIGNMENT"
                             checked={this.state.typeOfWork === "ASSIGNMENT"}
                             onChange={this.onRadioChange}
                           />
                           &ensp;
                           <span
                             className="type-label"
                             style={{ verticalAlign: "text-bottom" }}
                           >
                             Assignment
                           </span>
                         </label>
                       </div> */}
                    {/* 
                         Developer comment: No need this functionality for now by Vijay
                         <div className="px-5 radio">
                           <label>
                             <input
                               type="radio"
                               name="work"
                               value="TEST"
                               checked={this.state.typeOfWork === "TEST"}
                               onChange={this.onRadioChange}
                             />
                             &ensp;Test
                           </label>
                         </div> */}
                    {/* </div> */}
                    <div className="row px-3">
                      <div className="d-flex" style={{ flexGrow: 1 }}>
                        <input
                          type="radio"
                          className="h-radio"
                          name="work"
                          id="homework"
                          // disabled={!!this.state.updateAssignmentId}
                          value="HOMEWORK"
                          checked={this.state.typeOfWork === "HOMEWORK"}
                          defaultChecked
                          onChange={this.onRadioChange}
                        />
                        <label className="h-label" htmlFor="homework">
                          Homework
                        </label>
                      </div>

                      <div className="d-flex ml-2" style={{ flexGrow: 3 }}>
                        <input
                          type="radio"
                          className="p-radio"
                          name="work"
                          id="project"
                          // disabled={!!this.state.updateAssignmentId}
                          value="PROJECT"
                          checked={this.state.typeOfWork === "PROJECT"}
                          onChange={this.onRadioChange}
                        />
                        <label className="p-label" htmlFor="project">
                          Project
                        </label>
                      </div>

                      <div className="d-flex ml-2" style={{ flexGrow: 1 }}>
                        <input
                          type="radio"
                          className="a-radio"
                          name="work"
                          id="assignment"
                          value="ASSIGNMENT"
                          checked={this.state.typeOfWork === "ASSIGNMENT"}
                          onChange={this.onRadioChange}
                        />
                        <label className="a-label" htmlFor="assignment">
                          Assignment
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* <div className="px-2 form-group">
                     <div className="checkbox">
                       <label>
                         <input
                           disabled={
                             !!this.state.updateAssignmentId &&
                             moment(new Date()).format("YYYY-MM-DD") >
                               this.state.durationTo
                           }
                           type="checkbox"
                           id="isMaxMark"
                           name="isMaxMark"
                           checked={this.state.isMaxMark}
                           value={this.state.isMaxMark}
                           onChange={() => {
                             this.setState({
                               maxMark: "",
                               isMaxMark: !this.state.isMaxMark,
                             })
                           }}
                         />
                         &ensp;Need to award marks for the work ?
                       </label>
                     </div>
                   </div>
                   {this.state.isMaxMark ? (
                     <div className="px-2 form-group">
                       <div className="px-3 row">
                         <label htmlFor="MaxMark" className="font-weight-bold">
                           Max Mark<span>*</span>
                         </label>{" "}
                         &ensp;
                         <input
                           type="number"
                           className="form-control markwidth br-10"
                           style={{ width: "100px" }}
                           name="maxmark"
                           value={this.state.maxMark}
                           onChange={e => {
                             const value = e.target.value
                             if (value.length < 4) {
                               this.setState({ maxMark: e.target.value })
                             }
                           }}
                           required
                         />
                       </div>
                     </div>
                   ) : null} */}
                  <div className="px-2">
                    <label className="font-weight-bold">Max. Marks</label>
                  </div>
                  <div
                    className="ml-3 form-group"
                    onClick={() => this.viewMark()}
                  >
                    <div className="maxmark">
                      <input
                        type="text"
                        value={this.state.maxMark}
                        name="markValue"
                        // placeholder="Enter Mark"
                        onChange={e => {
                          e.target.value = e.target.value.replace(
                            /[^A-Za-z0-9]/gi,
                            ""
                          )
                          e.target.value =
                            e.target.value === "No marks" ? "" : e.target.value
                          this.setState({ maxMark: e.target.value })
                        }}
                      />
                      <button
                        onClick={e => {
                          // this.setState({ maxMark: "" })
                          this.viewMark()
                        }}
                      >
                        <i className="fas fa-caret-down"></i>
                      </button>
                    </div>
                    <div className="noMark" style={{ cursor: "pointer" }}>
                      <p
                        style={{ marginTop: "6px" }}
                        onClick={() => {
                          this.setState({
                            maxMark: this.state.noMark,
                            viewMark: false,
                          })
                          this.viewMark()
                        }}
                      >
                        {this.state.noMark}
                      </p>
                    </div>
                  </div>

                  <div className="px-2 form-group">
                    <label className="font-weight-bold">
                      Should the student attach any file for this work?
                      <span>*</span>{" "}
                    </label>
                    <div className="row">
                      <div className="px-5 radio">
                        <label>
                          <input
                            disabled={
                              !!this.state.updateAssignmentId &&
                              moment(new Date()).format("YYYY-MM-DD") >
                                this.state.durationTo
                            }
                            type="radio"
                            name="attachFile"
                            value="Yes"
                            checked={this.state.attachFile === "Yes"}
                            onChange={this.onRadioAttachFileChange}
                          />
                          &ensp;Yes
                        </label>
                      </div>
                      <div className="px-5 radio">
                        <label>
                          <input
                            disabled={
                              !!this.state.updateAssignmentId &&
                              moment(new Date()).format("YYYY-MM-DD") >
                                this.state.durationTo
                            }
                            type="radio"
                            name="attachFile"
                            value="No"
                            checked={this.state.attachFile === "No"}
                            onChange={this.onRadioAttachFileChange}
                          />
                          &ensp;No
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="px-2 form-group col-sm-8">
                    <label htmlFor="Duedate" className="font-weight-bold">
                      Due Date<span>*</span>
                    </label>
                    <DatePicker
                      className="form-control"
                      placeholder="Select date"
                      value={
                        this.state.durationTo
                          ? moment(this.state.durationTo)
                          : ""
                      }
                      format="YYYY-MM-DD"
                      size="large"
                      onChange={e => {
                        this.setState({ durationTo: e })
                      }}
                      disabledDate={current =>
                        current && current < moment().startOf("day")
                      }
                      defaultValue={moment(new Date(), "YYYY-MM-DD").add(
                        1,
                        "days"
                      )}
                    />
                  </div>
                  <div className="px-2 form-group">
                    <div className="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          id="isAssignLaterActive"
                          name="isAssignLaterActive"
                          checked={this.state.isAssignLaterActive}
                          value={this.state.isAssignLaterActive}
                          onChange={() => {
                            this.setState(
                              {
                                isAssignLaterActive: !this.state
                                  .isAssignLaterActive,
                              },
                              () => {
                                if (!this.state.isAssignLaterActive) {
                                  this.setState({
                                    durationFromTime: "",
                                    durationFrom: "",
                                  })
                                }
                              }
                            )
                          }}
                        />
                        &ensp;Assign later?
                      </label>
                    </div>
                  </div>
                  {this.state.isAssignLaterActive ? (
                    <div className="mb-3">
                      <FormGroup>
                        <Label className="mb-1" htmlFor="">
                          Date & Time
                        </Label>
                        <div style={{ display: "block" }}>
                          <DatePicker
                            style={{ width: "150px", display: "inline-flex" }}
                            className="form-control"
                            placeholder="Select date"
                            value={
                              this.state.durationFrom
                                ? moment(this.state.durationFrom)
                                : ""
                            }
                            format="YYYY-MM-DD"
                            size="large"
                            onChange={e => {
                              this.setState({ durationFrom: e })
                            }}
                            disabledDate={current =>
                              current && current < moment().startOf("day")
                            }
                            allowClear={true}
                          />

                          <TimePicker
                            style={{ width: "120px", display: "inline-flex" }}
                            className="form-control ml-4"
                            placeholder="Time"
                            value={
                              this.state.durationFrom
                                ? moment(this.state.durationFrom)
                                : ""
                            }
                            format="HH:mm"
                            minuteStep={5}
                            size="large"
                            onChange={e => {
                              this.setState({ durationFrom: e })
                            }}
                            disabledHours={() => {
                              var hours = []
                              for (var i = 0; i < moment().hour(); i++) {
                                if (
                                  moment(this.state.durationFrom).format(
                                    "YYYY-MM-DD"
                                  ) === moment().format("YYYY-MM-DD")
                                ) {
                                  hours.push(i)
                                }
                              }
                              return hours
                            }}
                            disabledMinutes={selectedHour => {
                              var minutes = []
                              if (selectedHour === moment().hour()) {
                                for (var i = 0; i < moment().minute(); i++) {
                                  if (
                                    moment(this.state.durationFrom).format(
                                      "YYYY-MM-DD"
                                    ) === moment().format("YYYY-MM-DD")
                                  ) {
                                    minutes.push(i)
                                  }
                                }
                              }
                              return minutes
                            }}
                          />
                        </div>
                      </FormGroup>
                    </div>
                  ) : null}
                  <div className="d-flex align-items-center justify-content-around">
                    <Button
                      className="bg-brand text-light px-4 py-1"
                      onClick={this.saveAssignment}
                      disabled={this.state.isShowSaveLoading}
                      style={{ borderRadius: 12, border: 0 }}
                    >
                      {this.state.isShowSaveLoading ? (
                        <HashLoader
                          sizeUnit="px"
                          size={16}
                          color="#fff"
                          loading={this.state.isShowSaveLoading}
                        />
                      ) : (
                        "Assign"
                      )}
                    </Button>

                    <Button
                      color="light"
                      className="text-muted px-4 py-1"
                      style={{ borderRadius: 12, border: 0 }}
                      data-dismiss="modal"
                      onClick={() => {
                        this.setState({ createAssignmentModalOpen: false })
                        this.clearData()
                      }}
                    >
                      Cancel
                    </Button>
                    <span></span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal>

        <AppWrapper
          teacherDashboard
          showTeacherThree
          showTeacherLogo
          screenName="Assignment"
        >
          <div>
            <div className="mx-3 mb-3 d-flex align-items-center">
              <div
                className="d-flex align-items-center"
                style={{
                  opacity: this.state.isSearchInputFocused ? "0" : "1",
                  transition: "150ms all ease-in",
                }}
              >
                {isTodaySelected ? (
                  <button
                    className="mr-2 btn btn-light br-10"
                    style={
                      isTodaySelected
                        ? {}
                        : { backgroundColor: "#13af8f", color: "#fff" }
                    }
                    disabled={isTodaySelected}
                    onClick={() => {
                      this.setState(
                        {
                          recordDate: [firstOfWeek, lastOfWeek],
                          pastDate: null,
                        },
                        () => {
                          this.getAllAssignment([firstOfWeek, lastOfWeek])
                        }
                      )
                    }}
                  >
                    Today
                  </button>
                ) : !this.state.isCurrentWeekVisible ||
                  this.state.weekState !== 0 ? (
                  <button
                    className="mr-2 btn btn-light br-10"
                    style={{ backgroundColor: "#13af8f", color: "#fff" }}
                    onClick={() => {
                      this.setState(
                        {
                          recordDate: [firstOfWeek, lastOfWeek],
                          pastDate: null,
                          isCurrentWeekVisible: true,
                          today: true,
                          completed: false,
                          upcoming: true,
                          overdues: true,
                          weekState: 0,
                        },
                        () => {
                          this.getAllAssignment([firstOfWeek, lastOfWeek])
                        }
                      )
                    }}
                  >
                    Refresh
                  </button>
                ) : (
                  <button
                    className="mr-2 btn btn-secondary br-10"
                    style={{ color: "#fff" }}
                    onClick={() => {
                      this.setState(
                        {
                          recordDate: [firstOfWeek, lastOfWeek],
                          pastDate: null,
                          isCurrentWeekVisible: true,
                          today: true,
                          completed: false,
                          upcoming: true,
                          overdues: true,
                          weekState: 0,
                        },
                        () => {
                          this.getAllAssignment([firstOfWeek, lastOfWeek])
                        }
                      )
                    }}
                  >
                    Refresh
                  </button>
                )}

                <div className="p-1 d-flex align-items-center">
                  <DatePicker
                    format="DD MMM YYYY"
                    allowClear={false}
                    value={this.state.pastDate ? this.state.pastDate : moment()}
                    bordered={false}
                    onChange={e => {
                      if (
                        moment(moment(e).format("DD MMM YYYY")).isAfter(
                          moment(new Date()).format("DD MMM YYYY")
                        )
                      ) {
                        this.setState({
                          today: false,
                          completed: false,
                          upcoming: true,
                          overdues: false,
                        })
                      }
                      if (
                        moment(moment(e).format("DD MMM YYYY")).isSame(
                          moment(new Date()).format("DD MMM YYYY")
                        )
                      ) {
                        this.setState({
                          today: true,
                          overdues: false,
                          upcoming: true,
                          completed: true,
                        })
                      }
                      if (
                        moment(moment(e).format("DD MMM YYYY")).isBefore(
                          moment(new Date()).format("DD MMM YYYY")
                        )
                      ) {
                        this.setState({
                          today: false,
                          completed: true,
                          upcoming: false,
                          overdues: true,
                        })
                      }
                      this.setState(
                        {
                          pastDate: e,
                          isCurrentWeekVisible: false,
                        },
                        () => {
                          if (this.state.pastDate !== null) {
                            if (
                              moment(moment(e).format("DD MMM YYYY")).isSame(
                                moment(new Date()).format("DD MMM YYYY")
                              )
                            ) {
                              this.setState(
                                {
                                  recordDate: [firstOfWeek, lastOfWeek],
                                  pastDate: null,
                                  isCurrentWeekVisible: true,
                                  today: true,
                                  completed: false,
                                  upcoming: true,
                                  overdues: true,
                                  weekState: 0,
                                },
                                () => {
                                  this.getAllAssignment([
                                    firstOfWeek,
                                    lastOfWeek,
                                  ])
                                }
                              )
                            } else {
                              this.getOneDayAssignment(this.state.pastDate)
                            }
                          }
                        }
                      )
                    }}
                  />
                </div>
              </div>
              {this.state.isCurrentWeekVisible && (
                <div
                  className="ml-auto d-flex align-items-center"
                  style={{
                    opacity: this.state.isSearchInputFocused ? "0" : "1",
                    transition: "150ms all ease-in",
                  }}
                >
                  <div
                    className="px-1"
                    onClick={() => {
                      if (this.state.isShowLoading) return

                      const sevenDaysAgo = moment(
                        this.state.recordDate[0]
                      ).subtract(7, "days")
                      if (this.state.weekState === "Create") {
                        const { firstOfWeek, lastOfWeek } = this.weekPicker(
                          moment()
                        )
                        this.setState(
                          { recordDate: [firstOfWeek, lastOfWeek] },
                          () => {
                            this.getAllAssignment([firstOfWeek, lastOfWeek])
                          }
                        )
                      } else {
                        const { firstOfWeek, lastOfWeek } = this.weekPicker(
                          sevenDaysAgo
                        )
                        this.setState(
                          { recordDate: [firstOfWeek, lastOfWeek] },
                          () => {
                            this.getAllAssignment([firstOfWeek, lastOfWeek])
                          }
                        )
                      }
                      this.setState(
                        {
                          weekState:
                            this.state.weekState === "Create"
                              ? 0
                              : this.state.weekState - 1,
                        },
                        () => {
                          if (this.state.weekState === 0) {
                            this.setState({
                              today: false,
                              upcoming: true,
                              overdues: true,
                              completed: false,
                            })
                          }
                          if (this.state.weekState < 0) {
                            this.setState({
                              today: false,
                              completed: true,
                              upcoming: false,
                              overdues: true,
                            })
                          }
                        }
                      )
                    }}
                  >
                    <i
                      className="mr-1 text-black far fa-angle-left fa-2x"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </div>
                  <div className="px-4" id="week-tooltip">
                    <UncontrolledTooltip
                      placement="top"
                      className="bg-danger"
                      target="week-tooltip"
                    >
                      To view tasks from every week
                    </UncontrolledTooltip>
                    {this.getWeekState(this.state.recordDate)}
                  </div>
                  <div
                    className="px-1"
                    onClick={() => {
                      if (this.state.isShowLoading) return

                      const sevenDaysLater = moment(
                        this.state.recordDate[1]
                      ).add(7, "days")
                      if (this.state.weekState === "Create") {
                        const { firstOfWeek, lastOfWeek } = this.weekPicker(
                          moment()
                        )
                        this.setState(
                          {
                            recordDate: [firstOfWeek, lastOfWeek],
                          },
                          () => {
                            this.getAllAssignment([firstOfWeek, lastOfWeek])
                          }
                        )
                      } else {
                        const { firstOfWeek, lastOfWeek } = this.weekPicker(
                          sevenDaysLater
                        )
                        this.setState(
                          { recordDate: [firstOfWeek, lastOfWeek] },
                          () => {
                            this.getAllAssignment([firstOfWeek, lastOfWeek])
                          }
                        )
                      }
                      this.setState(
                        {
                          today: false,
                          weekState:
                            this.state.weekState === "Create"
                              ? 0
                              : this.state.weekState + 1,
                        },
                        () => {
                          if (this.state.weekState === 0) {
                            this.setState({
                              today: false,
                              upcoming: true,
                              overdues: true,
                              completed: false,
                            })
                          }
                          if (this.state.weekState >= 1) {
                            this.setState({
                              today: false,
                              completed: false,
                              upcoming: true,
                              overdues: false,
                            })
                          }
                        }
                      )
                    }}
                  >
                    <i
                      className="mr-1 text-black far fa-angle-right fa-2x"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </div>
                </div>
              )}
              <div className="ml-auto d-flex align-items-center">
                <UncontrolledDropdown
                  direction="left"
                  style={{
                    opacity: this.state.isSearchInputFocused ? "0" : "1",
                    transition: "150ms all ease-in",
                  }}
                >
                  <DropdownToggle
                    tag="div"
                    className="p-2 mr-2 cursor-pointer d-flex"
                  >
                    <i
                      className="m-auto fas fa-filter text-muted h5"
                      id="filter-tooltip"
                    ></i>
                    <UncontrolledTooltip
                      placement="top"
                      className="bg-danger"
                      target="filter-tooltip"
                    >
                      Filter tasks by their completion status
                    </UncontrolledTooltip>
                  </DropdownToggle>
                  <DropdownMenu
                    tag="div"
                    className="px-2 py-2 rounded border-1 border-muted"
                  >
                    <FormGroup check className="mb-1">
                      <Label check htmlFor="today-dues">
                        <Input
                          type="checkbox"
                          id="today-dues"
                          name="today-dues"
                          checked={this.state.today}
                          onChange={() => {
                            this.setState({ today: !this.state.today }, () => {
                              this.getAllAssignment(
                                this.state.pastDate
                                  ? [
                                      moment(this.state.pastDate).startOf(
                                        "day"
                                      ),
                                      moment(this.state.pastDate).endOf("day"),
                                    ]
                                  : this.state.recordDate
                              )
                            })
                          }}
                        />{" "}
                        Today Dues
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mb-1">
                      <Label check htmlFor="upcoming-dues">
                        <Input
                          type="checkbox"
                          id="upcoming-dues"
                          name="upcoming-dues"
                          checked={this.state.upcoming}
                          onChange={() => {
                            this.setState(
                              { upcoming: !this.state.upcoming },
                              () => {
                                this.getAllAssignment(
                                  this.state.pastDate
                                    ? [
                                        moment(this.state.pastDate).startOf(
                                          "day"
                                        ),
                                        moment(this.state.pastDate).endOf(
                                          "day"
                                        ),
                                      ]
                                    : this.state.recordDate
                                )
                              }
                            )
                          }}
                        />{" "}
                        Upcoming Dues
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mb-1">
                      <Label check htmlFor="overdues">
                        <Input
                          type="checkbox"
                          id="overdues"
                          name="overdues"
                          checked={this.state.overdues}
                          onChange={() => {
                            this.setState(
                              { overdues: !this.state.overdues },
                              () => {
                                this.getAllAssignment(
                                  this.state.pastDate
                                    ? [
                                        moment(this.state.pastDate).startOf(
                                          "day"
                                        ),
                                        moment(this.state.pastDate).endOf(
                                          "day"
                                        ),
                                      ]
                                    : this.state.recordDate
                                )
                              }
                            )
                          }}
                        />{" "}
                        Overdues
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mb-1">
                      <Label check htmlFor="completed">
                        <Input
                          type="checkbox"
                          id="completed"
                          name="completed"
                          checked={this.state.completed}
                          onChange={() => {
                            this.setState(
                              { completed: !this.state.completed },
                              () => {
                                this.getAllAssignment(
                                  this.state.pastDate
                                    ? [
                                        moment(this.state.pastDate).startOf(
                                          "day"
                                        ),
                                        moment(this.state.pastDate).endOf(
                                          "day"
                                        ),
                                      ]
                                    : this.state.recordDate
                                )
                              }
                            )
                          }}
                        />{" "}
                        Completed
                      </Label>
                    </FormGroup>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <div
                  className="mr-2 position-relative"
                  style={{
                    width: this.state.isSearchInputFocused ? "60%" : 200,
                    maxWidth: "500px",
                    transition: "300ms all ease-in",
                  }}
                >
                  <Input
                    type="text"
                    placeholder="Search Assignment"
                    value={this.state.searchinput}
                    onChange={e => {
                      const searchinput = e.target.value
                      this.setState(
                        {
                          searchinput,
                          // isSearchInputFocused: searchinput !== "",
                          isShowLoading: searchinput !== "",
                        },
                        this.searchFunction
                      )
                    }}
                  />
                  <button
                    type="button"
                    className={`close position-absolute ${
                      this.state.searchinput ? "" : "disabled"
                    }`}
                    style={{ top: "0.5rem", right: "1.5rem" }}
                    aria-label="Close"
                    onClick={() => {
                      this.setState(
                        {
                          searchinput: "",
                          isSearchInputFocused: false,
                          isShowLoading: false,
                        },
                        this.searchFunction
                      )
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <UncontrolledDropdown className="custom-scrollbars">
                  <DropdownToggle className="nav-link" direction="left" tag="a">
                    {this.state.assignmentDues &&
                    this.state.assignmentDues.length > 0 ? (
                      <>
                        <button
                          title="Show only tasks are due"
                          className="btn br-10 text-light"
                          style={{ backgroundColor: "#E85A58" }}
                        >
                          Show Dues
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          title="Show only tasks are due"
                          className="btn br-10"
                          style={{ backgroundColor: "#f8f9fa" }}
                          disabled={true}
                        >
                          Show Dues
                        </button>
                      </>
                    )}
                  </DropdownToggle>
                  <DropdownMenu
                    style={{
                      width: "28em",
                      marginLeft: "-200px",
                      borderRadius: "7px",
                      maxHeight: "calc(100vh - 120px - 1rem)",
                      overflowX: "auto",
                    }}
                  >
                    <div className="card-title">
                      <h6 className="pt-4 pl-3 text-dark">
                        <strong>DUE LIST</strong>
                      </h6>
                    </div>
                    <div className="card-body">
                      {this.state.assignmentDues &&
                        this.state.assignmentDues.length > 0 &&
                        this.state.assignmentDues.map((dues, index) => (
                          <div key={index} className="mb-2">
                            <h6 className="text-dark">{dues.month}</h6>
                            {dues.assignments.length > 0 &&
                              dues.assignments.map(assign => (
                                <div
                                  key={assign.id}
                                  className="mb-3 text-light bg-theme-light br-10 d-flex "
                                  type="button"
                                  onClick={() => {
                                    this.props.history.push({
                                      pathname:
                                        URL_PATH.TEACHER_ASSIGNMENT_DETAILS,
                                      state: {
                                        assignmentId: assign.id,
                                      },
                                    })
                                  }}
                                >
                                  <div>
                                    <h6
                                      className="p-2 px-4 m-0 br-10"
                                      style={{
                                        backgroundColor: this.findColor(
                                          assign.typeOfWork
                                        ),
                                      }}
                                    >
                                      CLASS {assign.section.className} -{" "}
                                      {assign.section.sectionName}
                                    </h6>
                                  </div>
                                  <h6 className="px-4 mt-2 ml-4 text-light flex-center">
                                    Due Date:{" "}
                                    {moment(assign.durationTo).format(
                                      "DD MMM YYYY"
                                    )}
                                  </h6>
                                </div>
                              ))}
                          </div>
                        ))}
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
                {this.state.assignWorkLoading && this.state.isShowLoading ? (
                  <div style={{ borderRadius: "14%" }}>
                    <Skeleton height={35} width={90} />
                  </div>
                ) : (
                  <button
                    className="btn bg-card br-10 text-light"
                    type="button"
                    onClick={() => {
                      this.defaultPassing()
                      this.setState({ createAssignmentModalOpen: true })
                    }}
                  >
                    Assign Work
                  </button>
                )}
              </div>
            </div>

            <div className="mx-5 mb-4 row d-flex justify-content-start">
              <div className="mr-4 d-flex flex-column align-items-center">
                {this.state.isShowLoading ? (
                  <Skeleton height={45} width={45} circle />
                ) : (
                  <div
                    className="bg-brand text-light rounded-pill d-flex align-items-center justify-content-center"
                    style={{ height: 45, width: 45 }}
                  >
                    <h4 className="p-0 m-0 text-white" id="homework-tooltip">
                      <UncontrolledTooltip
                        placement="top"
                        className="bg-danger"
                        target="homework-tooltip"
                      >
                        Number of homework yet to be completed
                      </UncontrolledTooltip>
                      {(this.state.assignmentCount || {}).HOMEWORK || 0}
                    </h4>
                  </div>
                )}

                <div className="mt-2 text-dark">Homework</div>
              </div>
              <div className="mr-4 d-flex flex-column align-items-center">
                {this.state.isShowLoading ? (
                  <Skeleton height={45} width={45} circle />
                ) : (
                  <div
                    className="bg-brand text-light rounded-pill d-flex align-items-center justify-content-center"
                    style={{ height: 45, width: 45 }}
                  >
                    <h4 className="p-0 m-0 text-white" id="assignment-tooltip">
                      <UncontrolledTooltip
                        placement="top"
                        className="bg-danger"
                        target="assignment-tooltip"
                      >
                        Number of Assignments yet to be completed
                      </UncontrolledTooltip>
                      {(this.state.assignmentCount || {}).ASSIGNMENT || 0}
                    </h4>
                  </div>
                )}

                <div className="mt-2 text-dark">Assignment</div>
              </div>
              <div className="d-flex flex-column align-items-center ml-3">
                {this.state.isShowLoading ? (
                  <Skeleton height={45} width={45} circle />
                ) : (
                  <div
                    className="bg-brand text-light rounded-pill d-flex align-items-center justify-content-center"
                    style={{ height: 45, width: 45 }}
                  >
                    <h4 className="p-0 m-0 text-white" id="project-tooltip">
                      <UncontrolledTooltip
                        placement="top"
                        className="bg-danger"
                        target="project-tooltip"
                      >
                        Number of projects yet to be completed
                      </UncontrolledTooltip>
                      {(this.state.assignmentCount || {}).PROJECT || 0}
                    </h4>
                  </div>
                )}

                <div className="mt-2 text-dark">Project</div>
              </div>

              <div className="ml-auto d-flex align-items-center">
                <div className="ml-4 d-flex align-items-center">
                  <div>
                    <i className="text-white fa fa-check tick-icon" />
                  </div>
                  <span className="ml-2">Completed</span>
                </div>
                <div className="ml-4 d-flex align-items-center">
                  <div>
                    <img
                      title={"Over due"}
                      src={overdue}
                      alt="logo"
                      width={18}
                    />
                  </div>
                  <span className="ml-2">Overdue</span>
                </div>
              </div>
            </div>

            {this.state.isShowLoading ? (
              <Skeleton height={130} width={280} />
            ) : (
              <div>
                {this.state.searchinput &&
                  this.state.assignments.length === 0 && (
                    <h4 className="text-center text-dark">
                      No Assignments Found
                    </h4>
                  )}

                {/* Assignment Rows */}
                {this.state.assignments &&
                  this.state.assignments.length > 0 &&
                  this.state.assignments
                    .sort((a, b) =>
                      a.class.className.localeCompare(b.class.className)
                    )
                    .sort((a, b) =>
                      a.class.subjectName.localeCompare(b.class.subjectName)
                    )
                    // .sort((a, b) => a.class.className > b.class.className)
                    // .sort((a, b) => a.class.subjectName > b.class.subjectName)
                    .map((ass, index) => {
                      const subject =
                        String(ass.class.subjectName).split(" ").length > 2
                          ? String(ass.class.subjectName).split(" ")[0] +
                            " " +
                            String(ass.class.subjectName).split(" ")[1] +
                            " " +
                            String(ass.class.subjectName).split(" ")[2]
                          : ass.class.subjectName
                      return (
                        <div
                          key={index}
                          className="p-2 py-2 mb-3 row card"
                          style={{
                            marginLeft: "7%",
                            marginRight: "20px",
                            width: "80vw",
                            backgroundColor: "#def2f2",
                            boxShadow: "0px 11px 6px -7px rgba(0,0,0,0.49)",
                          }}
                        >
                          <div className="mr-1 row">
                            <div className="card-body side-badge">
                              <p>
                                Class {ass.class.className} -{" "}
                                {ass.class.sectionName} <br></br>{" "}
                                <small> {subject} </small>
                              </p>
                            </div>
                            <div className="row due-card-container scrollbar scrolling-wrapper">
                              {ass.assignmentDetails &&
                                ass.assignmentDetails.map(assign => (
                                  <div
                                    key={assign.id}
                                    className={`rounded bg-muted mr-4 d-flex flex-column${
                                      !assign.isNew
                                        ? moment(assign.durationTo).format(
                                            "YYYY-MM-DD"
                                          ) ===
                                          moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                          ? " bg-today-assignment"
                                          : ""
                                        : ""
                                    }`}
                                  >
                                    {assign.isNew && !this.state.completed ? (
                                      <div className="ml-auto mr-2 text-danger">
                                        New
                                      </div>
                                    ) : moment(assign.durationTo).format(
                                        "YYYY-MM-DD"
                                      ) ===
                                      moment(new Date()).format(
                                        "YYYY-MM-DD"
                                      ) ? (
                                      <div className="ml-auto mr-2">Today</div>
                                    ) : (
                                      <div className="mb-4 ml-auto mr-2"></div>
                                    )}
                                    <div
                                      className={`card due-card mt-auto ${
                                        moment(assign.durationTo).format(
                                          "YYYY-MM-DD"
                                        ) ===
                                        moment(new Date()).format("YYYY-MM-DD")
                                          ? "mx-1"
                                          : ""
                                      }`}
                                    >
                                      <div className="p-2 mx-2 mb-0 d-flex justify-content-between">
                                        <div className="ml-0 row">
                                          <small
                                            className="mt-1 mb-0 badge-pill text-dark"
                                            style={{
                                              backgroundColor: this.findColor(
                                                assign.typeOfWork
                                              ),
                                              fontWeight: 900,
                                            }}
                                          >
                                            {assign.typeOfWork}
                                          </small>
                                          {moment(assign.durationFrom) >
                                          moment() ? (
                                            <img
                                              className="ml-2"
                                              title={moment(
                                                assign.durationFrom
                                              ).format("DD MMM YYYY")}
                                              src={schedule}
                                              alt="logo"
                                              width={24}
                                            />
                                          ) : null}
                                        </div>
                                        <div className="flex-row-reverse p-0">
                                          <UncontrolledDropdown>
                                            <DropdownToggle
                                              tag="a"
                                              // isOpen={false}
                                              onClick={this.toggleDropdown}
                                              className="p-0 pr-1 nav-link"
                                            >
                                              <div className="text-light">
                                                <i className="fa fa-ellipsis-v" />
                                              </div>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                              <DropdownItem
                                                onClick={e => {
                                                  this.setUpdateAssignmentModal(
                                                    assign.id
                                                  )
                                                  this.setState({
                                                    createAssignmentModalOpen: true,
                                                  })
                                                }}
                                              >
                                                Edit
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={e =>
                                                  this.deteleAssignment(assign)
                                                }
                                              >
                                                Delete
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </UncontrolledDropdown>
                                        </div>
                                      </div>
                                      <div
                                        className="p-2 pb-0 mx-2 mt-0 card-body"
                                        type="button"
                                        onClick={() => {
                                          this.props.history.push({
                                            pathname:
                                              URL_PATH.TEACHER_ASSIGNMENT_DETAILS,
                                            state: {
                                              assignmentId: assign.id,
                                            },
                                          })
                                        }}
                                      >
                                        <h6 className="text-light text-truncate">
                                          {assign.title}
                                        </h6>
                                        <small style={{ fontSize: "13px" }}>
                                          {assign.typeOfWork === "TEST"
                                            ? "To be held on: "
                                            : "Due: "}{" "}
                                          {moment(assign.durationTo).format(
                                            "DD MMM YYYY"
                                          )}
                                        </small>
                                      </div>
                                      <div
                                        className="px-2 mx-2 mb-3 d-flex justify-content-center"
                                        style={{ height: 24 }}
                                      >
                                        <div className="d-flex align-items-center w-100">
                                          <UncontrolledTooltip
                                            placement="top"
                                            target={`TaskCompleteStatus${assign.id}`}
                                          >
                                            {`${assign.submissionProgress.completed} Submitted`}
                                          </UncontrolledTooltip>
                                          <UncontrolledTooltip
                                            placement="top"
                                            target={`TaskNotCompleteStatus${assign.id}`}
                                          >
                                            {`${assign.submissionProgress.notCompleted} Not Submitted`}
                                          </UncontrolledTooltip>

                                          <div
                                            className="progress"
                                            style={{
                                              height: "9px",
                                              flex: 1,
                                            }}
                                          >
                                            <div
                                              className={`progress-bar`}
                                              role="progressbar"
                                              id={`TaskCompleteStatus${assign.id}`}
                                              style={{
                                                /* total width of the progess bar is 240px, here 192 is 80% of 240 */
                                                width:
                                                  assign.submissionProgress
                                                    .percentage + "%",
                                                backgroundColor: "#13AF8F",
                                              }}
                                              aria-valuenow={
                                                assign.submissionProgress
                                                  .percentage
                                              }
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            ></div>
                                            <div
                                              className="progress-bar"
                                              role="progressbar"
                                              id={`TaskNotCompleteStatus${assign.id}`}
                                              style={{
                                                width: this.getNotCompletedPercentage(
                                                  assign
                                                ),
                                                backgroundColor:
                                                  assign.submissionProgress
                                                    .completed === 0
                                                    ? "rgba(8,67,83,0.80)"
                                                    : "#E85A58",
                                              }}
                                              aria-valuenow={
                                                100 -
                                                assign.submissionProgress
                                                  .percentage
                                              }
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            ></div>
                                          </div>
                                        </div>
                                        <div>
                                          {assign.isMarkCompleted &&
                                          assign.isMarkCompleted === 1 ? (
                                            <div>
                                              <UncontrolledTooltip
                                                placement="top"
                                                target={`TaskStatus${assign.id}`}
                                              >
                                                Completed Task
                                              </UncontrolledTooltip>
                                              <i
                                                id={`TaskStatus${assign.id}`}
                                                className={`fa fa-check tick-icon text-white`}
                                              ></i>
                                            </div>
                                          ) : moment(assign.durationTo).format(
                                              "YYYY-MM-DD"
                                            ) <
                                            moment(new Date()).format(
                                              "YYYY-MM-DD"
                                            ) ? (
                                            <div>
                                              <img
                                                className="ml-2"
                                                title={"Over due"}
                                                src={overdue}
                                                alt="logo"
                                                width={24}
                                              />
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      )
                    })}
              </div>
            )}
          </div>
        </AppWrapper>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getTeacherAssignedDetails,
      getAssignmentAssignedDetails,
      getClassStudents,
      getDues,
      getAssignmentList,
      getAssignmentDetails,
      deleteAssignDetails,
      saveAssignDetails,
      showNotification,
      uploadFile,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(AssignmentScreen)
