/*************************************************
 * RedInk
 * @exports
 * @class AppWrapper.js
 * @extends Component
 * @author Vijayakumar // on 26/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { Link } from "react-router-dom"

import schoolLogo from "../../assets/images/schoolLogo.jpeg"
import { URL_PATH } from "../../config/urlPath"
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import profilePic from "../../assets/images/blankdp.png"
import malePic from "../../assets/images/men.png"
import femalePic from "../../assets/images/female.png"
import { logout } from "../../utils/Utility"
import store from "store"

class AppWrapper extends Component {
  /**
   * Creates an instance of HOC.
   * @param {any} props
   * @memberof HOC
   */
  constructor(props) {
    super(props)
    this.state = {
      showLogo: true,
      toggleSidemenu: true,
      toggleMobileSidemenu: false,
    }
  }

  componentDidMount = () => {
    if (this.props.sideMenuCollapsed || this.props.sideMenuCollapsedBackExam) {
      this.setState({
        showLogo: false,
        toggleMobileSidemenu: false,
        toggleSidemenu: false,
      })
    }
  }

  hamburgerClicked = mobile => {
    this.setState({ showLogo: !this.state.showLogo })
    if (mobile)
      this.setState({
        toggleMobileSidemenu: !this.state.toggleMobileSidemenu,
      })
    else
      this.setState({
        toggleSidemenu: !this.state.toggleSidemenu,
      })
  }

  // toggleProfileDropdown = () => {
  //   this.setState({ profileDropdownOpen: !this.state.profileDropdownOpen })
  // }

  render() {
    return (
      <React.Fragment>
        <div
          className={`app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header ${
            this.state.toggleSidemenu ? "" : "closed-sidebar"
          } ${this.state.toggleMobileSidemenu ? "sidebar-mobile-open" : ""}`}
        >
          <div className="app-header header-shadow">
            <div className="app-header__logo">
              {/* <div className="logo-src" /> */}
              {this.state.showLogo && !this.props.showTeacherLogo ? (
                <div
                  style={{
                    color: `#d80f0e`,
                    fontWeight: 900,
                    textDecoration: `none`,
                    fontSize: 30,
                  }}
                >
                  <span style={{ margin: 0 }}>
                    RED
                    <span
                      style={{
                        color: `white`,
                        margin: 0,
                        fontWeight: 900,
                        textDecoration: `none`,
                        fontSize: 30,
                      }}
                    >
                      INK
                    </span>
                  </span>
                </div>
              ) : null}

              {this.state.showLogo &&
              this.props.showTeacherLogo &
                (!this.props.sideMenuCollapsed ||
                  !this.props.sideMenuCollapsedBackExam) ? (
                <div
                  style={{
                    color: `white`,
                    fontWeight: 900,
                    textDecoration: `none`,
                    fontSize: 20,
                  }}
                >
                  <span style={{ margin: 0 }}>
                    SCHOOL
                    <span
                      style={{
                        color: `white`,
                        margin: 0,
                        fontWeight: 900,
                        textDecoration: `none`,
                        fontSize: 20,
                      }}
                    >
                      {" "}
                      NAME
                    </span>
                  </span>
                </div>
              ) : null}

              <div className="header__pane ml-auto">
                <div>
                  {this.props.sideMenuCollapsed ? (
                    <>
                      <Link id="backToTeacherDashboard" to={URL_PATH.TEACHER}>
                        <i className="fal fa-arrow-left text-light fs-25" />
                      </Link>
                      <UncontrolledTooltip
                        placement="right"
                        target="backToTeacherDashboard"
                      >
                        Back to Dashboard
                      </UncontrolledTooltip>
                    </>
                  ) : this.props.sideMenuCollapsedBackExam ? (
                    <>
                      <Link
                        id="backToTeacherDashboard"
                        to={URL_PATH.TEACHER_EXAMS}
                      >
                        <i className="fal fa-arrow-left text-light fs-25" />
                      </Link>
                      <UncontrolledTooltip
                        placement="right"
                        target="backToTeacherDashboard"
                      >
                        Back to Gradebook
                      </UncontrolledTooltip>
                    </>
                  ) : (
                    <button
                      type="button"
                      className="hamburger close-sidebar-btn hamburger--elastic"
                      data-class="closed-sidebar"
                      onClick={() => this.hamburgerClicked()}
                    >
                      <i className="fal fa-bars text-light fs-25" />
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="app-header__mobile-menu">
              <div>
                {this.props.sideMenuCollapsed ? (
                  <>
                    <Link id="backToTeacherDashboard" to={URL_PATH.TEACHER}>
                      <i className="fal fa-arrow-left text-light fs-25" />
                    </Link>
                    <UncontrolledTooltip
                      placement="right"
                      target="backToTeacherDashboard"
                    >
                      Back to Dashboard
                    </UncontrolledTooltip>
                  </>
                ) : (
                  <button
                    type="button"
                    className="hamburger close-sidebar-btn hamburger--elastic"
                    onClick={() => this.hamburgerClicked("mobile")}
                  >
                    <i className="fal fa-bars text-light fs-25" />
                  </button>
                )}
                {this.props.sideMenuCollapsedBackExam ? (
                  <>
                    <Link
                      id="backToTeacherDashboard"
                      to={URL_PATH.TEACHER_EXAMS}
                    >
                      <i className="fal fa-arrow-left text-light fs-25" />
                    </Link>
                    <UncontrolledTooltip
                      placement="right"
                      target="backToTeacherDashboard"
                    >
                      Back to Gradebook
                    </UncontrolledTooltip>
                  </>
                ) : (
                  <button
                    type="button"
                    className="hamburger close-sidebar-btn hamburger--elastic"
                    onClick={() => this.hamburgerClicked("mobile")}
                  >
                    <i className="fal fa-bars text-light fs-25" />
                  </button>
                )}
              </div>
            </div>
            <div className="app-header__menu pr-0">
              <UncontrolledDropdown>
                <DropdownToggle tag="a" className="nav-link">
                  <Link
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={e => e.preventDefault()}
                    to=""
                    className="p-0 btn d-flex justify-content-between"
                  >
                    {this.props.showTeacherLogo ? (
                      <div
                        style={{
                          color: `#d80f0e`,
                          fontWeight: 900,
                          textDecoration: `none`,
                          fontSize: 30,
                        }}
                      >
                        <span style={{ margin: 0 }}>
                          RED
                          <span
                            style={{
                              color: `white`,
                              margin: 0,
                              fontWeight: 900,
                              textDecoration: `none`,
                              fontSize: 30,
                            }}
                          >
                            INK
                          </span>
                        </span>
                      </div>
                    ) : (
                      <img
                        className="rounded-circle"
                        src={malePic}
                        alt="logo"
                        width={32}
                      />
                    )}
                    <i className="fal fa-angle-down ml-2 text-light align-self-center" />
                  </Link>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header>Settings</DropdownItem>
                  <DropdownItem>Account</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => logout()}>Logout</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <div className="app-header__content">
              <div className="app-header-right">
                <div className="header-btn-lg pr-0">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <UncontrolledDropdown>
                          <DropdownToggle tag="a" className="nav-link">
                            <Link
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              className="p-0 btn"
                              to=""
                              onClick={e => e.preventDefault()}
                            >
                              {this.props.showTeacherLogo ? (
                                <Link
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  onClick={e => e.preventDefault()}
                                  to=""
                                  className="p-0 btn"
                                >
                                  <div
                                    style={{
                                      color: `#d80f0e`,
                                      fontWeight: 900,
                                      textDecoration: `none`,
                                      fontSize: 30,
                                    }}
                                  >
                                    <span style={{ margin: 0 }}>
                                      RED
                                      <span
                                        style={{
                                          color: `white`,
                                          margin: 0,
                                          fontWeight: 900,
                                          textDecoration: `none`,
                                          fontSize: 30,
                                        }}
                                      >
                                        INK
                                      </span>
                                    </span>
                                  </div>
                                </Link>
                              ) : (
                                <img
                                  className="rounded-circle"
                                  src={malePic}
                                  alt="Logo"
                                  width={42}
                                />
                              )}
                              <i className="fal fa-angle-down ml-2 text-light" />
                            </Link>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem header>Settings</DropdownItem>
                            <DropdownItem>Account</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem onClick={() => logout()}>
                              Logout
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="app-main">
            <div className="app-sidebar sidebar-shadow">
              <div className="app-header__logo">
                {/* <div className="logo-src" /> */}
                <div className="header__pane ml-auto">
                  <div>
                    <button
                      type="button"
                      className="hamburger close-sidebar-btn hamburger--elastic"
                      data-class="closed-sidebar"
                    >
                      <span className="hamburger-box">
                        <span className="hamburger-inner" />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="app-header__mobile-menu">
                <div>
                  <button
                    type="button"
                    className="hamburger hamburger--elastic mobile-toggle-nav"
                  >
                    <span className="hamburger-box">
                      <span className="hamburger-inner" />
                    </span>
                  </button>
                </div>
              </div>
              <div className="app-header__menu">
                <span>
                  <button
                    type="button"
                    className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav"
                  >
                    <span className="btn-icon-wrapper">
                      <i className="fa fa-ellipsis-v fa-w-6" />
                    </span>
                  </button>
                </span>
              </div>
              <div className="scrollbar-sidebar">
                <div className="app-sidebar__inner">
                  <ul className="vertical-nav-menu text-light">
                    <div className="mt-3 d-flex justify-content-around">
                      {this.props.showSchoolLogo ? (
                        <img
                          className="rounded-circle"
                          src={schoolLogo}
                          alt="Logo"
                          width={
                            this.state.toggleMobileSidemenu ||
                            this.state.toggleSidemenu
                              ? 62
                              : 32
                          }
                        />
                      ) : null}

                      {this.props.showTeacherLogo ? (
                        <img
                          className="rounded-circle"
                          src={
                            store.get("quickInfo")
                              ? store.get("quickInfo").teacherImage !== ""
                                ? store.get("quickInfo").teacherImage
                                : store.get("quickInfo").teacherGender !== "F"
                                ? malePic
                                : femalePic
                              : profilePic
                          }
                          alt="Logo"
                          width={
                            this.state.toggleMobileSidemenu ||
                            this.state.toggleSidemenu
                              ? 62
                              : 32
                          }
                        />
                      ) : null}
                      {/* Teacher */}
                      {(this.state.toggleMobileSidemenu ||
                        this.state.toggleSidemenu) &&
                      this.props.showTeacherThree ? (
                        <div className="d-flex align-items-start flex-column bd-highlight text-nowrap">
                          <div className="mb-auto p-2 bd-highlight text-light ">
                            {store.get("quickInfo")
                              ? store.get("quickInfo").teacherName
                              : "Vijayakumar"}
                          </div>
                          {/* <div className="row ml-2 three-icons">
                            {!this.props.teacherProfile ? (
                              <Link
                                id="myProfile"
                                className="sidemenu-square-logo"
                                to={URL_PATH.TEACHER_PROFILE}
                              >
                                <UncontrolledTooltip
                                  placement="top"
                                  target="myProfile"
                                >
                                  MY PROFILE
                                </UncontrolledTooltip>
                                <i className="far fa-user p-1"></i>
                              </Link>
                            ) : null} 
                            {!this.props.teacherStudentRecordSidemenu && store.get("userSession").userInfo.role !== 4? (
                              <Link
                                id="record"
                                className="sidemenu-square-logo"
                                to={URL_PATH.TEACHER_STUDENT_RECORD}
                              >
                                <UncontrolledTooltip
                                  placement="top"
                                  target="record"
                                >
                                  RECORDS
                                </UncontrolledTooltip>
                                <i className="far fa-id-badge px-2"></i>
                              </Link>
                            ) : null}
                            {store.get("userSession").userInfo.role !== 4? 
                            <span
                              id="teacherMessage"
                              className="sidemenu-square-logo cursor-pointer"
                              onClick={() => {this.props.openMessageModal()}}
                            >
                              <UncontrolledTooltip
                                placement="top"
                                target="teacherMessage"
                              >
                                MESSAGE
                              </UncontrolledTooltip>
                              <i className="far fa-envelope p-1"></i>
                            </span>
                            :
                            null
                            }
                          </div> */}
                        </div>
                      ) : null}
                    </div>
                    {/* Teacher */}
                    {this.props.founderDashboard ? (
                      <>
                        <li className="mt-4">
                          <Link
                            to={URL_PATH.FOUNDER}
                            className={
                              URL_PATH.FOUNDER === window.location.pathname
                                ? "mm-active"
                                : ""
                            }
                          >
                            <i className="metismenu-icon far fa-poll" />
                            Dashboard
                          </Link>
                        </li>
                        {/* <li>
                          <Link
                            to={URL_PATH.TEACHER_ATTENDANCE}
                            className={
                              URL_PATH.TEACHER_ATTENDANCE ===
                              window.location.pathname
                                ? "mm-active"
                                : ""
                            }
                          >
                            <i className="metismenu-icon far fa-clipboard-user" />
                            Attendance
                          </Link>
                        </li> */}
                        <li>
                          <Link
                            to={URL_PATH.FOUNDER_COMMUNICATION}
                            className={
                              URL_PATH.FOUNDER_COMMUNICATION ===
                              window.location.pathname
                                ? "mm-active"
                                : ""
                            }
                          >
                            <i className="metismenu-icon far fa-mobile-alt" />
                            Communication
                          </Link>
                        </li>
                      </>
                    ) : null}

                    {this.props.teacherProfile ? (
                      <>
                        <Link
                          to={URL_PATH.TEACHER}
                          id="backToDashboard"
                          className="d-flex justify-content-center align-content-center mt-4"
                        >
                          <UncontrolledTooltip
                            placement="top"
                            target="backToDashboard"
                          >
                            Back to Dashboard
                          </UncontrolledTooltip>
                          <i className="far fa-arrow-circle-left fs-25" />
                        </Link>
                        <li className="mt-4">
                          <Link
                            to={URL_PATH.TEACHER_PROFILE}
                            className={
                              URL_PATH.TEACHER_PROFILE ===
                              window.location.pathname
                                ? "mm-active"
                                : ""
                            }
                          >
                            <i className="metismenu-icon far fa-user" />
                            My Profile
                          </Link>
                        </li>
                      </>
                    ) : null}
                    {this.props.teacherStudentRecordSidemenu ? (
                      <>
                        <Link
                          to={URL_PATH.TEACHER}
                          id="backToDashboard"
                          className="d-flex justify-content-center align-content-center mt-4"
                        >
                          <UncontrolledTooltip
                            placement="top"
                            target="backToDashboard"
                          >
                            Back to Dashboard
                          </UncontrolledTooltip>
                          <i className="far fa-arrow-circle-left fs-25" />
                        </Link>
                        <li className="mt-4">
                          <Link
                            to={URL_PATH.TEACHER_STUDENT_RECORD}
                            className={
                              URL_PATH.TEACHER_STUDENT_RECORD ===
                              window.location.pathname
                                ? "mm-active"
                                : ""
                            }
                          >
                            <i className="metismenu-icon far fa-user" />
                            Student Database
                          </Link>
                        </li>
                      </>
                    ) : null}

                    {this.props.teacherAttendanceDashboard ? (
                      <>
                        <Link
                          to={URL_PATH.TEACHER}
                          id="backToDashboard"
                          className="d-flex justify-content-center align-content-center mt-4"
                        >
                          <UncontrolledTooltip
                            placement="top"
                            target="backToDashboard"
                          >
                            Back to Dashboard
                          </UncontrolledTooltip>
                          <i className="far fa-arrow-circle-left fs-25" />
                        </Link>
                        <li className="mt-4">
                          <Link
                            to={URL_PATH.TEACHER_ATTENDANCE}
                            className={
                              URL_PATH.TEACHER_ATTENDANCE ===
                              window.location.pathname
                                ? "mm-active"
                                : ""
                            }
                          >
                            <i className="metismenu-icon far fa-clipboard-user" />
                            Take Attendance
                          </Link>
                        </li>
                        {/* <li>
                          <Link
                            to={URL_PATH.TEACHER_VIEW_ATTENDANCE}
                            className={
                              URL_PATH.TEACHER_VIEW_ATTENDANCE ===
                              window.location.pathname
                                ? "mm-active"
                                : ""
                            }
                          >
                            <i className="metismenu-icon far fa-clipboard-user" />
                            View Attendance
                          </Link>
                        </li> */}
                        <li>
                          <Link
                            to={URL_PATH.TEACHER_ATTENDANCE_REPORT}
                            className={
                              URL_PATH.TEACHER_ATTENDANCE_REPORT ===
                              window.location.pathname
                                ? "mm-active"
                                : ""
                            }
                          >
                            <i className="metismenu-icon far fa-chart-pie" />
                            Attendance Report
                          </Link>
                        </li>
                      </>
                    ) : null}
                  </ul>
                </div>
              </div>
            </div>
            <div className="app-main__outer">
              <div className="app-main__inner">{this.props.children}</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default AppWrapper
