import React from "react"

function CheckBox({
  title,
  checked,
  onChange,
  lableCls = "fs-13",
  inputCls = "",
  disabled,
}) {
  return (
    <div className="d-flex align-items-center">
      <input
        id={`rc-em-${title}`}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className={`subject-checkbox ${inputCls} ${
          disabled ? "border-9E9E9E" : ""
        }`}
        disabled={disabled}
      />
      <label
        htmlFor={`rc-em-${title}`}
        className={`mb-0 ml-3 cursor-pointer ${lableCls} ${
          disabled ? "clr-9E9E9E" : ""
        }`}
      >
        {title}
      </label>
    </div>
  )
}

export default CheckBox
