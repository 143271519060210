import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { DatePicker } from "antd"
import { Scrollbars } from "react-custom-scrollbars"
import { connect } from "react-redux"
import { Range } from "react-range"
import { Modal } from "reactstrap"
import pdfMake from "pdfmake"
import moment from "moment"
import Chart from "chart.js"

import {
  getTermList,
  getAcademicMonthList,
  getStudentAttendanceReportList,
  getStudentAttendanceReportGraph,
  saveStudentAttendanceReportListInStore,
  getStudentAttendanceReportHolidayList,
} from "../../../actions/teacher/AttendanceReportAction"
import { getClassDetails } from "../../../actions/admin/ClassDetailsAction"

import { ReactComponent as Close } from "../../../assets/images/close.svg"
import redinkLogo from "../../../assets/images/refresh_icon.png"

const fonts = {
  Roboto: {
    normal:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
    bold:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
    italics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
    bolditalics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
  },
}

let myGraph = null

class ReportModal extends Component {
  constructor(props) {
    super(props)
    document.title = "Attendance | Redink"
    this.state = {
      selectedTerm: null,
      selectedClass: "",
      selectedTermId: "",
      selectedClassId: "",
      isTermReport: true,
      sliderValue: 0,
      classes: [],
      terms: [],
      studentPerformanceRange: [0, 100],
      ...this.initialState,
      isModalOpen: false,
      isMonth: false,
      academicMonthList: [],
      disableDownload: false,
      attendanceList: [],
      downloadAsPdfLoading: false,
    }
  }

  initialState = {
    workingDays: 0,
    studentsList: [],
    attedenceList: [],
    monthlyData: null,
    allMonthsInTerm: [],
    monthlyGraphPresentList: [],
    monthlyGraphAbsentList: [],
  }

  componentDidMount = () => {
    this.setState({ disableDownload: true })
    this.props.getClassDetails({}, (success, classes) => {
      if (success) {
        this.props.getAcademicMonthList((success, response) => {
          if (success) {
            this.setState({ academicMonthList: response.data.months })
          }
        })
        this.props.getTermList(
          { sectionId: classes[0].id },
          (successTerm, terms) => {
            if (successTerm) {
              this.setState(
                {
                  selectedClassId: classes[0].id,
                  selectedTermId: terms[0].termId,
                  selectedClass: classes[0].value,
                  selectedTerm: terms[0].termName,
                  termDetail: terms[0],
                  sendFrom: terms[0].termFrom,
                  sendTo: terms[0].termTo,
                  resetFrom: terms[0].termFrom,
                  resetTo: terms[0].termTo,
                  classes,
                  terms,
                },
                () => {
                  this.getStudentAttendanceReport(false)
                }
              )
            }
          }
        )
      }
    })
  }

  getPercentage = (days, workingDays) => {
    return workingDays ? Math.round((days / workingDays) * 1000) / 10 : 0
  }

  getStudentAttendanceReport = isMonthPicker => {
    if (!this.state.selectedClassId) return
    if (!this.state.termDetail) return

    let object = {
      sectionId: this.state.selectedClassId,
      from: this.state.sendFrom,
      to: this.state.sendTo,
    }

    if (isMonthPicker) {
      object.isMonth = true
      object.month = this.state.selectedMonth
    }
    this.props.getStudentAttendanceReportHolidayList(
      { sectionId: this.state.selectedClassId },
      () => {}
    )
    this.props.getStudentAttendanceReportList(object, (success, response) => {
      if (success && response.responseCode === 0) {
        this.setState({ disableDownload: false })
        const {
          students,
          months,
          fullAttendees,
          frequentAbsentees,
          totalWorkingDays,
          classTeacher,
        } = response.data

        this.setState(
          {
            attedenceList: students,
            attedenceListContent: students,
            frequentAbsentees,
            fullAttendees,
            workingDays: totalWorkingDays,
            monthlyData: months,
            classTeacher,
          },
          () => {
            this.showTakeAttendanceGraph()
          }
        )
      } else {
        this.setState({
          attedenceList: [],
          attedenceListContent: [],
          frequentAbsentees: [],
          fullAttendees: [],
        })
      }
    })
  }

  setCalendarClass = ({ date, view }) => {
    if (view !== "month") return ""

    const { monthlyData } = this.state
    if (!monthlyData) return ""

    const [yyyy, mm, dd] = date
      .toISOString()
      .split("T")[0]
      .split("-")
    if (!monthlyData[`${yyyy}-${mm}`]) return ""

    const { workingDays, holidays } = monthlyData[`${yyyy}-${mm}`]
    let color = "no-data"
    if (workingDays.some(e => e.split("-")[2] === dd)) {
      color = "working-day"
    } else if (holidays.some(e => e.split("-")[2] === dd)) {
      color = "holiday"
    }
    return color
  }

  showTakeAttendanceGraph = () => {
    const attendanceGraph = this.attendanceGraph
    if (!attendanceGraph) return

    if (myGraph != null) {
      myGraph.destroy()
    }
    const {
      allMonthsInTerm,
      monthlyGraphPresentList,
      monthlyGraphAbsentList,
    } = this.state
    if (allMonthsInTerm.length === 0) return

    const presentList = monthlyGraphPresentList
    const absentList = monthlyGraphAbsentList

    myGraph = new Chart(attendanceGraph, {
      type: "bar",
      data: {
        labels: allMonthsInTerm,
        datasets: [
          {
            label: "Presence",
            data: presentList,
            backgroundColor: "#094353",
            borderRadius: 150,
            borderWidth: 2,
            barThickness: 12,
            maxBarThickness: 12,
          },
          {
            label: "Absence",
            data: absentList,
            backgroundColor: "rgba(9,67,83, 0.33)",
            borderRadius: 150,
            borderWidth: 2,
            barThickness: 12,
            maxBarThickness: 12,
          },
        ],
      },
      options: {
        responsive: true,
        legend: {
          display: false,
          position: "bottom",
          labels: {
            usePointStyle: true,
            fontSize: 10,
            fontColor: "white",
          },
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              const present = data["datasets"][0].data[tooltipItem.index]
              const absent = data["datasets"][1].data[tooltipItem.index]
              const total = present + absent
              const percentage = Math.round((present / total) * 1000) / 10
              return present + "/" + total + "  (" + percentage + "%)"
            },
            labelColor: function() {
              return {
                backgroundColor: "#094353",
              }
            },
          },
        },
        scales: {
          dataset: [{ barPercentage: 0.1 }],
          xAxes: [
            {
              ticks: {
                fontColor: "#084353",
              },
              gridLines: {
                display: false,
              },
              stacked: true,
            },
          ],
          yAxes: [
            {
              ticks: {
                display: false,
              },
              gridLines: {
                display: false,
              },
              stacked: true,
            },
          ],
        },
      },
    })
  }

  customFilter = sliderValue => {
    let dummyStudents = this.state.attedenceListContent
    if (dummyStudents && dummyStudents.length > 0) {
      let array = dummyStudents.filter(item => {
        return (
          item.percentage >= sliderValue[0] && item.percentage <= sliderValue[1]
        )
      })

      this.setState({
        startSliderValue: sliderValue[0],
        endSliderValue: sliderValue[1],
        sliderTotalStudent: array.length,
        attedenceList: array,
      })
      this.props.saveStudentAttendanceReportListInStore(array)
    }
  }

  closeMessageModal = () => this.setState({ isMessageModalOpen: false })

  onClickMessageIcon = () => {
    this.setState({ isMessageModalOpen: !this.state.isMessageModalOpen })
  }

  onSubmitMessage = () => this.closeMessageModal()

  downloadAsPdf = () => {
    if (!this.state.disableDownload && this.state.attedenceList.length > 0) {
      this.setState({ downloadAsPdfLoading: true })
      const { attedenceList } = this.state

      const title = "Attendance Report"
      const head = ["Name", "Days Present", "Days Absent", "Percentage"]
      const body =
        attedenceList && attedenceList.length > 0
          ? attedenceList.map((item, index) => [
              item.studentName,
              item.attendedDays,
              item.absentDays,
              item.percentage,
            ])
          : []

      const docDefinition = {
        content: [
          {
            columns: [{ text: title, style: "header" }],
          },
          {
            columns: [
              { text: "Class : " + this.state.selectedClass, style: "title" },
              {
                text:
                  "Class Teacher: " +
                  this.state.classTeacher.firstName +
                  " " +
                  this.state.classTeacher.lastName,
                style: "title",
              },
            ],
          },
          {
            columns: [
              {
                text:
                  "Date : " +
                  moment(this.state.sendFrom).format("DD-MM-YYYY") +
                  " - " +
                  moment(this.state.sendTo).format("DD-MM-YYYY"),
                style: "title",
              },
              {
                text: "No of working days: " + this.state.workingDays,
                style: "title",
              },
            ],
          },
          {
            style: "table",
            table: {
              headerRows: 1,
              widths: ["*", "*", "*", "*"],
              body: [head, ...body],
            },
            layout: {
              hLineWidth: function(i, node) {
                return 1
              },
              vLineWidth: function(i, node) {
                return 1
              },
              hLineColor: function(i, node) {
                return "#bcbcbc"
              },
              vLineColor: function(i, node) {
                return "#bcbcbc"
              },
            },
          },
        ],
        footer: {
          table: {
            headerRows: 0,
            widths: ["*"],
            body: [
              [
                {
                  style: "marginTop",
                  image: "redink-logo",
                  height: 20,
                  width: 70,
                  alignment: "center",
                },
              ],
            ],
          },
          layout: {
            hLineWidth: function(i, node) {
              return i === 0 || i === 1 ? 1 : 0
            },
            vLineWidth: function(i, node) {
              return 1
            },
            hLineColor: function(i, node) {
              return "#fff"
            },
            vLineColor: function(i, node) {
              return "#fff"
            },
          },
        },
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 12, 0, 6],
          },
          title: {
            fontSize: 15,
            margin: [0, 6, 0, 6],
          },
          subheader: {
            fontSize: 12,
            color: "#5e5e5e",
            margin: [0, 0, 0, 10],
          },
          table: {
            margin: [0, 5, 0, -1],
          },
          cell: {
            margin: [12, 6, 12, 6],
          },
          center: {
            alignment: "center",
          },
          bottomText: {
            alignment: "center",
            margin: [0, 8, 0, 8],
          },
          marginTop: {
            margin: [12, 0, 0, 0],
          },
        },
        images: {
          "caret-right": require("../../../assets/images/caret-right_base64")
            .default,
          "redink-logo": require("../../../assets/images/redink_logo_base64")
            .default,
        },
      }

      pdfMake.createPdf(docDefinition, null, fonts).getBase64(async data => {
        const blob = await fetch(
          `data:application/pdf;base64,${data}`
        ).then(res => res.blob())
        const browserUrl = window.webkitURL || window.URL
        const pdfURL = browserUrl.createObjectURL(blob)
        const a = document.createElement("a")
        a.href = pdfURL
        a.download = `${title}.pdf`
        a.click()
        browserUrl.revokeObjectURL(pdfURL)
        this.setState({ downloadAsPdfLoading: false })
      })
    }
  }

  render() {
    const { attedenceList } = this.state
    const { RangePicker } = DatePicker

    return (
      <Modal
        isOpen
        backdropClassName="modal-backdrop-show"
        modalClassName="modal-dialog-shadow-none attendance-admin-report-modal"
        contentClassName="p-4"
        centered
        size="xl"
      >
        <div className="d-flex align-items-center">
          <h1 className="report-modal-wrapper-h1 mb-0 fw-600">
            Attendance Report
          </h1>

          <Close
            className="report-modal-close-icon ml-auto"
            onClick={this.props.closeModal}
          />
        </div>

        <div className="row">
          <div className={`${this.state.isTermReport ? "col-sm-8" : "col-sm"}`}>
            <div className="mb-2 card">
              <div className="px-3 py-2 d-flex row">
                <div className="row col-sm-9 ml-1">
                  <div className="form-group d-flex flex-column mr-4">
                    <label>Class</label>
                    <select
                      className="custom-select input-border-color"
                      style={{ borderRadius: 8, width: 125 }}
                      value={this.state.selectedClassId}
                      onChange={e => {
                        let classes = this.state.classes
                        let classObj = classes.find(
                          el => el.id === e.target.value
                        )
                        this.setState(
                          {
                            selectedClass: classObj.value,
                            selectedClassId: e.target.value,
                            sendFrom: this.state.resetFrom,
                            sendTo: this.state.resetTo,
                            selectedTerm: this.state.termDetail.termName,
                            selectedMonth: "",
                            ...this.initialState,
                          },
                          () => {
                            this.getStudentAttendanceReport(false)
                          }
                        )
                      }}
                    >
                      {this.state.classes &&
                        this.state.classes.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.value}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="form-group d-flex flex-column mr-4">
                    <label>Term</label>
                    <select
                      className="custom-select input-border-color"
                      style={{ borderRadius: 8, width: 125 }}
                      value={this.state.selectedTerm}
                      onChange={value => {
                        value = value.target.value
                        this.setState(
                          {
                            selectedMonth: "",
                            selectedTerm: value,
                            sendFrom: JSON.parse(value).hasOwnProperty(
                              "termFrom"
                            )
                              ? JSON.parse(value).termFrom
                              : JSON.parse(value).assessmentFrom,
                            sendTo: JSON.parse(value).hasOwnProperty("termTo")
                              ? JSON.parse(value).termTo
                              : JSON.parse(value).assessmentTo,
                          },
                          () => {
                            this.getStudentAttendanceReport(false)
                          }
                        )
                      }}
                    >
                      <option value="" disabled>
                        Select Term
                      </option>
                      {this.state.terms &&
                        this.state.terms.map((item, index) => (
                          <option value={JSON.stringify(item)} key={index}>
                            {item.termName}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="form-group d-flex flex-column mr-4">
                    <label>Month</label>
                    <select
                      className="custom-select input-border-color"
                      style={{ borderRadius: 8, width: 134 }}
                      onChange={e => {
                        const item = JSON.parse(e.target.value)
                        this.setState(
                          {
                            selectedMonth: item.value,
                            sendFrom: item.from,
                            sendTo: item.to,
                            selectedTerm: "",
                          },
                          () => {
                            this.getStudentAttendanceReport(true)
                          }
                        )
                      }}
                    >
                      <option selected={this.state.selectedMonth === ""}>
                        Select month
                      </option>
                      {this.state.academicMonthList &&
                        this.state.academicMonthList.map((item, index) => (
                          <option key={index} value={JSON.stringify(item)}>
                            {item.label}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>

              {!this.state.isTermReport && (
                <div className="container">
                  <div className="row">
                    {this.state.workingDays > 0 &&
                      attedenceList &&
                      attedenceList.map((item, i) => (
                        <div
                          className={
                            attedenceList.length - 1 === i
                              ? "col-sm mb-2"
                              : "col-sm border-right-attendance-report mb-3"
                          }
                        >
                          <div>
                            <div className="card-body">
                              <h5 className="text-center card-title">
                                Term {item}
                              </h5>
                              <div className="pt-2 pb-2 mt-2 mb-2 shadow-none card">
                                <b className="pb-2 mx-2 ml-3">
                                  100% Attendance
                                </b>
                                <Scrollbars
                                  autoHeight
                                  autoHeightMin={100}
                                  autoHeightMax="20vh"
                                  autoHide
                                >
                                  <div className="px-3 table-responsive attendance-table">
                                    <table className="table table-hover table-striped">
                                      <tbody>
                                        {this.state.fullAttendees &&
                                          this.state.fullAttendees.length > 0 &&
                                          this.state.fullAttendees.map(
                                            (item, index) => (
                                              <tr key={index}>
                                                <td>{item.studentName}</td>
                                              </tr>
                                            )
                                          )}
                                      </tbody>
                                    </table>
                                  </div>
                                </Scrollbars>
                              </div>
                              <div className="pt-2 pb-2 mt-2 mb-2 shadow-none card">
                                <b className="pb-2 mx-2 ml-3">
                                  Frequent Absentees
                                </b>
                                <Scrollbars
                                  autoHeight
                                  autoHeightMin={100}
                                  autoHeightMax="20vh"
                                  autoHide
                                >
                                  <div className="px-3 table-responsive attendance-table">
                                    <table className="table table-hover table-striped">
                                      <tbody>
                                        {this.state.frequentAbsentees &&
                                          this.state.frequentAbsentees.length >
                                            0 &&
                                          this.state.frequentAbsentees.map(
                                            (item, index) => (
                                              <tr key={index}>
                                                <td>{item.studentName}</td>
                                              </tr>
                                            )
                                          )}
                                      </tbody>
                                    </table>
                                  </div>
                                </Scrollbars>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Right Section */}
          <div className="col-sm-4">
            <div className="d-flex flex-colum flex-wrap">
              <button
                className="mt-2 mr-4 btn bg-card br-10 text-light"
                type="button"
                id="assignmModalToogler"
                onClick={() => {
                  this.setState({ isModalOpen: !this.state.isModalOpen })
                }}
              >
                Custom Filter
              </button>
              <div
                style={{ color: "rgb(6, 177, 145)" }}
                className="mt-3 cursor-pointer"
                onClick={this.downloadAsPdf}
              >
                {this.state.downloadAsPdfLoading
                  ? "Loading..."
                  : "Download as PDF"}
              </div>

              {this.state.isModalOpen && (
                <div className="mt-2">
                  <RangePicker
                    className="form-control unstyled input-border-color d-flex"
                    format="DD-MM-YYYY"
                    bordered={true}
                    allowClear={false}
                    onChange={e => {
                      this.setState(
                        {
                          sendFrom: moment(e[0]).format("YYYY-MM-DD"),
                          customFromDisplay: e[0],
                          sendTo: moment(e[1]).format("YYYY-MM-DD"),
                          customToDisplay: e[1],
                        },
                        () => {
                          // this.setState({ isModalOpen: false })
                          this.getStudentAttendanceReport(false)
                        }
                      )
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-8 mt-2">
            <div className="card">
              {this.state.isTermReport && (
                <>
                  <div className="mt-2 d-flex col-sm-12 ml-2">
                    <div className="p-0 col-sm-5 d-flex flex-column">
                      <small className="mb-3">Percent filter</small>
                      <Range
                        step={0.1}
                        min={0}
                        max={100}
                        values={this.state.studentPerformanceRange}
                        onChange={studentPerformanceRange => {
                          this.setState({ studentPerformanceRange })
                          this.customFilter(studentPerformanceRange)
                        }}
                        renderTrack={({ props, children }) => (
                          <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                              ...props.style,
                              height: "36px",
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <div
                              ref={props.ref}
                              style={{
                                height: "5px",
                                width: "50%",
                                borderRadius: "4px",
                                background: "#00434c",
                              }}
                            >
                              {children}
                            </div>
                          </div>
                        )}
                        renderThumb={({ index, props }) => (
                          <div
                            {...props}
                            style={{
                              ...props.style,
                              height: "20px",
                              width: "20px",
                              borderRadius: "50%",
                              display: "flex",
                              backgroundColor: "#479997",
                              justifyContent: "center",
                              color: "white",
                              fontSize: 10,
                              alignItems: "center",
                              boxShadow: "0px 2px 6px #AAA",
                            }}
                          >
                            {Math.round(
                              this.state.studentPerformanceRange[index].toFixed(
                                1
                              )
                            )}
                          </div>
                        )}
                      />
                    </div>
                    <div className="mt-2 col-sm">
                      <div
                        className="py-2 row justify-content-between"
                        style={{
                          width: "250px",
                          paddingLeft: "10px",
                          color: "rgb(6, 177, 145)",
                          backgroundColor: "rgb(6, 177, 145, 0.17)",
                          borderRadius: 10,
                        }}
                      >
                        <div>
                          {moment(this.state.sendFrom).format("DD/MM/YYYY")} to{" "}
                          {moment(this.state.sendTo).format("DD/MM/YYYY")}
                        </div>
                        <div
                          style={{
                            paddingRight: "10px",
                          }}
                          onClick={() => {
                            this.setState(
                              {
                                sendFrom: this.state.resetFrom,
                                sendTo: this.state.resetTo,
                                selectedTerm: this.state.termDetail.termName,
                                selectedMonth: "",
                              },
                              () => {
                                this.getStudentAttendanceReport(false)
                              }
                            )
                          }}
                        >
                          <img
                            className="img-fluid"
                            src={redinkLogo}
                            alt="Redink"
                            width="20"
                            height="20"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 col-sm-3 d-flex">
                      <div className="ml-auto">
                        Working Days: {this.state.workingDays}
                      </div>
                    </div>
                  </div>
                  <Scrollbars
                    autoHeight
                    autoHeightMin={100}
                    autoHeightMax="60vh"
                    autoHide
                  >
                    <div className="px-3 table-responsive attendance-table">
                      <table className="table table-hover">
                        <thead className="text-white bg-theme-light">
                          <tr>
                            <th scope="col" className="py-2 text-center">
                              Register No.
                            </th>
                            <th></th>
                            <th scope="col" className="pl-5">
                              Name
                            </th>
                            <th scope="col" className="py-2 text-center">
                              Days Present
                            </th>
                            <th scope="col" className="py-2 text-center">
                              Days Absent
                            </th>
                            <th scope="col" className="py-2 text-center">
                              Percentage
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {attedenceList &&
                            attedenceList.length > 0 &&
                            attedenceList.map(item => (
                              <tr key={item.studentId}>
                                <td className="text-center">
                                  {item.registerRollNumber}
                                </td>
                                <td></td>
                                <td>{item.studentName}</td>
                                <td className="text-center">
                                  {item.attendedDays}
                                </td>
                                <td className="text-center">
                                  {item.absentDays}
                                </td>
                                <td className="text-center">
                                  {item.percentage}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </Scrollbars>
                </>
              )}
            </div>
          </div>

          {this.state.isTermReport && (
            <div className="col-sm-4 mt-2">
              <div
                className="py-2 mt-2 mb-2 border-0 shadow card"
                style={{ borderRadius: 6 }}
              >
                <div className="mx-3 mb-2 d-flex align-items-center">
                  <span
                    className="mr-2 attendance-present"
                    style={{
                      height: "1.25rem",
                      width: "1.2rem",
                      display: "block",
                      padding: 0,
                    }}
                  ></span>
                  <b className="p-0 m-0">100% Attendancee</b>
                </div>
                <Scrollbars
                  autoHeight
                  autoHeightMin={100}
                  autoHeightMax="20vh"
                  autoHide
                >
                  <div className="px-3 table-responsive attendance-table">
                    <table className="table table-hover table-striped">
                      <tbody>
                        {this.state.fullAttendees &&
                          this.state.fullAttendees.length > 0 &&
                          this.state.fullAttendees.map((item, index) => (
                            <tr key={index}>
                              <td>{item.studentName}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </Scrollbars>
              </div>

              <div
                className="py-2 mt-2 mb-2 border-0 shadow card"
                style={{ borderRadius: 6 }}
              >
                <div className="mx-3 mb-2 d-flex align-items-center">
                  <span
                    className="mr-2 attendance-absent"
                    style={{
                      height: "1.25rem",
                      width: "1.2rem",
                      display: "block",
                      padding: 0,
                    }}
                  ></span>
                  <b className="p-0 m-0">Frequent Absentees</b>
                </div>
                <Scrollbars
                  autoHeight
                  autoHeightMin={135}
                  autoHeightMax="20vh"
                  autoHide
                >
                  <div className="px-3 table-responsive attendance-table">
                    <table className="table table-hover table-striped">
                      <tbody>
                        {this.state.frequentAbsentees &&
                          this.state.frequentAbsentees.length > 0 &&
                          this.state.frequentAbsentees.map((item, index) => (
                            <tr key={index}>
                              <td>{item.studentName}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </Scrollbars>
              </div>
            </div>
          )}
        </div>

        {!this.state.isTermReport && (
          <div className="pt-2 pb-2 mb-4 border-0 shadow card attendance-card">
            <div className="px-3 text-right card-title text-light">
              Students with 100% Attendance for the Academic year 2019-2020
            </div>
            <Scrollbars
              autoHeight
              autoHeightMin={100}
              autoHeightMax="50vh"
              autoHide
            >
              <div className="px-3 table-responsive attendance-table">
                <table className="table table-hover table-striped">
                  <tbody>
                    {attedenceList &&
                      attedenceList.length > 0 &&
                      attedenceList.map((item, index) => (
                        <tr key={index}>
                          <td className="text-light">{item.studentName}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </Scrollbars>
          </div>
        )}
      </Modal>
    )
  }
}

const mapStateToProps = () => ({})

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getTermList,
      getClassDetails,
      getAcademicMonthList,
      getStudentAttendanceReportList,
      getStudentAttendanceReportGraph,
      getStudentAttendanceReportHolidayList,
      saveStudentAttendanceReportListInStore,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(ReportModal)
