/*************************************************
 * RedInk
 * @exports
 * @class Dashboard.js
 * @extends Component
 * @author Sasidharan // on 11/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import MessageModal from "../MessageModal"
import AppWrapper from "../Wrapper1"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  saveNewsDetails,
  saveEventsDetails,
} from "../../../actions/admin/NewsEventsAction"
import EventsComponent from "./EventsComponent"
import NewsComponent from "./NewsComponent"
import { showNotification } from "../../../actions/CommonAction"
import { HashLoader } from "react-spinners"
import { DANGER_TOAST, VALIDATION_MSG } from "../../../utils/Constants"
import { myLog } from "../../../utils/Utility"

class NewsAndEventScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isNews: true,
      isEvents: false,
      newsTitle: "",
      newsDescription: "",
      newsPhoto: "",
      eventsTitle: "",
      eventsInvitee: "",
      eventDate: "",
      eventTimeFrom: "",
      eventTimeTo: "",
      eventNote: "",
      isShowButtonLoading: false,
    }
  }

  componentDidMount() {}

  onClickCreateNewAndModalIcon = () => {
    this.setState({
      isCreateEventModalOpen: false,
      isCreateNewsModalOpen: false,
      isCreateTasksModalOpen: false,
    })
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handlePhotoChange = event => {
    myLog(event.target.files[0])
    let file = event.target.files[0]
    if (file.size < 500000) {
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        this.setState(
          {
            newsPhotofile: file,
            newsPhoto: reader.result,
          },
          () => {
            myLog("photo", this.state.newsPhoto)
          }
        )
      }
    } else {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.OOPS,
        "Image is too long"
      )
    }
  }

  onSubmitNews = () => {
    this.setState({ isShowButtonLoading: true })
    let newsObj = {
      title: this.state.newsTitle,
      description: this.state.newsDescription,
      photo: this.state.newsPhoto,
    }
    this.props.saveNewsDetails(newsObj, (success, response) => {
      if (success) {
        myLog(response)
        this.setState({
          isShowButtonLoading: false,
          newsTitle: "",
          newsDescription: "",
          newsPhoto: "",
        })
      } else {
        myLog("REQUEST CODE:", response)
        this.setState({ isShowButtonLoading: false })
      }
    })
  }

  onSubmitEvents = () => {
    this.setState({ isShowButtonLoading: true })
    let newsObj = {
      title: this.state.eventsTitle,
      invite: this.state.eventsInvitee,
      date: this.state.eventDate,
      timeFrom: this.state.eventTimeFrom,
      timeTo: this.state.eventTimeTo,
      note: this.state.eventNote,
    }
    this.props.saveEventsDetails(newsObj, (success, response) => {
      if (success) {
        myLog(response)
        this.setState({
          isShowButtonLoading: false,
          eventsTitle: "",
          eventsInvitee: "",
          eventDate: "",
          eventTimeFrom: "",
          eventTimeTo: "",
          eventNote: "",
        })
      } else {
        myLog("REQUEST CODE:", response)
        this.setState({ isShowButtonLoading: false })
      }
    })
  }

  renderNewsModal = () => {
    return (
      <div
        className="modal right slide-in-right fade"
        data-backdrop="static"
        id="newsModal"
        role="dialog"
        aria-labelledby="myModalLabel2"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel2">
                Create News
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label htmlFor="taskTitle">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    name="newsTitle"
                    value={this.state.newsTitle}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="newsDescription">Description</label>
                  <textarea
                    className="form-control"
                    name="newsDescription"
                    rows="5"
                    value={this.state.newsDescription}
                    onChange={this.handleChange}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label>Photo</label>
                  <input
                    className="form-control-file"
                    type="file"
                    name="newsPhoto"
                    onChange={this.handlePhotoChange}
                    placeholder="YYYY-MM-DD"
                  />
                </div>
                <div className="d-flex align-content-end">
                  <button
                    type="button"
                    className="btn btn-theme align-self-end"
                    onClick={this.onSubmitNews}
                  >
                    {this.state.isShowButtonLoading ? (
                      <HashLoader
                        sizeUnit={"px"}
                        size={16}
                        color={"#ffffff"}
                        loading={this.state.isShowButtonLoading}
                      />
                    ) : (
                      <div>Submit</div>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderEventsModal = () => {
    return (
      <div
        className="modal right slide-in-right fade"
        data-backdrop="static"
        id="eventsModal"
        role="dialog"
        aria-labelledby="myModalLabel2"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel2">
                Create Event
              </h4>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control"
                    name="eventsTitle"
                    placeholder=""
                    value={this.state.eventsTitle}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Invite</label>
                  <input
                    type="text"
                    className="form-control"
                    name="eventsInvitee"
                    value={this.state.eventsInvitee}
                    onChange={this.handleChange}
                    placeholder=""
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Date</label>
                  <input
                    className="form-control"
                    type="date"
                    name="eventDate"
                    value={this.state.eventDate}
                    onChange={this.handleChange}
                    placeholder="DD-MM-YYY"
                    required
                  />
                </div>

                <div className="form-group" style={{ marginBottom: 0 }}>
                  <label>Time</label>
                  <div className="d-flex flex-row">
                    <div className="form-group col-6">
                      <div>
                        <label>From</label>
                        <input
                          className="form-control"
                          type="time"
                          name="eventTimeFrom"
                          value={this.state.eventTimeFrom}
                          onChange={this.handleChange}
                          placeholder="HH:MM"
                        />
                      </div>
                    </div>
                    <div className="form-group col-6">
                      <div>
                        <label>To</label>
                        <input
                          className="form-control"
                          type="time"
                          name="eventTimeTo"
                          placeholder="HH:MM"
                          value={this.state.eventTimeTo}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>Note</label>
                  <textarea
                    className="form-control"
                    name="eventNote"
                    value={this.state.eventNote}
                    onChange={this.handleChange}
                    rows="5"
                  ></textarea>
                </div>
                <div className="d-flex align-content-end">
                  <button
                    type="button"
                    className="btn btn-theme align-self-end"
                    onClick={this.onSubmitEvents}
                  >
                    {this.state.isShowButtonLoading ? (
                      <HashLoader
                        sizeUnit={"px"}
                        size={16}
                        color={"#ffffff"}
                        loading={this.state.isShowButtonLoading}
                      />
                    ) : (
                      <div>Submit</div>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.renderNewsModal()}
        {this.renderEventsModal()}
        <AppWrapper
          openMessageModal={() => this.onClickMessageIcon()}
          showSchoolLogo
          showAdminThree
          adminDashboard
        >
          <MessageModal
            isModalOpen={this.state.isMessageModalOpen}
            closeModal={() => this.onClickMessageIcon()}
            onSubmitMessage={() => this.onSubmitMessage()}
          />
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 pl-0 ">
                <div className="d-flex justify-content-end mb-3">
                  <div className="d-flex justify-content-end">
                    <div className="bg-theme-verylight p-1 br-10">
                      <button
                        className={`btn btn-outline rounded ${
                          this.state.isNews ? "bg-theme-light text-light" : ""
                        }`}
                        type="button"
                        onClick={() => {
                          this.setState({
                            isNews: true,
                            isEvents: false,
                          })
                        }}
                      >
                        NEWS
                      </button>
                      <button
                        className={`btn btn-outline rounded ${
                          this.state.isEvents ? "bg-theme-light text-light" : ""
                        }`}
                        type="button"
                        onClick={() => {
                          this.setState({
                            isEvents: true,
                            isNews: false,
                          })
                        }}
                      >
                        EVENTS
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 pl-0">
                  {this.state.isNews ? (
                    <NewsComponent
                      toCreateNewsModalOpen={boolean => {
                        this.setState({ isCreateNewsModalOpen: boolean })
                      }}
                    />
                  ) : (
                    <EventsComponent
                      toCreateEventModalOpen={boolean => {
                        this.setState({ isCreateEventModalOpen: boolean })
                      }}
                    />
                  )}
                </div>
              </div>
              {/* <div className="col-lg-4 px-4 py-3 mb-5">
              <div className="my-0 text-center">
                <div className="my-0 ml-5 pl-5 text-center rounded h-25">
                  <Calendar
                    id="Calendar"
                    className="shadow br-0 rounded p-3 mt-5"
                    onChange={this.onChange}
                    value={this.state.date}
                  />
                </div>
                <div className="container br-10 shadow p-3 rounded d-block">
                  <div className="row">
                    <h3
                      style={{
                        fontSize: "1.5vw",
                        color: "#084353",
                      }}
                      className="col-3"
                    >
                      TASKS
                    </h3>
                    <div className=" col-9">
                      <button
                        onClick={() => {
                          this.setState({ isCreateTasksModalOpen: true });
                        }}
                        className="btn btn-sm btn-light pl-4 rounded-pill mr-0 box-shadow-none  float-right"
                      >
                        <i className="fa fa-plus mr-2" />
                        Add Tasks
                      </button>
                    </div>
                  </div>
                  <div
                    style={{
                      height: "45vh",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    <div className="row">
                      <div className="col-4 my-3">
                        <hr />
                      </div>
                      <div
                        className="shadow px-4 py-1 my-3 col-7 float-right"
                        style={{
                          color: "#718200",
                          border: "1px solid #718200",
                          borderRadius: "10px",
                          marginBottom: "1%",
                        }}
                      >
                        <h5 style={{ fontSize: "0.6vw" }}>SUB TEACHER</h5>
                        <p style={{ fontSize: "0.5vw" }}>ENGLISH 6A</p>
                        <small style={{ fontSize: "0.5vw" }}>15:00-15:30</small>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4 my-3">
                        <hr />
                      </div>
                      <div
                        className="shadow px-4 py-1 my-3 col-7 float-right"
                        style={{
                          color: "#821901",
                          border: "1px solid #821901",
                          borderRadius: "10px",
                        }}
                      >
                        <h5 style={{ fontSize: "0.6vw" }}>SUB TEACHER</h5>
                        <p style={{ fontSize: "0.5vw" }}>ENGLISH 6A</p>
                        <small style={{ fontSize: "0.5vw" }}>15:00-15:30</small>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4 my-3">
                        <hr />
                      </div>
                      <div
                        className="shadow px-4 py-1 my-3 col-7 float-right"
                        style={{
                          color: "#023c6e",
                          border: "1px solid #023c6e",
                          borderRadius: "10px",
                        }}
                      >
                        <h5 style={{ fontSize: "0.6vw" }}>SUB TEACHER</h5>
                        <p style={{ fontSize: "0.5vw" }}>ENGLISH 6A</p>
                        <small style={{ fontSize: "0.5vw" }}>15:00-15:30</small>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4 my-3">
                        <hr />
                      </div>
                      <div
                        className="shadow px-4 py-1 my-3 col-7 float-right"
                        style={{
                          color: "#084353",
                          border: "1px solid #084353",
                          borderRadius: "10px",
                        }}
                      >
                        <h5 style={{ fontSize: "0.6vw" }}>SUB TEACHER</h5>
                        <p style={{ fontSize: "0.5vw" }}>ENGLISH 6A</p>
                        <small style={{ fontSize: "0.5vw" }}>15:00-15:30</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            </div>
          </div>
        </AppWrapper>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      saveNewsDetails,
      saveEventsDetails,
      showNotification,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(NewsAndEventScreen)
