/*************************************************
 * RedInk
 * @exports
 * @class Dashboard.js
 * @extends Component
 * @author Sasidharan // on 11/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"

import MessageModal from "../MessageModal"
import AppWrapper from "../Wrapper1"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import ClassTimetable from "./ClassTimetable"
import TeacherTimetable from "./TeacherTimetable"

class timeTableDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mondayTimeTableClass: [
        {
          subjectName: "English",
          teacherName: "Jessica",
          periodTime: "8 am- 9 am",
          backgroundColor: "#46989650",
        },
        {
          subjectName: "Science",
          teacherName: "Jones",
          periodTime: "9 am- 10 am",
          backgroundColor: "#46989650",
        },
        {
          subjectName: "Break",
          periodTime: "10 am- 10.15am",
          backgroundColor: "#efb56045",
        },
        {
          subjectName: "Maths",
          teacherName: "George",
          periodTime: "10.15 am- 11.15am",
          backgroundColor: "#46989650",
        },
        {
          subjectName: "Maths",
          teacherName: "George",
          periodTime: "11.15 am- 12.15 am",
          backgroundColor: "#46989650",
        },
        {
          subjectName: "Lunch Break",
          periodTime: "12.15 am- 1.00 am",
          backgroundColor: "#efb56045",
        },
        {
          subjectName: "Language",
          teacherName: "Kayalvizhi",
          periodTime: "1 pm- 2 pm",
          backgroundColor: "#46989650",
        },
        {
          subjectName: "Craft",
          teacherName: "Mary",
          periodTime: "2 pm- 3 pm",
          backgroundColor: "#46989650",
        },
        {
          subjectName: "CS",
          teacherName: "Tommy",
          periodTime: "3 pm- 4 pm",
          backgroundColor: "#46989650",
        },
      ],
      tuesdayTimeTableClass: [],
      wednesdayTimeTableClass: [],
      thursdayTimeTableClass: [],
      fridayTimeTableClass: [],
      saturdayTimeTableClass: [],
      mondayTimeTableTeacher: [
        {
          subjectName: "English",
          periodTime: "8 am- 9 am",
          backgroundColor: "#46989650",
          class: "Class 5A",
        },
        {
          subjectName: "English",
          periodTime: "9 am- 10 am",
          backgroundColor: "#46989650",
          class: "Class 6B",
        },
        {
          subjectName: "No Schedule",
          periodTime: "10 am- 10.15am",
          backgroundColor: "#00000050",
        },
        {
          subjectName: "No Schedule",
          periodTime: "10 am- 10.15am",
          backgroundColor: "#00000050",
        },
        {
          subjectName: "Maths",
          periodTime: "11.15 am- 12.15 am",
          backgroundColor: "#46989650",
          class: "Class 5A",
        },
        {
          subjectName: "Lunch Break",
          periodTime: "12.15 am- 1.00 am",
          backgroundColor: "#efb56045",
        },
        {
          subjectName: "Language",
          periodTime: "1 pm- 2 pm",
          backgroundColor: "#46989650",
          class: "Class 5A",
        },
        {
          subjectName: "Craft",
          class: "Class 5A",
          periodTime: "2 pm- 3 pm",
          backgroundColor: "#46989650",
        },
        {
          subjectName: "No Schedule",
          periodTime: "10 am- 10.15am",
          backgroundColor: "#00000050",
        },
      ],
      tuesdayTimeTableTeacher: [],
      wednesdayTimeTableTeacher: [],
      thursdayTimeTableTeacher: [],
      fridayTimeTableTeacher: [],
      saturdayTimeTableTeacher: [],
      showSideMenu: true,
      isMessageModalOpen: false,
      isClassTimetable: true,
      isTeacherTimetable: false,
      day: <div className="text-light fs-12 text-left"></div>,
    }
  }

  // componentDidMount() { }

  /**
   * Handles message modal
   */
  closeMessageModal = () => this.setState({ isMessageModalOpen: false })

  /**
   * handles on click message icon in sidebar
   */
  onClickMessageIcon = () => {
    this.setState({ isMessageModalOpen: !this.state.isMessageModalOpen })
  }
  /**
   * handles message submit
   */
  onSubmitMessage = () => {
    this.closeMessageModal()
  }

  addSlotMo = () => {
    let obj = {
      subjectName: "",
      teacherName: "",
      periodTime: "",
    }
    let array = this.state.mondayTimeTableClass
    array.push(obj)
    this.setState({ mondayTimeTableClass: array })
  }

  render() {
    return (
      <AppWrapper
        openMessageModal={() => this.onClickMessageIcon()}
        showAdminProfileSidemenu
        showAdminBackToDashboard
      >
        <MessageModal
          isModalOpen={this.state.isMessageModalOpen}
          closeModal={() => this.onClickMessageIcon()}
          onSubmitMessage={() => this.onSubmitMessage()}
        />
        <div className="d-flex justify-content-end mb-3">
          <div className="d-flex justify-content-end">
            <div className="mr-3 font-weight-bold">VIEW AS</div>
            <div className="bg-theme-verylight p-1 br-10">
              <button
                className={`btn btn-outline rounded ${
                  this.state.isClassTimetable ? "bg-theme-light text-light" : ""
                }`}
                type="button"
                onClick={() => {
                  this.setState({
                    isClassTimetable: true,
                    isTeacherTimetable: false,
                  })
                }}
              >
                CLASS TIMETABLE
              </button>
              <button
                className={`btn btn-outline rounded ${
                  this.state.isTeacherTimetable
                    ? "bg-theme-light text-light"
                    : ""
                }`}
                type="button"
                onClick={() => {
                  this.setState({
                    isTeacherTimetable: true,
                    isClassTimetable: false,
                  })
                }}
              >
                TEACHER TIMETABLE
              </button>
            </div>
          </div>
        </div>
        {this.state.isClassTimetable ? (
          <div className="row w-100">
            <h3 className="col-sm-6 text-dark">Class 6A</h3>
            <div className="col-sm-6 text-right">
              <select value="Class" className="border border-dark br-10 mr-3">
                <option>Class</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
              <select value="Class" className="border border-dark br-10 mr-3">
                <option>Section</option>
                <option>A</option>
                <option>B</option>
                <option>C</option>
                <option>D</option>
                <option>E</option>
              </select>
              <i className="far fa-calendar-alt fs-15" />
            </div>
          </div>
        ) : (
          <div className="row py-2">
            <div className="col-sm-6">
              <div
                className="rounded-circle float-left bg-dark mr-2"
                style={{ width: "50px", height: "50px" }}
              />
              <h3 className=" pt-2 text-dark">Ms. Jessica Jones</h3>
            </div>
            <div className="float-right text-right pt-2 col-sm-6">
              <input
                placeholder="Search"
                className="border border-dark br-10 mr-3"
              />
              <i className="far fa-calendar-alt fs-15" />
            </div>
          </div>
        )}
        <div>
          {this.state.isClassTimetable ? (
            <div>
              {/* Class timetable */}
              <ClassTimetable />
            </div>
          ) : (
            <div>
              {/* Teacher timetable */}
              <TeacherTimetable />
            </div>
          )}
        </div>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, dispatchToProps)(timeTableDashboard)
