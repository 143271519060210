import React, { useEffect, useState } from "react"
import { FormGroup, Button, Label, Input } from "reactstrap"
import { useDispatch } from "react-redux"
import { HashLoader } from "react-spinners"
import { DatePicker } from "antd"
import moment from "moment"

import {
  academicYearImorting,
  getOldAcademicList,
  updateAcademicYear,
} from "../../../actions/admin/UpgradeAcademicYear"
import { SUCCESS_TOAST, VALIDATION_MSG } from "../../../utils/Constants"
import { showNotification } from "../../../actions/CommonAction"
import LoaderModal from "./LoaderModal"

const { RangePicker } = DatePicker

function CreateAcademicYear({ closeModal }) {
  const [selectedAcademic, setSelectedAcademic] = useState("")
  const [academicList, setAcademicList] = useState([])
  const [isSubmited, setIsSubmited] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [isLoading, setIsLoading] = useState("")
  const dispatch = useDispatch()

  const [details, setDetails] = useState({
    academicName: "",
    academicShortName: "",
    duration: "",
  })

  useEffect(() => {
    getOldAcademicList((success, data) => {
      if (success) {
        setAcademicList(data)
      }
    })
  }, [])

  const onChange = e => {
    e.persist()
    setDetails(p => ({
      ...p,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = () => {
    setIsLoading("create")
    const payload = {
      academicName: details.academicName,
      academicShortName: details.academicShortName,
      academicFrom: moment(details.duration[0]).format("YYYY-MM-DD"),
      academicTo: moment(details.duration[1]).format("YYYY-MM-DD"),
    }
    updateAcademicYear(payload, success => {
      if (success) {
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            "New academic year created successfully"
          )
        )
        setIsSubmited(true)
        setIsLoading("")
      }
    })
  }

  const startImport = () => {
    setIsLoading("import")
    academicYearImorting(selectedAcademic, success => {
      if (success) {
        setShowLoader(true)
      }
    })
  }

  const closeImportModal = () => {
    dispatch(
      showNotification(
        SUCCESS_TOAST,
        VALIDATION_MSG.SUCCESS,
        "Previous academic year imported to current academic year successfully"
      )
    )
    window.location.replace("/admin")
  }

  return (
    <div style={{ maxWidth: "600px" }}>
      <div className="d-flex">
        <div className="col-md-5" style={{ maxWidth: 290 }}>
          <FormGroup>
            <Label htmlFor="academicName">Academic Name</Label>
            <Input
              id="academicName"
              type="text"
              name="academicName"
              value={details.academicName}
              onChange={onChange}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  let el = document.getElementById("academicShortName")
                  el.focus()
                }
              }}
            />
          </FormGroup>
          <div className="d-flex justify-content-center">
            {details.academicName === "" && details.hasError && (
              <p className="text-danger">Academic Name is required.</p>
            )}
          </div>
          <FormGroup>
            <Label htmlFor="academicShortName">Short Name</Label>
            <Input
              id="academicShortName"
              type="text"
              name="academicShortName"
              value={details.academicShortName}
              onChange={onChange}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  let el = document.getElementById("admin-sp-a-d-a-y")
                  el.focus()
                }
              }}
            />
          </FormGroup>
          <div className="d-flex justify-content-center">
            {details.academicShortName === "" && details.hasError && (
              <p className="text-danger">Academic Short Name is required.</p>
            )}
          </div>
          <FormGroup>
            <Label>Select Academic year</Label>
            <div>
              <RangePicker
                onChange={duration => setDetails(p => ({ ...p, duration }))}
                value={details.duration}
                format="DD-MM-YYYY"
                id="admin-sp-a-d-a-y"
              />
            </div>
          </FormGroup>
        </div>
        <div className="col"></div>
      </div>

      <div className="d-flex align-items-center justify-content-end my-3">
        <Button
          style={{
            backgroundColor: "#DBDBDB",
            color: "#06B191",
            border: "none",
            width: "5rem",
          }}
          className="rounded-pill ml-3"
          onClick={closeModal}
          disabled={isLoading}
        >
          cancel
        </Button>

        <Button
          style={{
            backgroundColor: "#0eb292",
            border: "none",
            width: "5rem",
          }}
          className="rounded-pill ml-3"
          onClick={onSubmit}
          disabled={isLoading}
        >
          {isLoading === "create" ? (
            <HashLoader
              css={{ margin: "0 auto" }}
              size={16}
              color="#fff"
              loading
              sizeUnit="px"
            />
          ) : (
            "Save"
          )}
        </Button>
      </div>

      <div className="pl-4 mt-4">
        <div className="mb-2 fs-15 fw-600">Import Configurations</div>

        <div className="p-4 bg-F7F7F8 br-8px">
          <div className="fs-14 clr-5B5B5B">Import from</div>

          <div className="d-flex align-items-center gap-1rem my-3">
            <select
              className="modern-arrow form-control border-0"
              value={selectedAcademic}
              onChange={e => setSelectedAcademic(e.target.value)}
              disabled={isLoading}
            >
              <option value="" disabled></option>
              {academicList?.map(ac => (
                <option value={ac.academicId} key={ac.academicId}>
                  {ac.academicName}
                </option>
              ))}
            </select>

            <button
              className="dis-grayed px-4 py-2 fw-600 bg-66B8A0 text-white br-5px"
              style={{ flexShrink: "0" }}
              disabled={isLoading || !isSubmited}
              onClick={startImport}
            >
              {isLoading === "import" ? (
                <HashLoader
                  css={{ margin: "0 auto" }}
                  size={16}
                  color="#fff"
                  loading
                  sizeUnit="px"
                />
              ) : (
                "Start Importing"
              )}
            </button>
          </div>

          <div className="fs-14 clr-5B5B5B">
            Import complete configuration like Examination setup, Teacher
            details <br />
            and Subjects. These can be changed later as well.
          </div>
        </div>
      </div>

      {showLoader && <LoaderModal closeModal={closeImportModal} />}
    </div>
  )
}

export default CreateAcademicYear
