import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"

export async function getReportCardTemplates(classId, callBack) {
  try {
    const response = await Client.get(
      `${URL.REPORT_CARD_TEMPLATES}${classId}`,
      null,
      true,
      true
    )
    myLog(response)

    callBack(true, response.data)
  } catch (error) {
    myLog(error)
  }
}

export async function getSignedReportCardUrl(path, callBack) {
  try {
    const params = new URLSearchParams({
      name: path,
    })
    const response = await Client.get(
      `${URL.FILE_INFO}?${params.toString()}`,
      null,
      true,
      true
    )
    myLog(response)

    callBack(true, response)
  } catch (error) {
    myLog(error)
  }
}

export async function createReportCardTemplates(body, callBack) {
  try {
    const response = await Client.post(
      `${URL.REPORT_CARD_TEMPLATES}${body.classId}`,
      body,
      true,
      true
    )
    myLog(response)
    callBack(true, response)
  } catch (error) {
    myLog(error)
  }
}

export async function getStudentListInReportCard(sectionId, callBack) {
  try {
    let url = URL.STUDENT_LIST_IN_REPORT_CARD + sectionId

    const response = await Client.get(url, null, true, true)
    myLog(response)
    callBack(true, response)
  } catch (error) {
    myLog(error)
  }
}

export async function GenerateStudentReportCardList(
  sectionId,
  downloadAll = false,
  callBack
) {
  try {
    let url = URL.STUDENT_LIST_IN_REPORT_CARD + sectionId

    if (downloadAll) {
      url = url + "&downloadAll=true"
    }

    const response = await Client.post(url, null, true, true)
    myLog(response)

    callBack(true, response)
  } catch (error) {
    myLog(error)
  }
}

export async function getTermListInReportCard(classId, callBack) {
  try {
    let url = URL.GET_TERM_LIST_IN_REPORT_CARD + classId

    const response = await Client.get(url, null, true, true)
    myLog("getTermListInReportCard", response)
    callBack(true, response.data)
  } catch (error) {
    myLog(error)
  }
}

export async function downloadConsolidatedReportCard(
  { assessmentId, sectionId, termId },
  callBack
) {
  try {
    const url = `${URL.CONSOLIDATE_REPORT_DOWNLOAD}?termId=${termId}&sectionId=${sectionId}&assessmentId=${assessmentId}`
    const response = await Client.post(url, null, true, true)
    myLog(response)

    callBack(true, response.data)
  } catch (error) {
    myLog(error)
  }
}
