import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import { showNotification } from "../CommonAction"
import {
  SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"
import URL from "../../config"

export const saveAssignDetails = (body, callBack) => {
  myLog("ASSIGN  MODAL OBJECT ->", body)
  return async dispatch => {
    try {
      const response = await Client.post(
        URL.TEACHER_MOBILE_ASSIGNMENT,
        body,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====saveAssignDetails RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveNewsDetails ERROR===????", error)
    }
  }
}

export const updateMultipleStudentAssignmentMark = (id, object, callBack) => {
  myLog("updateMultipleStudentAssignmentMark -> ", id)
  return async dispatch => {
    try {
      const response = await Client.patch(
        URL.UPDATE_STUDENT_ASSIGNMENT_DETAILS + "/" + id + "/assigned-students",
        object,
        true,
        true
      )
      myLog(response)
      if (response.responseCode === 0) {
        myLog(
          "====updateMultipleStudentAssignmentMark RESPONSE===::::",
          response
        )
        callBack(true, response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
      } else {
        callBack(false)
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
      }
    } catch (error) {
      callBack(false)
      // dispatch(showNotification(DANGER_TOAST, VALIDATION_MSG.OOPS, error))
      myLog("====updateMultipleStudentAssignmentMarks ERROR===????", error)
    }
  }
}

export const teacherViewStudentAssignment = (id, object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.patch(
        URL.UPDATE_STUDENT_ASSIGNMENT_DETAILS + "/assigned-students/" + id,
        object,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====teacherViewStudentAssignment RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
      }
    } catch (error) {
      callBack(false)
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      myLog("====teacherViewStudentAssignment ERROR===????", error)
    }
  }
}

export const updateSingleStudentAssignment = (id, object, callBack) => {
  myLog("updateSingleStudentAssignment -> ", id)
  return async dispatch => {
    try {
      const response = await Client.patch(
        URL.UPDATE_STUDENT_ASSIGNMENT_DETAILS + "/assigned-students/" + id,
        object,
        true,
        true
      )
      myLog(response)
      if (response.responseCode === 0) {
        myLog("====updateSingleStudentAssignment RESPONSE===::::", response)
        callBack(true, response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
      } else {
        callBack(false)
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
      }
    } catch (error) {
      callBack(false)
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      myLog("====updateSingleStudentAssignment ERROR===????", error)
    }
  }
}

export const updateAssignmentMarkComplete = (id, callBack) => {
  myLog("updateAssignmentMarkComplete -> ", id)
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.UPDATE_STUDENT_ASSIGNMENT_DETAILS + "/" + id + "/complete-mark",
        null,
        true,
        true
      )
      myLog(response)
      if (response.responseCode === 0) {
        myLog("====updateAssignmentMarkComplete RESPONSE===::::", response)
        callBack(true, response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
      } else {
        callBack(false)
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
      }
    } catch (error) {
      callBack(false)
      myLog("====updateAssignmentMarkComplete ERROR===????", error)
    }
  }
}

export const deleteAssignDetails = (id, callBack) => {
  myLog(id)
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.DELETE_ASSIGNMENT_DETAIL + "/" + id,
        null,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteAssignDetails RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteAssignDetails ERROR===????", error)
    }
  }
}

export const deleteStudentAssignmentDetails = (
  studentAssignmentId,
  callBack
) => {
  myLog(studentAssignmentId)
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.DELETE_STUDENT_ASSIGNMENT_DETAIL + "/" + studentAssignmentId,
        null,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteStudentAssignmentDetails RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteStudentAssignmentDetails ERROR===????", error)
    }
  }
}

export const getAssignmentList = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_ASSIGNMENT_DETAILS,
        body,
        true,
        true
      )
      if (response) {
        myLog("====getAssignmentList RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getAssignmentList ERROR===????", error)
    }
  }
}

export const getAssignmentDetails = (id, callBack) => {
  return async dispatch => {
    try {
      let body = {
        //   assignmentId: id,
      }
      const response = await Client.get(
        URL.GET_ASSIGNMENT_DETAILS + "/" + id,
        body,
        true,
        true
      )
      if (response) {
        myLog("====getAssignmentDetails RESPONSE===::::", response)
        // response.data.count included
        callBack(true, response.data)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getAssignmentDetails ERROR===????", error)
    }
  }
}

export const getAssignmentAssignedDetails = (id, callBack) => {
  return async dispatch => {
    try {
      let body = {
        //   assignmentId: id,
      }
      const response = await Client.get(
        URL.GET_ASSIGNMENT_DETAILS + "/" + id + "/assignedList",
        body,
        true,
        true
      )
      if (response) {
        myLog("====getAssignmentAssignedDetails RESPONSE===::::", response)
        // response.data.count included
        callBack(true, response.data)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getAssignmentAssignedDetails ERROR===????", error)
    }
  }
}

export const getSingleStudentAssignmentDetails = (id, callBack) => {
  return async dispatch => {
    try {
      let body = {}
      const response = await Client.get(
        URL.GET_STUDENT_ASSIGNMENT_DETAIL + "/" + id,
        body,
        true,
        true
      )
      if (response) {
        myLog("====getSingleStudentAssignmentDetails RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getSingleStudentAssignmentDetails ERROR===????", error)
    }
  }
}

export const getAssignmentCount = (date, callBack) => {
  return async dispatch => {
    try {
      let body = {
        startDate: date[0],
        endDate: date[1],
      }
      const response = await Client.get(
        URL.GET_ASSIGNMENT_COUNT,
        body,
        true,
        true
      )
      if (response) {
        myLog("====getAssignmentCount RESPONSE===::::", response)
        callBack(true, response.count)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getAssignmentCount ERROR===????", error)
    }
  }
}

export const getAssignmentDetailStatusCount = (id, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_ASSIGNMENT_DETAIL_STATUS_COUNT + "/" + id + "/count",
        null,
        true,
        true
      )
      if (response) {
        myLog("====getAssignmentCount RESPONSE===::::", response)
        callBack(true, response.count)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getAssignmentCount ERROR===????", error)
    }
  }
}

export const getDues = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_DUES_DETAILS, null, true, true)
      if (response) {
        myLog("====getDues RESPONSE===::::", response)
        callBack(true, response.dues)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getDues ERROR===????", error)
    }
  }
}

export const getAssignmentDetailAttachment = (assignmentId, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_ASSIGNMENT_DETAILS + "/" + assignmentId + "/file",
        null,
        true,
        true
      )
      if (response) {
        myLog("====getAssignmentDetailAttachment RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getAssignmentDetailAttachment ERROR===????", error)
    }
  }
}

export const getMarkReport = (id, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.getPdfOrExcel(
        URL.GET_ASSIGNMENT_DETAILS + "/" + id + "/mark-report",
        null,
        true,
        true
      )
      if (response) {
        myLog("====getMarkReport RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getMarkReport ERROR===????", error)
    }
  }
}

export const getStudentSubmissionWorkFile = (id, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_ASSIGNMENT_DETAILS + "/assigned-students/" + id + "/file",
        null,
        true,
        true
      )
      if (response) {
        myLog("====getStudentSubmissionWorkFile RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getStudentSubmissionWorkFile ERROR===????", error)
    }
  }
}

export const getStudentListBySections = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_STUDENT_LIST_BY_SECTIONS,
        object,
        true
      )
      if (response) {
        myLog("====getStudentListBySections RESPONSE===::::", response)
        callBack(true, response.studentDetails)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getStudentListBySections ERROR===????", error)
    }
  }
}

export const getSubjectListBySections = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_SUBJECT_LIST_BY_SECTIONS,
        object,
        true
      )
      if (response) {
        myLog("====getSubjectListBySections RESPONSE===::::", response)
        callBack(true, response.subjects)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getSubjectListBySections ERROR===????", error)
    }
  }
}

export const searchAssignmentList = (query, callBack) => {
  return async dispatch => {
    try {
      let object = {
        q: query,
      }
      const response = await Client.get(
        URL.SEARCH_ASSIGNMENTS,
        object,
        true,
        true
      )
      if (response) {
        myLog("====searchAssignmentList RESPONSE===::::", response)
        callBack(true, response.list)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====searchAssignmentList ERROR===????", error)
    }
  }
}

export const getClassListByTeacher = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_TEACHERS_CLASS_lIST,
        object,
        true
      )
      if (response) {
        myLog("====getClassListByTeacher RESPONSE===::::", response)
        // dispatch(saveClassDetailsInStore(response.teacherClasses))
        callBack(true, response.teacherClasses)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getClassListByTeacher ERROR===????", error)
    }
  }
}
