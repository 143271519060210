import { ACTION_TYPES } from "../../actions/types"
import { myLog } from "../../utils/Utility"

const INITIAL_STATE = {
  behaviourViewList: [],
  behaviourDetail: {
    id: "",
    recognitionName: "",
    point: "",
    isPositive: "",
    recognitionImage: "",
    isModalOpen: false,
  },
}

export const founderBehaviourReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.FOUNDER_BEHAVIOUR_LIST:
      myLog("behaviour payload", action.payload)
      return {
        ...state,
        behaviourDetail: { ...action.payload },
      }
    case ACTION_TYPES.FOUNDER_VIEW_LIST:
      return {
        ...state,
        behaviourViewList: [...action.payload],
      }
    default:
      return state
  }
}
