import { useEffect, useRef, useState } from "react"

function useOutSideClick() {
  const [isOpen, setIsOpen] = useState(false)
  const wrapperRef = useRef(null)

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [wrapperRef])

  function handleClickOutside(e) {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      return setIsOpen(false)
    }
  }

  const onOpenChange = () => setIsOpen(p => !p)

  return { wrapperRef, isOpen, onOpenChange }
}

export default useOutSideClick
