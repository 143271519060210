/*************************************************
 * RedInk
 * @exports
 * @class MessageModal.js
 * @extends Component
 * @author Sasidharan // on 11/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import Select, { components } from "react-select"
import { UncontrolledTooltip } from "reactstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  getTeachersDetails,
  saveCommunicationDetails,
  saveOpenMessageModalInStore,
} from "../../actions/admin/CommunicationDetailsAction"
// import { myLog } from "../../utils/Utility"
import { getStudentListBySections } from "../../actions/teacher/AssignmentAction"
import { HashLoader } from "react-spinners"
import makeAnimated from "react-select/animated"
import { MySelect } from "../common/MySelect"
import { showNotification } from "../../actions/CommonAction"
import {
  // SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
  MAX_FILE_UPLOAD,
} from "../../utils/Constants"

const Option = props => (
  <div>
    <components.Option {...props}>
      <div className="d-flex align-items-center">
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          className="subject-checkbox"
        />{" "}
        <label className="ml-2 mt-2">{props.label}</label>
      </div>
    </components.Option>
  </div>
)

const allOption = {
  label: "Select all",
  value: "*",
}

const MultiValue = props => {
  let labelToBeDisplayed = `${props.data.label}, `
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = "Selected All"
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  )
}

const ValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue()
  let toBeRendered = children
  if (currentValues.some(val => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]]
  }

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  )
}

const animatedComponents = makeAnimated()

class MessageModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: false,
      messageSubject: "",
      messageBody: "",
      sendEmail: true,
      sendSms: false,
      assignedRoleId: "",
      assignedRoleName: "",
      assignedStudents: [],
      assignedTeachers: [],
      assignedSubjects: [],
      attachments: [],
      labelId: "",
      studentList: [],
    }
  }

  style = {
    option: (provided, state) => ({
      ...provided,
      color: "black",
      backgroundColor: "white",
    }),
  }

  clearObject = () => {
    this.setState({
      messageSubject: "",
      messageBody: "",
      sendEmail: true,
      sendSms: false,
      assignedRoleId: "",
      assignedRoleName: "",
      assignedClassId: "",
      assignedRole: {},
      assignedClass: [],
      assignedParentClass: [],
      assignedStudents: [],
      assignedTeachers: [],
      assignedSubjects: [],
      attachments: [],
      labelId: "",
    })
    this.props.saveOpenMessageModalInStore(false)
  }

  // componentDidMount() {}

  getStudentsBySections = selectedSections => {
    /* backlog: need to update sectionIds mismatch */
    let object = {
      sectionIds: selectedSections.map(e => e.id).join(","),
    }
    this.props.getStudentListBySections(
      object,
      (studentSuccess, studentResponse) => {
        if (studentSuccess) {
          this.setState({
            studentList: studentResponse,
          })
        }
      }
    )
  }

  getTeachersBySubjects = selectedSections => {
    let sectionArray = ""
    for (let i = 0; i < selectedSections.length; i++) {
      sectionArray =
        selectedSections[i].id || selectedSections[i].value + "," + sectionArray
    }
    let object = {
      subjectIds: sectionArray,
    }
    this.props.getTeachersDetails(object, (studentSuccess, studentResponse) => {
      if (studentSuccess) {
        this.setState({
          teachersList: studentResponse,
        })
      }
    })
  }

  onMouseEnter = () => {
    this.setState({ dropdownOpen: true })
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  onMouseLeave = () => {
    this.setState({ dropdownOpen: false })
  }

  clickSend = () => {
    const wrongInputMsg = msg =>
      this.props.showNotification(DANGER_TOAST, VALIDATION_MSG.OOPS, msg)

    const {
      messageSubject,
      messageBody,
      labelId,
      assignedRoleId,
      assignedStudents,
      assignedClass,
      assignedTeachers,
      sendEmail,
      sendSms,
      attachments,
    } = this.state

    /* mandatory fields check */
    if (
      !assignedRoleId ||
      (assignedRoleId === "5" && !assignedStudents.length) ||
      (assignedRoleId === "3" && !assignedClass.length) ||
      (assignedRoleId === "4" && !assignedTeachers.length)
    ) {
      return wrongInputMsg("Select recipients...")
    }
    if (!messageSubject) return wrongInputMsg("Enter subject...")
    if (!sendEmail && !sendSms) return wrongInputMsg("Select email or sms...")
    if (!messageBody) return wrongInputMsg("Enter message...")

    this.setState({ isShowSaveLoading: true }, () => {
      let receiverArray = []
      if (assignedRoleId === "5") {
        for (let i = 0; i < assignedStudents.length; i++) {
          if (assignedStudents[i].label !== "Select all") {
            let object = {
              recipientId: assignedStudents[i].id,
              sectionId: assignedStudents[i].grade.id,
            }
            receiverArray.push(object)
          }
        }
      } else if (assignedRoleId === "3") {
        for (let i = 0; i < assignedClass.length; i++) {
          if (assignedClass[i].label !== "Select all") {
            let object = {
              sectionId: assignedClass[i].id,
            }
            receiverArray.push(object)
          }
        }
      } else if (assignedRoleId === "4") {
        for (let i = 0; i < assignedTeachers.length; i++) {
          if (assignedTeachers[i].label !== "Select all") {
            let object = {
              recipientId: assignedTeachers[i].teacherId,
              subjectId: assignedTeachers[i].classSubjectId,
            }
            receiverArray.push(object)
          }
        }
      }
      let formData = new FormData()
      const wayOfMessage = []
      if (sendEmail) wayOfMessage.push("EMAIL")
      if (sendSms) wayOfMessage.push("SMS")
      formData.append("wayOfMessage", JSON.stringify(wayOfMessage))
      formData.append("recipientRole", assignedRoleId || "")
      formData.append("recipients", JSON.stringify(receiverArray))
      formData.append("title", messageSubject || "")
      formData.append("content", messageBody || "")
      formData.append("labelId", labelId || "")
      if (sendEmail) {
        for (let i = 0; i < attachments.length; i++) {
          formData.append("attachments", attachments[i])
        }
      }
      this.props.saveCommunicationDetails(formData, (success, response) => {
        this.setState({ isShowSaveLoading: false }, () => {})
        if (success) {
          this.clearObject()

          if (this.props.afterSave) {
            this.props.afterSave(wayOfMessage, response.data)
          }
          this.props.saveOpenMessageModalInStore(false)
        }
      })
    })
  }

  render() {
    return this.props.openMessageModal ? (
      <div
        className="modal right slide-in-right fade show"
        style={{ display: "block" }}
        // data-backdrop="static"
        // id="messageModal"
        // role="dialog"
        // aria-labelledby="myModalLabel2"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div
              className="modal-body p-4"
              style={{ backgroundColor: "#F1F7F7", borderRadius: 12 }}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <h5
                    className="mb-4 text-brand font-weight-bold"
                    id="myModalLabel2"
                  >
                    Create Message
                  </h5>
                </div>

                <div>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      this.clearObject()
                    }}
                  >
                    <span
                      aria-hidden="true"
                      style={{ verticalAlign: "text-bottom" }}
                    >
                      &times;
                    </span>
                  </button>
                </div>
              </div>
              <form>
                <div className="form-group">
                  <label>To</label>
                  <div>
                    <Select
                      placeholder="Select"
                      isClearable
                      value={this.state.assignedRole}
                      options={[
                        { value: "5", label: "Parents" },
                        { value: "3", label: "Class Teachers" },
                        { value: "4", label: "Subject Teachers" },
                      ]}
                      onChange={selectedOption => {
                        if (selectedOption !== null) {
                          this.setState({
                            assignedRole: selectedOption,
                            assignedRoleId: selectedOption.value,
                            assignedRoleName: selectedOption.label,
                          })
                        } else {
                          this.setState({
                            assignedRole: selectedOption,
                            assignedRoleId: "",
                          })
                        }
                      }}
                      className="basic-multi-select mb-3"
                      classNamePrefix="select"
                    />
                  </div>
                  <div>
                    {this.state.assignedRoleId === "5" ? (
                      <MySelect
                        placeholder="Select Class"
                        isClearable
                        value={this.state.assignedParentClass}
                        options={this.props.classesList.map(item => ({
                          ...item,
                          value: item.id,
                        }))}
                        className="basic-multi-select mb-3"
                        classNamePrefix="select"
                        isMulti
                        allowSelectAll={true}
                        components={{
                          Option,
                          MultiValue,
                          ValueContainer,
                          animatedComponents,
                        }}
                        styles={this.style}
                        hideSelectedOptions={false}
                        closeMenuOnSelect={false}
                        onChange={selectedOption => {
                          if (selectedOption !== null) {
                            this.setState({
                              assignedParentClass: selectedOption,
                              assignedParentClassName: selectedOption.value,
                            })
                            this.getStudentsBySections(selectedOption)
                          } else {
                            this.setState({
                              assignedParentClass: selectedOption,
                              assignedStudents: [],
                              studentList: [],
                            })
                          }
                        }}
                      />
                    ) : // <Select
                    //   placeholder="Select Class"
                    //   isClearable
                    //   value={this.state.assignedParentClass}
                    //   options={this.props.classesList.map(item => ({
                    //     ...item,
                    //     value: item.id,
                    //   }))}
                    //   className="basic-multi-select mb-3"
                    //   isMulti
                    //   components={{ Option, MultiValue }}
                    //   classNamePrefix="select"
                    //   onChange={selectedOption => {
                    //     if (selectedOption !== null) {
                    //       this.setState({
                    //         assignedParentClass: selectedOption,
                    //         assignedParentClassName: selectedOption.value,
                    //       })
                    //       this.getStudentsBySections(selectedOption)
                    //     } else {
                    //       this.setState({
                    //         assignedParentClass: selectedOption,
                    //       })
                    //     }
                    //   }}
                    // />
                    this.state.assignedRoleId === "3" ? (
                      <MySelect
                        placeholder="Select Class"
                        isClearable
                        value={this.state.assignedClass}
                        options={this.props.classesList.map(item => ({
                          ...item,
                          value: item.id,
                        }))}
                        className="basic-multi-select mb-3"
                        classNamePrefix="select"
                        isMulti
                        allowSelectAll={true}
                        components={{
                          Option,
                          MultiValue,
                          ValueContainer,
                          animatedComponents,
                        }}
                        styles={this.style}
                        hideSelectedOptions={false}
                        closeMenuOnSelect={false}
                        onChange={selectedOption => {
                          this.setState({ assignedClass: selectedOption })
                        }}
                      />
                    ) : this.state.assignedRoleId === "4" ? (
                      <Select
                        placeholder="Select Subject"
                        isClearable
                        value={this.state.assignedSubjects}
                        options={this.props.subjectList.map(item => ({
                          ...item,
                          value: item.subjectId,
                        }))}
                        // options={this.props.subjectList.map(item => ({
                        //   label: item.subjectName,
                        //   value: item.id,
                        // }))}
                        className="basic-multi-select mb-3"
                        classNamePrefix="select"
                        isMulti
                        styles={this.style}
                        components={{ Option, MultiValue }}
                        onChange={selectedOption => {
                          if (selectedOption !== null) {
                            this.setState({
                              assignedSubjects: selectedOption,
                              assignedTeachers: [],
                            })
                            this.getTeachersBySubjects(selectedOption)
                          } else {
                            this.setState({
                              assignedSubjects: selectedOption,
                              assignedTeachers: [],
                              teachersList: [],
                            })
                          }
                        }}
                      />
                    ) : null}
                  </div>
                  <div>
                    {this.state.assignedRoleId === "5" ? (
                      <MySelect
                        placeholder="Select Student"
                        name="classes"
                        isClearable
                        value={this.state.assignedStudents}
                        options={this.state.studentList.map(item => ({
                          ...item,
                          value: item.id,
                        }))}
                        className="basic-multi-select mb-3"
                        classNamePrefix="select"
                        isMulti
                        allowSelectAll={true}
                        components={{
                          Option,
                          MultiValue,
                          ValueContainer,
                          animatedComponents,
                        }}
                        styles={this.style}
                        hideSelectedOptions={false}
                        closeMenuOnSelect={false}
                        onChange={selectedOption => {
                          if (selectedOption !== null) {
                            this.setState({
                              assignedStudents: selectedOption,
                            })
                          } else {
                            this.setState({
                              assignedStudents: selectedOption,
                            })
                          }
                        }}
                      />
                    ) : this.state.assignedRoleId === "4" ? (
                      <MySelect
                        placeholder="Select Teacher"
                        isClearable
                        value={this.state.assignedTeachers}
                        options={
                          this.state.teachersList
                            ? this.state.teachersList.map(item => ({
                                ...item,
                                value: item.teacherId,
                              }))
                            : []
                        }
                        className="basic-multi-select mb-3"
                        classNamePrefix="select"
                        isMulti
                        allowSelectAll={true}
                        components={{
                          Option,
                          MultiValue,
                          ValueContainer,
                          animatedComponents,
                        }}
                        styles={this.style}
                        hideSelectedOptions={false}
                        closeMenuOnSelect={false}
                        onChange={selectedOption => {
                          this.setState({
                            assignedTeachers: selectedOption,
                          })
                        }}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="form-group">
                  <label>Subject</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control mr-2 rounded"
                      value={this.state.messageSubject}
                      onChange={e =>
                        this.setState({ messageSubject: e.target.value })
                      }
                    />
                  </div>
                </div>
                {this.state.sendEmail && (
                  <div>
                    <div className="row mb-2">
                      <span className="col-sm ml-2 font-weight-bold fs-12 text-primary">
                        <label>
                          <input
                            type="file"
                            multiple="multiple"
                            style={{ display: "none" }}
                            onChange={e => {
                              const files = e.target.files
                              if (files) {
                                for (const file of files) {
                                  if (file.size > MAX_FILE_UPLOAD) {
                                    return this.props.showNotification(
                                      DANGER_TOAST,
                                      VALIDATION_MSG.OOPS,
                                      "File size should be less than 10MB..."
                                    )
                                  }
                                }
                                this.setState(state => ({
                                  attachments: [...state.attachments, ...files],
                                }))
                              }
                            }}
                          />
                          <u>+ Add Attachment</u>
                          <i className="fa fa-paperclip pl-1 col-sm-1 ml-0 pl-0 text-primary fs-15" />
                        </label>
                      </span>
                    </div>
                    {this.state.attachments.map((attachment, index) => (
                      <div className="d-flex flex-column justify-content-start px-2">
                        <div className="row m-2 mt-0 d-flex row bg-theme-verylight rounded-lg justify-content-between">
                          <div className="row">
                            <div
                              className="bg-theme-light rounded p-3"
                              style={{ width: "58px", height: "58px" }}
                            >
                              {attachment.type === "application/pdf" ? (
                                <img
                                  src={require("../../assets/images/assignment/pdf.svg")}
                                  alt={attachment.type}
                                  width="30"
                                  height="30"
                                />
                              ) : (
                                <i
                                  className="fa fa-paperclip "
                                  style={{
                                    fontSize: "35px",
                                    color: "#0DB191",
                                  }}
                                ></i>
                              )}
                            </div>
                            <div className="ml-3 align-self-center">
                              {attachment.name}
                            </div>
                          </div>
                          <div className="mr-3 align-self-center">
                            <i
                              className={`far fa-times fa-clock-hover`}
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Delete Student from assignment"
                              onClick={() => {
                                this.setState(state => ({
                                  attachments: state.attachments
                                    .map((e, i) =>
                                      i !== index ? e : undefined
                                    )
                                    .filter(x => x),
                                }))
                              }}
                            ></i>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <div className="form-group">
                  <label htmlFor="Label" className="font-weight-bold">
                    Label
                  </label>
                  <select
                    className="custom-select"
                    value={this.state.labelId}
                    onChange={e => {
                      let labelId = e.target.value
                      if (labelId === "Select a label") {
                        labelId = ""
                      }
                      this.setState({ labelId })
                    }}
                  >
                    <option>Select a label</option>
                    {this.props.labelList &&
                      this.props.labelList.map((label, i) => (
                        <option value={label.id} key={i}>
                          {label.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group">
                  <button
                    className={`btn primary-outline-btn right-br ${
                      this.state.sendEmail
                        ? "primary-btn text-light"
                        : "btn-hover"
                    }`}
                    type="button"
                    onClick={() =>
                      this.setState({ sendEmail: !this.state.sendEmail })
                    }
                  >
                    <i className="fas fa-at mr-2"></i>EMAIL
                  </button>
                  <button
                    className={`btn primary-outline-btn left-br ${
                      this.state.sendSms
                        ? "primary-btn text-light"
                        : "btn-hover"
                    }`}
                    type="button"
                    onClick={() =>
                      this.setState({ sendSms: !this.state.sendSms })
                    }
                  >
                    <i className="fas fa-mobile-alt mr-2"></i>SMS
                  </button>
                  <i
                    className="fas fa-info-circle ml-2"
                    id="sms-info"
                    aria-hidden="true"
                  ></i>
                  <UncontrolledTooltip
                    placement="top"
                    className="bg-danger"
                    target="sms-info"
                  >
                    Select mode of communication, you can select both and send
                    the message in email as well as SMS at the same time
                  </UncontrolledTooltip>
                </div>

                <div className="form-group">
                  <label>Message</label>
                  <textarea
                    rows={8}
                    className="form-control"
                    value={this.state.messageBody}
                    onChange={e =>
                      this.setState({ messageBody: e.target.value })
                    }
                  />
                </div>
                <button
                  type="button"
                  className="btn bg-theme text-white"
                  disabled={this.state.isShowSaveLoading}
                  onClick={this.clickSend}
                >
                  {this.state.isShowSaveLoading ? (
                    <HashLoader
                      sizeUnit={"px"}
                      size={16}
                      color={"#ffffff"}
                      loading={this.state.isShowSaveLoading}
                    />
                  ) : (
                    "Send"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    ) : null
  }
}

const mapStateToProps = state => {
  return {
    subjectList: state.communicationDetailsState.subjectList,
    classesList: state.communicationDetailsState.classesList,
    teachersList: state.communicationDetailsState.teachersList,
    openMessageModal: state.communicationDetailsState.openMessageModal,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getTeachersDetails,
      saveCommunicationDetails,
      getStudentListBySections,
      showNotification,
      saveOpenMessageModalInStore,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(MessageModal)
