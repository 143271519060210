/*************************************************
 * RedInk
 * @exports
 * @class Dashboard.js
 * @extends Component
 * @author Sasidharan // on 11/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
class ClassTimetable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mondayTimeTableClass: [
        {
          subjectName: "English",
          teacherName: "Jessica",
          periodTime: "8 am- 9 am",
          backgroundColor: "#46989650",
        },
        {
          subjectName: "Science",
          teacherName: "Jones",
          periodTime: "9 am- 10 am",
          backgroundColor: "#46989650",
        },
        {
          subjectName: "Break",
          periodTime: "10 am- 10.15am",
          backgroundColor: "#efb56045",
        },
        {
          subjectName: "Maths",
          teacherName: "George",
          periodTime: "10.15 am- 11.15am",
          backgroundColor: "#46989650",
        },
        {
          subjectName: "Maths",
          teacherName: "George",
          periodTime: "11.15 am- 12.15 am",
          backgroundColor: "#46989650",
        },
        {
          subjectName: "Lunch Break",
          periodTime: "12.15 am- 1.00 am",
          backgroundColor: "#efb56045",
        },
        {
          subjectName: "Language",
          teacherName: "Kayalvizhi",
          periodTime: "1 pm- 2 pm",
          backgroundColor: "#46989650",
        },
        {
          subjectName: "Craft",
          teacherName: "Mary",
          periodTime: "2 pm- 3 pm",
          backgroundColor: "#46989650",
        },
        {
          subjectName: "CS",
          teacherName: "Tommy",
          periodTime: "3 pm- 4 pm",
          backgroundColor: "#46989650",
        },
      ],
      tuesdayTimeTableClass: [],
      wednesdayTimeTableClass: [],
      thursdayTimeTableClass: [],
      fridayTimeTableClass: [],
      saturdayTimeTableClass: [],

      showSideMenu: true,
      isMessageModalOpen: false,
      isClassTimetable: true,
      isTeacherTimetable: false,
      day: <div className="text-light fs-12 text-left"></div>,
    }
  }

  // componentDidMount() { }

  render() {
    return (
      <div
        className="timetable overflow-auto"
        style={{ height: window.innerHeight - 152 }}
      >
        <div className="timetable-days">
          <div className="timetable-oneday p-1" style={{ float: "left" }}>
            <div className="row d-flex justify-content-center">
              <h5>
                <span className="badge align-self-center text-light p-2">
                  21-02-2021
                </span>
              </h5>
            </div>
            <div className="timetable-day text-light p-2">
              <div className="card-body d-flex justify-content-between">
                <h3>Monday</h3>
                <UncontrolledDropdown className="pl-4 pt-2 ml-4">
                  <DropdownToggle tag="b">
                    <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>Duplicate</DropdownItem>
                    <DropdownItem>Rename</DropdownItem>
                    <DropdownItem>Delete</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>

            <div className="timetable-timeline card p-2">
              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">English</p>
                    <p className="p-0">Jessica</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">8 am - 9 am</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Science</p>
                    <p className="p-0">Jones</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">9 am - 10 am</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="timetable-item card text-light m-1"
                style={{ backgroundColor: "#476257" }}
              >
                <div className="card-body p-2 pl-3 d-flex justify-content-between">
                  <span className="pl-1">Break</span>
                  <small className="pr-3">10 am - 10:15 am</small>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Maths</p>
                    <p className="p-0">George</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">10:15 am - 11:15 am</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Maths</p>
                    <p className="p-0">George</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">11:15 am - 12:15 am</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="timetable-item card text-light m-1"
                style={{ backgroundColor: "#476257" }}
              >
                <div className="card-body p-2 pl-3 d-flex justify-content-between">
                  <span className="pl-1">Lunch Break</span>
                  <small className="pr-3">12:15 pm - 1:00 pm</small>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Language</p>
                    <p className="p-0">Kayalvizhi</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">1 pm - 2 pm</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Crafts</p>
                    <p className="p-0">Mary</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">2 pm - 3 pm</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Computer Science</p>
                    <p className="p-0">Tommy</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">3 pm - 4 pm</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="timetable-item card text-light m-1"
                style={{ backgroundColor: "#476257" }}
              >
                <div className="card-body p-2 pl-3 d-flex justify-content-between">
                  <span className="pl-1">Break</span>
                  <small className="pr-3">8 am - 9 am</small>
                </div>
              </div>
            </div>
          </div>

          <div className="timetable-oneday p-1" style={{ float: "left" }}>
            <div className="row d-flex justify-content-center">
              <h5>
                <span className="badge align-self-center text-light p-2">
                  21-02-2021
                </span>
              </h5>
            </div>
            <div className="timetable-day text-light p-2">
              <div className="card-body d-flex justify-content-between">
                <h3>Tuesday</h3>
                <UncontrolledDropdown className="pl-4  pt-2 ml-4">
                  <DropdownToggle tag="b">
                    <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>Duplicate</DropdownItem>
                    <DropdownItem>Rename</DropdownItem>
                    <DropdownItem>Delete</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>

            <div className="timetable-timeline card p-2">
              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">English</p>
                    <p className="p-0">Jessica</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">8 am - 9 am</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Science</p>
                    <p className="p-0">Jones</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">9 am - 10 am</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="timetable-item card text-light m-1"
                style={{ backgroundColor: "#476257" }}
              >
                <div className="card-body p-2 pl-3 d-flex justify-content-between">
                  <span className="pl-1">Break</span>
                  <small className="pr-3">10 am - 10:15 am</small>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Maths</p>
                    <p className="p-0">George</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">10:15 am - 11:15 am</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Maths</p>
                    <p className="p-0">George</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">11:15 am - 12:15 am</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="timetable-item card text-light m-1"
                style={{ backgroundColor: "#476257" }}
              >
                <div className="card-body p-2 pl-3 d-flex justify-content-between">
                  <span className="pl-1">Lunch Break</span>
                  <small className="pr-3">12:15 pm - 1:00 pm</small>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Language</p>
                    <p className="p-0">Kayalvizhi</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">1 pm - 2 pm</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Crafts</p>
                    <p className="p-0">Mary</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">2 pm - 3 pm</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Computer Science</p>
                    <p className="p-0">Tommy</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">3 pm - 4 pm</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="timetable-item card text-light m-1"
                style={{ backgroundColor: "#476257" }}
              >
                <div className="card-body p-2 pl-3 d-flex justify-content-between">
                  <span className="pl-1">Break</span>
                  <small className="pr-3">8 am - 9 am</small>
                </div>
              </div>
            </div>
          </div>

          <div className="timetable-oneday p-1" style={{ float: "left" }}>
            <div className="row d-flex justify-content-center">
              <h5>
                <span className="badge align-self-center text-light p-2">
                  21-02-2021
                </span>
              </h5>
            </div>
            <div className="timetable-day text-light p-2">
              <div className="card-body d-flex justify-content-between">
                <h3>Wednesday</h3>
                <UncontrolledDropdown className="pl-4  pt-2 ml-4">
                  <DropdownToggle tag="b">
                    <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>Duplicate</DropdownItem>
                    <DropdownItem>Rename</DropdownItem>
                    <DropdownItem>Delete</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>

            <div className="timetable-timeline card p-2">
              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">English</p>
                    <p className="p-0">Jessica</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">8 am - 9 am</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Science</p>
                    <p className="p-0">Jones</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">9 am - 10 am</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="timetable-item card text-light m-1"
                style={{ backgroundColor: "#476257" }}
              >
                <div className="card-body p-2 pl-3 d-flex justify-content-between">
                  <span className="pl-1">Break</span>
                  <small className="pr-3">10 am - 10:15 am</small>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Maths</p>
                    <p className="p-0">George</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">10:15 am - 11:15 am</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Maths</p>
                    <p className="p-0">George</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">11:15 am - 12:15 am</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="timetable-item card text-light m-1"
                style={{ backgroundColor: "#476257" }}
              >
                <div className="card-body p-2 pl-3 d-flex justify-content-between">
                  <span className="pl-1">Lunch Break</span>
                  <small className="pr-3">12:15 pm - 1:00 pm</small>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Language</p>
                    <p className="p-0">Kayalvizhi</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">1 pm - 2 pm</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Crafts</p>
                    <p className="p-0">Mary</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">2 pm - 3 pm</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Computer Science</p>
                    <p className="p-0">Tommy</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">3 pm - 4 pm</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="timetable-item card text-light m-1"
                style={{ backgroundColor: "#476257" }}
              >
                <div className="card-body p-2 pl-3 d-flex justify-content-between">
                  <span className="pl-1">Break</span>
                  <small className="pr-3">8 am - 9 am</small>
                </div>
              </div>
            </div>
          </div>

          <div className="timetable-oneday p-1" style={{ float: "left" }}>
            <div className="row d-flex justify-content-center">
              <h5>
                <span className="badge align-self-center text-light p-2">
                  21-02-2021
                </span>
              </h5>
            </div>
            <div className="timetable-day text-light p-2">
              <div className="card-body d-flex justify-content-between">
                <h3>Thursday</h3>
                <UncontrolledDropdown className="pl-4  pt-2 ml-4">
                  <DropdownToggle tag="b">
                    <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>Duplicate</DropdownItem>
                    <DropdownItem>Rename</DropdownItem>
                    <DropdownItem>Delete</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>

            <div className="timetable-timeline card p-2">
              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">English</p>
                    <p className="p-0">Jessica</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">8 am - 9 am</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Science</p>
                    <p className="p-0">Jones</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">9 am - 10 am</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="timetable-item card text-light m-1"
                style={{ backgroundColor: "#476257" }}
              >
                <div className="card-body p-2 pl-3 d-flex justify-content-between">
                  <span className="pl-1">Break</span>
                  <small className="pr-3">10 am - 10:15 am</small>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Maths</p>
                    <p className="p-0">George</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">10:15 am - 11:15 am</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Maths</p>
                    <p className="p-0">George</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">11:15 am - 12:15 am</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="timetable-item card text-light m-1"
                style={{ backgroundColor: "#476257" }}
              >
                <div className="card-body p-2 pl-3 d-flex justify-content-between">
                  <span className="pl-1">Lunch Break</span>
                  <small className="pr-3">12:15 pm - 1:00 pm</small>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Language</p>
                    <p className="p-0">Kayalvizhi</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">1 pm - 2 pm</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Crafts</p>
                    <p className="p-0">Mary</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">2 pm - 3 pm</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Computer Science</p>
                    <p className="p-0">Tommy</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">3 pm - 4 pm</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="timetable-item card text-light m-1"
                style={{ backgroundColor: "#476257" }}
              >
                <div className="card-body p-2 pl-3 d-flex justify-content-between">
                  <span className="pl-1">Break</span>
                  <small className="pr-3">8 am - 9 am</small>
                </div>
              </div>
            </div>
          </div>

          <div className="timetable-oneday p-1" style={{ float: "left" }}>
            <div className="row d-flex justify-content-center">
              <h5>
                <span className="badge align-self-center text-light p-2">
                  21-02-2021
                </span>
              </h5>
            </div>
            <div className="timetable-day text-light p-2">
              <div className="card-body d-flex justify-content-between">
                <h3>Friday</h3>
                <UncontrolledDropdown className="pl-4  pt-2 ml-4">
                  <DropdownToggle tag="b">
                    <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>Duplicate</DropdownItem>
                    <DropdownItem>Rename</DropdownItem>
                    <DropdownItem>Delete</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>

            <div className="timetable-timeline card p-2">
              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">English</p>
                    <p className="p-0">Jessica</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">8 am - 9 am</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Science</p>
                    <p className="p-0">Jones</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">9 am - 10 am</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="timetable-item card text-light m-1"
                style={{ backgroundColor: "#476257" }}
              >
                <div className="card-body p-2 pl-3 d-flex justify-content-between">
                  <span className="pl-1">Break</span>
                  <small className="pr-3">10 am - 10:15 am</small>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Maths</p>
                    <p className="p-0">George</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">10:15 am - 11:15 am</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Maths</p>
                    <p className="p-0">George</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">11:15 am - 12:15 am</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="timetable-item card text-light m-1"
                style={{ backgroundColor: "#476257" }}
              >
                <div className="card-body p-2 pl-3 d-flex justify-content-between">
                  <span className="pl-1">Lunch Break</span>
                  <small className="pr-3">12:15 pm - 1:00 pm</small>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Language</p>
                    <p className="p-0">Kayalvizhi</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">1 pm - 2 pm</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Crafts</p>
                    <p className="p-0">Mary</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">2 pm - 3 pm</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Computer Science</p>
                    <p className="p-0">Tommy</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">3 pm - 4 pm</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="timetable-item card text-light m-1"
                style={{ backgroundColor: "#476257" }}
              >
                <div className="card-body p-2 pl-3 d-flex justify-content-between">
                  <span className="pl-1">Break</span>
                  <small className="pr-3">8 am - 9 am</small>
                </div>
              </div>
            </div>
          </div>

          <div className="timetable-oneday p-1" style={{ float: "left" }}>
            <div className="row d-flex justify-content-center">
              <h5>
                <span className="badge align-self-center text-light p-2">
                  21-02-2021
                </span>
              </h5>
            </div>
            <div className="timetable-day text-light p-2">
              <div className="card-body d-flex justify-content-between">
                <h3>Saturday</h3>
                <UncontrolledDropdown className="pl-4  pt-2 ml-4">
                  <DropdownToggle tag="b">
                    <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>Duplicate</DropdownItem>
                    <DropdownItem>Rename</DropdownItem>
                    <DropdownItem>Delete</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>

            <div className="timetable-timeline card p-2">
              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">English</p>
                    <p className="p-0">Jessica</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">8 am - 9 am</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Science</p>
                    <p className="p-0">Jones</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">9 am - 10 am</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="timetable-item card text-light m-1"
                style={{ backgroundColor: "#476257" }}
              >
                <div className="card-body p-2 pl-3 d-flex justify-content-between">
                  <span className="pl-1">Break</span>
                  <small className="pr-3">10 am - 10:15 am</small>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Maths</p>
                    <p className="p-0">George</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">10:15 am - 11:15 am</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Maths</p>
                    <p className="p-0">George</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">11:15 am - 12:15 am</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="timetable-item card text-light m-1"
                style={{ backgroundColor: "#476257" }}
              >
                <div className="card-body p-2 pl-3 d-flex justify-content-between">
                  <span className="pl-1">Lunch Break</span>
                  <small className="pr-3">12:15 pm - 1:00 pm</small>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Language</p>
                    <p className="p-0">Kayalvizhi</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">1 pm - 2 pm</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Crafts</p>
                    <p className="p-0">Mary</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">2 pm - 3 pm</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timetable-item card m-1 text-light">
                <div className="card-body col-12 pt-3 pb-0 d-flex">
                  <div className="col-6 p-0 justify-content-start">
                    <p className="p-0">Computer Science</p>
                    <p className="p-0">Tommy</p>
                  </div>
                  <div className="col-6 p-0">
                    <div className="row d-flex justify-content-end p-0">
                      <small className="pr-2">3 pm - 4 pm</small>
                      <UncontrolledDropdown className="p-0">
                        <DropdownToggle tag="b">
                          <i className="fas text-light fa-ellipsis-v cursor-pointer pr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.isTeacherTimetable ? (
                            <DropdownItem>Assign Task</DropdownItem>
                          ) : null}
                          <DropdownItem>Assign Substitute</DropdownItem>
                          {this.state.isClassTimetable ? (
                            <DropdownItem>Edit</DropdownItem>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="timetable-item card text-light m-1"
                style={{ backgroundColor: "#476257" }}
              >
                <div className="card-body p-2 pl-3 d-flex justify-content-between">
                  <span className="pl-1">Break</span>
                  <small className="pr-3">8 am - 9 am</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, dispatchToProps)(ClassTimetable)
