/*************************************************
 * RedInk
 * @exports
 * @class SubjectsScreen.js
 * @extends Component
 * @author Sasidharan // on 06/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { HashLoader } from "react-spinners"
import { connect } from "react-redux"

import {
  getGradeDetails,
  saveGradeDetails,
  saveGradeDetailsInStore,
  updateGradeDetails,
  deleteGradeDetails,
} from "../../../actions/admin/GradeDetailsAction"
import { VALIDATION_MSG, DANGER_TOAST } from "../../../utils/Constants"
import { confirmAlertPopUp } from "../../../utils/Utility"
import { showNotification } from "../../../actions/CommonAction"

import AppWrapper from "../Wrapper1"

class GradeDetailsScreen extends Component {
  static getDerivedStateFromProps = () => {
    return {}
  }

  constructor(props) {
    super(props)
    this.state = {
      isShowAddSubjectInput: false,
      updateSubjectDetailId: "",
      isShowLoading: false,
      grades: [],
    }
  }

  componentDidMount = () => {
    this.props.getGradeDetails({}, (success, response) => {
      if (success) {
        if (response.length > 0) {
          this.setState({ grades: response })
        }
      }
    })
  }

  /**
   * Add new subject
   */
  addSubject = () => {
    const tempARR = this.props.grades
    let object = {
      gradeName: "",
      gradeFrom: "",
      gradeTo: "",
      gradePoint: "",
    }
    tempARR.push(object)
    this.setState({ grades: tempARR }, () => {})
  }

  /**
   * Delete a grade
   */
  deleteGrade = (index, item) => {
    if (item.gradeId) {
      this.props.deleteGradeDetails({ gradeId: item.gradeId }, () => {})
    } else {
      if (this.props.grades.length > 1) {
        let arrSubject = this.props.grades
        arrSubject.splice(index, 1)
        this.setState({ grades: arrSubject })
      }
    }
  }

  /**
   * Delete a academic detail from db
   */
  deleteAcademicDetailFromDb = item => {
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT,
      yes => {
        if (yes) {
          this.props.deleteGradeDetails({ id: item.id }, () => {})
        }
      }
    )
  }

  /**
   * saveSubject
   */
  saveSubject = () => {
    this.setState(
      {
        isShowLoading: true,
      },
      () => {
        let error = false
        for (let i = 0; i < this.props.grades.length; i++) {
          if (
            this.props.grades[i].gradeFrom === "" ||
            this.props.grades[i].gradeTo === "" ||
            this.props.grades[i].gradeName === ""
          ) {
            error = true
          }
        }
        let duplicateError = false
        for (let i = 0; i < this.props.grades.length - 1; i++) {
          for (let j = 1; j < this.props.grades.length; j++) {
            if (i !== j) {
              if (
                this.props.grades[i].gradeName ===
                this.props.grades[j].gradeName
              ) {
                duplicateError = true
                break
              }
            }
          }
          if (duplicateError) break
        }
        if (error) {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            "Fill all the grades..."
          )
          this.setState({
            isShowLoading: false,
          })
        } else if (duplicateError) {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            "Duplicate in grades..."
          )
          this.setState({
            isShowLoading: false,
          })
        } else {
          let object = {
            grades: this.props.grades,
          }
          this.props.saveGradeDetails(object, success => {
            this.setState({
              isShowLoading: false,
            })
            if (success) {
              this.setState({
                isShowAddSubjectInput: false,
              })
            }
          })
        }
      }
    )
  }

  updateSubject = () => {
    this.setState(
      {
        isShowLoading: true,
      },
      () => {
        let error = false
        for (let i = 0; i < this.props.grades.length; i++) {
          if (
            this.props.grades[i].gradeFrom === "" ||
            this.props.grades[i].gradeTo === "" ||
            this.props.grades[i].gradeName === ""
          ) {
            error = true
          }
        }
        if (error) {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            "Fill all the grades..."
          )
          this.setState({
            isShowLoading: false,
          })
        } else {
          let object = {
            grades: this.props.grades,
          }
          this.props.updateGradeDetails(object, success => {
            this.setState({
              isShowLoading: false,
            })
            if (success) {
              this.setState({
                isShowAddSubjectInput: false,
              })
            }
          })
        }
      }
    )
  }

  render() {
    return (
      <AppWrapper
        showAdminProfileSidemenu
        showAdminBackToDashboard
        screenName="Grade Details"
      >
        <div className="card">
          <div className="row m-3">
            <div className="col-sm text-right">
              {this.state.isShowAddSubjectInput ? (
                this.state.isShowUpdateSubjectInput ? (
                  <button
                    type="button"
                    disabled={this.state.isShowLoading}
                    className="btn btn-sm btn-success px-4 rounded-pill"
                    style={{
                      backgroundColor: "#06B191",
                      color: "white ",
                      border: "none",
                    }}
                    onClick={() => this.updateSubject()}
                  >
                    {this.state.isShowLoading ? (
                      <HashLoader
                        size={16}
                        color="#fff"
                        loading={this.state.isShowLoading}
                        sizeUnit="px"
                      />
                    ) : (
                      <>
                        <i className="far fa-save mr-1" />
                        Save
                      </>
                    )}
                  </button>
                ) : (
                  <button
                    type="button"
                    disabled={this.state.isShowLoading}
                    className="btn btn-sm btn-success px-4 rounded-pill"
                    style={{
                      backgroundColor: "#06B191",
                      color: "white ",
                      border: "none",
                    }}
                    onClick={() => this.saveSubject()}
                  >
                    {this.state.isShowLoading ? (
                      <HashLoader
                        size={16}
                        color="#fff"
                        loading={this.state.isShowLoading}
                        sizeUnit="px"
                      />
                    ) : (
                      <>
                        <i className="far fa-save mr-1" />
                        Save
                      </>
                    )}
                  </button>
                )
              ) : (
                <button
                  type="button"
                  className="btn btn-sm btn-success px-4 rounded-pill"
                  style={{ background: "#084353" }}
                  onClick={() => {
                    this.setState({ isShowAddSubjectInput: true })
                    setTimeout(() => {
                      let el = document.getElementById("gr-low-0")
                      if (el) {
                        el.focus()
                      }
                    }, 500)
                  }}
                >
                  <i className="fas fa-save mr-2" />
                  Add/Edit
                </button>
              )}
            </div>
          </div>

          {this.state.isShowAddSubjectInput && (
            <div className="container ml-3">
              <form className="col-sm">
                {this.props.grades &&
                  this.props.grades
                    .sort((a, b) =>
                      parseInt(a.gradeFrom) < parseInt(b.gradeFrom)
                        ? 1
                        : parseInt(a.gradeFrom) > parseInt(b.gradeFrom)
                        ? -1
                        : 0
                    )
                    .map((item, index) => (
                      <div
                        key={index}
                        className="d-flex justify-content-start mb-1"
                      >
                        <div className="row">
                          <div className="col-sm">
                            <div className="form-group">
                              <input
                                id={`gr-low-${index}`}
                                type="number"
                                className="form-control form-control-sm"
                                placeholder="Grade From"
                                value={item.gradeFrom}
                                onChange={e => {
                                  if (
                                    e.target.value.trim() >= 0 &&
                                    e.target.value.trim() <= 100
                                  ) {
                                    let array = this.props.grades
                                    array[
                                      index
                                    ].gradeFrom = e.target.value.trim()
                                    this.setState({
                                      grades: array,
                                    })
                                    this.props.saveGradeDetailsInStore(array)
                                  }
                                }}
                                onKeyDown={e => {
                                  if (e.key === "Enter" && e.target.value) {
                                    let el = document.getElementById(
                                      `gr-hign-${index}`
                                    )
                                    if (el) {
                                      el.focus()
                                    }
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <p className="text-black fs-20">-</p>
                          <div className="col-sm">
                            <div className="form-group">
                              <input
                                id={`gr-hign-${index}`}
                                type="number"
                                className="form-control form-control-sm"
                                placeholder="Grade To"
                                value={item.gradeTo}
                                onChange={e => {
                                  if (
                                    e.target.value.trim() >= 0 &&
                                    e.target.value.trim() <= 100
                                  ) {
                                    let array = this.props.grades
                                    array[index].gradeTo = e.target.value.trim()
                                    this.setState({
                                      grades: array,
                                    })
                                    this.props.saveGradeDetailsInStore(array)
                                  }
                                }}
                                onKeyDown={e => {
                                  if (e.key === "Enter" && e.target.value) {
                                    let el = document.getElementById(
                                      `gr-grade-${index}`
                                    )
                                    if (el) {
                                      el.focus()
                                    }
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <p className="text-black fs-20">=</p>
                          <div className="col-sm">
                            <div className="form-group">
                              <input
                                id={`gr-grade-${index}`}
                                type="text"
                                maxLength="2"
                                className="form-control form-control-sm"
                                placeholder="Grade Name"
                                value={item.gradeName}
                                onChange={e => {
                                  let array = this.props.grades
                                  array[index].gradeName = e.target.value.trim()
                                  this.setState({
                                    grades: array,
                                  })
                                  this.props.saveGradeDetailsInStore(array)
                                }}
                                onKeyDown={e => {
                                  if (e.key === "Enter" && e.target.value) {
                                    let el = document.getElementById(
                                      `gr-point-${index}`
                                    )
                                    if (el) {
                                      el.focus()
                                    }
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm">
                            <div className="form-group">
                              <input
                                id={`gr-point-${index}`}
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Grade Point(optional)"
                                value={item.gradePoint}
                                onChange={e => {
                                  let array = this.props.grades
                                  array[
                                    index
                                  ].gradePoint = e.target.value.trim()
                                  this.setState({
                                    grades: array,
                                  })
                                  this.props.saveGradeDetailsInStore(array)
                                }}
                                onKeyDown={e => {
                                  if (e.key === "Enter") {
                                    let el = document.getElementById(
                                      `gr-low-${index + 1}`
                                    )
                                    if (el) {
                                      el.focus()
                                    }
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm">
                              <i
                                className="fas fa-plus text-success p-2 cursor-pointer"
                                onClick={() => this.addSubject()}
                              />
                              <i
                                className="fas fa-trash-alt text-danger p-2 cursor-pointer"
                                onClick={() => this.deleteGrade(index, item)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </form>
            </div>
          )}

          <div className="row m-5">
            {this.props.grades &&
              this.props.grades.map((grade, i) => (
                <div
                  key={i}
                  className="bg-gray px-3 rounded align-self-center ml-2 mt-2"
                  style={{ background: grade.gradeColor }}
                >
                  {grade.gradeName}
                </div>
              ))}
          </div>
        </div>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    grades: state.gradeDetailsState.gradeDetail,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getGradeDetails,
      saveGradeDetails,
      updateGradeDetails,
      deleteGradeDetails,
      saveGradeDetailsInStore,
      showNotification,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(GradeDetailsScreen)
