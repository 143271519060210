/*************************************************
 * RedInk
 * @exports
 * @class MarkScreenAction.js
 * @extends Component
 * @author vijayakumar // on 05/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { ACTION_TYPES } from "../types"
import { showNotification } from "../CommonAction"
import {
  SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"

export const saveMarkSubjectInStore = markSubjectDetails => {
  return {
    type: ACTION_TYPES.TEACHER_MARK_SUBJECT_DETAILS,
    payload: markSubjectDetails,
  }
}

export const saveMarkStudentListInStore = markStudentList => {
  return {
    type: ACTION_TYPES.TEACHER_MARK_STUDENT_LIST,
    payload: markStudentList,
  }
}

export const onChangeRemarkInAssessmentMarkScreen = payload => {
  return {
    type: ACTION_TYPES.TEACHER_REMARK_ASSESSMENT_MARK_SCREEN,
    payload,
  }
}

export const getMarkSubjectDetails = (object, callBack) => {
  return async dispatch => {
    try {
      let body = {
        // id: object.subjectId,
        // activityId: object.activityId,
        hSubjectId: object.subjectId,
        key: object.key,
        keyId:
          object.key === "S"
            ? object.subjectId
            : object.key === "A"
            ? object.activityId
            : object.key === "T"
            ? object.theoryId
            : object.key === "P"
            ? object.practicalId
            : "",
      }
      myLog("====getMarkSubjectDetails object===::::", object)
      const response = await Client.get(
        URL.GET_MARK_SUBJECT_DETAILS,
        body,
        true
      )
      if (response) {
        myLog("====getMarkSubjectDetails RESPONSE===::::", response)
        dispatch(saveMarkSubjectInStore(response.markSubjectDetails))
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getMarkSubjectDetails ERROR===????", error)
    }
  }
}

export const getMarkStudentList = (object, callBack) => {
  return async dispatch => {
    try {
      myLog("====getMarkStudentList object===::::", object)
      let body = {
        hSubjectId: object.subjectId,
        key: object.key,
        keyId:
          object.key === "S"
            ? object.subjectId
            : object.key === "A"
            ? object.activityId
            : object.key === "T"
            ? object.theoryId
            : object.key === "P"
            ? object.practicalId
            : "",
      }
      const response = await Client.get(
        URL.GET_MARK_STUDENT_lIST,
        body,
        true,
        true
      )
      if (response) {
        let newData = response.markStudentList.sort((a, b) =>
          a.row[1] > b.row[1] ? 1 : -1
        )
        myLog("====getMarkStudentList RESPONSE===::::", response)
        dispatch(saveMarkStudentListInStore(newData))
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getMarkStudentList ERROR===????", error)
    }
  }
}

export const getAssessmentMarkStudentList = (object, callBack) => {
  return async dispatch => {
    try {
      myLog("====getAssessmentMarkStudentList object===::::", object)
      let body = {
        assessmentId: object.faId,
        sectionId: object.sectionId,
      }
      const response = await Client.get(
        URL.GET_ASSESSMENT_MARK_STUDENT_lIST,
        body,
        true
      )
      if (response) {
        let newData = response.markStudentList.sort((a, b) =>
          a.row[1] > b.row[1] ? 1 : -1
        )
        myLog("====getAssessmentMarkStudentList RESPONSE===::::", response)
        dispatch(saveMarkStudentListInStore(newData))
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getAssessmentMarkStudentList ERROR===????", error)
    }
  }
}

export const saveOverAllRemarksInAssessment = (body, callBack) => {
  return async dispatch => {
    try {
      myLog("====saveOverAllRemarksInAssessment body===::::", body)
      const response = await Client.put(
        URL.SAVE_ASSESSMENT_OVERALL_MARK,
        body,
        true,
        true
      )

      myLog("====saveOverAllRemarksInAssessment RESPONSE===::::", response)

      if (response.responseCode === 0) {
        dispatch(showNotification(SUCCESS_TOAST, "", "Remarks updated"))
        callBack(true, response)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Somethings went wrong"
        )
      )
      callBack(false)
      myLog("====saveStudentSubjectMarkObject ERROR===????", error)
    }
  }
}

export const saveStudentSubjectMarkObject = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      myLog("====saveStudentSubjectMarkObject body===::::", body)
      let object = {
        markStudentList: body.markStudentList,
        sectionId: body.sectionId,
        hSubjectId: body.hSubjectId,
        assessmentId: body.assessmentId,
        activityId: body.activityId,
        theoryId: body.theoryId,
        practicalId: body.practicalId,
        key: body.key,
      }
      const response = await Client.post(
        URL.SAVE_STUDENT_SUBJECT_MARK_OBJECT,
        object,
        true
      )
      if (response.responseCode === 0) {
        myLog("====saveStudentSubjectMarkObject RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Somethings went wrong"
        )
      )
      callBack(false)
      myLog("====saveStudentSubjectMarkObject ERROR===????", error)
    }
  }
}

export const saveStudentSubjectReMark = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      myLog("====saveStudentSubjectReMark body===::::", body)
      let object = {
        markStudentList: body.markStudentList,
        sectionId: body.sectionId,
        hSubjectId: body.hSubjectId,
        assessmentId: body.assessmentId,
        activityId: body.activityId,
        theoryId: body.theoryId,
        practicalId: body.practicalId,
        key: body.key,
      }
      const response = await Client.post(
        URL.SAVE_STUDENT_SUBJECT_REMARK,
        object,
        true
      )
      if (response.responseCode === 0) {
        myLog("====saveStudentSubjectReMark RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Somethings went wrong"
        )
      )
      callBack(false)
      myLog("====saveStudentSubjectReMark ERROR===????", error)
    }
  }
}

export const saveStudentSubjectMarkUploadLockDetail = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      myLog("====saveStudentSubjectMarkUploadLockDetail body===::::", body)
      let object = {
        isLocked: body.isLocked,
        hSubjectId: body.subjectId,
        activityId: body.activityId,
        key: body.key,
        keyId:
          body.key === "S"
            ? body.detail.subjectId
            : body.key === "A"
            ? body.detail.activityId
            : body.key === "T"
            ? body.detail.theoryId
            : body.key === "P"
            ? body.detail.practicalId
            : "",
      }
      const response = await Client.post(
        URL.SAVE_STUDENT_SUBJECT_MARK_UPLOAD_LOCK_DETAIL,
        object,
        true
      )
      if (response.responseCode === 0) {
        myLog(
          "====saveStudentSubjectMarkUploadLockDetail RESPONSE===::::",
          response
        )
        // dispatch(getMarkStudentList(body, ()=>{}));
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Somethings went wrong"
        )
      )
      callBack(false)
      myLog("====saveStudentSubjectMarkUploadLockDetail ERROR===????", error)
    }
  }
}

export const saveStudentSubjectGenerateAnalyticsDetail = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      myLog("====saveStudentSubjectGenerateAnalyticsDetail body===::::", body)
      let object = {
        assessmentId: body.assessmentId,
        hSubjectId: body.hSubjectId,
        sectionId: body.sectionId,
      }
      const response = await Client.post(
        URL.SAVE_STUDENT_SUBJECT_GENERATE_ANALYTICS_DETAIL,
        object,
        true
      )
      if (response.responseCode === 0) {
        myLog(
          "====saveStudentSubjectGenerateAnalyticsDetail RESPONSE===::::",
          response
        )
        callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Somethings went wrong. Try again..."
        )
      )
      callBack(false)
      myLog("====saveStudentSubjectGenerateAnalyticsDetail ERROR===????", error)
    }
  }
}

export const saveCheckIsGenerateAnalyticsDetail = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      myLog("====saveStudentSubjectGenerateAnalyticsDetail body===::::", body)
      let object = {
        assessmentId: body.assessmentId,
        sectionId: body.sectionId,
      }
      const response = await Client.get(
        URL.GET_CHECK_IS_GENERATED_ANALYTICS,
        object,
        true
      )
      if (response.responseCode === 0) {
        myLog(
          "====saveStudentSubjectGenerateAnalyticsDetail RESPONSE===::::",
          response
        )
        callBack(true, response)
      } else if (response.responseCode === 1) {
        myLog(
          "====saveStudentSubjectGenerateAnalyticsDetail RESPONSE===::::",
          response
        )
        callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            "Somethings went wrong. Try again..."
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Somethings went wrong. Try again..."
        )
      )
      callBack(false)
      myLog("====saveStudentSubjectGenerateAnalyticsDetail ERROR===????", error)
    }
  }
}

export const getXlsheetTemplate = (subjectId, callBack) => {
  return async dispatch => {
    try {
      const params = {
        id: subjectId,
      }
      myLog("====get XLSheet object===::::", subjectId)
      const response = await Client.get(
        URL.GET_STUDENT_REMARKS_XL_SHEET_TEMPLATE,
        params,
        false,
        true
      )
      callBack(response)
    } catch (error) {
      callBack(false)
      myLog("====get XLSheet ERROR===????", error)
    }
  }
}

export const getXlsheetReport = (subjectId, callBack) => {
  return async dispatch => {
    try {
      const params = {
        id: subjectId,
      }
      myLog("====get XLSheet object===::::", subjectId)
      const response = await Client.get(
        URL.GET_STUDENT_REMARKS_XL_SHEET_REPORT,
        params,
        false,
        true
      )
      callBack(response)
    } catch (error) {
      callBack(false)
      myLog("====get XLSheet ERROR===????", error)
    }
  }
}
