/*************************************************
 * RedInk
 * @exports
 * @class TeachersScreen.js
 * @extends Component
 * @author Sasidharan // on 11/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import Select from "react-select"
import AppWrapper from "../Wrapper"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  getTeacherDetails,
  getTeacherList,
  saveTeacherDetails,
  updateTeacherDetails,
  deleteTeacherDetails,
} from "../../../actions/superAdmin/TeacherDetailsAction"
import { myLog } from "../../../utils/Utility"
import { getClassDetails } from "../../../actions/superAdmin/ClassDetailsAction"
import { getSubjectDetails } from "../../../actions/superAdmin/SubjectDetailsAction"
import { getSchoolsListDetails } from "../../../actions/superAdmin/schoolDetailsAction"
import malePic from "../../../assets/images/men.png"
import femalePic from "../../../assets/images/female.png"
import { URL_PATH } from "../../../config/urlPath"
import { saveTeacherDetailsInStore } from "../../../actions/admin/TeacherDetailsAction"
import debounce from "lodash/debounce"
import { Input } from "antd"
class TeachersScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      savedTeachers: true,
      teacherName: "",
      teacherGender: "",
      teacherEmail: "",
      teacherContactNumber: "",
      teacherIsActive: true,
      teacherId: "",
      assignedSubjects: [],
      assignedClasses: [],
      replaceExistingTeacher: {},
      classTeacher: {},
      updateTeacherInput: false,
      replaceExistingTeacherList: [],
      subjectsList: [],
      classesList: [],
      usersList: [],
      userDummyList: "",
      searchTeacher: "",
    }
  }

  static getDerivedStateFromProps(props, state) {
    myLog(props.teacherList)
    return {
      usersList: props.teacherList,
    }
  }

  componentDidMount = () => {
    this.props.getSchoolsListDetails((schoolSuccess, schoolResponse) => {
      this.getTeacherDetailsService(schoolResponse[0].branchId)
    })
  }

  getTeacherDetailsService = branchId => {
    let object = {
      branchId,
    }
    this.props.getTeacherList(object, (success, response) => {})
    this.props.getSubjectDetails(object, (success, response) => {})
    this.props.getClassDetails(object, (success, response) => {})
    this.props.getTeacherDetails(object, (success, response) => {
      if (success) {
        this.setState({ userDummyList: response })
        myLog("Teacher details", response)
      }
    })
  }

  cancelSubmit = () => {
    this.setState({
      teacherName: "",
      teacherGender: "",
      teacherEmail: "",
      teacherContactNumber: "",
      teacherIsActive: true,
      teacherId: "",
      assignedSubjects: [],
      assignedClasses: [],
      replaceExistingTeacher: {},
      classTeacher: {},
      updateTeacherInput: false,
    })
  }

  updateTeacherInputData = teacherDetails => {
    this.setState({
      teacherName: teacherDetails.teacherName,
      teacherGender: teacherDetails.gender,
      teacherEmail: teacherDetails.emailId,
      teacherContactNumber: teacherDetails.contactNumber,
      teacherIsActive: teacherDetails.status === 1 ? true : false,
      teacherId: teacherDetails.id,
      assignedSubjects: teacherDetails.assignedSubjects,
      assignedClasses: teacherDetails.assignedClasses,
      replaceExistingTeacher: teacherDetails.replacedFor,
      classTeacher: teacherDetails.classTeacherFor,
      updateTeacherInput: true,
      savedTeachers: false,
    })
  }
  /**
   * Delete a subject from db
   */
  deleteTeacherFromDb = item => {
    myLog(item.id)
    this.props.deleteTeacherDetails({ id: item.id }, () => {})
  }

  /**
   * saveSubject
   */
  saveTeacher = () => {
    myLog(this.state.teacherName)
    if (
      this.state.teacherName !== "" &&
      this.state.teacherGender !== "" &&
      this.state.teacherEmail !== "" &&
      this.state.teacherContactNumber !== "" &&
      this.state.teacherIsActive !== "" &&
      this.state.assignedSubjects.length !== 0 &&
      this.state.assignedClasses.length !== 0 &&
      Object.keys(this.state.replaceExistingTeacher).length !== 0 &&
      Object.keys(this.state.classTeacher).length !== 0
    ) {
      let teacherObject = {
        teacherName: this.state.teacherName,
        gender: this.state.teacherGender,
        emailId: this.state.teacherEmail,
        contactNumber: this.state.teacherContactNumber,
        status: this.state.teacherIsActive ? 1 : 0,
        assignedSubjects: this.state.assignedSubjects,
        assignedClasses: this.state.assignedClasses,
        replacedFor: this.state.replaceExistingTeacher,
        classTeacherFor: this.state.classTeacher,
      }
      myLog(teacherObject)
      this.setState(
        {
          isShowLoading: true,
        },
        () => {
          this.props.saveTeacherDetails(teacherObject, success => {
            this.setState({
              isShowLoading: false,
            })
            myLog("success", success)
            if (success) {
              this.cancelSubmit()
              this.setState({ savedTeachers: true })
            }
          })
        }
      )
    } else {
      this.setState({ hasError: true })
      myLog(
        "error",
        this.state.teacherName,
        this.state.replaceExistingTeacher,
        this.state.classTeacher
      )
    }
  }

  updateTeacher = () => {
    myLog(this.state.teacherName)
    if (
      this.state.teacherName !== "" &&
      this.state.teacherGender !== "" &&
      this.state.teacherEmail !== "" &&
      this.state.teacherContactNumber !== "" &&
      this.state.teacherIsActive !== "" &&
      this.state.assignedSubjects !== "" &&
      this.state.assignedClasses !== "" &&
      Object.keys(this.state.replaceExistingTeacher).length !== 0 &&
      Object.keys(this.state.classTeacher).length !== 0
    ) {
      let teacherObject = {
        id: this.state.teacherId,
        teacherName: this.state.teacherName,
        gender: this.state.teacherGender,
        emailId: this.state.teacherEmail,
        contactNumber: this.state.teacherContactNumber,
        status: this.state.teacherIsActive ? 1 : 0,
        assignedSubjects: this.state.assignedSubjects,
        assignedClasses: this.state.assignedClasses,
        replacedFor: this.state.replaceExistingTeacher,
        classTeacherFor: this.state.classTeacher,
      }
      myLog(teacherObject)
      this.setState(
        {
          isShowLoading: true,
        },
        () => {
          this.props.updateTeacherDetails(teacherObject, success => {
            this.setState({
              isShowLoading: false,
            })
            myLog("success", success)
            if (success) {
              this.cancelSubmit()
              this.setState({ savedTeachers: true })
            }
          })
        }
      )
    } else {
      this.setState({ hasError: true })
      myLog("error", this.state.teacherName)
    }
  }
  searchTeacher = value => {
    // this.setState({ searchTeacher: e.target.value })
    let usersList = this.state.userDummyList
    let filteredArray =
      usersList.length &&
      usersList.filter(
        data =>
          JSON.stringify(data)
            .toLowerCase()
            .indexOf(value.toLowerCase()) !== -1
      )
    this.props.saveTeacherDetailsInStore(
      JSON.parse(JSON.stringify(filteredArray))
    )

    this.setState(
      { usersList: JSON.parse(JSON.stringify(filteredArray)) },
      () => {
        myLog(this.state.usersList)
      }
    )
  }

  searchFunction = debounce(
    () => this.searchTeacher(this.state.searchTeacher),
    300
  )

  sortOrder = data => {
    const classOrder = [
      "-",
      "JKG",
      "LKG",
      "SKG",
      "UKG",
      ...Array(12)
        .fill()
        .map((_, idx) => `${1 + idx}`),
    ]
    const teachers = data
    teachers.sort((a, b) => {
      const aClass = a.classTeacherFor.value?.split("-")[0] || "-"
      const aSection = a.classTeacherFor.value?.split("-")[1]
      const bClass = b.classTeacherFor.value?.split("-")[0] || "-"
      const bSection = b.classTeacherFor.value?.split("-")[1]
      if (aClass === bClass) return aSection < bSection ? -1 : 1
      else return classOrder.indexOf(aClass) - classOrder.indexOf(bClass)
    })
    teachers.forEach(teacher => {
      teacher.assignedClasses.sort((a, b) => {
        const aClass = a.value?.split("-")[0] || "-"
        const aSection = a.value?.split("-")[1]
        const bClass = b.value?.split("-")[0] || "-"
        const bSection = b.value?.split("-")[1]
        if (aClass === bClass) return aSection < bSection ? -1 : 1
        else return classOrder.indexOf(aClass) - classOrder.indexOf(bClass)
      })
    })
    return teachers
  }

  render() {
    return (
      <AppWrapper
        showProfileSidemenu
        showBackToDashboard
        onChangeSchoolName={item => {
          myLog(item)
          this.getTeacherDetailsService(item.branchId)
        }}
      >
        <div className="card bg-white p-3 shadow">
          <span className="font-weight-bold fs-25">
            TEACHERS ({this.state.usersList.length})
          </span>
          <div
            className={`container ${this.state.savedTeachers ? "d-none" : ""}`}
          >
            <form>
              <div className="form-row">
                <div className="col-sm">
                  <div className="form-group w-100">
                    <small>Name</small>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.teacherName}
                      onChange={e =>
                        this.setState({ teacherName: e.target.value })
                      }
                    />
                    <div className="d-flex justify-content-center">
                      {this.state.teacherName === "" && this.state.hasError ? (
                        <p className="text-danger">Teacher Name is required.</p>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-sm">
                  <div className="form-group">
                    <small>Gender</small>
                    <select
                      className="form-control"
                      value={this.state.teacherGender}
                      onChange={e =>
                        this.setState({ teacherGender: e.target.value })
                      }
                    >
                      <option value="">Select</option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                      <option value="O">Other</option>
                    </select>
                    <div className="d-flex justify-content-center">
                      {this.state.teacherGender === "" &&
                      this.state.hasError ? (
                        <p className="text-danger">Gender is required.</p>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-sm">
                  <div className="form-group w-100">
                    <small>Email Address</small>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.teacherEmail}
                      onChange={e =>
                        this.setState({ teacherEmail: e.target.value })
                      }
                    />
                    <div className="d-flex justify-content-center">
                      {this.state.teacherEmail === "" && this.state.hasError ? (
                        <p className="text-danger">
                          Email Address is required.
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-sm">
                  <div className="form-group w-100">
                    <small>Contact Number</small>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.teacherContactNumber}
                      onChange={e =>
                        this.setState({ teacherContactNumber: e.target.value })
                      }
                    />
                    <div className="d-flex justify-content-center">
                      {this.state.teacherContactNumber === "" &&
                      this.state.hasError ? (
                        <p className="text-danger">
                          Contact Number is required.
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="col-sm">
                  <div className="form-group w-100">
                    <small>Assigned Subjects</small>
                    <Select
                      placeholder="Select subjects"
                      name="classes"
                      isClearable
                      isMulti
                      value={this.state.assignedSubjects}
                      options={this.props.subjectsList}
                      className="basic-multi-select mb-3"
                      classNamePrefix="select"
                      onChange={selectedOption => {
                        myLog(selectedOption)
                        this.setState({ assignedSubjects: selectedOption })
                      }}
                    />
                    <div className="d-flex justify-content-center">
                      {this.state.assignedSubjects.length === 0 &&
                      this.state.hasError ? (
                        <p className="text-danger">
                          Assigned Subjects is required.
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-sm">
                  <div className="form-group">
                    <small>Assigned Classes</small>
                    <Select
                      placeholder="Select assigned classes"
                      name="classes"
                      isClearable
                      isMulti
                      value={this.state.assignedClasses}
                      options={this.props.classesList}
                      className="basic-multi-select mb-3"
                      classNamePrefix="select"
                      onChange={selectedOption => {
                        myLog(selectedOption)
                        this.setState({ assignedClasses: selectedOption })
                      }}
                    />
                    <div className="d-flex justify-content-center">
                      {this.state.assignedClasses.length === 0 &&
                      this.state.hasError ? (
                        <p className="text-danger">
                          Assigned Classes is required.
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-sm">
                  <div className="form-group w-100">
                    <small>Class Teacher (optional)</small>
                    <Select
                      placeholder="Select class"
                      name="classes"
                      value={this.state.classTeacher}
                      isClearable
                      options={this.props.classesList}
                      className="basic-multi-select mb-3"
                      classNamePrefix="select"
                      onChange={selectedOption => {
                        myLog(selectedOption)
                        this.setState({ classTeacher: selectedOption })
                      }}
                    />
                    <div className="d-flex justify-content-center">
                      {Object.keys(this.state.classTeacher).length === 0 &&
                      this.state.hasError ? (
                        <p className="text-danger">
                          Class Teacher is required.
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <hr></hr>
            <h6>Replace existing teacher</h6>
            <div className="form-row">
              <div className="col-sm">
                <div className="form-group w-100">
                  <small>Name</small>
                  <Select
                    placeholder="Select teacher"
                    name="classes"
                    isClearable
                    value={this.state.replaceExistingTeacher}
                    options={this.props.replaceExistingTeacherList}
                    className="basic-multi-select mb-3"
                    classNamePrefix="select"
                    onChange={selectedOption => {
                      myLog(selectedOption)
                      this.setState({ replaceExistingTeacher: selectedOption })
                    }}
                  />
                  <div className="d-flex justify-content-center">
                    {Object.keys(this.state.replaceExistingTeacher).length ===
                      0 && this.state.hasError ? (
                      <p className="text-danger">
                        Replace existing teacher is required.
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="mr-2">
                <div className="d-flex flex-column">
                  <small>Status</small>
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={() =>
                        this.setState({
                          teacherIsActive: !this.state.teacherIsActive,
                        })
                      }
                      checked={this.state.teacherIsActive}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-secondary mt-3 mr-2 rounded-pill"
                  onClick={() => {
                    this.cancelSubmit()
                    this.setState({ savedTeachers: true })
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-success mt-3 rounded-pill"
                  onClick={() => {
                    if (!this.state.updateTeacherInput) {
                      this.saveTeacher()
                    } else {
                      this.updateTeacher()
                    }
                  }}
                >
                  <i className="fas fa-save mr-2" />
                  {!this.state.updateTeacherInput
                    ? "Save changes"
                    : "Save changes"}
                </button>
              </div>
            </div>
          </div>
          {/* Tables Starts */}
          <div className="my-3">
            <div className="row">
              <div className="col-sm">
                <Input
                  type="text"
                  className="px-3 py-2 shadow rounded-pill border-0 teachersSearchBox"
                  placeholder="Search"
                  style={{ width: "calc(100% - 27rem)" }}
                  value={this.state.searchTeacher}
                  onChange={e => {
                    this.setState(
                      { searchTeacher: e.target.value },
                      this.searchFunction
                    )
                    // this.searchTeacher(e)
                  }}
                  allowClear
                />
              </div>
              <div className="col-sm text-right">
                {/* <button
                  type="button"
                  className="btn btn-sm btn-dark rounded-pill addTeacherButton"
                  onClick={() => this.setState({ savedTeachers: false })}
                >
                  <i className="fas fa-plus mr-2" />
                  Add Teacher
                </button> */}
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-hover table-borderless">
              <thead className="bg-theme-light text-white">
                <tr>
                  <th scope="col" className="text-center">
                    Name
                  </th>
                  <th scope="col" className="text-center">
                    Gender
                  </th>
                  <th scope="col" className="text-center">
                    Assigned Class
                  </th>
                  <th scope="col"></th>
                  <th scope="col">Assigned Subjects</th>
                  {/* <th scope="col">Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {this.state.usersList &&
                  this.sortOrder(this.state.usersList)
                    // .sort(function(a, b) {
                    //   if (
                    //     a.classTeacherFor.value !== undefined &&
                    //     b.classTeacherFor.value !== undefined
                    //   ) {
                    //     const a_val = a.classTeacherFor.value.split("-")
                    //     const b_val = b.classTeacherFor.value.split("-")
                    //     if (a_val.length > 0 && b_val.length > 0) {
                    //       return parseInt(a_val[0]) > parseInt(b_val[0])
                    //     } else {
                    //       return -1
                    //     }
                    //   }
                    //   return 1
                    // })
                    .map((item, index) => {
                      return (
                        <tr key={index} style={{ height: "5.5rem" }}>
                          <td
                            className="row ml-4 mt-4 text-center"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.props.history.push({
                                pathname: URL_PATH.SUPER_ADMIN_TEACHER_PROFILE,
                                state: {
                                  teacherId: item.id,
                                },
                              })
                            }}
                          >
                            <img
                              className="rounded-circle mr-2"
                              src={item.gender === "M" ? malePic : femalePic}
                              alt=""
                              width={25}
                            />
                            {item.teacherName}
                          </td>
                          <td
                            className="text-center"
                            style={{ verticalAlign: "middle" }}
                          >
                            {item.gender === "M" ? (
                              <i className="fas fa-male text-green"></i>
                            ) : (
                              <i className="fas fa-female text-red"></i>
                            )}
                          </td>
                          <td
                            className="text-center"
                            style={{ verticalAlign: "middle" }}
                          >
                            {item.assignedClasses &&
                            item.assignedClasses.length > 0
                              ? item.assignedClasses.map((classes, index) => {
                                  return (
                                    <span
                                      className="badge px-1 bg-theme-verylight ml-1t"
                                      style={{ marginLeft: 5 }}
                                    >
                                      {classes.class}
                                    </span>
                                  )
                                })
                              : "-"}
                          </td>
                          <td></td>
                          <td style={{ verticalAlign: "middle" }}>
                            <div
                              className={`${
                                item.assignedSubjects.length === 0 ||
                                item.assignedSubjects.length === 1
                                  ? "text-center"
                                  : "d-flex flex-wrap"
                              }`}
                            >
                              {item.assignedSubjects &&
                              item.assignedSubjects.length > 0
                                ? item.assignedSubjects.map(
                                    (subject, index) => {
                                      return (
                                        <span className="bg-secondary p-1 rounded text-light mr-2">
                                          {subject.subjectName}
                                        </span>
                                      )
                                    }
                                  )
                                : "-"}
                            </div>
                          </td>
                          {/* <td>
                        <button className="badge badge-pill badge-secondary mr-2" onClick={()=>{
                          this.updateTeacherInputData(item); this.setState({ updateTeacherInput: true })
                          }}
                        >
                          <small>
                            <i className="far fa-user-edit mr-1" />
                            Edit
                          </small>
                        </button>
                        <button className="badge badge-pill badge-danger" onClick={()=>{
                          this.deleteTeacherFromDb(item);
                        }}>
                          <small>
                            <i className="far fa-user-minus mr-1" />
                            Delete
                          </small>
                        </button>
                      </td> */}
                        </tr>
                      )
                    })}
              </tbody>
            </table>
          </div>
        </div>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    subjectsList: state.subjectDetailsState.subjectList,
    classesList: state.classDetailsState.classList,
    replaceExistingTeacherList: state.teacherDetailsState.teacherList,
    teacherList: state.teacherDetailsState.teacherDetail,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getSchoolsListDetails,
      getTeacherList,
      getTeacherDetails,
      saveTeacherDetails,
      updateTeacherDetails,
      deleteTeacherDetails,
      getClassDetails,
      getSubjectDetails,
      saveTeacherDetailsInStore,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(TeachersScreen)
