import React, { useEffect, useState } from "react"
import { HashLoader } from "react-spinners"
import { Modal } from "reactstrap"
import pdfMake from "pdfmake"

import {
  getTermListInReportCard,
  downloadConsolidatedReportCard,
} from "../../../../actions/admin/ReportCardActions"

import { ReactComponent as Close } from "../../../../assets/images/close.svg"
import CheckBox from "./CheckBox"

const fonts = {
  Roboto: {
    normal:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
    bold:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
    italics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
    bolditalics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
  },
}

function ConsolidatedReport({
  classId,
  sectionId,
  className,
  updateShowConsolidate,
}) {
  const [isDownLoading, setDownIsLoading] = useState(false)
  const [assessmentId, setAssessentId] = useState("")
  const [list, setList] = useState([])

  const [details, setDetails] = useState({
    convertedScore: true,
    actualScore: true,
    percentage: true,
    grade: true,
  })

  useEffect(() => {
    const param =
      classId +
      `&sectionId=${sectionId}&flatList=1&retrieveOnlyAnalyticsGenerated=1`
    getTermListInReportCard(param, (success, res) => {
      if (success) {
        setList(res)
      }
    })
  }, [classId, sectionId])

  const btnClk = () => {
    setDownIsLoading(true)
    const params = {
      assessmentId,
      sectionId,
      termId: list.find(a => a.assessmentId === assessmentId)?.termId,
    }

    downloadConsolidatedReportCard(params, (success, res) => {
      if (success) {
        res.studentList.sort((a, b) =>
          a.studentInfo.studentName.toLowerCase() <
          b.studentInfo.studentName.toLowerCase()
            ? -1
            : 1
        )

        const overAllColumHeader = []
        let overAllColSpan = 2
        let colSpan = 0

        if (details.actualScore) {
          overAllColumHeader.push(
            res.studentList[0].assessmentTotalMaxMarkActual
          )
          overAllColSpan = overAllColSpan + 1
          colSpan = colSpan + 1
        }
        if (details.convertedScore) {
          overAllColumHeader.push(res.studentList[0].assessmentTotalMaxMark)
          overAllColSpan = overAllColSpan + 1
          colSpan = colSpan + 1
        }
        if (details.percentage) {
          colSpan = colSpan + 1
        }
        if (details.grade) {
          colSpan = colSpan + 1
        }

        overAllColumHeader.push("%")
        overAllColumHeader.push("Gr")

        const overAllNeedToBeFilled = new Array(overAllColSpan - 1).fill("")
        const needToBeFilled = new Array(colSpan - 1).fill("")

        const docDefinition = {
          content: [
            {
              text: `${className} - CONSOLIDATED REPORT`,
              fontSize: 14,
              bold: true,
              color: "#253978",
            },
            {
              columns: [
                // {
                //   image: res.schoolDetails.schoolLogo,
                //   width: 100,
                //   height: 100
                // },
                {
                  alignment: "left",
                  ul: [
                    {
                      text: res.schoolDetails.schoolName,
                      listType: "none",
                      fontSize: 14,
                      bold: true,
                    },
                    {
                      text: res.schoolDetails.city,
                      listType: "none",
                    },
                  ],
                },
              ],
            },
            {
              margin: [0, 8, 0, 12],
              alignment: "left",
              columns: [
                { text: "Exam name: ", width: "auto" },
                { text: "", width: 4 },
                { text: res.assessmentName, bold: true, width: "auto" },
                { text: "", width: 20 },
                { text: "No. of Students: ", width: "auto" },
                { text: "", width: 4 },
                { text: res.studentList.length, bold: true, width: "auto" },
                { text: "", width: 20 },
                { text: "Class Teacher: ", width: "auto" },
                { text: "", width: 4 },
                { text: res.teacherInfo.name, bold: true, width: "auto" },
              ],
            },
            {
              table: {
                headerRows: 2,
                body: [
                  [
                    {
                      text: "S.No",
                      rowSpan: 2,
                      margin: [0, 8, 0, 0],
                      bold: true,
                    },
                    {
                      text: "Roll No",
                      rowSpan: 2,
                      margin: [0, 8, 0, 0],
                      bold: true,
                    },
                    {
                      text: "Student Name",
                      rowSpan: 2,
                      margin: [0, 8, 0, 0],
                      bold: true,
                    },
                    ...res.subjectList.reduce((prev, curr) => {
                      prev.push({
                        text: curr.subjectShortName,
                        bold: true,
                        colSpan,
                      })
                      prev.push(...needToBeFilled)
                      return prev
                    }, []),
                    { text: "Overall", colSpan: overAllColSpan, bold: true },
                    ...overAllNeedToBeFilled,
                  ],
                  [
                    "",
                    "",
                    "",
                    ...res.subjectList.reduce((prev, curr) => {
                      const arr = []
                      if (details.actualScore) {
                        prev.push(curr.subjectAcutalTotalMark)
                      }
                      if (details.convertedScore) {
                        prev.push(curr.subjectTotalMark)
                      }
                      if (details.percentage) {
                        prev.push("%")
                      }
                      if (details.grade) {
                        prev.push("Gr")
                      }
                      prev.push(...arr)
                      return prev
                    }, []),
                    ...overAllColumHeader,
                  ],
                  ...res.studentList.map((s, i) => {
                    const final = [
                      i + 1,
                      {
                        text: s.studentInfo.registerRollNumber,
                        alignment: "left",
                      },
                      {
                        text: s.studentInfo.studentName,
                        alignment: "left",
                        bold: true,
                      },
                      ...res.subjectList.reduce((prev, curr) => {
                        let foundMark = s.assessmentMarksList.find(
                          aML => aML.subjectId === curr.subjectId
                        )
                        let {
                          studentActualMark,
                          studentMark,
                          percentage,
                          grade,
                        } = foundMark || {
                          studentActualMark: "-",
                          studentMark: "-",
                          percentage: "-",
                          grade: "-",
                        }
                        if (details.actualScore) {
                          prev.push(studentActualMark)
                        }
                        if (details.convertedScore) {
                          prev.push(studentMark)
                        }
                        if (details.percentage) {
                          prev.push(percentage)
                        }
                        if (details.grade) {
                          prev.push(grade)
                        }
                        return prev
                      }, []),
                    ]

                    if (details.actualScore) {
                      final.push(s.assessmentTotalStudentMarksActual)
                    }
                    if (details.convertedScore) {
                      final.push(s.assessmentTotalStudentMarks)
                    }

                    final.push(s.assessmentPercentage)
                    final.push(s.assessmentGrade)

                    return final
                  }),
                  [
                    {
                      text: "Total no. of participants",
                      colSpan: 3,
                    },
                    "",
                    "",
                    ...res.subjectList.reduce((prev, curr) => {
                      prev.push({
                        text: Number(curr.totalParticipants).toFixed(),
                        colSpan,
                      })
                      prev.push(...needToBeFilled)
                      return prev
                    }, []),
                    { text: "", colSpan: overAllColSpan },
                    ...overAllNeedToBeFilled,
                  ],
                ],
              },
              layout: {
                fillColor: function(rowIndex) {
                  return rowIndex < 2 || rowIndex % 2 !== 0 ? "#f4f4f4" : null
                },
                hLineColor: function() {
                  return "#939393"
                },
                vLineColor: function() {
                  return "#939393"
                },
                paddingLeft: function() {
                  return 6
                },
                paddingRight: function() {
                  return 6
                },
                paddingTop: function() {
                  return 6
                },
                paddingBottom: function() {
                  return 6
                },
              },
            },
            {
              text: "",
              margin: [0, 8, 0, 0],
            },
            {
              alignment: "left",
              table: {
                width: [200, 200],
                body: [
                  [
                    { text: "Toppers", bold: true },
                    { text: "Slow Learners", bold: true },
                  ],
                  [
                    {
                      ul: res.toppers.map(a => a.studentName),
                    },
                    {
                      ul: res.slowLearners.map(a => a.studentName),
                    },
                  ],
                ],
              },
              layout: "noBorders",
            },
          ],
          pageSize: "A4",
          pageOrientation: "landscape",
          pageMargins: 20,
          defaultStyle: {
            alignment: "center",
            fontSize: 7,
          },
        }

        if (details.actualScore) {
          docDefinition.content[3].table.body.push([
            { text: "Subject Total (Actual)", colSpan: 3 },
            "",
            "",
            ...res.subjectList.reduce((prev, curr) => {
              prev.push({
                text: Number(curr.totalStudentActualMark).toFixed(),
                colSpan,
              })
              prev.push(...needToBeFilled)
              return prev
            }, []),
            { text: "", colSpan: overAllColSpan },
            ...overAllNeedToBeFilled,
          ])
        }

        if (details.convertedScore) {
          docDefinition.content[3].table.body.push([
            { text: "Subject Total (Final)", colSpan: 3 },
            "",
            "",
            ...res.subjectList.reduce((prev, curr) => {
              prev.push({
                text: Number(curr.totalStudentMark).toFixed(),
                colSpan,
              })
              prev.push(...needToBeFilled)
              return prev
            }, []),
            { text: "", colSpan: overAllColSpan },
            ...overAllNeedToBeFilled,
          ])
        }

        docDefinition.content[3].table.body.push([
          { text: "Subject Average", colSpan: 3 },
          "",
          "",
          ...res.subjectList.reduce((prev, curr) => {
            prev.push({
              text: Number(curr.totalStudentAverageMark).toFixed(),
              colSpan,
            })
            prev.push(...needToBeFilled)
            return prev
          }, []),
          { text: "", colSpan: overAllColSpan },
          ...overAllNeedToBeFilled,
        ])

        pdfMake.createPdf(docDefinition, null, fonts).getBase64(async data => {
          const blob = await fetch(
            `data:application/pdf;base64,${data}`
          ).then(res => res.blob())
          const browserUrl = window.webkitURL || window.URL
          const pdfURL = browserUrl.createObjectURL(blob)
          const a = document.createElement("a")
          a.href = pdfURL
          a.download = `${className} - Consolidate report.pdf`
          a.click()
          browserUrl.revokeObjectURL(pdfURL)
          setDownIsLoading(false)
        })
      }
    })
  }

  const onChange = val => {
    setDetails(p => ({
      ...p,
      [val]: !p[val],
    }))
  }

  return (
    <Modal
      isOpen
      backdropClassName="modal-backdrop-show"
      modalClassName="modal-dialog-shadow-none"
      contentClassName="report-modal-wrapper p-4"
      centered
    >
      <div className="d-flex align-items-center">
        <h1 className="report-modal-wrapper-h1 mb-0 fw-600">
          Consolidated report for {className}
        </h1>

        <Close
          className="report-modal-close-icon ml-auto"
          onClick={updateShowConsolidate}
        />
      </div>

      <div className="my-4">
        <label
          htmlFor="SelectAssessmentInConsolidate"
          className="mr-2 mb-0 fs-18 fw-500"
          style={{ flexShrink: "0" }}
        >
          Select Assessment :
        </label>

        <select
          id="SelectAssessmentInConsolidate"
          value={assessmentId}
          onChange={e => setAssessentId(e.target.value)}
          className="p-2 w-100 border-eee"
        >
          <option value="" disabled></option>
          {list.map(a => (
            <option key={a.assessmentId} value={a.assessmentId}>
              {a.assessmentName}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-5 mt-3">
        <p className="fs-18 fw-500">Display</p>

        <div className="grid-col-2 gap-1rem px-4">
          <CheckBox
            title="Actual Score"
            checked={details.actualScore}
            lableCls="fs-18 fw-500"
            onChange={() => onChange("actualScore")}
          />
          <CheckBox
            title="Converted Score"
            checked={details.convertedScore}
            lableCls="fs-18 fw-500"
            onChange={() => onChange("convertedScore")}
          />
          <CheckBox
            title="Percentage"
            checked={details.percentage}
            lableCls="fs-18 fw-500"
            onChange={() => onChange("percentage")}
          />
          <CheckBox
            title="Grade"
            checked={details.grade}
            lableCls="fs-18 fw-500"
            onChange={() => onChange("grade")}
          />
        </div>
      </div>

      <button
        onClick={btnClk}
        className="disabled-btn px-4 py-2 mx-auto fs-15 fw-600 text-white bg-5DD1AD br-8px text-center"
        style={{ display: "inline-block" }}
        disabled={
          isDownLoading ||
          !assessmentId ||
          !(
            details.actualScore ||
            details.convertedScore ||
            details.grade ||
            details.percentage
          )
        }
      >
        {isDownLoading ? <HashLoader size={16} color="#fff" /> : "Download"}
      </button>
    </Modal>
  )
}

export default ConsolidatedReport
