/*************************************************
 * RedInk
 * @exports
 * @class StudentRecord.js
 * @extends Component
 * @author Sasidharan // on 01/02/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
} from "reactstrap"
import moment from "moment"
// import "antd/dist/antd.css"
// import { myLog } from "../../../utils/Utility"

class HierarchyItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // hierarchySubjects: []
    }
  }

  dateValue = date => {
    let array = []
    if (date.length > 0) {
      for (let i = 0; i < date.length; i++) {
        if (date[i] === "Invalid date" || date[i] === null || date[i] === "") {
          array.push("")
        } else {
          array.push(moment(date[i]))
        }
      }
    } else {
      array = ["", ""]
    }
    return array
  }

  shortName = s => {
    if (s.split(" ").length > 1) {
      var wordSplit = s.split(" ")
      let joined = ""
      for (let i = 0; i < wordSplit.length; i++) {
        if (wordSplit[i][0] === undefined) {
          continue
        }
        joined += String(wordSplit[i][0])
      }
      return joined.toUpperCase()
    } else {
      return s.toUpperCase()
    }
  }

  render() {
    const {
      item,
      index,
      hierarchyActivities,
      hierarchyTerms,
      hierarchySubjects,
      hierarchyFAs,
      hierarchyTheoryAndPractical,
      selectedIndex,
    } = this.props

    return (
      <>
        {item.isLockedHierarchy ? (
          <div
            className={`inner-box-hierarchy gr-inner-box disabled ${
              index === 0 ? "" : "ml-3"
            }`}
          >
            <div className="my-2 mr-2 text-right">
              <i className="far fa-lock fs-15"></i>
            </div>
            <div className="text-center">
              {hierarchyTerms || hierarchyFAs ? (
                <b>{this.shortName(item.shortName)}</b>
              ) : item.uploadStatus === 0 ? (
                <b className="border-bottom-danger">
                  {this.shortName(item.shortName)}
                </b>
              ) : item.uploadStatus === 1 ? (
                <b className="border-bottom-warning">
                  {this.shortName(item.shortName)}
                </b>
              ) : item.uploadStatus === 2 ? (
                <b className="border-bottom-success">
                  {this.shortName(item.shortName)}
                </b>
              ) : (
                <b className="border-bottom-danger">
                  {this.shortName(item.shortName)}
                </b>
              )}
            </div>
          </div>
        ) : (
          <div>
            <div
              className={`inner-box-hierarchy gr-inner-box ${
                index === 0 ? "" : "ml-3"
              } ${index === selectedIndex ? "gr-selected" : ""}`}
              onClick={this.props.hierarchyItemSelected}
              onDoubleClick={() => {
                if (!this.props.noAccessToView) {
                  if (hierarchyFAs && item.faId) {
                    this.props.viewFAsDock()
                  }

                  if (hierarchyTerms) {
                    this.props.viewTermDock()
                  }

                  if (hierarchySubjects && item.subjectId) {
                    if (item.enteredAssessmentDate)
                      this.props.viewSubjectsDock()
                    else this.props.errorSubjectsDock()
                  }

                  if (hierarchyTheoryAndPractical) {
                    this.props.viewTheoryAndPracticalDock()
                  }

                  if (hierarchyActivities && item.activityId) {
                    this.props.viewActivitiesDock()
                  }
                }
              }}
            >
              <div className="mt-3 mb-4"></div>
              <div className="text-center" style={{ height: "1.5rem" }}>
                {hierarchyTerms ? (
                  <b>{this.shortName(item.shortName)}</b>
                ) : item.uploadStatus === 0 ? (
                  <b className="border-bottom-danger">
                    {this.shortName(item.shortName)}
                  </b>
                ) : item.uploadStatus === 1 ? (
                  <b className="border-bottom-warning">
                    {this.shortName(item.shortName)}
                  </b>
                ) : item.uploadStatus === 2 ? (
                  <b className="border-bottom-success">
                    {this.shortName(item.shortName)}
                  </b>
                ) : (
                  <b className="border-bottom-danger">
                    {this.shortName(item.shortName)}
                  </b>
                )}
              </div>

              <div className="text-right">
                {hierarchyTerms && (
                  <Dropdown
                    direction="up"
                    isOpen={this.state.hterms}
                    onClick={e => e.stopPropagation()}
                    toggle={() => {
                      this.setState({ hterms: !this.state.hterms })
                    }}
                  >
                    <DropdownToggle className="Tdrop">
                      <i className="far fa-ellipsis-v p-2 cursor-pointer"></i>
                    </DropdownToggle>

                    <DropdownMenu
                      container="body"
                      className="custom-ddm-wrapper"
                    >
                      <DropdownItem onClick={this.props.newFADock}>
                        New Block
                      </DropdownItem>
                      {/* <DropdownItem onClick={this.props.viewTermDock}>
                        View Term
                      </DropdownItem> */}
                      {/* <DropdownItem onClick={this.props.deleteTermDock}>
                        Delete
                      </DropdownItem> */}
                      <DropdownItem onClick={this.props.hierarchyItemSelected}>
                        Settings
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                )}

                {hierarchyFAs && (
                  <Dropdown
                    direction="up"
                    isOpen={this.state.hfa}
                    onClick={e => e.stopPropagation()}
                    toggle={() => {
                      this.setState({ hfa: !this.state.hfa })
                    }}
                  >
                    <DropdownToggle className="Tdrop">
                      <i className="far fa-ellipsis-v p-2 cursor-pointer"></i>
                    </DropdownToggle>

                    <DropdownMenu
                      container="body"
                      className="custom-ddm-wrapper"
                    >
                      {item.isClassSpecific && (
                        <DropdownItem
                          onClick={() => {
                            if (item.faId) {
                              this.props.newSubjectsDock()
                            }
                          }}
                        >
                          New Block
                        </DropdownItem>
                      )}
                      {!this.props.noAccessToView && (
                        <DropdownItem
                          onClick={() => {
                            if (item.faId) {
                              this.props.viewFAsDock()
                            }
                          }}
                        >
                          View {this.shortName(item.shortName)}
                        </DropdownItem>
                      )}
                      {item.isClassSpecific && (
                        <DropdownItem onClick={this.props.deleteFAsDock}>
                          Delete
                        </DropdownItem>
                      )}
                      <DropdownItem onClick={this.props.hierarchyItemSelected}>
                        Settings
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                )}

                {hierarchySubjects && (
                  <div
                    className="d-flex"
                    style={{
                      alignItems: "baseline",
                      justifyContent: "flex-end",
                    }}
                  >
                    {item.convertMark ? (
                      <b>
                        {item.maxMark}{" "}
                        {item.convertMark ? "-> " + item.convertMark : ""}
                      </b>
                    ) : (
                      <b style={{ marginRight: "9px" }}>{item.maxMark}</b>
                    )}
                    <Dropdown
                      direction="up"
                      isOpen={this.state.hSubject}
                      onClick={e => e.stopPropagation()}
                      toggle={() => {
                        this.setState({ hSubject: !this.state.hSubject })
                      }}
                    >
                      <DropdownToggle className="Tdrop">
                        <i className="far fa-ellipsis-v p-2 cursor-pointer"></i>
                      </DropdownToggle>
                      <DropdownMenu
                        container="body"
                        className="custom-ddm-wrapper"
                      >
                        {/* <DropdownItem
                          onClick={this.props.newActivitiesDock}
                        >
                          New block
                        </DropdownItem> */}
                        <DropdownItem
                          onClick={() => {
                            if (item.subjectId) {
                              if (item.enteredAssessmentDate)
                                this.props.viewSubjectsDock()
                              else this.props.errorSubjectsDock()
                            }
                          }}
                        >
                          Enter/View Marks
                        </DropdownItem>
                        {item.isClassSpecific && (
                          <DropdownItem onClick={this.props.deleteSubjectDock}>
                            Delete
                          </DropdownItem>
                        )}
                        <DropdownItem
                          onClick={this.props.hierarchyItemSelected}
                        >
                          Settings
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                )}

                {hierarchyTheoryAndPractical && (
                  <div
                    className="d-flex"
                    style={{
                      alignItems: "baseline",
                      justifyContent: "flex-end",
                    }}
                  >
                    {item.convertMark ? (
                      <b>
                        {item.maxMark}{" "}
                        {item.convertMark ? "-> " + item.convertMark : ""}
                      </b>
                    ) : item.hasActivity && item.hasActivity === 1 ? (
                      <b style={{ marginRight: "9px" }}>{item.totalMark}</b>
                    ) : (
                      <b style={{ marginRight: "9px" }}>{item.maxMark}</b>
                    )}
                    <Dropdown
                      direction="up"
                      isOpen={this.state.hTP}
                      onClick={e => e.stopPropagation()}
                      toggle={() => {
                        this.setState({ hTP: !this.state.hTP })
                      }}
                    >
                      <DropdownToggle className="Tdrop">
                        <i className="far fa-ellipsis-v p-2 cursor-pointer"></i>
                      </DropdownToggle>

                      <DropdownMenu
                        container="body"
                        className="custom-ddm-wrapper"
                      >
                        {item.key !== "T" && (
                          <DropdownItem onClick={this.props.newActivitiesDock}>
                            New block
                          </DropdownItem>
                        )}
                        <DropdownItem
                          onClick={this.props.viewTheoryAndPracticalDock}
                        >
                          Enter/View Marks
                        </DropdownItem>
                        <DropdownItem
                          onClick={this.props.hierarchyItemSelected}
                        >
                          Settings
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                )}

                {hierarchyActivities && (
                  <div
                    className="d-flex"
                    style={{
                      alignItems: "baseline",
                      justifyContent: "flex-end",
                    }}
                  >
                    {item.convertMark ? (
                      <b>
                        {item.maxMark}{" "}
                        {item.convertMark ? "-> " + item.convertMark : ""}
                      </b>
                    ) : (
                      <b style={{ marginRight: "9px" }}>{item.maxMark}</b>
                    )}
                    <Dropdown
                      direction="up"
                      isOpen={this.state.hSubject}
                      onClick={e => e.stopPropagation()}
                      toggle={() => {
                        this.setState({ hSubject: !this.state.hSubject })
                      }}
                    >
                      <DropdownToggle className="Tdrop">
                        <i className="far fa-ellipsis-v p-2 cursor-pointer"></i>
                      </DropdownToggle>

                      <DropdownMenu
                        container="body"
                        className="custom-ddm-wrapper"
                      >
                        <DropdownItem
                          onClick={() => {
                            if (item.activityId) {
                              this.props.viewActivitiesDock()
                            }
                          }}
                        >
                          Enter/View Marks
                        </DropdownItem>
                        <DropdownItem onClick={this.props.deleteActivityDock}>
                          Delete
                        </DropdownItem>
                        <DropdownItem
                          onClick={this.props.hierarchyItemSelected}
                        >
                          Settings
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                )}
              </div>
            </div>

            {hierarchySubjects &&
            index === selectedIndex &&
            item.theoryAndPractical &&
            item.theoryAndPractical.length ===
              0 ? null : hierarchyTheoryAndPractical &&
              item.activities &&
              item.activities.length ===
                0 ? null : hierarchyActivities ? null : (
              <div
                className={index === selectedIndex ? "gr-line" : "d-none"}
                style={{ marginTop: "11px" }}
              ></div>
            )}
          </div>
        )}
      </>
    )
  }
}

export default HierarchyItem
