export const positiveComments = [
  "He/She shows responsible behavior, works well with a group and shows appreciation for the efforts of classmates.",
  "He/She is enthusiastic about what we do.",
  "He/She has steadily improved.",
  "He/She has demonstrated a positive attitude toward wanting to improve.",
  "He/She is a self-assured young learner who is always willing to try something new.",
  "He/She has great self-reflection skills, being able to identify his/her own strengths and weaknesses.",
  "He/She is very enthusiastic about this subject",
  "He/She is learning to use his/her free time wisely.",
  "Completes every assignment and task on time.",
  "He/She is performing to the best of his/her ability.",
  "He/She is a pleasant and responsible student.",
  "He/She takes the initiative and thinks things through on his/her own.",
  "He/She is an exceptionally thoughtful student.",
  "He/She is a cheerful, well-adjusted child.",
  "He/She enjoys reading and is passionate about literature.",
  "He/She expresses himself/herself clearly.",
  "He/She displays an enthusiasm for learning.",
  "He/She is a leader and a positive role model for other students.",
  "He/She demonstrates positive character traits.",
  "He/She is an active listener who is always ready to respond with relevant and engaging questions.",
  "He/She is a very helpful and respectful student.",
  "He/She is very good at following the rules.",
  "He/She is very good at reflecting on his/her weaknesses and working on them.",
  "Conducts himself/herself with honesty and integrity at all times.",
  "He/She is open, honest and upfront about his/her thoughts and beliefs.",
  "Shares thoughtful and genuine opinions during lessons.",
  "He/She has an active mind and is eager to achieve.",
  "He/She is an ambitious and proactive student.",
  "Projects his/her voice very well when communicating in class.",
  "He/She has a great deal of confidence when speaking to groups.",
  "Always has neat book work which shows respect and high regard for his/her own work.",
  "He/She is learning to use his/her free time wisely.",
  "He/She excels at applying what he/she learns in the classroom to real-world and real-life situations.",
  "It has been a pleasure to have *student name*'s enthusiasm, positivity and maturity in my class.",
  "Becoming quite regular and attentive in the class.",
  "He/She is always attentive in class and asks for clarification when required.",
  "He/She is good at working in small groups without any help of a teacher.",
  "Excels when given leadership roles in small groups.",
  "Always looks for ways to be helpful in the classroom.",
  "Comes to class with great questions based on the assigned homework tasks, showing thoughtfulness.",
  "Participates actively in all tasks.",
  "He/She is beginning to develop his/her own interest and is eager to learn more about them.",
  "Always finds interest in topics presented in class.",
  "He/She is very good at reflecting on his/her weaknesses and working on them.",
].map((a, i) => ({ txt: a, key: i }))

export const negativeComments = [
  "He/She often struggles to focus in class",
  "He/She has difficulty concentrating.",
  "He/She needs frequent reminders to stay focused throughout the school day.",
  "He/She is prone to making careless mistakes.",
  "He/She needs to recheck the assignment before submitting.",
  "He/She never finishes assignments in the time provided.",
  "He/She is unable to do individual assignments.",
  "His/Her efforts are inconsistent.",
  "He/She is easily disturbed and appears tired a lot at school.",
  "He/She needs to improve his/her reading speed and comprehension.",
  "He/She continues to mix up words that appear to be the same.",
  "His/Her handwriting needs to be improved.",
  "Needs to increase spaces between letters and words.",
  "Although some of *student name*'s printing is excellent, it is frequently clumsy in daily assignments.",
  "He/She needs to treat others with respect.",
  "I'm glad *student name* is eager to participate in class discussions, but he/she needs to raise his/her hand when he/she has something to say.",
  "He/She often forgets his/her books and assignments.",
  "He/She often comes to class unprepared.",
  "He/She needs frequent reminders to stay focused throughout the school day.",
  "He/She needs to follow teacher directions without complaining.",
  "He/She is often shy and intimidated when asked to speak up in class discussions.",
  "He/She is easily distracted during class discussions, has trouble repeating and remembering instructions.",
  "He/She is often disorganized and forgets to complete important assignments.",
  "Could work on using his/her time more efficiently to complete tasks in allotted time periods.",
  "I would like to see him/her working on his/her organizational skills in coming months so he/she can use his/her class time more efficiently.",
  "Regularly forgets to bring homework to school.",
  "He/She is often seen completing homework tasks the morning before they are due.",
  "He/She needs to show more appropriate behavior when interacting with classmates.",
  "He/She needs to listen to directions more attentively during lessons.",
  "He/She should more contribute ideas in class.",
].map((a, i) => ({ txt: a, key: i }))
