/*************************************************
 * RedInk
 * @exports
 * @class TeachersScreen.js
 * @extends Component
 * @author Sasidharan // on 11/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog"
import { bindActionCreators } from "redux"
import { HashLoader } from "react-spinners"
import readXlsxFile from "read-excel-file"
import { connect } from "react-redux"
import { Input } from "antd"
import moment from "moment"
import jsPDF from "jspdf"
import {
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap"

import {
  getAllStudentsDatabaseList,
  saveStudentDatabaseDetails,
  updateStudentDatabaseDetails,
  deleteStudentDatabaseDetails,
  saveStudentDatabaseDetailInStore,
  saveAdminStudentDatabaseDetailInStore,
  saveAdminStudentDetailInStore,
  sendStudentCredentials,
  deleteBulkStudentDatabaseDetails,
} from "../../../../actions/teacher/StudentDatabaseAction"
import { DANGER_TOAST, VALIDATION_MSG } from "../../../../utils/Constants"
import { saveIsShowLoadingInStore } from "../../../../actions/LoginAction"
import { confirmAlertPopUp } from "../../../../utils/Utility"
import { getSubjectDetails } from "../../../../actions/admin/SubjectDetailsAction"
import { ExcelDateToJSDate } from "../../../../utils/Utility"
import { showNotification } from "../../../../actions/CommonAction"
import { getClassDetails } from "../../../../actions/admin/ClassDetailsAction"
import { URL_PATH } from "../../../../config/urlPath"
import URL from "../../../../config"

import { ReactComponent as Arrow } from "../../../../assets/images/resize.svg"
import girlpic from "../../../../assets/images/girl.png"
import boypic from "../../../../assets/images/boy.png"

import UpgradeStudentsClass from "./UpgradeStudentsClass"
import StudentDetailsModal from "../StudentDetailsModal"
import AppWrapper from "../../Wrapper1"
import UpgradeModal from "./UpgradeModal"

const tooltipModifiers = {
  flip: { behaviour: ["top"] },
  preventOverflow: { boundariesElement: "viewport" },
}

const exportDefaultFields = [
  { include: true, field: "exportStudentName", label: "Student Name" },
  { include: true, field: "exportRollNumber", label: "Roll Number" },
  { include: true, field: "exportAdmissionNumber", label: "Admission Number" },
  { include: true, field: "exportClass", label: "Class" },
  { include: true, field: "exportGender", label: "Gender" },
  {
    include: true,
    field: "exportPrimaryContactName",
    label: "Primary Contact Name",
  },
]

class StudentsScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      subjectsList: [],
      classesList: [],
      isShowLoading: false,
      isStudentsLoading: true,
      studentList: [],
      selectedClass: "",
      searchText: "",
      activeStatus: "",
      isStudentDetailsModalOpen: false,
      modalData: {},
      limit: 50,
      offset: 0,
      totalStudents: 0,
      currentPage: 1,
      prevPage: null,
      nextPage: null,
      selectedStudents: [],
      files: false,
      isStudentListModalOpen: false,
      bulkUploadSectionId: null,
      isStudentExportModalOpen: false,
      exportDefaultFields: exportDefaultFields,
      exportEmptyFields: [],
      showAddEmptyColumnField: false,
      exportEmptyFieldName: "",
      isUploadModal: false,
      buttonEnable: false,
      selectedMethod: "",
      title: "",
      studentDummy: [],
      isSendCredModalOpen: false,
      isSendCredInProgress: false,
      sendCredOnEmail: true,
      sendCredOnSMS: true,
      userList: [],
      studentsList: [],
      showUpgrageProccess: false,
    }
  }

  static getDerivedStateFromProps(props) {
    return {
      userList: props.adminStudentList,
    }
  }

  componentDidMount = () => {
    this.props.getSubjectDetails({}, (success, response) => {
      if (success) {
        this.setState({
          coreSubjects: response.CORE || [],
          languageSubjects: response.LANGUAGE || [],
          otherSubjects: response.OTHER || [],
        })
      }
    })
    this.props.getClassDetails({}, () => {})
    this.getStudents()
  }

  getStudents = () => {
    this.setState({ isStudentsLoading: true }, () => {
      let object = { limit: this.state.limit, offset: this.state.offset }
      if (this.state.selectedClass) {
        object.sectionId = this.state.selectedClass
      }
      if (this.state.searchText) {
        object.searchText = this.state.searchText
      }
      if (this.state.activeStatus) {
        object.activeStatus = this.state.activeStatus
      }
      this.props.getAllStudentsDatabaseList(object, (success, response) => {
        this.setState({ isStudentsLoading: false })
        if (success) {
          this.setState({
            studentDummy: response.data.rows,
            isStudentsLoading: false,
            studentList: response.data.rows,
            totalStudents: response.data.count,
            nextPage:
              this.state.nextPage &&
              response.data.count > this.state.limit * this.state.currentPage
                ? this.state.nextPage + 1
                : !this.state.nextPage && response.data.count > this.state.limit
                ? 1
                : null,
          })
        }
      })
    })
  }

  // getStudentsSorted = () => {
  //   this.setState({ isStudentsLoading: false }, () => {
  //     let object = {
  //       limit: this.state.totalStudents,
  //       offset: this.state.offset,
  //     }
  //     if (this.state.selectedClass) {
  //       object.sectionId = this.state.selectedClass
  //     }
  //     if (this.state.searchText) {
  //       object.searchText = this.state.searchText
  //     }
  //     if (this.state.activeStatus) {
  //       object.activeStatus = this.state.activeStatus
  //     }
  //     this.props.getAllStudentsDatabaseList(object, (success, response) => {
  //       this.setState({ isStudentsLoading: false })
  //       if (success) {
  //         this.setState({
  //           studentDummy: response.data.rows,
  //           isStudentsLoading: false,
  //           studentList: response.data.rows,
  //           totalStudents: response.data.count,
  // nextPage:
  //   this.state.nextPage &&
  //   response.data.count > this.state.limit * this.state.currentPage
  //     ? this.state.nextPage + 1
  //     : !this.state.nextPage && response.data.count > this.state.limit
  //     ? 1
  //     : null,
  // })
  //     }
  //   })
  // })
  // this.getStudents()
  // }

  sendCredConfirmFn = () => {
    this.setState({
      isSendCredInProgress: true,
    })
    let object = {}
    if (this.state.selectedClass) {
      object.sectionId = this.state.selectedClass
    }
    if (this.state.searchText) {
      object.searchText = this.state.searchText
    }
    if (this.state.sendCredOnSMS) {
      object.sendSMS = this.state.sendCredOnSMS
    }
    if (this.state.sendCredOnEmail) {
      object.sendEmail = this.state.sendCredOnEmail
    }
    if (this.state.selectedStudents.length > 0) {
      object.selectedStudents = this.state.selectedStudents
    }
    this.props.sendStudentCredentials(object, success => {
      this.setState({ isSendCredInProgress: false })
      if (success) {
        this.setState({ isSendCredModalOpen: false })
      }
    })
  }

  sendCreds = () => {
    confirmAlertPopUp(
      "Send Credentials",
      "Are you sure want to send the credentials?",
      yes => {
        if (yes) {
          this.sendCredConfirmFn()
        }
      },
      {
        buttons: [],
        customUI: ({ onClose }) => {
          return (
            <div
              className="custom"
              style={{
                backgroundColor: "#fff",
                borderBottomRightRadius: "12px",
                borderBottomLeftRadius: "12px",
              }}
            >
              <div
                className="bg-brand text-light d-flex"
                style={{
                  padding: "0.5rem 2rem",
                  borderTopRightRadius: "12px",
                  borderTopLeftRadius: "12px",
                }}
              >
                <div className="mx-auto fs-18">Send Credentials</div>
              </div>

              <div className="mt-3" style={{ padding: "0.5rem 1.5rem" }}>
                <div className="text-muted fs-15">
                  Are you sure want to send the credentials?
                </div>
                <div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
                  <button
                    className="bg-brand text-light px-4 py-1 w-25 d-flex align-items-center justify-content-center yes-button"
                    style={{ borderRadius: "5px" }}
                    onClick={() => {
                      this.sendCredConfirmFn()
                      onClose()
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-brand text-light px-4 py-1 ml-4 w-25 d-flex align-items-center justify-content-center"
                    style={{ borderRadius: "5px" }}
                    onClick={onClose}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )
        },
      }
    )
  }

  searchFunction = e => {
    let studentListDummy = this.state.studentDummy
    let filteredArray =
      studentListDummy.length &&
      studentListDummy.filter(
        data =>
          JSON.stringify(data.studentFirstName)
            .toLowerCase()
            .indexOf(e.target.value.toLowerCase()) !== -1
      )

    this.props.saveAdminStudentDatabaseDetailInStore(
      JSON.parse(JSON.stringify(filteredArray))
    )
    this.setState({ userList: JSON.parse(JSON.stringify(filteredArray)) })
  }

  closeStudentDetailsModal = () => {
    this.setState({
      isStudentDetailsModalOpen: false,
    })
  }

  onSubmitStudentDetails = (toggle, data) => {
    if (data.hasOwnProperty("id")) {
      this.props.saveIsShowLoadingInStore(true)
      this.props.updateStudentDatabaseDetails(data, res => {
        this.props.saveIsShowLoadingInStore(false)
        if (res)
          this.setState({ isStudentDetailsModalOpen: toggle, modalData: {} })
        this.getStudents()
      })
    } else {
      this.props.saveIsShowLoadingInStore(true)
      this.props.saveStudentDatabaseDetails(data, res => {
        this.props.saveIsShowLoadingInStore(false)
        if (res)
          this.setState({ isStudentDetailsModalOpen: toggle, modalData: {} })
        this.getStudents()
      })
    }
  }

  scrollToMyRef = () => {
    this.myRef.scrollIntoView({ block: "start", behavior: "smooth" })
  }

  cancelSubmit = () => {}

  deleteStudentSingle = student => {
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT,
      yes => {
        if (yes) {
          this.props.deleteStudentDatabaseDetails(
            { id: student.studentId },
            this.getStudents
          )
        }
      },
      {
        buttons: [],
        customUI: ({ onClose }) => {
          return (
            <div
              className="custom"
              style={{
                backgroundColor: "#fff",
                borderBottomRightRadius: "12px",
                borderBottomLeftRadius: "12px",
              }}
            >
              <div
                className="bg-brand text-light d-flex"
                style={{
                  padding: "0.5rem 2rem",
                  borderTopRightRadius: "12px",
                  borderTopLeftRadius: "12px",
                }}
              >
                <div className="mx- fs-18">Delete</div>
              </div>

              <div className="mt-3 " style={{ padding: "0.5rem 1.5rem" }}>
                <div className="text-muted fs-15">
                  Are you sure you want to delete?
                </div>
                <div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
                  <button
                    className="bg-brand text-light px-4 py-1 w-25 d-flex align-items-center justify-content-center yes-button"
                    style={{ borderRadius: "5px" }}
                    onClick={() => {
                      this.props.deleteStudentDatabaseDetails(
                        { id: student.studentId },
                        this.getStudents
                      )
                      onClose()
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-brand text-light px-4 py-1 ml-4 w-25 d-flex align-items-center justify-content-center"
                    style={{ borderRadius: "5px" }}
                    onClick={onClose}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )
        },
      }
    )
  }

  deleteStudent = student => {
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT,
      yes => {
        if (yes) {
          // for (const student of this.state.selectedStudents) {
          //   this.props.deleteStudentDatabaseDetails({ id: student }, () => {
          //     this.getStudents()
          //     this.setState({ selectedStudents: [] })
          //   })
          // }
        }
      },
      {
        buttons: [],
        customUI: ({ onClose }) => {
          return (
            <div
              className="custom"
              style={{
                backgroundColor: "#fff",
                borderBottomRightRadius: "12px",
                borderBottomLeftRadius: "12px",
              }}
            >
              <div
                className="bg-brand text-light d-flex"
                style={{
                  padding: "0.5rem 2rem",
                  borderTopRightRadius: "12px",
                  borderTopLeftRadius: "12px",
                }}
              >
                <div className="mx-auto fs-18">Delete</div>
              </div>

              <div className="mt-3" style={{ padding: "0.5rem 1.5rem" }}>
                <div className="text-muted fs-15">
                  Are you sure you want to delete selected students?
                </div>
                <div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
                  <button
                    className="bg-brand text-light px-4 py-1 w-25 d-flex align-items-center justify-content-center yes-button"
                    style={{ borderRadius: "5px" }}
                    onClick={() => {
                      // for (const student of this.state.selectedStudents) {
                      //   this.props.deleteStudentDatabaseDetails(
                      //     { id: student },
                      // () => {
                      //   this.getStudents()
                      //   this.setState({ selectedStudents: [] })
                      // }
                      //   )
                      // }
                      this.props.deleteBulkStudentDatabaseDetails(
                        this.state.selectedStudents,
                        () => {
                          this.getStudents()
                          this.setState({ selectedStudents: [] })
                        }
                      )
                      onClose()
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-brand text-light px-4 py-1 ml-4 w-25 d-flex align-items-center justify-content-center"
                    style={{ borderRadius: "5px" }}
                    onClick={onClose}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )
        },
      }
    )
  }

  exportStudents = () => {
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT,
      yes => {
        if (yes) {
          for (const student of this.state.selectedStudents) {
            this.props.deleteStudentDatabaseDetails({ id: student }, () => {
              this.getStudents()
              this.setState({ selectedStudents: [] })
            })
          }
        }
      },
      {
        buttons: [],
        customUI: ({ onClose }) => {
          return (
            <div
              className="custom"
              style={{
                backgroundColor: "#fff",
                borderBottomRightRadius: "12px",
                borderBottomLeftRadius: "12px",
              }}
            >
              <div
                className="bg-brand text-light d-flex"
                style={{
                  padding: "0.5rem 2rem",
                  borderTopRightRadius: "12px",
                  borderTopLeftRadius: "12px",
                }}
              >
                <div className="mx-auto fs-18">Delete</div>
              </div>

              <div className="mt-3" style={{ padding: "0.5rem 1.5rem" }}>
                <div className="text-muted fs-15">
                  Are you sure you want to delete selected students?
                </div>
                <div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
                  <button
                    className="bg-brand text-light px-4 py-1 w-25 d-flex align-items-center justify-content-center"
                    style={{ borderRadius: "5px" }}
                    onClick={() => {
                      for (const student of this.state.selectedStudents) {
                        this.props.deleteStudentDatabaseDetails(
                          { id: student },
                          () => {
                            this.getStudents()
                            this.setState({ selectedStudents: [] })
                          }
                        )
                      }
                      onClose()
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-brand text-light px-4 py-1 ml-4 w-25 d-flex align-items-center justify-content-center"
                    style={{ borderRadius: "5px" }}
                    onClick={onClose}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )
        },
      }
    )
  }

  convertToRequestArray = rows => {
    delete rows[0]
    delete rows[1]
    if (Array.isArray(rows)) {
      const requestKeys = [
        // "name",
        "firstName",
        "lastName",
        "gender",
        "dob",
        "studentEmail",
        "studentPhone",

        "registerNumber",
        "dateOfJoining",

        "admissionNumber",
        "primaryParent",
        // "parent1",
        // "parent1email",
        // "parent1Phone",
        // "parent2",
        // "parent2email",
        // "parent2Phone",
        "fatherFirstName",
        "fatherLastName",
        "fatherEmail",
        "fatherPhone",
        "motherFirstName",
        "motherLastName",
        "motherEmail",
        "motherPhone",
        "legalGuardianFirstName",
        "legalGuardianLastName",
        "legalGuardianEmail",
        "legalGuardianPhone",
        "localGuardianFirstName",
        "localGuardianLastName",
        "localGuardianEmail",
        "localGuardianPhone",
      ]
      let finalArray = []
      let studentDetailsArray = []
      rows.forEach(row => {
        let tempObj = {}
        row.forEach((data, i) => {
          tempObj[requestKeys[i]] = data
        })
        finalArray.push(tempObj)
      })
      for (let i = 0; i < finalArray.length; i++) {
        let object = {
          // name: finalArray[i].name,
          firstName: finalArray[i].firstName,
          lastName: finalArray[i].lastName,
          studentEmail: finalArray[i].studentEmail,
          studentPhone: finalArray[i].studentPhone,
          registerNumber: "-",
          primaryParent: finalArray[i].primaryParent,
          admissionNumber: finalArray[i].admissionNumber,
        }
        if (finalArray[i].gender == null || finalArray[i].gender === "") {
          object.gender = null
        } else {
          object.gender =
            finalArray[i].gender.toUpperCase() === "MALE" ||
            finalArray[i].gender.toUpperCase() === "M"
              ? "M"
              : "F"
        }
        if (finalArray[i].dob == null || finalArray[i].dob === "") {
          object.dob = ""
        } else {
          object.dob =
            // typeof finalArray[i].dob === "string"
            //   ?
            moment(finalArray[i].dob, "DD-MM-YYYY").format("YYYY-MM-DD")
          // : moment(ExcelDateToJSDate(finalArray[i].dob)).format(
          //     "YYYY-MM-DD"
          //   )
        }
        if (
          finalArray[i].dateOfJoining == null ||
          finalArray[i].dateOfJoining === ""
        ) {
          object.dateOfJoining = ""
        } else {
          object.dateOfJoining =
            typeof finalArray[i].dateOfJoining === "string"
              ? moment(finalArray[i].dateOfJoining, "DD-MM-YYYYY").format(
                  "YYYY-MM-DD"
                )
              : moment(ExcelDateToJSDate(finalArray[i].dateOfJoining)).format(
                  "YYYY-MM-DD"
                )
        }
        let array = []
        array.push({
          fatherFirstName: finalArray[i].fatherFirstName,
          fatherLastName: finalArray[i].fatherLastName,
          fatherEmail: finalArray[i].fatherEmail,
          fatherPhone: finalArray[i].fatherPhone,
          motherFirstName: finalArray[i].motherFirstName,
          motherLastName: finalArray[i].motherLastName,
          motherEmail: finalArray[i].motherEmail,
          motherPhone: finalArray[i].motherPhone,
          legalGuardianEmail: finalArray[i].legalGuardianEmail,
          legalGuardianFirstName: finalArray[i].legalGuardianFirstName,
          legalGuardianLastName: finalArray[i].legalGuardianLastName,
          legalGuardianPhone: finalArray[i].legalGuardianPhone,
          localGuardianEmail: finalArray[i].localGuardianEmail,
          localGuardianFirstName: finalArray[i].localGuardianFirstName,
          localGuardianLastName: finalArray[i].localGuardianLastName,
          localGuardianPhone: finalArray[i].localGuardianPhone,
        })
        object["parentsList"] = array
        studentDetailsArray.push(object)
      }
      this.setState({
        studentsList: studentDetailsArray,
        isStudentListModalOpen: true,
      })
    }
  }

  saveExcelUpload = () => {
    this.setState({ files: false })
    let hasError = false

    if (this.state.studentsList.length === 0) {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.SUCCESS,
        "Fill out all details"
      )
      return
    }
    if (!this.state.bulkUploadSectionId) {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.SUCCESS,
        "Please select class"
      )
      return
    }
    for (let i = 0; i < this.state.studentsList.length; i++) {
      let list = this.state.studentsList[i]
      for (let j = 0; j < list.parentsList.length; j++) {
        if (
          !list.firstName ||
          // list.lastName === "" ||
          // list.lastName === null ||
          // list.studentEmail === "" ||
          // list.studentEmail === null ||
          // list.studentPhone === null ||
          // list.studentPhone === "" ||
          // list.registerNumber === null ||
          // list.admissionNumber === null ||
          // list.primaryParent === "" ||
          // list.primaryParent === null ||
          // list.gender === null ||
          // list.dob === null ||
          // list.dateOfJoining === null ||
          // list.registerNumber === "" ||
          // list.admissionNumber === "" ||
          !list.gender ||
          !list.dob
          // list.dateOfJoining === "" ||
          // list.parentsList[j].fatherFirstName === "" ||
          // list.parentsList[j].fatherFirstName === null ||
          // list.parentsList[j].fatherLastName === "" ||
          // list.parentsList[j].fatherLastName === null ||
          // list.parentsList[j].fatherEmail === "" ||
          // list.parentsList[j].fatherEmail === null ||
          // list.parentsList[j].fatherPhone === "" ||
          // list.parentsList[j].fatherPhone === null ||
          // list.parentsList[j].motherFirstName === "" ||
          // list.parentsList[j].motherFirstName === null ||
          // list.parentsList[j].motherLastName === "" ||
          // list.parentsList[j].motherLastName === null ||
          // list.parentsList[j].motherEmail === "" ||
          // list.parentsList[j].motherEmail === null ||
          // list.parentsList[j].motherPhone === "" ||
          // list.parentsList[j].motherPhone === null
        ) {
          hasError = true
        }
        // if (list.studentPhone.toString().length !== 10) {
        //   this.props.showNotification(
        //     DANGER_TOAST,
        //     VALIDATION_MSG.SUCCESS,
        //     " Student phone number is invalid"
        //   )
        //   return
        // }
        // if (!validateEmail(list.studentEmail)) {
        //   this.props.showNotification(
        //     DANGER_TOAST,
        //     VALIDATION_MSG.SUCCESS,
        //     " Student mail id invalid"
        //   )
        //   return
        // }
        // if (list.primaryParent === "MOTHER") {
        //   if (
        //     list.parentsList[j].motherFirstName === "" ||
        //     list.parentsList[j].motherFirstName === null ||
        //     list.parentsList[j].motherLastName === "" ||
        //     list.parentsList[j].motherLastName === null
        //   ) {
        //     this.props.showNotification(
        //       DANGER_TOAST,
        //       VALIDATION_MSG.SUCCESS,
        //       list.firstName + " - Mother First Name & Last Name is required"
        //     )
        //     return
        //   }
        //   if (list.parentsList[j].motherPhone.toString().length !== 10) {
        //     this.props.showNotification(
        //       DANGER_TOAST,
        //       VALIDATION_MSG.SUCCESS,
        //       list.firstName + " - Mother phone number is invalid"
        //     )
        //     return
        //   }
        //   if (list.parentsList[j].motherPhone.toString().length !== 10) {
        //     this.props.showNotification(
        //       DANGER_TOAST,
        //       VALIDATION_MSG.SUCCESS,
        //       list.firstName + " - Mother phone number is invalid"
        //     )
        //     return
        //   }
        //   if (!validateEmail(list.parentsList[j].motherEmail)) {
        //     this.props.showNotification(
        //       DANGER_TOAST,
        //       VALIDATION_MSG.SUCCESS,
        //       list.firstName + " - Mother mail id invalid"
        //     )
        //     return
        //   }
        // }
        // if (list.primaryParent === "FATHER") {
        //   if (
        //     list.parentsList[j].fatherFirstName === "" ||
        //     list.parentsList[j].fatherFirstName === null ||
        //     list.parentsList[j].fatherLastName === "" ||
        //     list.parentsList[j].fatherLastName === null
        //   ) {
        //     this.props.showNotification(
        //       DANGER_TOAST,
        //       VALIDATION_MSG.SUCCESS,
        //       list.firstName + " - Father First Name & Last Name is required"
        //     )
        //     return
        //   }
        //   if (list.parentsList[j].fatherPhone.toString().length !== 10) {
        //     this.props.showNotification(
        //       DANGER_TOAST,
        //       VALIDATION_MSG.SUCCESS,
        //       list.firstName + " - Father phone number is invalid"
        //     )
        //     return
        //   }
        //   if (!validateEmail(list.parentsList[j].fatherEmail)) {
        //     this.props.showNotification(
        //       DANGER_TOAST,
        //       VALIDATION_MSG.SUCCESS,
        //       list.firstName + " - Father mail id invalid"
        //     )
        //     return
        //   }
        // }
        // if (list.primaryParent === "LEG") {
        //   if (
        //     list.parentsList[j].legalGuardianFirstName === "" ||
        //     list.parentsList[j].legalGuardianFirstName === null ||
        //     list.parentsList[j].legalGuardianLastName === "" ||
        //     list.parentsList[j].legalGuardianLastName === null
        //   ) {
        //     this.props.showNotification(
        //       DANGER_TOAST,
        //       VALIDATION_MSG.SUCCESS,
        //       list.firstName +
        //         " - Legal Guardian First Name & Last Name is required"
        //     )
        //     return
        //   }
        //   if (list.parentsList[j].legalGuardianPhone.toString().length !== 10) {
        //     this.props.showNotification(
        //       DANGER_TOAST,
        //       VALIDATION_MSG.SUCCESS,
        //       list.firstName + " - Legal Guardian phone number is invalid"
        //     )
        //     return
        //   }
        //   if (!validateEmail(list.parentsList[j].legalGuardianEmail)) {
        //     this.props.showNotification(
        //       DANGER_TOAST,
        //       VALIDATION_MSG.SUCCESS,
        //       list.firstName + " - Legal Guardian mail id invalid"
        //     )
        //     return
        //   }
        // }
        // if (list.primaryParent === "LOG") {
        //   if (
        //     list.parentsList[j].localGuardianFirstName === "" ||
        //     list.parentsList[j].localGuardianFirstName === null ||
        //     list.parentsList[j].localGuardianLastName === "" ||
        //     list.parentsList[j].localGuardianLastName === null
        //   ) {
        //     this.props.showNotification(
        //       DANGER_TOAST,
        //       VALIDATION_MSG.SUCCESS,
        //       list.firstName +
        //         " - Local Guardian First Name & Last Name is required"
        //     )
        //     return
        //   }
        //   if (list.parentsList[j].localGuardianPhone.toString().length !== 10) {
        //     this.props.showNotification(
        //       DANGER_TOAST,
        //       VALIDATION_MSG.SUCCESS,
        //       list.firstName + " - Local Guardian phone number is invalid"
        //     )
        //     return
        //   }
        //   if (!validateEmail(list.parentsList[j].localGuardianEmail)) {
        //     this.props.showNotification(
        //       DANGER_TOAST,
        //       VALIDATION_MSG.SUCCESS,
        //       list.firstName + " - Local Guardian mail id invalid"
        //     )
        //     return
        //   }
        // }
        // if (list.dateOfJoining > moment(new Date()).format("YYYY-MM-DD")) {
        //   this.props.showNotification(
        //     DANGER_TOAST,
        //     VALIDATION_MSG.SUCCESS,
        //     list.firstName + " - Date of joining greater than today"
        //   )
        //   return
        // }
        // if (list.dob > moment(new Date()).format("YYYY-MM-DD")) {
        //   this.props.showNotification(
        //     DANGER_TOAST,
        //     VALIDATION_MSG.SUCCESS,
        //     list.firstName + " - Date of birth greater than today"
        //   )
        //   return
        // }
        // if (list.dateOfJoining !== null && list.dateOfJoining !== "") {
        //   if (list.dob > list.dateOfJoining) {
        //     this.props.showNotification(
        //       DANGER_TOAST,
        //       VALIDATION_MSG.SUCCESS,
        //       list.firstName + " - Date of birth greater than date of joining"
        //     )
        //     return
        //   }
        // }
      }
    }
    let duplicateError = false
    // for (let i = 0; i < this.state.studentsList.length - 1; i++) {
    //   for (let j = 1; j < this.state.studentsList.length; j++) {
    //     if (i !== j) {
    //       if (
    //         this.state.studentsList[i].registerNumber ===
    //         this.state.studentsList[j].registerNumber
    //       ) {
    //         duplicateError = true
    //         break
    //       }
    //     }
    //   }
    //   if (duplicateError) break
    // }
    if (!hasError) {
      if (!duplicateError) {
        // const newStudentsList = this.state.studentsList.map(v => ({...v, sectionId: this.state.bulkUploadSectionId}))
        let studentListObject = {
          sectionId: this.state.bulkUploadSectionId,
          studentsList: this.state.studentsList,
        }
        this.setState(
          {
            isShowExcelLoading: true,
          },
          () => {
            this.props.saveStudentDatabaseDetails(studentListObject, res => {
              this.setState({
                isShowExcelLoading: false,
                bulkUploadSectionId: null,
              })
              if (res) {
                this.setState(
                  {
                    isStudentListModalOpen: false,
                  },
                  () => this.getStudents()
                )
              }
            })
          }
        )
      } else {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Found duplicate register number..."
        )
      }
    } else {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.SUCCESS,
        "Fill out all fields..."
      )
    }
  }

  handleDrop = async files => {
    readXlsxFile(files[0])
      .then(rows => {
        this.convertToRequestArray(rows)
      })
      .catch(e => {
        this.setState({
          excelError: true,
          excelFileName: "",
          excelTitleData: [],
          uploadedExcelFile: {},
        })
      })
  }

  handleDropEMSI = async files => {
    readXlsxFile(files[0])
      .then(rows => {
        this.convertToRequestArrayEMSI(rows)
      })
      .catch(e => {
        this.setState({
          excelError: true,
          excelFileName: "",
          excelTitleData: [],
          uploadedExcelFile: {},
        })
      })
  }

  convertToRequestArrayEMSI = rows => {
    delete rows[0]
    if (Array.isArray(rows)) {
      const requestKeys = [
        // "name",
        "firstName",
        "lastName",
        "gender",
        "studentPhone",
        "dob",
        "registerNumber",
        "dateOfJoining",
        "studentEmail",
        "admissionNumber",
        "primaryParent",
        // "parent1",
        // "parent1email",
        // "parent1Phone",
        // "parent2",
        // "parent2email",
        // "parent2Phone",
        "fatherFirstName",
        "fatherLastName",
        "fatherEmail",
        "fatherPhone",
        "motherFirstName",
        "motherLastName",
        "motherEmail",
        "motherPhone",
        "legalGuardianFirstName",
        "legalGuardianLastName",
        "legalGuardianEmail",
        "legalGuardianPhone",
        "localGuardianFirstName",
        "localGuardianLastName",
        "localGuardianEmail",
        "localGuardianPhone",
      ]
      let finalArray = []
      let studentDetailsArray = []
      rows.forEach(row => {
        let tempObj = {}
        row.forEach((data, i) => {
          tempObj[requestKeys[i]] = data
        })
        finalArray.push(tempObj)
      })
      for (let i = 0; i < finalArray.length; i++) {
        let object = {
          name: "",
          firstName: finalArray[i].lastName,
          lastName: "",
          studentEmail: "",
          studentPhone: finalArray[i].fatherEmail,
          registerNumber: "-",
          primaryParent: "",
          admissionNumber: "",
          // gender:finalArray[i].motherFirstName
        }
        if (
          finalArray[i].motherFirstName == null ||
          finalArray[i].motherFirstName === ""
        ) {
          object.gender = null
        } else {
          object.gender =
            finalArray[i].motherFirstName.toUpperCase() === "MALE" ||
            finalArray[i].motherFirstName.toUpperCase() === "M"
              ? "M"
              : "F"
        }
        if (
          finalArray[i].fatherPhone == null ||
          finalArray[i].fatherPhone === ""
        ) {
          object.dob = ""
        } else {
          object.dob =
            // typeof finalArray[i].fatherPhone === "string"
            //   ?
            //   moment(finalArray[i].fatherPhone, "DD-MM-YYYYY").format(
            //       "YYYY-MM-DD"
            //     )
            //   :
            moment(ExcelDateToJSDate(finalArray[i].fatherPhone)).format(
              "YYYY-MM-DD"
            )
        }
        if (
          finalArray[i].dateOfJoining == null ||
          finalArray[i].dateOfJoining === ""
        ) {
          object.dateOfJoining = ""
        } else {
          object.dateOfJoining = ""
          // typeof finalArray[i].dateOfJoining === "string"
          //   ? moment(finalArray[i].dateOfJoining, "DD-MM-YYYYY").format(
          //       "YYYY-MM-DD"
          //     )
          //   : moment(ExcelDateToJSDate(finalArray[i].dateOfJoining)).format(
          //       "YYYY-MM-DD"
          //     )
        }
        let array = []
        array.push({
          fatherFirstName: "",
          fatherLastName: "",
          fatherEmail: "",
          fatherPhone: "",
          motherFirstName: "",
          motherLastName: "",
          motherEmail: "",
          motherPhone: "",
          legalGuardianEmail: "",
          legalGuardianFirstName: "",
          legalGuardianLastName: "",
          legalGuardianPhone: "",
          localGuardianEmail: "",
          localGuardianFirstName: "",
          localGuardianLastName: "",
          localGuardianPhone: "",
        })
        object["parentsList"] = array
        studentDetailsArray.push(object)
      }
      this.setState({
        studentsList: studentDetailsArray,
        isStudentListModalOpen: true,
      })
    }
  }

  exportPDF = () => {
    var doc = new jsPDF("p", "pt")

    doc.text(20, 20, "This is the first title.")

    doc.addFont("helvetica", "normal")
    doc.text(20, 60, "This is the second title.")
    doc.text(20, 100, "This is the thrid title.")

    doc.save("demo.pdf")

    // const unit = "pt"
    // const size = "A4" // Use A1, A2, A3 or A4
    // const orientation = "portrait" // portrait or landscape

    // const marginLeft = 40
    // const doc = new jsPDF(orientation, unit, size)

    // doc.setFontSize(15)

    // const title = "Students Database"

    // let titles = []

    // const headers = [["Name", "Gender", "Roll No."]]

    // const data = this.state.studentList.map((elt, i) => [
    //   elt.studentFirstName,
    //   elt.gender,
    //   elt.registerRollNumber,
    //   // `RI000${i + 1}`,
    // ])

    // let content = {
    //   startY: 50,
    //   head: headers,
    //   body: data,
    // }

    // doc.text(title, marginLeft, 40)
    // // doc.autoTable(content)
    // doc.save("report.pdf")
  }

  exportEXCEL = () => {
    // const unit = "pt"
    // const size = "A4" // Use A1, A2, A3 or A4
    // const orientation = "portrait" // portrait or landscape
    // const marginLeft = 40
    // const doc = new jsPDF(orientation, unit, size)
    // doc.setFontSize(15)
    // const title = "Students Report"
    // const headers = [["Name", "Gender", "Roll No.", "RI Id"]]
    // const data = this.state.studentList.map((elt, i) => [
    //   elt.name,
    //   elt.gender,
    //   elt.registerNumber,
    //   `RI000${i + 1}`,
    // ])
    // let content = {
    //   startY: 50,
    //   head: headers,
    //   body: data,
    // }
    // doc.text(title, marginLeft, 40)
    // doc.autoTable(content)
    // doc.save("report.pdf")
    // const multiDataSet = [
    //   {
    //     columns: [
    //       { value: "Name", widthPx: 50 }, // width in pixels
    //       { value: "Salary", widthCh: 20 }, // width in charachters
    //       { value: "Sex", widthPx: 60, widthCh: 20 }, // will check for width in pixels first
    //     ],
    //     data: [
    //       ["Johnson", 30000, "Male"],
    //       ["Monika", 355000, "Female"],
    //       ["Konstantina", 20000, "Female"],
    //       ["John", 250000, "Male"],
    //       ["Josef", 450500, "Male"],
    //     ],
    //   },
    //   {
    //     xSteps: 1, // Will start putting cell with 1 empty cell on left most
    //     ySteps: 5, //will put space of 5 rows,
    //     columns: ["Name", "Department"],
    //     data: [
    //       ["Johnson", "Finance"],
    //       ["Monika", "IT"],
    //       ["Konstantina", "IT Billing"],
    //       ["John", "HR"],
    //       ["Josef", "Testing"],
    //     ],
    //   },
    // ]
    // this.renderExportExcel(multiDataSet)
  }

  // renderExportExcel = multiDataSet => {
  //   return (
  //     <ExcelFile>
  //       <ExcelSheet dataSet={multiDataSet} name="Organization" />
  //     </ExcelFile>
  //   )
  // }

  renderSelectFilters = () => (
    <div className="d-flex align-items-center justify-content-start mb-4">
      <select
        className="mr-3"
        value={this.state.selectedClass}
        style={{ width: "7.5rem" }}
        onChange={e => {
          this.setState(
            {
              selectedClass: e.target.value,
              selectedStudents: [],
            },
            () => {
              this.getStudents()
            }
          )
        }}
      >
        <option value="">All Classes</option>
        {this.props.classesList &&
          this.props.classesList.map(option => (
            <option value={option.id} key={option.value}>
              {option.label}
            </option>
          ))}
      </select>

      <Input
        type="text"
        placeholder="Search by student name"
        style={{ height: "27px", width: "18rem" }}
        value={this.state.searchText}
        onChange={e => {
          this.setState({
            searchText: e.target.value,
          })
          this.searchFunction(e)
        }}
        allowClear
      />
      {/*}
        <select
          className="mr-3"
          value={this.state.selectedClass}
          onChange={e => {
            let findClass = this.state.classes.find(item => {
              return item.value === e.target.value
            })
            this.state.selectedClass = e.target.value
            this.loadChapterTopics()
            this.renderChaptersTopics()
          }}
        >
          <option value="">Status</option>
            <option value='active' key='active'>Active</option>
            <option value='active' key='active'>In Active</option>
        </select>
        {*/}
      <div style={{ flex: 3 }} />
    </div>
  )

  dateFunction = () => {
    var todayDate = new Date()
    var month = todayDate.getMonth() + 1
    var year = todayDate.getUTCFullYear()
    var tdate = todayDate.getDate()
    if (month < 10) {
      month = "0" + month
    }
    if (tdate < 10) {
      tdate += "0"
    }
    var maxdate = tdate + "-" + month + "-" + year
    document.getElementById("dob").setAttribute("max", maxdate)
  }

  editStu = item => {
    let object = item
    object.section = object.section || {}
    object.id = object.studentId || ""
    object.admissionNumber = object.admissionNumber ?? ""
    object.studentEmail = object.studentEmail || ""
    object.studentPhone = object.studentPhone || ""
    object.primaryParent = object.primaryParent || ""
    object.dateOfJoining = object.dateOfJoining || ""
    object.parentDetailId = object.parents[0].parentId
    object.fatherName = object.parents[0].fatherName || ""
    object.fatherFirstName = object.parents[0].fatherFirstName || ""
    object.fatherLastName = object.parents[0].fatherLastName || ""
    object.fatherEmail = object.parents[0].fatherEmail || ""
    object.fatherPhone = object.parents[0].fatherPhone || ""
    object.motherName = object.parents[0].motherName || ""
    object.motherFirstName = object.parents[0].motherFirstName || ""
    object.motherLastName = object.parents[0].motherLastName || ""
    object.motherEmail = object.parents[0].motherEmail || ""
    object.motherPhone = object.parents[0].motherPhone || ""
    object.legalGuardianEmail = object.parents[0].legalGuardianEmail || ""
    object.legalGuardianName = object.parents[0].legalGuardianName || ""
    object.legalGuardianFirstName =
      object.parents[0].legalGuardianFirstName || ""
    object.legalGuardianLastName = object.parents[0].legalGuardianLastName || ""
    object.legalGuardianPhone = object.parents[0].legalGuardianPhone || ""
    object.localGuardianEmail = object.parents[0].localGuardianEmail || ""
    object.localGuardianName = object.parents[0].localGuardianName || ""
    object.localGuardianFirstName =
      object.parents[0].localGuardianFirstName || ""
    object.localGuardianLastName = object.parents[0].localGuardianLastName || ""
    object.localGuardianPhone = object.parents[0].localGuardianPhone || ""

    this.props.saveStudentDatabaseDetailInStore(object)
    this.setState({
      modalData: object,
      isStudentDetailsModalOpen: true,
    })
  }

  render() {
    return (
      <AppWrapper
        showAdminProfileSidemenu
        showAdminBackToDashboard
        screenName="Students"
      >
        <StudentDetailsModal
          isModalOpen={this.state.isStudentDetailsModalOpen}
          closeModal={() => this.closeStudentDetailsModal()}
          onSubmitStudentDetails={(toggle, data) =>
            this.onSubmitStudentDetails(toggle, data)
          }
        />
        {this.state.isStudentsLoading ? (
          <div style={{ position: "absolute", top: "50%", left: "50%" }}>
            <HashLoader
              css={{
                position: "fixed",
                width: "100%",
                height: "100%",
                left: "0px",
                top: "0px",
              }}
              size={30}
              color="#084353"
              loading={this.state.isStudentsLoading}
              sizeUnit="px"
            />
          </div>
        ) : (
          <div
            className="card bg-white p-3 smoke"
            style={{ marginBottom: "6rem" }}
          >
            <div className="row">
              <div className="col-5">{this.renderSelectFilters()}</div>
              <div className="col-7">
                <div className="row align-items-center justify-content-end">
                  <div className="mr-2">
                    <button
                      type="button"
                      className="btn btn-sm bg-brand text-light rounded-pill px-3"
                      id="teacher-tooltip"
                      onClick={() =>
                        this.setState({
                          isSendCredModalOpen: true,
                        })
                      }
                    >
                      <i className="fas fa-envelope mr-2" />
                      Send Credentials
                    </button>
                  </div>
                  <div className="mr-2">
                    <button
                      type="button"
                      className="btn btn-sm bg-brand text-light rounded-pill px-3"
                      id="teacher-tooltip"
                      onClick={() =>
                        this.setState({ showUpgrageProccess: true })
                      }
                    >
                      <i className="fas fa-plus mr-2" />
                      Add Student
                    </button>
                  </div>
                  <div className="mr-2">
                    <a
                      className="btn btn-sm bg-light text-dark rounded-pill px-3"
                      id="UncontrolledTooltipTemplate"
                      href={URL.STUDENT_DATABASE_DETAIL_XLSX}
                      download
                    >
                      <span className="px-2 py-1">
                        <i className="fal fa-table mr-2"></i>
                        Template
                      </span>
                    </a>
                    <UncontrolledTooltip
                      placement="top"
                      className="bg-danger"
                      modifiers={tooltipModifiers}
                      target="UncontrolledTooltipTemplate"
                    >
                      Download offline template to add bulk student details
                      offline and upload them later
                    </UncontrolledTooltip>
                  </div>
                  <div className="d-flex btn btn-sm bg-light text-dark rounded-pill cursor-pointer mr-2">
                    <div>
                      <i className="fal fa-file-upload"></i>
                    </div>
                    <div>
                      <button
                        style={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          width: "100%",
                          height: "100%",
                        }}
                        onClick={() => this.setState({ isUploadModal: true })}
                      >
                        Upload Students
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ModalTransition>
              {this.state.isSendCredModalOpen && (
                <Modal
                  width="50%"
                  heading="SEND STUDENT CREDENTIALS"
                  actions={[
                    {
                      text: this.state.isSendCredInProgress
                        ? "SENDING..."
                        : "SEND",
                      onClick: this.sendCreds,
                    },
                    {
                      text: "CANCEL",
                      onClick: () => {
                        this.setState({
                          isSendCredModalOpen: false,
                        })
                      },
                      style: {
                        fontWeight: "bold",
                      },
                    },
                  ]}
                >
                  <div className="d-flex flex-row mb-4">
                    {this.state.selectedStudents.length > 0 ? (
                      <span>
                        Send Credentials to selected{" "}
                        {this.state.selectedStudents.length} students
                      </span>
                    ) : (
                      <span>Send Credentials to all the filtered students</span>
                    )}
                  </div>
                  <div className="d-flex flex-row">
                    <h6
                      className="text-dark mt-2"
                      style={{ width: "-webkit-fill-available" }}
                    >
                      Select mode of communication:{" "}
                    </h6>
                    <div
                      className="form-group"
                      style={{ width: "-webkit-fill-available" }}
                    >
                      <button
                        className={`btn primary-outline-btn right-br ${
                          this.state.sendCredOnEmail
                            ? "primary-btn text-light"
                            : "btn-hover"
                        }`}
                        type="button"
                        onClick={() =>
                          this.setState({
                            sendCredOnEmail: !this.state.sendCredOnEmail,
                          })
                        }
                      >
                        <i className="fas fa-at mr-2"></i>EMAIL
                      </button>
                      <button
                        className={`btn primary-outline-btn left-br ${
                          this.state.sendCredOnSMS
                            ? "primary-btn text-light"
                            : "btn-hover"
                        }`}
                        type="button"
                        onClick={() =>
                          this.setState({
                            sendCredOnSMS: !this.state.sendCredOnSMS,
                          })
                        }
                      >
                        <i className="fas fa-mobile-alt mr-2"></i>SMS
                      </button>
                      <i
                        className="fas fa-info-circle ml-2"
                        id="sms-info"
                        aria-hidden="true"
                      ></i>
                      <UncontrolledTooltip
                        placement="right"
                        className="bg-danger"
                        target="sms-info"
                      >
                        Select mode of communication, you can select both and
                        send the credentials in email as well as SMS at the same
                        time
                      </UncontrolledTooltip>
                    </div>
                  </div>
                </Modal>
              )}
            </ModalTransition>

            <ModalTransition>
              {this.state.isUploadModal && (
                <Modal width="40%" heading="UPLOAD STUDENT DETAILS">
                  <div className="d-flex flex-row">
                    <div
                      className="font-weight-bold mr-4"
                      style={{ marginTop: "2px", fontSize: "large" }}
                    >
                      Choose
                    </div>

                    {!this.state.files && (
                      <div
                        className="btn btn-sm bg-light text-dark rounded-pill cursor-pointer mr-2"
                        style={{ display: "none" }}
                      >
                        <span className="px-3 py-1 cursor-pointer">
                          <i className="fal fa-file-upload mr-2"></i>
                          Upload Student Database
                          <input
                            id="ownmethod"
                            type="file"
                            className="cursor-pointer"
                            onChange={event => {
                              this.setState({
                                files: true,
                                isUploadModal: false,
                                selectedMethod: "",
                                buttonEnable: false,
                              })
                              this.handleDrop(event.target.files)
                            }}
                            accept=".csv,.xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            style={{
                              opacity: 0.0,
                              position: "absolute",
                              top: 0,
                              left: 0,
                              bottom: 0,
                              right: 0,
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </span>
                      </div>
                    )}

                    {!this.state.files && (
                      <div
                        className="border-bottom card cursor-pointer mb-2 mr-3"
                        style={{ display: "none" }}
                      >
                        <span className="px-3 py-1" id="ownmethod">
                          <i className="fal fa-file-upload mr-2"></i>
                          Upload EMSI Database
                          <input
                            id="emis"
                            type="file"
                            className="cursor-pointer"
                            onChange={event => {
                              this.setState({
                                files: true,
                                isUploadModal: false,
                                selectedMethod: "",
                                buttonEnable: false,
                              })
                              this.handleDropEMSI(event.target.files)
                            }}
                            accept=".csv,.xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            style={{
                              opacity: 0.0,
                              position: "absolute",
                              top: 0,
                              left: 0,
                              bottom: 0,
                              right: 0,
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </span>
                      </div>
                    )}

                    <div style={{ width: "80%" }}>
                      <select
                        className="form-control input-border-color"
                        value={this.state.selectedMethod}
                        onChange={e => {
                          this.setState({
                            selectedMethod: e.target.value,
                            buttonEnable: true,
                          })
                        }}
                      >
                        <option value="">Select Method</option>
                        <option value="ownmethod"> Template Data</option>
                        <option value="emis">EMIS Student Data</option>
                      </select>
                    </div>
                  </div>

                  <ModalFooter style={{ float: "right" }}>
                    {this.state.buttonEnable && (
                      <div>
                        <label
                          htmlFor={this.state.selectedMethod}
                          className="text-light"
                          style={{
                            width: "5rem",
                            border: "1px solid",
                            cursor: "pointer",
                            borderRadius: "15px",
                            textAlign: "center",
                            marginRight: "10px",
                            marginTop: "6px",
                            backgroundColor: "#06B191",
                          }}
                        >
                          NEXT
                        </label>
                      </div>
                    )}

                    <Button
                      color="light"
                      onClick={() =>
                        this.setState({
                          isUploadModal: false,
                          selectedMethod: "",
                          buttonEnable: false,
                        })
                      }
                      className="text-muted px-4 py-1"
                      style={{ borderRadius: 12, border: 0 }}
                    >
                      Close
                    </Button>
                  </ModalFooter>
                </Modal>
              )}
            </ModalTransition>

            <div className="table-responsive">
              <table
                className={`table ${
                  this.state.userList.length > 0 ? "table-hover" : ""
                } table-borderless admin-stu-table`}
              >
                <thead className="bg-theme-light text-white">
                  <tr>
                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      <div style={{ marginLeft: "14px" }}>
                        <input
                          type="checkbox"
                          className="subject-checkbox"
                          checked={this.state.selectedStudents.length > 0}
                          onChange={e => {
                            if (e.target.checked) {
                              this.setState({
                                selectedStudents: this.state.studentList.map(
                                  ({ studentId }) => studentId
                                ),
                              })
                            } else {
                              this.setState({
                                selectedStudents: [],
                              })
                            }
                          }}
                        />
                      </div>
                    </td>
                    <th scope="col">
                      {this.state.selectedStudents.length > 0
                        ? this.state.selectedStudents.length +
                          " Students selected "
                        : "Student Name"}
                      {this.state.selectedStudents.length > 0 && (
                        <button
                          type="button"
                          className="btn btn-sm bg-light text-danger rounded-pill px-2 ml-2"
                          id="teacher-tooltip"
                          style={{ padding: "0px" }}
                          onClick={this.deleteStudent}
                        >
                          <i className="fal fa-trash-alt mr-1" />
                          Delete
                        </button>
                      )}
                    </th>
                    <th
                      scope="col"
                      className="text-center"
                      style={{
                        width:
                          this.state.selectedStudents.length > 0
                            ? "9.5rem"
                            : "",
                      }}
                    >
                      {this.state.selectedStudents.length > 0
                        ? ""
                        : "Roll Number"}
                    </th>
                    <th
                      scope="col"
                      className="text-center"
                      style={{
                        width:
                          this.state.selectedStudents.length > 0
                            ? "5.3rem"
                            : "",
                      }}
                    >
                      {this.state.selectedStudents.length > 0 ? "" : "Class"}
                    </th>
                    <th
                      scope="col"
                      className="text-center"
                      style={{
                        width:
                          this.state.selectedStudents.length > 0
                            ? "6.28rem"
                            : "",
                      }}
                    >
                      {this.state.selectedStudents.length > 0 ? "" : "Gender"}
                    </th>
                    <th
                      scope="col"
                      className="text-center"
                      style={{
                        width:
                          this.state.selectedStudents.length > 0
                            ? "16.1rem"
                            : "",
                      }}
                    >
                      {this.state.selectedStudents.length > 0
                        ? ""
                        : "Primary Contact Name"}
                    </th>
                    <th
                      scope="col"
                      className="text-center"
                      style={{
                        width:
                          this.state.selectedStudents.length > 0 ? "18rem" : "",
                      }}
                    >
                      {this.state.selectedStudents.length > 0
                        ? ""
                        : "Primary Contact Number"}
                    </th>
                    <th
                      scope="col"
                      style={{
                        width:
                          this.state.selectedStudents.length > 0
                            ? "6.2rem"
                            : "",
                      }}
                    >
                      {this.state.selectedStudents.length > 0 ? "" : "Actions"}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {this.state.userList.length > 0 ? (
                    this.state.userList.map((item, index) => (
                      <tr key={index} style={{ height: "5rem" }}>
                        <td style={{ verticalAlign: "middle" }}>
                          <div className="ml-3 text-center">
                            <input
                              type="checkbox"
                              className="subject-checkbox"
                              checked={
                                !!this.state.selectedStudents.includes(
                                  item.studentId
                                )
                              }
                              onChange={e => {
                                if (e.target.checked) {
                                  this.setState({
                                    selectedStudents: [
                                      item.studentId,
                                      ...this.state.selectedStudents,
                                    ],
                                  })
                                } else {
                                  this.setState({
                                    selectedStudents: this.state.selectedStudents.filter(
                                      str => str !== item.studentId
                                    ),
                                  })
                                }
                              }}
                            />
                          </div>
                        </td>
                        <td
                          style={{ verticalAlign: "middle" }}
                          onClick={() => {
                            this.props.history.push({
                              pathname: URL_PATH.ADMIN_STUDENT_PROFILE,
                              state: {
                                studentId: item.studentId,
                              },
                            })
                          }}
                        >
                          <div
                            className="row ml-2"
                            style={{ marginLeft: "6rem !important" }}
                          >
                            <img
                              className="rounded-circle mr-2"
                              src={
                                item.gender === null
                                  ? ""
                                  : item.gender === "M"
                                  ? boypic
                                  : girlpic
                              }
                              alt="Gender"
                              width={25}
                            />
                            <div className="cursor-pointer studentNameOnHover position-relative">
                              {item.studentFirstName} {item.studentLastName}
                              <button
                                className={`admin-stu-table-btn ${
                                  (
                                    item?.studentFirstName +
                                    item?.studentLastName
                                  )?.length > 15
                                    ? "big"
                                    : ""
                                }`}
                              >
                                <Arrow />
                                Open
                              </button>
                            </div>
                          </div>
                        </td>
                        <td
                          className="ml-2 text-center"
                          style={{ verticalAlign: "middle" }}
                        >
                          {item.registerRollNumber}
                        </td>
                        <td
                          className="ml-2 text-center"
                          style={{ verticalAlign: "middle" }}
                        >
                          {item.section ? (
                            <span>
                              {item.section.className}-
                              {item.section.sectionName}
                            </span>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td
                          className="ml-2 text-center"
                          style={{ verticalAlign: "middle" }}
                        >
                          {item.gender}
                        </td>
                        <td
                          className="ml-2 text-center"
                          style={{ verticalAlign: "middle" }}
                        >
                          {item.primaryParentName &&
                          String(item.primaryParentName).trim() !== ""
                            ? item.primaryParentName
                            : "-"}
                        </td>
                        <td
                          className="ml-2 text-center"
                          style={{ verticalAlign: "middle" }}
                        >
                          {item.primaryParentPhone
                            ? item.primaryParentPhone
                            : "-"}
                        </td>
                        <td
                          className="ml-2 "
                          style={{ verticalAlign: "middle" }}
                        >
                          <UncontrolledDropdown direction="left">
                            <DropdownToggle tag="button" className="nav-link">
                              <i className="fas fa-ellipsis-v text-black cursor-pointer"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem onClick={() => this.editStu(item)}>
                                Edit
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => this.deleteStudentSingle(item)}
                              >
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={8}>
                        <UpgradeStudentsClass getStudents={this.getStudents} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {this.state.userList.length > 0 && this.state.totalStudents && (
              <div className="row">
                <div className="container col-lg-8 text-left">
                  <span className="mr-3">
                    Page {this.state.currentPage} of{" "}
                    {Math.ceil(this.state.totalStudents / this.state.limit)}
                  </span>
                  Per Page:{" "}
                  <select
                    className="mr-3 mb-2"
                    defaultValue={this.state.limit}
                    onChange={e => {
                      this.setState(
                        {
                          limit: e.target.value,
                        },
                        () => {
                          this.getStudents()
                        }
                      )
                    }}
                  >
                    <option value="10" key="10">
                      10
                    </option>
                    <option value="15" key="15">
                      15
                    </option>
                    <option value="20" key="20">
                      20
                    </option>
                    <option value="25" key="25">
                      25
                    </option>
                    <option value="30" key="30">
                      30
                    </option>
                    <option value="50" key="50">
                      50
                    </option>
                  </select>
                  Total: {this.state.totalStudents} Students
                </div>
                <div className="container col-lg-4 text-right">
                  {!this.state.isLoading && (
                    <div>
                      {this.state.previousPage && (
                        <button
                          className="btn text-brand py-1 px-3 mx-2"
                          style={{
                            borderRadius: 8,
                            backgroundColor: "#C6C6C6",
                            width: 100,
                          }}
                          onClick={() => {
                            const previousPage = this.state.currentPage - 2
                            this.setState(
                              {
                                nextPage: this.state.currentPage + 1,
                                currentPage: this.state.currentPage - 1,
                                previousPage:
                                  previousPage < 0 ? null : previousPage,
                                offset: this.state.limit * previousPage,
                              },
                              () => {
                                this.getStudents()
                              }
                            )
                          }}
                        >
                          Previous
                        </button>
                      )}
                      {this.state.nextPage && (
                        <button
                          className="btn text-brand py-1 px-3 mx-2"
                          style={{
                            borderRadius: 8,
                            backgroundColor: "#C6C6C6",
                            width: 100,
                          }}
                          onClick={() => {
                            this.setState(
                              {
                                nextPage: this.state.nextPage + 1,
                                currentPage: this.state.currentPage + 1,
                                previousPage: this.state.currentPage + 1 - 1,
                                offset:
                                  this.state.limit * this.state.currentPage,
                              },
                              () => {
                                this.getStudents()
                              }
                            )
                          }}
                        >
                          Next
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}

            <ModalTransition>
              {this.state.isStudentListModalOpen && (
                <Modal
                  width="65%"
                  heading="STUDENT LIST"
                  actions={[
                    {
                      text: this.state.isShowExcelLoading
                        ? "LOADING..."
                        : "SAVE",
                      onClick: this.saveExcelUpload,
                      style: {
                        fontWeight: "bold",
                        backgroundColor: "#06B191 !important",
                        color: "white !important",
                        border: "none",
                      },
                    },
                    {
                      text: "CANCEL",
                      onClick: () => {
                        this.setState({
                          files: false,
                          isStudentListModalOpen: false,
                          isShowExcelLoading: false,
                        })
                      },
                      style: {
                        fontWeight: "bold",
                        border: "none",
                      },
                    },
                  ]}
                >
                  <div className="form-group col-lg-4">
                    <small>
                      Select Class <span style={{ color: "red" }}>*</span>
                    </small>
                    <select
                      className="form-control"
                      value={this.state.bulkUploadSectionId}
                      onChange={e => {
                        this.setState({ bulkUploadSectionId: e.target.value })
                      }}
                    >
                      <option value="">Select Class</option>
                      {this.props.classesList &&
                        this.props.classesList.map(option => (
                          <option value={option.id} key={option.value}>
                            {option.label}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="my-3 table-responsive">
                    <table className="table table-hover">
                      <thead className="bg-lightblue-custom">
                        <tr>
                          <th scope="col">First Name</th>
                          <th scope="col">Last Name</th>
                          <th scope="col">Gender</th>
                          <th scope="col">Dob</th>
                          {/* <th scope="col">Register Number</th>
                            <th scope="col">DOJ</th>*/}
                          <th scope="col">Student Mail-id</th>
                          <th scope="col">Phone number</th>
                          {/* <th scope="col">Admission Number</th>
                            <th scope="col">Primary Parent</th>
                            <th scope="col">Father First Name</th>
                            <th scope="col">Father Last Name</th>
                            <th scope="col">Father Email</th>
                            <th scope="col">Father Phone</th>
                            <th scope="col">Mother First Name</th>
                            <th scope="col">Mother Last Name</th>
                            <th scope="col">Mother Email</th>
                            <th scope="col">Mother Phone</th> */}
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.studentsList &&
                          this.state.studentsList.length > 0 &&
                          this.state.studentsList.map((student, index) => (
                            <tr key={index}>
                              <td>{student.firstName}</td>
                              <td>{student.lastName}</td>
                              <td>{student.gender}</td>
                              <td>{student.dob}</td>
                              {/* <td>{student.registerNumber}</td>
                                <td>{student.dateOfJoining}</td>*/}
                              <td>{student.studentEmail}</td>
                              <td>{student.studentPhone}</td>
                              {/* <td>{student.admissionNumber}</td>
                                <td>{student.primaryParent}</td>
                                <td>
                                  {student.parentsList[0].fatherFirstName}
                                </td>
                                <td>{student.parentsList[0].fatherLastName}</td>
                                <td>{student.parentsList[0].fatherEmail}</td>
                                <td>{student.parentsList[0].fatherPhone}</td>
                                <td>
                                  {student.parentsList[0].motherFirstName}
                                </td>
                                <td>{student.parentsList[0].motherLastName}</td>
                                <td>{student.parentsList[0].motherEmail}</td>
                                <td>{student.parentsList[0].motherPhone}</td> */}
                              <td>
                                <button
                                  className="badge badge-pill badge-danger"
                                  onClick={() => {
                                    let student = this.state.studentsList
                                    student.splice(index, 1)
                                    this.setState({ studentsList: student })
                                  }}
                                >
                                  <small>
                                    <i className="far fa-user-minus mr-1" />
                                    Delete
                                  </small>
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </Modal>
              )}
            </ModalTransition>

            <ModalTransition>
              {this.state.isStudentExportModalOpen && (
                <Modal width="55%" heading="Download Student List">
                  <div className="form-group">
                    <small>Select the columns you like to download</small>
                    <div
                      className="mb-3"
                      style={{ height: "350px", overflow: "auto" }}
                    >
                      {this.state.exportDefaultFields &&
                        this.state.exportDefaultFields.map((item, index) => (
                          <div className="mb-1">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={item.field}
                                value={item.include}
                                checked={item.include}
                                onChange={e => {
                                  let exportDefaultFieldsNew = this.state
                                    .exportDefaultFields
                                  if (e.target.checked) {
                                    exportDefaultFieldsNew[index].include = true
                                  } else {
                                    exportDefaultFieldsNew[
                                      index
                                    ].include = false
                                  }
                                  this.setState({
                                    exportDefaultFields: exportDefaultFieldsNew,
                                  })
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={item.field}
                              >
                                {item.label}
                              </label>
                            </div>
                          </div>
                        ))}

                      {this.state.exportEmptyFields &&
                        this.state.exportEmptyFields.map(item => (
                          <div className="mb-1">
                            <i
                              className="fas fa-times-circle mr-2 cursor-pointer"
                              onClick={e => {
                                this.setState({
                                  exportEmptyFields: this.state.exportEmptyFields.filter(
                                    val => val !== item
                                  ),
                                })
                              }}
                            ></i>
                            <span>{item}</span>
                          </div>
                        ))}
                    </div>

                    {this.state.showAddEmptyColumnField && (
                      <div>
                        <div style={{ display: "inline-block" }}>
                          <input
                            type="text"
                            placeholder="Enter column name"
                            style={{ height: "27px", width: "250px" }}
                            className="form-control br-8 mr-2"
                            name="Title"
                            value={this.state.exportEmptyFieldName}
                            onChange={e => {
                              this.setState({
                                exportEmptyFieldName: e.target.value,
                              })
                            }}
                          />
                        </div>
                        <div style={{ display: "inline" }}>
                          <button
                            type="button"
                            className="btn btn-sm bg-green text-light br-4 px-3"
                            onClick={() => {
                              let exportEmptyFieldsNew = this.state
                                .exportEmptyFields
                              exportEmptyFieldsNew.push(
                                this.state.exportEmptyFieldName
                              )
                              this.setState({
                                showAddEmptyColumnField: false,
                                exportEmptyFields: exportEmptyFieldsNew,
                                exportEmptyFieldName: "",
                              })
                            }}
                          >
                            Add
                          </button>
                        </div>
                        <div style={{ display: "inline" }} className="ml-2">
                          <button
                            type="button"
                            className="btn btn-sm br-4 px-3"
                            style={{
                              backgroundColor: "#DBDBDB",
                              color: "#06B191",
                              border: "none",
                            }}
                            onClick={() => {
                              this.setState({ showAddEmptyColumnField: false })
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    )}
                    <span
                      className="cursor-pointer text-primary"
                      onClick={e => {
                        this.setState({ showAddEmptyColumnField: true })
                      }}
                    >
                      + Add Empty column
                    </span>
                  </div>
                  <div className="row mb-3">
                    <div className="px-3 bg-light br-2 col-lg-8 py-1">
                      Download as{" "}
                      <span
                        className="mx-2 cursor-pointer"
                        onClick={e => {
                          this.exportEXCEL()
                        }}
                      >
                        <i className="fas fa-file-excel"></i> Excel
                      </span>{" "}
                      <span
                        className="mx-2 cursor-pointer"
                        onClick={this.exportPDF}
                      >
                        <i className="fas fa-file-pdf cursor-pointer"></i> PDF
                      </span>
                    </div>
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-sm br-4 px-3"
                        style={{ backgroundColor: "#DBDBDB", color: "#06B191" }}
                        onClick={() => {
                          this.setState({ isStudentExportModalOpen: false })
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Modal>
              )}
            </ModalTransition>

            {this.state.showUpgrageProccess && (
              <UpgradeModal
                getStudents={this.getStudents}
                closeModal={() => this.setState({ showUpgrageProccess: false })}
              />
            )}
          </div>
        )}
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    subjectsList: state.subjectDetailsState.subjectList,
    classesList: state.classDetailsState.classList,
    replaceExistingTeacherList: state.teacherDetailsState.teacherDetail,
    teacherList: state.teacherDetailsState.teacherDetail,
    adminStudentList: state.studentDatabaseState.adminStudentList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAllStudentsDatabaseList,
      saveStudentDatabaseDetails,
      updateStudentDatabaseDetails,
      deleteStudentDatabaseDetails,
      saveStudentDatabaseDetailInStore,
      saveAdminStudentDatabaseDetailInStore,
      saveAdminStudentDetailInStore,
      getClassDetails,
      getSubjectDetails,
      showNotification,
      saveIsShowLoadingInStore,
      sendStudentCredentials,
      deleteBulkStudentDatabaseDetails,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(StudentsScreen)
