import * as React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import AppWrapper from "../Wrapper"
import { getQuizReport } from "../../../actions/parent/QuizAction"
import { withRouter } from "react-router-dom"
import { URL_PATH } from "../../../config/urlPath"
import "./Style.css"
import Skeleton from "react-loading-skeleton"
// import { myLog } from "../../../utils/Utility"

const HAPPY = "HAPPY"
const NORMAL = "NORMAL"
const SAD = "SAD"

class StudentAssessmentReport extends React.Component {
  constructor(props) {
    super(props)
    document.title = "Redink"
    this.state = {
      quizAssignId: null,
      isShowLoading: null,
      report: null,
      className: null,
      sectionName: null,
      subjectName: null,
      title: null,
      instruction: null,
      timeLimit: null,
      timeTaken: null,
      isOnline: null,
      gradedMark: null,
      totalMark: null,
      gradePercentage: null,
      rank: null,
      questionsCnt: null,
      correct: null,
      wrong: null,
      ungraded: null,
      partial: null,
      happiness: NORMAL,
      highestMark: null,
      averageMark: null,
      lowestMark: null,
      strongTopics: [],
      weekTopics: [],
    }
  }

  componentDidMount() {
    const { quizAssignInfoId, isOnline } = this.props.location.state
    this.setState({ isShowLoading: true, isOnline })
    this.props.getQuizReport(quizAssignInfoId, (success, data) => {
      if (success) {
        const report = data
        const {
          section,
          subject,
          quizSet,
          gradedMark,
          gradePercentage,
          timeLimit,
          timeTaken,
          rank,
          questionsCnt,
          count,
          stat,
          strongTopics,
          weekTopics,
        } = report
        // myLog("Report Data", data)
        const { className, sectionName } = section
        const { subjectName } = subject
        const { title, totalMark, instruction } = quizSet
        const { correct, partial, incorrect: wrong, ungraded } = count
        const { highestMark, averageMark, lowestMark } = stat
        const happiness = () => {
          const { happy, normal, sad } = stat.happiness
          if (happy) return HAPPY
          if (normal) return NORMAL
          if (sad) return SAD
        }

        this.setState({
          quizAssignId: quizAssignInfoId,
          report,
          className,
          sectionName,
          subjectName,
          title,
          instruction,
          timeLimit: (timeLimit || []).slice(0, -3),
          gradedMark,
          gradePercentage,
          totalMark,
          rank,
          questionsCnt,
          timeTaken: (timeTaken || []).slice(0, -3),
          correct,
          wrong,
          ungraded,
          partial,
          happiness,
          highestMark,
          averageMark,
          lowestMark,
          strongTopics,
          weekTopics,
          markRate: stat.markRate,
          isShowLoading: false,
        })
      } else {
        this.setState({ isShowLoading: false, hasError: true })
      }
    })
  }

  happinessColor = {
    HAPPY: {
      color: "#06b191",
      src: require("./image/happy icon-01.png"),
    },
    NORMAL: {
      color: "#d39307",
      src: require("./image/smiling-01.png"),
    },
    SAD: {
      color: "#ed365c",
      src: require("./image/sad-01.png"),
    },
  }

  renderTopics = (item, _) => {
    const { topic, correctRatio } = item
    return (
      <p
        key={topic.id}
        className={"mr-3"}
        style={{
          color: "rgb(9,67,83)",
          fontSize: "14px",
          fontWeight: "100",
          backgroundColor: "#ddd",
          borderRadius: "5px",
          padding: "2px 15px",
        }}
        title={`(${correctRatio} %)`}
      >
        {topic.name}
      </p>
    )
  }

  renderStrongTopics = (item, _) => {
    const { topic, correctRatio } = item
    return (
      <p
        key={topic.id}
        className={"mr-3 mb-0"}
        style={{
          color: "#094353",
          fontSize: "14px",
          fontWeight: "100",
          backgroundColor: "rgb(148 220 206)",
          borderRadius: "5px",
          padding: "2px 15px",
        }}
        title={`(${correctRatio} %)`}
      >
        {topic.name}
      </p>
    )
  }

  renderWeakTopics = (item, _) => {
    const { topic, correctRatio } = item
    return (
      <p
        key={topic.id}
        className={"mr-3 mb-0"}
        style={{
          color: "#ed365c",
          fontSize: "14px",
          fontWeight: "100",
          backgroundColor: "rgb(246 197 207)",
          borderRadius: "5px",
          padding: "2px 15px",
        }}
        title={`(${correctRatio} %)`}
      >
        {topic.name}
      </p>
    )
  }

  renderProgressBarLine = (title, color, position) => (
    <div
      title={title}
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: position,
        height: "100%",
        width: 4,
        borderRadius: 4,
        backgroundColor: color,
        transform: "translateX(-2px)",
      }}
    />
  )

  renderProgressBarMyLine = (title, color, position) => {
    const current =
      parseInt(position) > 79 ? HAPPY : parseInt(position) > 40 ? NORMAL : SAD

    return (
      <div
        title={title}
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: position,
          height: "100%",
          width: 4,
          borderRadius: 4,
          backgroundColor: color,
          transform: "translateX(-2px)",
        }}
      >
        <div
          style={{
            position: "absolute",
            height: 32,
            width: 32,
            borderRadius: 16,
            top: "-36px",
            backgroundColor: "#E57B60",
            transform: "translateX(-14px)",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "-1.4rem",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            You
          </div>
          <img
            src={this.happinessColor[current].src}
            alt={title}
            style={{
              height: 28,
              width: 28,
              marginTop: 2,
              marginLeft: 2,
              borderRadius: 15,
              position: "absolute",
              zIndex: 5,
            }}
          />
          <div
            style={{
              height: 6,
              width: 6,
              background: "#E57B60",
              left: "calc(50% - 3px)",
              position: "absolute",
              transform: "rotate(45deg)",
              top: "calc(100% - 3px)",
            }}
          ></div>
        </div>
      </div>
    )
  }

  renderProgressBar = (low, avg, high, mine) => {
    return (
      <div
        style={{
          width: 400,
          height: 30,
          backgroundColor: "#094353",
          borderRadius: 4,
        }}
      >
        <div
          style={{
            position: "relative",
            width: "calc(100% - 4px)",
            height: "100%",
            left: 2,
          }}
        >
          {this.renderProgressBarLine("Lowest mark", "#ed365c", low || "0%")}
          {this.renderProgressBarLine("Avg. mark", "#ffc727", avg || "0%")}
          {this.renderProgressBarLine("Highest mark", "#06b191", high || "0%")}
          {this.renderProgressBarMyLine(
            high === mine ? "You have the highest marks" : "My marks",
            "#E57B60",
            mine || "0%"
          )}
        </div>
      </div>
    )
  }

  render() {
    const {
      className,
      sectionName,
      subjectName,
      isOnline,
      title,
      instruction,
      timeLimit,
      totalMark,
      gradedMark,
      gradePercentage,
      rank,
      questionsCnt,
      timeTaken,
      correct,
      wrong,
      ungraded,
      partial,
      strongTopics,
      weekTopics,
      markRate,
      isShowLoading,
      hasError,
    } = this.state

    if (isShowLoading) {
      return (
        <AppWrapper
          teacherStudentRecordSidemenu
          showTeacherThree
          showTeacherLogo
          screenName="TESTS Report"
        >
          <div className="row">
            <div className="col">
              <h5 className="text-dark font-weight-bold">
                <Skeleton width="250px" />
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <h4 className="text-dark font-weight-bold m-0 mr-2">
                  <Skeleton width="140px" />
                </h4>
              </div>
              <button className="button mx-3" disabled>
                Review Test
              </button>
            </div>
          </div>
        </AppWrapper>
      )
    }

    if (hasError) {
      return (
        <AppWrapper
          teacherStudentRecordSidemenu
          showTeacherThree
          showTeacherLogo
        >
          <div className="row">
            <div className="col">
              <h5 className="text-dark text-center font-weight-bold">
                An error occured while getting details make sure teacher has
                published the report
              </h5>
            </div>
          </div>
        </AppWrapper>
      )
    }

    return (
      <AppWrapper teacherStudentRecordSidemenu showTeacherThree showTeacherLogo>
        <div className="row">
          <div className="col">
            <h5 className="text-dark font-weight-bold">
              {`Class ${className}${sectionName} | ${subjectName} | ${
                isOnline ? "Online" : "InClass"
              }`}
            </h5>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <h4 className="text-dark font-weight-bold m-0 mr-2">{title}</h4>
              <i
                className="fas fa-info-circle"
                style={
                  instruction ? { color: "#04B191" } : { color: "#cbcbcb" }
                }
                title={instruction}
              />
              <i
                className="fas fa-circle text-muted mx-2"
                style={{ fontSize: 4 }}
              />
            </div>
            <button
              className="button mx-3"
              onClick={() =>
                this.props.history.push({
                  pathname: URL_PATH.STUDENT_TESTS_RESULT,
                  state: { quizAssignInfoId: this.state.quizAssignId },
                })
              }
            >
              Review Test
            </button>
          </div>
        </div>
        <div className="mt-4">
          <div className="bg-light first">
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div className="flex-1">
                <div>
                  <p style={{ color: "rgb(9,67,83)" }}>Your Score</p>
                  <p
                    style={{ color: "#f46345", fontWeight: "bold" }}
                    className="score m-0"
                  >
                    {gradedMark}
                    <span style={{ fontSize: "22px", color: "rgb(9,67,83" }}>
                      {" / "}
                      {totalMark}
                    </span>
                  </p>
                </div>
              </div>
              <div className="flex-1">
                <p style={{ color: "rgb(9,67,83" }}>Percentage</p>
                <p
                  className="bigText mb-0"
                  style={{ color: "rgb(9,67,83)", fontWeight: "bold" }}
                >
                  {gradePercentage}
                  <span style={{ fontSize: "22px", color: "rgb(9,67,83)" }}>
                    %
                  </span>
                </p>
              </div>
              <div className="flex-1">
                <p style={{ color: "rgb(9,67,83)" }}>Class Rank</p>
                <p
                  className="bigText mb-0"
                  style={{ color: "rgb(9,67,83)", fontWeight: "bold" }}
                >
                  {rank}
                </p>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                justifyContent: "flex-end",
                display: "flex",
                flexDirection: "column",
                marginTop: "30px",
              }}
            >
              <div className="mt-4 mb-1 d-flex align-items-center justify-content-center">
                <div
                  className="h5 font-weight-bold mr-2 mb-0"
                  style={{ color: "#094353" }}
                >
                  0
                </div>
                {this.renderProgressBar(
                  markRate && `${markRate.lowest.percentage}%`,
                  markRate && `${markRate.average.percentage}%`,
                  markRate && `${markRate.highest.percentage}%`,
                  `${gradePercentage}%`
                )}
                <div
                  className="h5 font-weight-bold ml-2 mb-0"
                  style={{ color: "#094353" }}
                >
                  {totalMark}
                </div>
              </div>

              <div
                style={{ flex: 1, justifyContent: "center", display: "flex" }}
              >
                <div
                  style={{
                    width: "430px",
                    justifyContent: "space-around",
                    marginTop: "10px",
                    display: "flex",
                  }}
                >
                  <div>
                    <div
                      className="my-1"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "0.6rem",
                          height: "10px",
                          width: "10px",
                          borderRadius: "5px",
                          backgroundColor: this.happinessColor[SAD].color,
                        }}
                      ></div>
                      <p
                        className="m-0"
                        style={{
                          color: this.happinessColor[SAD].color,
                          fontWeight: "bold",
                        }}
                      >
                        {markRate ? markRate.lowest.mark || "0" : "0"}
                      </p>
                    </div>
                    <p style={{ fontSize: "11px" }}>Lowest Mark</p>
                  </div>
                  <div>
                    <div
                      className="my-1"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "0.6rem",
                          height: "10px",
                          width: "10px",
                          borderRadius: "5px",
                          backgroundColor: "#ffc727",
                        }}
                      ></div>
                      <p
                        className="m-0"
                        style={{
                          color: this.happinessColor[NORMAL].color,
                          fontWeight: "bold",
                        }}
                      >
                        {markRate ? markRate.average.mark || "0" : "0"}
                      </p>
                    </div>
                    <p style={{ fontSize: "11px" }}>Avg. Mark</p>
                  </div>
                  <div>
                    <div
                      className="my-1"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "0.6rem",
                          height: "10px",
                          width: "10px",
                          borderRadius: "5px",
                          backgroundColor: this.happinessColor[HAPPY].color,
                        }}
                      ></div>
                      <p
                        className="m-0"
                        style={{
                          color: this.happinessColor[HAPPY].color,
                          fontWeight: "bold",
                        }}
                      >
                        {markRate ? markRate.highest.mark || "0" : "0"}
                      </p>
                    </div>
                    <p style={{ fontSize: "11px" }}>Highest Mark</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {!isOnline ? (
            <div className="d-flex justify-content-center mt-5 text-secondary">
              (No further report for InClass quiz)
            </div>
          ) : (
            <>
              <div className="bg-light second">
                <div className="heade">
                  <div className="dd">
                    <p className="labels mb-0">
                      Total Questions {questionsCnt}
                    </p>
                    <p className="labels mb-0">Time Limit {timeLimit}</p>
                  </div>
                  <p
                    className="labels ml-auto mb-0"
                    style={{ textAlign: "right" }}
                  >
                    Time Taken {timeTaken}
                  </p>
                </div>

                <div className="bodyy">
                  <div className="cc">
                    <img
                      src={require("./image/3.Correct Icon.png")}
                      alt="img"
                    />
                    <div className="inner">
                      <p style={{ color: "rgb(9,67,83)" }}>Correct Answer</p>
                      <p
                        style={{
                          color: "rgb(9,67,83)",
                          fontSize: "30px",
                          fontWeight: "bold",
                        }}
                      >
                        {correct}
                      </p>
                    </div>
                  </div>
                  <div className="cc">
                    <img
                      src={require("./image/4.Partial icon.png")}
                      alt="img"
                    />
                    <div className="inner">
                      <p style={{ color: "rgb(9,67,83)" }}>Partial Correct</p>
                      <p
                        style={{
                          color: "rgb(9,67,83)",
                          fontSize: "30px",
                          fontWeight: "bold",
                        }}
                      >
                        {partial}
                      </p>
                    </div>
                  </div>
                  <div className="cc">
                    <img src={require("./image/5.Wrong icon.png")} alt="img" />
                    <div className="inner">
                      <p style={{ color: "rgb(9,67,83)" }}>Wrong Answer</p>
                      <p
                        style={{
                          color: "rgb(9,67,83)",
                          fontSize: "30px",
                          fontWeight: "bold",
                        }}
                      >
                        {wrong}
                      </p>
                    </div>
                  </div>
                  <div className="cc">
                    <img
                      src={require("./image/6.Ungraded icon.png")}
                      alt="img"
                    />
                    <div className="inner">
                      <p style={{ color: "rgb(9,67,83)" }}>Ungraded</p>
                      <p
                        style={{
                          color: "rgb(9,67,83)",
                          fontSize: "30px",
                          fontWeight: "bold",
                        }}
                      >
                        {ungraded}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-light third">
                <p
                  className="my-1"
                  style={{
                    color: "rgb(9,67,83)",
                    fontSize: "14px",
                    fontWeight: "800",
                    marginRight: "70px",
                  }}
                >
                  Strong Topics
                </p>
                {strongTopics.length === 0
                  ? "-"
                  : strongTopics.map(this.renderStrongTopics)}
              </div>
              <div className="bg-light third">
                <p
                  className="my-1"
                  style={{
                    color: "rgb(9,67,83)",
                    fontSize: "14px",
                    fontWeight: "800",
                    marginRight: "70px",
                  }}
                >
                  Weak Topic
                </p>
                {weekTopics.length === 0
                  ? "-"
                  : weekTopics.map(this.renderWeakTopics)}
              </div>
            </>
          )}
        </div>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators({ getQuizReport }, dispatch)
}
export default connect(
  mapStateToProps,
  dispatchToProps
)(withRouter(StudentAssessmentReport))
