/*************************************************
 * RedInk
 * @exports
 * @class Wrapper.js
 * @extends Component
 * @author Sasidharan // on 04/06/2020
 * @copyright © RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import moment from "moment"
import store from "store"
import _ from "lodash"

import {
  updateTeacherMarkAsReadAssignment,
  getNotificationList,
  clearNotification,
} from "../../actions/teacher/DashboardAction"
import { getAssignmentAssignedDetails } from "../../actions/teacher/AssignmentAction"
import { gradeBookLoaded } from "../../actions/teacher/HierarchyAction"

import { URL_PATH } from "../../config/urlPath"
import { logout } from "../../utils/Utility"
import PATH_URL from "../../config/index"

import redinkLogo from "../../assets/images/redink/redink_logo.png"
import femalePic from "../../assets/images/female.png"
import malePic from "../../assets/images/men.png"

class Wrapper extends Component {
  constructor(props) {
    super(props)
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.state = {
      dropdownOpen: false,
      dropdownSubjectOpen: false,
      notificationList: [],
      isOpenToggle: false,
      showBehaviour: false,
    }
  }

  componentDidMount() {
    this.getNotification()
  }

  getNotification = () => {
    this.props.getNotificationList((success, response) => {
      if (success && response.data) {
        let dataSource = []
        response.data.forEach(el => {
          dataSource.push({
            id: el.assignmentDetail ? el.assignmentDetail.id : el.id,
            data: [],
            typeOfWork: el.assignmentDetail
              ? el.assignmentDetail.typeOfWork
              : "",
            module: el.module,
            subModule: el.subModule,
            updatedOn: el.updatedOn,
            studentDetails: [],
          })
        })
        dataSource = _.uniqBy(dataSource, "id")
        dataSource.forEach(el => {
          response.data.forEach(res => {
            if (res.assignmentDetail && el.id === res.assignmentDetail.id) {
              el.data.push(res)
              el.studentDetails.push(res.studentDetail)
            } else {
              el.data.push(res)
            }
          })
        })
        this.setState({ notificationList: dataSource })
      }
    })
  }

  profile = () => {
    this.props.history.push(URL_PATH.TEACHER_PROFILE)
  }

  onMouseEnter() {
    this.setState({ dropdownOpen: true })
  }

  onMouseLeave() {
    this.setState({ dropdownOpen: false })
  }

  onMouseSubjectEnter = () => {
    this.setState({ dropdownSubjectOpen: true })
  }

  onMouseSubjectLeave = () => {
    this.setState({ dropdownSubjectOpen: false })
  }

  render() {
    return (
      <div className="modern">
        <header className="header fixed-top">
          <nav className="bg-white navbar navbar-expand d-flex justify-content-between">
            <ul className="px-2 navbar-nav site-logo">
              <li
                className="p-2 cursor-pointer nav-item show-mdsm d-none"
                onClick={() =>
                  (document.getElementById("overlayNav").style.width = "100%")
                }
              >
                <i className="far fa-bars fs-17"></i>
              </li>
              <li className="nav-item row ml-2 ml-md-0">
                <div className="navbar-brand text-theme font-weight-bolder fs-16">
                  {this.props.screenName}
                </div>
              </li>
            </ul>
            <ul className="px-2 navbar-nav site-logo navbar-expand-sm">
              <li
                className={`nav-item px-2 mr-2 ${
                  this.state.notificationList &&
                  this.state.notificationList.length > 0
                    ? ""
                    : "disabled"
                }`}
                title="Notifications"
              >
                <UncontrolledDropdown
                  isOpen={this.state.isOpenToggle}
                  className="notification-scrollbars"
                >
                  <DropdownToggle
                    tag="a"
                    className="notification-icon"
                    onClick={() => {
                      this.setState({ isOpenToggle: !this.state.isOpenToggle })
                    }}
                  >
                    <div className="disabled nav-link text-dark">
                      <i className="far fa-bell" />
                      <div className="badge badge-light ml-1">
                        {this.state.notificationList &&
                          this.state.notificationList.length}
                      </div>
                    </div>
                  </DropdownToggle>
                  <DropdownMenu
                    right
                    style={{ maxHeight: "80vh", overflowY: "scroll" }}
                    className="px-3"
                  >
                    <div
                      className="cursor-pointer d-flex justify-content-end"
                      onClick={() => {
                        this.props.clearNotification(() => {
                          this.getNotification()
                          this.setState({ isOpenToggle: false })
                        })
                      }}
                    >
                      Clear All
                    </div>
                    {this.state.notificationList &&
                      this.state.notificationList.map((assignment, i) => {
                        if (assignment.module === "assignment") {
                          const student = _.uniqBy(
                            assignment.studentDetails,
                            "studentId"
                          )
                          return (
                            <DropdownItem
                              key={i}
                              onClick={() => {
                                this.setState({ isOpenToggle: false })
                                this.props.getAssignmentAssignedDetails(
                                  assignment.id,
                                  (success1, response1) => {
                                    let studentList = response1.assignedStudents
                                    const studentIndex = studentList.findIndex(
                                      students => {
                                        return (
                                          students.studentId ===
                                          student[0].studentId
                                        )
                                      }
                                    )
                                    if (studentIndex !== -1) {
                                      this.props.history.push({
                                        pathname:
                                          URL_PATH.TEACHER_ASSIGNMENT_VIEW_DETAILS,
                                        state: {
                                          currentAssignmentIndex: studentIndex,
                                          studentAssignments: studentList,
                                        },
                                      })
                                    }
                                    // this.props.history.push({
                                    //   pathname: URL_PATH.TEACHER_ASSIGNMENT_DETAILS,
                                    //   state: {
                                    //     assignmentId: assignment.id,
                                    //   },
                                    // })
                                  }
                                )
                                if (assignment.data) {
                                  assignment.data.forEach(el => {
                                    this.props.updateTeacherMarkAsReadAssignment(
                                      { notificationId: el.notificationId },
                                      () => {
                                        this.props.getNotificationList(
                                          (success, response) => {
                                            if (success && response.data) {
                                              this.setState({
                                                notificationList: response.data,
                                              })
                                            }
                                          }
                                        )
                                      }
                                    )
                                  })
                                }

                                this.setState({
                                  notificationList: this.state.notificationList.filter(
                                    item => item.id !== assignment.id
                                  ),
                                })
                              }}
                              tag="div"
                              className="px-2 mx-0 my-4 bg-white shadow br-10 d-flex w-100 text-light align-items-center"
                              style={{ minWidth: "500px" }}
                            >
                              <div
                                className="shadow bg-theme-light br-10"
                                style={{
                                  height: "60px",
                                  width: "60px",
                                  padding: "8px",
                                }}
                              >
                                <img
                                  src={require("../../assets/images/assignmentNotification.png")}
                                  style={{
                                    objectFit: "contain",
                                    objectPosition: "center",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  alt=""
                                />
                              </div>
                              <div
                                className="ml-3 text-dark"
                                style={{ flex: 1 }}
                              >
                                <div className="d-flex align-items-center">
                                  <div
                                    className="firstLetterUpper"
                                    style={{
                                      fontSize: "0.85rem",
                                      color: "#094353",
                                      fontWeight: "bold",
                                      textTransform: "lowercase",
                                    }}
                                  >
                                    {assignment && assignment.typeOfWork}
                                  </div>
                                  <div
                                    className="ml-auto text-muted"
                                    style={{ fontSize: "0.8rem" }}
                                  >
                                    {moment().to(assignment.updatedOn)}
                                  </div>
                                </div>
                                <div
                                  className="my-1"
                                  style={{ fontSize: "0.9rem" }}
                                >
                                  {student && student[0].studentFirstName}{" "}
                                  {student.length > 1 &&
                                    `and ${student.length -
                                      1} other students`}{" "}
                                  of class{" "}
                                  {assignment.classDetail &&
                                    assignment.data[i].classDetail.className +
                                      "-" +
                                      assignment.data[i].classDetail
                                        .sectionName}{" "}
                                  has{" "}
                                  {student.length < 2 &&
                                  assignment.data[i].approvalStatus === "REWORK"
                                    ? "resubmitted"
                                    : "submitted"}{" "}
                                  {assignment.data[i] &&
                                    assignment.data[i].assignmentDetail && (
                                      <b>
                                        {assignment.data[i] &&
                                        assignment.data[i].assignmentDetail &&
                                        assignment.data[i].assignmentDetail
                                          .title &&
                                        assignment.data[i].assignmentDetail
                                          .title.length < 25
                                          ? assignment.data[i].assignmentDetail
                                              .title
                                          : assignment.data[i]
                                              .assignmentDetail &&
                                            assignment.data[
                                              i
                                            ].assignmentDetail.title.substring(
                                              0,
                                              25
                                            ) + "...."}
                                      </b>
                                    )}
                                </div>
                                <div className="mb-1 d-flex align-items-center">
                                  {student.length < 2 &&
                                    assignment.data[i].submitStatus ===
                                      "OVERDUE" && (
                                      <>
                                        <button className="px-3 py-0 btn rounded-pill btn-sm bg-green text-light">
                                          Submit
                                        </button>{" "}
                                        <button className="px-3 py-0 ml-1 btn rounded-pill btn-sm bg-light text-green ">
                                          I'll do it later
                                        </button>
                                      </>
                                    )}
                                </div>
                              </div>
                            </DropdownItem>
                          )
                        } else if (assignment.module === "assessment") {
                          let assessment = assignment
                          const submittedStudents =
                            assessment.data &&
                            assessment.data[i] &&
                            assessment.data[i].quizSubmissions
                              ? assessment.data[i].quizSubmissions.filter(
                                  item => item.submitted == 1
                                )
                              : []
                          const remainingStudentCount = submittedStudents
                            ? submittedStudents.length - 1
                            : 0
                          return (
                            <DropdownItem
                              key={i}
                              onClick={() => {
                                this.setState({ isOpenToggle: false })
                                assignment.data.forEach(el => {
                                  this.props.updateTeacherMarkAsReadAssignment(
                                    { notificationId: el.notificationId },
                                    () => {
                                      this.props.getNotificationList(
                                        (success, response) => {
                                          if (success && response.data) {
                                            this.setState({
                                              notificationList: response.data,
                                            })
                                          }
                                        }
                                      )
                                    }
                                  )
                                })
                                this.setState({
                                  notificationList: this.state.notificationList.filter(
                                    item => item.id !== assessment.id
                                  ),
                                })

                                this.props.history.push({
                                  pathname:
                                    URL_PATH.TEACHER_ASSESSMENT_MARK_ENTRY,
                                  state: { quizAssignInfoId: assessment.id },
                                })
                              }}
                              tag="div"
                              className="px-2 mx-0 my-4 bg-white shadow br-10 d-flex w-100 text-light align-items-center"
                              style={{ minWidth: "500px" }}
                            >
                              <div
                                className="shadow bg-theme-light br-10"
                                style={{
                                  height: "60px",
                                  width: "60px",
                                  padding: "8px",
                                }}
                              >
                                <img
                                  src={require("../../assets/images/assignmentNotification.png")}
                                  style={{
                                    objectFit: "contain",
                                    objectPosition: "center",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  alt=""
                                />
                              </div>
                              <div
                                className="ml-3 text-dark"
                                style={{ flex: 1 }}
                              >
                                <div className="d-flex align-items-center">
                                  <div
                                    className="firstLetterUpper"
                                    style={{
                                      fontSize: "0.85rem",
                                      color: "#094353",
                                      fontWeight: "bold",
                                      textTransform: "lowercase",
                                    }}
                                  >
                                    {assessment.data &&
                                    assessment.data[i] &&
                                    assessment.data[i].submissionType &&
                                    assessment.data[i].submissionType ===
                                      "ONLINE"
                                      ? "Online Test"
                                      : "Inclass Test"}
                                  </div>
                                  <div
                                    className="ml-auto text-muted"
                                    style={{ fontSize: "0.8rem" }}
                                  >
                                    {moment().to(assignment.updatedOn)}
                                  </div>
                                </div>
                                <div
                                  className="my-1"
                                  style={{ fontSize: "0.9rem" }}
                                >
                                  {submittedStudents != null &&
                                  submittedStudents.length > 0
                                    ? submittedStudents[0].student.fullName
                                    : ""}
                                  {assessment.data &&
                                  submittedStudents.length > 1
                                    ? " & " + remainingStudentCount + " other"
                                    : ""}{" "}
                                  submitted the test{" "}
                                  <b>
                                    {assessment.data &&
                                      assessment.data[i] &&
                                      assessment.data[i].subject &&
                                      assessment.data[i].subject
                                        .subjectName}{" "}
                                    -{" "}
                                    {assessment.data &&
                                    assessment.data[i] &&
                                    assessment.data[i].quizSet &&
                                    assessment.data[i].quizSet &&
                                    assessment.data[i].quizSet.title &&
                                    assessment.data[i].quizSet.title.length < 25
                                      ? assessment.data[i].quizSet.title
                                      : assessment.data[i].quizSet?.title &&
                                        assessment.data[
                                          i
                                        ].quizSet.title.substring(0, 25) +
                                          "...."}
                                  </b>
                                </div>
                                {/*<div className="mb-1 d-flex align-items-center">
                                     <div
                                       className="mr-auto text-muted"
                                       style={{ fontSize: "0.85rem" }}
                                     >
                                       dsdsdlkajkdla
                                     </div>
                                   </div>*/}
                              </div>
                            </DropdownItem>
                          )
                        }
                      })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
              <li>
                <UncontrolledDropdown>
                  <DropdownToggle tag="a" className="nav-link">
                    <img
                      className="rounded-circle"
                      src={
                        store.get("quickInfo") &&
                        store.get("quickInfo").teacherGender === "F"
                          ? femalePic
                          : malePic
                      }
                      alt="Profile"
                      width={32}
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    {/* <DropdownItem header>Settings</DropdownItem> */}
                    <DropdownItem
                      onClick={() =>
                        this.props.history.push(URL_PATH.TEACHER_PROFILE)
                      }
                    >
                      Profile
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        this.props.history.push(URL_PATH.TEACHER_CHANGEPASSWORD)
                      }}
                    >
                      Change Password
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => logout()}>Logout</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          </nav>
        </header>
        {/* Overlay Navbar for tab and mobile */}
        <div id="overlayNav" className="overlay d-none">
          <span
            className="closebtn cursor-pointer"
            onClick={() =>
              (document.getElementById("overlayNav").style.width = "0%")
            }
          >
            &times;
          </span>
          <div className="overlay-content">
            <div className="app-sidebar__inner">
              <ul className="vertical-nav-menu text-light">
                <li
                  // className="mt-4"
                  className={
                    URL_PATH.TEACHER === window.location.pathname ||
                    URL_PATH.TEACHER_PROFILE === window.location.pathname
                      ? "menu-active"
                      : ""
                  }
                >
                  <Link to={URL_PATH.TEACHER}>Dashboard</Link>
                </li>
                <li
                  className={
                    URL_PATH.TEACHER_STUDENT_RECORD ===
                      window.location.pathname ||
                    URL_PATH.TEACHER_STUDENT_PROFILE ===
                      window.location.pathname ||
                    URL_PATH.TEACHER_STUDENT_FORM_DETAILS ===
                      window.location.pathname
                      ? "menu-active"
                      : ""
                  }
                >
                  <Link to={URL_PATH.TEACHER_STUDENT_RECORD}>RECORD</Link>
                </li>
                <li
                  className={
                    URL_PATH.TEACHER_ATTENDANCE === window.location.pathname
                      ? "menu-active"
                      : ""
                  }
                >
                  <Link to={URL_PATH.TEACHER_ATTENDANCE}>Attendance</Link>
                </li>
                <li
                  className={
                    URL_PATH.TEACHER_GRADEBOOK === window.location.pathname
                      ? "menu-active"
                      : ""
                  }
                >
                  <Link to={URL_PATH.TEACHER_GRADEBOOK}>Gradebook</Link>
                </li>
                <li
                  className={
                    URL_PATH.TEACHER_PERFORMANCE_REPORT ===
                    window.location.pathname
                      ? "menu-active"
                      : ""
                  }
                >
                  <Link to={URL_PATH.TEACHER_PERFORMANCE_REPORT}>
                    Performance Report
                  </Link>
                </li>
                {/* <li>
                        <Link
                        to={URL_PATH.TEACHER_NEWS_EVENTS}
                        className={
                            URL_PATH.TEACHER_NEWS_EVENTS === window.location.pathname
                            ? "menu-active"
                            : ""
                        }
                        >
                        News & Events
                        </Link>
                    </li> */}
                {/* <li>
                   <Link
                     to={URL_PATH.TEACHER_COMMUNICATION_DASHBOARD}
                     className={
                       URL_PATH.TEACHER_COMMUNICATION_DASHBOARD ===
                         window.location.pathname
                         ? "menu-active"
                         : ""
                     }
                   >
                     Communication
                   </Link>
                 </li> */}
                {/* <li>
                    <Link
                      to={URL_PATH.TEACHER_CLASS_ROOM_SCREEN}
                      className={
                        URL_PATH.TEACHER_CLASS_ROOM_SCREEN === window.location.pathname || URL_PATH.TEACHER_VIDEO_CALL_SCREEN === window.location.pathname
                          ? "menu-active"
                          : ""
                      }
                    >
                      Online Class Room
                    </Link>
                  </li> */}
              </ul>
            </div>
          </div>
        </div>
        {/* Overlay Navbar for tab and mobile ends*/}

        {/* Overlay Navbar for pc starts*/}
        <aside className="aside-left">
          <nav className="menu d-flex flex-column justify-content-between">
            {this.props.teacherDashboard && (
              <ul>
                <li>
                  <Link
                    to={URL_PATH.TEACHER}
                    className="d-flex flex-column justify-content-between"
                  >
                    <img
                      className="img-fluid"
                      src={redinkLogo}
                      alt="Redink"
                      width="40"
                      height="40"
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    to={URL_PATH.TEACHER}
                    className={
                      URL_PATH.TEACHER === window.location.pathname ||
                      URL_PATH.TEACHER_PROFILE === window.location.pathname ||
                      URL_PATH.TEACHER_CHANGEPASSWORD ===
                        window.location.pathname
                        ? "menu-active"
                        : ""
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-dashboard"
                      width="26"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#bdc7cb"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <circle cx="12" cy="13" r="2" />
                      <line x1="13.45" y1="11.55" x2="15.5" y2="9.5" />
                      <path d="M6.4 20a9 9 0 1 1 11.2 0Z" />
                    </svg>
                    <span>Dashboard</span>
                  </Link>
                </li>

                {store.get("userSession").userInfo.role !== 4 && (
                  <li>
                    <Link
                      className={
                        URL_PATH.TEACHER_STUDENT_RECORD ===
                          window.location.pathname ||
                        URL_PATH.TEACHER_STUDENT_PROFILE ===
                          window.location.pathname ||
                        URL_PATH.TEACHER_STUDENT_FORM_DETAILS ===
                          window.location.pathname
                          ? "menu-active"
                          : ""
                      }
                      to={URL_PATH.TEACHER_STUDENT_RECORD}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-users"
                        width="22"
                        height="22"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#bdc7cb"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <circle cx="9" cy="7" r="4" />
                        <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                        <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                        <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                      </svg>
                      <span>Students</span>
                    </Link>
                  </li>
                )}

                {store.get("userSession").userInfo.role !== 4 && (
                  <li className="sidelink">
                    <div
                      className={
                        URL_PATH.TEACHER_SUBJECT_DETAILS ===
                          window.location.pathname ||
                        URL_PATH.TEACHER_SUBJECT_ALLOTMENT ===
                          window.location.pathname
                          ? "menu-active"
                          : ""
                      }
                      style={{ padding: "9px 0px 4px 0px" }}
                    >
                      <UncontrolledDropdown
                        direction="right"
                        className="attendance_hover"
                        onMouseOver={this.onMouseSubjectEnter}
                        onMouseLeave={this.onMouseSubjectLeave}
                        isOpen={this.state.dropdownSubjectOpen}
                      >
                        <DropdownToggle>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-book"
                            width="24"
                            height="30"
                            viewBox="0 0 16 28"
                            strokeWidth="1.5"
                            stroke="#bdc7cb"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <path d="M3 19a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />
                            <path d="M3 6a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />
                            <line x1="3" y1="6" x2="3" y2="19" />
                            <line x1="12" y1="6" x2="12" y2="19" />
                            <line x1="21" y1="6" x2="21" y2="19" />
                          </svg>
                        </DropdownToggle>
                        <DropdownMenu className="p-0 ml-3 sidenav-menu">
                          <div className="text-white card-header justify-content-center">
                            <h5>Subject </h5>
                          </div>
                          <DropdownItem className="p-0">
                            <Link
                              className="dropdown-item sidenav-item"
                              to={URL_PATH.TEACHER_SUBJECT_DETAILS}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-clipboard-check"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="#bdc7cb"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <path d="M9 5H7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2V7a2 2 0 0 0 -2 -2h-2" />
                                <rect x="9" y="3" width="6" height="4" rx="2" />
                                <path d="M9 14l2 2l4 -4" />
                              </svg>
                              <p className="pt-2 pl-2 text-white h6">
                                Subject Details
                              </p>
                            </Link>
                          </DropdownItem>
                          <DropdownItem className="p-0">
                            <Link
                              className="dropdown-item sidenav-item"
                              to={URL_PATH.TEACHER_SUBJECT_ALLOTMENT}
                              style={{ backgroundColor: "black" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-chart-pie"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="#bdc7cb"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <path d="M10 3.2a9 9 0 1 0 10.8 10.8a1 1 0 0 0 -1 -1h-6.8a2 2 0 0 1 -2 -2v-7a.9 .9 0 0 0 -1 -.8" />
                                <path d="M15 3.5a9 9 0 0 1 5.5 5.5h-4.5a1 1 0 0 1 -1 -1v-4.5" />
                              </svg>
                              <p className="pt-2 pl-2 text-white h6">
                                Subject Allotment
                              </p>
                            </Link>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </li>
                )}

                {/*{ store.get("userSession").userInfo.role !== 4 ? (*/}
                {store.get("userSession") &&
                  store.get("userSession").userInfo &&
                  store.get("userSession").userInfo.isAttendanceActive && (
                    <li className="sidelink">
                      <div
                        className={
                          URL_PATH.TEACHER_ATTENDANCE ===
                            window.location.pathname ||
                          URL_PATH.TEACHER_ATTENDANCE_REPORT ===
                            window.location.pathname
                            ? "menu-active"
                            : ""
                        }
                        style={{ padding: "9px 0px 4px 0px" }}
                      >
                        <UncontrolledDropdown
                          direction="right"
                          className="attendance_hover"
                          onMouseOver={this.onMouseEnter}
                          onMouseLeave={this.onMouseLeave}
                          isOpen={this.state.dropdownOpen}
                        >
                          <DropdownToggle>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon icon-tabler icon-tabler-checks"
                              width="24"
                              height="30"
                              viewBox="0 0 16 28"
                              strokeWidth="1.5"
                              stroke="#bdc7cb"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" />
                              <path d="M7 12l5 5l10 -10" />
                              <path d="M2 12l5 5m5 -5l5 -5" />
                            </svg>
                          </DropdownToggle>
                          <DropdownMenu className="p-0 ml-3 sidenav-menu">
                            <div className="text-white card-header justify-content-center">
                              <h5>Attendance</h5>
                            </div>
                            <DropdownItem className="p-0">
                              <Link
                                className="dropdown-item sidenav-item"
                                to={URL_PATH.TEACHER_ATTENDANCE}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon icon-tabler icon-tabler-clipboard-check"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="#bdc7cb"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path stroke="none" d="M0 0h24v24H0z" />
                                  <path d="M9 5H7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2V7a2 2 0 0 0 -2 -2h-2" />
                                  <rect
                                    x="9"
                                    y="3"
                                    width="6"
                                    height="4"
                                    rx="2"
                                  />
                                  <path d="M9 14l2 2l4 -4" />
                                </svg>
                                <p className="pt-2 pl-2 text-white h6">
                                  Take Attendance
                                </p>
                              </Link>
                            </DropdownItem>
                            <DropdownItem className="p-0">
                              <Link
                                className="dropdown-item sidenav-item"
                                to={URL_PATH.TEACHER_ATTENDANCE_REPORT}
                                style={{ backgroundColor: "black" }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon icon-tabler icon-tabler-chart-pie"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="#bdc7cb"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path stroke="none" d="M0 0h24v24H0z" />
                                  <path d="M10 3.2a9 9 0 1 0 10.8 10.8a1 1 0 0 0 -1 -1h-6.8a2 2 0 0 1 -2 -2v-7a.9 .9 0 0 0 -1 -.8" />
                                  <path d="M15 3.5a9 9 0 0 1 5.5 5.5h-4.5a1 1 0 0 1 -1 -1v-4.5" />
                                </svg>
                                <p className="pt-2 pl-2 text-white h6">
                                  Attendance Report
                                </p>
                              </Link>
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </li>
                  )}

                {/* <li>
                      <Link
                        to={URL_PATH.TEACHER_ATTENDANCE}
                        className={
                          URL_PATH.TEACHER_ATTENDANCE === window.location.pathname
                            ? "menu-active"
                            : ""
                        }
                      >
                        <i className="metismenu-icon far fa-file-chart-line" />
                        <span>Attendance</span>
                      </Link>
                    </li> */}

                {store.get("userSession") &&
                  store.get("userSession").userInfo &&
                  store.get("userSession").userInfo.isAcademicActive && (
                    <>
                      <li>
                        <Link
                          to={URL_PATH.TEACHER_GRADEBOOK}
                          className={
                            URL_PATH.TEACHER_GRADEBOOK ===
                            window.location.pathname
                              ? "menu-active"
                              : ""
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-sitemap"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#bdc7cb"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <rect x="3" y="15" width="6" height="6" rx="2" />
                            <rect x="15" y="15" width="6" height="6" rx="2" />
                            <rect x="9" y="3" width="6" height="6" rx="2" />
                            <path d="M6 15v-1a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v1" />
                            <line x1="12" y1="9" x2="12" y2="12" />
                          </svg>
                          <span>Gradebook</span>
                        </Link>
                      </li>
                      {store.get("userSession").userInfo.role !== 4 && (
                        <li>
                          <Link
                            to={URL_PATH.TEACHER_PERFORMANCE_REPORT}
                            className={
                              URL_PATH.TEACHER_PERFORMANCE_REPORT ===
                              window.location.pathname
                                ? "menu-active"
                                : ""
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon icon-tabler icon-tabler-chart-donut"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="#bdc7cb"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" />
                              <path d="M10 3.2a9 9 0 1 0 10.8 10.8a1 1 0 0 0 -1 -1h-3.8a4.1 4.1 0 1 1 -5 -5v-4a.9 .9 0 0 0 -1 -.8" />
                              <path d="M15 3.5a9 9 0 0 1 5.5 5.5h-4.5a9 9 0 0 0 -1 -1v-4.5" />
                            </svg>
                            <span>Performance Report</span>
                          </Link>
                        </li>
                      )}
                    </>
                  )}

                {/* <li>
                        <Link
                        to={URL_PATH.TEACHER_NEWS_EVENTS}
                        className={
                            URL_PATH.TEACHER_NEWS_EVENTS === window.location.pathname
                            ? "menu-active"
                            : ""
                        }
                        >
                        <i className="metismenu-icon far fa-rss-square" />
                        <span>News & Events</span>
                        </Link>
                    </li> */}
                {/* {store.get("userSession") &&
                    store.get("userSession").userInfo &&
                    store.get("userSession").userInfo.isTaskActive ? (
                      <li>
                        <Link
                          to={URL_PATH.TEACHER_TASK_MANAGEMENT_SCREEN}
                          className={
                            URL_PATH.TEACHER_TASK_MANAGEMENT_SCREEN ===
                            window.location.pathname
                              ? "menu-active"
                              : ""
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-square-check"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#bdc7cb"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <rect x="4" y="4" width="16" height="16" rx="2" />
                            <path d="M9 12l2 2l4 -4" />
                          </svg>
                          <span>Task Management</span>
                        </Link>
                      </li>
                    ) : null} */}

                {/* {
                     store.get("userSession") &&
                     store.get("userSession").userInfo &&
                     store.get("userSession").userInfo.isCommunicationActive &&
                     <li>
                       <Link
                         to={URL_PATH.TEACHER_COMMUNICATION_DASHBOARD}
                         className={
                           URL_PATH.TEACHER_COMMUNICATION_DASHBOARD ===
                             window.location.pathname
                             ? "menu-active"
                             : ""
                         }
                       >
                         <svg
                           xmlns="http://www.w3.org/2000/svg"
                           className="icon icon-tabler icon-tabler-message"
                           width="24"
                           height="24"
                           viewBox="0 0 24 24"
                           strokeWidth="1.5"
                           stroke="#bdc7cb"
                           fill="none"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                         >
                           <path stroke="none" d="M0 0h24v24H0z" />
                           <path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4" />
                           <line x1="8" y1="9" x2="16" y2="9" />
                           <line x1="8" y1="13" x2="14" y2="13" />
                         </svg>
                         <span>Communication</span>
                       </Link>
                     </li>
                   } */}

                {store.get("userSession") &&
                  store.get("userSession").userInfo &&
                  store.get("userSession").userInfo.isBehaviourActive &&
                  this.state.showBehaviour && (
                    <li>
                      <Link
                        to={URL_PATH.TEACHER_BEHAVIOUR_DASHBOARD}
                        className={
                          URL_PATH.TEACHER_BEHAVIOUR_DASHBOARD ===
                          window.location.pathname
                            ? "menu-active"
                            : ""
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-mood-smile"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#bdc7cb"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <circle cx="12" cy="12" r="9" />
                          <line x1="9" y1="10" x2="9.01" y2="10" />
                          <line x1="15" y1="10" x2="15.01" y2="10" />
                          <path d="M9.5 15a3.5 3.5 0 0 0 5 0" />
                        </svg>
                        <span>Behaviour</span>
                      </Link>
                    </li>
                  )}

                {store.get("userSession") &&
                  store.get("userSession").userInfo &&
                  store.get("userSession").userInfo.isCommunicationActive && (
                    <li>
                      <Link
                        to={URL_PATH.TEACHER_ASSIGNMENT}
                        className={
                          URL_PATH.TEACHER_ASSIGNMENT ===
                            window.location.pathname ||
                          URL_PATH.TEACHER_ASSIGNMENT_DETAILS ===
                            window.location.pathname ||
                          URL_PATH.TEACHER_ASSIGNMENT_VIEW_DETAILS ===
                            window.location.pathname
                            ? "menu-active"
                            : ""
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-file-invoice"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#bdc7cb"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                          <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                          <line x1="9" y1="7" x2="10" y2="7" />
                          <line x1="9" y1="13" x2="15" y2="13" />
                          <line x1="13" y1="17" x2="15" y2="17" />
                        </svg>
                        <span>Assignment</span>
                      </Link>
                    </li>
                  )}

                {PATH_URL.showAssessment && (
                  <li>
                    <Link
                      className={
                        URL_PATH.TEACHER_TESTS === window.location.pathname
                          ? "menu-active"
                          : ""
                      }
                      to={URL_PATH.TEACHER_TESTS}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-clipboard-check"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#bdc7cb"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <path d="M9 5H7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2V7a2 2 0 0 0 -2 -2h-2" />
                        <rect x="9" y="3" width="6" height="4" rx="2" />
                        <path d="M9 14l2 2l4 -4" />
                      </svg>
                      <span>Tests</span>
                    </Link>
                  </li>
                )}

                {PATH_URL.showSchedule && (
                  <li>
                    <Link
                      className={
                        URL_PATH.TEACHER_SCHEDULE ===
                          window.location.pathname ||
                        URL_PATH.TEACHER_CREATE_SCHEDULE ===
                          window.location.pathname
                          ? "menu-active"
                          : ""
                      }
                      to={URL_PATH.TEACHER_SCHEDULE}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-calendar-time"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#bdc7cb"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4" />
                        <circle cx="18" cy="18" r="4" />
                        <path d="M15 3v4" />
                        <path d="M7 3v4" />
                        <path d="M3 11h16" />
                        <path d="M18 16.496v1.504l1 1" />
                      </svg>
                      <span>Schedule</span>
                    </Link>
                  </li>
                )}

                {/* <li>
                    <Link
                      to={URL_PATH.TEACHER_CLASS_ROOM_SCREEN}
                      className={
                        URL_PATH.TEACHER_CLASS_ROOM_SCREEN === window.location.pathname || URL_PATH.TEACHER_VIDEO_CALL_SCREEN === window.location.pathname
                          ? "menu-active"
                          : ""
                      }
                    >
                      <i className="metismenu-icon far fa-users" />
                      <span>Online Class Room</span>
                    </Link>
                  </li> */}
              </ul>
            )}

            {this.props.sideMenuCollapsed && (
              <ul>
                <li>
                  <Link
                    to={URL_PATH.TEACHER}
                    className={`d-flex flex-column justify-content-between`}
                  >
                    <img
                      className="img-fluid"
                      src={redinkLogo}
                      alt="Redink"
                      width="40"
                      height="40"
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    to={URL_PATH.TEACHER}
                    onClick={() => this.props.gradeBookLoaded(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-arrow-left-circle"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#bdc7cb"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <circle cx="12" cy="12" r="9" />
                      <line x1="8" y1="12" x2="16" y2="12" />
                      <line x1="8" y1="12" x2="12" y2="16" />
                      <line x1="8" y1="12" x2="12" y2="8" />
                    </svg>
                    <span>Back to Dashboard</span>
                  </Link>
                </li>
              </ul>
            )}

            {this.props.sideMenuCollapsedBackExam && (
              <ul>
                <li>
                  <Link
                    to={URL_PATH.TEACHER}
                    className="d-flex flex-column justify-content-between"
                  >
                    <img
                      className="img-fluid"
                      src={redinkLogo}
                      alt="Redink"
                      width="40"
                      height="40"
                    />
                  </Link>
                </li>
                <li>
                  <Link to={URL_PATH.TEACHER_GRADEBOOK}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-arrow-left-circle"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#bdc7cb"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <circle cx="12" cy="12" r="9" />
                      <line x1="8" y1="12" x2="16" y2="12" />
                      <line x1="8" y1="12" x2="12" y2="16" />
                      <line x1="8" y1="12" x2="12" y2="8" />
                    </svg>
                    <span>Back to Gradebook</span>
                  </Link>
                </li>
              </ul>
            )}

            {/* {this.props.teacherAttendanceDashboard ? (
                  <ul>
                    <li>
                      <Link
                        to={URL_PATH.TEACHER}
                        className={`d-flex flex-column justify-content-between`}
                      >
                        <img
                          className="img-fluid"
                          src={redinkLogo}
                          alt="Redink"
                          width="40"
                          height="40"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to={URL_PATH.TEACHER}>
                        <i className="metismenu-icon far fa-arrow-circle-left" />
                        <span>Back to Dashboard</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={URL_PATH.TEACHER_ATTENDANCE}
                        className={
                          URL_PATH.TEACHER_ATTENDANCE === window.location.pathname
                            ? "menu-active"
                            : ""
                        }
                      >
                        <i className="metismenu-icon far fa-clipboard-user" />
                        <span>Take Attendance</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={URL_PATH.TEACHER_ATTENDANCE_REPORT}
                        className={
                          URL_PATH.TEACHER_ATTENDANCE_REPORT ===
                          window.location.pathname
                            ? "menu-active"
                            : ""
                        }
                      >
                        <i className="metismenu-icon far fa-chart-pie" />
                        <span>Attendance Report</span>
                      </Link>
                    </li>
                  </ul>
                ) : null} */}
          </nav>
        </aside>

        <div
          className={`app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header closed-sidebar ${
            this.state.toggleMobileSidemenu ? "sidebar-mobile-open" : ""
          }`}
        >
          <div className="bg-white app-main">
            <div className="app-main__outer">
              <div className="app-main__inner">{this.props.children}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getNotificationList,
      updateTeacherMarkAsReadAssignment,
      getAssignmentAssignedDetails,
      clearNotification,
      gradeBookLoaded,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(withRouter(Wrapper))
