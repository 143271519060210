/*************************************************
 * RedInk
 * @exports
 * @class index.js
 * @extends Component
 * @author Sasidharan // on 01/02/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import AppWrapper from "./Wrapper"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { myLog } from "../../utils/Utility"
import { changePassword } from "../../actions/LoginAction"
import { VALIDATION_MSG, DANGER_TOAST } from "../../utils/Constants"
import { showNotification } from "../../actions/CommonAction"
class ChangePasswordScreen extends Component {
  constructor(props) {
    super(props)
    document.title = "Change Password | Redink"
    this.state = {
      newPassword: "",
      confirmPassword: "",
    }
  }

  componentDidMount = () => {}

  handleChange = event => {
    const { name, value } = event.target
    this.setState({
      [name]: value,
    })
  }

  render() {
    return (
      <AppWrapper founderDashboard>
        <div className="row align-items-center h-75">
          <div className="card offset-sm-2 mt-sm-5 p-5 col-sm-8">
            <div className="card-title ml-5 mt-3 text-dark ">
              <h2 className="text-dark">Change your Password</h2>
            </div>
            <div className="card-body row justify-content-around">
              <form className="form col-sm-6">
                <div className="form-group">
                  <label>New Password</label>
                  <input
                    type="password"
                    className="form-control mb-4"
                    name="newPassword"
                    onChange={this.handleChange}
                    value={this.state.newPassword}
                  />
                </div>
                <div className="form-group">
                  <label>Confirm New Password</label>
                  <input
                    type="password"
                    className="form-control"
                    name="confirmNewPassword"
                    onChange={this.handleChange}
                    value={this.state.confirmNewPassword}
                  />
                </div>
                <button
                  type="button"
                  className="btn mt-4 bg-theme text-white btn-block"
                  onClick={() => {
                    myLog(this.state.newPassword, this.state.confirmNewPassword)
                    if (this.state.newPassword.trim() !== "") {
                      if (
                        this.state.newPassword === this.state.confirmNewPassword
                      ) {
                        this.props.changePassword(
                          this.state.newPassword,
                          (success, response) => {
                            if (success) {
                              this.setState({
                                newPassword: "",
                                confirmNewPassword: "",
                              })
                            }
                          }
                        )
                      } else {
                        this.props.showNotification(
                          DANGER_TOAST,
                          VALIDATION_MSG.OOPS,
                          "Password don't match"
                        )
                      }
                    } else {
                      this.props.showNotification(
                        DANGER_TOAST,
                        VALIDATION_MSG.OOPS,
                        "Fill all fields"
                      )
                    }
                  }}
                >
                  Change my password
                </button>
              </form>
              <div className="mt-3 col-sm-4">
                <p>
                  <strong>Password must contain</strong>
                </p>
                <p>Atleast 1 upper case letter (A-Z)</p>
                <p>At least 1 special character</p>
                <p>At least 1 number (0-9)</p>
                <p>At least 8 characters</p>
              </div>
            </div>
          </div>
        </div>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      changePassword,
      showNotification,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(ChangePasswordScreen)
