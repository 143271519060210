import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"

export const getClassDetails = async (object, callBack) => {
  try {
    const response = await Client.get(
      URL.GET_CLASSES_IN_QUEST_PAPER_GEN,
      object,
      true,
      true
    )
    if (response) {
      myLog("====getClassDetails RESPONSE===::::", response)
      callBack(true, response.data)
    } else {
      callBack(false)
    }
  } catch (error) {
    callBack(false)
    myLog("====getClassDetails ERROR===????", error)
  }
}

export const getSubjectsChaptersTopics = async (object, callBack) => {
  try {
    const response = await Client.get(
      URL.GET_SUBJECT_CHAPTERS_TOPICS,
      object,
      true,
      true
    )
    if (response) {
      myLog("====getChapterTopics RESPONSE===::::", response)
      if (callBack) callBack(true, response.data)
    } else {
      if (callBack) callBack(false)
    }
  } catch (error) {
    if (callBack) callBack(false)
    myLog("====getSubjectMasterList ERROR===????", error)
  }
}

export const createQp = async (body, callBack) => {
  try {
    const response = await Client.post(URL.ADMIN_CREATE_QP, body, true, true)
    if (response) {
      myLog("====createQp RESPONSE===::::", response)
      callBack(true, response.data)
    } else {
      callBack(false)
    }
  } catch (error) {
    callBack(false)
    myLog("====createQp ERROR===????", error)
  }
}

export const getQpList = async (query, callBack) => {
  try {
    const response = await Client.get(
      URL.ADMIN_GET_QP_LIST + query,
      {},
      true,
      true
    )
    if (response) {
      myLog("====getQpList RESPONSE===::::", response)
      callBack(true, response.data)
    } else {
      callBack(false)
    }
  } catch (error) {
    callBack(false)
    myLog("====getQpList ERROR===????", error)
  }
}
