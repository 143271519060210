/*************************************************
 * RedInk
 * @exports
 * @class AdminDetails.js
 * @extends Component
 * @author Pathetic Geek // on 04/10/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"

/**
 * Returns admin details in callback
 * @param {{ tentantId: string }} options Pass in tentantId
 * @param {Function} callBack Returns the data, null if has error
 */
export const getAdminDetails = (options, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        `${URL.GET_ADMIN_DETAILS}?tentantId=${options.tentantId}`,
        null,
        true,
        true
      )
      myLog("hahaha", response)
      if (response) {
        myLog("====getAdminDetails RESPONSE===::::", response)
        callBack(true, response.data)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getAdminDetails ERROR===????", error)
    }
  }
}
