/*************************************************
 * RedInk
 * @exports
 * @class SubjectsScreen.js
 * @extends Component
 * @author Sasidharan // on 06/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { UncontrolledTooltip } from "reactstrap"

import AssesmentDetailsScreen from "./AssesmentDetailsScreen"
import TermDetailsScreen from "./TermDetailsScreen"
import AcademicTab from "./AcademicTab"
import AppWrapper from "../../Wrapper1"

class AcademicDetailsScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTab:
        this.props.location.state && this.props.location.state.selectTab
          ? this.props.location.state.selectTab
          : "ACADEMIC",
    }
  }

  render() {
    return (
      <AppWrapper
        showAdminProfileSidemenu
        showAdminBackToDashboard
        screenName={
          this.state.selectedTab === "ACADEMIC"
            ? "Academic Details"
            : this.state.selectedTab === "ASSESSMENT"
            ? "Assessment Details"
            : "Term Details"
        }
      >
        <div className="d-flex align-items-center justify-content-center mb-2">
          <div className="top-tab-pills">
            <button
              onClick={() => this.setState({ selectedTab: "ACADEMIC" })}
              className={`btn btn-sm ${this.state.selectedTab === "ACADEMIC" &&
                "active"}`}
              id="Academic"
            >
              <UncontrolledTooltip
                placement="top"
                className="bg-danger"
                target="Academic"
              >
                View Academic years
              </UncontrolledTooltip>
              Academic
            </button>
            <button
              onClick={() => this.setState({ selectedTab: "ASSESSMENT" })}
              className={`btn btn-sm ${this.state.selectedTab ===
                "ASSESSMENT" && "active"}`}
              id="assessment-tooltip"
            >
              <UncontrolledTooltip
                placement="top"
                className="bg-danger"
                target="assessment-tooltip"
              >
                View Assessments
              </UncontrolledTooltip>
              Assessment
            </button>
            <button
              onClick={() => this.setState({ selectedTab: "TERM" })}
              className={`btn btn-sm ${this.state.selectedTab === "TERM" &&
                "active"}`}
              id="term-tooltip"
            >
              <UncontrolledTooltip
                placement="top"
                className="bg-danger"
                target="term-tooltip"
              >
                View Terms
              </UncontrolledTooltip>
              Term
            </button>
          </div>
        </div>

        {this.state.selectedTab === "ASSESSMENT" && <AssesmentDetailsScreen />}
        {this.state.selectedTab === "ACADEMIC" && <AcademicTab />}
        {this.state.selectedTab === "TERM" && <TermDetailsScreen />}
      </AppWrapper>
    )
  }
}

export default AcademicDetailsScreen
