import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import moment from "moment"

import {
  showNotification,
  getCheckEmailAvailableDetails,
  getQuickInfoDetails,
} from "../../../../../actions/CommonAction"
import { DANGER_TOAST, VALIDATION_MSG } from "../../../../../utils/Constants"
import { saveStudentDatabaseDetails } from "../../../../../actions/teacher/StudentDatabaseAction"
import { validateEmail } from "../../../../../utils/Utility"
import { getClasses } from "../../../../../actions/admin/UpgradeAcademicYear"

function AddNewStudent({ onSuccess }) {
  const [classesList, setClassesList] = useState([])
  const [details, setDetails] = useState({
    studentImage: "",
    name: "",
    gender: "",
    dob: "",
    registerNumber: "",
    parentsList: "",
    firstName: "",
    lastName: "",
    studentEmail: "",
    studentPhone: "",
    admissionNumber: "",
    dateOfJoining: "",
    isRollNumberExist: false,
    primaryParent: "",
    fatherFirstName: "",
    fatherLastName: "",
    fatherEmail: "",
    fatherPhone: "",
    motherFirstName: "",
    motherLastName: "",
    motherEmail: "",
    motherPhone: "",
    legalGuardianEmail: "",
    legalGuardianFirstName: "",
    legalGuardianLastName: "",
    legalGuardianPhone: "",
    localGuardianEmail: "",
    localGuardianFirstName: "",
    localGuardianLastName: "",
    localGuardianPhone: "",
    sectionId: "",
  })

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      getQuickInfoDetails((success, res) => {
        if (success) {
          getClasses({ academicId: res.activeAcademicId }, (success2, res2) => {
            if (success2) {
              setClassesList(res2)
            }
          })
        }
      })
    )
  }, [dispatch])

  function dangerToast(msg = "") {
    dispatch(showNotification(DANGER_TOAST, VALIDATION_MSG.SUCCESS, msg))
  }

  const saveAddNext = () => {
    if (
      !details.sectionId ||
      !details.firstName ||
      !details.studentPhone ||
      !details.dob ||
      !details.gender
    ) {
      dangerToast("Fill out required fields")
      return
    }

    if (details.studentPhone) {
      let len = String(details.studentPhone).length
      if (len < 10 || len >= 15) {
        dangerToast("Student Phone number invalid")
        return
      }
    }

    if (details.dateOfJoining > moment(new Date()).format("YYYY-MM-DD")) {
      dangerToast("Date of joining greater than today")
      return
    }

    if (details.dob >= moment(new Date()).format("YYYY-MM-DD")) {
      dangerToast("Date of birth Invalid")
      return
    }

    if (details.fatherEmail) {
      if (!validateEmail(details.fatherEmail)) {
        dangerToast("Father mail id invalid")
        return
      }
    }

    if (details.fatherPhone) {
      let len = String(details.fatherPhone).length
      if (len < 10 || len > 15) {
        dangerToast("Father Phone number invalid")
        return
      }
    }

    if (details.motherEmail) {
      if (!validateEmail(details.motherEmail)) {
        dangerToast("Mother mail id invalid")
        return
      }
    }

    if (details.motherPhone) {
      let len = String(details.motherPhone).length
      if (len < 10 || len > 15) {
        dangerToast("Mother Phone number invalid")
        return
      }
    }

    if (details.legalGuardianEmail) {
      if (!validateEmail(details.legalGuardianEmail)) {
        dangerToast("Legal Guardian mail id invalid")
        return
      }
    }

    if (details.legalGuardianPhone) {
      let len = String(details.legalGuardianPhone).length
      if (len < 10 || len >= 15) {
        dangerToast("Legal Gaurdian Phone number invalid")
        return
      }
    }

    if (details.localGuardianEmail) {
      if (!validateEmail(details.localGuardianEmail)) {
        dangerToast("Local Guardian mail id invalid")
        return
      }
    }

    if (details.localGuardianPhone) {
      let len = String(details.localGuardianPhone).length
      if (len < 10 || len >= 15) {
        dangerToast("Local Guardian Phone number invalid")
        return
      }
    }

    let object = {
      sectionId: details.sectionId,
      firstName: details.firstName,
      lastName: details.lastName || null,
      studentEmail: details.studentEmail || null,
      studentPhone: details.studentPhone || null,
      admissionNumber: details.admissionNumber ?? null,
      registerNumber: details.registerNumber || "-",
      dateOfJoining: details.dateOfJoining || null,
      studentImage: details.studentImage,
      gender: details.gender,
      dob: details.dob,
      parentsList: [
        {
          id: null,
          fatherFirstName: details.fatherFirstName,
          fatherLastName: details.fatherLastName,
          fatherEmail: details.fatherEmail,
          fatherPhone: details.fatherPhone,
          motherFirstName: details.motherFirstName,
          motherLastName: details.motherLastName,
          motherEmail: details.motherEmail,
          motherPhone: details.motherPhone,
          legalGuardianEmail: details.legalGuardianEmail,
          legalGuardianFirstName: details.legalGuardianFirstName,
          legalGuardianLastName: details.legalGuardianLastName,
          legalGuardianPhone: details.legalGuardianPhone,
          localGuardianEmail: details.localGuardianEmail,
          localGuardianFirstName: details.localGuardianFirstName,
          localGuardianLastName: details.localGuardianLastName,
          localGuardianPhone: details.localGuardianPhone,
        },
      ],
    }

    if (details.primaryParent === "FATHER") {
      if (
        !details.fatherFirstName &&
        !details.fatherLastName &&
        !details.fatherEmail &&
        !details.fatherPhone
      ) {
        object.primaryParent = null
      }
    }
    if (details.primaryParent === "MOTHER") {
      if (
        !details.motherFirstName &&
        !details.motherLastName &&
        !details.motherEmail &&
        !details.motherPhone
      ) {
        object.primaryParent = null
      }
    }
    if (details.primaryParent === "LOG") {
      if (
        !details.localGuardianFirstName &&
        !details.localGuardianLastName &&
        !details.localGuardianEmail &&
        !details.localGuardianPhone
      ) {
        object.primaryParent = null
      }
    }
    if (details.primaryParent === "LEG") {
      if (
        !details.legalGuardianFirstName &&
        !details.legalGuardianLastName &&
        !details.localGuardianEmail &&
        !details.legalGuardianPhone
      ) {
        object.primaryParent = null
      }
    }

    dispatch(
      saveStudentDatabaseDetails(object, (success, res) => {
        if (success) {
          onSuccess()
        }
      })
    )
  }

  const gotoNextField = (e, id, withData = false) => {
    if (e.key === "Enter") {
      let canEmp = withData ? e.target.value : true
      if (canEmp) {
        let el = document.getElementById(id)
        if (el) {
          el.focus()
        }
      }
    }
  }

  const onChange = (key, val) => {
    setDetails(prev => ({
      ...prev,
      [key]: val,
    }))
  }

  return (
    <div className="add-student-in-upgrade-stu">
      <div className="row px-3">
        <div className="col-sm-9 p-0 pr-4 border-right">
          <div className="form-row">
            <div className="form-group col-sm">
              <small>
                First Name <span style={{ color: "red" }}>*</span>
              </small>
              <input
                id="admin-sp-stu-firstName"
                type="text"
                className="form-control"
                value={details.firstName}
                onChange={e => {
                  onChange(
                    "firstName",
                    e.target.value.replace(/[^A-Za-z ]/gi, "")
                  )
                }}
                onKeyDown={e => gotoNextField(e, "admin-sp-stu-lastName", true)}
              />
            </div>
            <div className="form-group col-sm">
              <small>Last Name</small>
              <input
                id="admin-sp-stu-lastName"
                type="text"
                className="form-control"
                value={details.lastName}
                onChange={e => {
                  onChange(
                    "lastName",
                    e.target.value.replace(/[^A-Za-z ]/gi, "")
                  )
                }}
                onKeyDown={e => gotoNextField(e, "admin-sp-stu-studentEmail")}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-sm">
              <small>Email Id</small>
              <input
                id="admin-sp-stu-studentEmail"
                type="text"
                className="form-control"
                value={details.studentEmail}
                pattern=""
                onChange={e => {
                  onChange("studentEmail", e.target.value)
                }}
                onKeyDown={e => gotoNextField(e, "admin-sp-stu-studentPhone")}
              />
            </div>
            <div className="form-group col-sm">
              <small>
                Mobile Number <span style={{ color: "red" }}>*</span>
              </small>
              <input
                id="admin-sp-stu-studentPhone"
                type="number"
                className="form-control"
                value={details.studentPhone}
                onChange={e => {
                  onChange(
                    "studentPhone",
                    e.target.value.replace(/[^0-9]/gi, "")
                  )
                }}
                onKeyDown={e =>
                  gotoNextField(e, "admin-sp-stu-registerNumber", true)
                }
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-sm">
              <small>Roll/Registration Number </small>
              <input
                id="admin-sp-stu-registerNumber"
                type="text"
                maxLength="20"
                className="form-control"
                value={details.registerNumber}
                onChange={e => {
                  setDetails(pr => ({ ...pr, isRollNumberExist: false }))
                  onChange("registerNumber", e.target.value)
                  getCheckEmailAvailableDetails(
                    e.target.value,
                    (success, response) => {
                      if (response) {
                        setDetails(pr => ({ ...pr, isRollNumberExist: true }))
                      }
                    }
                  )
                }}
                onKeyDown={e =>
                  gotoNextField(e, "admin-sp-stu-admissionNumber")
                }
              />
            </div>
            <div className="form-group col-sm">
              <small>Admission No.</small>
              <input
                id="admin-sp-stu-admissionNumber"
                type="text"
                className="form-control"
                value={details.admissionNumber}
                onChange={e => {
                  if (e.target.value >= 0) {
                    onChange(
                      "admissionNumber",
                      e.target.value.replace(/[^A-Za-z0-9 ]/gi, "")
                    )
                  }
                }}
                onKeyDown={e => gotoNextField(e, "admin-sp-stu-dateOfJoining")}
              />
            </div>

            <div className="form-group col-sm">
              <small>Date of Admission</small>
              <input
                id="admin-sp-stu-dateOfJoining"
                max="9999-12-31"
                type="date"
                className="form-control unstyled"
                value={details.dateOfJoining}
                onChange={e => {
                  onChange("dateOfJoining", e.target.value)
                }}
                onKeyDown={e => gotoNextField(e, "admin-sp-stu-sectionId")}
              />
            </div>
          </div>
        </div>

        <div className="col-sm-3">
          <div className="form-group col-sm">
            <small>Class</small>
            <span style={{ color: "red" }}>*</span>
            <select
              id="admin-sp-stu-sectionId"
              className="form-control"
              value={details.sectionId}
              onChange={e => {
                onChange("sectionId", e.target.value)
              }}
              onKeyDown={e => gotoNextField(e, "admin-sp-stu-gender", true)}
            >
              <option value="">Select Class</option>
              {classesList.map(option => (
                <option value={option.id} key={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group col-sm">
            <small>
              Gender <span style={{ color: "red" }}>*</span>
            </small>
            <select
              id="admin-sp-stu-gender"
              className="form-control"
              value={details.gender}
              onChange={e => {
                onChange("gender", e.target.value)
              }}
              onKeyDown={e => gotoNextField(e, "admin-sp-stu-dob", true)}
            >
              <option value="">Select</option>
              <option value="M">Male</option>
              <option value="F">Female</option>
              <option value="O">Other</option>
            </select>
          </div>
          <div className="form-group col-sm">
            <small>
              Date of Birth <span style={{ color: "red" }}>*</span>
            </small>
            <input
              id="admin-sp-stu-dob"
              max="9999-12-31"
              type="date"
              className="form-control unstyled"
              value={details.dob}
              onChange={e => {
                onChange("dob", e.target.value)
              }}
              onKeyDown={e => gotoNextField(e, "Father", true)}
            />
          </div>
        </div>
      </div>

      <hr className="dotted"></hr>
      <h5 className="my-3 text-dark">Parent's details</h5>

      <div className="d-flex align-items-center mt-3 mb-1">
        <div>Primary Contact</div>
        <div className="form-check form-check-inline ml-5">
          <input
            className="form-check-input"
            type="radio"
            name="primaryContact"
            id="Father"
            value={details.primaryParent}
            checked={details.primaryParent === "FATHER"}
            onChange={e => {
              onChange("primaryParent", "FATHER")
            }}
            onKeyDown={e => gotoNextField(e, "admin-sp-stu-fatherFirstName")}
          />
          <label className="form-check-label" htmlFor="Father">
            Father
          </label>
        </div>
        <div className="form-check form-check-inline ml-5">
          <input
            className="form-check-input"
            type="radio"
            name="primaryContact"
            id="Mother"
            value={details.primaryParent}
            checked={details.primaryParent === "MOTHER"}
            onChange={e => onChange("primaryParent", "MOTHER")}
            onKeyDown={e => gotoNextField(e, "admin-sp-stu-fatherFirstName")}
          />
          <label className="form-check-label" htmlFor="Mother">
            Mother
          </label>
        </div>
        <div className="form-check form-check-inline ml-5">
          <input
            className="form-check-input"
            type="radio"
            name="primaryContact"
            id="localGuardian"
            value="localGuardian"
            checked={details.primaryParent === "LOG"}
            onChange={e => {
              onChange("primaryParent", "LOG")
            }}
            onKeyDown={e => gotoNextField(e, "admin-sp-stu-fatherFirstName")}
          />
          <label className="form-check-label" htmlFor="localGuardian">
            Local Guardian
          </label>
        </div>
        <div className="form-check form-check-inline ml-5">
          <input
            className="form-check-input"
            type="radio"
            name="primaryContact"
            id="legalGuardian"
            value="legalGuardian"
            checked={details.primaryParent === "LEG"}
            onChange={e => {
              onChange("primaryParent", "LEG")
            }}
            onKeyDown={e => gotoNextField(e, "admin-sp-stu-fatherFirstName")}
          />
          <label className="form-check-label" htmlFor="legalGuardian">
            Legal Guardian
          </label>
        </div>
      </div>

      <div className="text-muted mb-3" style={{ fontSize: 14 }}>
        School will use the primary contact only to communicate any
        announcements
      </div>

      <div className="d-flex justify-content-between">
        <div className="form-group w-100 mr-2">
          <small>Father First Name</small>{" "}
          <input
            id="admin-sp-stu-fatherFirstName"
            type="text"
            className="form-control"
            value={details.fatherFirstName}
            onChange={e => {
              onChange(
                "fatherFirstName",
                e.target.value.replace(/[^A-Za-z ]/gi, "")
              )
            }}
            onKeyDown={e => gotoNextField(e, "admin-sp-stu-fatherLastName")}
          />
        </div>
        <div className="form-group w-100 mr-2">
          <small>Father Last Name</small>{" "}
          <input
            id="admin-sp-stu-fatherLastName"
            type="text"
            className="form-control"
            value={details.fatherLastName}
            onChange={e => {
              onChange(
                "fatherLastName",
                e.target.value.replace(/[^A-Za-z ]/gi, "")
              )
            }}
            onKeyDown={e => gotoNextField(e, "admin-sp-stu-fatherEmail")}
          />
        </div>
        <div className="form-group w-100 mr-2">
          <small>Email Id</small>{" "}
          <input
            id="admin-sp-stu-fatherEmail"
            type="text"
            className="form-control"
            value={details.fatherEmail}
            onChange={e => {
              onChange("fatherEmail", e.target.value)
            }}
            onKeyDown={e => gotoNextField(e, "admin-sp-stu-fatherPhone")}
          />
        </div>
        <div className="form-group w-100 mr-2">
          <small>Mobile Number</small>{" "}
          <input
            id="admin-sp-stu-fatherPhone"
            type="number"
            className="form-control"
            value={details.fatherPhone}
            onChange={e => {
              onChange("fatherPhone", e.target.value)
            }}
            onKeyDown={e => gotoNextField(e, "admin-sp-stu-motherFirstName")}
          />
        </div>
      </div>

      <div className="d-flex justify-content-between">
        <div className="form-group w-100 mr-2">
          <small>Mother First Name</small>{" "}
          <input
            id="admin-sp-stu-motherFirstName"
            type="text"
            className="form-control"
            value={details.motherFirstName}
            onChange={e => {
              onChange(
                "motherFirstName",
                e.target.value.replace(/[^A-Za-z ]/gi, "")
              )
            }}
            onKeyDown={e => gotoNextField(e, "admin-sp-stu-motherLastName")}
          />
        </div>
        <div className="form-group w-100 mr-2">
          <small>Mother Last Name</small>{" "}
          <input
            id="admin-sp-stu-motherLastName"
            type="text"
            className="form-control"
            value={details.motherLastName}
            onChange={e => {
              onChange(
                "motherLastName",
                e.target.value.replace(/[^A-Za-z ]/gi, "")
              )
            }}
            onKeyDown={e => gotoNextField(e, "admin-sp-stu-motherEmail")}
          />
        </div>
        <div className="form-group w-100 mr-2">
          <small>Email Id</small>{" "}
          <input
            id="admin-sp-stu-motherEmail"
            type="text"
            className="form-control"
            value={details.motherEmail}
            onChange={e => {
              onChange("motherEmail", e.target.value)
            }}
            onKeyDown={e => gotoNextField(e, "admin-sp-stu-motherPhone")}
          />
        </div>
        <div className="form-group w-100 mr-2">
          <small>Mobile Number</small>{" "}
          <input
            id="admin-sp-stu-motherPhone"
            type="number"
            className="form-control"
            value={details.motherPhone}
            onChange={e => {
              onChange("motherPhone", e.target.value)
            }}
            onKeyDown={e =>
              gotoNextField(e, "admin-sp-stu-localGuardianFirstName")
            }
          />
        </div>
      </div>

      <div className="d-flex align-items-center">
        <h5 className="my-3 text-dark">Guardian details</h5>
        <h6 className="my-3 text-muted ml-3">(Not mandatory)</h6>
      </div>

      <div className="d-flex justify-content-between">
        <div className="form-group w-100 mr-2">
          <small>Local Guardian First Name</small>
          <input
            id="admin-sp-stu-localGuardianFirstName"
            type="text"
            className="form-control"
            value={details.localGuardianFirstName}
            onChange={e => {
              onChange(
                "localGuardianFirstName",
                e.target.value.replace(/[^A-Za-z ]/gi, "")
              )
            }}
            onKeyDown={e =>
              gotoNextField(e, "admin-sp-stu-localGuardianLastName")
            }
          />
        </div>
        <div className="form-group w-100 mr-2">
          <small>Local Guardian Last Name</small>
          <input
            id="admin-sp-stu-localGuardianLastName"
            type="text"
            className="form-control"
            value={details.localGuardianLastName}
            onChange={e => {
              onChange(
                "localGuardianLastName",
                e.target.value.replace(/[^A-Za-z ]/gi, "")
              )
            }}
            onKeyDown={e => gotoNextField(e, "admin-sp-stu-localGuardianEmail")}
          />
        </div>
        <div className="form-group w-100 mr-2">
          <small>Email Id</small>
          <input
            id="admin-sp-stu-localGuardianEmail"
            type="text"
            className="form-control"
            value={details.localGuardianEmail}
            onChange={e => {
              onChange("localGuardianEmail", e.target.value)
            }}
            onKeyDown={e => gotoNextField(e, "admin-sp-stu-localGuardianPhone")}
          />
        </div>
        <div className="form-group w-100 mr-2">
          <small>Mobile Number</small>
          <input
            id="admin-sp-stu-localGuardianPhone"
            type="text"
            className="form-control"
            value={details.localGuardianPhone}
            maxLength="10"
            onChange={e => {
              onChange(
                "localGuardianPhone",
                e.target.value.replace(/[^0-9 ]/gi, "")
              )
            }}
            onKeyDown={e =>
              gotoNextField(e, "admin-sp-stu-legalGuardianFirstName")
            }
          />
        </div>
      </div>

      <div className="d-flex justify-content-between">
        <div className="form-group w-100 mr-2">
          <small>Legal Guardian First Name</small>
          <input
            id="admin-sp-stu-legalGuardianFirstName"
            type="text"
            className="form-control"
            value={details.legalGuardianFirstName}
            onChange={e => {
              onChange(
                "legalGuardianFirstName",
                e.target.value.replace(/[^A-Za-z ]/gi, "")
              )
            }}
            onKeyDown={e =>
              gotoNextField(e, "admin-sp-stu-legalGuardianLastName")
            }
          />
        </div>
        <div className="form-group w-100 mr-2">
          <small>Legal Guardian Last Name</small>
          <input
            id="admin-sp-stu-legalGuardianLastName"
            type="text"
            className="form-control"
            value={details.legalGuardianLastName}
            onChange={e => {
              onChange(
                "legalGuardianLastName",
                e.target.value.replace(/[^A-Za-z ]/gi, "")
              )
            }}
            onKeyDown={e => gotoNextField(e, "admin-sp-stu-legalGuardianEmail")}
          />
        </div>
        <div className="form-group w-100 mr-2">
          <small>Email Id</small>
          <input
            id="admin-sp-stu-legalGuardianEmail"
            type="text"
            className="form-control"
            value={details.legalGuardianEmail}
            onChange={e => {
              onChange("legalGuardianEmail", e.target.value)
            }}
            onKeyDown={e => gotoNextField(e, "admin-sp-stu-legalGuardianPhone")}
          />
        </div>
        <div className="form-group w-100 mr-2">
          <small>Mobile Number</small>
          <input
            id="admin-sp-stu-legalGuardianPhone"
            type="text"
            className="form-control"
            value={details.legalGuardianPhone}
            maxLength="10"
            onChange={e => {
              onChange(
                "legalGuardianPhone",
                e.target.value.replace(/[^0-9 ]/gi, "")
              )
            }}
          />
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-center mt-4">
        <button
          className="d-block mx-auto px-4 py-2 fw-500 text-white bg-50AE93 br-8px"
          onClick={saveAddNext}
        >
          Add Student
        </button>
      </div>
    </div>
  )
}

export default AddNewStudent
