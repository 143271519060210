import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import {
  getReportCardTemplates,
  getTermListInReportCard,
} from "../../../actions/admin/ReportCardActions"
import { getSchoolDetailsAdmin } from "../../../actions/admin/DashboardAction"
import { getFile } from "../../../actions/CommonAction"
import getRandom from "../../../utils/getRandom"

function getMark(terms, gradeDetail) {
  const subjects = ["ENGLISH", "LANGUAGE", "MATHS", "SCIENCE", "SOCIAL SCIENCE"]
  const data = []

  subjects.forEach(s => {
    const list = []

    terms.forEach(t => {
      let currentTotal = 0
      let currAssCount = 0

      t.assementList.forEach(a => {
        let mark = getRandom(10, 100)
        let grade =
          gradeDetail.find(g => mark <= Number(g.gradeScoreTo))?.gradeName ||
          gradeDetail[0]?.gradeName

        list.push({
          id: `${getRandom(100, 10000)}-${getRandom(100, 10000)}`,
          mark,
          grade,
          term: t.termName,
          assessment: a.assessmentName,
          assessmentType: a.assessmentType,
        })

        currAssCount = currAssCount + 1
        currentTotal = currentTotal + mark
      })

      if (t.resultCondition > 0) {
        let filteredAssessmentsMarks = list
          .filter(a => a.assessmentType === t.mainAssessmentType)
          .map(a => a.mark)

        let mark = 0
        let grade = gradeDetail[0]?.gradeName

        if (filteredAssessmentsMarks.length) {
          if (t.resultCondition > 2) {
            mark = Math.max(filteredAssessmentsMarks)
          } else {
            mark = Math.floor(
              filteredAssessmentsMarks.reduce((pr, cu) => pr + cu, 0) /
              filteredAssessmentsMarks.length
            )
          }

          grade =
            gradeDetail.find(g => mark <= Number(g.gradeScoreTo))?.gradeName ||
            gradeDetail[0]?.gradeName
        }

        list.push({
          id: `${getRandom(100, 10000)}-${getRandom(100, 10000)}`,
          mark,
          grade,
          term: t.termName,
          assessment: `${t.mainAssessmentType}s`,
        })
      }

      let mark = Math.floor(currentTotal / currAssCount)
      let grade =
        gradeDetail.find(g => mark <= Number(g.gradeScoreTo))?.gradeName ||
        gradeDetail[0]?.gradeName

      list.push({
        id: `${getRandom(100, 10000)}-${getRandom(100, 10000)}`,
        mark,
        grade,
        term: t.termName,
        assessment: t.termName,
        isTermCumulative: true,
      })
    })

    data.push({
      subject: s,
      list,
    })
  })

  return data
}

function useConditions(classId) {
  const [conditions, setConditions] = useState({
    displayStudentName: true,
    displaySectionName: true,
    displayTeacherName: true,
    displayFatherName: true,
    displayMotherName: true,
    displayRollNo: true,
    displayDOB: true,

    displayClassTeacherSignature: true,
    displayPrincipalSignature: true,
    displayParentsSignature: true,

    displayStudentRank: true,
    displayScholasticScore: true,
    displayScholasticGrade: true,
    displayScholasticSubjectRemarks: true,

    displayOverallGrade: true,
    displayOverallPercentage: true,
    displayOverallRemarks: true,

    reportCardTitle: "",

    selectedAssessments: [],
    selectedTerms: [],

    gradeDetail: [],
  })

  const [original, setOriginal] = useState({
    selectedAssessments: [],
    selectedTerms: [],
  })

  const [schoolDetails, setSchoolDetails] = useState({
    schoolLogo: "",
    schoolName: "",
    city: "",
  })

  const [applyToAllOther, setApplyToAllOther] = useState([])
  const [marks, setMarks] = useState([])
  const [terms, setTerms] = useState([])

  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    getAll()
    // eslint-disable-next-line
  }, [])

  function getAll() {
    const cb = (success, res) => {
      if (success) {
        let gradeDetail = [...res.gradeDetail].sort((a, b) =>
          Number(a.gradeScoreFrom) > Number(b.gradeScoreFrom) ? 1 : -1
        )
        getTermListInReportCard(classId, (success1, res1) => {
          if (success1) {
            const selectedTerms = []
            const selectedAssessments = []

            res1.forEach(t => {
              let count = 0
              t.assementList.forEach(a => {
                const selAssess =
                  res?.reportCardTemplate
                    ?.reportCardScholasticAssessmentsDetails || []
                const isAssessmentSelected = selAssess.some(
                  rc => rc.assessmentId === a.assessmentId
                )
                if (isAssessmentSelected) {
                  selectedAssessments.push(a.assessmentName)
                  count = count + 1
                }

                if (count === t.assementList.length) {
                  selectedTerms.push(t.termName)
                }
              })
            })

            let localConditions = {
              displayStudentName: true,
              displaySectionName: true,
              displayTeacherName: true,
              displayFatherName: true,
              displayMotherName: true,
              displayRollNo: true,
              displayDOB: true,

              displayClassTeacherSignature: true,
              displayPrincipalSignature: true,
              displayParentsSignature: true,

              displayStudentRank: true,
              displayScholasticScore: true,
              displayScholasticGrade: true,
              displayScholasticSubjectRemarks: selectedAssessments.length === 1,

              displayOverallGrade: true,
              displayOverallPercentage: true,
              displayOverallRemarks: true,

              reportCardTitle: "",
            }

            if (res?.reportCardTemplate) {
              Object.keys(localConditions).forEach(k => {
                if (k === "reportCardTitle") {
                  let currentYear = new Date().getFullYear()
                  localConditions[k] =
                    res?.reportCardTemplate?.[k] ||
                    `Academic Year ${currentYear} - ${currentYear + 1}`
                } else {
                  localConditions[k] = res?.reportCardTemplate?.[k]
                }
              })
            }

            setConditions({
              ...localConditions,
              selectedAssessments,
              selectedTerms,
              gradeDetail,
            })
            setOriginal({
              selectedAssessments,
              selectedTerms,
            })
            setMarks(getMark(res1, gradeDetail))
            setIsLoading(false)
            setTerms(res1)
          }
        })
      }
    }

    dispatch(
      getSchoolDetailsAdmin((success, response) => {
        if (success) {
          setSchoolDetails(pr => ({
            ...pr,
            schoolName: response.schoolName,
            city: response.city,
          }))

          if (response.schoolLogo) {
            dispatch(
              getFile(response.schoolLogo, (success2, res2) => {
                if (success2) {
                  getReportCardTemplates(classId, cb)
                  setSchoolDetails(pr => ({
                    ...pr,
                    schoolLogo: res2.url,
                  }))
                }
              })
            )
          }
        }
      })
    )
  }

  const onChange = val => {
    setConditions(pr => ({
      ...pr,
      [val]: !pr[val],
    }))
  }

  const onEditReportCardTitle = e => {
    e.persist()
    setConditions(pr => ({
      ...pr,
      reportCardTitle: e.target.value,
    }))
  }

  const updateAllSigns = () => {
    const isAllChecked =
      conditions.displayClassTeacherSignature &&
      conditions.displayParentsSignature &&
      conditions.displayPrincipalSignature

    setConditions(pr => ({
      ...pr,
      displayClassTeacherSignature: !isAllChecked,
      displayPrincipalSignature: !isAllChecked,
      displayParentsSignature: !isAllChecked,
    }))
  }

  const updateSelectedTerm = (termName, canSelect) => {
    let isRemarkSelected = conditions.displayScholasticSubjectRemarks
    const isAlreadyTermSelected = conditions.selectedTerms.some(
      p => p === termName
    )
    let selectedAssessments = [...conditions.selectedAssessments]
    let selectedTerms = [...conditions.selectedTerms]
    let currentTermAssessments = terms.find(d => d.termName === termName)
      ?.assementList

    if (isAlreadyTermSelected) {
      selectedAssessments = selectedAssessments.filter(
        p => !currentTermAssessments.some(c => c.assessmentName === p)
      )
      selectedTerms = selectedTerms.filter(p => p !== termName)
    } else {
      if (canSelect || original.selectedTerms.includes(termName)) {
        selectedTerms.push(termName)
        selectedAssessments = [
          ...new Set([
            ...selectedAssessments,
            ...currentTermAssessments.map(c => c.assessmentName),
          ]),
        ]
      }
    }

    setConditions(pr => ({
      ...pr,
      selectedTerms,
      selectedAssessments,
      displayScholasticSubjectRemarks:
        selectedAssessments.length > 1 ? false : isRemarkSelected,
    }))
  }

  const updateSelectedAssesments = (termName, assessmentName, canSelect) => {
    let isRemarkSelected = conditions.displayScholasticSubjectRemarks
    const isAlreadySelected = conditions.selectedAssessments.some(
      p => p === assessmentName
    )
    let selectedAssessments = [...conditions.selectedAssessments]
    let selectedTerms = [...conditions.selectedTerms]
    let currentTermAssessments = terms.find(d => d.termName === termName)
      ?.assementList

    if (isAlreadySelected) {
      selectedAssessments = selectedAssessments.filter(
        a => a !== assessmentName
      )
    } else {
      if (canSelect || original.selectedAssessments.includes(assessmentName)) {
        selectedAssessments.push(assessmentName)
      }
    }

    let currentlySelectedAssessInTerm = selectedAssessments.reduce(
      (prev, curr) => {
        let num = currentTermAssessments.some(p => p.assessmentName === curr)
          ? 1
          : 0
        return num + prev
      },
      0
    )

    if (currentlySelectedAssessInTerm === currentTermAssessments.length) {
      selectedTerms = [...new Set([...selectedTerms, termName])]
    } else {
      selectedTerms = selectedTerms.filter(p => p !== termName)
    }

    setConditions(pr => ({
      ...pr,
      selectedTerms,
      selectedAssessments,
      displayScholasticSubjectRemarks:
        selectedAssessments.length > 1 ? false : isRemarkSelected,
    }))
  }

  return {
    marks,
    terms,
    isLoading,
    conditions,
    schoolDetails,
    applyToAllOther,
    onChange,
    updateAllSigns,
    setApplyToAllOther,
    updateSelectedTerm,
    onEditReportCardTitle,
    updateSelectedAssesments,
  }
}

export default useConditions
