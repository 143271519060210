/*************************************************
 * RedInk
 * @exports
 * @class HierarchyReducer.js
 * @extends Component
 * @author vijayakumar // on 18/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from "../../actions/types"
import { myLog } from "../../utils/Utility"

const INTIAL_STATE = {
  isGradeBookLoaded: false,
  selected: {
    termIndex: 0,
    faIndex: 0,
    subjectIndex: 0,
    theoryAndPracticalIndex: 0,
    activityIndex: 0,
  },
  hierarchyDetails: {},
  hierarchyTerms: [],
  hierarchyFAs: [],
  hierarchySubjects: [],
  hierarchyTheoryAndPractical: [],
  hierarchyActivity: [],
  examList: [],
  studentPerformanceReportDetails: [],
  displayStudentPerformanceReportDetails: [],
  subjectList: [],
  examHierarchySettingObject: {
    name: "",
    shortName: "",
    mustPass: false,
    termScoreEvaluation: false,
    maxMark: "",
    minMark: "",
    passMark: "",
    distinctionMark: "",
    convertMark: "",
    needToConvertMark: false,
    grades: [{ gradeName: "a1", gradeFrom: "90", gradeTo: "100" }],
    considerMarkToAssessmentTotal: false,
    requiredToPassToGetRank: false,
    includeMarkToSubjectTotal: false,
  },
  activityConditionList: [],
}

export const hierarchyReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.TEACHER_EXAM_LIST_DETAILS:
      myLog("payload", action.payload)
      return {
        ...state,
        examList: [...action.payload],
      }

    case ACTION_TYPES.EXAM_HIERARCHY_DETAILS:
      myLog("payload", action.payload)
      return {
        ...state,
        hierarchyDetails: { ...action.payload },
      }

    case ACTION_TYPES.EXAM_HIERARCHY_TERMS_LIST:
      myLog("payload terms", action.payload)
      return {
        ...state,
        hierarchyTerms: [...action.payload],
      }

    case ACTION_TYPES.EXAM_HIERARCHY_FAS_LIST:
      myLog("payload fas", action.payload)
      return {
        ...state,
        hierarchyFAs: [...action.payload],
      }

    case ACTION_TYPES.EXAM_HIERARCHY_SUBJECT_LIST:
      myLog("payload subjects", action.payload)
      return {
        ...state,
        hierarchySubjects: [...action.payload],
      }

    case ACTION_TYPES.EXAM_HIERARCHY_THEORYANDPRACTICAL_DETAILS:
      myLog("payload theorys", action.payload)
      return {
        ...state,
        hierarchyTheoryAndPractical: [...action.payload],
      }

    case ACTION_TYPES.EXAM_HIERARCHY_ACTIVITY_DETAILS:
      myLog("payload activities", action.payload)
      return {
        ...state,
        hierarchyActivity: [...action.payload],
      }

    case ACTION_TYPES.TEACHER_STUDENT_PERFORMANCE_REPORT_DETAILS:
      myLog("payload", action.payload)
      return {
        ...state,
        studentPerformanceReportDetails: [...action.payload],
      }

    case ACTION_TYPES.TEACHER_DISPLAY_STUDENT_PERFORMANCE_REPORT_DETAILS:
      myLog("payload", action.payload)
      return {
        ...state,
        displayStudentPerformanceReportDetails: [...action.payload],
      }

    case ACTION_TYPES.TEACHER_SUBJECT_LIST_DETAILS:
      myLog("payload", action.payload)
      return {
        ...state,
        subjectList: [...action.payload],
      }

    case ACTION_TYPES.TEACHER_EXAM_HIERARCHY_SETTINGS_DETAILS:
      myLog("payload", action.payload)
      return {
        ...state,
        examHierarchySettingObject: { ...action.payload },
      }

    case ACTION_TYPES.TEACHER_ACTIVITY_CONDITION_DETAILS:
      myLog("payload", action.payload)
      return {
        ...state,
        activityConditionList: [...action.payload],
      }

    case ACTION_TYPES.IS_GRADEBOOK_LOADED:
      return {
        ...state,
        isGradeBookLoaded: action.payload,
      }

    case ACTION_TYPES.SELECTED_GRADEBOOK_BOX_IINDEICES:
      myLog("payload", action.payload)
      return {
        ...state,
        selected: {
          ...state.selected,
          ...action.payload,
        },
      }

    default:
      return state
  }
}
