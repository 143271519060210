import React, { Component, useState } from "react"
import AppWrapper from "../Wrapper"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  getSubjectsList,
  getSubjectGroupsList,
} from "../../../actions/teacher/SubjectDetailsActions"
import { HashLoader } from "react-spinners"

class SubjectDetails extends Component {
  constructor(props) {
    document.title = "Subject | Redink"

    super(props)

    this.state = {
      subjectsList: null,
      languageSubjects: [],
      otherSubjects: [],
      coreSubjects: null,
      subjectGroupsList: null,
      toggleSubjectGroup: null,
      isNullSubjectGroups: false,
      isLoading: true,
    }
  }

  componentDidMount = () => {
    this.props.getSubjectsList({}, (success, response) => {
      if (success) {
        const { CORE, LANGUAGE, OTHER, higherSecondary } = response
        this.setState({
          coreSubjects: CORE || [],
          languageSubjects: LANGUAGE || [],
          otherSubjects: OTHER || [],
          toggleSubjectGroup: higherSecondary,
          isLoading: false,
        })
      }
    })
    this.props.getSubjectGroupsList({}, (success, response) => {
      this.setState({
        subjectGroupsList: response,
      })
    })
  }

  renderSubject = (item, index) => {
    return (
      <div className="col-sm-3" key={item.subjectId}>
        <div className="text-light p-2 rounded text-center m-2 bg-midnight-bloom">
          <div className="d-flex justify-content-between">
            <div className="text-left">
              <span>{item.subjectName}</span>
              <br></br>
              <small>
                {item.studentCount} Student{item.studentCount > 1 && "s"}
              </small>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <AppWrapper
        teacherDashboard
        showTeacherThree
        showTeacherLogo
        screenName="Subject Details"
      >
        {/* <h4 className="text-dark font-weight-bold mb-3">Subject Details</h4> */}
        {this.state.isLoading ? (
          <div style={{ position: "absolute", left: "50%", top: "50%" }}>
            <HashLoader
              sizeUnit={"px"}
              size={35}
              color={"#084353"}
              loading={this.state.isShowLoading}
            />
          </div>
        ) : (
          <>
            {" "}
            {this.state.toggleSubjectGroup ? (
              <div className="m-3">
                <large className="font-weight-bold text-uppercase">
                  Subject Groups
                </large>
                <div className="row m-3">
                  {this.state.subjectGroupsList &&
                    this.state.subjectGroupsList.map((item, index) => (
                      <SubjectGroup key={index} {...item} />
                    ))}
                </div>
              </div>
            ) : null}
            {this.state.toggleSubjectGroup === false &&
            this.state.coreSubjects &&
            this.state.coreSubjects.length > 0 ? (
              <div className="m-3">
                <large className="font-weight-bold text-uppercase">
                  Core Subjects
                </large>
                <div className="row m-3">
                  {this.state.coreSubjects &&
                    this.state.coreSubjects.map(this.renderSubject)}
                </div>
              </div>
            ) : null}
            {this.state.languageSubjects &&
            this.state.languageSubjects.length > 0 ? (
              <div className="m-3">
                <large className="font-weight-bold text-uppercase">
                  Language Subjects
                </large>
                <div className="row m-3">
                  {this.state.languageSubjects.map(this.renderSubject)}
                </div>
              </div>
            ) : null}
            {this.state.otherSubjects && this.state.otherSubjects.length > 0 ? (
              <div className="m-3">
                <large className="font-weight-bold text-uppercase">
                  Other Subjects
                </large>
                <div className="row m-3">
                  {this.state.otherSubjects.map(this.renderSubject)}
                </div>
              </div>
            ) : null}
          </>
        )}
      </AppWrapper>
    )
  }
}

function SubjectGroup({ name, subjects, studentCount }) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="m-3 w-25">
      <div className="rounded p-3 bg-midnight-bloom">
        <div className="d-flex justify-content-between">
          <h5>
            {name}
            <small className="text-light ml-3">
              {"- " +
                subjects.map(subj => subj.subjectName.substr(0, 3)).join(", ")}
            </small>
          </h5>
          {/* <i className="fas fa-ellipsis-v text-white"></i> */}
        </div>
        <div className="mt-1 text-white">
          {studentCount} Student{studentCount > 1 && "s"}
        </div>
        {!isOpen && (
          <div
            onClick={() => setIsOpen(true)}
            style={{ height: 20, width: 20 }}
            className="bg-secondary d-flex align-items-center justify-content-center text-white rounded-pill cursor-pointer mx-auto mt-1"
          >
            <i className="fas fa-angle-down m-0"></i>
          </div>
        )}
      </div>
      {isOpen && (
        <div
          className="mx-3 p-3 bg-gray"
          style={{ borderBottomRightRadius: 24, borderBottomLeftRadius: 24 }}
        >
          <ul style={{ listStyleType: "none" }}>
            {subjects.map((subj, i) => (
              <li style={{ color: "#022636" }} key={i}>
                {subj.subjectName}
              </li>
            ))}
          </ul>
          <div
            onClick={() => setIsOpen(false)}
            style={{ height: 20, width: 20, margin: "0px auto" }}
            className="bg-dark d-flex align-items-center justify-content-center text-white rounded-pill cursor-pointer mt-3"
          >
            <i className="fas fa-angle-up m-0"></i>
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    subjectGroupsList: state.subjectDetailsState.subjectGroupsList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getSubjectGroupsList,
      getSubjectsList,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(SubjectDetails)
