/*************************************************
 * RedInk
 * @exports
 * @class index.js
 * @extends Component
 * @author Sasidharan // on 26/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import AppWrapper from "./Wrapper"
import SchoolDetailsModal from "./SchoolDetailsModal"
import MessageModal from "./MessageModal"
import { saveSchoolDetails } from "../../actions/superAdmin/schoolDetailsAction"
import { connect } from "react-redux"
import Chart from "chart.js"
import { Progress } from "react-sweet-progress"
import { getQuickInfoDetails } from "../../actions/CommonAction"
import store from "store"
import {
  getAdminSchoolInfoDetails,
  getAdminDashboardSchoolPerformanceByClassDetails,
  getAdminDashboardTopPerformingClassesDetails,
  getAdminDashboardTopPerformingTeachersDetails,
  getAdminDashboardDailyAttendanceReportDetails,
  getAdminDashboardTopPerformingStudentsDetails,
  getAdminDashboardStudentAttendanceReportList,
} from "../../actions/admin/DashboardAction"
import { bindActionCreators } from "redux"
import { getSchoolsListDetails } from "../../actions/superAdmin/schoolDetailsAction"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
// import Calendar from "react-calendar"
import "react-modern-calendar-datepicker/lib/DatePicker.css"
import { Calendar } from "react-modern-calendar-datepicker"
// import { HashLoader } from "react-spinners"
import { UncontrolledTooltip } from "reactstrap"
import { myLog } from "../../utils/Utility"

let myspGraph = null
let myATGraph = null

class Dashboard extends Component {
  constructor(props) {
    super(props)
    document.title = "Dashboard | Redink"
    this.state = {
      isMessageModalOpen: false,
      isSchoolDetailsModalOpen:
        this.props.userInfo && this.props.userInfo.firstLogin === 1
          ? true
          : false,

      clearSchoolDetailsModal: false,
      savingSchoolDetails: false,
      date: null,
    }
  }

  componentDidMount = () => {
    this.props.getQuickInfoDetails((success, response) => {
      if (success) {
        this.props.getSchoolsListDetails((schoolSuccess, schoolResponse) => {
          if (schoolSuccess) {
            // this.getReport(schoolResponse[0].branchId)
            this.setState({ selectedBranch: schoolResponse[0].branchId })
            this.getDashboardReport(schoolResponse[0].branchId)
          }
        })
      }
    })
  }

  getDashboardReport = branchId => {
    this.setState(
      { isShowLoadingSchoolGraph: false, isShowLoadingAttendanceReport: false },
      () => {
        let object = {
          branchId,
        }
        this.props.getAdminSchoolInfoDetails(object, () => {})
        this.props.getAdminDashboardStudentAttendanceReportList(
          object,
          (success, response) => {
            if (success) {
              this.setState({ classAttendanceReport: response })
            }
          }
        )
        this.props.getAdminDashboardTopPerformingStudentsDetails(
          object,
          (success, response) => {
            if (success) {
              this.setState({
                performanceReportAssessment: response.assessment,
                topPerformingStudents: response.topStudents,
              })
            }
          }
        )
        this.props.getAdminDashboardDailyAttendanceReportDetails(
          object,
          (success, response) => {
            if (success) {
              this.setState(
                {
                  dailyAttendanceReport: response,
                  isShowLoadingAttendanceReport: false,
                },
                () => {
                  this.showAttendanceTimelineGraph()
                }
              )
            }
          }
        )
        this.props.getAdminDashboardSchoolPerformanceByClassDetails(
          object,
          (success, response) => {
            if (success) {
              this.setState({
                schoolPerformanceByClassGraph: response,
                isShowLoadingSchoolGraph: false,
              })
              this.showSchoolPerformanceGraph()
            }
          }
        )
        this.props.getAdminDashboardTopPerformingClassesDetails(
          object,
          (success, response) => {
            if (success) {
              this.setState({
                performanceReportAssessment: response.assessment,
                topPerformingClasses: response.topClasses,
              })
            }
          }
        )
        this.props.getAdminDashboardTopPerformingTeachersDetails(
          object,
          (success, response) => {
            if (success) {
              this.setState({
                performanceReportAssessment: response.assessment,
                topPerformingTeachers: response.topTeachers,
              })
            }
          }
        )
      }
    )
  }

  showSchoolPerformanceGraph = () => {
    const schoolPerformanceGraph = this.schoolPerformanceGraph
    if (myspGraph != null) {
      myspGraph.destroy()
    }
    myspGraph = new Chart(schoolPerformanceGraph, {
      type: "doughnut",
      data: {
        labels: this.state.schoolPerformanceByClassGraph.labels,
        datasets: [
          {
            label: "Count",
            data: this.state.schoolPerformanceByClassGraph.data,
            backgroundColor: this.state.schoolPerformanceByClassGraph
              .backgroundColor,
            fill: false,
            borderWidth: 0,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "right",
          labels: {
            usePointStyle: true,
            fontSize: 8,
            fontColor: "white",
          },
        },
        tooltips: {
          callbacks: {
            title: function(tooltipItem, data) {
              return data["labels"][tooltipItem[0]["index"]]
            },
            label: function(tooltipItem, data) {
              myLog(data, tooltipItem)
              var dataset = data["datasets"][0]
              var percent = dataset.data[tooltipItem.index]
              return percent + "%"
            },
          },
          xAlign: "left",
        },
      },
    })
  }
  showAttendanceTimelineGraph = () => {
    if (myATGraph != null) {
      myATGraph.destroy()
    }
    myATGraph = new Chart(this.attendanceTimelineGraph, {
      type: "bar",
      data: {
        labels: this.state.dailyAttendanceReport.labels,
        datasets: [
          {
            barPercentage: 0.3,
            barThickness: 10,
            maxBarThickness: 10,
            minBarLength: 2,
            backgroundColor: "#18a489",
            data: this.state.dailyAttendanceReport.data,
          },
        ],
      },
      options: {
        responsive: true,
        legend: {
          display: false,
          position: "bottom",
          labels: {
            usePointStyle: true,
            fontSize: 10,
            fontColor: "white",
          },
        },
        scales: {
          xAxes: [
            {
              barPercentage: 0.3,
              ticks: {
                fontColor: "#FFFFFF",
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                display: false,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            title: function(tooltipItem, data) {
              return data["labels"][tooltipItem[0]["index"]]
            },
            label: function(tooltipItem, data) {
              myLog(data, tooltipItem)
              var dataset = data["datasets"][0]
              var percent = dataset.data[tooltipItem.index]
              return percent + "%"
            },
          },
          position: "average",
          // xAlign: "left",
        },
      },
    })
  }
  /**
   * Handles school details modal
   */
  closeSchoolDetailsModal = () =>
    this.setState({ isSchoolDetailsModalOpen: false })
  /**
   * Handles message modal
   */
  closeMessageModal = () => this.setState({ isMessageModalOpen: false })
  /**
   * Handles modal form submit
   */
  onSubmitSchoolDetails = (params, closeModal) => {
    this.setState({ savingSchoolDetails: true })
    this.props.saveSchoolDetails(params, promise => {
      this.setState({ savingSchoolDetails: false })
      if (promise) {
        if (closeModal) {
          this.closeSchoolDetailsModal()
        } else {
          this.setState({ clearSchoolDetailsModal: true })
        }
      }
    })
  }
  /**
   * handles on click message icon in sidebar
   */
  onClickMessageIcon = () => {
    this.setState({ isMessageModalOpen: true })
  }
  /**
   * handles message submit
   */
  onSubmitMessage = () => {
    this.closeMessageModal()
  }

  render() {
    return (
      <AppWrapper
        openMessageModal={() => this.onClickMessageIcon()}
        showThree
        showSchoolLogo
        superAdminDashboard
        onChangeSchoolName={item => {
          myLog(item)
          this.setState({ selectedBranch: item.branchId })
          if (myspGraph != null) {
            myspGraph.destroy()
          }
          if (myATGraph != null) {
            myATGraph.destroy()
          }
          this.getDashboardReport(item.branchId)
        }}
      >
        <SchoolDetailsModal
          isModalOpen={this.state.isSchoolDetailsModalOpen}
          closeModal={() => this.closeSchoolDetailsModal()}
          onSubmitSchoolDetails={(params, closeModal) =>
            this.onSubmitSchoolDetails(params, closeModal)
          }
          showLoading={this.state.savingSchoolDetails}
          clearSchoolDetailsModal={this.state.clearSchoolDetailsModal}
        />
        <MessageModal
          isModalOpen={this.state.isMessageModalOpen}
          closeModal={() => this.closeMessageModal()}
          onSubmitMessage={() => this.onSubmitMessage()}
        />
        <div className="row">
          <div className="col-sm bg-theme-light px-3 py-2 br-10">
            <div className="text-left text-light">
              <h5 className="font-weight-bold">
                Welcome{" "}
                {store.get("quickInfo")
                  ? store.get("quickInfo").tentantFullName
                  : ""}{" "}
                !
              </h5>
              <p>Its gonna be a great day, We are excited same as you are !</p>
            </div>
          </div>
          <div className="col-sm-3 bg-theme-light px-3 py-2 br-10 mx-2">
            <div className="text-left font-weight-bold fs-15 text-light">
              TODAY'S ATTENDANCE
            </div>
            <UncontrolledTooltip placement="top" target={`prekg-a-pass1`}>
              {this.state.classAttendanceReport
                ? this.state.classAttendanceReport.presentPercentage + "%"
                : "0%"}
              {" students present"}
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target={`prekg-a-fail2`}>
              {this.state.classAttendanceReport
                ? this.state.classAttendanceReport.absentPercentage + "%"
                : "0%"}
              {" students absent"}
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="top" target={`prekg-a-fail3`}>
              {this.state.classAttendanceReport
                ? this.state.classAttendanceReport.unmarkPercentage + "%"
                : "0%"}
              {" students unmarked"}
            </UncontrolledTooltip>
            <div className="progress mt-3">
              <div
                className="progress-bar bg-present"
                role="progressbar"
                id={`prekg-a-pass1`}
                style={{
                  width: this.state.classAttendanceReport
                    ? this.state.classAttendanceReport.presentPercentage + "%"
                    : "0",
                }}
                aria-valuenow={
                  this.state.classAttendanceReport
                    ? this.state.classAttendanceReport.presentPercentage
                    : "0"
                }
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {/* {'80%'} */}
              </div>
              <div
                className="progress-bar bg-absent"
                role="progressbar"
                id={`prekg-a-fail2`}
                style={{
                  width: this.state.classAttendanceReport
                    ? this.state.classAttendanceReport.absentPercentage + "%"
                    : "0",
                }}
                aria-valuenow={
                  this.state.classAttendanceReport
                    ? this.state.classAttendanceReport.absentPercentage
                    : "0"
                }
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {/* {'20%'} */}
              </div>
              <div
                className="progress-bar bg-unmarked"
                role="progressbar"
                id={`prekg-a-fail3`}
                style={{
                  width: this.state.classAttendanceReport
                    ? this.state.classAttendanceReport.unmarkPercentage + "%"
                    : "0",
                }}
                aria-valuenow={
                  this.state.classAttendanceReport
                    ? this.state.classAttendanceReport.unmarkPercentage
                    : "0"
                }
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {/* {'20%'} */}
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <div className="py-4 text-center">
                  <b className=" text-white text-center">
                    {this.state.classAttendanceReport
                      ? this.state.classAttendanceReport.presentCount
                      : "0"}
                  </b>
                  <div className="mt-1 border-top-present text-center">
                    <small className="text-white">Present</small>
                  </div>
                </div>
              </div>
              <div className="col-sm">
                <div className="py-4 text-center">
                  <b className=" text-white text-center">
                    {this.state.classAttendanceReport
                      ? this.state.classAttendanceReport.absentCount
                      : "0"}
                  </b>
                  <div className="mt-1 border-top-absent text-center">
                    <small className="text-white">Absent</small>
                  </div>
                </div>
              </div>
              <div className="col-sm">
                <div className="py-4 text-center">
                  <b className="text-white text-center">
                    {this.state.classAttendanceReport
                      ? this.state.classAttendanceReport.unmarkedCount
                      : "0"}
                  </b>
                  <div className="mt-1 border-top-unmarked text-center">
                    <small className="text-white">Unmarked</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3 bg-theme-light px-3 py-2 br-10 mx-2 fs-12 d-flex flex-column justify-content-center">
            <div>
              <div className="row text-light">
                <div className="col-sm d-flex flex-column text-center">
                  <b>
                    {this.props.adminSchoolDetail &&
                      this.props.adminSchoolDetail.students &&
                      this.props.adminSchoolDetail.students}
                  </b>
                  <span>Students</span>
                </div>
                <div className="col-sm d-flex flex-column text-center">
                  <b>
                    {this.props.adminSchoolDetail &&
                      this.props.adminSchoolDetail.boy &&
                      this.props.adminSchoolDetail.boy}
                  </b>
                  <span>Boys</span>
                </div>
                <div className="col-sm d-flex flex-column text-center">
                  <b>
                    {this.props.adminSchoolDetail &&
                      this.props.adminSchoolDetail.girl &&
                      this.props.adminSchoolDetail.girl}
                  </b>
                  <span>Girls</span>
                </div>
              </div>
              <hr
                style={{
                  borderColor: "black",
                  backgroundColor: "black",
                  color: "black",
                }}
              ></hr>
              <div className="row text-light">
                <div className="col-sm d-flex flex-column text-center">
                  <b>
                    {this.props.adminSchoolDetail &&
                      this.props.adminSchoolDetail.teachers &&
                      this.props.adminSchoolDetail.teachers}
                  </b>
                  <span>Teachers</span>
                </div>
                <div className="col-sm d-flex flex-column text-center">
                  <b>
                    {this.props.adminSchoolDetail &&
                      this.props.adminSchoolDetail.male &&
                      this.props.adminSchoolDetail.male}
                  </b>
                  <span>Male</span>
                </div>
                <div className="col-sm d-flex flex-column text-center">
                  <b>
                    {this.props.adminSchoolDetail &&
                      this.props.adminSchoolDetail.female &&
                      this.props.adminSchoolDetail.female}
                  </b>
                  <span>Female</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 2nd */}
        <div className="row my-3">
          <div className="col-sm-4 bg-theme-light px-3 py-2 br-10 d-flex flex-column">
            <div className="text-left font-weight-bold fs-15 text-light">
              SCHOOL PERFORMANCE
            </div>
            {!this.state.isShowLoadingSchoolGraph ? (
              <div className="p-3 my-auto align-items-center">
                <canvas
                  ref={schoolPerformanceGraph =>
                    (this.schoolPerformanceGraph = schoolPerformanceGraph)
                  }
                />
              </div>
            ) : (
              <div className="my-auto p-3 align-items-center">
                <div className="p-3 bg-brand rounded text-center">
                  <img
                    src={require("../../assets/images/nodata.png")}
                    alt="No Data Found"
                    style={{ width: 100 }}
                  />
                  <div className="text-white text-center mt-4">
                    No Data Found
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-sm-4 bg-theme-light px-3 py-2 br-10 mx-2 d-flex flex-column justify-content-between">
            <div className="text-left font-weight-bold fs-15 text-light">
              ATTENDANCE TIMELINE
            </div>
            {!this.state.isShowLoadingAttendanceReport ? (
              <div className="my-auto">
                <canvas
                  ref={attendanceTimelineGraph =>
                    (this.attendanceTimelineGraph = attendanceTimelineGraph)
                  }
                />
              </div>
            ) : (
              <div className="my-auto p-3 align-items-center">
                <div className="p-3 bg-brand rounded text-center">
                  <img
                    src={require("../../assets/images/nodata.png")}
                    alt="No Data Found"
                    style={{ width: 100 }}
                  />
                  <div className="text-white text-center mt-4">
                    No Data Found
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-sm d-inline-block bg-theme-light br-10 p-0 mr-2 py-2">
            <div className="text-left font-weight-bold fs-15 py-2 px-4 text-light">
              SCHEDULES
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <Calendar
                value={this.state.date}
                // onChange={val => this.setState({ date: val })}
                // value={{ day: "", month: "", year: "" }}
                colorPrimary="#9c88ff"
                calendarClassName="custom-calendar" // and this
                calendarTodayClassName="custom-today-day" // also this
                shouldHighlightWeekends
              />
            </div>
          </div>
        </div>
        {/* 3rd */}
        <div className="row my-3">
          <div className="col-sm bg-theme-light px-3 py-2 br-10">
            <div className="d-flex justify-content-between align-items-center">
              <div className="text-left font-weight-bold fs-15 text-light">
                TOP PERFORMING STUDENTS
              </div>
              <div className="px-3 badge bg-theme-verylight br-10 text-light">
                {this.state.performanceReportAssessment}
              </div>
            </div>
            {this.state.topPerformingStudents &&
              this.state.topPerformingStudents.map(item => {
                return (
                  <div className="d-flex bg-theme-verylight br-10 text-light mt-3 fs-12 align-items-center">
                    <div className="col-sm-5 d-flex flex-column text-center">
                      <b>{item.studentName}</b>
                      <span>
                        {item.studentAssessmentMark} out of{" "}
                        {item.totalAssessmentMark}
                      </span>
                    </div>
                    <div className="col-sm">
                      <b>{item.className}</b>
                    </div>
                    <div className="col-sm py-2">
                      <div style={{ width: "60px", height: "60px" }}>
                        <CircularProgressbar
                          value={item.percentage}
                          text={`${item.percentage}%`}
                          styles={buildStyles({
                            pathColor: `#0fb293`,
                            textColor: "#ffffff",
                            trailColor: "rgba(71, 153, 151, .22)",
                          })}
                        />
                      </div>
                    </div>
                  </div>
                )
              })}
            {!this.state.topPerformingStudents && (
              <div className="my-auto p-3 align-items-center">
                <div className="p-3 bg-brand rounded text-center">
                  <img
                    src={require("../../assets/images/nodata.png")}
                    alt="No Data Found"
                    style={{ width: 100 }}
                  />
                  <div className="text-white text-center mt-4">
                    No Data Found
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-sm-4 bg-theme-light px-3 py-2 br-10 mx-2">
            <div className="d-flex justify-content-between align-items-center">
              <div className="text-left font-weight-bold fs-15 text-light">
                TOP PERFORMING CLASSES
              </div>
              <div className="px-3 badge bg-theme-verylight br-10 text-light">
                {this.state.performanceReportAssessment}
              </div>
            </div>
            <div className="table-responsive mt-3">
              {this.state.topPerformingClasses &&
                this.state.topPerformingClasses.length > 0 && (
                  <table className="table table-borderless text-white text-center">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="first-child-curved-td text-center text-nowrap py-1 fs-12"
                        >
                          CLASS
                        </th>
                        <th
                          scope="col"
                          className="bg-lightblue-td text-center py-1 fs-12"
                        >
                          PERCENTAGE
                        </th>
                        <th
                          scope="col"
                          className="last-child-curved-td text-center py-1 fs-12"
                        >
                          TEACHER NAME
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-light fs-12">
                      {this.state.topPerformingClasses &&
                        this.state.topPerformingClasses.map((item, i) => (
                          <tr key={i}>
                            <td>
                              <div className="badge p-2 br-10 bg-theme-verylight">
                                {item.className}
                              </div>
                            </td>
                            <td>{item.percentage}%</td>
                            <td>{item.classTeacherName}</td>
                          </tr>
                        ))}
                      {/* <tr>
                    <td>
                      <div className="badge p-2 br-10 bg-theme-verylight">
                        8A
                      </div>
                    </td>
                    <td>45 %</td>
                    <td>Thornton</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="badge p-2 br-10 bg-theme-verylight">
                        2B
                      </div>
                    </td>
                    <td>45 %</td>
                    <td>Otto</td>
                  </tr> */}
                    </tbody>
                  </table>
                )}
            </div>

            {this.state.topPerformingClasses === undefined && (
              <div className="my-auto p-3 align-items-center">
                <div className="p-3 bg-brand rounded text-center">
                  <img
                    src={require("../../assets/images/nodata.png")}
                    alt="No Data Found"
                    style={{ width: 100 }}
                  />
                  <div className="text-white text-center mt-4">
                    No Data Found
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-sm-4 bg-theme-light px-3 py-2 br-10 mx-2">
            <div className="d-flex justify-content-between align-items-center">
              <div className="text-left font-weight-bold fs-15 text-light">
                TOP PERFORMING TEACHERS
              </div>
              <div className="px-3 badge bg-theme-verylight br-10 text-light">
                {this.state.performanceReportAssessment}
              </div>
            </div>
            <div className="table-responsive mt-3">
              {this.state.topPerformingTeachers &&
                this.state.topPerformingTeachers.length > 0 && (
                  <table className="table table-borderless text-white text-center">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="first-child-curved-td text-center text-nowrap py-1 fs-12"
                        >
                          TEACHER NAME
                        </th>
                        <th
                          scope="col"
                          className="bg-lightblue-td text-center py-1 fs-12"
                        >
                          SUBJECT
                        </th>
                        <th
                          scope="col"
                          colspan="2"
                          className="last-child-curved-td text-center py-1 fs-12"
                        >
                          PERCENTAGE
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-light fs-12">
                      {this.state.topPerformingTeachers &&
                        this.state.topPerformingTeachers.map((item, i) => (
                          <tr key={i}>
                            <td>{item.teacherName}</td>
                            <td>{item.subjectName}</td>
                            <td width={"25%"}>
                              <Progress
                                percent={item.percentage}
                                status={"active"}
                                className="my-1 ml-1"
                                symbolClassName="d-none"
                                theme={{
                                  active: {
                                    trailColor: "rgba(71, 153, 151, .22)",
                                    color: "#18a489",
                                  },
                                }}
                              />
                            </td>
                            <td>{item.percentage}%</td>
                          </tr>
                        ))}
                      {/* <tr>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td width={'25%'}>
                      <Progress
                        percent={"70"}
                        status={"active"}
                        className="my-1 ml-1"
                        symbolClassName="d-none"
                        theme={{
                          active: {
                            trailColor: "rgba(71, 153, 151, .22)",
                            color: "#18a489",
                          },
                        }}
                      />
                    </td>
                    <td>70%</td>
                  </tr>
                  <tr>
                    <td>@twitter</td>
                    <td>@twitter</td>
                    <td width={'25%'}>
                      <Progress
                        percent={"70"}
                        status={"active"}
                        className="my-1 ml-1"
                        symbolClassName="d-none"
                        theme={{
                          active: {
                            trailColor: "rgba(71, 153, 151, .22)",
                            color: "#18a489",
                          },
                        }}
                      />
                    </td>
                    <td>70%</td>
                  </tr> */}
                    </tbody>
                  </table>
                )}
              {this.state.topPerformingTeachers === undefined && (
                <div className="my-auto p-3 align-items-center">
                  <div className="p-3 bg-brand rounded text-center">
                    <img
                      src={require("../../assets/images/nodata.png")}
                      alt="No Data Found"
                      style={{ width: 100 }}
                    />
                    <div className="text-white text-center mt-4">
                      No Data Found
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    adminSchoolDetail: state.adminDashboardReducer.adminSchoolDetail,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      saveSchoolDetails,
      getQuickInfoDetails,
      getAdminSchoolInfoDetails,
      getAdminDashboardStudentAttendanceReportList,
      getAdminDashboardTopPerformingStudentsDetails,
      getAdminDashboardDailyAttendanceReportDetails,
      getAdminDashboardSchoolPerformanceByClassDetails,
      getAdminDashboardTopPerformingTeachersDetails,
      getAdminDashboardTopPerformingClassesDetails,
      getSchoolsListDetails,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(Dashboard)
