/*************************************************
 * RedInk
 * @exports
 * @class MessageModal.js
 * @extends Component
 * @author Sasidharan // on 11/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import Select, { components } from "react-select"
import { UncontrolledTooltip } from "reactstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  getTeachersDetails,
  saveCommunicationDetails,
} from "../../actions/teacher/CommunicationDetailsAction"
import {
  getTeacherAssignedDetails,
  getClassStudents,
} from "../../actions/teacher/QuizAction"
import makeAnimated from "react-select/animated"
import { MySelect } from "../common/MySelect"
import { HashLoader } from "react-spinners"
import { DropdownToggle, DropdownMenu, UncontrolledDropdown } from "reactstrap"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import { showNotification } from "../../actions/CommonAction"
import {
  VALIDATION_MSG,
  DANGER_TOAST,
  MAX_FILE_UPLOAD,
} from "../../utils/Constants"
import { saveOpenMessageModalInStore } from "../../actions/admin/CommunicationDetailsAction"
// import { myLog } from "../../utils/Utility"

const Option = props => (
  <div>
    <components.Option {...props}>
      <div className="d-flex align-items-center">
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          className="subject-checkbox"
        />{" "}
        <label className="ml-2 mt-2">{props.label}</label>
      </div>
    </components.Option>
  </div>
)

const allOption = {
  label: "Select all",
  value: "*",
}

const MultiValue = props => {
  let labelToBeDisplayed = `${props.data.label}, `
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = "Selected All"
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  )
}

const ValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue()
  let toBeRendered = children
  if (currentValues.some(val => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]]
  }

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  )
}

const animatedComponents = makeAnimated()

class MessageModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      messageSubject: "",
      messageBody: "",
      sendEmail: true,
      sendSms: false,
      assignedRoleId: "",
      assignedRoleName: "",
      assignedClassId: "",
      assignedClassName: "",
      assignedStudentId: "",
      assignedStudentName: "",
      assignedTeacherId: "",
      assignedTeacherName: "",
      assignedSubjectId: "",
      assignedSubjectName: "",
      assignedStudents: [],
      attachments: [],
      assignedClass: {},
      classStudentsList: [],
    }
  }

  style = {
    option: (provided, state) => ({
      ...provided,
      color: "black",
      backgroundColor: "white",
    }),
  }

  sortClassOrder = classess => {
    const classOrder = [
      "-",
      "JKG",
      "LKG",
      "SKG",
      "UKG",
      ...Array(12)
        .fill()
        .map((_, idx) => `${1 + idx}`),
    ]
    const classes = classess
    classes.sort((a, b) => {
      const aClass = a.className || "-"
      const aSection = a.sectionName || "-"
      const bClass = b.className || "-"
      const bSection = b.sectionName || "-"
      if (aClass === bClass) return aSection < bSection ? -1 : 1
      else return classOrder.indexOf(aClass) - classOrder.indexOf(bClass)
    })
    return classes
  }

  componentDidMount() {
    this.props.getTeacherAssignedDetails({}, (success, data) => {
      if (success) {
        // myLog("Message data", data.sections)
        this.setState({
          classesList: this.sortClassOrder(data.sections) || [],
        })
      }
    })
  }

  getStudents = classId => {
    this.props.getClassStudents(classId, (success, data) => {
      if (success) {
        this.setState({ classStudentsList: data })
      }
    })
  }

  clearObject = () => {
    this.setState({
      messageSubject: "",
      messageBody: "",
      sendEmail: true,
      sendSms: false,
      assignedRoleId: "",
      assignedRoleName: "",
      assignedClassId: "",
      assignedClassName: "",
      assignedStudentId: "",
      assignedStudentName: "",
      assignedTeacherId: "",
      assignedTeacherName: "",
      assignedSubjectId: "",
      assignedSubjectName: "",
      assignedRole: {},
      assignedClass: {},
      assignedStudents: [],
      assignedSubjects: {},
      attachments: [],
      labelId: "",
      selectedClass: null,
    })
  }

  clickSend = () => {
    const wrongInputMsg = msg =>
      this.props.showNotification(DANGER_TOAST, VALIDATION_MSG.OOPS, msg)

    if (this.props.dailyEmailUsage + this.props.dailySmsUsage <= 100) {
      const {
        messageSubject,
        messageBody,
        labelId,
        assignedStudents,
        sendEmail,
        sendSms,
        attachments,
      } = this.state

      /* mandatory fields check */
      if (!assignedStudents.length) return wrongInputMsg("Select recipients...")
      if (!messageSubject) return wrongInputMsg("Enter subject...")
      if (!sendEmail && !sendSms) return wrongInputMsg("Select email or sms...")
      if (!messageBody) return wrongInputMsg("Enter message...")

      this.setState({ isShowSaveLoading: true }, () => {
        let receiverArray = []
        for (let i = 0; i < assignedStudents.length; i++) {
          if (assignedStudents[i].label !== "Select all") {
            let object = {
              recipientId: assignedStudents[i].id,
              // sectionId: assignedStudents[i].grade.id,
              sectionId: assignedStudents[i].grade,
            }
            receiverArray.push(object)
          }
        }
        let formData = new FormData()
        const wayOfMessage = []
        if (sendEmail) wayOfMessage.push("EMAIL")
        if (sendSms) wayOfMessage.push("SMS")
        formData.append("wayOfMessage", JSON.stringify(wayOfMessage))
        formData.append("recipients", JSON.stringify(receiverArray))
        formData.append("title", messageSubject || "")
        formData.append("content", messageBody || "")
        formData.append("labelId", labelId || "")
        if (sendEmail) {
          for (let i = 0; i < attachments.length; i++) {
            formData.append("attachments", attachments[i])
          }
        }
        this.props.saveCommunicationDetails(formData, (success, response) => {
          this.setState({ isShowSaveLoading: false }, () => {})
          if (success) {
            this.clearObject()

            if (this.props.afterSave) {
              this.props.afterSave(wayOfMessage, response.data)
            }
          }
          this.props.saveOpenMessageModalInStore(false)
        })
      })
    } else {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.OOPS,
        "Daily limit exceeded..."
      )
    }
  }

  onMouseEnter = () => {
    this.setState({ dropdownOpen: true })
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  onMouseLeave = () => {
    this.setState({ dropdownOpen: false })
  }

  render() {
    return this.props.openMessageModal ? (
      <div
        className="modal right slide-in-right fade show"
        style={{ display: "block" }}
        // data-backdrop="static"
        // id="messageModal"
        // role="dialog"
        // aria-labelledby="myModalLabel2"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div
              className="modal-body p-4"
              style={{ backgroundColor: "#F1F7F7", borderRadius: 12 }}
            >
              <div className="d-flex pt-2 justify-content-between">
                <div style={{ width: "6rem" }}>
                  <h5
                    className="mb-4 text-brand font-weight-bold"
                    onClick={getTeacherAssignedDetails}
                  >
                    CREATE MESSAGE
                  </h5>
                </div>

                <div>
                  <UncontrolledDropdown
                    onMouseOver={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                    isOpen={this.state.dropdownOpen}
                    toggle={this.toggle}
                  >
                    <DropdownToggle
                      direction={"left"}
                      tag="a"
                      className="nav-link pt-0"
                    >
                      <div disabled className="btn bg-theme-verylight">
                        Daily Usage: Email - {this.props.dailyEmailUsage}/50,
                        Sms - {this.props.dailySmsUsage}/50
                        <i
                          className="fa fa-info-circle text-dark pl-3"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </DropdownToggle>
                    <DropdownMenu style={{ width: "15em" }}>
                      <div className="card-body flex-column align-items-center justify-content-center br-10">
                        <div className="d-flex row align-items-center justify-content-center">
                          <div>
                            <h6
                              className="pt-1 text-dark mb-1"
                              style={{ right: 0 }}
                            >
                              Daily Email Usage: {this.props.dailyEmailUsage}/50
                            </h6>
                          </div>
                          <div
                            className="align-items-center justify-content-center mt-2"
                            style={{ width: "10vh", height: "10vh" }}
                          >
                            <CircularProgressbar
                              value={this.props.dailyEmailUsage}
                              text={`${this.props.dailyEmailUsage}`}
                              styles={buildStyles({
                                pathColor: `#0fb293`,
                                textColor: "#000000",
                                trailColor: "rgba(71, 153, 151, .22)",
                              })}
                            />
                          </div>
                        </div>
                        <div className="d-flex row align-items-center justify-content-center mt-2">
                          <div>
                            <h6
                              className="pt-1 text-dark mb-1"
                              style={{ right: 0 }}
                            >
                              Daily Sms Usage: {this.props.dailySmsUsage}/50
                            </h6>
                          </div>
                          <div
                            className="justify-content-center mt-2"
                            style={{ width: "10vh", height: "10vh" }}
                          >
                            <CircularProgressbar
                              value={this.props.dailySmsUsage}
                              text={`${this.props.dailySmsUsage}`}
                              styles={buildStyles({
                                pathColor: `#0fb293`,
                                textColor: "#000000",
                                trailColor: "rgba(71, 153, 151, .22)",
                              })}
                            />
                          </div>
                        </div>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>

                <div style={{ height: "14px" }}>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      this.props.saveOpenMessageModalInStore(false)
                      this.clearObject()
                    }}
                  >
                    <span
                      aria-hidden="true"
                      style={{ verticalAlign: "text-bottom" }}
                    >
                      &times;
                    </span>
                  </button>
                </div>
              </div>

              {this.props.dailyUsage > 100 ? (
                <>{"daily limit is over"}</>
              ) : (
                <form>
                  <div className="form-group">
                    <label>Class</label>
                    <div>
                      <Select
                        placeholder="Select Class"
                        isClearable
                        value={this.state.selectedClass}
                        options={(this.state.classesList || []).map(c => ({
                          id: c.sectionId,
                          class: `${c.className}${c.sectionName}`,
                          label: `${c.className}${c.sectionName}`,
                          value: `${c.className}${c.sectionName}`,
                          classNumber: `${c.className}`,
                          board: `${c.school.board}`,
                        }))}
                        className="basic-multi-select mb-3"
                        classNamePrefix="select"
                        hideSelectedOptions={false}
                        closeMenuOnSelect={true}
                        onChange={(selectedOption, triggeredAction) => {
                          if (selectedOption) {
                            this.setState(
                              {
                                selectedClass: selectedOption,
                              },
                              () => {
                                this.getStudents(selectedOption.id)
                              }
                            )
                          } else {
                            this.setState({
                              selectedClass: null,
                              classStudentsList: null,
                            })
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label>To</label>
                    <div>
                      <MySelect
                        placeholder="Select Student"
                        name="classes"
                        isClearable
                        value={this.state.assignedStudents}
                        options={(this.state.classStudentsList || []).map(
                          c => ({
                            id: c.studentId,
                            label: c.studentName || c.fullName,
                            value: c.studentName || c.fullName,
                            grade: c.grade,
                          })
                        )}
                        className="basic-multi-select mb-3"
                        classNamePrefix="select"
                        isMulti
                        allowSelectAll={true}
                        components={{
                          Option,
                          MultiValue,
                          ValueContainer,
                          animatedComponents,
                        }}
                        hideSelectedOptions={false}
                        closeMenuOnSelect={false}
                        styles={this.style}
                        onChange={selectedOption => {
                          if (selectedOption !== null) {
                            this.setState({
                              assignedStudents: selectedOption,
                              assignedStudentId: selectedOption.id,
                              assignedStudentName: selectedOption.fullName,
                            })
                          } else {
                            this.setState({
                              assignedStudents: selectedOption,
                            })
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Subject</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control mr-2 rounded"
                        value={this.state.messageSubject}
                        onChange={e =>
                          this.setState({ messageSubject: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  {this.state.sendEmail && (
                    <div>
                      <div className="row mb-2">
                        <span className="col-sm ml-2 font-weight-bold fs-12 text-primary">
                          <label>
                            <input
                              type="file"
                              multiple="multiple"
                              style={{ display: "none" }}
                              onChange={e => {
                                const files = e.target.files
                                if (files) {
                                  for (const file of files) {
                                    if (file.size > MAX_FILE_UPLOAD) {
                                      return this.props.showNotification(
                                        DANGER_TOAST,
                                        VALIDATION_MSG.OOPS,
                                        "File size should be less than 20MB..."
                                      )
                                    }
                                  }
                                  this.setState(state => ({
                                    attachments: [
                                      ...state.attachments,
                                      ...files,
                                    ],
                                  }))
                                }
                              }}
                            />
                            <u>+ Add Attachment</u>
                            <i className="fa fa-paperclip pl-1 col-sm-1 ml-0 pl-0 text-primary fs-15" />
                          </label>
                        </span>
                      </div>
                      {this.state.attachments.map((attachment, index) => (
                        <div className="d-flex flex-column justify-content-start">
                          <div className="row m-2 mt-0 d-flex row bg-theme-verylight rounded-lg justify-content-between">
                            <div className="row">
                              <div
                                className="bg-theme-light rounded p-3"
                                style={{ width: "58px", height: "58px" }}
                              >
                                {attachment.type === "application/pdf" ? (
                                  <img
                                    src={require("../../assets/images/assignment/pdf.svg")}
                                    alt="pdf"
                                    width="30"
                                    height="30"
                                  />
                                ) : (
                                  <i
                                    className="fa fa-paperclip "
                                    style={{
                                      fontSize: "35px",
                                      color: "#0DB191",
                                    }}
                                  ></i>
                                )}
                              </div>
                              <div className="ml-3 align-self-center">
                                {attachment.name}
                              </div>
                            </div>
                            <div className="mr-3 align-self-center">
                              <i
                                className={`far fa-times fa-clock-hover`}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Remove"
                                onClick={() => {
                                  this.setState(state => ({
                                    attachments: state.attachments
                                      .map((e, i) =>
                                        i !== index ? e : undefined
                                      )
                                      .filter(x => x),
                                  }))
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="form-group">
                    <label htmlFor="Label" className="font-weight-bold">
                      Label
                    </label>
                    <select
                      className="custom-select"
                      value={this.state.labelId}
                      onChange={e => {
                        let labelId = e.target.value
                        if (labelId === "Select a label") {
                          labelId = ""
                        }
                        this.setState({ labelId })
                      }}
                    >
                      <option>Select a label</option>
                      {this.props.labelList &&
                        this.props.labelList.map((label, i) => (
                          <option value={label.id} key={i}>
                            {label.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <button
                      className={`btn primary-outline-btn right-br ${
                        this.state.sendEmail
                          ? "primary-btn text-light"
                          : "btn-hover"
                      }`}
                      type="button"
                      onClick={() =>
                        this.setState({ sendEmail: !this.state.sendEmail })
                      }
                    >
                      <i className="fas fa-at mr-2"></i>EMAIL
                    </button>
                    <button
                      className={`btn primary-outline-btn left-br ${
                        this.state.sendSms
                          ? "primary-btn text-light"
                          : "btn-hover"
                      }`}
                      type="button"
                      onClick={() =>
                        this.setState({ sendSms: !this.state.sendSms })
                      }
                    >
                      <i className="fas fa-mobile-alt mr-2"></i>SMS
                    </button>
                    <i
                      className="fas fa-info-circle ml-3"
                      id="sms-info"
                      aria-hidden="true"
                    ></i>
                    <UncontrolledTooltip
                      placement="top"
                      className="bg-danger"
                      target="sms-info"
                    >
                      Select mode of communication, you can select both and send
                      the message in email as well as SMS at the same time
                    </UncontrolledTooltip>
                    <br />
                  </div>

                  <div className="form-group">
                    <label>Message</label>
                    <textarea
                      rows={8}
                      className="form-control"
                      value={this.state.messageBody}
                      onChange={e =>
                        this.setState({ messageBody: e.target.value })
                      }
                    />
                  </div>
                  <button
                    type="button"
                    className="btn bg-theme text-white"
                    disabled={this.state.isShowSaveLoading}
                    onClick={this.clickSend}
                  >
                    {this.state.isShowSaveLoading ? (
                      <HashLoader
                        sizeUnit={"px"}
                        size={16}
                        color={"#ffffff"}
                        loading={this.state.isShowSaveLoading}
                      />
                    ) : (
                      "Send"
                    )}
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    ) : null
  }
}

const mapStateToProps = state => {
  return {
    studentsList: state.communicationDetailsState.studentsList,
    openMessageModal: state.communicationDetailsState.openMessageModal,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getTeachersDetails,
      saveCommunicationDetails,
      showNotification,
      saveOpenMessageModalInStore,
      getTeacherAssignedDetails,
      getClassStudents,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(MessageModal)
