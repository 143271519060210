/*************************************************
 * RedInk
 * @exports
 * @class index.js
 * @extends Component
 * @author Sasidharan // on 01/02/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import AppWrapper from "./Wrapper"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import MessageModal from "./MessageModal"
import { getQuickInfoDetails } from "../../actions/CommonAction"
import { getSuperAdminDetails } from "../../actions/founder/SuperAdminDetailsAction"
import { myLog } from "../../utils/Utility"

class FounderDashboard extends Component {
  constructor(props) {
    super(props)
    document.title = "Dashboard | Redink"
    this.state = {
      isMessageModalOpen: false,
      isSchoolDetailsModalOpen: false,
      clearSchoolDetailsModal: false,
      savingSchoolDetails: false,

      superAdminCount: "9",
      adminCount: "20",
      teacherCount: "809",
      studentCount: "30,678",

      notpaidlist: [
        {
          schoolName: "Ebu International",
          chairmanName: "Ebu",
          emailId: "ebu123@gmail.com",
          contactNumber: "1234567890",
          city: "Chennai",
        },
        {
          schoolName: "Alex CBSE Public School",
          chairmanName: "Alex",
          emailId: "alexarasu1999@gmail.com",
          contactNumber: "1234567890",
          city: "Munnar",
        },
      ],

      schoolList: [],
    }
  }

  componentDidMount = () => {
    this.props.getQuickInfoDetails(() => {})
    this.props.getSuperAdminDetails((success, response) => {
      myLog(response)
      if (success) {
        this.setState({
          schoolList: response,
        })
      }
    })
  }

  // Handles School Details Modal
  closeSchoolDetailsModal = () =>
    this.setState({ isSchoolDetailsModalOpen: false })
  // Handles Message Modal
  closeMessageModal = () => this.setState({ isMessageModalOpen: false })
  //  Handles on click message icon in sidebar
  onClickMessageIcon = () => {
    this.setState({ isMessageModalOpen: !this.state.isMessageModalOpen })
  }
  // Handles message submit
  onSubmitMessage = () => {
    this.closeMessageModal()
  }
  // edit function
  // editChange = (e, school) => {
  //   window.history.pushState(school, "edit_super_admin", "/founder/superAdmin/edit")
  //   myLog("EDIT SUPER ADMIN DETAILS ==>", school)
  // }

  render() {
    return (
      <AppWrapper
        openMessageModal={() => this.onClickMessageIcon()}
        founderDashboard
      >
        <MessageModal
          isModalOpen={this.state.isMessageModalOpen}
          closeModal={() => this.onClickMessageIcon()}
          onSubmitMessage={() => this.onSubmitMessage()}
        />

        {/* DASHBOARD */}
        <div className="foundercount card-deck">
          <div className="superadmin_count card ">
            <div className="card-body text-white">
              <h6>SUPER ADMIN</h6>
              <h5 className="float-right font-weight-bold">
                {this.state.superAdminCount}
              </h5>
            </div>
          </div>

          <div className="admin_count card ">
            <div className="card-body text-white">
              <h6>ADMIN</h6>
              <h5 className="float-right font-weight-bold">
                {this.state.adminCount}
              </h5>
            </div>
          </div>

          <div className="teacher_count card">
            <div className="card-body text-white">
              <h6>TEACHER</h6>
              <h5 className="float-right font-weight-bold">
                {this.state.teacherCount}
              </h5>
            </div>
          </div>

          <div className="student_count card ">
            <div className="card-body text-white">
              <h6>STUDENT</h6>
              <h5 className="float-right font-weight-bold">
                {this.state.studentCount}
              </h5>
            </div>
          </div>
        </div>

        <div className="table_row row mt-4 pt-4">
          <div className="container col-md-10">
            <div className="school_table_card card table-responsive">
              <table className="table table-hover">
                <thead className="text-white">
                  <tr>
                    <th scope="col">School Name</th>
                    <th scope="col">Chairman Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">City</th>
                    {/* <th scope="col">Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {this.state.schoolList &&
                    this.state.schoolList.map(school => (
                      <tr>
                        <td>{school.tentantFullName}</td>
                        <td>{school.chairmanName}</td>
                        <td>{school.tentantEmail}</td>
                        <td>{school.tentantPhone}</td>
                        <td>{school.city}</td>
                        {/* <td>
                        <button
                          className="btn badge badge-primary p-1"
                          onClick={(e) => this.editChange(e, school)}
                        >
                          Edit
                        </button>
                      </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* <div className="container col-md-3">
            <div className="not_paid_card card table-responsive">
              <div className="card-header d-flex justify-content-center">
                <h4 className="text-danger font-weight-bold">Not Paid Users</h4>
              </div>
              <table className="table table-hover">
                <thead className="text-white">
                  <tr>
                    <th scope="col">School Name</th>
                    <th scope="col">Chairman Name</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.notpaidlist.map(school => (
                    <tr>
                      <td>{school.schoolName}</td>
                      <td>{school.chairmanName}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div> */}
        </div>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getQuickInfoDetails,
      getSuperAdminDetails,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(FounderDashboard)
