/*************************************************
 * RedInk
 * @exports
 * @class StudentRecord.js
 * @extends Component
 * @author Sasidharan // on 01/02/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { CustomInput, FormGroup, Label } from "reactstrap"
import { bindActionCreators } from "redux"
import { HashLoader } from "react-spinners"
import { DatePicker } from "antd"
import { connect } from "react-redux"
import moment from "moment"
import "antd/dist/antd.css"

import {
  saveExamHierarchyFAsDate,
  saveExamHierarchyFAsList,
  saveExamHierarchyTermsList,
  saveExamHierarchyTheoryList,
  saveExamHierarchySubjectList,
  saveExamHierarchyActivityList,
  saveExamHierarchyPracticalList,
  saveExamHierarchyDetailsInStore,
  saveExamHierarchyAcademicObject,
  saveExamHierarchyFAsListInStore,
  saveExamHierarchyTermsListInStore,
  saveExamHierarchySubjectListInStore,
  saveExamHierarchyActivityListInStore,
  saveExamHierarchySettingsDetailsInStore,
} from "../../../actions/teacher/HierarchyAction"
import { DANGER_TOAST, VALIDATION_MSG } from "../../../utils/Constants"
import { saveIsShowLoadingInStore } from "../../../actions/LoginAction"
import { showNotification } from "../../../actions/CommonAction"

import { ReactComponent as Arrow } from "../../../assets/images/arrow.svg"

const { RangePicker } = DatePicker

function isEditedChecker(state, obj) {
  if (state.hierarchyName === "ASSESSMENT" || obj.isNewBox) return true

  let edited = false

  if (
    state.hierarchyName === "SUBJECT" ||
    state.hierarchyName === "THEORY AND PRACTICAL" ||
    state.hierarchyName === "ACTIVITY"
  ) {
    edited =
      state.maxMark !== obj.maxMark ||
      state.passMark !== obj.passMark ||
      state.distinctionMark !== obj.distinctionMark ||
      state.needToConvertMark !== obj.needToConvertMark ||
      Number(state.convertMark) !== Number(obj.convertMark)
  }

  if (edited) return true

  if (state.hierarchyName === "SUBJECT") {
    edited =
      state.examType !== obj.examType ||
      state.examDay !== obj.examDay ||
      state.considerMarkToAssessmentTotal !==
        obj.considerMarkToAssessmentTotal ||
      state.requiredToPassToGetRank !== obj.requiredToPassToGetRank
  }

  if (edited) return true

  if (
    state.hierarchyName === "THEORY AND PRACTICAL" ||
    state.hierarchyName === "ACTIVITY"
  ) {
    edited =
      state?.includeMarkToSubjectTotal !== obj?.includeMarkToSubjectTotal ||
      !!state?.requiredToPassToGetRank !== !!obj?.requiredToPassToGetRank
  }

  if (edited) return true

  if (
    state.hierarchyName === "THEORY AND PRACTICAL" &&
    state.hierarchyObject.activities.length > 0
  ) {
    edited = state?.selectedActivityCondition !== obj?.activityConditionValue
  }

  if (state.hierarchyName === "ACTIVITY") {
    edited = state.shortName !== obj.shortName
  }

  return edited
}

class ExamHierarchySettings extends Component {
  static getDerivedStateFromProps(props, state) {
    let arrayDate =
      props.examHierarchySettingObject && props.examHierarchySettingObject.date
        ? props.examHierarchySettingObject.date
        : ""

    let array = []
    if (arrayDate.length > 0) {
      if (arrayDate[0] !== null && arrayDate[1] !== null) {
        array.push(moment(arrayDate[0]))
        array.push(moment(arrayDate[1]))
      } else {
        array.push("")
        array.push("")
      }
    } else {
      array.push("")
      array.push("")
    }

    return {
      hierarchyName: props.name,
      hierarchyIndex: props.index,
      hierarchyObject: props.object,
      classSubjectId: props.examHierarchySettingObject
        ? props.examHierarchySettingObject.classSubjectId
        : props.classSubjectId,
      selectedSubject: props.examHierarchySettingObject
        ? props.examHierarchySettingObject.classSubjectId
        : props.classSubjectId,
      name:
        props.examHierarchySettingObject &&
        props.examHierarchySettingObject.name
          ? props.examHierarchySettingObject.name
          : "",
      shortName:
        props.examHierarchySettingObject &&
        props.examHierarchySettingObject.shortName
          ? props.examHierarchySettingObject.shortName
          : "",
      mustPass:
        props.examHierarchySettingObject &&
        props.examHierarchySettingObject.mustPass
          ? props.examHierarchySettingObject.mustPass
          : false,
      termScoreEvaluation:
        props.examHierarchySettingObject &&
        props.examHierarchySettingObject.termScoreEvaluation
          ? props.examHierarchySettingObject.termScoreEvaluation
          : false,
      maxMark:
        props.examHierarchySettingObject &&
        props.examHierarchySettingObject.maxMark
          ? props.examHierarchySettingObject.maxMark
          : "",
      minMark:
        props.examHierarchySettingObject &&
        props.examHierarchySettingObject.minMark
          ? props.examHierarchySettingObject.minMark
          : "",
      passMark:
        props.examHierarchySettingObject &&
        props.examHierarchySettingObject.passMark
          ? props.examHierarchySettingObject.passMark
          : "",
      distinctionMark:
        props.examHierarchySettingObject &&
        props.examHierarchySettingObject.distinctionMark
          ? props.examHierarchySettingObject.distinctionMark
          : "",
      convertMark:
        props.examHierarchySettingObject &&
        props.examHierarchySettingObject.convertMark
          ? props.examHierarchySettingObject.convertMark
          : "",
      needToConvertMark: props.examHierarchySettingObject
        ? props.examHierarchySettingObject.needToConvertMark
        : "",
      grades: props.examHierarchySettingObject
        ? props.examHierarchySettingObject.grades
        : [],
      considerMarkToAssessmentTotal:
        props.examHierarchySettingObject &&
        props.examHierarchySettingObject.considerMarkToAssessmentTotal
          ? props.examHierarchySettingObject.considerMarkToAssessmentTotal
          : false,
      requiredToPassToGetRank:
        props.examHierarchySettingObject &&
        props.examHierarchySettingObject.requiredToPassToGetRank
          ? props.examHierarchySettingObject.requiredToPassToGetRank
          : false,
      includeMarkToSubjectTotal:
        props.examHierarchySettingObject &&
        props.examHierarchySettingObject.includeMarkToSubjectTotal
          ? props.examHierarchySettingObject.includeMarkToSubjectTotal
          : false,
      selectedActivityCondition:
        props.examHierarchySettingObject &&
        props.examHierarchySettingObject.activityConditionValue
          ? props.examHierarchySettingObject.activityConditionValue
          : "0",
      theoryConvertMarkTo:
        props.examHierarchySettingObject &&
        props.examHierarchySettingObject.theoryConvertMarkTo
          ? props.examHierarchySettingObject.theoryConvertMarkTo
          : "",

      theoryMark:
        props.examHierarchySettingObject &&
        props.examHierarchySettingObject.theoryMark
          ? props.examHierarchySettingObject.theoryMark
          : "",

      needToConvertTheoryMark: props.examHierarchySettingObject
        ? props.examHierarchySettingObject.needToConvertTheoryMark
        : "",
      internalMark:
        props.examHierarchySettingObject &&
        props.examHierarchySettingObject.internalMark
          ? props.examHierarchySettingObject.internalMark
          : "",
      typeOfAssessment:
        props.examHierarchySettingObject &&
        props.examHierarchySettingObject.typeOfAssessment
          ? props.examHierarchySettingObject.typeOfAssessment
          : "",
      examType:
        props.examHierarchySettingObject &&
        props.examHierarchySettingObject.examType
          ? props.examHierarchySettingObject.examType
          : "",
      date: array,
      examDay:
        props.examHierarchySettingObject &&
        props.examHierarchySettingObject.examDay
          ? props.examHierarchySettingObject.examDay
          : "",
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      hierarchyName: this.props.name,
      hierarchyIndex: this.props.index,
      hierarchyObject: this.props.object,
      classId: this.props.classId,
      className: this.props.className,
      academicId: this.props.academicId,
      academicName: this.props.academicName,
      name: "",
      selectedSubject: this.props.classSubjectId,
      shortName: "",
      mustPass: false,
      termScoreEvaluation: false,
      maxMark: "",
      minMark: "",
      passMark: "",
      distinctionMark: "",
      convertMark: "",
      needToConvertMark: false,
      grades: [{ gradeName: "a1", gradeFrom: "90", gradeTo: "100" }],
      isShowEditGrade: false,
      considerMarkToAssessmentTotal: false,
      requiredToPassToGetRank: false,
      includeMarkToSubjectTotal: false,
      classSubjectId: this.props.classSubjectId,
      isShowLoading: false,
      selectedActivityCondition: 0,
      internalMark: "",
      typeOfAssessment: "",
      showMoreOnSubject: false,
      assessmentList: [
        {
          id: 1,
          value: "FA",
          keyName: "Formative/continuous assessment",
        },
        {
          id: 2,
          value: "SA",
          keyName: "Subjective/semester assessment",
        },
        {
          id: 3,
          value: "CT",
          keyName: "Cycle test",
        },
        {
          id: 4,
          value: "UT",
          keyName: "Unit test",
        },
        {
          id: 5,
          value: "ACT",
          keyName: "Activities",
        },
        {
          id: 6,
          value: "QAU",
          keyName: "Quarterly",
        },
        {
          id: 7,
          value: "HY",
          keyName: "Half-yearly",
        },
        {
          id: 8,
          value: "FE",
          keyName: "Final exams",
        },
        {
          id: 9,
          value: "ME",
          keyName: "Model exam",
        },
        {
          id: 10,
          value: "WT",
          keyName: "Weekly test",
        },
        {
          id: 11,
          value: "MOT",
          keyName: "Monthly test",
        },
        {
          id: 12,
          value: "MT",
          keyName: "Mid-term",
        },
      ],
      examTypeList: [
        {
          id: 0,
          value: "Only Theory",
        },
        {
          id: 1,
          value: "Only Practical",
        },
        {
          id: 2,
          value: "Theory & Practical",
        },
      ],
      isShowAdvanceSettings: false,
    }
  }

  saveExamHierarchySettings = () => {
    // if (this.state.hierarchyName === "ACADEMIC") {
    //   let object = this.state.hierarchyAcademicObject
    //   object.name = this.state.name
    //   object.shortName = this.state.shortName
    //   object["classId"] = this.state.classId
    //   object["academicId"] = this.state.academicId
    //   this.setState({ isShowLoading: true }, () => {
    //     this.props.saveIsShowLoadingInStore(true)
    //     this.props.saveExamHierarchyAcademicObject(object, success => {
    //       this.props.saveIsShowLoadingInStore(false)
    //       this.setState({ isShowLoading: false })
    //     })
    //   })
    // }

    if (this.state.hierarchyName === "TERM") {
      let object = this.state.hierarchyObject
      object["name"] = this.state.name
      object["shortName"] = this.state.shortName
      object["classId"] = this.state.classId
      object["academicId"] = this.state.academicId
      this.setState({ isShowLoading: true }, () => {
        this.props.saveIsShowLoadingInStore(true)
        this.props.saveExamHierarchyTermsList(object, success => {
          this.props.saveIsShowLoadingInStore(false)
          this.setState({ isShowLoading: false })
        })
      })
    }
    if (this.state.hierarchyName === "ASSESSMENT") {
      if (
        this.state.hierarchyObject &&
        this.state.hierarchyObject.isClassSpecific
      ) {
        let object = this.state.hierarchyObject
        if (this.state.date.length > 0) {
          if (this.state.date[0] !== "")
            object.assessmentFrom = moment(this.state.date[0]).format(
              "YYYY-MM-DD"
            )
          else object.assessmentFrom = ""
          if (this.state.date[1] !== "")
            object.assessmentTo = moment(this.state.date[1]).format(
              "YYYY-MM-DD"
            )
          else object.assessmentTo = ""
        } else {
          object.assessmentFrom = ""
          object.assessmentTo = ""
        }
        // object["term"] = this.state.hierarchyTermObject
        object["name"] = this.state.name
        object["shortName"] = this.state.shortName
        object["classId"] = this.state.classId
        object["academicId"] = this.state.academicId
        object["termScoreEvaluation"] = this.state.termScoreEvaluation
        object["mustPass"] = this.state.mustPass
        object["typeOfAssessment"] = this.state.typeOfAssessment
        // object.assessmentFrom = moment(this.state.date[0]).format("YYYY-MM-DD")
        // object.assessmentTo = moment(this.state.date[1]).format("YYYY-MM-DD")
        // hierarchyArray = this.props.hierarchyFAs
        // hierarchyArray.splice(this.state.hierarchyIndex, 1)
        // hierarchyArray.push(object)
        // this.props.saveExamHierarchyFAsListInStore(hierarchyArray)
        // this.props.saveExamHierarchyFAsList(object,()=>{})
        if (object.assessmentFrom === "") {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            "Need to add date for Assessments..."
          )
        } else {
          this.setState({ isShowLoading: true }, () => {
            this.props.saveIsShowLoadingInStore(true)
            this.props.saveExamHierarchyFAsList(object, success => {
              this.props.saveIsShowLoadingInStore(false)
              this.setState({ isShowLoading: false })
            })
          })
        }
      } else {
        let object = this.state.hierarchyObject
        if (this.state.date.length > 0) {
          if (this.state.date[0] !== "")
            object.assessmentFrom = moment(this.state.date[0]).format(
              "YYYY-MM-DD"
            )
          else object.assessmentFrom = ""
          if (this.state.date[1] !== "")
            object.assessmentTo = moment(this.state.date[1]).format(
              "YYYY-MM-DD"
            )
          else object.assessmentTo = ""
        } else {
          object.assessmentFrom = ""
          object.assessmentTo = ""
        }
        object.classId = this.state.hierarchyObject.sectionId
        if (object.assessmentFrom === "") {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            "Need to add date for Assessments..."
          )
        } else {
          // this.props.saveExamHierarchyFAsDate(object, () => { })
          this.setState({ isShowLoading: true }, () => {
            this.props.saveIsShowLoadingInStore(true)
            this.props.saveExamHierarchyFAsDate(object, success => {
              this.props.saveIsShowLoadingInStore(false)
              this.setState({ isShowLoading: false })
            })
          })
        }
      }
    }
    if (this.state.hierarchyName === "SUBJECT") {
      if (
        this.state.shortName !== "" &&
        this.state.distinctionMark !== "" &&
        this.state.distinctionMark >= 0 &&
        this.state.maxMark !== "" &&
        this.state.maxMark >= 0 &&
        this.state.passMark !== "" &&
        this.state.passMark >= 0
      ) {
        let object = this.state.hierarchyObject
        // object["term"] = this.state.hierarchyTermObject
        // object["fa"] = this.state.hierarchyFAObject
        object["name"] = this.state.name
        object["shortName"] = this.state.shortName
        object["classId"] = this.state.classId
        object["classSubjectId"] = this.state.classSubjectId
        object["academicId"] = this.state.academicId
        object["isSelected"] = true
        object["isLocked"] = false
        object["maxMark"] = this.state.maxMark
        object["minMark"] = this.state.minMark
        object["passMark"] = this.state.passMark
        object["distinctionMark"] = this.state.distinctionMark
        object["convertMark"] = this.state.convertMark
        object["needToConvertMark"] = this.state.needToConvertMark
        object["grades"] = this.state.grades
        object[
          "considerMarkToAssessmentTotal"
        ] = this.state.considerMarkToAssessmentTotal
        object["requiredToPassToGetRank"] = this.state.requiredToPassToGetRank
        object["activityConditionValue"] = this.state.selectedActivityCondition
        object["theoryConvertMarkTo"] = this.state.theoryConvertMarkTo
        object["theoryMark"] = this.state.theoryMark
        object["needToConvertTheoryMark"] = this.state.needToConvertTheoryMark
        object["isShowAdvanceSettings"] = this.state.isShowAdvanceSettings
        object["examType"] = this.state.examType
        object["examDay"] = this.state.examDay
        this.setState({ isShowLoading: true }, () => {
          this.props.saveIsShowLoadingInStore(true)
          this.props.saveExamHierarchySubjectList(object, success => {
            this.props.saveIsShowLoadingInStore(false)
            this.setState({ isShowLoading: false })
          })
        })
      } else {
        this.setState({ hasError: true })
      }
    }
    if (this.state.hierarchyName === "THEORY AND PRACTICAL") {
      // if (
      //   this.state.distinctionMark !== "" &&
      //   this.state.distinctionMark >= 0 &&
      //   this.state.maxMark !== "" &&
      //   this.state.maxMark >= 0 &&
      //   this.state.passMark !== "" &&
      //   this.state.passMark >= 0
      // ) {
      let object = this.state.hierarchyObject
      // object["term"] = this.state.hierarchyTermObject
      // object["fa"] = this.state.hierarchyFAObject
      // object["subject"] = this.state.hierarchySubjectObject
      object["name"] = this.state.name
      object["shortName"] = this.state.shortName
      object["classId"] = this.state.classId
      object["academicId"] = this.state.academicId
      object["isSelected"] = true
      object["isLocked"] = false
      object["maxMark"] = this.state.maxMark
      object["minMark"] = this.state.minMark
      object["passMark"] = this.state.passMark
      object["distinctionMark"] = this.state.distinctionMark
      object["convertMark"] = this.state.convertMark
      object["needToConvertMark"] = this.state.needToConvertMark
      object["grades"] = this.state.grades
      object["includeMarkToSubjectTotal"] = this.state.includeMarkToSubjectTotal
      object["requiredToPassToGetRank"] = this.state.requiredToPassToGetRank
      object["activityConditionValue"] = this.state.selectedActivityCondition
      this.setState({ isShowLoading: true }, () => {
        this.props.saveIsShowLoadingInStore(true)
        if (this.state.hierarchyObject.key === "P") {
          this.props.saveExamHierarchyPracticalList(object, success => {
            this.props.saveIsShowLoadingInStore(false)
            this.setState({ isShowLoading: false })
          })
        } else {
          this.props.saveExamHierarchyTheoryList(object, success => {
            this.props.saveIsShowLoadingInStore(false)
            this.setState({ isShowLoading: false })
          })
        }
      })
      // } else {
      //   this.setState({ hasError: true })
      // }
    }
    if (this.state.hierarchyName === "ACTIVITY") {
      if (
        this.state.shortName !== "" &&
        this.state.distinctionMark !== "" &&
        this.state.maxMark !== "" &&
        this.state.passMark !== ""
      ) {
        let object = this.state.hierarchyObject
        // object["term"] = this.state.hierarchyTermObject
        // object["fa"] = this.state.hierarchyFAObject
        // object["subject"] = this.state.hierarchySubjectObject
        object["name"] = this.state.name
        object["shortName"] = this.state.shortName
        object["classId"] = this.state.classId
        object["academicId"] = this.state.academicId
        object["isSelected"] = true
        object["isLocked"] = false
        object["maxMark"] = this.state.maxMark
        object["minMark"] = this.state.minMark
        object["passMark"] = this.state.passMark
        object["distinctionMark"] = this.state.distinctionMark
        object["convertMark"] = this.state.convertMark
        object["needToConvertMark"] = this.state.needToConvertMark
        object["grades"] = this.state.grades
        object[
          "includeMarkToSubjectTotal"
        ] = this.state.includeMarkToSubjectTotal
        this.setState({ isShowLoading: true }, () => {
          this.props.saveIsShowLoadingInStore(true)
          this.props.saveExamHierarchyActivityList(object, success => {
            this.props.saveIsShowLoadingInStore(false)
            this.setState({ isShowLoading: false })
          })
        })
      } else {
        this.setState({ hasError: true })
      }
    }
  }

  addSubject = () => {
    const tempARR = this.state.grades
    let object = {
      gradeName: "",
      gradeFrom: "",
      gradeTo: "",
    }
    tempARR.push(object)
    this.setState({ grades: tempARR })
  }

  deleteSubject = index => {
    if (this.state.grades.length > 1) {
      let arrSubject = this.state.grades
      arrSubject.splice(index, 1)
      this.setState({ grades: arrSubject })
    }
  }

  clearData = () => {
    this.setState({
      mustPass: false,
      termScoreEvaluation: false,
      maxMark: "",
      minMark: "",
      passMark: "",
      distinctionMark: "",
      convertMark: "",
      needToConvertMark: false,
      grades: [{ gradeName: "a1", gradeFrom: "90", gradeTo: "100" }],
      isShowEditGrade: false,
      considerMarkToAssessmentTotal: false,
      requiredToPassToGetRank: false,
      includeMarkToSubjectTotal: false,
    })
  }

  formatInput = e => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum = e.key === "e" || e.key === "+" || e.key === "-"
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum = e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189
    }
    return checkIfNum && e.preventDefault()
  }

  saveForm = () => {
    if (
      !this.state.hasDistictionInvalidMark &&
      !this.state.hasInvalidMark
      // this.state.needToConvertMark &&
      // this.state.convertMark !==""
    ) {
      if (this.state.hierarchyObject && !this.state.hierarchyObject.isLocked) {
        if (this.state.needToConvertMark && !this.state.convertMark) {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            "convert mark is empty..."
          )
          return
        }
        if (!this.state.isShowLoading) {
          this.saveExamHierarchySettings()
        }
      } else {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Subject is locked..."
        )
      }
    } else {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.OOPS,
        "Invalid mark entry"
      )
    }
  }

  render() {
    const isEdited = isEditedChecker(this.state, this.props.object)

    return (
      <div className="gradebook-setting-wrapper">
        <div
          className="gradebook-setting-wrapper-header d-flex justify-content-between"
          onClick={this.props.closeSettings}
        >
          <h5 className="px-3 pt-2 fs-18 fw-600">
            {this.state.hierarchyName} SETTINGS
          </h5>
          <i
            className="p-2 mr-1 cursor-pointer far fa-times fs-20"
            style={{ color: "#F7F9F9" }}
          />
        </div>

        <div className="justify-content-center" id="academicYear">
          <div className="px-3">
            <div className="py-1">
              {this.state.hierarchyName === "ASSESSMENT" && (
                <div className="mt-2">
                  <select
                    className="form-control"
                    disabled={
                      this.state.hierarchyObject.isNewBox ||
                      this.state.hierarchyObject.isClassSpecific
                        ? false
                        : true
                    }
                    value={this.state.typeOfAssessment}
                    onChange={e => {
                      let findClass = this.state.assessmentList.find(
                        item => item.value === e.target.value
                      )
                      this.setState({
                        typeOfAssessment: findClass.keyName,
                        hasError: false,
                      })
                      let object = this.props.examHierarchySettingObject
                      object.typeOfAssessment = e.target.value
                      this.props.saveExamHierarchySettingsDetailsInStore(object)
                    }}
                  >
                    <option value="">Select</option>
                    {this.state.assessmentList &&
                      this.state.assessmentList.map(item => (
                        <option key={item.value} value={item.value}>
                          {item.keyName}
                        </option>
                      ))}
                  </select>
                </div>
              )}
            </div>

            <div className="py-1">
              {this.state.hierarchyName === "SUBJECT" ? (
                <div className="gradebook-setting-subject-name px-3 py-2">
                  <p className="mb-1 fw-600 text-white">
                    {
                      this.props.subjectList?.find(
                        item => item.subjectId === this.state.selectedSubject
                      )?.value
                    }
                  </p>

                  <p className="mb-0 fs-13" style={{ color: "#C4C0C0" }}>
                    {this.state.shortName}
                  </p>

                  {/* <select
                    className="form-control"
                    disabled={
                      this.state.hierarchyObject.isNewBox ? false : true
                    }
                    value={this.state.selectedSubject}
                    onChange={e => {
                      // this.setState({selectedSubject: e.target.value}, () => {
                      let findClass = this.props.subjectList.find(
                        item => item.subjectId === e.target.value
                      )
                      this.setState({
                        name: findClass.value,
                        selectedSubject: e.target.value,
                        classSubjectId: e.target.value,
                      })
                      let object = this.props.examHierarchySettingObject
                      object.name = findClass.value
                      object.classSubjectId = e.target.value
                      this.props.saveExamHierarchySettingsDetailsInStore(object)
                    }}
                  >
                    <option value="">Select</option>
                    {this.props.subjectList &&
                      this.props.subjectList.map(item => (
                        <option key={item.subjectId} value={item.subjectId}>
                          {item.value}
                        </option>
                      ))}
                  </select> */}
                </div>
              ) : (
                <div>
                  <small className="text-white fw-500">Name</small>
                  <input
                    type="text"
                    disabled={
                      this.state.hierarchyObject.isNewBox ||
                      this.state.hierarchyObject.isClassSpecific
                        ? false
                        : true
                    }
                    className="form-control form-control-sm"
                    value={this.state.name}
                    onChange={e => {
                      this.setState({ name: e.target.value, hasError: false })
                      let object = this.props.examHierarchySettingObject
                      object.name = e.target.value
                      this.props.saveExamHierarchySettingsDetailsInStore(object)
                    }}
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        this.saveForm()
                      }
                    }}
                  />
                  <div className="mt-2 d-flex justify-content-center">
                    {this.state.name === "" && this.state.hasError && (
                      <p className="text-danger">Name is required.</p>
                    )}
                  </div>
                </div>
              )}
            </div>

            {this.state.hierarchyName !== "SUBJECT" && (
              <div className="py-1">
                <small className="text-white fw-500">Short Name</small>
                <input
                  type="text"
                  disabled={
                    this.state.hierarchyName === "SUBJECT" ||
                    this.state.hierarchyName === "ACTIVITY" ||
                    this.state.hierarchyObject.isClassSpecific
                      ? false
                      : this.state.hierarchyObject.isNewBox
                      ? false
                      : true
                  }
                  className="form-control form-control-sm"
                  value={this.state.shortName}
                  onChange={e => {
                    this.setState({
                      shortName: e.target.value,
                      hasError: false,
                    })
                    let object = this.props.examHierarchySettingObject
                    object.shortName = e.target.value
                    this.props.saveExamHierarchySettingsDetailsInStore(object)
                  }}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      this.saveForm()
                    }
                  }}
                />
                <div className="mt-2 d-flex justify-content-center">
                  {this.state.shortName === "" && this.state.hasError && (
                    <p className="text-danger">Short Name is required.</p>
                  )}
                </div>
              </div>
            )}

            {this.state.hierarchyName === "ASSESSMENT" && (
              <div className="text-white">
                <div className="py-1">
                  <small className="text-white fw-500">Assessment Date</small>
                  <RangePicker
                    disabled={
                      this.state.hierarchyObject &&
                      this.state.hierarchyObject.isClassSpecific
                        ? false
                        : true
                    }
                    onChange={date => {
                      if (date !== null) {
                        this.setState({ date, hasError: false })
                        let object = this.props.examHierarchySettingObject
                        object.date = date
                        this.props.saveExamHierarchySettingsDetailsInStore(
                          object
                        )
                      } else {
                        this.setState({ date: [], hasError: false })
                        let object = this.props.examHierarchySettingObject
                        object.date = []
                        this.props.saveExamHierarchySettingsDetailsInStore(
                          object
                        )
                      }
                    }}
                    value={this.state.date}
                  />
                </div>
                <div className="py-1 mt-2">
                  <FormGroup>
                    <Label
                      htmlFor="termScoreEvaluation"
                      className="fs-11 fw-500"
                    >
                      Consider this assessment for term score evaluation?
                    </Label>
                    <div className="row">
                      <div className="px-5 radio">
                        <label className="fs-11 d-flex align-items-center">
                          <input
                            type="radio"
                            name="termScoreEvaluation"
                            value="Yes"
                            checked={this.state.termScoreEvaluation}
                            onChange={e => {
                              if (
                                this.state.hierarchyObject &&
                                this.state.hierarchyObject.isClassSpecific
                              ) {
                                this.setState({
                                  termScoreEvaluation: true,
                                })
                                let object = this.props
                                  .examHierarchySettingObject
                                object.termScoreEvaluation = !this.state
                                  .termScoreEvaluation
                                this.props.saveExamHierarchySettingsDetailsInStore(
                                  object
                                )
                              } else {
                                this.props.showNotification(
                                  DANGER_TOAST,
                                  VALIDATION_MSG.OOPS,
                                  "No access to update this question"
                                )
                              }
                            }}
                          />
                          &ensp;Yes
                        </label>
                      </div>
                      <div className="px-5 radio">
                        <label className="fs-11 d-flex align-items-center">
                          <input
                            type="radio"
                            name="termScoreEvaluation"
                            value="No"
                            checked={!this.state.termScoreEvaluation}
                            onChange={e => {
                              if (
                                this.state.hierarchyObject &&
                                this.state.hierarchyObject.isClassSpecific
                              ) {
                                this.setState({
                                  termScoreEvaluation: false,
                                })
                                let object = this.props
                                  .examHierarchySettingObject
                                object.termScoreEvaluation = !this.state
                                  .termScoreEvaluation
                                this.props.saveExamHierarchySettingsDetailsInStore(
                                  object
                                )
                              } else {
                                this.props.showNotification(
                                  DANGER_TOAST,
                                  VALIDATION_MSG.OOPS,
                                  "No access to update this question"
                                )
                              }
                            }}
                          />
                          &ensp;No
                        </label>
                      </div>
                    </div>
                  </FormGroup>
                </div>
                <div className="py-1">
                  <FormGroup>
                    <Label htmlFor="mustPass" className="fs-11 fw-500">
                      Must pass this exam to get term rank?
                    </Label>
                    <div className="row">
                      <div className="px-5 radio">
                        <label className="fs-11 d-flex align-items-center">
                          <input
                            type="radio"
                            name="mustPass"
                            value="Yes"
                            checked={this.state.mustPass}
                            onChange={e => {
                              if (
                                this.state.hierarchyObject &&
                                this.state.hierarchyObject.isClassSpecific
                              ) {
                                this.setState({
                                  mustPass: true,
                                })
                                let object = this.props
                                  .examHierarchySettingObject
                                object.mustPass = !this.state.mustPass
                                this.props.saveExamHierarchySettingsDetailsInStore(
                                  object
                                )
                              } else {
                                this.props.showNotification(
                                  DANGER_TOAST,
                                  VALIDATION_MSG.OOPS,
                                  "No access to update this question"
                                )
                              }
                            }}
                          />
                          &ensp;Yes
                        </label>
                      </div>
                      <div className="px-5 radio">
                        <label className="fs-11 d-flex align-items-center">
                          <input
                            type="radio"
                            name="mustPass"
                            value="No"
                            checked={!this.state.mustPass}
                            onChange={e => {
                              if (
                                this.state.hierarchyObject &&
                                this.state.hierarchyObject.isClassSpecific
                              ) {
                                this.setState({
                                  mustPass: false,
                                })
                                let object = this.props
                                  .examHierarchySettingObject
                                object.mustPass = !this.state.mustPass
                                this.props.saveExamHierarchySettingsDetailsInStore(
                                  object
                                )
                              } else {
                                this.props.showNotification(
                                  DANGER_TOAST,
                                  VALIDATION_MSG.OOPS,
                                  "No access to update this question"
                                )
                              }
                            }}
                          />
                          &ensp;No
                        </label>
                      </div>
                    </div>
                  </FormGroup>
                </div>
              </div>
            )}

            <div
              className={
                this.state.hierarchyName === "SUBJECT"
                  ? "gradebook-subject-setting-box custom-scrollbar"
                  : ""
              }
            >
              {this.state.hierarchyName === "SUBJECT" && (
                <div>
                  <div className="py-1 mb-2">
                    <small className="text-white fw-500">Exam Type</small>
                    <div>
                      <select
                        className="form-control"
                        value={this.state.examType}
                        onChange={e => {
                          this.setState({
                            examType: e.target.value,
                          })
                          let object = this.props.examHierarchySettingObject
                          object.examType = e.target.value
                          this.props.saveExamHierarchySettingsDetailsInStore(
                            object
                          )
                        }}
                      >
                        <option value="">Select</option>
                        {this.state.examTypeList &&
                          this.state.examTypeList.map(item => (
                            <option key={item.id} value={item.id}>
                              {item.value}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="py-1 mb-2">
                    <small className="text-white fw-500">Exam Date</small>
                    <div className="form-group">
                      <DatePicker
                        className="form-control"
                        format="DD-MM-YYYY"
                        value={
                          this.state.examDay !== ""
                            ? moment(this.state.examDay)
                            : ""
                        }
                        bordered={true}
                        allowClear={false}
                        onChange={e => {
                          this.setState({ examDay: e })
                          let object = this.props.examHierarchySettingObject
                          object.examDay = moment(e).format("YYYY-MM-DD")
                          this.props.saveExamHierarchySettingsDetailsInStore(
                            object
                          )
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {(this.state.hierarchyName === "SUBJECT" ||
                (this.state.hierarchyName === "THEORY AND PRACTICAL" &&
                  this.state.hierarchyObject.activities.length === 0) ||
                this.state.hierarchyName === "ACTIVITY") && (
                <div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <small className="text-white fw-500">
                          Max mark <span style={{ color: "red" }}>*</span>
                        </small>
                        <input
                          type="number"
                          className="form-control form-control-sm"
                          value={this.state.maxMark}
                          onChange={e => {
                            const value = e.target.value
                            if (value.length < 4) {
                              this.setState({
                                maxMark: e.target.value,
                                hasError: false,
                              })
                              let object = this.props.examHierarchySettingObject
                              object.maxMark = e.target.value
                              this.props.saveExamHierarchySettingsDetailsInStore(
                                object
                              )
                            }
                          }}
                          onKeyDown={e => {
                            if (e.key === "Enter") {
                              this.saveForm()
                            }
                          }}
                        />

                        <div className="mt-2 d-flex justify-content-center">
                          {this.state.hasError &&
                            (this.state.maxMark === "" ? (
                              <p className="text-danger">
                                Max mark is required.
                              </p>
                            ) : this.state.maxMark < 0 ? (
                              <p className="text-danger">
                                Max mark cannot be negative
                              </p>
                            ) : null)}
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        <small className="text-white fw-500">
                          Pass mark <span style={{ color: "red" }}>*</span>
                        </small>
                        <input
                          type="number"
                          className="form-control form-control-sm"
                          value={this.state.passMark}
                          onChange={e => {
                            if (e.target.value.length < 6) {
                              this.setState({
                                passMark: e.target.value,
                                hasError: false,
                                hasInvalidMark: false,
                              })
                              let object = this.props.examHierarchySettingObject
                              object.passMark = e.target.value
                              this.props.saveExamHierarchySettingsDetailsInStore(
                                object
                              )
                              if (
                                e.target.value > parseInt(this.state.maxMark)
                              ) {
                                this.setState({
                                  hasInvalidMark: true,
                                })
                              }
                            }
                          }}
                          onKeyDown={e => {
                            if (e.key === "Enter") {
                              this.saveForm()
                            }
                          }}
                        />
                        <div className="mt-2 d-flex justify-content-center">
                          {this.state.hasError &&
                            (this.state.passMark === "" ? (
                              <p className="text-danger">
                                Pass mark is required.
                              </p>
                            ) : this.state.hasInvalidMark ? (
                              <p className="text-danger">
                                Pass mark more than Max mark.
                              </p>
                            ) : this.state.passMark < 0 ? (
                              <p className="text-danger">
                                pass mark cannot be negative
                              </p>
                            ) : null)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <small className="text-white fw-500">
                          Distinction <span style={{ color: "red" }}>*</span>
                        </small>
                        <input
                          type="number"
                          className="form-control form-control-sm"
                          value={this.state.distinctionMark}
                          onChange={e => {
                            if (
                              e.target.value.length < 6 &&
                              this.state.maxMark
                            ) {
                              this.setState({
                                distinctionMark: e.target.value,
                                hasError: false,
                                hasDistictionInvalidMark: false,
                              })
                              let object = this.props.examHierarchySettingObject
                              object.distinctionMark = e.target.value
                              this.props.saveExamHierarchySettingsDetailsInStore(
                                object
                              )
                              if (
                                e.target.value > parseInt(this.state.maxMark)
                              ) {
                                this.setState({
                                  hasDistictionInvalidMark: true,
                                })
                              }
                            }
                          }}
                          onKeyDown={e => {
                            if (e.key === "Enter") {
                              this.saveForm()
                            }
                          }}
                        />
                        <div className="mt-2 d-flex justify-content-center">
                          {this.state.hasError &&
                            (this.state.distinctionMark === "" ? (
                              <p className="text-danger">
                                Distinction mark is required.
                              </p>
                            ) : this.state.hasDistictionInvalidMark ? (
                              <p className="text-danger">
                                Distinction mark more than Max mark.
                              </p>
                            ) : this.state.distinctionMark < 0 ? (
                              <p className="text-danger">
                                Distinction mark cannot be negative
                              </p>
                            ) : null)}
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        <div
                          className="row justify-content-between"
                          style={{ marginRight: "0px", marginLeft: "0px" }}
                        >
                          <small className="text-white fw-500">
                            Convert Mark
                          </small>
                          <div style={{ marginLeft: "3px" }}>
                            <CustomInput
                              checked={this.state.needToConvertMark}
                              type="switch"
                              id="exampleCustomSwitch3"
                              name="customSwitch"
                              onChange={() => {
                                let object = this.props
                                  .examHierarchySettingObject
                                object.needToConvertMark = !this.state
                                  .needToConvertMark
                                this.props.saveExamHierarchySettingsDetailsInStore(
                                  object
                                )
                              }}
                            />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          value={this.state.convertMark}
                          disabled={!this.state.needToConvertMark}
                          style={
                            !this.state.needToConvertMark
                              ? { background: "#d6d7d8", color: "#999" }
                              : {}
                          }
                          onChange={e => {
                            if (
                              e.target.value !== "-" &&
                              e.target.value.length < 6
                            ) {
                              this.setState({
                                convertMark: e.target.value.replace("-", ""),
                              })
                              let object = this.props.examHierarchySettingObject
                              object.convertMark = e.target.value.replace(
                                "-",
                                ""
                              )
                              this.props.saveExamHierarchySettingsDetailsInStore(
                                object
                              )
                            }
                          }}
                          onKeyDown={e => {
                            if (e.key === "Enter") {
                              this.saveForm()
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {this.state.hierarchyName === "SUBJECT" && (
                    <button
                      className="gr-show-less-btn my-1 p-0 d-flex align-items-center fs-11 fw-500 text-20C9A9"
                      onClick={() =>
                        this.setState({
                          showMoreOnSubject: !this.state.showMoreOnSubject,
                        })
                      }
                    >
                      Show {this.state.showMoreOnSubject ? "Less" : "More"}
                      <Arrow
                        className={`ml-1 ${
                          this.state.showMoreOnSubject ? "fa-rotate-180" : ""
                        }`}
                      />
                    </button>
                  )}

                  {this.state.hierarchyName === "SUBJECT" &&
                    this.state.showMoreOnSubject && (
                      <div>
                        <div className="mt-2 text-white row">
                          <div className="col-sm">
                            <FormGroup>
                              <Label
                                htmlFor="considerMarkToAssessmentTotal"
                                className="fs-11 fw-500"
                              >
                                Consider this mark to assessment total?
                              </Label>
                              <div className="row">
                                <div className="px-5 radio">
                                  <label className="fs-11 d-flex align-items-center">
                                    <input
                                      type="radio"
                                      name="considerMarkToAssessmentTotal"
                                      value="Yes"
                                      checked={
                                        this.state.considerMarkToAssessmentTotal
                                      }
                                      onChange={e => {
                                        this.setState({
                                          considerMarkToAssessmentTotal: true,
                                        })
                                        let object = this.props
                                          .examHierarchySettingObject
                                        object.considerMarkToAssessmentTotal = !this
                                          .state.considerMarkToAssessmentTotal
                                        this.props.saveExamHierarchySettingsDetailsInStore(
                                          object
                                        )
                                      }}
                                    />
                                    &ensp;Yes
                                  </label>
                                </div>
                                <div className="px-5 radio">
                                  <label className="fs-11 d-flex align-items-center">
                                    <input
                                      type="radio"
                                      name="considerMarkToAssessmentTotal"
                                      value="No"
                                      checked={
                                        !this.state
                                          .considerMarkToAssessmentTotal
                                      }
                                      onChange={e => {
                                        this.setState({
                                          considerMarkToAssessmentTotal: false,
                                        })
                                        let object = this.props
                                          .examHierarchySettingObject
                                        object.considerMarkToAssessmentTotal = !this
                                          .state.considerMarkToAssessmentTotal
                                        this.props.saveExamHierarchySettingsDetailsInStore(
                                          object
                                        )
                                      }}
                                    />
                                    &ensp;No
                                  </label>
                                </div>
                              </div>
                            </FormGroup>
                          </div>
                        </div>

                        <div className="mt-2 text-white row">
                          <div className="col-sm">
                            <FormGroup>
                              <Label
                                htmlFor="requiredToPassToGetRank"
                                className="fs-11 fw-500"
                              >
                                Is it required to pass this subject to get rank?
                              </Label>
                              <div className="row">
                                <div className="px-5 radio">
                                  <label className="fs-11 d-flex align-items-center">
                                    <input
                                      type="radio"
                                      name="requiredToPassToGetRank"
                                      value="Yes"
                                      checked={
                                        this.state.requiredToPassToGetRank
                                      }
                                      onChange={e => {
                                        this.setState({
                                          requiredToPassToGetRank: true,
                                        })
                                        let object = this.props
                                          .examHierarchySettingObject
                                        object.requiredToPassToGetRank = !this
                                          .state.requiredToPassToGetRank
                                        this.props.saveExamHierarchySettingsDetailsInStore(
                                          object
                                        )
                                      }}
                                    />
                                    &ensp;Yes
                                  </label>
                                </div>
                                <div className="px-5 radio">
                                  <label className="fs-11 d-flex align-items-center">
                                    <input
                                      type="radio"
                                      name="requiredToPassToGetRank"
                                      value="No"
                                      checked={
                                        !this.state.requiredToPassToGetRank
                                      }
                                      onChange={e => {
                                        this.setState({
                                          requiredToPassToGetRank: false,
                                        })
                                        let object = this.props
                                          .examHierarchySettingObject
                                        object.requiredToPassToGetRank = !this
                                          .state.requiredToPassToGetRank
                                        this.props.saveExamHierarchySettingsDetailsInStore(
                                          object
                                        )
                                      }}
                                    />
                                    &ensp;No
                                  </label>
                                </div>
                              </div>
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                    )}

                  {(this.state.hierarchyName === "ACTIVITY" ||
                    (this.state.hierarchyName === "THEORY AND PRACTICAL" &&
                      this.state.hierarchyObject.activities.length === 0)) && (
                    <div className="mt-1 text-white row">
                      <div className="col-sm">
                        <FormGroup>
                          <Label
                            htmlFor="includeMarkToSubjectTotal"
                            className="mr-5 fs-11 fw-500"
                          >
                            Include this mark to subject total?
                          </Label>
                          <div className="row">
                            <div className="px-5 radio">
                              <label className="fs-11 d-flex align-items-center">
                                <input
                                  type="radio"
                                  name="includeMarkToSubjectTotal"
                                  value="Yes"
                                  checked={this.state.includeMarkToSubjectTotal}
                                  onChange={e => {
                                    this.setState({
                                      includeMarkToSubjectTotal: true,
                                    })
                                    let object = this.props
                                      .examHierarchySettingObject
                                    object.includeMarkToSubjectTotal = !this
                                      .state.includeMarkToSubjectTotal
                                    this.props.saveExamHierarchySettingsDetailsInStore(
                                      object
                                    )
                                  }}
                                />
                                &ensp;Yes
                              </label>
                            </div>
                            <div className="px-5 radio">
                              <label className="fs-11 d-flex align-items-center">
                                <input
                                  type="radio"
                                  name="includeMarkToSubjectTotal"
                                  value="No"
                                  checked={
                                    !this.state.includeMarkToSubjectTotal
                                  }
                                  onChange={e => {
                                    this.setState({
                                      includeMarkToSubjectTotal: false,
                                    })
                                    let object = this.props
                                      .examHierarchySettingObject
                                    object.includeMarkToSubjectTotal = !this
                                      .state.includeMarkToSubjectTotal
                                    this.props.saveExamHierarchySettingsDetailsInStore(
                                      object
                                    )
                                  }}
                                />
                                &ensp;No
                              </label>
                            </div>
                          </div>
                        </FormGroup>
                      </div>
                    </div>
                  )}

                  {this.state.hierarchyName === "THEORY AND PRACTICAL" &&
                    this.state.hierarchyObject.key === "T" && (
                      <div>
                        <div className="mt-2 text-white row">
                          <div className="col-sm">
                            <FormGroup>
                              <Label
                                htmlFor="requiredToPassToGetRank"
                                className="mr-5 fs-11 fw-500"
                              >
                                Should pass theory to get subject rank?
                              </Label>
                              <div className="row">
                                <div className="px-5 radio">
                                  <label className="fs-11 d-flex align-items-center">
                                    <input
                                      type="radio"
                                      name="requiredToPassToGetRank"
                                      value="Yes"
                                      className="fs-11"
                                      checked={
                                        this.state.requiredToPassToGetRank
                                      }
                                      onChange={e => {
                                        this.setState({
                                          requiredToPassToGetRank: true,
                                        })
                                        let object = this.props
                                          .examHierarchySettingObject
                                        object.requiredToPassToGetRank = !this
                                          .state.requiredToPassToGetRank
                                        this.props.saveExamHierarchySettingsDetailsInStore(
                                          object
                                        )
                                      }}
                                    />
                                    &ensp;Yes
                                  </label>
                                </div>
                                <div className="px-5 radio">
                                  <label className="fs-11 d-flex align-items-center">
                                    <input
                                      type="radio"
                                      name="requiredToPassToGetRank"
                                      value="No"
                                      className="fs-11"
                                      checked={
                                        !this.state.requiredToPassToGetRank
                                      }
                                      onChange={e => {
                                        this.setState({
                                          requiredToPassToGetRank: false,
                                        })
                                        let object = this.props
                                          .examHierarchySettingObject
                                        object.requiredToPassToGetRank = !this
                                          .state.requiredToPassToGetRank
                                        this.props.saveExamHierarchySettingsDetailsInStore(
                                          object
                                        )
                                      }}
                                    />
                                    &ensp;No
                                  </label>
                                </div>
                              </div>
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                    )}

                  {this.state.hierarchyName === "THEORY AND PRACTICAL" &&
                    this.state.hierarchyObject.key === "P" && (
                      <div>
                        <div className="mt-2 text-white row">
                          <div className="col-sm">
                            <FormGroup>
                              <Label
                                htmlFor="requiredToPassToGetRank"
                                className="mr-5 fs-11 fw-500"
                              >
                                Should pass practical to get subject rank?
                              </Label>
                              <div className="row">
                                <div className="px-5 radio">
                                  <label className="fs-11 d-flex align-items-center">
                                    <input
                                      type="radio"
                                      name="requiredToPassToGetRank"
                                      value="Yes"
                                      checked={
                                        this.state.requiredToPassToGetRank
                                      }
                                      onChange={e => {
                                        this.setState({
                                          requiredToPassToGetRank: true,
                                        })
                                        let object = this.props
                                          .examHierarchySettingObject
                                        object.requiredToPassToGetRank = !this
                                          .state.requiredToPassToGetRank
                                        this.props.saveExamHierarchySettingsDetailsInStore(
                                          object
                                        )
                                      }}
                                    />
                                    &ensp;Yes
                                  </label>
                                </div>
                                <div className="px-5 radio">
                                  <label className="fs-11 d-flex align-items-center">
                                    <input
                                      type="radio"
                                      name="requiredToPassToGetRank"
                                      value="No"
                                      checked={
                                        !this.state.requiredToPassToGetRank
                                      }
                                      onChange={e => {
                                        this.setState({
                                          requiredToPassToGetRank: false,
                                        })
                                        let object = this.props
                                          .examHierarchySettingObject
                                        object.requiredToPassToGetRank = !this
                                          .state.requiredToPassToGetRank
                                        this.props.saveExamHierarchySettingsDetailsInStore(
                                          object
                                        )
                                      }}
                                    />
                                    &ensp;No
                                  </label>
                                </div>
                              </div>
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                    )}

                  {/* <div
                  className="mt-2 text-white"
                  style={{ border: "1px solid #ffffff" }}
                />
                <div className="mt-3 d-flex justify-content-between">
                  <div>
                    <p className="text-white">Grade</p>
                  </div>
                  <div
                    onClick={() => {
                      this.setState({ isShowEditGrade: !this.state.isShowEditGrade })
                    }}
                  >
                    {this.state.grades && this.state.grades[0].gradeName !== '' ? 
                     <p className="text-white">Edit</p>
                     :
                     <p className="text-white">Add</p>
                  }
                  </div>
                </div> */}
                  {/* {!this.state.isShowEditGrade ? (
                <div className="ml-1 d-flex row align-self-center">
                  {this.state.grades && this.state.grades[0].gradeName !== '' &&
                    this.state.grades.map((grade, index) => {
                      return (
                        <div className="px-3 mt-2 ml-2 bg-white rounded align-self-center">
                          {grade.gradeName}
                        </div>
                      )
                    })}
                </div>
                ) : (
                  <div className="mt-3 mb-1 ml-1 mr-1 row">
                    <form className="col-sm">
                      {this.state.grades &&
                        this.state.grades.map((item, index) => {
                          return (
                            <div className="mb-1 d-flex justify-content-start">
                              <div className="row">
                                <div className="col-sm">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control form-control-sm"
                                      value={item.gradeFrom}
                                      onChange={e =>{
                                        let array = this.state.grades;
                                        array[index].gradeFrom = e.target.value.trim();
                                        this.setState({
                                          grades: array
                                        })
                                        let object = this.props.examHierarchySettingObject;
                                        object.grades = array
                                        this.props.saveExamHierarchySettingsDetailsInStore(object)
                                      }}
                                    />
                                  </div>*
                                </div>
                                <p className="text-white fs-20">-</p>
                                <div className="col-sm">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control form-control-sm"
                                      value={item.gradeTo}
                                      onChange={e =>{
                                        let array = this.state.grades;
                                        array[index].gradeTo = e.target.value.trim();
                                        this.setState({
                                          grades: array
                                        })
                                        let object = this.props.examHierarchySettingObject;
                                        object.grades = array
                                        this.props.saveExamHierarchySettingsDetailsInStore(object)
                                      }}
                                    />
                                  </div>
                                </div>
                                <p className="text-white fs-20">=</p>
                                <div className="col-sm">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control form-control-sm"
                                      value={item.gradeName}
                                      onChange={e =>{
                                        let array = this.state.grades;
                                        array[index].gradeName = e.target.value.trim();
                                        this.setState({
                                          grades: array
                                        })
                                        let object = this.props.examHierarchySettingObject;
                                        object.grades = array
                                        this.props.saveExamHierarchySettingsDetailsInStore(object)
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                <div className="col-sm">
                                <i
                                  className="p-2 fas fa-plus text-success"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => this.addSubject()}
                                />
                                <i
                                  className="p-2 fas fa-trash-alt text-danger"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => this.deleteSubject(index)}
                                />
                                </div>
                              </div>
                              </div>
                            </div>
                          )
                        })}
                    </form>
                  </div>
                )} */}
                </div>
              )}
            </div>

            {this.state.hierarchyName === "THEORY AND PRACTICAL" &&
              this.state.hierarchyObject.activities.length > 0 && (
                <div className="py-1">
                  <small className="text-white fw-500">
                    Calculation method
                  </small>
                  <div>
                    <select
                      className="form-control"
                      value={this.state.selectedActivityCondition}
                      onChange={e => {
                        this.setState({
                          selectedActivityCondition: e.target.value,
                        })
                        let object = this.props.examHierarchySettingObject
                        object.activityConditionValue = e.target.value
                        this.props.saveExamHierarchySettingsDetailsInStore(
                          object
                        )
                      }}
                    >
                      <option value="0">Select</option>
                      {this.props.activityConditionList &&
                        this.props.activityConditionList.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.conditionName}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              )}
          </div>

          {this.state.hierarchyName !== "TERM" &&
            this.state.hierarchyName !== "ASSESSMENT" && (
              <div className="pb-4 mt-3 d-flex justify-content-center gap-18px">
                <button
                  className={`mr-2 px-5 btn text-white btn-sm box-shadow-none rounded ${
                    isEdited ? "bg-06B191" : "bg-B9B9B9"
                  }`}
                  onClick={this.saveForm}
                  style={
                    this.state.hierarchyName === "SUBJECT"
                      ? { marginLeft: "20px" }
                      : {}
                  }
                  disabled={!isEdited}
                >
                  {this.state.isShowLoading ? (
                    <HashLoader
                      size={16}
                      color="#fff"
                      sizeUnit="px"
                      loading={this.state.isShowLoading}
                    />
                  ) : (
                    "Save"
                  )}
                </button>

                {(this.state.hierarchyName === "SUBJECT" ||
                  this.state.hierarchyName === "THEORY AND PRACTICAL" ||
                  this.state.hierarchyName === "ACTIVITY") &&
                  !this.props.examHierarchySettingObject?.isNewBox && (
                    <button
                      className={`px-3 btn text-white btn-sm box-shadow-none rounded ${
                        !isEdited ? "bg-06B191" : "bg-B9B9B9"
                      }`}
                      onClick={this.props.onClkSubjectViewBtn}
                      disabled={isEdited}
                    >
                      Enter/View Marks
                    </button>
                  )}
              </div>
            )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    hierarchyTerms: state.hierarchyState.hierarchyTerms,
    hierarchyFAs: state.hierarchyState.hierarchyFAs,
    hierarchySubjects: state.hierarchyState.hierarchySubjects,
    hierarchyActivities: state.hierarchyState.hierarchyActivity,
    subjectList: state.hierarchyState.subjectList,
    examHierarchySettingObject: state.hierarchyState.examHierarchySettingObject,
    activityConditionList: state.hierarchyState.activityConditionList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      saveExamHierarchySettingsDetailsInStore,
      saveExamHierarchyActivityListInStore,
      saveExamHierarchySubjectListInStore,
      saveExamHierarchyTermsListInStore,
      saveExamHierarchyFAsListInStore,
      saveExamHierarchyDetailsInStore,
      saveExamHierarchyAcademicObject,
      saveExamHierarchyPracticalList,
      saveExamHierarchyActivityList,
      saveExamHierarchySubjectList,
      saveExamHierarchyTheoryList,
      saveExamHierarchyTermsList,
      saveExamHierarchyFAsList,
      saveIsShowLoadingInStore,
      saveExamHierarchyFAsDate,
      showNotification,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(ExamHierarchySettings)
