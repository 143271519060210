/*************************************************
 * RedInk
 * @exports
 * @class axiosInstance.js
 * @extends Component
 * @author Sasidharan // on 17/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import axios from "axios"
import URL from "../config/index"
import { myLog, logout } from "./Utility"
import store from "store"

export const axiosPrivateInstance = axios.create({
  baseURL: URL.API_BASE_URL,
})

export const axiosCommonInstance = axios.create({
  baseURL: URL.API_BASE_URL,
})

export const communicationServerInstance = axios.create({
  baseURL: URL.COMMUNICATION_API_BASE_URL,
})

axiosCommonInstance.interceptors.request.use(
  function(config) {
    let originalRequest = config
    let userSession = store.get("userSession")
    if (Client.isTokenExpired()) {
      myLog("access token expires in 10 secs && refresh token called")
      return Client.refresh_token(userSession.refresh_token)
        .then(response => {
          store.set("userSession", response)
          if (response && response.refresh_token && response.expires_in) {
            store.set("expiryTime", Client.tokenExpires(response.expires_in))
          }
          originalRequest["Authorization"] =
            response.token_type + " " + response.access_token
          return originalRequest
        })
        .catch(err => {
          myLog("Refresh Token Error", err)
          logout()
        })
    }
    return config
  },
  function(err) {
    return Promise.reject(err)
  }
)

axiosCommonInstance.interceptors.response.use(
  response => {
    return response
  },
  function(error) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      myLog("Access token expired && calling refresh token ...")
      let userSession = store.get("userSession")
      return Client.refresh_token(userSession.refresh_token)
        .then(token_response => {
          store.set("userSession", token_response)
          if (
            token_response &&
            token_response.refresh_token &&
            token_response.expires_in
          ) {
            store.set(
              "expiryTime",
              Client.tokenExpires(token_response.expires_in)
            )
          }
          error.config.headers["Authorization"] =
            token_response.token_type + " " + token_response.access_token
          return Promise.resolve(axiosCommonInstance(error.config))
        })
        .catch(err => {
          myLog("Refresh Token Error", err)
          logout()
        })
    }
    return Promise.reject(error.response ? error.response : error)
  }
)

communicationServerInstance.interceptors.request.use(
  function(config) {
    let originalRequest = config
    let userSession = store.get("userSession")
    if (Client.isTokenExpired()) {
      myLog("access token expires in 10 secs && refresh token called")
      return Client.refresh_token(userSession.refresh_token)
        .then(response => {
          store.set("userSession", response)
          if (response && response.refresh_token && response.expires_in) {
            store.set("expiryTime", Client.tokenExpires(response.expires_in))
          }
          originalRequest["Authorization"] =
            response.token_type + " " + response.access_token
          return originalRequest
        })
        .catch(err => {
          myLog("Refresh Token Error", err)
          logout()
        })
    }
    return config
  },
  function(err) {
    return Promise.reject(err)
  }
)

communicationServerInstance.interceptors.response.use(
  response => {
    return response
  },
  function(error) {
    if (error.response.status === 401 || error.response.status === 403) {
      myLog("Access token expired && calling refresh token ...")
      let userSession = store.get("userSession")
      return Client.refresh_token(userSession.refresh_token)
        .then(token_response => {
          store.set("userSession", token_response)
          if (
            token_response &&
            token_response.refresh_token &&
            token_response.expires_in
          ) {
            store.set(
              "expiryTime",
              Client.tokenExpires(token_response.expires_in)
            )
          }
          error.config.headers["Authorization"] =
            token_response.token_type + " " + token_response.access_token
          return Promise.resolve(communicationServerInstance(error.config))
        })
        .catch(err => {
          myLog("Refresh Token Error", err)
          logout()
        })
    }
    return Promise.reject(error.response)
  }
)

export default class Client {
  static httpHeader(isAccessToken, isCommunicationServer) {
    let date = new Date()
    let headers = {}
    headers = {
      "Content-Type": "application/json",
      offset: date.getTimezoneOffset(),
    }
    // if (isCommunicationServer) {
    //   headers = {
    //     'Content-Disposition': "attachment; filename=student.xlsx",
    //     'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    //     'x-api-key' : 'eMGOWFiQxF3QT2zMg6xtp1iYJtedIpF76a074mow',
    //   }
    // }
    if (isAccessToken) {
      headers = {
        "Content-Type": "application/json",
        // tentant: "ken",
        tentant: store.get("userSession").userInfo.tentant,
        // tentant:window.location.hostname.split(".")[0],
        offset: date.getTimezoneOffset(),
        Authorization:
          typeof store.get("userSession") === "object"
            ? `${store.get("userSession").token_type} ${
                store.get("userSession").access_token
              }`
            : logout(),
      }
    }
    return headers
  }

  static isTokenExpired() {
    let expiryTime = store.get("expiryTime")
    if (Date.now() > expiryTime - 120 * 1000 || Date.now() > expiryTime) {
      return true
    } else {
      return false
    }
  }

  static tokenExpires(tokenExpires) {
    let minutes = Date.now() + tokenExpires * 1000
    return minutes
  }

  static OAUTH(username, password) {
    return new Promise(function(success, failed) {
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Basic cmVkaW5rOlNVTUdEYXlIamVEcE1nWHU=",
        },
        url: URL.OAUTH,
        method: "POST",
        data: `grant_type=password&scope=openid&username=${username}&password=${password}`,
      }
      // myLog("GET ::::::: INPUT", config)
      Client.callAPI(config, success, failed, true)
    })
  }

  static FORGET(params) {
    return new Promise(function(success, failed) {
      const config = {
        headers: {
          Authorization: "Basic cmVkaW5rOlNVTUdEYXlIamVEcE1nWHU=",
        },
        url: URL.FORGET_PASSWORD,
        method: "POST",
        data: params,
      }
      myLog("GET ::::::: INPUT", config)
      Client.callAPI(config, success, failed, true)
    })
  }

  static RESET(params) {
    return new Promise(function(success, failed) {
      const config = {
        headers: {
          Authorization: "Basic cmVkaW5rOlNVTUdEYXlIamVEcE1nWHU=",
        },
        url: URL.RESET_PASSWORD,
        method: "POST",
        data: params,
      }
      myLog("GET ::::::: INPUT", config)
      Client.callAPI(config, success, failed, true)
    })
  }

  static callAPI = (
    config,
    success,
    failed,
    isPrivate,
    isCommunicationServer
  ) => {
    let instance = isPrivate ? axiosPrivateInstance : axiosCommonInstance
    if (isCommunicationServer) {
      instance = communicationServerInstance
    }
    instance(config)
      .then(response => {
        // myLog("GET ::::::: response", response)
        success(response.data)
      })
      .catch(err => {
        myLog("GET ::::::: err", err)
        if (err.message === "Network Error") {
          // logout();
          failed(err.message)
          myLog("There was a network error.")
        } else {
          // myLog("GET ::::::: err", err)
          failed(err.response && err.response.data)
        }
        //  myLog("GET ::::::: err", err.message)
        // if (err.message === "Network Error") {
        //   failed(err.message)
        // } else {
        //   failed(err.response && err.response.data)
        // }
      })
  }

  static get(url, params, isAccessToken, isCommunicationServer) {
    return new Promise(function(success, failed) {
      let config = {
        method: "GET",
        url,
        params,
        headers: Client.httpHeader(isAccessToken, isCommunicationServer),
      }
      // if (isCommunicationServer) {
      //   config.responseType = 'arraybuffer'
      // }
      // myLog("GET ::::::: INPUT", config)
      Client.callAPI(config, success, failed, false, isCommunicationServer)
    })
  }

  static getPdfOrExcel(url, params, isAccessToken, isCommunicationServer) {
    return new Promise(function(success, failed) {
      let config = {
        method: "GET",
        url,
        params,
        headers: Client.httpHeader(isAccessToken, isCommunicationServer),
      }
      if (isCommunicationServer) {
        config.responseType = "arraybuffer"
      }
      // myLog("GET ::::::: INPUT", config)
      Client.callAPI(config, success, failed, false, isCommunicationServer)
    })
  }

  static post(url, data, isAccessToken, isCommunicationServer) {
    return new Promise(function(success, failed) {
      const config = {
        method: "POST",
        url,
        data,
        headers: Client.httpHeader(isAccessToken),
      }
      myLog("POST ::::: Input", config)
      Client.callAPI(config, success, failed, false, isCommunicationServer)
    })
  }

  static put(url, data, isAccessToken, isCommunicationServer) {
    return new Promise(function(success, failed) {
      const config = {
        method: "PUT",
        url,
        data,
        headers: Client.httpHeader(isAccessToken),
      }
      myLog("PUT ::::::: INPUT", config)
      Client.callAPI(config, success, failed, false, isCommunicationServer)
    })
  }

  static patch(url, data, isAccessToken, isCommunicationServer) {
    return new Promise(function(success, failed) {
      const config = {
        method: "PATCH",
        url,
        data,
        headers: Client.httpHeader(isAccessToken),
      }
      myLog("PATCH ::::::: INPUT", config)
      Client.callAPI(config, success, failed, false, isCommunicationServer)
    })
  }

  static delete(url, params, isAccessToken, isCommunicationServer) {
    return new Promise(function(success, failed) {
      const config = {
        method: "DELETE",
        url,
        params,
        headers: Client.httpHeader(isAccessToken),
      }
      myLog("DELETE ::::::: INPUT", config)
      Client.callAPI(config, success, failed, false, isCommunicationServer)
    })
  }

  static refresh_token(refresh_token) {
    return new Promise(function(success, failed) {
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Basic cmVkaW5rOlNVTUdEYXlIamVEcE1nWHU=",
        },
        url: URL.OAUTH,
        method: "POST",
        data: `grant_type=refresh_token&refresh_token=${refresh_token}&scope=openid`,
      }
      Client.callAPI(config, success, failed, true)
    })
  }
}
