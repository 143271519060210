/*************************************************
 * RedInk
 * @exports
 * @class SubjectsScreen.js
 * @extends Component
 * @author Sasidharan // on 06/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { HashLoader } from "react-spinners"
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Button,
  Label,
  Input,
  Modal,
} from "reactstrap"

import {
  getGradeMasterDetails,
  getGradeMasterList,
  saveGradeMaster,
  deleteGradeMaster,
  updateGradeClasses,
} from "../../../actions/admin/GradeMasterAction"
import { DANGER_TOAST, VALIDATION_MSG } from "../../../utils/Constants"
import { confirmAlertPopUp } from "../../../utils/Utility"
import { showNotification } from "../../../actions/CommonAction"
import { getClassDetails } from "../../../actions/admin/ClassDetailsAction"

import AppWrapper from "../Wrapper1"

const examTypeList = [
  { value: "scholastic", label: "Scholastic" },
  { value: "co-scholastic", label: "Co-Scholastic" },
]

const gradeColorList = [
  "#2ecc71",
  "#80e2aa",
  "#3498db",
  "#aed6f1",
  "#c39bd3",
  "#f1c40f",
  "#f7dc6f",
  "#e67e22",
  "#f0b27a",
  "#ee4675",
  "#d41349",
]

class GradeMasterScreen extends Component {
  static getDerivedStateFromProps = () => {
    return {}
  }

  constructor(props) {
    super(props)
    this.state = {
      gradeMasters: [],
      gradeMastersScholastic: [],
      gradeMastersCoScholastic: [],
      selectedGradeMasterId: null,
      selectedGradeMasterIndex: 0,
      gradeConditions: [],
      gradeClasses: [],
      isShowLoading: false,
      showEmptyGradeMaster: false,
      openSingleGradeMaster: false,
      inputTitle: null,
      inputBasedOn: "percentage",
      inputExamType: "scholastic",
      inputDescription: null,
      inputIsDefault: null,
      inputReqDesc: false,
      inputReqRemarks: false,
      showColorPickerModal: false,
      currentGradeConditionIndex: 0,
      showClassDropDown: false,
      isSavingGradeMaster: false,
      showManageDefaultGradingModal: false,
      manageDefaultScholasticGradingData: [],
      manageDefaultCoScholasticGradingData: [],
      isSavingGradeClasses: false,
    }
  }

  componentDidMount = () => {
    this.props.getClassDetails({}, (success, response) => {})
    this.getGradeMasterData()
  }

  getGradeMasterData = () => {
    this.setState({ isShowLoading: true })
    this.props.getGradeMasterList({}, (success, response) => {
      if (success) {
        if (response.length > 0) {
          const gradeMastersScholastic = response.filter(
            item => item.examType === "scholastic"
          )
          let manageDefaultScholasticGradingData = []
          for (const gradeMastersScholasticItem of gradeMastersScholastic) {
            for (const gradeClass of gradeMastersScholasticItem.gradeClasses) {
              manageDefaultScholasticGradingData.push({
                oldGradeId: gradeMastersScholasticItem.id,
                gradeId: gradeMastersScholasticItem.id,
                sectionId: gradeClass.sectionId,
              })
            }
          }
          this.setState({ manageDefaultScholasticGradingData })
          const gradeMastersCoScholastic = response.filter(
            item => item.examType === "co-scholastic"
          )

          let manageDefaultCoScholasticGradingData = []
          for (const gradeMastersCoScholasticItem of gradeMastersCoScholastic) {
            for (const gradeClass of gradeMastersCoScholasticItem.gradeClasses) {
              manageDefaultCoScholasticGradingData.push({
                oldGradeId: gradeMastersCoScholasticItem.id,
                gradeId: gradeMastersCoScholasticItem.id,
                sectionId: gradeClass.sectionId,
              })
            }
          }
          this.setState({
            gradeMasters: response,
            gradeMastersScholastic,
            gradeMastersCoScholastic,
            manageDefaultScholasticGradingData,
            manageDefaultCoScholasticGradingData,
          })
        }
      }
      this.setState({ isShowLoading: false })
    })
  }

  clearData = () => {
    this.setState({
      selectedGradeMasterId: null,
      gradeClasses: [],
      gradeConditions: [],
      showEmptyGradeMaster: false,
      openSingleGradeMaster: 0,
      inputTitle: null,
      inputBasedOn: "percentage",
      inputExamType: "scholastic",
      inputDescription: null,
      inputIsDefault: null,
      inputReqDesc: false,
      inputReqRemarks: false,
      currentGradeConditionIndex: 0,
    })
  }

  /**
   * Delete a grade condition
   */
  deleteGradeCondition = (index, item) => {
    if (this.state.gradeConditions.length > 1) {
      let gradeConditions = this.state.gradeConditions
      gradeConditions.splice(index, 1)
      this.setState({ gradeConditions: gradeConditions })
    }
  }

  showErrorNoti = message => {
    this.props.showNotification(DANGER_TOAST, VALIDATION_MSG.OOPS, message)
  }

  /**
   * Add new grade condition
   */
  addGradeCondition = () => {
    const tempARR = this.state.gradeConditions
    const gradeOrder = tempARR ? tempARR.length : 0
    let object = {
      gradeLetter: "",
      gradePoints: "",
      gradeLowerRange: "",
      gradeHigherRange: "",
      gradeColor: gradeColorList[gradeOrder]
        ? gradeColorList[gradeOrder]
        : gradeColorList[0],
      gradeOrder: gradeOrder,
      description: "",
      remarks: "",
    }
    tempARR.push(object)
    this.setState({ gradeConditions: tempARR })
  }

  saveGradeMaster = () => {
    // this.setState({isSavingGradeMaster: true})
    let object = {
      selectedGradeMasterId: this.state.selectedGradeMasterId,
      gradeClasses: this.state.gradeClasses,
      gradeConditions: this.state.gradeConditions,
      inputTitle: this.state.inputTitle,
      inputBasedOn: this.state.inputBasedOn,
      inputExamType: this.state.inputExamType,
      inputDescription: this.state.inputExamType,
      inputReqDesc: this.state.inputReqDesc,
      inputReqRemarks: this.state.inputReqRemarks,
    }

    // this.showErrorNoti(error)
    if (!object.inputTitle) {
      this.showErrorNoti("Enter Grade System Name")
      return
    }
    if (!object.inputBasedOn) {
      this.showErrorNoti("Select Grade system based on")
      return
    }
    if (!object.inputExamType) {
      this.showErrorNoti("Select Grade system exam type")
      return
    }
    if (object.gradeConditions.length === 0) {
      this.showErrorNoti("Input atleast on Grade conditions")
      return
    }
    for (const gradeCondition of object.gradeConditions) {
      if (gradeCondition.gradeLetter.trim() === "") {
        this.showErrorNoti("Enter Grade Letter for all rows")
        return
      }
      if (
        gradeCondition.gradePoints.trim() === "" ||
        isNaN(gradeCondition.gradePoints.trim())
      ) {
        this.showErrorNoti("Enter Valid Grade Points for all rows")
        return
      }
      if (gradeCondition.description.trim() === "" && object.inputReqDesc) {
        this.showErrorNoti("Enter Grade description for all rows")
        return
      }
      if (gradeCondition.remarks.trim() === "" && object.inputReqRemarks) {
        this.showErrorNoti("Enter Grade remarks for all rows")
        return
      }
      if (object.inputBasedOn === "percentage") {
        if (
          gradeCondition.gradeLowerRange.trim() === "" ||
          isNaN(gradeCondition.gradeLowerRange.trim())
        ) {
          this.showErrorNoti("Enter Valid Grade Lower Range for all rows")
          return
        }
        if (
          gradeCondition.gradeHigherRange.trim() === "" ||
          isNaN(gradeCondition.gradeHigherRange.trim())
        ) {
          this.showErrorNoti("Enter Valid Grade Higher Range for all rows")
          return
        }
      }
      if (gradeCondition.gradeColor.trim() === "") {
        this.showErrorNoti("Enter Grade Color for all rows")
        return
      }
    }

    const body = {
      id: this.state.selectedGradeMasterId,
      classes: this.state.gradeClasses,
      conditions: this.state.gradeConditions,
      title: this.state.inputTitle,
      basedOn: this.state.inputBasedOn,
      examType: this.state.inputExamType,
      description: this.state.inputExamType,
      reqDesc: this.state.inputReqDesc,
      reqRemarks: this.state.inputReqRemarks,
      isUpdate: this.state.selectedGradeMasterId ? true : false,
    }

    this.props.saveGradeMaster(body, (success, quizData) => {
      if (success) {
        this.setState({
          isSavingGradeMaster: false,
          showEmptyGradeMaster: false,
        })
        this.clearData()
        this.getGradeMasterData()
      } else {
        this.setState({ isSavingGradeMaster: false })
      }
    })
  }

  updateGradeClasses = () => {
    this.setState({ isSavingGradeClasses: true })
    let body = {
      scholasticGradeClassesData: this.state.manageDefaultScholasticGradingData,
      coScholasticGradeClassesData: this.state
        .manageDefaultCoScholasticGradingData,
    }

    this.props.updateGradeClasses(body, (success, quizData) => {
      if (success) {
        this.setState({
          isSavingGradeClasses: false,
          showEmptyGradeMaster: false,
          showManageDefaultGradingModal: false,
        })
        this.clearData()
        this.getGradeMasterData()
      } else {
        this.setState({ isSavingGradeClasses: false })
      }
    })
  }

  deteleGradeMaster = id => {
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT + "",
      yes => {
        if (yes) {
          this.deteleGradeMasterAction(id)
        }
      }
    )
  }

  deteleGradeMasterAction = id => {
    this.props.deleteGradeMaster({ id }, (success, _) => {
      if (success) {
        this.getGradeMasterData()
      }
    })
  }

  // renderSingleGradeMaster = (item, index) => {
  //   const itemNo = String(item.title).slice(0,1)
  //   const itemName = String(item.title).slice(2,)
  //   myLog("Item",item)
  //   return (
  //     <div
  //       className="card border-1 border-solid mb-4"
  //       style={{ borderRadius: "10px" }}
  //     >
  //       <div className="row m-3">
  //         <div className="col-lg-4">
  //           <h4 className="text-dark mt-2">
  //             {item.title} |{" "}
  //             {item.basedOn == "percentage" ? "Percentage" : "Points"}
  //             {/* {typeof(item.title)} */}
  //           </h4>
  //         </div>
  //         <div className="col-lg-3">
  //           <h5 className="text-dark mt-3">
  //             {item.examType === "scholastic" ? "Scholastic" : "Co-Scholastic"}
  //           </h5>
  //         </div>
  //         <div className="col-lg-3">
  //           <h5 className="text-dark mt-3">
  //             {item.gradeClasses.length > 0
  //               ? item.gradeClasses.map((itemClass, indexClass) => {
  //                   return (
  //                     <span className="mr-2">
  //                       {itemClass.section.className}-
  //                       {itemClass.section.sectionName}{" "}
  //                       {indexClass + 1 < item.gradeClasses.length ? "," : ""}
  //                     </span>
  //                   )
  //                 })
  //               : "assign class"}
  //           </h5>
  //         </div>
  //         <div className="col-lg-1"></div>
  //         <div className="col-lg-1 text-right">
  //           <UncontrolledDropdown direction={"left"}>
  //             <DropdownToggle
  //               tag="button"
  //               className="mt-2"
  //               style={{ float: "right" }}
  //             >
  //               <i className="fas fa-ellipsis-v text-black cursor-pointer"></i>
  //             </DropdownToggle>
  //             <DropdownMenu>
  //               <DropdownItem
  //                 onClick={e => {
  //                   this.setState({
  //                     selectedGradeMasterId: item.id,
  //                     gradeClasses: item.gradeClasses.map(
  //                       value => value.sectionId
  //                     ),
  //                     gradeConditions: item.gradeConditions,
  //                     showEmptyGradeMaster: true,
  //                     inputTitle: item.title,
  //                     inputBasedOn: item.basedOn,
  //                     inputExamType: item.examType,
  //                     inputReqDesc: item.reqDesc,
  //                     inputReqRemarks: item.reqRemarks,
  //                     currentGradeConditionIndex: 0,
  //                     openSingleGradeMaster: false,
  //                   })
  //                 }}
  //               >
  //                 Edit
  //               </DropdownItem>
  //               <DropdownItem
  //                 onClick={e => {
  //                   this.deteleGradeMaster(item.id)
  //                 }}
  //               >
  //                 Delete
  //               </DropdownItem>
  //             </DropdownMenu>
  //           </UncontrolledDropdown>
  //           {this.state.selectedGradeMasterId == item.id &&
  //           this.state.openSingleGradeMaster ? (
  //             <i
  //               className="fas fa-angle-up mt-2 fa-2x mr-3 text-muted cursor-pointer"
  //               style={{ float: "right" }}
  //               onClick={e => {
  //                 this.setState({
  //                   selectedGradeMasterId: null,
  //                   openSingleGradeMaster: false,
  //                 })
  //               }}
  //             ></i>
  //           ) : (
  //             <i
  //               className="fas fa-angle-down mt-2 fa-2x mr-3 text-muted cursor-pointer"
  //               style={{ float: "right" }}
  //               onClick={e => {
  //                 this.setState({
  //                   selectedGradeMasterId: item.id,
  //                   openSingleGradeMaster: true,
  //                 })
  //               }}
  //             ></i>
  //           )}
  //         </div>
  //       </div>

  //       {this.state.selectedGradeMasterId == item.id &&
  //         this.state.openSingleGradeMaster && (
  //           <div className="mx-4">
  //             <table style={{ width: "100%" }} className="table table-bordered">
  //               <thead>
  //                 <tr>
  //                   <th>Letter Grade</th>
  //                   {item.basedOn === "percentage" && (
  //                     <>
  //                       <th>Lower Range</th>
  //                       <th>Higher Range</th>
  //                     </>
  //                   )}
  //                   <th>Grade Point</th>
  //                   <th style={{ width: "100px" }}>Color</th>
  //                   {item.reqDesc && <th>Description</th>}
  //                   {item.reqRemarks && <th>Remarks</th>}
  //                 </tr>
  //               </thead>
  //               <tbody>
  //                 {item.gradeConditions && item.gradeConditions.length > 0
  //                   ? item.gradeConditions.map((item1, index1) => {
  //                       return (
  //                         <tr>
  //                           <td>{item1.gradeLetter}</td>
  //                           {item.basedOn === "percentage" && (
  //                             <>
  //                               <td>{Math.round(item1.gradeLowerRange)}%</td>
  //                               <td>{Math.round(item1.gradeHigherRange)}%</td>
  //                             </>
  //                           )}
  //                           <td>{Math.round(item1.gradePoints)}</td>
  //                           <td>
  //                             <div
  //                               style={{
  //                                 display: "block",
  //                                 width: "25px",
  //                                 height: "25px",
  //                                 backgroundColor: item1.gradeColor,
  //                                 borderRadius: "5px",
  //                               }}
  //                             ></div>
  //                           </td>
  //                           {item.reqDesc && <td>{item1.description}&nbsp;</td>}
  //                           {item.reqRemarks && <td>{item1.remarks}&nbsp;</td>}
  //                         </tr>
  //                       )
  //                     })
  //                   : null}
  //               </tbody>
  //             </table>
  //           </div>
  //         )}
  //     </div>
  //   )
  // }

  renderEmptyGradeMaster = () => {
    if (this.state.gradeConditions.length === 0) {
      this.addGradeCondition()
    }
    if (this.state.showEmptyGradeMaster) {
      return (
        <div
          className="card border-1 border-solid px-5 py-5 mb-5"
          style={{
            borderRadius: "10px",
            backgroundColor: "rgb(240, 240, 240)",
          }}
        >
          <div className="row">
            <div className="col-lg-4">
              Grade System Name{" "}
              <input
                id="admin-sp-gs-name"
                type="text"
                maxLength="150"
                className="form-control"
                placeholder="Grade Name"
                value={this.state.inputTitle}
                onChange={e => {
                  this.setState({ inputTitle: e.target.value })
                }}
                onKeyDown={e => {
                  if (e.key === "Enter" && e.target.value) {
                    let el = document.getElementById("admin-sp-gs-exam-type")
                    el.focus()
                  }
                }}
              />
            </div>
            <div className="col-lg-4">
              Exam Type
              <select
                id="admin-sp-gs-exam-type"
                className="form-control"
                value={this.state.inputExamType}
                onChange={e => {
                  if (e.target.value === "co-scholastic") {
                    this.setState({ inputBasedOn: "points" })
                  } else {
                    this.setState({ inputBasedOn: "percentage" })
                  }
                  this.setState({ inputExamType: e.target.value })
                }}
                onKeyDown={e => {
                  if (e.key === "Enter" && e.target.value) {
                    let el = document.getElementById("percentage")
                    el.focus()
                  }
                }}
              >
                {examTypeList &&
                  examTypeList.length > 0 &&
                  examTypeList.map(option => (
                    <option value={option.value} key={option.value}>
                      {option.label}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-lg-4 text-right">
              <i
                className="fas fa-times fa-2x cursor-pointer text-muted"
                style={{ float: "right" }}
                onClick={e => {
                  this.setState({ showEmptyGradeMaster: false })
                  this.clearData()
                  this.getGradeMasterData()
                }}
              ></i>
            </div>
          </div>
          <div className="row my-5">
            <div className="col-lg-6">
              Based On:
              <div className="form-check form-check-inline ml-5">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gradebasedon"
                  id="percentage"
                  value={this.state.inputBasedOn}
                  checked={this.state.inputBasedOn === "percentage"}
                  onChange={e => {
                    this.setState({ inputBasedOn: "percentage" })
                  }}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      let el = document.getElementById("isReqDescription")
                      el.focus()
                    }
                  }}
                />
                <label className="form-check-label" htmlFor="percentage">
                  Percentage
                </label>
              </div>
              <div className="form-check form-check-inline ml-5">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gradebasedon"
                  id="points"
                  value={this.state.inputBasedOn}
                  checked={this.state.inputBasedOn === "points"}
                  onChange={e => {
                    this.setState({ inputBasedOn: "points" })
                  }}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      let el = document.getElementById("isReqDescription")
                      el.focus()
                    }
                  }}
                />
                <label className="form-check-label" htmlFor="points">
                  Points
                </label>
              </div>
            </div>
            <div className="col-lg-6 text-right">
              <FormGroup className="form-check-inline" check>
                <Label check>
                  <Input
                    type="checkbox"
                    id="isReqDescription"
                    name="isReqDescription"
                    className="text-brand"
                    checked={this.state.inputReqDesc}
                    onChange={() => {
                      this.setState(state => ({
                        inputReqDesc: !state.inputReqDesc,
                      }))
                    }}
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        this.setState(state => ({
                          inputReqDesc: !state.inputReqDesc,
                        }))
                      }
                    }}
                  />{" "}
                  Require Description
                </Label>
              </FormGroup>

              <FormGroup className="form-check-inline" check>
                <Label check>
                  <Input
                    type="checkbox"
                    id="isReqRemark"
                    name="isReqRemark"
                    className="text-brand"
                    checked={this.state.inputReqRemarks}
                    onChange={() => {
                      this.setState(state => ({
                        inputReqRemarks: !state.inputReqRemarks,
                      }))
                    }}
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        this.setState(state => ({
                          inputReqRemarks: !state.inputReqRemarks,
                        }))
                      }
                    }}
                  />{" "}
                  Require Remarks
                </Label>
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div>
                <form>
                  <table>
                    <thead>
                      <tr>
                        {this.state.inputBasedOn === "percentage" && (
                          <>
                            <th>
                              Letter Grade{" "}
                              <span style={{ color: "red" }}>*</span>
                            </th>
                            <th>
                              Lower Range{" "}
                              <span style={{ color: "red" }}>*</span>
                            </th>
                            <th>
                              Higher Range{" "}
                              <span style={{ color: "red" }}>*</span>
                            </th>
                            <th>
                              Grade Point{" "}
                              <span style={{ color: "red" }}>*</span>
                            </th>
                          </>
                        )}
                        {this.state.inputBasedOn === "points" && (
                          <>
                            <th>
                              Grade Point{" "}
                              <span style={{ color: "red" }}>*</span>
                            </th>
                            <th>
                              Letter Grade{" "}
                              <span style={{ color: "red" }}>*</span>
                            </th>
                          </>
                        )}
                        <th style={{ width: "70px" }}>
                          Color <span style={{ color: "red" }}>*</span>
                        </th>
                        {this.state.inputReqDesc && (
                          <th>
                            Description <span style={{ color: "red" }}>*</span>
                          </th>
                        )}
                        {this.state.inputReqRemarks && (
                          <th>
                            Remarks <span style={{ color: "red" }}>*</span>
                          </th>
                        )}
                        <th style={{ width: "150px" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.gradeConditions &&
                        this.state.gradeConditions.map((item, index) => (
                          <tr>
                            {this.state.inputBasedOn === "percentage" && (
                              <>
                                <td>
                                  <div className="form-group mr-2">
                                    <input
                                      id={`admin-sp-gs-grade-${index}`}
                                      type="text"
                                      maxLength="2"
                                      className="form-control"
                                      style={{ maxWidth: "150px" }}
                                      value={item.gradeLetter}
                                      onChange={e => {
                                        let gradeConditions = this.state
                                          .gradeConditions
                                        gradeConditions[index]["gradeLetter"] =
                                          e.target.value
                                        this.setState({
                                          gradeConditions: gradeConditions,
                                        })
                                      }}
                                      onKeyDown={e => {
                                        if (
                                          e.key === "Enter" &&
                                          e.target.value
                                        ) {
                                          let el = document.getElementById(
                                            `admin-sp-gs-low-${index}`
                                          )
                                          el.focus()
                                        }
                                      }}
                                    />
                                  </div>
                                </td>

                                <td>
                                  <div className="form-group mr-2 d-flex">
                                    <input
                                      id={`admin-sp-gs-low-${index}`}
                                      type="number"
                                      className="form-control"
                                      style={{ maxWidth: "150px" }}
                                      value={item.gradeLowerRange}
                                      onChange={e => {
                                        let gradeConditions = this.state
                                          .gradeConditions
                                        gradeConditions[index][
                                          "gradeLowerRange"
                                        ] = e.target.value
                                        this.setState({
                                          gradeConditions: gradeConditions,
                                        })
                                      }}
                                      onKeyDown={e => {
                                        if (
                                          e.key === "Enter" &&
                                          e.target.value
                                        ) {
                                          let el = document.getElementById(
                                            `admin-sp-gs-high-${index}`
                                          )
                                          el.focus()
                                        }
                                      }}
                                    />
                                    <span className="font-weight-bolder mt-2">
                                      %
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group mr-2 d-flex">
                                    <input
                                      id={`admin-sp-gs-high-${index}`}
                                      type="number"
                                      className="form-control"
                                      style={{ maxWidth: "150px" }}
                                      value={item.gradeHigherRange}
                                      onChange={e => {
                                        let gradeConditions = this.state
                                          .gradeConditions
                                        gradeConditions[index][
                                          "gradeHigherRange"
                                        ] = e.target.value
                                        this.setState({
                                          gradeConditions: gradeConditions,
                                        })
                                      }}
                                      onKeyDown={e => {
                                        if (
                                          e.key === "Enter" &&
                                          e.target.value
                                        ) {
                                          let el = document.getElementById(
                                            `admin-sp-gs-point-${index}`
                                          )
                                          el.focus()
                                        }
                                      }}
                                    />
                                    <span className="font-weight-bolder mt-2">
                                      %
                                    </span>
                                  </div>
                                </td>

                                <td>
                                  <div className="form-group mr-2">
                                    <input
                                      id={`admin-sp-gs-point-${index}`}
                                      type="number"
                                      className="form-control"
                                      style={{ maxWidth: "150px" }}
                                      value={item.gradePoints}
                                      onChange={e => {
                                        let gradeConditions = this.state
                                          .gradeConditions
                                        gradeConditions[index]["gradePoints"] =
                                          e.target.value
                                        this.setState({
                                          gradeConditions: gradeConditions,
                                        })
                                      }}
                                      onKeyDown={e => {
                                        if (
                                          e.key === "Enter" &&
                                          e.target.value
                                        ) {
                                          let id = `admin-sp-gs-grade-${index +
                                            1}`
                                          if (this.state.inputReqRemarks) {
                                            id = `admin-sp-gs-remark-${index}`
                                          }
                                          if (this.state.inputReqDesc) {
                                            id = `admin-sp-gs-desc-${index}`
                                          }
                                          let el = document.getElementById(id)
                                          if (el) {
                                            el.focus()
                                          }
                                        }
                                      }}
                                    />
                                  </div>
                                </td>
                              </>
                            )}

                            {this.state.inputBasedOn === "points" && (
                              <>
                                <td>
                                  <div className="form-group mr-2">
                                    <input
                                      id={`admin-sp-gs-point-${index}`}
                                      type="text"
                                      maxLength="2"
                                      className="form-control"
                                      style={{ maxWidth: "150px" }}
                                      value={item.gradeLetter}
                                      onChange={e => {
                                        let gradeConditions = this.state
                                          .gradeConditions
                                        gradeConditions[index]["gradeLetter"] =
                                          e.target.value
                                        this.setState({
                                          gradeConditions: gradeConditions,
                                        })
                                      }}
                                      onKeyDown={e => {
                                        if (
                                          e.key === "Enter" &&
                                          e.target.value
                                        ) {
                                          let el = document.getElementById(
                                            `admin-sp-gs-grade-${index}`
                                          )
                                          el.focus()
                                        }
                                      }}
                                    />
                                  </div>
                                </td>

                                <td>
                                  <div className="form-group mr-2">
                                    <input
                                      id={`admin-sp-gs-grade-${index}`}
                                      type="number"
                                      className="form-control"
                                      style={{ maxWidth: "150px" }}
                                      value={item.gradePoints}
                                      onChange={e => {
                                        let gradeConditions = this.state
                                          .gradeConditions
                                        gradeConditions[index]["gradePoints"] =
                                          e.target.value
                                        this.setState({
                                          gradeConditions: gradeConditions,
                                        })
                                      }}
                                      onKeyDown={e => {
                                        if (
                                          e.key === "Enter" &&
                                          e.target.value
                                        ) {
                                          let id = `admin-sp-gs-point-${index +
                                            1}`
                                          if (this.state.inputReqRemarks) {
                                            id = `admin-sp-gs-remark-${index}`
                                          }
                                          if (this.state.inputReqDesc) {
                                            id = `admin-sp-gs-desc-${index}`
                                          }
                                          let el = document.getElementById(id)
                                          if (el) {
                                            el.focus()
                                          }
                                        }
                                      }}
                                    />
                                  </div>
                                </td>
                              </>
                            )}

                            <td>
                              <div
                                className="cursor-pointer"
                                style={{
                                  display: "block",
                                  width: "25px",
                                  height: "25px",
                                  backgroundColor: item.gradeColor,
                                  borderRadius: "5px",
                                  marginTop: "-15px",
                                }}
                                onClick={e => {
                                  this.setState({
                                    showColorPickerModal: true,
                                    currentGradeConditionIndex: index,
                                  })
                                }}
                              ></div>
                            </td>

                            {this.state.inputReqDesc && (
                              <td>
                                <div className="form-group mr-2">
                                  <input
                                    id={`admin-sp-gs-desc-${index}`}
                                    type="text"
                                    className="form-control"
                                    value={item.description}
                                    onChange={e => {
                                      let gradeConditions = this.state
                                        .gradeConditions
                                      gradeConditions[index]["description"] =
                                        e.target.value
                                      this.setState({
                                        gradeConditions: gradeConditions,
                                      })
                                    }}
                                    onKeyDown={e => {
                                      if (e.key === "Enter" && e.target.value) {
                                        let id =
                                          this.state.inputBasedOn ===
                                          "percentage"
                                            ? `admin-sp-gs-grade-${index + 1}`
                                            : `admin-sp-gs-point-${index + 1}`

                                        if (this.state.inputReqRemarks) {
                                          id = `admin-sp-gs-remark-${index}`
                                        }
                                        let el = document.getElementById(id)
                                        if (el) {
                                          el.focus()
                                        }
                                      }
                                    }}
                                  />
                                </div>
                              </td>
                            )}

                            {this.state.inputReqRemarks && (
                              <td>
                                <div className="form-group mr-2">
                                  <input
                                    id={`admin-sp-gs-remark-${index}`}
                                    type="text"
                                    className="form-control"
                                    value={item.remarks}
                                    onChange={e => {
                                      let gradeConditions = this.state
                                        .gradeConditions
                                      gradeConditions[index]["remarks"] =
                                        e.target.value
                                      this.setState({
                                        gradeConditions: gradeConditions,
                                      })
                                    }}
                                    onKeyDown={e => {
                                      if (e.key === "Enter" && e.target.value) {
                                        let id =
                                          this.state.inputBasedOn ===
                                          "percentage"
                                            ? `admin-sp-gs-grade-${index + 1}`
                                            : `admin-sp-gs-point-${index + 1}`

                                        let el = document.getElementById(id)
                                        if (el) {
                                          el.focus()
                                        }
                                      }
                                    }}
                                  />
                                </div>
                              </td>
                            )}
                            <td>
                              <div
                                className="col"
                                style={{ marginTop: "-15px" }}
                              >
                                <i
                                  className="fas fa-plus text-success p-2 cursor-pointer"
                                  onClick={() => this.addGradeCondition()}
                                />
                                {this.state.gradeConditions.length > 1 && (
                                  <i
                                    className="fas fa-trash-alt text-danger p-2 cursor-pointer"
                                    onClick={() =>
                                      this.deleteGradeCondition(index, item)
                                    }
                                  />
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>

                  <div
                    style={{
                      border: "solid 1px #cccccc",
                      borderRadius: "10px",
                    }}
                    className="p-3 mt-5"
                  >
                    <h5 className="text-dark">Assigned Classes</h5>
                    <div className="my-4">
                      {this.state.gradeClasses.length > 0 &&
                        this.state.gradeClasses.map((itemClass, indexClass) => {
                          const gradeDets = this.props.classesList.filter(
                            item1 => itemClass === item1.id
                          )
                          return (
                            <span
                              color="light"
                              className="text-dark px-4 py-1 mr-3"
                              style={{
                                borderRadius: 12,
                                backgroundColor: "#cccccc",
                              }}
                            >
                              {gradeDets && gradeDets.length > 0
                                ? gradeDets[0].label
                                : ""}
                              <i
                                onClick={() => {
                                  let gradeClasses = this.state.gradeClasses
                                  gradeClasses.splice(indexClass, 1)
                                  this.setState({ gradeClasses: gradeClasses })
                                }}
                                className="fas fa-times text-muted ml-3 cursor-pointer"
                              ></i>
                            </span>
                          )
                        })}

                      {!this.state.showClassDropDown && (
                        <Button
                          className="bg-green text-light px-4 py-1 mr-3"
                          loading={this.state.isSavingQuiz}
                          style={{ borderRadius: 12, border: 0 }}
                          onClick={() => {
                            this.setState({ showClassDropDown: true })
                          }}
                        >
                          <span>+ Add Class</span>
                        </Button>
                      )}
                      {this.state.showClassDropDown && (
                        <select
                          className="mr-3"
                          onChange={e => {
                            let gradeClasses = this.state.gradeClasses
                            if (!gradeClasses.includes(e.target.value)) {
                              gradeClasses.push(e.target.value)
                            }
                            this.setState({
                              gradeClasses: gradeClasses,
                              showClassDropDown: false,
                            })
                          }}
                        >
                          <option value="">Select Class</option>
                          {this.props.classesList &&
                            this.props.classesList
                              .filter(
                                item =>
                                  !this.state.gradeClasses.includes(item.id)
                              )
                              .map(option => (
                                <option value={option.id} key={option.value}>
                                  {option.label}
                                </option>
                              ))}
                        </select>
                      )}
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-around mt-5">
                    <div className="col"></div>
                    <Button
                      className="px-4 py-1 rounded-pill"
                      style={{
                        backgroundColor: "#DBDBDB",
                        color: "#06B191",
                        border: "none",
                      }}
                      onClick={this.clearData}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="rounded-pill px-4 py-1 ml-3"
                      loading={this.state.isSavingQuiz}
                      style={{
                        backgroundColor: "#06B191",
                        border: 0,
                        color: "white",
                      }}
                      onClick={this.saveGradeMaster}
                    >
                      {this.state.isSavingGradeMaster ? (
                        <HashLoader
                          size={20}
                          color="#fff"
                          loading={this.state.isSavingQuiz}
                          sizeUnit="px"
                        />
                      ) : (
                        "Save"
                      )}
                    </Button>

                    <span className="col"></span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  gradeColorPickerModal = () => (
    <Modal
      isOpen={this.state.showColorPickerModal}
      backdropClassName="modal-backdrop-show"
      modalClassName="modal-dialog-shadow-none"
      contentClassName="border-14px"
      centered="true"
    >
      <div className="modal-body p-0">
        <header
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            padding: "15px 0 0 24px",
          }}
        >
          Select Color
        </header>
        <div className="form-group px-4 col-sm-12">
          <div className="d-flex align-items-center justify-content-around mb-5">
            {gradeColorList &&
              gradeColorList.length > 0 &&
              gradeColorList.map((item, i) => (
                <div
                  key={i}
                  className="cursor-pointer"
                  style={{
                    display: "inline-block",
                    width: "25px",
                    height: "25px",
                    backgroundColor: item,
                    borderRadius: "5px",
                  }}
                  onClick={e => {
                    let gradeConditions = this.state.gradeConditions
                    gradeConditions[this.state.currentGradeConditionIndex][
                      "gradeColor"
                    ] = item
                    this.setState({
                      showColorPickerModal: false,
                      currentGradeConditionIndex: 0,
                      gradeConditions: gradeConditions,
                    })
                  }}
                ></div>
              ))}
          </div>
        </div>
        <div className="col-md-12 pb-3 text-right">
          <Button
            color="light"
            className="text-muted px-4 py-1 mr-3"
            style={{ borderRadius: 12, border: 0 }}
            onClick={() => this.setState({ showColorPickerModal: false })}
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  )

  manageDefaultGradingModal = () => (
    <Modal
      isOpen={this.state.showManageDefaultGradingModal}
      backdropClassName="modal-backdrop-show"
      modalClassName="modal-dialog-shadow-none"
      contentClassName="border-14px"
      centered="true"
      size="lg"
    >
      <div className="modal-body p-0">
        <header
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            padding: "15px 0 0 24px",
          }}
        >
          Manage Default Grading
        </header>
        <div className="form-group px-5 col-sm-12">
          <div className="row mr-2">
            <div className="col-2">
              <h5 className="text-dark">Class</h5>
            </div>
            <div className="col-5 text-center">
              <h5 className="text-dark">Scholastic</h5>
            </div>
            <div className="col-5 text-center">
              <h5 className="text-dark">Co-Scholastic</h5>
            </div>
          </div>

          <div
            style={{
              maxHeight: "24rem",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {this.props.classesList &&
              this.props.classesList.length > 0 &&
              this.props.classesList.map((item, index) => {
                // const scholasticValue = this.state.gradeMastersScholastic.filter(
                //   item1 =>
                //     item1.gradeClasses.filter(
                //       item2 => item2.sectionId == item.id
                //     ).length > 0
                // )
                let selectedScholasticValue = this.state.manageDefaultScholasticGradingData.filter(
                  dItem1 => dItem1.sectionId === item.id
                )
                // const coScholasticValue = this.state.gradeMastersCoScholastic.filter(
                //   item1 =>
                //     item1.gradeClasses.filter(
                //       item2 => item2.sectionId == item.id
                //     ).length > 0
                // )
                let selectedCoScholasticValue = this.state.manageDefaultCoScholasticGradingData.filter(
                  dItem1 => dItem1.sectionId === item.id
                )
                return (
                  <div className="row py-2 mr-2">
                    <div className="col-2">{item.label}</div>
                    <div className="col-5">
                      <select
                        className="form-control"
                        onChange={e => {
                          let manageDefaultScholasticGradingData = this.state
                            .manageDefaultScholasticGradingData
                          if (selectedScholasticValue.length === 0) {
                            manageDefaultScholasticGradingData.push({
                              gradeId: e.target.value,
                              sectionId: item.id,
                            })
                          } else {
                            for (let manageDefaultScholasticGradingDataItem of manageDefaultScholasticGradingData) {
                              if (
                                manageDefaultScholasticGradingDataItem.sectionId ===
                                item.id
                              ) {
                                manageDefaultScholasticGradingDataItem.gradeId =
                                  e.target.value
                              }
                            }
                          }
                          this.setState({ manageDefaultScholasticGradingData })
                        }}
                        value={
                          selectedScholasticValue[0]
                            ? selectedScholasticValue[0].gradeId
                            : ""
                        }
                      >
                        <option value="">Select Grading System</option>
                        {this.state.gradeMastersScholastic &&
                          this.state.gradeMastersScholastic.map(option => (
                            <option value={option.id} key={option.value}>
                              {option.title}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-5">
                      <select
                        className="form-control"
                        onChange={e => {
                          let manageDefaultCoScholasticGradingData = this.state
                            .manageDefaultCoScholasticGradingData
                          if (selectedCoScholasticValue.length === 0) {
                            manageDefaultCoScholasticGradingData.push({
                              gradeId: e.target.value,
                              sectionId: item.id,
                            })
                          } else {
                            for (let manageDefaultCoScholasticGradingDataItem of manageDefaultCoScholasticGradingData) {
                              if (
                                manageDefaultCoScholasticGradingDataItem.sectionId ===
                                item.id
                              ) {
                                manageDefaultCoScholasticGradingDataItem.gradeId =
                                  e.target.value
                              }
                            }
                          }
                          this.setState({
                            manageDefaultCoScholasticGradingData,
                          })
                        }}
                        value={
                          selectedCoScholasticValue[0]
                            ? selectedCoScholasticValue[0].gradeId
                            : ""
                        }
                      >
                        <option value="">Select Grading System</option>
                        {this.state.gradeMastersCoScholastic &&
                          this.state.gradeMastersCoScholastic.map(option => (
                            <option value={option.id} key={option.id}>
                              {option.title}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
        <div className="col-md-12 pb-3 text-center">
          <Button
            color="light"
            className="text-muted px-4 py-1 mr-3"
            style={{
              backgroundColor: "#DBDBDB",
              color: "#06B191",
              border: "none",
              borderRadius: "12px",
            }}
            onClick={() =>
              this.setState({ showManageDefaultGradingModal: false })
            }
          >
            Close
          </Button>
          <Button
            color="light"
            className="bg-green text-light px-4 py-1 ml-3"
            style={{ borderRadius: 12, border: 0 }}
            onClick={() => {
              this.updateGradeClasses()
            }}
          >
            {this.state.isSavingGradeClasses ? (
              <HashLoader
                size={20}
                color="#fff"
                loading={this.state.isSavingGradeClasses}
                sizeUnit="px"
              />
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </div>
    </Modal>
  )

  render() {
    return (
      <AppWrapper
        showAdminProfileSidemenu
        showAdminBackToDashboard
        screenName="Grade System"
      >
        <div className="row my-4">
          <div className="col-12 text-right">
            <button
              className="btn btn-secondary bg-light text-dark border-0 mr-2"
              onClick={e => {
                this.setState({
                  showManageDefaultGradingModal: true,
                })
              }}
            >
              Manage Default Grading
            </button>
            <button
              className="btn btn-success bg-green border-0"
              onClick={e => {
                this.setState({
                  showEmptyGradeMaster: !this.state.showEmptyGradeMaster,
                })
                setTimeout(() => {
                  let el = document.getElementById("admin-sp-gs-name")
                  el.focus()
                }, 500)
              }}
            >
              Create Custom Grading
            </button>
          </div>
        </div>
        {this.manageDefaultGradingModal()}
        {this.renderEmptyGradeMaster()}
        {this.gradeColorPickerModal()}

        {this.state.isShowLoading && (
          <HashLoader
            css={{
              position: "fixed",
              width: "100%",
              height: "100%",
              left: "0px",
              top: "0px",
            }}
            size={35}
            color="#094251"
            loading={this.state.isShowLoading}
            sizeUnit="px"
          />
        )}

        {this.state.gradeMasters &&
          !this.state.showEmptyGradeMaster &&
          this.state.gradeMasters
            .sort(function(one, two) {
              if (one.title !== "" && two.title !== "") {
                const oneValue = one.title.split(" ")
                const twoValue = two.title.split(" ")
                if (oneValue.length > 0 && twoValue.length > 0) {
                  return parseInt(twoValue[0]) - parseInt(oneValue[0])
                } else {
                  return -1
                }
              }
              return 1
            })
            .map(item => (
              <div
                className="card border-1 border-solid mb-4"
                style={{ borderRadius: "10px" }}
              >
                <div className="row m-3">
                  <div className="col-lg-4">
                    <h4 className="text-dark mt-2">
                      {item.title} |{" "}
                      {item.basedOn === "percentage" ? "Percentage" : "Points"}
                      {/* {typeof(item.title)} */}
                    </h4>
                  </div>
                  <div className="col-lg-3">
                    <h5 className="text-dark mt-3">
                      {item.examType === "scholastic"
                        ? "Scholastic"
                        : "Co-Scholastic"}
                    </h5>
                  </div>
                  <div className="col-lg-3">
                    <h5 className="text-dark mt-3">
                      {item.gradeClasses.length > 0
                        ? item.gradeClasses.map((itemClass, indexClass) => (
                            <span className="mr-2">
                              {itemClass.section.className}-
                              {itemClass.section.sectionName}{" "}
                              {indexClass + 1 < item.gradeClasses.length
                                ? ","
                                : ""}
                            </span>
                          ))
                        : "assign class"}
                    </h5>
                  </div>
                  <div className="col-lg-1"></div>
                  <div className="col-lg-1 text-right">
                    <UncontrolledDropdown direction="left">
                      <DropdownToggle
                        tag="button"
                        className="mt-2"
                        style={{ float: "right" }}
                      >
                        <i className="fas fa-ellipsis-v text-black cursor-pointer"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={e => {
                            this.setState({
                              selectedGradeMasterId: item.id,
                              gradeClasses: item.gradeClasses.map(
                                value => value.sectionId
                              ),
                              gradeConditions: item.gradeConditions,
                              showEmptyGradeMaster: true,
                              inputTitle: item.title,
                              inputBasedOn: item.basedOn,
                              inputExamType: item.examType,
                              inputReqDesc: item.reqDesc,
                              inputReqRemarks: item.reqRemarks,
                              currentGradeConditionIndex: 0,
                              openSingleGradeMaster: false,
                            })
                          }}
                        >
                          Edit
                        </DropdownItem>
                        <DropdownItem
                          onClick={e => {
                            this.deteleGradeMaster(item.id)
                          }}
                        >
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    {this.state.selectedGradeMasterId === item.id &&
                    this.state.openSingleGradeMaster ? (
                      <i
                        className="fas fa-angle-up mt-2 fa-2x mr-3 text-muted cursor-pointer"
                        style={{ float: "right" }}
                        onClick={e => {
                          this.setState({
                            selectedGradeMasterId: null,
                            openSingleGradeMaster: false,
                          })
                        }}
                      ></i>
                    ) : (
                      <i
                        className="fas fa-angle-down mt-2 fa-2x mr-3 text-muted cursor-pointer"
                        style={{ float: "right" }}
                        onClick={e => {
                          this.setState({
                            selectedGradeMasterId: item.id,
                            openSingleGradeMaster: true,
                          })
                        }}
                      ></i>
                    )}
                  </div>
                </div>

                {this.state.selectedGradeMasterId === item.id &&
                  this.state.openSingleGradeMaster && (
                    <div className="mx-4">
                      <table
                        style={{ width: "100%" }}
                        className="table table-bordered"
                      >
                        <thead>
                          <tr>
                            <th>Letter Grade</th>
                            {item.basedOn === "percentage" && (
                              <>
                                <th>Lower Range</th>
                                <th>Higher Range</th>
                              </>
                            )}
                            <th>Grade Point</th>
                            <th style={{ width: "100px" }}>Color</th>
                            {item.reqDesc && <th>Description</th>}
                            {item.reqRemarks && <th>Remarks</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {item.gradeConditions &&
                            item.gradeConditions.length > 0 &&
                            item.gradeConditions.map((item1, index1) => (
                              <tr>
                                <td>{item1.gradeLetter}</td>
                                {item.basedOn === "percentage" && (
                                  <>
                                    <td>
                                      {Math.round(item1.gradeLowerRange)}%
                                    </td>
                                    <td>
                                      {Math.round(item1.gradeHigherRange)}%
                                    </td>
                                  </>
                                )}
                                <td>{Math.round(item1.gradePoints)}</td>
                                <td>
                                  <div
                                    style={{
                                      display: "block",
                                      width: "25px",
                                      height: "25px",
                                      backgroundColor: item1.gradeColor,
                                      borderRadius: "5px",
                                    }}
                                  ></div>
                                </td>
                                {item.reqDesc && (
                                  <td>{item1.description}&nbsp;</td>
                                )}
                                {item.reqRemarks && (
                                  <td>{item1.remarks}&nbsp;</td>
                                )}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
              </div>
            ))}
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    classesList: state.classDetailsState.classList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getGradeMasterDetails,
      getGradeMasterList,
      saveGradeMaster,
      deleteGradeMaster,
      showNotification,
      getClassDetails,
      updateGradeClasses,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(GradeMasterScreen)
