/*************************************************
 * RedInk
 * @exports
 * @class LoginAction.js
 * @extends Component
 * @author Sasidharan // on 18/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import store from "store"
import _ from "lodash"
import URL from "../config"
import Client from "../utils/axiosInstance"
import { myLog } from "../utils/Utility"
import { ACTION_TYPES } from "./types"
import { getSchoolsListDetails } from "./superAdmin/schoolDetailsAction"
import { showNotification } from "./CommonAction"
import { SUCCESS_TOAST, VALIDATION_MSG, DANGER_TOAST } from "../utils/Constants"

export const saveIsShowLoadingInStore = isShowLoading => {
  return {
    type: ACTION_TYPES.IS_SHOW_LOADING,
    payload: isShowLoading,
  }
}

export const logInUser = (username, password, callBack) => {
  return dispatch => {
    return Client.OAUTH(username, password)
      .then(response => {
        if (
          _.has(response, "access_token") &&
          _.has(response, "token_type") &&
          _.has(response, "refresh_token") &&
          _.has(response, "expires_in") &&
          _.has(response, "userInfo")
        ) {
          store.set("userSession", response)
          store.set("expiryTime", Client.tokenExpires(response.expires_in))
          myLog("====LOGIN RESPONSE===::::", response)
          // dispatch(getQuickInfoDetails(()=>{}))
          dispatch({
            type: ACTION_TYPES.LOGIN_USER,
            payload: response,
          })
          dispatch({
            type: ACTION_TYPES.USER_INFO,
            payload: response.userInfo,
          })
          dispatch(getSchoolsListDetails(() => {}))
          callBack(true, response)
        } else {
          callBack(false)
          dispatch(
            showNotification(
              DANGER_TOAST,
              VALIDATION_MSG.OOPS,
              VALIDATION_MSG.AUTH_FAILED
            )
          )
        }
      })
      .catch(error => {
        if (error === "Network Error") {
          dispatch(
            showNotification(
              DANGER_TOAST,
              VALIDATION_MSG.OOPS,
              "Oops! Check your Internet connection"
            )
          )
          callBack(false, error)
        } else {
          callBack(false, error)
          dispatch(
            showNotification(
              DANGER_TOAST,
              VALIDATION_MSG.OOPS,
              VALIDATION_MSG.AUTH_FAILED
            )
          )
        }
        myLog("====LOGIN ERROR===????", error)
      })
  }
}

export const forgetPassword = (params, callBack) => {
  return async dispatch => {
    try {
      myLog("====forgetPassword body===::::", params)
      const response = await Client.FORGET(params)
      if (response.responseCode === 0) {
        myLog("====forgetPassword RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====forgetPassword ERROR===????", error)
    }
  }
}

export const changePassword = (newPassword, callBack) => {
  return async dispatch => {
    try {
      let body = {
        newPassword,
      }
      myLog("====changePassword body===::::", body)
      const response = await Client.post(URL.CHANGE_PASSWORD, body, true)
      if (response.responseCode === 0) {
        myLog("====changePassword RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====changePassword ERROR===????", error)
    }
  }
}

export const resetPassword = (params, callBack) => {
  return async dispatch => {
    try {
      myLog("====resetPassword body===::::", params)
      const response = await Client.RESET(params)
      if (response.responseCode === 0) {
        myLog("====resetPassword RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====resetPassword ERROR===????", error)
    }
  }
}
