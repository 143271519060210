/*************************************************
 * RedInk
 * @exports
 * @class TakeAttendance.js
 * @extends Component
 * @author Sasidharan // on 01/02/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import Chart from "chart.js"
import moment from "moment"
import { Scrollbars } from "react-custom-scrollbars"
import AppWrapper from "../Wrapper"
import {
  saveAttendenceInStore,
  getClassList,
  getStudentAttendanceList,
  saveStudentAttedanceDetails,
  updateMarkAsHoliday,
} from "../../../actions/teacher/TakeAttendanceAction"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { HashLoader } from "react-spinners"
import MessageModal from "../MessageModal"
import store from "store"
import { myLog } from "../../../utils/Utility"
import { DatePicker } from "antd"
import "antd/dist/antd.css"
// import Calendar from "react-calendar"
import "react-modern-calendar-datepicker/lib/DatePicker.css"
import { Calendar } from "react-modern-calendar-datepicker"
import { getStudentAttendanceReportHolidayList } from "../../../actions/teacher/AttendanceReportAction"

let myGraph = null

class TakeAttendance extends Component {
  // sortClassOrder = classess => {
  //   const classOrder = [
  //     "-",
  //     "JKG",
  //     "LKG",
  //     "SKG",
  //     "UKG",
  //     ...Array(12)
  //       .fill()
  //       .map((_, idx) => `${1 + idx}`),
  //   ]
  //   const classes = classess
  //   classes.sort((a, b) => {
  //     const aClass = a.className || "-"
  //     const aSection = a.sectionName || "-"
  //     const bClass = b.className || "-"
  //     const bSection = b.sectionName || "-"
  //     if (aClass === bClass) return aSection < bSection ? -1 : 1
  //     else return classOrder.indexOf(aClass) - classOrder.indexOf(bClass)
  //   })
  //   return classes
  // }

  static getDerivedStateFromProps(props, state) {
    return {}
  }

  constructor(props) {
    super(props)
    document.title = "Attendance | Redink"
    this.state = {
      takeAttendanceDate: moment().format("YYYY-MM-DD"),
      takeAttendanceDateDisplay: moment(),
      selectedClass: "",
      isShowLoading: false,
      markAsHoliday: false,
      monthlyData: null,
      holidayList: [],
      AttendenceTaken: false,
      isEditing: false,
    }
  }

  componentDidMount = () => {
    this.props.getClassList((success, response) => {
      if (success) {
        this.setState(
          {
            selectedClass: response[0].id,
          },
          () => {
            this.getStudentPresentAndAbsentList()
          }
        )
      }
    })
  }

  getStudentPresentAndAbsentList = () => {
    let params = {
      date: this.state.takeAttendanceDate,
      id: this.state.selectedClass,
    }
    this.props.getStudentAttendanceReportHolidayList(
      { sectionId: this.state.selectedClass },
      (success, response) => {
        if (success) {
          this.setState({ holidayList: response })
        }
      }
    )
    this.props.getStudentAttendanceList(params, (success, res) => {
      if (success) {
        this.setState({
          markAsHoliday: res.isHoliday,
          AttendenceTaken: res.isAttendanceTaken,
        })
        if (this.state.AttendenceTaken === false) {
          this.setState({ isEditing: true })
        }
        this.showTakeAttendanceGraph()
      }
    })
  }

  markAsHoliday = () => {
    let params = {
      dates: [this.state.takeAttendanceDate],
      sections: [{ sectionId: this.state.selectedClass }],
      markAsHoliday: this.state.markAsHoliday,
    }
    this.props.updateMarkAsHoliday(params, () => {
      this.getStudentPresentAndAbsentList()
    })
  }

  showTakeAttendanceGraph = () => {
    const attendanceGraph = this.attendanceGraph
    let presentCount = 0
    let absentCount = 0
    if (this.props.attedenceList && this.props.attedenceList.length > 0) {
      this.props.attedenceList &&
        this.props.attedenceList.forEach(item => {
          if (item.isPresent) {
            presentCount++
          }
          if (!item.isPresent) {
            absentCount++
          }
        })
      if (myGraph != null) {
        myGraph.destroy()
      }
      myGraph = new Chart(attendanceGraph, {
        type: "doughnut",
        data: {
          labels: ["Present", "Absent"],
          datasets: [
            {
              label: "Count",
              data: [presentCount, absentCount],
              backgroundColor: ["#479997", "#FF3059"],
              fill: false,
              borderWidth: 1,
            },
          ],
        },
        options: {
          legend: {
            display: true,
          },
        },
      })
    }
  }

  /**
   * Toggles attendance
   */
  toggleAttendance = index => {
    if (this.state.isEditing === true) {
      let attedenceList = this.props.attedenceList.slice()
      attedenceList[index].isPresent = !attedenceList[index].isPresent
      this.props.saveAttendenceInStore(attedenceList)
    }
  }
  /**
   * saves daily attendances
   */
  saveAttendence = () => {
    if (this.state.selectedClass !== "") {
      let params = {
        classId: this.state.selectedClass,
        date: this.state.takeAttendanceDate,
        studentsAttendanceList: this.props.attedenceList,
      }
      this.setState({ isShowLoading: true }, () => {
        this.props.saveStudentAttedanceDetails(params, success => {
          // if (success) this.props.history.push(URL_PATH.TEACHER_VIEW_ATTENDANCE)
          this.getStudentPresentAndAbsentList()
          this.setState({ isShowLoading: false })
        })
      })
    }
  }

  /**
   * Handles message modal
   */
  closeMessageModal = () => this.setState({ isMessageModalOpen: false })

  /**
   * handles on click message icon in sidebar
   */
  onClickMessageIcon = () => {
    this.setState({ isMessageModalOpen: !this.state.isMessageModalOpen })
  }
  /**
   * handles message submit
   */
  onSubmitMessage = () => {
    this.closeMessageModal()
  }
  render() {
    let { attedenceList, classes } = this.props
    myLog("Classes list", classes)
    return (
      <AppWrapper
        openMessageModal={() => this.onClickMessageIcon()}
        teacherAttendanceDashboard
        teacherDashboard
        showTeacherThree
        showTeacherLogo
        screenName={"Take Attendance"}
      >
        <MessageModal
          isModalOpen={this.state.isMessageModalOpen}
          closeModal={() => this.onClickMessageIcon()}
          onSubmitMessage={() => this.onSubmitMessage()}
        />
        <div className="row">
          <div className="col-sm-8">
            <div className="card mb-3">
              <div className="d-flex row justify-content-around px-3 py-2">
                <div className="col-sm">
                  <div className="form-group">
                    <small>Class</small>
                    <select
                      className="form-control input-border-color"
                      value={this.state.selectedClass}
                      onChange={e => {
                        this.setState(
                          {
                            selectedClass: e.target.value,
                            AttendenceTaken: true,
                          },
                          () => {
                            this.getStudentPresentAndAbsentList()
                          }
                        )
                      }}
                    >
                      {classes &&
                        classes.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.value}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-sm">
                  <div className="form-group">
                    <small>Date</small>
                    {/* <input
                      type="date"
                      className="form-control unstyled input-border-color"
                      value={
                        this.props.location.state
                          ? this.props.location.state.dateName
                          : this.state.takeAttendanceDate
                      }
                      onChange={e =>
                        this.setState(
                          { takeAttendanceDate: e.target.value },
                          () => this.getStudentPresentAndAbsentList()
                        )
                      }
                    /> */}
                    <DatePicker
                      className="form-control unstyled input-border-color"
                      format="DD-MM-YYYY"
                      value={
                        this.props.location.state
                          ? this.props.location.state.dateName
                          : this.state.takeAttendanceDateDisplay
                      }
                      bordered={true}
                      allowClear={false}
                      onChange={e => {
                        this.setState(
                          {
                            takeAttendanceDate: moment(e).format("YYYY-MM-DD"),
                            takeAttendanceDateDisplay: e,
                          },
                          () => this.getStudentPresentAndAbsentList()
                        )
                      }}
                    />
                  </div>
                </div>
              </div>
              {store.get("userSession").userInfo.role !== 4 ? (
                <div className="d-flex justify-content-end mx-4">
                  <div>
                    <input
                      type="checkbox"
                      checked={this.state.markAsHoliday}
                      onChange={() => {
                        this.setState(
                          { markAsHoliday: !this.state.markAsHoliday },
                          () => {
                            this.markAsHoliday()
                          }
                        )
                      }}
                    />
                    <label className="ml-2">Mark as holiday</label>
                  </div>
                </div>
              ) : null}
              {this.state.markAsHoliday ? (
                <div className="text-center mb-5">Marked as Holiday</div>
              ) : null}
              {this.state.takeAttendanceDate > moment().format("YYYY-MM-DD") ? (
                <div className="text-center mb-5">
                  Date greater than today's date. Only mark as holiday can do.
                </div>
              ) : null}
              {!this.state.markAsHoliday &&
                (this.state.takeAttendanceDate <=
                moment().format("YYYY-MM-DD") ? (
                  <>
                    {attedenceList && attedenceList.length > 0 ? (
                      <div>
                        <Scrollbars
                          autoHeight
                          autoHeightMin={100}
                          autoHeightMax="63vh"
                          autoHide
                        >
                          <div
                            className="table-responsive attendance-table px-3"
                            style={{
                              overflowY: "auto",
                              height: "61vh",
                              scrollbarWidth: "thin",
                            }}
                          >
                            <table className="table table-hover">
                              <thead
                                className="bg-theme-light text-white"
                                style={{ position: "sticky", top: 0 }}
                              >
                                <tr>
                                  <th scope="col" className="text-center py-2">
                                    Register No.
                                  </th>
                                  <th></th>
                                  <th></th>
                                  <th scope="col" className="pl-5">
                                    Name
                                  </th>
                                  <th scope="col" className="text-center py-2">
                                    Attendance
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {attedenceList &&
                                  attedenceList
                                    .sort(function(a, b) {
                                      if (
                                        a.registerRollNumber !== "" &&
                                        a.registerRollNumber !== "-"
                                      ) {
                                        return (
                                          a.registerRollNumber -
                                          b.registerRollNumber
                                        )
                                      } else {
                                        return a.name.localeCompare(b.name)
                                      }
                                    })
                                    .map((item, index) => {
                                      return (
                                        <tr key={index}>
                                          <td className="text-center">
                                            {item.registerRollNumber}
                                          </td>
                                          <td></td>
                                          <td></td>
                                          <td>{item.name}</td>
                                          <td className="text-center">
                                            {item.isPresent ? (
                                              <span
                                                className="attendance-present"
                                                onClick={() =>
                                                  this.toggleAttendance(index)
                                                }
                                              >
                                                P
                                              </span>
                                            ) : (
                                              <span
                                                className="attendance-absent"
                                                onClick={() =>
                                                  this.toggleAttendance(index)
                                                }
                                              >
                                                A
                                              </span>
                                            )}
                                          </td>
                                        </tr>
                                      )
                                    })}
                              </tbody>
                            </table>
                          </div>
                        </Scrollbars>
                        <div className="d-flex justify-content-center m-2">
                          {this.state.isShowLoading ? (
                            <HashLoader
                              sizeUnit={"px"}
                              size={16}
                              color={"#ffffff"}
                              loading={this.state.isShowLoading}
                            />
                          ) : this.state.isEditing === false ? (
                            <button
                              className="btn btn-sm text-white br-10 px-4"
                              style={{ backgroundColor: "#ef375c" }}
                              onClick={() => {
                                this.setState({
                                  isEditing: true,
                                })
                              }}
                            >
                              Edit
                            </button>
                          ) : this.state.isEditing === true ? (
                            <button
                              className="btn btn-sm text-white br-10 px-4"
                              style={{ backgroundColor: "#06b191" }}
                              onClick={() => {
                                this.setState({
                                  isEditing: false,
                                })
                                this.saveAttendence()
                              }}
                              // onClick={() => this.saveAttendence()}
                            >
                              Save
                            </button>
                          ) : this.state.AttendenceTaken === false ? (
                            <button
                              className="btn btn-sm text-white br-10 px-4"
                              style={{ backgroundColor: "#06b191" }}
                              onClick={() => {
                                this.setState({
                                  isEditing: false,
                                })
                                this.saveAttendence()
                              }}
                              // onClick={() => this.saveAttendence()}
                            >
                              Save
                            </button>
                          ) : null}
                          {/* <button
                            className="btn btn-sm badge-success rounded-pill px-4"
                            onClick={() => this.saveAttendence()}
                          >
                            {this.state.isShowLoading ? (
                              <HashLoader
                                sizeUnit={"px"}
                                size={16}
                                color={"#ffffff"}
                                loading={this.state.isShowLoading}
                              />
                            ) : this.state.isSaved === false ? (
                              <div
                              // onClick={() => {
                              //   this.setState({ isSaved: false })
                              // }}
                              >
                                <i className="far fa-save mr-1" />
                                Save
                              </div>
                            ) : (
                              <div>
                                <i className="far fa-save mr-1" />
                                Edit
                              </div>
                            )}
                          </button> */}
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{ position: "fixed", top: "50%", left: "37%" }}
                      >
                        <HashLoader
                          sizeUnit={"px"}
                          size={25}
                          color={"#084353"}
                          loading={true}
                        />
                      </div>
                    )}
                  </>
                ) : null)}
            </div>
          </div>

          {/* Right Section */}
          <div className="col-sm-4">
            {/* <div className="justify-content-center card shadow p-3 mb-2">
              <a
                className="border-bottom-primary card cursor-pointer mb-4 box-shadow-none text-dark"
                id="UncontrolledTooltipAttendanceTemplate"
                href={
                  "https://redink-assets.s3.ap-south-1.amazonaws.com/Attendance_Template.xlsx"
                }
                download
              >
                <span className="px-3 py-1">
                  <i className="fal fa-table mr-2"></i>
                  Download Template
                </span>
              </a>
              <UncontrolledTooltip
                placement="top"
                className="bg-danger"
                modifiers={tooltipModifiers}
                target="UncontrolledTooltipAttendanceTemplate"
              >
                Template to fill & upload the student attendance
              </UncontrolledTooltip>
              <div className="border-bottom card cursor-pointer mb-2 box-shadow-none">
                <span className="px-3 py-1">
                  <i className="fal fa-file-upload mr-2"></i>
                  Upload Marked Attendance
                  <input
                    type="file"
                    className="cursor-pointer"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    style={{
                      opacity: 0.0,
                      position: "absolute",
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </span>
              </div>
            </div> */}

            <div>
              {
                // /* <Calendar
                // id="Calendar"
                // className="shadow br-0 rounded mb-0 col-sm py-2 w-auto"
                // style={{ marginBottom: "0%" }}
                // onChange={() => {}}
                // // tileClassName={
                // //   /* condition to sync with state */
                // //   this.state.monthlyData ? this.setCalendarClass : null
                // // }
                // /> */
              }
              {this.state.holidayList ? (
                <div className="col-sm bg-theme-light px-3 py-2 br-10 mx-2 d-flex flex-column justify-content-between">
                  <div className="d-flex align-items-center justify-content-center">
                    <Calendar
                      value={null}
                      onChange={val => {
                        const date = val.year + "-" + val.month + "-" + val.day
                        this.setState(
                          {
                            takeAttendanceDate: moment(date, "YYYY-M-D").format(
                              "YYYY-MM-DD"
                            ),
                            takeAttendanceDateDisplay: moment(date, "YYYY-M-D"),
                          },
                          () => this.getStudentPresentAndAbsentList()
                        )
                        this.setState({ date: val })
                      }}
                      colorPrimary="#9c88ff"
                      calendarClassName="custom-calendar" // and this
                      calendarTodayClassName="custom-today-day" // also this
                      shouldHighlightWeekends
                      customDaysClassName={this.state.holidayList}
                    />
                  </div>
                </div>
              ) : null}
              <div className="my-3 d-flex justify-content-around">
                <div className="d-flex">
                  <div className="working-day px-3 py-2"></div>
                  <div className="ml-1"> working days</div>
                </div>

                <div className="d-flex">
                  <div className="holiday px-3 py-2"></div>
                  <div className="ml-1">holidays</div>
                </div>

                <div className="d-flex">
                  <div className="noo-data px-3 py-2"></div>
                  <div className="ml-1">no data</div>
                </div>
              </div>
            </div>

            {/* <div className="card mt-2 pt-2 pb-3 mb-2">
              <b className="ml-3 mt-1 mb-2">Today's Report</b>
              <canvas
                // style={{ width: 800, height: 600 }}
                ref={attendanceGraph =>
                  (this.attendanceGraph = attendanceGraph)
                }
              />
            </div> */}

            <div className="card shadow mt-2 pt-2 pb-2 mb-2">
              <b className="ml-3 mx-2 pb-2">Absentees</b>
              <Scrollbars
                autoHeight
                autoHeightMin={100}
                autoHeightMax="23vh"
                autoHide
              >
                <div className="table-responsive attendance-table px-3">
                  <table className="table table-hover table-striped">
                    <tbody>
                      {attedenceList && attedenceList.length === 0 ? (
                        <div className="text-center">No student's found</div>
                      ) : null}
                      {attedenceList &&
                        attedenceList.map((item, index) => {
                          if (!item.isPresent) {
                            return (
                              <tr key={index}>
                                <td className="text-center">{item.name}</td>
                                {/* <td className="text-center">
                                  {item.isPresent ? (
                                    <span className="attendance-present disabled">
                                      P
                                    </span>
                                  ) : (
                                    <span className="attendance-absent">A</span>
                                  )}
                                </td> */}
                              </tr>
                            )
                          }
                          return null
                        })}
                    </tbody>
                  </table>
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    attedenceList: state.takeAttendanceState.attedenceList,
    classes: state.takeAttendanceState.teacherClassList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      saveAttendenceInStore,
      saveStudentAttedanceDetails,
      getClassList,
      getStudentAttendanceList,
      updateMarkAsHoliday,
      getStudentAttendanceReportHolidayList,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(TakeAttendance)
