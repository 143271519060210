/*************************************************
 * RedInk
 * @exports
 * @class index.js
 * @extends Component
 * @author Sasidharan // on 15/02/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { UncontrolledTooltip } from "reactstrap"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import { getMarkUploadStatusDetails } from "../../actions/admin/MarkUploadStatusAction"
import { getExamListDetails } from "../../actions/admin/PerformanceReportAction"

import girlPic from "../../assets/images/female.png"
import boyPic from "../../assets/images/men.png"
import AppWrapper from "./Wrapper1"

class MarkUploadStatusScreen extends Component {
  constructor(props) {
    super(props)
    document.title = "Mark Entry Status | Redink"
    this.state = {
      isMessageModalOpen: false,
      reportDate: "",
      selectedAssessmentName: "",
      assessmentList: null,
    }
  }

  componentDidMount = () => {
    this.getMarkUploadStatus()
  }

  getMarkUploadStatus = () => {
    this.props.getExamListDetails(
      { sortBy: "latestSubjectPerformanceUpdatedOn" },
      (success, response) => {
        if (success) {
          this.setState({
            selectedExam: response[0].assessmentId,
            selectedAssessmentName: response[0].assessmentName,
            assessmentList: response,
          })
          this.getMarkUploadStatusService(response[0].assessmentId)
        }
      }
    )
  }

  getMarkUploadStatusService = id => {
    const findAssessmentIndex = this.state.assessmentList.findIndex(
      obj => id === obj.assessmentId
    )
    this.setState({
      selectedAssessmentName: this.state.assessmentList[findAssessmentIndex]
        .assessmentName,
    })
    let object = {
      assessmentId: id,
    }
    this.props.getMarkUploadStatusDetails(object, () => {})
  }

  getIndicator = number => {
    if (number === 0) return "attendance-absent"
    if (number === 1) return "attendance-inprogress"
    if (number === 2) return "attendance-present"
    return "attendance-absent"
  }

  render() {
    return (
      <AppWrapper
        openMessageModal={this.onClickMessageIcon}
        showSchoolLogo
        showAdminThree
        adminDashboard
        screenName="Mark Entry Status"
      >
        {/* <div className="d-flex justify-content-between"> */}
        {/* <h5 className="text-dark p-2">Mark Entry Status</h5> */}
        {/* <div>
            <select
              className="form-control mb-3"
              value={this.state.selectedPlan}
              onChange={e => this.setState({ selectedPlan: e.target.value })}
            >
              <option>SCHOLASTIC</option>
              <option>CO-SCHOLASTIC</option>
            </select>
          </div> */}
        {/* </div> */}
        <div
          style={{
            position: "sticky",
            top: "50px",
            zIndex: 3,
            margin: "0 -18px",
            padding: "0 18px",
          }}
          className="bg-white"
        >
          <div className="d-flex justify-content-end">
            <div className="d-flex mt-3">
              <div>
                <span
                  className="ml-3 attendance-present"
                  style={{ padding: "5px 14px" }}
                ></span>
                <span className="ml-2">Completed</span>
              </div>
              <div>
                <span
                  className="ml-3 attendance-inprogress"
                  style={{ padding: "5px 14px" }}
                ></span>
                <span className="ml-2">In progress</span>
              </div>
              <div>
                <span
                  className="ml-3 attendance-absent"
                  style={{ padding: "5px 14px" }}
                ></span>
                <span className="ml-2">Not Started</span>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between mt-4">
            <h5 className="text-dark p-2">
              {this.state.selectedAssessmentName}
            </h5>
            <div>
              <select
                className="form-control"
                value={this.state.selectedExam}
                onChange={e => {
                  this.setState({ selectedExam: e.target.value }, () => {
                    this.getMarkUploadStatusService(this.state.selectedExam)
                  })
                }}
              >
                {this.props.examList &&
                  this.props.examList.map((item, index) => (
                    <option key={index} value={item.assessmentId}>
                      {item.value}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>

        <div className="row mb-4 mt-2">
          {this.props.classList &&
            this.props.classList.map((item, classIndex) => (
              <div className="col-sm-4 my-2">
                <div className="card rounded">
                  <div className="d-flex outer-box-hierarchy-mark-upload justify-content-between rounded-bottom">
                    <span className="text-white">{item.name}</span>
                    {/* <span
                      className={this.getIndicator(item.classStatus)}
                      style={{ padding: "1px 60px", borderRadius: "15px" }}
                    /> */}
                  </div>
                  <div className="my-2 px-4 d-flex justify-content-around overflow-auto">
                    {item.subjects &&
                      item.subjects.map((subject, subjectIndex) => (
                        <div>
                          <UncontrolledTooltip
                            placement="left"
                            target={`prekg-a-pass${classIndex}${subjectIndex}`}
                          >
                            <div className="row px-4">
                              <div>
                                <img
                                  src={
                                    subject.teacherGender === "M"
                                      ? boyPic
                                      : girlPic
                                  }
                                  alt="Gender"
                                  className="student-pic center mx-auto d-block"
                                  style={{ width: "25px", height: "25px" }}
                                />
                              </div>
                              <div className="px-2">
                                <div>{subject.teacherName}</div>
                                <div>{subject.subjectName}</div>
                              </div>
                            </div>
                          </UncontrolledTooltip>
                          <div
                            className="text-center d-flex flex-column py-2 mr-2 cursor-pointer"
                            id={`prekg-a-pass${classIndex}${subjectIndex}`}
                          >
                            <div className="mb-2">
                              <span
                                className={this.getIndicator(
                                  subject.subjectStatus
                                )}
                                style={{ padding: "5px 14px" }}
                              />
                            </div>
                            <small className="text-secondary">
                              {subject.subjectShortName}
                            </small>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    classList:
      state.markUploadStatusDetailsState.markUploadStatusDetails.classes,
    termList: state.markUploadStatusDetailsState.markUploadStatusDetails.terms,
    examList: state.adminPerformanceReportDetailsState.examList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getMarkUploadStatusDetails,
      getExamListDetails,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(MarkUploadStatusScreen)
