/*************************************************
 * RedInk
 * @exports
 * @class teacherDetailsAction.js
 * @extends Component
 * @author vijayakumar // on 05/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { ACTION_TYPES } from "../types"

export const saveTeacherDetailsInStore = teacherDetails => {
  return {
    type: ACTION_TYPES.TEACHER_DETAIL,
    payload: teacherDetails,
  }
}

export const saveTeacherListInStore = teachersList => {
  return {
    type: ACTION_TYPES.TEACHER_LIST,
    payload: teachersList,
  }
}

export const getTeacherList = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_TEACHERS_LIST, object, true)
      if (response) {
        myLog("====getTeacherList RESPONSE===::::", response)
        dispatch(saveTeacherListInStore(response.teachersList))
        callBack(true, response.teachersList)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getTeacherList ERROR===????", error)
    }
  }
}

export const getTeacherDetails = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_TEACHERS_DETAILS, object, true)
      if (response) {
        myLog("====getTeacherDetails RESPONSE===::::", response)
        dispatch(saveTeacherDetailsInStore(response.teacherDetails))
        callBack(true, response.teacherDetails)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getTeacherDetails ERROR===????", error)
    }
  }
}

export const saveTeacherDetails = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      const response = await Client.post(URL.SAVE_TEACHERS_DETAILS, body, true)
      if (response.responseCode === 0) {
        myLog("====saveteacherDetails RESPONSE===::::", response)
        dispatch(getTeacherDetails(() => {}))
        callBack(true)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====saveteacherDetails ERROR===????", error)
    }
  }
}

export const updateTeacherDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.post(
        URL.UPDATE_TEACHERS_DETAILS,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====updateteacherDetails RESPONSE===::::", response)
        dispatch(getTeacherDetails(() => {}))
        callBack(true)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====updateteacherDetails ERROR===????", error)
    }
  }
}

export const deleteTeacherDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.DELETE_TEACHERS_DETAILS,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteteacherDetails RESPONSE===::::", response)
        dispatch(getTeacherDetails(() => {}))
        callBack(true)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====deleteteacherDetails ERROR===????", error)
    }
  }
}
