/*************************************************
 * RedInk
 * @exports
 * @class ResetPassword.js
 * @extends Component
 * @author Sasidharan // on 07/11/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"

import { logInUser, resetPassword } from "../../actions/LoginAction"
import { showNotification } from "../../actions/CommonAction"
import { URL_PATH } from "../../config/urlPath"
import AuthScreen from "./AuthScreen"

class ResetPassword extends Component {
  constructor(props) {
    super(props)
    document.title = "Reset password | Redink"
    this.state = {
      password: "",
      isLoading: false,
      isValidation: false,
      isPasswordVisible: false,
    }
  }

  /**
   * Handles on submit form
   */
  onClicked = () => {
    if (this.state.password === "") {
      this.setState({ isValidation: true, showLoginFailed: false })
    } else {
      this.setState({ isLoading: true, showLoginFailed: false })
      let token = this.props.history.location.search.split("=")[1]
      const params = {
        password: this.state.password,
        token,
      }
      this.props.resetPassword(params, (success, response) => {
        if (success) {
          window.location.href = "/"
        }
      })
    }
  }
  /**
   * Renders Login design
   */
  render() {
    const { isValidation, password } = this.state
    return (
      <AuthScreen>
        <div className="p-5 d-flex login bg-theme-light text-white w-100 justify-content-center">
          <div className="align-self-center">
            <Link
              to={URL_PATH.LOGIN}
              className="d-block d-sm-none text-center mb-2"
            >
              {/* <img src={logo} alt="" /> */}
            </Link>
            <div>
              <h4 className="text-left">Reset Password</h4>
              <small>
                Enter your new password. After confirming, you will be asked to
                log in again.
              </small>
              <div className="form-group mt-4">
                <label>New Password</label>

                {/* <input
                  type="password"
                  className={`form-control ${
                    isValidation && password === "" ? "is-invalid" : ""
                  }`}
                  value={this.state.password}
                  onChange={event =>
                    this.setState({
                      password: event.target.value,
                      showLoginFailed: false,
                    })
                  }
                  placeholder="Enter new password"
                /> */}
                {this.state.isPasswordVisible ? (
                  <div
                    className="w-100 d-flex align-items-center"
                    style={{ position: "relative" }}
                  >
                    <input
                      type="text"
                      className={`form-control ${
                        isValidation && password === "" ? "is-invalid" : ""
                      }`}
                      value={this.state.password}
                      onChange={event =>
                        this.setState({
                          password: event.target.value,
                          showLoginFailed: false,
                        })
                      }
                      placeholder="Enter new password"
                    />
                    <i
                      className="fas fa-eye-slash cursor-pointer text-dark"
                      style={{
                        position: "absolute",
                        left: "90%",
                      }}
                      aria-hidden="true"
                      onClick={() =>
                        this.setState({ isPasswordVisible: false })
                      }
                    ></i>
                  </div>
                ) : (
                  <div
                    className="w-100 d-flex align-items-center"
                    style={{ position: "relative" }}
                  >
                    <input
                      type="password"
                      className={`form-control ${
                        isValidation && password === "" ? "is-invalid" : ""
                      }`}
                      value={this.state.password}
                      onChange={event =>
                        this.setState({
                          password: event.target.value,
                          showLoginFailed: false,
                        })
                      }
                      placeholder="Enter new password"
                    />
                    <i
                      className="fas fa-eye cursor-pointer text-dark"
                      style={{
                        position: "absolute",
                        left: "90%",
                      }}
                      aria-hidden="true"
                      onClick={() => this.setState({ isPasswordVisible: true })}
                    ></i>
                  </div>
                )}
                <div className="invalid-feedback">
                  Please enter a valid password.
                </div>
              </div>
              <div className="text-right">
                <small>
                  <Link to={URL_PATH.LOGIN}>Take me back to login</Link>
                </small>
              </div>
              <button
                className="login-form-btn btn w-100 mt-3"
                onClick={() => this.onClicked()}
                disabled={this.state.isLoading}
              >
                {this.state.isLoading ? "Reseting..." : "Reset password"}
              </button>
            </div>
          </div>
        </div>
      </AuthScreen>
    )
  }
}

export default connect(null, { logInUser, showNotification, resetPassword })(
  ResetPassword
)
