/*************************************************
 * RedInk
 * @exports
 * @class index.js
 * @extends Component
 * @author Sasidharan // on 01/02/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import AppWrapper from "../Wrapper"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getSuperAdminDetails } from "../../../actions/founder/SuperAdminDetailsAction"
import {
  getBranchList,
  getTeacherDetails,
} from "../../../actions/founder/TeacherDetailsActions"
import {
  getSectionsList,
  getSectionDetails,
} from "../../../actions/founder/StudentDetailsActions"
import Select from "react-select"
import { HashLoader } from "react-spinners"
class FounderStudent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      studentsList: [],
      branchList: null,
      sectionList: null,
      isLoading: false,
      selectedSectionId: null,
      selectedBranchId: null,
      selectedSuperAdminId: null,
    }
  }

  componentDidMount = async () => {
    if (!this.props.superAdminList) await this.props.getSuperAdminDetails()
  }

  loadBranches = tentantId => {
    this.setState({ branchList: null })
    this.props.getBranchList({ tentantId }, (success, response) => {
      success && this.setState({ branchList: response, selectedBranchId: null })
    })
  }

  loadSections = branchId => {
    const { selectedSuperAdminId } = this.state
    this.setState({ sectionList: null })
    this.props.getSectionsList(
      { tentantId: selectedSuperAdminId.value, branchId },
      (success, response) => {
        success &&
          this.setState({ sectionList: response, selectedSectionId: null })
      }
    )
  }

  loadStudents = sectionId => {
    const { selectedSuperAdminId, selectedBranchId } = this.state
    this.setState({ isLoading: true })
    this.props.getSectionDetails(
      {
        tentantId: selectedSuperAdminId.value,
        branchId: selectedBranchId.value,
        sectionId,
      },
      (success, response) => {
        this.setState({ isLoading: false })
        success && this.setState({ studentsList: response })
      }
    )
  }

  render() {
    return (
      <AppWrapper founderDashboard>
        <div className="row">
          <div className="col-md">
            <h3 className="text-dark">Students</h3>
          </div>
          <div className="col d-flex align-items-center justify-content-end">
            <div style={{ flex: 1 }}>
              <Select
                placeholder="Select School"
                className="mx-2"
                value={this.state.selectedSuperAdminId}
                onChange={selectedSuperAdminId =>
                  this.setState({ selectedSuperAdminId }, () =>
                    this.loadBranches(selectedSuperAdminId.value)
                  )
                }
                options={
                  this.props.superAdminList
                    ? this.props.superAdminList.map(
                        ({ tentantFullName, id }) => ({
                          value: id,
                          label: tentantFullName,
                        })
                      )
                    : []
                }
                isDisabled={!this.props.superAdminList}
              />
            </div>
            <div style={{ flex: 1 }}>
              <Select
                placeholder="Select Branch"
                className="mx-2"
                value={this.state.selectedBranchId}
                onChange={selectedBranchId =>
                  this.setState({ selectedBranchId }, () =>
                    this.loadSections(selectedBranchId.value)
                  )
                }
                options={
                  this.state.branchList
                    ? this.state.branchList.map(({ schoolName, branchId }) => ({
                        value: branchId,
                        label: schoolName,
                      }))
                    : []
                }
                isDisabled={!this.state.branchList}
              />
            </div>
            <div style={{ flex: 1 }}>
              <Select
                placeholder="Select Section"
                className="mx-2"
                value={this.state.selectedSectionId}
                onChange={selectedSectionId =>
                  this.setState({ selectedSectionId }, () =>
                    this.loadStudents(selectedSectionId.value)
                  )
                }
                options={
                  this.state.sectionList
                    ? this.state.sectionList.map(
                        ({ className, sectionName, sectionId }) => ({
                          value: sectionId,
                          label: className + " " + sectionName,
                        })
                      )
                    : []
                }
                isDisabled={!this.state.sectionList}
              />
            </div>
          </div>
        </div>

        {this.state.isLoading && (
          <div
            className="d-flex justify-content-center"
            style={{ position: "absolute", left: "50%", top: "50%" }}
          >
            <HashLoader
              sizeUnit={"px"}
              size={35}
              color={"#084353"}
              loading={this.state.isLoading}
            />
          </div>
        )}

        <div className="table_row row mt-4 pt-4">
          <div className="container col-md-10">
            {this.state.selectedSuperAdminId &&
            this.state.selectedBranchId &&
            this.state.selectedSectionId ? (
              !this.state.isLoading && (
                <table className="table table-hover">
                  <thead
                    className="text-white"
                    style={{ backgroundColor: "rgb(7, 62, 77)" }}
                  >
                    <tr>
                      <th scope="col">Redink ID</th>
                      <th scope="col">Roll no</th>
                      <th scope="col">First Name</th>
                      <th scope="col">Last Name</th>
                      <th scope="col">Gender</th>
                      <th scope="col">Date of Birth</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.studentsList.length > 0 ? (
                      this.state.studentsList.map((student, index) => (
                        <tr key={student.studentId}>
                          <td>{student.redinkId}</td>
                          <td>{student.registerRollNumber}</td>
                          <td>{student.studentFirstName}</td>
                          <td>{student.studentLastName}</td>
                          <td>{student.gender}</td>
                          <td>{student.dob}</td>
                          <td>
                            <button
                              className="btn badge badge-primary p-1"
                              onClick={() => {}}
                            >
                              Login
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>No records Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )
            ) : (
              <h4
                className="text-dark text-center mt-2"
                style={{ width: "100%" }}
              >
                Select a school, branch & section
              </h4>
            )}
          </div>
        </div>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    superAdminList: state.founderDetails.superAdminList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getBranchList,
      getTeacherDetails,
      getSuperAdminDetails,
      getSectionsList,
      getSectionDetails,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(FounderStudent)
