/*************************************************
 * RedInk
 * @exports
 * @class MarkScreen.js
 * @extends Component
 * @author Sasidharan // on 15/03/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { HashLoader } from "react-spinners"
import readXlsxFile from "read-excel-file"
import { connect } from "react-redux"
import { Button } from "reactstrap"
import fileSaver from "file-saver"
import Skeleton from "react-loading-skeleton"
import pdfMake from "pdfmake"
import store from "store"

import {
  getXlsheetReport,
  getXlsheetTemplate,
  getMarkSubjectDetails,
  saveMarkStudentListInStore,
  getAssessmentMarkStudentList,
  saveStudentSubjectMarkObject,
  saveOverAllRemarksInAssessment,
  onChangeRemarkInAssessmentMarkScreen,
  saveStudentSubjectMarkUploadLockDetail,
  saveStudentSubjectGenerateAnalyticsDetail,
} from "../../../actions/teacher/MarkScreenAction"
import { showNotification } from "../../../actions/CommonAction"

import MessageModal from "../MessageModal"
import CommentBox from "./CommentBox"
import AppWrapper from "../Wrapper"

const fonts = {
  Roboto: {
    normal:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
    bold:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
    italics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
    bolditalics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
  },
}

class AssessmentMarkScreen extends Component {
  static getDerivedStateFromProps(props, state) {
    return {
      teacherName: props.markSubjectDetails
        ? props.markSubjectDetails.teacherName
        : "",
      date: props.markSubjectDetails ? props.markSubjectDetails.date : "",
      maxMark: props.markSubjectDetails ? props.markSubjectDetails.maxMark : "",
      minMark: props.markSubjectDetails ? props.markSubjectDetails.minMark : "",
      passMark: props.markSubjectDetails
        ? props.markSubjectDetails.passMark
        : "",
      distinctionMark: props.markSubjectDetails
        ? props.markSubjectDetails.distinctionMark
        : "",
      convertMark: props.markSubjectDetails
        ? props.markSubjectDetails.convertMark
        : "",
      allowQuickAnalytics: state.allowQuickAnalytics,
      theoryMark: props.markSubjectDetails
        ? props.markSubjectDetails.theoryMark
        : "",
      theoryConvertMarkTo: props.markSubjectDetails
        ? props.markSubjectDetails.theoryConvertMarkTo
        : "",
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      // selectedEnterAs: "MARK",
      // selectedViewAs: [{ value: "MARK", label: "MARK" }],
      hierarchySubjectDetails: this.props.location.state
        .hierarchySubjectDetails,
      // isShowGrade: false,
      // isShowMark: true,
      // isShowConvertedMark: false,
      // isShowPercentage: false,
      allowQuickAnalytics: false,
      // isShowLoading: false,
      isLocked: false,
      isCompleted: false,
      // isShowGenerateAnalyticsLoading: false,
      isGeneratedAnalytics: false,
      isFromActivity: this.props.location.state.isFromActivity,
      invalidMarkIndex: -1,
      isShowLoading: true,
      isSaving: false,
    }
  }

  componentDidMount() {
    this.props.getAssessmentMarkStudentList(
      this.state.hierarchySubjectDetails,
      (success, response) => {
        if (success) {
          this.setState({
            allowQuickAnalytics:
              response.completed && response.isLocked ? true : false,
            isLocked: response.isLocked ? response.isLocked : false,
            isCompleted: response.completed,
            isGeneratedAnalytics: response.isGenerated,
            columnNames: response.headers,
            isShowLoading: false,
          })
        }
      }
    )
  }

  componentWillUnmount() {
    this.props.saveMarkStudentListInStore([])
  }

  closeMessageModal = () => this.setState({ isMessageModalOpen: false })

  onClickMessageIcon = () => {
    this.setState({ isMessageModalOpen: !this.state.isMessageModalOpen })
  }

  onSubmitMessage = () => {
    this.closeMessageModal()
  }

  handleMarkEntry = (event, index, rowIndex) => {
    let numberRegex = /^[a-zA-Z0-9]+$/
    let studentArray = this.props.markStudentList
    studentArray[index].row[rowIndex] = event.target.value
    this.props.saveMarkStudentListInStore(studentArray)
    if (event.target.value !== "AB" && event.target.value !== "NT") {
      if (
        !numberRegex.test(event.target.value) ||
        !(
          event.target.value <=
          parseInt(
            this.state.isFromActivity
              ? this.state.maxMark
              : this.state.theoryMark
          )
        )
      ) {
        this.setState({ invalidMarkIndex: index })
      } else {
        this.setState({ invalidMarkIndex: -1 })
      }
    } else {
      this.setState({ invalidMarkIndex: -1 })
    }
  }

  downloadXlSheetTemplate = () => {
    const { hierarchySubjectDetails } = this.state
    this.props.getXlsheetTemplate(
      hierarchySubjectDetails.subjectId,
      response => {
        var blob = new Blob([response], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
        fileSaver.saveAs(blob, "studentRemarksTemplate.xlsx")
      }
    )
  }

  convertToRequestArray = rows => {
    delete rows[0]
    if (Array.isArray(rows)) {
      const requestKeys = ["roll_no", "student_name", "mark", "remark"]
      let marksArray = []
      rows.forEach(row => {
        let tempObj = {}
        row.forEach((data, i) => {
          tempObj[requestKeys[i]] = data
        })
        marksArray.push(tempObj)
      })
      let studentArray = this.props.markStudentList
      for (let i = 0; i < marksArray.length; i++) {
        for (let j = 0; j < studentArray.length; j++) {
          let found = marksArray[i].roll_no === studentArray[j].row[0]
          if (found) {
            studentArray[j].row[1] = marksArray[i].student_name
            studentArray[j].row[2] = marksArray[i].mark
            studentArray[j].row[3] = marksArray[i].remark
          }
        }
      }
      this.props.saveMarkStudentListInStore(studentArray)
    }
  }

  handleDrop = async files => {
    readXlsxFile(files[0])
      .then(rows => {
        this.convertToRequestArray(rows)
      })
      .catch(() => {
        this.setState({
          excelError: true,
          excelFileName: "",
          excelTitleData: [],
          uploadedExcelFile: {},
        })
      })
  }

  downloadXlSheetReport = () => {
    const { hierarchySubjectDetails } = this.state
    this.props.getXlsheetReport(hierarchySubjectDetails.subjectId, response => {
      var blob = new Blob([response], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
      fileSaver.saveAs(blob, "studentRemarksReport.xlsx")
    })
  }

  downloadAsPdf = () => {
    const { teacherName, date } = this.state
    // const {
    //   markSubjectDetails: { maxMark },
    // } = this.props
    const {
      subjectName,
      className,
      academicName,
      termName,
      faName,
      theoryOrPracticalName,
      activityName,
    } = this.props.location.state
    const schoolName = store.get("quickInfo").schoolShortName

    let location = [`${academicName}`]
    const addArrow = () =>
      location.push({
        image: "caret-right",
        width: 10,
        height: 10,
        margin: [2, 2, 2, 2],
      })
    if (termName) {
      addArrow()
      location.push(`${termName}`)
    }
    if (faName) {
      addArrow()
      location.push(`${faName}`)
    }
    if (subjectName) {
      addArrow()
      location.push(`${subjectName}`)
    }
    if (theoryOrPracticalName) {
      addArrow()
      location.push(`${theoryOrPracticalName}`)
    }
    if (activityName) {
      addArrow()
      location.push(`${activityName}`)
    }

    const head = this.state.columnNames.map(item => ({
      text: item,
      style: "header_cell",
    }))

    const body = this.props.markStudentList.map(item =>
      item.row.map(rowItem => ({
        text: rowItem,
        style: "cell",
      }))
    )

    const title = `${className} ${subjectName}`

    const docDefinition = {
      content: [
        {
          table: {
            headerRows: 0,
            widths: location.map(item =>
              typeof item === "string" ? "auto" : 10
            ),
            body: [location],
          },
          layout: "noBorders",
        },
        {
          columns: [
            { text: title, style: "header" },
            { text: schoolName, style: "header", alignment: "right" },
          ],
        },
        {
          columns: [
            { text: date || "21 Aug 2020", style: "subheader" },
            {
              text: `No. of Students: ${body.length}`,
              style: "subheader",
              alignment: "right",
            },
          ],
        },
        {
          style: "table",
          table: {
            headerRows: 1,
            widths: head.map((h, i) => (i === 1 ? "*" : "auto")),
            body: [head, ...body],
          },
          layout: {
            hLineWidth: function(i, node) {
              return 1
            },
            vLineWidth: function(i, node) {
              return 1
            },
            hLineColor: function(i, node) {
              return "#bcbcbc"
            },
            vLineColor: function(i, node) {
              return "#bcbcbc"
            },
          },
        },
        {
          table: {
            headerRows: 0,
            widths: ["*"],
            body: [
              [
                {
                  columns: [
                    {
                      text: "Ab - Absent",
                      style: "bottomText",
                      alignment: "left",
                    },
                    [
                      {
                        text: "Signature",
                        style: "bottomText",
                        margin: [0, 8, 0, 28],
                      },
                      {
                        text: teacherName || "Hailey Banner",
                        style: "bottomText",
                      },
                    ],
                  ],
                  style: "cell",
                },
              ],
              [
                {
                  image: "redink-logo",
                  height: 20,
                  width: 70,
                  alignment: "center",
                },
              ],
            ],
          },
          layout: {
            hLineWidth: function(i, node) {
              return i === 0 || i === 2 ? 1 : 0
            },
            vLineWidth: function(i, node) {
              return 1
            },
            hLineColor: function(i, node) {
              return "#bcbcbc"
            },
            vLineColor: function(i, node) {
              return "#bcbcbc"
            },
          },
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 12, 0, 12],
        },
        subheader: {
          fontSize: 12,
          color: "#5e5e5e",
          margin: [0, 0, 0, 10],
        },
        table: {
          fontSize: 10,
          margin: [0, 5, 0, -1],
        },
        header_cell: {
          fontSize: 10,
          margin: [8, 6, 8, 6],
        },
        cell: {
          fontSize: 10,
          margin: [10, 6, 10, 6],
        },
        center: {
          alignment: "center",
        },
        bottomText: {
          alignment: "center",
          margin: [0, 8, 0, 8],
        },
      },
      pageOrientation: "landscape",
      images: {
        "caret-right": require("../../../assets/images/caret-right_base64")
          .default,
        "redink-logo": require("../../../assets/images/redink_logo_base64")
          .default,
      },
    }

    pdfMake.createPdf(docDefinition, null, fonts).getBase64(async data => {
      const blob = await fetch(
        `data:application/pdf;base64,${data}`
      ).then(res => res.blob())
      const browserUrl = window.webkitURL || window.URL
      const pdfURL = browserUrl.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = pdfURL
      a.download = `${title} Exam Marks.pdf`
      a.click()
      browserUrl.revokeObjectURL(pdfURL)
    })
  }

  onSubmit = () => {
    this.setState({ isSaving: true })
    const payload = this.props.markStudentList.map(m => ({
      assessmentId: m.assessmentId,
      academicId: m.academicId,
      studentId: m.studentId,
      branchId: m.branchId,
      remark: m.row[m.row.length - 1],
    }))
    this.props.saveOverAllRemarksInAssessment(payload, success => {
      if (success) {
        this.setState({ isSaving: false })
      }
    })
  }

  render() {
    return (
      <AppWrapper
        openMessageModal={this.onClickMessageIcon}
        showTeacherThree
        showTeacherLogo
        sideMenuCollapsedBackExam
        screenName="Assessment Mark Entry Screen"
      >
        <MessageModal
          isModalOpen={this.state.isMessageModalOpen}
          closeModal={this.onClickMessageIcon}
          onSubmitMessage={this.onSubmitMessage}
        />

        <div className="gr-inner-mark-wrapper d-flex flex-column ml-2 mr-3">
          <div className="row bg-theme-light p-4 rounded text-light">
            <div className="col-sm">
              <div className="row">
                <div className="col-sm">
                  <div className="d-flex row">
                    {this.props.location.state.academicName}
                    <i className="fas fa-caret-right py-1 px-2" />
                    {this.props.location.state.termName}
                    <i className="fas fa-caret-right py-1 px-2" />
                    {this.props.location.state.faName}
                  </div>
                  <div className="d-flex row my-3 align-items-center">
                    <h1 className="font-weight-bold text-white">
                      {this.props.location.state.className}{" "}
                      {this.props.location.state.faName}
                    </h1>

                    {this.state.columnNames && (
                      <Button
                        style={{
                          backgroundColor: "343A40",
                          marginLeft: "auto",
                        }}
                        onClick={this.downloadAsPdf}
                      >
                        Download as Pdf
                      </Button>
                    )}
                  </div>
                  {/* <div>
                    <p className="text-muted">
                      TEACHER NAME:{" "}
                      <span className="text-white">
                        {this.state.teacherName}
                      </span>
                    </p>
                    <p className="text-muted">
                      DATE:{" "}
                      <span className="text-white">{this.state.date}</span>
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          {this.state.isShowLoading ? (
            <div className="mb-4" style={{ flex: 1, overflowY: "scroll" }}>
              <Skeleton count={15} height={30} width="100%" />
            </div>
          ) : (
            <>
              <div
                className="table-responsive mt-4 col-sm-12 p-0"
                style={{ flex: 1, overflowY: "scroll" }}
              >
                <table className="table table-bordered mark-entry-table">
                  <thead>
                    <tr>
                      {this.state.columnNames &&
                        this.state.columnNames.map((item, i) => (
                          <th width={i === 1 ? 150 : 50} scope="col" key={item}>
                            {item}
                          </th>
                        ))}
                    </tr>
                  </thead>

                  <tbody>
                    {this.props.markStudentList &&
                      this.props.markStudentList.map((item, i) => (
                        <tr key={i}>
                          {item.row.map((rowItem, rowI) => (
                            <td key={rowI}>
                              {item.row.length === rowI + 1 ? (
                                <div className="position-relative">
                                  <CommentBox
                                    i={i}
                                    gender={item.gender}
                                    stuName={item.row[1]}
                                    onChange={remark =>
                                      this.props.onChangeRemarkInAssessmentMarkScreen(
                                        {
                                          studentId: item.studentId,
                                          remark,
                                        }
                                      )
                                    }
                                  />

                                  <input
                                    type="text"
                                    className="form-control pl-5"
                                    value={rowItem}
                                    onChange={e =>
                                      this.props.onChangeRemarkInAssessmentMarkScreen(
                                        {
                                          studentId: item.studentId,
                                          remark: e.target.value,
                                        }
                                      )
                                    }
                                  />
                                </div>
                              ) : (
                                <p>{rowItem}</p>
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>

              <div className="d-flex align-items-center justify-content-center py-2">
                <button
                  className="text-white shadow-sm btn"
                  style={{ backgroundColor: "#06B191", color: "#3D3D3D" }}
                  onClick={this.onSubmit}
                  disabled={this.state.isSaving}
                >
                  {this.state.isSaving ? (
                    <HashLoader sizeUnit="px" size={16} color="#fff" loading />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </>
          )}
        </div>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    markSubjectDetails: state.markScreenState.markSubjectDetails,
    markStudentList: state.markScreenState.markStudentList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      saveStudentSubjectGenerateAnalyticsDetail,
      saveStudentSubjectMarkUploadLockDetail,
      onChangeRemarkInAssessmentMarkScreen,
      saveOverAllRemarksInAssessment,
      getAssessmentMarkStudentList,
      saveStudentSubjectMarkObject,
      saveMarkStudentListInStore,
      getMarkSubjectDetails,
      getXlsheetTemplate,
      showNotification,
      getXlsheetReport,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(AssessmentMarkScreen)
