/*************************************************
 * RedInk
 * @exports
 * @class AttendanceReportReducer.js
 * @extends Component
 * @author vijayakumar // on 18/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from "../../actions/types"

const INTIAL_STATE = {
  currentAssignment: {},
  toggleModal: false,
}

export const parentAssignmentReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.STUDENT_CURRENT_ASSIGNMENT:
      return {
        ...state,
        currentAssignment: { ...action.payload },
      }
    case ACTION_TYPES.STUDENT_ASSIGNMENT_TOGGLE_MODAL:
      return {
        ...state,
        toggleModal: action.payload,
      }
    default:
      return state
  }
}
