/*************************************************
 * RedInk
 * @exports
 * @class index.js
 * @extends Component
 * @author Sasidharan // on 15/02/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import AppWrapper from "../Wrapper"
import MessageModal from "../MessageModal"
import moment from "moment"
import Chart from "chart.js"
import { myLog } from "../../../utils/Utility"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getAttendanceDetails } from "../../../actions/superAdmin/AttendanceDetailsAction"

let myGraph = null

class AdminAttendanceReport extends Component {
  static getDerivedStateFromProps(props, state) {
    myLog(props.schoolAttendanceDetail)
    return {
      present: props.schoolAttendanceDetail
        ? props.schoolAttendanceDetail.presentCount
        : "0",
      absent: props.schoolAttendanceDetail
        ? props.schoolAttendanceDetail.absentCount
        : "0",
      unmark: props.schoolAttendanceDetail
        ? props.schoolAttendanceDetail.unmarkedCount
        : "0",
    }
  }

  constructor(props) {
    super(props)
    document.title = "Attendance Report | Redink"
    this.state = {
      isMessageModalOpen: false,
      reportDate: "",
      present: 0,
      absent: 0,
      unmark: 0,
    }
  }

  componentDidMount = () => {
    this.getAttendance(moment(new Date()).format("YYYY-MM-DD"))
  }

  componentDidUpdate = () => {
    // this.showAttendanceReportGraph()
  }

  getAttendance = reportDate => {
    this.setState({ reportDate }, () => {
      this.props.getAttendanceDetails(reportDate, () => {
        // this.showAttendanceReportGraph()
      })
    })
  }

  showAttendanceReportGraph = () => {
    if (myGraph != null) {
      myGraph.destroy()
    }
    myGraph = new Chart(this.attendanceReportGraph, {
      type: "bar",
      data: {
        labels: ["Present", "Absent", "Unmarked"],
        datasets: [
          {
            label: "Count",
            data: [this.state.present, this.state.absent, this.state.unmark],
            backgroundColor: ["#479997", "#FF3059"],
            fill: false,
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                maintainAspectRatio: true,
              },
              display: true,
              gridLines: {
                display: false,
              },
            },
          ],
          xAxes: [
            {
              display: true,
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      },
    })
  }

  /**
   * handles on click message icon in sidebar
   */
  onClickMessageIcon = () => {
    this.setState({ isMessageModalOpen: !this.state.isMessageModalOpen })
  }

  /**
   * handles message submit
   */
  onSubmitMessage = () => {
    this.closeMessageModal()
  }

  render() {
    return (
      <AppWrapper
        openMessageModal={() => this.onClickMessageIcon()}
        showSchoolLogo
        showThree
        superAdminDashboard
        onChangeSchoolName={item => {
          myLog(item)
          // this.getAttendance(moment(new Date()).format("YYYY-MM-DD"));
        }}
      >
        <MessageModal
          isModalOpen={this.state.isMessageModalOpen}
          closeModal={() => this.onClickMessageIcon()}
          onSubmitMessage={() => this.onSubmitMessage()}
        />
        <div className="d-flex justify-content-between">
          <b className="p-2">Today's Attendance</b>
          <div className="d-flex row">
            <div
              className="px-3 mt-1"
              onClick={() => {
                let startdate = ""
                startdate = moment(this.state.reportDate).subtract(1, "days")
                startdate = startdate.format("YYYY-MM-DD")
                myLog(startdate)
                this.getAttendance(startdate)
              }}
            >
              <i className="far fa-angle-left text-black fa-2x mr-1"></i>
            </div>
            <input
              max="9999-12-31"
              type="date"
              className="p-2 rounded align-items-center unstyled"
              value={this.state.reportDate}
              onChange={e => this.setState({ reportDate: e.target.value })}
            />
            <div
              className="px-3 mt-1"
              onClick={() => {
                let startdate = ""
                startdate = moment(this.state.reportDate).add(1, "days")
                startdate = startdate.format("YYYY-MM-DD")
                myLog(startdate)
                this.getAttendance(startdate)
              }}
            >
              <i className="far fa-angle-right text-black fa-2x mr-1"></i>
            </div>
          </div>
        </div>
        <div className="card my-2">
          <div className="d-flex justify-content-center mt-5">
            {/* <div style={{ width: "300px" }}>
              <canvas
                style={{ height: "100px" }}
                ref={attendanceReportGraph =>
                  (this.attendanceReportGraph = attendanceReportGraph)
                }
              />
            </div> */}
            <div className="justify-content-center" style={{ width: "500px" }}>
              <div className="progress ">
                <div
                  className="progress-bar bg-present"
                  role="progressbar"
                  id={`prekg-a-pass1`}
                  style={{
                    width: this.props.schoolAttendanceDetail
                      ? this.props.schoolAttendanceDetail.presentPercentage +
                        "%"
                      : "0",
                  }}
                  aria-valuenow={
                    this.props.schoolAttendanceDetail
                      ? this.props.schoolAttendanceDetail.presentPercentage
                      : "0"
                  }
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {/* {'80%'} */}
                </div>
                <div
                  className="progress-bar bg-absent"
                  role="progressbar"
                  id={`prekg-a-fail2`}
                  style={{
                    width: this.props.schoolAttendanceDetail
                      ? this.props.schoolAttendanceDetail.absentPercentage + "%"
                      : "0",
                  }}
                  aria-valuenow={
                    this.props.schoolAttendanceDetail
                      ? this.props.schoolAttendanceDetail.absentPercentage
                      : "0"
                  }
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {/* {'20%'} */}
                </div>
                <div
                  className="progress-bar"
                  role="progressbar"
                  id={`prekg-a-fail3`}
                  style={{
                    width: this.props.schoolAttendanceDetail
                      ? this.props.schoolAttendanceDetail.unmarkPercentage + "%"
                      : "0",
                    backgroundColor: "gray",
                  }}
                  aria-valuenow={
                    this.props.schoolAttendanceDetail
                      ? this.props.schoolAttendanceDetail.unmarkPercentage
                      : "0"
                  }
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {/* {'20%'} */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <div className="card border-0 shadow-none p-5">
                <b className="border-bottom-absent text-center">
                  {this.props.schoolAttendanceDetail
                    ? this.props.schoolAttendanceDetail.absentCount
                    : "0"}
                </b>
                <div className="mt-2 text-center">
                  <span className="attendance-absent mr-2">A</span>
                  <small>Absent</small>
                </div>
              </div>
            </div>
            <div className="col-sm">
              <div className="card border-0 shadow-none p-5">
                <b className="border-bottom-present text-center">
                  {this.props.schoolAttendanceDetail
                    ? this.props.schoolAttendanceDetail.presentCount
                    : "0"}
                </b>
                <div className="mt-2 text-center">
                  <span className="attendance-present mr-2">P</span>
                  <small>Present</small>
                </div>
              </div>
            </div>
            <div className="col-sm">
              <div className="card border-0 shadow-none p-5">
                <b className="border-bottom-unmarked text-center">
                  {this.props.schoolAttendanceDetail
                    ? this.props.schoolAttendanceDetail.unmarkedCount
                    : "0"}
                </b>
                <div className="mt-2 text-center">
                  <span className="attendance-unmarked mr-2">X</span>
                  <small>Unmarked</small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {this.props.schoolList &&
            this.props.schoolList.map(item => {
              return (
                <div className="col-sm-4 my-2">
                  <div className={`card mx-2 p-2 border-left-color-report`}>
                    <span>{item.name}</span>
                    <div className="my-2 d-flex justify-content-around">
                      <div className="text-center d-flex flex-column">
                        <div className="mb-2">
                          <span className="attendance-absent">A</span>
                        </div>
                        <b>
                          {item.absentPercentageStatus === "increased" ? (
                            <i className="far fa-arrow-up text-success fa-1x mr-1"></i>
                          ) : (
                            <i className="far fa-arrow-down text-danger fa-1x mr-1"></i>
                          )}
                          {item.absentCount}
                        </b>
                        <small className="text-secondary">
                          {item.absentPercentage}%
                        </small>
                      </div>
                      <div className="text-center d-flex flex-column">
                        <div className="mb-2">
                          <span className="attendance-present">P</span>
                        </div>
                        <b>
                          {item.presentPercentageStatus === "increased" ? (
                            <i className="far fa-arrow-up text-success fa-1x mr-1"></i>
                          ) : (
                            <i className="far fa-arrow-down text-danger fa-1x mr-1"></i>
                          )}
                          {item.presentCount}
                        </b>
                        <small className="text-secondary">
                          {item.presentPercentage}%
                        </small>
                      </div>
                      <div className="text-center d-flex flex-column">
                        <div className="mb-2">
                          <span className="attendance-unmarked">X</span>
                        </div>
                        <b>
                          {item.unmarkedPercentageStastus === "increased" ? (
                            <i className="far fa-arrow-up text-success fa-1x mr-1"></i>
                          ) : (
                            <i className="far fa-arrow-down text-danger fa-1x mr-1"></i>
                          )}
                          {item.unmarkedCount}
                        </b>
                        <small className="text-secondary">
                          {item.unmarkedPercentage}%
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    schoolList: state.attendanceDetailsState.attendanceDetails.schools,
    schoolAttendanceDetail:
      state.attendanceDetailsState.attendanceDetails.schoolAttendanceDetail,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAttendanceDetails,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(AdminAttendanceReport)
