import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { showNotification } from "../CommonAction"
import {
  SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"

import { ACTION_TYPES } from "../types"
export const saveAdminSchoolDetailsInStore = adminSchoolDetail => {
  return {
    type: ACTION_TYPES.ADMIN_DASBOARD_SCHOOL_DETAIL,
    payload: adminSchoolDetail,
  }
}
export const saveTask = (body, callBack) => {
  myLog("Task ManageMent =>", body)
  return async dispatch => {
    try {
      myLog("haha", body)
      const response = await Client.post(URL.SAVE_TASK, body, true)
      if (response.responseCode === 0) {
        myLog("====saveTask RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "something is wrong"
        )
      )
      callBack(false)
      myLog("====saveTask ERROR===????", error)
    }
  }
}

export const deleteTask = (body, callBack) => {
  myLog("Delete =>", body)
  return async dispatch => {
    // try {
    //   const response = await Client.delete(URL.DELETE_TASK, body, true)
    //   if (response.responseCode === 0) {
    //     myLog("====deleteTask RESPONSE===::::", response)
    //     dispatch(showNotification(
    //       SUCCESS_TOAST,
    //       VALIDATION_MSG.SUCCESS,
    //       response.responseMessage
    //     ))
    //     callBack(true)
    //   } else {
    //     dispatch(showNotification(
    //       DANGER_TOAST,
    //       VALIDATION_MSG.OOPS,
    //       response.responseMessage
    //     ))
    //     callBack(false)
    //   }
    // } catch (error) {
    //   dispatch(showNotification(
    //     DANGER_TOAST,
    //     VALIDATION_MSG.OOPS,
    //     error
    //   ))
    //   callBack(false)
    //   myLog("====deleteTask ERROR===????", error)
    // }
  }
}

export const getTask = callBack => {
  myLog("haha")
  const response = [
    {
      isStarred: 1,
      sentNames: ["Vijay"],
      messageSubject: "Task Management",
      messageContent: "Task Management",
      sentOn: "22/02/2020",
    },
    {
      isStarred: 1,
      sentNames: ["Vijay"],
      messageSubject: "Task Management",
      messageContent: "Task Management",
      sentOn: "22/02/2020",
    },
    {
      isStarred: 1,
      sentNames: ["Vijay"],
      messageSubject: "Task Management",
      messageContent: "Task Management",
      sentOn: "22/02/2020",
    },
    {
      isStarred: 1,
      sentNames: ["Vijay"],
      messageSubject: "Task Management",
      messageContent: "Task Management",
      sentOn: "22/02/2020",
    },
    {
      isStarred: 1,
      sentNames: ["Vijay"],
      messageSubject: "Task Management",
      messageContent: "Task Management",
      sentOn: "22/02/2020",
    },
    {
      isStarred: 1,
      sentNames: ["Vijay"],
      messageSubject: "Task Management",
      messageContent: "Task Management",
      sentOn: "22/02/2020",
    },
    {
      isStarred: 1,
      sentNames: ["Vijay"],
      messageSubject: "Task Management",
      messageContent: "Task Management",
      sentOn: "22/02/2020",
    },
    {
      isStarred: 1,
      sentNames: ["Vijay"],
      messageSubject: "Task Management",
      messageContent: "Task Management",
      sentOn: "22/02/2020",
    },
  ]
  return async dispatch => {
    callBack(true, response)
    //   try {
    //     const response = await Client.get(URL.GET_TASK, null, true)
    //     if (response) {
    //       myLog("====getTask RESPONSE===::::", response)
    //       callBack(true, response)
    //     } else {
    //       callBack(false)
    //     }
    //   } catch (error) {
    //     callBack(false)
    //     myLog("====getTask ERROR===????", error)
    //   }
  }
}
