/*************************************************
 * RedInk
 * @exports
 * @class AttendanceReportReducer.js
 * @extends Component
 * @author vijayakumar // on 18/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from "../../actions/types"

const INTIAL_STATE = {
  subjectsList: [],
  subjectGroupsList: [],
}

export const teacherSubjectDetailsReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.TEACHER_GET_SUBJECT_LIST:
      return {
        ...state,
        subjectsList: action.payload,
      }
    case ACTION_TYPES.TEACHER_GET_SUBJECT_GROUPS:
      return {
        ...state,
        subjectGroupsList: action.payload,
      }

    default:
      return state
  }
}
