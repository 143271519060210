/*************************************************
 * RedInk
 * @exports
 * @class LoginReducer.js
 * @extends Component
 * @author Sasidharan // on 18/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from "../actions/types"
import { myLog } from "../utils/Utility"

const INTIAL_STATE = {
  loginState: {},
  userInfo: {},
  isShowLoading: false,
  QuickInfoDetails: {},
}

export const loginReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOGIN_USER:
      return {
        ...state,
        loginState: action.payload,
      }
    case ACTION_TYPES.USER_INFO:
      myLog("payload", action.payload)
      return {
        ...state,
        userInfo: action.payload,
      }
    case ACTION_TYPES.IS_SHOW_LOADING:
      myLog("payload", action.payload)
      return {
        ...state,
        isShowLoading: action.payload,
      }
    case ACTION_TYPES.QUICK_INFO_DETAIL:
      myLog("payload", action.payload)
      return {
        ...state,
        QuickInfoDetails: { ...action.payload },
      }

    default:
      return state
  }
}
