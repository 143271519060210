/*************************************************
 * RedInk
 * @exports
 * @class OnboardingActions.js
 * @extends Component
 * @author patheticGeek // on 28/11/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import { myLog } from "../../utils/Utility"
// import { ACTION_TYPES } from "../types"
import Client from "../../utils/axiosInstance"
import URL from "../../config"

export const setOnBoardSteps = (currentStep, callback) => {
  return async dispatch => {
    let data = {
      currentStep,
    }
    if (currentStep === 4) data.firstLogin = false
    else data.firstLogin = true
    try {
      myLog("====setOnBoardSteps data===::::", data)
      const response = await Client.post(URL.SET_ONBOARD_STEPS, data, true)
      if (response) {
        myLog("====setOnBoardSteps RESPONSE===::::", response)
        if (callback) callback(true)
      } else {
        if (callback) callback(false)
      }
    } catch (error) {
      if (callback) callback(false)
      myLog("====setOnBoardSteps ERROR===????", error)
    }
  }
}
