/*************************************************
 * RedInk
 * @exports
 * @class DashboardAction.js
 * @extends Component
 * @author vijayakumar // on 05/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { ACTION_TYPES } from "../types"

export const saveAdminSchoolDetailsInStore = adminSchoolDetail => {
  return {
    type: ACTION_TYPES.ADMIN_DASBOARD_SCHOOL_DETAIL,
    payload: adminSchoolDetail,
  }
}

export const getAdminSchoolInfoDetails = (body, callBack) => {
  return async dispatch => {
    try {
      // dispatch(saveStudentAttendanceReportListInStore([]));
      // let response = {
      //   schoolDetails: {
      //     students: 45,
      //     boys: 25,
      //     girls: 20,
      //     teachers: 7,
      //     male: 2,
      //     female: 5,
      //   }
      // }
      const response = await Client.get(
        URL.GET_DASHBOARD_ADMIN_SCHOOL_INFO,
        body,
        true
      )
      if (response) {
        myLog("====getAdminSchoolInfoDetails RESPONSE===::::", response)
        dispatch(saveAdminSchoolDetailsInStore(response.memberCounts))
        callBack(true, response.memberCounts)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getAdminSchoolInfoDetails ERROR===????", error)
    }
  }
}

export const getAdminDashboardStudentAttendanceReportList = (
  body,
  callBack
) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_DASHBOARD_ADMIN_ATTENDANCE_REPORT,
        body,
        true
      )
      // let response = {
      //     classAttendanceDetail: {
      //       absentCount: "40",
      //       presentCount: "100",
      //       unmarkedCount: "5",
      //       absentPercentage: "40",
      //       presentPercentage: "100",
      //       unmarkPercentage: "5",
      //     },
      // }
      if (response) {
        myLog(
          "====getAdminDashboardStudentAttendanceReportList RESPONSE===::::",
          response
        )
        callBack(true, response.todaysSchoolAttendance)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog(
        "====getAdminDashboardStudentAttendanceReportList ERROR===????",
        error
      )
    }
  }
}

export const getAdminDashboardTopPerformingStudentsDetails = (
  body,
  callBack
) => {
  return async dispatch => {
    try {
      // let response = {
      //   assessment: "FA1",
      //   topPerformers: [
      //     {
      //       studentName: 'Jessie packer',
      //       className: '8-A',
      //       studentAssessmentMark: '485',
      //       totalAssessmentMark: '500',
      //       percentage: '72',
      //     }
      //   ],
      // }
      const response = await Client.get(
        URL.GET_DASHBOARD_ADMIN_SCHOOL_TOP_PERFORMERING_STUDENTS,
        body,
        true
      )
      if (response) {
        myLog(
          "====getAdminDashboardTopPerformingStudentsDetails RESPONSE===::::",
          response
        )
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog(
        "====getAdminDashboardTopPerformingStudentsDetails ERROR===????",
        error
      )
    }
  }
}

export const getAdminDashboardSchoolPerformanceByClassDetails = (
  body,
  callBack
) => {
  return async dispatch => {
    try {
      // let response = {
      //       labels: [
      //         "PRE PRIMARY AND PRIMARY",
      //         "MIDDLE",
      //         "SECONDARY AND HIGHER SECONDARY",
      //       ],
      //       data: [5, 2, 8],
      //       backgroundColor: ["#ea5a58", "#13af8f", "#64c2e9"],
      //     }
      const response = await Client.get(
        URL.GET_DASHBOARD_ADMIN_SCHOOL_PERFORMERS,
        body,
        true
      )
      if (response) {
        myLog(
          "====getAdminDashboardSchoolPerformanceByClassDetails RESPONSE===::::",
          response
        )
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog(
        "====getAdminDashboardSchoolPerformanceByClassDetails ERROR===????",
        error
      )
    }
  }
}

export const getAdminDashboardDailyAttendanceReportDetails = (
  body,
  callBack
) => {
  return async dispatch => {
    try {
      // let response =  {
      //       labels: ["12 Mar", "13 Mar", "14 Mar", "15 Mar", "16 Mar", "17 Mar"],
      //       data: [2, 4, 6, 7, 3, 5],
      //     }
      const response = await Client.get(
        URL.GET_DASHBOARD_ADMIN_SCHOOL_ATTENDANCE_TIMELINE,
        body,
        true
      )
      if (response) {
        myLog(
          "====getAdminDashboardDailyAttendanceReportDetails RESPONSE===::::",
          response
        )
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog(
        "====getAdminDashboardDailyAttendanceReportDetails ERROR===????",
        error
      )
    }
  }
}

export const getAdminDashboardTopPerformingClassesDetails = (
  body,
  callBack
) => {
  return async dispatch => {
    try {
      // let response = {
      //   assessment: "FA1",
      //   topPerformers: [
      //     {
      //       teacherName: 'Otto',
      //       className: '8-A',
      //       percentage: '72',
      //     }
      //   ],
      // }
      const response = await Client.get(
        URL.GET_DASHBOARD_ADMIN_SCHOOL_TOP_PERFORMERING_CLASSES,
        body,
        true
      )
      if (response) {
        myLog(
          "====getAdminDashboardTopPerformingClassesDetails RESPONSE===::::",
          response
        )
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog(
        "====getAdminDashboardTopPerformingClassesDetails ERROR===????",
        error
      )
    }
  }
}

export const getAdminDashboardTopPerformingTeachersDetails = (
  body,
  callBack
) => {
  return async dispatch => {
    try {
      // let response = {
      //   assessment: "FA1",
      //   topPerformers: [
      //     {
      //       teacherName: 'Otto',
      //       className: '8-A',
      //       subject: 'English',
      //       percentage: '72',
      //     }
      //   ],
      // }
      const response = await Client.get(
        URL.GET_DASHBOARD_ADMIN_SCHOOL_TOP_PERFORMERING_TEACHERS,
        body,
        true
      )
      if (response) {
        myLog(
          "====getAdminDashboardTopPerformingTeachersDetails RESPONSE===::::",
          response
        )
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog(
        "====getAdminDashboardTopPerformingTeachersDetails ERROR===????",
        error
      )
    }
  }
}

export const getSchoolDetailsAdmin = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_SCHOOL_DEATILS_ADMIN,
        null,
        true
      )
      if (response) {
        myLog("====getSchoolDetailsAdmin RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getSchoolDetailsAdmin ERROR===????", error)
    }
  }
}
