import URL from "../../config"
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import { showNotification } from "../CommonAction"
import {
  SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"

export const getStudentProfileBasicInfo = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_STUDENT_PROFILE_BASIC_INFO,
        object,
        true,
        true
      )
      if (response) {
        myLog("====getStudentProfileBasicInfo RESPONSE===::::", response)
        callBack(true, response.data)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getStudentProfileBasicInfo ERROR===????", error)
    }
  }
}

export const getStudentProfileExamInfo = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_STUDENT_PROFILE_EXAM_INFO,
        object,
        true,
        true
      )
      if (response) {
        myLog("====getStudentProfileExamInfo RESPONSE===::::", response)
        callBack(true, response.data)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getStudentProfileExamInfo ERROR===????", error)
    }
  }
}

export const getStudentLast5Score = (object = {}, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_STUDENT_PROFILE_ASSESSMENTS,
        object,
        true,
        true
      )
      if (response) {
        myLog("====getStudentLast5Score RESPONSE===::::", response)
        callBack(true, response.data)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getStudentLast5Score ERROR===????", error)
    }
  }
}

export const getStudentProfileAttendanceInfo = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_STUDENT_PROFILE_ATTENDANCE_INFO,
        object,
        true,
        true
      )
      if (response) {
        myLog("====getStudentProfileAttendanceInfo RESPONSE===::::", response)
        callBack(true, response.data)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getStudentProfileAttendanceInfo ERROR===????", error)
    }
  }
}

export const getStudentProfileAssignmentInfo = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_STUDENT_PROFILE_ASSIGNMENT_INFO,
        object,
        true,
        true
      )
      if (response) {
        myLog("====getStudentProfileAssignmentInfo RESPONSE===::::", response)
        callBack(true, response.data)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getStudentProfileAssignmentInfo ERROR===????", error)
    }
  }
}

export const getStudentProfileSubjectsList = (body, callback) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.TEACHER_GET_SUBJECT_LIST,
        {},
        true,
        true
      )
      if (response) {
        myLog("====getStudentProfileSubjectsList RESPONSE===::::", response)
        const { data } = response
        callback(true, data)
      } else {
        callback(false)
      }
    } catch (error) {
      callback(false)
      myLog("====getStudentProfileSubjectsList ERROR===????", error)
    }
  }
}

export const getSubjectsListForStudent = (body, callback) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.STUDENT_GET_SUBJECT_LIST,
        {},
        true,
        true
      )
      if (response) {
        myLog("====getSubjectsListForStudent RESPONSE===::::", response)
        const { data } = response
        callback(true, data)
      } else {
        callback(false)
      }
    } catch (error) {
      callback(false)
      myLog("====getSubjectsListForStudent ERROR===????", error)
    }
  }
}

export const updateStudentProfileAciveInfo = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.UPDATE_STUDENT_PROFILE_ACTIVE_INFO,
        object,
        true,
        true
      )

      if (response.responseCode === 0) {
        myLog("====updateStudentProfileAciveInfo RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true, response.responseMessage)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====updateStudentProfileAciveInfo ERROR===????", error)
    }
  }
}

export const getStudentProfileExtendInfo = (body, callback) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_STUDENT_PROFILE_EXTEND_INFO,
        body,
        true,
        true
      )
      if (response) {
        myLog("====getStudentProfileExtendInfo RESPONSE===::::", response)
        const { data } = response
        callback(true, data)
      } else {
        callback(false)
      }
    } catch (error) {
      callback(false)
      myLog("====getStudentProfileExtendInfo ERROR===????", error)
    }
  }
}

export const updateStudentProfileExtendInfo = (body, callback) => {
  return async dispatch => {
    try {
      const response = await Client.put(
        URL.UPDATE_STUDENT_PROFILE_EXTEND_INFO,
        body,
        true,
        true
      )
      myLog("====updateStudentProfileExtendInfo RESPONSE===::::", response)
      if (response.responseCode === 0) {
        myLog("====updateStudentProfileExtendInfo RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        const { data } = response
        callback(true, data)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callback(false)
      }
    } catch (error) {
      callback(false)
      myLog("====updateStudentProfileExtendInfo ERROR===????", error)
    }
  }
}

export const updateParentExtendInfo = (body, callback) => {
  return async dispatch => {
    try {
      const response = await Client.put(
        URL.UPDATE_PARENT_EXTEND_INFO,
        body,
        true,
        true
      )
      myLog("====updateParentExtendInfo RESPONSE===::::", response)
      if (response.responseCode === 0) {
        myLog("====updateStudentProfileExtendInfo RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        const { data } = response
        callback(true, data)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callback(false)
      }
    } catch (error) {
      callback(false)
      myLog("====updateParentExtendInfo ERROR===????", error)
    }
  }
}

export const getStudentBehaviourIno = (studentId, callback) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.STUDENT_BEHAVIOUR + "/" + studentId,
        null,
        true,
        true
      )

      if (response) {
        myLog("====getStudentBehaviourIno RESPONSE===::::", response)
        const { data } = response
        callback(true, data)
      } else {
        callback(false)
      }
    } catch (error) {
      callback(false)
      myLog("====getStudentBehaviourIno ERROR===????", error)
    }
  }
}
