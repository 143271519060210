/*************************************************
 * RedInk
 * @exports
 * @class AttendanceDetailsAction.js
 * @extends Component
 * @author vijayakumar // on 05/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import { myLog } from "../../utils/Utility"
import { ACTION_TYPES } from "../types"
import Client from "../../utils/axiosInstance"
import URL from "../../config"

export const saveSchoolPerformanceReportDetailsInStore = schoolPerformanceReportDetails => {
  return {
    type: ACTION_TYPES.ADMIN_SCHOOL_PERFORMANCE_REPORT_DETAILS,
    payload: schoolPerformanceReportDetails,
  }
}

export const saveDisplayAdminStudentPerformanceReportDetailsInStore = displayAdminStudentPerformanceReportDetails => {
  return {
    type: ACTION_TYPES.ADMIN_DISPLAY_STUDENT_PERFORMANCE_REPORT_DETAILS,
    payload: displayAdminStudentPerformanceReportDetails,
  }
}

export const saveSchoolPerformanceReportPassFailDetailsInStore = schoolPerformanceReportDetails => {
  return {
    type: ACTION_TYPES.ADMIN_SCHOOL_PERFORMANCE_PASS_FAIL_REPORT_DETAILS,
    payload: schoolPerformanceReportDetails,
  }
}

export const saveClassPerformanceReportDetailsInStore = classPerformanceReportDetails => {
  return {
    type: ACTION_TYPES.ADMIN_CLASS_PERFORMANCE_REPORT_DETAILS,
    payload: classPerformanceReportDetails,
  }
}

export const saveSubjectPerformanceReportDetailsInStore = subjectPerformanceReportDetails => {
  return {
    type: ACTION_TYPES.ADMIN_SUBJECT_PERFORMANCE_REPORT_DETAILS,
    payload: subjectPerformanceReportDetails,
  }
}

export const saveStudentPerformanceReportDetailsInStore = studentPerformanceReportDetails => {
  return {
    type: ACTION_TYPES.ADMIN_STUDENT_PERFORMANCE_REPORT_DETAILS,
    payload: studentPerformanceReportDetails,
  }
}

export const saveExamListDetailsInStore = examList => {
  return {
    type: ACTION_TYPES.ADMIN_EXAM_LIST_DETAILS,
    payload: examList,
  }
}

export const getExamListDetails = (object, callBack) => {
  return async dispatch => {
    try {
      myLog("====getExamListDetails===::::")
      const response = await Client.get(
        URL.GET_ADMIN_ASSESSMENT_DETAILS,
        object,
        true
      )
      if (response) {
        myLog("====getExamListDetails RESPONSE===::::", response)
        const sorted = [...response.assessments]

        if (object.sortBy) {
          sorted.sort((a, b) =>
            Number(a[object.sortBy]) < Number(b[object.sortBy]) ? 1 : -1
          )
        }

        dispatch(saveExamListDetailsInStore(sorted))
        callBack(true, sorted)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getExamListDetails ERROR===????", error)
    }
  }
}

export const getSchoolPerformanceReportDetails = (object, callBack) => {
  return async dispatch => {
    try {
      myLog("====getSchoolPerformanceReportDetails===::::")
      const response = await Client.get(
        URL.GET_SCHOOL_OVERALL_PERFORMANCE_REPORT,
        object,
        true
      )
      if (response) {
        myLog("====getSchoolPerformanceReportDetails RESPONSE===::::", response)
        dispatch(
          saveSchoolPerformanceReportDetailsInStore(
            response.schoolPerformanceReportDetails
          )
        )
        callBack(true, response.schoolPerformanceReportDetails)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getSchoolPerformanceReportDetails ERROR===????", error)
    }
  }
}

export const getSchoolPerformancePassFailReportDetails = (object, callBack) => {
  return async dispatch => {
    try {
      myLog("====getSchoolPerformancePassFailReportDetails===::::")
      const response = await Client.get(
        URL.GET_SCHOOL_OVERALL_PERFORMANCE_PASS_FAIL_REPORT,
        object,
        true
      )
      if (response) {
        myLog(
          "====getSchoolPerformancePassFailReportDetails RESPONSE===::::",
          response
        )
        dispatch(
          saveSchoolPerformanceReportPassFailDetailsInStore(
            response.schoolOverallPassFailDetails
          )
        )
        callBack(true, response.schoolOverallPassFailDetails)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getSchoolPerformancePassFailReportDetails ERROR===????", error)
    }
  }
}

export const getClassPerformanceReportDetails = (object, callBack) => {
  return async dispatch => {
    try {
      myLog("====getClassPerformanceReportDetails===::::")
      let response = {
        classPerformanceReportDetails: {
          classes: [
            "CLASS 1",
            "CLASS 2",
            "CLASS 3",
            "CLASS 4",
            "CLASS 5",
            "CLASS 6",
            "CLASS 7",
            "CLASS 8",
            "CLASS 9",
            "CLASS 10",
          ],
          schools: [
            {
              label: "SCHOOL 1",
              data: [7, 4, 3, 6, 5, 8, 3, 3, 2, 3],
              backgroundColor: "#3b74bd",
              borderWidth: 0,
            },
            {
              label: "SCHOOL 2",
              data: [1, 3, 4, 5, 7, 8, 9, 0, 4, 3],
              borderWidth: 0,
              backgroundColor: "#18dfa7",
            },
            {
              label: "SCHOOL 3",
              borderWidth: 0,
              data: [1, 4, 8, 9, 4, 3, 5, 8, 9, 0],
              backgroundColor: "#1cdfff",
            },
            {
              label: "SCHOOL 4",
              borderWidth: 0,
              data: [3, 4, 8, 1, 8, 5, 3, 7, 4, 3],
              backgroundColor: "#e85252",
            },
            {
              label: "SCHOOL 5",
              borderWidth: 0,
              data: [7, 2, 3, 4, 6, 5, 3, 4, 2, 3],
              backgroundColor: "#f7c70e",
            },
          ],
        },
      }
      myLog("====getClassPerformanceReportDetails===::::", response)
      dispatch(
        saveClassPerformanceReportDetailsInStore(
          response.classPerformanceReportDetails
        )
      )
      callBack(true, response.classPerformanceReportDetails)
      //   const response = await Client.get(URL.GET_ATTENDANCE_DETAILS, date, true)
      //   if (response) {
      //     myLog("====getClassPerformanceReportDetails RESPONSE===::::", response)
      //     dispatch(savePerformanceReportDetailsInStore(response.classPerformanceReportDetails));
      //     callBack(true, response.classPerformanceReportDetails)
      //   } else {
      //     callBack(false)
      //   }
    } catch (error) {
      callBack(false)
      myLog("====getClassPerformanceReportDetails ERROR===????", error)
    }
  }
}

export const getSubjectPerformanceReportDetails = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_SCHOOL_OVERALL_PERFORMANCE_SUBJECT_REPORT,
        object,
        true
      )
      if (response) {
        myLog(
          "====getSubjectPerformanceReportDetails RESPONSE===::::",
          response
        )
        dispatch(
          saveSubjectPerformanceReportDetailsInStore(
            response.subjectPerformanceReportDetails
          )
        )
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getSubjectPerformanceReportDetails ERROR===????", error)
    }
  }
}

export const getStudentPerformanceReportDetails = (object, callBack) => {
  return async dispatch => {
    try {
      myLog("====getStudentPerformanceReportDetails===::::")
      const response = await Client.get(
        URL.GET_ADMIN_STUDENT_PERFORMANCE_TABLE_DETAILS,
        object,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog(
          "====getStudentPerformanceReportDetails RESPONSE===::::",
          response
        )
        dispatch(
          saveStudentPerformanceReportDetailsInStore(response.studentRankList)
        )
        dispatch(
          saveDisplayAdminStudentPerformanceReportDetailsInStore(
            response.studentRankList
          )
        )
        callBack(true, response)
      } else {
        dispatch(saveStudentPerformanceReportDetailsInStore([]))
        dispatch(saveDisplayAdminStudentPerformanceReportDetailsInStore([]))
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getStudentPerformanceReportDetails ERROR===????", error)
    }
  }
}
