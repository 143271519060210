/*************************************************
 * RedInk
 * @exports
 * @class subjectDetailsReducer.js
 * @extends Component
 * @author Vijayakumar // on 18/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from "../../actions/types"
import { myLog } from "../../utils/Utility"

const INTIAL_STATE = {
  SchoolsListDetails: [],
  displaySchoolNameDetails: {},
  SchoolsDetails: {
    schoolLogo: "",
    admins: [{ adminName: "", adminEmail: "", adminContact: "" }],
    schoolName: "",
    schoolShortName: "",
    schoolEmail: "",
    affiliation: "",
    contactNumber: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "India",
    zipCode: "",
  },
}

export const schoolDetailsReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.SUPER_ADMIN_SCHOOL_LIST_DETAILS:
      myLog("SUPER_ADMIN_SCHOOL_LIST_DETAILS", action.payload)
      return {
        ...state,
        SchoolsListDetails: [...action.payload],
      }
    case ACTION_TYPES.SUPER_ADMIN_SCHOOL_DETAILS:
      myLog("SUPER_ADMIN_SCHOOL_DETAILS", action.payload)
      return {
        ...state,
        SchoolsDetails: { ...action.payload },
      }

    case ACTION_TYPES.SUPER_ADMIN_DISPLAY_SCHOOL_NAME_DETAILS:
      myLog("SUPER_ADMIN_DISPLAY_SCHOOL_NAME_DETAILS", action.payload)
      return {
        ...state,
        displaySchoolNameDetails: { ...action.payload },
      }

    default:
      return state
  }
}
