import React, { useEffect, useRef, useState } from "react"
import FullCalendar from "@fullcalendar/react"
import timeGridPlugin from "@fullcalendar/timegrid"
import { useDispatch } from "react-redux"
import moment from "moment"

import { getStudentSchedule } from "../../../../actions/parent/ScheduleAction"

import Header from "./Header"
import "./cal.css"

const eventTimeFormat = {
  hour12: true,
  hour: "2-digit",
  minute: "2-digit",
}

const slotLabelFormat = {
  hour: "numeric",
  meridiem: "long",
  omitCommas: true,
}

const isActiveCheck = (start, end) => {
  let now = Date.now()
  let startTime = new Date(start).getTime()
  let endTime = new Date(end).getTime()

  return {
    isActive: now >= startTime && now <= endTime,
    isSmall: endTime - startTime <= 900000,
  }
}

function Calendar({
  events,
  updateEvents,
  getSingleScheduleDetails,
  forUpdatingEnts,
}) {
  const [isTodayVisible, setIsTodayVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [first, setFirst] = useState(true)
  const [title, setTitle] = useState("")
  const dispatch = useDispatch()
  const calRef = useRef()

  useEffect(() => {
    changeView("timeGridThreeDay", true)
    let startTime = moment().subtract(1, "days")

    let endTime = moment()
      .utc(moment())
      .add(2, "days")

    setFirst(false)
    fetchEvent(startTime, endTime)

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!first) {
      fetchEtxtra()
    }

    // eslint-disable-next-line
  }, [forUpdatingEnts])

  const updateTitle = () => setTitle(calRef.current.getApi().view.title)

  const fetchEvent = (start, end) => {
    setIsLoading(true)

    let startTime = moment
      .utc(start)
      .add(1, "days")
      .startOf("day")
      .format("YYYY-MM-DD HH:mm:ss")
    let endTime = moment
      .utc(end)
      .endOf("day")
      .format("YYYY-MM-DD HH:mm:ss")

    dispatch(
      getStudentSchedule({ startTime, endTime }, (success, response) => {
        if (success) {
          let payload = response.data.map(res => ({
            ...res,
            start: moment(res.startTime).format("YYYY-MM-DD HH:mm:ss"),
            end: moment(res.startTime)
              .add(res.duration, "minutes")
              .format("YYYY-MM-DD HH:mm:ss"),
            // title: res.subject.subjectName + " - " + "" + res.title,
          }))
          updateEvents(payload)
          setIsLoading(false)
        }
      })
    )
  }

  const fetchEtxtra = () => {
    updateTitle()
    let { activeStart, currentEnd } = calRef.current.getApi().view
    setIsTodayVisible(!moment(new Date()).isBetween(activeStart, currentEnd))
    fetchEvent(activeStart, currentEnd)
  }

  const next = () => {
    calRef.current.getApi().next()
    fetchEtxtra()
  }

  const prev = () => {
    calRef.current.getApi().prev()
    fetchEtxtra()
  }

  const changeView = (val, isFromUseEffect) => {
    calRef.current.getApi().changeView(val)
    if (!isFromUseEffect) {
      fetchEtxtra()
    } else {
      updateTitle()
    }
  }

  const gotoToday = () => {
    calRef.current.getApi().today()
    fetchEtxtra()
  }

  const goto = date => {
    calRef.current.getApi().gotoDate(date)
    fetchEtxtra()
  }

  const eventContent = arg => {
    const { isActive, isSmall } = isActiveCheck(arg.event.start, arg.event.end)

    const onClick = e => {
      e.preventDefault()
      e.stopPropagation()
      const onlineMeetingDetails = JSON.parse(
        arg.event.extendedProps.onlineMeetingDetails
      )
      if (onlineMeetingDetails.join_url) {
        window.open(onlineMeetingDetails.join_url, "_blank")
      }
    }

    return (
      <div
        className={`event ${isActive ? "isActive" : ""} ${
          isSmall ? "isSmall" : ""
        }`}
        onClick={() => getSingleScheduleDetails(arg.event.id, true)}
      >
        <p>
          {/* {arg?.event?.title} */}
          {`${arg.event.extendedProps?.section?.className}${arg.event.extendedProps?.section?.sectionName}`}{" "}
          -{" "}
          {arg.event.extendedProps?.subject?.subjectName.charAt(0) +
            arg.event.extendedProps?.subject?.subjectName
              .slice(1)
              .toLowerCase()}
        </p>

        <p className="small">
          {arg?.timeText}
          {arg.event.extendedProps?.scheduleType === "online" ? (
            <i className="fas fa-video" />
          ) : (
            <i className="fas fa-map-marker-alt" />
          )}
        </p>

        {isActive &&
          arg.event.extendedProps.scheduleType === "online" &&
          arg.event.extendedProps.onlineMeetingProvider === "ZOOM" && (
            <>
              {/* <p className="forC"></p> */}
              <button className="start" onClick={onClick}>
                Join
              </button>
            </>
          )}
      </div>
    )
  }

  const dayHeaderContent = arg => {
    let day = `${new Date(arg.date).getDate()}`
    let txt = arg?.text?.split(" ")
    let weekname = txt?.[0] || ""

    return (
      <div className="day-haeder">
        <p>{weekname}</p>
        <p className={arg.isToday ? "isToday" : ""}>
          {day.length === 1 ? `0${day}` : day}
        </p>
      </div>
    )
  }

  // function entClk(val) {
  //   getSingleScheduleDetails(val.event.id, true)
  // }

  return (
    <div className="calendar-wrapper">
      <Header
        next={next}
        prev={prev}
        goto={goto}
        title={title}
        isLoading={isLoading}
        gotoToday={gotoToday}
        changeView={changeView}
        isTodayVisible={isTodayVisible}
      />

      <FullCalendar
        ref={calRef}
        events={events}
        height="calc(100vh - 145px)"
        plugins={[timeGridPlugin]}
        allDaySlot={false}
        headerToolbar={false}
        slotMinTime="07:00:00"
        slotMaxTime="20:00:00"
        eventTimeFormat={eventTimeFormat}
        slotLabelFormat={slotLabelFormat}
        dayHeaderContent={dayHeaderContent}
        eventContent={eventContent}
        // eventClick={e => entClk(e)}
        slotEventOverlap={false}
        slotDuration="00:60"
        views={{
          timeGridThreeDay: {
            type: "timeGrid",
            duration: { days: 3 },
          },
          timeGridSevenDay: {
            type: "timeGrid",
            duration: { days: 7 },
          },
        }}
      />
    </div>
  )
}

export default Calendar
