/*************************************************
 * RedInk
 * @exports
 * @class TakeAttendanceReducer.js
 * @extends Component
 * @author vijayakumar // on 18/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from "../../actions/types"

const INTIAL_STATE = {
  userInfo: {},
  teacherClassList: [],
  attedenceList: [],
  viewAttedenceList: [],
}

export const takeAttendanceReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      }
    case ACTION_TYPES.TEACHER_CLASS_LIST:
      return {
        ...state,
        teacherClassList: [...action.payload],
      }
    case ACTION_TYPES.TAKE_ATTENDENCE:
      return {
        ...state,
        attedenceList: action.payload,
      }
    case ACTION_TYPES.VIEW_ATTENDENCE:
      return {
        ...state,
        viewAttedenceList: action.payload,
      }
    default:
      return state
  }
}
