/*************************************************
 * RedInk
 * @exports
 * @class Login.js
 * @extends Component
 * @author Sasidharan // on 17/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { createRef, Component } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import queryString from "query-string"
import store from "store"

import { logInUser, forgetPassword } from "../../actions/LoginAction"
import { showNotification } from "../../actions/CommonAction"
import { URL_PATH } from "../../config/urlPath"
import AuthScreen from "./AuthScreen"

class Login extends Component {
  constructor(props) {
    super(props)
    document.title = "Login | Redink"
    this.emailRef = createRef()
    this.passRef = createRef()
    this.btnRef = createRef()
    this.state = {
      username: "",
      password: "",
      emailid: "",
      isLoading: false,
      isValidation: false,
      isPasswordVisible: false,
      urlParams: {},
    }
  }

  componentDidMount = () => {
    this.focusEmail()
    this.setState({ urlParams: queryString.parse(window.location.search) })

    // if (!store.get("userSession") || store.get("userSession") === "undefined") {
    //   window.FreshworksWidget("hide")
    // }
    let userSession = store.get("userSession")
    let userInfo =
      userSession &&
      store.get("userSession") &&
      store.get("userSession") !== "undefined" &&
      store.get("userSession").userInfo
    if (userInfo && userInfo.role === 0) {
      // if (userInfo.firstLogin === 1) {
      //   this.props.history.push(URL_PATH.FOUNDER_CHANGEPASSWORD)
      // } else {
      this.props.history.push(URL_PATH.FOUNDER)
      // }
    }
    if (userInfo && userInfo.role === 1) {
      if (userInfo.firstLogin === 1) {
        this.props.history.push(URL_PATH.SUPER_ADMIN_CHANGEPASSWORD)
      } else {
        this.props.history.push(URL_PATH.SUPER_ADMIN)
      }
    }
    if (userInfo && userInfo.role === 2) {
      if (
        userInfo.firstLogin === 1 ||
        userInfo.currentStep === 0 ||
        userInfo.currentStep === 1 ||
        userInfo.currentStep === 2 ||
        userInfo.currentStep === 3
      ) {
        this.props.history.push(URL_PATH.ADMIN_ONBOARDING)
      } else {
        this.props.history.push(URL_PATH.ADMIN)
      }
    }
    if (userInfo && userInfo.role === 3) {
      if (userInfo.firstLogin === 1) {
        this.props.history.push(URL_PATH.TEACHER_CHANGEPASSWORD)
      } else {
        this.props.history.push(URL_PATH.TEACHER)
      }
    }
    if (userInfo && userInfo.role === 4) {
      if (userInfo.firstLogin === 1) {
        this.props.history.push(URL_PATH.TEACHER_CHANGEPASSWORD)
      } else {
        this.props.history.push(URL_PATH.TEACHER)
      }
    }
    if (userInfo && userInfo.role === 5) {
      if (userInfo.firstLogin === 1) {
        this.props.history.push(URL_PATH.PARENT_CHANGEPASSWORD)
      } else {
        this.props.history.push(URL_PATH.PARENT)
      }
    }

    window.addEventListener(
      "onKeyDown",
      // (event)=>{
      //   if(event.key === 'Enter'){
      //     this.onLoginClicked()
      //   }
      // }
      this.onLoginClicked
    )
  }

  /**
   * Handles on submit login form
   */
  onLoginClicked = () => {
    if (this.state.isShowForgetPassword) {
      if (this.state.emailid.trim() === "") {
        this.setState({ isValidation: true, showLoginFailed: false })
      } else {
        this.setState({ isLoading: true, showLoginFailed: false })
        this.props.forgetPassword(this.state.emailid, (success, response) => {
          this.setState({ isLoading: false, emailid: "" })
        })
      }
    } else {
      if (
        this.state.username.trim() === "" ||
        this.state.password.trim() === ""
      ) {
        this.setState({ isValidation: true, showLoginFailed: false })
      } else {
        this.setState({ isLoading: true, showLoginFailed: false })
        this.props.logInUser(
          encodeURIComponent(this.state.username.trim()),
          this.state.password.trim(),
          (boolean, loginResponse) => {
            this.setState({ isLoading: false })
            if (boolean) {
              if (
                this.state.urlParams.redirect &&
                this.state.urlParams.redirect === "zoom"
              ) {
                window.location.href =
                  "https://zoom.us/oauth/authorize?response_type=code&client_id=npW2qm3SoQNxiEaF2mg&redirect_uri=https://school.redink.app/connect/zoom"
                return
              }

              if (loginResponse.userInfo.role === 0) {
                // if (loginResponse.userInfo.firstLogin === 1) {
                // this.props.history.push(URL_PATH.FOUNDER_CHANGEPASSWORD)
                // } else {
                this.props.history.push(URL_PATH.FOUNDER)
                // }
              }
              if (loginResponse.userInfo.role === 1) {
                if (loginResponse.userInfo.firstLogin === 1) {
                  this.props.history.push(URL_PATH.SUPER_ADMIN_CHANGEPASSWORD)
                } else {
                  this.props.history.push(URL_PATH.SUPER_ADMIN)
                }
              }
              if (loginResponse.userInfo.role === 2) {
                if (
                  loginResponse.userInfo.firstLogin === 1 ||
                  loginResponse.userInfo.currentStep === 0 ||
                  loginResponse.userInfo.currentStep === 1 ||
                  loginResponse.userInfo.currentStep === 2 ||
                  loginResponse.userInfo.currentStep === 3
                ) {
                  this.props.history.push(URL_PATH.ADMIN_ONBOARDING)
                } else {
                  this.props.history.push(URL_PATH.ADMIN)
                }
              }
              if (loginResponse.userInfo.role === 3) {
                if (loginResponse.userInfo.firstLogin === 1) {
                  this.props.history.push(URL_PATH.TEACHER_CHANGEPASSWORD)
                } else {
                  this.props.history.push(URL_PATH.TEACHER)
                }
              }
              if (loginResponse.userInfo.role === 4) {
                if (loginResponse.userInfo.firstLogin === 1) {
                  this.props.history.push(URL_PATH.TEACHER_CHANGEPASSWORD)
                } else {
                  this.props.history.push(URL_PATH.TEACHER)
                }
              }
              if (loginResponse.userInfo.role === 5) {
                if (loginResponse.userInfo.firstLogin === 1) {
                  this.props.history.push(URL_PATH.PARENT_CHANGEPASSWORD)
                } else {
                  this.props.history.push(URL_PATH.PARENT)
                }
              }
            } else {
              this.setState({ showLoginFailed: true })
              // if (loginResponse !== "") {
              //   this.props.showNotification(
              //     DANGER_TOAST,
              //     VALIDATION_MSG.OOPS,
              //     VALIDATION_MSG.AUTH_FAILED
              //   )
              // }
            }
          }
        )
      }
    }
  }

  focusEmail() {
    this.emailRef.current.focus()
  }

  focusPassword() {
    this.passRef.current.focus()
  }

  focusLoginBtn() {
    this.btnRef.current.focus()
  }

  render() {
    const { username, password, isValidation } = this.state

    return (
      <AuthScreen>
        <div className="p-5 text-white d-flex login bg-theme-light w-100 justify-content-center">
          <div className="align-self-center">
            <Link
              to={URL_PATH.LOGIN}
              className="mb-2 text-center d-block d-sm-none"
            >
              {/* <img src={logo} alt="" /> */}
            </Link>

            <div>
              <h4 className="text-center text-uppercase">
                Login To Your Account
              </h4>

              <div className="mt-4 form-group">
                <label className="required">Username / Email</label>
                <input
                  ref={this.emailRef}
                  type="text"
                  className={`form-control ${
                    isValidation && username === "" ? "is-invalid" : ""
                  }`}
                  value={this.state.username}
                  onChange={e =>
                    this.setState({
                      username: e.target.value,
                      showLoginFailed: false,
                    })
                  }
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      this.focusPassword()
                    }
                  }}
                  placeholder="Username / Email"
                />
                <div className="invalid-feedback">
                  Please enter a valid username / email.
                </div>
              </div>

              <div className="mb-4 form-group">
                <label className="required">Password</label>
                <div className="mt-3">
                  <div className="w-100 d-flex align-items-center position-relative">
                    <input
                      ref={this.passRef}
                      type={this.state.isPasswordVisible ? "text" : "password"}
                      className={`form-control position-absolute ${
                        isValidation && password === "" ? "is-invalid" : ""
                      }`}
                      value={this.state.password}
                      onChange={e =>
                        this.setState({
                          password: e.target.value,
                          showLoginFailed: false,
                        })
                      }
                      placeholder="Password"
                      onKeyDown={e => {
                        if (e.key === "Enter") {
                          this.focusLoginBtn()
                        }
                      }}
                    />{" "}
                    <i
                      className="cursor-pointer fas fa-eye text-dark"
                      style={{
                        position: "absolute",
                        left: "90%",
                      }}
                      aria-hidden="true"
                      onClick={() =>
                        this.setState({
                          isPasswordVisible: !this.state.isPasswordVisible,
                        })
                      }
                    ></i>
                  </div>
                </div>
              </div>

              {isValidation && password === "" && (
                <small className="mt-1 text-danger">
                  Please enter a valid password.
                </small>
              )}

              <div className="text-right">
                <Link to={URL_PATH.FORGET_PASSWORD} className="cursor-pointer">
                  <small>Forgot Password?</small>
                </Link>
              </div>
              <button
                className="mt-3 login-form-btn btn w-100"
                onClick={() => this.onLoginClicked()}
                disabled={this.state.isLoading}
                ref={this.btnRef}
              >
                {this.state.isLoading ? "Logging in..." : "LOGIN"}
              </button>
            </div>
          </div>
        </div>
      </AuthScreen>
    )
  }
}

export default connect(null, { logInUser, showNotification, forgetPassword })(
  Login
)
