import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { HashLoader } from "react-spinners"

import { getClassDetails } from "../../../actions/admin/ClassDetailsAction"

import IndividualClass from "./IndividualClass"
import ManualModal from "./ManualModal"
import AppWrapper from "../Wrapper1"
import "./index.css"

function QuestionGen() {
  const classList = useSelector(({ classDetailsState }) => {
    const newData = []

    classDetailsState.classList.forEach(ch => {
      const isAlreadyPresent = newData.findIndex(
        nd => nd.className === ch.classNameOnly
      )

      if (isAlreadyPresent !== -1) {
        newData[isAlreadyPresent].subClasses.push({ ...ch })
      } else {
        newData.push({
          id: ch.classId,
          className: ch.classNameOnly,
          subClasses: [{ ...ch }],
        })
      }
    })

    return newData
  })

  const [isLoading, setIsLoading] = useState(true)
  const [show, setShow] = useState("")
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getClassDetails({}, () => setIsLoading(false)))

    document.body.classList.add("bg-gray-for-report")

    return () => document.body.classList.remove("bg-gray-for-report")
  }, [])

  return (
    <AppWrapper adminDashboard screenName="Question paper">
      {!show && (
        <div
          className="d-flex align-items-end justify-content-end mx-auto"
          style={{ maxWidth: "930px" }}
        >
          <button
            className="px-4 py-2 fs-15 fw-600 bg-50AE93 text-white br-8px"
            onClick={() => setShow("create")}
          >
            Create Question Paper
          </button>
        </div>
      )}

      {isLoading && (
        <HashLoader
          css={{
            position: "fixed",
            width: "100%",
            height: "100%",
            left: "0px",
            top: "0px",
          }}
          size={30}
          color="#084353"
          sizeUnit="px"
          loading
        />
      )}

      {!isLoading &&
        !show &&
        classList.map(c => (
          <div
            key={c.id}
            onClick={() => setShow(pr => (pr === c.id ? "" : c.id))}
            className={`qp-card d-flex align-items-center fs-17 ${
              show === c.id ? "fw-600" : "fw-500"
            }`}
          >
            Class {c.className}
          </div>
        ))}

      {!isLoading && show && show !== "create" && (
        <IndividualClass
          goBack={() => setShow("")}
          sectionName={classList.find(c => c.id === show)?.className}
          classId={show}
        />
      )}

      {show === "create" && <ManualModal toHome={() => setShow("")} />}
    </AppWrapper>
  )
}

export default QuestionGen
