/*************************************************
 * RedInk
 * @exports
 * @class index.js
 * @extends Component
 * @author Sasidharan // on 01/02/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import AppWrapper from "../Wrapper"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { HashLoader } from "react-spinners"
import { DANGER_TOAST, VALIDATION_MSG } from "../../../utils/Constants"
import {
  showNotification,
  getCheckEmailAvailableDetails,
} from "../../../actions/CommonAction"
import {
  addSuperAdminDetails,
  getSuperAdminDetails,
  checkTentantNameAvailability,
} from "../../../actions/founder/SuperAdminDetailsAction"
import { myLog, validateEmail } from "../../../utils/Utility"
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip"

class EditSuperAdmin extends Component {
  constructor(props) {
    super(props)
    document.title = "Dashboard | Redink"
    this.state = {
      userName: this.props.location.state.schoolDetails.userName,
      chairmanName: this.props.location.state.schoolDetails.chairmanName,
      tentantEmail: this.props.location.state.schoolDetails.tentantEmail,
      tentantPhone: this.props.location.state.schoolDetails.tentantPhone,
      tentantFullName: this.props.location.state.schoolDetails.tentantFullName,
      tentantName: this.props.location.state.schoolDetails.tentantName,
      isTaskActive: this.props.location.state.schoolDetails
        ? this.props.location.state.schoolDetails.isTaskActive
        : true,
      isNewAndEventsActive: this.props.location.state.schoolDetails
        ? this.props.location.state.schoolDetails.isNewAndEventsActive
        : true,
      isCommunicationActive: this.props.location.state.schoolDetails
        ? this.props.location.state.schoolDetails.isCommunicationActive
        : true,
      isBehaviourActive: this.props.location.state.schoolDetails
        ? this.props.location.state.schoolDetails.isBehaviourActive
        : true,
      isAssignmentActive: this.props.location.state.schoolDetails
        ? this.props.location.state.schoolDetails.isAssignmentActive
        : true,
      isAttendanceActive: this.props.location.state.schoolDetails
        ? this.props.location.state.schoolDetails.isAttendanceActive
        : true,
      isAcademicActive: this.props.location.state.schoolDetails
        ? this.props.location.state.schoolDetails.isAcademicActive
        : true,
      isTimetableActive: this.props.location.state.schoolDetails
        ? this.props.location.state.schoolDetails.isTimetableActive
        : true,
      schoolStatus:
        this.props.location.state.schoolDetails.schoolStatus || true,
      city: this.props.location.state.schoolDetails.city,
      id: this.props.location.state.schoolDetails.id,
      dataEntrySupport:
        this.props.location.state.schoolDetails.dataEntrySupport || false,
      dataEntrySupportEmail: this.props.location.state.schoolDetails
        .dataEntrySupportEmail,
    }
  }

  componentDidMount = () => {
    // this.props.getSuperAdminDetails((success, response) => {
    //   myLog(response)
    //   if (success) {
    //     this.setState({
    //       schoolList : response
    //     })
    //   }
    // })
  }

  handleChange = () => event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handlePermission = () => event => {
    myLog(event.target.value)
    this.setState({ [event.target.name]: event.target.value ? true : false })
  }

  clearState = () => {
    this.setState({
      userName: "",
      tentantEmail: "",
      tentantPhone: "",
      tentantFullName: "",
      tentantName: "",
      chairmanName: "",
      isCommunicationActive: "",
      isTaskActive: "",
      isAttendanceActive: "",
      isNewAndEventsActive: "",
      isAssignmentActive: "",
      isBehaviourActive: "",
      isAcademicActive: "",
      isTimetableActive: "",
      city: "",
      schoolStatus: "",
      hideFields: false,
      hasErrorInTentantEmail: false,
      hasErrorInTentantName: false,
      dataEntrySupport: false,
      dataEntrySupportEmail: "",
    })
  }

  addSuperAdmin = () => {
    this.setState({ isShowLoading: true })

    if (!this.state.tentantFullName || this.state.tentantFullName === " ") {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.OOPS,
        "School Full Name cannot empty"
      )
      this.setState({ isShowLoading: false })
      return
    }

    if (!this.state.tentantName || this.state.tentantName === " ") {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.OOPS,
        "School Acronym cannot empty"
      )
      this.setState({ isShowLoading: false })
      return
    }

    if (!this.state.chairmanName || this.state.chairmanName === " ") {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.OOPS,
        "Chairman name cannot be empty"
      )
      this.setState({ isShowLoading: false })
      return
    }

    if (this.state.tentantPhone !== "" && this.state.tentantPhone !== null) {
      if (
        String(this.state.tentantPhone).length < 10 ||
        String(this.state.tentantPhone).length > 15
      ) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Invalid contact number"
        )
        this.setState({ isShowLoading: false })
        return
      }
    } else {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.OOPS,
        "Contact number cannot empty"
      )
      this.setState({ isShowLoading: false })
      return
    }

    if (this.state.tentantEmail && this.state.tentantEmail !== " ") {
      if (!validateEmail(this.state.tentantEmail)) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Invalid Email Id"
        )
        this.setState({ isShowLoading: false })
        return
      }
    } else {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.OOPS,
        "Email Id cannot be empty"
      )
      this.setState({ isShowLoading: false })
      return
    }

    if (!this.state.city || this.state.city === " ") {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.OOPS,
        "City cannot be empty"
      )
      this.setState({ isShowLoading: false })
      return
    }

    if (this.state.hasErrorInTentantEmail) {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.SUCCESS,
        "Email already exists"
      )
      this.setState({ isShowLoading: false })
      return
    }
    if (this.state.hasErrorInTentantName) {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.SUCCESS,
        "Acronym already exists"
      )
      this.setState({ isShowLoading: false })
      return
    }

    if (this.state.dataEntrySupport && this.state.dataEntrySupportEmail) {
      if (!validateEmail(this.state.dataEntrySupportEmail)) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Data Support Email Id Invalid"
        )
        this.setState({ isShowLoading: false })
        return
      }
    }

    let object = {
      id: this.state.id,
      userName: this.state.tentantEmail,
      tentantEmail: this.state.tentantEmail,
      tentantPhone: this.state.tentantPhone,
      tentantFullName: this.state.tentantFullName,
      tentantName: this.state.tentantName,
      chairmanName: this.state.chairmanName,
      isCommunicationActive: this.state.isCommunicationActive,
      isTaskActive: false,
      isAttendanceActive: this.state.isAttendanceActive,
      isNewAndEventsActive: false,
      isAssignmentActive: this.state.isAssignmentActive,
      isBehaviourActive: false,
      isAcademicActive: this.state.isAcademicActive,
      isTimetableActive: false,
      city: this.state.city,
      schoolStatus: this.state.schoolStatus,
      dataEntrySupport: this.state.dataEntrySupport,
      dataEntrySupportEmail: this.state.dataEntrySupportEmail,
    }
    myLog("SUPER ADMIN DETAILS ==>", object)
    this.props.addSuperAdminDetails(object, (success, response) => {
      if (success) {
        myLog(response)
        this.setState({ isShowLoading: false })
        this.clearState()
        this.props.history.push("/founder/superAdmin")
      } else {
        myLog("REQUEST CODE:", response)
        this.setState({ isShowLoading: false })
      }
    })
  }

  superAdminDetails = () => {
    return (
      <div className="card p-4" style={{ borderRadius: "10px" }}>
        <form>
          <h3 className="text-danger pr-2 mb-4">Super Admin</h3>
          {this.state.hideFields && (
            <div className="form-group">
              <label>User Name :</label>
              <input
                type="text"
                className="form-control"
                name="userName"
                value={this.state.userName}
                onChange={
                  // this.handleChange()
                  e => {
                    e.target.value = e.target.value.replace(/[^A-Za-z. ]/gi, "")
                    this.setState({ userName: e.target.value })
                  }
                }
              />
            </div>
          )}
          <div className="form-group">
            <label>School Full Name</label>
            <input
              type="text"
              className="form-control"
              name="tentantFullName"
              // maxLength="50"
              value={this.state.tentantFullName}
              onChange={
                // this.handleChange()
                e =>
                  this.setState({
                    tentantFullName: e.target.value.replace(
                      /[^A-Za-z./ ]/gi,
                      ""
                    ),
                  })
              }
            />
          </div>
          <div className="row">
            <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
              <UncontrolledTooltip placement="top" target={`acronym-info`}>
                Purpose of acronym is to provide custom subdomain for school
                like svs.redink.app
              </UncontrolledTooltip>{" "}
              <label>
                School Acronym{" "}
                <i id="acronym-info" className="fas mx-2 fa-info-circle"></i>{" "}
                (used for subdomain, eg names - svs, psna, ksr)
              </label>
              <input
                type="text"
                className="form-control"
                name="tentantName"
                value={this.state.tentantName}
                maxLength="25"
                onChange={e => {
                  // this.handleChange()
                  e.target.value = e.target.value.replace(/[^A-Za-z. ]/gi, "")
                  this.setState({
                    tentantName: e.target.value,
                    hasErrorInTentantName: false,
                  })
                  this.props.checkTentantNameAvailability(
                    e.target.value,
                    (success, response) => {
                      if (response) {
                        this.setState({ hasErrorInTentantName: true })
                        this.props.showNotification(
                          DANGER_TOAST,
                          VALIDATION_MSG.OOPS,
                          "Acronym already exists."
                        )
                      }
                    }
                  )
                }}
              />
            </div>
            <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
              <label>Chairman Name</label>
              <input
                type="text"
                className="form-control"
                name="chairmanName"
                maxLength="25"
                value={this.state.chairmanName}
                onChange={e => {
                  e.target.value = e.target.value.replace(/[^A-Za-z. ]/gi, "")
                  this.setState({ chairmanName: e.target.value })
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
              <label>City</label>
              <input
                type="text"
                className="form-control"
                name="city"
                value={this.state.city}
                onChange={e => {
                  e.target.value = e.target.value.replace(/[^A-Za-z.\- ]/gi, "")
                  this.setState({ city: e.target.value })
                }}
              />
            </div>
            <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
              <label>Phone</label>
              <input
                type="number"
                className="form-control"
                name="tentantPhone"
                value={this.state.tentantPhone}
                onChange={this.handleChange()}
              />
            </div>
          </div>
          <div className="form-group">
            <UncontrolledTooltip placement="top" target={`email-info`}>
              Signup mail will be sent to this Email ID
            </UncontrolledTooltip>{" "}
            <label>
              Email <i id="email-info" className="fas ml-2 fa-info-circle"></i>
            </label>
            <input
              type="email"
              className="form-control"
              name="tentantEmail"
              value={this.state.tentantEmail}
              onChange={e => {
                this.handleChange()
                this.setState({
                  tentantEmail: e.target.value,
                  hasErrorInTentantEmail: false,
                })
                this.props.getCheckEmailAvailableDetails(
                  e.target.value,
                  (success, response) => {
                    if (response) {
                      this.setState({ hasErrorInTentantEmail: true })
                      this.props.showNotification(
                        DANGER_TOAST,
                        VALIDATION_MSG.OOPS,
                        "Email id already exists."
                      )
                    }
                  }
                )
              }}
            />
          </div>
          <h4 className="text-danger pr-2 mt-4">Permission Access</h4>
          {this.state.hideFields && (
            <div className="form-check form-check-inline">
              <input
                type="checkbox"
                id="isTaskActive"
                name="isTaskActive"
                value={this.state.isTaskActive}
                defaultChecked={this.state.isTaskActive}
                onChange={() => {
                  this.setState({ isTaskActive: !this.state.isTaskActive })
                }}
              />
              <label className="form-check-label p-2" htmlFor="isTaskActive">
                Show Tasks
              </label>
            </div>
          )}
          {this.state.hideFields && (
            <div className="form-check form-check-inline">
              <input
                type="checkbox"
                id="isNewAndEventsActive"
                name="isNewAndEventsActive"
                value={this.state.isNewAndEventsActive}
                defaultChecked={this.state.isNewAndEventsActive}
                onChange={() => {
                  this.setState({
                    isNewAndEventsActive: !this.state.isNewAndEventsActive,
                  })
                }}
              />
              <label
                className="form-check-label p-2"
                htmlFor="isNewAndEventsActive"
              >
                Show News and Events
              </label>
            </div>
          )}

          <div className="form-check form-check-inline">
            <input
              type="checkbox"
              id="isCommunicationActive"
              name="isCommunicationActive"
              value={this.state.isCommunicationActive}
              defaultChecked={this.state.isCommunicationActive}
              onChange={() => {
                this.setState({
                  isCommunicationActive: !this.state.isCommunicationActive,
                })
              }}
            />
            <label
              className="form-check-label p-2"
              htmlFor="isCommunicationActive"
            >
              Show Communication
            </label>
          </div>

          <div className="form-check form-check-inline">
            <input
              type="checkbox"
              id="isAssignmentActive"
              name="isAssignmentActive"
              value={this.state.isAssignmentActive}
              defaultChecked={this.state.isAssignmentActive}
              onChange={() => {
                this.setState({
                  isAssignmentActive: !this.state.isAssignmentActive,
                })
              }}
            />
            <label
              className="form-check-label p-2"
              htmlFor="isAssignmentActive"
            >
              Show Assignment
            </label>
          </div>

          <div className="form-check form-check-inline">
            <input
              type="checkbox"
              id="isAcademicActive"
              name="isAcademicActive"
              value={this.state.isAcademicActive}
              defaultChecked={this.state.isAcademicActive}
              onChange={() => {
                this.setState({
                  isAcademicActive: !this.state.isAcademicActive,
                })
              }}
            />
            <label className="form-check-label p-2" htmlFor="isAcademicActive">
              Show Academic
            </label>
          </div>

          <div className="form-check form-check-inline">
            <input
              type="checkbox"
              id="isAttendanceActive"
              name="isAttendanceActive"
              value={this.state.isAttendanceActive}
              defaultChecked={this.state.isAttendanceActive}
              onChange={() => {
                this.setState({
                  isAttendanceActive: !this.state.isAttendanceActive,
                })
              }}
            />
            <label
              className="form-check-label p-2"
              htmlFor="isAttendanceActive"
            >
              Show Attendance
            </label>
          </div>
          {this.state.hideFields && (
            <div className="form-check form-check-inline">
              <input
                type="checkbox"
                id="isBehaviourActive"
                name="isBehaviourActive"
                value={this.state.isBehaviourActive}
                defaultChecked={this.state.isBehaviourActive}
                onChange={() => {
                  this.setState({
                    isBehaviourActive: !this.state.isBehaviourActive,
                  })
                }}
              />
              <label
                className="form-check-label p-2"
                htmlFor="isBehaviourActive"
              >
                Show Behvaiour
              </label>
            </div>
          )}
          {this.state.hideFields && (
            <div className="form-check form-check-inline">
              <input
                type="checkbox"
                id="isTimetableActive"
                name="isTimetableActive"
                value={this.state.isTimetableActive}
                defaultChecked={this.state.isTimetableActive}
                onChange={() => {
                  this.setState({
                    isTimetableActive: !this.state.isTimetableActive,
                  })
                }}
              />
              <label
                className="form-check-label p-2"
                htmlFor="isTimetableActive"
              >
                Show TimeTable
              </label>
            </div>
          )}

          <div className="form-group pt-3">
            <div className="d-flex">
              <h5 className="pr-2 pt-2 text-danger">Status</h5>
              <label className="switch">
                <input
                  name="schoolStatus"
                  type="checkbox"
                  onChange={() =>
                    this.setState({
                      schoolStatus: !this.state.schoolStatus,
                    })
                  }
                  checked={this.state.schoolStatus}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>

          <div
            className="p-5 rounded-lg"
            style={{ backgroundColor: "#ffa3a3" }}
          >
            <h5 className="text-dark mb-4">
              <b>Note</b>: Enable this to receive all onboarding / signup mails
              for this school to the below mail id. (Toggle this off when
              handover to school)
            </h5>
            <div className="form-group">
              <div className="d-flex align-items-center">
                <h5 className="pr-4 text-dark">Provide Data entry Support</h5>
                <label className="switch">
                  <input
                    name="dataEntrySupport"
                    type="checkbox"
                    onChange={() =>
                      this.setState(
                        {
                          dataEntrySupport: !this.state.dataEntrySupport,
                        },
                        () => {
                          if (!this.state.dataEntrySupport) {
                            this.setState({ dataEntrySupportEmail: "" })
                          } else {
                            this.setState({
                              dataEntrySupportEmail: this.props.location.state
                                .schoolDetails.dataEntrySupportEmail,
                            })
                          }
                        }
                      )
                    }
                    checked={this.state.dataEntrySupport}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>

            <div className="form-group">
              <label>Redink data entry support Email Id</label>
              <input
                type="email"
                className={`form-control ${
                  !this.state.dataEntrySupport ? "disabled" : ""
                }`}
                name="dataEntrySupportEmail"
                placeholder="Enter redink email address here"
                value={this.state.dataEntrySupportEmail}
                onChange={this.handleChange()}
              />
            </div>
          </div>

          <div className="d-flex align-content-end mt-4">
            <button
              type="button"
              className="btn btn-secondary align-self-end rounded-pill"
              style={{
                background: "rgb(219, 219, 219)",
                color: "#06b191",
                border: "none",
              }}
              onClick={() => this.props.history.push("/founder/superAdmin")}
            >
              Cancel
            </button>

            <button
              type="button"
              className="btn btn-theme align-self-end rounded-pill ml-3"
              style={{ backgroundColor: "#06b191" }}
              onClick={this.addSuperAdmin}
            >
              {this.state.isShowLoading ? (
                <HashLoader
                  sizeUnit={"px"}
                  size={16}
                  color={"#ffffff"}
                  loading={this.state.isShowLoading}
                />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </form>
      </div>
    )
  }

  render() {
    return <AppWrapper founderDashboard>{this.superAdminDetails()}</AppWrapper>
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      addSuperAdminDetails,
      getSuperAdminDetails,
      showNotification,
      checkTentantNameAvailability,
      getCheckEmailAvailableDetails,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(EditSuperAdmin)
