import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { Button, Modal } from "reactstrap"
import { HashLoader } from "react-spinners"
import { connect } from "react-redux"
import moment from "moment"

import { getSingleScheduleDetails } from "../../../actions/parent/ScheduleAction"
import { getFile } from "../../../actions/CommonAction"

import AppWrapper from "../Wrapper"
import FullCalendar from "./Calendar"

class StudentScheduleScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoadingSchedule: false,
      isShowLoading: false,

      events: [],
      forUpdatingEnts: false,

      createScheduleModalOpen: false,
      viewScheduleModalOpen: false,
      classesList: null,
      selectedSchedule: null,
      scheduleType: "OFFLINE",
      title: null,
      description: null,
      attachments: [],
      selectedClass: null,
      selectedSubject: null,
      selectedStudents: [],
      startTime: null,
      duration: null,
      onlineMeetingProvider: null,
      onlineMeetingDetails: null,
      repeat: null,
      youtubeURL: null,
      attachmentFile: null,
      attachmentType: "file",
      isDownloadingAttachment: false,
      loading: false,
      isUpdate: false,
    }
  }

  clearData = () => {
    this.setState({
      selectedSchedule: null,
      scheduleType: "OFFLINE",
      startTime: "",
      title: "",
      description: "",
      attachments: [],
      duration: null,
      onlineMeetingProvider: null,
      onlineMeetingDetails: null,
      repeat: null,
      youtubeURL: null,
      attachmentFile: null,
      attachmentType: null,
    })
  }

  parseFileNameFromURL = (fileName, locationLength) => {
    if (typeof fileName !== "string") return ""
    const locations = fileName.split("?")[0].split("/")
    ;[...Array(locationLength)].map(() => locations.shift())
    const removedLocation = locations.join("")
    const removedId = removedLocation.slice(removedLocation.indexOf("_") + 1)
    return decodeURIComponent(decodeURI(removedId))
  }

  getSingleScheduleDetails = (id, viewModal = false) => {
    this.props.getSingleScheduleDetails(id, (success, response) => {
      this.clearData()
      this.setState(
        { isShowLoading: false, selectedSchedule: response.data },
        () => {
          const {
            // selectedSchedule,
            scheduleType,
            title,
            description,
            duration,
            repeat,
            onlineMeetingProvider,
            onlineMeetingDetails,
          } = this.state.selectedSchedule
          const selectedClass = {}
          selectedClass.id = this.state.selectedSchedule.section.sectionId
          selectedClass.label =
            this.state.selectedSchedule.section.className +
            this.state.selectedSchedule.section.sectionName
          selectedClass.value =
            this.state.selectedSchedule.section.className +
            this.state.selectedSchedule.section.sectionName

          const selectedSubject = {}
          selectedSubject.id = this.state.selectedSchedule.subject.subjectId
          selectedSubject.label = this.state.selectedSchedule.subject.subjectName
          selectedSubject.value = this.state.selectedSchedule.subject.subjectName

          const selectedStudents = []
          for (const student of this.state.selectedSchedule.scheduleStudents) {
            selectedStudents.push({
              id: student.studentId,
              label: student.student.studentName || student.student.fullName,
              value: student.student.studentName || student.student.fullName,
            })
          }

          this.setState(
            {
              scheduleType: scheduleType.toUpperCase(),
              title,
              description,
              duration,
              repeat,
              startTime: moment(this.state.selectedSchedule.startTime),
              attachments: this.state.selectedSchedule.scheduleAttachments,
              selectedClass,
              selectedSubject,
              onlineMeetingProvider,
              onlineMeetingDetails,
            },
            () => {
              this.setState({ viewScheduleModalOpen: viewModal })
            }
          )
        }
      )
    })
  }

  parseFileName = (fileName, location) => {
    if (typeof fileName !== "string") return ""

    const removedLocation = fileName.replace(location, "")
    return decodeURI(removedLocation.slice(removedLocation.indexOf("_") + 1))
  }

  getAttachment = url => {
    this.props.getFile(url, (success, response) => {
      if (success) {
        let filename = this.parseFileName(
          response.url,
          "schedule/teacherUploads"
        )
        filename = filename.slice(0, filename.indexOf("?X-Amz"))
        this.setState({ isDownloadingAttachment: true })
        const xhr = new XMLHttpRequest()
        xhr.onload = () => {
          const file = new Blob([xhr.response])
          const browserUrl = window.webkitURL || window.URL
          const fileUrl = browserUrl.createObjectURL(file)
          const a = document.createElement("a")
          a.href = fileUrl
          a.download = filename
          a.click()
          browserUrl.revokeObjectURL(fileUrl)
          this.setState({ isDownloadingAttachment: false })
        }
        xhr.open("GET", response.url, true)
        xhr.responseType = "blob"
        xhr.send(null)
      }
    })
  }

  updateEvents = payload => {
    this.setState({ events: payload })
  }

  renderViewScheduleModal = () => (
    <Modal
      isOpen={this.state.viewScheduleModalOpen}
      modalClassName="right slide-in-right fade"
    >
      <div
        className="modal-body p-4"
        style={{ backgroundColor: "#F1F7F7", borderRadius: 12 }}
      >
        <div className="d-flex justify-content-between">
          <div>
            <h5 className="mb-4 text-brand font-weight-bold">
              {this.state.selectedSubject && this.state.selectedSubject.label}
            </h5>
          </div>
          <div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                this.setState({
                  viewScheduleModalOpen: false,
                })
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>

        {this.state.isShowModalLoading ? (
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: 150 }}
          >
            <HashLoader
              sizeUnit={"px"}
              size={35}
              color={"#084353"}
              loading={this.state.isShowModalLoading}
            />
          </div>
        ) : (
          <>
            <div className="row align-items-center justify-content-between ml-1 mb-3">
              {this.state.scheduleType === "ONLINE" ? (
                <div
                  style={{
                    borderRadius: 12,
                    backgroundColor: "rgba(82, 199, 178, 0.2)",
                    padding: "5px 10px",
                    display: "flex",
                  }}
                >
                  <div
                    className="font-weight-bold m-auto"
                    style={{
                      color: "#08A083",
                      fontSize: "0.8rem",
                      fontWeight: "normal",
                    }}
                  >
                    <i className="fas fa-video" />
                    <span className="ml-2">Online Lecture</span>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    borderRadius: 8,
                    backgroundColor: "rgba(255, 175, 192, 0.43)",
                    padding: "5px 10px",
                    display: "flex",
                  }}
                >
                  <div
                    className="font-weight-bold m-auto"
                    style={{
                      fontWeight: "normal",
                      color: "rgb(206, 90, 123)",
                      fontSize: "0.8rem",
                    }}
                  >
                    <i className="fas fa-map-marker-alt" />
                    <span className="ml-2">InClass Lecture</span>
                  </div>
                </div>
              )}
            </div>

            <div className="d-flex mb-3">
              <i className="fas fa-calendar-alt mt-1 text-muted"></i>
              <div className="ml-3">
                {this.state.selectedSchedule &&
                  moment(this.state.selectedSchedule.startTime).format(
                    "ddd, MMM D, YYYY"
                  )}
                <br />
                {this.state.selectedSchedule &&
                  moment(this.state.selectedSchedule.startTime).format(
                    "h:mm a"
                  )}{" "}
                -{" "}
                {this.state.selectedSchedule &&
                  moment(this.state.selectedSchedule.startTime)
                    .add(this.state.duration, "minutes")
                    .format("h:mm a")}{" "}
                ({this.state.duration}mins)
              </div>
            </div>

            <div className="d-flex mb-3">
              <i className="fas fa-dot-circle mt-1 text-muted"></i>
              <div className="ml-3">
                <span className="text-muted">Title</span>
                <br />
                <span
                  className="mt-3"
                  style={{ color: "#006063 !important", fontSize: "1.2rem" }}
                >
                  {this.state.title}
                </span>
              </div>
            </div>

            <div className="d-flex mb-3">
              <i className="fas fa-file-alt mt-1 text-muted"></i>
              <div className="ml-3">
                <span className="text-muted">Description</span>
                <br />
                <span className="mt-3 text-dark" style={{ fontSize: "1rem" }}>
                  {this.state.description}
                </span>
              </div>
            </div>

            {this.state.attachments.length > 0 && (
              <div className="d-flex mb-4">
                <i className="fas fa-paperclip mt-1 text-muted"></i>
                <div
                  className="ml-3"
                  style={{
                    width: "100%",
                  }}
                >
                  <span className="text-muted">Attachments</span>
                  <br />
                  {this.state.attachments.length > 0 &&
                    this.state.attachments.map((item, index) => {
                      return (
                        item.visibility && (
                          <div
                            className="mt-3 px-3 py-2"
                            style={{
                              borderRadius: "10px",
                              border: "solid 1px #eeeeee",
                            }}
                          >
                            <div className="row">
                              <div className="col-12">
                                {item.attachmentType === "youtube" ? (
                                  <div>
                                    <i className="fas fa-video fs-15 text-warning" />{" "}
                                    <span
                                      className="text-brand"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Youtube Video
                                    </span>
                                  </div>
                                ) : this.parseFileNameFromURL(
                                    item.attachment
                                  ).endsWith(".pdf") ? (
                                  <div>
                                    <img
                                      src={require("../../../assets/images/assignment/PDF.png")}
                                      alt="pdf"
                                      width="20"
                                    />{" "}
                                    <span
                                      className="text-brand"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      PDF
                                    </span>
                                  </div>
                                ) : this.parseFileNameFromURL(
                                    item.attachment
                                  ).endsWith(".png") ? (
                                  <div>
                                    <img
                                      src={require("../../../assets/images/assignment/PNG.png")}
                                      alt="png"
                                      width="20"
                                    />{" "}
                                    <span
                                      className="text-brand"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Image
                                    </span>
                                  </div>
                                ) : this.parseFileNameFromURL(
                                    item.attachment
                                  ).endsWith(".mp4") ? (
                                  <div>
                                    <img
                                      src={require("../../../assets/images/assignment/MP4.png")}
                                      alt="video"
                                      width="20"
                                    />{" "}
                                    <span
                                      className="text-brand"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Video
                                    </span>
                                  </div>
                                ) : this.parseFileNameFromURL(
                                    item.attachment
                                  ).endsWith(".doc") ||
                                  this.parseFileNameFromURL(
                                    item.attachment
                                  ).endsWith(".docx") ? (
                                  <div>
                                    <img
                                      src={require("../../../assets/images/assignment/DOC.png")}
                                      alt="document"
                                      width="20"
                                    />{" "}
                                    <span
                                      className="text-brand"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Document
                                    </span>
                                  </div>
                                ) : this.parseFileNameFromURL(
                                    item.attachment
                                  ).endsWith(".ppt") ||
                                  this.parseFileNameFromURL(
                                    item.attachment
                                  ).endsWith(".pptx") ? (
                                  <div>
                                    <img
                                      src={require("../../../assets/images/assignment/PPT.png")}
                                      alt="ppt"
                                      width="20"
                                    />{" "}
                                    <span
                                      className="text-brand"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      PPT
                                    </span>
                                  </div>
                                ) : (
                                  <div>
                                    <img
                                      src={require("../../../assets/images/assignment/JPG.png")}
                                      alt="jpg"
                                      width="20"
                                    />{" "}
                                    <span
                                      className="text-brand"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Image
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className="mt-2 px-3 py-2"
                              style={{
                                borderRadius: "10px",
                                border: "solid 1px #eeeeee",
                              }}
                            >
                              {item.attachmentType === "youtube" ? (
                                <div>
                                  <a
                                    href={item.attachment}
                                    target="_blank"
                                    className="text-dark"
                                    rel="noopener noreferrer"
                                  >
                                    {item.attachment.substr(0, 35)}...
                                  </a>
                                </div>
                              ) : (
                                <div
                                  className="cursor-pointer"
                                  onClick={() => {
                                    if (this.state.isDownloadingAttachment)
                                      return
                                    this.getAttachment(item.attachment)
                                  }}
                                >
                                  {this.parseFileNameFromURL(
                                    item.attachment,
                                    2
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        )
                      )
                    })}
                </div>
              </div>
            )}

            <div className="d-flex align-items-center justify-content-around mt-4">
              <span></span>
              {moment() >= moment(this.state.startTime) &&
                moment() <=
                  moment(this.state.startTime).add(this.state.duration, "m") &&
                this.state.scheduleType === "ONLINE" &&
                this.state.onlineMeetingProvider === "ZOOM" && (
                  <Button
                    className="bg-brand text-light px-4 py-1"
                    style={{ borderRadius: 12, border: 0 }}
                    data-dismiss="modal"
                    onClick={() => {
                      const onlineMeetingDetails = JSON.parse(
                        this.state.onlineMeetingDetails
                      )
                      if (onlineMeetingDetails.join_url !== undefined) {
                        window.open(onlineMeetingDetails.join_url, "_blank")
                      }
                    }}
                  >
                    Join
                  </Button>
                )}
              <Button
                color="light"
                className="text-muted px-4 py-1"
                style={{ borderRadius: 12, border: 0 }}
                data-dismiss="modal"
                onClick={() => {
                  this.setState({
                    viewScheduleModalOpen: false,
                  })
                }}
              >
                Close
              </Button>
              <span></span>
            </div>
          </>
        )}
      </div>
    </Modal>
  )

  render() {
    return (
      <>
        <AppWrapper
          teacherDashboard
          showTeacherThree
          showTeacherLogo
          screenName="Schedule"
        >
          {this.renderViewScheduleModal()}

          <FullCalendar
            events={this.state.events}
            updateEvents={this.updateEvents}
            forUpdatingEnts={this.state.forUpdatingEnts}
            getSingleScheduleDetails={this.getSingleScheduleDetails}
          />
        </AppWrapper>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getSingleScheduleDetails,
      getFile,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(StudentScheduleScreen)
