/*************************************************
 * RedInk
 * @exports
 * @class AttendanceReportReducer.js
 * @extends Component
 * @author vijayakumar // on 18/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from "../../actions/types"

const INTIAL_STATE = {
  teacherClassDetail: {},
}

export const teacherDashboardReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.TEACHER_DASBOARD_CLASS_DETAIL:
      return {
        ...state,
        teacherClassDetail: action.payload,
      }

    default:
      return state
  }
}
