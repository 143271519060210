/*************************************************
 * RedInk
 * @exports
 * @class Dashboard.js
 * @extends Component
 * @author Sasidharan // on 11/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import Modal, { ModalTransition } from "@atlaskit/modal-dialog"
import { bindActionCreators } from "redux"
import { Modal as BSModal } from "reactstrap"
import { HashLoader } from "react-spinners"
import { DatePicker } from "antd"
import { connect } from "react-redux"
import moment from "moment"
import {
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"

import {
  getAssignmentDetails,
  updateAssignmentMarkComplete,
  deleteStudentAssignmentDetails,
  getAssignmentDetailStatusCount,
  updateMultipleStudentAssignmentMark,
  teacherViewStudentAssignment,
  getMarkReport,
  getAssignmentAssignedDetails,
} from "../../../actions/teacher/AssignmentAction"
import { DANGER_TOAST, VALIDATION_MSG } from "../../../utils/Constants"
import { confirmAlertPopUp } from "../../../utils/Utility"
import { showNotification } from "../../../actions/CommonAction"
import { URL_PATH } from "../../../config/urlPath"

import AppWrapper from "../Wrapper"

import girlPic from "../../../assets/images/girl.png"
import boyPic from "../../../assets/images/boy.png"

class AssignmentDetailsScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: false,
      assignmentId: this.props.location.state.assignmentId,
      isShowLoading: true,
      isEditing: false,
      isNotViewedModalOpen: false,
      disabledButtonMark: true,
      indexDurationToEdit: null,
      invalidMarkIndex: -1,
      statusList: ["AWAITING", "REWORK", "APPROVED"],
      submitStatusList: [
        { name: "Submitted", status: 1 },
        { name: "Not Submitted", status: 0 },
      ],
      // modalDueDate: null,
      assignmentDetails: null,
      assignmentDetailCount: null,
      studentAssignments: [],
      displayStudentAssignments: [],
    }
  }
  togglea = () => {
    this.setState(prevState => ({
      dropdownOpena: !prevState.dropdownOpen,
    }))
  }

  onMouseEntera = () => {
    this.setState({ dropdownOpena: true })
  }

  onMouseLeavea = () => {
    this.setState({ dropdownOpena: false })
  }

  toggleb = () => {
    this.setState(prevState => ({
      dropdownOpenb: !prevState.dropdownOpenb,
    }))
  }

  onMouseEnterb = () => {
    this.setState({ dropdownOpenb: true })
  }

  onMouseLeaveb = () => {
    this.setState({ dropdownOpenb: false })
  }

  togglec = () => {
    this.setState(prevState => ({
      dropdownOpenc: !prevState.dropdownOpenc,
    }))
  }

  onMouseEnterc = () => {
    this.setState({ dropdownOpenc: true })
  }

  onMouseLeavec = () => {
    this.setState({ dropdownOpenc: false })
  }

  componentDidMount() {
    this.setState({ isShowLoading: true }, () => {
      this.getAssignmentDetail()
    })
  }

  getAssignmentDetail = () => {
    this.props.getAssignmentDetails(
      this.state.assignmentId,
      (success, response) => {
        this.setState({ isShowLoading: false })
        if (success) {
          this.setState({
            assignmentDetails: response.assignment,
          })
          this.props.getAssignmentAssignedDetails(
            this.state.assignmentId,
            (success1, response1) => {
              this.setState({
                assignmentDetails: response.assignment,
                assignmentDetailCount: response1.count,
                studentAssignments: response1.assignedStudents,
                displayStudentAssignments: response1.assignedStudents,
              })
            }
          )
        }
      }
    )
  }

  viewAssignment = studentAssignmentId => {
    const id = studentAssignmentId
    const obj = { teacherViewed: 1 }
    this.props.teacherViewStudentAssignment(id, obj, (success, response) => {
      if (success) {
        const studentAssignments = this.state.studentAssignments
        this.setState({
          studentAssignments: studentAssignments.map(e => {
            if (e.id === id) {
              return response.data
            }
            return e
          }),
        })
      }
    })
  }

  downloadMarkReport = async () => {
    await this.props.getMarkReport(
      this.state.assignmentId,
      async (success, response) => {
        if (success) {
          const file = new Blob([response], {
            type: "application/pdf",
          })
          const browserUrl = window.webkitURL || window.URL
          const fileUrl = browserUrl.createObjectURL(file)
          const a = document.createElement("a")
          a.href = fileUrl
          a.download = "mark-report.pdf"
          a.click()
          browserUrl.revokeObjectURL(fileUrl)
        }
      }
    )
  }

  componentWillUnmount() {}

  saveStudentAssignment = () => {
    this.setState({ isShowSaveLoading: true }, () => {
      let array = []
      for (let i = 0; i < this.state.studentAssignments.length; i++) {
        const assignment = this.state.studentAssignments[i]
        let object = {
          id: assignment.id,
          mark: assignment.mark,
          remark: assignment.remark,
          approvalStatus: assignment.approvalStatus,
          durationTo: moment.utc(moment(assignment.durationTo)).endOf("day"),
        }
        array.push(object)
      }
      this.props.updateMultipleStudentAssignmentMark(
        this.state.assignmentId,
        { details: array },
        (success, response) => {
          if (success) {
            this.getAssignmentDetail()
            this.setState({ disabledButtonMark: true })
          }
          this.setState({ isShowSaveLoading: false, isEditing: false })
        }
      )
    })
  }

  updateAssignmentCompleteMark = () => {
    this.setState({ isShowSaveCompleteLoading: true }, () => {
      this.props.updateAssignmentMarkComplete(
        this.state.assignmentId,
        (success, response) => {
          if (success) {
            this.getAssignmentDetail()
          }
          this.setState({ isShowSaveCompleteLoading: false })
        }
      )
    })
  }

  deteleAssignment = ass => {
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT,
      yes => {
        if (yes) {
          this.props.deleteStudentAssignmentDetails(
            ass.id,
            (success, response) => {
              if (success) this.getAssignmentDetail()
            }
          )
        }
      }
    )
  }

  /**
   * handles mark entry
   */
  handleMarkEntry = (event, index) => {
    let numberRegex = /^[a-zA-Z0-9]+$/
    let studentArray = this.state.studentAssignments
    studentArray[index].mark = event.target.value
    this.setState({ studentAssignments: studentArray })
    if (event.target.value !== "AB" && event.target.value !== "NT") {
      if (
        !numberRegex.test(event.target.value) ||
        !(
          event.target.value <=
          parseInt(
            this.state.assignmentDetails &&
              this.state.assignmentDetails.maxMark !== null
              ? this.state.assignmentDetails.maxMark
              : 0
          )
        )
      ) {
        this.setState({ invalidMarkIndex: index })
      } else {
        this.setState({ invalidMarkIndex: -1 })
      }
    } else {
      this.setState({ invalidMarkIndex: -1 })
    }
  }

  render() {
    const notViewedData = this.state.studentAssignments
      ? this.state.studentAssignments.filter(({ viewed }) => !viewed)
      : []
    const fileSubmission =
      this.state.assignmentDetails &&
      this.state.assignmentDetails.fileSubmission === 1

    return (
      <>
        <ModalTransition>
          {/* {this.state.openDurationToEditModal && (
            <Modal
              heading="Update Duration"
              actions={[
                {
                  text: "Done",
                  onClick: () => {
                    const assignmentDurationTo = moment(
                      this.state.assignmentDetails.durationTo
                    )
                    const modalDurationTo = moment(
                      this.state.modalDueDate
                    ).endOf("day")
                    if (assignmentDurationTo <= modalDurationTo) {
                      const toUpdate = this.state.studentAssignments
                      toUpdate[
                        this.state.indexDurationToEdit
                      ].durationTo = modalDurationTo
                      this.setState({
                        openDurationToEditModal: false,
                        studentAssignments: toUpdate,
                      })
                    } else {
                      this.props.showNotification(
                        DANGER_TOAST,
                        VALIDATION_MSG.OOPS,
                        `Please set due date later than or equal to ${assignmentDurationTo.format(
                          "DD MMM YYYY"
                        )}...`
                      )
                    }
                  },
                },
                {
                  text: "Cancel",
                  onClick: () =>
                    this.setState({ openDurationToEditModal: false }),
                },
              ]}
            >
              <div className="form-group  px-2 col-sm-8">
                <label htmlFor="durationTo" className="font-weight-bold">
                  Due Date
                </label>
                <input
                  type="date"
                  className="form-control br-10"
                  name="durationTo"
                  required
                  value={this.state.modalDueDate}
                  onChange={e => {
                    const { value } = e.target
                    this.setState({
                      modalDueDate: value,
                    })
                  }}
                />
              </div>
            </Modal>
          )} */}
        </ModalTransition>
        <ModalTransition>
          {this.state.isNotViewedModalOpen && (
            <Modal
              heading={`Not Viewed (${notViewedData.length})`}
              actions={[
                {
                  text: "Done",
                  onClick: () => this.setState({ isNotViewedModalOpen: false }),
                },
              ]}
            >
              {notViewedData.map(
                ({ studentId, student: { gender, studentName, fullName } }) => (
                  <div
                    key={studentId}
                    className="d-flex align-items-center px-2 py-2"
                  >
                    <img
                      src={gender === "M" ? boyPic : girlPic}
                      alt="Student"
                      className="rounded-pill"
                      style={{ width: 40, height: 40 }}
                    />
                    <div
                      className="text-dark ml-3"
                      style={{ fontSize: "1rem" }}
                    >
                      {studentName || fullName}
                    </div>
                  </div>
                )
              )}
            </Modal>
          )}
        </ModalTransition>
        <>
          <BSModal
            isOpen={this.state.openDurationToEditModal}
            backdropClassName="modal-backdrop-show"
            modalClassName="modal-dialog-shadow-none"
            contentClassName="border-14px"
            centered={true}
            size="md"
          >
            <div className="px-3 pt-2 " style={{ borderTopRightRadius: 14 }}>
              <div style={{ fontSize: 24 }}>Update Duration</div>
            </div>
            <div className="px-2 mt-4">
              <div className="form-group  px-2 col-sm-8 text-brand">
                <label htmlFor="durationTo" className="font-weight-bold">
                  Due Date
                </label>
                <DatePicker
                  className="form-control"
                  placeholder="Select date"
                  format="DD MMM YYYY"
                  value={this.state.modalDueDate}
                  size="large"
                  onSelect={e => {
                    this.setState({ modalDueDate: e })
                  }}
                  disabledDate={current =>
                    current && current < moment().startOf("day")
                  }
                />
              </div>
              <div className="d-flex justify-content-end my-4 mr-2">
                <button
                  className="bg-brand px-3 py-1 text-white"
                  style={{ borderRadius: 5 }}
                  onClick={() => {
                    const assignmentDurationTo = moment(
                      this.state.assignmentDetails.durationTo
                    )
                    const modalDurationTo = this.state.modalDueDate
                    if (assignmentDurationTo <= modalDurationTo) {
                      const toUpdate = this.state.studentAssignments
                      toUpdate[
                        this.state.indexDurationToEdit
                      ].durationTo = modalDurationTo
                      this.setState({
                        openDurationToEditModal: false,
                        studentAssignments: toUpdate,
                      })
                    } else {
                      this.props.showNotification(
                        DANGER_TOAST,
                        VALIDATION_MSG.OOPS,
                        `Please set due date later than or equal to ${assignmentDurationTo.format(
                          "DD MMM YYYY"
                        )}...`
                      )
                    }
                  }}
                >
                  Done
                </button>
                <button
                  className="px-3 py-1 ml-2"
                  style={{ borderRadius: 5, backgroundColor: "#d8d8d8" }}
                  onClick={() => {
                    this.setState({ openDurationToEditModal: false })
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </BSModal>

          <AppWrapper
            teacherDashboard
            showTeacherThree
            showTeacherLogo
            screenName={"Assignment Details"}
          >
            {this.state.isShowLoading ? (
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <HashLoader
                  size={35}
                  color="#084353"
                  sizeUnit="px"
                  loading={this.state.isShowLoading}
                />
              </div>
            ) : (
              <div style={{ height: "76rem" }}>
                <div>
                  <h5 className="text-dark">
                    <b>
                      Class{" "}
                      {this.state.assignmentDetails &&
                        this.state.assignmentDetails.section.className}{" "}
                      -{" "}
                      {this.state.assignmentDetails &&
                        this.state.assignmentDetails.section.sectionName}{" "}
                      |{" "}
                      {this.state.assignmentDetails &&
                        this.state.assignmentDetails.subject.subjectName}{" "}
                      |{" "}
                      {this.state.assignmentDetails &&
                        this.state.assignmentDetails.typeOfWork}
                    </b>
                  </h5>
                </div>
                <div className="row">
                  <div className="col-sm-5">
                    <div className="bg-theme-light card aligntext py-1 mt-2 br-10">
                      <div className="row px-3" style={{ height: "9rem" }}>
                        <div className="text-light col-md-5 mt-2 p-3 ">
                          <h6 className="t-1 text-white">
                            <b>
                              {this.state.assignmentDetails &&
                                this.state.assignmentDetails.title}
                            </b>
                          </h6>
                          <div className="mt-2">
                            {this.state.assignmentDetails &&
                            this.state.assignmentDetails.description.length >
                              50 ? (
                              <>
                                {this.state.assignmentDetails &&
                                  this.state.assignmentDetails.description.slice(
                                    0,
                                    50
                                  )}
                                ...
                                <i
                                  className="fas fa-info-circle cursor-pointer ml-1"
                                  style={{ color: "#04B191", fontSize: "16px" }}
                                  id="assignmentDescription"
                                />
                                <UncontrolledTooltip
                                  placement="right"
                                  target="assignmentDescription"
                                >
                                  {this.state.assignmentDetails &&
                                    this.state.assignmentDetails.description}
                                </UncontrolledTooltip>
                              </>
                            ) : (
                              this.state.assignmentDetails &&
                              this.state.assignmentDetails.description
                            )}
                          </div>
                        </div>
                        <div className="text-light row mt-3 ">
                          <div className="datebox">
                            <p>
                              Posted{" "}
                              {this.state.assignmentDetails &&
                              this.state.assignmentDetails.durationFrom
                                ? moment(
                                    this.state.assignmentDetails.durationFrom
                                  ).format("DD MMM YYYY")
                                : null}
                              {this.state.assignmentDetails &&
                              !this.state.assignmentDetails.durationFrom
                                ? moment(
                                    this.state.assignmentDetails.createdOn
                                  ).format("DD MMM YYYY")
                                : null}
                            </p>
                            <p>
                              Due on{" "}
                              {this.state.assignmentDetails &&
                                moment(
                                  this.state.assignmentDetails.durationTo
                                ).format("DD MMM YYYY")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div
                      className="bg-theme-light card py-1 mt-2 br-10"
                      style={{ height: "9.5rem" }}
                    >
                      <div className="text-light d-flex p-3 justify-content-around">
                        <p>APPROVED</p>
                        <p>REWORK</p>
                        <p>AWAITING</p>
                      </div>
                      <div className="d-flex px-3  justify-content-around">
                        <div className="count-cards2 border border-success pt-2 text-white">
                          <h4 className="mt-1">
                            {this.state.assignmentDetailCount &&
                              this.state.assignmentDetailCount.APPROVED.length}
                          </h4>
                        </div>

                        {/* <UncontrolledDropdown
                    onMouseOver={this.onMouseEntera}
                    onMouseLeave={this.onMouseLeavea}
                    isOpen={this.state.dropdownOpena}
                    toggle={this.togglea}
                  >
                    <DropdownToggle
                      direction={"left"}
                      tag="a"
                      className="nav-link pt-0"
                    >
                      <h4 className="count-cards3 text-white border border-warning pt-2 ">
                        {this.state.assignmentDetailCount && this.state.assignmentDetailCount.APPROVED.length}
                      </h4>
                    </DropdownToggle>
                    <DropdownMenu style={{ width: "35em" }}>
                      <div className="card-title">
                        <h6 className="pt-4 pl-3 text-dark">
                          <strong>APPROVED</strong>
                        </h6>
                      </div>
                      <div className="card-body ">
                        <h6 className="text-dark">
                          You have assigned approved for the following students
                        </h6>
                        {this.state.assignmentDetailCount && this.state.assignmentDetailCount.APPROVED.length && 
                          this.state.assignmentDetailCount.APPROVED.map((item, index) => {
                            return (
                              <div className="d-flex justify-content-around mt-3">
                                <div> {item.studentName}</div>
                                <div> {item.isSubmitted === 0 ? '-  Not Submitted' : '-  Submitted'}</div>
                              </div>
                            );
                        })}
                        
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown> */}
                        <UncontrolledDropdown
                          onMouseOver={this.onMouseEnterb}
                          onMouseLeave={this.onMouseLeaveb}
                          isOpen={this.state.dropdownOpenb}
                          toggle={this.toggleb}
                        >
                          <DropdownToggle
                            className="nav-link pt-0"
                            direction="right"
                            tag="b"
                          >
                            <div className="count-cards3 text-white border border-warning pt-2 ">
                              <h4 className="mt-1">
                                {this.state.assignmentDetailCount &&
                                  this.state.assignmentDetailCount.REWORK
                                    .length}
                              </h4>
                            </div>
                          </DropdownToggle>
                          <DropdownMenu style={{ width: "35em" }}>
                            {/* <DropdownItem header>Settings</DropdownItem> */}
                            <div className="card-title">
                              <h6 className="pt-4 pl-3 text-dark">
                                <strong>REWORK</strong>
                              </h6>
                            </div>
                            <div className="card-body ">
                              {this.state.assignmentDetailCount &&
                              this.state.assignmentDetailCount.REWORK.length >
                                0 ? (
                                <div>
                                  <h6 className="text-dark">
                                    You have assigned rework for the following
                                    students
                                  </h6>
                                  {this.state.assignmentDetailCount &&
                                    this.state.assignmentDetailCount.REWORK
                                      .length &&
                                    this.state.assignmentDetailCount.REWORK.map(
                                      item => (
                                        <div
                                          className="d-flex justify-content-around mt-3"
                                          onClick={() => {
                                            const studentIndex = this.state.studentAssignments.findIndex(
                                              student => {
                                                return (
                                                  student.studentId ===
                                                  item.studentId
                                                )
                                              }
                                            )
                                            this.props.history.push({
                                              pathname:
                                                URL_PATH.TEACHER_ASSIGNMENT_VIEW_DETAILS,
                                              state: {
                                                currentAssignmentIndex: studentIndex,
                                                studentAssignments: this.state
                                                  .displayStudentAssignments,
                                                assignmentId: "Sd",
                                              },
                                            })
                                          }}
                                        >
                                          <div className="d-flex justify-content-around">
                                            <div className="cursor-pointer studentNameOnHover">
                                              {" "}
                                              {item.studentName ||
                                                item.fullName}
                                            </div>
                                          </div>
                                          <div>
                                            {moment(item.durationTo).format(
                                              "DD MMM YYYY"
                                            )}
                                          </div>
                                          <div>
                                            {" "}
                                            {item.isSubmitted === 0
                                              ? "-  Not Submitted"
                                              : "-  Submitted"}
                                          </div>
                                        </div>
                                      )
                                    )}
                                </div>
                              ) : (
                                <div className="text-center">
                                  {" "}
                                  Not yet assigned{" "}
                                </div>
                              )}
                            </div>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                        <div className="count-cards2 border border-danger pt-2 text-white">
                          <h4 className="mt-1">
                            {this.state.assignmentDetailCount &&
                              this.state.assignmentDetailCount.AWAITING.length}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-sm-3 mt-4 px-1"
                    style={{ textAlign: "right" }}
                  >
                    {/* {this.state.assignmentDetails &&
                    this.state.assignmentDetails.isMarkCompleted ? (
                      <div className="btn disabled bg-card mt-2 w-75 br-10 rounded-pill text-center text-light">
                        Mark Completed
                      </div>
                    ) : ( */}
                    <button
                      className="btn bg-card mt-2 w-75 br-10 rounded-pill text-center text-light mr-4"
                      onClick={() => {
                        this.setState({
                          isEditing: !this.state.isEditing,
                          disabledButtonMark: !this.state.disabledButtonMark,
                        })
                        setTimeout(() => {
                          const nextEl = document.getElementsByClassName(
                            "usr-sub-mark-0"
                          )[0]
                          if (nextEl) {
                            nextEl.focus()
                          }
                        }, 500)
                      }}
                    >
                      Add/edit Marks
                    </button>

                    <div>
                      <button
                        className="btn bg-card mt-2 w-75 br-10 rounded-pill text-center text-light mr-4"
                        onClick={this.downloadMarkReport}
                      >
                        Download Mark List
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row mt-4 mr-5 justify-content-end">
                  {this.state.studentAssignments && (
                    <div className="col d-flex align-items-center">
                      <div className="m-0 text-muted d-flex align-items-center">
                        <i className="fas fa-exclamation-triangle text-warning mr-2" />
                        <div
                          className="text-danger mr-1 cursor-pointer"
                          style={{ textDecoration: "underline" }}
                          onClick={() =>
                            this.setState({
                              isNotViewedModalOpen: !this.state
                                .isNotViewedModalOpen,
                            })
                          }
                        >
                          {notViewedData.length > 1
                            ? `${notViewedData.length} students`
                            : `${notViewedData.length} student`}
                        </div>
                        <div>haven't viewed this assignment</div>
                      </div>
                    </div>
                  )}
                  <div className="p-2">
                    <select
                      className="form-control mr-3 mb-2"
                      value={this.state.selectedSubmitStatus}
                      onChange={e => {
                        this.setState(
                          {
                            selectedSubmitStatus: e.target.value,
                            selectedStatus: "",
                          },
                          () => {
                            let array = []
                            if (this.state.selectedSubmitStatus === "ALL")
                              array = this.state.displayStudentAssignments
                            else
                              array = this.state.displayStudentAssignments.filter(
                                item => {
                                  if (this.state.selectedSubmitStatus === "1")
                                    return item.isSubmitted
                                  if (this.state.selectedSubmitStatus === "0")
                                    return !item.isSubmitted
                                  /* else : no filter */
                                }
                              )
                            this.setState({ studentAssignments: array })
                          }
                        )
                      }}
                    >
                      <option value="ALL">Select Status</option>
                      {this.state.submitStatusList &&
                        this.state.submitStatusList.map((item, index) => (
                          <option key={index} value={item.status}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="p-2">
                    <select
                      className="form-control mr-3 mb-2"
                      value={this.state.selectedStatus}
                      onChange={e => {
                        this.setState(
                          {
                            selectedStatus: e.target.value,
                            selectedSubmitStatus: "",
                          },
                          () => {
                            let array = []
                            if (this.state.selectedStatus === "ALL")
                              array = this.state.displayStudentAssignments
                            else
                              array = this.state.displayStudentAssignments.filter(
                                item =>
                                  item.approvalStatus ===
                                  this.state.selectedStatus
                              )
                            this.setState({ studentAssignments: array })
                          }
                        )
                      }}
                    >
                      <option value="ALL">Select Approval Status</option>
                      {this.state.statusList &&
                        this.state.statusList.map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <div
                      className=" bg-theme-light card mt-2"
                      style={{ borderRadius: 16 }}
                    >
                      <div
                        className=" text-light d-flex p-2 pt-2"
                        style={{
                          color: "white",
                          alignItems: "center",
                          height: "4rem",
                        }}
                      >
                        {" "}
                        <div className="col-sm-3">
                          <span className="status text-light">
                            Student Name
                          </span>
                        </div>
                        {fileSubmission && (
                          <div
                            className={`col-sm-${fileSubmission ? "2" : "2"} `}
                          >
                            <span className="ml-3">Status</span>
                          </div>
                        )}
                        {fileSubmission && (
                          <div className="col-sm-1" style={{ padding: "0px" }}>
                            <span>Work</span>
                          </div>
                        )}
                        {/* {!fileSubmission && <div className="col-sm-1"></div>} */}
                        <div className="col-sm-2 text-center">
                          <div>Mark </div>
                          <div>
                            {this.state.assignmentDetails &&
                            this.state.assignmentDetails.maxMark !== "" ? (
                              <span
                                className="text-muted"
                                style={{ fontSize: "10px" }}
                              >
                                Out Of{" "}
                                {this.state.assignmentDetails &&
                                  this.state.assignmentDetails.maxMark}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        {!fileSubmission && <div className="col-sm-1"></div>}
                        {/* <div className="col-sm-1"></div> */}
                        <div className="col-sm-2 text-center">
                          <span>Remark</span>
                        </div>
                        {!fileSubmission && <div className="col-sm-2"></div>}
                        <div className="col-sm-2 text-center">
                          <span>Approval Status</span>
                        </div>
                        {/* <div className="col-sm-1  text-center">
                      <h5 className=" text-light">Delete</h5>
                    </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-2 mb-3 mx-2">
                  {this.state.studentAssignments &&
                    this.state.studentAssignments.map((item, index) => {
                      const isMissedSubmission =
                        !item.isSubmitted &&
                        this.state.assignmentDetails.isMarkCompleted
                      const isLateSubmission = item.onTimeSubmission === "LATE"
                      const { studentName, fullName } = item.student

                      return (
                        <div
                          key={item.student.studentId}
                          className="col-sm-12 mx-0 px-0"
                        >
                          <div className="border  mt-2 br-10 ">
                            <div className="p-1 d-flex row d-flex align-items-center">
                              <div className="row col-sm-3 ml-2 d-flex align-items-center">
                                <img
                                  src={
                                    item.student.gender === "M"
                                      ? boyPic
                                      : girlPic
                                  }
                                  alt="logo"
                                  style={{ width: 35, height: 35 }}
                                />
                                <div
                                  className="text-dark ml-2"
                                  // style={{ fontSize: "1.1rem" }}
                                >
                                  {studentName || fullName}
                                </div>
                              </div>
                              {fileSubmission && (
                                <div
                                  className={`col-sm-${
                                    fileSubmission ? "2" : "2"
                                  } d-flex flex-column ml-1`}
                                >
                                  <div
                                    className="text-dark"
                                    // style={{ fontSize: "1.1rem" }}
                                  >
                                    {item.isSubmitted === 1 ||
                                    item.approvalStatus === "APPROVED"
                                      ? "Submitted"
                                      : "Not Submitted"}
                                  </div>
                                  {isLateSubmission ? (
                                    <div
                                      className="rounded-pill mt-1 px-2 py-05 font-weight-bold ml-1"
                                      style={{
                                        backgroundColor: "#DFACB8",
                                        color: "#CE7E94",
                                        width: "50px",
                                      }}
                                    >
                                      LATE
                                    </div>
                                  ) : null}
                                </div>
                              )}
                              {fileSubmission && (
                                <div className="col-sm-1">
                                  {item.isSubmitted === 1 ? (
                                    item.workFile || item.prevWorkFile ? (
                                      <div
                                        className={`cursor-pointer text-center ${
                                          item.teacherViewed ? "" : "text-green"
                                        }`}
                                        onClick={() => {
                                          // this.viewAssignment(item.id)
                                          const studentIndex = this.state.displayStudentAssignments.findIndex(
                                            student =>
                                              student.studentId ===
                                              item.studentId
                                          )
                                          this.props.history.push({
                                            pathname:
                                              URL_PATH.TEACHER_ASSIGNMENT_VIEW_DETAILS,
                                            state: {
                                              currentAssignmentIndex: studentIndex,
                                              studentAssignments: this.state
                                                .displayStudentAssignments,
                                            },
                                          })
                                        }}
                                      >
                                        <u
                                          style={{ color: "rgb(19, 175, 143)" }}
                                          className={
                                            item.teacherViewed &&
                                            "text-decoration"
                                          }
                                        >
                                          {item.approvalStatus === "REWORK"
                                            ? "View rework"
                                            : "View work"}
                                        </u>
                                      </div>
                                    ) : isMissedSubmission ? (
                                      <div className="text-danger">MISSED</div>
                                    ) : (
                                      <div>NA</div>
                                    )
                                  ) : (
                                    <div>NA</div>
                                  )}
                                </div>
                              )}
                              <div className="col-sm-2">
                                <div className="form-group">
                                  {this.state.assignmentDetails.maxMark ? (
                                    <input
                                      type="number"
                                      disabled={this.state.disabledButtonMark}
                                      className={`form-control text-center usr-sub-mark-${index}`}
                                      id="usr"
                                      value={item.mark || ""}
                                      onChange={e => {
                                        this.handleMarkEntry(e, index)
                                      }}
                                      onKeyDown={e => {
                                        if (
                                          e.key === "Enter" &&
                                          e.target.value
                                        ) {
                                          if (
                                            this.state.invalidMarkIndex !==
                                            index
                                          ) {
                                            const nextEl = document.getElementsByClassName(
                                              `usr-sub-mark-${index + 1}`
                                            )[0]
                                            if (nextEl) {
                                              nextEl.focus()
                                            } else {
                                              if (
                                                this.state.invalidMarkIndex ===
                                                -1
                                              ) {
                                                this.saveStudentAssignment()
                                              }
                                            }
                                          }
                                        }
                                      }}
                                    />
                                  ) : (
                                    <input
                                      className="form-control text-center"
                                      disabled={true}
                                    />
                                  )}
                                  {this.state.invalidMarkIndex === index && (
                                    <small className="text-danger">
                                      Only AB, NT and mark not exceed maximum
                                      mark are allowed.
                                    </small>
                                  )}
                                </div>
                              </div>

                              {!fileSubmission && (
                                <div className="col-sm-1"></div>
                              )}

                              <div className="col-sm-2 text-center">
                                <div className="form-group">
                                  {item.maxMark !== null ? (
                                    <input
                                      type="text"
                                      disabled={this.state.disabledButtonMark}
                                      className={`form-control text-center usr-sub-mark-rem-${index}`}
                                      id="usr"
                                      value={item.remark || ""}
                                      onChange={e => {
                                        let studentArray = this.state
                                          .studentAssignments
                                        studentArray[index].remark =
                                          e.target.value
                                        this.setState({
                                          studentAssignments: studentArray,
                                        })
                                      }}
                                      onKeyDown={e => {
                                        if (
                                          e.key === "Enter" &&
                                          e.target.value
                                        ) {
                                          const nextEl = document.getElementsByClassName(
                                            `usr-sub-mark-rem-${index + 1}`
                                          )[0]
                                          if (nextEl) {
                                            nextEl.focus()
                                          }
                                        }
                                      }}
                                    />
                                  ) : (
                                    <input
                                      className="form-control text-center"
                                      disabled={true}
                                    />
                                  )}
                                </div>
                              </div>
                              {!fileSubmission && (
                                <div className="col-sm-2"></div>
                              )}
                              <div className="col-sm-2 text-center">
                                {!this.state.disabledButtonMark ? (
                                  <h5 className="namebox">
                                    <i
                                      className={`far fa-check-circle ${
                                        item.approvalStatus === "APPROVED"
                                          ? "fa-check-circle-hover"
                                          : ""
                                      }`}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Approved"
                                      onClick={() => {
                                        let studentArray = this.state
                                          .studentAssignments
                                        studentArray[index].approvalStatus =
                                          "APPROVED"
                                        this.setState({
                                          studentAssignments: studentArray,
                                        })
                                      }}
                                    ></i>
                                    &emsp;
                                    <i
                                      className={`far fa-redo ${
                                        item.approvalStatus === "REWORK"
                                          ? "fa-redo-hover"
                                          : ""
                                      }`}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title={
                                        item.approvalStatus === "REWORK"
                                          ? moment(
                                              this.state.studentAssignments[
                                                index
                                              ].durationTo
                                            ).format("DD MMM YYYY HH:mm")
                                          : "REWORK"
                                      }
                                      onClick={() => {
                                        let studentArray = this.state
                                          .studentAssignments
                                        studentArray[index].approvalStatus =
                                          "REWORK"
                                        this.setState({
                                          studentAssignments: studentArray,
                                          indexDurationToEdit: index,
                                          openDurationToEditModal: true,
                                          modalDueDate: moment(
                                            studentArray[index].durationTo
                                          ),
                                          // .format("YYYY-MM-DD"),
                                        })
                                      }}
                                    >
                                      &emsp;
                                    </i>
                                    <i
                                      className={`far fa-clock ${
                                        item.approvalStatus === "AWAITING"
                                          ? "fa-clock-hover"
                                          : ""
                                      }`}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Awaiting"
                                      onClick={() => {
                                        let studentArray = this.state
                                          .studentAssignments
                                        studentArray[index].approvalStatus =
                                          "AWAITING"
                                        this.setState({
                                          studentAssignments: studentArray,
                                        })
                                      }}
                                    ></i>
                                  </h5>
                                ) : (
                                  <div className="center">
                                    {item.approvalStatus === "APPROVED" ? (
                                      <div className="row justify-content-center">
                                        <h5 className="namebox">
                                          <i
                                            className={`far fa-check-circle ${
                                              item.approvalStatus === "APPROVED"
                                                ? "fa-check-circle-hover"
                                                : ""
                                            }`}
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Approved"
                                            onClick={() => {}}
                                          ></i>
                                        </h5>
                                        <div className="text-dark namebox ml-2">
                                          {" "}
                                          APPROVED
                                        </div>
                                      </div>
                                    ) : null}
                                    {item.approvalStatus === "REWORK" ? (
                                      <div className="row justify-content-center">
                                        <h5 className="namebox">
                                          <i
                                            className={`far fa-redo ${
                                              item.approvalStatus === "REWORK"
                                                ? "fa-redo-hover"
                                                : ""
                                            }`}
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title={moment(
                                              item.durationTo
                                            ).format("DD MMM YYYY HH:mm")}
                                            onClick={() => {}}
                                          ></i>
                                        </h5>
                                        <div className="text-dark namebox ml-2">
                                          {" "}
                                          REWORK
                                        </div>
                                      </div>
                                    ) : null}
                                    {item.approvalStatus === "AWAITING" ? (
                                      <div className="row justify-content-center">
                                        <h5 className="namebox">
                                          <i
                                            className={`far fa-clock ${
                                              item.approvalStatus === "AWAITING"
                                                ? "fa-clock-hover"
                                                : ""
                                            }`}
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Awaiting"
                                            onClick={() => {}}
                                          ></i>
                                        </h5>
                                        <div className="text-dark namebox ml-2">
                                          {" "}
                                          AWAITING
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                )}
                              </div>
                              {/* <div className="col-sm-1">
                            <h5 className="namebox text-center">
                              <i
                                className={`far fa-trash fa-clock-hover`}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete Student from assignment"
                                onClick={() => {
                                  this.deteleAssignment(item)
                                }}
                              ></i>
                            </h5>
                          </div> Delete column */}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
                <div className="mt-4 mb-4 d-flex justify-content-end">
                  {this.state.isEditing && (
                    <button
                      className="btn btn-sm text-light btnsave rounded-pill px-4"
                      disabled={this.state.isShowSaveLoading}
                      onClick={() => {
                        if (this.state.invalidMarkIndex === -1)
                          this.saveStudentAssignment()
                      }}
                    >
                      {this.state.isShowSaveLoading ? (
                        <HashLoader
                          size={16}
                          color="#fff"
                          sizeUnit="px"
                          loading={this.state.isShowSaveLoading}
                        />
                      ) : (
                        "Save"
                      )}
                    </button>
                  )}
                  &emsp;
                  {this.state.assignmentDetails &&
                  this.state.assignmentDetails.isMarkCompleted === 1 ? (
                    <button className="btn btn-sm  btncom rounded-pill px-4">
                      Marked as Complete
                    </button>
                  ) : (
                    <button
                      className="btn btn-sm  btncom rounded-pill px-4"
                      disabled={this.state.isShowSaveCompleteLoading}
                      onClick={this.updateAssignmentCompleteMark}
                    >
                      {this.state.isShowSaveCompleteLoading ? (
                        <HashLoader
                          size={16}
                          color="#084353"
                          sizeUnit="px"
                          loading={this.state.isShowSaveCompleteLoading}
                        />
                      ) : (
                        "Mark as Complete"
                      )}
                    </button>
                  )}
                </div>
              </div>
            )}
          </AppWrapper>
        </>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAssignmentDetails,
      getAssignmentDetailStatusCount,
      updateMultipleStudentAssignmentMark,
      updateAssignmentMarkComplete,
      deleteStudentAssignmentDetails,
      teacherViewStudentAssignment,
      getMarkReport,
      showNotification,
      getAssignmentAssignedDetails,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  dispatchToProps
)(AssignmentDetailsScreen)
