/*************************************************
 * RedInk
 * @exports
 * @class index.js
 * @extends Component
 * @author Mahesh // on 02/05/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import AppWrapper from "../Wrapper"
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Button,
  // FormGroup,
  // Input,
  // Table,
} from "reactstrap"
import Modal, {
  ModalTransition,
  // ModalHeader,
  ModalFooter,
  // ModalBody,
  // ModalTitle,
} from "@atlaskit/modal-dialog"
import { myLog, confirmAlertPopUp } from "../../../utils/Utility"
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack"
import {
  getMasterSubjects,
  uploadQuestionBank,
  getChapterTopics,
  getQuestionsAnswers,
  deleteChapters,
  deleteTopics,
  deleteQuestions,
  updateChaptersTopics,
  updateQuestionsAnswers,
} from "../../../actions/founder/QuestionBankActions"
import Skeleton from "react-loading-skeleton"
// import LoadingButton from "../../common/LoadingButton"
import { showNotification } from "../../../actions/CommonAction"
import {
  DANGER_TOAST,
  // SUCCESS_TOAST,
  VALIDATION_MSG,
} from "../../../utils/Constants"
// import moment from "moment"
// import URL from "../../../config"
// import { result } from "lodash-es"
import ExportComponent from "./exportComponent"

const boards = [
  { value: "CBSE", label: "CBSE" },
  { value: "IB", label: "IB" },
  { value: "ICSE", label: "ICSE" },
  { value: "IGSE", label: "IGSE" },
  { value: "IGCSE", label: "IGCSE" },
  { value: "SB", label: "STATE BOARD" },
]

const classes = [
  { value: "JKG", label: "JKG" },
  { value: "SKG", label: "SKG" },
  { value: "LKG", label: "LKG" },
  { value: "UKG", label: "UKG" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
]

const questionTypes = [
  { value: "TRUE_OR_FALSE", label: "True or false" },
  { value: "MULTIPLE_CHOICE", label: "Multiple choice" },
  { value: "MULTIPLE_ANSWER", label: "Multiple answer" },
  { value: "SHORT_ANSWER", label: "Short answer" },
  { value: "LONG_ANSWER", label: "Long answer" },
  { value: "MATCHING", label: "Matching" },
  { value: "FILL_IN_THE_BLANKS", label: "Fill in the blanks" },
  { value: "SEQUENCING", label: "Sequencing" },
]

const defaultQuestion = {
  type: "",
  quizTopicId: "",
  text: "",
  questions: [""],
  answers: [""],
  distractorAnswers: [],
  mark: 2,
  difficulty: "EASY",
  attachment: null,
  sectionId: "",
  subjectId: "",
  subjectChapterId: "",
  subjectTopicId: "",
}

// const tooltipModifiers = {
//   flip: { behaviour: ["top"] },
//   preventOverflow: { boundariesElement: "viewport" },
// }

class FounderQuestionBank extends React.Component {
  constructor(props) {
    super(props)
    this.questionInputElement = React.createRef()
    this.state = {
      isLoading: false,
      classesList: [],
      selectedBoard: null,
      selectedClass: null,
      selectedSubject: null,
      selectedChapter: null,
      selectedTopic: null,
      subjectsPerSection: {},
      subjectsList: [],
      lessonsList: [],
      // selectedTopic: "",
      boards: boards,
      classes: classes,
      questionTypes: questionTypes,
      deletingIndex: null,
      isAddQuestionModalOpen: false,
      savingQuestion: "",
      newQuestion: defaultQuestion,
      currentPage: 1,
      nextPage: null,
      prevPage: null,
      totalPages: null,
      totalRecords: 0,
      pageSize: 10,
      selectedChapters: {},
      selectedTopics: {},
      selectedQuestions: {},
      chapterList: false,
      topicList: false,
      isQuestionModalOpen: false,
      currentChapter: null,
      currentTopic: null,
      selectedChaptersToDelete: [],
      selectedTopicsToDelete: [],
      subjectAllotmentList: [],
      randomVerification: null,
      randomVerificationInput: null,
      isVerificationModalOpen: false,
      isChapterDeleting: false,
      isTopicDeleting: false,
      isQuestionDeleting: false,
      selectedQuestionsToDelete: [],
      isChapterTopicEdit: {},
      chaptersToEdit: [],
      topicsToEdit: [],
      isQuestionEdit: {},
      questionsToEdit: [],
      exportArray: [
        ["Subject", "Board", "Class", "Chapter", "Topic", "Total Questions"],
      ],
      sortedArray: [],
      flag: true,
      exportOpen: false,
      selectExportBoard: "",
    }
  }

  componentDidMount = async () => {
    if (!this.props.masterSubjectsList) await this.props.getMasterSubjects()
    this.loadChapterTopics()
    this.genRandomVerification()
  }

  genRandomVerification() {
    const min = 1000
    const max = 9999
    const rand = min + Math.random() * (max - min)
    this.setState({ randomVerification: rand.toFixed(0) })
  }

  apiCheck = () => {
    if (this.state.boards && this.state.selectedClass) {
      this.setState({ flag: false })
      let data = {
        currentPage: this.state.currentPage,
        pageSize: this.state.totalRecords,
      }
      if (this.state.selectedBoard)
        data.selectedBoard = this.state.selectedBoard
      if (this.state.selectedClass)
        data.selectedClass = this.state.selectedClass
      myLog("total records", data.pageSize)
      this.props.getChapterTopics(data, (success, response) => {
        if (success) {
          this.setState({
            totalRecords: response.pagination.paginationInfo.count,
          })
          const result = response.data.paginatedData.map(Object.values)
          var removeValFrom = [0, 4, 6, 8, 9, 10]
          this.setState({
            sortedArray: this.state.exportArray.concat(
              result.map(ele =>
                ele.filter((v, i) => removeValFrom.indexOf(i) === -1)
              )
            ),
          })
          this.setState({
            sortedArray: this.state.sortedArray.map(element =>
              element.map(values =>
                typeof values === "string" ? values.replace(",", ";") : values
              )
            ),
          })
          myLog("Sorted array", this.state.sortedArray)
        }
      })
      this.loadChapterTopics()
    }
  }

  downloadFile = () => {
    if (this.state.flag === true) {
      this.showErrorNoti("Kindly select Board and class")
    }
    if (this.state.selectedClass && this.state.boards) {
      var CsvString = ""
      this.state.sortedArray.forEach(function(RowItem, RowIndex) {
        RowItem.forEach(function(ColItem, ColIndex) {
          CsvString += ColItem + ","
          // myLog("Row item",RowItem)
        })
        CsvString += "\r\n"
      })
      CsvString = "data:application/csv," + encodeURIComponent(CsvString)
      var x = document.createElement("A")
      x.setAttribute("href", CsvString)
      x.setAttribute("download", "questionBank.csv")
      document.body.appendChild(x)
      x.click()
    }
  }

  loadChapterTopics = () => {
    this.setState({ isLoading: true })
    let data = {
      currentPage: this.state.currentPage,
      pageSize: this.state.pageSize,
    }
    if (this.state.selectedBoard) data.selectedBoard = this.state.selectedBoard
    if (this.state.selectedClass) data.selectedClass = this.state.selectedClass
    if (this.state.selectedSubject)
      data.selectedSubject = this.state.selectedSubject
    if (this.state.selectedChapter)
      data.selectedChapter = this.state.selectedChapter
    if (this.state.selectedTopic) data.selectedTopic = this.state.selectedTopic
    // myLog("load chaptertopic data index.js", data)
    this.props.getChapterTopics(data, (success, response) => {
      if (success) {
        this.setState({
          isLoading: false,
          chapterTopicsList: response.data,
          nextPage: response.pagination.paginationInfo.nextPage,
          totalPages: response.pagination.paginationInfo.pageCount,
          totalRecords: response.pagination.paginationInfo.count,
        })
        if (!this.state.selectedChapter) {
          if (response.data.chaptersList.length > 0) {
            this.setState({ chapterList: response.data.chaptersList })
          } else {
            this.setState({ chapterList: false })
          }
        }
        if (!this.state.selectedTopic) {
          if (response.data.topicsList.length > 0) {
            this.setState({ topicList: response.data.topicsList })
          } else {
            this.setState({ topicList: false })
          }
        }
        if (response.pagination.paginationInfo.pageCount > 0) {
          this.setState({
            nextPage: response.pagination.paginationInfo.nextPage,
          })
        } else {
          this.setState({ nextPage: null })
        }
        if (this.state.currentPage - 1 < 1) {
          this.setState({ prevPage: null })
        } else {
          this.setState({ prevPage: this.state.currentPage - 1 })
        }
      }
    })
  }

  loadQuestionsAnswers = (chapterId, topicId) => {
    let data = {}
    this.setState({
      currentChapter: chapterId,
      currentTopic: topicId,
    })
    // myLog("this.state.currentChapter", this.state.currentChapter)
    if (this.state.selectedBoard) data.selectedBoard = this.state.selectedBoard
    if (this.state.selectedClass) data.selectedClass = this.state.selectedClass
    if (this.state.selectedSubject)
      data.selectedSubject = this.state.selectedSubject
    if (chapterId) data.selectedChapter = chapterId
    if (topicId) data.selectedTopic = topicId
    this.props.getQuestionsAnswers(data, (success, response) => {
      if (success) {
        this.setState({
          isLoading: false,
        })
      }
    })
  }

  deleteChapterTopicsQuestions = () => {
    if (this.state.randomVerificationInput == this.state.randomVerification) {
      let data = {}
      if (this.state.isChapterDeleting) {
        if (this.state.selectedChaptersToDelete.length > 0) {
          data.selectedChapters = this.state.selectedChaptersToDelete
          this.props.deleteChapters(data, (success, response) => {
            this.setState({
              isVerificationModalOpen: false,
              isChapterDeleting: false,
              isTopicDeleting: false,
              isQuestionDeleting: false,
            })
            if (success) {
              this.setState({
                selectedChaptersToDelete: [],
                selectedTopicsToDelete: [],
              })
              this.genRandomVerification()
              this.loadChapterTopics()
              this.renderChaptersTopics()
            } else {
              this.showErrorNoti("Error occurred while deleting records")
            }
          })
        } else {
          this.showErrorNoti("Select atleast one record")
        }
      }
      if (this.state.isTopicDeleting) {
        if (this.state.selectedTopicsToDelete.length > 0) {
          data.selectedTopics = this.state.selectedTopicsToDelete
          this.props.deleteTopics(data, (success, response) => {
            this.setState({
              isVerificationModalOpen: false,
              isChapterDeleting: false,
              isTopicDeleting: false,
              isQuestionDeleting: false,
            })
            if (success) {
              this.setState({
                selectedChaptersToDelete: [],
                selectedTopicsToDelete: [],
              })
              this.genRandomVerification()
              this.loadChapterTopics()
              this.renderChaptersTopics()
            } else {
              this.showErrorNoti("Error occurred while deleting records")
            }
          })
        } else {
          this.showErrorNoti("Select atleast one record")
        }
      }
      if (this.state.isQuestionDeleting) {
        if (this.state.selectedQuestionsToDelete.length > 0) {
          data.selectedQuestions = this.state.selectedQuestionsToDelete
          this.props.deleteQuestions(data, (success, response) => {
            this.setState({
              isVerificationModalOpen: false,
              isChapterDeleting: false,
              isTopicDeleting: false,
              isQuestionDeleting: false,
              isQuestionModalOpen: false,
            })
            if (success) {
              this.setState({
                selectedChaptersToDelete: [],
                selectedTopicsToDelete: [],
              })
              this.genRandomVerification()
              this.loadChapterTopics()
              this.renderChaptersTopics()
            } else {
              this.showErrorNoti("Error occurred while deleting records")
            }
          })
        } else {
          this.showErrorNoti("Select atleast one record")
        }
      }
    } else {
      this.showErrorNoti("Invalid Number")
    }
  }

  updateChapterTopic = () => {
    // myLog("chaptersToEdit", this.state.chaptersToEdit)
    // myLog("topicsToEdit", this.state.topicsToEdit)
    let object = {
      selectedChapters: this.state.chaptersToEdit,
      selectedTopics: this.state.topicsToEdit,
    }
    confirmAlertPopUp(
      "Update Record",
      "Are you sure want to update records?",
      yes => {
        if (yes) {
          this.props.updateChaptersTopics(object, (success, newItem) => {
            if (success) {
              this.setState({
                isChapterTopicEdit: {},
                chaptersToEdit: [],
                topicsToEdit: [],
              })
              this.loadChapterTopics()
              this.renderChaptersTopics()
            }
          })
        }
      }
    )
  }

  updateQuestionsAnswers = () => {
    // // const input = document.querySelector(".input_questions_edit")
    // // this.questionInputElement.change()
    // this.questionInputElement.dispatchEvent(new Event("change", { bubbles: true }))
    // myLog("questionsToEdit", this.state.questionsToEdit)
    let object = {
      questionsToEdit: this.state.questionsToEdit,
    }
    confirmAlertPopUp(
      "Update Record",
      "Are you sure want to update records?",
      yes => {
        if (yes) {
          this.props.updateQuestionsAnswers(object, (success, newItem) => {
            if (success) {
              this.setState({
                isQuestionModalOpen: false,
                isQuestionEdit: {},
                questionsToEdit: [],
              })
              this.loadChapterTopics()
              this.renderChaptersTopics()
            }
          })
        }
      }
    )
  }

  deleteQuestionAnswers = () => {
    if (this.state.randomVerificationInput == this.state.randomVerification) {
      let data = {}
      if (this.state.selectedQuestions.length > 0) {
        data.selectedChapters = this.state.selected
        this.props.deleteChapters(data, (success, response) => {
          this.setState({
            isVerificationModalOpen: false,
          })
          if (success) {
            this.genRandomVerification()
            this.loadChapterTopics()
            this.renderChaptersTopics()
          } else {
            this.showErrorNoti("Error occurred while deleting records")
          }
        })
      } else {
        this.showErrorNoti("Select atleast one record")
      }
    } else {
      this.showErrorNoti("Invalid Number")
    }
  }

  showErrorNoti = message => {
    this.props.showNotification(DANGER_TOAST, VALIDATION_MSG.OOPS, message)
  }

  handleDrop = async files => {
    // files[0]
    let object = {
      file: files[0],
      selectedSubject: this.state.selectedSubject,
      selectedBoard: this.state.selectedBoard,
      selectedClass: this.state.selectedClass,
      selectedChapter: this.state.selectedChapter,
      selectedTopic: this.state.selectedTopic,
    }
    this.props.uploadQuestionBank(object, (success, newItem) => {
      if (success) {
        this.setState({
          selectedTopic: null,
        })
        this.loadChapterTopics()
        this.renderChaptersTopics()
      }
    })
  }

  renderChapterTopicItem = (item, index) => {
    return (
      <tr key={item.id}>
        <td>{item.board ? item.board : "None"}</td>
        <td>{item.class ? item.class : "None"}</td>
        <td>{item.masterSubjectName ? item.masterSubjectName : "None"}</td>
        <td>
          {this.state.isChapterTopicEdit[item.topicId] ? (
            <input
              type="text"
              defaultValue={item.chapterName}
              className="form-control form-control-sm input-border-color"
              placeholder=""
              onChange={e => {
                const findChapterEditIndex = this.state.chaptersToEdit.findIndex(
                  obj => obj.chapterID == item.chapterId
                )
                if (findChapterEditIndex !== -1) {
                  this.state.chaptersToEdit[findChapterEditIndex].chapterText =
                    e.target.value
                } else {
                  this.setState({
                    chaptersToEdit: [
                      {
                        chapterID: item.chapterId,
                        chapterText: e.target.value,
                      },
                      ...this.state.chaptersToEdit,
                    ],
                  })
                }
              }}
            />
          ) : (
            item.chapterName
          )}
        </td>
        <td>
          {this.state.isChapterTopicEdit[item.topicId] ? (
            <input
              type="text"
              defaultValue={item.topicName}
              className="form-control form-control-sm input-border-color"
              placeholder=""
              onChange={e => {
                const findTopicEditIndex = this.state.topicsToEdit.findIndex(
                  obj => obj.topicID == item.topicId
                )
                if (findTopicEditIndex !== -1) {
                  this.state.topicsToEdit[findTopicEditIndex].topicText =
                    e.target.value
                } else {
                  this.setState({
                    topicsToEdit: [
                      { topicID: item.topicId, topicText: e.target.value },
                      ...this.state.topicsToEdit,
                    ],
                  })
                }
              }}
            />
          ) : (
            item.topicName
          )}
        </td>
        <td>
          {item.questionCount != 0 ? (
            <a
              className="link cursor-pointer text-primary"
              onClick={() => {
                // myLog("item.chapterId", item.chapterId)
                // myLog("item.topicId", item.topicId)
                this.setState({
                  isQuestionModalOpen: true,
                  currentChapter: item.chapterId,
                  currentTopic: item.topicId,
                })
                this.loadQuestionsAnswers(item.chapterId, item.topicId)
              }}
            >
              {item.topicQuestionCount} Questions{" "}
            </a>
          ) : (
            "0 Questions"
          )}
        </td>
        <td>
          <span className="col-sm font-weight-bold fs-12 text-primary">
            <label
              onClick={() => {
                this.setState({
                  files: true,
                  selectedChapter: item.chapterId,
                  selectedTopic: item.topicId,
                  selectedBoard: item.board,
                  selectedClass: item.class,
                  selectedSubject: item.masterSubjectId,
                })
              }}
            >
              <i className="fa fa-file-upload text-primary fs-15 cursor-pointer mr-2" />
              <input
                type="file"
                style={{ display: "none" }}
                onChange={e => {
                  this.setState({
                    files: true,
                    selectedChapter: item.chapterId,
                    selectedTopic: item.topicId,
                    selectedBoard: item.board,
                    selectedClass: item.class,
                    selectedSubject: item.masterSubjectId,
                  })
                  this.handleDrop(e.target.files)
                }}
              />
              <u className="cursor-pointer"></u>
            </label>
          </span>
        </td>
        <td>
          <UncontrolledDropdown direction="left">
            <DropdownToggle tag="a" className="nav-link p-0 pr-1">
              <i
                className="fa fa-ellipsis-v text-black"
                style={{ fontSize: "12px", color: "black" }}
              ></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  let isChapterTopicEdit = {}
                  isChapterTopicEdit[item.topicId] = true
                  this.setState({ isChapterTopicEdit })
                }}
              >
                Edit
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  this.setState({
                    isVerificationModalOpen: true,
                    isChapterDeleting: true,
                    isTopicDeleting: false,
                    selectedChaptersToDelete: [item.chapterId],
                  })
                }}
              >
                Delete Chapter
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  this.setState({
                    isVerificationModalOpen: true,
                    isChapterDeleting: false,
                    isTopicDeleting: true,
                    selectedTopicsToDelete: [item.topicId],
                  })
                }}
              >
                Delete Topic
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      </tr>
    )
  }

  renderQuestionItemLoading = opacity => {
    return (
      <tr>
        <td>
          <div
            className="text-muted mx-1"
            style={{ flex: 2, overflow: "hidden" }}
          >
            <Skeleton width={60} />
          </div>
        </td>
        <td>
          <div
            className="text-muted mx-1"
            style={{ flex: 2, overflow: "hidden" }}
          >
            <Skeleton width={60} />
          </div>
        </td>
        <td>
          <div
            className="text-muted mx-1"
            style={{ flex: 2, overflow: "hidden" }}
          >
            <Skeleton width={90} />
          </div>
        </td>
        <td>
          <div
            className="text-muted mx-1"
            style={{ flex: 2, overflow: "hidden" }}
          >
            <Skeleton width={60} />
          </div>
        </td>
        <td>
          <div
            className="text-muted mx-1"
            style={{ flex: 2, overflow: "hidden" }}
          >
            <Skeleton width={35} />
          </div>
        </td>
        <td>
          <div
            className="text-muted mx-1"
            style={{ flex: 2, overflow: "hidden" }}
          >
            <Skeleton width={70} />
          </div>
        </td>
        <td>
          <div
            className="text-muted mx-1"
            style={{ flex: 2, overflow: "hidden" }}
          >
            <Skeleton width={70} />
          </div>
        </td>
        <td>
          <div
            className="text-muted mx-1"
            style={{ flex: 2, overflow: "hidden" }}
          >
            <Skeleton width={50} />
          </div>
        </td>
      </tr>
    )
  }

  renderSelectFilters = () => (
    <div className="d-flex align-items-center justify-content-start mb-4">
      <select
        className="mr-3 mb-2"
        value={this.state.selectedBoard}
        onChange={e => {
          this.state.selectedBoard = e.target.value
          this.loadChapterTopics()
          this.renderChaptersTopics()
        }}
      >
        <option value="">All Boards</option>
        {this.state.boards &&
          this.state.boards.map(option => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
      </select>

      <select
        className="mr-3 mb-2"
        value={this.state.selectedClass}
        onChange={e => {
          // let findClass = this.state.classes.find(item => {
          //   return item.value === e.target.value
          // })
          this.state.selectedClass = e.target.value
          //  myLog (" this.state.selectedClass", this.state.selectedClass)

          this.loadChapterTopics()
          this.renderChaptersTopics()
          this.apiCheck()
        }}
      >
        <option value="">All Classes</option>
        {this.state.classes &&
          this.state.classes.map(option => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
      </select>

      <select
        className="mr-3 mb-2"
        value={this.state.selectedSubject}
        onChange={e => {
          let findSubject = this.props.masterSubjectsList.data.find(item => {
            return item.masterSubjectId === e.target.value
          })
          this.state.selectedSubject = e.target.value
          if (e.target.value == "") {
            this.state.selectedChapter = null
            this.state.selectedTopic = null
            this.state.chapterList = null
            this.state.topicList = null
          }
          this.loadChapterTopics()
          this.renderChaptersTopics()
        }}
      >
        <option value="">All Subjects</option>
        {this.props.masterSubjectsList
          ? this.props.masterSubjectsList.data.map(
              ({ subjectName, masterSubjectId }) => {
                return <option value={masterSubjectId}>{subjectName}</option>
              }
            )
          : ""}
      </select>

      <select
        className="mr-3 mb-2"
        value={this.state.selectedChapter}
        disabled={!this.state.selectedSubject}
        onChange={e => {
          // let findChapter = this.state.chapterList.find(item => {
          //   return item.id === e.target.value
          // })
          this.state.selectedChapter = e.target.value
          this.loadChapterTopics()
          this.renderChaptersTopics()
          this.state.selectedTopic = false
        }}
      >
        <option value="">All Chapters</option>""
        {this.state.chapterList
          ? this.state.chapterList.map(({ name, id }) => {
              return <option value={id}>{name}</option>
            })
          : ""}
      </select>

      <select
        className="mr-3 mb-2"
        value={this.state.selectedTopic}
        onChange={e => {
          this.state.selectedTopic = e.target.value
          this.loadChapterTopics()
          this.renderChaptersTopics()
        }}
      >
        <option value="">Select Topic</option>
        {this.state.topicList
          ? this.state.topicList.map(({ name, id }) => {
              return <option value={id}>{name}</option>
            })
          : ""}
      </select>

      <div style={{ flex: 3 }} />
    </div>
  )

  renderChaptersTopics = () => (
    <>
      <div className="d-flex align-items-center justify-content-between mb-1">
        {this.props.onQuestionSelect && (
          <div
            className="px-3 py-2 d-flex align-items-center"
            style={{
              backgroundColor: "#D2F1EB",
              color: "#199F84",
              borderRadius: 8,
            }}
          >
            <i className="fal fa-box" style={{ fontSize: "1.75rem" }} />
            <span className="ml-3 mr-1">Selected questions: </span>
            <span style={{ fontSize: "1.2rem", fontWeight: "700" }}>
              {Object.keys(this.state.selectedQuestions).length}
            </span>
            <span className="ml-3 mr-1">Selected questions: </span>
            <span style={{ fontSize: "1.2rem", fontWeight: "700" }}>
              {Object.values(this.state.selectedQuestions).reduce(
                (val, item) => val + item.mark,
                0
              )}
            </span>
          </div>
        )}
      </div>

      <div className="table_row row">
        <div className="container col-md-12">
          <div className="table-responsive">
            <table className="table">
              <thead
                className="text-white"
                style={{ backgroundColor: "rgb(7, 62, 77)" }}
              >
                <tr>
                  <th scope="col">Board</th>
                  <th scope="col">Class</th>
                  <th scope="col">Subject</th>
                  <th scope="col" style={{ minWidth: "110px" }}>
                    Chapter
                  </th>
                  <th scope="col">Topic</th>
                  <th scope="col" style={{ width: "110px" }}>
                    Questions
                  </th>
                  <th scope="col" style={{ width: "110px" }}>
                    Upload Q&A
                  </th>
                  <th scope="col" style={{ width: "110px" }}>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.isLoading ? (
                  <>
                    {this.renderQuestionItemLoading(1)}
                    {this.renderQuestionItemLoading(0.68)}
                    {this.renderQuestionItemLoading(0.5)}
                  </>
                ) : (
                  this.props.chapterTopicsList &&
                  this.props.chapterTopicsList.data.paginatedData.map(
                    this.renderChapterTopicItem
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {this.state.totalPages && (
        <div className="row">
          <div className="container col-md-6 text-left">
            <span className="mr-3">
              Page {this.state.currentPage} of{" "}
              {Math.ceil(this.state.totalRecords / this.state.pageSize)}
            </span>
            Per Page:{" "}
            <select
              className="mr-3 mb-2"
              defaultValue={this.state.pageSize}
              onChange={e => {
                this.setState(
                  {
                    pageSize: e.target.value,
                  },
                  () => {
                    this.loadChapterTopics()
                    this.renderChaptersTopics()
                  }
                )
              }}
            >
              <option value="10" key="10">
                10
              </option>
              <option value="15" key="15">
                15
              </option>
              <option value="20" key="20">
                20
              </option>
              <option value="25" key="25">
                25
              </option>
              <option value="30" key="30">
                30
              </option>
            </select>
            Total: {this.state.totalRecords} Records
          </div>
          <div className="container col-md-6 text-right">
            {!this.state.isLoading && (
              <div>
                {this.state.prevPage && (
                  <button
                    className="btn text-brand py-1 px-3 mx-2"
                    style={{
                      borderRadius: 8,
                      backgroundColor: "#C6C6C6",
                      width: 100,
                    }}
                    onClick={() => {
                      this.state.currentPage = this.state.prevPage
                      this.loadChapterTopics()
                      this.renderChaptersTopics()
                    }}
                  >
                    Previous
                  </button>
                )}
                {this.state.nextPage && (
                  <button
                    className="btn text-brand py-1 px-3 mx-2"
                    style={{
                      borderRadius: 8,
                      backgroundColor: "#C6C6C6",
                      width: 100,
                    }}
                    onClick={() => {
                      this.state.currentPage = this.state.nextPage
                      this.loadChapterTopics()
                      this.renderChaptersTopics()
                    }}
                  >
                    Next
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {this.state.totalRecords < 0 && (
        <div className="container col-md-12 text-center">No records found</div>
      )}
    </>
  )

  exportModal = () => {
    return (
      <ModalTransition>
        {this.state.exportOpen && (
          <Modal>
            <div className="modal-header d-flex align-items-center mt-2">
              <h4 className="text-dark">Export Data</h4>
            </div>

            <div className="modal-body">
              <select
                className="mr-3 mb-2"
                value={this.state.selectExportBoard}
                onChange={e => {
                  this.setState({ selectExportBoard: e.target.value })
                  // this.state.selectedBoard = e.target.value
                  this.loadChapterTopics()
                  this.renderChaptersTopics()
                }}
              >
                <option value="">All Boards</option>
                {this.state.boards &&
                  this.state.boards.map(option => (
                    <option value={option.value} key={option.value}>
                      {option.label}
                    </option>
                  ))}
              </select>
            </div>
            <ModalFooter>
              <Button onClick={() => this.setState({ exportOpen: false })}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    )
  }

  closeExportModal = () => {
    this.setState({ exportOpen: false })
  }
  render() {
    // myLog("State values index",this.state.selectedBoard, this.state.selectedClass)
    return (
      <>
        {this.renderVerificationModal()}
        {this.renderQuestionsModal()}
        {/* {this.exportModal()} */}
        <AppWrapper founderDashboard screenName={"Question Bank"}>
          <div className="row">
            <div className="col-xl">
              <h3 className="text-dark">Question Bank</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12">{this.renderSelectFilters()}</div>
            <div className="col-4">
              <h5 className="text-dark mt-2">Chapters & Topics</h5>
            </div>
            <div className="col-8">
              <div className="row align-items-center justify-content-end mr-2">
                {Object.keys(this.state.isChapterTopicEdit).length !== 0 ? (
                  <div className="mb-2 align-self-center">
                    <button
                      type="button"
                      className="btn btn-md btn-info px-4 btn-icon mr-3 box-shadow-none"
                      onClick={() => {
                        this.updateChapterTopic()
                      }}
                    >
                      <i className="fas fa-save mr-2" />
                      Save
                    </button>
                  </div>
                ) : (
                  ""
                )}
                <div
                  className="border-bottom card cursor-pointer mb-2 mr-3"
                  style={{ "border-color": "#298608" }}
                >
                  <span
                    className="px-3 py-1"
                    onClick={() => {
                      // this.downloadFile()
                      this.setState({ exportOpen: true })
                      // this.exportModal()
                    }}
                  >
                    <i className="fal fa-file-download mr-2"></i>
                    Export
                  </span>
                </div>
                <div
                  className="border-bottom card cursor-pointer mb-2 mr-3"
                  style={{ "border-color": "#298608" }}
                >
                  <span className="px-3 py-1">
                    <i className="fal fa-file-upload mr-2"></i>
                    Upload Chapter & Topics
                    <input
                      type="file"
                      className="cursor-pointer"
                      onChange={event => {
                        if (
                          this.state.selectedBoard == null ||
                          this.state.selectedClass == null ||
                          this.state.selectedSubject == null
                        ) {
                          alert("Select Board, Class & Subject")
                        } else {
                          this.setState({ files: true })
                          this.handleDrop(event.target.files)
                        }
                      }}
                      accept=".csv,.xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      style={{
                        opacity: 0.0,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </span>
                </div>
                <div className="border-bottom card cursor-pointer mb-2">
                  <span className="px-3 py-1">
                    <i className="fal fa-file-upload mr-2"></i>
                    Upload Question & Answers
                    <input
                      type="file"
                      className="cursor-pointer"
                      onChange={event => {
                        if (this.state.selectedChapter == null) {
                          alert("Select Chapter & Topic")
                        } else {
                          this.setState({ files: true })
                          this.handleDrop(event.target.files)
                        }
                      }}
                      accept=".csv,.xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      style={{
                        opacity: 0.0,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl">{this.renderChaptersTopics()}</div>
          </div>
        </AppWrapper>
        <ExportComponent
          isOpen={this.state.exportOpen}
          closeModal={this.closeExportModal}
        />
      </>
    )
  }

  renderVerificationModal = () => {
    return (
      <ModalTransition>
        {this.state.isVerificationModalOpen && (
          <Modal autoFocus={false} width="40%" heading="">
            <div className="modal-header d-flex align-items-center mt-2">
              <h3 className="text-dark">Confirm Delete</h3>
              <button
                type="button"
                className="close ml-auto"
                aria-label="Close"
                onClick={() => {
                  this.genRandomVerification()
                  this.setState({
                    isVerificationModalOpen: false,
                    isChapterDeleting: false,
                    isTopicDeleting: false,
                  })
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="form-group">
                <label>{this.state.randomVerification}</label>
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Enter the number above"
                    className="form-control mr-2 rounded"
                    onChange={e =>
                      this.setState({ randomVerificationInput: e.target.value })
                    }
                  />
                </div>
              </div>
              <button
                type="button"
                className="btn bg-success text-white mr-3"
                onClick={() => {
                  this.deleteChapterTopicsQuestions()
                }}
              >
                Confirm Delete
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                disabled={this.state.isShowSaveLoading}
                onClick={() => {
                  this.genRandomVerification()
                  this.setState({
                    isVerificationModalOpen: false,
                    isChapterDeleting: false,
                    isTopicDeleting: false,
                  })
                }}
              >
                Cancel
              </button>
            </div>
          </Modal>
        )}
      </ModalTransition>
    )
  }

  renderQuestionsModal = () => {
    return (
      <ModalTransition>
        {this.state.isQuestionModalOpen && (
          <Modal autoFocus={false} width="80%" heading="">
            <div className="modal-header d-flex align-items-center mt-2">
              <h3 className="text-dark">Questions & Answers</h3>
              <button
                type="button"
                className="close ml-auto"
                aria-label="Close"
                onClick={() =>
                  this.setState({
                    isQuestionModalOpen: false,
                    currentChapter: null,
                    isQuestionEdit: {},
                    questionsToEdit: [],
                    questionsAnswersList: null,
                  })
                }
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="mb-2 align-self-center">
                {Object.keys(this.state.isQuestionEdit).length !== 0 && (
                  <button
                    type="button"
                    className="btn btn-md btn-info px-4 btn-icon mr-3 box-shadow-none"
                    onClick={() => {
                      this.updateQuestionsAnswers()
                    }}
                  >
                    <i className="fas fa-save mr-2" />
                    Save
                  </button>
                )}
              </div>
              <table className="table">
                <thead
                  className="text-white"
                  style={{ backgroundColor: "rgb(7, 62, 77)" }}
                >
                  <tr>
                    <th scope="col">Question</th>
                    <th scope="col">Text</th>
                    <th scope="col">Answer</th>
                    <th scope="col">Options</th>
                    <th scope="col">Solution</th>
                    <th scope="col" style={{ width: "50px" }}>
                      Type
                    </th>
                    <th scope="col" style={{ width: "50px" }}>
                      Marks
                    </th>
                    <th scope="col" style={{ width: "50px" }}>
                      Level
                    </th>
                    <th scope="col" style={{ width: "50px" }}>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.questionsAnswersList &&
                    this.props.questionsAnswersList.data.map(item => (
                      <tr>
                        <td>
                          {this.state.isQuestionEdit[item.id] ? (
                            <textarea
                              rows={8}
                              cols={70}
                              className="form-control"
                              defaultValue={
                                Array.isArray(item.question)
                                  ? item.question.join(" | ")
                                  : item.question
                              }
                              onChange={e => {
                                const findQuestionEditIndex = this.state.questionsToEdit.findIndex(
                                  obj => obj.ID == item.id
                                )
                                let questionArr = e.target.value
                                  .split("|")
                                  .map(function(item) {
                                    return item.trim()
                                  })
                                if (findQuestionEditIndex !== -1) {
                                  this.state.questionsToEdit[
                                    findQuestionEditIndex
                                  ].questionText = questionArr
                                } else {
                                  this.setState({
                                    questionsToEdit: [
                                      {
                                        ID: item.id,
                                        questionText: questionArr,
                                      },
                                      ...this.state.questionsToEdit,
                                    ],
                                  })
                                }
                              }}
                            />
                          ) : Array.isArray(item.question) ? (
                            item.question.join(" | ")
                          ) : (
                            item.question
                          )}
                        </td>
                        <td>
                          {this.state.isQuestionEdit[item.id] ? (
                            <input
                              type="text"
                              defaultValue={item.text}
                              className="form-control form-control-sm input-border-color input_questions_edit"
                              placeholder=""
                              onChange={e => {
                                const findQuestionEditIndex = this.state.questionsToEdit.findIndex(
                                  obj => obj.ID == item.id
                                )
                                if (findQuestionEditIndex !== -1) {
                                  this.state.questionsToEdit[
                                    findQuestionEditIndex
                                  ].textText = e.target.value
                                } else {
                                  this.setState({
                                    questionsToEdit: [
                                      {
                                        ID: item.id,
                                        textText: e.target.value,
                                      },
                                      ...this.state.questionsToEdit,
                                    ],
                                  })
                                }
                              }}
                            />
                          ) : (
                            item.text
                          )}
                        </td>
                        <td>
                          {this.state.isQuestionEdit[item.id] ? (
                            <input
                              type="text"
                              defaultValue={
                                Array.isArray(item.answer)
                                  ? item.answer.join(" | ")
                                  : item.answer
                              }
                              className="form-control form-control-sm input-border-color input_questions_edit"
                              placeholder=""
                              onChange={e => {
                                const findQuestionEditIndex = this.state.questionsToEdit.findIndex(
                                  obj => obj.ID == item.id
                                )
                                let answersArr = e.target.value
                                  .split("|")
                                  .map(function(item) {
                                    return item.trim()
                                  })
                                if (findQuestionEditIndex !== -1) {
                                  this.state.questionsToEdit[
                                    findQuestionEditIndex
                                  ].answerText = answersArr
                                } else {
                                  this.setState({
                                    questionsToEdit: [
                                      {
                                        ID: item.id,
                                        answerText: answersArr,
                                      },
                                      ...this.state.questionsToEdit,
                                    ],
                                  })
                                }
                              }}
                            />
                          ) : Array.isArray(item.answer) ? (
                            item.answer.join(" | ")
                          ) : (
                            item.answer
                          )}
                        </td>
                        <td>
                          {this.state.isQuestionEdit[item.id] ? (
                            <input
                              type="text"
                              defaultValue={
                                Array.isArray(item.distractionAnswers)
                                  ? item.distractionAnswers.join(" | ")
                                  : ""
                              }
                              className="form-control form-control-sm input-border-color input_questions_edit"
                              placeholder=""
                              onChange={e => {
                                const findQuestionEditIndex = this.state.questionsToEdit.findIndex(
                                  obj => obj.ID == item.id
                                )
                                let distractionAnswersArr = e.target.value
                                  .split("|")
                                  .map(function(item) {
                                    return item.trim()
                                  })
                                if (findQuestionEditIndex !== -1) {
                                  this.state.questionsToEdit[
                                    findQuestionEditIndex
                                  ].distractionAnswers = distractionAnswersArr
                                } else {
                                  this.setState({
                                    questionsToEdit: [
                                      {
                                        ID: item.id,
                                        distractionAnswers: distractionAnswersArr,
                                      },
                                      ...this.state.questionsToEdit,
                                    ],
                                  })
                                }
                              }}
                            />
                          ) : Array.isArray(item.distractionAnswers) ? (
                            item.distractionAnswers.join(" | ")
                          ) : (
                            item.distractionAnswers
                          )}
                        </td>
                        <td>
                          {this.state.isQuestionEdit[item.id] ? (
                            <input
                              ref={input => (this.questionInputElement = input)}
                              type="text"
                              defaultValue={item.solution}
                              className="form-control form-control-sm input-border-color input_questions_edit"
                              placeholder=""
                              onChange={e => {
                                const findQuestionEditIndex = this.state.questionsToEdit.findIndex(
                                  obj => obj.ID == item.id
                                )
                                if (findQuestionEditIndex !== -1) {
                                  this.state.questionsToEdit[
                                    findQuestionEditIndex
                                  ].solutionText = e.target.value
                                } else {
                                  this.setState({
                                    questionsToEdit: [
                                      {
                                        ID: item.id,
                                        solutionText: e.target.value,
                                      },
                                      ...this.state.questionsToEdit,
                                    ],
                                  })
                                }
                              }}
                            />
                          ) : (
                            item.solution
                          )}
                        </td>
                        <td>
                          {this.state.isQuestionEdit[item.id] ? (
                            <select
                              className="mr-3 mb-2"
                              defaultValue={item.type}
                              onChange={e => {
                                const findQuestionEditIndex = this.state.questionsToEdit.findIndex(
                                  obj => obj.ID == item.id
                                )
                                if (findQuestionEditIndex !== -1) {
                                  this.state.questionsToEdit[
                                    findQuestionEditIndex
                                  ].typeText = e.target.value
                                } else {
                                  this.setState({
                                    questionsToEdit: [
                                      { ID: item.id, typeText: e.target.value },
                                      ...this.state.questionsToEdit,
                                    ],
                                  })
                                }
                              }}
                            >
                              {this.state.questionTypes &&
                                this.state.questionTypes.map(option => (
                                  <option
                                    value={option.value}
                                    key={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                            </select>
                          ) : (
                            item.type
                          )}
                        </td>
                        <td>
                          {this.state.isQuestionEdit[item.id] ? (
                            <input
                              type="text"
                              defaultValue={item.mark}
                              className="form-control form-control-sm input-border-color input_questions_edit"
                              placeholder=""
                              onChange={e => {
                                const findQuestionEditIndex = this.state.questionsToEdit.findIndex(
                                  obj => obj.ID == item.id
                                )
                                if (findQuestionEditIndex !== -1) {
                                  this.state.questionsToEdit[
                                    findQuestionEditIndex
                                  ].markText = e.target.value
                                } else {
                                  this.setState({
                                    questionsToEdit: [
                                      { ID: item.id, markText: e.target.value },
                                      ...this.state.questionsToEdit,
                                    ],
                                  })
                                }
                              }}
                            />
                          ) : (
                            item.mark
                          )}
                        </td>
                        <td>
                          {this.state.isQuestionEdit[item.id] ? (
                            <select
                              className="mr-3 mb-2"
                              defaultValue={item.difficulty}
                              onChange={e => {
                                const findQuestionEditIndex = this.state.questionsToEdit.findIndex(
                                  obj => obj.ID == item.id
                                )
                                if (findQuestionEditIndex !== -1) {
                                  this.state.questionsToEdit[
                                    findQuestionEditIndex
                                  ].difficultyText = e.target.value
                                } else {
                                  this.setState({
                                    questionsToEdit: [
                                      {
                                        ID: item.id,
                                        difficultyText: e.target.value,
                                      },
                                      ...this.state.questionsToEdit,
                                    ],
                                  })
                                }
                              }}
                            >
                              <option value="" key="">
                                None
                              </option>
                              <option value="EASY" key="EASY">
                                Easy
                              </option>
                              <option value="MEDIUM" key="MEDIUM">
                                Medium
                              </option>
                              <option value="DIFFICULT" key="DIFFICULT">
                                Difficult
                              </option>
                            </select>
                          ) : (
                            item.difficulty
                          )}
                        </td>
                        <td>
                          <UncontrolledDropdown direction="left">
                            <DropdownToggle
                              tag="a"
                              className="nav-link p-0 pr-1"
                            >
                              <i
                                className="fa fa-ellipsis-v text-black"
                                style={{ fontSize: "12px", color: "black" }}
                              ></i>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => {
                                  let isQuestionEdit = {}
                                  isQuestionEdit[item.id] = true
                                  this.setState({ isQuestionEdit })
                                }}
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  this.setState({
                                    isVerificationModalOpen: true,
                                    isQuestionDeleting: true,
                                    selectedQuestionsToDelete: [item.id],
                                  })
                                }}
                              >
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Modal>
        )}
      </ModalTransition>
    )
  }
}

const mapStateToProps = state => {
  return {
    masterSubjectsList: state.founderQuestionBankState.masterSubjectsList,
    chapterTopicsList: state.founderQuestionBankState.chapterTopicsList,
    questionsAnswersList: state.founderQuestionBankState.questionsAnswersList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getMasterSubjects,
      uploadQuestionBank,
      getChapterTopics,
      getQuestionsAnswers,
      showNotification,
      deleteChapters,
      deleteTopics,
      deleteQuestions,
      updateChaptersTopics,
      updateQuestionsAnswers,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(FounderQuestionBank)
