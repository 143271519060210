/*************************************************
 * RedInk
 * @exports
 * @class MarkScreenAction.js
 * @extends Component
 * @author vijayakumar // on 05/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { ACTION_TYPES } from "../types"
import _ from "lodash"

export const saveClassPerformanceAssessmentMarkByTeacherInStore = classPerformanceAssessmentMarkByTeacherDetails => {
  return {
    type: ACTION_TYPES.TEACHER_CLASS_PERFORMANCE_ASSESSMENT_MARK_DETAILS,
    payload: classPerformanceAssessmentMarkByTeacherDetails,
  }
}

export const saveStudentsPerformersListAssessmentByTeacherInStore = studentsPerformerAssessmentObject => {
  return {
    type: ACTION_TYPES.TEACHER_STUDENTS_PERFORMER_ASSESSMENT_OBJECT,
    payload: studentsPerformerAssessmentObject,
  }
}

export const saveGradePerformanceAssessmentByTeacherInStore = gradePerformanceAssessmentObject => {
  return {
    type: ACTION_TYPES.TEACHER_GRADE_PERFORMANCE_ASSESSMENT_OBJECT,
    payload: gradePerformanceAssessmentObject,
  }
}

export const saveSubjectPassFailPercentageGraphInStore = subjectPassFailPercentageGraphObject => {
  return {
    type: ACTION_TYPES.TEACHER_SUBJECT_PASS_FAIL_PERCENTAGE_ASSESSMENT_OBJECT,
    payload: subjectPassFailPercentageGraphObject,
  }
}

export const saveSubjectAverageMarkPercentageGraphInStore = subjectAverageMarkPercentageGraphObject => {
  return {
    type:
      ACTION_TYPES.TEACHER_SUBJECT_AVERAGE_MARK_PERCENTAGE_ASSESSMENT_OBJECT,
    payload: subjectAverageMarkPercentageGraphObject,
  }
}

export const saveSubjectAverageGradePercentageGraphInStore = subjectAverageGradePercentageGraphObject => {
  return {
    type:
      ACTION_TYPES.TEACHER_SUBJECT_AVERAGE_GRADE_PERCENTAGE_ASSESSMENT_OBJECT,
    payload: subjectAverageGradePercentageGraphObject,
  }
}

export const saveSubjectsDetailsInStore = SubjectsDetails => {
  return {
    type: ACTION_TYPES.TEACHER_SUBJECTS_LIST,
    payload: SubjectsDetails,
  }
}

export const getSubjectsList = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_TEACHERS_SUBJECT_LIST,
        body,
        true
      )
      if (response) {
        myLog("====getSubjectsList RESPONSE===::::", response)
        dispatch(saveSubjectsDetailsInStore(response.teacherSubjects))
        callBack(true, response.teacherSubjects)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getSubjectsList ERROR===????", error)
    }
  }
}

export const getTeacherHierarchySubjectDetails = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_TEACHER_HIERARCHY_SUBJECT_DETAILS,
        object,
        true
      )
      if (response) {
        myLog(
          "====getTeacherHierarchySubjectDetails RESPONSE===::::",
          response.hierarchySubjectDetails
        )
        // dispatch(saveSubjectsDetailsInStore(response.teacherSubjectses));
        callBack(true, response.hierarchySubjectDetails)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getTeacherHierarchySubjectDetails ERROR===????", error)
    }
  }
}

export const getClassPerformanceAssessmentMarkByTeacherDetails = (
  object,
  callBack
) => {
  return async dispatch => {
    try {
      let body = {
        id: object.faId,
      }
      myLog(
        "====getClassPerformanceAssessmentMarkByTeacherDetails object===::::",
        object
      )
      const response = await Client.get(
        URL.GET_TEACHERS_CLASS_PERFORMANCE_ASSESSMENT_MARK_OBJECT,
        body,
        true,
        true
      )
      if (response) {
        myLog(
          "====getClassPerformanceAssessmentMarkByTeacherDetails RESPONSE===::::",
          response
        )
        if (response.responseCode === 0) {
          dispatch(
            saveClassPerformanceAssessmentMarkByTeacherInStore(
              response.classPerformanceAssessmentMarkByTeacherDetails
            )
          )
          dispatch(
            saveSubjectAverageMarkPercentageGraphInStore(
              response.classPerformanceAssessmentMarkByTeacherDetails
                .subjectAverageMarkPercentageGraphObject
            )
          )
          dispatch(
            saveSubjectAverageGradePercentageGraphInStore(
              response.classPerformanceAssessmentMarkByTeacherDetails
                .subjectAverageGradeObject
            )
          )
          callBack(true, response)
        } else {
          dispatch(saveClassPerformanceAssessmentMarkByTeacherInStore({}))
          dispatch(saveSubjectAverageMarkPercentageGraphInStore({}))
          dispatch(saveSubjectAverageGradePercentageGraphInStore({}))
          callBack(false)
        }
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog(
        "====getClassPerformanceAssessmentMarkByTeacherDetails ERROR===????",
        error
      )
    }
  }
}

export const getGradePerformanceAssessmentByTeacherDetails = (
  object,
  callBack
) => {
  return async dispatch => {
    try {
      myLog(
        "====getGradePerformanceAssessmentByTeacherDetails object===::::",
        object
      )
      let body = {
        id: object.faId,
      }
      const response = await Client.get(
        URL.GET_CLASS_TEACHERS_GRADE_PERFORMANCE_DISTRIBUTION_OBJECT,
        body,
        true,
        true
      )
      if (response) {
        myLog(
          "====getGradePerformanceAssessmentByTeacherDetails RESPONSE===::::",
          response
        )
        if (response.responseCode === 0) {
          dispatch(
            saveGradePerformanceAssessmentByTeacherInStore(
              response.gradePerformanceAssessmentObject
            )
          )
        } else {
          dispatch(saveGradePerformanceAssessmentByTeacherInStore({}))
        }
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog(
        "====getGradePerformanceAssessmentByTeacherDetails ERROR===????",
        error
      )
    }
  }
}

export const getStudentsPerformersAssessmentListByTeacherDetails = (
  object,
  callBack
) => {
  return async dispatch => {
    try {
      myLog(
        "====getStudentsPerformersAssessmentListByTeacherDetails object===::::",
        object
      )
      let body = {
        id: object.faId,
      }

      const response = await Client.get(
        URL.GET_CLASS_TEACHERS_PERFORMER_OBJECT,
        body,
        true,
        true
      )
      if (response) {
        myLog(
          "====getStudentsPerformersAssessmentListByTeacherDetails RESPONSE===::::",
          response
        )
        if (response.responseCode === 0) {
          let studentsPerformanceObject =
            response.studentsPerformerAssessmentObject
          let absenteesList =
            response.studentsPerformerAssessmentObject.absenteesList
          let absentees = _.mapValues(
            _.groupBy(absenteesList, "studentName"),
            clist => clist.map(car => _.omit(car, "studentName"))
          )
          let array = []
          for (let i in absentees) {
            array.push({
              studentName: i,
              subjects: absentees[i],
            })
          }
          studentsPerformanceObject["absenteesArray"] = array
          dispatch(
            saveStudentsPerformersListAssessmentByTeacherInStore(
              studentsPerformanceObject
            )
          )
        } else {
          dispatch(saveStudentsPerformersListAssessmentByTeacherInStore({}))
        }
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog(
        "====getStudentsPerformersAssessmentListByTeacherDetails ERROR===????",
        error
      )
    }
  }
}

export const getSubjectPassFailPercentageGraphDetails = (object, callBack) => {
  return async dispatch => {
    try {
      let body = {
        id: object.faId,
      }
      const response = await Client.get(
        URL.GET_CLASS_TEACHERS_SUBJECT_PASS_FAIL_PERCENTAGE_GRAPH_OBJECT,
        body,
        true,
        true
      )
      if (response) {
        myLog(
          "====getSubjectPassFailPercentageGraphDetails RESPONSE===::::",
          response
        )
        if (response.responseCode === 0) {
          dispatch(
            saveSubjectPassFailPercentageGraphInStore(
              response.subjectPassFailPercentageGraphObject
            )
          )
        } else {
          dispatch(saveSubjectPassFailPercentageGraphInStore({}))
        }
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getSubjectPassFailPercentageGraphDetails ERROR===????", error)
    }
  }
}

export const getSubjectAverageMarkPercentageGraphDetails = callBack => {
  return async dispatch => {
    try {
      let response = {
        subjectAverageMarkPercentageGraphObject: {
          labels: [
            "ENGLISH",
            "TAMIL",
            "MATHS",
            "SOCIAL",
            "SCIENCE",
            "COMPUTER SCIENCE",
          ],
          backgroundColor: [
            "#3fd6cc",
            "#afa93b",
            "#edab5d",
            "#8b73ef",
            "#3093ee",
            "#ba3c6c",
          ],
          data: [23, 34, 43, 45, 23, 43],
        },
      }
      dispatch(
        saveSubjectAverageMarkPercentageGraphInStore(
          response.subjectAverageMarkPercentageGraphObject
        )
      )
      callBack(true, response)
      //   const response = await Client.get(URL.GET_TEACHERS_CLASS_lIST, body, true)
      //   if (response) {
      //     myLog("====getSubjectAverageMarkPercentageGraphDetails RESPONSE===::::", response)
      //     // dispatch(saveMarkSubjectInStore(response.markStudentList));
      //     callBack(true, response)
      //   } else {
      //     callBack(false)
      //   }
    } catch (error) {
      callBack(false)
      myLog(
        "====getSubjectAverageMarkPercentageGraphDetails ERROR===????",
        error
      )
    }
  }
}

export const getSubjectAverageGradePercentageGraphDetails = callBack => {
  return async dispatch => {
    try {
      //   myLog("====getSubjectAverageGradePercentageGraphDetails object===::::", object)
      //   let body = {
      //     id: object.subjectId
      //   }
      let response = {
        subjectAverageGradePercentageGraphObject: {
          labels: [
            "ENGLISH",
            "TAMIL",
            "MATHS",
            "SOCIAL",
            "SCIENCE",
            "COMPUTER SCIENCE",
          ],
          backgroundColor: [
            "#3fd6cc",
            "#afa93b",
            "#edab5d",
            "#8b73ef",
            "#3093ee",
            "#ba3c6c",
          ],
          data: [23, 34, 43, 45, 23, 43],
        },
      }
      dispatch(
        saveSubjectAverageGradePercentageGraphInStore(
          response.subjectAverageGradePercentageGraphObject
        )
      )
      callBack(true, response)
      //   const response = await Client.get(URL.GET_TEACHERS_CLASS_lIST, body, true)
      //   if (response) {
      //     myLog("====getSubjectAverageGradePercentageGraphDetails RESPONSE===::::", response)
      //     // dispatch(saveGradeSubjectInStore(response.GradeStudentList));
      //     callBack(true, response)
      //   } else {
      //     callBack(false)
      //   }
    } catch (error) {
      callBack(false)
      myLog(
        "====getSubjectAverageGradePercentageGraphDetails ERROR===????",
        error
      )
    }
  }
}
