/*************************************************
 * RedInk
 * @exports
 * @class ClassDetailsAction.js
 * @extends Component
 * @author vijayakumar // on 05/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { showNotification } from "../CommonAction"
import { ACTION_TYPES } from "../types"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import {
  SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"
import moment from "moment"
import _ from "lodash"

export const saveAcademicYearDetailsInStore = AcademicYearDetail => {
  return {
    type: ACTION_TYPES.ADMIN_ACADEMIC_YEAR_LIST,
    payload: AcademicYearDetail,
  }
}

export const saveOnBoardingAcademicYearDetailsInStore = AcademicYearDetail => {
  return {
    type: ACTION_TYPES.ADMIN_ONBOARDING_ACADEMIC_YEAR_LIST,
    payload: AcademicYearDetail,
  }
}

export const getAcademicYearDetails = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_ACADEMIC_YEAR_DETAILS,
        null,
        true
      )
      if (response) {
        myLog("====getAcademicYearDetails RESPONSE===::::", response)
        let academicArray = response.academicYears
        let onboardingArray = response.academicYears
        let allocationArray = []
        if (academicArray && academicArray.length > 0) {
          for (let i = 0; i < onboardingArray.length; i++) {
            let array = []
            array.push(moment(onboardingArray[i].academicFrom))
            array.push(moment(onboardingArray[i].academicTo))
            onboardingArray[i].duration = array
          }
          for (let i = 0; i < academicArray.length; i++) {
            if (
              academicArray[i].allocationDetails &&
              academicArray[i].allocationDetails.length > 0
            ) {
              for (
                let j = 0;
                j < academicArray[i].allocationDetails.length;
                j++
              ) {
                academicArray[i].allocationDetails[j]["academicId"] =
                  academicArray[i].academicId
                academicArray[i].allocationDetails[j]["academicName"] =
                  academicArray[i].academicName
                academicArray[i].allocationDetails[j]["academicShortName"] =
                  academicArray[i].academicShortName
                academicArray[i].allocationDetails[j]["academicTo"] =
                  academicArray[i].academicTo
                academicArray[i].allocationDetails[j]["academicFrom"] =
                  academicArray[i].academicFrom
                let changeMaxMarkArray =
                  academicArray[i].allocationDetails[j].changeMaxMark
                let object = _.groupBy(changeMaxMarkArray, "termName")

                let termMarkArray = []
                let totalMark = 0
                for (let i in object) {
                  let mark = 0
                  for (let k = 0; k < object[i].length; k++) {
                    mark = mark + object[i][k].maxMark
                  }
                  let termObject = {
                    maxMark: mark,
                    termName: i,
                  }
                  totalMark = totalMark + mark
                  termMarkArray.push(termObject)
                }
                let totalTermMarkObject = {
                  maxMark: totalMark,
                  termName: "Total",
                }
                termMarkArray.push(totalTermMarkObject)
                academicArray[i].allocationDetails[
                  j
                ].termMarkArray = termMarkArray
                allocationArray.push(academicArray[i].allocationDetails[j])
              }
            } else {
              let object = {
                detailId: "",
                academicTo: academicArray[i].academicTo,
                academicFrom: academicArray[i].academicFrom,
                resultCondition: "",
                sections: [],
                changeMaxMark: [],
                academicId: academicArray[i].academicId,
                academicName: academicArray[i].academicName,
                academicShortName: academicArray[i].academicShortName,
              }
              allocationArray.push(object)
            }
          }
        }
        myLog(
          "====getTermDetails RESPONSE===:::: allocationArray",
          allocationArray
        )
        dispatch(saveAcademicYearDetailsInStore(allocationArray))
        dispatch(saveOnBoardingAcademicYearDetailsInStore(onboardingArray))
        callBack(true, response.academicYears)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getAcademicYearDetails ERROR===????", error)
    }
  }
}

export const saveAcademicYearDetails = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      myLog("====saveAcademicYearDetails body===::::", body)
      const response = await Client.post(
        URL.SAVE_ACADEMIC_YEAR_DETAILS,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====saveAcademicYearDetails RESPONSE===::::", response)
        dispatch(getAcademicYearDetails(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveAcademicYearDetails ERROR===????", error)
    }
  }
}

export const updateAcademicYearDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.post(
        URL.UPDATE_ACADEMIC_YEAR_DETAILS,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====updateAcademicYearDetails RESPONSE===::::", response)
        dispatch(getAcademicYearDetails(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====updateAcademicYearDetails ERROR===????", error)
    }
  }
}

export const deleteAcademicYearDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.DELETE_ACADEMIC_YEAR_DETAILS,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteAcademicYearDetails RESPONSE===::::", response)
        dispatch(getAcademicYearDetails(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteAcademicYearDetails ERROR===????", error)
    }
  }
}

export const deleteDetailAcademicYearDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.DELETE_DETAILS_ACADEMIC_YEAR,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteDetailAcademicYearDetails RESPONSE===::::", response)
        dispatch(getAcademicYearDetails(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteDetailAcademicYearDetails ERROR===????", error)
    }
  }
}

export const getMaxMarksList = callback => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_MAX_MARKS_LIST, {}, true)
      if (response.responseCode === 0) {
        myLog("====getMaxMarksList RESPONSE===::::", response)
        callback(response)
      } else {
        callback()
      }
    } catch (error) {
      callback(false)
      myLog("====getMaxMarksList ERROR===????", error)
    }
  }
}
