import React, { useState } from "react"
import { Collapse, Modal } from "reactstrap"
import { HashLoader } from "react-spinners"

import { createReportCardTemplates } from "../../../../actions/admin/ReportCardActions"
import useConditions from "../useConditions"

import { ReactComponent as Close } from "../../../../assets/images/close.svg"
import { ReactComponent as Arrow } from "../../../../assets/images/arrow.svg"
import Template from "../Template"
import CheckBox from "./CheckBox"

function CollapseWrapper({
  title,
  selected,
  children,
  setSelected,
  topCont = null,
  arrowCls = "",
  needCollapse = true,
}) {
  return (
    <div className="report-edit-modal-card py-4 px-4 mb-4">
      <div
        className="d-flex align-items-center cursor-pointer"
        onClick={() => setSelected(pr => (pr === title ? "" : title))}
      >
        {topCont || <h3 className="mb-0 fs-17 fw-600">{title}</h3>}

        <Arrow
          className={`ml-auto ${arrowCls} ${selected === title ? "rotate-Z-180" : ""
            }`}
        />
      </div>

      {needCollapse && (
        <Collapse isOpen={selected === title}>
          <div className="pt-4">{children}</div>
        </Collapse>
      )}
    </div>
  )
}

function EditTemplate({ data, isOpen, classList = [], updateShowEdit }) {
  const {
    marks,
    terms,
    isLoading,
    conditions,
    schoolDetails,
    // applyToAllOther,
    onChange,
    updateAllSigns,
    // setApplyToAllOther,
    updateSelectedTerm,
    onEditReportCardTitle,
    updateSelectedAssesments,
  } = useConditions(data.id)

  const [isSubmiting, setIsSubmiting] = useState(false)
  const [selected, setSelected] = useState("")

  const onSave = () => {
    setIsSubmiting(true)
    const {
      selectedAssessments: assList,
      selectedTerms,
      gradeDetail,
      ...rest
    } = conditions
    const selectedAssessments = []

    terms.forEach(t => {
      t.assementList.forEach(a => {
        if (assList.includes(a.assessmentName)) {
          selectedAssessments.push({
            termId: t.termId,
            assessmentId: a.assessmentId,
          })
        }
      })
    })

    createReportCardTemplates(
      {
        classId: data.id,
        displayConfigItems: {
          ...rest,
          selectedAssessments,
        },
      },
      success => {
        if (success) {
          updateShowEdit(false, {})
        }
      }
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      backdropClassName="modal-backdrop-show"
      modalClassName="modal-dialog-shadow-none report-card-modal-full-wrapper"
      contentClassName="report-modal-wrapper p-4"
      centered
      size="xl"
    >
      <div className="d-flex align-items-center">
        <h1 className="report-modal-wrapper-h1 mb-0 fw-600">
          Class {data.className} report card template
        </h1>

        <Close
          className="report-modal-close-icon ml-auto"
          onClick={() => updateShowEdit(false, {})}
        />
      </div>

      {isLoading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "400px" }}
        >
          <HashLoader size={30} color="#084353" sizeUnit="px" loading />
        </div>
      ) : (
        <div className="report-edit-modal-layout">
          <div className="px-4 mt-4 report-edit-modal-left-layout">
            <CollapseWrapper
              title="Report Card Title"
              selected={selected}
              setSelected={setSelected}
            >
              <label htmlFor="" className="fs-13 clr-929392">
                Report Card Title
              </label>

              <input
                type="text"
                className="fs-15 border-C4C4C4 px-2 py-1"
                value={conditions.reportCardTitle}
                onChange={onEditReportCardTitle}
              />
            </CollapseWrapper>

            {/* <CollapseWrapper
              title="Student Details"
              selected={selected}
              setSelected={setSelected}
            >
              <div className="grid-col-2 gap-1rem">
                <CheckBox
                  title="Student Name"
                  checked={conditions.displayStudentName}
                  onChange={() => onChange("displayStudentName")}
                />
                <CheckBox
                  title="Class"
                  checked={conditions.displaySectionName}
                  onChange={() => onChange("displaySectionName")}
                />
                <CheckBox
                  title="Teacher Name"
                  checked={conditions.displayTeacherName}
                  onChange={() => onChange("displayTeacherName")}
                />
                <CheckBox
                  title="Roll Number"
                  checked={conditions.displayRollNo}
                  onChange={() => onChange("displayRollNo")}
                />
                <CheckBox
                  title="DOB"
                  checked={conditions.displayDOB}
                  onChange={() => onChange("displayDOB")}
                />
              </div>
            </CollapseWrapper> */}

            <CollapseWrapper
              title="Scholastic Area"
              selected={selected}
              setSelected={setSelected}
            >
              <div className="report-edit-modal-card-scholastic-area custom-scrollbars">
                {terms.map(t => (
                  <div key={t.termId} className="mb-4">
                    <CheckBox
                      title={t.termName}
                      checked={conditions?.selectedTerms?.includes(t.termName)}
                      lableCls="fs-13 fw-500"
                      onChange={() =>
                        updateSelectedTerm(
                          t.termName,
                          t?.assementList?.every(
                            asL => asL.isAnalyticsGenerated === true
                          )
                        )
                      }
                    // disabled={
                    //   !t?.assementList?.every(
                    //     asL => asL.isAnalyticsGenerated === true
                    //   ) && !conditions?.selectedTerms?.includes(t.termName)
                    // }
                    />

                    <div className="grid-col-2 gap-8px my-2 pl-2rem">
                      {t.assementList.map(c => (
                        <CheckBox
                          key={c.assessmentId}
                          title={c.assessmentShortName}
                          checked={conditions?.selectedAssessments?.includes(
                            c.assessmentName
                          )}
                          onChange={() =>
                            updateSelectedAssesments(
                              t.termName,
                              c.assessmentName,
                              c.isAnalyticsGenerated
                            )
                          }
                        // disabled={!c.isAnalyticsGenerated}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>

              <div className="border-t-56565617"></div>

              <div className="my-3 fs-13 clr-929392">Display</div>

              <div className="grid-col-2 gap-1rem pl-2rem">
                <CheckBox
                  title="Score"
                  checked={conditions.displayScholasticScore}
                  onChange={() => onChange("displayScholasticScore")}
                />
                <CheckBox
                  title="Grade"
                  checked={conditions.displayScholasticGrade}
                  onChange={() => onChange("displayScholasticGrade")}
                />
                <CheckBox
                  title="Subject Remarks"
                  checked={conditions.displayScholasticSubjectRemarks}
                  onChange={() => onChange("displayScholasticSubjectRemarks")}
                  disabled={conditions.selectedAssessments.length > 1}
                />
                <CheckBox
                  title="Rank"
                  checked={conditions.displayStudentRank}
                  onChange={() => onChange("displayStudentRank")}
                />
              </div>
            </CollapseWrapper>

            <CollapseWrapper
              title="Remarks"
              arrowCls="d-none"
              selected={selected}
              setSelected={setSelected}
              needCollapse={false}
              topCont={
                <CheckBox
                  title="Remarks"
                  lableCls="fs-17 fw-600"
                  checked={conditions.displayOverallRemarks}
                  onChange={() => onChange("displayOverallRemarks")}
                />
              }
            ></CollapseWrapper>

            <CollapseWrapper
              title="Signature"
              selected={selected}
              setSelected={setSelected}
              topCont={
                <CheckBox
                  title="Signature"
                  lableCls="fs-17 fw-600"
                  checked={
                    conditions.displayClassTeacherSignature &&
                    conditions.displayParentsSignature &&
                    conditions.displayPrincipalSignature
                  }
                  onChange={updateAllSigns}
                />
              }
            >
              <div
                className="justify-content-center gap-1rem"
                style={{ display: "grid" }}
              >
                <CheckBox
                  title="Class Teacher Signature"
                  checked={conditions.displayClassTeacherSignature}
                  onChange={() => onChange("displayClassTeacherSignature")}
                />
                <CheckBox
                  title="Parents Signature"
                  checked={conditions.displayParentsSignature}
                  onChange={() => onChange("displayParentsSignature")}
                />
                <CheckBox
                  title="Principal Signature"
                  checked={conditions.displayPrincipalSignature}
                  onChange={() => onChange("displayPrincipalSignature")}
                />
              </div>
            </CollapseWrapper>
          </div>

          <div>
            <div className="report-preview-modal-center custom-scrollbars mt-4 px-4 pt-2 bg-E8E8E8">
              <Template
                {...conditions}
                {...schoolDetails}
                marks={marks}
                terms={terms}
                className={data.className}
              />
            </div>

            {/* <div
              className="d-flex align-items-center justify-content-center py-2 bg-e8e8e829"
              style={{ gap: ".5rem" }}
            >
              <input
                id="apply-to-all-other-class"
                type="checkbox"
                className="subject-checkbox"
              />
              <label htmlFor="apply-to-all-other-class" className="mb-0">
                Apply same setting to selected classrooms
              </label>
              <Info width={16} height={16} />
              <div className="select-box-in-edit-report">
                <SelectAssignment
                  isClearable
                  isMulti
                  allowSelectAll
                  name="classes"
                  value={applyToAllOther}
                  styles={style}
                  allOption={allOption}
                  placeholder="None selected"
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={setApplyToAllOther}
                  options={classList.map(c => ({
                    id: c.id,
                    value: c.className,
                    label: c.className,
                  }))}
                  components={{
                    Option,
                    MultiValue,
                    ValueContainer,
                    animatedComponents,
                  }}
                />
              </div>
            </div> */}

            <div className="d-flex align-items-center justify-content-center gap-2rem mt-4">
              <button
                className="report-preview-modal-print-btn d-flex align-items-center justify-content-center px-4 py-2 fs-15 fw-600 bg-bcbdc030 clr-909091 br-8px"
                onClick={() => updateShowEdit(false, {})}
              >
                Cancel
              </button>

              <button
                onClick={onSave}
                disabled={isSubmiting}
                className="report-preview-modal-download-btn d-flex align-items-center justify-content-center px-4 py-2 fs-15 fw-600 bg-011918 text-white br-8px"
              >
                {isSubmiting ? (
                  <HashLoader size={20} color="#fff" sizeUnit="px" loading />
                ) : (
                  "Save Template"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default EditTemplate
