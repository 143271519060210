/*************************************************
 * RedInk
 * @exports
 * @class ClassDetailsAction
 * @extends Component
 * @author vijayakumar // on 05/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { ACTION_TYPES } from "../types"
import { showNotification } from "../CommonAction"
import {
  SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"

export const saveTermDetailsInStore = TermDetail => {
  return {
    type: ACTION_TYPES.ADMIN_TERM_LIST,
    payload: TermDetail,
  }
}

export const getTermDetails = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_TERM_DETAILS, null, true)
      if (response) {
        myLog("====getTermDetails RESPONSE===::::", response)
        let termlist = response.terms
        let allocationArray = []
        if (termlist && termlist.length > 0) {
          for (let i = 0; i < termlist.length; i++) {
            if (
              termlist[i].allocationDetails &&
              termlist[i].allocationDetails.length > 0
            ) {
              for (let j = 0; j < termlist[i].allocationDetails.length; j++) {
                termlist[i].allocationDetails[j]["termId"] = termlist[i].termId
                termlist[i].allocationDetails[j]["termName"] =
                  termlist[i].termName
                termlist[i].allocationDetails[j]["termShortName"] =
                  termlist[i].termShortName
                allocationArray.push(termlist[i].allocationDetails[j])
              }
            } else {
              let object = {
                resultCondition: "",
                termFrom: "",
                termTo: "",
                sections: [],
                assessments: [],
                changeMaxMark: [],
                termId: termlist[i].termId,
                termName: termlist[i].termName,
                termShortName: termlist[i].termShortName,
              }
              allocationArray.push(object)
            }
          }
        }
        myLog(
          "====getTermDetails RESPONSE===:::: allocationArray",
          allocationArray
        )
        dispatch(saveTermDetailsInStore(allocationArray))
        callBack(true, response.terms)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getTermDetails ERROR===????", error)
    }
  }
}

export const saveTermDetails = (body, callBack) => {
  return async (dispatch, getState) => {
    try {
      myLog("====saveTermDetails body===::::", body)
      const response = await Client.post(URL.SAVE_TERM_DETAILS, body, true)
      if (response.responseCode === 0) {
        myLog("====saveTermDetails RESPONSE===::::", response)
        dispatch(getTermDetails(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveTermDetails ERROR===????", error)
    }
  }
}

export const updateTermDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.post(URL.UPDATE_TERM_DETAILS, body, true)
      if (response.responseCode === 0) {
        myLog("====updateTermDetails RESPONSE===::::", response)
        dispatch(getTermDetails(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      myLog("====updateTermDetails ERROR===????", error)
      dispatch(showNotification(DANGER_TOAST, VALIDATION_MSG.OOPS, "error"))
      callBack(false)
    }
  }
}

export const deleteTermDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(URL.DELETE_TERM_DETAILS, body, true)
      if (response.responseCode === 0) {
        myLog("====deleteTermDetails RESPONSE===::::", response)
        dispatch(getTermDetails(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteTermDetails ERROR===????", error)
    }
  }
}

export const deleteDetailTermDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(URL.DELETE_DETAIL_TERM, body, true)
      if (response.responseCode === 0) {
        myLog("====deleteDetailTermDetails RESPONSE===::::", response)
        dispatch(getTermDetails(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteDetailTermDetails ERROR===????", error)
    }
  }
}
