import React from "react"
import { ChooseType, Header, Mathchtype, ParaType } from "./Types"

function Step3({
  min,
  data,
  title,
  instruction,
  totalMarks,
  selectedClass,
  selectedSubject,
  schoolDetails,
  downloadGPPdf,
}) {
  return (
    <>
      <div className="preview-gp bg-EAEAEA p-4">
        <div className="actual-gp-preview p-5 m-2 bg-white br-8px">
          <Header
            schoolName={schoolDetails.schoolName}
            address={`${schoolDetails.city} - ${schoolDetails.zipCode}`}
            exam={title}
            standard={selectedClass}
            time={min}
            subject={selectedSubject.map(a => a.subjectName).join(", ")}
            mark={totalMarks}
            instruction={instruction}
          />

          {data.map((q, i) => {
            let prevNum =
              i === 0
                ? 0
                : data.reduce(
                    (prev, curr, j) =>
                      j < i ? prev + curr.included.length : prev,
                    0
                  )
            if (q.quest === "MCQ") {
              return (
                <ChooseType
                  key={i}
                  sectioNo={i + 1}
                  count={q.count}
                  mark={q.mark}
                  questions={q.included}
                  sectionName={q.displayName}
                  prevNum={prevNum}
                />
              )
            }
            if (q.quest === "Match the following") {
              return (
                <Mathchtype
                  key={i}
                  sectioNo={i + 1}
                  count={q.count}
                  mark={q.mark}
                  questions={q.included}
                  sectionName={q.displayName}
                  prevNum={prevNum}
                />
              )
            }
            return (
              <ParaType
                key={i}
                sectioNo={i + 1}
                count={q.count}
                mark={q.mark}
                questions={q.included}
                sectionName={q.displayName}
                prevNum={prevNum}
              />
            )
          })}
        </div>
      </div>

      <div className="text-center mt-4">
        <button
          className="px-5 py-2 fs-16 fw-500 bg-50AE93 text-white br-8px"
          onClick={downloadGPPdf}
        >
          Download
        </button>
      </div>
    </>
  )
}

export default Step3
