/*************************************************
 * RedInk
 * @exports
 * @class ClassDetailscreen.js
 * @extends Component
 * @author Sasidharan // on 08/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import Select, { components } from "react-select"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  getClassDetails,
  saveClassDetails,
  deleteClassDetails,
  updateClassDetails,
  getClassGroup,
  saveClassGroup,
  deleteClassGroup,
  updateClassGroup,
} from "../../../actions/admin/ClassDetailsAction"
import { myLog, confirmAlertPopUp } from "../../../utils/Utility"
import { HashLoader } from "react-spinners"
import { VALIDATION_MSG } from "../../../utils/Constants"
// import { filter } from "lodash"

export const sections = [
  { value: "A", label: "A" },
  { value: "A1", label: "A1" },
  { value: "A2", label: "A2" },
  { value: "B", label: "B" },
  { value: "C", label: "C" },
  { value: "D", label: "D" },
  { value: "E", label: "E" },
  { value: "F", label: "F" },
  { value: "G", label: "G" },
  { value: "H", label: "H" },
  { value: "I", label: "I" },
  { value: "J", label: "J" },
  { value: "K", label: "K" },
  { value: "L", label: "L" },
  { value: "M", label: "M" },
  { value: "N", label: "N" },
  { value: "O", label: "O" },
  { value: "P", label: "P" },
  { value: "Q", label: "Q" },
  { value: "R", label: "R" },
  { value: "S", label: "S" },
  { value: "T", label: "T" },
  { value: "U", label: "U" },
  { value: "V", label: "V" },
  { value: "W", label: "W" },
  { value: "X", label: "X" },
  { value: "Y", label: "Y" },
  { value: "Z", label: "Z" },
]

export const classes = [
  { value: "JKG", label: "JKG" },
  { value: "SKG", label: "SKG" },
  { value: "LKG", label: "LKG" },
  { value: "UKG", label: "UKG" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
]

const colorStyle = {
  control: styles => ({ ...styles }),
  dropdownIndicator: styles => ({ ...styles, color: "#084353" }),
}

const Option = props => (
  <div>
    <components.Option {...props}>
      <input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
      <label>{props.value} </label>
    </components.Option>
  </div>
)

const MultiValue = props => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
)

class ClassScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTab: "Classes",
      createGroupOpen: false,
      groupAdding: false,
      editingGroupId: null,
      newGroupName: "",
      newGroupClasses: [],
      savedClasses: true,
      selectedClasses: [],
      selectedSections: [],
      selectedClassesAndSections: [],
      saveSelectedClassesAndSections: [],
      isShowLoading: false,
    }
  }

  componentDidMount = () => {
    this.props.getClassDetails({}, (success, response) => {})
    this.props.getClassGroup({}, (success, response) => {})
  }

  addClassesAndSubject = () => {
    myLog(this.state.selectedClasses, this.state.selectedSections)
    if (
      this.state.selectedClasses &&
      this.state.selectedSections &&
      this.state.selectedSections.length > 0
    ) {
      let array = this.state.selectedClassesAndSections
      let string = this.state.selectedClasses.value + "-"
      let alreayAvailableClassindex = -1
      let alreaySavedSelectedClassesAndSectionIndex = -1
      let classes = this.state.saveSelectedClassesAndSections
      let sectionsList = []

      for (let j = 0; j < array.length; j++) {
        let dummyString = array[j].classesAndSections
        let splitString = dummyString.split("-")
        if (splitString[0] === this.state.selectedClasses.value) {
          alreayAvailableClassindex = j
          string = dummyString
        }
      }

      for (let k = 0; k < array.length; k++) {
        let dummyString = classes[k].class
        if (dummyString === this.state.selectedClasses.value) {
          alreaySavedSelectedClassesAndSectionIndex = k
          for (let m = 0; m < classes[k].sections.length; m++) {
            sectionsList.push(classes[k].sections[m])
          }
        }
      }

      for (let i = 0; i < this.state.selectedSections.length; i++) {
        if (sectionsList.length > 0) {
          let error = false
          for (let j = 0; j < sectionsList.length; j++) {
            if (this.state.selectedSections[i].value === sectionsList[j]) {
              error = true
              break
            }
          }
          if (!error) {
            string = string + this.state.selectedSections[i].value + ","
            sectionsList.push(this.state.selectedSections[i].value)
          }
        } else {
          string = string + this.state.selectedSections[i].value + ","
          sectionsList.push(this.state.selectedSections[i].value)
        }
      }

      if (alreaySavedSelectedClassesAndSectionIndex !== -1) {
        classes.splice(alreaySavedSelectedClassesAndSectionIndex, 1)
      }
      let classObject = {
        class: this.state.selectedClasses.value,
      }
      classObject.sections = [...new Set(sectionsList)]
      classes.push(classObject)

      if (alreayAvailableClassindex !== -1) {
        array.splice(alreayAvailableClassindex, 1)
      }
      let object = {
        classesAndSections: string,
      }
      array.push(object)

      this.cancelSubmit()
      this.setState(
        {
          selectedClassesAndSections: array,
          saveSelectedClassesAndSections: classes,
        },
        () => {
          let classObject = {
            classes: this.state.saveSelectedClassesAndSections,
          }
          this.setState({
            isShowLoading: true,
          })
          this.props.saveClassDetails(classObject, success => {
            this.cancelSubmit()
            this.setState({
              isShowLoading: false,
            })
          })
        }
      )
    }
  }

  cancelSubmit = () => {
    this.setState({
      selectedClasses: [],
      selectedSections: [],
      selectedClassesAndSections: [],
      saveSelectedClassesAndSections: [],
    })
  }
  /**
   * Delete a subject
   */
  deleteClassesAndSections = index => {
    if (this.state.selectedClassesAndSections.length > 0) {
      let arrselectedClassesAndSections = this.state.selectedClassesAndSections
      let arrSaveSelectedClassesAndSections = this.state
        .saveSelectedClassesAndSections
      arrSaveSelectedClassesAndSections.splice(index, 1)
      arrselectedClassesAndSections.splice(index, 1)
      this.setState(
        {
          selectedClassesAndSections: arrselectedClassesAndSections,
          saveSelectedClassesAndSections: arrSaveSelectedClassesAndSections,
        },
        () => {
          myLog(this.state.selectedClassesAndSections)
        }
      )
    }
  }

  /**
   * Delete a subject from db
   */
  deleteClassFromDb = item => {
    myLog(item.id)
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT,
      yes => {
        if (yes) {
          this.props.deleteClassDetails({ id: item.id }, () => {})
        }
      },
      {
        buttons: [],
        customUI: ({ onClose }) => {
          return (
            <div
              className="custom"
              style={{
                backgroundColor: "#fff",

                borderBottomRightRadius: "12px",
                borderBottomLeftRadius: "12px",
              }}
            >
              <div
                className="bg-brand text-light d-flex"
                style={{
                  padding: "0.5rem 2rem",
                  borderTopRightRadius: "12px",
                  borderTopLeftRadius: "12px",
                }}
              >
                <div className="mx-auto" style={{ fontSize: "18px" }}>
                  Delete
                </div>
              </div>

              <div
                className="mt-3 "
                style={{
                  padding: "0.5rem 1.5rem",
                }}
              >
                <div className="text-muted" style={{ fontSize: "15px" }}>
                  Are you sure you want to delete?
                </div>
                <div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
                  <button
                    className="bg-brand text-light px-4 py-1 w-25 d-flex align-items-center justify-content-center"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      this.props.deleteClassDetails({ id: item.id }, () => {})
                      onClose()
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-brand text-light px-4 py-1 ml-4 w-25 d-flex align-items-center justify-content-center"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={onClose}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )
        },
      }
    )
  }

  /**
   * saveSubject
   */
  saveClass = cb => {
    myLog(this.state.saveSelectedClassesAndSections)
    if (this.state.saveSelectedClassesAndSections.length > 0) {
      let classObject = {
        classes: this.state.saveSelectedClassesAndSections,
      }
      myLog(classObject)
      this.setState({
        isShowLoading: true,
      })
      this.props.saveClassDetails(classObject, success => {
        this.setState({
          isShowLoading: false,
        })
        myLog("success", success)
        if (success) {
          if (cb) cb()
          // this.cancelSubmit()
          // this.setState({
          //   savedClasses: false,
          //   saveSelectedClassesAndSections: [],
          // })
        }
      })
    } else {
      this.setState({ hasError: true })
      myLog("error", this.state.className)
    }
  }

  render() {
    return (
      <>
        <h3 className="text-brand font-weight-bold mb-5 text-align-right">
          Create Class
        </h3>

        <div className="row mb-auto" style={{ minHeight: "60%" }}>
          <div
            className="col-sm-5 d-flex flex-column pt-3"
            style={{ borderRight: "2px solid #eaeaea" }}
          >
            <label className="text-dark font-weight-bold">Class</label>
            <Select
              placeholder="Select class"
              name="classes"
              isClearable
              value={this.state.selectedClasses}
              options={classes}
              className="mb-3"
              classNamePrefix="select"
              onChange={selectedOption => {
                myLog(selectedOption)
                this.setState({ selectedClasses: selectedOption })
              }}
              styles={colorStyle}
            />
            <label className="text-dark font-weight-bold">Sections</label>
            <Select
              placeholder="Select sections"
              isMulti
              components={{ Option, MultiValue }}
              value={this.state.selectedSections}
              name="sections"
              options={sections.filter(cl => {
                let filterme = true
                const selectedOnes = this.state.saveSelectedClassesAndSections
                const selectedClass = this.state.selectedClasses
                  ? this.state.selectedClasses.value
                  : ""
                for (const classesAndSections of selectedOnes) {
                  if (classesAndSections.class === selectedClass) {
                    const isSame = !classesAndSections.sections.includes(
                      cl.value
                    )
                    filterme = filterme && isSame
                    if (isSame) break
                  }
                  if (!filterme) break
                }
                return filterme
              })}
              className="mb-3"
              classNamePrefix="select"
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              onChange={selectedOption => {
                myLog(selectedOption)
                this.setState({ selectedSections: selectedOption })
              }}
              styles={colorStyle}
            />
            <button
              type="button"
              style={{ borderRadius: 12 }}
              className="btn bg-brand text-light px-5 mx-auto rounded-lg"
              onClick={() => this.addClassesAndSubject()}
            >
              {this.state.isShowLoading ? (
                <HashLoader
                  sizeUnit={"px"}
                  size={16}
                  color={"#ffffff"}
                  loading={this.state.isShowLoading}
                />
              ) : (
                <span>Add</span>
              )}
            </button>
          </div>
          <div className="col-sm-7 pt-3">
            <ul
              className="list-group"
              style={{
                height: "60vh",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              {this.props.classesList &&
                this.props.classesList.map((item, index) => {
                  return (
                    <li
                      className="list-group-item d-flex justify-content-between align-items-center"
                      style={{ backgroundColor: "transparent", border: 0 }}
                    >
                      {item.className}
                      <div>
                        <button
                          type="button"
                          onClick={() => {
                            this.deleteClassFromDb(item)
                          }}
                        >
                          <i className="fas fa-minus-circle text-danger"></i>
                        </button>
                      </div>
                    </li>
                  )
                })}
              {this.state.selectedClassesAndSections.length === 0 &&
                this.state.hasError && (
                  <p className="text-danger">Classes is required.</p>
                )}
            </ul>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <button
            onClick={() => {
              this.setState({
                saveSelectedClassesAndSections: [],
              })
              this.props.onPrevious()
            }}
            className="btn text-light d-flex align-items-centers"
            style={{ backgroundColor: "#04B08F", borderRadius: 12 }}
            disabled={this.state.isShowLoading || this.state.isNextStepLoading}
          >
            <div className="d-flex align-items-center">
              <i className="fas fa-chevron-left" />
              <span className="ml-2">Previous</span>
            </div>
          </button>

          <button
            onClick={() => {
              // this.saveClass(this.props.onNext)
              this.props.onNext()
            }}
            className="btn text-light d-flex align-items-center ml-auto"
            style={{ backgroundColor: "#04B08F", borderRadius: 12 }}
            disabled={this.state.isShowLoading || this.state.isNextStepLoading}
          >
            <span className="mr-2">
              {this.props.isLast ? "Save & Proceed" : "Next Step"}
            </span>
            <i className="fas fa-chevron-right" />
          </button>
        </div>

        {/* <button
          onClick={() => {
            this.saveClass(this.props.onNext)
          }}
          className="btn text-light d-flex align-items-center ml-auto"
          style={{ backgroundColor: "#04B08F", borderRadius: 12 }}
          disabled={this.state.isShowLoading || this.state.isNextStepLoading}
        >
          <span className="mr-2">
            {this.props.isLast ? "Save & Proceed" : "Next Step"}
          </span>
          <i className="fas fa-chevron-right" />
        </button> */}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    subjectList: state.subjectDetailsState.subjectList,
    classesList: state.classDetailsState.classList,
    classGroupsList: state.classDetailsState.classGroupsList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getClassDetails,
      saveClassDetails,
      deleteClassDetails,
      updateClassDetails,
      getClassGroup,
      saveClassGroup,
      deleteClassGroup,
      updateClassGroup,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(ClassScreen)
