/*************************************************
 * RedInk
 * @exports
 * @class Wrapper.js
 * @extends Component
 * @author Sasidharan // on 04/06/2020
 * @copyright © RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { Link } from "react-router-dom"
import { URL_PATH } from "../../config/urlPath"
import PATH_URL from "../../config/index"

import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap"
import profilePic from "../../assets/images/admin.png"
import boyPic from "../../assets/images/boy.png"
import girlPic from "../../assets/images/girl.png"
import redinkLogo from "../../assets/images/redink/redink_logo.png"
import { logout } from "../../utils/Utility"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import store from "store"
import { withRouter } from "react-router"
import {
  getStudentSearchDetails,
  getStudentRemoteLogin,
} from "../../actions/admin/StudentSearchAction"

class Wrapper extends Component {
  /**
   * Creates an instance of HOC.
   * @param {any} props
   * @memberof HOC
   */
  constructor(props) {
    super(props)
    this.state = {
      searchBarItem: "",
      options: [],
      cursor: 0,
      searchBarOpen: false,
    }
  }

  onSearchChange = e => {
    this.setState(
      {
        searchBarItem: e.target.value,
        searchBarOpen: true,
      },
      () => {
        if (this.state.searchBarItem.length >= 3) {
          let object = {
            searchTerm: this.state.searchBarItem,
          }
          this.props.getStudentSearchDetails(object, (success, response) => {
            if (success) {
              this.setState({ options: response.students })
              // myLog("Options",this.state.options)
            }
          })
        }
      }
    )
  }

  handleKeyDown = e => {
    const { cursor, options } = this.state
    if (e.keyCode === 38 && cursor > 0) {
      this.setState(prevState => ({
        cursor: prevState.cursor - 1,
      }))
    } else if (e.keyCode === 40 && cursor < options.length - 1) {
      this.setState(prevState => ({
        cursor: prevState.cursor + 1,
      }))
    }
  }

  render() {
    const hideFewMenusForCoordinatorAccount =
      store.get("userSession") &&
      store.get("userSession").userInfo &&
      store.get("userSession").userInfo.userId ===
        "98527298-a7da-4848-b653-6ab85837d155"
    // myLog('length',typeof(this.state.options))
    return (
      <div className="modern">
        <header className="header fixed-top">
          <nav className="bg-white navbar navbar-expand d-flex justify-content-between">
            <ul className="px-2 navbar-nav site-logo">
              <li
                className="p-1 mr-2 cursor-pointer nav-item show-mdsm d-none"
                onClick={() =>
                  (document.getElementById("overlayNav").style.width = "100%")
                }
              >
                <i className="far fa-bars"></i>
              </li>
              <li className="px-2 nav-item row">
                <div
                  className="mt-2 navbar-brand text-theme font-weight-bolder"
                  style={{ fontSize: "25px" }}
                >
                  {this.props.screenName}
                </div>
              </li>
            </ul>

            <ul className="px-2 navbar-nav site-logo navbar-expand-sm">
              {window.location.pathname === "/admin" && (
                <li className="p-0 searchbar">
                  <div
                    className="SearchBox"
                    style={{ position: "absolute", margintRight: "97vh" }}
                  >
                    <div className="d-flex search">
                      <input
                        type="search"
                        className="form-control"
                        onChange={this.onSearchChange}
                        value={this.state.searchBarItem}
                        placeholder="Search by Student name/ Roll number"
                      />
                      <div
                        className="mr-3 font-weight-bold"
                        style={{
                          marginTop: "6px",
                          cursor: "pointer",
                          visibility:
                            this.state.searchBarItem.length > 0 ? "" : "hidden",
                        }}
                        onClick={() => {
                          this.setState({ searchBarItem: "" })
                        }}
                      >
                        x
                      </div>
                    </div>
                    {this.state.searchBarOpen &&
                      this.state.searchBarItem &&
                      this.state.searchBarItem.length >= 3 && (
                        <ul className="p-0" style={{ listStyleType: "none" }}>
                          {this.state.options.length > 0 ? (
                            this.state.options.map((item, i) => {
                              return (
                                <li
                                  onClick={() => {
                                    this.setState({
                                      searchBarOpen: false,
                                      searchBarItem: "",
                                    })
                                    this.props.history.push({
                                      pathname: URL_PATH.ADMIN_STUDENT_PROFILE,
                                      state: {
                                        studentId: item.studentId,
                                      },
                                    })
                                    // let object = {
                                    //   userId: item.userId,
                                    // }
                                    // this.props.getStudentRemoteLogin(
                                    //   object,
                                    //   (success, response) => {
                                    //     if (success) {
                                    //       if (response.userInfo.role === 0)
                                    //         this.props.history.push(
                                    //           URL_PATH.FOUNDER
                                    //         )
                                    //       if (response.userInfo.role === 1)
                                    //         this.props.history.push(
                                    //           URL_PATH.SUPER_ADMIN
                                    //         )
                                    //       if (response.userInfo.role === 2)
                                    //         this.props.history.push(
                                    //           URL_PATH.ADMIN
                                    //         )
                                    //       if (response.userInfo.role === 3)
                                    //         this.props.history.push(
                                    //           URL_PATH.TEACHER
                                    //         )
                                    //       if (response.userInfo.role === 4)
                                    //         this.props.history.push(
                                    //           URL_PATH.TEACHER
                                    //         )
                                    //       if (response.userInfo.role === 5)
                                    //         this.props.history.push(
                                    //           URL_PATH.PARENT
                                    //         )
                                    //     }
                                    //   }
                                    // )
                                  }}
                                >
                                  <div className="d-flex justify-content-between bg-white">
                                    <div className="p-0 ml-2">
                                      <img
                                        className="mt-2 mr-2 rounded-circle align-self-center"
                                        src={
                                          item.gender === "M" ? boyPic : girlPic
                                        }
                                        alt="Profile"
                                        width={34}
                                      />
                                      <div className="float-right p-1 ml-2 d-flex">
                                        <div>
                                          <h6 className="text-dark align-self-start">
                                            {item.name}
                                          </h6>
                                          <small className="align-self-end">
                                            {item.registerNumber}
                                          </small>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="ml-2 mr-4 align-self-center">
                                      CLASS{" " + item.className}
                                    </div>
                                  </div>
                                </li>
                              )
                            })
                          ) : (
                            <li className="p-0 bg-white">
                              <h6 className="p-4 m-0 text-dark">
                                No students Found!
                              </h6>
                            </li>
                          )}
                        </ul>
                      )}
                  </div>
                </li>
              )}
              {!this.props.showUpgradeAcadic && (
                <li
                  className="px-2 mr-2 nav-item notification-icon"
                  title="Notifications"
                >
                  <div className="nav-link text-dark ">
                    <i className="far fa-bell"></i>
                  </div>
                </li>
              )}
              <li>
                <UncontrolledDropdown>
                  <DropdownToggle tag="a" className="nav-link">
                    <img
                      className="rounded-circle"
                      src={profilePic}
                      alt="Profile"
                      width={32}
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    {!this.props.showUpgradeAcadic && (
                      <>
                        <DropdownItem
                          onClick={() => {
                            this.props.history.push(
                              URL_PATH.ADMIN_CHANGEPASSWORD
                            )
                          }}
                        >
                          Change Password
                        </DropdownItem>
                        <DropdownItem divider />
                      </>
                    )}
                    <DropdownItem onClick={() => logout()}>Logout</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          </nav>
        </header>
        {/* Overlay Navbar for tab and mobile */}
        <div id="overlayNav" className="overlay d-none">
          <span
            className="closebtn"
            onClick={() =>
              (document.getElementById("overlayNav").style.width = "0%")
            }
          >
            &times;
          </span>
          <div className="overlay-content">
            <div className="app-sidebar__inner">
              <ul className="vertical-nav-menu text-light">
                <li
                  // className="mt-4"
                  className={
                    URL_PATH.SUPER_ADMIN === window.location.pathname
                      ? "menu-active"
                      : ""
                  }
                >
                  <Link to={URL_PATH.SUPER_ADMIN}>Dashboard</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Overlay Navbar for tab and mobile ends*/}
        {/* Overlay Navbar for pc starts*/}
        <aside className="aside-left">
          <nav className="menu d-flex flex-column justify-content-between">
            {this.props.adminDashboard && (
              <ul>
                <li>
                  <Link
                    to={URL_PATH.ADMIN}
                    className={`d-flex flex-column justify-content-between`}
                  >
                    <img
                      className="img-fluid"
                      src={redinkLogo}
                      alt="Redink"
                      width="40"
                      height="40"
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    to={URL_PATH.ADMIN}
                    className={
                      URL_PATH.ADMIN === window.location.pathname ||
                      URL_PATH.ADMIN_CHANGEPASSWORD === window.location.pathname
                        ? "menu-active"
                        : ""
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-dashboard"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#bdc7cb"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <circle cx="12" cy="13" r="2" />
                      <line x1="13.45" y1="11.55" x2="15.5" y2="9.5" />
                      <path d="M6.4 20a9 9 0 1 1 11.2 0Z" />
                    </svg>
                    <span>Dashboard</span>
                  </Link>
                </li>

                {store.get("userSession") &&
                  store.get("userSession").userInfo &&
                  store.get("userSession").userInfo.isAttendanceActive && (
                    <li>
                      <Link
                        to={URL_PATH.ADMIN_ATTENDANCE_REPORT}
                        className={
                          URL_PATH.ADMIN_ATTENDANCE_REPORT ===
                          window.location.pathname
                            ? "menu-active"
                            : ""
                        }
                      >
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 46 46"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M27.592 10.0819L32.4814 14.9712L42.2587 5.19253"
                            stroke="#BDC7CB"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.0359 1.01481C6.19995 3.75881 -1.17072 15.5788 1.57328 27.4148C4.31595 39.2508 16.1373 46.6228 27.9733 43.8775C36.1533 41.9828 42.5399 35.5948 44.4359 27.4148C44.4359 26.0655 43.3413 24.9708 41.9919 24.9708H25.3693C22.6693 24.9708 20.4799 22.7815 20.4799 20.0815V2.97081C20.3533 1.76281 19.2719 0.885473 18.0639 1.01214C18.0546 1.01347 18.0453 1.01481 18.0359 1.01481Z"
                            stroke="#BDC7CB"
                            strokeWidth="4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span>Attendance Report</span>
                      </Link>
                    </li>
                  )}

                {store.get("userSession") &&
                  store.get("userSession").userInfo &&
                  store.get("userSession").userInfo.isAcademicActive && (
                    <>
                      <li>
                        <Link
                          to={URL_PATH.ADMIN_MARK_UPLOAD_STATUS}
                          className={
                            URL_PATH.ADMIN_MARK_UPLOAD_STATUS ===
                            window.location.pathname
                              ? "menu-active"
                              : ""
                          }
                        >
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 42 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 22.3333V27.2226C1 29.9213 3.18933 32.1106 5.88933 32.1106H35.2227C37.9227 32.1106 40.1107 29.9213 40.1107 27.2226V22.3333"
                              stroke="#BDC7CB"
                              strokeWidth="3"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M3.44473 0.999949C4.7954 0.999949 5.88873 2.09462 5.88873 3.44395C5.88873 4.79462 4.7954 5.88928 3.44473 5.88928C2.09407 5.88928 1.00073 4.79462 1.00073 3.44395C1.00073 2.09462 2.09407 0.999949 3.44473 0.999949Z"
                              stroke="#BDC7CB"
                              strokeWidth="3"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M20.5561 0.999949C21.9054 0.999949 23.0001 2.09462 23.0001 3.44395C23.0001 4.79462 21.9054 5.88928 20.5561 5.88928C19.2054 5.88928 18.1121 4.79462 18.1121 3.44395C18.1121 2.09462 19.2054 0.999949 20.5561 0.999949Z"
                              stroke="#BDC7CB"
                              strokeWidth="3"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M37.6667 0.999949C39.0173 0.999949 40.1107 2.09462 40.1107 3.44395C40.1107 4.79462 39.0173 5.88928 37.6667 5.88928C36.3173 5.88928 35.2227 4.79462 35.2227 3.44395C35.2227 2.09462 36.3173 0.999949 37.6667 0.999949Z"
                              stroke="#BDC7CB"
                              strokeWidth="3"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <span>Mark Entry Status</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={URL_PATH.ADMIN_PERFORMANCE_REPORT}
                          // className={
                          //   URL_PATH.ADMIN_PERFORMANCE_REPORT ===
                          //     window.location.pathname ||
                          //   URL_PATH.ADMIN_STUDENT_PROFILE ===
                          //     window.location.pathname
                          //     ? "menu-active"
                          //     : ""
                          // }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-chart-donut"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#bdc7cb"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <path d="M10 3.2a9 9 0 1 0 10.8 10.8a1 1 0 0 0 -1 -1h-3.8a4.1 4.1 0 1 1 -5 -5v-4a.9 .9 0 0 0 -1 -.8" />
                            <path d="M15 3.5a9 9 0 0 1 5.5 5.5h-4.5a9 9 0 0 0 -1 -1v-4.5" />
                          </svg>
                          <span>Performance Report</span>
                        </Link>
                      </li>
                      {hideFewMenusForCoordinatorAccount ? null : (
                        <li>
                          <Link
                            to={URL_PATH.ADMIN_REPORT_CARD}
                            className={`d-flex justify-content-center ${
                              URL_PATH.ADMIN_REPORT_CARD ===
                              window.location.pathname
                                ? "menu-active"
                                : ""
                            }`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="28"
                              height="28"
                              viewBox="0 0 24 24"
                              fill="#bdc7cb"
                            >
                              <path d="M11.59,22c-0.27-0.09-0.56-0.08-0.84-0.12c-1.22-0.18-2.4-0.45-3.49-1.07c-1.72-0.98-2.92-2.41-3.67-4.22c-0.75-1.81-0.94-3.7-0.66-5.63c0.33-2.22,1.23-4.17,2.77-5.81C7.1,3.67,8.8,2.7,10.73,2.1c1.89-0.59,3.84-0.77,5.81-0.66c0.36,0.02,0.72,0.02,1.08,0.09c0.27,0.05,0.46,0.28,0.48,0.55c0.02,0.25-0.15,0.5-0.39,0.6c-0.1,0.04-0.21,0.04-0.32,0.03c-1.17-0.1-2.34-0.13-3.51-0.01c-2.6,0.27-4.98,1.09-6.9,2.93C5.23,7.31,4.25,9.38,4.07,11.8c-0.15,1.95,0.22,3.79,1.24,5.47c1.04,1.72,2.55,2.79,4.52,3.22c1.5,0.32,3.01,0.44,4.52,0.12c2.16-0.46,3.76-1.67,4.84-3.58c1.02-1.8,1.36-3.76,1.23-5.81c-0.13-1.99-0.71-3.85-1.92-5.47c-0.16-0.21-0.34-0.41-0.51-0.62c-0.25-0.29-0.25-0.66,0.01-0.9c0.26-0.24,0.63-0.2,0.89,0.09c1.24,1.35,2.01,2.96,2.42,4.73c0.51,2.19,0.47,4.37-0.19,6.53c-0.62,2-1.69,3.69-3.43,4.91c-1.09,0.76-2.3,1.2-3.61,1.4c-0.28,0.04-0.57,0.03-0.84,0.12C12.69,22,12.14,22,11.59,22z" />
                              <path d="M14.06,16.76c-0.22-0.72-0.43-1.45-0.66-2.17c-0.05-0.17-0.05-0.24,0.15-0.27c0.31-0.05,0.51-0.35,0.47-0.65c-0.04-0.32-0.29-0.54-0.62-0.54c-0.08,0-0.16,0-0.24,0.02c-0.16,0.04-0.21-0.03-0.25-0.18c-0.45-1.49-0.9-2.97-1.35-4.45c-0.06-0.21-0.18-0.37-0.39-0.44c-0.38-0.13-0.7,0.08-0.81,0.52c-0.42,1.58-0.84,3.16-1.25,4.75C9.05,13.5,9,13.57,8.83,13.58c-0.3,0.01-0.6,0.05-0.9,0.09c-0.36,0.05-0.58,0.35-0.53,0.7c0.05,0.31,0.35,0.53,0.7,0.49c0.19-0.02,0.38-0.04,0.59-0.06c-0.04,0.15-0.07,0.27-0.1,0.4c-0.14,0.51-0.28,1.02-0.41,1.53c-0.12,0.47,0.2,0.87,0.66,0.8c0.3-0.04,0.45-0.25,0.53-0.53c0.19-0.72,0.38-1.43,0.56-2.15c0.03-0.12,0.07-0.17,0.21-0.18c0.61-0.05,1.21-0.11,1.82-0.18c0.13-0.02,0.16,0.05,0.18,0.15c0.24,0.81,0.49,1.61,0.73,2.42c0.07,0.23,0.19,0.42,0.43,0.49c0.22,0.06,0.42,0.02,0.59-0.14C14.08,17.21,14.13,17,14.06,16.76zM11.64,13.31c-0.43,0.04-0.86,0.08-1.32,0.12c0.23-0.85,0.45-1.68,0.68-2.56c0.24,0.8,0.47,1.55,0.7,2.29C11.72,13.22,11.76,13.3,11.64,13.31z" />
                              <path d="M16.02,8.77c0,0.18,0,0.36,0,0.54c-0.01,0.36-0.26,0.62-0.6,0.62c-0.34,0-0.61-0.27-0.61-0.63c-0.01-0.4-0.01-0.79,0-1.19c0-0.11-0.01-0.17-0.15-0.17c-0.19,0-0.37-0.02-0.56-0.03c-0.37-0.03-0.62-0.3-0.6-0.65c0.02-0.34,0.29-0.57,0.65-0.56c0.17,0,0.34,0,0.5,0.03c0.14,0.02,0.16-0.03,0.15-0.15c-0.01-0.16,0-0.32,0-0.48c0.01-0.37,0.26-0.64,0.6-0.64c0.35,0,0.6,0.26,0.61,0.63c0,0.19,0.01,0.38,0,0.56c-0.01,0.12,0.03,0.17,0.15,0.17c0.19,0,0.39,0.01,0.58,0.03c0.37,0.04,0.62,0.33,0.58,0.66c-0.04,0.34-0.32,0.57-0.69,0.54c-0.16-0.01-0.32-0.01-0.48-0.03c-0.1-0.01-0.14,0.02-0.14,0.13C16.03,8.37,16.02,8.57,16.02,8.77z" />
                            </svg>
                            <span>Report Card</span>
                          </Link>
                        </li>
                      )}

                      {hideFewMenusForCoordinatorAccount ? null : (
                        <li>
                          <Link
                            to={URL_PATH.ADMIN_QUEST_GEN}
                            className={`d-flex justify-content-center ${
                              URL_PATH.ADMIN_QUEST_GEN ===
                              window.location.pathname
                                ? "menu-active"
                                : ""
                            }`}
                          >
                            <svg
                              viewBox="0 0 32 32"
                              fill="none"
                              width="28"
                              height="28"
                              stroke="#bdc7cb"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path d="M8,28L28,8l-4-4L4,24L8,28" />
                              <path d="M20,8l4,4" />
                              <path d="M12,4c0,1.47,1.19,2.67,2.67,2.67C13.19,6.67,12,7.86,12,9.33c0-1.47-1.19-2.67-2.67-2.67C10.81,6.67,12,5.47,12,4" />
                              <path d="M25.33,17.33c0,1.47,1.19,2.67,2.67,2.67c-1.47,0-2.67,1.19-2.67,2.67c0-1.47-1.19-2.67-2.67-2.67C24.14,20,25.33,18.81,25.33,17.33" />
                            </svg>
                            <span>Question Paper Generation</span>
                          </Link>
                        </li>
                      )}
                      {hideFewMenusForCoordinatorAccount ? null : (
                        <li>
                          <Link
                            className={
                              URL_PATH.ADMIN_SCHOOL_PROFILE_ABOUT ===
                              window.location.pathname
                                ? "menu-active"
                                : ""
                            }
                            to={URL_PATH.ADMIN_SCHOOL_PROFILE_ABOUT}
                          >
                            <svg
                              viewBox="0 0 24 24"
                              className="icon icon-tabler icon-tabler-chart-donut"
                              width="24"
                              height="24"
                            >
                              <g
                                fill="none"
                                stroke="#bdc7cb"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="7.3333"
                              >
                                <path d="M9.98,3.02c0.48-2,3.32-2,3.81,0c0.25,1.05,1.31,1.7,2.37,1.44c0.2-0.05,0.39-0.13,0.56-0.23c1.75-1.07,3.76,0.94,2.69,2.69c-0.56,0.92-0.27,2.13,0.65,2.69c0.17,0.11,0.36,0.18,0.56,0.23c2,0.48,2,3.32,0,3.81c-1.05,0.25-1.7,1.31-1.44,2.37c0.05,0.2,0.13,0.39,0.23,0.56c1.07,1.75-0.94,3.76-2.69,2.69c-0.92-0.56-2.13-0.27-2.69,0.65c-0.11,0.17-0.18,0.36-0.23,0.56c-0.48,2-3.32,2-3.81,0c-0.25-1.05-1.31-1.7-2.37-1.44c-0.2,0.05-0.39,0.13-0.56,0.23c-1.75,1.07-3.76-0.94-2.69-2.69c0.56-0.92,0.27-2.13-0.65-2.69c-0.17-0.11-0.36-0.18-0.56-0.23c-2-0.48-2-3.32,0-3.81c1.05-0.25,1.7-1.31,1.44-2.37C4.55,7.29,4.47,7.1,4.37,6.92C3.3,5.17,5.3,3.16,7.06,4.23C8.2,4.92,9.67,4.31,9.98,3.02z" />
                                <circle cx="11.89" cy="11.75" r="3.41" />
                              </g>
                            </svg>
                            <span>Settings</span>
                          </Link>
                        </li>
                      )}
                    </>
                  )}

                {/* {store.get("userSession") &&
                store.get("userSession").userInfo &&
                store.get("userSession").userInfo.isNewAndEventsActive ? (
                  <li>
                    <Link
                      to={URL_PATH.ADMIN_NEWS_EVENTS}
                      className={
                        URL_PATH.ADMIN_NEWS_EVENTS === window.location.pathname
                          ? "menu-active"
                          : ""
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-calendar-event"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#bdc7cb"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <rect x="4" y="5" width="16" height="16" rx="2" />
                        <line x1="16" y1="3" x2="16" y2="7" />
                        <line x1="8" y1="3" x2="8" y2="7" />
                        <line x1="4" y1="11" x2="20" y2="11" />
                        <rect x="8" y="15" width="2" height="2" />
                      </svg>
                      <span>News & Events</span>
                    </Link>
                  </li>
                  ) : null} */}

                {/* {store.get("userSession") &&
                  store.get("userSession").userInfo &&
                  store.get("userSession").userInfo.isCommunicationActive ? (
                  <li>
                    <Link
                      to={URL_PATH.ADMIN_COMMUNICATION_DASHBOARD}
                      className={
                        URL_PATH.ADMIN_COMMUNICATION_DASHBOARD ===
                          window.location.pathname
                          ? "menu-active"
                          : ""
                      }
                      onClick={() => {
                        ReactGA.event({
                          category: "Link",
                          action: "User visiting communication page",
                        })
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-message"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#bdc7cb"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4" />
                        <line x1="8" y1="9" x2="16" y2="9" />
                        <line x1="8" y1="13" x2="14" y2="13" />
                      </svg>
                      <span>Communication</span>
                    </Link>
                  </li>
                ) : null} */}
              </ul>
            )}

            {this.props.showAdminProfileSidemenu && (
              <ul>
                <li>
                  <Link
                    to={URL_PATH.ADMIN}
                    className={`d-flex flex-column justify-content-between`}
                  >
                    <img
                      className="img-fluid"
                      src={redinkLogo}
                      alt="Redink"
                      width="40"
                      height="40"
                    />
                  </Link>
                </li>
                <li>
                  <Link to={URL_PATH.ADMIN}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-arrow-left-circle"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#bdc7cb"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <circle cx="12" cy="12" r="9" />
                      <line x1="8" y1="12" x2="16" y2="12" />
                      <line x1="8" y1="12" x2="12" y2="16" />
                      <line x1="8" y1="12" x2="12" y2="8" />
                    </svg>
                    <span>Back to Dashboard</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to={URL_PATH.ADMIN_SCHOOL_PROFILE_ABOUT}
                    className={
                      URL_PATH.ADMIN_SCHOOL_PROFILE_ABOUT ===
                      window.location.pathname
                        ? "menu-active"
                        : ""
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-building-community"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#bdc7cb"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <path d="M8 9l5 5v7h-5v-4m0 4h-5v-7l5 -5m1 1v-6a1 1 0 0 1 1 -1h10a1 1 0 0 1 1 1v17h-8" />
                      <line x1="13" y1="7" x2="13" y2="7.01" />
                      <line x1="17" y1="7" x2="17" y2="7.01" />
                      <line x1="17" y1="11" x2="17" y2="11.01" />
                      <line x1="17" y1="15" x2="17" y2="15.01" />
                    </svg>
                    <span>About School</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to={URL_PATH.ADMIN_SCHOOL_PROFILE_ACADEMICYEAR}
                    className={
                      URL_PATH.ADMIN_SCHOOL_PROFILE_ACADEMICYEAR ===
                      window.location.pathname
                        ? "menu-active"
                        : ""
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-calendar-plus"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#bdc7cb"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <rect x="4" y="5" width="16" height="16" rx="2" />
                      <line x1="16" y1="3" x2="16" y2="7" />
                      <line x1="8" y1="3" x2="8" y2="7" />
                      <line x1="4" y1="11" x2="20" y2="11" />
                      <line x1="10" y1="16" x2="14" y2="16" />
                      <line x1="12" y1="14" x2="12" y2="18" />
                    </svg>
                    <span>Academic year</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to={URL_PATH.ADMIN_SCHOOL_PROFILE_CLASS_DETAILS}
                    className={
                      URL_PATH.ADMIN_SCHOOL_PROFILE_CLASS_DETAILS ===
                      window.location.pathname
                        ? "menu-active"
                        : ""
                    }
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 46 41"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.07804 1.53318H37.678C41.626 1.53318 44.8274 4.73451 44.8274 8.68251V32.5158C44.8274 36.4652 41.626 39.6678 37.678 39.6678H9.07804C5.12871 39.6678 1.92871 36.4652 1.92871 32.5158V8.68251C1.92871 4.73451 5.12871 1.53318 9.07804 1.53318Z"
                        stroke="#BDC7CB"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.4609 11.4668H35.2943"
                        stroke="#BDC7CB"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.4609 20.8H35.2943"
                        stroke="#BDC7CB"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.4609 31.4668H35.2943"
                        stroke="#BDC7CB"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>Classes</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to={URL_PATH.ADMIN_SCHOOL_PROFILE_SUBJECTS}
                    className={
                      URL_PATH.ADMIN_SCHOOL_PROFILE_SUBJECTS ===
                      window.location.pathname
                        ? "menu-active"
                        : ""
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-book"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#bdc7cb"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <path d="M3 19a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />
                      <path d="M3 6a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />
                      <line x1="3" y1="6" x2="3" y2="19" />
                      <line x1="12" y1="6" x2="12" y2="19" />
                      <line x1="21" y1="6" x2="21" y2="19" />
                    </svg>
                    <span>Subjects</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to={URL_PATH.ADMIN_SCHOOL_PROFILE_TEACHERS}
                    className={
                      URL_PATH.ADMIN_SCHOOL_PROFILE_TEACHERS ===
                        window.location.pathname ||
                      URL_PATH.ADMIN_TEACHER_PROFILE ===
                        window.location.pathname
                        ? "menu-active"
                        : ""
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-friends"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#bdc7cb"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <circle cx="7" cy="5" r="2" />
                      <path d="M5 22v-5l-1-1v-4a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4l-1 1v5" />
                      <circle cx="17" cy="5" r="2" />
                      <path d="M15 22v-4h-2l2 -6a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1l2 6h-2v4" />
                    </svg>
                    <span>Teachers</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to={URL_PATH.ADMIN_SCHOOL_PROFILE_STUDENTS}
                    className={
                      URL_PATH.ADMIN_SCHOOL_PROFILE_STUDENTS ===
                      window.location.pathname
                        ? //    ||
                          // URL_PATH.ADMIN_STUDENT_PROFILE_FORM_DETAIL ===
                          //   window.location.pathname
                          "menu-active"
                        : ""
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-users"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#bdc7cb"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="9" cy="7" r="4" />
                      <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                      <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                      <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                    </svg>
                    <span>Students</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to={URL_PATH.ADMIN_SCHOOL_PROFILE_GRADE}
                    className={
                      URL_PATH.ADMIN_SCHOOL_PROFILE_GRADE ===
                      window.location.pathname
                        ? "menu-active"
                        : ""
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-star"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#bdc7cb"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <path d="M12 17.75l-6.172 3.245 1.179-6.873-4.993-4.867 6.9-1.002L12 2l3.086 6.253 6.9 1.002-4.993 4.867 1.179 6.873z" />
                    </svg>
                    <span>Grades</span>
                  </Link>
                </li>
                {PATH_URL.showNewGrading && (
                  <li>
                    <Link
                      to={URL_PATH.ADMIN_GRADE_MASTER}
                      className={
                        URL_PATH.ADMIN_GRADE_MASTER === window.location.pathname
                          ? "menu-active"
                          : ""
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-star"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#bdc7cb"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <path d="M12 17.75l-6.172 3.245 1.179-6.873-4.993-4.867 6.9-1.002L12 2l3.086 6.253 6.9 1.002-4.993 4.867 1.179 6.873z" />
                      </svg>
                      <span>Grade Master</span>
                    </Link>
                  </li>
                )}

                <li>
                  <Link
                    to={URL_PATH.ADMIN_BEHAVIOUR}
                    className={`d-flex justify-content-center
                    ${
                      URL_PATH.ADMIN_BEHAVIOUR === window.location.pathname
                        ? "menu-active"
                        : ""
                    }
                  `}
                  >
                    <svg
                      className="icon icon-tabler icon-tabler-file-invoice"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#bdc7cb"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit={10}
                    >
                      <circle cx="12.13" cy="9.32" r="5.87" />
                      <path d="M12.13,15.19l3.07,5.32c0.11,0.19,0.38,0.18,0.48-0.02l1.26-2.54c0.05-0.1,0.15-0.16,0.26-0.15l2.83,0.18c0.22,0.01,0.36-0.22,0.25-0.41l-3.07-5.32" />
                      <path d="M7.04,12.26l-3.07,5.32C3.86,17.77,4,18,4.22,17.99l2.83-0.18c0.11-0.01,0.21,0.05,0.26,0.15l1.26,2.54c0.1,0.2,0.37,0.2,0.48,0.02l3.07-5.32" />
                    </svg>
                    <span>Behaviour</span>
                  </Link>
                </li>

                {/* {store.get("userSession") &&
                store.get("userSession").userInfo &&
                store.get("userSession").userInfo.isTimetableActive ? (
                  <li>
                    <Link
                      to={URL_PATH.ADMIN_TIMETABLE_DASHBOARD}
                      className={
                        URL_PATH.ADMIN_TIMETABLE_DASHBOARD ===
                        window.location.pathname
                          ? "menu-active"
                          : ""
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-clock"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#bdc7cb"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <circle cx="12" cy="12" r="9" />
                        <polyline points="12 7 12 12 15 15" />
                      </svg>
                      <span>TimeTable</span>
                    </Link>
                  </li>
                ) : null} */}
              </ul>
            )}

            {this.props.showUpgradeAcadic && (
              <ul>
                <li>
                  <Link
                    to={URL_PATH.ADMIN}
                    className={`d-flex flex-column justify-content-between`}
                  >
                    <img
                      className="img-fluid"
                      src={redinkLogo}
                      alt="Redink"
                      width="40"
                      height="40"
                    />
                  </Link>
                </li>

                <li>
                  <Link
                    to={URL_PATH.ADMIN_UPGRADE_ACADEMICYEAR}
                    className="menu-active"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-calendar-plus"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#bdc7cb"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <rect x="4" y="5" width="16" height="16" rx="2" />
                      <line x1="16" y1="3" x2="16" y2="7" />
                      <line x1="8" y1="3" x2="8" y2="7" />
                      <line x1="4" y1="11" x2="20" y2="11" />
                      <line x1="10" y1="16" x2="14" y2="16" />
                      <line x1="12" y1="14" x2="12" y2="18" />
                    </svg>
                    <span>Academic year</span>
                  </Link>
                </li>
              </ul>
            )}
          </nav>
        </aside>
        <div
          className={`app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header closed-sidebar ${
            this.state.toggleMobileSidemenu ? "sidebar-mobile-open" : ""
          }`}
        >
          <div className="bg-white app-main">
            <div className="app-main__outer">
              <div className="app-main__inner">{this.props.children}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return { getStudentSearchDetails }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    { getStudentSearchDetails, getStudentRemoteLogin },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(withRouter(Wrapper))
