import Client from "../../utils/axiosInstance"
import URL from "../../config"
import { ACTION_TYPES } from "../types"
import { myLog } from "../../utils/Utility"
import { showNotification } from "../CommonAction"
import {
  DANGER_TOAST,
  SUCCESS_TOAST,
  VALIDATION_MSG,
} from "../../utils/Constants"

export const getSubjectAllotmentList = (body, callback) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.TEACHER_SUBJECT_ALLOTMENT,
        {},
        true,
        true
      )
      const subjectAllotmentList = response.data

      dispatch({
        type: ACTION_TYPES.TEACHER_SUBJECT_ALLOTMENT_LIST_UPDATE,
        payload: subjectAllotmentList,
      })

      if (callback) callback(true, subjectAllotmentList)
    } catch (err) {
      if (callback) callback(false)
    }
  }
}

export const saveSubjectAllotment = (body, callback) => {
  return async dispatch => {
    try {
      const response = await Client.put(
        URL.TEACHER_SUBJECT_ALLOTMENT,
        { allotments: body },
        true,
        true
      )

      if (response.responseCode === 0) {
        myLog("====deleteStudentDatabaseDetails RESPONSE===::::", response)
        const responseList = await Client.get(
          URL.TEACHER_SUBJECT_ALLOTMENT,
          {},
          true,
          true
        )
        const subjectAllotmentList = responseList.data
        dispatch({
          type: ACTION_TYPES.TEACHER_SUBJECT_ALLOTMENT_LIST_UPDATE,
          payload: subjectAllotmentList,
        })
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callback(true, subjectAllotmentList)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callback(false)
      }
    } catch (err) {
      if (callback) callback(false)
    }
  }
}
