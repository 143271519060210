/*************************************************
 * RedInk
 * @exports
 * @class SubjectsScreen.js
 * @extends Component
 * @author Sasidharan // on 06/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { myLog } from "../../../utils/Utility"
import AppWrapper from "../Wrapper"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  saveSubjectDetails,
  getSubjectDetails,
  updateSubjectDetails,
  deleteSubjectDetails,
} from "../../../actions/superAdmin/SubjectDetailsAction"
import { getSchoolsListDetails } from "../../../actions/superAdmin/schoolDetailsAction"
class SubjectsScreen extends Component {
  static getDerivedStateFromProps = (props, state) => {
    // myLog(props.subjectList);
    return {}
  }

  constructor(props) {
    super(props)
    this.state = {
      subjectsSet: [""],
      isShowAddSubjectInput: false,
      updateSubjectDetailId: "",
    }
  }

  componentDidMount = () => {
    this.props.getSchoolsListDetails((schoolSuccess, schoolResponse) => {
      this.getSubjectDetailsService(
        schoolResponse && schoolResponse[0].branchId
      )
    })
  }

  getSubjectDetailsService = branchId => {
    let object = {
      branchId,
    }
    this.props.getSubjectDetails(object, (success, response) => {
      if (success) {
        if (response.length > 0) {
          this.setState({
            isShowAddSubjectInput: false,
          })
        }
      }
    })
  }

  /**
   * Add new subject
   */
  addSubject = () => {
    const tempARR = this.state.subjectsSet
    tempARR.push("")
    this.setState({ subjectsSet: tempARR }, () => {
      myLog(this.state.subjectsSet)
    })
  }
  /**
   * Delete a subject
   */
  deleteSubject = index => {
    if (this.state.subjectsSet.length > 1) {
      let arrSubject = this.state.subjectsSet
      arrSubject.splice(index, 1)
      this.setState(
        {
          subjectsSet: arrSubject,
        },
        () => {
          myLog(this.state.subjectsSet)
        }
      )
    }
  }

  /**
   * Delete a subject from db
   */
  deleteSubjectFromDb = item => {
    myLog(item.id)
    this.props.deleteSubjectDetails({ id: item.id }, () => {})
  }

  /**
   * saveSubject
   */
  saveSubject = () => {
    myLog(this.state.subjectsSet)
    if (this.state.subjectsSet[0] !== "") {
      let subjects = []
      for (let i = 0; i < this.state.subjectsSet.length; i++) {
        if (this.state.subjectsSet[i] !== "") {
          let subjectObject = {
            subjectName: this.state.subjectsSet[i],
          }
          subjects.push(subjectObject)
        }
      }
      myLog(subjects)
      this.setState(
        {
          isShowLoading: true,
        },
        () => {
          let object = { subjects }
          this.props.saveSubjectDetails(object, success => {
            this.setState({
              isShowLoading: false,
            })
            myLog("success", success)
            if (success) {
              this.setState(
                {
                  subjectsSet: [],
                  isShowAddSubjectInput: false,
                },
                () => {
                  myLog("success", this.state.subjectsSet)
                }
              )
            }
          })
        }
      )
    } else {
      this.setState({ hasError: true })
      myLog("error", this.state.subjectsSet)
    }
  }

  updateSubject = () => {
    myLog(this.state.subjectsSet)
    if (this.state.subjectsSet[0] !== "") {
      let subjects = []
      for (let i = 0; i < this.state.subjectsSet.length; i++) {
        if (this.state.subjectsSet[i] !== "") {
          let subjectObject = {
            id: this.state.updateSubjectDetailId,
            subjectName: this.state.subjectsSet[i],
          }
          subjects.push(subjectObject)
        }
      }
      myLog(subjects)
      this.setState(
        {
          isShowLoading: true,
        },
        () => {
          let object = { subjects }
          this.props.updateSubjectDetails(object, success => {
            this.setState({
              isShowLoading: false,
            })
            myLog("success", success)
            if (success) {
              this.setState(
                {
                  subjectsSet: [],
                  isShowAddSubjectInput: false,
                },
                () => {
                  myLog("success", this.state.subjectsSet)
                }
              )
            }
          })
        }
      )
    } else {
      this.setState({ hasError: true })
      myLog("error", this.state.subjectsSet)
    }
  }

  render() {
    return (
      <AppWrapper
        showProfileSidemenu
        showBackToDashboard
        onChangeSchoolName={item => {
          myLog(item)
          this.getSubjectDetailsService(item.branchId)
        }}
      >
        <div className="card">
          <div className="m-3 row">
            <div className="col-sm">
              <span className="font-weight-bold fs-25">SUBJECT DETAILS</span>
            </div>
            {/* <div className="text-right col-sm">
              
              {this.state.isShowAddSubjectInput ?
                this.state.isShowUpdateSubjectInput ?
                  <button
                    type="button"
                    className="px-4 btn btn-sm btn-success rounded-pill"
                    onClick={() => this.updateSubject()}
                  >
                    <i className="mr-2 fas fa-save" />
                    Save
                  </button>
                  :
                  <button
                    type="button"
                    className="px-4 btn btn-sm btn-success rounded-pill"
                    onClick={() => this.saveSubject()}
                  >
                    <i className="mr-2 fas fa-save" />
                    SAVE
                  </button>
              :
              <button
                type="button"
                className="px-4 btn btn-sm btn-success rounded-pill"
                onClick={() => this.setState({isShowAddSubjectInput: true, subjectsSet: [""]})}
              >
                <i className="mr-2 fas fa-save" />
                Add
              </button>
              }
            </div> */}
          </div>
          {this.state.isShowAddSubjectInput ? (
            <div className="m-3 mb-2 row ">
              <form className="col-sm">
                {this.state.subjectsSet &&
                  this.state.subjectsSet.map((item, index) => {
                    return (
                      <div className="mb-2 ml-2 d-flex justify-content-start">
                        <div>
                          <input
                            key={index}
                            type="text"
                            value={item}
                            className="form-control form-control-sm"
                            placeholder="Subject Name"
                            onChange={e => {
                              let state = this.state.subjectsSet
                              state[index] = e.target.value
                              this.setState({
                                subjectsSet: state,
                              })
                            }}
                          />
                        </div>
                        {this.state.isShowUpdateSubjectInput ? null : (
                          <div>
                            <i
                              className="p-2 fas fa-plus text-success"
                              style={{ cursor: "pointer" }}
                              onClick={() => this.addSubject()}
                            />
                            <i
                              className="p-2 fas fa-trash-alt text-danger"
                              style={{ cursor: "pointer" }}
                              onClick={() => this.deleteSubject(index)}
                            />
                          </div>
                        )}
                      </div>
                    )
                  })}
              </form>
            </div>
          ) : null}
          <div className="ml-5">
            {this.state.subjectsSet[0] === "" && this.state.hasError ? (
              <p className="text-danger">Subject is required.</p>
            ) : null}
          </div>
          <div className="m-3 row">
            {this.props.subjectList &&
              this.props.subjectList.map((item, index) => {
                return (
                  <div className="col-sm-3">
                    <div className="p-2 m-2 text-center rounded text-light bg-midnight-bloom">
                      <div className="d-flex justify-content-between">
                        <div className="text-left">
                          <span>{item.subjectName}</span>
                          <br></br>
                          <small>{item.teacherCount} teachers</small>
                        </div>
                        {/* <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="nav-link">
                            <i className="cursor-pointer fas fa-ellipsis-v"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => {this.setState({isShowAddSubjectInput: true, isShowUpdateSubjectInput: true, subjectsSet: [item.subjectName], updateSubjectDetailId: item.id})}}>Update</DropdownItem>
                          <DropdownItem onClick={() => { this.deleteSubjectFromDb(item)}}>Delete</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown> */}
                      </div>
                    </div>
                  </div>
                )
              })}
            {/* {this.props.subjectList.length === 0 ?
              <div className="text-center">
                <p>No data found</p>
              </div>
              :
              null
            } */}
          </div>
        </div>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    subjectList: state.subjectDetailsState.subjectList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getSchoolsListDetails,
      getSubjectDetails,
      saveSubjectDetails,
      updateSubjectDetails,
      deleteSubjectDetails,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(SubjectsScreen)
