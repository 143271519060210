/*************************************************
 * RedInk
 * @exports
 * @class AssessmentScreen.js
 * @extends Component
 * @author Sasidharan // on 11/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import AppWrapper from "../Wrapper"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { URL_PATH } from "../../../config/urlPath"
import { withRouter } from "react-router-dom"
import {
  Modal as BSModal,
  Button,
  FormGroup,
  Input,
  Label,
  // DropdownToggle,
  // DropdownMenu,
  // UncontrolledDropdown,
} from "reactstrap"
import { HashLoader } from "react-spinners"
import Modal, { ModalTransition } from "@atlaskit/modal-dialog"
import { myLog } from "../../../utils/Utility"
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack"
import {
  getSingleQuiz,
  saveQuiz,
  getPresignedUrl,
  putFileToPresignedUrl,
  getQuizTopics,
  saveQuizTopics,
  deleteQuizTopic,
} from "../../../actions/teacher/QuizAction"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import { v4 as uuid } from "uuid"
import moment from "moment"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { showNotification } from "../../../actions/CommonAction"
import {
  DANGER_TOAST,
  VALIDATION_MSG,
  // MAX_FILE_UPLOAD,
} from "../../../utils/Constants"
import QuestionBank from "./QuestionBank"

const LEGENDS_COLORS = {
  ANSWERED: {
    bg: "#7ED5C5",
    font: "#236d66",
  },
  NOTANSWERED: {
    bg: "#F3A3B4",
    font: "#a03050",
  },
  NOTVISITED: {
    bg: "#E8E8E8",
    font: "#777777",
  },
  FLAGGED: {
    bg: "#B3C4EC",
    font: "#274882",
  },
}

const questionTypes = [
  { value: "TRUE_OR_FALSE", label: "True or false" },
  { value: "MULTIPLE_CHOICE", label: "Multiple choice" },
  { value: "MULTIPLE_ANSWER", label: "Multiple answer" },
  { value: "SHORT_ANSWER", label: "Short answer" },
  { value: "LONG_ANSWER", label: "Long answer" },
  { value: "MATCHING", label: "Matching" },
  { value: "FILL_IN_THE_BLANKS", label: "Fill in the blanks" },
  { value: "SEQUENCING", label: "Sequencing" },
]

const defaultQuestion = () => ({
  draggableId: uuid(),
  type: "TRUE_OR_FALSE",
  quizTopicId: "",
  text: "",
  questions: [""],
  answers: [""],
  distractorAnswers: [],
  mark: 1,
  difficulty: "EASY",
  attachment: null,
})

class CreateAssessmentScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isAddingQuiz: false,
      isPreviewOpen: false,
      isPreviewModalOpen: false,
      isRead: false,

      quizPreviewSections: null,
      currentSection: 0,
      currentQuestion: 0,

      isReuse: false,
      quizData: {},
      quizAssignInfoId: "",
      hasSection: false,
      title: "",
      instruction: "",
      quizSections: [
        {
          title: "",
          description: "",
          droppableId: uuid(),
          quizQuestions: [defaultQuestion()],
        },
      ],

      isAddQuestionFromBankOpen: false,
      addInSec: 0,
      addTopicModalOpen: false,
      addTopicIn: { section: 0, question: 0 },
      selectedTopic: null,
      newTopic: "",
      isAddingNewTopic: false,
      topicsList: [],

      pdfPageNo: 0,
      selectedChapterAddTopic: null,
      singleQuizData: null,
      questionOpen: `0-0`,
      timeLimit: "",
    }
  }

  saveTimer = null

  componentDidMount = async () => {
    this.setState({ isLoadingData: true })
    const { quizData, reuseQuizData, title } = this.props.location.state
      ? this.props.location.state
      : {}
    if (reuseQuizData) {
      this.props.getSingleQuiz(
        reuseQuizData.quizSet && {
          id: reuseQuizData.quizSet.id,
          data: { reuse: true },
        },
        (success, response) => {
          if (success) {
            this.setState({ singleQuizData: response.data })
            let { instruction, quizSections, hasSection } = response.data
            quizSections = quizSections.map(
              ({ quizQuestions, ...section }, index) => ({
                ...section,
                droppableId: uuid(),
                quizQuestions: quizQuestions.map((que, qIndex) => ({
                  ...que,
                  mark: parseInt(que.mark),
                  draggableId: uuid(),
                  difficulty: que.difficulty || "EASY",
                })),
              })
            )
            this.setState(
              {
                quizData,
                hasSection,
                title,
                instruction,
                quizSections,
                quizAssignInfoId: quizData.id,
                isLoadingData: false,
                isReuse: true,
              },
              () => {
                this.getPDFForQues()
                this.loadFromLocalStorage()
              }
            )
          }
        }
      )
    } else {
      this.setState({ isLoadingData: true })
      this.props.getSingleQuiz(quizData.quizSet.id, (success, response) => {
        if (success) {
          this.setState({ singleQuizData: response.data })
          let quizSections = response.data.quizSections
          let newQuizSections
          if (quizSections && quizSections.length > 0) {
            newQuizSections = quizSections.map(
              ({ quizQuestions, ...section }, index) => ({
                ...section,
                droppableId: uuid(),
                quizQuestions: quizQuestions.map((que, qIndex) => ({
                  ...que,
                  mark: parseInt(que.mark),
                  draggableId: uuid(),
                  difficulty: que.difficulty || "EASY",
                })),
              })
            )
          } else {
            newQuizSections = [
              {
                title: "",
                description: "",
                droppableId: uuid(),
                quizQuestions: [defaultQuestion()],
              },
            ]
          }
          this.setState(
            {
              quizData,
              ...response.data,
              quizSections: newQuizSections,
              isEdit: true,
              isLoadingData: false,
              quizAssignInfoId: quizData.id,
            },
            () => {
              this.getPDFForQues()
              this.loadFromLocalStorage()
            }
          )
        }
      })
    }
    // await new Promise((resolve, reject) => {
    //   this.props.getQuizTopics(null, (success, response) => {
    //     myLog("this.props.getQuizTopics", success, response)
    //     if (success) {
    //       this.setState({ topicsList: response.data })
    //     }
    //     resolve()
    //   })
    // })
  }

  loadChapterTopics = chapterId => {
    this.props.getQuizTopics(
      { selectedChapter: chapterId },
      (success, response) => {
        if (success) {
          this.setState({ topicsList: response.data.topicsList })
        }
      }
    )
  }

  loadFromLocalStorage = () => {
    const {
      quizAssignInfoId,
      quizData: { updatedOn },
      // singleQuizData,
    } = this.state
    const savedData = JSON.parse(
      localStorage.getItem(`quiz-${quizAssignInfoId}`) || "{}"
    )
    const { savedOn, ...quiz } = savedData
    if (savedOn && moment(savedOn).isAfter(moment(updatedOn))) {
      this.setState({ ...quiz })
    }

    this.saveTimer = setInterval(() => {
      const {
        isReuse,
        title,
        instruction,
        quizSections,
        quizData,
        quizAssignInfoId,
        hasSection,
        singleQuizData,
      } = this.state
      const data = {
        savedOn: moment(),
        isReuse, title, instruction, quizSections, quizData, quizAssignInfoId, hasSection, singleQuizData,
      } // prettier-ignore
      localStorage.setItem(`quiz-${quizAssignInfoId}`, JSON.stringify(data))
    }, 2000)
  }

  componentWillUnmount() {
    this.saveTimer = null
  }

  getPDFForQues = async () => {
    let quizSections = this.state.quizSections

    let sectionIndex = 0
    for (const section of quizSections) {
      let queIndex = 0
      for (const quiz of section.quizQuestions) {
        if (quiz.attachment) {
          const datums = await this.makeRequest("GET", quiz.attachment.url)
          const file = new Blob([datums], {
            type: "application/pdf",
          })
          const browserUrl = window.webkitURL || window.URL
          const pdfURL = browserUrl.createObjectURL(file)
          quizSections[sectionIndex].quizQuestions[queIndex].attachment = {
            ...quiz.attachment,
            pdfURL,
          }
        }

        queIndex++
      }
      sectionIndex++
    }

    this.setState({ quizSections })
  }

  makeRequest = (method, url) => {
    return new Promise(function(resolve, reject) {
      var xhr = new XMLHttpRequest()
      xhr.open(method, url, true)
      xhr.responseType = "blob"
      xhr.onload = function() {
        if (this.status >= 200 && this.status < 300) {
          resolve(xhr.response)
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText,
          })
        }
      }
      xhr.onerror = function() {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        })
      }
      xhr.send(null)
    })
  }

  uploadFile = file => {
    return new Promise((resolve, reject) => {
      this.props.getPresignedUrl(file.name, (success, resp) => {
        if (success) {
          const formData = new FormData()
          formData.append("file", file)
          const url = resp.data.url
          const xhr = new XMLHttpRequest()
          xhr.open("put", url, true)
          xhr.setRequestHeader("Content-Type", "application/pdf")
          xhr.onload = () => {
            if (xhr.status === 200) {
              resolve({ name: resp.data.name })
            } else {
              reject({ error: `File upload error (status: ${xhr.status})` })
            }
          }
          xhr.onerror = () => {
            reject({ error: "File upload error" })
          }
          xhr.send(formData)
        }
      })
    }).catch(err => {
      return err
    })
  }

  showErrorNoti = message => {
    this.props.showNotification(DANGER_TOAST, VALIDATION_MSG.OOPS, message)
  }

  toggleHasSection = hasSection => {
    if (!hasSection) {
      let quizSections = [
        {
          title: "default",
          description: "default",
          quizQuestions: [],
          droppableId: uuid(),
        },
      ]
      this.state.quizSections.forEach(section =>
        section.quizQuestions.forEach(que =>
          quizSections[0].quizQuestions.push(que)
        )
      )
      this.setState({ hasSection: 0, quizSections })
    } else {
      this.setState({
        hasSection: 1,
        quizSections: this.state.quizSections.map(section => ({
          ...section,
          title: "",
          description: "",
        })),
      })
    }
  }

  assign = async () => {
    this.setState({ isAddingQuiz: true })

    let error = ""
    const title = (this.state.title || "").trim()
    if (!title) {
      error += "Please set title of quiz\n"
    }
    const instruction = (this.state.instruction || "").trim()
    // if (!instruction) {
    //   error += "Please set instruction of quiz\n"
    // }
    let quizSections = [...this.state.quizSections]
    let sectionIndex = 0
    for (const section of quizSections) {
      if (this.state.hasSection) {
        if (!section.title) {
          error += `Please add title in section no ${sectionIndex + 1}\n`
        }
        if (!section.description) {
          error += `Please add description in section no ${sectionIndex + 1}\n`
        }
      } else {
        section.title = "default"
        section.description = "default"
      }
      let questionIndex = 0
      for (const question of section.quizQuestions) {
        if (!question.text) {
          error += `Please add question text in section no ${sectionIndex +
            1} question no ${questionIndex + 1}\n`
        }
        if (!question.difficulty) {
          error += `Please set difficulty in section no ${sectionIndex +
            1} question no ${questionIndex + 1}\n`
        }
        if (
          (question.type === "MULTIPLE_CHOICE" ||
            question.type === "MULTIPLE_ANSWER" ||
            question.type === "MATCHING" ||
            question.type === "SEQUENCING") &&
          question.questions.length <= 1
        ) {
          error += `Please add atleast two options in section no ${sectionIndex +
            1} question no ${questionIndex + 1}\n `
        }
        if (
          (question.type === "MULTIPLE_CHOICE" ||
            question.type === "MULTIPLE_ANSWER" ||
            question.type === "MATCHING" ||
            question.type === "SEQUENCING") &&
          question.answers.length === 0
        ) {
          error += `Please add answer in section no ${sectionIndex +
            1} question no ${questionIndex + 1}\n `
        }
        if (
          question.type === "FILL_IN_THE_BLANKS" &&
          (question.answers.length === 0 || question.answers[0] === "")
        ) {
          error += `Please add answer in section no ${sectionIndex +
            1} question no ${questionIndex + 1}\n `
        }
        if (
          question.type === "MATCHING" &&
          (question.answers.length === 0 || question.answers[0] === "")
        ) {
          error += `Please add answer in section no ${sectionIndex +
            1} question no ${questionIndex + 1}\n `
        }
        if (
          question.type === "SEQUENCING" &&
          (question.answers.length === 0 || question.answers[0] === "")
        ) {
          error += `Please add answer in section no ${sectionIndex +
            1} question no ${questionIndex + 1}\n `
        }
        questionIndex++
      }
      sectionIndex++
    }

    if (error) {
      this.showErrorNoti(error)
      this.setState({ isAddingQuiz: false })
      return
    }

    let { id, quizAssignInfoId } = this.state
    let totalMark = 0
    let sIndex = 0
    for (const section of quizSections) {
      section.title = (section.title || "").trim()
      section.description = (section.description || "").trim()
      section.droppableId = undefined
      let qIndex = 0
      for (const question of section.quizQuestions) {
        const { mark, attachment, type, text } = question
        // index
        totalMark += parseInt(mark)
        if (attachment && typeof attachment === "object") {
          /* gotton name from api */
          const { name, error } = await this.uploadFile(attachment)
          if (error) {
            this.showErrorNoti(error)
            return this.setState({ isAddingQuiz: false })
          }
          question.attachment = name
        } else if (typeof attachment !== "string") {
          question.attachment = null
        }
        if (type === "FILL_IN_THE_BLANKS" || type === "TRUE_OR_FALSE") {
          question.questions = [text]
        }
        question.mark = mark.toString()
        question.index = qIndex.toString()
        question.droppableId = undefined
        qIndex++
      }
      section.index = sIndex.toString()
      sIndex++
    }
    totalMark = totalMark.toString()

    const data = {
      id,
      title,
      instruction,
      hasSection: this.state.hasSection ? 1 : 0,
      quizSections,
      totalMark,
      quizAssignInfoId,
      reuse: this.state.isReuse,
    }
    this.props.saveQuiz(data, (success, response) => {
      this.setState({ isAddingQuiz: false })
      if (success) {
        const { quizAssignInfoId } = this.state
        localStorage.removeItem(`quiz-${quizAssignInfoId}`)
        this.props.history.push(URL_PATH.TEACHER_TESTS)
      }
    })
  }

  createNewTopic = () => {
    if (this.state.quizData.subjectChapters.length === 0) {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.OOPS,
        "Please add chapter to the assessment before creating a topic"
      )
    } else if (this.state.newTopic !== "" && !this.state.isAddingNewTopic) {
      this.setState({ isAddingNewTopic: true })
      const {
        teacherAssignedDetail: { assignedClass, assignedSubject },
        subjectChapters,
      } = this.state.quizData
      const data = {
        name: this.state.newTopic,
        subjectChapterId: subjectChapters[0] && subjectChapters[0].id,
        sectionId: assignedClass,
        subjectId: assignedSubject,
      }
      this.props.saveQuizTopics(data, (success, response) => {
        success && this.props.getQuizTopics(null, (success2, resp) => {
          this.setState({ isAddingNewTopic: false, topicsList: resp.data, newTopic: '' })
        })
      }) // prettier-ignore
    }
  }

  deleteQuizTopic = deleteId => {
    this.setState({ deletingTopicId: deleteId })
    this.props.deleteQuizTopic(deleteId, (success, response) => {
      this.setState({
        topicsList: this.state.topicsList.filter(({ id }) => id !== deleteId),
        deletingTopicId: null,
      })
      if (
        this.state.selectedTopic &&
        this.state.selectedTopic.id === deleteId
      ) {
        this.setState({ selectedTopic: null })
      }
    })
  }

  bytesToSize = bytes => {
    let sizes = ["Bytes", "KB", "MB", "GB", "TB"]
    if (bytes === 0) return "0 Byte"
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i]
  }

  calcTotalMarks = sectionIndex => {
    return this.state.quizSections[sectionIndex].quizQuestions.reduce(
      (val, que) => {
        const add = typeof que.mark === "number" ? que.mark : parseInt(que.mark)
        return val + add
      },
      0
    )
  }

  calcQueNumber = (
    sectionNo,
    questionNo,
    quizSections = this.state.quizSections
  ) => {
    let number = 0

    let sectionIndex = 0
    for (const section of quizSections) {
      let questionIndex = 0
      let tobreak = false
      for (const question of section.quizQuestions) {
        number++
        if (sectionIndex === sectionNo && questionIndex === questionNo) {
          tobreak = true
          break
        }

        questionIndex++
      }
      if (tobreak) break

      sectionIndex++
    }

    return number
  }

  addNewSection = position => {
    const newSection = {
      title: "",
      description: "",
      droppableId: uuid(),
      quizQuestions: [defaultQuestion()],
    }

    let quizSections = this.state.quizSections
    quizSections =
      position === "top"
        ? [newSection, ...quizSections]
        : position === "bottom"
        ? [...quizSections, newSection]
        : this.state.quizSections
    const questionOpen = `${position === "top" ? 0 : quizSections.length - 1}-0`
    this.setState({ quizSections, questionOpen })
  }

  addQueInSection = (sIndex, qIndex) => {
    let quizSections = this.state.quizSections
    let newQuestions = [
      ...quizSections[sIndex].quizQuestions.slice(0, qIndex + 1),
      defaultQuestion(),
      ...quizSections[sIndex].quizQuestions.slice(
        qIndex + 1,
        quizSections[sIndex].quizQuestions.length
      ),
    ]
    quizSections[sIndex].quizQuestions = newQuestions
    this.setState({
      quizSections,
      questionOpen: `${sIndex}-${qIndex + 1}`,
    })
  }

  onDragEndHandler = result => {
    const { destination, source, draggableId } = result
    if (
      !destination ||
      (destination.draggableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return
    }

    // in section droppableId
    // in question draggableId

    let quizSections = [...this.state.quizSections]
    const sourceSectionIndex = quizSections.findIndex(
      ({ droppableId: dId }) => dId === source.droppableId
    )
    const destinationSectionIndex = quizSections.findIndex(
      ({ droppableId: dId }) => dId === destination.droppableId
    )
    let sourceNewSection = quizSections[sourceSectionIndex]
    let destinationNewSection = quizSections[destinationSectionIndex]
    const sourceData = sourceNewSection.quizQuestions.find(
      ({ draggableId: dId }) => dId === draggableId
    )

    sourceNewSection.quizQuestions.splice(source.index, 1)
    destinationNewSection.quizQuestions.splice(destination.index, 0, sourceData)

    quizSections[sourceSectionIndex] = sourceNewSection
    quizSections[destinationSectionIndex] = destinationNewSection
    this.setState({ quizSections })
  }

  /**
   * Render question icon for sidebar
   * @param {number} number
   * @param {'ANSWERED'|'NOTANSWERED'|'NOTVISITED'|'FLAGGED'} type
   * @param {true|false} isSelected
   * @param {Function} onClick
   */
  renderQuestionIcon = (number, type, isSelected, onClick) => (
    <div
      className="m-2 cursor-pointer"
      style={{
        borderRadius: 16,
        border: "3px solid transparent",
        borderColor: isSelected ? LEGENDS_COLORS[type].bg : "transparent",
      }}
      onClick={() => onClick && onClick(number)}
    >
      <div
        style={{
          borderRadius: 12,
          backgroundColor: LEGENDS_COLORS[type].bg,
          margin: 2,
          width: 35,
          height: 35,
          display: "flex",
        }}
      >
        <div
          className="m-auto font-weight-bold"
          style={{
            color: LEGENDS_COLORS[type].font,
            fontSize: "1.2rem",
          }}
        >
          {number}
        </div>
      </div>
    </div>
  )

  renderAddTopicModal = () => (
    <ModalTransition>
      {this.state.addTopicModalOpen && (
        <Modal
          heading="Topic"
          actions={[
            {
              text: "Done",
              onClick: () => {
                let quizSections = this.state.quizSections
                quizSections[this.state.addTopicIn.section].quizQuestions[
                  this.state.addTopicIn.question
                ].subjectTopic = this.state.selectedTopic
                this.setState({
                  quizSections,
                  addTopicModalOpen: false,
                  selectedChapterAddTopic: "",
                  topicsList: [],
                })
              },
            },
            {
              text: "Cancel",
              onClick: () => {
                this.setState({
                  addTopicModalOpen: false,
                  selectedChapterAddTopic: "",
                  topicsList: [],
                })
              },
            },
          ]}
        >
          {/* <div className="mb-3 d-flex">
            <Input
              type="text"
              value={this.state.newTopic}
              onChange={e => this.setState({ newTopic: e.target.value })}
              placeholder="Create new topic"
              name="newTopic"
              className="m-0 mr-2"
              style={{ flex: 1 }}
            />
            <Button
              className="d-flex align-items-center justify-content-center"
              style={{ width: 90 }}
              onClick={this.createNewTopic}
              disabled={this.state.isAddingNewTopic}
            >
              {this.state.isAddingNewTopic ? (
                <HashLoader
                  sizeUnit={"px"}
                  size={16}
                  color="#fff"
                  loading={true}
                />
              ) : (
                "Add Topic"
              )}
            </Button>
          </div>*/}
          <div className="mb-3 d-flex">
            <select
              className="mr-3 mb-2"
              value={this.state.selectedChapterAddTopic}
              onChange={e => {
                this.setState({
                  selectedChapterAddTopic: e.target.value,
                })
                this.loadChapterTopics(e.target.value)
              }}
            >
              <option value="">Select Chapter</option>
              {this.state.quizData &&
                this.state.singleQuizData.quizAssignInfo.defaultSubjectChapters.map(
                  option => (
                    <option value={option.id} key={option.id}>
                      {option.name}
                    </option>
                  )
                )}
            </select>
          </div>
          {/*<div check className="mx-3 my-2 d-flex align-items-center">
            <Input
              type="radio"
              radioGroup="topicRadio"
              checked={!this.state.selectedTopic}
              onChange={e => {
                e.target.checked && this.setState({ selectedTopic: null })
              }}
            />
            <span className="mx-2" style={{ flex: 1 }}>
              No topic
            </span>
          </div>*/}
          {this.state.topicsList.map(topic => {
            const isSelected =
              this.state.selectedTopic &&
              this.state.selectedTopic.id === topic.id
            const isDeleting = this.state.deletingTopicId === topic.id

            return (
              <div
                className="mx-3 my-2 d-flex align-items-center hover-parent"
                key={topic.id}
                style={{ opacity: isDeleting ? "0.7" : "1" }}
              >
                <Input
                  className="cursor-pointer"
                  type="radio"
                  radioGroup="topicRadio"
                  value={topic.id}
                  checked={isSelected}
                  onChange={e => {
                    e.target.checked && this.setState({ selectedTopic: topic })
                  }}
                />
                <span
                  className="px-2 cursor-pointer"
                  onClick={() => this.setState({ selectedTopic: topic })}
                  style={{ flex: 1 }}
                >
                  {topic.name}
                </span>
                {/*<i
                  className={`fas fa-times p-1 text-danger ${!isDeleting &&
                    "cursor-pointer"} hover-child`}
                  onClick={e => {
                    e.stopPropagation()
                    !isDeleting && this.deleteQuizTopic(topic.id)
                  }}
                />*/}
              </div>
            )
          })}
        </Modal>
      )}
    </ModalTransition>
  )

  renderAddQuestionFromBankModal = () => {
    const { quizData } = this.state
    if (!quizData || !quizData.teacherAssignedDetail) return
    const {
      teacherAssignedDetail: { assignedSubject, assignedClass },
    } = quizData
    const defaultSubjectChapters = quizData.defaultSubjectChapters

    return (
      <BSModal
        isOpen={this.state.isAddQuestionFromBankOpen}
        backdropClassName="modal-backdrop-show"
        modalClassName="modal-dialog-shadow-none"
        contentClassName="rounded-lg p-4 border-0"
        size="xl"
        centered={true}
      >
        <QuestionBank
          defaultSelection={{
            sectionId: assignedClass,
            subjectId: assignedSubject,
            defaultSubjectChapters: defaultSubjectChapters,
          }}
          onClose={() => this.setState({ isAddQuestionFromBankOpen: false })}
          onQuestionSelect={items => {
            let newQues = []
            items.forEach(item => {
              const {
                type,
                text,
                questions,
                answers,
                attachment,
                subjectTopicId: quizTopicId,
                subjectTopic,
              } = item
              const difficulty = item.difficulty || "EASY"
              const mark =
                item.type === "MULTIPLE_ANSWER" ||
                item.type === "MATCHING" ||
                item.type === "SEQUENCING"
                  ? item.mark * item.answers.length
                  : item.mark
              const que = {
                draggableId: uuid(),
                type,
                text,
                questions,
                answers,
                mark,
                difficulty,
                attachment,
                quizTopicId,
                subjectTopic,
              }
              newQues.push(que)
            })
            const sIndex = this.state.addInSec
            let quizSections = this.state.quizSections
            let newQuestions = [
              ...quizSections[sIndex].quizQuestions,
              ...newQues,
            ]
            quizSections[sIndex].quizQuestions = newQuestions
            this.setState({ quizSections })
            this.setState({ isAddQuestionFromBankOpen: false })
          }}
        />
      </BSModal>
    )
  }

  renderPreviewModal = () => (
    <BSModal
      isOpen={this.state.isPreviewModalOpen}
      backdropClassName="modal-backdrop-show"
      modalClassName="modal-dialog-shadow-none"
      contentClassName="border-14px"
      centered={true}
    >
      <div className="p-0 modal-body">
        <div
          className="bg-brand text-light d-flex"
          style={{
            padding: "0.5rem 2rem",
            borderTopRightRadius: "14px",
            borderTopLeftRadius: "14px",
          }}
        >
          <div>
            Duration:{" "}
            {moment
              .duration(
                this.state.quizData.timeLimit
                  ? this.state.quizData.timeLimit
                  : "00:15:00"
              )
              .asMinutes()}{" "}
            Mins.
          </div>
          <div className="mx-auto">
            Total Questions: {this.state.totalQuestions}
          </div>
          <div>Total Marks: {this.state.totalMark}</div>
        </div>

        <div style={{ padding: "2rem" }}>
          <h5 className="text-brand font-weight-bold">{this.state.title}</h5>
          <p className="mt-4">{this.state.instruction}</p>

          {this.state.hasSection
            ? this.state.quizSections.map((section, index) => (
                <div className="py-3 mt-3 border-top border-muted" key={index}>
                  <div className="d-flex align-items-center">
                    <h5 className="m-0 text-brand" style={{ width: "30%" }}>
                      {section.title}
                    </h5>
                    <div
                      className="mx-auto text-muted font-weight-bold"
                      style={{ width: "30%" }}
                    >
                      {section.quizQuestions.length} Questions
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ width: "30%" }}
                    >
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          backgroundColor: "rgba(0,0,0,0.2)",
                          height: "2rem",
                          width: "2rem",
                          borderRadius: 6,
                        }}
                      >
                        {this.calcTotalMarks(index)}
                      </div>
                      <div className="ml-2">Marks</div>
                    </div>
                  </div>
                  <p className="m-0 mt-3">{section.description}</p>
                </div>
              ))
            : null}
          <div className="my-3 border-top border-muted" />

          <form
            onSubmit={e => {
              e.preventDefault()
              this.state.isRead && this.setState({ isPreviewModalOpen: false })
            }}
          >
            <FormGroup check>
              <Input
                id="read"
                name="read"
                type="checkbox"
                value={this.state.isRead}
                onChange={e => this.setState({ isRead: e.target.checked })}
                required
              />
              <Label htmlFor="read" check className="text-muted">
                I have read and understood the given instructions
              </Label>
            </FormGroup>

            <div className="mt-3 d-flex justify-content-end">
              <button
                className="px-3 py-1 btn btn-secondary"
                style={{ borderRadius: 12 }}
                type="submit"
              >
                Start Quiz
              </button>
            </div>
          </form>
        </div>
      </div>
    </BSModal>
  )

  renderQuestionItem = (que, qIndex, index) => {
    const isOpen = this.state.questionOpen === `${index}-${qIndex}`
    const isLastQuestion =
      this.state.quizSections.length - 1 === index &&
      this.state.quizSections[index].quizQuestions.length - 1 === qIndex
    let perAnswerMarks =
      (que.type === "MULTIPLE_ANSWER" ||
        que.type === "MATCHING" ||
        que.type === "SEQUENCING") &&
      que.answers.length !== 0 &&
      que.answers.length !== undefined
        ? Math.floor(que.mark / que.answers.length)
        : parseInt(que.mark)
    if (perAnswerMarks === 0) {
      perAnswerMarks = 1
    }

    // const browserUrl = window.webkitURL || window.URL

    return (
      <Draggable
        draggableId={que.draggableId}
        index={qIndex}
        key={que.draggableId}
      >
        {(provided, snapshot) => (
          <div
            className={`px-5 py-3 question-card ${isOpen && "rounded my-2"}`}
            data-dragging={snapshot.isDragging}
            key={qIndex}
            {...provided.draggableProps}
            ref={provided.innerRef}
          >
            <i
              className="cursor-grab fas fa-grip-vertical text-muted d-flex align-items-center justify-content-center"
              style={{
                position: "absolute",
                height: 30,
                width: 30,
                top: "1.5rem",
                left: "calc(1.5rem - 15px)",
                fontSize: 16,
              }}
              {...provided.dragHandleProps}
            />
            <i
              onClick={() =>
                this.setState({
                  questionOpen: isOpen ? null : `${index}-${qIndex}`,
                })
              }
              className={`fas fa-chevron-${
                isOpen ? "up" : "down"
              } rounded cursor-pointer text-muted d-flex align-items-center justify-content-center`}
              style={{
                position: "absolute",
                height: 40,
                width: 40,
                top: "calc(50% - 20px)",
                right: "calc(1.5rem - 20px)",
                fontSize: 16,
              }}
            />
            {isOpen ? (
              <>
                <FormGroup>
                  <div className="row align-items-start justify-content-start">
                    <div className="col-row" style={{ paddingLeft: "32px" }}>
                      <span className="mr-auto">
                        {this.calcQueNumber(index, qIndex)}.
                      </span>
                      <Label
                        htmlFor="questionType"
                        className="m-0 mt-0 text-left font-weight-bold text-dark w-500"
                      >
                        Question Type
                      </Label>
                    </div>

                    <div className="m-0 mt-0 col-4">
                      <select
                        className="bg-white border align-items-right custom-select border-dark"
                        value={que.type}
                        onChange={e => {
                          const value = e.target.value
                          let sections = this.state.quizSections
                          sections[index].quizQuestions[qIndex].type = value
                          if (value === "LONG_ANSWER") {
                            sections[index].quizQuestions[qIndex].questions = []
                            sections[index].quizQuestions[qIndex].answers = []
                            sections[index].quizQuestions[qIndex].mark = 5
                          } else if (value === "SHORT_ANSWER") {
                            sections[index].quizQuestions[qIndex].questions = []
                            sections[index].quizQuestions[qIndex].answers = []
                            sections[index].quizQuestions[qIndex].mark = 3
                          } else if (value === "TRUE_OR_FALSE") {
                            sections[index].quizQuestions[qIndex].questions = [
                              "",
                            ]
                            sections[index].quizQuestions[qIndex].answers = [
                              "true",
                            ]
                            sections[index].quizQuestions[qIndex].mark = 1
                          } else if (value === "SEQUENCING") {
                            sections[index].quizQuestions[qIndex].questions = [
                              "1",
                            ]
                            sections[index].quizQuestions[qIndex].answers = [""]
                            sections[index].quizQuestions[qIndex].mark = 1
                          } else if (value === "FILL_IN_THE_BLANKS") {
                            sections[index].quizQuestions[qIndex].questions = [
                              "",
                            ]
                            sections[index].quizQuestions[qIndex].answers = [""]
                            sections[index].quizQuestions[qIndex].mark = 1
                          } else if (
                            value === "MULTIPLE_CHOICE" ||
                            value === "MULTIPLE_ANSWER"
                          ) {
                            sections[index].quizQuestions[qIndex].questions = [
                              "",
                            ]
                            sections[index].quizQuestions[qIndex].answers = []
                            sections[index].quizQuestions[qIndex].mark = 1
                          }
                          this.setState({ quizSections: sections })
                        }}
                      >
                        {questionTypes.map(queType => (
                          <option
                            key={queType.value}
                            value={queType.value}
                            label={queType.label}
                          />
                        ))}
                      </select>
                    </div>
                  </div>
                </FormGroup>
                {que.type === "FILL_IN_THE_BLANKS" && (
                  <FormGroup className="mb-1">
                    <div className="row align-items-start justify-content-start">
                      <div className="col-2"></div>
                      <div className="col-8">
                        <span style={{ color: "#abadae" }}>
                          Use 4 underscores `____` for mentioning blanks.
                        </span>
                      </div>
                    </div>
                  </FormGroup>
                )}
                <FormGroup>
                  <div className="row align-items-start justify-content-start">
                    <div className="col-2">
                      <Label
                        htmlFor="questionType"
                        className="m-0 mt-2 text-right font-weight-bold text-dark w-100"
                      >
                        Question
                      </Label>
                    </div>
                    <div className="col-8">
                      <div style={{ position: "relative" }}>
                        <div
                          className="px-3 py-1 ml-2 mt-2 cursor-pointer"
                          style={{
                            // backgroundColor: !que.subjectTopic && "rgba(2, 38, 54, 0.29)",
                            // color: !que.subjectTopic && "#022636",
                            backgroundColor: "rgba(2, 38, 54, 0.29)",
                            color: "#022636",
                            borderRadius: "5px",
                            bottom: "10px",
                            position: "absolute",
                            textAlign: "left",
                            zIndex: "1",
                          }}
                        >
                          <div
                            style={{
                              float: "left",
                            }}
                            onClick={() =>
                              this.setState({
                                selectedTopic: que.subjectTopic,
                                addTopicIn: {
                                  section: index,
                                  question: qIndex,
                                },
                                addTopicModalOpen: true,
                              })
                            }
                          >
                            {(que.subjectTopic && "Topic: ") || ""}{" "}
                            {(que.subjectTopic && que.subjectTopic.name) ||
                              "Add Topic"}
                          </div>
                          {que.subjectTopic && (
                            <div
                              style={{
                                float: "right",
                              }}
                              className="cursor-pointer ml-2"
                              onClick={() => {
                                let quizSections = this.state.quizSections
                                quizSections[index].quizQuestions[
                                  qIndex
                                ].subjectTopic = ""
                                this.setState({
                                  quizSections,
                                  addTopicModalOpen: false,
                                })
                              }}
                            >
                              X
                            </div>
                          )}
                        </div>
                        <textarea
                          value={que.text}
                          onChange={e => {
                            const value = e.target.value
                            let quizSections = this.state.quizSections
                            quizSections[index].quizQuestions[
                              qIndex
                            ].text = value
                            this.setState({ quizSections })
                          }}
                          placeholder="Enter your Question here"
                          id="quizInstructions"
                          name="quizInstructions"
                          className="bg-white"
                          rows="5"
                          style={{
                            padding: "0.375rem 0.75rem",
                            border: "1px solid #e7e7e7",
                            width: "100%",
                            borderRadius: 5,
                            resize: "vertical",
                            position: "relative",
                          }}
                        />
                      </div>
                      <div>
                        <Button
                          size="sm"
                          className="px-3 mt-2 ml-auto rounded-pill"
                          style={{
                            position: "relative",
                            backgroundColor: "#dcf9f7",
                            borderWidth: "0",
                            color: "#0a1414",
                          }}
                        >
                          <i className="fas fa-paperclip"></i>
                          <span className="ml-2">Attach files</span>
                          <input
                            type="file"
                            accept=".pdf,.gif,.jpg,.jpeg,.png"
                            style={{
                              opacity: 0,
                              position: "absolute",
                              top: 0,
                              left: 0,
                              bottom: 0,
                              right: 0,
                              width: "100%",
                              height: "100%",
                            }}
                            onChange={e => {
                              const selectedFile = e.target.files[0]
                              if (selectedFile.size > 50 * 1000 * 1000) {
                                this.showErrorNoti(
                                  "File is too big (limit: 50MB)"
                                )
                                return
                              }
                              if (
                                selectedFile.name.endsWith(".pdf") ||
                                selectedFile.name.endsWith(".gif") ||
                                selectedFile.name.endsWith(".jpg") ||
                                selectedFile.name.endsWith(".jpeg") ||
                                selectedFile.name.endsWith(".png")
                              ) {
                                const quizSections = this.state.quizSections
                                quizSections[index].quizQuestions[
                                  qIndex
                                ].attachment = selectedFile
                                this.setState({ quizSections })
                              } else {
                                this.props.showNotification(
                                  DANGER_TOAST,
                                  VALIDATION_MSG.OOPS,
                                  "Attach a pdf/image file..."
                                )
                              }
                            }}
                          />
                        </Button>
                        {que.attachment &&
                          que.attachment.name &&
                          que.attachment.name.endsWith(".pdf") && (
                            <div className="mt-3  rounded border-muted d-flex flex-row">
                              {/* <Document
                              file={
                                que.attachment.lastModified
                                  ? que.attachment
                                  : que.attachment.pdfURL
                              }
                              onLoadSuccess={pdfPageNo =>
                                this.setState({ pdfPageNo })
                              }
                              options={{}}
                            >
                              {Array.from(
                                new Array(this.state.pdfPageNo),
                                (el, index) => (
                                  <Page
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                  />
                                )
                              )}
                            </Document> */}
                              <div
                                className="px-3 py-2 mt-1 rounded-lg d-flex align-items-center border-top border-muted flex-grow-1"
                                style={{ backgroundColor: "#094353" }}
                              >
                                <img
                                  style={{
                                    width: 20,
                                    height: 20,
                                    objectFit: "contain",
                                    objectPosition: "center",
                                    // filter: "invert(1)",
                                  }}
                                  src={require("../../../assets/images/assignment/PDF.png")}
                                  alt=""
                                />
                                <p
                                  className="my-0 ml-3 mr-auto "
                                  style={{ color: "white" }}
                                >
                                  {que.attachment.name}
                                </p>
                                <span
                                  className="text-black"
                                  style={{ color: "white" }}
                                >
                                  {this.bytesToSize(que.attachment.size)}
                                </span>
                              </div>
                              <div
                                className="px-2 mt-1 rounded-lg d-flex align-items-center border-top border-muted"
                                style={{
                                  backgroundColor: "#ef385c",
                                  color: "white",
                                }}
                              >
                                <button
                                  type="button"
                                  className="btn-close"
                                  aria-label="Close"
                                  onClick={() => {
                                    const quizSections = this.state.quizSections
                                    quizSections[index].quizQuestions[
                                      qIndex
                                    ].attachment = ""
                                    this.setState({ quizSections })
                                  }}
                                >
                                  &times;
                                </button>
                              </div>

                              {/* <UncontrolledDropdown direction="right">
                                <DropdownToggle
                                  tag="i"
                                  className="p-2 mx-2 cursor-pointer fas fa-ellipsis-v text-muted"
                                ></DropdownToggle>
                                <DropdownMenu
                                  tag="div"
                                  className="px-2 py-2 rounded border-1 border-muted"
                                >
                                  <div
                                    className="px-2 py-1 cursor-pointer d-flex align-items-center"
                                    onClick={() => {
                                      if (que.attachment.lastModified) {
                                        const browserUrl =
                                          window.webkitURL || window.URL
                                        const url = browserUrl.createObjectURL(
                                          que.attachment
                                        )
                                        const a = document.createElement("a")
                                        a.href = url
                                        a.download = que.attachment.name
                                        a.click()
                                      } else {
                                        const a = document.createElement("a")
                                        a.href = que.attachment.pdfURL
                                        a.download = que.attachment.name
                                        a.click()
                                      }
                                    }}
                                  >
                                    <i className="fas fa-download text-muted" />
                                    <span className="ml-2">Download</span>
                                  </div>
                                  <div
                                    className="px-2 py-1 cursor-pointer d-flex align-items-center"
                                    onClick={() => {
                                      const quizSections = this.state
                                        .quizSections
                                      quizSections[index].quizQuestions[
                                        qIndex
                                      ].attachment = ""
                                      this.setState({ quizSections })
                                    }}
                                  >
                                    <i className="fas fa-times text-muted" />
                                    <span className="ml-2">Remove</span>
                                  </div>
                                </DropdownMenu>
                              </UncontrolledDropdown> */}
                            </div>
                          )}
                        {que.attachment &&
                          que.attachment.name &&
                          que.attachment.name.endsWith(".png") && (
                            <div className="mt-3 rounded border-muted d-flex flex-row">
                              <div
                                className="px-3 py-2 mt-1 rounded-lg d-flex align-items-center border-top border-muted flex-grow-1"
                                style={{ backgroundColor: "#094353" }}
                              >
                                <img
                                  style={{
                                    width: 20,
                                    height: 20,
                                    objectFit: "contain",
                                    objectPosition: "center",
                                    // filter: "invert(1)",
                                  }}
                                  src={require("../../../assets/images/assignment/PNG.png")}
                                  alt=""
                                />
                                <p
                                  className="my-0 ml-3 mr-auto "
                                  style={{ color: "white" }}
                                >
                                  {que.attachment.name}
                                </p>
                                <span
                                  className="text-black"
                                  style={{ color: "white" }}
                                >
                                  {this.bytesToSize(que.attachment.size)}
                                </span>
                              </div>
                              <div
                                className="px-2 mt-1 rounded-lg d-flex align-items-center border-top border-muted"
                                style={{
                                  backgroundColor: "#ef385c",
                                  color: "white",
                                }}
                              >
                                <button
                                  type="button"
                                  className="btn-close"
                                  aria-label="Close"
                                  onClick={() => {
                                    const quizSections = this.state.quizSections
                                    quizSections[index].quizQuestions[
                                      qIndex
                                    ].attachment = ""
                                    this.setState({ quizSections })
                                  }}
                                >
                                  &times;
                                </button>
                              </div>
                            </div>
                          )}
                        {que.attachment &&
                          que.attachment.name &&
                          que.attachment.name.endsWith(".doc") && (
                            <div className="mt-3 rounded border-muted d-flex flex-row">
                              <div
                                className="px-3 py-2 mt-1 rounded-lg d-flex align-items-center border-top border-muted flex-grow-1"
                                style={{ backgroundColor: "#094353" }}
                              >
                                <img
                                  style={{
                                    width: 20,
                                    height: 20,
                                    objectFit: "contain",
                                    objectPosition: "center",
                                    // filter: "invert(1)",
                                  }}
                                  src={require("../../../assets/images/assignment/DOC.png")}
                                  alt=""
                                />
                                <p
                                  className="my-0 ml-3 mr-auto "
                                  style={{ color: "white" }}
                                >
                                  {que.attachment.name}
                                </p>
                                <span
                                  className="text-black"
                                  style={{ color: "white" }}
                                >
                                  {this.bytesToSize(que.attachment.size)}
                                </span>
                              </div>
                              <div
                                className="px-2 mt-1 rounded-lg d-flex align-items-center border-top border-muted"
                                style={{
                                  backgroundColor: "#ef385c",
                                  color: "white",
                                }}
                              >
                                <button
                                  type="button"
                                  className="btn-close"
                                  aria-label="Close"
                                  onClick={() => {
                                    const quizSections = this.state.quizSections
                                    quizSections[index].quizQuestions[
                                      qIndex
                                    ].attachment = ""
                                    this.setState({ quizSections })
                                  }}
                                >
                                  &times;
                                </button>
                              </div>
                            </div>
                          )}
                        {que.attachment &&
                          que.attachment.name &&
                          (que.attachment.name.endsWith(".gif") ||
                            que.attachment.name.endsWith(".jpg") ||
                            que.attachment.name.endsWith(".jpeg")) && (
                            <div className="mt-3  rounded border-muted d-flex flex-row">
                              {/* <img src={que.attachment.lastModified ? browserUrl.createObjectURL(que.attachment) : que.attachment.pdfURL} alt="NA"/> */}
                              <div
                                className="px-3 py-2 mt-1 rounded-lg d-flex align-items-center border-top border-muted flex-grow-1"
                                style={{ backgroundColor: "#094353" }}
                              >
                                <img
                                  style={{
                                    width: 20,
                                    height: 20,
                                    objectFit: "contain",
                                    objectPosition: "center",
                                    // filter: "invert(1)",
                                  }}
                                  src={require("../../../assets/images/assignment/JPG.png")}
                                  alt=""
                                />
                                <p
                                  className="my-0 ml-3 mr-auto"
                                  style={{ color: "white" }}
                                >
                                  {que.attachment.name}
                                </p>
                                <span
                                  className="text-black"
                                  style={{ color: "white" }}
                                >
                                  {this.bytesToSize(que.attachment.size)}
                                </span>
                              </div>
                              <div
                                className="px-2 mt-1 rounded-lg d-flex align-items-center border-top border-muted"
                                style={{
                                  backgroundColor: "#ef385c",
                                  color: "white",
                                }}
                              >
                                <button
                                  type="button"
                                  className="btn-close"
                                  aria-label="Close"
                                  onClick={() => {
                                    const quizSections = this.state.quizSections
                                    quizSections[index].quizQuestions[
                                      qIndex
                                    ].attachment = ""
                                    this.setState({ quizSections })
                                  }}
                                >
                                  &times;
                                </button>
                              </div>

                              {/* <UncontrolledDropdown direction="right">
                                <DropdownToggle
                                  tag="i"
                                  className="p-2 mx-2 cursor-pointer fas fa-ellipsis-v text-muted"
                                ></DropdownToggle>
                                <DropdownMenu
                                  tag="div"
                                  className="px-2 py-2 rounded border-1 border-muted"
                                >
                                  <div
                                    className="px-2 py-1 cursor-pointer d-flex align-items-center"
                                    onClick={() => {
                                      if (que.attachment.lastModified) {
                                        const browserUrl =
                                          window.webkitURL || window.URL
                                        const url = browserUrl.createObjectURL(
                                          que.attachment
                                        )
                                        const a = document.createElement("a")
                                        a.href = url
                                        a.download = que.attachment.name
                                        a.click()
                                      } else {
                                        const a = document.createElement("a")
                                        a.href = que.attachment.pdfURL
                                        a.download = que.attachment.name
                                        a.click()
                                      }
                                    }}
                                  >
                                    <i className="fas fa-download text-muted" />
                                    <span className="ml-2">Download</span>
                                  </div>
                                  <div
                                    className="px-2 py-1 cursor-pointer d-flex align-items-center"
                                    onClick={() => {
                                      const quizSections = this.state
                                        .quizSections
                                      quizSections[index].quizQuestions[
                                        qIndex
                                      ].attachment = ""
                                      this.setState({ quizSections })
                                    }}
                                  >
                                    <i className="fas fa-times text-muted" />
                                    <span className="ml-2">Remove</span>
                                  </div>
                                </DropdownMenu>
                              </UncontrolledDropdown> */}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </FormGroup>

                <div style={{ maxWidth: 700 }}>
                  {/* True false */}
                  {que.type === "TRUE_OR_FALSE" && (
                    <>
                      <div className="mb-2 d-flex">
                        <div className="col-8">
                          <h5 className="text-dark ">Options</h5>
                        </div>
                        <div className="col-4 d-flex justify-content-center">
                          <h5 className="text-dark ">Correct Answer</h5>
                        </div>
                      </div>

                      <div
                        className="px-2 py-3 mb-3 rounded d-flex"
                        style={{ border: "1px solid #e7e7e7" }}
                      >
                        <div className="col-8">
                          <h5 className="m-0 text-dark">True</h5>
                        </div>
                        <div className="col-4 d-flex align-items-center justify-content-center">
                          <Input
                            type="radio"
                            className="mb-1"
                            checked={que.answers[0] === "true"}
                            onChange={e => {
                              let quizSections = this.state.quizSections
                              quizSections[index].quizQuestions[
                                qIndex
                              ].answers[0] = "true"
                              this.setState({ quizSections })
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="px-2 py-3 mb-3 rounded d-flex"
                        style={{ border: "1px solid #e7e7e7" }}
                      >
                        <div className="col-8">
                          <h5 className="m-0 text-dark">False</h5>
                        </div>
                        <div className="col-4 d-flex align-items-center justify-content-center">
                          <Input
                            type="radio"
                            className="mb-1"
                            checked={que.answers[0] === "false"}
                            onChange={e => {
                              let quizSections = this.state.quizSections
                              quizSections[index].quizQuestions[
                                qIndex
                              ].answers[0] = "false"
                              this.setState({ quizSections })
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {/* Multiple choice */}
                  {que.type === "MULTIPLE_CHOICE" && (
                    <>
                      <div className="mb-2 d-flex">
                        <div className="col-8">
                          <h5 className="text-dark ">Options</h5>
                        </div>
                        <div className="col-4 d-flex justify-content-center">
                          <h5 className="text-dark ">Correct Answer</h5>
                        </div>
                      </div>
                      {que.questions.map((ans, aIndex) => {
                        const isSelected = que.answers[0] === ans
                        return (
                          <div
                            key={aIndex}
                            className="px-2 py-1 mb-3 rounded d-flex"
                            style={{ border: "1px solid #e7e7e7" }}
                          >
                            <div className="col-8">
                              <Input
                                value={ans}
                                onChange={e => {
                                  let quizSections = this.state.quizSections
                                  quizSections[index].quizQuestions[
                                    qIndex
                                  ].questions[aIndex] = e.target.value
                                  if (isSelected) {
                                    quizSections[index].quizQuestions[
                                      qIndex
                                    ].answers[0] = e.target.value
                                  }
                                  this.setState({ quizSections })
                                }}
                                type="text"
                                name="option"
                                placeholder="Enter Answer"
                                className="bg-white border-0"
                              />
                            </div>
                            <div className="col-4 d-flex align-items-center justify-content-center">
                              <Input
                                type="radio"
                                className="mb-1 mr-3"
                                checked={ans && isSelected}
                                onChange={e => {
                                  let quizSections = this.state.quizSections
                                  if (e.target.checked) {
                                    quizSections[index].quizQuestions[
                                      qIndex
                                    ].answers[0] = ans
                                  }
                                  this.setState({ quizSections })
                                }}
                              />
                              <button
                                className="mb-0 ml-auto"
                                style={{
                                  fontSize: "1.2em",
                                  display:
                                    que.questions.length === 1
                                      ? "none"
                                      : "block",
                                }}
                                onClick={() => {
                                  let quizSections = this.state.quizSections
                                  quizSections[index].quizQuestions[
                                    qIndex
                                  ].questions = quizSections[
                                    index
                                  ].quizQuestions[qIndex].questions.filter(
                                    qu => qu !== ans
                                  )
                                  this.setState({ quizSections })
                                }}
                              >
                                &times;
                              </button>
                            </div>
                          </div>
                        )
                      })}
                      <div>
                        <button
                          className="px-2 py-1 btn btn-light text-muted"
                          onClick={() => {
                            let quizSections = this.state.quizSections
                            quizSections[index].quizQuestions[
                              qIndex
                            ].questions.push("")
                            this.setState({ quizSections })
                          }}
                        >
                          Add Option
                        </button>
                      </div>
                    </>
                  )}
                  {/* Multiple Answers Option */}
                  {que.type === "MULTIPLE_ANSWER" && (
                    <>
                      <div className="mb-2 d-flex">
                        <div className="col-8">
                          <h5 className="text-dark ">Options</h5>
                        </div>
                        <div className="col-4 d-flex justify-content-center">
                          <h5 className="text-dark ">Correct Answer</h5>
                        </div>
                      </div>
                      {que.questions.map((ans, aIndex) => {
                        const isSelected = que.answers.includes(ans)
                        return (
                          <div
                            key={aIndex}
                            className="px-2 py-1 mb-3 rounded d-flex"
                            style={{ border: "1px solid #e7e7e7" }}
                          >
                            <div className="col-8">
                              <Input
                                value={ans}
                                onChange={e => {
                                  let quizSections = this.state.quizSections
                                  quizSections[index].quizQuestions[
                                    qIndex
                                  ].questions[aIndex] = e.target.value
                                  if (isSelected) {
                                    const ansIndex = que.answers.findIndex(
                                      val => val === ans
                                    )
                                    quizSections[index].quizQuestions[
                                      qIndex
                                    ].answers[ansIndex] = e.target.value
                                  }
                                  quizSections[index].quizQuestions[
                                    qIndex
                                  ].answers = quizSections[index].quizQuestions[
                                    qIndex
                                  ].answers.filter(item => item)
                                  this.setState({ quizSections })
                                }}
                                type="text"
                                name="option"
                                placeholder="Enter Answer"
                                className="bg-white border-0"
                              />
                            </div>
                            <div className="col-4 d-flex align-items-center justify-content-center">
                              <Input
                                type="checkbox"
                                className="mb-1 mr-3 subject-checkbox"
                                checked={ans && isSelected}
                                onChange={e => {
                                  let quizSections = this.state.quizSections
                                  if (e.target.checked) {
                                    quizSections[index].quizQuestions[
                                      qIndex
                                    ].answers.push(ans)
                                  } else {
                                    quizSections[index].quizQuestions[
                                      qIndex
                                    ].answers = quizSections[
                                      index
                                    ].quizQuestions[qIndex].answers.filter(
                                      as => as !== ans
                                    )
                                  }
                                  quizSections[index].quizQuestions[
                                    qIndex
                                  ].answers = quizSections[index].quizQuestions[
                                    qIndex
                                  ].answers.filter(item => item)
                                  quizSections[index].quizQuestions[
                                    qIndex
                                  ].mark = parseInt(
                                    perAnswerMarks * que.answers.length
                                  )
                                  this.setState({ quizSections })
                                }}
                              />
                              <button
                                className="mb-0 ml-auto"
                                style={{
                                  fontSize: "1.2em",
                                  display:
                                    que.questions.length === 1
                                      ? "none"
                                      : "block",
                                }}
                                onClick={() => {
                                  let quizSections = this.state.quizSections
                                  quizSections[index].quizQuestions[
                                    qIndex
                                  ].questions = quizSections[
                                    index
                                  ].quizQuestions[qIndex].questions.filter(
                                    qu => qu !== ans
                                  )
                                  quizSections[index].quizQuestions[
                                    qIndex
                                  ].mark = parseInt(
                                    perAnswerMarks * que.answers.length
                                  )
                                  quizSections[index].quizQuestions[
                                    qIndex
                                  ].answers = quizSections[index].quizQuestions[
                                    qIndex
                                  ].answers.filter(item => item)
                                  this.setState({ quizSections })
                                }}
                              >
                                &times;
                              </button>
                            </div>
                          </div>
                        )
                      })}
                      <div>
                        <button
                          className="px-2 py-1 btn btn-light text-muted"
                          onClick={() => {
                            let quizSections = this.state.quizSections
                            quizSections[index].quizQuestions[
                              qIndex
                            ].questions.push("")
                            quizSections[index].quizQuestions[
                              qIndex
                            ].mark = perAnswerMarks
                            quizSections[index].quizQuestions[
                              qIndex
                            ].answers = quizSections[index].quizQuestions[
                              qIndex
                            ].answers.filter(item => item)
                            this.setState({ quizSections })
                          }}
                        >
                          Add Option
                        </button>
                      </div>
                    </>
                  )}
                  {/* Short Answer Type */}
                  {que.type === "SHORT_ANSWER" && <></>}
                  {/* Long Answer Type */}
                  {que.type === "LONG_ANSWER" && <></>}
                  {/* Fill in the blank Type */}
                  {que.type === "FILL_IN_THE_BLANKS" && (
                    <>
                      <div className="mb-2 d-flex">
                        <div className="col-3 d-flex align-items-center">
                          <h5 className="text-dark ">Answer</h5>
                        </div>
                        <div className="col-9 d-flex align-items-center">
                          <Input
                            placeholder="Correct Answer"
                            value={que.answers[0]}
                            onChange={e => {
                              const value = e.target.value
                              let quizSections = this.state.quizSections
                              quizSections[index].quizQuestions[
                                qIndex
                              ].answers[0] = value
                              this.setState({ quizSections })
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {que.type === "SEQUENCING" && (
                    <>
                      <div className="mb-2 d-flex">
                        <div className="col-4">
                          <h5 className="text-dark">Order</h5>
                        </div>
                        <div className="col-8 d-flex">
                          <h5 className="text-dark ml-3">Answer</h5>
                        </div>
                      </div>
                      {que.questions.map((quest, aIndex) => {
                        return (
                          <div
                            key={aIndex}
                            className="px-2 py-1 mb-3 rounded d-flex"
                            style={{ border: "1px solid #e7e7e7" }}
                          >
                            <div className="col-4 d-flex align-items-center">
                              <h6 className="mb-0 text-dark">{quest}</h6>
                            </div>
                            <div className="col-8 d-flex align-items-center justify-content-center">
                              <Input
                                value={que.answers[aIndex]}
                                onChange={e => {
                                  let quizSections = this.state.quizSections
                                  quizSections[index].quizQuestions[
                                    qIndex
                                  ].answers[aIndex] = e.target.value
                                  this.setState({ quizSections })
                                }}
                                type="text"
                                name="option"
                                placeholder="Enter Answer"
                                className="bg-white border-0"
                              />
                              <button
                                className="mb-0 ml-auto"
                                style={{
                                  fontSize: "1.2em",
                                  display:
                                    que.questions.length === 1
                                      ? "none"
                                      : "block",
                                }}
                                onClick={() => {
                                  let quizSections = this.state.quizSections
                                  quizSections[index].quizQuestions[
                                    qIndex
                                  ].questions.splice(aIndex, 1)
                                  quizSections[index].quizQuestions[
                                    qIndex
                                  ].questions = quizSections[
                                    index
                                  ].quizQuestions[qIndex].questions.map(
                                    (_, index) => `${index + 1}`
                                  )
                                  quizSections[index].quizQuestions[
                                    qIndex
                                  ].answers.splice(aIndex, 1)
                                  quizSections[index].quizQuestions[
                                    qIndex
                                  ].mark = parseInt(
                                    perAnswerMarks * que.answers.length
                                  )
                                  this.setState({ quizSections })
                                }}
                              >
                                &times;
                              </button>
                            </div>
                          </div>
                        )
                      })}
                      <div>
                        <button
                          className="px-2 py-1 btn btn-light text-muted"
                          onClick={() => {
                            let quizSections = this.state.quizSections
                            quizSections[index].quizQuestions[
                              qIndex
                            ].questions.push(`${que.questions.length + 1}`)
                            quizSections[index].quizQuestions[
                              qIndex
                            ].answers.push("")
                            quizSections[index].quizQuestions[
                              qIndex
                            ].mark = parseInt(
                              perAnswerMarks * que.answers.length
                            )
                            this.setState({ quizSections })
                          }}
                        >
                          Add Option
                        </button>
                      </div>
                    </>
                  )}
                  {que.type === "MATCHING" && (
                    <>
                      <div className="mb-2 d-flex">
                        <div className="col-6">
                          <h5 className="text-dark ml-3">Match</h5>
                        </div>
                        <div className="col-6 d-flex">
                          <h5 className="text-dark ml-3">Answer</h5>
                        </div>
                      </div>
                      {que.questions.map((quest, aIndex) => {
                        return (
                          <div
                            key={aIndex}
                            className="px-2 py-1 mb-3 rounded d-flex"
                            style={{ border: "1px solid #e7e7e7" }}
                          >
                            <div className="col-6 d-flex align-items-center">
                              <Input
                                value={que.questions[aIndex]}
                                onChange={e => {
                                  let quizSections = this.state.quizSections
                                  quizSections[index].quizQuestions[
                                    qIndex
                                  ].questions[aIndex] = e.target.value
                                  this.setState({ quizSections })
                                }}
                                type="text"
                                name="option"
                                placeholder="Enter Match"
                                className="bg-white border-0"
                              />
                            </div>
                            <div className="col-6 d-flex align-items-center justify-content-center">
                              <Input
                                value={que.answers[aIndex]}
                                onChange={e => {
                                  let quizSections = this.state.quizSections
                                  quizSections[index].quizQuestions[
                                    qIndex
                                  ].answers[aIndex] = e.target.value
                                  quizSections[index].quizQuestions[
                                    qIndex
                                  ].mark = parseInt(
                                    perAnswerMarks * que.answers.length
                                  )
                                  this.setState({ quizSections })
                                }}
                                type="text"
                                name="option"
                                placeholder="Enter Answer"
                                className="bg-white border-0"
                              />
                              <button
                                className="mb-0 ml-auto"
                                style={{
                                  fontSize: "1.2em",
                                  display:
                                    que.questions.length === 1
                                      ? "none"
                                      : "block",
                                }}
                                onClick={() => {
                                  let quizSections = this.state.quizSections
                                  quizSections[index].quizQuestions[
                                    qIndex
                                  ].questions.splice(aIndex, 1)
                                  quizSections[index].quizQuestions[
                                    qIndex
                                  ].answers.splice(aIndex, 1)
                                  this.setState({ quizSections })
                                  quizSections[index].quizQuestions[
                                    qIndex
                                  ].mark = parseInt(
                                    perAnswerMarks * que.answers.length
                                  )
                                }}
                              >
                                &times;
                              </button>
                            </div>
                          </div>
                        )
                      })}
                      <div>
                        <button
                          className="px-2 py-1 btn text-green"
                          onClick={() => {
                            let quizSections = this.state.quizSections
                            quizSections[index].quizQuestions[
                              qIndex
                            ].questions.push("")
                            quizSections[index].quizQuestions[
                              qIndex
                            ].answers.push("")
                            quizSections[index].quizQuestions[
                              qIndex
                            ].mark = parseInt(
                              perAnswerMarks * que.answers.length
                            )
                            this.setState({ quizSections })
                          }}
                        >
                          <i className="fas fa-plus"></i>
                          <span className="ml-2">Add Row</span>
                        </button>

                        <p className="mt-4 mb-2 text-muted">
                          You can create additional answers to increase the
                          difficulty level
                        </p>
                        {que.distractorAnswers &&
                          que.distractorAnswers.map((quest, aIndex) => {
                            return (
                              <div
                                key={aIndex}
                                className="px-2 py-1 mb-3 rounded d-flex"
                                style={{ border: "1px solid #e7e7e7" }}
                              >
                                <div className="col-6 d-flex align-items-center">
                                  <Input
                                    value={quest}
                                    onChange={e => {
                                      let quizSections = this.state.quizSections
                                      quizSections[index].quizQuestions[
                                        qIndex
                                      ].distractorAnswers[aIndex] =
                                        e.target.value
                                      this.setState({ quizSections })
                                    }}
                                    type="text"
                                    name="option"
                                    placeholder="Enter additonal answer"
                                    className="bg-white border-0"
                                  />
                                </div>
                                <div className="col-6 d-flex align-items-center justify-content-center">
                                  <button
                                    className="mb-0 ml-auto"
                                    style={{
                                      fontSize: "1.2em",
                                    }}
                                    onClick={() => {
                                      let quizSections = this.state.quizSections
                                      quizSections[index].quizQuestions[
                                        qIndex
                                      ].distractorAnswers.splice(aIndex, 1)
                                      this.setState({ quizSections })
                                    }}
                                  >
                                    &times;
                                  </button>
                                </div>
                              </div>
                            )
                          })}
                        <button
                          className="px-2 py-1 btn text-green"
                          onClick={() => {
                            let quizSections = this.state.quizSections
                            quizSections[index].quizQuestions[qIndex]
                              .distractorAnswers
                              ? quizSections[index].quizQuestions[
                                  qIndex
                                ].distractorAnswers.push("")
                              : (quizSections[index].quizQuestions[
                                  qIndex
                                ].distractorAnswers = [""])
                            this.setState({ quizSections })
                          }}
                        >
                          <i className="fas fa-plus"></i>
                          <span className="ml-2">Add additional answer</span>
                        </button>
                      </div>
                    </>
                  )}
                </div>

                <div className="mt-5 d-flex align-items-center">
                  <h6 className="m-0 mr-2 text-dark">
                    Marks{" "}
                    {que.type === "MULTIPLE_ANSWER" ||
                    que.type === "MATCHING" ||
                    que.type === "SEQUENCING"
                      ? "per correct answer"
                      : ""}
                  </h6>
                  <Input
                    value={perAnswerMarks}
                    onChange={e => {
                      const value = e.target.valueAsNumber
                      if (value < 0) return
                      let quizSections = this.state.quizSections
                      quizSections[index].quizQuestions[qIndex].mark =
                        que.type === "MULTIPLE_ANSWER" ||
                        que.type === "MATCHING" ||
                        que.type === "SEQUENCING"
                          ? parseInt(value * que.answers.length)
                          : value
                      this.setState({ quizSections })
                    }}
                    type="number"
                    style={{ width: 60, borderRadius: 8 }}
                  />

                  <h6 className="m-0 ml-4 text-dark">Difficulty Level</h6>
                  <select
                    className="ml-2 mr-auto custom-select"
                    style={{ width: 105, borderRadius: 8 }}
                    value={que.difficulty}
                    onChange={e => {
                      const value = e.target.value
                      if (value < 0) return
                      let quizSections = this.state.quizSections
                      quizSections[index].quizQuestions[
                        qIndex
                      ].difficulty = value
                      this.setState({ quizSections })
                    }}
                  >
                    <option value="EASY">Easy</option>
                    <option value="MEDIUM">Medium</option>
                    <option value="DIFFICULT">Difficult</option>
                  </select>

                  <Button
                    className="mr-2 text-dark"
                    style={{
                      border: "0px solid #fff",
                      backgroundColor: "#8DD9CC",
                      color: "#002E2C",
                    }}
                    onClick={() => {
                      // let quizSections = this.state.quizSections
                      // let section = quizSections[index].quizQuestions
                      // section.push({
                      //   ...que,
                      //   draggableId: uuid(),
                      //   id: uuid(),
                      //   index: que.index + 1,
                      // })
                      // myLog("section", section)
                      // this.setState({ quizSections })
                      let quizSections = this.state.quizSections
                      let defaultQue = defaultQuestion()
                      defaultQue.id = defaultQue.draggableId
                      defaultQue.answers = []
                      if (que.answers) {
                        for (let ans1 of que.answers) {
                          defaultQue.answers.push(ans1)
                        }
                      }
                      defaultQue.questions = []
                      if (que.questions) {
                        for (let ques1 of que.questions) {
                          defaultQue.questions.push(ques1)
                        }
                      }
                      defaultQue.distractorAnswers = []
                      if (que.distractorAnswers) {
                        for (let disans1 of que.distractorAnswers) {
                          defaultQue.distractorAnswers.push(disans1)
                        }
                      }
                      defaultQue.attachment = que.attachment
                        ? que.attachment
                        : ""
                      defaultQue.difficulty = que.difficulty
                        ? que.difficulty
                        : ""
                      defaultQue.mark = que.mark
                      defaultQue.quizSectionId = que.quizSectionId
                      defaultQue.quizSetId = que.quizSetId
                      defaultQue.subjectChapter = que.subjectChapter
                      defaultQue.subjectChapterId = que.subjectChapterId
                      defaultQue.subjectTopic = que.subjectTopic
                      defaultQue.subjectTopicId = que.subjectTopicId
                      defaultQue.type = que.type
                      defaultQue.text = que.text
                      defaultQue.index =
                        quizSections[index].quizQuestions.length + 1
                      quizSections[index].quizQuestions.push(defaultQue)
                      this.setState({
                        quizSections,
                        questionOpen: `${index}-${quizSections[index]
                          .quizQuestions.length - 1}`,
                      })
                    }}
                  >
                    <i className="far fa-copy"></i>
                    <span className="ml-2">Duplicate</span>
                  </Button>
                  <Button
                    className="mr-2"
                    style={{
                      border: "0px solid #fff",
                      backgroundColor: "#F1859C",
                      color: "#002E2C",
                    }}
                    disabled={
                      this.state.quizSections[index].quizQuestions.length === 1
                    }
                    onClick={() => {
                      let quizSections = this.state.quizSections
                      let sec = quizSections[index].quizQuestions
                      sec.splice(qIndex, 1)
                      this.setState({ quizSections })
                    }}
                  >
                    <i className="far fa-trash-alt"></i>
                    <span className="ml-2">Delete</span>
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className="mb-1 text-dark">
                  {this.calcQueNumber(index, qIndex)}. {que.text}
                </div>
                <div className="mb-1 text-muted">
                  {questionTypes.find(qt => qt.value === que.type).label} (
                  {que.mark} {que.mark > 1 ? "Marks" : "Mark"})
                </div>
                <div className="d-flex">
                  <div className="px-3 py-1 text-white rounded-lg bg-info">
                    {(que.subjectTopic && que.subjectTopic.name) || "No Topic"}
                  </div>
                </div>
              </>
            )}
            {snapshot.isDragging ? null : this.state.hasSection &&
              isLastQuestion ? (
              <Button
                color="light"
                className="px-4 py-1 ml-3 bg-muted text-dark"
                style={{
                  position: "absolute",
                  left: 0,
                  bottom: "-2.2rem",
                  borderRadius: 0,
                  borderBottomLeftRadius: 18,
                  borderBottomRightRadius: 18,
                  backgroundColor: "rgb(141, 217, 204)",
                }}
                onClick={() => this.addNewSection("bottom")}
              >
                Add Section
              </Button>
            ) : (
              <div
                className="question-add-wrapper d-flex"
                style={{
                  position: "relative",
                  height: "2rem",
                  marginBottom: "-2rem",
                  zIndex: 100,
                }}
              >
                <div
                  className="my-auto ml-auto bg-green rounded-pill d-flex"
                  style={{
                    height: "2rem",
                    width: "2rem",
                    cursor: "pointer",
                  }}
                  onClick={() => this.addQueInSection(index, qIndex)}
                >
                  <i className="m-auto far fa-plus text-light" />
                </div>
              </div>
            )}
          </div>
        )}
      </Draggable>
    )
  }

  renderSectionItem = (sec, index) => {
    let quizSecQuestionsList = sec.quizQuestions
    const quizQuestionsMap = quizSecQuestionsList.sort(
      (a, b) => a.index - b.index
    )
    return (
      <div key={index} className="mt-2">
        {this.state.hasSection ? (
          <div
            className="px-5 py-3 bg-white"
            style={{
              border: "1px solid #e7e7e7",
              borderRadius: 16,
              maxWidth: 900,
              margin: "0px auto",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <div style={{ flex: 0.2 }}>
                <h5 className="m-0 text-dark font-weight-bold">
                  Section Title
                </h5>
              </div>
              <div style={{ flex: 0.5 }}>
                <Input
                  value={this.state.quizSections[index].title}
                  onChange={e => {
                    const value = e.target.value
                    const sections = this.state.quizSections
                    const thisSection = sections[index]
                    thisSection.title = value
                    this.setState({ quizSections: sections })
                  }}
                  type="text"
                  id={`sectionName-${index}`}
                  name={`sectionName-${index}`}
                  className="bg-white "
                />
              </div>
              <div
                style={{
                  flex: 0.3,
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <i
                  className="fas fa-times text-muted p-2 rounded cursor-pointer"
                  onClick={() => {
                    if (this.state.quizSections.length > 1) {
                      let quizSections = this.state.quizSections
                      quizSections.splice(index, 1)
                      this.setState({ quizSections })
                    } else {
                      this.toggleHasSection(false)
                    }
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ flex: 0.2 }}>
                <h5 className="m-0 text-dark font-weight-bold">Description</h5>
              </div>
              <div style={{ flex: 0.5 }}>
                <textarea
                  value={this.state.quizSections[index].description}
                  onChange={e => {
                    const quizSections = this.state.quizSections
                    const thisSection = quizSections[index]
                    thisSection.description = e.target.value
                    this.setState({ quizSections })
                  }}
                  id="sectionDescription"
                  name="sectionDescription"
                  className="bg-white"
                  style={{
                    padding: "0.375rem 0.75rem",
                    border: "1px solid #ddd",
                    width: "100%",
                    borderRadius: 5,
                  }}
                />
              </div>
              <div
                style={{
                  flex: 0.3,
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              ></div>
            </div>
          </div>
        ) : null}

        <div className="my-4" style={{ maxWidth: 900, margin: "0px auto" }}>
          <div
            className="px-5 py-3 bg-white d-flex align-items-start"
            style={{
              border: "1px solid #e7e7e7",
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
            }}
          >
            <h4 className="m-0 text-dark font-weight-bold">Questions</h4>
            <div className="ml-auto">
              <span className="mr-3">
                Total Questions:{" "}
                {this.state.quizSections[index].quizQuestions.length}
              </span>
              <span className="mr-3">
                Total Mark: {this.calcTotalMarks(index)}
              </span>
            </div>
          </div>

          <Droppable droppableId={sec.droppableId}>
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {quizQuestionsMap.map((ques, qIndex) =>
                  this.renderQuestionItem(ques, qIndex, index)
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          <div className="mt-5 d-flex align-items-center justify-content-end">
            <Button
              className="px-5 mr-2 add-question-btn"
              onClick={() => {
                let quizSections = this.state.quizSections
                quizSections[index].quizQuestions.push(defaultQuestion())
                this.setState({
                  quizSections,
                  questionOpen: `${index}-${quizSections[index].quizQuestions
                    .length - 1}`,
                })
              }}
            >
              Add New Question
            </Button>
            <Button
              className="px-4 mr-2 add-question-btn"
              onClick={() => {
                this.setState({
                  addInSec: index,
                  isAddQuestionFromBankOpen: true,
                })
              }}
            >
              Add from Question Bank
            </Button>
          </div>
        </div>
      </div>
    )
  }

  renderEditQuiz = () => {
    let quizSectionList = this.state.quizSections
    const sections = quizSectionList.sort((a, b) => a.index - b.index)

    return (
      <>
        <div
          className="px-4 py-3"
          style={{
            backgroundColor: "#0A4354",
            borderRadius: 16,
            maxWidth: 900,
            margin: "0px auto",
          }}
        >
          <FormGroup>
            <div className="row align-items-start justify-content-start">
              <div className="col-2">
                <Label
                  htmlFor="quizTitle"
                  className="m-0 mt-2 text-white font-weight-bold"
                >
                  Quiz Title
                </Label>
              </div>
              <div className="col-4">
                <Input
                  placeholder="Quiz title"
                  value={
                    this.props.location.state.reuseQuizData && this.state.title
                      ? this.state.title
                      : this.state.title
                  }
                  onChange={e => {
                    const value = e.target.value
                    this.setState({ title: value })
                  }}
                  type="text"
                  id="quizTitle"
                  name="quizTitle"
                  className="bg-white"
                />
              </div>
            </div>
          </FormGroup>

          <FormGroup>
            <div className="row align-items-start justify-content-start">
              <div className="col-2">
                <Label
                  htmlFor="instruction"
                  className="m-0 mt-1 text-white font-weight-bold"
                >
                  Instructions
                </Label>
              </div>
              <div className="col-8">
                <textarea
                  placeholder="Instructions to follow"
                  value={this.state.instruction}
                  onChange={e => this.setState({ instruction: e.target.value })}
                  id="instruction"
                  name="instruction"
                  className="bg-white"
                  style={{
                    padding: "0.375rem 0.75rem",
                    border: "0px solid #fff",
                    width: "100%",
                    borderRadius: 5,
                  }}
                />
              </div>
            </div>
          </FormGroup>

          <div className="row align-items-start justify-content-start">
            <div className="col-5">
              <FormGroup check>
                <Input
                  checked={this.state.hasSection ? true : false}
                  onChange={e => {
                    this.toggleHasSection(e.target.checked)
                  }}
                  type="checkbox"
                  id="hasSection"
                  name="hasSection"
                  check
                />
                <Label
                  htmlFor="hasSection"
                  className="m-0 mt-1 text-white font-weight-bold"
                  check
                >
                  Include sections
                </Label>
              </FormGroup>
            </div>
          </div>
        </div>
        {!this.state.hasSection && this.state.noFirstSectionButton ? (
          <div
            className="d-flex"
            style={{
              maxWidth: 900,
              margin: "0px auto",
            }}
          >
            <Button
              color="light"
              className="px-4 py-1 ml-3 bg-muted text-dark"
              style={{
                borderRadius: 0,
                borderBottomLeftRadius: 18,
                borderBottomRightRadius: 18,
              }}
              onClick={() => this.addNewSection("top")}
            >
              Add Section
            </Button>
          </div>
        ) : null}

        <DragDropContext onDragEnd={this.onDragEndHandler}>
          {sections.map(this.renderSectionItem)}
        </DragDropContext>
      </>
    )
  }

  renderQuizPreview = () => {
    if (!this.state.quizPreviewSections) return null
    const selectedSection = this.state.quizPreviewSections[
      this.state.currentSection
    ]
    const selectedQuestion =
      selectedSection.quizQuestions[this.state.currentQuestion]

    return (
      <>
        <div className="mt-2">
          <div
            className="px-3 py-4 d-flex align-items-center"
            style={{ borderRadius: 8, backgroundColor: "#f7f7f7" }}
          >
            <div className="m-0 text-brand font-weight-bold">
              {this.state.title}
            </div>
            <i
              className="ml-3 fas fa-info-circle text-green"
              style={{ fontSize: "1.2rem" }}
              onClick={() => this.setState({ isPreviewModalOpen: true })}
            />
          </div>

          <div
            className="d-flex align-items-center"
            style={{ borderBottom: "1px solid #eaeaea" }}
          >
            {this.state.hasSection
              ? this.state.quizPreviewSections &&
                this.state.quizPreviewSections.map((section, index) => {
                  const isSelected = index === this.state.currentSection

                  return (
                    <div
                      key={index}
                      onClick={() =>
                        this.setState({
                          currentSection: index,
                          currentQuestion: 0,
                        })
                      }
                      className={`py-2 px-3 cursor-pointer ${
                        isSelected ? "text-green" : "text-muted"
                      }`}
                      style={{
                        borderBottom: `4px solid ${
                          isSelected ? "#04b191" : "transparent"
                        }`,
                      }}
                    >
                      {section.title}
                    </div>
                  )
                })
              : null}
            <div className="py-2 ml-auto mr-3 text-muted">
              Total Questions: {this.state.totalQuestions}
            </div>
            <div className="py-2 text-muted">
              Total Marks: {this.state.totalMark}
            </div>
          </div>

          <div
            className="row"
            style={{
              marginLeft: 0,
              marginRight: 0,
              backgroundColor: "rgba(0, 0, 0, 0.02)",
            }}
          >
            <div className="col-md-8">
              <div className="mt-4 d-flex align-items-center">
                <div
                  className="bg-brand text-light d-flex align-items-center justify-content-center rounded-pill"
                  style={{ width: 34, height: 34 }}
                >
                  Q
                  {this.calcQueNumber(
                    this.state.currentSection,
                    this.state.currentQuestion,
                    this.state.quizPreviewSections
                  )}
                </div>
                <div className="mx-2" style={{ flex: 1 }}>
                  <div className="text-muted">
                    {
                      questionTypes.find(
                        ({ value }) => value === selectedQuestion.type
                      ).label
                    }{" "}
                    ({selectedQuestion.mark} marks)
                  </div>
                </div>
                <div className="mr-2">
                  <button
                    className="px-4 py-1 mr-2 text-green d-flex align-items-center"
                    style={{ borderRadius: 12 }}
                  >
                    <i className="far fa-bookmark"></i>
                    <span className="ml-2">Flag</span>
                  </button>
                </div>
              </div>

              {selectedQuestion.type === "TRUE_OR_FALSE" && (
                <div className="pt-5 pb-3 mt-3 pv-2">
                  <h4 className="mb-4 text-dark">{selectedQuestion.text}</h4>

                  <div
                    className="px-3 py-3 mt-3 rounded d-flex align-items-center"
                    style={{
                      cursor: "pointer",
                      border:
                        selectedQuestion.answers[0] === "true"
                          ? "1px solid transparent"
                          : "1px solid #eaeaea",
                      backgroundColor:
                        selectedQuestion.answers[0] === "true" &&
                        "rgba(0,0,0,0.05)",
                    }}
                  >
                    <input
                      className="mr-2"
                      type="radio"
                      checked={selectedQuestion.answers[0] === "true"}
                    />
                    <div>True</div>
                  </div>

                  <div
                    className="px-3 py-3 mt-3 rounded d-flex align-items-center"
                    style={{
                      cursor: "pointer",
                      border:
                        selectedQuestion.answers[0] === "false"
                          ? "1px solid transparent"
                          : "1px solid #eaeaea",
                      backgroundColor:
                        selectedQuestion.answers[0] === "false" &&
                        "rgba(0,0,0,0.05)",
                    }}
                  >
                    <input
                      className="mr-2"
                      type="radio"
                      checked={selectedQuestion.answers[0] === "false"}
                    />
                    <div>False</div>
                  </div>
                </div>
              )}

              {["LONG_ANSWER", "SHORT_ANSWER"].includes(
                selectedQuestion.type
              ) && (
                <div className="pt-5 pb-3 mt-3 pv-2">
                  <h4 className="mb-4 text-dark">{selectedQuestion.text}</h4>

                  <div style={{ position: "relative" }}>
                    <Button
                      color={this.state.attachment ? "info" : "light"}
                      size="sm"
                      className="px-3 rounded-pill"
                      style={{
                        position: "absolute",
                        top: 4,
                        right: 18,
                      }}
                    >
                      Attach file
                    </Button>
                    <textarea
                      placeholder="Enter your answer"
                      id="answer"
                      name="answer"
                      className="bg-white"
                      rows={selectedQuestion.type === "LONG_ANSWER" ? 8 : 3}
                      style={{
                        backgroundColor: "transparent",
                        padding: "0.375rem 0.75rem",
                        border: "1px solid #e7e7e7",
                        width: "100%",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                </div>
              )}

              {selectedQuestion.type === "SEQUENCING" && (
                <div className="pt-5 pb-3 mt-3 rounded pv-2">
                  <h4 className="mb-4 text-dark">{selectedQuestion.text}</h4>

                  <div className="mb-1 d-flex align-items-center">
                    <div className="d-flex align-items-center justify-content-center">
                      <div
                        className="text-muted"
                        style={{ fontSize: "1rem", width: 40 }}
                      >
                        Order
                      </div>
                    </div>
                    <div style={{ flex: 1, marginLeft: 12 }}>
                      <p className="m-0 ml-2 text-muted">
                        Drag & drop answer choices to matching answers
                      </p>
                    </div>
                  </div>

                  <div className="d-flex align-items-start">
                    <div style={{ flex: 2 }}>
                      {selectedQuestion.questions.map((ques, index) => (
                        <div
                          className="mb-2 d-flex align-items-center"
                          key={index}
                        >
                          <div className="d-flex align-items-center justify-content-center">
                            <div
                              className="border text-dark d-flex align-items-center justify-content-center border-muted"
                              style={{
                                borderRadius: 8,
                                width: 40,
                                height: 40,
                                backgroundColor: "transparent",
                              }}
                            >
                              {index + 1}
                            </div>
                          </div>
                          <div style={{ flex: 1, marginLeft: 12 }}>
                            <div
                              className="px-3 border d-flex align-items-center border-muted"
                              style={{
                                height: 40,
                                overflow: "hidden",
                                borderRadius: 8,
                              }}
                            >
                              <i
                                className="mr-3 fas fa-grip-vertical"
                                style={{
                                  color: "rgba(0,0,0,0.7)",
                                  fontSize: 20,
                                }}
                              />
                              <span>{ques}</span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {["MULTIPLE_ANSWER", "MULTIPLE_CHOICE"].includes(
                selectedQuestion.type
              ) && (
                <div className="pt-5 pb-3 mt-3 pv-2">
                  <h4 className="mb-4 text-dark">{selectedQuestion.text}</h4>

                  {selectedQuestion.questions.map((ans, index) => (
                    <div
                      key={index}
                      className={`text-dark d-flex align-items-center mb-2 px-3 py-3 border border-muted`}
                      style={{
                        cursor: "pointer",
                        borderRadius: 8,
                      }}
                    >
                      <input
                        className="mr-2"
                        type={
                          selectedQuestion.type === "MULTIPLE_ANSWER"
                            ? "checkbox"
                            : "radio"
                        }
                      />
                      <span className="ml-4">{ans}</span>
                    </div>
                  ))}
                </div>
              )}

              {selectedQuestion.type === "FILL_IN_THE_BLANKS" && (
                <div className="pt-5 pb-3 mt-3 rounded pv-2">
                  <div className="d-flex align-items-center">
                    <div className="pt-5 pb-3 mt-3 rounded pv-2">
                      <div className="d-flex align-items-center">
                        <h4 className="mb-0 w-100 text-dark">
                          {selectedQuestion.text.split("____")[0]}
                          <input
                            name="answer"
                            id="answer"
                            className="mx-2 form-control"
                            placeholder="Your answer..."
                            style={{
                              width: 150,
                              backgroundColor: "transparent",
                              display: "inline",
                            }}
                          />
                          {selectedQuestion.text.split("____")[1]}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {selectedQuestion.type === "MATCHING" && (
                <div className="pt-5 pb-3 mt-3 rounded pv-2">
                  <h4 className="mb-2 text-dark">{selectedQuestion.text}</h4>

                  <div className="d-flex">
                    <div style={{ flex: 2 }}>
                      <p className="m-0 mb-2 text-muted">
                        Drag & drop answer choices to matching answers
                      </p>

                      {selectedQuestion.questions.map((ques, index) => (
                        <div
                          className="px-2 py-2 mb-2 rounded d-flex align-items-strech justify-content-between"
                          style={{
                            backgroundColor: "rgba(0,0,0,0.1)",
                            width: "50%",
                            minWidth: "min(100%, 500px)",
                          }}
                        >
                          <div
                            className="ml-2 mr-2 text-center bg-green d-flex flex-column"
                            style={{
                              flex: 1,
                              overflow: "hidden",
                              borderRadius: 8,
                            }}
                          >
                            <div
                              className="px-4 py-3 border text-dark border-muted d-flex align-items-center justify-content-center"
                              style={{
                                flex: 1,
                                borderRadius: 8,
                                backgroundColor: "#fff",
                              }}
                            >
                              {ques}
                            </div>
                          </div>

                          <div
                            className="ml-2 text-center bg-green d-flex flex-column"
                            style={{
                              flex: 1,
                              overflow: "hidden",
                              borderRadius: 8,
                            }}
                          >
                            <div
                              className="px-4 py-3 text-dark d-flex align-items-center justify-content-center"
                              style={{
                                border: "2px dotted #eaeaea",
                                verticalAlign: "center",
                                flex: 1,
                                borderRadius: 8,
                                backgroundColor: "#fff",
                              }}
                            ></div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div style={{ flex: 1, marginLeft: 12 }}>
                      <p className="m-0 mb-2 text-muted">Answers</p>

                      {selectedQuestion.questions.map((ques, index) => (
                        <div
                          className="px-4 py-3 mb-2 border text-dark border-muted d-flex align-items-center justify-content-start"
                          style={{
                            verticalAlign: "center",
                            borderRadius: 8,
                          }}
                        >
                          <i
                            className="mr-3 fas fa-grip-vertical"
                            style={{
                              color: "rgba(0,0,0,0.7)",
                              fontSize: 20,
                            }}
                          />
                          <span>{ques}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {selectedQuestion.attachment && (
                <div
                  className="mb-3 rounded cursor-pointer d-flex align-items-center"
                  style={{ maxWidth: 250, backgroundColor: "#ececec" }}
                >
                  <div className="p-3 rounded bg-brand">
                    <img
                      src={require("../../../assets/images/assignment/pdf.svg")}
                      alt="Attachment"
                      style={{ height: 25, width: 25 }}
                    />
                  </div>
                  <div className="px-2">{selectedQuestion.attachment.name}</div>
                </div>
              )}
            </div>
            <div className="px-4 py-4 col-md-4 border-left border-muted d-flex flex-column">
              {this.state.hasSection ? (
                <div className="mb-2 d-flex align-items-center">
                  <h5 className="mb-0 mr-auto text-brand">
                    {selectedSection.title} (
                    {this.calcTotalMarks(this.state.currentSection)} Marks)
                  </h5>
                </div>
              ) : null}

              <div className="flex-wrap mx-auto my-1 d-flex align-items-center">
                {this.state.quizPreviewSections[
                  this.state.currentSection
                ].quizQuestions.map((_, index) =>
                  this.renderQuestionIcon(
                    this.calcQueNumber(
                      this.state.currentSection,
                      index,
                      this.state.quizPreviewSections
                    ),
                    "NOTANSWERED",
                    index === this.state.currentQuestion,
                    () => this.setState({ currentQuestion: index })
                  )
                )}
              </div>

              <h5 className="mr-auto text-brand">Legends & Stats</h5>

              <div className="my-1">
                <div className="row">
                  <div className="col d-flex align-items-center">
                    {this.renderQuestionIcon(1, "ANSWERED", false)}
                    <p className="m-0">Answered</p>
                  </div>

                  <div className="col d-flex align-items-center">
                    {this.renderQuestionIcon(2, "NOTANSWERED", false)}
                    <p className="m-0">Not Answered</p>
                  </div>
                </div>

                <div className="row">
                  <div className="col d-flex align-items-center">
                    {this.renderQuestionIcon(3, "NOTVISITED", false)}
                    <p className="m-0">Not Visited</p>
                  </div>

                  <div className="col d-flex align-items-center">
                    {this.renderQuestionIcon(4, "FLAGGED", false)}
                    <p className="m-0">Flagged</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-8">
              <div className="my-4 d-flex align-items-center">
                <button
                  className="px-4 py-1 mr-2 btn btn-light"
                  style={{ borderRadius: 12 }}
                  disabled={
                    this.state.currentQuestion === 0 &&
                    this.state.currentSection === 0
                  }
                  onClick={() => {
                    if (this.state.currentQuestion === 0) {
                      const newSectionIndex = this.state.currentSection - 1
                      this.setState({
                        currentSection: newSectionIndex,
                        currentQuestion:
                          this.state.quizPreviewSections[newSectionIndex]
                            .quizQuestions.length - 1,
                      })
                    } else {
                      this.setState({
                        currentQuestion: this.state.currentQuestion - 1,
                      })
                    }
                  }}
                >
                  Previous
                </button>
                <button
                  className="px-4 py-1 btn btn-light"
                  style={{ borderRadius: 12 }}
                >
                  Clear
                </button>

                <button
                  className="px-4 py-1 ml-auto btn bg-green text-light"
                  style={{ borderRadius: 12 }}
                  disabled={
                    this.state.currentSection ===
                      this.state.quizPreviewSections.length - 1 &&
                    this.state.currentQuestion ===
                      selectedSection.quizQuestions.length - 1
                  }
                  onClick={() => {
                    if (
                      this.state.currentQuestion + 1 ===
                      selectedSection.quizQuestions.length
                    ) {
                      this.setState({
                        currentSection: this.state.currentSection + 1,
                        currentQuestion: 0,
                      })
                    } else {
                      this.setState({
                        currentQuestion: this.state.currentQuestion + 1,
                      })
                    }
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  loadQuizPreview = () => {
    let totalQuestions = 0
    let totalMark = 0
    let quizSections = []

    for (let section of this.state.quizSections) {
      let quizSection = { ...section, quizQuestions: [] }
      for (let question of section.quizQuestions) {
        let ques = { ...question }
        totalQuestions++
        totalMark += parseInt(question.mark)
        ques.possibleAnswers = question.answers
        ques.answers = []
        quizSection.quizQuestions.push(ques)
      }
      quizSections.push(quizSection)
    }

    this.setState({
      isPreviewOpen: true,
      isPreviewModalOpen: true,
      quizPreviewSections: quizSections,
      totalQuestions,
      totalMark,
    })
  }

  render() {
    myLog("state values", this.state.quizData)
    if (this.state.isLoadingData) {
      return (
        <AppWrapper
          teacherDashboard
          showTeacherThree
          showTeacherLogo
          screenName={"TESTS"}
        >
          <div>
            <div className="mb-4 d-flex align-items-center">
              <h2 className="mr-auto text-dark">Test</h2>
            </div>

            <div
              className="d-flex align-items-center justify-content-center"
              style={{ position: "absolute", left: "50%", top: "50%" }}
            >
              <HashLoader
                sizeUnit={"px"}
                size={32}
                color={"#0a4354"}
                loading={this.state.isLoadingData}
              />
            </div>
          </div>
        </AppWrapper>
      )
    }
    return (
      <>
        {this.renderAddTopicModal()}
        {this.renderPreviewModal()}
        {this.renderAddQuestionFromBankModal()}
        <AppWrapper teacherDashboard showTeacherThree showTeacherLogo>
          <div className="d-flex align-items-center">
            <h2 className="mr-auto text-dark">Test</h2>
            {this.state.isPreviewOpen ? (
              <>
                <div className="mr-3 d-flex align-items-center">
                  <i className="far fa-clock" style={{ fontSize: "1rem" }} />
                  <div className="ml-2" style={{ fontSize: "1.05rem" }}>
                    {this.state.quizData && this.state.quizData.timeLimit}
                  </div>
                </div>
                <Button
                  color="light"
                  className="px-4 py-1 mr-2"
                  style={{ borderRadius: 14 }}
                  onClick={() =>
                    this.setState({
                      isPreviewOpen: false,
                      isPreviewModalOpen: false,
                      currentSection: 0,
                      currentQuestion: 0,
                    })
                  }
                >
                  Exit Preview
                </Button>
              </>
            ) : (
              <>
                <Button
                  className="px-4 py-1 mr-2 border-0 text-muted font-weight-bold"
                  style={{ borderRadius: 14, backgroundColor: "#D0F0EA" }}
                  onClick={this.loadQuizPreview}
                >
                  Preview
                </Button>
                <Button
                  className="px-4 py-1 border-0 bg-green text-light font-weight-bold"
                  style={{ borderRadius: 14 }}
                  onClick={() => this.assign()}
                  disabled={this.state.isAddingQuiz}
                >
                  {this.state.isAddingQuiz ? (
                    <HashLoader
                      sizeUnit={"px"}
                      size={16}
                      color={"#ffffff"}
                      loading={this.state.isAddingQuiz}
                    />
                  ) : (
                    "Assign"
                  )}
                </Button>
              </>
            )}
          </div>

          {this.state.isPreviewOpen
            ? this.renderQuizPreview()
            : this.renderEditQuiz()}
        </AppWrapper>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getSingleQuiz,
      saveQuiz,
      getPresignedUrl,
      putFileToPresignedUrl,
      getQuizTopics,
      saveQuizTopics,
      deleteQuizTopic,
      showNotification,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  dispatchToProps
)(withRouter(CreateAssessmentScreen))
