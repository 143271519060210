/*************************************************
 * RedInk
 * @exports
 * @class subjectDetailsReducer.js
 * @extends Component
 * @author Vijayakumar // on 18/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from "../../actions/types"
import { myLog } from "../../utils/Utility"

const INTIAL_STATE = {
  schoolPerformanceReportDetails: {},
  classPerformanceReportDetails: {},
  subjectPerformanceReportDetails: {},
  examList: [],
}

export const performanceReportDetailsReducer = (
  state = INTIAL_STATE,
  action
) => {
  switch (action.type) {
    case ACTION_TYPES.EXAM_LIST_DETAILS:
      myLog("payload", action.payload)
      return {
        ...state,
        examList: [...action.payload],
      }

    case ACTION_TYPES.SCHOOL_PERFORMANCE_REPORT_DETAILS:
      myLog("payload", action.payload)
      return {
        ...state,
        schoolPerformanceReportDetails: { ...action.payload },
      }

    case ACTION_TYPES.CLASS_PERFORMANCE_REPORT_DETAILS:
      myLog("payload", action.payload)
      return {
        ...state,
        classPerformanceReportDetails: { ...action.payload },
      }

    case ACTION_TYPES.SUBJECT_PERFORMANCE_REPORT_DETAILS:
      myLog("payload", action.payload)
      return {
        ...state,
        subjectPerformanceReportDetails: { ...action.payload },
      }

    default:
      return state
  }
}
