import React, { useState } from "react"
import { Modal } from "reactstrap"
import pdfMake from "pdfmake"

import { createQp } from "../../../../../actions/admin/QuestionGeneration"
import { formatTime, change, alphas } from "../../common/utils"

import { ReactComponent as Close } from "../../../../../assets/images/close.svg"
import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"

const fonts = {
  Roboto: {
    normal: "https://redink-assets.s3.ap-south-1.amazonaws.com/Cambria.ttf",
    bold: "https://redink-assets.s3.ap-south-1.amazonaws.com/cambriab-bold.ttf",
    italics: "https://redink-assets.s3.ap-south-1.amazonaws.com/Cambria.ttf",
    bolditalics:
      "https://redink-assets.s3.ap-south-1.amazonaws.com/Cambria.ttf",
  },
}

function SaveQP({
  data,
  classId,
  selectedClass,
  selectedSubject,
  schoolDetails,
  closeModal,
  toHome,
}) {
  const [instruction, setInstruction] = useState(
    `i. Read the questions carefully \nii. Use Blue or black pen to write and pencil to draw diagrams`
  )
  const [isLoading, setIsLoading] = useState(false)
  const [title, setTitle] = useState("")
  const [step, setStep] = useState(1)
  const [min, setMin] = useState("")
  const [totalMarks] = useState(
    data.reduce((prev, curr) => prev + curr.count * curr.mark, 0)
  )

  const onSave = () => {
    const body = {
      title,
      classId,
      subjectId: selectedSubject.map(s => s.masterSubjectId).join(","),
      totalMarks,
      duration: Number(min),
      instructions: instruction,
      status: "COMPLETED",
      questionTypes: data.map((d, i) => ({
        sectionName: d.displayName,
        questionType: d.included?.[0]?.type || "",
        marksPerQuestion: d.mark,
        order: i + 1,
        questions: d.included.map((q, j) => ({
          masterSubjectId: q.masterSubjectId,
          quizDefaultQuestionId: q.id,
          order: j + 1,
        })),
      })),
    }

    setIsLoading(true)
    createQp(body, success => {
      if (success) {
        setStep(2)
      }
    })
  }

  function downloadGPPdf() {
    const instructions = `General Instructions:\n${instruction}`
      .split("\n")
      .map((a, i) => ({
        text: `${a}\n`,
        bold: i === 0,
        margin: [0, 0, 0, 3],
      }))

    const docDefinition = {
      content: [
        {
          text: schoolDetails.schoolName,
          alignment: "center",
          margin: [0, 0, 0, 1],
          fontSize: 14,
        },
        {
          text: `${schoolDetails.city} - ${schoolDetails.zipCode}`,
          alignment: "center",
          margin: [0, 0, 0, 8],
          fontSize: 13,
        },
        {
          text: title,
          alignment: "center",
          style: "header",
          margin: [0, 0, 0, 5],
        },
        {
          text: `Class ${selectedClass}`,
          alignment: "center",
          style: "header",
          margin: [0, 0, 0, 10],
        },
        {
          style: "header",
          margin: [0, 0, 0, 10],
          columns: [
            {
              text: `Time: ${formatTime(Number(min))}`,
              width: "*",
            },
            {
              text: `${selectedSubject.map(a => a.subjectName).join(", ")}`,
              width: "auto",
            },
            {
              text: `Total Marks: ${totalMarks}`,
              width: "*",
              alignment: "right",
            },
          ],
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 0,
              x2: 555,
              y2: 0,
              lineWidth: 1,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        ...instructions,
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 0,
              x2: 555,
              y2: 0,
              lineWidth: 1,
            },
          ],
          margin: [0, 8, 0, 20],
        },
      ],
      footer: function(currentPage, pageCount) {
        return {
          text: `Page ${currentPage} of ${pageCount}`,
          fontSize: 10,
          alignment: "right",
          margin: [0, 0, 20, 0],
        }
      },
      pageSize: "A4",
      pageMargins: 20,
      defaultStyle: {
        fontSize: 12,
      },
      styles: {
        header: {
          fontSize: 13,
          bold: true,
        },
      },
    }

    data.forEach((q, i) => {
      let prevNum =
        i === 0
          ? 0
          : data.reduce(
              (prev, curr, j) => (j < i ? prev + curr.included.length : prev),
              0
            )
      docDefinition.content.push({
        style: "header",
        margin: [0, 0, 0, 6],
        columns: [
          {
            width: "*",
            text: `SECTION ${i + 1}: ${q.displayName}`,
          },
          {
            width: "auto",
            fontSize: 12,
            text: `${q.count} x ${q.mark} = ${q.count * q.mark}`,
          },
        ],
      })

      if (q.quest === "MCQ") {
        q.included.forEach(({ text, question }, i) => {
          docDefinition.content.push({
            text: `${prevNum + i + 1}. ${text}`,
            margin: [0, 0, 0, 6],
          })

          docDefinition.content.push({
            columns: [change(question[0], 0), change(question[1], 1), "", ""],
            margin: [16, 0, 0, 4],
          })
          docDefinition.content.push({
            columns: [change(question[2], 2), change(question[3], 3), "", ""],
            margin: [16, 0, 0, 10],
          })
        })
      } else if (q.quest === "Match the following") {
        q.included.forEach(({ question, match, text }) => {
          docDefinition.content.push({
            text: `${prevNum + i + 1}. ${text}`,
            margin: [0, 0, 0, 8],
          })

          question.forEach((q, i) => {
            docDefinition.content.push({
              margin: [12, 0, 0, 8],
              columns: [
                { text: `${alphas[i]}. ${q.trim()}`, width: 110 },
                { text: "-", width: 30 },
                { text: match[i] },
              ],
            })
          })
        })
      } else {
        q.included.forEach(({ text }, i) => {
          docDefinition.content.push({
            text: `${prevNum + i + 1}. ${text}`,
            margin: [0, 0, 0, 8],
          })
        })
      }

      docDefinition.content.push({
        text: "",
        margin: [0, 0, 0, 10],
      })
    })

    pdfMake.createPdf(docDefinition, null, fonts).getBase64(async data => {
      const blob = await fetch(
        `data:application/pdf;base64,${data}`
      ).then(res => res.blob())
      const browserUrl = window.webkitURL || window.URL
      const pdfURL = browserUrl.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = pdfURL
      a.download = `${title} ${selectedSubject
        .map(a => a.subjectName)
        .join(", ")} ${selectedClass}.pdf`
      a.click()
      browserUrl.revokeObjectURL(pdfURL)
      toHome()
    })
  }

  return (
    <Modal
      isOpen
      backdropClassName="modal-backdrop-show"
      modalClassName="modal-dialog-shadow-none report-card-modal-full-wrapper"
      contentClassName="report-modal-wrapper py-4 px-5 bg-F6F7F8"
      centered
      size={step < 3 ? "md" : "lg"}
    >
      <div className="d-flex align-items-center mb-4">
        <p className="mb-0 fs-18 fw-700">
          {step === 1 && "Save Question Paper"}
          {step === 3 && title}
        </p>

        <Close
          width={28}
          height={28}
          onClick={closeModal}
          className="ml-auto cursor-pointer"
        />
      </div>

      {step === 1 && (
        <Step1
          min={min}
          title={title}
          instruction={instruction}
          isLoading={isLoading}
          setInstruction={setInstruction}
          setTitle={setTitle}
          setMin={setMin}
          onSave={onSave}
        />
      )}

      {step === 2 && <Step2 downloadGPPdf={downloadGPPdf} setStep={setStep} />}

      {step === 3 && (
        <Step3
          min={formatTime(Number(min)) || ""}
          data={data}
          title={title}
          instruction={instruction}
          totalMarks={totalMarks}
          selectedClass={selectedClass}
          selectedSubject={selectedSubject}
          schoolDetails={schoolDetails}
          downloadGPPdf={downloadGPPdf}
        />
      )}
    </Modal>
  )
}

export default SaveQP
