/*************************************************
 * RedInk
 * @exports
 * @class Dashboard.js
 * @extends Component
 * @author Sasidharan // on 11/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"

import MessageModal from "../MessageModal"
import AppWrapper from "../AppWrapper"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  getCommunicationDetails,
  getStudentsDetails,
  saveCommunicationStarredDetails,
  saveCommunicationDetailsInStore,
  saveCommunicationDetails,
  getClassDetails,
  getSubjectDetails,
  getCommunicationCountDetails,
} from "../../../actions/teacher/CommunicationDetailsAction"

class CommunicationDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showSideMenu: true,
      isMessageModalOpen: false,
      isShowAll: true,
      isShowStarred: false,
      isShowToday: false,
      isShowMonth: false,
      todayCount: 0,
      thismonthCount: 0,
    }
  }

  componentDidMount() {
    this.props.getStudentsDetails(() => {
      this.props.getCommunicationCountDetails((success, response) => {
        this.setState({
          todayCount: response.todayCount,
          thismonthCount: response.thisMonthCount,
        })
      })
      this.getCommunication()
    })
  }

  getCommunication = type => {
    let object = {}
    if (type === 1) {
      object.today = true
    } else if (type === 2) {
      object.starred = true
    } else if (type === 3) {
      object.thisMonth = true
    }
    this.props.getCommunicationDetails(object, () => {})
  }

  /**
   * Handles message modal
   */
  closeMessageModal = () => this.setState({ isMessageModalOpen: false })

  /**
   * handles on click message icon in sidebar
   */
  onClickMessageIcon = () => {
    this.setState({ isMessageModalOpen: !this.state.isMessageModalOpen })
  }
  /**
   * handles message submit
   */
  onSubmitMessage = () => {
    this.closeMessageModal()
  }

  render() {
    return (
      // <AppWrapper
      // openMessageModal={() => this.onClickMessageIcon()}
      // showAdminProfileSidemenu
      // showAdminBackToDashboard>
      <AppWrapper
        openMessageModal={() => this.onClickMessageIcon()}
        parentDashboard
        showTeacherLogo
      >
        <MessageModal
          isModalOpen={this.state.isMessageModalOpen}
          closeModal={() => this.onClickMessageIcon()}
          onSubmitMessage={() => this.onSubmitMessage()}
        />
        <div className="mx-2">
          <div className="row">
            <div className="col-sm-3 pl-0">
              <div className="card shadow">
                <div className="card-header">
                  <button
                    className="btn btn-dark btn-block rounded-pill"
                    type="button"
                    onClick={() =>
                      this.setState({
                        isMessageModalOpen: true,
                        isShowAll: true,
                        isShowStarred: false,
                        isShowMonth: false,
                        isShowToday: false,
                      })
                    }
                  >
                    <i className="fas fa-edit mr-2" />
                    Compose
                  </button>
                </div>
                <ul className="list-group list-group-flush">
                  <li
                    className={`list-group-item ${
                      this.state.isShowAll ? "li-bg-color" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        isShowAll: true,
                        isShowStarred: false,
                        isShowMonth: false,
                        isShowToday: false,
                      })
                      this.getCommunication()
                    }}
                  >
                    All
                  </li>
                  <li
                    className={`list-group-item ${
                      this.state.isShowStarred ? "li-bg-color" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        isShowAll: false,
                        isShowStarred: true,
                        isShowMonth: false,
                        isShowToday: false,
                      })
                      this.getCommunication(2)
                    }}
                  >
                    Starred
                  </li>
                </ul>
              </div>
              <div className="card my-3 shadow">
                <div className="card-header">
                  <i className="fas fa-inbox mr-2"></i>
                  Summary
                </div>
                <ul className="list-group list-group-flush">
                  <li
                    className={`list-group-item justify-content-between d-flex align-items-center ${
                      this.state.isShowToday ? "li-bg-color" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        isShowAll: false,
                        isShowStarred: false,
                        isShowMonth: false,
                        isShowToday: true,
                      })
                      this.getCommunication(1)
                    }}
                  >
                    Sent today
                    <span className="badge badge-info">
                      {this.state.todayCount}
                    </span>
                    <span className="sr-only">unread messages</span>
                  </li>
                  <li
                    className={`list-group-item justify-content-between d-flex align-items-center ${
                      this.state.isShowMonth ? "li-bg-color" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        isShowAll: false,
                        isShowStarred: false,
                        isShowMonth: true,
                        isShowToday: false,
                      })
                      this.getCommunication(3)
                    }}
                  >
                    Sent this month
                    <span className="badge badge-info">
                      {this.state.thismonthCount}
                    </span>
                    <span className="sr-only">unread messages</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-9 pl-0">
              <div className="card shadow">
                <div className="table-responsive rounded">
                  <p className="mt-2 mx-3 fs-20">Sent Messages</p>
                  <table className="text-nowrap table-lg m-0 table table-hover">
                    <tbody>
                      {this.props.communicationList &&
                        this.props.communicationList.map((item, index) => {
                          return (
                            <tr>
                              {/* <td
                                className="text-center"
                                style={{ width: "78px" }}
                              >
                                <div className="custom-checkbox custom-control">
                                  <input
                                    type="checkbox"
                                    id="eCheckbox1"
                                    className="custom-control-input"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="eCheckbox1"
                                  >
                                    &nbsp;
                                  </label>
                                </div>
                              </td> */}
                              <td className="text-center pl-1">
                                {item.isStarred === 0 ? (
                                  <div
                                    onClick={() => {
                                      let array = this.props.communicationList
                                      array[index].isStarred = 1
                                      this.props.saveCommunicationDetailsInStore(
                                        array
                                      )
                                      let object = {
                                        communicationId: item.id,
                                      }
                                      this.props.saveCommunicationStarredDetails(
                                        object,
                                        () => {}
                                      )
                                    }}
                                  >
                                    <i className="far fa-star" />
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => {
                                      let array = this.props.communicationList
                                      array[index].isStarred = 0
                                      this.props.saveCommunicationDetailsInStore(
                                        array
                                      )
                                      let object = {
                                        communicationId: item.id,
                                      }
                                      this.props.saveCommunicationStarredDetails(
                                        object,
                                        () => {}
                                      )
                                    }}
                                  >
                                    <i className="fa fa-star text-warning" />
                                  </div>
                                )}
                              </td>
                              <td>
                                <div className="widget-content p-0">
                                  <div className="widget-content-wrapper">
                                    <div className="widget-content-left">
                                      {item.sentNames.map(name => {
                                        return (
                                          <div className="widget-heading rounded bg-primary text-white px-3">
                                            {/* {item.role === '0'?
                                        item.studentName
                                        :
                                        item.role === '1' ? 
                                          item.className
                                          :
                                          item.role === '2' ?
                                            item.teacherName
                                            :
                                            null
                                      } */}
                                            {name}
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="text-left">
                                {item.messageSubject} {item.messageContent}
                              </td>
                              <td className="text-center">
                                <i className="fa fa-calendar-alt opacity-4 mr-2" />
                                {item.sentOn}
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    subjectList: state.communicationDetailsState.subjectList,
    classesList: state.communicationDetailsState.classList,
    teachersList: state.communicationDetailsState.teachersList,
    studentsList: state.communicationDetailsState.studentsList,
    communicationList: state.communicationDetailsState.communicationList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getCommunicationDetails,
      saveCommunicationDetails,
      getClassDetails,
      getSubjectDetails,
      getStudentsDetails,
      saveCommunicationDetailsInStore,
      saveCommunicationStarredDetails,
      getCommunicationCountDetails,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(CommunicationDashboard)
