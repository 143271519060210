import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import { showNotification } from "../CommonAction"
import {
  SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"
import URL from "../../config"
import { ACTION_TYPES } from "../types"
// import { URL_PATH } from "../../config/urlPath"

const {
  GET_QUIZ_ASSIGN_LIST,
  ADD_QUIZ_ASSIGN,
  GET_SINGLE_QUIZ_ASSIGN,
  UPDATE_SINGLE_QUIZ_ASSIGN,
  DELETE_QUIZ_ASSIGN,
} = ACTION_TYPES

export const saveQuizAssigns = (quizloading, body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.put(
        URL.QUIZ_ASSIGN_DETAILS,
        body,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====saveQuizAssigns RESPONSE===::::", response)
        if (body.quizSet) {
          // only show noti on in class
          if (quizloading) {
            dispatch(
              showNotification(
                SUCCESS_TOAST,
                VALIDATION_MSG.SUCCESS,
                response.responseMessage
              )
            )
          }
        }
        dispatch({
          type: body.id ? UPDATE_SINGLE_QUIZ_ASSIGN : ADD_QUIZ_ASSIGN,
          payload: response.data,
        })
        callBack(true, response.data)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveQuizAssigns ERROR===????", error)
    }
  }
}

export const getTeacherAssignedDetails = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_TEACHER_ASSIGNED_DETAILS,
        body,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====getTeacherAssignedDetails RESPONSE===::::", response)
        callBack(true, response.data)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====getTeacherAssignedDetails ERROR===????", error)
    }
  }
}

export const getClassStudents = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        `${URL.GET_TEACHER_CLASS_STUDENTS}/${body}`,
        {},
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====getClassStudents RESPONSE===::::", response)
        callBack(true, response.data)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====getClassStudents ERROR===????", error)
    }
  }
}

export const getSingleQuizAssign = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        `${URL.QUIZ_ASSIGN_DETAILS}/${body}`,
        {},
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====getSingleQuizAssign RESPONSE===::::", response)
        dispatch({
          type: GET_SINGLE_QUIZ_ASSIGN,
          payload: response.data,
        })
        callBack(true, response.data)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====getSingleQuizAssign ERROR===????", error)
    }
  }
}

export const deleteQuizAssign = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        `${URL.QUIZ_ASSIGN_DETAILS}/${body.id}`,
        {},
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteQuizAssign RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        dispatch({
          type: DELETE_QUIZ_ASSIGN,
          payload: body,
        })
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteQuizAssign ERROR===????", error)
    }
  }
}

export const getQuizAssignList = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.QUIZ_ASSIGN_DETAILS,
        body,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====getQuizAssignList RESPONSE===::::", response)
        dispatch({
          type: GET_QUIZ_ASSIGN_LIST,
          payload: response.data,
        })
        callBack(true, response.data)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====getQuizAssignList ERROR===????", error)
    }
  }
}

export const saveQuiz = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.put(URL.SAVE_QUIZ, body, true, true)
      if (response.responseCode === 0) {
        myLog("====saveQuiz RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true, response.data)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveQuiz ERROR===????", error)
    }
  }
}

export const updateQuizMarks = (id, body, callBack) => {
  return async dispatch => {
    myLog("====updateQuizMarks id, body===::::", id, body)
    try {
      const response = await Client.patch(
        `${URL.QUIZ_ASSIGN_DETAILS}/${id}/marks`,
        body,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====updateQuizMarks RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true, response.data)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====updateQuizMarks ERROR===????", error)
    }
  }
}

export const updateQuiz = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.patch(
        `${URL.QUIZ_ASSIGN_DETAILS}/${body.id}`,
        body,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====updateQuiz RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true, response.data)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====updateQuiz ERROR===????", error)
    }
  }
}

export const getQuizReport = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        `${URL.GET_TEACHER_QUIZ_REPORT}/${body}`,
        {},
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====getQuizReport RESPONSE===::::", response)
        callBack(true, response.data)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====getQuizReport ERROR===????", error)
    }
  }
}

export const getQuestionWiseQuizReport = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        `${URL.GET_TEACHER_QUIZ_REPORT}/${body}/questions`,
        {},
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====getQuestionWiseQuizReport RESPONSE===::::", response)
        callBack(true, response.data)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====getQuestionWiseQuizReport ERROR===????", error)
    }
  }
}

export const getMarkReport = (id, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.getPdfOrExcel(
        URL.GET_TEACHER_QUIZ_REPORT + "/" + id + "/pdf",
        null,
        true,
        true
      )
      if (response) {
        myLog("====getMarkReport RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getMarkReport ERROR===????", error)
    }
  }
}

export const getPresignedUrl = (fileName, callBack = () => {}) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_QUIZ_FILE_UPLOAD_URL + `?name=${fileName}`,
        null,
        true,
        true
      )
      if (response) {
        myLog("====getPresignedUrl RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getPresignedUrl ERROR===????", error)
    }
  }
}

export const putFileToPresignedUrl = (url, file, callBack = () => {}) => {
  return async dispatch => {
    try {
      let data = new FormData()
      data.append("files", file)
      const response = await Client.put(url, data)
      if (response) {
        myLog("====putFileToPresignedUrl RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====putFileToPresignedUrl ERROR===????", error)
    }
  }
}

export const getSingleQuiz = (body, callBack = () => {}) => {
  return async dispatch => {
    try {
      const id = typeof body === "object" ? body.id : body
      const data = typeof body === "object" ? body.data : {}
      const response = await Client.get(
        URL.SAVE_QUIZ + "/" + id,
        data,
        true,
        true
      )
      if (response) {
        myLog("====getSingleQuiz RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getSingleQuiz ERROR===????", error)
    }
  }
}

export const getQuizTopics = (body, callBack = () => {}) => {
  return async dispatch => {
    try {
      // const response = await Client.get(URL.GET_QUIZ_TOPICS, body, true, true)
      const params = new URLSearchParams({ ...body })
      const response = await Client.get(
        `${URL.GET_FOUNDER_SUBJECT_CHAPTERS_TOPICS}?${params.toString()}`,
        body,
        true,
        true
      )
      if (response) {
        myLog("====getQuizTopics RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getQuizTopics ERROR===????", error)
    }
  }
}

export const saveQuizTopics = (body, callBack = () => {}) => {
  return async dispatch => {
    try {
      const response = await Client.post(URL.GET_QUIZ_TOPICS, body, true, true)
      if (response) {
        myLog("====saveQuizTopics RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====saveQuizTopics ERROR===????", error)
    }
  }
}

export const deleteQuizTopic = (id, callBack = () => {}) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        URL.GET_QUIZ_TOPICS + "/" + id,
        null,
        true,
        true
      )
      if (response) {
        myLog("====deleteQuizTopic RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====deleteQuizTopic ERROR===????", error)
    }
  }
}

export const getStudentQuizSubmission = (submissionId, callBack = () => {}) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_STUDENT_QUIZ_SUBMISSION + "/" + submissionId,
        null,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====getStudentQuizSubmission RESPONSE===::::", response)
        callBack(true, response.data)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====getStudentQuizSubmission ERROR===????", error)
    }
  }
}

export const saveStudentQuizSubmission = (
  submissionId,
  data,
  callBack = () => {}
) => {
  return async dispatch => {
    try {
      const response = await Client.patch(
        URL.GET_STUDENT_QUIZ_SUBMISSION + "/" + submissionId,
        data,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====saveStudentQuizSubmission RESPONSE===::::", response)
        callBack(true, response.data)
        // dispatch(
        //   showNotification(
        //     SUCCESS_TOAST,
        //     VALIDATION_MSG.SUCCESS,
        //     "Saved successfully"
        //   )
        // )
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveStudentQuizSubmission ERROR===????", error)
    }
  }
}

export const retakeStudentQuizSubmission = (
  { id, startTime, endTime },
  callBack = () => {}
) => {
  return async dispatch => {
    try {
      const response = await Client.patch(
        URL.GET_STUDENT_QUIZ_SUBMISSION + "/" + id + "/retake",
        { startTime, endTime },
        true,
        true
      )
      if (response.responseCode === 0) {
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        myLog("====retakeStudentQuizSubmission RESPONSE===::::", response)
        callBack(true, response.data)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====retakeStudentQuizSubmission ERROR===????", error)
    }
  }
}

export const getChapters = (data, callBack = () => {}) => {
  return async dispatch => {
    try {
      const newdata = {
        selectedSubject: data.subjectId,
        selectedClass: data.sectionId,
        selectedBoard: data.board,
        selectedChapter: data.chapterId,
      }
      const params = new URLSearchParams({ ...newdata })
      const response = await Client.get(
        // `${URL.GET_SUBJECT_CHAPTERS}?${params.toString()}`,
        `${URL.GET_FOUNDER_SUBJECT_CHAPTERS_TOPICS}?${params.toString()}`,
        null,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====getChapters RESPONSE===::::", response)
        callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====getChapters ERROR===????", error)
    }
  }
}

/**
 *
 * @param {{
 *  page: number,
 *  limit: number,
 *  sectionId: string,
 *  subjectId: string,
 *  subjectTopicId: string,
 *  type: string,
 *  difficulty: string
 * }} data
 * @param {Function} callBack
 */
export const getQuizQuestionBank = (data, callBack = () => {}) => {
  return async dispatch => {
    try {
      const params = new URLSearchParams({ ...data })
      myLog("getStudentQuizSubmission data", params, params.toString())
      const response = await Client.get(
        `${URL.GET_QUIZ_QUESTION_BANK}?${params.toString()}`,
        null,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====getQuizQuestionBank RESPONSE===::::", response)
        callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====getQuizQuestionBank ERROR===????", error)
    }
  }
}

export const deleteQuizQuestionBank = (data, callBack = () => {}) => {
  return async dispatch => {
    try {
      const response = await Client.delete(
        `${URL.GET_QUIZ_QUESTION_BANK}/${data.id}`,
        null,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteQuizQuestionBank RESPONSE===::::", response)
        callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteQuizQuestionBank ERROR===????", error)
    }
  }
}

export const saveQuizQuestionBank = (data, callBack = () => {}) => {
  return async dispatch => {
    try {
      const response = await Client.put(
        `${URL.GET_QUIZ_QUESTION_BANK}`,
        data,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====saveQuizQuestionBank RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false, response)
      }
    } catch (error) {
      callBack(false)
      myLog("====saveQuizQuestionBank ERROR===????", error)
    }
  }
}
