import URL from "../../config"
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"

export const getNotificationList = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_STUDENT_NOTIFICATIONS,
        null,
        true,
        true
      )
      if (response) {
        myLog("====getNotificationList RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getNotificationList ERROR===????", error)
    }
  }
}

export const clearNotification = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_STUDENT_NOTIFICATIONS + "/clearAll",
        null,
        true,
        true
      )
      if (response) {
        myLog("====clearNotification RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====clearNotification ERROR===????", error)
    }
  }
}
