/*************************************************
 * RedInk
 * @exports
 * @class AssessmentScreen.js
 * @extends Component
 * @author Sasidharan // on 11/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import AppWrapper from "../Wrapper"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  Button,
  FormGroup,
  Label,
  Input,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
} from "reactstrap"
import { DatePicker } from "antd"

import makeAnimated from "react-select/animated"
import Select, { components } from "react-select"
import { SelectAssignment } from "../../common/MySelect" // MySelect,
import { HashLoader } from "react-spinners"
import { DANGER_TOAST, VALIDATION_MSG } from "../../../utils/Constants"
import moment from "moment"
import { withRouter, Link } from "react-router-dom"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

import { showNotification } from "../../../actions/CommonAction"
import {
  saveQuizAssigns,
  getSingleQuizAssign,
  deleteQuizAssign,
  getQuizAssignList,
  getTeacherAssignedDetails,
  getClassStudents,
  getChapters,
} from "../../../actions/teacher/QuizAction"
import { URL_PATH } from "../../../config/urlPath"
import { myLog, confirmAlertPopUp } from "../../../utils/Utility"
import createHistory from "history/createBrowserHistory"
import { ReactComponent as Plus } from "../../../assets/images/plus.svg"
import { ReactComponent as Minus } from "../../../assets/images/minus.svg"

const start = moment()
const remainder = 30 - (start.minute() % 30)

const quizStartTime = moment(start)
  .add(remainder, "minutes")
  .set({ second: 0, millisecond: 0 })
const quizEndTime = moment(quizStartTime)
  .add(60, "minutes")
  .set({ second: 0, millisecond: 0 })

const { TimePicker, RangePicker } = DatePicker

const Option = props => (
  <div>
    <components.Option {...props}>
      <div className="d-flex align-items-center">
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          className="subject-checkbox"
        />{" "}
        <label className="ml-2 mt-2">{props.label}</label>
      </div>
    </components.Option>
  </div>
)

const allOption = {
  id: null,
  label: "All Students",
  value: "*",
}

const MultiValueStudent = props => {
  let labelToBeDisplayed = `${props.data.label}, `
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = "All Students"
  }
  return (
    <components.MultiValueStudent {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValueStudent>
  )
}

// const MultiValue = props => {
//   let labelToBeDisplayed = `${props.data.label}, `
//   if (props.data.value === allOption.value) {
//     labelToBeDisplayed = "Selected All"
//   }
//   return (
//     <components.MultiValue {...props}>
//       <span>{labelToBeDisplayed}</span>
//     </components.MultiValue>
//   )
// }

const ValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue()
  let toBeRendered = children
  if (currentValues.some(val => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]]
  }

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  )
}

const animatedComponents = makeAnimated()

class AssessmentScreen extends Component {
  constructor(props) {
    document.title = "TESTS | Redink"

    super(props)
    this.state = {
      isSavingQuiz: false,
      isShowLoading: null,
      isShowModalLoading: null,
      isFirstShowLoading: true,
      classesPageIndex: 0,
      deletingItemId: null,
      querySection: "",
      classesList: [],
      subjectsPerSection: {},
      subjectsList: [],
      studentsList: [],
      chaptersList: [],
      selectedChapters: [],
      quizAnalytics: null,
      upcoming: [],
      ungraded: [],
      completed: [],
      quizAssignId: null,
      ...this.initialFormData,
      unvalidMaxmark: false,
      deletedItem: false,
      isReuseTest: false,
      selectedClassNumber: null,
      selectedBoard: null,
      selectedSubjectMasterId: null,
      noMark: "No marks",
      viewMark: true,
      createTestLoading: true,
      hidden: true,
    }
  }

  intervalID

  componentDidMount() {
    this.getData(this.state.isFirstShowLoading)
    this.intervalID = setInterval(this.getData.bind(this), 10000)
  }

  sortClassOrder = classess => {
    const classOrder = [
      "-",
      "JKG",
      "LKG",
      "SKG",
      "UKG",
      ...Array(12)
        .fill()
        .map((_, idx) => `${1 + idx}`),
    ]
    const classes = classess
    classes.sort((a, b) => {
      const aClass = a.className || "-"
      const aSection = a.sectionName || "-"
      const bClass = b.className || "-"
      const bSection = b.sectionName || "-"
      if (aClass === bClass) return aSection < bSection ? -1 : 1
      else return classOrder.indexOf(aClass) - classOrder.indexOf(bClass)
    })
    return classes
  }

  getData = (isFirstShowLoading = false) => {
    this.props.getTeacherAssignedDetails({}, (success, data) => {
      if (success) {
        this.setState(
          {
            createTestLoading: false,
            classesList: this.sortClassOrder(data.sections) || [],
            subjectsPerSection: data.assignedSubject || {},
            selectedBoard: data.sections[0].school.board || "",
            // selectedClassNumber:data.sections[0].className || ""
          },
          () => myLog("Response", data.sections)
        )
      }
    })

    this.getQuizAssignList()
    this.reuseFromViewAll()
  }

  componentWillUnmount = () => {
    clearTimeout(this.intervalID)
  }

  errorHandling = message => {
    this.props.showNotification(DANGER_TOAST, VALIDATION_MSG.OOPS, message)
    this.setState({ isSavingQuiz: false })
  }

  preSelection = () => {
    const subId = this.state.classesList
      ? this.state.classesList[0].sectionId
      : ""
    const subjectsList = this.state.subjectsPerSection[subId]

    this.setState(
      prevState => ({
        selectedClass: {
          ...prevState.selectedClass,
          board: this.state.classesList
            ? this.state.classesList[0].school.board
            : "",
          class: this.state.classesList
            ? this.state.classesList[0].className +
              this.state.classesList[0].sectionName
            : "",
          classNumber: this.state.classesList
            ? this.state.classesList[0].className
            : "",
          id: this.state.classesList ? this.state.classesList[0].sectionId : "",
          label: this.state.classesList
            ? this.state.classesList[0].className +
              this.state.classesList[0].sectionName
            : "",
          value: this.state.classesList
            ? this.state.classesList[0].className +
              this.state.classesList[0].sectionName
            : "",
        },
        selectedSubject: {
          ...prevState.selectedSubject,
          class: subjectsList ? subjectsList[0].subjectName : "",
          id: subjectsList ? subjectsList[0].subjectId : "",
          label: subjectsList ? subjectsList[0].subjectName : "",
          masterSubjectId: subjectsList ? subjectsList[0].masterSubjectId : "",
          value: subjectsList ? subjectsList[0].subjectName : "",
        },
        subjectsList: subjectsList,
        selectedSubjectMasterId: subjectsList
          ? subjectsList[0].masterSubjectId
          : "",
        selectedClassNumber: this.state.classesList
          ? this.state.classesList[0].className
          : "",
        selectedBoard: this.state.classesList
          ? this.state.classesList[0].school.board
          : "",
      }),
      () => {
        this.props.getChapters(
          {
            subjectId: subjectsList ? subjectsList[0].masterSubjectId : "",
            sectionId: this.state.classesList[0].className,
            board: this.state.classesList
              ? this.state.classesList[0].school.board
              : "",
          },
          (success, response) => {
            if (success && response.data) {
              this.setState({
                chaptersList: response.data.chaptersList,
                selectedSubjectMasterId: null,
                // selectedBoard: null,
                // selectedClassNumber: null,
              })
            }
          }
        )
      }
    ) // setstate
    this.getStudents(subId)
  }

  getAssignedSubject = event => {
    this.setState(
      {
        selectedSubject: {
          class: event ? event[0].subjectName : "",
          id: event ? event[0].subjectId : "",
          label: event ? event[0].subjectName : "",
          masterSubjectId: event ? event[0].masterSubjectId : "",
          value: event ? event[0].subjectName : "",
        },
        selectedSubjectMasterId: event ? event[0].masterSubjectId : "",
      },
      () => this.getChapters()
    )
  }
  getChapters = (subjectId = null, sectionId = null) => {
    // const { selectedSubject, selectedClass } = this.state
    // if (((!subjectId || !sectionId) && !selectedSubject) || !selectedClass)
    //   return

    // this.props.getChapters(
    //   {
    //     subjectId: subjectId || selectedSubject.id,
    //     sectionId: sectionId || selectedClass.id,
    //   },
    //   (success, response) => {
    //     if (success && response.data) {
    //       this.setState({ chaptersList: response.data })
    //     }
    //   }
    // )
    const {
      selectedSubjectMasterId,
      selectedClassNumber,
      selectedBoard,
    } = this.state
    // if (!selectedSubjectMasterId || !selectedClassNumber || !selectedBoard)
    //   return

    this.props.getChapters(
      {
        subjectId: selectedSubjectMasterId,
        sectionId: selectedClassNumber,
        board: selectedBoard,
      },
      (success, response) => {
        if (success && response.data) {
          this.setState({
            chaptersList: response.data.chaptersList,
            selectedSubjectMasterId: null,
            // selectedBoard: null,
            // selectedClassNumber: null,
          })
        }
      }
    )
  }

  getStudents = classId => {
    this.props.getClassStudents(classId, (success, data) => {
      if (success) {
        this.setState({
          studentsList: data,
          selectedStudents: [{ label: "All Students", value: "*" }].concat(
            data.map(student => ({
              class: student.fullName,
              label: student.fullName,
              value: student.fullName,
              id: student.studentId,
            }))
          ),
        })
      }
    })
  }

  getQuizAssignList = (querySection = "") => {
    const query = {}
    if (querySection) query.assignedClass = querySection
    if (this.state.isFirstShowLoading) {
      this.setState({ isShowLoading: true, isFirstShowLoading: false })
    }
    this.props.getQuizAssignList(query, success => {
      this.setState({ isShowLoading: false })
      if (success) {
        this.setQuizAnalytics()
      }
    })
  }

  initialFormData = {
    reuseQuizData: null,
    submissionType: "ONLINE",
    startTime: quizStartTime,
    endTime: quizEndTime,
    assignLater: null,
    isAssignLaterActive: false,
    title: "",
    description: "",
    maxMarks: "10",
    timeLimit: 0,
    shuffle: null,
    immediateResult: null,
    selectedClass: null,
    selectedSubject: null,
    selectedStudents: [],
    selectedChapters: [],
    submitTime: moment().set({ second: 0, millisecond: 0 }),
    hidden: true,
  }

  clearFormData = (quizAssignId = null) => {
    this.setState({ quizAssignId })
    this.setState(this.initialFormData)
  }

  reuseFromViewAll = () => {
    if (
      this.props.location.state !== undefined &&
      this.props.location.state.fromViewAllType !== undefined &&
      this.props.location.state.reuseQuizData !== undefined
    ) {
      this.setState({ isCreateTestModalOpen: true })
      if (this.props.location.state.fromViewAllType === "reuse") {
        this.clearFormData()
      } else {
        this.clearFormData(this.props.location.state.reuseQuizData.id)
      }
      this.setState({
        reuseQuizData: this.props.location.state.reuseQuizData,
      })
      this.setReuseModal(this.props.location.state.reuseQuizData.id)
      const history = createHistory()
      let state = { ...history.location.state }
      delete state.reuseQuizData
      delete state.fromViewAllType
    }
  }

  calculateTimeLimit = () => {
    const { startTime, endTime } = this.state
    let timeLimit = null
    timeLimit = endTime.diff(startTime, "minutes")
    this.setState({ timeLimit })
  }

  setReuseModal = id => {
    this.setState({ isShowModalLoading: true })
    this.props.getSingleQuizAssign(id, (success, data) => {
      this.setState({ isShowModalLoading: false })
      if (success) {
        const quiz = data
        const { quizSet, defaultSubjectChapters } = quiz
        // subjectChapters,

        const { section, subject } = quiz.teacherAssignedDetail
        if (quizSet) {
          this.setState({
            title: this.state.isReuseTest
              ? "Copy of " + quizSet.title
              : quizSet.title,
            description: quizSet.instruction,
            maxMarks:
              quizSet.totalMark === null || quizSet.totalMark == "0"
                ? this.state.noMark
                : quizSet.totalMark,
          })
        }
        if (quiz.timeLimit) {
          quiz.timeLimit = moment.duration(quiz.timeLimit).asMinutes()
        }

        const upcoming = moment(quiz.startTime).isAfter(quizStartTime)

        this.setState({
          startTime: upcoming ? moment(quiz.startTime) : quizStartTime,
          endTime: upcoming ? moment(quiz.endTime) : quizEndTime,
          submissionType: quiz.submissionType,
          timeLimit: quiz.timeLimit,
          shuffle: quiz.shuffle,
          immediateResult: quiz.immediateResult,
          selectedChapters: defaultSubjectChapters.map(e => ({
            id: e.id,
            label: e.name,
            value: e.id,
          })),
          selectedClass: {
            id: section.sectionId,
            class: `${section.className}${section.sectionName}`,
            label: `${section.className}${section.sectionName}`,
            value: `${section.className}${section.sectionName}`,
          },
          selectedSubject: {
            id: subject.subjectId,
            class: subject.subjectName,
            label: subject.subjectName,
            value: subject.subjectName,
          },
          selectedSubjectMasterId: subject.masterSubjectId,
          selectedBoard: section.school.board,
          selectedClassNumber: section.className,
          subjectsList: this.state.subjectsPerSection[section.sectionId],
          selectedStudents: quiz.assignedStudents.map(({ student }) => ({
            id: student.studentId,
            class: student.studentName || student.fullName,
            label: student.studentName || student.fullName,
            value: student.studentName || student.fullName,
          })),
          assignLater: quiz.assignLater ? moment(quiz.assignLater) : null,
          isAssignLaterActive: !!quiz.assignLater,
        })
        this.getStudents(section.sectionId)

        this.getChapters(subject.subjectId, section.sectionId)
      }
    })
  }

  deteleQuiz = id => {
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT + "",
      yes => {
        if (yes) {
          this.deteleQuizAction(id)
        }
      },
      {
        customUI: ({ onClose }) => {
          return (
            <div
              className="custom"
              style={{
                backgroundColor: "#fff",
                borderBottomRightRadius: "12px",
                borderBottomLeftRadius: "12px",
              }}
            >
              <div
                className="bg-brand text-light d-flex"
                style={{
                  padding: "0.5rem 2rem",
                  borderTopRightRadius: "12px",
                  borderTopLeftRadius: "12px",
                }}
              >
                <div className="mx-auto" style={{ fontSize: "18px" }}>
                  Delete
                </div>
              </div>

              <div
                className="mt-3 "
                style={{
                  padding: "0.5rem 1.5rem",
                }}
              >
                <div className="text-muted" style={{ fontSize: "15px" }}>
                  Are you sure you want to delete?
                </div>
                <div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
                  <button
                    className="bg-brand text-light px-4 py-1 w-25 d-flex align-items-center justify-content-center yes-button"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      this.deteleQuizAction(id)
                      onClose()
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-brand text-light px-4 py-1 ml-4 w-25 d-flex align-items-center justify-content-center"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={onClose}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )
        },
      }
    )
  }

  deteleQuizAction = id => {
    this.setState({ deletingItemId: id })
    this.props.deleteQuizAssign({ id }, (success, _) => {
      this.setState({ deletingItemId: null, deletedItem: true })
      if (success) {
        this.getQuizAssignList()
      }
    })
  }

  setQuizAnalytics = () => {
    // this.getQuizAssignList()
    const { quizAssignList } = this.props
    const quizAnalytics = {}
    const upcoming = quizAssignList
      .filter(e => e.status === "UPCOMING")
      .sort((a, b) => a.startTime.localeCompare(b.startTime))
      .reverse()
    const ungraded = quizAssignList
      .filter(e => e.status === "UNGRADED")
      .sort((a, b) => a.startTime.localeCompare(b.startTime))
      .reverse()
    const completed = quizAssignList
      .filter(e => e.status === "COMPLETED")
      .sort((a, b) => a.startTime.localeCompare(b.startTime))
      .reverse()

    quizAssignList.forEach(quiz => {
      // Add it to quiz analytics
      const { className, sectionName } = quiz.teacherAssignedDetail.section
      const key = `${className}-${sectionName}`
      const count = quizAnalytics[key] || 0
      quizAnalytics[key] = count + 1
    })

    this.setState({
      quizAnalytics,
      upcoming,
      ungraded,
      completed,
    })

    if (this.state.deletedItem) {
      this.setState({
        staticquizAnalytics: quizAnalytics,
        deletedItem: false,
      })
    } else {
      if (!this.state.staticquizAnalytics) {
        this.setState({
          staticquizAnalytics: quizAnalytics,
        })
      }
    }
  }

  saveQuizAssigns = () => {
    const {
      quizAssignId,
      startTime,
      endTime,
      submissionType,
      assignLater,
      title,
      description,
      maxMarks,
      timeLimit,
      shuffle,
      immediateResult,
      selectedClass,
      selectedSubject,
      selectedStudents,
      selectedChapters,
      submitTime,
    } = this.state

    const subjectChapterIds = selectedChapters
      .map(({ id }) => id)
      .filter(val => !!val)

    /* checking mandatory fields... */
    if (!submitTime) {
      return this.errorHandling("Something went wrong...")
    }
    if (!startTime || !endTime) {
      return this.errorHandling("Select date & time...")
    }
    if (!quizAssignId) {
      if (startTime >= endTime || endTime < submitTime) {
        return this.errorHandling("Select correct Date & Time...")
      }
      if (assignLater && assignLater < submitTime) {
        return this.errorHandling("Select correct assign later time...")
      }
    }
    if (
      !submissionType ||
      (submissionType !== "IN_SCHOOL" && submissionType !== "ONLINE")
    ) {
      return this.errorHandling("Select right submission type...")
    }
    const isOnline = submissionType === "ONLINE"

    if (!selectedClass || !selectedClass.id) {
      return this.errorHandling("Select class...")
    }
    if (!selectedSubject || !selectedSubject.id) {
      return this.errorHandling("Select subject...")
    }
    if (!selectedStudents || selectedStudents.filter(e => e.id).length === 0) {
      return this.errorHandling("Select students...")
    }
    // if (subjectChapterIds.length === 0) {
    //   return this.errorHandling("Select chapters...")
    // }
    if (isOnline) {
      if (!timeLimit) {
        return this.errorHandling("Select time limit...")
      }
      if (timeLimit > endTime.diff(startTime, "minutes")) {
        return this.errorHandling(
          "Enter time limit lower than duration time..."
        )
      }
    }
    if (!isOnline) {
      if (!description) {
        return this.errorHandling("Enter description...")
      }
      if (!maxMarks) {
        return this.errorHandling("Enter Maximum Marks")
      }
      try {
        const intMaxMarks = parseInt(maxMarks)
        if (intMaxMarks <= 0) {
          return this.errorHandling("Maximum Marks should be more than 0")
        }
        if (intMaxMarks > 999) {
          return this.errorHandling("Maximum Marks cannot be more than 998")
        }
      } catch (e) {
        return this.errorHandling("Maximum Marks should be a number")
      }
    }
    if (!title) {
      return this.errorHandling("Enter title...")
    }

    /*
     * mandatory fields:
     * start,end,submissionType,
     * teacherAssignedDetail
     * assignedStudents
     * quizSet: IN_SCHOOL ONLY
     */
    const body = {
      startTime: moment(startTime, "YYYY-MM-DD HH:mm:ss"),
      endTime: moment(endTime, "YYYY-MM-DD HH:mm:ss"),
      submissionType,
      teacherAssignedDetail: {
        section: {
          sectionId: selectedClass.id,
        },
        subject: {
          subjectId: selectedSubject.id,
        },
      },
      subjectChapterIds,
      assignedStudents: selectedStudents
        .filter(e => e.id)
        .map(e => ({ studentId: e.id })),
      assignLater: assignLater
        ? moment(assignLater, "YYYY-MM-DD HH:mm:ss")
        : null,
    }

    if (quizAssignId) body.id = quizAssignId

    if (!isOnline) {
      body.quizSet = {
        title,
        instruction: description,
        totalMark: maxMarks,
      }
    } else {
      const min = moment.duration(Number(timeLimit), "minutes")
      body.timeLimit = moment.utc(min.as("milliseconds")).format("HH:mm:ss")
      body.shuffle = !!shuffle
      body.immediateResult = !!immediateResult
      body.quizSet = {
        title,
      }
    }

    // quizSet
    // quizSet and old quizSet

    this.setState({ isSavingQuiz: true })
    this.props.saveQuizAssigns(
      this.state.isSavingQuiz,
      body,
      (success, quizData) => {
        this.setState({ isSavingQuiz: false })
        if (success) {
          this.setQuizAnalytics()
          document.body.classList.remove("modal-open") // temporary fix
          this.setState({ isCreateTestModalOpen: false })
          if (isOnline && !quizAssignId) {
            const reuseQuizData = this.state.reuseQuizData
            this.props.history.push({
              pathname: URL_PATH.TEACHER_CREATE_TESTS,
              state:
                reuseQuizData && reuseQuizData.quizSet
                  ? {
                      quizData,
                      reuseQuizData,
                      title,
                    }
                  : { quizData },
            })
          }
        }
      }
    )
  }

  renderCreateTestModal = () => (
    <Modal
      isOpen={this.state.isCreateTestModalOpen}
      modalClassName="right slide-in-right fade"
    >
      <div
        className="modal-body p-4"
        style={{ backgroundColor: "#F1F7F7", borderRadius: 12 }}
      >
        <div className="d-flex justify-content-between">
          <div>
            <h5
              className="mb-4 text-brand font-weight-bold"
              onClick={getTeacherAssignedDetails}
            >
              CREATE ONLINE TEST
            </h5>
          </div>
          <div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                this.setState({
                  isCreateTestModalOpen: false,
                  isReuseTest: false,
                })
              }}
            >
              <span aria-hidden="true" style={{ verticalAlign: "middle" }}>
                &times;
              </span>
            </button>
          </div>
        </div>

        {this.state.isShowModalLoading ? (
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: 150 }}
          >
            <HashLoader sizeUnit="px" size={35} color="#084353" loading />
          </div>
        ) : (
          <>
            {/* <div className="row align-items-center justify-content-center mx-3 mb-3">
              <Button
                onClick={() => this.setState({ submissionType: "IN_SCHOOL" })}
                style={{
                  borderRadius: 8,
                  backgroundColor:
                    this.state.submissionType === "IN_SCHOOL"
                      ? "#52C7B2"
                      : "#E6E8E7",
                  color:
                    this.state.submissionType === "IN_SCHOOL"
                      ? "#fff"
                      : "#919191",
                  border: 0,
                  marginRight: "4rem",
                }}
              >
                <i className="fas fa-map-marker-alt" />
                <span className="ml-2">InClass Test</span>
              </Button>
              <Button
                onClick={() => this.setState({ submissionType: "ONLINE" })}
                style={{
                  borderRadius: 8,
                  backgroundColor:
                    this.state.submissionType === "ONLINE"
                      ? "#52C7B2"
                      : "#E6E8E7",
                  color:
                    this.state.submissionType === "ONLINE" ? "#fff" : "#919191",
                  border: 0,
                }}
              >
                <i className="fas fa-desktop" />
                <span className="ml-2">Online Test</span>
              </Button>
            </div> */}

            <div className="d-flex mb-3">
              <div style={{ flex: 1, marginRight: 12 }}>
                <FormGroup>
                  <Label
                    className="mb-1 text-brand font-weight-bold"
                    htmlFor="class"
                  >
                    Class<span>*</span>
                  </Label>
                  <Select
                    placeholder="Select Class"
                    isClearable
                    value={this.state.selectedClass}
                    options={(this.state.classesList || []).map(c => ({
                      id: c.sectionId,
                      class: `${c.className}${c.sectionName}`,
                      label: `${c.className}${c.sectionName}`,
                      value: `${c.className}${c.sectionName}`,
                      classNumber: `${c.className}`,
                      board: `${c.school.board}`,
                    }))}
                    className="basic-multi-select mb-3"
                    classNamePrefix="select"
                    hideSelectedOptions={false}
                    closeMenuOnSelect
                    onChange={selectedOption => {
                      if (selectedOption) {
                        const subjectsList = this.state.subjectsPerSection[
                          selectedOption.id
                        ]
                        // myLog("selectedOption", selectedOption)
                        this.setState(
                          {
                            chaptersList: [],
                            selectedClass: selectedOption,
                            subjectsList: subjectsList,
                            selectedSubject: "",
                            selectedStudents: [],
                            selectedChapters: [],
                            selectedClassNumber: parseInt(
                              selectedOption.classNumber
                            ),
                            selectedBoard: selectedOption.board,
                          },
                          () => {
                            this.getStudents(selectedOption.id)
                            this.getAssignedSubject(subjectsList)
                          }
                        )
                      } else {
                        this.setState({
                          selectedClass: null,
                          subjectsList: [],
                          selectedSubject: "",
                          selectedStudents: [],
                          selectedChapters: [],
                          selectedClassNumber: null,
                          selectedBoard: null,
                        })
                      }
                    }}
                  />
                </FormGroup>
              </div>
              <div style={{ flex: 1 }}>
                <FormGroup>
                  <Label
                    className="mb-1 text-brand font-weight-bold"
                    htmlFor="subject"
                  >
                    Subject <span>*</span>
                  </Label>
                  <Select
                    placeholder="Select Subject"
                    isClearable
                    value={this.state.selectedSubject}
                    options={(this.state.subjectsList || []).map(c => ({
                      id: c.subjectId,
                      class: `${c.subjectName}`,
                      label: `${c.subjectName}`,
                      value: `${c.subjectName}`,
                      masterSubjectId: `${c.masterSubjectId}`,
                    }))}
                    className="basic-multi-select mb-3"
                    classNamePrefix="select"
                    hideSelectedOptions={false}
                    closeMenuOnSelect={true}
                    onChange={selectedOption => {
                      if (selectedOption) {
                        this.setState(
                          {
                            chaptersList: [],
                            selectedChapters: [],
                            selectedSubject: selectedOption,
                            selectedSubjectMasterId:
                              selectedOption.masterSubjectId,
                          },
                          () => {
                            this.getChapters()
                          }
                        )
                      } else {
                        this.setState({
                          selectedSubject: "",
                          selectedSubjectMasterId: null,
                        })
                      }
                    }}
                    styles={this.styles}
                  />
                </FormGroup>
              </div>
            </div>

            <div className="mb-3">
              <FormGroup>
                <Label
                  className="mb-1 text-brand font-weight-bold"
                  htmlFor="subject"
                >
                  Students <span>*</span>
                </Label>
                <SelectAssignment
                  placeholder="Select Students"
                  isClearable
                  value={this.state.selectedStudents}
                  options={(this.state.studentsList || []).map(c => ({
                    id: c.studentId,
                    class: c.studentName || c.fullName,
                    label: c.studentName || c.fullName,
                    value: c.studentName || c.fullName,
                  }))}
                  className="basic-multi-select mb-3"
                  classNamePrefix="select"
                  isMulti
                  allowSelectAll={true}
                  components={{
                    Option,
                    MultiValueStudent,
                    ValueContainer,
                    animatedComponents,
                  }}
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  onChange={selectedOption => {
                    this.setState({ selectedStudents: selectedOption })
                    // myLog("selectedoption students", selectedOption)
                  }}
                  styles={this.styleStudent}
                />
              </FormGroup>
              <div className="mb-3">
                <FormGroup>
                  <Label
                    className="mb-1 text-brand font-weight-bold"
                    htmlFor="title"
                  >
                    Title <span>*</span>
                  </Label>
                  <Input
                    type="text"
                    id="title"
                    name="title"
                    placeholder="Enter Title"
                    value={this.state.title}
                    onChange={e => this.setState({ title: e.target.value })}
                  />
                </FormGroup>
              </div>
            </div>

            {/* <div className="mb-3">
              <FormGroup>
                <Label
                  className="mb-1 text-brand font-weight-bold"
                  htmlFor="subject"
                >
                  Select Chapters <span>*</span>
                </Label>
                <MySelect
                  placeholder="Select Chapters"
                  isClearable
                  value={this.state.selectedChapters}
                  options={(this.state.chaptersList || []).map(c => ({
                    id: c.id,
                    label: c.name,
                    value: c.id,
                  }))}
                  className="basic-multi-select mb-3"
                  classNamePrefix="select"
                  isMulti
                  allowSelectAll={true}
                  components={{
                    Option,
                    MultiValue,
                    ValueContainer,
                    animatedComponents,
                  }}
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  onChange={selectedOption => {
                    this.setState({ selectedChapters: selectedOption })
                  }}
                  styles={this.styleStudent}
                />
              </FormGroup>
            </div> */}

            <div className="mb-3">
              <FormGroup>
                <Label className="mb-1 text-brand font-weight-bold" htmlFor="">
                  Date & Time <span>*</span>
                  <i
                    className="ml-2 fas fa-question-circle text-muted"
                    id="dateTimeTooltip"
                  />
                </Label>
                <UncontrolledTooltip placement="top" target="dateTimeTooltip">
                  Date & Time is the period where the test portal will open.
                </UncontrolledTooltip>
                <div className={`d-flex form-control ${this.state.endTime}`}>
                  <RangePicker
                    showTime={{ user12hours: true }}
                    bordered={false}
                    style={{ width: "100%" }}
                    value={[this.state.startTime, this.state.endTime]}
                    onChange={value => {
                      const [startTime, endTime] = value || [moment(), moment()]

                      this.setState({
                        startTime: startTime,
                        endTime: endTime,
                      })
                    }}
                    format="DD MMM YYYY, hh:mm A"
                    disabledDate={current =>
                      current && current < moment().startOf("day")
                    }
                  />
                </div>
              </FormGroup>
            </div>

            {this.state.submissionType === "IN_SCHOOL" && (
              <>
                <div className="mb-3">
                  <FormGroup>
                    <Label
                      className="mb-1 text-brand font-weight-bold"
                      htmlFor="description"
                    >
                      Description <span>*</span>
                    </Label>
                    <Input
                      type="textarea"
                      id="description"
                      name="description"
                      placeholder="Enter Description"
                      value={this.state.description}
                      rows={4}
                      onChange={e =>
                        this.setState({ description: e.target.value })
                      }
                    />
                  </FormGroup>
                </div>
                <div className="px-2">
                  <label className="font-weight-bold text-brand">
                    Max. Marks
                  </label>
                </div>
                <div
                  className="ml-1  form-group"
                  onClick={() => this.viewMark()}
                >
                  <div className="maxmark">
                    <input
                      type="text"
                      value={this.state.maxMarks}
                      name="markValue"
                      // placeholder="Enter Mark"
                      onChange={e => {
                        e.target.value = e.target.value.replace(
                          /[^A-Za-z0-9]/gi,
                          ""
                        )
                        e.target.value =
                          e.target.value === "No marks" ? "" : e.target.value
                        this.setState({ maxMarks: e.target.value })
                      }}
                    />
                    <button
                      onClick={e => {
                        // this.setState({ maxMarks: "" })
                        this.viewMark()
                      }}
                    >
                      <i className="fas fa-caret-down"></i>
                    </button>
                  </div>
                  <div className="noMark" style={{ cursor: "pointer" }}>
                    <p
                      style={{ marginTop: "6px" }}
                      onClick={() => {
                        this.setState({
                          maxMarks: this.state.noMark,
                          viewMark: false,
                        })
                        this.viewMark()
                      }}
                    >
                      {this.state.noMark}
                    </p>
                  </div>
                </div>

                {/* <div className="mb-3">
                   <FormGroup>
                     <Label
                       className="mb-1 text-brand font-weight-bold"
                       htmlFor="maxMarks"
                     >
                       Max. Marks <span>*</span>
                     </Label>
                     <Input
                       type="number"
                       id="maxMarks"
                       name="maxMarks"
                       value={this.state.maxMarks}
                       style={{ width: 100 }}
                       onChange={e => {
                         const value = e.target.value
                         this.setState({ maxMarks: value })
                       }}
                     />
                   </FormGroup>
                 </div> */}
              </>
            )}

            {this.state.submissionType === "ONLINE" && (
              <>
                <FormGroup className="mb-3">
                  <Label
                    className="mb-1 text-brand font-weight-bold"
                    htmlFor="timeLimit"
                  >
                    Max. Time limits <span>*</span>
                    <i
                      className="ml-2 fas fa-question-circle text-muted"
                      id="timeLimitTooltip"
                    />
                  </Label>
                  <UncontrolledTooltip
                    placement="top"
                    target="timeLimitTooltip"
                  >
                    Set a time limit for your test, in minutes. Students must
                    answer all questions before the allotted time has passed.
                  </UncontrolledTooltip>
                  <div className="d-flex align-items-center">
                    <Input
                      id="timeLimit"
                      name="timeLimit"
                      type="number"
                      style={{ width: 70 }}
                      value={this.state.timeLimit}
                      className={
                        this.state.unvalidMaxmark ? "border-danger" : ""
                      }
                      onChange={e => {
                        const value = e.target.valueAsNumber
                        const { startTime, endTime } = this.state
                        if (value && startTime && endTime) {
                          const diff = endTime.diff(startTime, "minutes")
                          if (diff)
                            this.setState({ unvalidMaxmark: value > diff })
                        }
                        this.setState({ timeLimit: value })
                      }}
                    />
                    <div className="text-muted ml-2">mins.</div>
                  </div>
                </FormGroup>

                <div
                  className="mb-2 d-flex align-items-center"
                  onClick={() => this.setState({ hidden: !this.state.hidden })}
                >
                  <p
                    className="cursor-pointer mb-0 mr-2 font-weight-bold"
                    style={{
                      color: this.state.hidden
                        ? "#094251"
                        : "rgb(82, 199, 178,0.9)",
                    }}
                  >
                    Advance Settings
                  </p>

                  <p
                    className="cursor-pointer mb-0 d-flex align-items-center"
                    style={{
                      width: "24px",
                      height: "24px",
                      borderRadius: "50%",
                      backgroundColor: this.state.hidden
                        ? "#094251"
                        : "rgb(255, 0, 0, 0.8)",
                    }}
                  >
                    {this.state.hidden ? (
                      <Plus
                        width="24px"
                        height="24px"
                        className="cursor-pointer"
                        style={{ fill: "#fff" }}
                      />
                    ) : (
                      <Minus
                        width="24px"
                        height="24px"
                        className="cursor-pointer"
                        style={{ fill: "#fff" }}
                      />
                    )}
                  </p>
                </div>

                {!this.state.hidden && (
                  <>
                    <FormGroup className="mb-3" check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={this.state.shuffle}
                          onChange={() =>
                            this.setState(state => ({
                              shuffle: !state.shuffle,
                            }))
                          }
                        />{" "}
                        Shuffle Questions
                      </Label>
                    </FormGroup>

                    <FormGroup className="mb-3" check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={this.state.immediateResult}
                          onChange={() => {
                            this.setState(state => ({
                              immediateResult: !state.immediateResult,
                            }))
                          }}
                        />{" "}
                        Show result to students after completion
                      </Label>
                    </FormGroup>

                    <FormGroup className="mb-3" check>
                      <Label check>
                        <Input
                          type="checkbox"
                          id="isAssignLaterActive"
                          name="isAssignLaterActive"
                          className="text-brand"
                          checked={this.state.isAssignLaterActive}
                          onChange={() => {
                            this.setState(
                              state => ({
                                isAssignLaterActive: !state.isAssignLaterActive,
                              }),
                              () => {
                                this.setState({
                                  assignLater: this.state.isAssignLaterActive
                                    ? moment().set({
                                        minute: 0,
                                        second: 0,
                                        millisecond: 0,
                                      })
                                    : null,
                                })
                              }
                            )
                          }}
                        />{" "}
                        Assign Later
                      </Label>
                    </FormGroup>
                  </>
                )}
              </>
            )}

            {this.state.isAssignLaterActive && (
              <div className="mb-3">
                <FormGroup>
                  <Label className="mb-1" htmlFor="">
                    Date & Time
                  </Label>
                  <div style={{ display: "block" }}>
                    <DatePicker
                      placeholder="select date"
                      style={{ width: "150px", display: "inline-flex" }}
                      value={this.state.assignLater}
                      format="DD MMM YYYY"
                      size="large"
                      onSelect={e => {
                        this.setState({ assignLater: e })
                      }}
                      disabledDate={current =>
                        current &&
                        (current < moment().startOf("day") ||
                          current >= moment(this.state.endTime))
                      }
                    />
                    <TimePicker
                      placeholder="select time"
                      style={{ width: "123px", display: "inline-flex" }}
                      value={this.state.assignLater}
                      format="HH:mm"
                      minuteStep={5}
                      size="large"
                      onSelect={e => {
                        this.setState({ assignLater: e })
                      }}
                      disabledHours={() => {
                        var hours = []
                        for (var i = 0; i < moment().hour(); i++) {
                          hours.push(i)
                        }
                        return hours
                      }}
                      disabledMinutes={selectedHour => {
                        var minutes = []
                        if (selectedHour === moment().hour()) {
                          for (var i = 0; i < moment().minute(); i++) {
                            minutes.push(i)
                          }
                        }
                        return minutes
                      }}
                    />
                  </div>
                </FormGroup>
              </div>
            )}

            <div className="d-flex align-items-center justify-content-around">
              <Button
                className="bg-brand text-light px-4 py-1"
                loading={this.state.isSavingQuiz}
                style={{ borderRadius: 12, border: 0 }}
                onClick={this.saveQuizAssigns}
              >
                {this.state.isSavingQuiz ? (
                  <HashLoader sizeUnit={"px"} size={20} color="#fff" loading />
                ) : this.state.submissionType === "ONLINE" &&
                  !this.state.quizAssignId ? (
                  [
                    <span>
                      {this.state.isReuseTest ? "REUSE TEST" : "CREATE "}
                    </span>,
                  ]
                ) : (
                  <span>ASSIGN</span>
                )}
              </Button>
              <Button
                color="light"
                className="text-muted px-4 py-1"
                style={{ borderRadius: 12, border: 0 }}
                data-dismiss="modal"
                onClick={() => {
                  this.setState({
                    isCreateTestModalOpen: false,
                    isReuseTest: false,
                  })
                }}
              >
                Cancel
              </Button>
              <span></span>
            </div>
          </>
        )}
      </div>
    </Modal>
  )

  renderTestPreview = (item, index) => {
    const {
      id,
      assignLater,
      endTime,
      publishLater,
      published,
      startTime,
      submissionType,
      quizSet,
      teacherAssignedDetail,
      assignedStudents,
      status,
    } = item

    const { section, subject } = teacherAssignedDetail
    const { className, sectionName } = section
    const { subjectName } = subject
    const publishLaterDateTime = publishLater
      ? moment(publishLater).format("DD MMM YYYY, hh:mm a")
      : ""
    const isPublishLater = publishLater
      ? moment(publishLater) > moment()
      : false

    const customInfo = () => {
      switch (status) {
        case "UPCOMING":
          return moment().to(startTime)
        case "UNGRADED":
          const attendees = assignedStudents.filter(s => s.submitted).length
          const total = assignedStudents.length
          return `${attendees} / ${total} attendees`
        case "COMPLETED":
          return published && !isPublishLater ? "Published" : "Publish later"
        default:
          return ""
      }
    }

    const isOnline = submissionType === "ONLINE"
    const isDraft = quizSet.draft === 1

    const now = moment()
    const isLive =
      !isDraft &&
      moment(startTime).isSameOrBefore(now) &&
      moment(endTime).isAfter(now)

    const isDeleting = this.state.deletingItemId === id

    return (
      <div
        key={item.id}
        className="bg-light px-2 py-3 my-2 shadow teacher-test-preview cursor-pointer"
        style={{ position: "relative", borderRadius: 14 }}
        onClick={() => {
          if (status !== "UPCOMING") {
            this.props.history.push({
              pathname: URL_PATH.TEACHER_TESTS_MARK_ENTRY,
              state: { quizAssignInfoId: item.id },
            })
          }
        }}
      >
        {isDeleting && (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              borderRadius: 14,
              overflow: "hidden",
            }}
          >
            <Skeleton height="100%" width="100%" />
          </div>
        )}
        {!isDeleting && !isLive && !isDraft && (
          <div
            className="text-center"
            style={{
              width: "40%",
              minWidth: "100px",
              position: "absolute",
              top: 0,
              right: 0,
              borderTopRightRadius: 14,
              borderBottomLeftRadius: 14,
              fontSize: "0.9rem",
              fontWeight: "bold",
              backgroundColor: "rgba(9, 67, 83, 0.21)",
              color: "#094353",
            }}
            title={isPublishLater ? publishLaterDateTime : ""}
          >
            {customInfo()}
          </div>
        )}
        <div className="d-flex align-items-center mb-05">
          <div
            className="font-weight-bold px-1 py-05 mr-3 rounded"
            style={{
              backgroundColor: "rgba(9, 67, 83, 0.21)",
              color: "#094353",
            }}
          >
            Class {`${className}${sectionName}`}
          </div>
          <div className="d-flex align-items-center">
            <div
              className="rounded-pill"
              style={{
                width: 10,
                height: 10,
                backgroundColor: isOnline ? "#15af8f" : "#f16d6f",
              }}
            />
            <div className="ml-1">{isOnline ? "Online" : "InClass"}</div>
          </div>
          {isDraft && (
            <div
              className="bg-warning text-dark rounded px-2 py-05 ml-auto d-flex align-items-center"
              onClick={() =>
                this.props.history.push({
                  pathname: URL_PATH.TEACHER_CREATE_TESTS,
                  state: { quizData: item },
                })
              }
            >
              <span className="ml-1" style={{ fontSize: 14 }}>
                DRAFT
              </span>
            </div>
          )}
          {!isDraft && isLive && (
            <div className="bg-danger text-light rounded px-2 py-05 ml-auto d-flex align-items-center">
              <i className="far fa-circle" style={{ fontSize: 10 }} />
              <span className="ml-1" style={{ fontSize: 14 }}>
                LIVE
              </span>
            </div>
          )}
        </div>
        <h5
          className="text-brand m-0 pt-3 pb-2"
          style={{
            lineBreak: "anywhere",
            whiteSpace: "pre-wrap",
          }}
        >
          {subjectName} -{" "}
          {quizSet
            ? String(quizSet.title).length > 25
              ? String(quizSet.title)
                  .slice(0, 10)
                  .concat("...")
              : quizSet.title
            : ""}
        </h5>
        <div className="d-flex align-item-center mt-05">
          <div className="text-brand mr-1 ">
            {moment(startTime).format("DD MMM  hh:mm a")} -{" "}
            {moment(endTime).format("DD MMM  hh:mm a")}
          </div>
          <div className="ml-auto d-flex">
            {moment(assignLater) > moment() && (
              <i
                className="px-2 py-1 cursor-pointer text-brand fas fa-calendar-alt mr-1"
                title={moment(assignLater).format("DD MMM YYYY hh:mm a")}
              />
            )}

            {!isDeleting && !isLive && (
              <UncontrolledDropdown direction="top">
                <DropdownToggle
                  tag="a"
                  onClick={e => {
                    e.stopPropagation()
                  }}
                  className="nav-link p-0 pr-1"
                >
                  <i className="px-2 py-1 cursor-pointer text-brand fas fa-ellipsis-v teacher-test-preview-buttons" />
                </DropdownToggle>
                <DropdownMenu direction="top" style={{ left: "-90px" }}>
                  <DropdownItem
                    className={
                      !isOnline || status !== "UPCOMING" ? "disabled" : ""
                    }
                    onClick={e => {
                      if (isOnline) {
                        e.stopPropagation()
                        this.props.history.push({
                          pathname: URL_PATH.TEACHER_CREATE_TESTS,
                          state: { isEdit: true, quizData: item },
                        })
                      }
                    }}
                  >
                    Edit questions
                  </DropdownItem>
                  <DropdownItem
                    data-toggle="modal"
                    data-target="#createTestModal"
                    className={status !== "UPCOMING" ? "disabled" : ""}
                    onClick={e => {
                      e.stopPropagation()
                      this.clearFormData(id)
                      this.setState({
                        isCreateTestModalOpen: true,
                      })
                      this.setReuseModal(id)
                    }}
                  >
                    Edit details
                  </DropdownItem>
                  <DropdownItem
                    className={
                      !item.quizSet || status === "UPCOMING" ? "disabled" : ""
                    }
                    onClick={e => {
                      if (item.quizSet) {
                        e.stopPropagation()
                        this.props.history.push({
                          pathname: URL_PATH.TEACHER_TESTS_MARK_ENTRY,
                          state: { quizAssignInfoId: item.id },
                        })
                      }
                    }}
                  >
                    Enter/view marks
                  </DropdownItem>
                  <DropdownItem
                    className={
                      !item.quizSet || status === "UPCOMING" ? "disabled" : ""
                    }
                    onClick={e => {
                      if (
                        ["COMPLETED", "UNGRADED"].includes(status) &&
                        item.quizSet
                      ) {
                        e.stopPropagation()
                        this.props.history.push({
                          pathname: URL_PATH.TEACHER_TESTS_REPORT,
                          state: { quizAssignId: item.id },
                        })
                      }
                    }}
                  >
                    View Analysis
                  </DropdownItem>
                  <DropdownItem
                    data-toggle="modal"
                    data-target="#createTestModal"
                    onClick={e => {
                      e.stopPropagation()
                      this.clearFormData()
                      this.setState(
                        {
                          isCreateTestModalOpen: true,
                          reuseQuizData: item,
                          isReuseTest: true,
                        },
                        () => {
                          this.setReuseModal(id)
                        }
                      )
                    }}
                  >
                    Reuse test
                  </DropdownItem>
                  <DropdownItem
                    onClick={e => {
                      e.stopPropagation()
                      this.deteleQuiz(id)
                    }}
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
          </div>
        </div>
      </div>
    )
  }

  quizHashLoader = () => (
    <div className="d-flex justify-content-center" style={{ marginTop: 150 }}>
      <HashLoader
        sizeUnit={"px"}
        size={35}
        color={"#ffffff"}
        loading={this.state.isShowLoading}
      />
    </div>
  )

  quizSkeletonLoader = () => (
    <div
      style={{
        height: 90,
        position: "relative",
        borderRadius: 14,
        overflow: "hidden",
      }}
    >
      <Skeleton height="100%" width="100%" />
    </div>
  )

  styles = {
    option: (provided, state) => ({
      ...provided,
      color: "black",
      backgroundColor: "white",
    }),
  }

  studentStyle = {
    option: (provided, state) => ({
      ...provided,
      color: "black",
      backgroundColor: "white",
    }),
    multiValueRemove: () => ({
      display: "none",
    }),
  }

  styleStudent = {
    option: (provided, state) => ({
      ...provided,
      color: "black",
      backgroundColor: "white",
    }),
    multiValueRemove: () => ({
      display: "none",
    }),
  }

  viewMark = () => {
    var field = document.getElementsByClassName("noMark")[0]
    this.setState({ viewMark: !this.state.viewMark })
    this.state.viewMark
      ? (field.style.display = "block")
      : (field.style.display = "none")
  }

  render() {
    const {
      classesPageIndex,
      quizAnalytics,
      upcoming,
      ungraded,
      completed,
    } = this.state
    return (
      <>
        {this.renderCreateTestModal()}
        <SkeletonTheme color="#f8f9fa" highlightColor="#eee">
          <AppWrapper
            teacherDashboard
            showTeacherThree
            showTeacherLogo
            screenName={"TESTS"}
          >
            <div
              className="mx-2 d-flex flex-column"
              style={{ minHeight: "calc(100% - 1rem)" }}
            >
              <div className="row">
                <div className="col-md-6 d-flex align-items-center justify-content-start">
                  <div
                    className="py-2 shadow d-flex"
                    style={{ borderRadius: 18 }}
                  >
                    <div
                      className="px-2 py-2 d-flex flex-column mx-2 cursor-pointer"
                      style={{
                        borderRadius: 16,
                        backgroundColor: !this.state.querySection && "#CAD7DB",
                      }}
                      onClick={() => {
                        this.setState({ querySection: "" })
                        this.getQuizAssignList("")
                      }}
                    >
                      <h4 className="text-brand font-weight-bold text-center my-1">
                        {this.props.quizAssignList.length}
                      </h4>
                      <div className="text-brand text-uppercase text-center font-weight-bold">
                        Total Test
                      </div>
                    </div>
                    {this.state.staticquizAnalytics &&
                      Object.entries(this.state.staticquizAnalytics).map(
                        ([section, count], idx) => {
                          const [className, sectionName] = section.split("-")
                          const sec = this.state.classesList.find(
                            cls =>
                              cls.className === className &&
                              cls.sectionName === sectionName
                          )
                          if (!sec) return <></>
                          return (
                            <div
                              key={idx}
                              className="px-2 py-2 d-flex flex-column mx-2 cursor-pointer"
                              style={{
                                borderRadius: 16,
                                backgroundColor:
                                  this.state.querySection === sec.sectionId &&
                                  "#CAD7DB",
                              }}
                              onClick={() => {
                                this.setState({ querySection: sec.sectionId })
                                this.getQuizAssignList(sec.sectionId)
                              }}
                            >
                              <h5 className="text-brand font-weight-bold my-1">
                                {count}
                              </h5>

                              <div className="text-brand text-center">
                                Class {`${className}${sectionName}`}
                              </div>
                            </div>
                          )
                        }
                      )}
                    {classesPageIndex > 0 && (
                      <div
                        className="py-2 d-flex flex-column mx-2"
                        style={{ borderRadius: 16 }}
                      >
                        <div
                          className="px-2 cursor-pointer"
                          style={{ height: "50%" }}
                          onClick={() =>
                            this.setState({
                              classesPageIndex: classesPageIndex - 1,
                            })
                          }
                        >
                          <i className="text-brand fas fa-chevron-left" />
                        </div>
                        {quizAnalytics &&
                          Object.entries(quizAnalytics).slice(
                            classesPageIndex,
                            classesPageIndex + 4
                          ).length > 4 && (
                            <div
                              className="px-2 cursor-pointer mt-auto"
                              style={{ height: "50%" }}
                              onClick={() =>
                                this.setState({
                                  classesPageIndex: classesPageIndex + 1,
                                })
                              }
                            >
                              <i className="text-brand fas fa-chevron-right" />
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 d-flex align-items-start justify-content-end pt-2">
                  <Link
                    to={URL_PATH.TEACHER_TESTS_QUESTION_BANK}
                    className="btn btn-light mr-2 border-0 font-weight-bold"
                    style={{ borderRadius: 8, color: "#13af8f" }}
                  >
                    Question Bank
                  </Link>
                  {this.state.createTestLoading ? (
                    <div style={{ borderRadius: "14%" }}>
                      <Skeleton height={35} width={90} />
                    </div>
                  ) : (
                    <Button
                      onClick={() => {
                        this.setState({ isCreateTestModalOpen: true })
                        this.clearFormData()
                        this.preSelection()
                      }}
                      className="bg-brand text-light border-0"
                      style={{ borderRadius: 8 }}
                    >
                      Create Test
                    </Button>
                  )}
                </div>
              </div>
              <div className="row align-items-center mt-2">
                <div className="col d-flex justify-content-end">
                  <FormGroup className="d-flex align-items-center mb-0 mr-2">
                    <Label
                      htmlFor="selectClass"
                      className="text-dark mb-0 mr-2"
                    >
                      Class
                    </Label>
                    <select
                      id="selectClass"
                      className="custom-select"
                      value={this.state.querySection}
                      onChange={e => {
                        this.setState({ querySection: e.target.value })
                        this.getQuizAssignList(e.target.value)
                      }}
                    >
                      <option value="">All Classes</option>
                      {(this.state.classesList || []).map(item => {
                        return (
                          <option key={item.sectionId} value={item.sectionId}>
                            {`${item.className}${item.sectionName}`}
                          </option>
                        )
                      })}
                    </select>
                  </FormGroup>
                </div>
              </div>

              <div className="row" style={{ flex: 1 }}>
                <div className="col-md mt-4 mx-2 d-flex flex-column">
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="text-brand font-weight-bold mb-0">
                      Upcoming ({upcoming.length})
                    </h5>
                  </div>

                  <div
                    className="bg-brand shadow mt-2 px-2 d-flex flex-column align-items-strech py-2"
                    style={{ borderRadius: 18, flex: 1 }}
                  >
                    {this.state.isShowLoading
                      ? this.quizHashLoader()
                      : upcoming.slice(0, 5).map(this.renderTestPreview)}
                  </div>
                </div>
                <div className="col-md mt-4 mx-2 d-flex flex-column">
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="text-brand font-weight-bold mb-0">
                      Yet To Grade ({ungraded.length})
                    </h5>
                  </div>

                  <div
                    className="bg-brand shadow mt-2 px-2 d-flex flex-column align-items-strech py-2"
                    style={{ borderRadius: 18, flex: 1 }}
                  >
                    {this.state.isShowLoading
                      ? this.quizHashLoader()
                      : ungraded.slice(0, 5).map(this.renderTestPreview)}
                  </div>
                </div>
                <div className="col-md mt-4 mx-2 d-flex flex-column">
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="text-brand font-weight-bold mb-0">
                      Completed ({completed.length})
                    </h5>
                    <div
                      className="d-flex align-items-center px-2 cursor-pointer"
                      onClick={() =>
                        this.props.history.push({
                          pathname: URL_PATH.TEACHER_TESTS_VIEW_ALL,
                          state: { classesList: this.state.classesList },
                        })
                      }
                    >
                      <div
                        className="font-weight-bold"
                        style={{ color: "#13af8f" }}
                      >
                        View All
                      </div>
                      <i
                        className="fas fa-chevron-right text-light rounded-sm ml-2"
                        style={{
                          backgroundColor: "#13af8f",
                          padding: "4px",
                          fontSize: "10px",
                        }}
                      />
                    </div>
                  </div>

                  <div
                    className="bg-brand shadow mt-2 px-2 d-flex flex-column align-items-strech py-2"
                    style={{ borderRadius: 18, flex: 1 }}
                  >
                    {this.state.isShowLoading
                      ? this.quizHashLoader()
                      : completed.slice(0, 5).map(this.renderTestPreview)}
                  </div>
                </div>
              </div>
            </div>
          </AppWrapper>
        </SkeletonTheme>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    quizAssignList: state.quiz.quizAssignList,
    quizAssign: state.quiz.quizAssign,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      showNotification,
      saveQuizAssigns,
      getSingleQuizAssign,
      deleteQuizAssign,
      getQuizAssignList,
      getTeacherAssignedDetails,
      getClassStudents,
      getChapters,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  dispatchToProps
)(withRouter(AssessmentScreen))
