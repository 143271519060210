/*************************************************
 * RedInk
 * @exports
 * @class SchoolDetailsModal.js
 * @extends Component
 * @author Sasidharan // on 18/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import Modal, { ModalTransition } from "@atlaskit/modal-dialog"
import { getBase64, validateEmail } from "../../utils/Utility"
import { countryList } from "../../utils/Countries"

class SchoolDetailsModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showChooseLogo: false,
      schoolLogo: "",
      assignAdminSet: [{ adminName: "", adminEmail: "", adminContact: "" }],
      schoolName: "",
      schoolEmail: "",
      affiliation: "",
      contactNumber: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      country: "India",
      zipCode: "",

      hasError: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.schoolDetailsData !== this.props.schoolDetailsData) {
      this.setState({
        schoolLogo: this.props.schoolDetailsData.schoolLogo,
        assignAdminSet: this.props.schoolDetailsData.admins,
        schoolName: this.props.schoolDetailsData.schoolName,
        schoolEmail: this.props.schoolDetailsData.schoolEmailId,
        affiliation: this.props.schoolDetailsData.affiliation,
        contactNumber: this.props.schoolDetailsData.schoolContactNumber,
        addressLine1: this.props.schoolDetailsData.addressLine1,
        addressLine2: this.props.schoolDetailsData.addressLine2,
        city: this.props.schoolDetailsData.city,
        state: this.props.schoolDetailsData.state,
        country: this.props.schoolDetailsData.country,
        zipCode: this.props.schoolDetailsData.zipCode,
      })
    }
    if (
      nextProps.clearSchoolDetailsModal !== this.props.clearSchoolDetailsModal
    ) {
      this.setState({
        schoolLogo: "",
        assignAdminSet: [{ adminName: "", adminEmail: "", adminContact: "" }],
        schoolName: "",
        schoolEmail: "",
        affiliation: "",
        contactNumber: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "India",
        zipCode: "",
      })
    }
  }

  /**
   * Open File Explorer on click logo area
   */
  selectSchoolLogoClicked = () => {
    this.refs.schoolLogoUpload.click()
  }
  /**
   * After choosing image from explorer
   */
  onSelectSchoolLogo = file => {
    getBase64(file, schoolLogo => {
      this.setState({ schoolLogo })
    })
  }
  /**
   * Add new assign admin form
   */
  addAssignAdminForm = index => {
    const tempARR = this.state.assignAdminSet
    tempARR.push({
      adminName: "",
      adminEmail: "",
      adminContact: "",
    })
    this.setState({ assignAdminSet: tempARR })
  }
  /**
   * Delete new assign admin form
   */
  deleteAssignAdminForm = index => {
    if (this.state.assignAdminSet.length > 1) {
      let arrAdmin = this.state.assignAdminSet
      arrAdmin.splice(index, 1)
      this.setState({
        assignAdminSet: arrAdmin,
      })
    }
  }

  /**
   * Handles on submit school details
   */
  onSubmitSchoolDetails = closeModal => {
    if (
      this.state.schoolLogo === "" ||
      this.state.assignAdminSet[0].adminName === "" ||
      this.state.assignAdminSet[0].adminEmail === "" ||
      this.state.assignAdminSet[0].adminContact === "" ||
      this.state.schoolName === "" ||
      this.state.schoolEmail === "" ||
      !validateEmail(this.state.schoolEmail) ||
      this.state.affiliation === "" ||
      this.state.contactNumber === "" ||
      this.state.addressLine1 === "" ||
      this.state.addressLine2 === "" ||
      this.state.city === "" ||
      this.state.state === "" ||
      this.state.country === "" ||
      this.state.zipCode === ""
    ) {
      this.setState({ hasError: true })
    } else {
      let params = {
        schoolName: this.state.schoolName,
        emailId: this.state.schoolEmail,
        affiliation: this.state.affiliation.toUpperCase(),
        schoolLogo: this.state.schoolLogo,
        contactNumber: this.state.contactNumber,
        addressLine1: this.state.addressLine1,
        addressLine2: this.state.addressLine2,
        city: this.state.city,
        state: this.state.state,
        country: this.state.country,
        zipCode: this.state.zipCode,
        assignedAdmin: this.state.assignAdminSet,
      }
      if (this.props.schoolDetailsData.branchId) {
        params.branchId = this.props.schoolDetailsData.branchId
      }
      this.props.onSubmitSchoolDetails(params, closeModal)
    }
  }

  render() {
    const { isModalOpen } = this.props
    return (
      <ModalTransition>
        {isModalOpen && (
          <Modal
            width="80%"
            heading="ENTER YOUR SCHOOL DETAILS"
            actions={[
              {
                text: this.props.showLoading ? "Submitting" : "Submit",
                className: this.props.showLoading ? "disabled" : "",
                onClick: () => this.onSubmitSchoolDetails(true),
                style: {
                  borderRadius: 5,
                },
              },
              {
                text: this.props.showLoading
                  ? "Please wait"
                  : this.props.isCreateSchoolClicked ||
                    this.props.isEditSchoolClicked
                  ? "Cancel"
                  : "Add Another Branch",
                className: this.props.showLoading ? "disabled" : "",
                onClick: () => {
                  if (
                    this.props.isCreateSchoolClicked ||
                    this.props.isEditSchoolClicked
                  ) {
                    this.props.closeModal()
                  } else {
                    this.onSubmitSchoolDetails(false)
                  }
                },
                style: { borderRadius: 5 },
              },
            ]}
          >
            <div className="d-flex justify-content-center align-items-center mb-4">
              {this.state.schoolLogo === "" ? (
                <span
                  onClick={() => this.selectSchoolLogoClicked()}
                  onMouseEnter={() => this.setState({ showChooseLogo: true })}
                  onMouseLeave={() => this.setState({ showChooseLogo: false })}
                  className="school-form-logo"
                >
                  {!this.state.showChooseLogo &&
                  this.state.schoolLogo === "" ? (
                    <i className="fal fa-image"></i>
                  ) : null}
                </span>
              ) : null}
              {this.state.schoolLogo !== "" ? (
                <img
                  onMouseEnter={() => this.setState({ showChooseLogo: true })}
                  onMouseLeave={() => this.setState({ showChooseLogo: false })}
                  style={{ objectFit: "cover", width: 100, height: 100 }}
                  onClick={() => this.selectSchoolLogoClicked()}
                  src={this.state.schoolLogo}
                  className="img-fluid rounded-circle"
                  alt="logo"
                ></img>
              ) : null}
              {this.state.showChooseLogo ? (
                <small
                  className="p-1 cursor-pointer"
                  style={{
                    position: "absolute",
                    fontWeight: "bold",
                    backgroundColor: "white",
                    borderRadius: 5,
                  }}
                  onClick={() => this.selectSchoolLogoClicked()}
                  onMouseEnter={() => this.setState({ showChooseLogo: true })}
                  onMouseLeave={() => this.setState({ showChooseLogo: false })}
                >
                  CHOOSE LOGO
                  <input
                    type="file"
                    onChange={event =>
                      this.onSelectSchoolLogo(event.target.files[0])
                    }
                    ref="schoolLogoUpload"
                    className="d-none"
                    accept="image/x-png,image/gif,image/jpeg"
                  />
                </small>
              ) : null}
            </div>
            <div className="d-flex justify-content-center">
              {this.state.schoolLogo === "" && this.state.hasError ? (
                <p className="text-danger">School Logo is required.</p>
              ) : null}
            </div>
            <div className="d-flex justify-content-around">
              <div className="w-50">
                <div className="form-group w-100">
                  <small>School Name</small>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.schoolName}
                    onChange={e =>
                      this.setState({ schoolName: e.target.value })
                    }
                  />
                  {this.state.schoolName === "" && this.state.hasError ? (
                    <p className="text-danger">School Name is required.</p>
                  ) : null}
                </div>
                <div className="form-group">
                  <small>Affiliation</small>
                  <select
                    className="form-control"
                    value={this.state.affiliation}
                    onChange={e =>
                      this.setState({ affiliation: e.target.value })
                    }
                  >
                    <option value="">Select a Affiliation</option>
                    <option value="CBSE">CBSE</option>
                    <option value="IB">IB</option>
                    <option value="ICSE">ICSE</option>
                    <option value="IGSE">IGSE</option>
                    <option value="IGCSE">IGCSE</option>
                    <option value="STATE BOARD">STATE BOARD</option>
                  </select>
                  {this.state.affiliation === "" && this.state.hasError ? (
                    <p className="text-danger">Affiliation is required.</p>
                  ) : null}
                </div>
                <div className="form-group">
                  <small>Address Line 1</small>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.addressLine1}
                    onChange={e =>
                      this.setState({ addressLine1: e.target.value })
                    }
                  />
                  {this.state.addressLine1 === "" && this.state.hasError ? (
                    <p className="text-danger">Address Line 1 is required.</p>
                  ) : null}
                </div>
              </div>
              <div className="px-2"></div>
              <div className="w-50">
                <div className="form-group">
                  <small>Email Id</small>
                  <input
                    type="email"
                    className="form-control"
                    value={this.state.schoolEmail}
                    onChange={e =>
                      this.setState({ schoolEmail: e.target.value })
                    }
                  />
                  {(this.state.schoolEmail === "" ||
                    !validateEmail(this.state.schoolEmail)) &&
                  this.state.hasError ? (
                    <p className="text-danger">Email is required.</p>
                  ) : null}
                </div>
                <div className="form-group">
                  <small>Contact number</small>
                  <input
                    type="number"
                    className="form-control"
                    value={this.state.contactNumber}
                    onChange={e =>
                      this.setState({ contactNumber: e.target.value })
                    }
                  />
                  {this.state.contactNumber === "" && this.state.hasError ? (
                    <p className="text-danger">Contact Number is required.</p>
                  ) : null}
                </div>
                <div className="form-group">
                  <small>Address Line 2</small>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.addressLine2}
                    onChange={e =>
                      this.setState({ addressLine2: e.target.value })
                    }
                  />
                  {this.state.addressLine2 === "" && this.state.hasError ? (
                    <p className="text-danger">Address Line 2 is required.</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="form-group mr-2">
                <small>City</small>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.city}
                  onChange={e => this.setState({ city: e.target.value })}
                />
                {this.state.city === "" && this.state.hasError ? (
                  <p className="text-danger">City is required.</p>
                ) : null}
              </div>
              <div className="form-group mr-2">
                <small>State</small>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.state}
                  onChange={e => this.setState({ state: e.target.value })}
                />
                {this.state.state === "" && this.state.hasError ? (
                  <p className="text-danger">State is required.</p>
                ) : null}
              </div>
              <div className="form-group mr-2">
                <small>Country</small>
                <select
                  className="form-control"
                  value={this.state.country}
                  onChange={e => this.setState({ country: e.target.value })}
                >
                  <option value="India">India</option>
                  {countryList.map(country => {
                    return <option value={country}>{country}</option>
                  })}
                </select>
                {this.state.country === "" && this.state.hasError ? (
                  <p className="text-danger">Country is required.</p>
                ) : null}
              </div>
              <div className="form-group">
                <small>Zip Code</small>
                <input
                  type="number"
                  className="form-control"
                  value={this.state.zipCode}
                  onChange={e => this.setState({ zipCode: e.target.value })}
                />
                {this.state.zipCode === "" && this.state.hasError ? (
                  <p className="text-danger">Zip Code is required.</p>
                ) : null}
              </div>
            </div>
            <hr></hr>
            <h5 className="text-center">ASSIGN ADMIN</h5>
            {this.state.assignAdminSet &&
              this.state.assignAdminSet.length &&
              this.state.assignAdminSet.map((item, index) => {
                return (
                  <div className="d-flex justify-content-between">
                    <div className="form-group w-100 mr-2">
                      <small>Admin Name</small>
                      <input
                        type="text"
                        className="form-control"
                        value={item.adminName}
                        onChange={e => {
                          let temp = this.state.assignAdminSet
                          temp[index].adminName = e.target.value
                          this.setState({ assignAdminSet: temp })
                        }}
                      />
                    </div>
                    <div className="form-group w-100 mr-2">
                      <small>Email Id</small>
                      <input
                        type="email"
                        className="form-control"
                        value={item.adminEmail}
                        onChange={e => {
                          let temp = this.state.assignAdminSet
                          temp[index].adminEmail = e.target.value
                          this.setState({ assignAdminSet: temp })
                        }}
                      />
                    </div>
                    <div className="form-group w-100 mr-2">
                      <small>Contact Number</small>
                      <input
                        type="number"
                        className="form-control"
                        value={item.adminContact}
                        onChange={e => {
                          let temp = this.state.assignAdminSet
                          temp[index].adminContact = e.target.value
                          this.setState({ assignAdminSet: temp })
                        }}
                      />
                    </div>
                    <div
                      onClick={() => this.addAssignAdminForm(index)}
                      className="d-flex justify-content-center align-items-center text-info pt-2 fs-20 mx-3"
                    >
                      <i
                        className="far fa-plus-circle"
                        style={{ cursor: "pointer" }}
                      ></i>
                    </div>
                    <div
                      onClick={() => this.deleteAssignAdminForm(index)}
                      className="d-flex justify-content-center align-items-center text-danger pt-2 fs-20"
                    >
                      <i
                        className="far fa-trash-alt"
                        style={{ cursor: "pointer" }}
                      ></i>
                    </div>
                  </div>
                )
              })}
            <div className="d-flex justify-content-center">
              {this.state.assignAdminSet &&
              this.state.assignAdminSet.length &&
              (this.state.assignAdminSet[0].adminName === "" ||
                this.state.assignAdminSet[0].adminEmail === "" ||
                !validateEmail(this.state.assignAdminSet[0].adminEmail) ||
                this.state.assignAdminSet[0].adminContact === "") &&
              this.state.hasError ? (
                <p className="text-danger">Valid admin details is required.</p>
              ) : null}
            </div>
          </Modal>
        )}
      </ModalTransition>
    )
  }
}

export default SchoolDetailsModal
