/*************************************************
 * RedInk
 * @exports
 * @class SubjectsScreen.js
 * @extends Component
 * @author Sasidharan // on 06/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import Modal, { ModalTransition } from "@atlaskit/modal-dialog"
import { bindActionCreators } from "redux"
import { HashLoader } from "react-spinners"
import { components } from "react-select"
import { DatePicker } from "antd"
import makeAnimated from "react-select/animated"
import { connect } from "react-redux"
import moment from "moment"
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Collapse,
  Button,
  Label,
  Input,
  Table,
} from "reactstrap"

import "antd/dist/antd.css"

import {
  getTermDetails,
  saveTermDetails,
  updateTermDetails,
  deleteTermDetails,
  deleteDetailTermDetails,
} from "../../../../actions/admin/TermDetailsAction"
import { DANGER_TOAST, VALIDATION_MSG } from "../../../../utils/Constants"
import { confirmAlertPopUp } from "../../../../utils/Utility"
import { showNotification } from "../../../../actions/CommonAction"
import { getMaxMarksList } from "../../../../actions/admin/AcademicDetailsAction"
import { getClassGroup } from "../../../../actions/admin/ClassDetailsAction"
import { URL_PATH } from "../../../../config/urlPath"

import { MySelect } from "../../../common/MySelect"

const { RangePicker } = DatePicker

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  )
}

const allOption = {
  label: "Select all",
  value: "*",
}

const MultiValue = props => {
  let labelToBeDisplayed = `${props.data.label}, `
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = "Selected All"
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  )
}

const ValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue()
  let toBeRendered = children
  if (currentValues.some(val => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]]
  }

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  )
}

const animatedComponents = makeAnimated()

// const academicConditon = [
//   {
//     id: "0",
//     value: "Sum of Terms",
//   },
//   {
//     id: "1",
//     value: "Average of Terms",
//   },
//   {
//     id: "2",
//     value: "Best of Terms",
//   },
// ]

class TermDetailsScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      advanceOpen: true,
      isChangeMarkOpen: false,
      indexChangeMark: 0,
      isShowLoading: false,
      isLoading: true,
      addTermOpen: false,
      name: "",
      shortName: "",
      maxMarkList: [],
      allocationDetails: [
        {
          resultCondition: "",
          termFrom: "",
          termTo: "",
          sectionGroups: [],
          assessments: [],
          changeMaxMark: [],
        },
      ],
      termCondition: [],
      isShowUpdateSubjectInput: false,
      termResultCondtionId: null,
    }
  }

  componentDidMount = () => {
    this.props.getTermDetails((success, response) => {
      if (success) {
        // this.setState({ addTermOpen: response.length === 0 })
        this.props.getClassGroup({}, () => {})
        this.props.getMaxMarksList(response => {
          if (response) {
            this.setState({
              maxMarkList: response.assessmentMaxMarks.map(d => ({
                ...d,
                // isDisabled: false,
                value: d.assessmentId,
                label: d.assessmentShortName,
                type: d.assessmentType,
              })),
              isLoading: false,
            })
          }
        })
        if (!this.props.classGroupsList) {
          this.props.getClassGroup({}, () => {})
        }
        if (response.length > 0) {
          this.setState({ addTermOpen: false })
        }
      }
    })
  }

  addMoreAllocations = () => {
    let array = this.state.allocationDetails
    array.push({
      resultCondition: "", termFrom: "",
      termTo: "", sectionGroups: [], assessments: [], changeMaxMark: []
    }) // prettier-ignore
    this.setState({ allocationDetails: array })
  }

  deleteAllocation = i => {
    if (this.state.allocationDetails.length > 1) {
      let array = this.state.allocationDetails
      array.splice(i, 1)
      this.setState({ allocationDetails: array })
    } else {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.SUCCESS,
        "Atleast one term details required..."
      )
    }
  }

  cancelTerm = () => {
    this.setState({
      name: "",
      shortName: "",
      allocationDetails: [
        {
          resultCondition: "",
          mainAssessmentType: "",
          termFrom: "",
          termTo: "",
          sectionGroups: [],
          assessments: [],
          changeMaxMark: [],
        },
      ],
      isShowUpdateSubjectInput: false,
    })
  }

  saveTermDetails = () => {
    this.setState({ isShowLoading: true })
    const { name, shortName, allocationDetails } = this.state
    if (this.state.isShowLoading) return
    if (
      name !== "" &&
      shortName !== "" &&
      allocationDetails.length > 0 &&
      this.state.allocationDetails[0].termFrom !== "" &&
      this.state.allocationDetails[0].termTo !== "" &&
      this.state.allocationDetails[0].sectionGroups.length > 0 &&
      this.state.allocationDetails[0].assessments.length > 0 &&
      this.state.allocationDetails[0].changeMaxMark.length > 0
    ) {
      let object = {
        name,
        shortName,
        allocationDetails: allocationDetails.map(alloc => ({
          ...alloc,
          termFrom: moment(alloc.termFrom).format("YYYY-MM-DD"),
          termTo: moment(alloc.termTo).format("YYYY-MM-DD"),
        })),
      }
      this.props.saveTermDetails(object, success => {
        this.setState({ isShowLoading: false })
        if (success) {
          this.setState({ addTermOpen: false }, this.cancelTerm)
        }
      })
    } else {
      this.setState({ hasError: true, isShowLoading: false })
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.SUCCESS,
        "Please fill out all fields"
      )
    }
  }

  updateTermDetails = () => {
    this.setState({ isShowLoading: true })
    if (this.state.isShowLoading) return
    if (
      this.state.name !== "" &&
      this.state.shortName !== "" &&
      this.state.allocationDetails.length > 0 &&
      this.state.allocationDetails[0].termFrom !== "" &&
      this.state.allocationDetails[0].termTo !== "" &&
      this.state.allocationDetails[0].sectionGroups.length > 0 &&
      this.state.allocationDetails[0].assessments.length > 0 &&
      this.state.allocationDetails[0].changeMaxMark.length > 0
    ) {
      let object = {
        termName: this.state.name,
        termShortName: this.state.shortName,
        termId: this.state.updateSubjectDetailId,
        termFrom: moment(this.state.allocationDetails[0].termFrom).format(
          "YYYY-MM-DD"
        ),
        termTo: moment(this.state.allocationDetails[0].termTo).format(
          "YYYY-MM-DD"
        ),
        changeMaxMark: this.state.allocationDetails[0].changeMaxMark,
        id: this.state.allocationDetails[0].id,
        resultCondition: this.state.allocationDetails[0].resultCondition,
        mainAssessmentType: this.state.allocationDetails[0].mainAssessmentType,
        sectionGroups: this.state.allocationDetails[0].sectionGroups,
        assessments: this.state.allocationDetails[0].assessments,
      }
      this.props.updateTermDetails(object, success => {
        if (success) {
          this.setState(
            {
              addTermOpen: false,
              isShowLoading: false,
            },
            this.cancelTerm
          )
        } else {
          this.setState({ isShowLoading: false })
        }
      })
    } else {
      this.setState({ hasError: true, isShowLoading: false })
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.SUCCESS,
        "Please fill out all fields"
      )
    }
  }

  deleteTermDetailFromDb = item => {
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT,
      yes => {
        if (yes) {
          this.props.deleteDetailTermDetails(
            { termId: item.termId, groupId: item.sectionGroups[0].groupId },
            () => {}
          )
        }
      },
      {
        buttons: [],
        customUI: ({ onClose }) => {
          return (
            <div
              className="custom"
              style={{
                backgroundColor: "#fff",
                borderBottomRightRadius: "12px",
                borderBottomLeftRadius: "12px",
              }}
            >
              <div
                className="bg-brand text-light d-flex"
                style={{
                  padding: "0.5rem 2rem",
                  borderTopRightRadius: "12px",
                  borderTopLeftRadius: "12px",
                }}
              >
                <div className="mx-auto fs-18">Delete</div>
              </div>

              <div
                className="mt-3"
                style={{
                  padding: "0.5rem 1.5rem",
                }}
              >
                <div className="text-muted fs-15">
                  Are you sure you want to delete?
                </div>
                <div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
                  <button
                    className="px-4 py-1 bg-brand text-light w-25 d-flex align-items-center justify-content-center yes-button"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      this.props.deleteDetailTermDetails(
                        {
                          termId: item.termId,
                          groupId: item.sectionGroups[0].groupId,
                        },
                        () => {}
                      )
                      onClose()
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="px-4 py-1 ml-4 bg-brand text-light w-25 d-flex align-items-center justify-content-center"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={onClose}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )
        },
      }
    )
  }

  deleteTermFromDb = item => {
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT,
      yes => {
        if (yes) {
          this.props.deleteTermDetails({ id: item.termId }, () => {})
        }
      }
    )
  }

  editTerm = (item, index) => {
    const otherAssess = []

    this.props.termDetailList.forEach((td, i) => {
      if (i !== index) {
        td.assessments.forEach(ad => {
          otherAssess.push(ad.assessmentId)
        })
      }
    })

    this.setState(
      {
        addTermOpen: true,
        isShowUpdateSubjectInput: true,
        updateSubjectDetailId: item.termId,
        name: item.termName,
        shortName: item.termShortName,
        allocationDetails: [
          {
            id: item.id,
            resultCondition: item.resultCondition,
            mainAssessmentType: item.mainAssessmentType,
            termFrom: item.termFrom !== "" ? moment(item.termFrom) : "",
            termTo: item.termTo !== "" ? moment(item.termTo) : "",
            sectionGroups: item.sectionGroups ? item.sectionGroups : [],
            assessments: item.assessments,
            changeMaxMark: item.changeMaxMark,
          },
        ],
        // maxMarkList: this.state.maxMarkList.map(d => ({
        //   ...d,
        //   isDisabled: otherAssess.includes(d.assessmentId),
        // })),
      },
      () => {
        this.getAssessmentConditionListName(
          item.assessments,
          item.resultCondition,
          item.mainAssessmentType
        )
      }
    )
    setTimeout(() => {
      let el = document.getElementById("termName")
      el.focus()
    }, 500)
  }

  getAssessmentConditionListName = (
    assessments,
    resultCondition,
    mainAssessmentType = "FA"
  ) => {
    const typeOfAssesments = []
    const assementsNames = []
    for (const ass of assessments) {
      if (ass.considerForTermScore) {
        if (!typeOfAssesments.includes(ass.assessmentType)) {
          typeOfAssesments.push(ass.assessmentType)
        }
        assementsNames.push({
          assessmentShortName: ass.assessmentShortName,
          assessmentType: ass.assessmentType,
        })
      }
    }

    const academicConditon = [
      {
        id: "0",
        conditionId: 0,
        mainAssessmentType: null,
        value:
          "Sum of " +
          assementsNames.map(an => an.assessmentShortName).join(" + "),
      },
    ]
    const defaultTermCondtion = [
      {
        formula: "Average of",
        condtionId: 1,
      },
      {
        formula: "Best of",
        condtionId: 2,
      },
    ]
    for (let index = 0; index < defaultTermCondtion.length; index++) {
      const defaultTermCondtionInfo = defaultTermCondtion[index]
      typeOfAssesments.forEach(assesmentType => {
        const primaryAssemntNames = []
        const secondaryAssementNames = []
        assementsNames.forEach(assInfo => {
          if (assInfo.assessmentType === assesmentType) {
            primaryAssemntNames.push(assInfo.assessmentShortName)
          } else {
            secondaryAssementNames.push(assInfo.assessmentShortName)
          }
        })
        academicConditon.push({
          id: String(academicConditon.length),
          conditionId: defaultTermCondtionInfo.condtionId,
          mainAssessmentType: assesmentType,
          value: `${
            defaultTermCondtionInfo.formula
          } (${primaryAssemntNames.join(", ")}) ${
            secondaryAssementNames.length
              ? `+ ${secondaryAssementNames.join(" + ")}`
              : ""
          }`,
        })
      })
    }
    if (resultCondition === 0) {
      this.setState({ termResultCondtionId: "0" })
    } else if (resultCondition === 1 || resultCondition === 2) {
      const givenCondition = academicConditon.find(
        ac =>
          ac.conditionId === resultCondition &&
          ac.mainAssessmentType === mainAssessmentType
      )
      this.setState({
        termResultCondtionId: givenCondition ? givenCondition.id : -1,
      })
    }
    this.setState({ termCondition: academicConditon })
  }

  createTerm = () => {
    this.cancelTerm()
    this.setState({ addTermOpen: true, hasError: false })
    setTimeout(() => {
      let el = document.getElementById("termName")
      el.focus()
    }, 500)
  }

  render() {
    return (
      <>
        <ModalTransition>
          {this.state.isChangeMarkOpen && (
            <Modal
              heading="Set Max. Marks"
              actions={[
                {
                  text: "Save",
                  onClick: () => this.setState({ isChangeMarkOpen: false }),
                },
                {
                  text: "cancel",
                  onClick: () => this.setState({ isChangeMarkOpen: false }),
                },
              ]}
            >
              <div className="mt-3 d-flex">
                <div className="col d-flex flex-column align-items-center">
                  <p className="mb-2 text-dark text-uppercase font-weight-bold">
                    Assessment name
                  </p>
                </div>
                <div className="col d-flex flex-column align-items-center">
                  <p className="mb-2 text-dark text-uppercase font-weight-bold">
                    Max. Marks
                  </p>
                </div>
              </div>

              {this.state.allocationDetails &&
                this.state.allocationDetails.length > 0 &&
                this.state.allocationDetails[this.state.indexChangeMark]
                  .changeMaxMark &&
                this.state.allocationDetails[
                  this.state.indexChangeMark
                ].changeMaxMark.map((item, index) => {
                  const maxMarkItem = this.state.allocationDetails[
                    this.state.indexChangeMark
                  ].changeMaxMark.find(
                    ite => ite.assessmentId === item.assessmentId
                  )
                  return (
                    <FormGroup className="mb-3 d-flex" key={item.assessmentId}>
                      <div className="col d-flex justify-content-center align-items-center">
                        <Label
                          className="m-0"
                          htmlFor={`maxMark-${item.assessmentShortName}`}
                        >
                          {item.assessmentShortName}
                        </Label>
                      </div>
                      <div className="col d-flex justify-content-center align-items-center">
                        <Input
                          type="number"
                          name={`maxMark-${item.assessmentShortName}`}
                          id={`maxMark-${item.assessmentShortName}`}
                          value={maxMarkItem ? maxMarkItem.maxMark : 0}
                          onChange={e => {
                            const value = e.target.value
                            if (maxMarkItem) {
                              let allocDet = this.state.allocationDetails
                              allocDet[
                                this.state.indexChangeMark
                              ].changeMaxMark = allocDet[
                                this.state.indexChangeMark
                              ].changeMaxMark.map(ite =>
                                ite.assessmentId === maxMarkItem.assessmentId
                                  ? { ...maxMarkItem, maxMark: value }
                                  : ite
                              )
                              this.setState({
                                allocationDetails: allocDet,
                              })
                            } else {
                              const allocDet = this.state.allocationDetails
                              allocDet[
                                this.state.indexChangeMark
                              ].changeMaxMark = [
                                { ...item, maxMark: value },
                                ...allocDet[this.state.indexChangeMark]
                                  .changeMaxMark,
                              ]
                              this.setState({ allocationDetails: allocDet })
                            }
                          }}
                        />
                      </div>
                    </FormGroup>
                  )
                })}
            </Modal>
          )}
        </ModalTransition>

        <div className="mb-2 row">
          <div className="col d-flex align-items-center justify-content-end">
            <Button
              color="light"
              size="sm"
              className="rounded-pill"
              onClick={this.createTerm}
            >
              <i className="fas fa-plus text-dark"></i> Create Term
            </Button>
          </div>
        </div>

        <Collapse isOpen={this.state.addTermOpen}>
          <div className="d-flex">
            <div className="col-md">
              <FormGroup>
                <Label htmlFor="termName">Term Name</Label>
                <Input
                  type="text"
                  name="termName"
                  id="termName"
                  value={this.state.name}
                  onChange={e => this.setState({ name: e.target.value })}
                  onKeyDown={e => {
                    if (e.key === "Enter" && e.target.value) {
                      let el = document.getElementById("shortName")
                      el.focus()
                    }
                  }}
                />
              </FormGroup>
              <div className="d-flex justify-content-center">
                {this.state.name === "" && this.state.hasError && (
                  <p className="text-danger">Term Name is required.</p>
                )}
              </div>
              <FormGroup>
                <Label htmlFor="shortname">Short Name</Label>
                <Input
                  type="text"
                  name="shortName"
                  id="shortName"
                  value={this.state.shortName}
                  onChange={e => this.setState({ shortName: e.target.value })}
                />
              </FormGroup>
              <div className="d-flex justify-content-center">
                {this.state.shortName === "" && this.state.hasError && (
                  <p className="text-danger">Term Short Name is required.</p>
                )}
              </div>
            </div>
            <div className="col-md"></div>
          </div>
          {this.props.classGroupsList &&
          this.props.classGroupsList.length > 0 ? (
            this.state.advanceOpen ? (
              <>
                <div className="mt-3 d-flex justify-content-start">
                  <div className="col-sm">
                    <h5 className="text-dark font-weight-bold">Assign class</h5>
                  </div>
                  <div className="col-sm">
                    <h5 className="text-dark font-weight-bold">Time period</h5>
                  </div>
                  <div className="col-sm">
                    <h5 className="text-dark font-weight-bold">Assessments</h5>
                  </div>
                  <div className="col-md">
                    <h5 className="text-dark font-weight-bold">
                      Term Result condition
                    </h5>
                  </div>
                  <div className="col-sm"></div>
                </div>

                {this.state.allocationDetails &&
                  this.state.allocationDetails.length > 0 &&
                  this.state.allocationDetails.map((alloc, index) => (
                    <div
                      className="mb-3 d-flex justify-content-start"
                      key={index}
                    >
                      <div className="flex-1 col-sm d-flex flex-column justify-content-center">
                        <MySelect
                          placeholder="Select Class"
                          isClearable
                          value={alloc.sectionGroups.map(it => ({
                            value: it.groupName || it.value,
                            label: it.groupName || it.label,
                            id: it.groupId,
                          }))}
                          options={this.props.classGroupsList.map(it => ({
                            value: it.groupName || it.value,
                            label: it.groupName || it.label,
                            id: it.groupId,
                          }))}
                          className="basic-multi-select"
                          isMulti
                          allowSelectAll
                          hideSelectedOptions={false}
                          closeMenuOnSelect={false}
                          components={{
                            Option,
                            MultiValue,
                            ValueContainer,
                            animatedComponents,
                          }}
                          classNamePrefix="select"
                          onChange={selectedOption => {
                            if (selectedOption !== null) {
                              let array = []
                              for (let i = 0; i < selectedOption.length; i++) {
                                if (selectedOption[i].label !== "Select all") {
                                  array.push({
                                    groupId: selectedOption[i].id,
                                    groupName: selectedOption[i].label,
                                  })
                                }
                              }
                              this.setState(state => {
                                state.allocationDetails[
                                  index
                                ].sectionGroups = array
                                return state
                              })
                            } else {
                              this.setState(state => {
                                state.allocationDetails[
                                  index
                                ].sectionGroups = []
                                return state
                              })
                            }
                          }}
                        />
                      </div>
                      <div className="col-sm d-flex flex-column justify-content-center">
                        <RangePicker
                          onChange={([termFrom, termTo]) => {
                            this.setState(state => {
                              state.allocationDetails[index].termFrom = termFrom
                              state.allocationDetails[index].termTo = termTo
                              return state
                            })
                          }}
                          value={[alloc.termFrom, alloc.termTo]}
                          format="DD-MM-YYYY"
                        />
                      </div>
                      <div className="col-sm d-flex flex-column justify-content-center">
                        <MySelect
                          isMulti
                          value={alloc.assessments.map(alVal => ({
                            ...alVal,
                            value: alVal.assessmentId,
                            label: alVal.assessmentShortName,
                            type: alVal.assessmentType,
                          }))}
                          hideSelectedOptions={false}
                          closeMenuOnSelect={false}
                          components={{
                            Option,
                            MultiValue,
                            ValueContainer,
                            animatedComponents,
                          }}
                          onChange={newValue => {
                            this.setState(
                              state => {
                                state.allocationDetails[
                                  index
                                ].assessments = newValue ? [...newValue] : []
                                state.allocationDetails[
                                  index
                                ].changeMaxMark = newValue
                                  ? newValue.map(({ value, label }) => ({
                                      assessmentId: value,
                                      assessmentShortName: label,
                                      maxMark: 0,
                                    }))
                                  : []
                                return state
                              },
                              () => {
                                this.getAssessmentConditionListName(
                                  this.state.allocationDetails[index]
                                    .assessments
                                )
                              }
                            )
                          }}
                          options={this.state.maxMarkList}
                        ></MySelect>
                      </div>
                      <div className="col-md d-flex flex-column justify-content-center">
                        <select
                          className="form-control"
                          value={this.state.termResultCondtionId}
                          onChange={e => {
                            const value = e.target.value
                            const selectedData = this.state.termCondition.find(
                              e => e.id === value
                            )
                            if (selectedData) {
                              let array = this.state.allocationDetails
                              array[index].resultCondition =
                                selectedData.conditionId
                              array[index].mainAssessmentType =
                                selectedData.mainAssessmentType
                              this.setState({
                                allocationDetails: array,
                                termResultCondtionId: value,
                              })
                            }
                          }}
                        >
                          <option>Select Term conditions</option>
                          {this.state.termCondition &&
                            this.state.termCondition.map(item => (
                              <option key={item.id} value={item.id}>
                                {item.value}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-sm d-flex align-items-center">
                        <Button
                          color="light"
                          className="mr-3"
                          size="sm"
                          onClick={() =>
                            this.setState({
                              isChangeMarkOpen: true,
                              indexChangeMark: index,
                            })
                          }
                          style={{ width: "8rem", height: "2.5rem" }}
                        >
                          Set Max. Marks
                        </Button>
                        {!this.state.isShowUpdateSubjectInput ? (
                          <div className="d-flex">
                            <Button
                              color="light"
                              className="mr-3"
                              size="lg"
                              onClick={this.addMoreAllocations}
                              style={{ height: "2.5rem" }}
                            >
                              <i
                                className="fas fa-plus text-dark"
                                style={{ verticalAlign: "text-top" }}
                              ></i>
                            </Button>
                            <Button
                              color="light"
                              className="mr-3"
                              size="lg"
                              onClick={() => this.deleteAllocation(index)}
                              style={{ height: "2.5rem" }}
                            >
                              <i
                                className="far fa-trash-alt text-danger"
                                style={{ verticalAlign: "text-top" }}
                              ></i>
                            </Button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ))}
                <div className="d-flex justify-content-center">
                  {this.state.allocationDetails[0].termFrom === "" &&
                  this.state.allocationDetails[0].termTo === "" &&
                  this.state.allocationDetails[0].sectionGroups.length === 0 &&
                  this.state.allocationDetails[0].assessments.length === 0 &&
                  this.state.allocationDetails[0].changeMaxMark.length === 0 &&
                  this.state.hasError ? (
                    <p className="text-danger">Term Details is required.</p>
                  ) : null}
                </div>
              </>
            ) : (
              <></>
            )
          ) : (
            <div className="mt-3 mb-2 ml-2 d-flex">
              <div className="alert alert-info fade show">
                Please create classes and class group in records tab in left
                menu or{" "}
                <div
                  onClick={() => {
                    this.props.history.push({
                      pathname: URL_PATH.ADMIN_SCHOOL_PROFILE_CLASS_DETAILS,
                      state: {},
                    })
                  }}
                >
                  click here
                </div>
              </div>
            </div>
          )}
          <div className="my-3 d-flex align-items-center justify-content-end">
            <Button
              style={{
                backgroundColor: "#DBDBDB",
                color: "#06B191",
                border: "none",
                width: "5rem",
              }}
              className="mr-3 rounded-pill"
              onClick={() => {
                this.setState({
                  addTermOpen: false,
                  isShowLoading: false,
                })
              }}
            >
              cancel
            </Button>

            <Button
              style={{
                backgroundColor: "#0eb292",
                border: "none",
                width: "5rem",
              }}
              className="rounded-pill"
              onClick={() => {
                if (this.state.isShowUpdateSubjectInput) {
                  this.updateTermDetails()
                } else {
                  this.saveTermDetails()
                }
              }}
            >
              {this.state.isShowLoading ? (
                <HashLoader
                  css={{ paddingLeft: "40px" }}
                  size={16}
                  color="#fff"
                  loading={this.state.isShowLoading}
                  sizeUnit="px"
                />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </Collapse>

        <Table>
          <thead
            style={{
              backgroundColor: "#022636",
              textTransform: "uppercase",
              color: "#fff",
            }}
          >
            <tr>
              <th className="text-center">Term Name</th>
              <th>Result Condition</th>
              <th className="text-center">Assigned classes</th>
              <th></th>
            </tr>
          </thead>

          {this.state.isLoading ? (
            <HashLoader
              css={{
                position: "fixed",
                width: "100%",
                height: "100%",
                left: "0px",
                top: "0px",
              }}
              size={30}
              color="#084353"
              loading
              sizeUnit="px"
            />
          ) : (
            <tbody>
              {this.props.termDetailList &&
                this.props.termDetailList.length > 0 &&
                this.props.termDetailList.map((item, index) => (
                  <tr key={item.id}>
                    <td className="text-center">
                      <h5 className="m-0 text-dark">{item.termName}</h5>
                    </td>
                    {/* <td className="text-center">
                      <h5 className="m-0 text-dark">
                        {item.termFrom} - {item.termTo}
                      </h5>
                    </td> */}
                    <td>
                      {item.changeMaxMark && item.changeMaxMark.length > 0 ? (
                        <div>
                          <div className="d-flex row">
                            {item.changeMaxMark.map((item, index) => (
                              <div
                                key={index}
                                className="ml-4 text-white rounded-lg d-flex align-items-center"
                                style={{ backgroundColor: "#022636" }}
                              >
                                <div className="px-2 py-1 rounded-lg">
                                  {item.assessmentShortName}
                                </div>
                                <div
                                  className="px-2 py-1 bg-white rounded-lg"
                                  style={{ color: "#022636", margin: 1 }}
                                >
                                  {item.maxMark}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div>-</div>
                      )}
                    </td>
                    <td className="text-center align-items-center">
                      {item.sectionGroups && item.sectionGroups.length > 0 ? (
                        <div className="px-3 py-2 badge badge-info">
                          {item.sectionGroups[0].label}
                        </div>
                      ) : (
                        <div className="text-center">-</div>
                      )}
                    </td>
                    <td>
                      <UncontrolledDropdown direction="left">
                        <DropdownToggle tag="button" className="nav-link">
                          <i className="text-black cursor-pointer fas fa-ellipsis-v"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => this.editTerm(item, index)}
                          >
                            Edit
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              this.deleteTermDetailFromDb(item)
                            }}
                          >
                            Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </Table>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    termDetailList: state.adminTermDetailsState.termDetail,
    classGroupsList: state.classDetailsState.classGroupsList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getMaxMarksList,
      getTermDetails,
      saveTermDetails,
      updateTermDetails,
      deleteTermDetails,
      getClassGroup,
      deleteDetailTermDetails,
      showNotification,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(TermDetailsScreen)
