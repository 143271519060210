/*************************************************
 * RedInk
 * @exports
 * @class AttendanceReportReducer.js
 * @extends Component
 * @author vijayakumar // on 18/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from "../../actions/types"

const INTIAL_STATE = {
  adminSchoolDetail: {},
}

export const adminDashboardReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.ADMIN_DASBOARD_SCHOOL_DETAIL:
      return {
        ...state,
        adminSchoolDetail: action.payload,
      }

    default:
      return state
  }
}
