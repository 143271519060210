/*************************************************
 * RedInk
 * @exports
 * @class Profile.js
 * @extends Component
 * @author Sasidharan // on 01/02/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import "react-sweet-progress/lib/style.css"
import AppWrapper from "../Wrapper1"
import {
  updateTeacherProfile,
  deleteTeacherQualification,
  deleteTeacherSkill,
} from "../../../actions/teacher/DashboardAction"
import { HashLoader } from "react-spinners"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import malePic from "../../../assets/images/men.png"
import femalePic from "../../../assets/images/female.png"
import { myLog, validateEmail } from "../../../utils/Utility"
import { getAdminTeacherProfile } from "../../../actions/admin/ProfileAction"
import { showNotification } from "../../../actions/CommonAction"
import { DANGER_TOAST, VALIDATION_MSG } from "../../../utils/Constants"

const checkOnlyLetters = name => {
  var regex = new RegExp(/^[A-Za-z. ]+$/)
  return name.match(regex)
}

// const checkOnlyNumber = num => {
//   var regex = new RegExp(/^[0-9]{10}$/)
//   return num.match(regex)
// }

class AdminTeacherProfile extends Component {
  constructor(props) {
    super(props)
    document.title = "Profile | Redink"
    this.state = {
      isShowLoading: false,
      id: "",
      firstName: "",
      lastName: "",
      gender: "",
      contactNumber: "",
      emailId: "",
      address: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
      classTeacherFor: "",
      assignedClassAndSubject: [],
      currClass: {},
      qualificationDisplay: [{ qualification: "", id: "" }],
      skillDisplay: [],
      isShowAddqualification: true,
      updatequalificationId: "",
      isShowAddSkill: false,
      skillName: "",
      teacherImage: "",
      isProfileEditing: true,
      isEdQualifyEditing: true,
      isEdQualifyLoading: false,
      yearError: false,
    }
  }

  componentDidMount = () => {
    this.getProfile()
  }

  getProfile = () => {
    this.props.getAdminTeacherProfile(
      { teacherId: this.props.location.state.teacherId },
      (success, response) => {
        if (success) {
          myLog("Teacher Data in Profile", response.teacherDetails)
          this.setState({
            id: response.teacherDetails.id,
            assignedClasses: response.assigned,
            // teacherImage: response.teacherImage,
            firstName: response.teacherDetails.firstName,
            lastName: response.teacherDetails.lastName,
            gender: response.teacherDetails.gender,
            contactNumber: response.teacherDetails.contactNumber,
            emailId: response.teacherDetails.emailId,
            address: response.teacherDetails.address,
            city: response.teacherDetails.city,
            state: response.teacherDetails.state,
            country: response.teacherDetails.country,
            zipCode: response.teacherDetails.zipCode,
            classTeacherFor: response.teacherDetails.classTeacherFor.value,
            assignedClassAndSubject:
              response.teacherDetails.assignedClassAndSubject,
            dateOfJoining: response.teacherDetails.dateOfJoining,
            experience: response.teacherDetails.experience,
            qualificationDisplay:
              response.teacherDetails.qualifications.length > 0
                ? response.teacherDetails.qualifications
                : [{ qualification: "", id: "" }],
            skillDisplay:
              response.teacherDetails.skills.length > 0
                ? response.teacherDetails.skills
                : [],
          })
        }
      }
    )
  }

  addqualification = () => {
    const tempARR = this.state.qualificationDisplay
    tempARR.push({ qualification: "", id: "" })
    this.setState({ qualificationDisplay: tempARR }, () => {
      myLog(this.state.qualificationDisplay)
    })
  }

  deletequalification = (item, index) => {
    if (this.state.qualificationDisplay.length > 1) {
      let arrqualification = this.state.qualificationDisplay
      arrqualification.splice(index, 1)
      this.setState({ qualificationDisplay: arrqualification })
      if (item.id !== "") {
        let body = {
          id: item.id,
        }
        this.props.deleteTeacherQualification(body, () => {
          this.getProfile()
        })
      }
    }
  }

  deleteSkill = (item, index) => {
    let arrqualification = this.state.skillDisplay
    arrqualification.splice(index, 1)
    this.setState({ skillDisplay: arrqualification })
    if (item.id !== "") {
      let body = {
        id: item.id,
      }
      this.props.deleteTeacherSkill(body, () => {
        this.getProfile()
      })
    }
  }

  profileErrorAbstract = error => {
    this.props.showNotification(DANGER_TOAST, VALIDATION_MSG.OOPS, error)
    this.setState({ isShowLoading: false, isProfileEditing: false })
  }

  // Update for teacher profile
  onUpdate = () => {
    // if (this.state.isProfileEditing == false) {
    //   this.setState({
    //     isShowLoading: true,
    //     isProfileEditing: true,
    //     isEdQualifyEditing: this.state.isEdQualifyEditing,
    //   })
    // }
    if (this.state.isEdQualifyEditing === false) {
      this.setState({
        // isShowLoading: true,
        isEdQualifyEditing: true,
        // isProfileEditing: this.state.isProfileEditing,
      })
    } else {
      this.setState({
        isShowLoading: true,
        isProfileEditing: true,
        // isEdQualifyEditing: this.state.isEdQualifyEditing,
      })
    }

    let qualifications = []
    for (let i = 0; i < this.state.qualificationDisplay.length; i++) {
      if (this.state.qualificationDisplay[i].id === "")
        qualifications.push(this.state.qualificationDisplay[i].qualification)
    }
    let skills = []
    if (this.state.skillName !== "") skills.push(this.state.skillName)

    if (this.state.firstName !== "" && this.state.firstName !== null) {
      if (!checkOnlyLetters(this.state.firstName)) {
        this.profileErrorAbstract("Invalid First Name")
        return
      }
    }
    // else {
    //   this.profileErrorAbstract("First Name cannot be empty")
    //   return
    // }

    if (this.state.lastName !== "" && this.state.lastName !== null) {
      if (!checkOnlyLetters(this.state.lastName)) {
        this.profileErrorAbstract("Invalid Last Name")
        return
      }
    }
    // else {
    //   this.profileErrorAbstract("Last Name cannot be empty")
    //   return
    // }

    if (this.state.contactNumber !== "" && this.state.contactNumber !== null) {
      if (
        String(this.state.contactNumber).length < 10 ||
        String(this.state.contactNumber).length > 15
      ) {
        this.profileErrorAbstract("Invalid Contact No.")
        return
      }
    }
    // else {
    //   this.profileErrorAbstract("Contact No. cannot be empty")
    //   return
    // }

    if (this.state.emailId !== "" && this.state.emailId !== null) {
      if (!validateEmail(this.state.emailId)) {
        this.profileErrorAbstract("Invalid Email ID")
        return
      }
    }
    // else {
    //   this.profileErrorAbstract("Email ID cannot be empty")
    //   return
    // }

    // if (this.state.address === "" && this.state.address === null) {
    //   this.profileErrorAbstract("Address cannot be empty")
    //   return
    // }

    if (this.state.city !== "" && this.state.city !== null) {
      if (!checkOnlyLetters(this.state.city)) {
        this.profileErrorAbstract("Invalid City Name")
        return
      }
    }
    // else {
    //   this.profileErrorAbstract("City cannot be empty")
    //   return
    // }

    if (this.state.state !== "" && this.state.state !== null) {
      if (!checkOnlyLetters(this.state.state)) {
        this.profileErrorAbstract("Invalid State Name")
        return
      }
    }
    //  else {
    //   this.profileErrorAbstract("State cannot be empty")
    //   return
    // }

    if (this.state.country !== "" && this.state.country !== null) {
      if (!checkOnlyLetters(this.state.country)) {
        this.profileErrorAbstract("Invalid Country Name")
        return
      }
    }
    // else {
    //   this.profileErrorAbstract("Country cannot be empty")
    //   return
    // }

    if (this.state.zipCode) {
      if (String(this.state.zipCode).length < 6) {
        this.profileErrorAbstract("Invalid Zip Code")
        return
      }
    }
    // else{
    //   this.profileErrorAbstract("Zip code cannot be empty")
    //     return
    // }

    let object = {
      id: this.state.id,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      teacherGender: this.state.gender,
      teacherContactNumber: this.state.contactNumber,
      emailId: this.state.emailId,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      zipCode: this.state.zipCode,
      qualifications: qualifications,
      dateOfJoining: this.state.dateOfJoining,
      experience: this.state.experience,
      skills: skills,
      teacherImage: this.state.teacherImage,
      // isProfileEditing: this.state.isProfileEditing,
    }
    this.props.updateTeacherProfile(object, (success, response) => {
      if (success) {
        this.setState({
          isShowLoading: false,
          skillName: "",
          isEdQualifyLoading: false,
        })
        this.getProfile()
      } else {
        this.setState({ isShowLoading: false, isEdQualifyLoading: false })
      }
    })
    this.setState({ yearError: false })
  }
  // Handles Change of field items in form
  handleChange = () => event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onClickCreateNewAndModalIcon = () => {
    this.setState({
      isCreateResponsibilitiesModalOpen: false,
      isCreateExperienceModalOpen: false,
      isCompleteProfile: false,
      isCreateEducationModalOpen: false,
    })
  }

  render() {
    return (
      <AppWrapper
        showAdminProfileSidemenu
        showAdminBackToDashboard
        screenName="Teacher Profile"
      >
        {/* <div className="px-4 py-3">
           <CreateExperienceModal
             isModalOpen={this.state.isCreateExperienceModalOpen}
             closeModal={() => this.onClickCreateNewAndModalIcon()}
             onSubmitMessage={() => this.onSubmitMessage()}
           />
           <CreateEducationModal
             isModalOpen={this.state.isCreateEducationModalOpen}
             closeModal={() => this.onClickCreateNewAndModalIcon()}
             onSubmitMessage={() => this.onSubmitMessage()}
           />
         </div> */}
        <div className="row">
          <div className="col-sm-3">
            <div className="card p-3 br-10  text-white shadow profile1">
              <img
                className="img1"
                src={this.state.gender === "M" ? malePic : femalePic}
                alt="pic"
              />
              <br />
              &emsp;
              <h2>{this.state.firstName}</h2>
              <h3 style={{ fontSize: "1vw" }}>
                CLASS TEACHER &ensp;
                {this.state.classTeacherFor
                  ? this.state.classTeacherFor
                  : "Teacher"}
              </h3>
            </div>
            <div className="card p-3 br-10 mt-2  text-white shadow profile1">
              <h5 className="myskills mt-3">
                <b>My Classes</b>
              </h5>
              <h4 className="count-cards-one pt-1 text-white numberbox">
                {this.state.assignedClassAndSubject.length}
              </h4>
              {this.state.assignedClassAndSubject.map(currClass => (
                <div className="btn-group mt-4" role="group">
                  <button type="button" className="btn button4 pt-2 text-white">
                    {currClass.assignedClass.value}
                  </button>
                  <button
                    type="button"
                    className="btn button4 pt-2 text-white btn-sm profile-subject"
                  >
                    {currClass.assignedSubject.value}
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div className="col-sm-9">
            <div className="card bg-white p-3 shadow">
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="myskills text-dark">
                    <b>Personal Details</b>
                  </h5>
                </div>

                <div>
                  <button
                    type="button"
                    className="btn btn-sm text-light rounded-pill"
                    style={{
                      backgroundColor: this.state.isProfileEditing
                        ? "#094353"
                        : "#0eb292",
                      border: "none",
                      width: "5rem",
                    }}
                    onClick={() =>
                      this.state.isProfileEditing
                        ? this.setState({ isProfileEditing: false })
                        : this.onUpdate()
                    }
                  >
                    {this.state.isShowLoading ? (
                      <HashLoader
                        sizeUnit={"px"}
                        size={18}
                        color={"white"}
                        css={{ paddinRight: "55px" }}
                        loading={this.state.isShowLoading}
                      />
                    ) : this.state.isProfileEditing ? (
                      "Edit"
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
              <div className="row personaldetails">
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label htmlFor="firstname">FIRST NAME :</label>
                  <input
                    disabled={this.state.isProfileEditing}
                    type="text"
                    className="form-control"
                    id="firstname"
                    name="firstName"
                    placeholder="First Name"
                    value={this.state.firstName}
                    onChange={this.handleChange()}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label htmlFor="lastname">LAST NAME :</label>
                  <input
                    disabled={this.state.isProfileEditing}
                    type="text"
                    className="form-control"
                    id="lastname"
                    name="lastName"
                    placeholder="Last Name"
                    value={this.state.lastName}
                    onChange={this.handleChange()}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label htmlFor="contact">CONTACT NUMBER :</label>
                  <input
                    disabled={this.state.isProfileEditing}
                    type="text"
                    className="form-control"
                    id="contact"
                    name="contactNumber"
                    placeholder="Contact Number"
                    value={this.state.contactNumber}
                    onChange={this.handleChange()}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label htmlFor="email">EMAIL ADDRESS :</label>
                  <input
                    disabled={this.state.isProfileEditing}
                    type="email"
                    className="form-control"
                    id="email"
                    name="emailId"
                    placeholder="Email"
                    value={this.state.emailId}
                    onChange={this.handleChange()}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label htmlFor="address">ADDRESS:</label>
                  <input
                    disabled={this.state.isProfileEditing}
                    type="text"
                    className="form-control"
                    id="address"
                    name="address"
                    placeholder="Address"
                    value={this.state.address}
                    onChange={this.handleChange()}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label htmlFor="city">CITY:</label>
                  <input
                    disabled={this.state.isProfileEditing}
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    placeholder="City"
                    value={this.state.city}
                    onChange={this.handleChange()}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <label htmlFor="state">STATE:</label>
                  <input
                    disabled={this.state.isProfileEditing}
                    type="text"
                    className="form-control"
                    id="state"
                    name="state"
                    placeholder="State"
                    value={this.state.state}
                    onChange={this.handleChange()}
                  />
                </div>
                <div className="form-group col-sm-3 col-md-3 col-xl-3 col-lg-3">
                  <label htmlFor="state">COUNTRY:</label>
                  <input
                    disabled={this.state.isProfileEditing}
                    type="text"
                    className="form-control"
                    id="country"
                    name="country"
                    placeholder="Country"
                    value={this.state.country}
                    onChange={this.handleChange()}
                  />
                </div>
                <div className="form-group col-sm-3 col-md-3 col-xl-3 col-lg-3">
                  <label htmlFor="state">ZIP CODE:</label>
                  <input
                    disabled={this.state.isProfileEditing}
                    type="text"
                    className="form-control"
                    id="zipcode"
                    name="zipCode"
                    maxLength="12"
                    placeholder="Zip Code"
                    value={this.state.zipCode}
                    onChange={e => {
                      e.target.value = e.target.value.replace(/[^0-9\- ]/gi, "")
                      this.setState({ zipCode: e.target.value })
                    }}
                  />
                </div>
              </div>
            </div>
            {this.state.isShowAddqualification ? (
              <div className="card bg-white shadow br-7 mt-4 p-3">
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between">
                    <div>
                      <label htmlFor="Eduql">
                        <h5 className=" text-dark">
                          <b>Educational Qualification</b>
                        </h5>
                      </label>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-sm text-light rounded-pill"
                        style={{
                          backgroundColor: this.state.isEdQualifyEditing
                            ? "#094353"
                            : "#0eb292",
                          border: "none",
                          width: "5rem",
                        }}
                        onClick={() =>
                          this.state.isEdQualifyEditing
                            ? this.setState({ isEdQualifyEditing: false })
                            : this.onUpdate()
                        }
                      >
                        {this.state.isEdQualifyLoading ? (
                          <HashLoader
                            sizeUnit={"px"}
                            size={18}
                            color={"white"}
                            css={{ paddinRight: "55px" }}
                            loading={this.state.isEdQualifyLoading}
                          />
                        ) : this.state.isEdQualifyEditing ? (
                          "Edit"
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                {this.state.qualificationDisplay &&
                  this.state.qualificationDisplay.map((item, index) => {
                    return (
                      <div className="row">
                        <div className="form-group  col-sm-5">
                          <input
                            disabled={this.state.isEdQualifyEditing}
                            type="text"
                            className="form-control"
                            id="Eduql"
                            onChange={e => {
                              let state = this.state.qualificationDisplay
                              state[index].qualification = e.target.value
                              this.setState({
                                qualificationDisplay: state,
                              })
                              // myLog(
                              //   "qualificationDisplay",
                              //   this.state.qualificationDisplay
                              // )
                            }}
                            value={item.qualification}
                          />
                        </div>
                        <i
                          className="fas fa-plus text-success"
                          style={
                            this.state.isEdQualifyEditing
                              ? { display: "none", cursor: "pointer" }
                              : { cursor: "pointer" }
                          }
                          onClick={() => this.addqualification()}
                        ></i>
                        <i
                          className="fas fa-trash-alt text-danger px-2 mx-2"
                          style={
                            this.state.isEdQualifyEditing
                              ? { display: "none", cursor: "pointer" }
                              : { cursor: "pointer" }
                          }
                          onClick={() => this.deletequalification(item, index)}
                        ></i>
                      </div>
                    )
                  })}
                {/* <div className="d-flex jjustify-content-sm-start">
                 <h5><b>Experience in Years</b></h5>
                 <h5><b>Date of joining</b></h5>
               </div> */}
                <div className="d-flex justify-content-sm-start row">
                  <div className="form-group  haha col-sm-3">
                    <label htmlFor="experience">
                      <h5 className=" text-dark">
                        <b>Experience in Years</b>
                      </h5>
                    </label>
                    <input
                      disabled={this.state.isEdQualifyEditing}
                      type="text"
                      className="form-control"
                      id="experience"
                      value={this.state.experience}
                      onChange={e => {
                        this.setState({ experience: e.target.value })
                        if (e.target.value > 100) {
                          this.setState({ yearError: true })
                        }
                      }}
                    />
                    {this.state.yearError ? (
                      <span style={{ color: "red" }}>
                        Years of Experience invalid{" "}
                      </span>
                    ) : null}
                  </div>
                  <div className="form-group col-sm-4">
                    <label htmlFor="dateOfJoining">
                      <h5 className=" text-dark">
                        <b>Date of joining</b>
                      </h5>
                    </label>
                    <input
                      disabled={this.state.isEdQualifyEditing}
                      type="date"
                      className="form-control"
                      id="dateOfJoining"
                      value={this.state.dateOfJoining}
                      onChange={e => {
                        this.setState({ dateOfJoining: e.target.value })
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <div className="card bg-white p-3 shadow mb-5 br-7 mt-3 p-3">
              <div className="d-flex justify-content-between">
                <h5 className=" text-dark">
                  <b>SKILLS</b>
                </h5>
                <button
                  type="button"
                  className="btn btn-sm text-light br-10"
                  style={{
                    backgroundColor: !this.state.isShowAddSkill
                      ? "#094353"
                      : "#0eb292",
                    border: "none",
                    width: "5rem",
                  }}
                  onClick={() => {
                    this.setState(
                      { isShowAddSkill: !this.state.isShowAddSkill },
                      () => {
                        if (!this.state.isShowAddSkill) {
                          this.onUpdate()
                        }
                      }
                    )
                  }}
                >
                  {this.state.isShowAddSkill ? "Save Skill" : "Add Skill"}
                </button>
              </div>
              {this.state.isShowAddSkill ? (
                <input
                  type="text"
                  className="form-control col-sm-4 mt-2 mb-2"
                  id="Eduql"
                  placeholder="Skill Name"
                  onChange={e => {
                    this.setState({
                      skillName: e.target.value,
                    })
                  }}
                  value={this.state.skillName}
                />
              ) : null}
              <div className="row px-3 mt-2 d-flex">
                {this.state.skillDisplay.length > 0 ? (
                  this.state.skillDisplay.map((item, index) => {
                    return (
                      // <div className="col-sm-3 skillbgcolor mt-3 rounded-pill mr-3">
                      //   <div className="row  justify-content-between px-4">
                      //     <h6 className="text-center mt-2">{item.skill}</h6>
                      //     <i
                      //       className="fas fa-trash-alt text-danger ml-2 mt-2"
                      //       style={{ cursor: "pointer" }}
                      //       onClick={() => this.deleteSkill(item, index)}
                      //     ></i>
                      //   </div>
                      // </div>
                      <div style={{ width: "14rem" }}>
                        <div
                          className="d-flex align-items-center skillbgcolor rounded-pill"
                          style={{ height: "4rem" }}
                        >
                          <div className="ml-3">
                            <i
                              className="fas fa-trash-alt text-danger cursor-pointer"
                              onClick={() => this.deleteSkill(item, index)}
                            ></i>
                          </div>

                          <div>
                            <p
                              className="text-white ml-3 mr-2"
                              style={{
                                marginTop: "11px",
                                wordBreak: "break-all",
                              }}
                            >
                              {item.skill}
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div className="no-data">No data found</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAdminTeacherProfile,
      updateTeacherProfile,
      deleteTeacherQualification,
      deleteTeacherSkill,
      showNotification,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(AdminTeacherProfile)
