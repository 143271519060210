/*************************************************
 * RedInk
 * @exports
 * @class StudentDetailsModal.js
 * @extends Component
 * @author Sasidharan // on 01/02/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import Modal, { ModalTransition } from "@atlaskit/modal-dialog"
import { bindActionCreators } from "redux"
import ModalFooter from "reactstrap/lib/ModalFooter"
import { connect } from "react-redux"
import moment from "moment"

import {
  showNotification,
  getCheckEmailAvailableDetails,
} from "../../../actions/CommonAction"
import { saveStudentDatabaseDetailInStore } from "../../../actions/teacher/StudentDatabaseAction"
import { DANGER_TOAST, VALIDATION_MSG } from "../../../utils/Constants"
import { validateEmail } from "../../../utils/Utility"

class StudentDetailsModal extends Component {
  static getDerivedStateFromProps(props) {
    return {
      studentImage: "",
      firstName: props.studentData.studentFirstName,
      lastName: props.studentData.studentLastName,
      name: props.studentData.studentName,
      studentEmail: props.studentData.studentEmail,
      studentPhone: props.studentData.studentPhone,
      gender: props.studentData.gender,
      dob: props.studentData.dob,
      registerNumber: props.studentData.registerRollNumber,
      parentsList: props.studentData.parents,
      oldName: props.studentData.oldName,
      admissionNumber: props.studentData.admissionNumber,
      dateOfJoining: props.studentData.dateOfJoining,
      primaryParent: props.studentData.primaryParent,
      fatherName: props.studentData.fatherName,
      fatherFirstName: props.studentData.fatherFirstName,
      fatherLastName: props.studentData.fatherLastName,
      fatherEmail: props.studentData.fatherEmail,
      fatherPhone: props.studentData.fatherPhone,
      motherName: props.studentData.motherName,
      motherFirstName: props.studentData.motherFirstName,
      motherLastName: props.studentData.motherLastName,
      motherEmail: props.studentData.motherEmail,
      motherPhone: props.studentData.motherPhone,
      legalGuardianEmail: props.studentData.legalGuardianEmail,
      legalGuardianName: props.studentData.legalGuardianName,
      legalGuardianFirstName: props.studentData.legalGuardianFirstName,
      legalGuardianLastName: props.studentData.legalGuardianLastName,
      legalGuardianPhone: props.studentData.legalGuardianPhone,
      localGuardianEmail: props.studentData.localGuardianEmail,
      localGuardianName: props.studentData.localGuardianName,
      localGuardianFirstName: props.studentData.localGuardianFirstName,
      localGuardianLastName: props.studentData.localGuardianLastName,
      localGuardianPhone: props.studentData.localGuardianPhone,
      sectionId: props.studentData.section
        ? props.studentData.section.sectionId
        : "",
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      studentImage: "",
      name: "",
      gender: "",
      dob: "",
      registerNumber: "",
      parentsList: "",
      firstName: "",
      lastName: "",
      studentEmail: "",
      studentPhone: "",
      admissionNumber: "",
      dateOfJoining: "",
      isRollNumberExist: false,
      primaryParent: "",
      fatherFirstName: "",
      fatherLastName: "",
      fatherEmail: "",
      fatherPhone: "",
      motherFirstName: "",
      motherLastName: "",
      motherEmail: "",
      motherPhone: "",
      legalGuardianEmail: "",
      legalGuardianFirstName: "",
      legalGuardianLastName: "",
      legalGuardianPhone: "",
      localGuardianEmail: "",
      localGuardianFirstName: "",
      localGuardianLastName: "",
      localGuardianPhone: "",
      sectionId: "",
      classesList: [],
    }
  }

  clearState() {
    let studentDetail = {
      name: "",
      gender: "",
      dob: "",
      registerNumber: "",
      studentImage: "",
      firstName: "",
      lastName: "",
      studentPhone: "",
      admissionNumber: "",
      dateOfJoining: "",
      primaryParent: "",
      fatherName: "",
      fatherFirstName: "",
      fatherLastName: "",
      fatherEmail: "",
      fatherPhone: "",
      motherName: "",
      motherFirstName: "",
      motherLastName: "",
      motherEmail: "",
      motherPhone: "",
      legalGuardianEmail: "",
      legalGuardianName: "",
      legalGuardianFirstName: "",
      legalGuardianLastName: "",
      legalGuardianPhone: "",
      localGuardianEmail: "",
      localGuardianName: "",
      localGuardianFirstName: "",
      localGuardianLastName: "",
      localGuardianPhone: "",
      sectionId: "",
      parentsList: [{ parentName: "", parentEmail: "", parentPhone: "" }],
    }
    this.setState({ isRollNumberExist: false })
    this.props.saveStudentDatabaseDetailInStore(studentDetail)
  }

  saveAddNext = () => {
    if (
      !this.state.sectionId ||
      !this.state.firstName ||
      !this.state.studentPhone ||
      !this.state.dob ||
      !this.state.gender
    ) {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.SUCCESS,
        "Fill out required fields"
      )
      return
    }

    if (this.state.studentPhone) {
      let len = String(this.state.studentPhone).length
      if (len < 10 || len >= 15) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Student Phone number invalid"
        )
        return
      }
    }

    if (this.state.dateOfJoining > moment(new Date()).format("YYYY-MM-DD")) {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.SUCCESS,
        "Date of joining greater than today"
      )
      return
    }

    if (this.state.dob >= moment(new Date()).format("YYYY-MM-DD")) {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.SUCCESS,
        "Date of birth Invalid"
      )
      return
    }

    if (this.state.fatherEmail) {
      if (!validateEmail(this.state.fatherEmail)) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Father mail id invalid"
        )
        return
      }
    }

    if (this.state.fatherPhone) {
      let len = String(this.state.fatherPhone).length
      if (len < 10 || len > 15) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Father Phone number invalid"
        )
        return
      }
    }

    if (this.state.motherEmail) {
      if (!validateEmail(this.state.motherEmail)) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Mother mail id invalid"
        )
        return
      }
    }

    if (this.state.motherPhone) {
      let len = String(this.state.motherPhone).length
      if (len < 10 || len > 15) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Mother Phone number invalid"
        )
        return
      }
    }

    if (this.state.legalGuardianEmail) {
      if (!validateEmail(this.state.legalGuardianEmail)) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Legal Guardian mail id invalid"
        )
        return
      }
    }

    if (this.state.legalGuardianPhone) {
      let len = String(this.state.legalGuardianPhone).length
      if (len < 10 || len >= 15) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Legal Gaurdian Phone number invalid"
        )
        return
      }
    }

    if (this.state.localGuardianEmail) {
      if (!validateEmail(this.state.localGuardianEmail)) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Local Guardian mail id invalid"
        )
        return
      }
    }

    if (this.state.localGuardianPhone) {
      let len = String(this.state.localGuardianPhone).length
      if (len < 10 || len >= 15) {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Local Guardian Phone number invalid"
        )
        return
      }
    }

    let object = {
      sectionId: this.state.sectionId,
      firstName: this.state.firstName,
      lastName: this.state.lastName || null,
      studentEmail: this.state.studentEmail || null,
      studentPhone: this.state.studentPhone || null,
      admissionNumber: this.state.admissionNumber ?? null,
      registerNumber: this.state.registerNumber || "-",
      dateOfJoining: this.state.dateOfJoining || null,
      studentImage: this.state.studentImage,
      gender: this.state.gender,
      dob: this.state.dob,
      parentsList: [
        {
          id: this.props.studentData.parentDetailId || null,
          fatherFirstName: this.state.fatherFirstName,
          fatherLastName: this.state.fatherLastName,
          fatherEmail: this.state.fatherEmail,
          fatherPhone: this.state.fatherPhone,
          motherFirstName: this.state.motherFirstName,
          motherLastName: this.state.motherLastName,
          motherEmail: this.state.motherEmail,
          motherPhone: this.state.motherPhone,
          legalGuardianEmail: this.state.legalGuardianEmail,
          legalGuardianFirstName: this.state.legalGuardianFirstName,
          legalGuardianLastName: this.state.legalGuardianLastName,
          legalGuardianPhone: this.state.legalGuardianPhone,
          localGuardianEmail: this.state.localGuardianEmail,
          localGuardianFirstName: this.state.localGuardianFirstName,
          localGuardianLastName: this.state.localGuardianLastName,
          localGuardianPhone: this.state.localGuardianPhone,
        },
      ],
    }
    if (this.props.studentData && this.props.studentData.id) {
      object.id = this.props.studentData.id
    }
    if (this.state.primaryParent === "FATHER") {
      if (
        !this.state.fatherFirstName &&
        !this.state.fatherLastName &&
        !this.state.fatherEmail &&
        !this.state.fatherPhone
      ) {
        object.primaryParent = null
      }
    }
    if (this.state.primaryParent === "MOTHER") {
      if (
        !this.state.motherFirstName &&
        !this.state.motherLastName &&
        !this.state.motherEmail &&
        !this.state.motherPhone
      ) {
        object.primaryParent = null
      }
    }
    if (this.state.primaryParent === "LOG") {
      if (
        !this.state.localGuardianFirstName &&
        !this.state.localGuardianLastName &&
        !this.state.localGuardianEmail &&
        !this.state.localGuardianPhone
      ) {
        object.primaryParent = null
      }
    }
    if (this.state.primaryParent === "LEG") {
      if (
        !this.state.legalGuardianFirstName &&
        !this.state.legalGuardianLastName &&
        !this.state.localGuardianEmail &&
        !this.state.legalGuardianPhone
      ) {
        object.primaryParent = null
      }
    }
    this.props.onSubmitStudentDetails(false, object)
    this.clearState()
  }

  gotoNextField = (e, id, withData = false) => {
    if (e.key === "Enter") {
      let canEmp = withData ? e.target.value : true
      if (canEmp) {
        let el = document.getElementById(id)
        if (el) {
          el.focus()
        }
      }
    }
  }

  render() {
    const { isModalOpen, closeModal } = this.props

    return (
      <ModalTransition>
        {isModalOpen && (
          <Modal
            width="80%"
            heading={
              this.props.studentData && this.props.studentData.id
                ? "STUDENT DETAILS"
                : "ADD NEW STUDENT DETAILS"
            }
            actions={[
              {
                text: this.props.isShowLoading ? "LOADING..." : "SAVE",
                onClick: this.saveAddNext,
              },
              {
                text: "CANCEL",
                onClick: () => {
                  closeModal()
                  this.clearState()
                },
                style: {
                  fontWeight: "bold",
                },
              },
            ]}
          >
            <small>
              {this.state.firstName !== "" ? "" : this.state.oldName}
            </small>
            <div className="row px-3">
              <div className="col-sm-9 p-0 pr-4 border-right">
                <div className="form-row">
                  <div className="form-group col-sm">
                    <small>
                      First Name <span style={{ color: "red" }}>*</span>
                    </small>
                    <input
                      id="admin-sp-stu-firstName"
                      type="text"
                      className="form-control"
                      value={this.state.firstName}
                      onChange={e => {
                        let object = this.props.studentData
                        object.studentFirstName = e.target.value.replace(
                          /[^A-Za-z ]/gi,
                          ""
                        )
                        this.props.saveStudentDatabaseDetailInStore(object)
                      }}
                      onKeyDown={e =>
                        this.gotoNextField(e, "admin-sp-stu-lastName", true)
                      }
                    />
                  </div>
                  <div className="form-group col-sm">
                    <small>Last Name</small>
                    <input
                      id="admin-sp-stu-lastName"
                      type="text"
                      className="form-control"
                      value={this.state.lastName}
                      onChange={e => {
                        let object = this.props.studentData
                        object.studentLastName = e.target.value.replace(
                          /[^A-Za-z ]/gi,
                          ""
                        )
                        this.props.saveStudentDatabaseDetailInStore(object)
                      }}
                      onKeyDown={e =>
                        this.gotoNextField(e, "admin-sp-stu-studentEmail")
                      }
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-sm">
                    <small>Email Id</small>
                    <input
                      id="admin-sp-stu-studentEmail"
                      type="text"
                      className="form-control"
                      value={this.state.studentEmail}
                      onChange={e => {
                        let object = this.props.studentData
                        object.studentEmail = e.target.value
                        this.props.saveStudentDatabaseDetailInStore(object)
                      }}
                      onKeyDown={e =>
                        this.gotoNextField(e, "admin-sp-stu-studentPhone")
                      }
                    />
                  </div>
                  <div className="form-group col-sm">
                    <small>
                      Mobile Number <span style={{ color: "red" }}>*</span>
                    </small>
                    <input
                      id="admin-sp-stu-studentPhone"
                      type="number"
                      className="form-control"
                      value={this.state.studentPhone}
                      onChange={e => {
                        let object = this.props.studentData
                        object.studentPhone = e.target.value.replace(
                          /[^0-9]/gi,
                          ""
                        )
                        this.props.saveStudentDatabaseDetailInStore(object)
                      }}
                      onKeyDown={e =>
                        this.gotoNextField(
                          e,
                          "admin-sp-stu-registerNumber",
                          true
                        )
                      }
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-sm">
                    <small>Roll/Registration Number </small>
                    <input
                      id="admin-sp-stu-registerNumber"
                      type="text"
                      maxLength="20"
                      className="form-control"
                      value={this.state.registerNumber}
                      onChange={e => {
                        this.setState({ isRollNumberExist: false })
                        let object = this.props.studentData
                        object.registerRollNumber = e.target.value
                        this.props.saveStudentDatabaseDetailInStore(object)
                        this.props.getCheckEmailAvailableDetails(
                          e.target.value,
                          (success, response) => {
                            if (response) {
                              this.setState({ isRollNumberExist: true })
                            }
                          }
                        )
                      }}
                      onKeyDown={e =>
                        this.gotoNextField(e, "admin-sp-stu-admissionNumber")
                      }
                    />
                  </div>
                  <div className="form-group col-sm">
                    <small>Admission No.</small>
                    <input
                      id="admin-sp-stu-admissionNumber"
                      type="text"
                      className="form-control"
                      value={this.state.admissionNumber}
                      onChange={e => {
                        if (e.target.value >= 0) {
                          let object = this.props.studentData
                          object.admissionNumber = e.target.value.replace(
                            /[^A-Za-z0-9 ]/gi,
                            ""
                          )
                          this.props.saveStudentDatabaseDetailInStore(object)
                        }
                      }}
                      onKeyDown={e =>
                        this.gotoNextField(e, "admin-sp-stu-dateOfJoining")
                      }
                    />
                  </div>

                  <div className="form-group col-sm">
                    <small>Date of Admission</small>
                    <input
                      id="admin-sp-stu-dateOfJoining"
                      max="9999-12-31"
                      type="date"
                      className="form-control unstyled"
                      value={this.state.dateOfJoining}
                      onChange={e => {
                        let object = this.props.studentData
                        object.dateOfJoining = e.target.value
                        this.props.saveStudentDatabaseDetailInStore(object)
                      }}
                      onKeyDown={e =>
                        this.gotoNextField(e, "admin-sp-stu-sectionId")
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-3">
                <div className="form-group col-sm">
                  <small>Class</small>
                  <span style={{ color: "red" }}>*</span>
                  <select
                    id="admin-sp-stu-sectionId"
                    className="form-control"
                    value={this.state.sectionId}
                    onChange={e => {
                      let object = this.props.studentData
                      object.section = {}
                      object.section.sectionId = e.target.value
                      this.props.saveStudentDatabaseDetailInStore(object)
                    }}
                    onKeyDown={e =>
                      this.gotoNextField(e, "admin-sp-stu-gender", true)
                    }
                  >
                    <option value="">Select Class</option>
                    {this.props.classesList &&
                      this.props.classesList.map(option => (
                        <option value={option.id} key={option.value}>
                          {option.label}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group col-sm">
                  <small>
                    Gender <span style={{ color: "red" }}>*</span>
                  </small>
                  <select
                    id="admin-sp-stu-gender"
                    className="form-control"
                    value={this.state.gender}
                    onChange={e => {
                      let object = this.props.studentData
                      object.gender = e.target.value
                      this.props.saveStudentDatabaseDetailInStore(object)
                    }}
                    onKeyDown={e =>
                      this.gotoNextField(e, "admin-sp-stu-dob", true)
                    }
                  >
                    <option value="">Select</option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                    <option value="O">Other</option>
                  </select>
                </div>
                <div className="form-group col-sm">
                  <small>
                    Date of Birth <span style={{ color: "red" }}>*</span>
                  </small>
                  <input
                    id="admin-sp-stu-dob"
                    max="9999-12-31"
                    type="date"
                    className="form-control unstyled"
                    value={this.state.dob}
                    onChange={e => {
                      let object = this.props.studentData
                      object.dob = e.target.value
                      this.props.saveStudentDatabaseDetailInStore(object)
                    }}
                    onKeyDown={e => this.gotoNextField(e, "Father", true)}
                  />
                </div>
              </div>
            </div>

            <hr className="dotted"></hr>
            <h5 className="my-3 text-dark">Parent's details</h5>
            <div className="d-flex align-items-center mt-3 mb-1">
              <div>Primary Contact</div>
              <div className="form-check form-check-inline ml-5">
                <input
                  className="form-check-input"
                  type="radio"
                  name="primaryContact"
                  id="Father"
                  value={this.state.primaryParent}
                  checked={this.state.primaryParent === "FATHER"}
                  onChange={e => {
                    let object = this.props.studentData
                    object.primaryParent = "FATHER"
                    this.props.saveStudentDatabaseDetailInStore(object)
                  }}
                  onKeyDown={e =>
                    this.gotoNextField(e, "admin-sp-stu-fatherFirstName")
                  }
                />
                <label className="form-check-label" htmlFor="Father">
                  Father
                </label>
              </div>
              <div className="form-check form-check-inline ml-5">
                <input
                  className="form-check-input"
                  type="radio"
                  name="primaryContact"
                  id="Mother"
                  value={this.state.primaryParent}
                  checked={this.state.primaryParent === "MOTHER"}
                  onChange={e => {
                    let object = this.props.studentData
                    object.primaryParent = "MOTHER"
                    this.props.saveStudentDatabaseDetailInStore(object)
                  }}
                  onKeyDown={e =>
                    this.gotoNextField(e, "admin-sp-stu-fatherFirstName")
                  }
                />
                <label className="form-check-label" htmlFor="Mother">
                  Mother
                </label>
              </div>
              <div className="form-check form-check-inline ml-5">
                <input
                  className="form-check-input"
                  type="radio"
                  name="primaryContact"
                  id="localGuardian"
                  value="localGuardian"
                  checked={this.state.primaryParent === "LOG"}
                  onChange={e => {
                    let object = this.props.studentData
                    object.primaryParent = "LOG"
                    this.props.saveStudentDatabaseDetailInStore(object)
                  }}
                  onKeyDown={e =>
                    this.gotoNextField(e, "admin-sp-stu-fatherFirstName")
                  }
                />
                <label className="form-check-label" htmlFor="localGuardian">
                  Local Guardian
                </label>
              </div>
              <div className="form-check form-check-inline ml-5">
                <input
                  className="form-check-input"
                  type="radio"
                  name="primaryContact"
                  id="legalGuardian"
                  value="legalGuardian"
                  checked={this.state.primaryParent === "LEG"}
                  onChange={e => {
                    let object = this.props.studentData
                    object.primaryParent = "LEG"
                    this.props.saveStudentDatabaseDetailInStore(object)
                  }}
                  onKeyDown={e =>
                    this.gotoNextField(e, "admin-sp-stu-fatherFirstName")
                  }
                />
                <label className="form-check-label" htmlFor="legalGuardian">
                  Legal Guardian
                </label>
              </div>
            </div>
            <div className="text-muted mb-3" style={{ fontSize: 14 }}>
              School will use the primary contact only to communicate any
              announcements
            </div>
            <>
              <div className="d-flex justify-content-between">
                <div className="form-group w-100 mr-2">
                  <small>Father First Name</small>{" "}
                  <input
                    id="admin-sp-stu-fatherFirstName"
                    type="text"
                    className="form-control"
                    value={this.state.fatherFirstName}
                    onChange={e => {
                      let object = this.props.studentData
                      object.fatherFirstName = e.target.value.replace(
                        /[^A-Za-z ]/gi,
                        ""
                      )
                      this.props.saveStudentDatabaseDetailInStore(object)
                    }}
                    onKeyDown={e =>
                      this.gotoNextField(e, "admin-sp-stu-fatherLastName")
                    }
                  />
                </div>
                <div className="form-group w-100 mr-2">
                  <small>Father Last Name</small>{" "}
                  <input
                    id="admin-sp-stu-fatherLastName"
                    type="text"
                    className="form-control"
                    value={this.state.fatherLastName}
                    onChange={e => {
                      let object = this.props.studentData
                      object.fatherLastName = e.target.value.replace(
                        /[^A-Za-z ]/gi,
                        ""
                      )
                      this.props.saveStudentDatabaseDetailInStore(object)
                    }}
                    onKeyDown={e =>
                      this.gotoNextField(e, "admin-sp-stu-fatherEmail")
                    }
                  />
                </div>
                <div className="form-group w-100 mr-2">
                  <small>Email Id</small>{" "}
                  <input
                    id="admin-sp-stu-fatherEmail"
                    type="text"
                    className="form-control"
                    value={this.state.fatherEmail}
                    onChange={e => {
                      let object = this.props.studentData
                      object.fatherEmail = e.target.value
                      this.props.saveStudentDatabaseDetailInStore(object)
                    }}
                    onKeyDown={e =>
                      this.gotoNextField(e, "admin-sp-stu-fatherPhone")
                    }
                  />
                </div>
                <div className="form-group w-100 mr-2">
                  <small>Mobile Number</small>{" "}
                  <input
                    id="admin-sp-stu-fatherPhone"
                    type="number"
                    className="form-control"
                    value={this.state.fatherPhone}
                    onChange={e => {
                      let object = this.props.studentData
                      object.fatherPhone = e.target.value
                      // object.fatherPhone = object.fatherPhone.replace(
                      //   /[^0-9 ]/gi,
                      //   ""
                      // )
                      this.props.saveStudentDatabaseDetailInStore(object)
                    }}
                    onKeyDown={e =>
                      this.gotoNextField(e, "admin-sp-stu-motherFirstName")
                    }
                  />
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <div className="form-group w-100 mr-2">
                  <small>Mother First Name</small>{" "}
                  <input
                    id="admin-sp-stu-motherFirstName"
                    type="text"
                    className="form-control"
                    value={this.state.motherFirstName}
                    onChange={e => {
                      let object = this.props.studentData
                      object.motherFirstName = e.target.value.replace(
                        /[^A-Za-z ]/gi,
                        ""
                      )
                      this.props.saveStudentDatabaseDetailInStore(object)
                    }}
                    onKeyDown={e =>
                      this.gotoNextField(e, "admin-sp-stu-motherLastName")
                    }
                  />
                </div>
                <div className="form-group w-100 mr-2">
                  <small>Mother Last Name</small>{" "}
                  <input
                    id="admin-sp-stu-motherLastName"
                    type="text"
                    className="form-control"
                    value={this.state.motherLastName}
                    onChange={e => {
                      let object = this.props.studentData
                      object.motherLastName = e.target.value.replace(
                        /[^A-Za-z ]/gi,
                        ""
                      )
                      this.props.saveStudentDatabaseDetailInStore(object)
                    }}
                    onKeyDown={e =>
                      this.gotoNextField(e, "admin-sp-stu-motherEmail")
                    }
                  />
                </div>
                <div className="form-group w-100 mr-2">
                  <small>Email Id</small>{" "}
                  <input
                    id="admin-sp-stu-motherEmail"
                    type="text"
                    className="form-control"
                    value={this.state.motherEmail}
                    onChange={e => {
                      let object = this.props.studentData
                      object.motherEmail = e.target.value
                      this.props.saveStudentDatabaseDetailInStore(object)
                    }}
                    onKeyDown={e =>
                      this.gotoNextField(e, "admin-sp-stu-motherPhone")
                    }
                  />
                </div>
                <div className="form-group w-100 mr-2">
                  <small>Mobile Number</small>{" "}
                  <input
                    id="admin-sp-stu-motherPhone"
                    type="number"
                    className="form-control"
                    value={this.state.motherPhone}
                    onChange={e => {
                      let object = this.props.studentData
                      object.motherPhone = e.target.value
                      this.props.saveStudentDatabaseDetailInStore(object)
                    }}
                    onKeyDown={e =>
                      this.gotoNextField(
                        e,
                        "admin-sp-stu-localGuardianFirstName"
                      )
                    }
                  />
                </div>
              </div>
            </>

            <div className="d-flex align-items-center">
              <h5 className="my-3 text-dark">Guardian details</h5>
              <h6 className="my-3 text-muted ml-3">(Not mandatory)</h6>
            </div>
            <div className="d-flex justify-content-between">
              <div className="form-group w-100 mr-2">
                <small>Local Guardian First Name</small>
                <input
                  id="admin-sp-stu-localGuardianFirstName"
                  type="text"
                  className="form-control"
                  value={this.state.localGuardianFirstName}
                  onChange={e => {
                    let object = this.props.studentData
                    object.localGuardianFirstName = e.target.value.replace(
                      /[^A-Za-z ]/gi,
                      ""
                    )
                    this.props.saveStudentDatabaseDetailInStore(object)
                  }}
                  onKeyDown={e =>
                    this.gotoNextField(e, "admin-sp-stu-localGuardianLastName")
                  }
                />
              </div>
              <div className="form-group w-100 mr-2">
                <small>Local Guardian Last Name</small>
                <input
                  id="admin-sp-stu-localGuardianLastName"
                  type="text"
                  className="form-control"
                  value={this.state.localGuardianLastName}
                  onChange={e => {
                    let object = this.props.studentData
                    object.localGuardianLastName = e.target.value.replace(
                      /[^A-Za-z ]/gi,
                      ""
                    )
                    this.props.saveStudentDatabaseDetailInStore(object)
                  }}
                  onKeyDown={e =>
                    this.gotoNextField(e, "admin-sp-stu-localGuardianEmail")
                  }
                />
              </div>
              <div className="form-group w-100 mr-2">
                <small>Email Id</small>
                <input
                  id="admin-sp-stu-localGuardianEmail"
                  type="text"
                  className="form-control"
                  value={this.state.localGuardianEmail}
                  onChange={e => {
                    let object = this.props.studentData
                    object.localGuardianEmail = e.target.value
                    this.props.saveStudentDatabaseDetailInStore(object)
                  }}
                  onKeyDown={e =>
                    this.gotoNextField(e, "admin-sp-stu-localGuardianPhone")
                  }
                />
              </div>
              <div className="form-group w-100 mr-2">
                <small>Mobile Number</small>
                <input
                  id="admin-sp-stu-localGuardianPhone"
                  type="text"
                  className="form-control"
                  value={this.state.localGuardianPhone}
                  maxLength="10"
                  onChange={e => {
                    let object = this.props.studentData
                    object.localGuardianPhone = e.target.value.replace(
                      /[^0-9 ]/gi,
                      ""
                    )
                    this.props.saveStudentDatabaseDetailInStore(object)
                  }}
                  onKeyDown={e =>
                    this.gotoNextField(e, "admin-sp-stu-legalGuardianFirstName")
                  }
                />
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <div className="form-group w-100 mr-2">
                <small>Legal Guardian First Name</small>
                <input
                  id="admin-sp-stu-legalGuardianFirstName"
                  type="text"
                  className="form-control"
                  value={this.state.legalGuardianFirstName}
                  onChange={e => {
                    let object = this.props.studentData
                    object.legalGuardianFirstName = e.target.value.replace(
                      /[^A-Za-z ]/gi,
                      ""
                    )
                    this.props.saveStudentDatabaseDetailInStore(object)
                  }}
                  onKeyDown={e =>
                    this.gotoNextField(e, "admin-sp-stu-legalGuardianLastName")
                  }
                />
              </div>
              <div className="form-group w-100 mr-2">
                <small>Legal Guardian Last Name</small>
                <input
                  id="admin-sp-stu-legalGuardianLastName"
                  type="text"
                  className="form-control"
                  value={this.state.legalGuardianLastName}
                  onChange={e => {
                    let object = this.props.studentData
                    object.legalGuardianLastName = e.target.value.replace(
                      /[^A-Za-z ]/gi,
                      ""
                    )
                    this.props.saveStudentDatabaseDetailInStore(object)
                  }}
                  onKeyDown={e =>
                    this.gotoNextField(e, "admin-sp-stu-legalGuardianEmail")
                  }
                />
              </div>
              <div className="form-group w-100 mr-2">
                <small>Email Id</small>
                <input
                  id="admin-sp-stu-legalGuardianEmail"
                  type="text"
                  className="form-control"
                  value={this.state.legalGuardianEmail}
                  onChange={e => {
                    let object = this.props.studentData
                    object.legalGuardianEmail = e.target.value
                    this.props.saveStudentDatabaseDetailInStore(object)
                  }}
                  onKeyDown={e =>
                    this.gotoNextField(e, "admin-sp-stu-legalGuardianPhone")
                  }
                />
              </div>
              <div className="form-group w-100 mr-2">
                <small>Mobile Number</small>
                <input
                  id="admin-sp-stu-legalGuardianPhone"
                  type="text"
                  className="form-control"
                  value={this.state.legalGuardianPhone}
                  maxLength="10"
                  onChange={e => {
                    let object = this.props.studentData
                    object.legalGuardianPhone = e.target.value.replace(
                      /[^0-9 ]/gi,
                      ""
                    )
                    this.props.saveStudentDatabaseDetailInStore(object)
                  }}
                />
              </div>
            </div>
            <ModalFooter></ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    )
  }
}

const mapStateToProps = state => {
  return {
    studentData: state.studentDatabaseState.studentDetail,
    classesList: state.classDetailsState.classList,
    isShowLoading: state.loginState.isShowLoading,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      saveStudentDatabaseDetailInStore,
      getCheckEmailAvailableDetails,
      showNotification,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(StudentDetailsModal)
