import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import { showNotification } from "../CommonAction"
import {
  // SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"
import URL from "../../config"

export const getStudentSchedule = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.STUDENT_SCHEDULE,
        object,
        true,
        true
      )
      if (response) {
        myLog("====getStudentSchedule RESPONSE===::::", response)
        // dispatch(saveClassDetailsInStore(response.teacherClasses))
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getStudentSchedule ERROR===????", error)
    }
  }
}

export const getSingleScheduleDetails = (id, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.STUDENT_SCHEDULE + "/" + id,
        null,
        true,
        true
      )
      if (response) {
        myLog("====getSingleScheduleDetails RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====getSingleScheduleDetails ERROR===????", error)
    }
  }
}
