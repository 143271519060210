import React, { useState } from "react"
import { Collapse, Modal } from "reactstrap"

import { change } from "../common/utils"

import { ReactComponent as Close } from "../../../../assets/images/close.svg"
import { ReactComponent as Arrow } from "../../../../assets/images/arrow.svg"
import Match from "../common/Match"

function Wrapper({
  id,
  text,
  type,
  question = [],
  match = [],
  selected,
  setSelected,
  updateByReplacing,
  isHidden,
}) {
  return (
    <div
      className="p-4 pb-0 bg-white"
      style={isHidden ? { display: "none" } : {}}
    >
      <div
        className="d-flex align-items-start cursor-pointer"
        onClick={() => setSelected(pr => (pr === id ? -1 : id))}
      >
        <p className="mb-0 mr-2" style={{ whiteSpace: "pre-wrap" }}>
          {text}
        </p>

        <button
          className="ml-auto fs-12 fw-500 px-4 py-0 clr-50AE93 bg-50AE934D br-5px"
          onClick={updateByReplacing}
        >
          Add
        </button>

        {(type === "MULTIPLE_CHOICE" || type === "MATCHING") && (
          <button>
            <Arrow className="clr-DFDEDE" />
          </button>
        )}
      </div>

      <Collapse isOpen={selected === id}>
        <div>
          {type === "MULTIPLE_CHOICE" &&
            question.map((o, i) => (
              <label
                key={o}
                className="d-flex align-items-center pl-2 ml-4 fs-14 clr-201F1F"
              >
                {change(o, i)}
              </label>
            ))}

          {type === "MATCHING" && <Match question={question} match={match} />}
        </div>
      </Collapse>

      <div className="pt-4 border-b-DFDEDED9"></div>
    </div>
  )
}

function Replace({
  data,
  chapters,
  chapterName,
  selectedChapter,
  selectedSubject,
  masterSubjectId,
  defaultSubjectChapterId,
  updateByReplacing,
  closeModal,
}) {
  const [transformedChapter] = useState(
    chapters
      .map(c1 =>
        c1.chapters.map(c2 => ({
          chapterId: c2.chapterId,
          chapterName: c2.chapterName,
          masterSubjectId: c1.masterSubjectId,
        }))
      )
      .flat()
  )
  const [currentChapter, setCurrentChapter] = useState(defaultSubjectChapterId)
  const [currentSubject, setCurrentSubject] = useState(masterSubjectId)
  const [selected, setSelected] = useState("")

  const filtered = data.filter(
    d => currentChapter === d.defaultSubjectChapterId
  )

  return (
    <Modal
      isOpen
      backdropClassName="modal-backdrop-show"
      modalClassName="modal-dialog-shadow-none report-card-modal-full-wrapper"
      contentClassName="report-modal-wrapper py-4 px-5"
      centered
      size="xl"
    >
      <div className="d-flex align-items-center mb-2">
        <p className="mb-0 fs-18 fw-700">Replace Questions</p>

        <Close
          width={28}
          height={28}
          onClick={closeModal}
          className="ml-auto cursor-pointer"
        />
      </div>

      <div className="bg-EDEDEDA8 mx-4 my-2 br-5px">
        <p className="df px-5 pt-4 fw-500 clr-898888" style={{ gap: "6px" }}>
          More from&nbsp;
          {selectedSubject.length > 1 && "Subject:"}
          {selectedSubject.length > 1 && (
            <select
              value={currentSubject}
              onChange={e => {
                setCurrentSubject(e.target.value)
                setCurrentChapter("")
              }}
              style={{
                minWidth: "200px",
                border: "none",
                fontSize: "16px",
                fontWeight: "500",
                borderRadius: "5px",
              }}
            >
              {selectedSubject.map(s => (
                <option value={s.masterSubjectId}>{s.subjectName}</option>
              ))}
            </select>
          )}
          Chapter:
          <select
            value={currentChapter}
            onChange={e => setCurrentChapter(e.target.value)}
            style={{
              minWidth: "200px",
              border: "none",
              fontSize: "16px",
              fontWeight: "500",
              borderRadius: "5px",
            }}
          >
            <option value="" disabled>
              Select chapter
            </option>
            {transformedChapter
              .filter(t => Number(t.masterSubjectId) === Number(currentSubject))
              .map(c => (
                <option value={c.chapterId}>{c.chapterName}</option>
              ))}
          </select>
        </p>

        <div
          className={`manual-quest-wrapper mx-5 bg-white br-5px ${
            filtered.length === 0
              ? "d-flex align-items-center justify-content-center"
              : ""
          }`}
          style={{ height: "60vh" }}
        >
          {filtered.length === 0 && (
            <p className="fs-20 fw-500">No questions found</p>
          )}
          {data.map((d, i) => (
            <Wrapper
              key={d.id}
              {...d}
              selected={selected}
              setSelected={setSelected}
              updateByReplacing={() => updateByReplacing(i)}
              isHidden={currentChapter !== d.defaultSubjectChapterId}
            />
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default Replace
