/*************************************************
 * RedInk
 * @exports
 * @class AttendanceReportReducer.js
 * @extends Component
 * @author vijayakumar // on 18/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from "../../actions/types"
import { myLog } from "../../utils/Utility"

const INTIAL_STATE = {
  classPerformanceAssessmentMarkByTeacherDetails: {},
  gradePerformanceAssessmentObject: {},
  studentsPerformerAssessmentObject: {},
  subjectPassFailPercentageGraphObject: {},
  subjectAverageMarkPercentageGraphObject: {},
  subjectAverageGradePercentageGraphObject: {},
  teachersSubjectList: [],
}

export const performanceReportReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.TEACHER_CLASS_PERFORMANCE_ASSESSMENT_MARK_DETAILS:
      myLog("payload", action.payload)
      return {
        ...state,
        classPerformanceAssessmentMarkByTeacherDetails: { ...action.payload },
      }
    case ACTION_TYPES.TEACHER_SUBJECTS_LIST:
      myLog("payload", action.payload)
      return {
        ...state,
        teachersSubjectList: [...action.payload],
      }
    case ACTION_TYPES.TEACHER_GRADE_PERFORMANCE_ASSESSMENT_OBJECT:
      myLog("payload", action.payload)
      return {
        ...state,
        gradePerformanceAssessmentObject: { ...action.payload },
      }
    case ACTION_TYPES.TEACHER_STUDENTS_PERFORMER_ASSESSMENT_OBJECT:
      myLog("payload", action.payload)
      return {
        ...state,
        studentsPerformerAssessmentObject: { ...action.payload },
      }
    case ACTION_TYPES.TEACHER_SUBJECT_PASS_FAIL_PERCENTAGE_ASSESSMENT_OBJECT:
      myLog("payload", action.payload)
      return {
        ...state,
        subjectPassFailPercentageGraphObject: { ...action.payload },
      }
    case ACTION_TYPES.TEACHER_SUBJECT_AVERAGE_MARK_PERCENTAGE_ASSESSMENT_OBJECT:
      myLog("payload", action.payload)
      return {
        ...state,
        subjectAverageMarkPercentageGraphObject: { ...action.payload },
      }
    case ACTION_TYPES.TEACHER_SUBJECT_AVERAGE_GRADE_PERCENTAGE_ASSESSMENT_OBJECT:
      myLog("payload", action.payload)
      return {
        ...state,
        subjectAverageGradePercentageGraphObject: { ...action.payload },
      }

    default:
      return state
  }
}
