/*************************************************
 * RedInk
 * @exports
 * @class AttendanceReportReducer.js
 * @extends Component
 * @author vijayakumar // on 18/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from "../../actions/types"
import { myLog } from "../../utils/Utility"

const INTIAL_STATE = {
  markSubjectDetails: {},
  markStudentList: [],
}

export const markScreenReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.TEACHER_MARK_SUBJECT_DETAILS:
      myLog("payload", action.payload)
      return {
        ...state,
        markSubjectDetails: { ...action.payload },
      }

    case ACTION_TYPES.TEACHER_MARK_STUDENT_LIST:
      myLog("payload", action.payload)
      return {
        ...state,
        markStudentList: [...action.payload],
      }

    case ACTION_TYPES.TEACHER_REMARK_ASSESSMENT_MARK_SCREEN: {
      myLog("payload", action.payload)
      return {
        ...state,
        markStudentList: state.markStudentList.map(m => {
          if (m.studentId === action.payload.studentId) {
            const row = [...m.row]
            row[row.length - 1] = action.payload.remark

            return {
              ...m,
              row,
            }
          }

          return m
        }),
      }
    }

    default:
      return state
  }
}
