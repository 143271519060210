/*************************************************
 * RedInk
 * @exports
 * @class QuickAnalytics.js
 * @extends Component
 * @author Sasidharan // on 22/03/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import Modal, { ModalTransition } from "@atlaskit/modal-dialog"
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip"
import { bindActionCreators } from "redux"
import { HashLoader } from "react-spinners"
import { Progress } from "react-sweet-progress"
import { connect } from "react-redux"
import Skeleton from "react-loading-skeleton"
import { Range } from "react-range"
import Chart from "chart.js"
import _ from "lodash"

import "react-circular-progressbar/dist/styles.css"

import {
  getStudentsPerformersListBySubjectDetails,
  saveDisplayStudentPerformanceReportDetailsInStore,
  saveStudentPerformanceReportDetailsInStore,
  getStudentPerformanceReportDetails,
  getStudentsPerformanceMarkBySubjectDetails,
  getGradePerformanceListBySubjectDetails,
} from "../../../actions/teacher/QuickAnalyticsAction"
import { getExamListDetails } from "../../../actions/teacher/HierarchyAction"
import { getSubjectsList } from "../../../actions/teacher/PerfromanceReportAction"
import { getClassList } from "../../../actions/teacher/TakeAttendanceAction"

import NoDataFound from "../../../assets/images/NoData.gif"

let myGraph = null

class QuickAnalyticsComponent extends Component {
  static getDerivedStateFromProps(props, state) {
    if (
      props.displayStudentPerformanceReportDetails !==
      state.displayStudentPerformanceReportDetails
    ) {
      return {
        displayStudentPerformanceReportDetails:
          props.displayStudentPerformanceReportDetails,
        sortedStudentsArray: props.displayStudentPerformanceReportDetails,
      }
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      hierarchySubjectDetails:
        this.props.location && this.props.location.hasOwnProperty("state")
          ? this.props.location.state &&
            this.props.location.state.hasOwnProperty("hierarchySubjectDetails")
            ? this.props.location.state.hierarchySubjectDetails
            : this.props.hierarchySubjectDetails
          : this.props.hierarchySubjectDetails,
      className:
        this.props.location && this.props.location.hasOwnProperty("state")
          ? this.props.location.state &&
            this.props.location.state.hasOwnProperty("className")
            ? this.props.location.state.className
            : this.props.selectedClassName
          : this.props.selectedClassName,
      faName:
        // this.props.examList[0].label,
        this.props.location && this.props.location.hasOwnProperty("state")
          ? this.props.location.state &&
            this.props.location.state.hasOwnProperty("faName")
            ? this.props.location.state.faName
            : this.props.selectedAssementName
          : this.props.selectedAssementName,
      // changed since props is not working
      subjectName:
        this.props.location && this.props.location.hasOwnProperty("state")
          ? this.props.location.state &&
            this.props.location.state.hasOwnProperty("subjectName")
            ? this.props.location.state.subjectName
            : this.props.selectedSubjectName
          : this.props.selectedSubjectName,
      studentPerformanceRange: [0, 100],
      startSliderValue: 0,
      endSliderValue: 100,
      sliderTotalStudent: 0,
      totalStudent: 0,
      displayStudentPerformanceReportDetails: [],
      sortedStudentsArray: [],
      isShowViewData: true,
      isShowNoDateFound: false,
      sortOrderByNames: 0,
      sortOrderByMarks: 0,
      sortOrderByGrades: 0,
      isShowLoadingStudentPerformance: true,
      outOffMark: 0,
    }
  }

  componentDidMount = () => {
    this.props.getExamListDetails((examSuccess, examResponse) => {
      if (examSuccess) {
        this.setState({
          selectedExamStudentAcademicPerformance: examResponse[0].id,
          assementName: examResponse[0].value,
        })
        this.props.getClassList((classSuccess, classResponse) => {
          if (classSuccess) {
            this.setState({
              selectedClassStudentAcademicPerformance: classResponse[0].id,
              selectedClass: classResponse[0].id,
              selectedClassName: classResponse[0].value,
            })
            let subjectObject = {
              assessmentId: examResponse[0].id,
              sectionId: classResponse[0].id,
            }
            this.props.getSubjectsList(
              subjectObject,
              (subjectSuccess, subjectResponse) => {
                if (subjectSuccess) {
                  this.setState({
                    selectedSubject: subjectResponse[0].id,
                    selectedSubjectName: subjectResponse[0].value,
                  })
                }
              }
            )
          }
        })
      }
    })

    if (this.state.hierarchySubjectDetails) {
      this.setState({
        selectedSubject: this.state.hierarchySubjectDetails.subjectId,
        selectedClass: this.state.hierarchySubjectDetails.classId,
        selectedExam: this.state.hierarchySubjectDetails.faId,
      })
      this.getQuickAnalyticsReport(
        this.state.hierarchySubjectDetails.subjectId,
        this.state.hierarchySubjectDetails.faId,
        this.state.hierarchySubjectDetails.classId
      )
    } else {
      this.setState({
        isShowNoDateFound: true,
      })
    }
  }

  getQuickAnalyticsReport = (subjectId, faId, sectionId) => {
    let object = {
      assessmentId: faId,
      hSubjectId: subjectId,
      sectionId: sectionId,
    }
    this.setState(
      {
        isShowLoading: true,
        isShowNoDateFound: false,
      },
      () => {
        this.props.getStudentsPerformanceMarkBySubjectDetails(
          object,
          (success, response) => {
            if (success) {
              this.setState({ isShowLoadingStudentPerformance: true }, () => {
                this.props.getStudentPerformanceReportDetails(
                  object,
                  (success, response) => {
                    if (success) {
                      this.setState({
                        isShowLoadingStudentPerformance: false,
                        sliderTotalStudent: this.props.studentPerformanceReport
                          .length,
                        totalStudent: this.props.studentPerformanceReport
                          .length,
                        outOffMark: response.outOffMark
                          ? response.outOffMark
                          : "",
                        isShowLoading: false,
                        isShowNoDateFound: false,
                      })
                      this.props.getStudentsPerformersListBySubjectDetails(
                        object,
                        () => {}
                      )
                      this.props.getGradePerformanceListBySubjectDetails(
                        object,
                        () => {
                          if (
                            !this.state.isShowLoading &&
                            !this.state.isShowNoDateFound
                          )
                            this.showGradeDistributionGraph()
                        }
                      )
                    } else {
                      this.setState({
                        isShowLoadingStudentPerformance: false,
                        isShowLoading: false,
                        isShowNoDateFound: true,
                      })
                    }
                  }
                )
              })
            } else {
              this.setState({
                isShowLoading: false,
                isShowNoDateFound: true,
              })
            }
          }
        )
      }
    )
  }

  componentWillUnmount() {
    // this.props.saveStudentPerformanceReportDetailsInStore([]);
    // this.props.saveDisplayStudentPerformanceReportDetailsInStore([]);
  }

  onChangeQuickAnalyticsReport = (faId, sectionId) => {
    let subjectObject = {
      assessmentId: faId,
      sectionId: sectionId,
    }
    this.setState(
      {
        isShowLoading: true,
        isShowNoDateFound: false,
      },
      () => {
        this.props.getSubjectsList(
          subjectObject,
          (subjectSuccess, subjectResponse) => {
            if (subjectSuccess) {
              this.setState({
                selectedSubject: subjectResponse[0].id,
                selectedSubjectName: subjectResponse[0].value,
              })
              this.getQuickAnalyticsReport(
                subjectResponse[0].id,
                faId,
                sectionId
              )
            }
          }
        )
      }
    )
  }

  customFilter = sliderValue => {
    const dummyStudents = this.props.studentPerformanceReport
    let array = []
    if (dummyStudents && dummyStudents.length > 0) {
      array = dummyStudents.filter(item => {
        return (
          item.percentage >= sliderValue[0] && item.percentage <= sliderValue[1]
        )
      })
      this.setState({
        sortedStudentsArray: array,
        startSliderValue: sliderValue[0],
        endSliderValue: sliderValue[1],
        sliderTotalStudent: array.length,
      })
      this.props.saveDisplayStudentPerformanceReportDetailsInStore(array)
    }
  }

  sortStudentListBy = (column, state) => {
    this.setState(
      {
        [state]: this.state[state] === 0 ? 1 : this.state[state] === 1 ? -1 : 0,
      },
      () => {
        let sortArray = this.state.displayStudentPerformanceReportDetails
        let newArray = []
        if (this.state[state] !== 0) {
          let sortBy = this.state[state] === -1 ? ["desc"] : ["asc"]
          newArray = _.orderBy(sortArray, [column], sortBy) // Use Lodash to sort array by key
          this.setState({ sortedStudentsArray: newArray })
        } else {
          this.setState({
            sortedStudentsArray: this.state
              .displayStudentPerformanceReportDetails,
          })
        }
      }
    )
  }

  sortGrades = data => {
    const grades = data.gradeGraph.labels
    grades.sort((a, b) =>
      a.charCodeAt(0) === b.charCodeAt(0)
        ? (a.charCodeAt(1) || 44) - (b.charCodeAt(1) || 44)
        : a.charCodeAt(0) - b.charCodeAt(0)
    )
    return grades
  }

  showGradeDistributionGraph = () => {
    if (this.props.gradePerformerObject.gradeGraph) {
      if (myGraph != null) {
        myGraph.destroy()
      }
      myGraph = new Chart(this.gradeDistributionGraph, {
        type: "bar",
        data: {
          labels:
            this.props.gradePerformerObject &&
            this.sortGrades(this.props.gradePerformerObject),
          datasets: [
            {
              backgroundColor: this.props.gradePerformerObject.gradeGraph
                .backgroundColor,
              label: "Count",
              barPercentage: 0.5,
              data: this.props.gradePerformerObject.gradeGraph.data,
            },
          ],
        },
        options: {
          responsive: true,
          legend: {
            display: false,
            position: "bottom",
            labels: {
              fontSize: 10,
              fontColor: "white",
            },
          },
          scales: {
            xAxes: [
              {
                barPercentage: 0.3,
                ticks: {
                  fontColor: "#FFFFFF",
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  display: false,
                  beginAtZero: true,
                },
                gridLines: {
                  display: false,
                },
              },
            ],
          },
        },
      })
    }
  }

  onChangeSelectedExam = e => {
    this.setState({ selectedExam: e.target.value }, () => {
      if (myGraph != null) {
        myGraph.destroy()
      }
      let alreadySelectedIndex = this.props.examList.findIndex(
        item => item.id === this.state.selectedExam
      )
      if (alreadySelectedIndex !== -1) {
        this.setState({
          faName: this.props.examList[alreadySelectedIndex].value,
          subjectName: this.props.teachersSubjectList[0].label,
          //changed here
        })
      }
      this.onChangeQuickAnalyticsReport(
        this.state.selectedExam,
        this.state.selectedClass
      )
      // this.getQuickAnalyticsReport(this.state.selectedSubject, this.state.selectedExam, this.state.selectedClass);
    })
  }

  onChangeSelectedClass = e => {
    this.setState({ selectedClass: e.target.value }, () => {
      if (myGraph != null) {
        myGraph.destroy()
      }
      let alreadySelectedIndex = this.props.classes.findIndex(
        item => item.id === this.state.selectedClass
      )
      if (alreadySelectedIndex !== -1) {
        this.setState({
          className: this.props.classes[alreadySelectedIndex].value,
        })
      }
      this.onChangeQuickAnalyticsReport(
        this.state.selectedExam,
        this.state.selectedClass
      )
      // this.getQuickAnalyticsReport(this.state.selectedSubject, this.state.selectedExam, this.state.selectedClass);
    })
  }

  onChangeSelectedSubject = e => {
    this.setState({ selectedSubject: e.target.value }, () => {
      if (myGraph != null) {
        myGraph.destroy()
      }
      let alreadySelectedIndex = this.props.teachersSubjectList.findIndex(
        item => item.id === this.state.selectedSubject
      )
      if (alreadySelectedIndex !== -1) {
        this.setState({
          subjectName: this.props.teachersSubjectList[alreadySelectedIndex]
            .value,
        })
      }
      this.getQuickAnalyticsReport(
        this.state.selectedSubject,
        this.state.selectedExam,
        this.state.selectedClass
      )
    })
  }

  render() {
    return (
      <div>
        {this.props.isFromPerformanceReport && (
          <div className="row mr-5">
            <div className="p-2">
              <select
                className="form-control mr-3 mb-2"
                value={this.state.selectedExam}
                onChange={this.onChangeSelectedExam}
              >
                {this.props.examList &&
                  this.props.examList.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.value}
                    </option>
                  ))}
              </select>
            </div>
            <div className="p-2">
              <select
                className="form-control"
                value={this.state.selectedClass}
                onChange={this.onChangeSelectedClass}
              >
                {this.props.classes &&
                  this.props.classes.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.value}
                    </option>
                  ))}
              </select>
            </div>
            <div className="p-2">
              <select
                className="form-control mr-3 mb-2"
                value={this.state.selectedSubject}
                onChange={this.onChangeSelectedSubject}
              >
                {this.props.teachersSubjectList &&
                  this.props.teachersSubjectList.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.value}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        )}

        {this.state.isShowLoading ? (
          <div
            className="d-flex justify-content-center"
            style={{ position: "absolute", left: "50%", top: "50%" }}
          >
            <HashLoader
              sizeUnit="px"
              loading={this.state.isShowLoading}
              color="#084353"
              size={35}
            />
          </div>
        ) : !this.state.isShowNoDateFound ? (
          <div>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <h1 className="text-theme fw-1000">{this.state.className}</h1>
                <div className="border-theme-light border-right-theme mx-3" />
                <div>
                  <h3 className="text-theme fw-500 m-0">
                    {this.state.faName} - {this.state.subjectName}
                  </h3>
                  <small>
                    <b className="text-theme">
                      MAX.MARK:{" "}
                      {this.props.studentsPerformanceMarkBySubjectDetails
                        .markInfo &&
                        this.props.studentsPerformanceMarkBySubjectDetails
                          .markInfo.maxMark}{" "}
                      | PASS MARK:{" "}
                      {this.props.studentsPerformanceMarkBySubjectDetails
                        .markInfo &&
                        this.props.studentsPerformanceMarkBySubjectDetails
                          .markInfo.passMark}
                    </b>
                  </small>
                </div>
              </div>
              <div className="d-flex">
                <div className="text-center">
                  <div className="d-flex justify-content-center">
                    <div className="theme-rounded">
                      {this.props.studentsPerformanceMarkBySubjectDetails
                        .classInfo &&
                        this.props.studentsPerformanceMarkBySubjectDetails
                          .classInfo.total}
                    </div>
                  </div>
                  <small>
                    <b>TOTAL</b>
                  </small>
                </div>
                <div className="text-center mx-3">
                  <div className="d-flex justify-content-center">
                    <div className="theme-rounded">
                      {this.props.studentsPerformanceMarkBySubjectDetails
                        .classInfo &&
                        this.props.studentsPerformanceMarkBySubjectDetails
                          .classInfo.attended}
                    </div>
                  </div>
                  <small>
                    <b>ATTENDED</b>
                  </small>
                </div>
                <div className="text-center">
                  <div className="d-flex justify-content-center">
                    <div className="theme-rounded">
                      {this.props.studentsPerformanceMarkBySubjectDetails
                        .classInfo &&
                        this.props.studentsPerformanceMarkBySubjectDetails
                          .classInfo.absent}
                    </div>
                  </div>
                  <small>
                    <b>ABSENT</b>
                  </small>
                </div>
              </div>
            </div>

            <div className="d-flex bg-theme-light p-3 mt-3 border-bottom-0 bt-10">
              <div className="d-flex justify-content-between col-sm-9">
                <div
                  className="bg-theme-verylight text-center p-3 br-10 text-light col-sm-2"
                  style={{ borderRadius: 30 }}
                >
                  <div style={{ color: "#B6D4DB" }}>Avg.Grade</div>
                  <div className="d-flex justify-content-center">
                    <div
                      className="grade-b1"
                      style={{
                        backgroundColor: this.props
                          .studentsPerformanceMarkBySubjectDetails.averageGrade
                          ? this.props.studentsPerformanceMarkBySubjectDetails
                              .averageGrade.gradeColor
                          : "",
                      }}
                    >
                      {this.props.studentsPerformanceMarkBySubjectDetails
                        .averageGrade
                        ? this.props.studentsPerformanceMarkBySubjectDetails
                            .averageGrade.grade
                        : ""}
                    </div>
                  </div>
                </div>

                {this.props.studentsPerformanceMarkBySubjectDetails.mark &&
                  this.props.studentsPerformanceMarkBySubjectDetails.mark.map(
                    (item, i) => (
                      <div
                        key={i}
                        style={{ borderRadius: 30 }}
                        className="bg-theme-verylight text-center p-3 br-10 text-light d-flex justify-content-between ml-3 col-sm-3"
                      >
                        <div style={{ width: "60px", height: "60px" }}>
                          <CircularProgressbar
                            value={item.percentage}
                            text={`${item.percentage}%`}
                            styles={buildStyles({
                              pathColor: `#0fb293`,
                              textColor: "#ffffff",
                              trailColor: "rgba(71, 153, 151, .22)",
                              textSize: `${String(item.percent).length === 1 &&
                                "23px"}`,
                            })}
                          />
                        </div>
                        <div className="d-flex flex-column justify-content-center ml-3">
                          <div style={{ color: "#B6D4DB" }}>{item.name}</div>
                          <div className="fs-20">{item.mark}</div>
                        </div>
                      </div>
                    )
                  )}
              </div>

              <div className="p-2 br-10 col-sm">
                <UncontrolledTooltip target="progress-tooltip">
                  <div className="text-center d-flex justify-content-around fs-12 font-weight-bold text-light">
                    <span>
                      <span className="text-light">
                        {"Pass: "}
                        {this.props.studentsPerformanceMarkBySubjectDetails
                          .passFailPercentage &&
                          this.props.studentsPerformanceMarkBySubjectDetails
                            .passFailPercentage.passPercentage}
                        %
                      </span>
                    </span>
                    <span>
                      <span className="text-light ml-2">
                        {"Fail: "}
                        {this.props.studentsPerformanceMarkBySubjectDetails
                          .passFailPercentage &&
                          this.props.studentsPerformanceMarkBySubjectDetails
                            .passFailPercentage.failPercentage}
                        %
                      </span>
                    </span>
                  </div>
                </UncontrolledTooltip>

                <div id="progress-tooltip">
                  <Progress
                    percent={
                      this.props.studentsPerformanceMarkBySubjectDetails
                        .passFailPercentage &&
                      this.props.studentsPerformanceMarkBySubjectDetails
                        .passFailPercentage.passPercentage
                    }
                    status="active"
                    className="my-2"
                    symbolClassName="d-none"
                    theme={{
                      active: {
                        symbol: "-",
                        trailColor: "#f77272",
                        color: "#0fb293",
                      },
                    }}
                  />
                </div>
                <div className="text-center d-flex justify-content-around fs-12 font-weight-bold text-light">
                  <span>
                    <i
                      className="fas fa-circle mr-1"
                      style={{ color: "#0fb293" }}
                    ></i>
                    Pass
                  </span>
                  <span>
                    <i
                      className="fas fa-circle mr-1"
                      style={{ color: "#f77272" }}
                    ></i>
                    Fail
                  </span>
                </div>
                <div className="text-center d-flex justify-content-around fs-12 font-weight-bold text-light">
                  <span>
                    <span className="text-light ml-3 pl-3">
                      {this.props.studentsPerformanceMarkBySubjectDetails
                        .passFailPercentage &&
                        this.props.studentsPerformanceMarkBySubjectDetails
                          .passFailPercentage.passCount}
                    </span>
                  </span>
                  <span>
                    <span className="text-light ml-3 pl-3">
                      {this.props.studentsPerformanceMarkBySubjectDetails
                        .passFailPercentage &&
                        this.props.studentsPerformanceMarkBySubjectDetails
                          .passFailPercentage.failCount}
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <div className="bg-theme-light p-3 bb-10 d-flex justify-content-between">
              <div className="bg-theme-verylight p-3 br-10 col-sm-4 mx-3 text-light d-flex flex-column">
                <div className="d-flex">
                  <h6 style={{ color: "#B6D4DB" }}>
                    <i className="fas fa-trophy mr-2 award-color" />
                    {this.props?.studentsPerformerObject?.topPerformers
                      ?.length > 0
                      ? ""
                      : "NO"}{" "}
                    TOP PERFORMERS
                  </h6>
                </div>

                <div className="text-left table-responsive mt-2">
                  <table className="table table-borderless text-light">
                    <tbody>
                      {this.props.studentsPerformerObject.topPerformers &&
                        this.props.studentsPerformerObject.topPerformers
                          .filter((f, i) => i < 3)
                          .map((item, i) => (
                            <tr>
                              <td style={{ width: "15rem" }}>
                                <div>{item.studentName}</div>
                              </td>
                              <td>
                                <div>
                                  {item.mark} <small>out of</small>{" "}
                                  {item.outOffMark}{" "}
                                  <small>
                                    ({String(item.percentage).slice(0, 5)}%)
                                  </small>
                                </div>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>

                  {this.props.studentsPerformerObject.topPerformers &&
                    this.props.studentsPerformerObject.topPerformers.length >
                      3 && (
                      <div
                        style={{
                          fontSize: "12px",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                        onClick={() => {
                          this.setState({
                            isShowModal: true,
                            modalHeading: "TOP PERFORMERS",
                            modalStudentList: this.props.studentsPerformerObject
                              .topPerformers,
                          })
                        }}
                      >
                        VIEW MORE
                      </div>
                    )}
                </div>

                <div className="d-flex mt-3">
                  <h6 style={{ color: "#B6D4DB" }}>
                    {" "}
                    <i className="fas fa-exclamation-triangle mr-2 attention-color"></i>
                    ATTENTION NEEDED
                  </h6>
                </div>

                <div className="text-left table-responsive mt-2">
                  <table className="table table-borderless text-light">
                    <tbody>
                      {this.props.studentsPerformerObject
                        .attentionNeedStudents &&
                        this.props.studentsPerformerObject.attentionNeedStudents
                          .filter((f, i) => i < 3)
                          .map((item, i) => (
                            <tr key={i}>
                              <td style={{ width: "15rem" }}>
                                <div>{item.studentName}</div>
                              </td>
                              <td>
                                <div>
                                  {item.mark} <small>out of</small>{" "}
                                  {item.outOffMark}{" "}
                                  <small>
                                    ({String(item.percentage).slice(0, 5)}%)
                                  </small>
                                </div>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>

                  {this.props.studentsPerformerObject.attentionNeedStudents &&
                    this.props.studentsPerformerObject.attentionNeedStudents
                      .length > 3 && (
                      <div
                        style={{ fontSize: "12px" }}
                        onClick={() => {
                          this.setState({
                            isShowModal: true,
                            modalHeading: "ATTENTION NEEDED",
                            modalStudentList: this.props.studentsPerformerObject
                              .attentionNeedStudents,
                          })
                        }}
                      >
                        VIEW MORE
                      </div>
                    )}
                </div>
              </div>

              <div className="bg-theme-verylight p-2 br-10 col-sm mr-3 d-flex flex-column justify-content-between">
                <h6
                  style={{ color: "#B6D4DB" }}
                  className="text-right text-light p-2"
                >
                  GRADE DISTRIBUTION
                </h6>
                <div className="mt-3">
                  <canvas
                    ref={gradeDistributionGraph =>
                      (this.gradeDistributionGraph = gradeDistributionGraph)
                    }
                  />
                </div>
              </div>

              {/* <div className="bg-theme-verylight p-2 br-10 col-sm-2 d-flex flex-column justify-content-around text-center"> */}
              <div className="bg-theme-verylight br-10 col-sm-2 text-center">
                {this.props.gradePerformerObject.grades &&
                  this.props.gradePerformerObject.grades
                    .sort((a, b) =>
                      parseInt(a.gradeFrom) < parseInt(b.gradeFrom)
                        ? 1
                        : parseInt(a.gradeFrom) > parseInt(b.gradeFrom)
                        ? -1
                        : 0
                    )
                    .map((item, i) => (
                      <table
                        key={i}
                        className="table table-borderless table-sm"
                        style={{ marginBottom: "0px" }}
                      >
                        <tbody>
                          <tr>
                            <td></td>
                            <td>
                              <div
                                className="grade-a1 col"
                                style={{ backgroundColor: item.gradeColor }}
                              >
                                {item.grade}
                              </div>
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              <div className="col" style={{ color: "#B6D4DB" }}>
                                {item.gradeFrom} - {item.gradeTo}
                              </div>
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    ))}
              </div>
            </div>

            {/* <div className="d-flex justify-content-end my-3">
           <button type="button" className="btn btn-info br-10"  style={{background: '#084353'}}onClick={()=>{
             this.setState({isShowViewData: true})
           }}>
             VIEW MORE
           </button>
         </div> */}

            {this.state.isShowViewData ? (
              <div>
                <div className="d-flex justify-content-between my-4">
                  <div className="w-50">
                    <label>PERCENT SLIDER</label>
                    <Range
                      step={0.1}
                      min={0}
                      max={100}
                      values={this.state.studentPerformanceRange}
                      onChange={studentPerformanceRange => {
                        this.setState({ studentPerformanceRange })
                        this.customFilter(studentPerformanceRange)
                      }}
                      renderTrack={({ props, children }) => (
                        <div
                          onMouseDown={props.onMouseDown}
                          onTouchStart={props.onTouchStart}
                          style={{
                            ...props.style,
                            height: "36px",
                            display: "flex",
                            width: "100%",
                          }}
                        >
                          <div
                            ref={props.ref}
                            style={{
                              height: "5px",
                              width: "50%",
                              borderRadius: "4px",
                              background: "#00434c",
                            }}
                          >
                            {children}
                          </div>
                        </div>
                      )}
                      renderThumb={({ index, props }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: "20px",
                            width: "20px",
                            borderRadius: "50%",
                            display: "flex",
                            backgroundColor: "#479997",
                            justifyContent: "center",
                            color: "white",
                            fontSize: 10,
                            alignItems: "center",
                            boxShadow: "0px 2px 6px #AAA",
                          }}
                        >
                          {Math.round(
                            this.state.studentPerformanceRange[index].toFixed(1)
                          )}
                        </div>
                      )}
                    />
                  </div>

                  {/* <div>
                 <form className="navbar-form" role="search">
                   <div className="input-group">
                     <input
                       type="text"
                       className="form-control"
                       placeholder="Search"
                     />
                     <div className="input-group-btn">
                       <button className="btn btn-theme" type="button">
                         <i className="fal fa-search" />
                       </button>
                     </div>
                   </div>
                 </form>
               </div> */}
                </div>

                <div className="d-flex justify-content-end mb-3">
                  <div className="bg-theme-light p-2 table-top-exclam">
                    <i className="fas fa-exclamation table-top-exclam-color" />
                  </div>
                  <div className="bg-lightblue-td br-10 px-2 py-1">
                    {this.state.sliderTotalStudent} of {this.state.totalStudent}{" "}
                    students have secured marks between{" "}
                    {this.state.startSliderValue}% and{" "}
                    {this.state.endSliderValue}%
                  </div>
                </div>

                <div className="table-responsive mb-4">
                  <table className="table table-borderless" cellPadding="3">
                    <thead>
                      <tr className="text-light">
                        <th
                          scope="col"
                          className="first-child-curved text-center"
                        >
                          ROLL NO
                        </th>
                        <th
                          scope="col"
                          className="bg-theme-light text-center cursor-pointer"
                          onClick={() => {
                            this.sortStudentListBy("name", "sortOrderByNames")
                          }}
                        >
                          NAME
                          {this.state.sortOrderByNames === 1 ? (
                            <i className="fas fa-caret-up ml-3" />
                          ) : this.state.sortOrderByNames === -1 ? (
                            <i className="fas fa-caret-down ml-3" />
                          ) : (
                            <i className="fas fa-sort ml-3" />
                          )}
                        </th>
                        <th scope="col" className="bg-theme-light text-center">
                          SECTION
                        </th>
                        <th
                          scope="col"
                          className="bg-theme-light text-center cursor-pointer"
                          onClick={() => {
                            this.sortStudentListBy("mark", "sortOrderByMarks")
                          }}
                        >
                          MARKS <small>out of {this.state.outOffMark}</small>
                          {this.state.sortOrderByMarks === 1 ? (
                            <i className="fas fa-caret-up ml-3" />
                          ) : this.state.sortOrderByMarks === -1 ? (
                            <i className="fas fa-caret-down ml-3" />
                          ) : (
                            <i className="fas fa-sort ml-3" />
                          )}
                        </th>
                        <th scope="col" className="bg-theme-light text-center">
                          PERCENTAGE(%)
                        </th>
                        <th
                          scope="col"
                          className="bg-theme-light text-center cursor-pointer"
                          // onClick={() => {
                          //   this.sortStudentListBy("grade", "sortOrderByGrades")
                          // }}
                        >
                          GRADE
                          {/* {this.state.sortOrderByGrades === 1 ? (
                             <i className="fas fa-caret-up ml-3" />
                           ) : this.state.sortOrderByGrades === -1 ? (
                             <i className="fas fa-caret-down ml-3" />
                           ) : (
                             <i className="fas fa-sort ml-3" />
                           )} */}
                        </th>
                        <th
                          scope="col"
                          className="bg-theme-light text-center cursor-pointer"
                          onClick={() => {
                            this.sortStudentListBy("rank", "sortOrderByRanks")
                          }}
                        >
                          RANK
                          {this.state.sortOrderByRanks === 1 ? (
                            <i className="fas fa-caret-up ml-3" />
                          ) : this.state.sortOrderByRanks === -1 ? (
                            <i className="fas fa-caret-down ml-3" />
                          ) : (
                            <i className="fas fa-sort ml-3" />
                          )}
                        </th>
                        <th
                          scope="col"
                          className="last-child-curved text-center"
                        >
                          SCHOOL RANK
                        </th>
                      </tr>
                    </thead>

                    {!this.state.isShowLoadingStudentPerformance ? (
                      <tbody>
                        {this.state.sortedStudentsArray &&
                          this.state.sortedStudentsArray.map((item, index) => (
                            <>
                              <div className="m-2"></div>
                              <tr>
                                <td className="first-child-curved-td text-center">
                                  {item.rollNo}
                                </td>
                                <td className="bg-lightblue-td text-left">
                                  <span className="ml-5">{item.name}</span>
                                </td>
                                <td className="bg-lightblue-td text-center">
                                  {item.section}
                                </td>
                                <td className="bg-lightblue-td text-center">
                                  {item.mark}
                                </td>
                                <td className="bg-lightblue-td text-center">
                                  {String(item.percentage).slice(0, 5)}
                                </td>
                                <td className="d-flex justify-content-center bg-lightblue-td text-center">
                                  <div
                                    className="grade-a1 fs-12"
                                    style={{
                                      background: item.gradeColor,
                                      padding: 3,
                                      height: 22,
                                      width: 22,
                                    }}
                                  >
                                    {item.grade}
                                  </div>
                                </td>
                                <td className="bg-lightblue-td text-center">
                                  <span>
                                    {item.rank}
                                    {/* {item.rankStatus === "increase" ? (
                           <i className="fas fa-caret-up ml-2 text-success" />
                         ) : (
                           <i className="fas fa-caret-down ml-2 text-danger" />
                         )} */}
                                  </span>
                                </td>
                                <td className="last-child-curved-td text-center">
                                  <span>
                                    {item.classRank}
                                    {/* {item.schoolRankStatus === "increase" ? (
                           <i className="fas fa-caret-up ml-2 text-success" />
                         ) : (
                           <i className="fas fa-caret-down ml-2 text-danger" />
                         )} */}
                                  </span>
                                </td>
                              </tr>
                              <div className="m-2"></div>
                            </>
                          ))}
                      </tbody>
                    ) : null}
                  </table>

                  {!this.state.isShowLoadingStudentPerformance &&
                    this.state.sortedStudentsArray &&
                    this.state.sortedStudentsArray.length === 0 && (
                      <div className="d-flex justify-content-center">
                        No data found
                      </div>
                    )}

                  {this.state.isShowLoadingStudentPerformance && (
                    <Skeleton count={5} height={30} width="100%" />
                  )}
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="text-center" style={{ marginTop: 50 }}>
            <div style={{ marginTop: 20 }}>
              <b style={{ color: "#084353", fontSize: 24 }}>No Data Found</b>
            </div>
            <div className="d-flex justify-content-center">
              <img src={NoDataFound} alt="logo" width={400} height={400} />
            </div>
          </div>
        )}

        <ModalTransition>
          {this.state.isShowModal && (
            <Modal
              autoFocus={false}
              width="50%"
              heading={this.state.modalHeading}
              actions={[
                {
                  text: "Close",
                  onClick: () => this.setState({ isShowModal: false }),
                  style: { borderRadius: 10 },
                },
              ]}
            >
              <div className="container mt-4">
                <table className="table table-borderless text-dark">
                  <tbody>
                    {this.state.modalStudentList &&
                      this.state.modalStudentList.map((item, i) => (
                        <tr key={i}>
                          <td>{item.studentName}</td>
                          <td>{item.mark}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </Modal>
          )}
        </ModalTransition>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    subjectList: state.subjectDetailsState.subjectList,
    classes: state.takeAttendanceState.teacherClassList,
    examList: state.hierarchyState.examList,
    studentPerformanceReport:
      state.hierarchyState.studentPerformanceReportDetails,
    studentsPerformerObject: state.quickAnalyticsState.studentsPerformerObject,
    studentsPerformanceMarkBySubjectDetails:
      state.quickAnalyticsState.studentsPerformanceMarkBySubjectDetails,
    gradePerformerObject: state.quickAnalyticsState.gradePerformerObject,
    teachersSubjectList: state.performanceReportState.teachersSubjectList,
    displayStudentPerformanceReportDetails:
      state.hierarchyState.displayStudentPerformanceReportDetails,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getClassList,
      getSubjectsList,
      getExamListDetails,
      getStudentPerformanceReportDetails,
      getStudentsPerformanceMarkBySubjectDetails,
      getStudentsPerformersListBySubjectDetails,
      getGradePerformanceListBySubjectDetails,
      saveStudentPerformanceReportDetailsInStore,
      saveDisplayStudentPerformanceReportDetailsInStore,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  dispatchToProps
)(QuickAnalyticsComponent)
