/*************************************************
 * RedInk
 * @exports
 * @class TeachersScreen.js
 * @extends Component
 * @author Sasidharan // on 11/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { UncontrolledTooltip } from "reactstrap"
import { bindActionCreators } from "redux"
import { HashLoader } from "react-spinners"
import { connect } from "react-redux"
import { Input } from "antd"
import Select from "react-select"
import _ from "lodash"

import {
  getTeacherDetails,
  getTeacherList,
  saveTeacherDetails,
  updateTeacherDetails,
  deleteTeacherDetails,
  deleteAssignedClassDetails,
  getCheckClassTeacherAvailabilityDetails,
  getCheckClassAndSubjectAvailabilityDetails,
  getTeacherRemoteLogin,
  saveTeacherDetailsInStore,
} from "../../../actions/admin/TeacherDetailsAction"
import {
  getCheckEmailAvailableDetails,
  showNotification,
} from "../../../actions/CommonAction"
import { confirmAlertPopUp, validateEmail } from "../../../utils/Utility"
import { DANGER_TOAST, VALIDATION_MSG } from "../../../utils/Constants"
import { getSubjectDetails } from "../../../actions/admin/SubjectDetailsAction"
import { getClassDetails } from "../../../actions/admin/ClassDetailsAction"
import { URL_PATH } from "../../../config/urlPath"

import AppWrapper from "../Wrapper1"

import femalePic from "../../../assets/images/female.png"
import malePic from "../../../assets/images/men.png"

const colorStyle = {
  control: styles => ({ ...styles, borderColor: "#084353", borderWidth: 1 }),
  dropdownIndicator: styles => ({ ...styles, color: "#084353" }),
}

const CheckOnlyLetters = text => {
  var re = new RegExp(/^[A-Za-z. ]+$/)
  return text.match(re)
}

class TeachersScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      savedTeachers: true,
      firstName: "",
      lastName: "",
      teacherGender: "",
      teacherEmail: "",
      teacherContactNumber: "",
      teacherIsActive: true,
      teacherId: "",
      isLoadingSubjects: true,
      assignedSubjects: [],
      assignedClasses: [],
      replaceExistingTeacher: null,
      classTeacher: null,
      updateTeacherInput: false,
      replaceExistingTeacherList: [],
      subjectsList: [],
      classesList: [],
      assignedClassAndSubject: [{ assignedClass: {}, assignedSubject: {} }],
      classSubjects: [{ classId: "", subjectId: "" }],
      isShowLoading: false,
      alreadyExist: false,
      userList: [],
      coreSubjects: [],
      languageSubjects: [],
      otherSubjects: [],
    }
  }

  static getDerivedStateFromProps(props, state) {
    return {
      userList: props.teacherList,
    }
  }

  componentDidMount = () => {
    this.props.getTeacherList(() => {})
    this.props.getSubjectDetails({}, (success, response) => {
      if (success) {
        this.setState({
          coreSubjects: response.CORE ? response.CORE : [],
          languageSubjects: response.LANGUAGE ? response.LANGUAGE : [],
          otherSubjects: response.OTHER ? response.OTHER : [],
          isLoadingSubjects: false,
        })
      }
    })
    this.props.getClassDetails({}, () => {})
    this.getTeacher()
  }

  getTeacher = () => {
    this.setState({ isTeacherLoading: true }, () => {
      this.props.getTeacherDetails({}, (success, response) => {
        this.setState({ isTeacherLoading: false })
        if (success) {
          this.setState({ userDummyList: response })
        }
      })
    })
  }

  scrollToMyRef = () => {
    this.myRef.scrollIntoView({ block: "start", behavior: "smooth" })
  }

  cancelSubmit = () => {
    this.setState({
      hasErrorInEmail: false,
      hasError: false,
      savedTeachers: false,
      firstName: "",
      lastName: "",
      teacherGender: "",
      teacherEmail: "",
      teacherContactNumber: "",
      teacherIsActive: true,
      teacherId: "",
      assignedSubjects: [],
      assignedClasses: [],
      replaceExistingTeacher: null,
      classTeacher: null,
      updateTeacherInput: false,
      assignedClassAndSubject: [{ assignedClass: {}, assignedSubject: {} }],
      classSubjects: [{ classId: "", subjectId: "" }],
    })
  }

  updateTeacherInputData = teacherDetails => {
    this.setState({
      firstName: teacherDetails.firstName ? teacherDetails.firstName : "",
      lastName: teacherDetails.lastName ? teacherDetails.lastName : "",
      teacherGender: teacherDetails.gender,
      teacherEmail: teacherDetails.emailId,
      teacherContactNumber: teacherDetails.contactNumber,
      teacherIsActive: teacherDetails.inActive === 0 ? true : false,
      teacherId: teacherDetails.id,
      assignedSubjects: teacherDetails.assignedSubjects,
      assignedClasses: teacherDetails.assignedClasses,
      replaceExistingTeacher:
        Object.keys(teacherDetails.replacedFor).length === 0 &&
        teacherDetails.replacedFor.constructor === Object
          ? null
          : teacherDetails.replacedFor,
      classTeacher: teacherDetails.classTeacherFor,
      updateTeacherInput: true,
      savedTeachers: false,
      assignedClassAndSubject:
        teacherDetails.assignedClassAndSubject.length > 0
          ? teacherDetails.assignedClassAndSubject
          : [{ assignedClass: {}, assignedSubject: {} }],
      classSubjects:
        teacherDetails.classSubjects.length > 0
          ? teacherDetails.classSubjects
          : [{ classId: "", subjectId: "" }],
    })
  }

  /**
   * Delete a subject from db
   */
  deleteTeacherFromDb = item => {
    // confirmAlertPopUp(
    //   VALIDATION_MSG.DELETE_TITLE,
    //   VALIDATION_MSG.DELETE_CONTENT,
    //   yes => {
    //     if (yes) {
    //       this.props.deleteTeacherDetails({ id: item.id }, () => {})
    //     }
    //   }
    // )
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT,
      yes => {
        if (yes) {
          this.props.deleteTeacherDetails({ id: item.id }, () => {})
        }
      },
      {
        buttons: [],
        customUI: ({ onClose }) => {
          return (
            <div
              className="custom"
              style={{
                backgroundColor: "#fff",
                borderBottomRightRadius: "12px",
                borderBottomLeftRadius: "12px",
              }}
            >
              <div
                className="bg-brand text-light d-flex"
                style={{
                  padding: "0.5rem 2rem",
                  borderTopRightRadius: "12px",
                  borderTopLeftRadius: "12px",
                }}
              >
                <div className="mx-auto fs-18">Delete</div>
              </div>

              <div
                className="mt-3 "
                style={{
                  padding: "0.5rem 1.5rem",
                }}
              >
                <div className="text-muted fs-15">
                  Are you sure you want to delete?
                </div>
                <div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
                  <button
                    className="bg-brand text-light px-4 py-1 w-25 d-flex align-items-center justify-content-center yes-button"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      this.props.deleteTeacherDetails({ id: item.id }, () => {})
                      onClose()
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-brand text-light px-4 py-1 ml-4 w-25 d-flex align-items-center justify-content-center"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={onClose}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )
        },
      }
    )
  }

  /**
   * saveSubject
   */
  saveTeacher = () => {
    if (!this.state.alreadyExist) {
      if (this.state.classSubjects.length > 0) {
        let array = this.state.classSubjects
        // if (
        //   this.state.firstName !== "" &&
        //   this.state.teacherGender !== "" &&
        //   this.state.teacherIsActive !== ""
        // ) {

        if (this.state.firstName !== "") {
          if (!CheckOnlyLetters(this.state.firstName)) {
            this.props.showNotification(
              DANGER_TOAST,
              VALIDATION_MSG.SUCCESS,
              "Invalid First Name"
            )
            return
          }
        } else {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "First Name cannot be empty"
          )
          return
        }

        if (this.state.lastName !== "") {
          if (!CheckOnlyLetters(this.state.lastName)) {
            this.props.showNotification(
              DANGER_TOAST,
              VALIDATION_MSG.SUCCESS,
              "Invalid Last Name"
            )
            return
          }
        }

        if (this.state.teacherContactNumber !== "") {
          if (
            String(this.state.teacherContactNumber).length < 10 ||
            String(this.state.teacherContactNumber).length >= 15
          ) {
            this.props.showNotification(
              DANGER_TOAST,
              VALIDATION_MSG.SUCCESS,
              "Please enter a valid contact number"
            )
            return
          }
        } else {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Contact number cannot be empty"
          )
          return
        }

        if (this.state.teacherEmail !== "") {
          if (!validateEmail(this.state.teacherEmail)) {
            this.props.showNotification(
              DANGER_TOAST,
              VALIDATION_MSG.OOPS,
              "Invalid Email ID"
            )
            return
          }

          this.props.getCheckEmailAvailableDetails(
            this.state.teacherEmail,
            (success, response) => {
              if (response) {
                this.setState({ hasErrorInEmail: true })
                // this.props.showNotification(
                //   DANGER_TOAST,
                //   VALIDATION_MSG.OOPS,
                //   "Email id already exists on save."
                // )
                return
              }
            }
          )
        } else {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            "Email ID cannot be empty"
          )
          return
        }

        if (this.state.teacherGender === "") {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            "Gender cannot be empty"
          )
          return
        }

        let assignClassAndSubjectError = false
        for (let i = 0; i < array.length; i++) {
          if (array[i].subjectId === "" || array[i].classId === "") {
            assignClassAndSubjectError = true
            break
          }
        }
        if (this.state.classTeacher) {
          assignClassAndSubjectError = false
        }
        if (!assignClassAndSubjectError) {
          let assignError = false
          for (let i = 0; i < array.length - 1; i++) {
            for (let j = 1; j < array.length; j++) {
              if (i !== j) {
                if (
                  array[i].subjectId === array[j].subjectId &&
                  array[i].classId === array[j].classId
                ) {
                  assignError = true
                  break
                }
              }
            }
            if (assignError) break
          }
          if (!assignError) {
            if (!this.state.hasErrorInEmail) {
              let teacherObject = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                gender: this.state.teacherGender,
                emailId: this.state.teacherEmail,
                contactNumber: this.state.teacherContactNumber,
                inActive: this.state.teacherIsActive ? 0 : 1,
                replacedFor: this.state.replaceExistingTeacher,
                classTeacherFor: this.state.classTeacher,
                assignedClassAndSubject: this.state.classSubjects.filter(
                  item => item.classId !== "" && item.subjectId !== ""
                ),
              }
              this.setState(
                {
                  isShowLoading: true,
                },
                () => {
                  this.props.saveTeacherDetails(teacherObject, success => {
                    this.setState({
                      isShowLoading: false,
                    })
                    if (success) {
                      this.cancelSubmit()
                      this.setState({ savedTeachers: true })
                    }
                  })
                }
              )
            }
          } else {
            this.props.showNotification(
              DANGER_TOAST,
              VALIDATION_MSG.OOPS,
              "Class already assigned to subject"
            )
          }
        } else {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            "Class or subject not assigned"
          )
        }
        // } else {
        //   this.setState({ hasError: true })
        //   myLog("error", this.state.classTeacher)
        // }
      } else {
        this.setState({ hasError: true })
      }
    } else {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.OOPS,
        this.state.alreadyExistErrorMessage
      )
    }
  }

  updateCheckEmail = e => {
    if (e.target.value !== "") {
      this.props.getCheckEmailAvailableDetails(
        e.target.value,
        (success, response) => {
          if (response) {
            this.setState({ hasErrorInEmail: true })
            this.props.showNotification(
              DANGER_TOAST,
              VALIDATION_MSG.OOPS,
              "Email id already exists on update."
            )
            return
          }
        }
      )
    }
    return
  }

  updateTeacher = () => {
    if (!this.state.alreadyExist) {
      let array = this.state.classSubjects
      // if (
      //   this.state.firstName !== "" &&
      //   this.state.teacherGender !== "" &&
      //   this.state.teacherEmail !== "" &&
      //   this.state.teacherContactNumber !== "" &&
      //   this.state.teacherIsActive !== ""
      // ) {
      if (this.state.firstName !== "" && this.state.lastName !== null) {
        if (!CheckOnlyLetters(this.state.firstName)) {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Invalid First Name"
          )
          return
        }
      } else {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SUCCESS,
          "First Name cannot be empty"
        )
        return
      }

      if (
        this.state.teacherContactNumber !== "" &&
        this.state.teacherContactNumber !== null
      ) {
        if (String(this.state.teacherContactNumber).length !== 10) {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Please enter a valid contact number"
          )
          return
        }
      } else {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Contact number cannot be empty"
        )
        return
      }
      if (this.state.teacherEmail !== "" && this.state.teacherEmail !== null) {
        if (!validateEmail(this.state.teacherEmail)) {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            "Invalid Email ID"
          )
          return
        }
      } else {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Teacher Email ID cannot be empty"
        )
        return
      }

      if (this.state.lastName !== "") {
        if (!CheckOnlyLetters(this.state.lastName)) {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Invalid Last Name"
          )
          return
        }
      }

      if (this.state.teacherGender === "" || this.state.teacherGender === "") {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Gender cannot be empty"
        )
        return
      }

      let assignClassAndSubjectError = false
      for (let i = 0; i < array.length; i++) {
        if (array[i].subjectId === "" || array[i].classId === "") {
          assignClassAndSubjectError = true
          break
        }
      }
      if (this.state.classTeacher) {
        assignClassAndSubjectError = false
      }
      if (!assignClassAndSubjectError) {
        let assignError = false
        for (let i = 0; i < array.length - 1; i++) {
          for (let j = 1; j < array.length; j++) {
            if (i !== j) {
              if (
                array[i].subjectId === array[j].subjectId &&
                array[i].classId === array[j].classId
              ) {
                assignError = true
                break
              }
            }
          }
          if (assignError) break
        }
        if (!assignError) {
          if (!this.state.hasErrorInEmail) {
            let teacherObject = {
              id: this.state.teacherId,
              firstName: this.state.firstName,
              lastName: this.state.lastName === "" ? "" : this.state.lastName,
              gender: this.state.teacherGender,
              emailId: this.state.teacherEmail,
              contactNumber: this.state.teacherContactNumber,
              inActive: this.state.teacherIsActive ? 0 : 1,
              replacedFor: this.state.replaceExistingTeacher,
              classTeacherFor: this.state.classTeacher,
              assignedClassAndSubject: array.filter(
                item => item.classId !== "" && item.subjectId !== ""
              ),
            }
            this.setState(
              {
                isShowLoading: true,
              },
              () => {
                this.props.updateTeacherDetails(teacherObject, success => {
                  this.setState({
                    isShowLoading: false,
                  })
                  if (success) {
                    this.cancelSubmit()
                    this.setState({ savedTeachers: true })
                  }
                })
              }
            )
          }
        } else {
          this.props.showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.SUCCESS,
            "Class already assigned to subject"
          )
        }
      } else {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Class or subject not assigned"
        )
      }
      // } else {
      //   this.setState({ hasError: true })
      // }
    } else {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.OOPS,
        this.state.alreadyExistErrorMessage
      )
    }
  }

  /**
   * Add new subject
   */
  addSubject = index => {
    const tempARR = this.state.assignedClassAndSubject
    const tempClassSubjectARR = this.state.classSubjects
    let object = {
      assignedClass: {},
      assignedSubject: tempARR[index].assignedSubject,
    }
    tempARR.push(object)
    let classSubjectObject = {
      classId: "",
      subjectId:
        tempARR[index].assignedSubject.id !== undefined
          ? tempARR[index].assignedSubject.id
          : tempARR[index].assignedSubject.subjectId,
    }
    tempClassSubjectARR.push(classSubjectObject)
    this.setState({
      assignedClassAndSubject: tempARR,
      classSubjects: tempClassSubjectARR,
    })
  }
  /**
   * Delete a subject
   */
  deleteSubject = index => {
    this.setState({
      alreadyExist: false,
    })
    if (this.state.assignedClassAndSubject.length > 1) {
      // let arrSubject = this.state.assignedClassAndSubject
      if (
        this.state.assignedClassAndSubject[index].assignedId &&
        this.state.assignedClassAndSubject[index].assignedId !== ""
      ) {
        confirmAlertPopUp(
          VALIDATION_MSG.DELETE_TITLE,
          VALIDATION_MSG.DELETE_CONTENT,
          yes => {
            if (yes) {
              let object = {
                assignedId: this.state.assignedClassAndSubject[index]
                  .assignedId,
              }
              this.props.deleteAssignedClassDetails(object, () => {
                this.state.assignedClassAndSubject.splice(index, 1)
                this.state.classSubjects.splice(index, 1)
                this.setState({
                  assignedClassAndSubject: this.state.assignedClassAndSubject,
                  classSubjects: this.state.classSubjects,
                })
              })
            }
          },
          {
            customUI: ({ onClose }) => {
              return (
                <div
                  className="custom"
                  style={{
                    backgroundColor: "#fff",
                    borderBottomRightRadius: "12px",
                    borderBottomLeftRadius: "12px",
                  }}
                >
                  <div
                    className="bg-brand text-light d-flex"
                    style={{
                      padding: "0.5rem 2rem",
                      borderTopRightRadius: "12px",
                      borderTopLeftRadius: "12px",
                    }}
                  >
                    <div className="mx-auto" style={{ fontSize: "18px" }}>
                      Delete
                    </div>
                  </div>

                  <div
                    className="mt-3 "
                    style={{
                      padding: "0.5rem 1.5rem",
                    }}
                  >
                    <div className="text-muted" style={{ fontSize: "15px" }}>
                      Are you sure you want to delete?
                    </div>
                    <div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
                      <button
                        className="bg-brand text-light px-4 py-1 w-25 d-flex align-items-center justify-content-center yes-button"
                        style={{
                          borderRadius: "5px",
                        }}
                        onClick={() => {
                          let object = {
                            assignedId: this.state.assignedClassAndSubject[
                              index
                            ].assignedId,
                          }
                          this.props.deleteAssignedClassDetails(object, () => {
                            this.state.assignedClassAndSubject.splice(index, 1)
                            this.state.classSubjects.splice(index, 1)
                            this.setState({
                              assignedClassAndSubject: this.state
                                .assignedClassAndSubject,
                              classSubjects: this.state.classSubjects,
                            })
                          })
                          onClose()
                        }}
                      >
                        Yes
                      </button>
                      <button
                        className="bg-brand text-light px-4 py-1 ml-4 w-25 d-flex align-items-center justify-content-center"
                        style={{
                          borderRadius: "5px",
                        }}
                        onClick={onClose}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              )
            },
          }
        )
      } else {
        this.state.assignedClassAndSubject.splice(index, 1)
        this.state.classSubjects.splice(index, 1)
        this.setState({
          assignedClassAndSubject: this.state.assignedClassAndSubject,
          classSubjects: this.state.classSubjects,
        })
      }
    }
  }

  searchTeacher = event => {
    this.setState({ searchTeacher: event.target.value })
    let usersList = this.state.userDummyList
    let filteredArray =
      usersList.length &&
      usersList.filter(
        data =>
          JSON.stringify(data)
            .toLowerCase()
            .indexOf(event.target.value.toLowerCase()) !== -1
      )
    this.props.saveTeacherDetailsInStore(
      JSON.parse(JSON.stringify(filteredArray))
    )
    this.setState({ userList: JSON.parse(JSON.stringify(filteredArray)) })
  }

  sortOrder = data => {
    const classOrder = [
      "-",
      "JKG",
      "LKG",
      "SKG",
      "UKG",
      ...Array(12)
        .fill()
        .map((_, idx) => `${1 + idx}`),
    ]
    const teachers = data
    teachers.sort((a, b) => {
      const aClass = a.classTeacherFor.value?.split("-")[0] || "-"
      const aSection = a.classTeacherFor.value?.split("-")[1]
      const bClass = b.classTeacherFor.value?.split("-")[0] || "-"
      const bSection = b.classTeacherFor.value?.split("-")[1]
      if (aClass === bClass) return aSection < bSection ? -1 : 1
      else return classOrder.indexOf(aClass) - classOrder.indexOf(bClass)
    })
    teachers.forEach(teacher => {
      teacher.assignedClasses.sort((a, b) => {
        const aClass = a.value?.split("-")[0] || "-"
        const aSection = a.value?.split("-")[1]
        const bClass = b.value?.split("-")[0] || "-"
        const bSection = b.value?.split("-")[1]
        if (aClass === bClass) return aSection < bSection ? -1 : 1
        else return classOrder.indexOf(aClass) - classOrder.indexOf(bClass)
      })
    })
    return teachers
  }

  sortEditOrder = () => {
    const classOrder = [
      "-",
      "JKG",
      "LKG",
      "SKG",
      "UKG",
      ...Array(12)
        .fill()
        .map((_, idx) => `${1 + idx}`),
    ]
    const teachers = this.state.assignedClassAndSubject
    teachers.sort((a, b) => {
      const aClass = a.assignedClass.class?.split("-")[0] || "-"
      const aSection = a.assignedClass.class?.split("-")[1]
      const bClass = b.assignedClass.class?.split("-")[0] || "-"
      const bSection = b.assignedClass.class?.split("-")[1]
      if (aClass === bClass) return aSection < bSection ? -1 : 1
      else return classOrder.indexOf(aClass) - classOrder.indexOf(bClass)
    })
    return this.state.assignedClassAndSubject
  }

  render() {
    return (
      <AppWrapper
        showAdminProfileSidemenu
        showAdminBackToDashboard
        screenName={
          this.state.userList.length > 1 ? "Teacher Details" : "Teacher Detail"
        }
      >
        {this.state.isTeacherLoading ? (
          <HashLoader
            css={{
              position: "fixed",
              width: "100%",
              height: "100%",
              left: "0px",
              top: "0px",
            }}
            size={30}
            color="#084353"
            loading={this.state.isShowSaveLoading}
            sizeUnit="px"
          />
        ) : (
          <div
            className="card bg-white p-3 shadow"
            ref={refData => (this.myRef = refData)}
          >
            <span className="font-weight-bold fs-25">
              TEACHERS ({this.state.userList.length})
            </span>
            {!this.state.isLoadingSubjects &&
            this.state.coreSubjects &&
            this.state.coreSubjects.length === 0 &&
            this.state.languageSubjects &&
            this.state.languageSubjects.length === 0 &&
            this.state.otherSubjects &&
            this.state.otherSubjects.length === 0 ? (
              <div className="m-3 text-center mt-5">
                <div className="font-weight-bold">Subject's not available</div>
              </div>
            ) : (
              <div
                className={`container ${
                  this.state.savedTeachers ? "d-none" : ""
                }`}
              >
                <div>
                  <div className="form-row">
                    <div className="col-sm">
                      <div className="form-group w-100">
                        <small>
                          First Name <span style={{ color: "red" }}>*</span>
                        </small>
                        <input
                          id="admin-sp-td-firstname"
                          type="text"
                          className="form-control input-border-color"
                          value={this.state.firstName}
                          onChange={e =>
                            this.setState({ firstName: e.target.value })
                          }
                          onKeyDown={e => {
                            if (e.key === "Enter" && e.target.value) {
                              let el = document.getElementById(
                                "admin-sp-td-lastname"
                              )
                              el.focus()
                            }
                          }}
                        />
                        <div className="d-flex justify-content-center">
                          {this.state.firstName === "" &&
                            this.state.hasError && (
                              <p className="text-danger">
                                First Name is required.
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm">
                      <div className="form-group w-100">
                        <small>Last Name </small>
                        <input
                          id="admin-sp-td-lastname"
                          type="text"
                          className="form-control input-border-color"
                          value={this.state.lastName}
                          onChange={e =>
                            this.setState({ lastName: e.target.value })
                          }
                          onKeyDown={e => {
                            if (e.key === "Enter") {
                              let el = document.getElementById(
                                "admin-sp-td-gender"
                              )
                              el.focus()
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm">
                      <div className="form-group">
                        <small>
                          Gender <span style={{ color: "red" }}>*</span>
                        </small>
                        <select
                          id="admin-sp-td-gender"
                          required
                          className="form-control input-border-color"
                          value={this.state.teacherGender}
                          onChange={e =>
                            this.setState({ teacherGender: e.target.value })
                          }
                          onKeyDown={e => {
                            if (e.key === "Enter" && e.target.value) {
                              let el = document.getElementById(
                                "admin-sp-td-email"
                              )
                              el.focus()
                            }
                          }}
                        >
                          <option value="">Select</option>
                          <option value="M">Male</option>
                          <option value="F">Female</option>
                          <option value="O">Other</option>
                        </select>
                        <div className="d-flex justify-content-center">
                          {this.state.teacherGender === "" &&
                            this.state.hasError && (
                              <p className="text-danger">Gender is required.</p>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm">
                      <div className="form-group w-100">
                        <small>
                          Email Address <span style={{ color: "red" }}>*</span>
                        </small>
                        <input
                          id="admin-sp-td-email"
                          type="text"
                          className="form-control input-border-color"
                          value={this.state.teacherEmail}
                          onChange={e => {
                            this.setState({
                              teacherEmail: e.target.value,
                              hasErrorInEmail: false,
                            })
                            if (this.state.updateTeacherInput) {
                              this.updateCheckEmail(e)
                            }
                            // this.props.getCheckEmailAvailableDetails(
                            //   this.state.teacherEmail,
                            //   (success, response) => {
                            //     if (response) {
                            //       this.setState({ hasErrorInEmail: true })
                            //       this.props.showNotification(
                            //         DANGER_TOAST,
                            //         VALIDATION_MSG.OOPS,
                            //         "Email id already exists."
                            //       )
                            //     }
                            //   }
                            // )
                          }}
                          onKeyDown={e => {
                            if (e.key === "Enter" && e.target.value) {
                              let el = document.getElementById(
                                "admin-sp-td-contact"
                              )
                              el.focus()
                            }
                          }}
                        />
                        <div className="d-flex justify-content-center">
                          {this.state.teacherEmail === "" &&
                            this.state.hasError && (
                              <p className="text-danger">
                                Email Address is required.
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-sm-4">
                      <div className="form-group w-100">
                        <small>
                          Contact Number <span style={{ color: "red" }}>*</span>
                        </small>
                        <input
                          id="admin-sp-td-contact"
                          type="number"
                          className="form-control input-border-color"
                          value={this.state.teacherContactNumber}
                          onChange={e =>
                            this.setState({
                              teacherContactNumber: e.target.value,
                            })
                          }
                        />
                        <div className="d-flex justify-content-center">
                          {this.state.teacherContactNumber === "" &&
                            this.state.hasError && (
                              <p className="text-danger">
                                Contact Number is required.
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group w-100">
                        <small>Class Teacher (optional)</small>
                        <Select
                          placeholder="Select class"
                          name="classes"
                          value={this.state.classTeacher}
                          isClearable
                          options={this.props.classesList}
                          className="basic-multi-select mb-3"
                          classNamePrefix="select"
                          onChange={selectedOption => {
                            if (selectedOption !== null) {
                              this.setState({
                                classTeacher: selectedOption,
                                alreadyExist: false,
                              })
                              let object = {
                                sectionId: selectedOption.id,
                              }
                              this.props.getCheckClassTeacherAvailabilityDetails(
                                object,
                                (success, response) => {
                                  if (success && response.exists) {
                                    this.setState({
                                      alreadyExist: true,
                                      alreadyExistErrorMessage:
                                        response.responseMessage,
                                    })
                                  }
                                }
                              )
                            } else {
                              this.setState({
                                classTeacher: null,
                                alreadyExist: false,
                              })
                            }
                          }}
                          styles={colorStyle}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-sm">
                      <small>
                        Assign class & subject{" "}
                        <span style={{ color: "red" }}>*</span>
                      </small>
                      {this.state.assignedClassAndSubject &&
                        this.state.assignedClassAndSubject.map(
                          (item, index) => (
                            <div className="justify-content-start mb-1">
                              <div className="row">
                                <div className="col-sm-4">
                                  <div className="form-group">
                                    <Select
                                      required
                                      isDisabled={
                                        this.state.updateTeacherInput &&
                                        item.assignedId
                                      }
                                      placeholder="Select subjects"
                                      name="classes"
                                      isClearable
                                      value={item.assignedSubject}
                                      options={this.props.subjectsList}
                                      className="basic-multi-select mb-3"
                                      classNamePrefix="select"
                                      onChange={selectedOption => {
                                        // this.setState({ assignedSubjects: selectedOption })
                                        if (selectedOption !== null) {
                                          delete selectedOption.assignClass
                                          let array = this.state
                                            .assignedClassAndSubject
                                          array[
                                            index
                                          ].assignedSubject = selectedOption
                                          array[index].assignedClass = {}
                                          let tempClassSubjectARR = this.state
                                            .classSubjects
                                          tempClassSubjectARR[index].subjectId =
                                            selectedOption.id
                                          tempClassSubjectARR[index].classId =
                                            ""
                                          this.setState({
                                            alreadyExist: false,
                                            assignedClassAndSubject: array,
                                            classSubjects: tempClassSubjectARR,
                                          })
                                          // let object = {
                                          //   subjectId: selectedOption.id,
                                          //   sectionId:
                                          //     tempClassSubjectARR[index].classId,
                                          // }
                                          // this.props.getCheckClassAndSubjectAvailabilityDetails(
                                          //   object,
                                          //   (success, response) => {
                                          //     if (success && response.exists) {
                                          //       this.setState({
                                          //         alreadyExist: true,
                                          //         alreadyExistErrorMessage:
                                          //           response.responseMessage,
                                          //       })
                                          //     }
                                          //   }
                                          // )
                                        } else {
                                          let array = this.state
                                            .assignedClassAndSubject
                                          array[index].assignedSubject = {}
                                          let tempClassSubjectARR = this.state
                                            .classSubjects
                                          tempClassSubjectARR[index].subjectId =
                                            ""
                                          this.setState({
                                            alreadyExist: false,
                                            assignedClassAndSubject: array,
                                            classSubjects: tempClassSubjectARR,
                                          })
                                        }
                                      }}
                                      styles={colorStyle}
                                    />
                                  </div>
                                </div>
                                <p className="text-black fs-20">-</p>
                                <div className="col-sm-4">
                                  <div className="form-group w-100">
                                    <Select
                                      isDisabled={
                                        this.state.updateTeacherInput &&
                                        item.assignedId
                                      }
                                      placeholder="Select assigned classes"
                                      name="classes"
                                      isClearable
                                      value={item.assignedClass}
                                      options={this.props.classesList}
                                      className="basic-multi-select mb-3"
                                      classNamePrefix="select"
                                      onChange={selectedOption => {
                                        // this.setState({ assignedClasses: selectedOption })
                                        if (selectedOption !== null) {
                                          let array = this.state
                                            .assignedClassAndSubject
                                          array[
                                            index
                                          ].assignedClass = selectedOption
                                          let tempClassSubjectARR = this.state
                                            .classSubjects
                                          if (
                                            tempClassSubjectARR[index]
                                              .subjectId !== ""
                                          ) {
                                            this.setState({
                                              alreadyExist: false,
                                              assignedClassAndSubject: array,
                                              classSubjects: tempClassSubjectARR,
                                            })
                                            tempClassSubjectARR[index].classId =
                                              selectedOption.id
                                            let object = {
                                              subjectId:
                                                tempClassSubjectARR[index]
                                                  .subjectId,
                                              sectionId: selectedOption.id,
                                            }
                                            this.props.getCheckClassAndSubjectAvailabilityDetails(
                                              object,
                                              (success, response) => {
                                                if (
                                                  success &&
                                                  response.exists
                                                ) {
                                                  this.setState({
                                                    alreadyExist: true,
                                                    alreadyExistErrorMessage:
                                                      response.responseMessage,
                                                  })
                                                }
                                              }
                                            )
                                          } else {
                                            this.props.showNotification(
                                              DANGER_TOAST,
                                              VALIDATION_MSG.OOPS,
                                              "Select subject before class..."
                                            )
                                          }
                                        } else {
                                          let array = this.state
                                            .assignedClassAndSubject
                                          array[index].assignedClass = {}
                                          let tempClassSubjectARR = this.state
                                            .classSubjects
                                          tempClassSubjectARR[index].classId =
                                            ""
                                          this.setState({
                                            alreadyExist: false,
                                            assignedClassAndSubject: array,
                                            classSubjects: tempClassSubjectARR,
                                          })
                                        }
                                      }}
                                      styles={colorStyle}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm">
                                    <i
                                      className="fas fa-plus text-success p-2"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => this.addSubject(index)}
                                    />
                                    <i
                                      className="fas fa-trash-alt text-danger p-2"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => this.deleteSubject(index)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  </div>
                </div>
                <hr></hr>
                <h6 className="text-dark">Replace existing teacher</h6>
                <div className="form-row">
                  <div className="col-sm">
                    <div className="form-group w-100">
                      <small>Name</small>
                      <Select
                        placeholder="Select teacher"
                        name="classes"
                        isClearable
                        value={this.state.replaceExistingTeacher}
                        options={this.props.replaceExistingTeacherList}
                        className="basic-multi-select mb-3"
                        classNamePrefix="select"
                        onChange={selectedOption => {
                          if (selectedOption !== null) {
                            let array = []
                            let classSubjectArray = []
                            for (
                              let i = 0;
                              i < selectedOption.assignedClassAndSubject.length;
                              i++
                            ) {
                              array.push({
                                assignedClass:
                                  selectedOption.assignedClassAndSubject[i]
                                    .assignedClass,
                                assignedSubject:
                                  selectedOption.assignedClassAndSubject[i]
                                    .assignedSubject,
                              })
                              classSubjectArray.push({
                                classId:
                                  selectedOption.assignedClassAndSubject[i]
                                    .assignedClass.classId,
                                subjectId:
                                  selectedOption.assignedClassAndSubject[i]
                                    .assignedSubject.subjectId,
                              })
                            }
                            let newArrayToAssign = []
                            let newArrayToClassSubjets = []
                            for (
                              let i = 0;
                              i < this.state.assignedClassAndSubject.length;
                              i++
                            ) {
                              if (
                                !_.isEmpty(
                                  this.state.assignedClassAndSubject[i]
                                    .assignedClass
                                ) &&
                                !_.isEmpty(
                                  this.state.assignedClassAndSubject[i]
                                    .assignedSubject
                                )
                              ) {
                                newArrayToAssign.push(
                                  this.state.assignedClassAndSubject[i]
                                )
                              }
                            }
                            for (
                              let i = 0;
                              i < this.state.classSubjects.length;
                              i++
                            ) {
                              if (
                                this.state.classSubjects[i].subjectId !== "" &&
                                this.state.classSubjects[i].classId !== ""
                              ) {
                                newArrayToClassSubjets.push(
                                  this.state.classSubjects[i]
                                )
                              }
                            }
                            this.setState({
                              replaceExistingTeacher: selectedOption,
                              assignedClassAndSubject:
                                array.length > 0
                                  ? newArrayToAssign.concat(array)
                                  : [
                                      {
                                        assignedClass: {},
                                        assignedSubject: {},
                                      },
                                    ],
                              classSubjects:
                                classSubjectArray.length > 0
                                  ? newArrayToClassSubjets.concat(
                                      classSubjectArray
                                    )
                                  : [{ classId: "", subjectId: "" }],
                              classTeacher: selectedOption.classTeacherFor,
                            })
                          } else {
                            this.setState({
                              replaceExistingTeacher: null,
                            })
                          }
                        }}
                        styles={colorStyle}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="mr-2">
                    <div className="d-flex flex-column">
                      <small>Status</small>
                      <label className="switch">
                        <input
                          type="checkbox"
                          onChange={() =>
                            this.setState({
                              teacherIsActive: !this.state.teacherIsActive,
                            })
                          }
                          checked={this.state.teacherIsActive}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-secondary mt-3 mr-2 rounded-pill"
                      style={{
                        backgroundColor: "#DBDBDB",
                        color: "#06B191",
                        border: "none",
                      }}
                      onClick={() => {
                        this.cancelSubmit()
                        this.setState({
                          savedTeachers: true,
                        })
                        this.props.getTeacherDetails(
                          {},
                          (success, response) => {
                            this.setState({ isTeacherLoading: false })
                            if (success) {
                              this.setState({ userDummyList: response })
                            }
                          }
                        )
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      disabled={this.state.isShowLoading}
                      className="btn btn-success mt-3 rounded-pill"
                      style={{ background: "#0eb292", border: "none" }}
                      onClick={() => {
                        if (!this.state.updateTeacherInput) {
                          this.saveTeacher()
                        } else {
                          this.updateTeacher()
                        }
                      }}
                    >
                      {this.state.isShowLoading ? (
                        <HashLoader
                          size={16}
                          color="#fff"
                          loading={this.state.isShowLoading}
                          sizeUnit="px"
                        />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {/* Tables Starts */}
            <div className="container-fluid my-3">
              <div className="row">
                <div className="col-sm" style={{ marginLeft: "inherit" }}>
                  {/* <input
                    type="text"
                    className="px-3 py-2 shadow rounded-pill border-0 bg-lightgray teachersSearchBox"
                    placeholder="Search"
                    style={{ outlineWidth: 0 }}
                    value={this.state.searchTeacher}
                    onChange={e => {
                      this.searchTeacher(e)
                    }}
                  /> */}
                  <Input
                    placeholder="Search"
                    allowClear
                    className="px-3 py-2 shadow rounded-pill border-0 teachersSearchBox"
                    value={this.state.searchTeacher}
                    onChange={e => {
                      this.searchTeacher(e)
                    }}
                    style={{ width: "calc(100% - 350px)" }}
                  />
                </div>
                <div className="col-sm text-right">
                  <button
                    type="button"
                    className="btn btn-sm bg-brand text-light rounded-pill addTeacherButton "
                    id="teacher-tooltip"
                    onClick={() => {
                      this.cancelSubmit()
                      setTimeout(() => {
                        let el = document.getElementById(
                          "admin-sp-td-firstname"
                        )
                        el.focus()
                      }, 500)
                    }}
                  >
                    <i className="fas fa-plus mr-2" />
                    <UncontrolledTooltip
                      placement="top"
                      className="bg-danger"
                      target="teacher-tooltip"
                    >
                      Add a new teacher to the registry with their details
                    </UncontrolledTooltip>
                    Add Teacher
                  </button>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table table-hover table-borderless">
                <thead className="bg-theme-light text-white">
                  <tr>
                    <th scope="col" className="text-center">
                      Name
                    </th>
                    <th scope="col" className="text-center">
                      Gender
                    </th>
                    <th scope="col" className="text-center">
                      Class Teacher
                    </th>
                    <th scope="col" className="text-center">
                      Assigned Class
                    </th>
                    <th scope="col" className="text-center">
                      Assigned Subjects
                    </th>
                    <th scope="col" className="text-center">
                      Actions
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {this.state.userList &&
                    // this.state.userList
                    // .sort(function(a, b) {
                    //   if (
                    //     a.classTeacherFor.value !== undefined &&
                    //     b.classTeacherFor.value !== undefined
                    //   ) {
                    //     const a_val = a.classTeacherFor.value.split("-")
                    //     const b_val = b.classTeacherFor.value.split("-")
                    //     if (a_val.length > 0 && b_val.length > 0) {
                    //       return parseInt(a_val[0]) > parseInt(b_val[0])
                    //     } else {
                    //       return -1
                    //     }
                    //   }
                    //   return 1
                    // })
                    this.sortOrder(this.state.userList).map((item, index) => (
                      <tr key={index} style={{ height: "5.5rem" }}>
                        <td
                          className="row ml-4 mt-4 text-center"
                          onClick={() => {
                            this.props.history.push({
                              pathname: URL_PATH.ADMIN_TEACHER_PROFILE,
                              state: {
                                teacherId: item.id,
                              },
                            })
                          }}
                        >
                          <img
                            className="rounded-circle mr-2"
                            src={
                              item.gender === null
                                ? ""
                                : item.gender === "M"
                                ? malePic
                                : femalePic
                            }
                            alt=""
                            width={25}
                          />
                          <div className="cursor-pointer studentNameOnHover">
                            {item.teacherName}
                          </div>
                        </td>
                        <td
                          className="text-center"
                          style={{ verticalAlign: "middle" }}
                        >
                          {item.gender === null ? (
                            ""
                          ) : item.gender === "M" ? (
                            <i className="fas fa-male text-green"></i>
                          ) : (
                            <i className="fas fa-female text-red"></i>
                          )}
                        </td>
                        <td
                          className="text-center"
                          style={{ verticalAlign: "middle" }}
                        >
                          {item.classTeacherFor.value
                            ? item.classTeacherFor.value
                            : "-"}
                        </td>
                        <td
                          className="text-center"
                          style={{ verticalAlign: "middle" }}
                        >
                          {item.assignedClasses &&
                          item.assignedClasses.length > 0
                            ? item.assignedClasses
                                // .sort(function(a, b) {
                                //   if (
                                //     a.class !== undefined &&
                                //     b.class !== undefined
                                //   ) {
                                //     const a_val = a.class.split("-")
                                //     const b_val = b.class.split("-")
                                //     myLog(String(a_val[1]) > String(b_val[1]))
                                //       return String(a_val[1]) > String(b_val[1]) ? 1 : -1
                                //   }
                                //   return 1
                                // })
                                .map((classes, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="badge px-1 bg-theme-verylight ml-1"
                                    >
                                      {classes.class ? classes.class : "-"}
                                    </span>
                                  )
                                })
                            : "-"}
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          <div
                            className={`${
                              item.assignedSubjects.length === 0 ||
                              item.assignedSubjects.length === 1
                                ? "text-center"
                                : "d-flex flex-wrap"
                            }`}
                          >
                            {item.assignedSubjects &&
                            item.assignedSubjects.length > 0 ? (
                              item.assignedSubjects.map((subject, index) => {
                                return (
                                  <span className="bg-secondary p-1 rounded text-light my-1 mr-2">
                                    {String(subject.subjectName).split(" ")
                                      .length > 1
                                      ? String(subject.subjectName)
                                          .split(" ")[0]
                                          .slice(0, 3) +
                                        "." +
                                        String(subject.subjectName)
                                          .split(" ")[1]
                                          .slice(0, 2)
                                      : subject.subjectName}
                                  </span>
                                )
                              })
                            ) : (
                              <div> {"-"}</div>
                            )}
                          </div>
                        </td>
                        <td
                          className="text-center"
                          style={{ verticalAlign: "middle" }}
                        >
                          <button
                            className="badge badge-pill badge-secondary mr-2"
                            onClick={() => {
                              this.scrollToMyRef()
                              this.updateTeacherInputData(item)
                              this.setState({ updateTeacherInput: true })
                              setTimeout(() => {
                                let el = document.getElementById(
                                  "admin-sp-td-firstname"
                                )
                                el.focus()
                              }, 500)
                            }}
                          >
                            <small>
                              <i className="far fa-user-edit mr-1" />
                              Edit
                            </small>
                          </button>
                          <button
                            className="badge badge-pill badge-danger"
                            onClick={() => {
                              this.deleteTeacherFromDb(item)
                            }}
                          >
                            <small>
                              <i className="far fa-user-minus mr-1" />
                              Delete
                            </small>
                          </button>
                          {/* {item.inActive === 0 && (
                        <button
                          className="badge badge-pill badge-primary ml-2"
                          onClick={() => {
                            let object = {
                              userId: item.userId,
                            }
                            this.props.getTeacherRemoteLogin(
                              object,
                              (success, response) => {
                                if (success) {
                                  if (response.userInfo.role === 0)
                                    this.props.history.push(
                                      URL_PATH.FOUNDER
                                    )
                                  if (response.userInfo.role === 1)
                                    this.props.history.push(
                                      URL_PATH.SUPER_ADMIN
                                    )
                                  if (response.userInfo.role === 2)
                                    this.props.history.push(URL_PATH.ADMIN)
                                  if (response.userInfo.role === 3)
                                    this.props.history.push(
                                      URL_PATH.TEACHER
                                    )
                                  if (response.userInfo.role === 4)
                                    this.props.history.push(
                                      URL_PATH.TEACHER
                                    )
                                  if (response.userInfo.role === 5)
                                    this.props.history.push(URL_PATH.PARENT)
                                }
                              }
                            )
                          }}
                        >
                          <small>
                            <i className="far fa-user-edit mr-1" />
                            Login
                          </small>
                        </button>
                      )} */}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    subjectsList: state.subjectDetailsState.subjectList,
    classesList: state.classDetailsState.classList,
    replaceExistingTeacherList: state.teacherDetailsState.teacherDetail,
    teacherList: state.teacherDetailsState.teacherDetail,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getTeacherList,
      getTeacherDetails,
      saveTeacherDetails,
      updateTeacherDetails,
      deleteTeacherDetails,
      getClassDetails,
      getSubjectDetails,
      deleteAssignedClassDetails,
      getCheckEmailAvailableDetails,
      showNotification,
      getCheckClassTeacherAvailabilityDetails,
      getCheckClassAndSubjectAvailabilityDetails,
      getTeacherRemoteLogin,
      saveTeacherDetailsInStore,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(TeachersScreen)
