/*************************************************
 * RedInk
 * @exports
 * @class index.js
 * @extends Component
 * @author Sasidharan // on 01/02/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { changePassword } from "../../../actions/LoginAction"
import { VALIDATION_MSG, DANGER_TOAST } from "../../../utils/Constants"
import { showNotification } from "../../../actions/CommonAction"
import { myLog } from "../../../utils/Utility"

class ChangePassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newPassword: "",
      confirmNewPassword: "",
      isPasswordVisible: false,
      isConfirmPasswordVisible: false,
    }
  }

  componentDidMount = () => {}

  handleChange = event => {
    const { name, value } = event.target
    this.setState({
      [name]: value,
    })
    this.setState({ isInvalidPassword: false })
  }

  validatePassword = password => {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
    return re.test(password)
  }

  onSubmit = () => {
    myLog(this.state.newPassword, this.state.confirmNewPassword)
    if (this.state.newPassword.trim() !== "") {
      if (!this.validatePassword(this.state.newPassword)) {
        this.setState({ isInvalidPassword: true })
        return
      } else {
        this.setState({ isInvalidPassword: false })
      }
      if (this.state.newPassword === this.state.confirmNewPassword) {
        this.props.changePassword(
          this.state.newPassword,
          (success, response) => {
            if (success) {
              this.props.onNext()
              // this.setState({
              //   newPassword: "",
              //   confirmNewPassword: "",
              //   isPasswordVisible: false,
              //   isConfirmPasswordVisible: false,
              // })
            }
          }
        )
      } else {
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Password don't match"
        )
      }
    } else {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.OOPS,
        "Fill all fields"
      )
    }
  }

  render() {
    const { isInvalidPassword } = this.state
    return (
      <>
        <div></div>
        <div className="row justify-content-center align-items-center flex-wrap">
          <div
            className="col-md-6 d-flex align-items-center justify-content-start"
            style={{ minWidth: 250 }}
          >
            <img
              src={require("../../../assets/images/Character illustration - school details page.png")}
              style={{ width: 300, height: 380 }}
              alt=""
            />
          </div>
          <form className="form col-md-5" style={{ minWidth: 250 }}>
            <h3 className="text-brand font-weight-bold mb-5">
              Change Password
            </h3>
            <div className="form-group">
              <label className="text-brand font-weight-bold">
                New Password
              </label>
              {this.state.isPasswordVisible ? (
                <div
                  className="d-flex align-items-center"
                  style={{ position: "relative" }}
                >
                  <input
                    type="text"
                    name="newPassword"
                    onChange={this.handleChange}
                    value={this.state.newPassword}
                    className={`form-control ${
                      isInvalidPassword ? "is-invalid" : ""
                    }`}
                  />
                  <i
                    className="fas fa-eye-slash cursor-pointer text-dark"
                    style={{
                      position: "absolute",
                      left: "89%",
                      // top: "22%",
                    }}
                    aria-hidden="true"
                    onClick={() => this.setState({ isPasswordVisible: false })}
                  ></i>
                </div>
              ) : (
                <div
                  className="d-flex align-items-center"
                  style={{ position: "relative" }}
                >
                  <input
                    type="password"
                    name="newPassword"
                    onChange={this.handleChange}
                    value={this.state.newPassword}
                    className={`form-control ${
                      isInvalidPassword ? "is-invalid" : ""
                    }`}
                  />
                  <i
                    className="fas fa-eye cursor-pointer text-dark"
                    style={{
                      position: "absolute",
                      left: "89%",
                    }}
                    aria-hidden="true"
                    onClick={() => this.setState({ isPasswordVisible: true })}
                  ></i>
                </div>
              )}
            </div>
            <div className="form-group">
              <label className="text-brand font-weight-bold">
                Confirm New Password
              </label>

              <div
                className="d-flex align-items-center"
                style={{ position: "relative" }}
              >
                <input
                  type={
                    this.state.isConfirmPasswordVisible ? "text" : "password"
                  }
                  className="form-control"
                  name="confirmNewPassword"
                  onChange={this.handleChange}
                  value={this.state.confirmNewPassword}
                />
                <i
                  className={`fas ${
                    this.state.isConfirmPasswordVisible
                      ? "fa-eye-slash"
                      : "fa-eye"
                  } cursor-pointer text-dark`}
                  style={{
                    position: "absolute",
                    left: "89%",
                    // top: "22%",
                  }}
                  aria-hidden="true"
                  onClick={() =>
                    this.setState({
                      isConfirmPasswordVisible: !this.state
                        .isConfirmPasswordVisible,
                    })
                  }
                ></i>
              </div>
            </div>
            <div className="text-brand">
              <p>
                <strong>Password must contain</strong>
              </p>
              <p>Atleast 1 upper case letter (A-Z)</p>
              <p>At least 1 special character</p>
              <p>At least 1 number (0-9)</p>
              <p>At least 8 characters</p>
            </div>
          </form>
        </div>
        <button
          onClick={this.onSubmit}
          className="btn text-light d-flex align-items-center ml-auto"
          style={{ backgroundColor: "#04B08F", borderRadius: 12 }}
          disabled={this.state.isShowLoading || this.props.isNextStepLoading}
        >
          <span className="mr-2">
            {this.props.isLast ? "Save & Proceed" : "Next Step"}
          </span>
          <i className="fas fa-chevron-right" />
        </button>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      changePassword,
      showNotification,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(ChangePassword)
