/*************************************************
 * RedInk
 * @exports
 * @class DashboardAction.js
 * @extends Component
 * @author vijayakumar // on 05/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { ACTION_TYPES } from "../types"

export const saveAdminSchoolDetailsInStore = adminSchoolDetail => {
  return {
    type: ACTION_TYPES.ADMIN_DASBOARD_SCHOOL_DETAIL,
    payload: adminSchoolDetail,
  }
}

export const getSuperAdminSchoolInfoDetails = callBack => {
  return async dispatch => {
    try {
      // dispatch(saveStudentAttendanceReportListInStore([]));
      let response = {
        memberCounts: {
          students: 45,
          boy: 25,
          girl: 20,
          teachers: 7,
          male: 2,
          female: 5,
        },
      }
      //   const response = await Client.get(URL.GET_DASHBOARD_ADMIN_SCHOOL_INFO, null, true)
      if (response) {
        myLog("====getSuperAdminSchoolInfoDetails RESPONSE===::::", response)
        dispatch(saveAdminSchoolDetailsInStore(response.memberCounts))
        callBack(true, response.memberCounts)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getSuperAdminSchoolInfoDetails ERROR===????", error)
    }
  }
}

export const getSuperAdminDashboardStudentAttendanceReportList = callBack => {
  return async dispatch => {
    try {
      //   const response = await Client.get(URL.GET_DASHBOARD_ADMIN_ATTENDANCE_REPORT, null, true)
      let response = {
        todaysSchoolAttendance: {
          absentCount: "40",
          presentCount: "100",
          unmarkedCount: "5",
          absentPercentage: "40",
          presentPercentage: "100",
          unmarkPercentage: "5",
        },
      }
      if (response) {
        myLog(
          "====getSuperAdminDashboardStudentAttendanceReportList RESPONSE===::::",
          response
        )
        callBack(true, response.todaysSchoolAttendance)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog(
        "====getSuperAdminDashboardStudentAttendanceReportList ERROR===????",
        error
      )
    }
  }
}

export const getSuperAdminDashboardTopPerformingStudentsDetails = callBack => {
  return async dispatch => {
    try {
      let response = {
        assessment: "FA1",
        topPerformers: [
          {
            studentName: "Jessie packer",
            className: "8-A",
            studentAssessmentMark: "485",
            totalAssessmentMark: "500",
            percentage: "72",
          },
        ],
      }
      // const response = await Client.get(URL.GET_DASHBOARD_TEACHER_PERFORMERS, null, true)
      if (response) {
        myLog(
          "====getSuperAdminDashboardTopPerformingStudentsDetails RESPONSE===::::",
          response
        )
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog(
        "====getSuperAdminDashboardTopPerformingStudentsDetails ERROR===????",
        error
      )
    }
  }
}

export const getSuperAdminDashboardSchoolPerformanceByClassDetails = callBack => {
  return async dispatch => {
    try {
      let response = {
        labels: [
          "PRE PRIMARY AND PRIMARY",
          "MIDDLE",
          "SECONDARY AND HIGHER SECONDARY",
        ],
        data: [5, 2, 8],
        backgroundColor: ["#ea5a58", "#13af8f", "#64c2e9"],
      }
      //   const response = await Client.get(URL.GET_DASHBOARD_ADMIN_SCHOOL_PERFORMERS, null, true)
      if (response) {
        myLog(
          "====getSuperAdminDashboardSchoolPerformanceByClassDetails RESPONSE===::::",
          response
        )
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog(
        "====getSuperAdminDashboardSchoolPerformanceByClassDetails ERROR===????",
        error
      )
    }
  }
}

export const getSuperAdminDashboardSchoolPassPercentageDetails = callBack => {
  return async dispatch => {
    try {
      let response = {
        labels: ["School 1", "School 2", "School 3"],
        passData: [3, 4, 6],
        failData: [5, 4, 2],
      }
      // const response = await Client.get(URL.GET_DASHBOARD_TEACHER_CLASS_PERFORMANCE_BY_ASSESSMENT, null, true)
      if (response) {
        myLog(
          "====getSuperAdminDashboardSchoolPassPercentageDetails RESPONSE===::::",
          response
        )
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog(
        "====getSuperAdminDashboardSchoolPassPercentageDetails ERROR===????",
        error
      )
    }
  }
}

export const getSuperAdminDashboardBoardWisePerformanceDetails = callBack => {
  return async dispatch => {
    try {
      let response = {
        labels: ["Metric.", "CBSE", "IBSE"],
        data: [20, 17, 19],
      }
      // const response = await Client.get(URL.GET_DASHBOARD_TEACHER_CLASS_PERFORMANCE_BY_ASSESSMENT, null, true)
      if (response) {
        myLog(
          "====getSuperAdminDashboardBoardWisePerformanceDetails RESPONSE===::::",
          response
        )
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog(
        "====getSuperAdminDashboardBoardWisePerformanceDetails ERROR===????",
        error
      )
    }
  }
}

export const getSuperAdminDashboardTopPerformingTeachersDetails = callBack => {
  return async dispatch => {
    try {
      let response = {
        assessment: "FA1",
        topPerformers: [
          {
            teacherName: "Otto",
            schoolName: "School 1",
            subject: "English",
            percentage: "72",
          },
        ],
      }
      // const response = await Client.get(URL.GET_DASHBOARD_TEACHER_PERFORMERS, null, true)
      if (response) {
        myLog(
          "====getSuperAdminDashboardTopPerformingTeachersDetails RESPONSE===::::",
          response
        )
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog(
        "====getSuperAdminDashboardTopPerformingTeachersDetails ERROR===????",
        error
      )
    }
  }
}

export const getSchoolDetails = callBack => {
  return async dispatch => {
    try {
      // let response = {
      //   teacherName: "Rexa",
      //   contactNumber: "999999999",
      //   emailId: "rexa@gmail.com",
      //   assigned: [
      //     { section: "12-c", subject: "Maths", color: "#f2aa3f50" },
      //     { section: "2-a", subject: "Maths", color: "#f1fa3f50" },
      //     { section: "10-d", subject: "Maths", color: "#519a3f50" },
      //     { section: "8-b", subject: "Maths", color: "#a2b5cb50" },
      //   ],
      // }
      const response = await Client.get(
        URL.GET_SCHOOL_DEATILS_SUPER_ADMIN,
        null,
        true
      )
      if (response) {
        myLog("====getSchoolDetailsAdmin RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getSchoolDetailsAdmin ERROR===????", error)
    }
  }
}
