import React from "react"

function Step1({
  setStep,
  classList,
  selectedSubject,
  setSelectedSubject,
  selectedClass,
  setSelectedClass,
}) {
  return (
    <>
      <div className="manual-quest-wrapper px-5 py-4 bg-F0F0F099 br-8px">
        <div className="mb-5">
          <div className="mb-2 fs-18 fw-600 clr-201F1F">Select Class</div>

          <div
            className="d-flex align-items-center flex-wrap"
            style={{ gap: "28px 40px" }}
          >
            {classList.map(c => (
              <button
                key={c.classId}
                className={`select-class-btn px-4 py-2 fs-15 br-5px ${
                  selectedClass === c.className
                    ? "active fw-500 clr-30876E"
                    : "bg-white clr-201F1F"
                }`}
                onClick={() => setSelectedClass(c.className)}
              >
                Class {c.className}
              </button>
            ))}
          </div>
        </div>

        <div>
          <div className="mb-2 fs-18 fw-600 clr-201F1F">Select Subject</div>

          <div className="d-flex flex-wrap gap-2rem mb-4">
            {!classList?.find(c => c.className === selectedClass) && (
              <p className="fs-17 fw-500 clr-848484 text-center mt-5 mx-auto">
                Choose Class
              </p>
            )}

            {classList
              ?.find(c => c.className === selectedClass)
              ?.subjects?.map(s => (
                <label
                  key={s.masterSubjectId}
                  className="d-flex align-items-center pl-4 pr-5 py-2 fs-15 clr-201F1F bg-white br-5px select-class-btn cursor-pointer"
                >
                  <input
                    className="subject-checkbox mr-4"
                    type="checkbox"
                    checked={selectedSubject.some(
                      selected => selected.masterSubjectId === s.masterSubjectId
                    )}
                    onChange={() =>
                      setSelectedSubject(prev =>
                        prev.some(
                          selected =>
                            selected.masterSubjectId === s.masterSubjectId
                        )
                          ? prev.filter(
                              p => p.masterSubjectId !== s.masterSubjectId
                            )
                          : [...prev, s]
                      )
                    }
                  />
                  {s?.subjectName}
                </label>
              ))}
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-between mt-4">
        <button
          onClick={() => setStep(2)}
          className="dis-grayed px-5 py-2 mx-auto fs-15 fw-500 bg-50AE93 text-white br-8px"
          disabled={!selectedClass || !selectedSubject.length}
        >
          Next
        </button>
      </div>
    </>
  )
}

export default Step1
