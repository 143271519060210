/*************************************************
 * RedInk
 * @exports
 * @class TakeAttendance.js
 * @extends Component
 * @author Sasidharan // on 01/02/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import Chart from "chart.js"
import moment from "moment"
import { Scrollbars } from "react-custom-scrollbars"
import AppWrapper from "../Wrapper"
import {
  saveAttendenceInStore,
  getClassList,
  getStudentPresentAndAbsentList,
} from "../../../actions/teacher/TakeAttendanceAction"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { URL_PATH } from "../../../config/urlPath"
import MessageModal from "../MessageModal"
import { myLog } from "../../../utils/Utility"

// const tooltipModifiers = {
//   flip: { behaviour: ["top"] },
//   preventOverflow: { boundariesElement: "viewport" },
// }

let myGraph = null

class ViewAttendance extends Component {
  static getDerivedStateFromProps(props, state) {
    return {
      classes: props.classList,
    }
  }

  constructor(props) {
    super(props)
    document.title = "Attendance | Redink"
    this.state = {
      takeAttendanceDate: "",
      classes: [],
      selectedClass: "",
      selectedClassName: "",
    }
  }

  componentDidMount = () => {
    this.setState({
      takeAttendanceDate: moment(new Date()).format("YYYY-MM-DD"),
    })
    this.getClassList()
  }

  getClassList = () => {
    this.props.getClassList((success, response) => {
      myLog(response)
      if (success) {
        this.setState(
          {
            selectedClass: response[0].id,
            selectedClassName: response[0].value,
          },
          () => this.getStudentPresentAndAbsentList()
        )
      }
    })
  }

  getStudentPresentAndAbsentList = () => {
    let params = {
      date: this.state.takeAttendanceDate,
      id: this.state.selectedClass,
    }
    myLog("params", params)
    this.props.getStudentPresentAndAbsentList(params, res => {
      this.showTakeAttendanceGraph()
    })
  }

  showTakeAttendanceGraph = () => {
    const attendanceGraph = this.attendanceGraph
    let presentCount = 0
    let absentCount = 0
    if (this.props.attedenceList.length > 0) {
      this.props.attedenceList &&
        this.props.attedenceList.forEach(item => {
          if (item.isPresent) {
            presentCount++
          }
          if (!item.isPresent) {
            absentCount++
          }
        })
      if (myGraph != null) {
        myGraph.destroy()
      }
      myGraph = new Chart(attendanceGraph, {
        type: "doughnut",
        data: {
          labels: ["Present", "Absent"],
          datasets: [
            {
              label: "Count",
              data: [presentCount, absentCount],
              backgroundColor: ["#479997", "#FF3059"],
              fill: false,
              borderWidth: 1,
            },
          ],
        },
        options: {
          legend: {
            display: true,
          },
        },
      })
    }
  }

  /**
   * Toggles attendance
   */
  toggleAttendance = index => {
    let attedenceList = this.props.attedenceList.slice()
    attedenceList[index].isPresent = !attedenceList[index].isPresent
    this.props.saveAttendenceInStore(attedenceList)
    this.showTakeAttendanceGraph()
  }

  updateAttendence = () => {
    myLog(this.state.takeAttendanceDate, this.state.selectedClassName)
    this.props.history.push({
      pathname: URL_PATH.TEACHER_ATTENDANCE,
      state: {
        dateName: this.state.takeAttendanceDate,
        className: this.state.selectedClassName,
      },
    })
  }

  /**
   * Handles message modal
   */
  closeMessageModal = () => this.setState({ isMessageModalOpen: false })

  /**
   * handles on click message icon in sidebar
   */
  onClickMessageIcon = () => {
    this.setState({ isMessageModalOpen: !this.state.isMessageModalOpen })
  }
  /**
   * handles message submit
   */
  onSubmitMessage = () => {
    this.closeMessageModal()
  }

  render() {
    let { attedenceList } = this.props
    return (
      <AppWrapper
        openMessageModal={() => this.onClickMessageIcon()}
        teacherAttendanceDashboard
        showTeacherThree
        showTeacherLogo
        screenName={"View Attendance"}
      >
        <MessageModal
          isModalOpen={this.state.isMessageModalOpen}
          closeModal={() => this.onClickMessageIcon()}
          onSubmitMessage={() => this.onSubmitMessage()}
        />
        <div className="row">
          <div className="col-sm-8">
            <div className="card mb-3">
              <div className="d-flex row justify-content-around px-3 py-2">
                <div className="col-sm">
                  <div className="form-group">
                    <small>Class</small>
                    <select
                      className="form-control"
                      name={this.state.selectedClass}
                      onChange={e => {
                        this.setState({ selectedClass: e.target.value }, () => {
                          myLog(this.state.selectedClass)
                          let findClass = this.state.classes.find(item => {
                            return item.id === this.state.selectedClass
                          })
                          myLog(findClass)
                          this.setState({ selectedClassName: findClass })
                          this.getStudentPresentAndAbsentList()
                        })
                      }}
                    >
                      {this.state.classes &&
                        this.state.classes.map((item, index) => {
                          return <option value={item.id}>{item.value}</option>
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-sm">
                  <div className="form-group">
                    <small>Date</small>
                    <input
                      type="date"
                      className="form-control unstyled"
                      value={this.state.takeAttendanceDate}
                      onChange={e =>
                        this.setState(
                          { takeAttendanceDate: e.target.value },
                          () => this.getStudentPresentAndAbsentList()
                        )
                      }
                    />
                  </div>
                </div>
              </div>
              {/* <div className="d-flex justify-content-end px-3 py-2">
                <button
                  className="btn btn-sm badge-success rounded-pill px-4"
                  onClick={() => this.updateAttendence()}
                >
                  <i className="far fa-save mr-1" />
                  Edit
                </button>
              </div> */}
              <Scrollbars
                autoHeight
                autoHeightMin={100}
                autoHeightMax="63vh"
                autoHide
              >
                <div className="table-responsive attendance-table px-3">
                  <table className="table table-hover">
                    <thead className="bg-lightblue-custom">
                      <tr>
                        <th scope="col" className="text-center py-2">
                          Name
                        </th>
                        <th scope="col" className="text-center py-2">
                          Attendance
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {attedenceList &&
                        attedenceList.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="text-center">{item.name}</td>
                              <td className="text-center">
                                {item.isPresent ? (
                                  <span className="attendance-present">P</span>
                                ) : (
                                  <span className="attendance-absent">A</span>
                                )}
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>
              </Scrollbars>
            </div>
          </div>

          {/* Right Section */}
          <div className="col-sm-4">
            {/* <div className="justify-content-center card shadow p-3 mb-2">
              <a
                className="border-bottom-primary card cursor-pointer mb-4 box-shadow-none text-dark"
                id="UncontrolledTooltipAttendanceTemplate"
                href={attendanceTemplate}
                download
              >
                <span className="px-3 py-1">
                  <i className="fal fa-table mr-2"></i>
                  Download Template
                </span>
              </a>
              <UncontrolledTooltip
                placement="top"
                className="bg-danger"
                modifiers={tooltipModifiers}
                target="UncontrolledTooltipAttendanceTemplate"
              >
                Template to fill & upload the student attendance
              </UncontrolledTooltip>
              <div className="border-bottom card cursor-pointer mb-2 box-shadow-none">
                <span className="px-3 py-1">
                  <i className="fal fa-file-upload mr-2"></i>
                  Upload Marked Attendance
                  <input
                    type="file"
                    className="cursor-pointer"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    style={{
                      opacity: 0.0,
                      position: "absolute",
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </span>
              </div>
            </div> */}

            <div className="card pt-2 pb-3 mb-2">
              <b className="ml-3 mt-1 mb-2">Today's Report</b>
              <canvas
                // style={{ width: 800, height: 600 }}
                ref={attendanceGraph =>
                  (this.attendanceGraph = attendanceGraph)
                }
              />
            </div>

            <div className="card shadow mt-2 pt-2 pb-2 mb-2">
              <b className="ml-3 mx-2 pb-2">Absentees</b>
              <Scrollbars
                autoHeight
                autoHeightMin={300}
                autoHeightMax="43vh"
                autoHide
              >
                <div className="table-responsive attendance-table px-3">
                  <table className="table table-hover table-striped">
                    <tbody>
                      {attedenceList &&
                        attedenceList.map((item, index) => {
                          if (!item.isPresent) {
                            return (
                              <tr key={index}>
                                <td className="text-center">{item.name}</td>
                                {/* <td className="text-center">
                                {item.isPresent ? (
                                  <span className="attendance-present disabled">
                                    P
                                  </span>
                                ) : (
                                  <span className="attendance-absent">A</span>
                                )}
                              </td> */}
                              </tr>
                            )
                          }
                          return null
                        })}
                    </tbody>
                  </table>
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    attedenceList: state.takeAttendanceState.viewAttedenceList,
    classList: state.takeAttendanceState.teacherClassList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      saveAttendenceInStore,
      getClassList,
      getStudentPresentAndAbsentList,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(ViewAttendance)
