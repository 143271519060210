import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { HashLoader } from "react-spinners"

import { getClassDetails } from "../../../actions/admin/ClassDetailsAction"

import { ReactComponent as Arrow } from "../../../assets/images/arrow.svg"

import StudentPreviewModal from "./Modals/StudentPreview"
import EditTemplateModal from "./Modals/EditTemplate"
import IndividualClass from "./IndividualClass"
import PreviewModal from "./Modals/Preview"
import AppWrapper from "../Wrapper1"
import Card from "./Card"
import "./index.css"

function ReportCard() {
  const classList = useSelector(({ classDetailsState }) => {
    const newData = []

    classDetailsState.classList.forEach(ch => {
      const isAlreadyPresent = newData.findIndex(
        nd => nd.className === ch.classNameOnly
      )

      if (isAlreadyPresent !== -1) {
        newData[isAlreadyPresent].subClasses.push({ ...ch })
      } else {
        newData.push({
          id: ch.classId,
          className: ch.classNameOnly,
          subClasses: [{ ...ch }],
        })
      }
    })

    return newData
  })

  const [showStuPreview, setShowStuPreview] = useState({
    state: false,
    data: {},
  })
  const [selectedClass, setSelectedClass] = useState({ state: false, data: {} })
  const [showPreview, setShowPreview] = useState({ state: false, data: {} })
  const [isLoading, setIsLoading] = useState(true)
  const [showEdit, setShowEdit] = useState({ state: false, data: {} })
  const [selected, setSelected] = useState("")
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getClassDetails({}, () => setIsLoading(false)))

    document.body.classList.add("bg-gray-for-report")

    return () => document.body.classList.remove("bg-gray-for-report")
  }, [dispatch])

  const updateStuShowPreview = (state, data) =>
    setShowStuPreview({ state, data })
  const updateSelectedClass = (state, data) => setSelectedClass({ state, data })
  const updateShowPreview = (state, data) => setShowPreview({ state, data })
  const updateShowEdit = (state, data) => setShowEdit({ state, data })

  return (
    <AppWrapper adminDashboard screenName="Report Card">
      {!selectedClass.state ? (
        <div className="generate-report fs-13">
          Generate report card for respective classes
        </div>
      ) : (
        <button
          className="back-btn-report-card d-flex align-items-center justify-content-center bg-white"
          onClick={() => updateSelectedClass(false, {})}
        >
          <Arrow
            width={16}
            height={16}
            style={{ transform: "rotateZ(90deg)" }}
          />
          Back
        </button>
      )}

      {isLoading && (
        <HashLoader
          css={{
            position: "fixed",
            width: "100%",
            height: "100%",
            left: "0px",
            top: "0px",
          }}
          size={30}
          color="#084353"
          sizeUnit="px"
          loading
        />
      )}

      {!isLoading &&
        !selectedClass.state &&
        classList.map(d => (
          <Card
            key={d.className}
            list={d.subClasses}
            title={d.className}
            selected={selected}
            setSelected={setSelected}
            updateShowEdit={() => updateShowEdit(true, d)}
            updateShowPreview={() => updateShowPreview(true, d)}
            updateSelectedClass={updateSelectedClass}
          />
        ))}

      {selectedClass.state && (
        <IndividualClass
          selectedClass={selectedClass.data}
          updateStuShowPreview={updateStuShowPreview}
        />
      )}

      {showPreview.state && (
        <PreviewModal
          isOpen
          data={showPreview.data}
          updateShowPreview={updateShowPreview}
        />
      )}

      {showEdit.state && (
        <EditTemplateModal
          isOpen
          classList={classList}
          data={showEdit.data}
          updateShowEdit={updateShowEdit}
        />
      )}

      {showStuPreview.state && (
        <StudentPreviewModal
          isOpen
          data={showStuPreview.data}
          updateStuShowPreview={updateStuShowPreview}
        />
      )}
    </AppWrapper>
  )
}

export default ReportCard
