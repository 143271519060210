/*************************************************
 * RedInk
 * @exports
 * @class questionBankReducer.js
 * @extends Component
 * @author Mahesh // on 02/05/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from "../../actions/types"
// import { myLog } from "../../utils/Utility"

const INTIAL_STATE = {
  currentAssignment: {},
  toggleModal: false,
  masterSubjectsList: null,
  chapterTopicsList: null,
  questionsAnswersList: null,
  questionsAnswersListFilter: null,
  deleteChaptersRes: null,
  deleteTopicsRes: null,
  deleteQuestionsRes: null,
  updateChaptersTopicsRes: null,
  updateQuestionsAnswersRes: null,
}

export const founderQuestionBankReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.MASTER_SUBJECTS:
      return {
        ...state,
        masterSubjectsList: action.payload,
      }
    case ACTION_TYPES.FOUNDER_CHAPTERS_TOPICS:
      return {
        ...state,
        chapterTopicsList: action.payload,
      }
    case ACTION_TYPES.FOUNDER_QUESTIONS_DATA:
      return {
        ...state,
        questionsAnswersList: action.payload,
      }
    case ACTION_TYPES.FOUNDER_QUESTIONS_DATA_FILTER:
      return {
        ...state,
        questionsAnswersListFilter: action.payload,
      }

    case ACTION_TYPES.FOUNDER_UPDATE_CHAPTERS_TOPICS:
      return {
        ...state,
        updateChaptersTopicsRes: action.payload,
      }
    case ACTION_TYPES.FOUNDER_UPDATE_QUESTIONS_CHAPTERS:
      return {
        ...state,
        updateQuestionsAnswersRes: action.payload,
      }
    case ACTION_TYPES.FOUNDER_DETELE_CHAPTERS:
      return {
        ...state,
        deleteChaptersRes: action.payload,
      }
    case ACTION_TYPES.FOUNDER_DETELE_TOPICS:
      return {
        ...state,
        deleteTopicsRes: action.payload,
      }
    case ACTION_TYPES.FOUNDER_DETELE_QUESTIONS:
      return {
        ...state,
        deleteQuestionsRes: action.payload,
      }
    default:
      return state
  }
}
