import React, { useEffect, useState } from "react"
// import { myLog } from "../../../utils/Utility"
import { showNotification } from "../../../actions/CommonAction"
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog"
import { Button } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import {
  getChapterTopicsFilter,
  getQuestionsAnswersFilter,
} from "../../../actions/founder/QuestionBankActions"
import { DANGER_TOAST, VALIDATION_MSG } from "../../../utils/Constants"

const boards = [
  { value: "CBSE", label: "CBSE" },
  { value: "IB", label: "IB" },
  { value: "ICSE", label: "ICSE" },
  { value: "IGSE", label: "IGSE" },
  { value: "IGCSE", label: "IGCSE" },
  { value: "SB", label: "STATE BOARD" },
]

const classes = [
  { value: "JKG", label: "JKG" },
  { value: "SKG", label: "SKG" },
  { value: "LKG", label: "LKG" },
  { value: "UKG", label: "UKG" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
]

function ExportComponent({ isOpen, closeModal }) {
  const dispatch = useDispatch()
  const masterSubjectsList = useSelector(
    state => state.founderQuestionBankState.masterSubjectsList
  )

  const [, setLoading] = useState(false)
  const [currentPage] = useState(1)
  const [pageSize] = useState(10)
  const [selectedBoard, setBoard] = useState(null)
  const [, setchapterTopicsList] = useState([])
  const [boardList] = useState(boards)
  const [selectedClass, setselectedClass] = useState(null)
  const [defaultClasses] = useState(classes)
  const [selectedSubject, setselectedSubject] = useState(null)
  const [selectedChapter, setselectedChapter] = useState(null)
  const [selectedTopic, setselectedTopic] = useState(null)
  const [chapterList, setchapterList] = useState(false)
  const [topicList, settopicList] = useState(false)
  const [qaList, setQalist] = useState("")

  useEffect(() => {
    loadChapterTopics()
  }, [
    selectedBoard,
    selectedClass,
    selectedChapter,
    selectedSubject,
    selectedTopic,
  ])

  const loadChapterTopics = () => {
    let data = {
      currentPage: currentPage,
      pageSize: pageSize,
    }

    if (selectedBoard) data.selectedBoard = selectedBoard
    if (selectedClass) data.selectedClass = selectedClass
    if (selectedChapter) data.selectedChapter = selectedChapter
    if (selectedSubject) data.selectedSubject = selectedSubject
    if (selectedTopic) data.selectedTopic = selectedTopic

    dispatch(
      getChapterTopicsFilter(data, (success, response) => {
        if (success) {
          setLoading(false)
          setchapterTopicsList(prevState => [...prevState, response.data])

          if (!selectedChapter) {
            if (response.data.chaptersList.length > 0) {
              setchapterList(response.data.chaptersList)
            } else {
              setchapterList(false)
            }
          }

          if (!selectedTopic) {
            if (response.data.topicsList.length > 0) {
              settopicList(response.data.topicsList)
            } else {
              settopicList(false)
            }
          }
        }
      })
    )
  }

  const loadQuestionsAnswers = () => {
    if (
      selectedBoard &&
      selectedClass &&
      selectedSubject &&
      selectedChapter &&
      selectedTopic
    ) {
      let data = {}
      if (selectedBoard) data.selectedBoard = selectedBoard
      if (selectedClass) data.selectedClass = selectedClass
      if (selectedSubject) data.selectedSubject = selectedSubject
      if (selectedChapter) data.selectedChapter = selectedChapter
      if (selectedTopic) data.selectedTopic = selectedTopic

      dispatch(
        getQuestionsAnswersFilter(data, (success, response) => {
          if (success) {
            setQalist(response.data)
          }
        })
      )
    } else {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Choose all the fields"
        )
      )
    }
  }

  const downloadFile = () => {
    qaList.forEach(item => {
      delete item.id
      delete item.createdOn
      let data = {
        type_type: item.type,
        question: item.question,
      }
      return data
    })
  }

  return (
    <div>
      <ModalTransition>
        {isOpen && (
          <Modal>
            <div>
              <div className="modal-header fs-25"> Export data</div>
              <div className="align-items-center justify-content-start mt-4">
                <select
                  className="mr-3 mb-2"
                  value={selectedBoard}
                  onChange={e => setBoard(e.target.value)}
                >
                  <option value="">All Boards</option>
                  {boardList &&
                    boardList.map(option => (
                      <option value={option.value} key={option.value}>
                        {option.label}
                      </option>
                    ))}
                </select>

                <select
                  className="mr-3 mb-2"
                  value={selectedClass}
                  onChange={e => setselectedClass(e.target.value)}
                >
                  <option value="">All Classes</option>
                  {defaultClasses &&
                    defaultClasses.map(option => (
                      <option value={option.value} key={option.value}>
                        {option.label}
                      </option>
                    ))}
                </select>

                <select
                  className="mr-3 mb-2"
                  value={selectedSubject}
                  onChange={e => {
                    setselectedSubject(e.target.value)
                    setselectedChapter(null)
                  }}
                >
                  <option value="">All Subjects</option>
                  {masterSubjectsList
                    ? masterSubjectsList.data.map(
                        ({ subjectName, masterSubjectId }) => {
                          return (
                            <option value={masterSubjectId}>
                              {subjectName}
                            </option>
                          )
                        }
                      )
                    : ""}
                </select>

                <select
                  className="mr-3 mb-2"
                  value={selectedChapter}
                  onChange={e => {
                    setselectedChapter(e.target.value)
                    setselectedTopic(null)
                  }}
                >
                  <option value="">All Chapters</option>""
                  {chapterList
                    ? chapterList.map(({ name, id }) => {
                        return <option value={id}>{name}</option>
                      })
                    : ""}
                </select>

                <select
                  className="mr-3 mb-2"
                  value={selectedTopic}
                  onChange={e => {
                    setselectedTopic(e.target.value)
                  }}
                >
                  <option value="">Select Topic</option>
                  {topicList
                    ? topicList.map(({ name, id }) => {
                        return <option value={id}>{name}</option>
                      })
                    : ""}
                </select>
              </div>
              {qaList.length > 0 && (
                <div className="table-responsive">
                  <table className="table">
                    <thead
                      className="text-white"
                      style={{ backgroundColor: "rgb(7, 62, 77)" }}
                    >
                      <tr>
                        <th scope="col">Question</th>
                        <th scope="col">Text</th>
                        <th scope="col">Answer</th>
                        <th scope="col">Options</th>
                        <th scope="col">Solution</th>
                        <th scope="col">Type </th>
                        <th scope="col"> Marks</th>
                        <th scope="col">Level </th>
                      </tr>
                    </thead>
                    {qaList.map((item, index) => {
                      return (
                        <tbody key={index}>
                          <tr>
                            <td>{item.question}</td>
                            <td>{item.text}</td>
                            <td>{item.answer}</td>
                            <td>{item.distractionAnswers}</td>
                            <td>{item.solution}</td>
                            <td>{item.type}</td>
                            <td>{item.mark}</td>
                            <td>{item.difficulty}</td>
                          </tr>
                        </tbody>
                      )
                    })}
                  </table>
                </div>
              )}

              <ModalFooter
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button onClick={closeModal}>Close</Button>
                <Button
                  className="ml-3"
                  onClick={loadQuestionsAnswers}
                  style={{}}
                >
                  View
                </Button>
                {qaList.length > 0 && (
                  <Button onClick={downloadFile}>Export</Button>
                )}
              </ModalFooter>
            </div>
          </Modal>
        )}
      </ModalTransition>
    </div>
  )
}

export default ExportComponent
