import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import { showNotification } from "../CommonAction"
import {
  SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"

import URL from "../../config"

export const getBehaviourDetails = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_BEHAVIOUR_DETAILS, null, true)
      if (response) {
        myLog("====getBehaviourDetails RESPONSE===::::", response)
        callBack(true, response.studentsList)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getBehaviourDetails ERROR===????", error)
    }
  }
}

export const getBehaviourRecognitionDetails = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_BEHAVIOUR_RECOGNITION_DETAILS,
        null,
        true
      )
      if (response) {
        myLog("====getBehaviourDetails RESPONSE===::::", response)
        callBack(true, response.studentsList)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getBehaviourDetails ERROR===????", error)
    }
  }
}

export const updateBehaviourDetails = (body, callBack) => {
  myLog("updateBehaviourDetails -> ", body)
  return async dispatch => {
    //   try {
    //     const response = await Client.post(
    //       URL.GET_BEHAVIOUR_DETAILS,
    //       body,
    //       true
    //     )
    //     myLog(response)
    //     if (response.responseCode === 0) {
    //       myLog("====updateBehaviourDetails RESPONSE===::::", response)
    //       callBack(true, response)
    //       dispatch(
    //         showNotification(
    //           SUCCESS_TOAST,
    //           VALIDATION_MSG.SUCCESS,
    //           response.responseMessage
    //         )
    //       )
    //     } else {
    //       callBack(false)
    //       dispatch(
    //         showNotification(
    //           DANGER_TOAST,
    //           VALIDATION_MSG.OOPS,
    //           response.responseMessage
    //         )
    //       )
    //     }
    //   } catch (error) {
    //     callBack(false)
    //     dispatch(showNotification(DANGER_TOAST, VALIDATION_MSG.OOPS, error))
    //     myLog("====updateBehaviourDetailss ERROR===????", error)
    //   }
  }
}

export const deleteBehaviourDetails = (body, callBack) => {
  myLog(body)
  return async dispatch => {
    try {
      const response = await Client.post(
        URL.DELETE_BEHAVIOUR_DETAIL,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====deleteBehaviourDetails RESPONSE===::::", response)
        dispatch(getBehaviourDetails(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====deleteBehaviourDetails ERROR===????", error)
    }
  }
}

export const saveBehaviourDetails = (body, callBack) => {
  myLog("Behaviour  MODAL OBJECT ->", body)
  return async dispatch => {
    try {
      const response = await Client.post(URL.SAVE_BEHAVIOUR_DETAILS, body, true)
      if (response.responseCode === 0) {
        myLog("====saveBehaviourDetails RESPONSE===::::", response)
        dispatch(getBehaviourDetails(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveNewsDetails ERROR===????", error)
    }
  }
}

export const saveBehaviourRecognitionDetails = (body, callBack) => {
  myLog("Behaviour  MODAL OBJECT ->", body)
  return async dispatch => {
    try {
      const response = await Client.post(
        URL.SAVE_BEHAVIOUR_RECOGNITION_DETAILS,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====saveBehaviourRecognitionDetails RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveBehaviourRecognitionDetails ERROR===????", error)
    }
  }
}
