function extractBucketNameAndPath(url) {
  const _decodedUrl = decodeURIComponent(url)

  // http://s3.amazonaws.com/bucket/key1/key2
  let _match = _decodedUrl.match(
    /^https?:\/\/s3.amazonaws.com\/([^\/]+)\/?(.*?)$/
  )
  if (_match) {
    const key = _match[2]
    return {
      bucket: _match[1],
      key,
      region: "",
      fileName: key.split("/").pop(),
    }
  }

  // http://s3-aws-region.amazonaws.com/bucket/key1/key2
  _match = _decodedUrl.match(
    /^https?:\/\/s3-([^.]+).amazonaws.com\/([^\/]+)\/?(.*?)$/
  )
  if (_match) {
    const key = _match[3]
    return {
      bucket: _match[2],
      key,
      region: _match[1],
      fileName: key.split("/").pop(),
    }
  }

  // http://bucket.s3.amazonaws.com/key1/key2
  _match = _decodedUrl.match(/^https?:\/\/([^.]+).s3.amazonaws.com\/?(.*?)$/)
  if (_match) {
    const key = _match[2]
    return {
      bucket: _match[1],
      key,
      region: "",
      fileName: key.split("/").pop(),
    }
  }

  // http://bucket.s3-aws-region.amazonaws.com/key1/key2 or,
  // http://bucket.s3.aws-region.amazonaws.com/key1/key2
  _match = _decodedUrl.match(
    /^https?:\/\/([^.]+).(?:s3-|s3\.)([^.]+).amazonaws.com\/?(.*?)$/
  )
  if (_match) {
    const key = _match[3]
    return {
      bucket: _match[1],
      key,
      region: _match[2],
      fileName: key.split("/").pop(),
    }
  }

  return {
    // bucket: BUCKET_NAME,
    key: url,
    region: "",
    fileName: url.split("/").pop(),
  }
}

export default extractBucketNameAndPath
