/*************************************************
 * RedInk
 * @exports
 * @class StudentRecord.js
 * @extends Component
 * @author Sasidharan // on 01/02/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import Chart from "chart.js"

import boyPic from "../../assets/images/boy_illus.png"

import AppWrapper from "./Wrapper"

import { getQuickInfoDetails } from "../../actions/CommonAction"
import { myLog } from "../../utils/Utility"

// import malePic from "../../assets/images/men.png"
// import noData from "../../assets/images/NoData.gif"
// import chemistrypic from "../../assets/images/ConicalFlask.jpg"
// import physicspic from "../../assets/images/atom.jpg"
// import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
// import store from "store"

let myexGraph = null

class StudentDashboard extends Component {
  constructor(props) {
    super(props)
    document.title = "Dashboard | Redink"
    this.state = {
      subject: true,
      test: false,
      studentName: "",
      newAndEvents: [
        {
          title: "SPORTS DAY CELEBRATION",
          sendBy: "Admin. 9 minutes",
          description: "The sports day event was a great success",
        },
        {
          title: "SPORTS DAY CELEBRATION",
          sendBy: "Admin. 9 minutes",
          description: "The sports day event was a great success",
        },
        {
          title: "SPORTS DAY CELEBRATION",
          sendBy: "Admin. 9 minutes",
          description: "The sports day event was a great success",
        },
        {
          title: "SPORTS DAY CELEBRATION",
          sendBy: "Admin. 9 minutes",
          description: "The sports day event was a great success",
        },
      ],
      recentTest: {
        subject: "ENGLISH",
        date: "Jun 11",
        testName: "Cycle Test 2",
        percentage: 92,
        totalScore: 25,
        prevTest: "Cycle Test 1",
        score: "64%",
      },
      todaySession: [
        {
          subject: "CHEMISTRY",
          time: "09:00 AM - 09:45 AM",
          teacher: "JONES",
        },
        {
          subject: "CHEMISTRY",
          time: "09:00 AM - 09:45 AM",
          teacher: "JONES",
        },
        {
          subject: "CHEMISTRY",
          time: "09:00 AM - 09:45 AM",
          teacher: "JONES",
        },
        {
          subject: "CHEMISTRY",
          time: "09:00 AM - 09:45 AM",
          teacher: "JONES",
        },
        {
          subject: "CHEMISTRY",
          time: "09:00 AM - 09:45 AM",
          teacher: "JONES",
        },
      ],
      upcomingTest: [
        {
          subject: "MATHS",
          topic: "Trigonometry",
          date: "15 JUNE",
          time: "8:00pm",
          daysLeft: 1,
        },
        {
          subject: "MATHS",
          topic: "Trigonometry",
          date: "15 JUNE",
          time: "8:00pm",
          daysLeft: 4,
        },
        {
          subject: "MATHS",
          topic: "Trigonometry",
          date: "15 JUNE",
          time: "8:00pm",
          daysLeft: 4,
        },
        {
          subject: "MATHS",
          topic: "Trigonometry",
          date: "15 JUNE",
          time: "8:00pm",
          daysLeft: 4,
        },
      ],
      assignments: [
        {
          subject: "CHEMISTRY",
          topic: "p block elements",
          dueDate: "15",
        },
        {
          subject: "CHEMISTRY",
          topic: "p block elements",
          dueDate: "15",
        },
        {
          subject: "CHEMISTRY",
          topic: "p block elements",
          dueDate: "15",
        },
        {
          subject: "CHEMISTRY",
          topic: "p block elements",
          dueDate: "15",
        },
      ],
      days: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
    }
  }

  toggleGraph = () => {
    this.setState({ showGraphType: !this.state.showGraphType })
    myLog(this.state.showGraphType)
  }

  componentDidMount = () => {
    // this.showExamPerformanceGraph()
    this.props.getQuickInfoDetails((success, response) => {
      if (success) {
        this.setState({ studentName: response.studentName })
      }
    })
  }

  showExamPerformanceGraph = () => {
    const examPerformanceGraph = this.examPerformanceGraph
    if (myexGraph != null) {
      myexGraph.destroy()
    }
    myexGraph = new Chart(examPerformanceGraph, {
      type: "bar",
      data: {
        labels: ["FA1", "FA2", "SA1", "FA3", "FA4"],
        datasets: [
          {
            label: "Marks",
            data: [80, 90, 100, 100, 78],
            backgroundColor: "#0fb293",
            borderRadius: 150,
            borderWidth: 2,
            barThickness: 12,
            maxBarThickness: 12,
            height: 13,
          },
        ],
      },
      options: {
        responsive: true,
        legend: {
          display: false,
          position: "bottom",
          labels: {
            usePointStyle: true,
            fontSize: 10,
            fontColor: "white",
          },
        },
        scales: {
          xAxes: [
            {
              barPercentage: 0.1,
              ticks: {
                fontColor: "#000000",
              },
              gridLines: {
                display: false,
              },
              stacked: true,
            },
          ],
          yAxes: [
            {
              ticks: {
                display: false,
              },
              gridLines: {
                display: false,
              },
              stacked: true,
            },
          ],
        },
      },
    })
  }

  render() {
    // const news = this.state.newAndEvents.map(val => {
    //   return (
    //     <div className="card-body mt-0 p-2">
    //       <div className="bg-card-light p-2 br-10 d-flex">
    //         <div>
    //           <img
    //             src={noData}
    //             className="br-10"
    //             width="55px"
    //             height="55px"
    //             alt=""
    //           />
    //         </div>
    //         <div className="ml-4">
    //           <h6 className="text-dark">
    //             <strong>{val.title}</strong> <br></br>{" "}
    //             <small>{val.sendBy}</small>
    //           </h6>
    //           <p className="mb-0" style={{ fontSize: "10px" }}>
    //             {val.description}
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //   )
    // })
    // const todaysession = this.state.todaySession.map(ses => {
    //   return (
    //     <div className="bg-card-light card p-3 mx-1 br-10 text-center">
    //       <img
    //         src={chemistrypic}
    //         style={{ width: "70px", height: "70px" }}
    //         alt=""
    //       />
    //       <p>
    //         <strong> {ses.subject}</strong> <br></br>
    //         <small>{ses.time}</small>
    //       </p>
    //       <div className="d-flex justify-content-around">
    //         <img
    //           src={malePic}
    //           alt=""
    //           className="student-pic center d-block"
    //           style={{ width: "20px", height: "20px" }}
    //         />
    //         <small>{ses.teacher}</small>
    //       </div>
    //     </div>
    //   )
    // })

    // const upcomingTest = this.state.upcomingTest.map(test => {
    //   return (
    //     <div className="bg-card-light mb-2 d-flex justify-content-between p-2 upcoming-card">
    //       <div className="d-flex">
    //         <small>
    //           <strong> {test.subject} </strong> <br></br> {test.topic} <br></br>{" "}
    //           {test.time}
    //         </small>
    //       </div>
    //       <div style={{ width: "50px", height: "50px" }}>
    //         <CircularProgressbar
    //           value={25}
    //           text={`${test.daysLeft} Day(s)`}
    //           styles={buildStyles({
    //             pathColor: `#41B69C`,
    //             textColor: "#000000",
    //             trailColor: "#D5D5DF",
    //           })}
    //         />
    //       </div>
    //     </div>
    //   )
    // })

    // const assignments = this.state.assignments.map(assign => {
    //   return (
    //     <div className="card p-2 d-flex mb-2 justify-content-between assign-card">
    //       <div className="d-flex justify-content-between">
    //         <div className="d-flex">
    //           <img src={physicspic} width="50px" height="50px" alt="" />
    //           <p className="ml-3">
    //             {assign.subject} <br></br> <small>{assign.topic} </small>
    //           </p>
    //         </div>
    //         <div>
    //           <small>Due on {assign.dueDate}</small>
    //         </div>
    //       </div>
    //     </div>
    //   )
    // })
    let d = new Date().toString()
    d = d.split(" ")

    return (
      <AppWrapper
        openMessageModal={() => this.onClickMessageIcon()}
        teacherStudentRecordSidemenu
        studentDashboard
        showTeacherThree
        showTeacherLogo
        screenName="Dashboard"
      >
        <div className="container-fluid row mt-4">
          {/* Col 1 */}
          <div className="col-md-8">
            {/* welcome card */}
            <div className="row my-3">
              <div className="col mb-3">
                <div className="bg-theme-light br-10">
                  <div className="text-left p-3 text-light">
                    <h5>Hi {this.state.studentName} !</h5>
                    {/* <p>
                      You have completed 60% of your goal this week ! <br></br>{" "}
                      a new goal and improve your result !
                    </p> */}
                    <p>Set new goal and improve your results.</p>
                  </div>
                  <img
                    src={boyPic}
                    alt=""
                    className="img-width d-none d-sm-block"
                  />
                </div>
              </div>
            </div>
            {/* <div className="row my-3">
              <div className="col-sm-7 mb-4">
                <div className="card px-4 py-2 br-10">
                  <div className="card-title d-flex justify-content-between text-dark my-4">
                    <h6 className="text-dark">
                      <strong>TEST PERFORMANCE</strong>
                    </h6>
                    <div>
                      <button
                        className={`badge-pills ${
                          this.state.subject ? "pill-btn-active" : "pill-btn"
                        } pill-left`}
                        onClick={() =>
                          this.setState({ subject: true, test: false })
                        }
                      >
                        Subject
                      </button>
                      <button
                        className={`badge-pills  ${
                          this.state.test ? "pill-btn-active" : "pill-btn"
                        } pill-right`}
                        onClick={() =>
                          this.setState({ subject: false, test: true })
                        }
                      >
                        Test
                      </button>
                    </div>
                    <div className="dropdown">
                      <button
                        className="badge-pills pill-btn br-10 dropdown-toggle"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        English
                      </button>
                      <div
                        className="dropdown-menu mt-1 p-0"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div className="dropdown-item p-2">Science</div>
                        <div className="dropdown-item p-2">Mathematics</div>
                        <div className="dropdown-item p-2">Social Science</div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body performance">
                    <div className="p-3">
                      <canvas
                        ref={examPerformanceGraph =>
                          (this.examPerformanceGraph = examPerformanceGraph)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="recent_test_score_card col-sm-5 bg-theme-light text-light br-10 p-3 mb-4">
                <div className="card-body">
                  <h6>RECENT TEST SCORE</h6>
                  <div className="d-flex p-3 justify-content-between">
                    <div className="d-flex">
                      <p
                        data-letters={this.state.recentTest.subject.charAt(0)}
                      />
                      <div>
                        <p>
                          {this.state.recentTest.subject} <br></br>
                          <small>{this.state.recentTest.date}</small>
                        </p>
                      </div>
                    </div>
                    <div>
                      <small className="badge-pill bg-light-primary">
                        {this.state.recentTest.testName}
                      </small>
                    </div>
                  </div>
                  <div className="d-flex justify-content-around">
                    <div style={{ width: "80px", height: "80px" }}>
                      <CircularProgressbar
                        value={this.state.recentTest.percentage}
                        text={`${this.state.recentTest.percentage}%`}
                        styles={buildStyles({
                          pathColor: `#ffffff`,
                          textColor: "#ffffff",
                          trailColor: "#3a6979",
                        })}
                      />
                    </div>
                    <div className="text-center mt-2">
                      <h5>Your Score</h5>
                      <p>
                        {this.state.recentTest.score} <small>out of</small>{" "}
                        {this.state.recentTest.totalScore}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-around mt-4">
                    <div className="text-center">
                      <h5>Previous Test Score</h5>
                      <p>Cycle Test 1</p>
                    </div>
                    <div>
                      <h5 className="bg-light-primary br-10 p-3">64%</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ height: "19.5vh" }}>
              {" "}
              <div className="col-sm-8">
                <div className="card px-3 py-2 br-10">
                  <div className="card-title mt-3">
                    <div className="d-flex justify-content-between">
                      <h6 className="text-dark">
                        <strong>TODAYS SESSION</strong>
                      </h6>
                      <div>
                        <small className="bg-lightgray py-1 badge-pill">
                          <strong>15 JUNE 2020</strong>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-body scrollbar scrolling-wrapper my-2"
                    style={{ padding: "0rem" }}
                  >
                    {todaysession}
                  </div>
                </div>
              </div>
              <div className="col-sm-4 mb-4 br-10 card">
                <div className="px-2 py-2 br-10">
                  <div className="card-title mt-4 mb-0 text-dark">
                    <h6 className="text-dark">
                      <strong>UPCOMING TESTS</strong>
                    </h6>
                  </div>
                  <div
                    className="card-body px-2 overflow-auto scrollbar"
                    style={{ height: "32vh" }}
                  >
                    {upcomingTest}
                  </div>
                </div>
              </div>
            </div>*/}
          </div>

          <div className="col-md-4 right-col mb-3">
            <div className="col-12 mt-3 mb-3">
              <div className="card br-10">
                <div className="card-body justify-content-b">
                  <span className="h5 font-weight-bold">Today :</span>
                  <span className="h5 pl-2">{`${d[1]} ${d[2]}, ${d[3]}`}</span>
                </div>
              </div>
            </div>
            {/*<div className="col-12 mb-4">
              <div className="card px-3 py-2 br-10">
                <div className="card-title text-dark mb-0 pt-3">
                  <div className="d-flex justify-content-between">
                    <h6 className="text-dark">
                      <strong>NEWS & EVENTS</strong>
                    </h6>
                    <p className="bg-lightgray badge-pill py-1"> News</p>
                  </div>
                </div>
                <div
                  className="overflow-auto scrollbar"
                  style={{ height: "40vh" }}
                >
                  {news}
                </div>
              </div>
            </div>
            <div className="col-12 mt-4">
              <div className="card px-3 py-2 br-10" style={{ height: "58vh" }}>
                <div className="card-title mt-4 mb-0 text-dark">
                  <div className="d-flex justify-content-between">
                    <h6 className="text-dark">
                      <strong>ASSIGNMENTS</strong>
                    </h6>
                    <p className="bg-lightgray py-1 badge-pill">Due</p>
                  </div>
                </div>
                <div className="card-body pt-0 px-1 mb-4 mt-1 overflow-auto scrollbar">
                  {assignments}
                </div>
              </div>
            </div>*/}
          </div>
        </div>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    studentList: state.studentDatabaseState.studentList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getQuickInfoDetails,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(StudentDashboard)
