import React, { useEffect, useState } from "react"
import { HashLoader } from "react-spinners"
import { useDispatch } from "react-redux"
import { Modal } from "reactstrap"

import extractBucketNameAndPath from "../../../../utils/extractBucketNameAndPath"
import { showNotification } from "../../../../actions/CommonAction"
import { SUCCESS_TOAST } from "../../../../utils/Constants"
import { getFile } from "../../../../actions/CommonAction"

import { ReactComponent as Download } from "../../../../assets/images/download.svg"
import { ReactComponent as Print } from "../../../../assets/images/print.svg"
import { ReactComponent as Close } from "../../../../assets/images/close.svg"

function StudentPreviewModal({ data, isOpen, updateStuShowPreview }) {
  const [isDownLoading, setDownIsLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [pdfUrl, setPdfUrl] = useState("")
  const dispatch = useDispatch()

  useEffect(() => {
    let { key } = extractBucketNameAndPath(data.reportCardUrl)
    dispatch(
      getFile(key, (success, res) => {
        if (success) {
          setPdfUrl(res.url)
          setIsLoading(false)
        }
      })
    )
  }, [dispatch, data.reportCardUrl])

  function downloadPdf() {
    setDownIsLoading(true)
    let { key } = extractBucketNameAndPath(data.reportCardUrl)

    dispatch(
      getFile(key, (success, res) => {
        if (success) {
          const xhr = new XMLHttpRequest()
          xhr.onload = () => {
            const file = new Blob([xhr.response])
            const browserUrl = window.webkitURL || window.URL
            const fileUrl = browserUrl.createObjectURL(file)
            const a = document.createElement("a")
            a.href = fileUrl
            a.download = `${data.studentName}.pdf`
            a.click()
            browserUrl.revokeObjectURL(fileUrl)
            setDownIsLoading(false)
            dispatch(showNotification(SUCCESS_TOAST, "", "Download successful"))
          }
          xhr.open("GET", res.url, true)
          xhr.responseType = "blob"
          xhr.send(null)
        }
      })
    )
  }

  function printPdf() {
    window.open(pdfUrl, "_blank")
  }

  return (
    <Modal
      isOpen={isOpen}
      backdropClassName="modal-backdrop-show"
      modalClassName="modal-dialog-shadow-none"
      contentClassName="report-modal-wrapper p-4"
      centered
      size="lg"
    >
      <div className="d-flex align-items-center">
        <h1 className="report-modal-wrapper-h1 mb-0 fw-600">
          {data.studentName} - Report card preview
        </h1>

        <Close
          className="report-modal-close-icon ml-auto"
          onClick={() => updateStuShowPreview(false, {})}
        />
      </div>

      {isLoading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "400px" }}
        >
          <HashLoader size={30} color="#084353" sizeUnit="px" loading />
        </div>
      ) : (
        <>
          <div
            id="student-pdf-viewer"
            className="report-preview-modal-center my-4 py-3"
            style={{ overflowY: "hidden", background: "#e6e6e6" }}
          >
            <object data={pdfUrl} width="100%" height="100%">
              Student Report Card
            </object>
          </div>

          <div className="report-preview-modal-btns-wrapper d-flex align-items-center justify-content-center gap-2rem">
            <button
              className="report-preview-modal-print-btn d-flex align-items-center justify-content-center px-4 py-2 fs-15 fw-600 bg-bcbdc030 clr-909091 br-8px"
              onClick={printPdf}
            >
              <Print className="mr-2" />
              Print
            </button>

            <button
              className="report-preview-modal-download-btn disabled-btn d-flex align-items-center justify-content-center px-4 py-2 fs-15 fw-600 bg-5DD1AD text-white br-8px"
              onClick={downloadPdf}
              disabled={isDownLoading}
            >
              {isDownLoading ? (
                <HashLoader size={20} color="#fff" sizeUnit="px" loading />
              ) : (
                <>
                  <Download className="mr-2" />
                  Download
                </>
              )}
            </button>
          </div>
        </>
      )}
    </Modal>
  )
}

export default StudentPreviewModal
