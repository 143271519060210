/*************************************************
 * Redink
 * @exportsnpm
 * @file App.js
 * @author Sasidharan // on 19/12/2019
 * @copyright © 2019 Redink. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"
import store from "store"

import "../assets/css/template/template.css"
import "../assets/fontawesome5/css/all.min.css"
import "../assets/bootstrap/css/bootstrap.min.css"
import "../assets/css/dev.css"
import "../assets/css/404.css"
import "../assets/css/wrapper.css"

import {
  isAdminLoggedIn,
  isTeacherLoggedIn,
  isParentLoggedIn,
  isSuperAdminLoggedIn,
  isFounderLoggedIn,
} from "../utils/Utility"
import { URL_PATH } from "./../config/urlPath"

import Login from "./auth/Login"
import NotFoundPage from "./common/404"
import ResetPassword from "./auth/ResetPassword"
import ForgotPassword from "./auth/ForgotPassword"
import ZoomIntegration from "./common/integrations/zoom"

import SuperAdminChangePasswordScreen from "./superAdmin/ChangePasswordScreen"
import SuperAdminAttendanceReport from "./superAdmin/attendance"
import SuperAdminTeacherProfile from "./superAdmin/profile/TeacherProfile"
import SchoolDetailsDashboard from "./superAdmin/schoolProfile/SchoolDetailsDashboard"
import SuperAdminCommunication from "./superAdmin/communication/Dashboard"
import MarkUploadStatusScreen from "./superAdmin/MarkUploadStatusScreen"
import PerformanceReport from "./superAdmin/performanceReports/AdminPerformanceReport"
import ClassDetailscreen from "./superAdmin/schoolProfile/ClassDetailsScreen"
import TeachersScreen from "./superAdmin/schoolProfile/TeachersScreen"
import SubjectsScreen from "./superAdmin/schoolProfile/SubjectsScreen"
import Branches from "./superAdmin/schoolProfile/BranchesScreen"
import Dashboard from "./superAdmin"

import AdminSchoolDetailsDashboard from "./admin/schoolProfile/SchoolDetailsDashboard"
import AdminMarkUploadStatusScreen from "./admin/MarkUploadStatusScreen"
import AdminAcademicDetailsScreen from "./admin/schoolProfile/AcademicDetailsScreen"
import AdminChangePasswordScreen from "./admin/ChangePasswordScreen"
import AdminGradeDetailsScreen from "./admin/schoolProfile/GradeDetailsScreen"
import AdminStudentFormDetails from "./admin/performanceReports/FormDetails"
import AdminTimeTableDashboard from "./admin/timeTable"
import AdminPerformanceReport from "./admin/performanceReports/PerformanceReport"
import AdminClassDetailscreen from "./admin/schoolProfile/ClassDetailsScreen"
import AdminGradeMasterScreen from "./admin/schoolProfile/GradeMasterScreen"
import AdminClassGroupScreen from "./admin/Onboarding/ClassGroupScreen"
import AdminAttendanceReport from "./admin/attendance"
import AdminUpgradeAcademic from "./admin/UpgradeAcademic"
import AdminStudentProfile from "./admin/performanceReports/StudentProfile"
import AdminStudentsScreen from "./admin/schoolProfile/StudentsScreen"
import AdminSubjectsScreen from "./admin/schoolProfile/SubjectsScreen"
import AdminTeachersScreen from "./admin/schoolProfile/TeachersScreen"
import AdminAcademicScreen from "./admin/Onboarding/AcademicScreen"
import AdminChangePassword from "./admin/Onboarding/ChangePassword"
import AdminTeacherProfile from "./admin/profile/TeacherProfile"
import AdminCommunication from "./admin/communication/Dashboard"
import NewsAndEventScreen from "./admin/newsAndEvents/NewsAndEventScreen"
import TermDetailsScreen from "./admin/schoolProfile/AcademicDetailsScreen/TermDetailsScreen"
import AdminClassScreen from "./admin/Onboarding/ClassScreen"
import AdminQuestionGen from "./admin/QuestGen"
import AdminReportCard from "./admin/ReportCard"
import AdminOnboarding from "./admin/Onboarding"
import AdminBehavior from "./admin/behaviour"
import AdminDashboard from "./admin"

import TeacherAssignmentDetailScreen from "./teacher/assignment/AssignmentDetailScreen"
import TeacherAssignmentViewDetails from "./teacher/assignment/AssignmentViewDetails"
import TeacherChangePasswordScreen from "./teacher/ChangePasswordScreen"
import TeacherPerformanceReport from "./teacher/performanceReports/PerformanceReport"
import TeacherViewAllAssesments from "./teacher/assessment/TeacherViewAllAssesments"
import TeacherAssessmentScreen from "./teacher/assessment/AssessmentScreen"
import TeacherAssignmentScreen from "./teacher/assignment"
import CreateAssessmentScreen from "./teacher/assessment/CreateAssessmentScreen"
import TeacherScheduleScreen from "./teacher/schedule"
import MarkEvaluationScreen from "./teacher/assessment/MarkEvaluationScreen"
import AssessmentMarkScreen from "./teacher/exams/AssessmentMarkScreen"
import TeacherCommunication from "./teacher/communication/Dashboard"
import TeacherQuestionBank from "./teacher/assessment/QuestionBank"
import AssessmentReport from "./teacher/assessment/AssessmentReport"
import AttendanceReport from "./teacher/attendance/AttendanceReport"
import TeacherDashboard from "./teacher"
import MarkEntryScreen from "./teacher/assessment/MarkEntryScreen"
import BehaviourScreen from "./teacher/behaviour"
import ViewAttendance from "./teacher/attendance/ViewAttendance"
import TakeAttendance from "./teacher/attendance/TakeAttendance"
import StudentProfile from "./teacher/student/StudentProfile"
import TermMarkScreen from "./teacher/exams/TermMarkScreen"
import QuickAnalytics from "./teacher/exams/QuickAnalytics"
import TeacherProfile from "./teacher/Profile"
import ExamHierarchy from "./teacher/exams/ExamHierarchyScreen"
import StudentRecord from "./teacher/StudentRecord"
import TaskDashboard from "./teacher/taskManagement"
import FormDetails from "./teacher/student/FormDetails"
import MarkScreen from "./teacher/exams/MarkScreen"

import ParentAssignmentResultScreen from "./parent/assessment/AssesmentResult"
import ParentChangePasswordScreen from "./parent/ChangePasswordScreen"
import StudentAssessmentReport from "./parent/assessment/AssessmentReport"
import StudentAssessmentScreen from "./parent/assessment/AssessmentScreen"
import StudentAssignmentScreen from "./parent/assignment"
import StudentScheduleScreen from "./parent/schedule"
import TakeAssessmentScreen from "./parent/assessment/TakeAssessmentScreen"
import ParentStudentProfile from "./parent/Profile"
import ParentCommunication from "./parent/communication/Dashboard"
import StudentFormDetails from "./parent/FormDetails"
import ParentDashboard from "./parent"

import FounderChangePasswordScreen from "./founder/ChangePasswordScreen"
import FounderSuperAdminDashboard from "./founder/superAdmin"
import TeacherSubjectAllotment from "./teacher/subject/SubjectAllotment"
import FounderEditSuperAdmin from "./founder/superAdmin/EditSuperAdmin"
import FounderCommunication from "./founder/communication/Dashboard"
import TeacherSubjectDetails from "./teacher/subject/SubjectDetails"
import FounderProfileScreen from "./founder/ProfileScreen"
import FounderQuestionBank from "./founder/questionBank"
import FounderBehaviour from "./founder/behaviour/index"
import FounderDashboard from "./founder"
import FounderTeacher from "./founder/teacher"
import FounderStudent from "./founder/student"
import FounderAdmin from "./founder/admin"

const SuperAdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (isSuperAdminLoggedIn()) return <Component {...props} />
      return <Redirect to={URL_PATH.LOGIN} />
    }}
  />
)

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (isAdminLoggedIn()) return <Component {...props} />
      return <Redirect to={URL_PATH.LOGIN} />
    }}
  />
)

const TeacherRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (isTeacherLoggedIn()) return <Component {...props} />
      return <Redirect to={URL_PATH.LOGIN} />
    }}
  />
)

const ParentRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (isParentLoggedIn()) return <Component {...props} />
      return <Redirect to={URL_PATH.LOGIN} />
    }}
  />
)

const FounderRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (isFounderLoggedIn()) return <Component {...props} />
      return <Redirect to={URL_PATH.LOGIN} />
    }}
  />
)

class App extends Component {
  getEmail = () => {
    if (store.get("quickInfo") && store.get("quickInfo").role === 1) {
      return store.get("quickInfo").tentantEmail
    }
    if (store.get("quickInfo") && store.get("quickInfo").role === 2) {
      return store.get("quickInfo").adminEmail
    }
    if (store.get("quickInfo") && store.get("quickInfo").role === 3) {
      return store.get("quickInfo").teacherEmail
    }
    if (store.get("quickInfo") && store.get("quickInfo").role === 4) {
      return store.get("quickInfo").teacherEmail
    }
    if (store.get("quickInfo") && store.get("quickInfo").role === 5) {
      return store.get("quickInfo").primaryParentEmail
    }
  }

  getName = () => {
    if (store.get("quickInfo") && store.get("quickInfo").role === 1) {
      return store.get("quickInfo").tentantFullName
    }
    if (store.get("quickInfo") && store.get("quickInfo").role === 2) {
      return store.get("quickInfo").adminName
    }
    if (store.get("quickInfo") && store.get("quickInfo").role === 3) {
      return store.get("quickInfo").teacherName
    }
    if (store.get("quickInfo") && store.get("quickInfo").role === 4) {
      return store.get("quickInfo").teacherName
    }
    if (store.get("quickInfo") && store.get("quickInfo").role === 5) {
      return store.get("quickInfo").primaryParentName
    }
  }

  // componentDidMount = () => {
  //   // To set user email and name
  //   if (store.get("userSession")) {
  //     // window.FreshworksWidget("identify", "ticketForm", {
  //     //   name: this.getName(),
  //     //   email: this.getEmail(),
  //     // })
  //     // window.FreshworksWidget("disable", "ticketForm", ["name", "email"])
  //   }
  // }

  render() {
    return (
      <React.StrictMode>
        <BrowserRouter>
          <Switch>
            <Route path={URL_PATH.LOGIN} exact component={Login} />
            <Route
              path={URL_PATH.FORGET_PASSWORD}
              exact
              component={ForgotPassword}
            />
            <Route
              path={URL_PATH.RESET_PASSWORD}
              exact
              component={ResetPassword}
            />
            {/* SuperAdmin starts */}
            <SuperAdminRoute
              path={URL_PATH.SUPER_ADMIN}
              exact
              component={Dashboard}
            />
            <SuperAdminRoute
              path={URL_PATH.COMMUNICATION_DASHBOARD}
              exact
              component={SuperAdminCommunication}
            />
            <SuperAdminRoute
              path={URL_PATH.SUPER_ADMIN_SCHOOL_PROFILE_ABOUT}
              exact
              component={SchoolDetailsDashboard}
            />
            <SuperAdminRoute
              path={URL_PATH.SUPER_ADMIN_SCHOOL_PROFILE_BRANCHES}
              exact
              component={Branches}
            />
            <SuperAdminRoute
              path={URL_PATH.SUPER_ADMIN_SCHOOL_PROFILE_SUBJECTS}
              exact
              component={SubjectsScreen}
            />
            <SuperAdminRoute
              path={URL_PATH.SUPER_ADMIN_SCHOOL_PROFILE_CLASS_DETAILS}
              exact
              component={ClassDetailscreen}
            />
            <SuperAdminRoute
              path={URL_PATH.SUPER_ADMIN_SCHOOL_PROFILE_TEACHERS}
              exact
              component={TeachersScreen}
            />
            <SuperAdminRoute
              path={URL_PATH.ATTENDANCE_REPORT}
              exact
              component={SuperAdminAttendanceReport}
            />
            <SuperAdminRoute
              path={URL_PATH.MARK_UPLOAD_STATUS}
              exact
              component={MarkUploadStatusScreen}
            />
            {/* <SuperAdminRoute
                  path={URL_PATH.PERFORMANCE_REPORT}
                  exact
                  component={PerformanceReport}
                /> */}
            <SuperAdminRoute
              path={URL_PATH.PERFORMANCE_REPORT}
              exact
              component={PerformanceReport}
            />
            <SuperAdminRoute
              path={URL_PATH.SUPER_ADMIN_CHANGEPASSWORD}
              exact
              component={SuperAdminChangePasswordScreen}
            />
            <SuperAdminRoute
              path={URL_PATH.SUPER_ADMIN_TEACHER_PROFILE}
              exact
              component={SuperAdminTeacherProfile}
            />
            {/* SuperAdmin Ends */}

            {/* Admin starts */}
            <AdminRoute
              path={URL_PATH.ADMIN}
              exact
              component={AdminDashboard}
            />
            <AdminRoute
              path={URL_PATH.ADMIN_UPGRADE_ACADEMICYEAR}
              exact
              component={AdminUpgradeAcademic}
            />
            <AdminRoute
              path={URL_PATH.ADMIN_COMMUNICATION_DASHBOARD}
              exact
              component={AdminCommunication}
            />
            <AdminRoute
              path={URL_PATH.ADMIN_SCHOOL_PROFILE_ABOUT}
              exact
              component={AdminSchoolDetailsDashboard}
            />
            <AdminRoute
              path={URL_PATH.ADMIN_SCHOOL_PROFILE_SUBJECTS}
              exact
              component={AdminSubjectsScreen}
            />
            <AdminRoute
              path={URL_PATH.ADMIN_SCHOOL_PROFILE_ACADEMICYEAR}
              exact
              component={AdminAcademicDetailsScreen}
            />
            <AdminRoute
              path={URL_PATH.ADMIN_SCHOOL_PROFILE_TERM}
              exact
              component={TermDetailsScreen}
            />

            <AdminRoute
              path={URL_PATH.ADMIN_SCHOOL_PROFILE_CLASS_DETAILS}
              exact
              component={AdminClassDetailscreen}
            />
            <AdminRoute
              path={URL_PATH.ADMIN_SCHOOL_PROFILE_TEACHERS}
              exact
              component={AdminTeachersScreen}
            />
            <AdminRoute
              path={URL_PATH.ADMIN_SCHOOL_PROFILE_STUDENTS}
              exact
              component={AdminStudentsScreen}
            />
            <AdminRoute
              path={URL_PATH.ADMIN_SCHOOL_PROFILE_GRADE}
              exact
              component={AdminGradeDetailsScreen}
            />
            <AdminRoute
              path={URL_PATH.ADMIN_ATTENDANCE_REPORT}
              exact
              component={AdminAttendanceReport}
            />
            <AdminRoute
              path={URL_PATH.ADMIN_MARK_UPLOAD_STATUS}
              exact
              component={AdminMarkUploadStatusScreen}
            />
            <AdminRoute
              path={URL_PATH.ADMIN_PERFORMANCE_REPORT}
              exact
              component={AdminPerformanceReport}
            />
            <AdminRoute
              path={URL_PATH.ADMIN_NEWS_EVENTS}
              exact
              component={NewsAndEventScreen}
            />
            <AdminRoute
              path={URL_PATH.ADMIN_TIMETABLE_DASHBOARD}
              exact
              component={AdminTimeTableDashboard}
            />
            <AdminRoute
              path={URL_PATH.ADMIN_CHANGEPASSWORD}
              exact
              component={AdminChangePasswordScreen}
            />
            <AdminRoute
              path={URL_PATH.ADMIN_STUDENT_PROFILE}
              exact
              component={AdminStudentProfile}
            />
            <AdminRoute
              path={URL_PATH.ADMIN_ACADEMIC_SCREEN}
              exact
              component={AdminAcademicScreen}
            />
            <AdminRoute
              path={URL_PATH.ADMIN_CLASS_SCREEN}
              exact
              component={AdminClassScreen}
            />
            <AdminRoute
              path={URL_PATH.ADMIN_CLASS_GROUP_SCREEN}
              exact
              component={AdminClassGroupScreen}
            />
            <AdminRoute
              path={URL_PATH.ADMIN_CHANGE_PASSWORD}
              exact
              component={AdminChangePassword}
            />
            <AdminRoute
              path={URL_PATH.ADMIN_ONBOARDING}
              exact
              component={AdminOnboarding}
            />
            <AdminRoute
              path={URL_PATH.ADMIN_TEACHER_PROFILE}
              exact
              component={AdminTeacherProfile}
            />
            <AdminRoute
              path={URL_PATH.ADMIN_STUDENT_PROFILE_FORM_DETAIL}
              exact
              component={AdminStudentFormDetails}
            />
            <AdminRoute
              path={URL_PATH.ADMIN_GRADE_MASTER}
              exact
              component={AdminGradeMasterScreen}
            />
            <AdminRoute
              path={URL_PATH.ADMIN_BEHAVIOUR}
              exact
              component={AdminBehavior}
            />
            <AdminRoute
              path={URL_PATH.ADMIN_REPORT_CARD}
              exact
              component={AdminReportCard}
            />
            <AdminRoute
              path={URL_PATH.ADMIN_QUEST_GEN}
              exact
              component={AdminQuestionGen}
            />
            {/* Admin Ends */}

            {/* Teacher starts */}
            <TeacherRoute
              path={URL_PATH.TEACHER}
              exact
              component={TeacherDashboard}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_PROFILE}
              exact
              component={TeacherProfile}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_STUDENT_RECORD}
              exact
              component={StudentRecord}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_STUDENT_PROFILE}
              exact
              component={StudentProfile}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_ATTENDANCE}
              exact
              component={TakeAttendance}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_VIEW_ATTENDANCE}
              exact
              component={ViewAttendance}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_ATTENDANCE_REPORT}
              exact
              component={AttendanceReport}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_GRADEBOOK}
              exact
              component={ExamHierarchy}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_MARKS}
              exact
              component={MarkScreen}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_EXAMS_MARKS}
              exact
              component={AssessmentMarkScreen}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_TERM_MARKS}
              exact
              component={TermMarkScreen}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_MARKS_ANALYTICS}
              exact
              component={QuickAnalytics}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_PERFORMANCE_REPORT}
              exact
              component={TeacherPerformanceReport}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_COMMUNICATION_DASHBOARD}
              exact
              component={TeacherCommunication}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_TASK_MANAGEMENT_SCREEN}
              exact
              component={TaskDashboard}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_BEHAVIOUR_DASHBOARD}
              exact
              component={BehaviourScreen}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_CHANGEPASSWORD}
              exact
              component={TeacherChangePasswordScreen}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_ASSIGNMENT}
              exact
              component={TeacherAssignmentScreen}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_ASSIGNMENT_DETAILS}
              exact
              component={TeacherAssignmentDetailScreen}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_ASSIGNMENT_VIEW_DETAILS}
              exact
              component={TeacherAssignmentViewDetails}
            />

            <TeacherRoute
              path={URL_PATH.TEACHER_SUBJECT_DETAILS}
              exact
              component={TeacherSubjectDetails}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_SUBJECT_ALLOTMENT}
              exact
              component={TeacherSubjectAllotment}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_TESTS}
              exact
              component={TeacherAssessmentScreen}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_CREATE_TESTS}
              exact
              component={CreateAssessmentScreen}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_TESTS_MARK_ENTRY}
              exact
              component={MarkEntryScreen}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_TESTS_MARK_EVALUATION}
              exact
              component={MarkEvaluationScreen}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_TESTS_REPORT}
              exact
              component={AssessmentReport}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_TESTS_VIEW_ALL}
              exact
              component={TeacherViewAllAssesments}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_TESTS_QUESTION_BANK}
              exact
              component={TeacherQuestionBank}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_STUDENT_FORM_DETAILS}
              exact
              component={FormDetails}
            />
            <TeacherRoute
              path={URL_PATH.TEACHER_SCHEDULE}
              exact
              component={TeacherScheduleScreen}
            />

            {/* Parent Route */}
            <ParentRoute
              path={URL_PATH.PARENT}
              exact
              component={ParentDashboard}
            />
            <ParentRoute
              path={URL_PATH.PARENT_PROFILE}
              exact
              component={ParentStudentProfile}
            />
            <ParentRoute
              path={URL_PATH.PARENT_COMMUNICATION}
              exact
              component={ParentCommunication}
            />
            <ParentRoute
              path={URL_PATH.PARENT_CHANGEPASSWORD}
              exact
              component={ParentChangePasswordScreen}
            />
            <ParentRoute
              path={URL_PATH.PARENT_STUDENT_ASSIGNMENT}
              exact
              component={StudentAssignmentScreen}
            />
            <ParentRoute
              path={URL_PATH.PARENT_STUDENT_TESTS}
              exact
              component={StudentAssessmentScreen}
            />
            <ParentRoute
              path={URL_PATH.STUDENT_TAKE_TESTS}
              exact
              component={TakeAssessmentScreen}
            />
            <ParentRoute
              path={URL_PATH.STUDENT_TESTS_REPORT}
              exact
              component={StudentAssessmentReport}
            />
            <ParentRoute
              path={URL_PATH.STUDENT_TESTS_RESULT}
              exact
              component={ParentAssignmentResultScreen}
            />
            <ParentRoute
              path={URL_PATH.STUDENT_PROFILE_FORM_DETAIL}
              exact
              component={StudentFormDetails}
            />
            <ParentRoute
              path={URL_PATH.PARENT_STUDENT_SCHEDULE}
              exact
              component={StudentScheduleScreen}
            />

            {/* founder Route */}
            <FounderRoute
              path={URL_PATH.FOUNDER}
              exact
              component={FounderDashboard}
            />
            <FounderRoute
              path={URL_PATH.FOUNDER_COMMUNICATION}
              exact
              component={FounderCommunication}
            />
            <FounderRoute
              path={URL_PATH.FOUNDER_PROFILE}
              exact
              component={FounderProfileScreen}
            />
            <FounderRoute
              path={URL_PATH.FOUNDER_CHANGEPASSWORD}
              exact
              component={FounderChangePasswordScreen}
            />
            <FounderRoute
              path={URL_PATH.FOUNDER_SUPER_ADMIN}
              exact
              component={FounderSuperAdminDashboard}
            />
            <FounderRoute
              path={URL_PATH.FOUNDER_EDIT_SUPER_ADMIN}
              exact
              component={FounderEditSuperAdmin}
            />
            <FounderRoute
              path={URL_PATH.FOUNDER_BEHAVIOUR}
              exact
              component={FounderBehaviour}
            />

            <FounderRoute
              path={URL_PATH.FOUNDER_ADMIN}
              exact
              component={FounderAdmin}
            />
            <FounderRoute
              path={URL_PATH.FOUNDER_TEACHER}
              exact
              component={FounderTeacher}
            />
            <FounderRoute
              path={URL_PATH.FOUNDER_STUDENT}
              exact
              component={FounderStudent}
            />
            <FounderRoute
              path={URL_PATH.FOUNDER_QUESTIONBANK}
              exact
              component={FounderQuestionBank}
            />

            <Route
              exact
              path={URL_PATH.ZOOM_INTEGRATION}
              component={ZoomIntegration}
            />
            <Route exact component={NotFoundPage} />
            <Route path={URL_PATH.NOT_FOUND} exact component={NotFoundPage} />
          </Switch>
        </BrowserRouter>
      </React.StrictMode>
    )
  }
}

export default App
