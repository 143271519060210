import React, { useState } from "react"
import { SortableContext, useSortable } from "@dnd-kit/sortable"
import { useDispatch } from "react-redux"
import { DndContext } from "@dnd-kit/core"
import { CSS } from "@dnd-kit/utilities"

import { SUCCESS_TOAST } from "../../../../utils/Constants"
import { showNotification } from "../../../../actions/CommonAction"
import { change } from "../common/utils"

import repeat from "../../../../assets/images/repeat.png"
import dots6 from "../../../../assets/images/6dots.png"

import AddOrEditSection from "../Modals/AddOrEditSection"
import Replace from "../Modals/Replace"
import SaveQP from "../Modals/SaveQP"
import Match from "../common/Match"

function Draggable({
  i,
  id,
  type,
  text,
  question,
  match,
  openReplace,
  quizDefaultSubjectChapter,
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    isDragging,
  } = useSortable({ id })

  return (
    <div
      ref={setNodeRef}
      style={{ transform: CSS.Translate.toString(transform) }}
      className="py-4 px-5 mb-3 bg-white br-5px position-relative"
    >
      <button
        {...listeners}
        {...attributes}
        style={{ cursor: isDragging ? "grabbing" : "grab" }}
        className="manual-quest-6dots position-absolute"
      >
        <img src={dots6} alt="" />
      </button>

      <p className="d-flex justify-content-between pl-4 fs-14 clr-A1A1A1">
        {quizDefaultSubjectChapter?.name}
      </p>

      <p
        className="mb-1 pl-4 fs-14 clr-201F1F"
        style={{ whiteSpace: "pre-wrap" }}
      >
        {i + 1}. {text}
      </p>

      {type === "MULTIPLE_CHOICE" &&
        question?.map((q, i) => (
          <p
            key={q}
            className="d-flex align-items-center pl-4 ml-4 mb-0 fs-14 clr-201F1F"
          >
            {change(q, i)}
          </p>
        ))}

      {type === "MATCHING" && <Match question={question} match={match} />}

      <button
        className="manual-quest-cancel fs-10 position-absolute bg-white br-10 clr-50AE93"
        onClick={openReplace}
      >
        <img width={20} height={20} src={repeat} alt="" />
        Replace
      </button>
    </div>
  )
}

function Options({ prevSum, title, topics, setData, openReplace }) {
  function handleDragEnd({ active, over }) {
    const activeIndex = active?.data?.current?.sortable?.index
    const overIndex = over?.data?.current?.sortable?.index
    const itemsCopy = [...topics]
    const [reorderedItem] = itemsCopy.splice(activeIndex, 1)
    itemsCopy.splice(overIndex, 0, reorderedItem)

    setData(prev =>
      prev.map(pr => {
        if (pr.quest === title) {
          return {
            ...pr,
            included: itemsCopy,
          }
        }

        return pr
      })
    )
  }

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <SortableContext id={title} items={topics.map(d => d.id)}>
        {topics.map((t, i) => (
          <Draggable
            i={i + prevSum}
            {...t}
            key={t.id}
            openReplace={() =>
              openReplace(
                i,
                t.defaultSubjectChapterId,
                t.quizDefaultSubjectChapter?.name,
                t.quizDefaultSubjectChapter?.masterSubjectId
              )
            }
          />
        ))}
      </SortableContext>
    </DndContext>
  )
}

function Step4({
  data,
  setData,
  chapters,
  selectedClass,
  selectedSubject,
  selectedChapter,
  classList,
  schoolDetails,
  toHome,
}) {
  const [replace, setReplace] = useState({})
  const [modal, setModal] = useState("")
  const dispatch = useDispatch()

  const closeModal = () => setModal("")

  const openReplace = (
    chapterIndex,
    topicIndex,
    defaultSubjectChapterId,
    chapterName,
    masterSubjectId
  ) => {
    setModal("replace")
    setReplace({
      chapterIndex,
      topicIndex,
      defaultSubjectChapterId,
      chapterName,
      masterSubjectId,
    })
  }

  const updateByReplacing = replacerIndex => {
    const itemsCopy = JSON.parse(JSON.stringify(data))
    const [reorderedOldItem] = itemsCopy[
      replace?.chapterIndex
    ]?.included.splice(replace?.topicIndex, 1)
    const [reorderedNewItem] = itemsCopy[
      replace?.chapterIndex
    ]?.excluded.splice(replacerIndex, 1)

    itemsCopy[replace?.chapterIndex].included.splice(
      replace?.topicIndex,
      0,
      reorderedNewItem
    )
    itemsCopy[replace?.chapterIndex].excluded.push(reorderedOldItem)
    setModal("")
    setData([...itemsCopy])
    dispatch(
      showNotification(SUCCESS_TOAST, "", "Question replaced successful")
    )
  }
  return (
    <>
      <div
        className="manual-quest-wrapper py-4 px-5 bg-FAFAFA"
        style={{ height: "75vh" }}
      >
        <div className="fw-700 mb-4">PREVIEW - Question paper</div>

        {data.map((c, i) => {
          return (
            <div key={c.id} className="mb-5 br-5px bg-F7F7F8">
              <div className="d-flex align-items-center justify-content-between mb-3 py-2 px-5 bg-white br-5px">
                <div>
                  <p className="mb-0 fs-14 clr-A1A1A1">Section {i + 1}</p>
                  <p className="d-flex gap-1rem mb-0">
                    <span className="fw-600 clr-201F1F">{c.displayName}</span>
                    <span
                      className="fs-14 clr-4472E9 cursor-pointer"
                      onClick={() => setModal("edit")}
                    >
                      Edit
                    </span>
                  </p>
                </div>

                <div className="mt-auto fs-14 clr-A1A1A1">
                  {c.mark * c.count} Marks
                </div>
              </div>

              <Options
                prevSum={
                  i === 0
                    ? 0
                    : data.reduce(
                        (prev, curr, j) =>
                          j < i ? prev + curr.included.length : prev,
                        0
                      )
                }
                title={c.quest}
                topics={c.included}
                setData={setData}
                openReplace={(
                  topicIndex,
                  defaultSubjectChapterId,
                  chapterName,
                  masterSubjectId
                ) =>
                  openReplace(
                    i,
                    topicIndex,
                    defaultSubjectChapterId,
                    chapterName,
                    masterSubjectId
                  )
                }
              />
            </div>
          )
        })}
      </div>

      <div className="d-flex align-items-center justify-content-center gap-18px mt-4">
        <button
          onClick={() => setModal("edit")}
          className="px-5 py-2 fs-16 fw-500 bg-F7F7F7 clr-8A8A8A br-8px"
        >
          Edit Section
        </button>

        <button
          onClick={() => setModal("save")}
          className="px-5 py-2 fs-16 fw-500 bg-50AE93 text-white br-8px"
        >
          Download
        </button>
      </div>

      {modal === "replace" && (
        <Replace
          data={data?.[replace?.chapterIndex]?.excluded}
          defaultSubjectChapterId={replace?.defaultSubjectChapterId}
          masterSubjectId={replace?.masterSubjectId}
          chapterName={replace?.chapterName}
          updateByReplacing={updateByReplacing}
          selectedSubject={selectedSubject}
          selectedChapter={selectedChapter}
          chapters={chapters}
          closeModal={closeModal}
        />
      )}

      {modal === "edit" && (
        <AddOrEditSection
          data={data}
          setData={setData}
          closeModal={closeModal}
        />
      )}

      {modal === "save" && (
        <SaveQP
          data={data}
          classId={classList.find(c => c.className === selectedClass)?.classId}
          schoolDetails={schoolDetails}
          selectedClass={selectedClass}
          selectedSubject={selectedSubject}
          closeModal={closeModal}
          toHome={toHome}
        />
      )}
    </>
  )
}

export default Step4
