import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { HashLoader } from "react-spinners"
import { connect } from "react-redux"
import {
  Button,
  Modal,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap"

import {
  getAllBehaviourRecognitionDetails,
  deleteBehaviourRecognition,
  saveBehaviourRecognition,
} from "../../../actions/founder/BehaviourActions"
import {
  showNotification,
  uploadFile,
  getFile,
} from "../../../actions/CommonAction"
import { DANGER_TOAST, VALIDATION_MSG } from "../../../utils/Constants"
import { confirmAlertPopUp } from "../../../utils/Utility"

import placeholder from "../../../assets/images/placeholder.png"
import AppWrapper from "../Wrapper"

class FounderBehaviour extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: "",
      behaviourName: "",
      behaviourIcon: "",
      saveBehaviour: "",
      behaviourType: "positive",
      behaviourPoints: "1",
      toggleState: 1,
      isAddModal: false,
      comploading: true,
    }
  }

  componentDidMount() {
    this.getDetails()
  }

  getDetails = () => {
    this.props.getAllBehaviourRecognitionDetails(success => {
      if (success) {
        this.setState({ comploading: false })
      }
    })
  }

  closeModal = () => {
    this.setState({
      id: "",
      isAddModal: false,
      behaviourName: "",
      behaviourPoints: "1",
      behaviourType: "positive",
      behaviourIcon: "",
      saveBehaviour: "",
    })
  }

  getBehaviourUrl = url => {
    this.props.getFile(url, (success, response) => {
      if (success) {
        this.setState({
          behaviourIcon: response.url,
          saveBehaviour: response.name,
        })
      }
    })
  }

  behaviourImage = async e => {
    await new Promise(resolve =>
      this.props.uploadFile(
        e.target.files[0],
        "behaviour/icons",
        (success, response) => {
          this.getBehaviourUrl(response.name)
          resolve(true)
        }
      )
    )
  }

  saveDetails = () => {
    if (!this.state.behaviourName) {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.ALERT,
        "Name cannot be empty"
      )
      return
    }
    if (!this.state.behaviourPoints) {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.ALERT,
        "Point cannot be empty"
      )
      return
    }
    if (!this.state.behaviourIcon) {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.ALERT,
        "Image cannot be empty"
      )
      return
    }

    let object = {
      recognitionImage: this.state.saveBehaviour,
      recognitionName: this.state.behaviourName,
      isPositive: this.state.behaviourType === "positive" ? 1 : 0,
      point: this.state.behaviourPoints,
    }

    if (this.state.id) {
      object.id = this.state.id
    }

    this.props.saveBehaviourRecognition(object, res => {
      if (res) {
        let toggleState = (this.state.behaviourType === "positive") == 1 ? 1 : 2
        this.toggleTab(toggleState)
        this.closeModal()
      }
    })
  }

  toggleTab = index => this.setState({ toggleState: index })

  deleteStudent = id => {
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT,
      yes => {
        if (yes) {
          this.props.deleteBehaviourRecognition(id, () => {})
        }
      },
      {
        buttons: [],
        customUI: ({ onClose }) => {
          return (
            <div
              className="custom"
              style={{
                backgroundColor: "#fff",
                borderBottomRightRadius: "12px",
                borderBottomLeftRadius: "12px",
              }}
            >
              <div
                className="bg-brand text-light d-flex"
                style={{
                  padding: "0.5rem 2rem",
                  borderTopRightRadius: "12px",
                  borderTopLeftRadius: "12px",
                }}
              >
                <div className="mx-auto fs-18">Delete</div>
              </div>

              <div className="mt-3 px-4 py-2">
                <div className="text-muted fs-15">
                  Are you sure you want to delete?
                </div>
                <div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
                  <button
                    className="bg-brand text-light px-4 py-1 w-25 d-flex align-items-center justify-content-center yes-button"
                    style={{ borderRadius: "5px" }}
                    onClick={() =>
                      this.props.deleteBehaviourRecognition(id, onClose)
                    }
                  >
                    Yes
                  </button>
                  <button
                    className="bg-brand text-light px-4 py-1 ml-4 w-25 d-flex align-items-center justify-content-center"
                    style={{ borderRadius: "5px" }}
                    onClick={onClose}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )
        },
      }
    )
  }

  editReg = item => {
    this.setState({
      id: item.id,
      isAddModal: true,
      behaviourName: item.recognitionName,
      behaviourIcon: item.recognitionImageUrl,
      saveBehaviour: item.recognitionImage,
      behaviourType: item.isPositive === 1 ? "positive" : "negative",
      behaviourPoints: item.point,
    })
  }

  render() {
    const { viewBehaviourList } = this.props // records coming from redux
    const { toggleState, comploading } = this.state

    return (
      <AppWrapper founderDashboard>
        <div className="container-fluid" style={{ height: "42rem" }}>
          <div className="row">
            <div className="col mt-1 navbar-brand text-theme font-weight-bolder fs-14">
              Behaviour
            </div>
            <div className="col mt-1" style={{ textAlign: "end" }}>
              <Button
                style={{
                  backgroundColor: "#06b191",
                  borderRadius: "10px",
                  borderStyle: "none",
                  cursor: "pointer",
                }}
                onClick={() => this.setState({ isAddModal: true })}
              >
                Add Behaviour
              </Button>
            </div>
          </div>
          {/* workspace content */}

          <div className="container tab-container">
            <div className="bloc-tabs justify-content-center">
              <button
                id="tb-1"
                className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                onClick={() => this.toggleTab(1)}
                style={{ width: "10rem", fontWeight: "bolder" }}
              >
                Positive
              </button>
              <button
                id="tb-2"
                style={{ width: "10rem", fontWeight: "bolder" }}
                className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                onClick={() => this.toggleTab(2)}
              >
                Negative
              </button>
            </div>

            {comploading ? (
              <div>
                <HashLoader
                  css={{
                    position: "fixed",
                    width: "100%",
                    height: "100%",
                    left: "0px",
                    top: "5rem",
                  }}
                  sizeUnit="px"
                  size={30}
                  color="#084353"
                  loading
                />
              </div>
            ) : (
              <div className="content-tabs">
                <div
                  className={
                    toggleState === 1 ? "content active-content" : "content"
                  }
                >
                  <div className="row row-cols-1 row-cols-md-4">
                    {viewBehaviourList
                      .filter(item => item.isPositive === 1)
                      .map(item => (
                        <div key={item.id} className="col mb-4">
                          <div className="all-behaviour-positve p-0 mr-3 mb-4 pb-1">
                            <div className="card-body p-2">
                              <div className="d-flex flex-row-reverse p-0">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="p-0 pr-1 nav-link"
                                  >
                                    <button
                                      className="fa fa-ellipsis-v"
                                      style={{
                                        fontSize: "12px",
                                        color: "black",
                                      }}
                                    ></button>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      className="text-black"
                                      data-toggle="modal"
                                      data-target="#assignModal"
                                      onClick={() => this.editReg(item)}
                                    >
                                      Edit
                                    </DropdownItem>
                                    <DropdownItem
                                      className="text-black"
                                      data-toggle="modal"
                                      data-target="#assignModal"
                                      onClick={() =>
                                        this.deleteStudent(item.id)
                                      }
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                              <img
                                src={
                                  item.recognitionImageUrl
                                    ? item.recognitionImageUrl
                                    : placeholder
                                }
                                className="student-pic center mx-auto d-block"
                                style={{ width: "83px", height: "50%" }}
                                alt={item.recognitionName}
                              />
                              <div className="text-center ml-2 mt-1 font-weight-bold">
                                <i className="point-card fa fa-plus ml-3"></i>
                                <span className="p-1"> {item.point} </span>
                              </div>
                              <p className="card-subtitle recog-name text-theme pt-2 pb-3 text-center">
                                {item.recognitionName}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                {/* Negative Tab */}
                <div
                  className={
                    toggleState === 2 ? "content  active-content" : "content"
                  }
                >
                  <div className="row row-cols-1 row-cols-md-4">
                    {viewBehaviourList
                      .filter(item => item.isPositive === 0)
                      .map(item => (
                        <div key={item.id} className="col mb-4">
                          <div className="all-behaviour p-0 mr-3 mb-4 pb-1">
                            <div className="card-body p-2">
                              <div className="d-flex flex-row-reverse p-0">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="p-0 pr-1 nav-link"
                                  >
                                    <button
                                      className="fa fa-ellipsis-v"
                                      style={{
                                        fontSize: "12px",
                                        color: "black",
                                      }}
                                    ></button>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      className="text-black"
                                      data-toggle="modal"
                                      data-target="#assignModal"
                                      onClick={() => this.editReg(item)}
                                    >
                                      Edit
                                    </DropdownItem>
                                    <DropdownItem
                                      className="text-black"
                                      data-toggle="modal"
                                      data-target="#assignModal"
                                      onClick={() =>
                                        this.deleteStudent(item.id)
                                      }
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                              <img
                                src={
                                  item.recognitionImageUrl
                                    ? item.recognitionImageUrl
                                    : placeholder
                                }
                                className="student-pic center mx-auto d-block"
                                style={{ width: "83px", height: "50%" }}
                                alt={item.recognitionName}
                              />
                              <div className="text-center ml-2 mt-1 font-weight-bold">
                                <i className="point-card fa fa-minus"></i>
                                <span className="p-1 text-theme">
                                  {" "}
                                  {item.point}
                                </span>
                              </div>
                              <p className="card-subtitle text-theme pt-2 pb-3 text-center recog-name">
                                {item.recognitionName}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <Modal
          isOpen={this.state.isAddModal}
          backdropClassName="modal-backdrop-show"
          modalClassName="modal-dialog-shadow-none"
          contentClassName="border-14px"
          centered
        >
          <div className="modal-body p-0 b-body">
            <header
              style={{
                fontSize: "19px",
                fontWeight: "bold",
                padding: "15px 40px 0 40px",
              }}
            >
              {this.state.id ? "Edit " : "Add "}Behaviour
            </header>

            <div style={{ padding: "0 40px 0 40px" }}>
              {!this.state.behaviourIcon ? (
                <label
                  className="d-flex flex-column align-items-center justify-content-center add-icon add-icon-hv"
                  htmlFor="img-select"
                >
                  <span
                    className="mt-1"
                    style={{ fontSize: "5rem", lineHeight: ".6" }}
                  >
                    +
                  </span>
                  Add icon
                </label>
              ) : (
                <div className="col-sm-6 px-0">
                  <div className="img-icon">
                    <img
                      src={this.state.behaviourIcon}
                      alt="behaviour icon"
                      style={{ width: "6rem" }}
                    />
                  </div>
                  <span
                    className="rounded-circle img-close"
                    onClick={() =>
                      this.setState({ behaviourIcon: "", saveBehaviour: "" })
                    }
                  >
                    x
                  </span>
                </div>
              )}
            </div>
            {/* IMage input  */}
            <input
              type="file"
              id="img-select"
              name="img-select"
              accept="png,gif,jpeg,jpg"
              onChange={this.behaviourImage}
              className="d-none"
            />

            <div style={{ padding: "16px 40px 0 40px" }}>
              <label className="font-weight-bold">Behaviour Name </label>
              <input
                type="text"
                className="b-input w-100"
                value={this.state.behaviourName}
                onChange={e =>
                  this.setState({
                    behaviourName: e.target.value.replace(/[^A-Za-z ]/gi, ""),
                  })
                }
              />
            </div>

            <div style={{ padding: "16px 40px 0 40px" }}>
              <label className="font-weight-bold">Behaviour Type </label>
              <div
                className="d-flex align-items-center"
                style={{ gap: ".5rem" }}
              >
                <input
                  type="radio"
                  id="Postive-be-ad"
                  name="for-ad-behav"
                  value="positive"
                  checked={this.state.behaviourType === "positive"}
                  onChange={e =>
                    this.setState({ behaviourType: e.target.value })
                  }
                />
                <label htmlFor="Postive-be-ad" className="mb-0 mr-2">
                  Postive
                </label>

                <input
                  type="radio"
                  id="Negative-be-ad"
                  name="for-ad-behav"
                  value="negative"
                  checked={this.state.behaviourType === "negative"}
                  onChange={e =>
                    this.setState({ behaviourType: e.target.value })
                  }
                />
                <label htmlFor="Negative-be-ad" className="mb-0">
                  Negative
                </label>
              </div>
            </div>

            <div style={{ padding: "16px 40px 0 40px" }}>
              <label className="font-weight-bold">Points</label>
              <div>
                <input
                  className="b-input"
                  type="text"
                  value={this.state.behaviourPoints}
                  onChange={e =>
                    this.setState({ behaviourPoints: e.target.value })
                  }
                />
              </div>
            </div>

            <div
              className="col-md-12 pb-3 mt-2 text-right"
              style={{
                position: "absolute",
                bottom: "0",
                padding: "0 40px 0 40px",
              }}
            >
              <Button
                style={{
                  backgroundColor: "#DBDBDB",
                  color: "#06B191",
                  width: "6rem",
                  fontSize: "15px",
                  borderRadius: "11px",
                  borderStyle: "none",
                  marginRight: "15px",
                }}
                onClick={this.closeModal}
              >
                Cancel
              </Button>
              <Button
                style={{
                  backgroundColor: "#06b191",
                  width: "6rem",
                  fontSize: "15px",
                  borderRadius: "11px",
                  borderStyle: "none",
                  marginRight: "15px",
                }}
                onClick={this.saveDetails}
              >
                Save
              </Button>
            </div>
          </div>
        </Modal>
      </AppWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    viewBehaviourList: state.founderBehaviourState.behaviourViewList,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAllBehaviourRecognitionDetails,
      deleteBehaviourRecognition,
      saveBehaviourRecognition,
      showNotification,
      uploadFile,
      getFile,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(FounderBehaviour)
