/*************************************************
 * RedInk
 * @exports
 * @class StudentRecord.js
 * @extends Component
 * @author Sasidharan // on 01/02/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component } from "react"
import AppWrapper from "../Wrapper1"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Progress } from "react-sweet-progress"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import {
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap"
import { Modal as BSModal } from "reactstrap"
import "react-sweet-progress/lib/style.css"
import boyPic from "../../../assets/images/boy.png"
import girlPic from "../../../assets/images/girl.png"
import { URL_PATH } from "../../../config/urlPath"
import {
  getStudentProfileBasicInfo,
  getStudentProfileExamInfo,
  getStudentProfileAttendanceInfo,
  getStudentProfileAssignmentInfo,
  getStudentLast5Score,
} from "../../../actions/common/StudentProfileAction"
import moment from "moment"
import { getExamListDetails } from "../../../actions/admin/PerformanceReportAction"
import { getSubjectDetailsToPR } from "../../../actions/admin/SubjectDetailsAction"
import { getCommunicationLabelDetails } from "../../../actions/admin/CommunicationDetailsAction"
import { getAcademicMonthList } from "../../../actions/teacher/AttendanceReportAction"
import { getGradeDetails } from "../../../actions/admin/GradeDetailsAction"
import { HashLoader } from "react-spinners"
import {
  DANGER_TOAST,
  MAX_FILE_UPLOAD,
  VALIDATION_MSG,
} from "../../../utils/Constants"
import { saveCommunicationDetails } from "../../../actions/admin/CommunicationDetailsAction"
import { myLog } from "../../../utils/Utility"

class StudentProfile extends Component {
  constructor(props) {
    super(props)
    document.title = "Profile | Redink"
    this.state = {
      isSubjectsModalOpen: false,
      isGender: "M",
      monthName: "Over All",
      selectedSubjectName: "Over All",
      isShowLoading: false,
      //Email/sms
      sendEmail: true,
      sendSms: false,
      messageBody: "",
      messageSubject: "",
      attachments: [],
      grades: [],
    }
  }

  componentDidMount = () => {
    this.setState({ isShowLoading: true }, () => {
      let object = {
        studentId: this.props.location.state.studentId,
      }
      this.props.getStudentProfileBasicInfo(object, (success, response) => {
        this.setState({ isShowLoading: false })
        if (success) {
          this.setState({
            studentBasicInfo: response,
          })
          this.props.getExamListDetails({}, (examSuccess, examResponse) => {
            if (examSuccess) {
              this.setState({
                examList: examResponse,
                selectedExam: examResponse[0].assessmentId,
                selectedExamStudentAcademicPerformance:
                  examResponse[0].assessmentId,
                assessmentName: examResponse[0].value,
              })
              let examObject = {
                studentId: this.props.location.state.studentId,
                assessmentId: examResponse[0].assessmentId,
              }
              this.props.getStudentProfileExamInfo(
                examObject,
                (assessmentSuccess, assessmentResponse) => {
                  if (assessmentSuccess) {
                    this.setState({
                      studentExamInfo: assessmentResponse,
                    })
                  }
                }
              )
            }
          })

          this.props.getStudentLast5Score(object, (success, response) => {
            if (success) {
              this.setState({
                studentLast5Score:
                  response.assessments && response.assessments.rows
                    ? response.assessments.rows
                    : null,
              })
            }
          })

          this.props.getAcademicMonthList((success, response) => {
            if (success) {
              this.setState({ academicMonthList: response.data.months })
            }
          })
          let attenanceObject = {
            studentId: this.props.location.state.studentId,
            sectionId: response.studentDetail.section.sectionId,
          }
          this.props.getStudentProfileAttendanceInfo(
            attenanceObject,
            (success, response) => {
              if (success) {
                this.setState({
                  studentAttendanceInfo: response,
                })
              }
            }
          )
          this.props.getSubjectDetailsToPR({}, (success, response) => {
            if (success) {
              this.setState({
                classSubjectList: response,
              })
            }
          })
          this.props.getStudentProfileAssignmentInfo(
            attenanceObject,
            (success, response) => {
              if (success) {
                this.setState({
                  studentAssignmentInfo: response,
                })
              }
            }
          )
          this.getCommunicationLabel()
        }
      })
    })

    this.props.getGradeDetails({}, (success, response) => {
      if (success) {
        if (response.length > 0) {
          this.setState({ grades: response })
        }
      }
    })
  }

  getCommunicationLabel = () => {
    this.props.getCommunicationLabelDetails((success, response) => {
      if (success) {
        this.setState({ labelList: response })
      }
    })
  }

  clickSend = () => {
    this.setState({ isShowSaveLoading: true }, () => {
      let formData = new FormData()
      const wayOfMessage = []
      let receiverArray = []
      let object = {
        recipientId: this.props.location.state.studentId,
        sectionId: this.state.studentBasicInfo.studentDetail.section.sectionId,
      }
      receiverArray.push(object)
      if (this.state.sendEmail) wayOfMessage.push("EMAIL")
      if (this.state.sendSms) wayOfMessage.push("SMS")
      formData.append("wayOfMessage", JSON.stringify(wayOfMessage))
      formData.append("recipients", JSON.stringify(receiverArray))
      formData.append("title", this.state.messageSubject || "")
      formData.append("content", this.state.messageBody || "")
      formData.append("labelId", this.state.labelId || "")
      if (this.state.sendEmail) {
        for (let i = 0; i < this.state.attachments.length; i++) {
          formData.append("attachments", this.state.attachments[i])
        }
      }
      this.props.saveCommunicationDetails(formData, (success, response) => {
        this.setState({ isShowSaveLoading: false }, () => {})
        if (success) {
        }
      })
    })
  }
  sortGrades = data => {
    const grades = data
    grades.sort((a, b) =>
      a.charCodeAt(0) === b.charCodeAt(0)
        ? (a.charCodeAt(1) || 44) - (b.charCodeAt(1) || 44)
        : a.charCodeAt(0) - b.charCodeAt(0)
    )
    return grades
  }

  render() {
    myLog("class subject", this.state.classSubjectList)

    return (
      <>
        <BSModal
          isOpen={this.state.isSubjectsModalOpen}
          backdropClassName="modal-backdrop-show"
          modalClassName="modal-dialog-shadow-none"
          contentClassName="border-14px"
          centered={true}
          size="md"
        >
          <div
            className="cursor-pointer ml-auto px-3 py-2 bg-white"
            style={{ borderTopRightRadius: 10 }}
          >
            <i
              className="fas fa-times text-muted "
              onClick={() => {
                this.setState({ isSubjectsModalOpen: false })
              }}
            ></i>
          </div>
          <div
            className=" text-dark"
            style={{
              padding: "20px 50px",
            }}
          >
            <div style={{}}>
              <div className="text-muted font-weight-bold">Class Teacher</div>
              <div className="d-flex text-muted my-4">
                <div>
                  Class{" "}
                  {this.state.studentBasicInfo &&
                    this.state.studentBasicInfo.studentDetail.section
                      .className +
                      " - " +
                      this.state.studentBasicInfo.studentDetail.section
                        .sectionName}
                </div>
                <div className="d-flex ml-auto">
                  <img
                    src={require("../../../assets/images/female.png")}
                    style={{ width: 20, height: 20 }}
                    alt=""
                  />
                  <div className="ml-3">
                    {this.state.studentBasicInfo
                      ? this.state?.studentBasicInfo?.teacher?.firstName +
                        " " +
                        this.state?.studentBasicInfo?.teacher?.lastName
                      : ""}
                  </div>
                </div>
              </div>
              <div
                style={{
                  borderBottom: "2px solid #EEF2F3",
                  margin: "25px 10px",
                }}
              />
              <div className="text-muted font-weight-bold">Subject Teacher</div>
              {this.state.studentBasicInfo &&
                this.state.studentBasicInfo.teachersAndSubjects.map(teacher => {
                  return (
                    <div className="d-flex text-muted my-4 align-items-center justify-content-center">
                      <div>{teacher.subject.subjectName}</div>
                      <div className="d-flex ml-auto">
                        {teacher.teacher.gender === "M" ? (
                          <img
                            src={require("../../../assets/images/men.png")}
                            style={{ width: 20, height: 20 }}
                            alt=""
                          />
                        ) : (
                          <img
                            src={require("../../../assets/images/female.png")}
                            style={{ width: 20, height: 20 }}
                            alt=""
                          />
                        )}
                        <div className="ml-3">
                          {teacher.teacher.firstName +
                            " " +
                            teacher.teacher.lastName}
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </BSModal>

        <BSModal
          isOpen={this.state.isEmailModalOpen}
          backdropClassName="modal-backdrop-show"
          modalClassName="modal-dialog-shadow-none"
          contentClassName="border-14px"
          centered={true}
          size="md"
        >
          <div
            className="d-flex p-3"
            style={{
              backgroundColor: "#f8f9fa",
              borderTopRightRadius: 14,
              borderTopLeftRadius: 14,
            }}
          >
            <div className="d-flex align-items-center w-100">
              <div
                className="text-dark mr-auto font-weight-bold"
                style={{ fontSize: 18 }}
              >
                Create Message
              </div>
              {/* <div
                className=" br-10 py-2 px-3 font-weight-bold mr-3"
                style={{ backgroundColor: "rgba(71,153,151,.22)", width: 180 }}
              >
                <div className="d-flex align-items-center ml-auto ">
                  <div>Daily Usage:</div>
                  <div>0/100</div>
                  <i
                    className="fa fa-info-circle text-dark ml-auto"
                    aria-hidden="true"
                  ></i>
                </div>
              </div> */}
            </div>
            <div className="d-flex cursor-pointer ml-auto" style={{}}>
              <i
                className="fas fa-times text-muted "
                onClick={() => {
                  this.setState({
                    isEmailModalOpen: false,
                    sendEmail: true,
                    sendSms: false,
                    messageBody: "",
                    messageSubject: "",
                    attachments: [],
                  })
                }}
              ></i>
            </div>
          </div>
          <div className="px-3 my-3">
            <form>
              {/* <div className="form-group">
                <lablel>To</lablel>
                <div>
                  <MySelect
                    placeholder="Select Student"
                    name="classes"
                    isClearable
                    // value={this.state.assignedStudents}
                    options={["A", "B", "C"]}
                    className="basic-multi-select mb-3"
                    classNamePrefix="select"
                    isMulti
                    allowSelectAll={true}
                    // components={{
                    //   Option,
                    //   MultiValue,
                    //   ValueContainer,
                    //   animatedComponents,
                    // }}
                    hideSelectedOptions={false}
                    closeMenuOnSelect={false}
                    // onChange={selectedOption => {
                    //   if (selectedOption !== null) {
                    //     this.setState({
                    //       assignedStudents: selectedOption,
                    //       assignedStudentId: selectedOption.id,
                    //       assignedStudentName: selectedOption.value,
                    //     })
                    //   } else {
                    //     this.setState({ assignedStudents: selectedOption })
                    //   }
                    // }}
                  />
                </div>
              </div> */}
              <div className="form-group">
                <label>Subject</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control mr-2 rounded"
                    value={this.state.messageSubject}
                    onChange={e =>
                      this.setState({ messageSubject: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="form-group">
                <button
                  className={`btn primary-outline-btn right-br ${
                    this.state.sendEmail
                      ? "primary-btn text-light"
                      : "btn-hover"
                  }`}
                  type="button"
                  onClick={() =>
                    this.setState({ sendEmail: !this.state.sendEmail })
                  }
                >
                  <i className="fas fa-at mr-2"></i>EMAIL
                </button>
                <button
                  className={`btn primary-outline-btn left-br ${
                    this.state.sendSms ? "primary-btn text-light" : "btn-hover"
                  }`}
                  type="button"
                  onClick={() =>
                    this.setState({ sendSms: !this.state.sendSms })
                  }
                >
                  <i className="fas fa-mobile-alt mr-2"></i>SMS
                </button>
                <i
                  className="fas fa-info-circle ml-3"
                  id="sms-info"
                  aria-hidden="true"
                ></i>
                <UncontrolledTooltip
                  placement="top"
                  className="bg-danger"
                  target="sms-info"
                >
                  Select mode of communication, you can select both and send the
                  message in email as well as SMS at the same time
                </UncontrolledTooltip>
                <br />
              </div>
              {this.state.sendEmail && (
                <div>
                  {this.state.attachments.length === 0 ? (
                    <div className="row mb-2">
                      <span className="col-sm ml-2 font-weight-bold fs-12 text-primary">
                        <label>
                          <input
                            type="file"
                            multiple="multiple"
                            style={{ display: "none" }}
                            onChange={e => {
                              const files = e.target.files
                              if (files) {
                                for (const file of files) {
                                  if (file.size > MAX_FILE_UPLOAD) {
                                    return this.props.showNotification(
                                      DANGER_TOAST,
                                      VALIDATION_MSG.OOPS,
                                      "File size should be less than 20MB..."
                                    )
                                  }
                                }
                                this.setState(state => ({
                                  attachments: [...state.attachments, ...files],
                                }))
                              }
                            }}
                          />
                          <u className="cursor-pointer">+ Add Attachment</u>
                          <i className="fa fa-paperclip pl-1 col-sm-1 ml-0 pl-0 text-primary fs-15 cursor-pointer" />
                        </label>
                      </span>
                    </div>
                  ) : null}
                  {this.state.attachments.map((attachment, index) => (
                    <div className="mx-2 d-flex flex-column justify-content-start">
                      <div className="row m-2 mt-0 d-flex row bg-theme-verylight rounded-lg justify-content-between">
                        <div className="row">
                          <div
                            className="bg-theme-light rounded p-3"
                            style={{ width: "58px", height: "58px" }}
                          >
                            {attachment.type === "application/pdf" ? (
                              <img
                                src={require("../../../assets/images/assignment/pdf.svg")}
                                alt="pdf"
                                width="30"
                                height="30"
                              />
                            ) : (
                              <i
                                className="fa fa-paperclip "
                                style={{
                                  fontSize: "35px",
                                  color: "#0DB191",
                                }}
                              ></i>
                            )}
                          </div>
                          <div className="ml-3 align-self-center">
                            {attachment.name}
                          </div>
                        </div>
                        <div className="mr-3 align-self-center">
                          <i
                            className={`far fa-times fa-clock-hover`}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Remove"
                            onClick={() => {
                              this.setState(state => ({
                                attachments: state.attachments
                                  .map((e, i) => (i !== index ? e : undefined))
                                  .filter(x => x),
                              }))
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <div className="form-group">
                <label htmlFor="Label" className="font-weight-bold">
                  Label
                </label>
                <select
                  className="custom-select"
                  value={this.state.labelId}
                  onChange={e => {
                    let labelId = e.target.value
                    if (labelId === "Select a label") {
                      labelId = ""
                    }
                    this.setState({ labelId })
                  }}
                >
                  <option>Select a label</option>
                  {this.state.labelList &&
                    this.state.labelList.map((label, i) => (
                      <option value={label.id} key={i}>
                        {label.name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="form-group">
                <label>Message</label>
                <textarea
                  rows={5}
                  className="form-control"
                  value={this.state.messageBody}
                  onChange={e => this.setState({ messageBody: e.target.value })}
                />
              </div>
              <button
                type="button"
                className="btn bg-theme text-white"
                disabled={this.state.isShowSaveLoading}
                onClick={this.clickSend}
              >
                {this.state.isShowSaveLoading ? (
                  <HashLoader
                    sizeUnit={"px"}
                    size={16}
                    color={"#ffffff"}
                    loading={this.state.isShowSaveLoading}
                  />
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </BSModal>

        <AppWrapper
          showAdminProfileSidemenu
          showSchoolLogo
          showAdminThree
          screenName={"Student Profile"}
        >
          {this.state.isShowLoading ? (
            <div
              className="d-flex justify-content-center"
              style={{ position: "absolute", top: "50%", left: "50%" }}
            >
              <HashLoader
                sizeUnit={"px"}
                size={35}
                color={"#084353"}
                loading={this.state.isShowLoading}
              />
            </div>
          ) : (
            <div className="row">
              {this.state.studentBasicInfo && (
                <div className="col-sm-3">
                  <div className="card p-3 br-10  text-white shadow profile1">
                    <div
                      className="d-flex align-items-center justify-content-center br-8 px-2 py-1 ml-auto"
                      style={{
                        backgroundColor: "rgba(9, 177, 144,0.3)",
                        color: "rgb(9, 177, 144)",
                        borderRadius: 7,
                      }}
                    >
                      <div
                        className="rounded-pill mx-2"
                        style={{
                          width: 6,
                          height: 6,
                          // backgroundColor: isOnline ? "#15af8f" : "#f16d6f",
                          backgroundColor: "#15af8f",
                        }}
                      />
                      <div style={{ fontSize: 10 }}>ON TRACK</div>
                    </div>
                    <img
                      className="img1"
                      style={{ marginTop: 0, width: "30%" }}
                      src={
                        this.state.studentBasicInfo &&
                        this.state.studentBasicInfo.studentDetail.gender === "M"
                          ? boyPic
                          : girlPic
                      }
                      alt="pic"
                    />
                    <h6 className="mt-2">
                      {this.state.studentBasicInfo
                        ? this.state.studentBasicInfo.studentDetail
                            .studentFirstName +
                          " " +
                          this.state.studentBasicInfo.studentDetail
                            .studentLastName
                        : ""}
                    </h6>
                    {/* <UncontrolledDropdown
                  style={{
                    // opacity: this.state.isSearchInputFocused ? "0" : "1",
                    transition: "150ms all ease-in",
                  }}
                >
                  <DropdownToggle
                    tag="div"
                    className="d-flex cursor-pointer p-2 mr-2"
                  > */}
                    <div className="d-flex align-items-center justify-content-center">
                      <div>
                        {this.state.studentBasicInfo &&
                          this.state.studentBasicInfo.studentDetail.section
                            .className +
                            " - " +
                            this.state.studentBasicInfo.studentDetail.section
                              .sectionName}
                      </div>
                      {/* <i
                        className="fa fa-chevron-down ml-2"
                        aria-hidden="true"
                      ></i> */}
                    </div>
                    {/* </DropdownToggle>
                  <DropdownMenu
                    tag="div"
                    className="rounded border-1 border-muted px-2 py-2"
                  >
                    <DropdownItem>7A</DropdownItem>
                    <DropdownItem>8A</DropdownItem>
                    <DropdownItem>9A</DropdownItem>
                    <DropdownItem>6A</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
                    <h6 className="my-2">
                      {this.state.studentBasicInfo &&
                        moment(
                          this.state.studentBasicInfo.academicYear.academicFrom
                        ).format("YYYY") +
                          " - " +
                          moment(
                            this.state.studentBasicInfo.academicYear.academicTo
                          ).format("YYYY")}
                    </h6>
                  </div>
                  <div className="card p-3 br-10 mt-2  text-white shadow ">
                    <div className="d-flex">
                      <p
                        className="text-muted"
                        style={{ color: "#4d4d4d", fontWeight: "bold" }}
                      >
                        Info.
                      </p>
                      {/* <div className="d-flex ml-auto text-primary">
                        <p style={{ color: "#15af8f" }}>Active</p>
                        <p style={{ color: "#15af8f" }}>Toggle</p>
                      </div> */}
                    </div>
                    <div className="d-flex align-items-center">
                      <img
                        src={require("../../../assets/images/admission-no-icon-01.png")}
                        style={{ width: 20, height: 20 }}
                        alt=""
                      />
                      <div className="text-muted ml-3" id="admissionNo">
                        {this.state.studentBasicInfo &&
                          this.state.studentBasicInfo.studentDetail
                            .admissionNumber}
                      </div>
                      <UncontrolledTooltip placement="top" target="admissionNo">
                        Admission No.
                      </UncontrolledTooltip>
                    </div>
                    <div className="d-flex my-3 align-items-center">
                      <img
                        src={require("../../../assets/images/register-no-or-roll-no-icon-01.png")}
                        style={{ width: 20, height: 20 }}
                        alt=""
                      />
                      <div className="text-muted ml-3" id="registrationNo">
                        {this.state.studentBasicInfo &&
                          this.state.studentBasicInfo.studentDetail
                            .registerRollNumber}
                      </div>
                      <UncontrolledTooltip
                        placement="top"
                        target="registrationNo"
                      >
                        Registration/Roll No.
                      </UncontrolledTooltip>
                    </div>
                    <div className="d-flex align-items-center">
                      <img
                        src={require("../../../assets/images/DOB-ICON-01.png")}
                        style={{ width: 20, height: 20 }}
                        alt=""
                      />
                      <div className="text-muted ml-3" id="dateOfBirth">
                        {this.state.studentBasicInfo &&
                          moment(
                            this.state.studentBasicInfo.studentDetail.dob
                          ).format("DD MMM YYYY")}
                      </div>
                      <UncontrolledTooltip placement="top" target="dateOfBirth">
                        Date of Birth
                      </UncontrolledTooltip>
                    </div>
                    <div className="d-flex my-4">
                      <div
                        className="text-muted"
                        style={{ color: "#4d4d4d", fontWeight: "bold" }}
                      >
                        Parent Details
                      </div>
                      <div
                        className="ml-auto cursor-pointer"
                        style={{ color: "#15af8f" }}
                        onClick={() => {
                          this.props.history.push({
                            pathname:
                              URL_PATH.ADMIN_STUDENT_PROFILE_FORM_DETAIL,
                            state: {
                              studentId: this.props.location.state.studentId,
                            },
                          })
                        }}
                      >
                        View more
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      {this.state.studentBasicInfo.studentDetail
                        .primaryParentGender === "M" ? (
                        <img
                          src={require("../../../assets/images/men.png")}
                          style={{ width: 20, height: 20 }}
                          alt=""
                        />
                      ) : (
                        <img
                          src={require("../../../assets/images/female.png")}
                          style={{ width: 20, height: 20 }}
                          alt=""
                        />
                      )}

                      <div className="text-muted ml-3">
                        {this.state.studentBasicInfo
                          ? this.state.studentBasicInfo.studentDetail
                              .primaryParentName
                          : ""}
                      </div>
                    </div>
                    <div className="d-flex my-3 align-items-center">
                      <img
                        src={require("../../../assets/images/mail-icon-01.png")}
                        style={{ width: 20, height: 20 }}
                        alt=""
                      />
                      <div className="text-muted ml-3">
                        {this.state.studentBasicInfo
                          ? this.state.studentBasicInfo.studentDetail
                              .primaryParentEmail
                          : ""}
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <img
                        src={require("../../../assets/images/Phone-icon-01.png")}
                        style={{ width: 20, height: 20 }}
                        alt=""
                      />
                      <div className="text-muted ml-3">
                        {this.state.studentBasicInfo
                          ? this.state.studentBasicInfo.studentDetail
                              .primaryParentPhone
                          : ""}
                      </div>
                    </div>
                    <div className="d-flex justify-content-center my-4">
                      <button
                        className="px-5 py-1 br-10"
                        style={{ backgroundColor: "#15af8f" }}
                        onClick={() => {
                          this.setState({ isEmailModalOpen: true })
                        }}
                      >
                        Send Email/Sms
                      </button>
                    </div>
                  </div>
                  <div className="card p-3 br-10 mt-2 text-dark shadow mb-2">
                    <div className="d-flex">
                      <div
                        className="text-muted"
                        style={{ color: "#4d4d4d", fontWeight: "bold" }}
                      >
                        Class Teacher
                      </div>
                      <div
                        className="ml-auto cursor-pointer"
                        style={{ color: "#15af8f" }}
                        onClick={() => {
                          this.setState({ isSubjectsModalOpen: true })
                        }}
                      >
                        View Subjects
                      </div>
                    </div>
                    <div className="d-flex align-items-center my-3">
                      {this.state.isGender === "M" ? (
                        <img
                          src={require("../../../assets/images/men-icon.png")}
                          style={{ width: 20, height: 20 }}
                          alt=""
                        />
                      ) : (
                        <img
                          src={require("../../../assets/images/women-icon.png")}
                          style={{ width: 20, height: 20 }}
                          alt=""
                        />
                      )}
                      <div className="text-muted ml-3">
                        {this.state.studentBasicInfo
                          ? this.state?.studentBasicInfo?.teacher?.firstName +
                            " " +
                            this.state?.studentBasicInfo?.teacher?.lastName
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="card p-3 br-10 mt-2  text-dark shadow ">
                    <div
                      className="my-3 text-brand"
                      style={{ color: "#000", fontWeight: "bold" }}
                    >
                      Recent Activity
                    </div>
                    <div>
                      <ul className="timeline">
                        <li>
                          <div className="text-muted" style={{ color: "#000" }}>
                            <span style={{ fontWeight: "bold" }}>
                              Christopher
                            </span>{" "}
                            submitted science assignment.
                          </div>
                          <div style={{ marginTop: 0 }} className="text-muted">
                            2 days ago
                          </div>
                        </li>
                        <li>
                          <div className="text-muted" style={{ color: "#000" }}>
                            <span style={{ fontWeight: "bold" }}>
                              Christopher
                            </span>{" "}
                            submitted science assignment.
                          </div>
                          <div style={{ marginTop: 0 }} className="text-muted">
                            2 days ago
                          </div>
                        </li>
                        <li>
                          <div className="text-muted" style={{ color: "#000" }}>
                            <span style={{ fontWeight: "bold" }}>
                              Christopher
                            </span>{" "}
                            submitted science assignment.
                          </div>
                          <div style={{ marginTop: 0 }} className="text-muted">
                            2 days ago
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-sm" style={{ paddingLeft: 0 }}>
                {this.state.studentAttendanceInfo && (
                  <div className="card p-3 br-10 text-dark shadow ">
                    <div className="d-flex align-items-center align-items-center">
                      <div
                        className="text-brand font-weight-bold "
                        style={{ fontSize: 18 }}
                      >
                        Exam
                      </div>
                      <UncontrolledDropdown
                        className="ml-2"
                        style={{
                          // opacity: this.state.isSearchInputFocused ? "0" : "1",
                          transition: "150ms all ease-in",
                        }}
                      >
                        <DropdownToggle
                          tag="div"
                          className="d-flex cursor-pointer p-2 mr-2"
                        >
                          <div className="d-flex align-items-center justify-content-center">
                            <div className="text-muted">
                              {this.state.assessmentName}
                            </div>
                            <i
                              className="fa fa-chevron-down ml-1 text-muted"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </DropdownToggle>
                        <DropdownMenu
                          tag="div"
                          className="rounded border-1 border-muted px-2 py-2 subDropdown"
                        >
                          {this.state.examList &&
                            this.state.examList.map(item => {
                              return (
                                <DropdownItem
                                  onClick={() => {
                                    this.setState({
                                      assessmentName: item.value,
                                    })
                                    let examObject = {
                                      studentId: this.props.location.state
                                        .studentId,
                                      assessmentId: item.assessmentId,
                                    }
                                    this.props.getStudentProfileExamInfo(
                                      examObject,
                                      (
                                        assessmentSuccess,
                                        assessmentResponse
                                      ) => {
                                        if (assessmentSuccess) {
                                          this.setState({
                                            studentExamInfo: assessmentResponse,
                                          })
                                        }
                                      }
                                    )
                                  }}
                                  className="dropdownItem"
                                >
                                  {item.value}
                                </DropdownItem>
                              )
                            })}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                    <div>
                      {this.state.studentExamInfo &&
                      this.state.studentExamInfo.assessmentCompleted ? (
                        <div className="row">
                          <div>
                            <div className="mx-5 d-flex mt-3 justify-content-center">
                              <div>
                                <div
                                  className="text-muted px-4 py-3 br-10"
                                  style={{ backgroundColor: "#EEF2F3" }}
                                >
                                  <div className="d-flex align-items-center">
                                    <div
                                      className="text-brand  fs-20"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {this.state.studentExamInfo &&
                                        this.state.studentExamInfo.forAssessment
                                          .studentRank}
                                    </div>
                                    {/* <img
                              className="ml-3"
                              style={{ height: 30, width: 30 }}
                              src={require("../../../assets/images/down-arrow-01.png")}
                              alt=""
                            /> */}
                                  </div>
                                  <div className="text-muted">Rank</div>
                                </div>
                                <div
                                  className="text-muted px-4 py-3 mt-3 br-10"
                                  style={{ backgroundColor: "#EEF2F3" }}
                                >
                                  <div
                                    className="text-brand  fs-20"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {this.state.studentExamInfo &&
                                      this.state.studentExamInfo.forAssessment
                                        .schoolRank}
                                  </div>

                                  <div className="text-muted">School Rank</div>
                                </div>
                              </div>
                              <div
                                className="my-auto"
                                style={{
                                  borderLeft: "2px solid #EEF2F3",
                                  margin: "0px 50px",
                                  height: 120,
                                }}
                              />
                              <div>
                                <div
                                  className="text-muted px-4 py-3 br-10"
                                  style={{ backgroundColor: "#EEF2F3" }}
                                >
                                  <div
                                    className="text-brand  fs-20"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {this.state.studentExamInfo &&
                                      this.state.studentExamInfo.forAssessment
                                        .percentage}
                                  </div>

                                  <div className="text-muted">Percentage</div>
                                </div>
                                <div
                                  className="text-muted px-4 py-3 mt-3 br-10"
                                  style={{ backgroundColor: "#EEF2F3" }}
                                >
                                  <div className="text-brand">
                                    <span
                                      className="fs-20"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {this.state.studentExamInfo &&
                                        this.state.studentExamInfo.forAssessment
                                          .mark}{" "}
                                      /{" "}
                                      <span
                                        className="fs-20"
                                        style={{ fontWeight: "normal" }}
                                      >
                                        {this.state.studentExamInfo &&
                                          this.state.studentExamInfo
                                            .forAssessment.outOffMark}
                                      </span>
                                    </span>
                                  </div>

                                  <div className="text-muted">
                                    Overall Marks
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="text-brand my-3 mx-3">Grade</div>
                            <div
                              className="d-flex w-75 mx-auto py-2 mb-"
                              style={{
                                border: "2px solid rgba(160, 162, 165,0.57)",
                                borderRadius: "20px",
                              }}
                            >
                              {this.state.grades.length > 0 &&
                                this.state.grades
                                  .sort((a, b) =>
                                    parseInt(a.gradeFrom) <
                                    parseInt(b.gradeFrom)
                                      ? 1
                                      : parseInt(a.gradeFrom) >
                                        parseInt(b.gradeFrom)
                                      ? -1
                                      : 0
                                  )
                                  .map((item, index) => (
                                    <div
                                      className={`${
                                        this.state.studentExamInfo &&
                                        this.state.studentExamInfo.forAssessment
                                          .grade == item.gradeName
                                          ? "d-flex activeBar justify-content-center"
                                          : ""
                                      } ${
                                        index === 0 ? "ml-3" : ""
                                      } px-1 col cursor-pointer`}
                                    >
                                      <div
                                        className={
                                          this.state.studentExamInfo &&
                                          this.state.studentExamInfo
                                            .forAssessment.grade ===
                                            item.gradeName
                                            ? "my-auto"
                                            : ""
                                        }
                                      >
                                        {item.gradeName}
                                      </div>
                                    </div>
                                  ))}
                            </div>
                          </div>
                          <div
                            className="my-auto"
                            style={{
                              borderLeft: "2px solid #EEF2F3",
                              marginRight: 15,
                              height: "40vh",
                            }}
                          />
                          <div>
                            {this.state.studentExamInfo &&
                              this.state.studentExamInfo.subjectWise.map(
                                subject => {
                                  const trimedPercent = subject.percentage.toLocaleString(
                                    "en",
                                    {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0,
                                    }
                                  )
                                  const subjName =
                                    String(subject.subjectName).split(" ")
                                      .length > 1
                                      ? String(subject.subjectName)
                                          .split(" ")[0]
                                          .slice(0, 3) +
                                        " " +
                                        String(subject.subjectName)
                                          .split(" ")[1]
                                          .slice(0, 3)
                                      : subject.subjectName
                                  return (
                                    <div className="d-flex row mt-4 align-items-center">
                                      <div
                                        className="text-dark firstLetterUpper col-sm"
                                        style={{ textTransform: "lowercase" }}
                                      >
                                        {subjName}
                                      </div>
                                      <div
                                        className="col-sm-8"
                                        style={{ width: 300 }}
                                      >
                                        <Progress
                                          style={{ fontSize: 12 }}
                                          percent={trimedPercent}
                                          status="active"
                                          theme={{
                                            active: {
                                              trailColor:
                                                "rgba(71, 153, 151, .22)",
                                              color: "#18a489",
                                            },
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )
                                }
                              )}
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-center">
                          <div>
                            <img
                              src={require("../../../assets/images/noabsentee.svg")}
                              alt="noabsentee"
                              style={{ width: 225, height: 225 }}
                            />
                          </div>
                          {/* <div className="text-dark text-center mt-4">
                              No data for Assessment
                            </div> */}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-sm-4">
                    {this.state.studentAttendanceInfo && (
                      <div className="card p-3 br-10 text-dark shadow mt-2">
                        <div className="d-flex align-items-center">
                          <div
                            className="text-brand font-weight-bold"
                            style={{ fontSize: 18 }}
                          >
                            Attendance
                          </div>
                          <UncontrolledDropdown
                            className="ml-auto"
                            style={{
                              // opacity: this.state.isSearchInputFocused ? "0" : "1",
                              transition: "150ms all ease-in",
                            }}
                          >
                            <DropdownToggle
                              tag="div"
                              className="d-flex cursor-pointer p-2 mr-2"
                            >
                              <div className="d-flex align-items-center justify-content-center">
                                <div className="text-muted">
                                  {this.state.monthName}
                                </div>
                                <i
                                  className="fa fa-chevron-down ml-2 text-muted"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </DropdownToggle>
                            <DropdownMenu
                              tag="div"
                              className="rounded border-1 border-muted px-2 py-2 subDropdown"
                            >
                              <DropdownItem
                                onClick={() => {
                                  this.setState({ monthName: "Overall" })
                                  let attenanceObject = {
                                    studentId: this.props.location.state
                                      .studentId,
                                    sectionId: this.state.studentBasicInfo
                                      .studentDetail.section.sectionId,
                                  }
                                  this.props.getStudentProfileAttendanceInfo(
                                    attenanceObject,
                                    (success, response) => {
                                      if (success) {
                                        this.setState({
                                          studentAttendanceInfo: response,
                                        })
                                      }
                                    }
                                  )
                                }}
                                className="dropdownItem"
                              >
                                {this.state.monthName === "Overall"
                                  ? ""
                                  : "Overall"}
                              </DropdownItem>
                              {this.state.academicMonthList &&
                                this.state.academicMonthList.map(month => {
                                  return (
                                    <DropdownItem
                                      onClick={() => {
                                        this.setState({
                                          monthName: month.label,
                                        })
                                        let attenanceObject = {
                                          studentId: this.props.location.state
                                            .studentId,
                                          sectionId: this.state.studentBasicInfo
                                            .studentDetail.section.sectionId,
                                          from: month.from,
                                          to: month.to,
                                        }
                                        this.props.getStudentProfileAttendanceInfo(
                                          attenanceObject,
                                          (success, response) => {
                                            if (success) {
                                              this.setState({
                                                studentAttendanceInfo: response,
                                              })
                                            }
                                          }
                                        )
                                      }}
                                      className="dropdownItem"
                                    >
                                      {month.label}
                                    </DropdownItem>
                                  )
                                })}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                        <div
                          className="d-flex align-items-center justify-content-center my-3"
                          style={{
                            color: "#029176",
                            fontSize: 24,
                            fontWeight: "bold",
                          }}
                        >
                          {this.state.studentAttendanceInfo &&
                            this.state.studentAttendanceInfo.attendanceReport
                              .percentage}
                          %
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="mx-4 col">Present</div>
                          <div className="ml-5 col">
                            {this.state.studentAttendanceInfo &&
                              this.state.studentAttendanceInfo.attendanceReport
                                .attendedDays}
                          </div>
                        </div>
                        <div className="d-flex mt-3 align-items-center">
                          <div className="mx-4 col">Absent</div>
                          <div className="ml-5 col">
                            {this.state.studentAttendanceInfo &&
                              this.state.studentAttendanceInfo.attendanceReport
                                .absentDays}
                          </div>
                        </div>
                        <div
                          className="ml-auto cursor-pointer mt-5"
                          style={{ color: "#15af8f" }}
                        >
                          {/*View more*/}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-sm-5 p-0">
                    {this.state.studentAssignmentInfo && (
                      <div className="card p-3 br-10 text-dark shadow mt-2">
                        <div className="d-flex align-items-center">
                          <div
                            className="text-brand font-weight-bold"
                            style={{ fontSize: 18 }}
                          >
                            Assignment
                          </div>
                          <UncontrolledDropdown
                            className="ml-auto"
                            style={{
                              // opacity: this.state.isSearchInputFocused ? "0" : "1",
                              transition: "150ms all ease-in",
                            }}
                          >
                            <DropdownToggle
                              tag="div"
                              className="d-flex cursor-pointer p-2 mr-2"
                            >
                              <div className="d-flex align-items-center justify-content-center">
                                <div className="text-muted">
                                  {this.state.selectedSubjectName}
                                </div>
                                <i
                                  className="fa fa-chevron-down ml-2 text-muted"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </DropdownToggle>
                            <DropdownMenu
                              tag="div"
                              className="rounded border-1 border-muted px-2 py-2 subDropdown"
                            >
                              <DropdownItem
                                onClick={() => {
                                  this.setState({
                                    selectedSubjectName: "Overall",
                                  })
                                  let attenanceObject = {
                                    studentId: this.props.location.state
                                      .studentId,
                                    sectionId: this.state.studentBasicInfo
                                      .studentDetail.section.sectionId,
                                  }
                                  this.props.getStudentProfileAssignmentInfo(
                                    attenanceObject,
                                    (success, response) => {
                                      if (success) {
                                        this.setState({
                                          studentAssignmentInfo: response,
                                        })
                                      }
                                    }
                                  )
                                }}
                                className="dropdownItem"
                              >
                                {this.state.selectedSubjectName === "Overall"
                                  ? ""
                                  : "Overall"}
                              </DropdownItem>
                              {this.state.classSubjectList &&
                                this.state.classSubjectList.map(item => {
                                  return (
                                    <DropdownItem
                                      onClick={() => {
                                        this.setState({
                                          selectedSubjectName:
                                            item.subjectShortName,
                                        })
                                        let attenanceObject = {
                                          studentId: this.props.location.state
                                            .studentId,
                                          sectionId: this.state.studentBasicInfo
                                            .studentDetail.section.sectionId,
                                          subjectId: item.subjectId,
                                        }
                                        this.props.getStudentProfileAssignmentInfo(
                                          attenanceObject,
                                          (success, response) => {
                                            if (success) {
                                              this.setState({
                                                studentAssignmentInfo: response,
                                              })
                                            }
                                          }
                                        )
                                      }}
                                      className="dropdownItem"
                                    >
                                      {item.subjectShortName}
                                    </DropdownItem>
                                  )
                                })}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                        <div className="d-flex mt-3 ml-3 align-items-center">
                          <div
                            style={{
                              width: "100px",
                              height: "100px",
                              fontWeight: "bold",
                            }}
                          >
                            <CircularProgressbar
                              value={
                                this.state.studentAssignmentInfo &&
                                this.state.studentAssignmentInfo
                                  .completedPercent
                              }
                              text={`${this.state.studentAssignmentInfo &&
                                this.state.studentAssignmentInfo
                                  .completedPercent}%`}
                              styles={buildStyles({
                                pathColor: `rgba(9, 177, 144,0.74)`,
                                textColor: "#029176",
                                trailColor: "rgba(9, 177, 144,0.29)",
                              })}
                            />
                            <div
                              className="d-flex mt-3"
                              style={{ justifyContent: "center" }}
                            >
                              {" "}
                              {this.state.studentAssignmentInfo &&
                                this.state.studentAssignmentInfo
                                  .completeCnt}{" "}
                              /{" "}
                              {this.state.studentAssignmentInfo &&
                                this.state.studentAssignmentInfo.total}
                            </div>
                          </div>

                          <div className="ml-5">
                            <div className="row d-flex align-items-center">
                              <div
                                className="rounded-pill mx-2"
                                style={{
                                  width: 8,
                                  height: 8,
                                  backgroundColor: "#15af8f",
                                }}
                              />
                              <div className="col">OnTime</div>
                              <div className="col d-flex justify-content-end">
                                {this.state.studentAssignmentInfo &&
                                  this.state.studentAssignmentInfo.onTimeCnt}
                              </div>
                            </div>
                            <div className="row d-flex my-3 align-items-center">
                              <div
                                className="rounded-pill bg-warning mx-2"
                                style={{
                                  width: 8,
                                  height: 8,
                                }}
                              />
                              <div className="col">Late</div>
                              <div className="col d-flex justify-content-end">
                                {this.state.studentAssignmentInfo &&
                                  this.state.studentAssignmentInfo.lateCnt}
                              </div>
                            </div>
                            <div className="row d-flex align-items-center">
                              <div
                                className="rounded-pill bg-danger mx-2"
                                style={{
                                  width: 8,
                                  height: 8,
                                }}
                              />
                              <div className="col">Missed</div>
                              <div className="col d-flex justify-content-end">
                                {this.state.studentAssignmentInfo &&
                                  this.state.studentAssignmentInfo.missingCnt}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="ml-auto mt-5 cursor-pointer"
                          style={{ color: "#15af8f" }}
                        >
                          {/*View more*/}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-sm-3">
                    {/* {this.state.studentBehaviourInfo && ( */}
                    <div className="card p-3 br-10 text-dark shadow mt-2">
                      <div
                        className="text-brand font-weight-bold"
                        style={{ fontSize: 18 }}
                      >
                        Behaviour
                      </div>
                      <div
                        className="d-flex  justify-content-center mt-2"
                        style={{ marginBottom: 37 }}
                      >
                        <div style={{ marginTop: 40 }}>
                          <div
                            className="rounded-pill d-flex align-items-center justify-content-center mx-2"
                            style={{
                              width: 45,
                              height: 45,
                              backgroundColor: "rgba(9, 177, 144,0.29)",
                            }}
                          >
                            <div
                              className="fs-15 font-weight-bold"
                              style={{ color: "#09b190" }}
                            >
                              +8
                            </div>
                          </div>
                          <div className="mt-5 text-muted">Positive</div>
                        </div>

                        <div
                          // className="my-auto"
                          style={{
                            borderLeft: "2px solid #EEF2F3",
                            margin: "0px 10px",
                            height: 50,
                            marginTop: 40,
                          }}
                        />
                        <div style={{ marginTop: 40 }}>
                          <div
                            className="rounded-pill d-flex align-items-center justify-content-center mx-2"
                            style={{
                              width: 45,
                              height: 45,
                              backgroundColor: "rgba(239, 55, 92,0.32)",
                            }}
                          >
                            <div
                              className="fs-15 font-weight-bold"
                              style={{ color: "#ef375c" }}
                            >
                              -3
                            </div>
                          </div>
                          <div className="mt-5 text-muted">Negative</div>
                        </div>
                      </div>
                    </div>
                    {/* )} */}
                  </div>
                </div>
                <div className="row align-items-stretch">
                  {/* <div className="col-6">
                    <div className="card p-3 br-10 text-dark shadow mt-2">
                      <div className="d-flex align-items-center">
                        <div
                          className="text-brand font-weight-bold"
                          style={{ fontSize: 18 }}
                        >
                          Recent test Score
                        </div>
                        <UncontrolledDropdown
                          className="ml-auto"
                          style={{
                            // opacity: this.state.isSearchInputFocused ? "0" : "1",
                            transition: "150ms all ease-in",
                          }}
                        >
                          <DropdownToggle
                            tag="div"
                            className="d-flex cursor-pointer p-2 mr-2"
                          >
                            <div className="d-flex align-items-center justify-content-center">
                              <div className="text-muted">overall</div>
                              <i
                                className="fa fa-chevron-down ml-2 text-muted"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </DropdownToggle>
                          <DropdownMenu
                            tag="div"
                            className="rounded border-1 border-muted px-2 py-2 subDropdown"
                          >
                            <DropdownItem className="dropdownItem">
                              English
                            </DropdownItem>
                            <DropdownItem className="dropdownItem">
                              Language
                            </DropdownItem>
                            <DropdownItem className="dropdownItem">
                              Science
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      <div className="d-flex mt-5 ml-3 align-items-center">
                        <div style={{ width: "100px", height: "100px" }}>
                          <CircularProgressbar
                            value={70}
                            text={`70%`}
                            strokeWidth={2}
                            styles={buildStyles({
                              pathColor: `rgba(9, 177, 144,0.74)`,
                              textColor: "#029176",
                              trailColor: "rgba(160, 162, 165,0.57)",
                            })}
                          />
                        </div>
                        <div className="ml-4">
                          <div className="text-brand font-weight-bold">
                            English - Chapter 2
                          </div>
                          <div className="d-flex  mt-3  align-items-center ">
                            <div>
                              <div>7/10</div>
                              <div className="text-muted fs-12">Score</div>
                            </div>
                            <div
                              className="my-auto "
                              style={{
                                borderLeft: "2px solid #EEF2F3",
                                margin: "0px 15px",
                                height: 50,
                              }}
                            />
                            <div>
                              <div>5</div>
                              <div className="text-muted fs-12">position</div>
                            </div>
                            <div
                              className="my-auto "
                              style={{
                                borderLeft: "2px solid #EEF2F3",
                                margin: "0px 15px",
                                height: 50,
                              }}
                            />
                            <div>
                              <div>5 Feb 2020</div>
                              <div className="text-muted fs-12">Held on</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          borderBottom: "2px solid #EEF2F3",
                          margin: "20px 10px",
                        }}
                      />
                      <div className="text-brand font-weight-bold">
                        Last 5 tests
                      </div>

                      <div className="d-flex mb-4">
                        <div
                          id="Subject1Tooltip"
                          className="cursor-pointer w-100 position-relative hoveredSubject1 p-2 mt-3 br-10 d-flex flex-column align-items-center justify-content-center"
                        >
                          <div
                            className="fs-15"
                            style={{ fontWeight: "bold", color: "#ef3753" }}
                          >
                            25%
                          </div>

                          <div className="text-muted">Science</div>
                          <UncontrolledTooltip
                            placement="top-start"
                            target="Subject1Tooltip"
                          >
                            <div
                              style={{
                                boxShadow: "8px 8px 5px rgba(0, 0, 0, 1)",
                              }}
                            >
                              <div className="text-light fs-12" style={{}}>
                                English - Chapter 2
                              </div>
                              <div className="d-flex mt-3  align-items-center">
                                <div>
                                  <div className="text-danger d-flex align-items-center">
                                    <div
                                      className="font-weight-bold"
                                      style={{
                                        fontSize: 18,
                                      }}
                                    >
                                      7/
                                    </div>
                                    <div>10</div>
                                  </div>
                                  <div className="text-muted fs-10">Score</div>
                                </div>
                                <div
                                  className="my-auto"
                                  style={{
                                    borderLeft: "2px solid #EEF2F3",
                                    margin: "0px 10px",
                                    height: 50,
                                  }}
                                />
                                <div>
                                  <div>5</div>
                                  <div className="text-muted fs-12">
                                    position
                                  </div>
                                </div>
                                <div
                                  className="my-auto"
                                  style={{
                                    borderLeft: "2px solid #EEF2F3",
                                    margin: "0px 10px",
                                    height: 50,
                                  }}
                                />
                                <div>
                                  <div>5 Feb 2020</div>
                                  <div className="text-muted fs-12">
                                    Held on
                                  </div>
                                </div>
                              </div>
                            </div>
                          </UncontrolledTooltip>
                        </div>
                        <div
                          id="Subject2Tooltip"
                          className="w-100 cursor-pointer hoveredSubject1 p-2 mt-3 mx-2 br-10 d-flex flex-column align-items-center justify-content-center"
                        >
                          <div
                            className="fs-15"
                            style={{ fontWeight: "bold", color: "#ef3753" }}
                          >
                            25%
                          </div>
                          <div className="text-muted">Maths</div>
                          <UncontrolledTooltip
                            placement="top"
                            target="Subject2Tooltip"
                          >
                            <div>
                              <div className="text-light fs-12">
                                English - Chapter 2
                              </div>
                              <div className="d-flex mt-3  align-items-center">
                                <div>
                                  <div className="text-danger d-flex align-items-center">
                                    <div
                                      className="font-weight-bold"
                                      style={{ fontSize: 18 }}
                                    >
                                      7/
                                    </div>
                                    <div>10</div>
                                  </div>
                                  <div className="text-muted fs-10">Score</div>
                                </div>
                                <div
                                  className="my-auto"
                                  style={{
                                    borderLeft: "2px solid #EEF2F3",
                                    margin: "0px 10px",
                                    height: 50,
                                  }}
                                />
                                <div>
                                  <div>5</div>
                                  <div className="text-muted fs-12">
                                    position
                                  </div>
                                </div>
                                <div
                                  className="my-auto"
                                  style={{
                                    borderLeft: "2px solid #EEF2F3",
                                    margin: "0px 10px",
                                    height: 50,
                                  }}
                                />
                                <div>
                                  <div>5 Feb 2020</div>
                                  <div className="text-muted fs-12">
                                    Held on
                                  </div>
                                </div>
                              </div>
                            </div>
                          </UncontrolledTooltip>
                        </div>
                        <div
                          id="Subject3Tooltip"
                          className="w-100 cursor-pointer hoveredSubject2 p-2 mt-3 mx-2 br-10 d-flex flex-column align-items-center justify-content-center"
                        >
                          <div
                            className="fs-15"
                            style={{ fontWeight: "bold", color: "#029176" }}
                          >
                            25%
                          </div>
                          <div className="text-muted ">Social Science</div>
                          <UncontrolledTooltip
                            placement="top"
                            target="Subject3Tooltip"
                          >
                            <div>
                              <div className="text-light fs-12">
                                English - Chapter 2
                              </div>
                              <div className="d-flex mt-3  align-items-center">
                                <div>
                                  <div className="text-danger d-flex align-items-center">
                                    <div
                                      className="font-weight-bold"
                                      style={{ fontSize: 18 }}
                                    >
                                      7/
                                    </div>
                                    <div>10</div>
                                  </div>
                                  <div className="text-muted fs-10">Score</div>
                                </div>
                                <div
                                  className="my-auto"
                                  style={{
                                    borderLeft: "2px solid #EEF2F3",
                                    margin: "0px 10px",
                                    height: 50,
                                  }}
                                />
                                <div>
                                  <div>5</div>
                                  <div className="text-muted fs-12">
                                    position
                                  </div>
                                </div>
                                <div
                                  className="my-auto"
                                  style={{
                                    borderLeft: "2px solid #EEF2F3",
                                    margin: "0px 10px",
                                    height: 50,
                                  }}
                                />
                                <div>
                                  <div>5 Feb 2020</div>
                                  <div className="text-muted fs-12">
                                    Held on
                                  </div>
                                </div>
                              </div>
                            </div>
                          </UncontrolledTooltip>
                        </div>
                        <div
                          id="Subject4Tooltip"
                          className="cursor-pointer w-100 hoveredSubject3 p-2 mt-3 mx-2 br-10 d-flex flex-column align-items-center justify-content-center"
                        >
                          <div
                            className="fs-15"
                            style={{ fontWeight: "bold", color: "#f47d1c" }}
                          >
                            25%
                          </div>
                          <div className="text-muted">Maths</div>
                          <UncontrolledTooltip
                            placement="top"
                            target="Subject4Tooltip"
                          >
                            <div>
                              <div className="text-light fs-12">
                                English - Chapter 2
                              </div>
                              <div className="d-flex mt-3  align-items-center">
                                <div>
                                  <div className="text-danger d-flex align-items-center">
                                    <div
                                      className="font-weight-bold"
                                      style={{ fontSize: 18 }}
                                    >
                                      7/
                                    </div>
                                    <div>10</div>
                                  </div>
                                  <div className="text-muted fs-10">Score</div>
                                </div>
                                <div
                                  className="my-auto"
                                  style={{
                                    borderLeft: "2px solid #EEF2F3",
                                    margin: "0px 10px",
                                    height: 50,
                                  }}
                                />
                                <div>
                                  <div>5</div>
                                  <div className="text-muted fs-12">
                                    position
                                  </div>
                                </div>
                                <div
                                  className="my-auto"
                                  style={{
                                    borderLeft: "2px solid #EEF2F3",
                                    margin: "0px 10px",
                                    height: 50,
                                  }}
                                />
                                <div>
                                  <div>5 Feb 2020</div>
                                  <div className="text-muted fs-12">
                                    Held on
                                  </div>
                                </div>
                              </div>
                            </div>
                          </UncontrolledTooltip>
                        </div>
                        <div
                          id="Subject5Tooltip"
                          className="cursor-pointer w-100 hoveredSubject2 p-2 mt-3 mx-2 br-10 d-flex flex-column align-items-center justify-content-center"
                        >
                          <div
                            className="fs-15"
                            style={{ fontWeight: "bold", color: "#029176" }}
                          >
                            25%
                          </div>
                          <div className="text-muted">Science</div>
                          <UncontrolledTooltip
                            placement="top"
                            target="Subject5Tooltip"
                          >
                            <div>
                              <div className="text-light fs-12">
                                English - Chapter 2
                              </div>
                              <div className="d-flex mt-3  align-items-center">
                                <div>
                                  <div className="text-danger d-flex align-items-center">
                                    <div
                                      className="font-weight-bold"
                                      style={{ fontSize: 18 }}
                                    >
                                      7/
                                    </div>
                                    <div>10</div>
                                  </div>
                                  <div className="text-muted fs-10">Score</div>
                                </div>
                                <div
                                  className="my-auto"
                                  style={{
                                    borderLeft: "2px solid #EEF2F3",
                                    margin: "0px 10px",
                                    height: 50,
                                  }}
                                />
                                <div>
                                  <div>5</div>
                                  <div className="text-muted fs-12">
                                    position
                                  </div>
                                </div>
                                <div
                                  className="my-auto"
                                  style={{
                                    borderLeft: "2px solid #EEF2F3",
                                    margin: "0px 10px",
                                    height: 50,
                                  }}
                                />
                                <div>
                                  <div>5 Feb 2020</div>
                                  <div className="text-muted fs-12">
                                    Held on
                                  </div>
                                </div>
                              </div>
                            </div>
                          </UncontrolledTooltip>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="col-6">
                    <div className="card p-3 br-10 text-dark shadow mt-2">
                      <div className="d-flex align-items-center">
                        <div
                          className="text-brand font-weight-bold"
                          style={{ fontSize: 18 }}
                        >
                          Recent Test Score
                        </div>
                        {this.state.studentExamInfo &&
                          this.state.studentExamInfo.assessmentCompleted && (
                            <UncontrolledDropdown
                              className="ml-auto"
                              style={{
                                // opacity: this.state.isSearchInputFocused ? "0" : "1",
                                transition: "150ms all ease-in",
                              }}
                            >
                              <DropdownToggle
                                tag="div"
                                className="d-flex cursor-pointer p-2 mr-2"
                              >
                                <div className="d-flex align-items-center justify-content-center">
                                  <div className="text-muted">
                                    {this.state.last5TestScoreSubject}
                                  </div>
                                  <i
                                    className="fa fa-chevron-down ml-2 text-muted"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </DropdownToggle>
                              <DropdownMenu
                                tag="div"
                                className="rounded border-1 border-muted px-2 py-2 subDropdown"
                              >
                                <DropdownItem
                                  className="dropdownItem"
                                  onClick={() => {
                                    let object = {
                                      studentId: this.state.studentId,
                                    }
                                    this.props.getStudentLast5Score(
                                      object,
                                      (success, response) => {
                                        if (success) {
                                          this.setState({
                                            last5TestScoreSubject: "Overall",
                                            studentLast5Score:
                                              response.assessments &&
                                              response.assessments.rows
                                                ? response.assessments.rows
                                                : null,
                                          })
                                        }
                                      }
                                    )
                                  }}
                                >
                                  {this.state.last5TestScoreSubject ===
                                  "Overall"
                                    ? ""
                                    : "Overall"}
                                </DropdownItem>
                                {this.state.classSubjectList &&
                                  this.state.classSubjectList.map(
                                    (ques, index) => (
                                      <DropdownItem
                                        className="dropdownItem"
                                        onClick={() => {
                                          let object = {
                                            studentId: this.state.studentId,
                                          }
                                          object.subjectId = ques.subjectId
                                          this.props.getStudentLast5Score(
                                            object,
                                            (success, response) => {
                                              if (success) {
                                                this.setState({
                                                  last5TestScoreSubject:
                                                    ques.subjectShortName,
                                                  studentLast5Score:
                                                    response.assessments &&
                                                    response.assessments.rows
                                                      ? response.assessments
                                                          .rows
                                                      : null,
                                                })
                                              }
                                            }
                                          )
                                        }}
                                      >
                                        {ques.subjectShortName}
                                      </DropdownItem>
                                    )
                                  )}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          )}
                      </div>
                      {this.state.studentLast5Score &&
                      this.state.studentLast5Score.overall &&
                      this.state.studentExamInfo ? (
                        <div className="d-flex mt-5 ml-3 align-items-center">
                          <div style={{ width: "100px", height: "100px" }}>
                            <CircularProgressbar
                              value={
                                this.state.selectedSubjectRecentTest
                                  ? this.state.selectedSubjectRecentTest
                                      .percentage
                                  : this.state.studentExamInfo &&
                                    this.state.studentExamInfo.forAssessment
                                  ? this.state.studentExamInfo.forAssessment
                                      .percentage
                                  : 0
                              }
                              text={
                                this.state.selectedSubjectRecentTest
                                  ? this.state.selectedSubjectRecentTest
                                      .percentage + "%"
                                  : this.state.studentExamInfo &&
                                    this.state.studentExamInfo.forAssessment
                                  ? this.state.studentExamInfo.forAssessment
                                      .percentage + "%"
                                  : "0%"
                              }
                              strokeWidth={2}
                              styles={buildStyles({
                                pathColor: `rgba(9, 177, 144,0.74)`,
                                textColor: "#029176",
                                trailColor: "rgba(160, 162, 165,0.57)",
                              })}
                            />
                          </div>
                          <div className="ml-4">
                            <div className="text-brand font-weight-bold">
                              {this.state.selectedSubjectRecentTest
                                ? this.state.selectedSubjectRecentTest
                                    .studentName
                                : this.state.studentExamInfo &&
                                  this.state.studentExamInfo.forAssessment
                                ? this.state.studentExamInfo.forAssessment
                                    .studentName
                                : ""}
                            </div>
                            <div className="d-flex  mt-3  align-items-center ">
                              <div>
                                <div>
                                  {this.state.selectedSubjectRecentTest
                                    ? this.state.selectedSubjectRecentTest.mark
                                    : this.state.studentExamInfo &&
                                      this.state.studentExamInfo.forAssessment
                                    ? this.state.studentExamInfo.forAssessment
                                        .mark
                                    : ""}
                                  /
                                  {this.state.selectedSubjectRecentTest
                                    ? this.state.selectedSubjectRecentTest
                                        .outOffMark
                                    : this.state.studentExamInfo &&
                                      this.state.studentExamInfo.forAssessment
                                    ? this.state.studentExamInfo.forAssessment
                                        .outOffMark
                                    : ""}
                                </div>
                                <div className="text-muted fs-12">Score</div>
                              </div>
                              <div
                                className="my-auto "
                                style={{
                                  borderLeft: "2px solid #EEF2F3",
                                  margin: "0px 15px",
                                  height: 50,
                                }}
                              />
                              <div>
                                <div>
                                  {this.state.selectedSubjectRecentTest
                                    ? this.state.selectedSubjectRecentTest
                                        .studentRank
                                    : this.state.studentExamInfo &&
                                      this.state.studentExamInfo.forAssessment
                                    ? this.state.studentExamInfo.forAssessment
                                        .studentRank
                                    : ""}
                                </div>
                                <div className="text-muted fs-12">position</div>
                              </div>
                              <div
                                className="my-auto "
                                style={{
                                  borderLeft: "2px solid #EEF2F3",
                                  margin: "0px 15px",
                                  height: 50,
                                }}
                              />
                              <div>
                                <div>
                                  {this.state.selectedSubjectRecentTest
                                    ? moment(
                                        this.state.selectedSubjectRecentTest
                                          .updatedOn
                                      ).format("DD MMM YYYY")
                                    : this.state.studentExamInfo &&
                                      this.state.studentExamInfo.forAssessment
                                    ? moment(
                                        this.state.studentExamInfo.forAssessment
                                          .updatedOn
                                      ).format("DD MMM YYYY")
                                    : ""}
                                </div>
                                <div className="text-muted fs-12">Held on</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {/*<div className="d-flex mt-2 align-items-center justify-content-center">
                        No data found
                      </div>*/}
                      {this.state.studentLast5Score &&
                        this.state.studentLast5Score.overall && (
                          <div
                            style={{
                              borderBottom: "2px solid #EEF2F3",
                              margin: "20px 10px",
                            }}
                          />
                        )}
                      {this.state.studentLast5Score &&
                        this.state.studentLast5Score.overall && (
                          <div className="text-brand font-weight-bold">
                            Last 5 tests
                          </div>
                        )}
                      <div className="row mb-4">
                        {this.state.studentLast5Score &&
                        this.state.studentLast5Score.length > 0 ? (
                          this.state.studentLast5Score.map((ques, index) => (
                            <div
                              id={`Assessment-${ques.id}`}
                              className="cursor-pointer w-100 position-relative hoveredSubject1 p-2 mt-3 br-10 ml-3 col-3 align-items-center justify-content-center"
                            >
                              <div
                                className="fs-15"
                                style={{
                                  fontWeight: "bold",
                                  color: "#ef3753",
                                }}
                              >
                                {(
                                  (ques.quizSubmissions[0].gradedMark /
                                    ques.quizSet.totalMark) *
                                  100
                                ).toFixed(0)}
                                %
                              </div>
                              <div
                                className="text-muted"
                                style={{ fontSize: "0.8rem" }}
                              >
                                {ques.teacherAssignedDetail.subject.subjectName}
                              </div>
                              <UncontrolledTooltip
                                placement="top-start"
                                target={`Assessment-${ques.id}`}
                              >
                                <div
                                  style={{
                                    boxShadow: "8px 8px 5px rgba(0, 0, 0, 1)",
                                  }}
                                >
                                  <div className="text-light fs-12" style={{}}>
                                    {
                                      ques.teacherAssignedDetail.subject
                                        .subjectName
                                    }
                                  </div>
                                  <div className="d-flex mt-3  align-items-center">
                                    <div>
                                      <div className="text-danger d-flex align-items-center">
                                        <div
                                          className="font-weight-bold"
                                          style={{
                                            fontSize: 18,
                                          }}
                                        >
                                          {ques.quizSubmissions[0].gradedMark}/
                                        </div>
                                        <div>{ques.quizSet.totalMark}</div>
                                      </div>
                                      <div className="text-muted fs-10">
                                        Score
                                      </div>
                                    </div>
                                    <div
                                      className="my-auto"
                                      style={{
                                        borderLeft: "2px solid #EEF2F3",
                                        margin: "0px 10px",
                                        height: 50,
                                      }}
                                    />
                                    <div>
                                      <div>
                                        {ques.assignedStudents.findIndex(
                                          p =>
                                            p.studentId ===
                                            ques.quizSubmissions[0].studentId
                                        ) + 1}
                                      </div>
                                      <div className="text-muted fs-12">
                                        position
                                      </div>
                                    </div>
                                    <div
                                      className="my-auto"
                                      style={{
                                        borderLeft: "2px solid #EEF2F3",
                                        margin: "0px 10px",
                                        height: 50,
                                      }}
                                    />
                                    <div>
                                      <div>
                                        {moment(ques.startTime).format(
                                          "DD MMM YYYY"
                                        )}
                                      </div>
                                      <div className="text-muted fs-12">
                                        Held on
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </UncontrolledTooltip>
                            </div>
                          ))
                        ) : (
                          <div
                            className="d-flex ml-3 mt-3"
                            style={{ position: "absolute", left: "38%" }}
                          >
                            No data found
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-6" style={{ paddingLeft: 0 }}>
                    {/* <div className="card p-3 br-10 text-dark shadow mt-2">
                    <div className="text-brand font-weight-bold">Progress</div>
                  </div>  */}
                    <div className="card p-3 br-10 text-dark shadow mt-2">
                      <div className="d-flex align-items-center">
                        <div
                          className="text-brand font-weight-bold"
                          style={{ fontSize: 18 }}
                        >
                          Strength and Weakness
                        </div>
                        <UncontrolledDropdown
                          className="ml-auto"
                          style={{
                            // opacity: this.state.isSearchInputFocused ? "0" : "1",
                            transition: "150ms all ease-in",
                          }}
                        >
                          <DropdownToggle
                            tag="div"
                            className="d-flex cursor-pointer p-2 mr-2"
                          >
                            <div className="d-flex align-items-center justify-content-center">
                              <div className="text-muted">overall</div>
                              <i
                                className="fa fa-chevron-down ml-2 text-muted"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </DropdownToggle>
                          <DropdownMenu
                            tag="div"
                            className="rounded border-1 border-muted px-2 py-2 subDropdown"
                          >
                            <DropdownItem className="dropdownItem">
                              English
                            </DropdownItem>
                            <DropdownItem className="dropdownItem">
                              Language
                            </DropdownItem>
                            <DropdownItem className="dropdownItem">
                              Science
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      <div className="mt-4">
                        <div className="fs-12 text-muted">Strength</div>
                        <div className="mt-3">
                          <div className="d-flex">
                            <div
                              className="d-flex shadow p-2 pr-4 align-items-center"
                              style={{ borderRadius: 20 }}
                            >
                              <img
                                src={require("../../../assets/images/history.png")}
                                style={{ width: 45, height: 45 }}
                                alt=""
                              />
                              <div className="text-dark ml-2">History</div>
                            </div>
                            <div
                              className="d-flex shadow ml-3 p-2 pr-4 align-items-center"
                              style={{ borderRadius: 20 }}
                            >
                              <img
                                src={require("../../../assets/images/social.png")}
                                style={{ width: 45, height: 45 }}
                                alt=""
                              />
                              <div className="text-dark ml-2">
                                Social Science
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-4">
                          <div className="fs-12 text-muted">Weakness</div>
                          <div className="mt-3">
                            <div className="d-flex">
                              <div
                                className="d-flex shadow p-2 pr-4 align-items-center"
                                style={{ borderRadius: 20 }}
                              >
                                <img
                                  src={require("../../../assets/images/maths.png")}
                                  style={{ width: 45, height: 45 }}
                                  alt=""
                                />
                                <div className="text-dark ml-2">Maths</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </AppWrapper>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getStudentProfileBasicInfo,
      getStudentProfileExamInfo,
      getStudentProfileAttendanceInfo,
      getStudentProfileAssignmentInfo,
      getExamListDetails,
      getAcademicMonthList,
      getSubjectDetailsToPR,
      saveCommunicationDetails,
      getCommunicationLabelDetails,
      getStudentLast5Score,
      getGradeDetails,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(StudentProfile)
