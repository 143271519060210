import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import { showNotification } from "../CommonAction"
import {
  SUCCESS_TOAST,
  VALIDATION_MSG,
  DANGER_TOAST,
} from "../../utils/Constants"
import URL from "../../config"
import { ACTION_TYPES } from "../types"
// import { getNotificationList } from "./DashboardAction"

export const saveCurrentAssignmentDetailsInStore = currentAssignment => {
  return {
    type: ACTION_TYPES.STUDENT_CURRENT_ASSIGNMENT,
    payload: currentAssignment,
  }
}

export const saveAssignmentToggleModalDetailsInStore = toggleModal => {
  return {
    type: ACTION_TYPES.STUDENT_ASSIGNMENT_TOGGLE_MODAL,
    payload: toggleModal,
  }
}

export const updateViewedAssignment = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.SAVE_STUDENT_SUBMIT_DETAILS + "/" + body.id + "/view",
        null,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====updateViewedAssignment RESPONSE===::::", response)
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====updateViewedAssignment ERROR===????", error)
    }
  }
}

export const updateMarkAsReadAssignment = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.UPDATE_MARK_AS_READ_ASSIGNMENT,
        body,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog("====updateMarkAsReadAssignment RESPONSE===::::", response)
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====updateMarkAsReadAssignment ERROR===????", error)
    }
  }
}

export const saveStudentSubmitAssignmentDetails = (id, formdata, callBack) => {
  myLog("ASSIGN  MODAL OBJECT ->", formdata)
  return async dispatch => {
    try {
      const response = await Client.post(
        URL.SAVE_STUDENT_SUBMIT_DETAILS + "/" + id + "/submit",
        formdata,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog(
          "====saveStudentSubmitAssignmentDetails RESPONSE===::::",
          response
        )
        // dispatch(getAssignmentList(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveStudentSubmitAssignmentDetails ERROR===????", error)
    }
  }
}

export const saveStudentUnSubmitAssignmentDetails = (id, callBack) => {
  myLog("ASSIGN  MODAL OBJECT ->", id)
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.SAVE_STUDENT_SUBMIT_DETAILS + "/" + id + "/unsubmit",
        null,
        true,
        true
      )
      if (response.responseCode === 0) {
        myLog(
          "====saveStudentUnSubmitAssignmentDetails RESPONSE===::::",
          response
        )
        // dispatch(getAssignmentList(() => {}))
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveStudentUnSubmitAssignmentDetails ERROR===????", error)
    }
  }
}

export const getAssignmentList = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_STUDENT_ASSIGNMENT_DETAILS,
        object,
        true,
        true
      )
      if (response) {
        myLog("====getAssignmentList RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getAssignmentList ERROR===????", error)
    }
  }
}

export const getAssignmentOverview = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        `${URL.GET_STUDENT_ASSIGNMENT_DETAILS}/overview`,
        object,
        true,
        true
      )
      if (response) {
        myLog("====getAssignmentOverview RESPONSE===::::", response)
        callBack(true, response)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            "Something went wrong..."
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====getAssignmentOverview ERROR===????", error)
    }
  }
}

export const getAssignmentYearlyGraph = (query, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.STUDENT_ASSIGNMENT_YEAR,
        query,
        true,
        true
      )
      if (response) {
        myLog("====getAssignmentYearlyGraph RESPONSE===::::", response)
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getAssignmentYearlyGraph ERROR===????", error)
    }
  }
}

export const getAssignmentMonthlyCompleteRatio = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_STUDENT_ASSIGNMENT_DETAILS + "/monthly-ratio",
        null,
        true,
        true
      )
      if (response) {
        myLog("====getAssignmentMonthlyCompleteRatio RESPONSE===::::", response)
        callBack(true, response.result)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getAssignmentMonthlyCompleteRatio ERROR===????", error)
    }
  }
}

export const getAssignmentDetailTeacherAttachment = (
  assignmentId,
  callBack
) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_STUDENT_ASSIGNMENT_DETAILS +
          "/" +
          assignmentId +
          "/assignment-file",
        null,
        true,
        true
      )
      if (response) {
        myLog(
          "====getAssignmentDetailTeacherAttachment RESPONSE===::::",
          response
        )
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getAssignmentDetailTeacherAttachment ERROR===????", error)
    }
  }
}

export const getAssignmentDetailStudentAttachment = (
  assignmentId,
  callBack
) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_STUDENT_ASSIGNMENT_DETAILS +
          "/" +
          assignmentId +
          "/submission-file",
        null,
        true,
        true
      )
      if (response) {
        myLog(
          "====getAssignmentDetailStudentAttachment RESPONSE===::::",
          response
        )
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getAssignmentDetailStudentAttachment ERROR===????", error)
    }
  }
}
