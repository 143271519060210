/*************************************************
 * RedInk
 * @exports
 * @class ClassDetailscreen.js
 * @extends Component
 * @author Sasidharan // on 08/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import React, { Component, useState } from "react"
import Select, { components } from "react-select"
import { bindActionCreators } from "redux"
import { HashLoader } from "react-spinners"
import { connect } from "react-redux"
import {
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Collapse,
  Button,
  Label,
  Input,
} from "reactstrap"

import {
  getClassDetails,
  saveClassDetails,
  deleteClassDetails,
  updateClassDetails,
  getClassGroup,
  saveClassGroup,
  deleteClassGroup,
  updateClassGroup,
  deleteClassFromGroup,
} from "../../../actions/admin/ClassDetailsAction"
import { DANGER_TOAST, VALIDATION_MSG } from "../../../utils/Constants"
import { confirmAlertPopUp } from "../../../utils/Utility"
import { showNotification } from "../../../actions/CommonAction"

import AppWrapper from "../Wrapper1"

export const sections = [
  { value: "A", label: "A" },
  { value: "A1", label: "A1" },
  { value: "A2", label: "A2" },
  { value: "ABE", label: "ABE" },
  { value: "B", label: "B" },
  { value: "C", label: "C" },
  { value: "CD", label: "CD" },
  { value: "D", label: "D" },
  { value: "E", label: "E" },
  { value: "F", label: "F" },
  { value: "G", label: "G" },
  { value: "H", label: "H" },
  { value: "I", label: "I" },
  { value: "J", label: "J" },
  { value: "K", label: "K" },
  { value: "L", label: "L" },
  { value: "M", label: "M" },
  { value: "N", label: "N" },
  { value: "O", label: "O" },
  { value: "P", label: "P" },
  { value: "Q", label: "Q" },
  { value: "R", label: "R" },
  { value: "S", label: "S" },
  { value: "T", label: "T" },
  { value: "U", label: "U" },
  { value: "V", label: "V" },
  { value: "W", label: "W" },
  { value: "X", label: "X" },
  { value: "Y", label: "Y" },
  { value: "Z", label: "Z" },
]
export const classes = [
  { value: "PreKG", label: "PreKG" },
  { value: "JKG", label: "JKG" },
  { value: "SKG", label: "SKG" },
  { value: "LKG", label: "LKG" },
  { value: "UKG", label: "UKG" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
]

const colorStyle = {
  control: styles => ({ ...styles, borderColor: "#084353", borderWidth: 2 }),
  dropdownIndicator: styles => ({ ...styles, color: "#084353" }),
}

const Option = props => (
  <div>
    <components.Option {...props}>
      <input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
      <label>{props.value} </label>
    </components.Option>
  </div>
)

const MultiValue = props => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
)

class ClassDetailscreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTab: "Classes",
      createGroupOpen: false,
      groupAdding: false,
      editingGroupId: null,
      newGroupName: "",
      newGroupClasses: [],
      savedClasses: false,
      selectedClasses: [],
      selectedSections: [],
      selectedClassesAndSections: [],
      saveSelectedClassesAndSections: [],
      isShowLoading: false,
      classToShowInGrouping: [],
    }
  }

  componentDidMount = () => {
    this.props.getClassDetails({}, () => {})
    this.props.getClassGroup({}, () => {})
  }

  addClassesAndSubject = () => {
    if (this.state.selectedClasses && this.state.selectedClasses.length <= 0) {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.SUCCESS,
        "Class cannot be empty"
      )
      return
    }

    if (
      this.state.selectedSections &&
      this.state.selectedSections.length <= 0
    ) {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.SUCCESS,
        "Sections cannot be empty"
      )
      return
    }

    if (
      this.state.selectedClasses &&
      // this.state.selectedClasses.length > 0 &&
      this.state.selectedSections &&
      this.state.selectedSections.length > 0
    ) {
      let array = this.state.selectedClassesAndSections
      let string = this.state.selectedClasses.value + "-"
      let alreayAvailableClassindex = -1
      let alreaySavedSelectedClassesAndSectionIndex = -1
      let classes = this.state.saveSelectedClassesAndSections
      let sectionsList = []

      for (let j = 0; j < array.length; j++) {
        let dummyString = array[j].classesAndSections
        let splitString = dummyString.split("-")
        if (splitString[0] === this.state.selectedClasses.value) {
          alreayAvailableClassindex = j
          string = dummyString
        }
      }

      for (let k = 0; k < array.length; k++) {
        let dummyString = classes[k].class
        if (dummyString === this.state.selectedClasses.value) {
          alreaySavedSelectedClassesAndSectionIndex = k
          for (let m = 0; m < classes[k].sections.length; m++) {
            sectionsList.push(classes[k].sections[m])
          }
        }
      }

      for (let i = 0; i < this.state.selectedSections.length; i++) {
        if (sectionsList.length > 0) {
          let error = false
          for (let j = 0; j < sectionsList.length; j++) {
            if (this.state.selectedSections[i].value === sectionsList[j]) {
              error = true
              break
            }
          }
          if (!error) {
            string = string + this.state.selectedSections[i].value + ","
            sectionsList.push(this.state.selectedSections[i].value)
          }
        } else {
          string = string + this.state.selectedSections[i].value + ","
          sectionsList.push(this.state.selectedSections[i].value)
        }
      }

      if (alreaySavedSelectedClassesAndSectionIndex !== -1) {
        classes.splice(alreaySavedSelectedClassesAndSectionIndex, 1)
        this.props.showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.SUCCESS,
          "Cannot create duplicate class"
        )
      }
      let classObject = {
        class: this.state.selectedClasses.value,
      }
      classObject.sections = [...new Set(sectionsList)]
      classes.push(classObject)

      if (alreayAvailableClassindex !== -1) {
        array.splice(alreayAvailableClassindex, 1)
      }
      let object = {
        classesAndSections: string,
      }
      array.push(object)

      this.cancelSubmit()

      this.setState({
        selectedClassesAndSections: array,
        saveSelectedClassesAndSections: classes,
      })
    }
  }

  comparer = otherArray => {
    return function(current) {
      return (
        otherArray.filter(
          other =>
            other.value === current.value && other.display === current.display
        ).length === 0
      )
    }
  }

  onClickCreateGroup = () => {
    let defaultClasses = this.props.classesList
    let alreadyGroupedSections = this.props.alreadyAssignedClasses
    let onlyInA = defaultClasses.filter(this.comparer(alreadyGroupedSections))
    let onlyInB = alreadyGroupedSections.filter(this.comparer(defaultClasses))
    this.setState({ classToShowInGrouping: onlyInA.concat(onlyInB) })
  }

  cancelSubmit = () => {
    this.setState({
      selectedClasses: [],
      selectedSections: [],
      selectedClassesAndSections: [],
      saveSelectedClassesAndSections: [],
      hasError: false,
    })
  }

  deleteClassesAndSections = index => {
    if (this.state.selectedClassesAndSections.length > 0) {
      let arrselectedClassesAndSections = this.state.selectedClassesAndSections
      let arrSaveSelectedClassesAndSections = this.state
        .saveSelectedClassesAndSections
      arrSaveSelectedClassesAndSections.splice(index, 1)
      arrselectedClassesAndSections.splice(index, 1)
      this.setState({
        selectedClassesAndSections: arrselectedClassesAndSections,
        saveSelectedClassesAndSections: arrSaveSelectedClassesAndSections,
      })
    }
  }

  /**
   * Delete a subject from db
   */
  deleteClassFromDb = item => {
    // confirmAlertPopUp(
    //   VALIDATION_MSG.DELETE_TITLE,
    //   VALIDATION_MSG.DELETE_CONTENT,
    //   yes => {
    //     if (yes) {
    //       this.props.deleteClassDetails({ id: item.id }, () => {})
    //     }
    //   }
    // )
    confirmAlertPopUp(
      VALIDATION_MSG.DELETE_TITLE,
      VALIDATION_MSG.DELETE_CONTENT,
      yes => {
        if (yes) {
          this.props.deleteClassDetails({ id: item.id }, () => {})
        }
      },
      {
        buttons: [],
        customUI: ({ onClose }) => {
          return (
            <div
              className="custom"
              style={{
                backgroundColor: "#fff",

                borderBottomRightRadius: "12px",
                borderBottomLeftRadius: "12px",
              }}
            >
              <div
                className="bg-brand text-light d-flex"
                style={{
                  padding: "0.5rem 2rem",
                  borderTopRightRadius: "12px",
                  borderTopLeftRadius: "12px",
                }}
              >
                <div className="mx-auto fs-18">Delete</div>
              </div>

              <div
                className="mt-3 "
                style={{
                  padding: "0.5rem 1.5rem",
                }}
              >
                <div className="text-muted fs-15">
                  Are you sure you want to delete?
                </div>
                <div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
                  <button
                    className="px-4 py-1 bg-brand text-light w-25 d-flex align-items-center justify-content-center yes-button"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={() =>
                      this.props.deleteClassDetails({ id: item.id }, onClose)
                    }
                  >
                    Yes
                  </button>
                  <button
                    className="px-4 py-1 ml-4 bg-brand text-light w-25 d-flex align-items-center justify-content-center"
                    style={{
                      borderRadius: "5px",
                    }}
                    onClick={onClose}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )
        },
      }
    )
  }

  /**
   * saveSubject
   */
  saveClass = () => {
    if (this.state.saveSelectedClassesAndSections.length > 0) {
      let classObject = {
        classes: this.state.saveSelectedClassesAndSections,
      }
      this.setState(
        {
          isShowLoading: true,
        },
        () => {
          this.props.saveClassDetails(classObject, success => {
            this.setState({
              isShowLoading: false,
            })
            if (success) {
              this.cancelSubmit()
              this.setState({
                savedClasses: false,
                saveSelectedClassesAndSections: [],
              })
            }
          })
        }
      )
    } else {
      // this.setState({ hasError: true })
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.SUCCESS,
        "Fill out all fields..."
      )
      return
    }
  }

  saveClassGroup = () => {
    let { editingGroupId, newGroupName, newGroupClasses } = this.state
    if (newGroupName !== "" && newGroupClasses.length > 0) {
      this.setState({ groupAdding: true })
      newGroupClasses = newGroupClasses.map(val => ({
        sectionId: val.id || val.sectionId,
      }))
      let data = {
        groupName: newGroupName.trim(),
        classList: newGroupClasses,
      }
      if (editingGroupId) data.groupId = editingGroupId
      this.props.saveClassGroup(data, success => {
        this.setState({
          createGroupOpen: false,
          groupAdding: false,
          editingGroupId: null,
          newGroupName: "",
          newGroupClasses: [],
        })
      })
    } else {
      this.props.showNotification(
        DANGER_TOAST,
        VALIDATION_MSG.SUCCESS,
        "Fill out all fields..."
      )
    }
  }

  render() {
    return (
      <AppWrapper
        showAdminProfileSidemenu
        showAdminBackToDashboard
        screenName="Class Details"
      >
        <div className="row align-items-center">
          <div className="col">
            {/* <h3 className="m-0 h3 font-weight-bold text-dark">Class Details</h3> */}
          </div>

          <div className="px-3 col-lg-auto">
            <div className="top-tab-pills">
              <button
                onClick={() => this.setState({ selectedTab: "Classes" })}
                className={`btn btn-sm ${this.state.selectedTab === "Classes" &&
                  "active"}`}
                id="classes-tooltip"
              >
                <UncontrolledTooltip
                  placement="bottom"
                  className="bg-danger"
                  target="classes-tooltip"
                >
                  View Classes
                </UncontrolledTooltip>
                Classes
              </button>
              <button
                onClick={() => this.setState({ selectedTab: "Groups" })}
                className={`btn btn-sm ${this.state.selectedTab === "Groups" &&
                  "active"}`}
                id="groups-tooltip"
              >
                Groups
                <UncontrolledTooltip
                  placement="bottom"
                  className="bg-danger"
                  target="groups-tooltip"
                >
                  View class groups
                </UncontrolledTooltip>
              </button>
            </div>
          </div>

          <div className="col d-flex justify-content-end">
            {this.state.selectedTab === "Classes" && (
              <button
                type="button"
                className="btn btn-sm text-light rounded-pill addGradeButton"
                style={{ backgroundColor: "#094353" }}
                onClick={() =>
                  this.setState({ savedClasses: !this.state.savedClasses })
                }
              >
                <i className="mr-2 fas fa-plus" />
                Add Class
              </button>
            )}
            {this.state.selectedTab === "Groups" && (
              <button
                type="button"
                className="btn btn-sm rounded-pill text-light addGradeButton"
                onClick={() => {
                  this.setState({
                    createGroupOpen: !this.state.createGroupOpen,
                  })
                  this.onClickCreateGroup()
                  setTimeout(() => {
                    let el = document.getElementById("groupName")
                    el.focus()
                  }, 500)
                }}
                style={{ backgroundColor: "rgb(9, 67, 83)" }}
              >
                <i className="mr-2 fas fa-plus" />
                Add Group
              </button>
            )}
          </div>
        </div>

        {this.state.selectedTab === "Classes" && (
          <>
            <Collapse isOpen={!!this.state.savedClasses}>
              <div className="my-3 row">
                <div className="col-sm-5">
                  <Select
                    placeholder="Select class"
                    name="classes"
                    isClearable
                    value={this.state.selectedClasses}
                    options={classes}
                    className="mb-3 basic-multi-select input-border-color"
                    classNamePrefix="select"
                    onChange={selectedOption =>
                      this.setState({ selectedClasses: selectedOption })
                    }
                    styles={colorStyle}
                  />
                  <Select
                    isMulti
                    placeholder="Select sections"
                    components={{ Option, MultiValue }}
                    value={this.state.selectedSections}
                    name="sections"
                    options={sections}
                    className="basic-multi-select input-border-color"
                    classNamePrefix="select"
                    hideSelectedOptions={false}
                    closeMenuOnSelect={false}
                    onChange={selectedOption =>
                      this.setState({ selectedSections: selectedOption })
                    }
                    styles={colorStyle}
                  />
                  <button
                    type="button"
                    className="my-3 btn btn-dark btn-block"
                    style={{ background: "#084353" }}
                    onClick={this.addClassesAndSubject}
                  >
                    <i className="mr-2 fas fa-arrow-right" />
                    Add
                  </button>
                </div>
                <div className="col-sm-7">
                  <ul className="list-group">
                    {this.state.selectedClassesAndSections &&
                      this.state.selectedClassesAndSections.map(
                        (item, index) => (
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            {item.classesAndSections}
                            <div>
                              <button
                                type="button"
                                onClick={() => {
                                  this.deleteClassesAndSections(index)
                                }}
                              >
                                <i className="fas fa-minus-circle text-danger"></i>
                              </button>
                            </div>
                          </li>
                        )
                      )}
                    {this.state.selectedClassesAndSections.length === 0 &&
                      this.state.hasError && (
                        <p className="text-danger">Classes is required.</p>
                      )}
                  </ul>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  style={{
                    backgroundColor: "#DBDBDB",
                    color: "#06B191",
                    border: "none",
                  }}
                  type="button"
                  className="mt-3 mr-2 btn rounded-pill"
                  onClick={() => {
                    this.cancelSubmit()
                    this.setState({ savedClasses: false })
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  disabled={this.state.isShowLoading}
                  className="mt-3 btn btn-success rounded-pill"
                  style={{ background: "#0eb292", border: "none" }}
                  onClick={() => {
                    this.saveClass()
                  }}
                >
                  {this.state.isShowLoading ? (
                    <HashLoader
                      size={16}
                      color="#fff"
                      loading={this.state.isShowLoading}
                      sizeUnit="px"
                    />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </Collapse>

            <div className="mt-4 row d-flex justify-content-around">
              {/* Class card */}
              <div className="container">
                <div className="row px-3">
                  {this.props.classesList &&
                    this.props.classesList.map(item => (
                      <div key={item.id} className="my-2 col-sm-4">
                        <div className={`card mx-2 p-2 border-left-color`}>
                          <div className="mt-2 d-flex justify-content-between">
                            <span>Class {item.className}</span>
                            <UncontrolledDropdown>
                              <DropdownToggle tag="a" className="nav-link">
                                <i className="cursor-pointer fas fa-ellipsis-v"></i>
                              </DropdownToggle>
                              <DropdownMenu>
                                {/* <DropdownItem onClick={() => {this.setState({isShowAddSubjectInput: true, isShowUpdateSubjectInput: true, subjectsSet: [item.subjectName], updateSubjectDetailId: item.id})}}>Update</DropdownItem> */}
                                <DropdownItem
                                  onClick={() => {
                                    this.deleteClassFromDb(item)
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                            {/* <i className="cursor-pointer fas fa-ellipsis-v"></i> */}
                          </div>
                          <div className="my-2 d-flex justify-content-between">
                            <div className="text-center">
                              <b>{item.studentsCount}</b>
                              <br></br>
                              <small className="text-secondary">
                                {item.studentsCount === 1
                                  ? "Student"
                                  : "Students"}
                              </small>
                            </div>
                            <div className="text-center">
                              <b>{item.teacherCount}</b>
                              <br></br>
                              <small className="text-secondary">
                                {item.teacherCount === 1
                                  ? "Teacher"
                                  : "Teachers"}
                              </small>
                            </div>
                            <div className="text-center">
                              <b>{item.subjectsCount}</b>
                              <br></br>
                              <small className="text-secondary">
                                {item.subjectsCount === 1
                                  ? "Subject"
                                  : "Subjects"}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        )}

        {this.state.selectedTab === "Groups" && (
          <>
            <Collapse isOpen={this.state.createGroupOpen}>
              <div className="my-3 shadow-sm card">
                <div className="p-3 card-body d-flex">
                  <div className="col-md-5">
                    <FormGroup>
                      <Label htmlFor="groupName">Group Name</Label>
                      <Input
                        type="text"
                        name="groupName"
                        id="groupName"
                        value={this.state.newGroupName}
                        onChange={e =>
                          this.setState({ newGroupName: e.target.value })
                        }
                      />
                    </FormGroup>

                    <div className="d-flex align-items-start justify-content-start">
                      <FormGroup style={{ flex: 1 }}>
                        <Label htmlFor="groupName">Classes</Label>
                        <Select
                          isMulti
                          isClearable
                          placeholder="Select Class"
                          value={this.state.newGroupClasses}
                          options={this.state.classToShowInGrouping}
                          className="mb-3 basic-multi-select"
                          hideSelectedOptions={false}
                          closeMenuOnSelect={false}
                          components={{ Option, MultiValue }}
                          classNamePrefix="select"
                          onChange={selectedOption => {
                            if (selectedOption !== null) {
                              this.setState({
                                newGroupClasses: selectedOption,
                              })
                            } else {
                              this.setState({
                                newGroupClasses: [],
                              })
                            }
                          }}
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="col-md d-flex justify-content-end align-items-end  ">
                    <Button
                      className="mr-3 rounded-pill"
                      style={{
                        backgroundColor: "#DBDBDB",
                        color: "#06B191",
                        border: "none",
                      }}
                      onClick={() =>
                        this.setState({
                          newGroupClasses: [],
                          newGroupName: "",
                          editingGroupId: null,
                          createGroupOpen: false,
                        })
                      }
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={this.state.groupAdding}
                      className="rounded-pill"
                      style={{ backgroundColor: "#0eb292", border: "none" }}
                      onClick={this.saveClassGroup}
                    >
                      {this.state.groupAdding ? (
                        <HashLoader
                          size={20}
                          color="#fff"
                          loading={this.state.groupAdding}
                          sizeUnit="px"
                        />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </div>
                </div>
                {this.state.editingGroupId && (
                  <div className="mx-3">
                    <p>
                      <b className="text-warning">Warning</b> : Removing class
                      from group will affect / remove all data related it.
                    </p>
                  </div>
                )}
              </div>
            </Collapse>

            <div className="d-flex align-items-start justify-content-evenly">
              {this.props.classGroupsList.map(grp => (
                <ClassGroup
                  key={grp.groupId}
                  {...grp}
                  closeCreateForm={() =>
                    this.setState({ createGroupOpen: false })
                  }
                  deleteClassFromGroup={this.props.deleteClassFromGroup}
                  onClickUpdate={() => {
                    this.onClickCreateGroup()
                    this.setState({
                      editingGroupId: grp.groupId,
                      newGroupName: grp.groupName,
                      newGroupClasses: grp.classes,
                      createGroupOpen: true,
                    })
                  }}
                  onClickDelete={() => {
                    this.props.deleteClassGroup(
                      { groupId: grp.groupId },
                      () => {}
                    )
                  }}
                />
              ))}
            </div>
          </>
        )}
      </AppWrapper>
    )
  }
}

function ClassGroup({
  groupName,
  classes,
  onClickUpdate,
  onClickDelete,
  // deleteClassFromGroup,
  // closeCreateForm,
}) {
  const [isDeleting, setIsDeleting] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="m-3 w-25" style={{ opacity: isDeleting && 0.5 }}>
      <div className="p-3 rounded bg-midnight-bloom">
        <div className="d-flex justify-content-between">
          <h5>{groupName}</h5>
          <UncontrolledDropdown>
            <DropdownToggle tag="a" className="nav-link">
              <i className="text-white cursor-pointer fas fa-ellipsis-v"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={onClickUpdate}>Edit</DropdownItem>
              <DropdownItem
                onClick={() => {
                  // setIsDeleting(true)
                  // onClickDelete(() => setIsDeleting(false))
                  confirmAlertPopUp(
                    VALIDATION_MSG.DELETE_TITLE,
                    VALIDATION_MSG.DELETE_CONTENT,
                    yes => {
                      if (yes) {
                        setIsDeleting(true)
                        onClickDelete(() => setIsDeleting(false))
                      }
                    },
                    {
                      buttons: [],
                      customUI: ({ onClose }) => {
                        return (
                          <div
                            className="custom"
                            style={{
                              backgroundColor: "#fff",
                              borderBottomRightRadius: "12px",
                              borderBottomLeftRadius: "12px",
                            }}
                          >
                            <div
                              className="bg-brand text-light d-flex"
                              style={{
                                padding: "0.5rem 2rem",
                                borderTopRightRadius: "12px",
                                borderTopLeftRadius: "12px",
                              }}
                            >
                              <div
                                className="mx-auto"
                                style={{ fontSize: "18px" }}
                              >
                                Delete
                              </div>
                            </div>

                            <div
                              className="mt-3"
                              style={{
                                padding: "0.5rem 1.5rem",
                              }}
                            >
                              <div
                                className="text-muted"
                                style={{ fontSize: "15px" }}
                              >
                                Are you sure you want to delete?
                              </div>
                              <div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
                                <button
                                  className="px-4 py-1 bg-brand text-light w-25 d-flex align-items-center justify-content-center yes-button"
                                  style={{
                                    borderRadius: "5px",
                                  }}
                                  onClick={() => {
                                    setIsDeleting(true)
                                    onClickDelete(() => setIsDeleting(false))
                                    onClose()
                                  }}
                                >
                                  Yes
                                </button>
                                <button
                                  className="px-4 py-1 ml-4 bg-brand text-light w-25 d-flex align-items-center justify-content-center"
                                  style={{
                                    borderRadius: "5px",
                                  }}
                                  onClick={onClose}
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </div>
                        )
                      },
                    }
                  )
                }}
              >
                Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        {!isOpen && (
          <div
            onClick={() => setIsOpen(true)}
            style={{
              height: 20,
              width: 20,
              backgroundColor: "rgba(156,156,156,0.4)",
            }}
            className="mx-auto mt-1 text-white cursor-pointer d-flex align-items-center justify-content-center rounded-pill"
          >
            <i className="m-0 fas fa-angle-down"></i>
          </div>
        )}
      </div>
      {isOpen && (
        <div
          className="p-3 mx-3"
          style={{
            borderBottomRightRadius: 24,
            borderBottomLeftRadius: 24,
            backgroundColor: "#e2e0e0",
          }}
        >
          <div
            className="subjectgroup-title font-weight-bold"
            style={{ color: "#022636" }}
          >
            Assigned Classes
          </div>
          <ul
            style={{ listStyleType: "none" }}
            className="flex-wrap p-0 m-0 d-flex"
          >
            {classes.map((cls, i) => (
              <li
                key={cls.subjectId}
                style={{
                  padding: "4px 12px",
                  margin: "2px 6px",
                  backgroundColor: "rgba(6,177,145,0.25)",
                  color: "#022636",
                }}
                className="rounded-pill"
              >
                {cls.label}{" "}
                {/* <i
                  className="p-2 cursor-pointer fas fa-times text-brand"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure want to delete class from group?"
                      )
                    ) {
                      closeCreateForm()
                      deleteClassFromGroup(
                        { sectionId: cls.sectionId },
                        () => {}
                      )
                    }
                  }}
                /> */}
              </li>
            ))}
          </ul>
          <div
            onClick={() => setIsOpen(false)}
            style={{
              height: 20,
              width: 20,
              margin: "0px auto",
              backgroundColor: "rgba(7,75,88,0.82)",
            }}
            className="mt-3 text-white cursor-pointer d-flex align-items-center justify-content-center rounded-pill"
          >
            <i className="m-0 fas fa-angle-up"></i>
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    subjectList: state.subjectDetailsState.subjectList,
    classesList: state.classDetailsState.classList,
    classGroupsList: state.classDetailsState.classGroupsList,
    alreadyAssignedClasses: state.classDetailsState.alreadyAssignedClasses,
  }
}

const dispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getClassDetails,
      saveClassDetails,
      deleteClassDetails,
      updateClassDetails,
      getClassGroup,
      saveClassGroup,
      deleteClassGroup,
      updateClassGroup,
      deleteClassFromGroup,
      showNotification,
    },
    dispatch
  )
}

export default connect(mapStateToProps, dispatchToProps)(ClassDetailscreen)
