/*************************************************
 * RedInk
 * @exports
 * @class StudentDatabaseReducer.js
 * @extends Component
 * @author vijayakumar // on 18/12/2019
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from "../../actions/types"
import { myLog } from "../../utils/Utility"

const INTIAL_STATE = {
  adminStudentList: [],
  adminStudentDetail: [],
  studentList: [],
  studentDetail: {
    name: "",
    gender: "",
    registerNumber: "",
    primaryParent: "",
    dob: "",
    riId: "",
    grade: "",
    studentImage: "",
    studentEmail: "",
    studentPhone: "",
    parentsList: [{ parentName: "", parentEmail: "", parentPhone: "" }],
  },
  studentGroups: [],
  studentCurrentGroupDetail: {
    groupName: "",
    studentList: [],
    groupId: "",
  },
}

export const studentDatabaseReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.STUDENT_DATABASE_LIST:
      myLog("payload", action.payload)
      return {
        ...state,
        studentList: [...action.payload],
      }
    case ACTION_TYPES.STUDENT_DATABASE_DETAIL:
      myLog("payload", action.payload)
      return {
        ...state,
        studentDetail: { ...action.payload },
      }
    case ACTION_TYPES.STUDENT_CURRENT_GROUP_DETAIL:
      myLog("payload", action.payload)
      return {
        ...state,
        studentCurrentGroupDetail: { ...action.payload },
      }
    case ACTION_TYPES.STUDENT_DATABASE_DETAIL_INITIAL:
      myLog("payload", state.studentDetail)
      return {
        studentDetail: state.studentDetail,
      }
    case ACTION_TYPES.TEACHER_UPDATE_STUDENT_GROUPS:
      myLog("payload", action.payload)
      return {
        ...state,
        studentGroups: action.payload.studentGroups,
      }
    case ACTION_TYPES.ADMIN_STUDENT_DATABASE_LIST:
      return {
        ...state,
        adminStudentList: [...action.payload],
      }
    case ACTION_TYPES.ADMIN_STUDENT_DETAILS:
      return {
        ...state,
        adminStudentDetail: [...action.payload],
      }
    default:
      return state
  }
}
