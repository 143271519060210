import React, { useState } from "react"
import { PopoverBody, Popover } from "reactstrap"
import { positiveComments, negativeComments } from "./commentsList"
import chatIc from "../../../assets/images/chat.png"

function Btn({ txt = "", onSubmit }) {
  const [readMore, setReadMore] = useState(false)

  const updateShowMore = e => {
    e.stopPropagation()
    setReadMore(p => !p)
  }

  return (
    <button
      onClick={() => onSubmit(txt)}
      className="block w-100 py-1 mb-2 text-left"
    >
      {txt.length > 50 && !readMore ? (
        <>
          {txt.slice(0, 50)}{" "}
          <span className="clr-50AE93 cursor-pointer" onClick={updateShowMore}>
            Read More
          </span>
        </>
      ) : (
        txt
      )}
    </button>
  )
}

function CommentBox({ i, disabled, onChange, gender, stuName }) {
  const [open, setOpen] = useState(false)
  const [tab, setTab] = useState("positive")

  const toggle = () => setOpen(p => !p)

  const updateTab = val => {
    setTab(val)
    document
      .getElementsByClassName("mark-commentbox-popover-txt-box")[0]
      .scroll(0, 0)
  }

  const onSubmit = val => {
    const He_She = gender === "M" ? "he" : "she"
    const Him_Her = gender === "M" ? "him" : "her"
    const His_Her = gender === "M" ? "his" : "her"
    const Himself_Herself = gender === "M" ? "himself" : "herself"

    let final = val
      .replace(/He\/She/gi, He_She)
      .replace(/Him\/Her/gi, Him_Her)
      .replace(/His\/Her/gi, His_Her)
      .replace(/Himself\/Herself/gi, Himself_Herself)
      .replace(/\*student name\*/gi, stuName.trim())

    final = final[0].toUpperCase() + final.slice(1)

    toggle()
    onChange(final)
  }

  return (
    <>
      <div
        className={`position-absolute mark-commentbox-popover-icon cursor-pointer ${
          open ? "active" : ""
        }`}
        onClick={toggle}
        id={`mark-commentbox-popover-${i}`}
      >
        <img src={chatIc} alt="" />
      </div>

      <Popover
        placement="left"
        trigger="legacy"
        isOpen={!disabled && open}
        target={`mark-commentbox-popover-${i}`}
        className="mark-commentbox-popover-wrapper"
        toggle={toggle}
        placementPrefix="custom-po-wrapper bs-popover"
      >
        <PopoverBody className="p-3">
          <div className="mark-commentbox-popover-tab">
            <button
              className={tab === "positive" ? "active fw-600" : ""}
              onClick={() => updateTab("positive")}
            >
              Positive Remark
            </button>
            <button
              className={tab === "negative" ? "active fw-600" : ""}
              onClick={() => updateTab("negative")}
            >
              Negative Remark
            </button>
          </div>

          <div className="mark-commentbox-popover-txt-box custom-scrollbar">
            {tab === "positive"
              ? positiveComments.map(a => (
                  <Btn key={a.key} txt={a.txt} onSubmit={onSubmit} />
                ))
              : negativeComments.map(a => (
                  <Btn key={a.key} txt={a.txt} onSubmit={onSubmit} />
                ))}
          </div>
        </PopoverBody>
      </Popover>
    </>
  )
}

export default CommentBox
