import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"

export const updateAcademicYear = async (body, callBack) => {
  try {
    const response = await Client.post(
      URL.UPDATE_ACADEMIC_YEAR,
      body,
      true,
      true
    )

    if (response.responseCode === 0) {
      myLog("====updateAcademicYear RESPONSE===::::", response)
      callBack(true, response)
    } else {
      callBack(false)
    }
  } catch (error) {
    callBack(false)
    myLog("====updateAcademicYear ERROR===????", error)
  }
}

export const academicYearImorting = async (academicId, callBack) => {
  try {
    const response = await Client.post(
      URL.IMPORT_ACADAMIC + academicId,
      {},
      true,
      true
    )

    if (response.responseCode === 0) {
      myLog("====academicYearImorting RESPONSE===::::", response)
      callBack(true, response)
    } else {
      callBack(false)
    }
  } catch (error) {
    callBack(false)
    myLog("====academicYearImorting ERROR===????", error)
  }
}

export const getOldAcademicList = async callback => {
  try {
    const response = await Client.get(URL.GET_OLD_ACADEMIC_LIST, {}, true, true)
    if (response.responseCode === 0) {
      myLog("====getOldAcademicList RESPONSE===::::", response)
      callback(true, response.data)
    } else {
      callback(false)
    }
  } catch (error) {
    callback(false)
    myLog("====getOldAcademicList ERROR===????", error)
  }
}

export const getClasses = async (params, callback) => {
  try {
    const response = await Client.get(
      URL.GET_CLASSES_IN_UPGRADATION,
      params,
      true,
      false
    )
    if (response.responseCode === 0) {
      myLog("====getClasses RESPONSE===::::", response)
      callback(true, response.classList)
    } else {
      callback(false)
    }
  } catch (error) {
    callback(false)
    myLog("====getClasses ERROR===????", error)
  }
}

export const getStudentLists = async (params, callback) => {
  try {
    const response = await Client.get(
      URL.GET_STUDENTS_LIST_FOR_UPGRADE,
      params,
      true,
      true
    )
    if (response.responseCode === 0) {
      myLog("====getStudentLists RESPONSE===::::", response)
      callback(true, response.data.rows)
    } else {
      callback(false)
    }
  } catch (error) {
    callback(false)
    myLog("====getStudentLists ERROR===????", error)
  }
}

export const updateStudentSections = async (body, callBack) => {
  try {
    const response = await Client.post(URL.PROMOTE_STUDENTS, body, true, true)
    myLog(response)
    callBack(true)
  } catch (error) {
    callBack(false)
    myLog("====updateStudentSections ERROR===????", error)
  }
}
