/*************************************************
 * RedInk
 * @exports
 * @class TakeAttendanceAction.js
 * @extends Component
 * @author vijayakumar // on 05/01/2020
 * @copyright © 2019 RedInk. All rights reserved.
 *************************************************/
import Client from "../../utils/axiosInstance"
import { myLog } from "../../utils/Utility"
import URL from "../../config"
import { ACTION_TYPES } from "../types"
import { showNotification } from "../CommonAction"
import {
  DANGER_TOAST,
  VALIDATION_MSG,
  SUCCESS_TOAST,
} from "../../utils/Constants"

export const saveClassDetailsInStore = ClassDetails => {
  return {
    type: ACTION_TYPES.TEACHER_CLASS_LIST,
    payload: ClassDetails,
  }
}

export const saveAttendenceInStore = attedenceList => {
  return disaptch => {
    disaptch({ type: ACTION_TYPES.TAKE_ATTENDENCE, payload: attedenceList })
  }
}

export const saveViewAttendenceInStore = attedenceList => {
  return disaptch => {
    disaptch({ type: ACTION_TYPES.VIEW_ATTENDENCE, payload: attedenceList })
  }
}

export const getClassList = callBack => {
  return async dispatch => {
    try {
      const response = await Client.get(URL.GET_TEACHERS_CLASS_lIST, null, true)
      if (response) {
        myLog("====getClassList RESPONSE===::::", response)
        dispatch(saveClassDetailsInStore(response.teacherClasses))
        callBack(true, response.teacherClasses)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getClassList ERROR===????", error)
    }
  }
}

export const getStudentAttendanceList = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_TEACHERS_STUDENT_PRESENT_ABSENT_LIST,
        body,
        true
      )
      if (response) {
        myLog("====getStudentAttendanceList RESPONSE===::::", response)
        dispatch(saveAttendenceInStore(response.attendanceList))
        callBack(true, response)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getStudentAttendanceList ERROR===????", error)
    }
  }
}

export const getStudentPresentAndAbsentList = (object, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.get(
        URL.GET_TEACHERS_STUDENT_PRESENT_ABSENT_LIST,
        object,
        true
      )
      if (response) {
        myLog("====getStudentPresentAndAbsentList RESPONSE===::::", response)
        dispatch(saveViewAttendenceInStore(response.attendanceList))
        callBack(true, response.attendanceList)
      } else {
        callBack(false)
      }
    } catch (error) {
      callBack(false)
      myLog("====getStudentPresentAndAbsentList ERROR===????", error)
    }
  }
}

export const saveStudentAttedanceDetails = (body, callBack) => {
  myLog(body)
  return async (dispatch, getState) => {
    try {
      const response = await Client.post(
        URL.SAVE_TEACHERS_STUDENT_ATTENDANCE_LIST,
        body,
        true
      )
      if (response.responseCode === 0) {
        myLog("====saveStudentAttedanceDetails RESPONSE===::::", response)
        // dispatch(getStudentAttendanceList(()=>{}));
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====saveStudentAttedanceDetails ERROR===????", error)
    }
  }
}

export const updateMarkAsHoliday = (body, callBack) => {
  return async dispatch => {
    try {
      const response = await Client.post(URL.SAVE_MARK_AS_HOLIDAY, body, true)
      if (response.responseCode === 0) {
        myLog("====updateMarkAsHoliday RESPONSE===::::", response)
        dispatch(
          showNotification(
            SUCCESS_TOAST,
            VALIDATION_MSG.SUCCESS,
            response.responseMessage
          )
        )
        callBack(true)
      } else {
        dispatch(
          showNotification(
            DANGER_TOAST,
            VALIDATION_MSG.OOPS,
            response.responseMessage
          )
        )
        callBack(false)
      }
    } catch (error) {
      dispatch(
        showNotification(
          DANGER_TOAST,
          VALIDATION_MSG.OOPS,
          "Something went wrong..."
        )
      )
      callBack(false)
      myLog("====updateMarkAsHoliday ERROR===????", error)
    }
  }
}

// export const deleteClassDetails = (body, callBack) => {
//   return async (dispatch) => {
//     try {
//       const response = await Client.delete(URL.DELETE_CLASS_DETAILS, body, true)
//       if (response.responseCode === 0) {
//         myLog("====deleteClassDetails RESPONSE===::::", response)
//         dispatch(getStudentAttendanceList(()=>{}));
//         callBack(true)
//       } else {
//         callBack(false)
//       }
//     } catch (error) {
//       callBack(false)
//       myLog("====deleteClassDetails ERROR===????", error)
//     }
//   }
// }
